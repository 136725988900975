var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal hide-footer no-stacking",
      attrs: {
        id: "ConfirmDeleteAccountModal",
        "data-test-hook": "modal-title-confirm-delete-acct-modal"
      }
    },
    [
      _c("div", { staticClass: "modal-dialog modal-dialog-centered" }, [
        _c("div", { staticClass: "modal-content" }, [
          _c("div", { staticClass: "modal-header" }, [
            _c("h2", { staticClass: "modal-title" }, [
              _c("i", {
                staticClass: "far fa-keyboard colorOrange mr-1",
                attrs: {
                  "data-test-hook": "modal-heading-confirm-delete-acct-modal"
                }
              }),
              _vm._v(" " + _vm._s(_vm.$t("xConfirmDelete")) + " ")
            ]),
            _c(
              "button",
              {
                staticClass: "btn btn-icon-button btn-icon-button--gray",
                attrs: {
                  "data-test-hook": "x-close-btn-confirm-delete-acct-modal"
                },
                on: {
                  click: function($event) {
                    return _vm.closeModal()
                  }
                }
              },
              [_c("i", { staticClass: "fas fa-times" })]
            )
          ]),
          _c("div", { staticClass: "modal-body" }, [
            _c(
              "div",
              { staticClass: "row mt-3", attrs: { "align-h": "around" } },
              [
                _c(
                  "div",
                  {
                    staticClass: "col ml-3 mr-3",
                    attrs: {
                      "data-test-hook": "modal-body-confirm-delete-acct-modal"
                    }
                  },
                  [
                    _vm._v(
                      " " + _vm._s(_vm.$t("xEnterDeleteInFieldBelow")) + " "
                    )
                  ]
                )
              ]
            ),
            _c(
              "div",
              { staticClass: "row mt-4", attrs: { "align-h": "around" } },
              [
                _c("div", { staticClass: "col ml-3 mr-3" }, [
                  _c(
                    "div",
                    {
                      staticClass: "form-group",
                      attrs: { id: "deleteConfirm-group" }
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.$v.editDeleteConfrim.$model,
                            expression: "$v.editDeleteConfrim.$model"
                          }
                        ],
                        staticClass: "form-control",
                        class: _vm.status(_vm.$v.editDeleteConfrim),
                        attrs: {
                          id: "deleteConfirm",
                          type: "text",
                          "aria-describedby": "deleteConfirm-invalid",
                          "data-test-hook":
                            "enter-delete-input-confirm-delete-acct-modal"
                        },
                        domProps: { value: _vm.$v.editDeleteConfrim.$model },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.$v.editDeleteConfrim,
                              "$model",
                              $event.target.value
                            )
                          }
                        }
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "invalid-feedback",
                          attrs: {
                            id: "deleteConfirm-invalid",
                            "data-test-hook":
                              "enter-delete-error-text-confirm-delete-acct-modal"
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("xPleaseEnterDeleteInAllCapitalLetters")
                              ) +
                              " "
                          )
                        ]
                      )
                    ]
                  )
                ])
              ]
            )
          ]),
          _c("div", { staticClass: "modal-footer" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-secondary",
                attrs: {
                  type: "button",
                  disabled: _vm.deleting,
                  "data-test-hook": "cancel-btn-confirm-delete-acct-modal"
                },
                on: {
                  click: function($event) {
                    return _vm.closeModal()
                  }
                }
              },
              [_vm._v(" " + _vm._s(_vm.$t("xCancel")) + " ")]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                attrs: {
                  disabled: _vm.editDeleteConfrim != "DELETE" || _vm.deleting,
                  "data-test-hook": "delete-btn-confirm-delete-acct-modal"
                },
                on: { click: _vm.deleteAccount }
              },
              [
                _vm.deleting
                  ? _c("div", {
                      staticClass: "mr-2 spinner-border text-muted",
                      staticStyle: { width: "1.5rem", height: "1.5rem" }
                    })
                  : _vm._e(),
                _vm._v(" " + _vm._s(_vm.$t("xConfirmDelete")) + " ")
              ]
            )
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }