var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "Modal",
        { attrs: { modalId: "downgrade-paid-sub-step-1", size: "modal-lg" } },
        [
          _c(
            "ModalHeader",
            {
              attrs: {
                title: _vm.$t("xManageSubscription"),
                icon: "far fa-badge-dollar colorOrange"
              }
            },
            [
              _c(
                "button",
                {
                  staticClass: "btn btn-icon-button btn-icon-button--gray",
                  attrs: {
                    "data-test-hook": "x-button-step1-downgrade-sub-modal"
                  },
                  on: {
                    click: function($event) {
                      return _vm.closeModals()
                    }
                  }
                },
                [_c("i", { staticClass: "fas fa-times" })]
              )
            ]
          ),
          _c("ModalBody", [
            _vm.currentSubscription[0].productId == _vm.dealerManaged
              ? _c("div", [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("xYourDealerHasAManagedSubscription")) +
                          " "
                      )
                    ])
                  ])
                ])
              : _c("div", [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col" }, [
                      _c("h3", [_vm._v(_vm._s(_vm.$t("xAreYouSure")))])
                    ])
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col" }, [
                      _vm._v(" " + _vm._s(_vm.$t("xYoullBeMissingOut")) + " ")
                    ])
                  ]),
                  _c("div", { staticClass: "row mt-2" }, [
                    _c("div", { staticClass: "col" }, [
                      _c("ul", [
                        _c("li", [
                          _vm._v(
                            _vm._s(_vm.$t("xEmailRemindersAndNotifications"))
                          )
                        ]),
                        _c("li", [
                          _vm._v(_vm._s(_vm.$t("xTextOrPushNotifications")))
                        ]),
                        _c("li", [
                          _vm._v(
                            _vm._s(_vm.$t("xAbilityToModifyExerciseSchedule"))
                          )
                        ])
                      ])
                    ])
                  ])
                ])
          ]),
          _c("ModalFooter", [
            _c(
              "button",
              {
                staticClass: "btn btn-outline-warning",
                attrs: {
                  type: "button",
                  "data-test-hook": "previous-button-step1-downgrade-sub-modal"
                },
                on: {
                  click: function($event) {
                    _vm.openModal("manage-sub-paid-step-1"),
                      _vm.closeModal("downgrade-paid-sub-step-1")
                  }
                }
              },
              [_vm._v(" " + _vm._s(_vm.$t("xPrevious")) + " ")]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-warning",
                attrs: {
                  type: "button",
                  "data-test-hook":
                    "yes-downgrade-button-step1-downgrade-sub-modal"
                },
                on: {
                  click: function($event) {
                    _vm.openModal("downgrade-paid-sub-step-2"),
                      _vm.closeModal("downgrade-paid-sub-step-1")
                  }
                }
              },
              [_vm._v(" " + _vm._s(_vm.$t("xYesDowngrade")) + " ")]
            )
          ])
        ],
        1
      ),
      _c(
        "Modal",
        { attrs: { modalId: "downgrade-paid-sub-step-2", size: "modal-lg" } },
        [
          _c(
            "ModalHeader",
            {
              attrs: {
                title: _vm.$t("xManageSubscription"),
                icon: "far fa-badge-dollar colorOrange mr-1"
              }
            },
            [
              _c("div", { staticClass: "cols-auto float-right" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-icon-button btn-icon-button--gray",
                    attrs: {
                      type: "button",
                      "data-test-hook": "x-button-step2-downgrade-sub-modal"
                    },
                    on: {
                      click: function($event) {
                        return _vm.closeModals()
                      }
                    }
                  },
                  [_c("i", { staticClass: "fas fa-times" })]
                )
              ])
            ]
          ),
          _c("ModalBody", [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col" }, [
                _c("h3", [
                  _vm._v(_vm._s(_vm.$t("xGotAMinuteToShareYourFeedback")))
                ]),
                _c("p", [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("xWereSorryToHearThatThe")) +
                      " " +
                      _vm._s(_vm.currentSubscription[0].productName) +
                      " " +
                      _vm._s(_vm.$t("xPlanIsNoLongerSatisfyingYourNeeds")) +
                      " " +
                      _vm._s(_vm.$t("xPleaseTellUsWhy")) +
                      " "
                  )
                ])
              ])
            ]),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col" }, [
                _c("fieldset", { staticClass: "form-group" }, [
                  _c("div", { staticClass: "custom-control custom-radio" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.downgradeReason,
                          expression: "downgradeReason"
                        }
                      ],
                      staticClass: "custom-control-input",
                      attrs: {
                        type: "radio",
                        id: "itsTooExpensive",
                        value: "It's too expensive"
                      },
                      domProps: {
                        checked: _vm._q(
                          _vm.downgradeReason,
                          "It's too expensive"
                        )
                      },
                      on: {
                        change: function($event) {
                          _vm.downgradeReason = "It's too expensive"
                        }
                      }
                    }),
                    _c(
                      "label",
                      {
                        staticClass: "custom-control-label",
                        attrs: {
                          "data-test-hook":
                            "too-expensive-radio-step2-downgrade-sub-modal",
                          for: "itsTooExpensive"
                        }
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("xItsTooExpensive")) + " ")]
                    )
                  ]),
                  _c("div", { staticClass: "custom-control custom-radio" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.downgradeReason,
                          expression: "downgradeReason"
                        }
                      ],
                      staticClass: "custom-control-input",
                      attrs: {
                        type: "radio",
                        id: "iAmHavingTooManyTechnicalProblems",
                        value: "I'm having too many techincal problems"
                      },
                      domProps: {
                        checked: _vm._q(
                          _vm.downgradeReason,
                          "I'm having too many techincal problems"
                        )
                      },
                      on: {
                        change: function($event) {
                          _vm.downgradeReason =
                            "I'm having too many techincal problems"
                        }
                      }
                    }),
                    _c(
                      "label",
                      {
                        staticClass: "custom-control-label",
                        attrs: {
                          "data-test-hook":
                            "too-many-problems-radio-step2-downgrade-sub-modal",
                          for: "iAmHavingTooManyTechnicalProblems"
                        }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("xIAmHavingTooManyTechnicalProblems")
                            ) +
                            " "
                        )
                      ]
                    )
                  ]),
                  _c("div", { staticClass: "custom-control custom-radio" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.downgradeReason,
                          expression: "downgradeReason"
                        }
                      ],
                      staticClass: "custom-control-input",
                      attrs: {
                        type: "radio",
                        id: "theFeaturesAreNotValuableEnoughForThePrice",
                        value:
                          "The features aren't valuable enough for the price"
                      },
                      domProps: {
                        checked: _vm._q(
                          _vm.downgradeReason,
                          "The features aren't valuable enough for the price"
                        )
                      },
                      on: {
                        change: function($event) {
                          _vm.downgradeReason =
                            "The features aren't valuable enough for the price"
                        }
                      }
                    }),
                    _c(
                      "label",
                      {
                        staticClass: "custom-control-label",
                        attrs: {
                          "data-test-hook":
                            "not-valuable-radio-step2-downgrade-sub-modal",
                          for: "theFeaturesAreNotValuableEnoughForThePrice"
                        }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "xTheFeaturesAreNotValuableEnoughForThePrice"
                              )
                            ) +
                            " "
                        )
                      ]
                    )
                  ]),
                  _c("div", { staticClass: "custom-control custom-radio" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.downgradeReason,
                          expression: "downgradeReason"
                        }
                      ],
                      staticClass: "custom-control-input",
                      attrs: {
                        type: "radio",
                        id: "thereAreNotEnoughFeaturesForThePrice",
                        value: "There are not enough features for the price"
                      },
                      domProps: {
                        checked: _vm._q(
                          _vm.downgradeReason,
                          "There are not enough features for the price"
                        )
                      },
                      on: {
                        change: function($event) {
                          _vm.downgradeReason =
                            "There are not enough features for the price"
                        }
                      }
                    }),
                    _c(
                      "label",
                      {
                        staticClass: "custom-control-label",
                        attrs: {
                          "data-test-hook":
                            "not-enough-features-radio-step2-downgrade-sub-modal",
                          for: "thereAreNotEnoughFeaturesForThePrice"
                        }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("xThereAreNotEnoughFeaturesForThePrice")
                            ) +
                            " "
                        )
                      ]
                    )
                  ]),
                  _c("div", { staticClass: "custom-control custom-radio" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.downgradeReason,
                          expression: "downgradeReason"
                        }
                      ],
                      staticClass: "custom-control-input",
                      attrs: { type: "radio", id: "other", value: "Other" },
                      domProps: {
                        checked: _vm._q(_vm.downgradeReason, "Other")
                      },
                      on: {
                        change: function($event) {
                          _vm.downgradeReason = "Other"
                        }
                      }
                    }),
                    _c(
                      "label",
                      {
                        staticClass: "custom-control-label",
                        attrs: {
                          "data-test-hook":
                            "other-radio-step2-downgrade-sub-modal",
                          for: "other"
                        }
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("xOther")) + " ")]
                    )
                  ]),
                  _vm.downgradeReason == "Other"
                    ? _c("div", { staticClass: "mt-2" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.downgradeReasonSpecification,
                              expression: "downgradeReasonSpecification"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            maxlength: "500",
                            "data-test-hook":
                              "other-reason-description-downgrade-sub-modal"
                          },
                          domProps: { value: _vm.downgradeReasonSpecification },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) return
                              _vm.downgradeReasonSpecification =
                                $event.target.value
                            }
                          }
                        })
                      ])
                    : _vm._e()
                ])
              ])
            ])
          ]),
          _c("ModalFooter", [
            _c(
              "button",
              {
                staticClass: "btn btn-outline-warning",
                attrs: {
                  type: "button",
                  "data-test-hook": "previous-button-step2-downgrade-sub-modal"
                },
                on: {
                  click: function($event) {
                    _vm.openModal("downgrade-paid-sub-step-1"),
                      _vm.closeModal("downgrade-paid-sub-step-2"),
                      _vm.cleanReasons()
                  }
                }
              },
              [_vm._v(" " + _vm._s(_vm.$t("xPrevious")) + " ")]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-warning",
                attrs: {
                  type: "button",
                  disabled: !_vm.downgradeReason || _vm.downgradingSubscription,
                  "data-test-hook":
                    "confirm-downgrade-step2-downgrade-sub-modal"
                },
                on: {
                  click: function($event) {
                    return _vm.downgradeSubscription()
                  }
                }
              },
              [
                _vm._v(" " + _vm._s(_vm.$t("xConfirmDowngrade")) + " "),
                _vm.downgradingSubscription
                  ? _c("b-spinner", { staticClass: "ml-2" })
                  : _vm._e()
              ],
              1
            )
          ])
        ],
        1
      ),
      _c(
        "Modal",
        { attrs: { modalId: "downgrade-paid-sub-step-3", size: "modal-md" } },
        [
          _c(
            "ModalHeader",
            {
              attrs: {
                title: _vm.$t("xManageSubscription"),
                icon: "far fa-badge-dollar colorOrange mr-1"
              }
            },
            [
              _c(
                "button",
                {
                  staticClass: "btn btn-icon-button btn-icon-button--gray",
                  attrs: {
                    type: "button",
                    "data-test-hook": "x-button-step3-downgrade-sub-modal"
                  },
                  on: {
                    click: function($event) {
                      return _vm.closeModals()
                    }
                  }
                },
                [_c("i", { staticClass: "fas fa-times" })]
              )
            ]
          ),
          _c("ModalBody", [
            _c("div", { staticClass: "row no-gutters" }, [
              _c(
                "div",
                { staticClass: "col" },
                [
                  _c("lottie", {
                    attrs: {
                      options: _vm.successAnimationOptions,
                      height: 80,
                      width: 80
                    },
                    on: { animCreated: _vm.handleAnimation }
                  })
                ],
                1
              )
            ]),
            _c("div", { staticClass: "row text-center mt-3" }, [
              _c("div", { staticClass: "col" }, [
                _c("h1", [_vm._v(_vm._s(_vm.$t("xSuccess")) + "!")]),
                _c("p", [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("xYourSubscriptionPlanHasBeenDowngraded")) +
                      " " +
                      _vm._s(
                        _vm
                          .$moment(_vm.currentSubscription[0].nextBillingDate)
                          .format(_vm.$t("xShortDate"))
                      ) +
                      "." +
                      _vm._s(" ") +
                      _vm._s(_vm.$t("xYouCanUpgradeAtAnyTime")) +
                      " "
                  )
                ])
              ])
            ])
          ]),
          _c("ModalFooter", [
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                attrs: {
                  type: "button",
                  "data-test-hook": "ok-button-step3-downgrade-sub-modal"
                },
                on: {
                  click: function($event) {
                    _vm.closeModals(), _vm.emitSuccess()
                  }
                }
              },
              [_vm._v(" " + _vm._s(_vm.$t("xOK")) + " ")]
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }