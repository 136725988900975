<template>
  <Modal :modalId="'EditEmailPreferencesModal-' + editEmailPreferencesModalInfo.apparatusId">
    <ModalHeader :title="$t('xEditEmailPreferences')" icon="fas fa-user-circle">
      <button
        class="btn btn-icon-button btn-icon-button--gray"
        @click="saving ? null : closeModal('EditEmailPreferencesModal-' + editValue.apparatusId)"
        data-test-hook="x-button-edit-primary"
      >
        <i class="fas fa-times" />
      </button>
    </ModalHeader>
    <ModalBody v-if="editValue">
      <div class="row collapseBackground">
        <div
          class="col"
          v-if="
            editValue &&
              editValue.currentPreferences &&
              editValue.currentPreferences.subscription &&
              (editValue.currentPreferences.subscription.type != freeSubscriptionType ||
                isTankType(editEmailPreferencesModalInfo.apparatusType))
          "
        >
          <div v-for="setting in emailSettings" :key="setting.type" :class="setting.rowClass">
            <hr class="row" v-if="!setting.first && settingTypeList(editValue.currentPreferences, setting.type)" />
            <div
              class="row"
              align-v="center"
              v-if="
                settingTypeList(editValue.currentPreferences, setting.type) &&
                  isEnrolledInVPP(setting, editEmailPreferencesModalInfo)
              "
            >
              <div class="col-1">
                <i
                  :class="iconToDisplay(setting.type, setting.icon)"
                  :data-test-hook="
                    setting.title
                      .toLowerCase()
                      .replace(/\s/g, '-')
                      .concat('-icon-edit-email-prefs-modal')
                  "
                ></i>
              </div>
              <div
                class="col pl-0"
                :data-test-hook="
                  setting.title
                    .toLowerCase()
                    .replace(/\s/g, '-')
                    .concat('-name-edit-email-prefs-modal')
                "
              >
                {{ setting.title }}:
                <b
                  :data-test-hook="
                    setting.title
                      .toLowerCase()
                      .replace(/\s/g, '-')
                      .concat('-status-edit-email-prefs-modal')
                  "
                >
                  {{
                    editValue.currentPreferences.preferences.find(p => p.type == setting.type).receiveEmail
                      ? 'ON'
                      : 'OFF'
                  }}
                </b>
              </div>
              <div class="col-auto ml-1">
                <div
                  class="custom-control custom-switch b-custom-control-lg"
                  :class="
                    editValue.currentPreferences.preferences.find(p => p.type == setting.type).receiveEmail
                      ? 'switch-success'
                      : null
                  "
                >
                  <input
                    class="custom-control-input"
                    type="checkbox"
                    :id="message + setting.title"
                    v-model="editValue.currentPreferences.preferences.find(p => p.type == setting.type).receiveEmail"
                    :data-test-hook="
                      setting.title
                        .toLowerCase()
                        .replace(/\s/g, '-')
                        .concat('-switch-edit-email-prefs-modal')
                    "
                  />
                  <label :for="message + setting.title" class="custom-control-label"></label>
                </div>
              </div>
            </div>
            <div
              class="row align-items-center mt-3"
              v-if="
                setting.type == preferenceTypeTankFuelWarning &&
                  settingTypeList(editValue.currentPreferences, setting.type)
              "
            >
              <div class="col-auto ml-3">
                <i class="fa-light fa-triangle-exclamation"></i>
              </div>
              <div class="col-5 pl-0">
                {{ $t('xWarningLevel') }}
              </div>
              <div class="col-5 ml-auto">
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control text-right"
                    v-model="$v.editValue.currentPreferences.threshold1.$model"
                    :state="validState($v.editValue.currentPreferences.threshold1)"
                    aria-describedby="tankThreshold-invalid"
                    v-mask="'##'"
                    maxlength="2"
                    :disabled="!editValue.currentPreferences.preferences.find(p => p.type == setting.type).receiveEmail"
                  />
                  <div class="input-group-append" :is-text="true">
                    <div class="input-group-text">%</div>
                  </div>
                  <div class="invalid-feedback" id="tankThreshold-invalid">
                    {{ $t('xInvalidTankThreshold') }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col" v-else>
          <div class="row" align-v="center">
            <div class="col-auto">
              <i
                :class="monthlyEmailSummary.icon"
                :data-test-hook="
                  monthlyEmailSummary.title
                    .toLowerCase()
                    .replace(/\s/g, '-')
                    .concat('-icon-edit-email-prefs-modal')
                "
              />
            </div>
            <div
              class="col pl-0"
              :data-test-hook="
                monthlyEmailSummary.title
                  .toLowerCase()
                  .replace(/\s/g, '-')
                  .concat('-name-edit-email-prefs-modal')
              "
            >
              {{ monthlyEmailSummary.title }}:
              <b
                :data-test-hook="
                  monthlyEmailSummary.title
                    .toLowerCase()
                    .replace(/\s/g, '-')
                    .concat('-status-edit-email-prefs-modal')
                "
              >
                {{
                  editValue.currentPreferences.preferences.find(p => p.type == monthlyEmailSummary.type).receiveEmail
                    ? 'ON'
                    : 'OFF'
                }}
              </b>
            </div>
            <div class="col-auto ml-1">
              <div
                class="custom-control custom-switch b-custom-control-lg"
                :class="
                  editValue.currentPreferences.preferences.find(p => p.type == monthlyEmailSummary.type).receiveEmail
                    ? 'switch-success'
                    : null
                "
              >
                <input
                  class="custom-control-input"
                  type="checkbox"
                  id="monthlySummarySwitch"
                  v-model="
                    editValue.currentPreferences.preferences.find(p => p.type == monthlyEmailSummary.type).receiveEmail
                  "
                  :data-test-hook="
                    monthlyEmailSummary.title
                      .toLowerCase()
                      .replace(/\s/g, '-')
                      .concat('-switch-edit-email-prefs-modal')
                  "
                />
                <label for="monthlySummarySwitch" class="custom-control-label"></label>
              </div>
            </div>
          </div>
          <hr />
          <div class="row" align-v="center">
            <div class="col-auto">
              <i
                :class="iconToDisplay(disconnected.type, disconnected.icon)"
                :data-test-hook="
                  disconnected.title
                    .toLowerCase()
                    .replace(/\s/g, '-')
                    .concat('-icon-edit-email-prefs-modal')
                "
              />
            </div>
            <div
              class="col pl-0"
              :data-test-hook="
                disconnected.title
                  .toLowerCase()
                  .replace(/\s/g, '-')
                  .concat('-name-edit-email-prefs-modal')
              "
            >
              {{ disconnected.title }}:
              <b
                :data-test-hook="
                  disconnected.title
                    .toLowerCase()
                    .replace(/\s/g, '-')
                    .concat('-status-edit-email-prefs-modal')
                "
              >
                {{
                  editValue.currentPreferences.preferences.find(p => p.type == disconnected.type).receiveEmail
                    ? 'ON'
                    : 'OFF'
                }}
              </b>
            </div>
            <div class="ml-1 col-auto">
              <div
                class="custom-control custom-switch b-custom-control-lg"
                :class="
                  editValue.currentPreferences.preferences.find(p => p.type == disconnected.type).receiveEmail
                    ? 'switch-success'
                    : null
                "
              >
                <input
                  class="custom-control-input"
                  type="checkbox"
                  id="disconnectedNotificationSwitch"
                  v-model="editValue.currentPreferences.preferences.find(p => p.type == disconnected.type).receiveEmail"
                  :data-test-hook="
                    disconnected.title
                      .toLowerCase()
                      .replace(/\s/g, '-')
                      .concat('-switch-edit-email-prefs-modal')
                  "
                />
                <label for="disconnectedNotificationSwitch" class="custom-control-label"></label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ModalBody>

    <ModalFooter>
      <button
        type="button"
        class="btn btn-outline-warning"
        @click="closeModal('EditEmailPreferencesModal-' + editEmailPreferencesModalInfo.apparatusId)"
        :disabled="saving"
        data-test-hook="cancel-button-edit-primary"
      >
        {{ $t('xCancel') }}
      </button>

      <button
        type="button"
        class="btn btn-warning"
        @click="save()"
        :disabled="!validThreshold || saving"
        data-test-hook="save-button-edit-primary"
      >
        {{ $t('xSave') }}
        <div
          class="ml-2 spinner-border text-muted"
          v-if="saving"
          data-test-hook="save-button-spinner-edit-primary"
        ></div>
      </button>
    </ModalFooter>
  </Modal>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { required, minLength, maxLength, email, numeric, between, requiredIf } from 'vuelidate/lib/validators'
import * as Constants from '@/constants'
import { componentsPlugin } from 'bootstrap-vue'

export default {
  components: {},
  props: ['editEmailPreferencesModalInfo'],
  computed: {
    ...mapState({
      currentAppConfig: state => state.application.currentAppConfig
    }),
    vppFeatureFlag() {
      return Constants.VirtualPowerPlantFF
    },
    freeSubscriptionType() {
      return Constants.FreeSubscription
    },
    preferenceTypeTankFuelWarning() {
      return Constants.PreferenceTypeTankFuelWarning
    }
  },
  data() {
    return {
      editValue: null,
      saving: false,
      validThreshold: null,
      minThresholdVal: 17,
      maxThresholdVal: 99,
      message: 'EditEmailPreferencesModal: ',
      emailSettings: [
        {
          title: this.$t('xStatusChanges'),
          icon: 'far fa-history',
          type: Constants.PreferenceTypeStatusChange,
          rowClass: null,
          first: true
        },
        {
          title: this.$t('xMaintenanceReminders'),
          icon: 'far fa-toolbox',
          type: Constants.PreferenceTypeMaintenance,
          rowClass: null
        },
        {
          title: this.$t('xFaults'),
          icon: 'far fa-minus-octagon',
          type: Constants.PreferenceTypeFault,
          rowClass: null
        },
        {
          title: this.$t('xWarnings'),
          icon: 'far fa-exclamation-triangle',
          type: Constants.PreferenceTypeWarning,
          rowClass: null
        },
        {
          title: this.$t('xExerciseConfirmations'),
          icon: 'far fa-bolt',
          type: Constants.PreferenceTypeExercise,
          rowClass: null
        },
        {
          title: this.$t('xMonthlyEmailSummary'),
          icon: 'far fa-envelope-open-text',
          type: 7,
          rowClass: null
        },
        {
          title: this.$t('xVirtualPowerPlant'),
          icon: 'far fa-regular fa-plug',
          type: Constants.PreferenceTypeGridServices,
          rowClass: null
        },
        {
          title: this.$t('xCriticalBatteryLevel'),
          icon: 'far fa-light fa-battery-exclamation',
          type: Constants.PreferenceTypeTankBatteryCritcal,
          rowClass: null,
          first: true
        },
        {
          title: this.$t('xTankLevelWarning'),
          icon: 'far fa-light fa-gauge-high',
          type: Constants.PreferenceTypeTankFuelWarning,
          rowClass: null
        },
        {
          title: this.$t('xNoSignalAlerts'),
          icon: 'far fa-signal-slash',
          type: Constants.PreferenceTypeDisconnected,
          rowClass: null
        }
      ],
      monthlyEmailSummary: {
        title: this.$t('xMonthlyEmailSummary'),
        icon: 'far fa-envelope-open-text',
        type: Constants.PreferenceTypeMonthSummary,
        rowClass: null
      },
      disconnected: {
        title: this.$t('xNoSignalAlerts'),
        icon: 'far fa-signal-slash',
        type: Constants.PreferenceTypeDisconnected,
        rowClass: null
      }
    }
  },
  validations: {
    editValue: {
      phone: {
        required,
        minLength: minLength(10),
        maxLength: maxLength(12),
        numeric
      },
      email: {
        required,
        email
      },
      currentPreferences: {
        threshold1: {
          required: requiredIf(function() {
            return this.editValue.currentPreferences.preferences.find(
              p => p.type == Constants.PreferenceTypeTankFuelWarning
            ).receiveEmail
          }),
          between: between(17, 99),
          maxLength: maxLength(2),
          numeric
        }
      }
    }
  },
  methods: {
    async save() {
      this.saving = true
      for (let setting of this.emailSettings) {
        if (this.settingTypeList(this.editValue.currentPreferences, setting.type)) {
          if (
            this.editValue.currentPreferences.preferences.find(p => p.type == setting.type).receiveEmail !=
            this.editEmailPreferencesModalInfo.currentPreferences.preferences.find(p => p.type == setting.type)
              .receiveEmail
          ) {
            await this.savePreference(setting.type, 'email')
          }
          if (setting.type == Constants.PreferenceTypeTankFuelWarning) {
            if (
              this.editValue.currentPreferences.threshold1 !=
              this.editEmailPreferencesModalInfo.currentPreferences.threshold1
            ) {
              await this.saveThreshold()
            }
          }
        }
      }
      if (
        this.editValue.currentPreferences.preferences.find(p => p.type == this.disconnected.type).receiveEmail !=
        this.editEmailPreferencesModalInfo.currentPreferences.preferences.find(p => p.type == this.disconnected.type)
          .receiveEmail
      ) {
        await this.savePreference(this.disconnected.type, 'email')
      }
      this.closeModal('EditEmailPreferencesModal-' + this.editEmailPreferencesModalInfo.apparatusId)
      this.$emit('buildPreferenceInfo', {})
      this.saving = false
    },
    async savePreference(preferenceType, notificationType) {
      await this.$store
        .dispatch('updateApparatusPreference', {
          apparatusId: this.editEmailPreferencesModalInfo.apparatusId,
          preference: this.editValue.currentPreferences.preferences.find(p => p.type == preferenceType)
        })
        .then(response => {
          this.editEmailPreferencesModalInfo.currentPreferences.preferences.find(
            p => p.type == preferenceType
          ).receiveEmail = !this.editEmailPreferencesModalInfo.currentPreferences.preferences.find(
            p => p.type == preferenceType
          ).receiveEmail
        })
    },
    async saveThreshold() {
      await this.$store
        .dispatch('updateTankUtilityApparatus', {
          apparatusId: this.editEmailPreferencesModalInfo.apparatusId,
          threshold1: this.editValue.currentPreferences.threshold1
        })
        .then(response => {
          this.$store.dispatch('displayToast', {
            title: this.$t('xSuccess'),
            message: this.$t('xTankThresholdUpdated'),
            variant: 'success'
          })
        })
        .catch(error => {
          this.$store.dispatch('displayToast', {
            title: this.$t('xError'),
            message: this.$t('xTankThresholdFailed'),
            variant: 'danger'
          })
        })
    },
    isEnrolledInVPP(settings, preference) {
      if (settings.type != Constants.PreferenceTypeGridServices) return true
      if (preference.currentPreferences.isEnrolledInVPP) return true
      return false
    },
    vppFlag() {
      return (
        this.currentAppConfig &&
        this.currentAppConfig.featureFlags &&
        this.currentAppConfig.featureFlags[this.vppFeatureFlag]
      )
    },
    settingTypeList(currentPreferences, settingType) {
      if (this.editEmailPreferencesModalInfo.apparatusType == Constants.FuelTankApparatusType) {
        if (
          settingType == Constants.PreferenceTypeTankBatteryWarning ||
          settingType == Constants.PreferenceTypeTankBatteryCritcal ||
          settingType == Constants.PreferenceTypeTankFuelWarning
        ) {
          return true
        }
        return false
      }
      if (this.editEmailPreferencesModalInfo.apparatusType == Constants.HsbApparatusType) {
        if (
          settingType == Constants.PreferenceTypeTankBatteryWarning ||
          settingType == Constants.PreferenceTypeTankBatteryCritcal ||
          settingType == Constants.PreferenceTypeTankFuelWarning
        ) {
          return false
        }
        if (
          (settingType != Constants.PreferenceTypeGridServices &&
            currentPreferences &&
            currentPreferences.subscription &&
            this.subscriptionPaid(currentPreferences.subscription)) ||
          settingType == Constants.PreferenceTypeMonthSummary
        ) {
          return true
        }
        if (
          settingType == Constants.PreferenceTypeGridServices &&
          currentPreferences &&
          currentPreferences.subscription &&
          currentPreferences.preferences.find(p => p.type == settingType) != undefined &&
          this.vppFlag()
        ) {
          return true
        }
        return false
      }
    },
    isTankType(type) {
      if (type == Constants.FuelTankApparatusType) {
        return true
      }
      return false
    },
    iconToDisplay(type, icon) {
      if (type == Constants.PreferenceTypeDisconnected) {
        if (
          this.editValue.currentPreferences.deviceType == Constants.HsbWifiDeviceType ||
          this.editValue.currentPreferences.deviceNetworkType == Constants.HsbWifiDeviceType
        )
          return 'far fa-light fa-wifi-slash'
        if (
          this.editValue.currentPreferences.deviceType == Constants.HsbLteDeviceType ||
          this.editValue.currentPreferences.deviceType == Constants.HsbEthDeviceType ||
          this.editValue.currentPreferences.deviceType == Constants.HsbCdmaDeviceType ||
          this.editValue.currentPreferences.deviceNetworkType == Constants.HsbLteDeviceType
        )
          return 'far fa-signal-slash'
      }
      return icon
    }
  },
  watch: {
    editEmailPreferencesModalInfo: function(newVal, oldVal) {
      if (newVal) {
        this.editValue = _.cloneDeep(newVal)
        if (this.editValue.currentPreferences.threshold1 > 99 || this.editValue.currentPreferences.threshold1 < 1) {
          this.editValue.currentPreferences.threshold1 = 30
        }
      }
    },
    'editValue.currentPreferences.threshold1': function(newVal, oldVal) {
      if (newVal && (newVal < this.minThresholdVal || newVal > this.maxThresholdVal)) {
        this.validThreshold = false
      } else if (!newVal) this.validThreshold = false
      else if (isNaN(newVal)) this.validThreshold = false
      else this.validThreshold = true
    },
    deep: true
  },
  mounted() {
    this.editValue = _.cloneDeep(this.editEmailPreferencesModalInfo)
    if (this.editValue.currentPreferences.threshold1 > 99 || this.editValue.currentPreferences.threshold1 < 1) {
      this.editValue.currentPreferences.threshold1 = 30
    }
    if (!this.currentAppConfig) this.$store.dispatch('getApplicationConfiguration', 'Any')
  },
  created() {
    this.editValue = _.cloneDeep(this.editEmailPreferencesModalInfo)
  }
}
</script>
