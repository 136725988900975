<template>
  <div>
    <div v-if="cdmaWarning" @click="trackAppInsightsEvent('cdma-banner-click')" class="pt-3">
      <b-link :href="cdmaWarning.link" target="_blank" style="text-decoration: none !important;">
        <b-alert variant="warning" class="hoverPointer mb-0" show>
          <b-row>
            <b-col>
              <h2>
                <i class="fas fa-exclamation-triangle banner-category-warning" />
                {{ cdmaWarning.title }}
              </h2>
              <p>{{ cdmaWarning.message }}</p>
              <p class="mb-0 pb-0">
                {{ cdmaWarning.linkText }}
                <i class="fas fa-arrow-right" />
              </p>
            </b-col>
          </b-row>
        </b-alert>
      </b-link>
    </div>
    <b-alert
      v-if="
        currentAppConfig && currentAppConfig.featureFlags && currentAppConfig.featureFlags[billingMigrationFeatureFlag]
      "
      show
      variant="warning"
    >
      {{ $t('xBillingMaintenanceMessage') }}
    </b-alert>
    <div @click="$bvModal.show('banner-message-modal')">
      <b-alert
        v-if="showBannerMessage(bannerMessage)"
        class="hoverPointer"
        show
        :variant="getBannerVariant(bannerMessage)"
      >
        <i :class="getBannerIconClassAndColor(bannerMessage)" />
        {{ bannerMessage.BannerMessage }}
        <strong>
          {{ $t('xLearnMore') }}
          <i class="fas fa-arrow-right" />
        </strong>
      </b-alert>
    </div>
    <b-row class="py-4">
      <b-col lg="5" xl="4" class="mb-3 d-flex pr-md-0">
        <b-card class="w-100">
          <b-row>
            <b-col>
              <h2>{{ $t('xHowCanWeHelpYou') }}</h2>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              {{ $t('xHereForYou') }}
            </b-col>
          </b-row>
          <b-row class="my-3">
            <b-col cols="auto">
              <b-link size="lg" class="body-text-color d-inline-block mr-2">
                <i class="fas fa-phone-alt colorOrange"></i>
                (855) 436-8439 (USA)
              </b-link>
              |
              <b-link size="lg" class="body-text-color d-inline-block ml-2">
                (844) 843-9436 (Canada)
              </b-link>
            </b-col>
          </b-row>
          <b-row class="my-3">
            <b-col>
              <b-link
                class="body-text-color d-block"
                href="mailto:Support@MobileLinkGen.com?subject=Mobile%20Link%20Support%20Request"
                size="lg"
                text-decoration-none
              >
                <i class="fas fa-envelope colorOrange"></i>
                support@mobilelinkgen.com
              </b-link>
            </b-col>
          </b-row>
          <b-row class="my-3">
            <b-col>
              <b-link href="https://support.generac.com/mobilelink" target="_blank" class="d-block body-text-color">
                <i class="fas fa-external-link fa-lg colorOrange" />
                {{ $t('xSupportCenter') }}
              </b-link>
            </b-col>
          </b-row>

          <b-row class="mt-5">
            <b-col>
              <h2 class="body-text-color">
                {{ $t('xSuggestions') }}
              </h2>
            </b-col>
          </b-row>
          <b-row class="mt-2 mb-3">
            <b-col>
              <b-button variant="outline-warning" @click="$bvModal.show('feedback-modal')">
                <i class="fas fa-lightbulb" />
                {{ $t('xSendFeedback') }}
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import * as Constants from '@/constants'

export default {
  data() {
    return {}
  },
  computed: {
    ...mapState({
      currentAppConfig: state => state.application.currentAppConfig,
      bannerMessage: state => state.application.bannerMessage,
      cdmaWarning: state => state.cdmaBanner.cdmaWarning
    }),
    billingMigrationFeatureFlag() {
      return Constants.BillingMigrationFeatureFlag
    }
  },
  methods: {
    getCircleImage() {
      return require('@/assets/images/help-circles.svg')
    },
    goTo(routeName) {
      this.$router.push({ name: routeName })
    }
  },
  beforeMount() {
    if (!this.cdmaWarning) this.$store.dispatch('getCdmaWarning')
  },
  mounted() {
    if (!this.currentAppConfig) this.$store.dispatch('getApplicationConfiguration', 'Any')
  }
}
</script>

<style scoped>
.support-center-btn {
  background-color: white;
}
.support-center-btn:hover {
  background-color: white;
}
.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}
</style>
