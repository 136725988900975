var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    !_vm.statusHistory
      ? _c("div", { staticClass: "d-flex justify-content-center mb-3 pt-4" }, [
          _vm._m(0)
        ])
      : _c("div", [
          _c("div", { staticClass: "row my-4 d-md-none no-gutters" }, [
            _vm._m(1),
            _c("div", { staticClass: "ml-2 col" }, [
              _c("h3", [_vm._v(_vm._s(_vm.$t("xStatusHistory")))])
            ])
          ]),
          _c("div", { staticClass: "card" }, [
            _c(
              "div",
              { staticClass: "list-group list-group-flush" },
              _vm._l(_vm.statusHistory, function(status, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "list-group-item",
                    attrs: { id: index }
                  },
                  [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-auto" }, [
                        _c("small", [
                          _vm._v(
                            _vm._s(
                              _vm.displayShortDateTimeWithTZ(
                                status.eventOccurred
                              )
                            )
                          )
                        ])
                      ])
                    ]),
                    _c("div", { staticClass: "row no-gutters center" }, [
                      _c(
                        "div",
                        { staticClass: "col-auto" },
                        [
                          _c("StatusIcon", {
                            attrs: {
                              apparatusStatus: status.type,
                              isConnected: true,
                              showWarning: false,
                              small: true,
                              deviceType:
                                _vm.deviceInfo == null
                                  ? _vm.hsbLteDeviceType
                                  : _vm.deviceInfo.deviceType ==
                                    _vm.hsbG3DeviceType
                                  ? _vm.deviceInfo.networkType
                                  : _vm.deviceInfo.deviceType
                            }
                          })
                        ],
                        1
                      ),
                      _c("div", { staticClass: "col ml-2" }, [
                        _vm._v(" " + _vm._s(status.message) + " ")
                      ])
                    ])
                  ]
                )
              }),
              0
            ),
            _c("div", { staticClass: "card-footer" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-outline-warning",
                  attrs: {
                    disabled: _vm.noMoreHistory,
                    type: "button",
                    "data-test-hook": "view-more-button-status-history"
                  },
                  on: {
                    click: function($event) {
                      return _vm.viewMore()
                    }
                  }
                },
                [
                  _vm._v(" View More "),
                  _vm.loadingAdditionalStatusHistory
                    ? _c(
                        "div",
                        {
                          staticClass: "ml-2 spinner-border text-dark",
                          attrs: { role: "status" }
                        },
                        [
                          _c("span", { staticClass: "sr-only" }, [
                            _vm._v("Loading...")
                          ])
                        ]
                      )
                    : _vm._e()
                ]
              )
            ])
          ])
        ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "spinner-border text-warning",
        staticStyle: { width: "5rem", height: "5rem" },
        attrs: { role: "status" }
      },
      [_c("span", { staticClass: "sr-only" }, [_vm._v("Loading...")])]
    )
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-auto" }, [
      _c("i", { staticClass: "fas fa-history fa-2x colorOrange" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }