var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.fetching
      ? _c("div", { staticClass: "d-flex justify-content-center mt-5 pt-4" }, [
          _c("span", {
            staticClass: "spinner-border text-warning",
            staticStyle: { width: "5rem", height: "5rem" },
            attrs: { label: "Spinning" }
          })
        ])
      : _vm._e(),
    !_vm.fetching
      ? _c(
          "div",
          { staticClass: "mt-2 ml-2" },
          [
            _c("div", { staticClass: "row mb-2" }, [
              _c("div", { staticClass: "col" }, [
                _c("h1", { staticClass: "mb-0 pb-0" }, [
                  _c("i", {
                    staticClass: "far fa-warning colorOrange mr-1",
                    attrs: { "data-test-hook": "fuel-level-warning-icon" }
                  }),
                  _c(
                    "span",
                    {
                      attrs: {
                        "data-test-hook": "fuel-level-warning-title-text"
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("xFuelLevelWarning")))]
                  )
                ])
              ])
            ]),
            _c("div", { staticClass: "card" }, [
              _c("div", { staticClass: "card-body" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col" }, [
                    _c(
                      "span",
                      {
                        staticStyle: { size: "16px", "line-height": "20px" },
                        attrs: {
                          "data-test-hook": "fuel-level-warning-heading"
                        }
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("xWarningLevel")) + " ")]
                    ),
                    _c("strong", [
                      _c(
                        "p",
                        {
                          staticStyle: {
                            "padding-top": "4px",
                            size: "16px",
                            "line-height": "20px"
                          },
                          attrs: {
                            "data-test-hook":
                              "fuel-level-warning-threshold-value"
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.threashold() + "%") + " ")]
                      )
                    ])
                  ]),
                  _c("div", { staticClass: "cols float-right" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-icon-button",
                        attrs: {
                          type: "button",
                          "data-test-hook":
                            "tank-fuel-level-warning-edit-button"
                        },
                        on: {
                          click: function($event) {
                            return _vm.openEditModal()
                          }
                        }
                      },
                      [
                        _c("i", {
                          staticClass: "far fa-pencil",
                          attrs: {
                            "data-test-hook":
                              "fuel-level-warning-edit-button-icon"
                          }
                        })
                      ]
                    )
                  ])
                ])
              ])
            ]),
            _c("EditFuelLevelWarningModal", {
              attrs: { threashold: this.threashold() }
            })
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }