var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.isLoading
      ? _c(
          "div",
          { staticClass: "d-flex justify-content-center mb-3 pt-4" },
          [
            _c("b-spinner", {
              staticStyle: { width: "5rem", height: "5rem" },
              attrs: { variant: "warning", label: "Spinning" }
            })
          ],
          1
        )
      : _c(
          "div",
          [
            _c(
              "div",
              {
                staticClass: "card no-padding",
                attrs: { "body-class": "pt-0", "header-bg-variant": "white" }
              },
              [
                _c("div", { staticClass: "card-header" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex align-items-center justify-content-between"
                    },
                    [
                      _c("h1", { staticClass: "d-inline-block mb-0" }, [
                        _c("i", {
                          staticClass: "far fa-user-circle colorOrange"
                        }),
                        _c(
                          "span",
                          {
                            staticClass: "ml-2",
                            attrs: {
                              "data-test-hook":
                                "account-info-title-account-settings"
                            }
                          },
                          [_vm._v(_vm._s(_vm.$t("xAccountInformation")) + " ")]
                        )
                      ])
                    ]
                  )
                ]),
                _c("div", { staticClass: "row mt-4 ml-2" }, [
                  _c("div", { staticClass: "col-auto mb-1" }, [
                    _c("i", { staticClass: "far fa-user colorOrange" }),
                    _c(
                      "strong",
                      {
                        staticClass: "ml-2",
                        attrs: {
                          "data-test-hook":
                            "account-holder-name-address-title-account-settings"
                        }
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("xAccountHolder")) + " ")]
                    )
                  ]),
                  _c(
                    "div",
                    { staticClass: "col-auto" },
                    [
                      _c(
                        "popper",
                        {
                          attrs: {
                            trigger: "hover",
                            options: { placement: "right" }
                          }
                        },
                        [
                          _c("div", { staticClass: "popper" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("xPrimaryBillingContactAndAddressInfo")
                                ) +
                                " "
                            )
                          ]),
                          _c("i", {
                            staticClass: "far fa-question-circle",
                            attrs: { slot: "reference" },
                            slot: "reference"
                          })
                        ]
                      )
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "row mt-1 ml-2" }, [
                  !_vm.accountInfo
                    ? _c(
                        "div",
                        {
                          staticClass: "d-flex justify-content-center mb-3 pt-4"
                        },
                        [
                          _c("span", {
                            staticClass:
                              "spinner-border spinner-border-xl text-warning",
                            staticStyle: { width: "5rem", height: "5rem" },
                            attrs: {
                              "data-test-hook": "LoadingSpinner",
                              label: "Spinning"
                            }
                          })
                        ]
                      )
                    : _vm._e(),
                  _vm.accountInfo
                    ? _c("div", { staticClass: "col" }, [
                        _c(
                          "p",
                          {
                            attrs: {
                              "data-test-hook":
                                "account-holder-name-account-settings"
                            }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.accountInfo.ownerFirstName +
                                    " " +
                                    _vm.accountInfo.ownerLastName
                                ) +
                                " "
                            )
                          ]
                        ),
                        _vm.accountInfo.ownerAddress.line1
                          ? _c(
                              "p",
                              {
                                attrs: {
                                  "data-test-hook":
                                    "account-holder-address-line-1-account-settings"
                                }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.accountInfo.ownerAddress.line1) +
                                    " "
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm.accountInfo.ownerAddress.line2
                          ? _c(
                              "p",
                              {
                                attrs: {
                                  "data-test-hook":
                                    "account-holder-address-line-2-account-settings"
                                }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.accountInfo.ownerAddress.line2) +
                                    " "
                                )
                              ]
                            )
                          : _vm._e(),
                        _c(
                          "p",
                          {
                            attrs: {
                              "data-test-hook":
                                "account-holder-address-city-state-zip-account-settings"
                            }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.accountInfo.ownerAddress.city +
                                    ", " +
                                    _vm.accountInfo.ownerAddress.region +
                                    " " +
                                    _vm.accountInfo.ownerAddress.postalCode
                                ) +
                                " "
                            )
                          ]
                        ),
                        _c(
                          "p",
                          {
                            attrs: {
                              "data-test-hook":
                                "account-holder-phone-number-account-settings"
                            }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.accountInfo.ownerPhoneNumber) +
                                " "
                            )
                          ]
                        )
                      ])
                    : _vm._e()
                ]),
                _c("div", { staticClass: "row ml-2 mb-4" }, [
                  _c("div", { staticClass: "col" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-outline-warning mt-3",
                        attrs: {
                          type: "button",
                          title: _vm.$t("xUpdateAccountInfo"),
                          "data-test-hook":
                            "update-account-info-button-account-settings"
                        },
                        on: {
                          click: function($event) {
                            return _vm.openEditAccountModal()
                          }
                        }
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("xUpdateAccountInfo")) + " ")]
                    )
                  ])
                ]),
                _c("div", { staticClass: "row mt-4 ml-2" }, [
                  _c("div", { staticClass: "col-auto mb-1" }, [
                    _c("i", { staticClass: "far fa-lock colorOrange" }),
                    _c(
                      "strong",
                      {
                        staticClass: "ml-2",
                        attrs: {
                          "data-test-hook":
                            "account-holder-login-title-account-settings"
                        }
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("xAccountLogin")) + " ")]
                    )
                  ]),
                  _c(
                    "div",
                    { staticClass: "col-auto" },
                    [
                      _c(
                        "popper",
                        {
                          attrs: {
                            trigger: "hover",
                            options: { placement: "right" }
                          }
                        },
                        [
                          _c("div", { staticClass: "popper" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("xEmailUsedToLoginAndRegisterMessage")
                                ) +
                                " "
                            )
                          ]),
                          _c("i", {
                            staticClass: "far fa-question-circle",
                            attrs: { slot: "reference" },
                            slot: "reference"
                          })
                        ]
                      )
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "row ml-2" }, [
                  _c("div", { staticClass: "col-auto" }, [
                    _vm.accountProfile.identityEmail
                      ? _c(
                          "p",
                          {
                            attrs: {
                              "data-test-hook":
                                "account-holder-login-email-account-settings"
                            }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.accountProfile.identityEmail) +
                                " "
                            )
                          ]
                        )
                      : _vm._e()
                  ])
                ]),
                _c("div", { staticClass: "row ml-2 mb-4" }, [
                  _c("div", { staticClass: "col" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-outline-warning mt-3",
                        attrs: {
                          type: "button",
                          title: _vm.$t("xUpdateAccountEmail"),
                          "data-test-hook":
                            "update-account-email-button-account-settings"
                        },
                        on: {
                          click: function($event) {
                            return _vm.updateEmail(
                              _vm.accountProfile.identityEmail
                            )
                          }
                        }
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.$t("xUpdateAccountEmail")) + " "
                        ),
                        _c("i", { staticClass: "fas fa-external-link" })
                      ]
                    )
                  ])
                ]),
                _vm.subscription
                  ? _c("div", [
                      _c("div", { staticClass: "row mt-4 ml-2" }, [
                        _c("div", { staticClass: "col-auto mb-1" }, [
                          _c("i", {
                            staticClass: "far fa-credit-card colorOrange"
                          }),
                          _c(
                            "strong",
                            {
                              staticClass: "ml-2",
                              attrs: {
                                "data-test-hook":
                                  "account-holder-payment-information-account-settings"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("xPaymentInformation")) +
                                  " "
                              )
                            ]
                          )
                        ]),
                        _c(
                          "div",
                          { staticClass: "col-auto" },
                          [
                            _c(
                              "popper",
                              {
                                attrs: {
                                  trigger: "hover",
                                  options: { placement: "right" }
                                }
                              },
                              [
                                _c("div", { staticClass: "popper" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "xPrimaryPaymentInformationMessage"
                                        )
                                      ) +
                                      " "
                                  )
                                ]),
                                _c("i", {
                                  staticClass: "far fa-question-circle",
                                  attrs: { slot: "reference" },
                                  slot: "reference"
                                })
                              ]
                            )
                          ],
                          1
                        )
                      ]),
                      _c("div", { staticClass: "row ml-2" }, [
                        _c("div", { staticClass: "col-auto" }, [
                          _c(
                            "p",
                            {
                              attrs: {
                                "data-test-hook":
                                  "account-holder-payment-information-card-account-settings"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.subscription.cardType.toUpperCase() +
                                      " - " +
                                      _vm.subscription.cardSuffix
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ])
                      ]),
                      _c("div", { staticClass: "row ml-2" }, [
                        _c("div", { staticClass: "col-auto" }, [
                          _c(
                            "p",
                            {
                              attrs: {
                                "data-test-hook":
                                  "account-holder-payment-information-expiration-account-settings"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("xExpires") +
                                      " " +
                                      _vm.displayMonth(
                                        _vm.subscription.expirationMonth
                                      ) +
                                      "/" +
                                      _vm.subscription.expirationYear
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ])
                      ]),
                      _vm.shouldShowUpdatePayment
                        ? _c("div", { staticClass: "row ml-2 mb-4" }, [
                            _c("div", { staticClass: "col" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-outline-warning mt-2",
                                  attrs: {
                                    type: "button",
                                    title: _vm.$t("xUpdatePaymentInformation"),
                                    "data-test-hook":
                                      "update-payment-information-button-account-settings"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.openModal(
                                        "update-payment-method"
                                      )
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("xUpdatePaymentInformation")
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ])
                          ])
                        : _vm._e(),
                      _vm.shouldShowRemovePayment
                        ? _c("div", { staticClass: "row ml-2 mb-4" }, [
                            _c("div", { staticClass: "col" }, [
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "btn btn-outline-danger rounded-pill mt-4",
                                  attrs: {
                                    type: "button",
                                    title: _vm.$t("xRemovePaymentInformation"),
                                    "data-test-hook":
                                      "remove-payment-information-button-account-settings"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.removePaymentInformation()
                                    }
                                  }
                                },
                                [
                                  _c("i", {
                                    staticClass: "fas fa-trash-alt colorOrange"
                                  }),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("xRemovePaymentInformation")
                                      ) +
                                      " "
                                  ),
                                  _vm.removing
                                    ? _c("span", {
                                        staticClass: "spinner-border ml-2"
                                      })
                                    : _vm._e()
                                ]
                              )
                            ])
                          ])
                        : _vm._e()
                    ])
                  : _vm._e(),
                _vm._m(0),
                _c("div", { staticClass: "row ml-2 mb-4" }, [
                  _c("div", { staticClass: "col" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-outline-danger rounded-pill mt-4",
                        attrs: {
                          type: "button",
                          disabled: _vm.isImpersonating,
                          title: _vm.deleteAccountTitle(),
                          "data-test-hook":
                            "delete-account-button-account-settings"
                        },
                        on: {
                          click: function($event) {
                            return _vm.deleteAccount()
                          }
                        }
                      },
                      [
                        _c("i", {
                          staticClass: "fas fa-trash-alt colorOrange"
                        }),
                        _vm._v(" " + _vm._s(_vm.$t("xDeleteAccount")) + " ")
                      ]
                    )
                  ])
                ])
              ]
            ),
            _c(
              "Modal",
              {
                attrs: {
                  modalId: "unable-to-delete-account-modal",
                  size: "modal-lg"
                }
              },
              [
                _c(
                  "ModalHeader",
                  {
                    attrs: {
                      title: _vm.$t("xUnableToDeleteAccount"),
                      icon: "fa-regular fa-trash-can"
                    }
                  },
                  [
                    _c(
                      "button",
                      {
                        staticClass:
                          "btn btn-icon-button btn-icon-button--gray",
                        attrs: {
                          "data-test-hook": "unable-to-delete-account-x-button"
                        },
                        on: {
                          click: function($event) {
                            return _vm.closeModal(
                              "unable-to-delete-account-modal"
                            )
                          }
                        }
                      },
                      [_c("i", { staticClass: "fas fa-times" })]
                    )
                  ]
                ),
                _c("ModalBody", [
                  _c("p", {
                    attrs: { "data-test-hook": "modalBodyText1" },
                    domProps: {
                      innerHTML: _vm._s(_vm.$t("xVppDeleteAccountModalBody1"))
                    }
                  }),
                  _c(
                    "p",
                    {
                      staticClass: "mt-3",
                      attrs: { "data-test-hook": "modalBodyText2" }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("xVppDeleteAccountModalBody2")) +
                          " "
                      )
                    ]
                  )
                ]),
                _c("ModalFooter", [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: { "data-test-hook": "modalDismissButton" },
                      on: {
                        click: function($event) {
                          return _vm.closeModal(
                            "unable-to-delete-account-modal"
                          )
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("xDismiss")) + " ")]
                  )
                ])
              ],
              1
            ),
            _c(
              "Modal",
              {
                attrs: { modalId: "update-payment-method", size: "modal-md" },
                on: {
                  hidden: function($event) {
                    return _vm.closePaymentModal()
                  }
                }
              },
              [
                _c(
                  "ModalHeader",
                  {
                    attrs: {
                      title: _vm.$t("xUpdatePaymentMethod"),
                      icon: "fal fa-credit-card colorOrange mr-1"
                    }
                  },
                  [
                    _c("div", { staticClass: "cols-auto" }, [
                      _c(
                        "button",
                        {
                          staticClass:
                            "btn btn-icon-button btn-icon-button--gray",
                          attrs: {
                            type: "button",
                            "data-test-hook": "CloseModalBtn"
                          },
                          on: {
                            click: function($event) {
                              return _vm.closePaymentModal()
                            }
                          }
                        },
                        [_c("i", { staticClass: "fas fa-times" })]
                      )
                    ])
                  ]
                ),
                _c("ModalBody", [
                  _vm.showValidationBanner
                    ? _c(
                        "div",
                        {
                          staticClass: "alert alert-danger",
                          attrs: {
                            role: "alert",
                            "data-test-hook": "ValidationAlert"
                          }
                        },
                        [
                          _c("p", [
                            _vm._v(_vm._s(_vm.$t("xExperiencedTheseErrors")))
                          ]),
                          _vm._l(_vm.errorMessages, function(message, index) {
                            return _c("ul", { key: index }, [
                              _c(
                                "li",
                                {
                                  attrs: {
                                    id: index,
                                    "data-test-hook": "ValidationMsg"
                                  }
                                },
                                [_vm._v(" " + _vm._s(message) + " ")]
                              )
                            ])
                          })
                        ],
                        2
                      )
                    : _vm._e(),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-12" }, [
                      _c("h3", [
                        _c(
                          "strong",
                          { attrs: { "data-test-hook": "CcInfoHeader" } },
                          [_vm._v(_vm._s(_vm.$t("xCreditCardInfo")))]
                        ),
                        _c("span", { staticClass: "body-text-color" }, [
                          _vm._v("*")
                        ])
                      ])
                    ])
                  ]),
                  _c("div", { staticClass: "row mb-3" }, [
                    _c("div", { staticClass: "col" }, [
                      _c(
                        "i",
                        {
                          staticClass: "asterisk-text",
                          attrs: { "data-test-hook": "UpdatePaymentWarningMsg" }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("xUpdatingPaymentWarning")) +
                              " "
                          )
                        ]
                      )
                    ])
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-12" }, [
                      _c(
                        "div",
                        {
                          staticClass: "form-group",
                          attrs: { id: "credit-card-number" }
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.$v.creditCardNumber.$model,
                                expression: "$v.creditCardNumber.$model"
                              },
                              {
                                name: "mask",
                                rawName: "v-mask",
                                value: "################",
                                expression: "'################'"
                              }
                            ],
                            staticClass: "form-control",
                            class: _vm.status(_vm.$v.creditCardNumber),
                            attrs: {
                              id: "creditCardNumber",
                              maxlength: "16",
                              type: "text",
                              placeholder: _vm.$t("xCreditCardNumber"),
                              "aria-describedby": "card-number-invalid",
                              "data-test-hook": "CcNumber"
                            },
                            domProps: { value: _vm.$v.creditCardNumber.$model },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.$v.creditCardNumber,
                                  "$model",
                                  $event.target.value
                                )
                              }
                            }
                          }),
                          _c(
                            "div",
                            {
                              staticClass: "invalid-feedback",
                              attrs: {
                                id: "card-number-invalid",
                                "data-test-hook": "invalidCcNo"
                              }
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("xCCFieldValidation")) + " "
                              )
                            ]
                          )
                        ]
                      )
                    ])
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col col-lg-6 col-md-6 col-sm-12" },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "form-group",
                            attrs: { id: "expiry-date-month" }
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.$v.expirationMonth.$model,
                                  expression: "$v.expirationMonth.$model"
                                },
                                {
                                  name: "mask",
                                  rawName: "v-mask",
                                  value: "##",
                                  expression: "'##'"
                                }
                              ],
                              staticClass: "form-control",
                              class: _vm.status(_vm.$v.expirationMonth),
                              attrs: {
                                id: "xNm",
                                maxlength: "2",
                                type: "text",
                                placeholder: _vm.$t("xMm"),
                                "aria-describedby": "expiry-month-invalid",
                                "data-test-hook": "CcExpireMonth"
                              },
                              domProps: {
                                value: _vm.$v.expirationMonth.$model
                              },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.$v.expirationMonth,
                                    "$model",
                                    $event.target.value
                                  )
                                }
                              }
                            }),
                            _c(
                              "div",
                              {
                                staticClass: "invalid-feedback",
                                attrs: {
                                  id: "expiry-month-invalid",
                                  "data-test-hook": "invalidCcMth"
                                }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("xExpirationMonthValidation")
                                    ) +
                                    " "
                                )
                              ]
                            )
                          ]
                        )
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "col col-lg-6 col-md-t6 col-sm-12" },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "form-group",
                            attrs: { id: "expiry-date-year" }
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.$v.expirationYear.$model,
                                  expression: "$v.expirationYear.$model"
                                },
                                {
                                  name: "mask",
                                  rawName: "v-mask",
                                  value: "##",
                                  expression: "'##'"
                                }
                              ],
                              staticClass: "form-control",
                              class: _vm.status(_vm.$v.expirationYear),
                              attrs: {
                                type: "text",
                                placeholder: _vm.$t("xYy"),
                                maxlength: "2",
                                "aria-describedby": "expiry-year-invalid",
                                "data-test-hook": "CcExpireYear"
                              },
                              domProps: { value: _vm.$v.expirationYear.$model },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.$v.expirationYear,
                                    "$model",
                                    $event.target.value
                                  )
                                }
                              }
                            }),
                            _c(
                              "div",
                              {
                                staticClass: "invalid-feedback",
                                attrs: {
                                  id: "expiry-year-invalid",
                                  "data-test-hook": "invalidCcYear"
                                }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("xExpirationYearValidation")
                                    ) +
                                    " "
                                )
                              ]
                            )
                          ]
                        )
                      ]
                    ),
                    _c("div", { staticClass: "col-12" }, [
                      _c(
                        "div",
                        { staticClass: "input-group", attrs: { id: "cvv" } },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.$v.cvv.$model,
                                expression: "$v.cvv.$model"
                              },
                              {
                                name: "mask",
                                rawName: "v-mask",
                                value: "####",
                                expression: "'####'"
                              }
                            ],
                            staticClass: "form-control",
                            class: _vm.status(_vm.$v.cvv),
                            attrs: {
                              type: "text",
                              maxlength: "4",
                              placeholder: _vm.$t("xCvv"),
                              "aria-describedby": "cvv-invalid",
                              "data-test-hook": "CcCvv"
                            },
                            domProps: { value: _vm.$v.cvv.$model },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.$v.cvv,
                                  "$model",
                                  $event.target.value
                                )
                              }
                            }
                          }),
                          _c("div", { staticClass: "input-group-append" }, [
                            _c("div", { staticClass: "input-group-text" }, [
                              _c("img", {
                                attrs: {
                                  height: 20,
                                  src: require("@/assets/images/3-digit-code.svg"),
                                  "data-test-hook": "ccCvvImage"
                                }
                              })
                            ])
                          ]),
                          _c(
                            "div",
                            {
                              staticClass: "invalid-feedback",
                              attrs: {
                                id: "cvv-invalid",
                                "data-test-hook": "invalidCcCvv"
                              }
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("xCVVValidation")) + " "
                              )
                            ]
                          )
                        ]
                      )
                    ])
                  ]),
                  _vm.accountData
                    ? _c("div", { staticClass: "mt-4" }, [
                        _c("div", { staticClass: "row mb-2" }, [
                          _c("div", { staticClass: "col" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "font-weight-bold custom-control custom-checkbox"
                              },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.useExistingBillingInformation,
                                      expression:
                                        "useExistingBillingInformation"
                                    }
                                  ],
                                  staticClass: "custom-control-input",
                                  attrs: {
                                    id: "useExistingBillingInformation",
                                    type: "checkbox",
                                    disabled: _vm.savingAccountHolder,
                                    "data-test-hook":
                                      "UseCurrentBillingAddressChkBox"
                                  },
                                  domProps: {
                                    checked: Array.isArray(
                                      _vm.useExistingBillingInformation
                                    )
                                      ? _vm._i(
                                          _vm.useExistingBillingInformation,
                                          null
                                        ) > -1
                                      : _vm.useExistingBillingInformation
                                  },
                                  on: {
                                    change: function($event) {
                                      var $$a =
                                          _vm.useExistingBillingInformation,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            (_vm.useExistingBillingInformation = $$a.concat(
                                              [$$v]
                                            ))
                                        } else {
                                          $$i > -1 &&
                                            (_vm.useExistingBillingInformation = $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1)))
                                        }
                                      } else {
                                        _vm.useExistingBillingInformation = $$c
                                      }
                                    }
                                  }
                                }),
                                _c(
                                  "label",
                                  {
                                    staticClass: "custom-control-label",
                                    attrs: {
                                      for: "useExistingBillingInformation",
                                      "data-test-hook": "useCurrBillingInfoText"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("xUseCurrentBillingInfo")
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ]
                            )
                          ])
                        ]),
                        _vm.useExistingBillingInformation
                          ? _c("div", { staticClass: "row" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "col-12",
                                  attrs: {
                                    "data-test-hook": "ExistingBillingName"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.accountData.firstName) +
                                      " " +
                                      _vm._s(_vm.accountData.lastName) +
                                      " "
                                  )
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "col-12",
                                  attrs: {
                                    "data-test-hook": "ExistingBillingAddress"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.DisplayAddress(
                                          _vm.accountData.address
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ])
                          : _vm._e(),
                        !_vm.useExistingBillingInformation
                          ? _c("div", [
                              _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col-12" }, [
                                  _c(
                                    "fieldset",
                                    {
                                      staticClass: "form-group",
                                      attrs: { id: "firstName-group" }
                                    },
                                    [
                                      _c("div", [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.accountData.firstName,
                                              expression:
                                                "accountData.firstName"
                                            }
                                          ],
                                          staticClass: "form-control",
                                          attrs: {
                                            type: "text",
                                            id: "firstName",
                                            "data-test-hook": "firstName"
                                          },
                                          domProps: {
                                            value: _vm.accountData.firstName
                                          },
                                          on: {
                                            input: function($event) {
                                              if ($event.target.composing)
                                                return
                                              _vm.$set(
                                                _vm.accountData,
                                                "firstName",
                                                $event.target.value
                                              )
                                            }
                                          }
                                        })
                                      ])
                                    ]
                                  )
                                ])
                              ]),
                              _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col-12" }, [
                                  _c(
                                    "fieldset",
                                    {
                                      staticClass: "form-group",
                                      attrs: { id: "lastName-group" }
                                    },
                                    [
                                      _c("div", [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.accountData.lastName,
                                              expression: "accountData.lastName"
                                            }
                                          ],
                                          staticClass: "form-control",
                                          attrs: {
                                            id: "lastName",
                                            type: "text",
                                            "data-test-hook": "lastName"
                                          },
                                          domProps: {
                                            value: _vm.accountData.lastName
                                          },
                                          on: {
                                            input: function($event) {
                                              if ($event.target.composing)
                                                return
                                              _vm.$set(
                                                _vm.accountData,
                                                "lastName",
                                                $event.target.value
                                              )
                                            }
                                          }
                                        })
                                      ])
                                    ]
                                  )
                                ])
                              ]),
                              _c("div", { staticClass: "row" }, [
                                _c(
                                  "div",
                                  { staticClass: "col" },
                                  [
                                    _c("TypeAheadAddress", {
                                      ref: "typeAheadAddressSubscription",
                                      attrs: {
                                        currentAddress: _vm.accountData.address
                                      },
                                      on: {
                                        newAddress: _vm.setNewBillingAddress
                                      }
                                    })
                                  ],
                                  1
                                )
                              ])
                            ])
                          : _vm._e()
                      ])
                    : _vm._e()
                ]),
                _c("ModalFooter", [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-outline-warning",
                      attrs: { type: "button", "data-test-hook": "CancelBtn" },
                      on: {
                        click: function($event) {
                          _vm.closeModal("update-payment-method"),
                            _vm.closePaymentModal()
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("xCancel")) + " ")]
                  ),
                  _vm.useExistingBillingInformation
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-primary",
                          attrs: {
                            type: "button",
                            disabled: !_vm.canPay(),
                            "data-test-hook": "SaveChangesBtn"
                          },
                          on: {
                            click: function($event) {
                              return _vm.updatePaymentInformation()
                            }
                          }
                        },
                        [
                          _vm._v(" " + _vm._s(_vm.$t("xSave")) + " "),
                          _vm.paying
                            ? _c("span", { staticClass: "spinner-border ml-2" })
                            : _vm._e()
                        ]
                      )
                    : _c(
                        "button",
                        {
                          staticClass: "btn btn-warning",
                          attrs: {
                            type: "button",
                            disabled: _vm.savingAccountHolder,
                            "data-test-hook": "SaveChangesBtn"
                          },
                          on: {
                            click: function($event) {
                              _vm.saveAccountHolder(),
                                (_vm.savingAccountHolder = true)
                            }
                          }
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("xConfirmBillingInfo")) + " "
                          ),
                          _vm.savingAccountHolder
                            ? _c("span", { staticClass: "spinner-border ml-2" })
                            : _vm._e()
                        ]
                      )
                ])
              ],
              1
            ),
            _c("EditAccountInfoModal", {
              attrs: { shouldSetModal: _vm.shouldSetModal },
              on: {
                shouldSetModal: function($event) {
                  return _vm.setShouldSetModal()
                }
              }
            }),
            _c("DeleteAccountModal", {
              attrs: { deleteAccountModalInfo: _vm.deleteAccountModalInfo },
              on: {
                showConfirmDeleteAccountModal: _vm.showConfirmDeleteAccountModal
              }
            }),
            _c("ConfirmDeleteAccountModal", {
              attrs: {
                confirmDeleteAccountModalInfo: _vm.confirmDeleteAccountModalInfo
              }
            })
          ],
          1
        )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row ml-2 mr-2" }, [
      _c("div", { staticClass: "col" }, [
        _c("hr", { staticClass: "mx-0 px-0" })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }