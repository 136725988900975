<template>
  <Modal modalId="EditAccountInfoModal">
    <ModalHeader :title="$t('xEditAccountInformation')" icon="fas fa-user-circle">
      <button
        class="btn btn-icon-button btn-icon-button--gray"
        @click="resetModal(), closeModal('EditAccountInfoModal')"
        data-test-hook="x-button-edit-account-information-modal"
      >
        <i class="fas fa-times" />
      </button>
    </ModalHeader>
    <ModalBody>
      <div v-if="accountData">
        <div class="row">
          <div class="col">
            <div class="form-group" id="firstName-group">
              <input
                class="form-control"
                id="firstName"
                v-model="$v.accountData.firstName.$model"
                type="text"
                :placeholder="$t('xFirstName')"
                :state="validState($v.accountData.firstName)"
                aria-describedby="firstName-invalid"
              />
              <div class="invalid-feedback" id="firstName-invalid">
                {{ $t('xRequiredField') }}
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="form-group" id="lastName-group">
              <input
                class="form-control"
                id="lastName"
                v-model="$v.accountData.lastName.$model"
                type="text"
                :placeholder="$t('xLastName')"
                :state="validState($v.accountData.lastName)"
                aria-describedby="lastName-invalid"
              />
              <div class="invalid-feedback" id="lastName-invalid">
                {{ $t('xRequiredField') }}
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="form-group" id="phone-group">
              <input
                class="form-control"
                id="phone"
                :placeholder="$t('xPhoneNumber')"
                v-model="$v.phoneNumber.$model"
                type="text"
                maxlength="12"
                :state="validState($v.phoneNumber)"
                :class="status($v.phoneNumber)"
                aria-describedby="phoneNumber-invalid"
              />
              <div class="invalid-feedback" id="phoneNumber-invalid">
                {{ $t('xPleaseEnterAValidPhoneNumber') }}
              </div>
            </div>
          </div>
        </div>
        <div class="row" v-if="!editingAddress">
          <div class="col">
            <p>{{ accountProfile.address.line1 }}</p>
            <p v-if="accountProfile.address.line2">{{ accountProfile.address.line2 }}</p>
            <p>
              {{
                accountProfile.address.city +
                  ', ' +
                  accountProfile.address.region +
                  ' ' +
                  accountProfile.address.postalCode
              }}
            </p>
          </div>
          <div class="col-auto" v-if="!editingAddress">
            <button
              class="btn btn-icon-button"
              @click="editingAddress = !editingAddress"
              data-test-hook="pen-button-edit-account-modal"
            >
              <i class="fas fa-pencil" />
            </button>
          </div>
        </div>
        <div class="row" v-else>
          <div class="col">
            <TypeAheadAddress
              ref="typeAheadAddressEditAccountModal"
              :currentAddress="accountData.address"
              @newAddress="setCurrentAddress"
            />
          </div>
        </div>
      </div>
    </ModalBody>
    <ModalFooter>
      <button
        class="btn btn-outline-warning"
        @click="resetModal(), closeModal('EditAccountInfoModal')"
        data-test-hook="cancel-button-edit-account-information-modal"
      >
        {{ $t('xCancel') }}
      </button>
      <button
        class="btn btn-warning"
        @click="save()"
        :disabled="canSave()"
        data-test-hook="save-button-edit-account-information-modal"
      >
        {{ $t('xSave') }}
        <div class="spinner-border text-muted ml-2" label="Spinning" v-if="saving"></div>
      </button>
    </ModalFooter>
  </Modal>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import TypeAheadAddress from '@/components/TypeAheadAddress'
import { required, minLength, maxLength, numeric } from 'vuelidate/lib/validators'

export default {
  components: { TypeAheadAddress },
  props: ['shouldSetModal'],
  data() {
    return {
      accountData: null,
      editingAddress: false,
      saving: false,
      addressSaveDisabled: false,
      phoneNumber: null
    }
  },
  validations: {
    phoneNumber: {
      required,
      minLength: minLength(10),
      maxLength: maxLength(12),
      numeric,
      startsWithZero(phoneNumber) {
        const regex = /^[1-9]{1}[0-9]{9,11}$/
        return regex.test(phoneNumber)
      }
    },
    accountData: {
      firstName: {
        required
      },
      lastName: {
        required
      }
    }
  },
  computed: {
    ...mapState({
      accountProfile: state => state.account.accountProfile,
      accountInfo: state => state.account.accountInfo,
      apparatuses: state => state.apparatus.apparatuses,
      user: state => state.account.user,
      isImpersonating: state => state.auth.isImpersonating
    })
  },
  methods: {
    setModal() {
      this.accountData = _.cloneDeep(this.accountProfile)
      if (this.accountInfo.ownerPhoneNumber) {
        this.phoneNumber = _.cloneDeep(this.accountInfo.ownerPhoneNumber)
      }
    },
    resetModal() {
      this.accountData = null
      this.saving = false
      this.editingAddress = false
      this.phoneNumber = null
      this.$emit('shouldSetModal')
    },
    setCurrentAddress(newAddress) {
      this.accountData.address = newAddress
      if (this.$refs.typeAheadAddressEditAccountModal.$v.$anyError) {
        this.addressSaveDisabled = true
      } else {
        this.addressSaveDisabled = false
      }
    },
    canSave() {
      this.$v.$touch()

      if (!this.$v.$anyError && !this.saving) {
        return false
      } else return true
    },
    async save() {
      this.saving = true
      let updateResult = true
      if (
        this.accountData.firstName != this.accountProfile.firstName ||
        this.accountData.lastName != this.accountProfile.lastName ||
        this.accountData.address.line1 != this.accountProfile.address.line1 ||
        this.accountData.address.line2 != this.accountProfile.address.line2 ||
        this.accountData.address.city != this.accountProfile.address.city ||
        this.accountData.address.region != this.accountProfile.address.region ||
        this.accountData.address.country != this.accountProfile.address.country ||
        this.accountData.address.postalCode != this.accountProfile.address.postalCode
      ) {
        updateResult = await this.saveAccountHolder()
      }
      if (updateResult && this.phoneNumber != this.accountInfo.ownerPhoneNumber) {
        updateResult = await this.savePrimary()
      }
      this.saving = false
      if (updateResult) {
        this.closeModal('EditAccountInfoModal')
      } else {
        this.$store.dispatch('displayToast', {
          title: this.$t('xError'),
          message: this.$t('xUpdatingYourInformationFailedPleaseTryAgain'),
          variant: 'danger'
        })
      }
    },
    async saveAccountHolder() {
      let result = false
      await this.$store
        .dispatch('updateAccountHolder', {
          firstName: this.accountData.firstName,
          lastName: this.accountData.lastName,
          organizationAddress: {
            ...this.accountData.address,
            organizationId: this.accountData.organizationId
          }
        })
        .then(response => {
          if (!this.isImpersonating) this.$store.dispatch('getUser')
          else this.$store.dispatch('getImpersonatedUser')
          this.$store.dispatch('getAccountProfile').then(response => {})
          this.$store.dispatch('getAccountInfo')
          result = true
        })
        .catch(error => {})
        .finally(() => {})
      return result
    },
    async savePrimary() {
      let result = false
      if (this.apparatuses == null || this.apparatuses.length == 0) {
        await this.$store.dispatch('getApparatuses')
      }
      let currentPreferences
      let contactInfo
      if (this.apparatuses != null && this.apparatuses.length != 0) {
        let apparatusId = this.apparatuses[0].apparatusId
        await this.$store
          .dispatch('getApparatusPreferencesById', apparatusId)
          .then(response => {
            currentPreferences = response.data
          })
          .catch(error => {
            this.saving = false
            return result
          })
          .finally(() => {})

        if (!currentPreferences) {
          return result
        }
        contactInfo = _.cloneDeep(currentPreferences)
      } else {
        contactInfo = { phones: [], emails: [] }
      }
      const newPrimaryPhone = [this.phoneNumber]
      await this.$store
        .dispatch('updateContactInfo', {
          phones: newPrimaryPhone,
          emails: contactInfo.emails,
          changePrimary: true
        })
        .then(response => {
          currentPreferences = contactInfo
          if (!this.isImpersonating) this.$store.dispatch('getUser')
          else this.$store.dispatch('getImpersonatedUser')
          this.$store.dispatch('getAccountInfo')
          result = true
        })
        .catch(_ => false)
      return result
    }
  },
  watch: {
    shouldSetModal: function(newVal, oldVal) {
      if (newVal) {
        this.setModal()
      }
    }
  }
}
</script>

<style scoped>
.editBottom {
  bottom: 0;
}
</style>
