var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.subscriptionLoading
    ? _c(
        "div",
        { staticClass: "mt-2 ml-md-2" },
        [
          _c("div", { staticClass: "row no-gutters mb-2" }, [
            _c("div", { staticClass: "col" }, [
              _c(
                "h1",
                {
                  staticClass: "mb-0 pb-0",
                  attrs: { "data-test-hook": "subPageTitle" }
                },
                [
                  _c("i", {
                    staticClass: "far fa-badge-dollar colorOrange mr-1",
                    attrs: { "data-test-hook": "subPageTitleIcon" }
                  }),
                  _c(
                    "span",
                    { attrs: { "data-test-hook": "subPageTitleText" } },
                    [_vm._v(_vm._s(_vm.$t("xSubscription")))]
                  )
                ]
              )
            ])
          ]),
          _vm.subscription &&
          this.subscription.activeProduct &&
          _vm.subscription.activeProduct.length > 0 &&
          !_vm.DealerManagedProductId() &&
          _vm.subscription.activeProduct[0].productId != _vm.FreeProductId &&
          _vm.currentAppConfig &&
          _vm.currentAppConfig.featureFlags &&
          !_vm.currentAppConfig.featureFlags[_vm.billingMigrationFeatureFlag]
            ? _c("div", { staticClass: "card" }, [
                _c("div", { staticClass: "card-body" }, [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col" }, [
                      _c(
                        "h2",
                        { attrs: { "data-test-hook": "subPageHeadingText" } },
                        [_vm._v(_vm._s(_vm.$t("xSubscription")))]
                      )
                    ])
                  ]),
                  _c("div", { staticClass: "row mt-1" }, [
                    _c("div", { staticClass: "col" }, [
                      _c(
                        "p",
                        {
                          attrs: { "data-test-hook": "subPageSubHeadingText" }
                        },
                        [_vm._v("Current Subscription")]
                      ),
                      _c("p", { attrs: { "data-test-hook": "ProductName" } }, [
                        _c("strong", [
                          _vm._v(
                            _vm._s(
                              _vm.subscription.activeProduct[0].productName
                            )
                          )
                        ])
                      ])
                    ])
                  ]),
                  _c("div", { staticClass: "row mt-3" }, [
                    _c("div", { staticClass: "col" }, [
                      !_vm.pendingSubscriptionChanges()
                        ? _c(
                            "div",
                            { attrs: { "data-test-hook": "BillingDateTitle" } },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("xNextBillingDate")) + " "
                              ),
                              _c("div", { staticClass: "mt-1" }, [
                                _c(
                                  "p",
                                  {
                                    attrs: {
                                      "data-test-hook": "NextBillingDate"
                                    }
                                  },
                                  [
                                    _c("strong", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.subscription.activeProduct[0]
                                              .nextBillingDate
                                              ? _vm
                                                  .$moment(
                                                    _vm.subscription
                                                      .activeProduct[0]
                                                      .nextBillingDate
                                                  )
                                                  .format(_vm.$t("xShortDate"))
                                              : _vm
                                                  .$moment(
                                                    String(
                                                      _vm.calculateNextBilling
                                                    )
                                                  )
                                                  .format(_vm.$t("xShortDate"))
                                          ) +
                                          " "
                                      )
                                    ])
                                  ]
                                )
                              ])
                            ]
                          )
                        : !_vm.pendingSubscriptionCancellation() &&
                          !_vm.pendingSubscriptionChanges()
                        ? _c("div", [
                            _c(
                              "p",
                              { attrs: { "data-test-hook": "PlanEndDate" } },
                              [_vm._v(_vm._s(_vm.$t("xPlanEndDate")))]
                            ),
                            _c(
                              "p",
                              {
                                attrs: { "data-test-hook": "NextBillingDate" }
                              },
                              [
                                _c("strong", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.subscription.activeProduct[0]
                                          .nextBillingDate
                                          ? _vm
                                              .$moment(
                                                _vm.subscription
                                                  .activeProduct[0]
                                                  .nextBillingDate
                                              )
                                              .format(_vm.$t("xShortDate"))
                                          : ""
                                      ) +
                                      " "
                                  )
                                ])
                              ]
                            )
                          ])
                        : _vm._e(),
                      _vm.subscription.discount[0] != null &&
                      !_vm.pendingSubscriptionChanges() &&
                      !_vm.pendingSubscriptionCancellation()
                        ? _c(
                            "p",
                            {
                              staticClass: "mt-1",
                              staticStyle: { "font-size": "14px" },
                              attrs: { "data-test-hook": "subPageBodyText1" }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("xYouWillNotRecieveAnInvoice")
                                  ) +
                                  " "
                              )
                            ]
                          )
                        : _vm._e()
                    ])
                  ]),
                  !_vm.pendingSubscriptionChanges()
                    ? _c("div", { staticClass: "row mt-3" }, [
                        _c(
                          "div",
                          {
                            staticClass: "col",
                            attrs: { "data-test-hook": "PlanCostLabel" }
                          },
                          [
                            _c(
                              "p",
                              {
                                attrs: { "data-test-hook": "PlanCostLabelText" }
                              },
                              [_vm._v(_vm._s(_vm.$t("xSubscriptionCost")))]
                            ),
                            _c(
                              "div",
                              { attrs: { "data-test-hook": "PlanCost" } },
                              [
                                _vm.subscription.discount[0] != null &&
                                _vm
                                  .$moment()
                                  .isBefore(
                                    _vm
                                      .$moment(
                                        _vm.subscription.discount[0].endDate
                                      )
                                      .add(
                                        23,
                                        "hours",
                                        59,
                                        "minutes",
                                        59,
                                        "seconds"
                                      )
                                  ) &&
                                _vm
                                  .$moment()
                                  .isAfter(
                                    _vm.$moment(
                                      _vm.subscription.discount[0].startDate
                                    )
                                  )
                                  ? _c("div", { staticClass: "mt-1" }, [
                                      _c("strong", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("formatCurrency")(
                                                _vm.calculatePrice.toFixed(2)
                                              )
                                            ) +
                                            " "
                                        ),
                                        _vm.subscription.activeProduct[0]
                                          .durationInMonths == 12
                                          ? _c(
                                              "span",
                                              {
                                                attrs: {
                                                  "data-test-hook":
                                                    "PlanCostInterval"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(_vm.$t("xPerYear")) +
                                                    " (" +
                                                    _vm._s(
                                                      _vm.$t("xRegularly")
                                                    ) +
                                                    " " +
                                                    _vm._s(
                                                      _vm._f("formatCurrency")(
                                                        _vm.subscription.activeProduct[0].cost.toFixed(
                                                          2
                                                        )
                                                      )
                                                    ) +
                                                    " " +
                                                    _vm._s(_vm.$t("xPerYear")) +
                                                    ") "
                                                )
                                              ]
                                            )
                                          : _c(
                                              "span",
                                              {
                                                attrs: {
                                                  "data-test-hook":
                                                    "PlanCostInterval"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.$t("xPerMonth")) +
                                                    " (" +
                                                    _vm._s(
                                                      _vm.$t("xRegularly")
                                                    ) +
                                                    " " +
                                                    _vm._s(
                                                      _vm._f("formatCurrency")(
                                                        _vm.subscription.activeProduct[0].cost.toFixed(
                                                          2
                                                        )
                                                      )
                                                    ) +
                                                    " " +
                                                    _vm._s(
                                                      _vm.$t("xPerMonth")
                                                    ) +
                                                    ")"
                                                )
                                              ]
                                            )
                                      ]),
                                      _c(
                                        "p",
                                        {
                                          staticClass: "mt-1",
                                          staticStyle: { "font-size": "14px" },
                                          attrs: {
                                            "data-test-hook":
                                              "PlanCostIntervalBodyText"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.subscription.discount[0]
                                                  .percentage
                                              ) +
                                              "% " +
                                              _vm._s(
                                                _vm.$t("xDiscountApplied")
                                              ) +
                                              _vm._s(_vm.formattedDate) +
                                              " "
                                          )
                                        ]
                                      )
                                    ])
                                  : _c("div", [
                                      _c("strong", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("formatCurrency")(
                                                this.subscription.activeProduct[0].cost.toFixed(
                                                  2
                                                )
                                              )
                                            ) +
                                            " "
                                        ),
                                        _vm.subscription.activeProduct[0]
                                          .durationInMonths == 12
                                          ? _c(
                                              "span",
                                              {
                                                attrs: {
                                                  "data-test-hook":
                                                    "PlanCostInterval"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(_vm.$t("xPerYear")) +
                                                    " "
                                                )
                                              ]
                                            )
                                          : _c(
                                              "span",
                                              {
                                                attrs: {
                                                  "data-test-hook":
                                                    "PlanCostInterval"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.$t("xPerMonth"))
                                                )
                                              ]
                                            )
                                      ])
                                    ])
                              ]
                            )
                          ]
                        )
                      ])
                    : _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col" },
                          [
                            _c("PendingSubscriptionChangeCard", {
                              attrs: {
                                currentSubscription:
                                  _vm.subscription.activeProduct[0],
                                pendingSubscription:
                                  _vm.subscription.pendingProduct[0]
                              },
                              on: {
                                pendingSubscriptionReverted: function($event) {
                                  return _vm.getSubscription()
                                }
                              }
                            })
                          ],
                          1
                        )
                      ]),
                  !_vm.pendingSubscriptionChanges() &&
                  !_vm.pendingSubscriptionCancellation()
                    ? _c("div", { staticClass: "row mt-3" }, [
                        _c("div", { staticClass: "col" }, [
                          !_vm.DealerManagedProductId() &&
                          _vm.subscription.activeProduct[0].productId !=
                            _vm.FreeProductId &&
                          _vm.currentApparatusDetails.apparatusClassification ==
                            _vm.hsbGeneratorApparatus
                            ? _c(
                                "button",
                                {
                                  staticClass: "btn btn-warning",
                                  attrs: {
                                    type: "button",
                                    disabled:
                                      _vm.currentAppConfig &&
                                      _vm.currentAppConfig.featureFlags &&
                                      _vm.currentAppConfig.featureFlags[
                                        _vm.billingMigrationFeatureFlag
                                      ],
                                    "data-test-hook": "ManagePlanBtn"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.openModal(
                                        "manage-sub-paid-step-1"
                                      )
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("xManagePlan")) + " "
                                  )
                                ]
                              )
                            : _vm._e()
                        ])
                      ])
                    : _vm._e(),
                  _c("div", { staticClass: "row mt-3" }, [
                    _c("div", { staticClass: "col" }, [
                      _c("i", { attrs: { "data-test-hook": "AutoRenewMsg" } }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t("xThisSubscriptionWillAutoRenewShortVersion")
                          )
                        )
                      ])
                    ])
                  ]),
                  !_vm.pendingSubscriptionChanges() &&
                  !_vm.pendingSubscriptionCancellation()
                    ? _c("div", { staticClass: "row mt-3" }, [
                        _c("div", { staticClass: "col" }, [
                          !_vm.DealerManagedProductId() &&
                          _vm.subscription.activeProduct[0].productId !=
                            _vm.FreeProductId &&
                          _vm.currentApparatusDetails.apparatusClassification ==
                            _vm.fuelTankApparatusType
                            ? _c(
                                "button",
                                {
                                  staticClass: "btn btn-warning",
                                  attrs: {
                                    type: "button",
                                    disabled:
                                      _vm.currentAppConfig &&
                                      _vm.currentAppConfig.featureFlags &&
                                      _vm.currentAppConfig.featureFlags[
                                        _vm.billingMigrationFeatureFlag
                                      ],
                                    "data-test-hook": "subPageCancelSubBtn"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.openModal(
                                        "cancel-paid-sub-step-1"
                                      )
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("xCancelSubscription")) +
                                      " "
                                  )
                                ]
                              )
                            : _vm._e()
                        ])
                      ])
                    : _vm._e(),
                  _vm._m(0),
                  !_vm.pendingSubscriptionCancellation()
                    ? _c("div", [
                        _c("div", { staticClass: "row mt-1" }, [
                          _c(
                            "div",
                            { staticClass: "col d-flex align-items-center" },
                            [
                              _c(
                                "h2",
                                {
                                  staticClass: "m-0",
                                  attrs: {
                                    "data-test-hook": "PaymentMethodLabel"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("xPaymentMethod")) + " "
                                  )
                                ]
                              )
                            ]
                          ),
                          _vm.existingPaymentInfo && !_vm.billingInfoLoading
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "cols-auto d-flex align-items-center align-content-flex-end"
                                },
                                [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-icon-button",
                                      attrs: {
                                        type: "button",
                                        "data-test-hook": "EditPaymentMethodBtn"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.openModal(
                                            "update-payment-method"
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "fas fa-pencil",
                                        attrs: {
                                          "data-test-hook":
                                            "EditPaymentMethodBtnIcon"
                                        }
                                      })
                                    ]
                                  )
                                ]
                              )
                            : _vm._e()
                        ]),
                        _vm.billingInfoLoading
                          ? _c("div", { staticClass: "row" }, [_vm._m(1)])
                          : _vm.existingPaymentInfo
                          ? _c("div", { staticClass: "row mt-3" }, [
                              _c("div", { staticClass: "col-12" }, [
                                _c(
                                  "p",
                                  {
                                    attrs: {
                                      "data-test-hook": "ExistingCardInfo"
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "far fa-credit-card mr-1",
                                      attrs: {
                                        "data-test-hook": "ExistingCardInfoIcon"
                                      }
                                    }),
                                    _c(
                                      "span",
                                      {
                                        attrs: {
                                          "data-test-hook":
                                            "ExistingCardInfoType"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.existingPaymentInfo.cardType
                                          )
                                        )
                                      ]
                                    ),
                                    _vm._v(" - "),
                                    _c(
                                      "span",
                                      {
                                        attrs: {
                                          "data-test-hook":
                                            "ExistingCardInfoSuffix"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.existingPaymentInfo.cardSuffix
                                          )
                                        )
                                      ]
                                    )
                                  ]
                                ),
                                _c(
                                  "p",
                                  {
                                    attrs: { "data-test-hook": "CardExpDate" }
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        attrs: {
                                          "data-test-hook":
                                            "ExistingCardInfoExpireHeading"
                                        }
                                      },
                                      [_vm._v(_vm._s(_vm.$t("xExpires")))]
                                    ),
                                    _c(
                                      "span",
                                      {
                                        attrs: {
                                          "data-test-hook":
                                            "ExistingCardInfoExpireDate"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.existingPaymentInfo
                                                .expirationMonth +
                                                "/" +
                                                _vm.existingPaymentInfo
                                                  .expirationYear
                                            ) +
                                            " "
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ])
                            ])
                          : _c("div", { staticClass: "row mt-1" }, [
                              _c("div", { staticClass: "col" }, [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-warning",
                                    attrs: {
                                      type: "button",
                                      "data-test-hook": "AddPaymentMethodBtn"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.openModal(
                                          "update-payment-method"
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s("Add Payment Method") + " "
                                    )
                                  ]
                                )
                              ])
                            ])
                      ])
                    : _vm._e()
                ])
              ])
            : _vm.subscription &&
              _vm.subscription.activeProduct &&
              _vm.subscription.activeProduct.length > 0 &&
              _vm.DealerManagedProductId() &&
              _vm.currentAppConfig &&
              _vm.currentAppConfig.featureFlags &&
              !_vm.currentAppConfig.featureFlags[
                _vm.billingMigrationFeatureFlag
              ]
            ? _c("div", { staticClass: "card" }, [
                _c("div", { staticClass: "card-body" }, [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col" }, [
                      _c(
                        "p",
                        {
                          attrs: { "data-test-hook": "subPageSubHeadingText" }
                        },
                        [_vm._v("Current Subscription")]
                      ),
                      _c("p", [
                        _c(
                          "strong",
                          {
                            staticClass: "colorBlack",
                            attrs: { "data-test-hook": "ProductName" }
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.subscription.activeProduct[0].productName
                              )
                            )
                          ]
                        )
                      ])
                    ])
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      {
                        staticClass: "col",
                        attrs: { "data-test-hook": "DealerManagedMsg" }
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.$t("xDealerManagedSubMessage")) + " "
                        )
                      ]
                    )
                  ])
                ])
              ])
            : _vm.subscription &&
              _vm.subscription.activeProduct &&
              _vm.subscription.activeProduct.length > 0 &&
              _vm.subscription.activeProduct[0].productId ==
                _vm.FreeProductId &&
              _vm.currentAppConfig &&
              _vm.currentAppConfig.featureFlags &&
              !_vm.currentAppConfig.featureFlags[
                _vm.billingMigrationFeatureFlag
              ]
            ? _c("div", { staticClass: "card" }, [
                _c("div", { staticClass: "card-body" }, [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col" }, [
                      _c(
                        "p",
                        {
                          attrs: { "data-test-hook": "subPageSubHeadingText" }
                        },
                        [_vm._v("Current Subscription")]
                      ),
                      _c("p", { attrs: { "data-test-hook": "ProductName" } }, [
                        _c("strong", [
                          _vm._v(
                            _vm._s(
                              _vm.subscription.activeProduct[0].productName
                            )
                          )
                        ])
                      ])
                    ])
                  ]),
                  _vm.canHavePaidSubscription(_vm.accountData)
                    ? _c("div", { staticClass: "row mt-2" }, [
                        _c(
                          "div",
                          { staticClass: "col" },
                          [
                            _c(
                              "b-button",
                              {
                                attrs: {
                                  variant: "primary",
                                  "data-test-hook": "UpgradeBtn"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.openModal(
                                      "upgrade-free-sub-step-1"
                                    )
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("xManagePlan")) + " "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ])
                    : _vm._e()
                ])
              ])
            : _c("div", { staticClass: "card-shadow card" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col" }, [
                    _c("h4", { staticClass: "colorBlack" }, [
                      _vm._v(_vm._s(_vm.$t("xSubscriptionInfoUnavailable")))
                    ])
                  ])
                ])
              ]),
          _c(
            "Modal",
            {
              attrs: { modalId: "update-payment-method", size: "modal-md" },
              on: {
                hidden: function($event) {
                  return _vm.closePaymentModal()
                }
              }
            },
            [
              _c(
                "ModalHeader",
                {
                  attrs: {
                    title: _vm.$t("xUpdatePaymentMethod"),
                    icon: "fal fa-credit-card colorOrange mr-1"
                  }
                },
                [
                  _c("div", { staticClass: "cols-auto" }, [
                    _c(
                      "button",
                      {
                        staticClass:
                          "btn btn-icon-button btn-icon-button--gray",
                        attrs: {
                          type: "button",
                          "data-test-hook": "CloseModalBtn"
                        },
                        on: {
                          click: function($event) {
                            return _vm.closePaymentModal()
                          }
                        }
                      },
                      [_c("i", { staticClass: "fas fa-times" })]
                    )
                  ])
                ]
              ),
              _c("ModalBody", [
                _vm.showValidationBanner
                  ? _c(
                      "div",
                      {
                        staticClass: "alert alert-danger",
                        attrs: {
                          role: "alert",
                          "data-test-hook": "ValidationAlert"
                        }
                      },
                      [
                        _c("p", [
                          _vm._v(_vm._s(_vm.$t("xExperiencedTheseErrors")))
                        ]),
                        _vm._l(_vm.errorMessages, function(message, index) {
                          return _c("ul", { key: index }, [
                            _c(
                              "li",
                              {
                                attrs: {
                                  id: index,
                                  "data-test-hook": "ValidationMsg"
                                }
                              },
                              [_vm._v(" " + _vm._s(message) + " ")]
                            )
                          ])
                        })
                      ],
                      2
                    )
                  : _vm._e(),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-12" }, [
                    _c("h3", [
                      _c(
                        "strong",
                        { attrs: { "data-test-hook": "CcInfoHeader" } },
                        [_vm._v(_vm._s(_vm.$t("xCreditCardInfo")))]
                      ),
                      _c("span", { staticClass: "body-text-color" }, [
                        _vm._v("*")
                      ])
                    ])
                  ])
                ]),
                _c("div", { staticClass: "row mb-3" }, [
                  _c("div", { staticClass: "col" }, [
                    _c(
                      "i",
                      {
                        staticClass: "asterisk-text",
                        attrs: { "data-test-hook": "UpdatePaymentWarningMsg" }
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.$t("xUpdatingPaymentWarning")) + " "
                        )
                      ]
                    )
                  ])
                ]),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-12" }, [
                    _c(
                      "div",
                      {
                        staticClass: "form-group",
                        attrs: { id: "credit-card-number" }
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.$v.creditCardNumber.$model,
                              expression: "$v.creditCardNumber.$model"
                            },
                            {
                              name: "mask",
                              rawName: "v-mask",
                              value: "################",
                              expression: "'################'"
                            }
                          ],
                          staticClass: "form-control",
                          class: _vm.status(_vm.$v.creditCardNumber),
                          attrs: {
                            id: "creditCardNumber",
                            maxlength: "16",
                            type: "text",
                            placeholder: _vm.$t("xCreditCardNumber"),
                            "aria-describedby": "card-number-invalid",
                            "data-test-hook": "CcNumber"
                          },
                          domProps: { value: _vm.$v.creditCardNumber.$model },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.$v.creditCardNumber,
                                "$model",
                                $event.target.value
                              )
                            }
                          }
                        }),
                        _c(
                          "div",
                          {
                            staticClass: "invalid-feedback",
                            attrs: {
                              id: "card-number-invalid",
                              "data-test-hook": "invalidCcNo"
                            }
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.$t("xCCFieldValidation")) + " "
                            )
                          ]
                        )
                      ]
                    )
                  ])
                ]),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col col-lg-6 col-md-6 col-sm-12" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "form-group",
                          attrs: { id: "expiry-date-month" }
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.$v.expirationMonth.$model,
                                expression: "$v.expirationMonth.$model"
                              },
                              {
                                name: "mask",
                                rawName: "v-mask",
                                value: "##",
                                expression: "'##'"
                              }
                            ],
                            staticClass: "form-control",
                            class: _vm.status(_vm.$v.expirationMonth),
                            attrs: {
                              id: "xNm",
                              maxlength: "2",
                              type: "text",
                              placeholder: _vm.$t("xMm"),
                              "aria-describedby": "expiry-month-invalid",
                              "data-test-hook": "CcExpireMonth"
                            },
                            domProps: { value: _vm.$v.expirationMonth.$model },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.$v.expirationMonth,
                                  "$model",
                                  $event.target.value
                                )
                              }
                            }
                          }),
                          _c(
                            "div",
                            {
                              staticClass: "invalid-feedback",
                              attrs: {
                                id: "expiry-month-invalid",
                                "data-test-hook": "invalidCcMth"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("xExpirationMonthValidation")) +
                                  " "
                              )
                            ]
                          )
                        ]
                      )
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "col col-lg-6 col-md-t6 col-sm-12" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "form-group",
                          attrs: { id: "expiry-date-year" }
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.$v.expirationYear.$model,
                                expression: "$v.expirationYear.$model"
                              },
                              {
                                name: "mask",
                                rawName: "v-mask",
                                value: "##",
                                expression: "'##'"
                              }
                            ],
                            staticClass: "form-control",
                            class: _vm.status(_vm.$v.expirationYear),
                            attrs: {
                              type: "text",
                              placeholder: _vm.$t("xYy"),
                              maxlength: "2",
                              "aria-describedby": "expiry-year-invalid",
                              "data-test-hook": "CcExpireYear"
                            },
                            domProps: { value: _vm.$v.expirationYear.$model },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.$v.expirationYear,
                                  "$model",
                                  $event.target.value
                                )
                              }
                            }
                          }),
                          _c(
                            "div",
                            {
                              staticClass: "invalid-feedback",
                              attrs: {
                                id: "expiry-year-invalid",
                                "data-test-hook": "invalidCcYear"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("xExpirationYearValidation")) +
                                  " "
                              )
                            ]
                          )
                        ]
                      )
                    ]
                  ),
                  _c("div", { staticClass: "col-12" }, [
                    _c(
                      "div",
                      { staticClass: "input-group", attrs: { id: "cvv" } },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.$v.cvv.$model,
                              expression: "$v.cvv.$model"
                            },
                            {
                              name: "mask",
                              rawName: "v-mask",
                              value: "####",
                              expression: "'####'"
                            }
                          ],
                          staticClass: "form-control",
                          class: _vm.status(_vm.$v.cvv),
                          attrs: {
                            type: "text",
                            maxlength: "4",
                            placeholder: _vm.$t("xCvv"),
                            "aria-describedby": "cvv-invalid",
                            "data-test-hook": "CcCvv"
                          },
                          domProps: { value: _vm.$v.cvv.$model },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.$v.cvv,
                                "$model",
                                $event.target.value
                              )
                            }
                          }
                        }),
                        _c("div", { staticClass: "input-group-append" }, [
                          _c("div", { staticClass: "input-group-text" }, [
                            _c("img", {
                              attrs: {
                                height: 20,
                                src: require("@/assets/images/3-digit-code.svg"),
                                "data-test-hook": "ccCvvImage"
                              }
                            })
                          ])
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "invalid-feedback",
                            attrs: {
                              id: "cvv-invalid",
                              "data-test-hook": "invalidCcCvv"
                            }
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("xCVVValidation")) + " ")]
                        )
                      ]
                    )
                  ])
                ]),
                _vm.accountData
                  ? _c("div", { staticClass: "mt-4" }, [
                      _c("div", { staticClass: "row mb-2" }, [
                        _c("div", { staticClass: "col" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "font-weight-bold custom-control custom-checkbox"
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.useExistingBillingInformation,
                                    expression: "useExistingBillingInformation"
                                  }
                                ],
                                staticClass: "custom-control-input",
                                attrs: {
                                  id: "useExistingBillingInformation",
                                  type: "checkbox",
                                  disabled: _vm.savingAccountHolder,
                                  "data-test-hook":
                                    "UseCurrentBillingAddressChkBox"
                                },
                                domProps: {
                                  checked: Array.isArray(
                                    _vm.useExistingBillingInformation
                                  )
                                    ? _vm._i(
                                        _vm.useExistingBillingInformation,
                                        null
                                      ) > -1
                                    : _vm.useExistingBillingInformation
                                },
                                on: {
                                  change: function($event) {
                                    var $$a = _vm.useExistingBillingInformation,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          (_vm.useExistingBillingInformation = $$a.concat(
                                            [$$v]
                                          ))
                                      } else {
                                        $$i > -1 &&
                                          (_vm.useExistingBillingInformation = $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1)))
                                      }
                                    } else {
                                      _vm.useExistingBillingInformation = $$c
                                    }
                                  }
                                }
                              }),
                              _c(
                                "label",
                                {
                                  staticClass: "custom-control-label",
                                  attrs: {
                                    for: "useExistingBillingInformation",
                                    "data-test-hook": "useCurrBillingInfoText"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("xUseCurrentBillingInfo")) +
                                      " "
                                  )
                                ]
                              )
                            ]
                          )
                        ])
                      ]),
                      _vm.useExistingBillingInformation
                        ? _c("div", { staticClass: "row" }, [
                            _c(
                              "div",
                              {
                                staticClass: "col-12",
                                attrs: {
                                  "data-test-hook": "ExistingBillingName"
                                }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.accountData.firstName) +
                                    " " +
                                    _vm._s(_vm.accountData.lastName) +
                                    " "
                                )
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "col-12",
                                attrs: {
                                  "data-test-hook": "ExistingBillingAddress"
                                }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.DisplayAddress(
                                        _vm.accountData.address
                                      )
                                    ) +
                                    " "
                                )
                              ]
                            )
                          ])
                        : _vm._e(),
                      !_vm.useExistingBillingInformation
                        ? _c("div", [
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-12" }, [
                                _c(
                                  "fieldset",
                                  {
                                    staticClass: "form-group",
                                    attrs: { id: "firstName-group" }
                                  },
                                  [
                                    _c("div", [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.accountData.firstName,
                                            expression: "accountData.firstName"
                                          }
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          type: "text",
                                          id: "firstName",
                                          "data-test-hook": "firstName"
                                        },
                                        domProps: {
                                          value: _vm.accountData.firstName
                                        },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              _vm.accountData,
                                              "firstName",
                                              $event.target.value
                                            )
                                          }
                                        }
                                      })
                                    ])
                                  ]
                                )
                              ])
                            ]),
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-12" }, [
                                _c(
                                  "fieldset",
                                  {
                                    staticClass: "form-group",
                                    attrs: { id: "lastName-group" }
                                  },
                                  [
                                    _c("div", [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.accountData.lastName,
                                            expression: "accountData.lastName"
                                          }
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          id: "lastName",
                                          type: "text",
                                          "data-test-hook": "lastName"
                                        },
                                        domProps: {
                                          value: _vm.accountData.lastName
                                        },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              _vm.accountData,
                                              "lastName",
                                              $event.target.value
                                            )
                                          }
                                        }
                                      })
                                    ])
                                  ]
                                )
                              ])
                            ]),
                            _c("div", { staticClass: "row" }, [
                              _c(
                                "div",
                                { staticClass: "col" },
                                [
                                  _c("TypeAheadAddress", {
                                    ref: "typeAheadAddressSubscription",
                                    attrs: {
                                      currentAddress: _vm.accountData.address
                                    },
                                    on: { newAddress: _vm.setNewBillingAddress }
                                  })
                                ],
                                1
                              )
                            ])
                          ])
                        : _vm._e()
                    ])
                  : _vm._e()
              ]),
              _c("ModalFooter", [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-outline-warning",
                    attrs: { type: "button", "data-test-hook": "CancelBtn" },
                    on: {
                      click: function($event) {
                        _vm.closeModal("update-payment-method"),
                          _vm.closePaymentModal()
                      }
                    }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("xCancel")) + " ")]
                ),
                _vm.useExistingBillingInformation
                  ? _c(
                      "button",
                      {
                        staticClass: "btn btn-primary",
                        attrs: {
                          type: "button",
                          disabled: !_vm.canPay(),
                          "data-test-hook": "SaveChangesBtn"
                        },
                        on: {
                          click: function($event) {
                            return _vm.payForProduct()
                          }
                        }
                      },
                      [
                        _vm._v(" " + _vm._s(_vm.$t("xSave")) + " "),
                        _vm.paying
                          ? _c("span", { staticClass: "spinner-borderr ml-2" })
                          : _vm._e()
                      ]
                    )
                  : _c(
                      "button",
                      {
                        staticClass: "btn btn-warning",
                        attrs: {
                          type: "button",
                          disabled: _vm.savingAccountHolder,
                          "data-test-hook": "SaveChangesBtn"
                        },
                        on: {
                          click: function($event) {
                            _vm.saveAccountHolder(),
                              (_vm.savingAccountHolder = true)
                          }
                        }
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.$t("xConfirmBillingInfo")) + " "
                        ),
                        _vm.savingAccountHolder
                          ? _c("span", { staticClass: "spinner-border ml-2" })
                          : _vm._e()
                      ]
                    )
              ])
            ],
            1
          ),
          _vm.subscription &&
          _vm.subscription.activeProduct &&
          _vm.existingPaymentInfo &&
          !_vm.DealerManagedProductId() &&
          _vm.subscription.activeProduct[0].productId != _vm.FreeProductId
            ? _c("ManageSubscriptionModals", {
                attrs: {
                  currentSubscription: _vm.subscription.activeProduct,
                  paymentInfo: _vm.existingPaymentInfo,
                  coupon: _vm.subscription.discount
                },
                on: {
                  success: function($event) {
                    return _vm.getSubscription()
                  },
                  cancelSubscription: function($event) {
                    return _vm.openModal("cancel-paid-sub-step-1")
                  }
                }
              })
            : _vm._e(),
          _vm.subscription &&
          _vm.subscription.activeProduct &&
          _vm.subscription.activeProduct[0].productId == _vm.FreeProductId
            ? _c("UpgradeFreeSubscriptionModals", {
                attrs: {
                  currentSubscription: _vm.subscription.activeProduct,
                  paymentInfo: _vm.existingPaymentInfo
                },
                on: {
                  success: function($event) {
                    return _vm.upgradeFreeSubscription()
                  },
                  cancelSubscription: function($event) {
                    return _vm.openModal("cancel-paid-sub-step-1")
                  }
                }
              })
            : _vm._e(),
          _c("CancelSubscriptionModals", {
            attrs: { currentSubscription: _vm.subscription.activeProduct },
            on: {
              success: function($event) {
                return _vm.getSubscription()
              }
            }
          })
        ],
        1
      )
    : _c("div", { staticClass: "d-flex justify-content-center mt-5 pt-4" }, [
        _c("span", {
          staticClass: "spinner-border text-warning",
          staticStyle: { width: "5rem", height: "5rem" },
          attrs: { label: "Spinning" }
        })
      ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row mt-3" }, [
      _c("div", { staticClass: "col" }, [
        _c("hr", { staticClass: "mx-0 px-0" })
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col" }, [
      _c("span", {
        staticClass: "spinner-border text-warning",
        staticStyle: { width: "5rem", height: "5rem" },
        attrs: { label: "Spinning", "data-test-hook": "LoadingSpinner" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }