var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.currentAppConfig &&
      _vm.currentAppConfig.featureFlags &&
      _vm.currentAppConfig.featureFlags[_vm.billingMigrationFeatureFlag]
        ? _c("b-alert", { attrs: { show: "", variant: "warning" } }, [
            _vm._v(" " + _vm._s(_vm.$t("xBillingMaintenanceMessage")) + " ")
          ])
        : _vm._e(),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            [
              _c(
                "b-input-group",
                { staticClass: "mt-2" },
                [
                  _c(
                    "b-input-group-prepend",
                    [
                      _c(
                        "b-dropdown",
                        {
                          staticClass: "input-height search-in",
                          attrs: {
                            variant: "primary",
                            "data-test-hook": "search-criteria-dropdown-admin"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "button-content",
                              fn: function() {
                                return [
                                  _c("span", { staticClass: "dropdown-text" }, [
                                    _vm._v(
                                      " " + _vm._s(_vm.searchCriteriaText) + " "
                                    )
                                  ])
                                ]
                              },
                              proxy: true
                            }
                          ])
                        },
                        [
                          _c(
                            "b-dropdown-item",
                            [
                              _c(
                                "b-button",
                                {
                                  staticClass: "text-left input-height",
                                  attrs: { variant: "link", block: "" },
                                  on: {
                                    click: function($event) {
                                      return _vm.SetSearchCriteria(
                                        "Serial Number"
                                      )
                                    }
                                  }
                                },
                                [_vm._v(" Serial Number ")]
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-dropdown-item",
                            [
                              _c(
                                "b-button",
                                {
                                  staticClass: "text-left input-height",
                                  attrs: { variant: "link", block: "" },
                                  on: {
                                    click: function($event) {
                                      return _vm.SetSearchCriteria("Email")
                                    }
                                  }
                                },
                                [_vm._v(" Email ")]
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-dropdown-item",
                            [
                              _c(
                                "b-button",
                                {
                                  staticClass: "text-left input-height",
                                  attrs: { variant: "link", block: "" },
                                  on: {
                                    click: function($event) {
                                      return _vm.SetSearchCriteria("LastName")
                                    }
                                  }
                                },
                                [_vm._v(" Last Name ")]
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-dropdown-item",
                            [
                              _c(
                                "b-button",
                                {
                                  staticClass: "text-left input-height",
                                  attrs: { variant: "link", block: "" },
                                  on: {
                                    click: function($event) {
                                      return _vm.SetSearchCriteria("Device ID")
                                    }
                                  }
                                },
                                [_vm._v(" Device ID ")]
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-dropdown-item",
                            [
                              _c(
                                "b-button",
                                {
                                  staticClass: "text-left input-height",
                                  attrs: { variant: "link", block: "" },
                                  on: {
                                    click: function($event) {
                                      return _vm.SetSearchCriteria(
                                        "Mac Address"
                                      )
                                    }
                                  }
                                },
                                [_vm._v(" Mac Address ")]
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-dropdown-item",
                            [
                              _c(
                                "b-button",
                                {
                                  staticClass: "text-left input-height",
                                  attrs: { variant: "link", block: "" },
                                  on: {
                                    click: function($event) {
                                      return _vm.SetSearchCriteria(
                                        "Lte Tether Serial"
                                      )
                                    }
                                  }
                                },
                                [_vm._v(" Lte Tether Serial ")]
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-dropdown-item",
                            [
                              _c(
                                "b-button",
                                {
                                  staticClass: "text-left input-height",
                                  attrs: { variant: "link", block: "" },
                                  on: {
                                    click: function($event) {
                                      return _vm.SetSearchCriteria(
                                        "Ethernet Tether Serial"
                                      )
                                    }
                                  }
                                },
                                [_vm._v(" Ethernet Tether Serial ")]
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-dropdown-item",
                            [
                              _c(
                                "b-button",
                                {
                                  staticClass: "text-left input-height",
                                  attrs: { variant: "link", block: "" },
                                  on: {
                                    click: function($event) {
                                      return _vm.SetSearchCriteria("MDN")
                                    }
                                  }
                                },
                                [_vm._v(" MDN ")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("b-form-input", {
                    staticStyle: { height: "50px" },
                    attrs: { maxlength: "255", size: "lg" },
                    on: {
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.Search.apply(null, arguments)
                      }
                    },
                    model: {
                      value: _vm.searchCriteria,
                      callback: function($$v) {
                        _vm.searchCriteria = $$v
                      },
                      expression: "searchCriteria"
                    }
                  }),
                  _c(
                    "b-input-group-append",
                    { staticClass: "my-0 py-0" },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "input-height",
                          attrs: {
                            variant: "primary",
                            "data-test-hook": "go-button-admin"
                          },
                          on: { click: _vm.Search }
                        },
                        [_c("i", { staticClass: "fas fa-search" })]
                      ),
                      _c(
                        "b-button",
                        {
                          staticClass: "input-height",
                          attrs: {
                            variant: "light",
                            "data-test-hook": "clear-button-admin"
                          },
                          on: { click: _vm.ClearSearch }
                        },
                        [_c("i", { staticClass: "fas fa-times" })]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.searchPending
        ? _c(
            "b-row",
            [
              _c(
                "b-col",
                {
                  staticClass: "d-flex justify-content-center my-3 mt-4",
                  attrs: { cols: "12" }
                },
                [
                  _c("b-spinner", {
                    staticStyle: { width: "5rem", height: "5rem" },
                    attrs: { variant: "warning", label: "Spinning" }
                  })
                ],
                1
              )
            ],
            1
          )
        : !_vm.searchPending &&
          _vm.csSearchResults &&
          _vm.csSearchResults.length > 0 &&
          _vm.searchCriteriaText != "Email" &&
          _vm.searchCriteriaText != "LastName"
        ? _c(
            "div",
            { staticClass: "search-results" },
            _vm._l(_vm.csSearchResults, function(result, index) {
              return _c(
                "div",
                {
                  key: result.apparatusId,
                  staticClass: "result",
                  attrs: { id: "result" + index }
                },
                [
                  result.apparatusId
                    ? _c("SearchResult", {
                        attrs: { value: result },
                        on: {
                          transferComplete: _vm.TransferCompleteSearch,
                          successfulRemoval: _vm.successfulRemoval
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            }),
            0
          )
        : !_vm.searchPending &&
          _vm.csSearchResults &&
          _vm.csSearchResults.length > 0 &&
          (_vm.searchCriteriaText == "Email" ||
            _vm.searchCriteriaText == "LastName")
        ? _c(
            "div",
            { staticClass: "search-results" },
            _vm._l(_vm.csSearchResults, function(result, index) {
              return _c(
                "div",
                {
                  key: result.organizationId,
                  staticClass: "result",
                  attrs: { id: "result" + index }
                },
                [
                  _c("EmailSearchResult", {
                    attrs: { value: result },
                    on: { transferComplete: _vm.TransferCompleteSearch }
                  })
                ],
                1
              )
            }),
            0
          )
        : _vm.csSearchResults && _vm.csSearchResults.length == 0
        ? _c(
            "b-row",
            [
              _c("b-col", { attrs: { cols: "auto" } }, [
                _c("h4", [_vm._v("This search returned no results")])
              ])
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }