var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.bannerMessage
    ? _c(
        "div",
        [
          _c(
            "b-modal",
            {
              attrs: {
                id: "banner-message-modal",
                centered: "",
                "hide-footer": ""
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "modal-header",
                    fn: function() {
                      return [
                        _c(
                          "b-row",
                          {
                            staticClass: "w-100",
                            attrs: { "align-v": "center", "no-gutters": "" }
                          },
                          [
                            _c("b-col", [
                              _c("h2", { staticClass: "modal-title" }, [
                                _vm.bannerMessage.Category == 0
                                  ? _c("i", {
                                      staticClass:
                                        "fas fa-exclamation-triangle colorOrange mr-1"
                                    })
                                  : _vm._e(),
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.bannerMessage.MessagePageTitle) +
                                    " "
                                )
                              ])
                            ]),
                            _c(
                              "b-col",
                              {
                                staticClass: "float-right",
                                attrs: { cols: "auto" }
                              },
                              [
                                _c(
                                  "b-button",
                                  {
                                    staticClass: "btn-icon-button--gray",
                                    attrs: { variant: "icon-button" },
                                    on: {
                                      click: function($event) {
                                        return _vm.$bvModal.hide(
                                          "banner-message-modal"
                                        )
                                      }
                                    }
                                  },
                                  [_c("i", { staticClass: "fas fa-times" })]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    },
                    proxy: true
                  }
                ],
                null,
                false,
                3567910701
              )
            },
            [
              _c(
                "b-row",
                [
                  _c("b-col", [
                    _vm._v(" " + _vm._s(_vm.bannerMessage.MessageBody1) + " ")
                  ])
                ],
                1
              ),
              _c(
                "b-row",
                { staticClass: "mt-3" },
                [
                  _c("b-col", [
                    _c("i", [_vm._v(_vm._s(_vm.bannerMessage.MessageBody2))])
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }