<template>
  <!--Bootstrap Classes-->
  <div class="modal show fade" :id="'fixDunningModal' + apparatusId" style="bottom: 25%;">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <header class="modal-header">
          <div class="row w-100 no-gutters align-items-center">
            <div class="col">
              <h2 data-test-hook="ModalTitle" class="modal-title">
                <i class="fal fa-credit-card mr-1"> </i>
                {{ $t('xUpdatePaymentMethod') }}
              </h2>
            </div>
            <div class="col-auto">
              <button
                data-test-hook="CloseModalBtn"
                @click="closeModal()"
                type="button"
                class="btn btn-icon-button--gray btn-icon-button"
              >
                <i class="fas fa-times"></i>
              </button>
            </div>
          </div>
        </header>
        <div
          v-if="subscriptionLoading"
          class="spinner-border text-warning"
          style="width: 5rem; height: 5rem;"
          role="status"
        >
          <span class="sr-only">Loading...</span>
        </div>
        <div v-else class="modal-body">
          <div>
            <div class="row">
              <div class="col-12">
                <h3>
                  <strong data-test-hook="CcInfoTitle">{{ $t('xCreditCardInfo') }}</strong>
                  <span class="body-text-color">*</span>
                </h3>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col">
                <i class="asterisk-text">{{ $t('xProblemProcessingPayment') }}</i>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <fieldset id="credit-card-number" class="form-group">
                  <div>
                    <input
                      v-model="$v.creditCardNumber.$model"
                      type="text"
                      maxlength="16"
                      v-mask="'################'"
                      :placeholder="$t('xCreditCardNumber')"
                      :state="validState($v.creditCardNumber)"
                      aria-describedby="card-number-invalid"
                      data-test-hook="CcNumber"
                      class="form-control"
                    />
                    <div id="card-number-invalid" class="invalid-feedback">{{ $t('xProblemProcessingPayment') }}</div>
                  </div>
                </fieldset>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 col-md-t6 col-lg-6">
                <fieldset id="expiry-date-month" class="form-group">
                  <input
                    v-model="$v.expirationMonth.$model"
                    type="text"
                    :placeholder="$t('xMm')"
                    maxlength="2"
                    v-mask="'##'"
                    :state="validState($v.expirationMonth)"
                    aria-describedby="expiry-month-invalid"
                    data-test-hook="CcExpireMonth"
                    class="form-control"
                  />
                  <div id="expiry-month-invalid" class="invalid-feedback">{{ $t('xExpirationMonthValidation') }}</div>
                </fieldset>
              </div>
              <div class="col-sm-12 col-md-t6 col-lg-6">
                <fieldset id="expiry-date-year" class="form-group">
                  <input
                    v-model="$v.expirationYear.$model"
                    type="text"
                    :placeholder="$t('xYy')"
                    maxlength="2"
                    v-mask="'##'"
                    :state="validState($v.expirationYear)"
                    aria-describedby="expiry-year-invalid"
                    data-test-hook="CcExpireYear"
                    class="form-control"
                  />
                  <div id="expiry-year-invalid" class="invalid-feedback">{{ $t('xExpirationYearValidation') }}</div>
                </fieldset>
              </div>
              <div class="col-12">
                <div id="cvv" class="input-group" role="group">
                  <input
                    v-model="$v.cvv.$model"
                    type="text"
                    v-mask="'####'"
                    maxlength="4"
                    :placeholder="$t('xCvv')"
                    :state="validState($v.cvv)"
                    aria-describedby="cvv-invalid"
                    data-test-hook="CcCvv"
                    class="form-control"
                  />
                  <div class="input-group-append">
                    <div class="input-group-text">
                      <img :src="require('@/assets/images/3-digit-code.svg')" height="20" />
                    </div>
                  </div>
                  <div id="cvv-invalid" class="invalid-feedback">{{ $t('xExpirationMonthValidation') }}</div>
                </div>
              </div>
            </div>
            <div class="mt-4" v-if="accountData">
              <div class="row mb-2">
                <div class="col">
                  <div class="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      v-model="useExistingBillingInformation"
                      id="customCheck"
                      data-test-hook="UseCurrentBillingAddressBtn"
                    />
                    <label class="custom-control-label" for="customCheck" data-test-hook="useCurrBillingInfoText">
                      {{ $t('xUseCurrentBillingInfo') }}
                    </label>
                  </div>
                </div>
              </div>
              <div class="row" v-if="useExistingBillingInformation">
                <div data-test-hook="ExistingBillingName" class="col-12">
                  {{ accountData.firstName }} {{ accountData.lastName }}
                </div>
                <div data-test-hook="ExistingBillingAddress" class="col-12">
                  {{ DisplayAddress(accountData.address) }}
                </div>
              </div>
              <div v-if="!useExistingBillingInformation">
                <div class="row">
                  <div class="col-12">
                    <div role="group" class="form-group" id="firstName-group">
                      <input class="form-control" type="text" id="firstName" v-model="accountData.firstName" />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <div role="group" class="form-group" id="lastName-group">
                      <input class="form-control" type="text" id="lastName" v-model="accountData.lastName" />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <TypeAheadAddress
                      ref="typeAheadAddressSubscription"
                      :currentAddress="accountData.address"
                      @newAddress="setNewBillingAddress"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="asterisk-text col">
                <i data-test-hook="UpdatePaymentWarningMsg" class="colorOrange">*{{ $t('xUpdatingPaymentWarning') }}</i>
              </div>
            </div>
          </div>
        </div>
        <footer class="modal-footer">
          <button
            v-if="useExistingBillingInformation"
            variant="warning"
            block
            @click="payForProduct()"
            :disabled="!canPay()"
            data-test-hook="SaveChangesBtn"
            type="button"
            class="btn btn-warning btn-block"
          >
            {{ $t('xSaveAndClose') }}
            <div class="spinner-border" v-if="paying" />
          </button>
          <button
            v-else
            variant="outline-warning"
            block
            @click="saveAccountHolder(), (savingAccountHolder = true)"
            :disabled="savingAccountHolder"
            data-test-hook="SaveChangesBtn"
          >
            {{ $t('xConfirmBillingInfo') }}
          </button>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { required, requiredIf, minLength, maxLength, email, numeric } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'
import * as Constants from '@/constants'
import TypeAheadAddress from '@/components/TypeAheadAddress'

const monthInRange = (value, vm) => value >= 1 && value <= 12

export default {
  components: {
    TypeAheadAddress
  },
  props: ['existingPaymentInfo', 'accountProfile', 'apparatusId', 'subscriptionStatus'],
  data() {
    return {
      paying: false,
      creditCardNumber: null,
      expirationMonth: null,
      expirationYear: null,
      cvv: null,
      showValidationBanner: false,
      DealerManagedProductId: Constants.DealerManagedProductId,
      FreeProductId: Constants.FreeWifiEthProductId,
      accountData: null,
      useExistingBillingInformation: true,
      savingAccountHolder: false,
      errorMessages: [],
      subscription: null,
      subscriptionLoading: false,
      productId: null
    }
  },
  validations: {
    cvv: {
      required,
      minLength: minLength(3),
      maxLength: maxLength(4),
      numeric
    },
    expirationMonth: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(2),
      numeric,
      monthInRange
    },
    expirationYear: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(2),
      numeric
    },
    creditCardNumber: {
      required,
      minLength: minLength(15),
      maxLength: maxLength(16),
      numeric
    }
  },
  computed: {
    ...mapState({
      currentApparatusDetails: state => state.apparatus.currentApparatusDetails,
      currentAppConfig: state => state.application.currentAppConfig,
      isImpersonating: state => state.auth.isImpersonating
    }),
    billingMigrationFeatureFlag() {
      return Constants.BillingMigrationFeatureFlag
    }
  },
  methods: {
    cancelledDunning() {
      if (this.subscriptionStatus && this.subscriptionStatus == Constants.SubscriptionCancelled) return true
      return false
    },
    suspendedDunning() {
      if (this.subscriptionStatus && this.subscriptionStatus == Constants.SubscriptionSuspended) return true
      return false
    },
    setNewBillingAddress(newAddress) {
      this.accountData.address = newAddress
      if (this.$refs.typeAheadAddressSubscription.$v.$anyError) {
        this.addressSaveDisabled = true
      } else {
        this.addressSaveDisabled = false
      }
    },
    saveAccountHolder() {
      this.savingAccountHolder = true
      this.$store
        .dispatch('updateAccountHolder', {
          firstName: this.accountData.firstName,
          lastName: this.accountData.lastName,
          organizationAddress: {
            ...this.accountData.address,
            organizationId: this.accountData.organizationId
          }
        })
        .then(response => {
          if (!this.isImpersonating) this.$store.dispatch('getUser')
          else this.$store.dispatch('getImpersonatedUser')
          this.$store.dispatch('getAccountProfile')
          this.timeTheAccountChange()
        })
        .catch(error => {
          this.$store.dispatch('displayToast', {
            title: this.$t('xError'),
            message: this.$t('xFailedToUpdateBillingNameAndAddress'),
            variant: 'danger'
          })
        })
    },
    timeTheAccountChange() {
      setTimeout(
        function() {
          this.savingAccountHolder = false
          this.useExistingBillingInformation = true
        }.bind(this),
        5000
      )
    },
    payForProduct() {
      this.paying = true
      let addPaymentModel = {
        organizationId: this.accountProfile.organizationId,
        cardNumber: this.creditCardNumber,
        cvv: this.cvv,
        expirationMonth: this.expirationMonth,
        expirationYear: '20' + this.expirationYear
      }
      if (this.existingPaymentInfo) {
        let removePaymentModel = {
          organizationId: this.accountProfile.organizationId,
          paymentId: this.existingPaymentInfo.id
        }
        this.$store
          .dispatch('addBillingAccountPaymentMethod', addPaymentModel)
          .then(response => {
            this.$store
              .dispatch('removeBillingAccountPaymentMethod', removePaymentModel)
              .then(response => {
                if (this.cancelledDunning() && this.productId) {
                  //need to set up brand new subscription
                  this.$store
                    .dispatch('changeBillingAccountProduct', {
                      organizationId: this.accountProfile.organizationId,
                      apparatusId: this.apparatusId,
                      productId: this.productId
                    })
                    .then(response => {
                      this.paying = false
                      document.getElementById('fixDunningModal' + this.apparatusId).style.display = 'none'
                      this.$store.dispatch('getApparatuses')
                    })
                    .catch(error => {
                      this.$store.dispatch('displayToast', {
                        title: this.$t('xError'),
                        message: this.$t('xFailedSetUpGeneratorSubscription'),
                        variant: 'danger'
                      })
                    })
                } else {
                  this.paying = false
                  document.getElementById('fixDunningModal' + this.apparatusId).style.display = 'none'
                  this.$store.dispatch('getApparatuses')
                }
              })
              .catch(error => {
                this.paying = false
                this.$store.dispatch('displayToast', {
                  title: this.$t('xError'),
                  message: this.t$('xFailedToRemoveExistingPaymentInformation'),
                  variant: 'danger'
                })
              })
          })
          .catch(error => {
            this.paying = false
            this.existingPaymentInfo = false
            this.errorMessages = []
            if (error.response.data.errors) {
              if (error.response.data.errors.CardNumber || error.response.data.errors.cardNumber) {
                this.showValidationBanner = true
                this.errorMessages.push(this.$t('xCCIsInvalid'))
              }
              if (error.response.data.errors.Cvv || error.response.data.errors.cvv) {
                this.showValidationBanner = true
                this.errorMessages.push(this.$t('xCvvInvalid'))
              }
              if (error.response.data.errors.ExpirationMonth || error.response.data.errors.expirationMonth) {
                this.showValidationBanner = true
                this.errorMessages.push(this.$t('xExpirationMonthInvalid'))
              }
              if (error.response.data.errors.ExpirationYear || error.response.data.errors.expirationYear) {
                this.showValidationBanner = true
                this.errorMessages.push(this.$t('xExpirationYearInvalid'))
              }
              if (error.response.data.errors.billing || error.response.data.errors.Billing) {
                this.$store.dispatch('displayToast', {
                  title: this.$t('xError'),
                  message: error.response.data.errors.billing
                    ? error.response.data.errors.billing
                    : error.data.response.errors.Billing,
                  variant: 'danger'
                })
              }
            } else {
              this.$store.dispatch('displayToast', {
                title: this.$t('xError'),
                message: this.$t('xFailedToAddPaymentMethod'),
                variant: 'danger'
              })
            }
          })
      } else {
        this.$store
          .dispatch('addBillingAccountPaymentMethod', addPaymentModel)
          .then(response => {
            if (this.cancelledDunning() && this.productId) {
              //need to set up brand new subscription
              this.$store
                .dispatch('changeBillingAccountProduct', {
                  organizationId: this.accountProfile.organizationId,
                  apparatusId: this.apparatusId,
                  productId: this.productId
                })
                .then(response => {
                  this.paying = false
                  document.getElementById('fixDunningModal' + this.apparatusId).style.display = 'none'
                  this.$store.dispatch('getApparatuses')
                })
                .catch(error => {
                  this.$store.dispatch('displayToast', {
                    title: this.$t('xError'),
                    message: this.$t('xFailedSetUpGeneratorSubscription'),
                    variant: 'danger'
                  })
                })
            } else {
              this.paying = false
              document.getElementById('fixDunningModal' + this.apparatusId).style.display = 'none'
              this.$store.dispatch('getApparatuses')
            }
          })
          .catch(error => {
            this.paying = false
            this.existingPaymentInfo = false
            this.errorMessages = []
            if (error.response.data.errors) {
              if (error.response.data.errors.CardNumber || error.response.data.errors.cardNumber) {
                this.showValidationBanner = true
                this.errorMessages.push(this.$t('xCCIsInvalid'))
              }
              if (error.response.data.errors.Cvv || error.response.data.errors.cvv) {
                this.showValidationBanner = true
                this.errorMessages.push(this.$t('xCvvInvalid'))
              }
              if (error.response.data.errors.ExpirationMonth || error.response.data.errors.expirationMonth) {
                this.showValidationBanner = true
                this.errorMessages.push(this.$t('xExpirationMonthInvalid'))
              }
              if (error.response.data.errors.ExpirationYear || error.response.data.errors.expirationYear) {
                this.showValidationBanner = true
                this.errorMessages.push(this.$t('xExpirationYearInvalid'))
              }
              if (error.response.data.errors.billing || error.response.data.errors.Billing) {
                this.$store.dispatch('displayToast', {
                  title: this.$t('xError'),
                  message: error.response.data.errors.billing
                    ? error.response.data.errors.billing
                    : error.data.response.errors.Billing,
                  variant: 'danger'
                })
              }
            } else {
              this.$store.dispatch('displayToast', {
                title: this.$t('xError'),
                message: this.$t('xFailedToAddPaymentMethod'),
                variant: 'danger'
              })
            }
          })
      }
    },
    closeModal() {
      this.creditCardNumber = null
      this.expirationMonth = null
      this.expirationYear = null
      this.cvv = null
      this.paying = false
      this.showValidationBanner = false
      this.errorMessages = []
      this.$v.$reset()
      document.getElementById('fixDunningModal' + this.apparatusId).style.display = 'none'
    },
    canPay() {
      if (!this.creditCardNumber || !this.cvv || !this.expirationMonth || !this.expirationYear) {
        return false
      }
      if (!this.$v.$anyError && !this.paying) {
        return true
      } else return false
    }
  },
  watch: {
    accountProfile: function(newVal, oldVal) {
      if (newVal) this.accountData = _.cloneDeep(newVal)
    }
  },
  mounted() {
    if (this.accountProfile) {
      this.accountData = _.cloneDeep(this.accountProfile)
    }
    this.subscriptionLoading = true
    this.$store
      .dispatch('getApparatusSubscription', {
        organizationId: this.accountProfile.organizationId,
        apparatusId: this.apparatusId
      })
      .then(response => {
        this.subscription = response.data
        if (
          this.subscription.activeProduct &&
          this.subscription.activeProduct.length > 0 &&
          this.subscription.activeProduct[0] &&
          this.subscription.activeProduct[0].productId
        ) {
          this.productId = this.subscription.activeProduct[0].productId
        }
        this.subscriptionLoading = false
      })
      .catch(error => {
        this.subscriptionLoading = false
        this.$store.dispatch('displayToast', {
          title: this.$t('xError'),
          message: this.$t('xNoSubscriptionsFoundForThisGenerator'),
          variant: 'danger'
        })
      })
  }
}
</script>
