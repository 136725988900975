<template>
  <div class="mt-2 ml-md-2">
    <div v-if="!statusHistory" class="d-flex justify-content-center mb-3 mt-4">
      <span class="spinner-border text-warning" style="width: 5rem; height: 5rem;" label="Spinning"></span>
    </div>
    <div v-else>
      <div class="row no-gutters mb-2">
        <div class="col">
          <h1 class="mb-0 pb-0" data-test-hook="stsHistPageTitle">
            <i class="far fa-history colorOrange mr-1"></i> {{ $t('xStatusHistory') }}
          </h1>
        </div>
        <div class="cols-auto d-flex align-content-flex-end">
          <button
            type="butotn"
            class="btn btn-icon-button"
            @click="
              openModal('selectStatusHistoryDateRange'),
                trackAppInsightsEvent('open-export-status-history-modal', {
                  page: 'Status History',
                  organizationId: accountProfile.organizationId,
                  userId: accountProfile.userId,
                  apparatusId: $route.params.apparatusId
                })
            "
            data-test-hook="export-button-status-history"
          >
            <i class="fas fa-file-export" />
          </button>
        </div>
      </div>
      <div
        v-if="hasDisconnectedNotificationsOn && currentApparatusDetails.apparatusStatus == disconnectedStatus"
        class="row mb-3"
      >
        <div class="col">
          <div class="card snooze-card" data-test-hook="snoozeCard">
            <div class="card-body">
              <div class="snooze-card-title" data-test-hook="snoozeCardTitle">
                <span v-if="hsbWifiDeviceType()">
                  <i class="fas fa-wifi-slash" />
                  {{ $t('xNoWifiSignal') }}
                </span>
                <span v-else-if="hsbEthDeviceType()">
                  <i class="fas fa-signal-alt-slash" />
                  {{ $t('xNoSignal') }}
                </span>
                <span v-else>
                  <i class="fas fa-signal-alt-slash" />
                  {{ $t('xNoCellularSignal') }}
                </span>
              </div>
              <p v-if="hsbWifiDeviceType() || hsbEthDeviceType()" class="mt-3">
                {{ $t('xWifiOrEthNotCommunicating', { 0: currentApparatusDetails.name }) }}
              </p>
              <p v-else class="mt-3">
                {{ $t('xLteOrCdmaNotCommunicating', { 0: currentApparatusDetails.name }) }}
              </p>
              <p v-if="showSnoozeMessage()" class="mt-3">
                {{
                  $t('xYourNoSignalNotificationHasBeenSnoozedUntil', {
                    0: displayShortDate(disconnectedNotification.snoozeUntil),
                    1: displayExtraShortTimeWithTZ(disconnectedNotification.snoozeUntil)
                  })
                }}
              </p>
              <div class="row align-middle">
                <div class="col-auto align-middle">
                  <button
                    v-if="!showSnoozeMessage()"
                    type="button"
                    class="btn btn-outline-warning"
                    @click="openSnoozeModal(), trackSnoozeAnalytics('snooze-notification-button-clicked')"
                    data-test-hook="snoozeNotificationBtn"
                  >
                    {{ $t('xSnoozeNotification') }}
                  </button>
                  <button
                    v-else
                    type="button"
                    class="btn btn-outline-warning"
                    @click="openResumeNotificationModal(), trackSnoozeAnalytics('resume-notification-button-clicked')"
                  >
                    {{ $t('xResumeNotification') }}
                  </button>
                </div>
                <div class="col-auto float-left mr-2 troubleshoot-link-col">
                  <a
                    target="_blank"
                    class="snooze-troubleshoot-link"
                    :href="
                      hsbWifiDeviceType() || hsbEthDeviceType()
                        ? 'https://support.generac.com/mobilelink/s/global-search/wifi%20troubleshooting'
                        : 'https://support.generac.com/mobilelink/s/global-search/LTE'
                    "
                  >
                    <i class="fa-solid fa-circle-question colorOrange" />
                    {{ $t('xTroubleshootingHelp') }}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card">
        <div class="list-group list-group-flush" v-for="(status, index) in statusHistory" :key="index">
          <div class="list-group-item" :id="index">
            <div class="row">
              <div class="cols-auto">
                <small :data-test-hook="'StatusHistoryItemDateTime' + index">{{
                  displayShortDateTimeWithTZ(status.eventOccurred)
                }}</small>
              </div>
            </div>
            <div class="row no-gutters align-items-center">
              <div class="col-auto">
                <StatusIcon
                  :apparatusStatus="status.type"
                  :isConnected="true"
                  :showWarning="false"
                  :small="true"
                  :deviceType="
                    currentApparatusDetails.deviceType == hsbG3DeviceType
                      ? currentApparatusDetails.networkType
                      : currentApparatusDetails.deviceType
                  "
                  :data-test-hook="'StatusHistoryItemIcon' + index"
                />
              </div>
              <div class="col ml-2" :data-test-hook="'StatusHistoryItemMessage' + index">
                {{ status.message }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Modal modalId="selectStatusHistoryDateRange" size="modal-md" @hidden="selectedDateRange = null">
      <ModalHeader :title="$t('xExportStatusHistory')" icon="fas fa-file-export colorOrange mr-1">
        <div class="cols-auto float-right">
          <button
            type="button"
            class="btn btn-icon-button btn-icon-button--gray"
            @click="closeModal('selectStatusHistoryDateRange')"
            data-test-hook="x-button-selectStatusHistoryDateRange-status-history"
          >
            <i class="fas fa-times" />
          </button>
        </div>
      </ModalHeader>
      <ModalBody>
        <div class="row mb-3">
          <div class="col">
            <strong data-test-hook="SelectDateRangeLabel">{{ 'xSelectYourHistoryRange' | formatLabel }}</strong>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="list-group list-group-flush">
              <div class="list-group-item">
                <div class="custom-radio">
                  <input
                    type="radio"
                    v-model="selectedDateRange"
                    value="Months6"
                    id="months6"
                    data-test-hook="6-months-radio-selectStatusHistoryDateRange-status-history"
                  />
                  <label for="months6" class="ml-2" style="margin-bottom: 0px;">
                    {{ $t('x6Months') }}
                  </label>
                </div>
              </div>
              <div class="list-group-item">
                <div class="custom-radio">
                  <input
                    type="radio"
                    v-model="selectedDateRange"
                    value="Years1"
                    id="years1"
                    data-test-hook="1-year-radio-selectStatusHistoryDateRange-status-history"
                  />
                  <label for="years1" class="ml-2" style="margin-bottom: 0px;">
                    {{ $t('x1Year') }}
                  </label>
                </div>
              </div>
              <div class="list-group-item">
                <div class="custom-radio">
                  <input
                    type="radio"
                    v-model="selectedDateRange"
                    value="Years2"
                    id="years2"
                    data-test-hook="2-years-radio-selectStatusHistoryDateRange-status-history"
                  />
                  <label for="years2" class="ml-2" style="margin-bottom: 0px;">
                    {{ $t('x2Years') }}
                  </label>
                </div>
              </div>
              <div class="list-group-item">
                <div class="custom-radio">
                  <input
                    type="radio"
                    v-model="selectedDateRange"
                    value="Years3"
                    id="years3"
                    data-test-hook="3-years-radio-selectStatusHistoryDateRange-status-history"
                  />
                  <label for="years3" class="ml-2" style="margin-bottom: 0px;">
                    {{ $t('x3Years') }}
                  </label>
                </div>
              </div>
              <div class="list-group-item">
                <div class="custom-radio">
                  <input
                    type="radio"
                    v-model="selectedDateRange"
                    value="All"
                    id="all"
                    data-test-hook="all-radio-selectStatusHistoryDateRange-status-history"
                  />
                  <label for="all" class="ml-2" style="margin-bottom: 0px;">
                    {{ $t('xAll') }}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <button
          type="button"
          class="btn btn-outline-warning"
          @click="closeModal('selectStatusHistoryDateRange')"
          data-test-hook="cancel-button-selectStatusHistoryDateRange-status-history"
        >
          {{ $t('xCancel') }}
        </button>
        <button
          type="button"
          class="btn btn-warning"
          :disabled="downloadingStatusHistory || !selectedDateRange"
          @click="
            exportStatusHistory(),
              trackAppInsightsEvent('download-status-history', {
                page: 'Status History',
                organizationId: accountProfile.organizationId,
                userId: accountProfile.userId,
                apparatusId: $route.params.apparatusId,
                dateRange: selectedDateRange
              })
          "
          data-test-hook="download-button-selectStatusHistoryDateRange-status-history"
        >
          <i class="fas fa-file-csv mr-1" /> {{ $t('xDownload') }}
          <span class="spinner-border" v-if="downloadingStatusHistory" />
        </button>
      </ModalFooter>
    </Modal>

    <Modal modalId="snooze-modal">
      <ModalHeader :title="$t('xSnoozeNotifications')" icon="fa-regular fa-bell-slash">
        <button
          class="btn btn-icon-button btn-icon-button--gray"
          @click="closeSnoozeModal(), trackSnoozeAnalytics('snooze-modal-cancel-button-clicked')"
        >
          <i class="fas fa-times" />
        </button>
      </ModalHeader>
      <ModalBody>
        <p>
          {{ $t('xSnoozeNotificationMessage1') }}
        </p>
        <p class="mt-3">
          {{ $t('xSnoozeNotificationMessage2') }}
        </p>
        <p class="mt-3">
          {{ $t('xSnoozeNotificationUntil') }}
        </p>
        <fieldset class="form-group">
          <div class="mt-3 custom-control custom-radio">
            <input class="custom-control-input" id="1DayRadio" type="radio" v-model="selectDateTime" value="1Day" />
            <label class="custom-control-label" for="1DayRadio">
              {{
                $t('xSnoozeUntilTomorrow', {
                  0: displayShortDate($moment().add(1, 'days')),
                  1: displayExtraShortTimeWithTZ($moment().add(1, 'days'))
                })
              }}
            </label>
          </div>
          <div class="mt-2 custom-control custom-radio">
            <input
              class="custom-control-input"
              id="1WeekRadio"
              type="radio"
              v-model="selectDateTime"
              value="1Week"
              checked
            />
            <label class="custom-control-label" for="1WeekRadio">
              {{
                $t('xSnoozeUntilNextWeek', {
                  0: displayShortDate($moment().add(7, 'days')),
                  1: displayExtraShortTimeWithTZ($moment().add(7, 'days'))
                })
              }}
            </label>
          </div>
          <div class="mt-2 custom-control custom-radio">
            <input
              class="custom-control-input"
              id="DateTimeRadio"
              type="radio"
              v-model="selectDateTime"
              value="custom"
            />
            <label class="custom-control-label align-middle" for="DateTimeRadio">
              <span v-html="$t('xDateAndTime')" />
            </label>
          </div>
        </fieldset>
        <div v-if="showCustomDateTime">
          <input
            class="form-control mt-2"
            id="customDate"
            type="date"
            v-model="customDate"
            :min="datePickerMinDate()"
            :max="datePickerMaxDate()"
          />
          <input class="form-control mt-2" id="customTime" type="time" v-model="customTime" />
        </div>
      </ModalBody>
      <ModalFooter>
        <button
          type="button"
          class="btn btn-outline-warning"
          @click="closeSnoozeModal(), trackSnoozeAnalytics('snooze-modal-cancel-button-clicked')"
        >
          {{ $t('xCancel') }}
        </button>
        <button
          type="button"
          class="btn btn-primary"
          @click=";(resumeSnooze = false), setSnooze()"
          :disabled="saveSnoozeDisabled() || settingSnooze"
        >
          {{ $t('xSave') }}
          <b-spinner v-if="settingSnooze" class="ml-2" />
        </button>
      </ModalFooter>
    </Modal>
    <Modal modalId="resume-modal">
      <ModalHeader :title="$t('xResumeNotification')" icon="fa-regular fa-bell-on">
        <button class="btn btn-icon-button btn-icon-button--gray" @click="closeResumeNotificationModal()">
          <i class="fas fa-times" />
        </button>
      </ModalHeader>
      <ModalBody>
        <p>
          {{ $t('xResumeNotificationMessage', { 0: currentApparatusDetails.name }) }}
        </p>
        <p class="mt-3">
          {{ $t('xResumeNotificationMessage2') }}
        </p>
      </ModalBody>
      <ModalFooter>
        <button type="button" class="btn btn-outline-warning" @click="closeResumeNotificationModal()">
          {{ $t('xNoCancel') }}
        </button>
        <button
          type="button"
          class="btn btn-primary"
          @click=";(resumeSnooze = true), setSnooze()"
          :disabled="settingSnooze"
        >
          {{ $t('xYesImSure') }}
          <b-spinner v-if="settingSnooze" class="ml-2" />
        </button>
      </ModalFooter>
    </Modal>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import StatusIcon from '@/components/StatusIcon'
import * as Constants from '@/constants'

export default {
  components: { StatusIcon },
  data() {
    return {
      statusHistory: null,
      pageNumber: 1,
      pageSize: 15,
      loadingAdditionalStatusHistory: false,
      selectedDateRange: null,
      downloadingStatusHistory: false,
      selectDateTime: null,
      snoozeDateTime: false,
      showCustomDateTime: false,
      customDate: null,
      customTime: null,
      settingSnooze: false,
      resumeSnooze: false,
      disconnectedNotification: null,
      hasDisconnectedNotificationsOn: false
    }
  },
  computed: {
    ...mapState({
      currentApparatusStatusHistory: state => state.apparatus.currentApparatusStatusHistory,
      currentApparatusDetails: state => state.apparatus.currentApparatusDetails,
      accountProfile: state => state.account.accountProfile
    }),
    disconnectedStatus() {
      return Constants.StatusIsDisconnected
    },
    hsbG3DeviceType() {
      return Constants.HsbG3DeviceType
    }
  },
  methods: {
    trackSnoozeAnalytics(eventName) {
      this.trackAppInsightsEvent(eventName, {
        page: 'statusHistory',
        organizationId: this.accountProfile.organizationId,
        userId: this.accountProfile.userId,
        apparatusId: this.$route.params.apparatusId,
        receiveDisconnectedEmail: this.disconnectedNotification.receiveEmail,
        receiveDisconnectedSms: this.disconnectedNotification.receiveSms,
        receiveDisconnectedPush: this.disconnectedNotification.receivePush
      })
    },
    openResumeNotificationModal() {
      document.getElementById('resume-modal').style.display = 'block'
    },
    closeResumeNotificationModal() {
      document.getElementById('resume-modal').style.display = 'none'
    },
    showSnoozeMessage() {
      if (this.disconnectedNotification && this.disconnectedNotification.snoozeUntil) {
        if (this.disconnectedNotification.snoozeUntil < new Date().toISOString()) return false
        else return true
      }
      return false
    },
    datePickerMinDate() {
      let date = new Date()
      return date.toISOString().split('T')[0]
    },
    datePickerMaxDate() {
      let date = new Date()
      date.setDate(date.getDate() + 30)
      return date.toISOString().split('T')[0]
    },
    saveSnoozeDisabled() {
      if (this.selectDateTime !== 'custom') {
        if (!this.snoozeDateTime) return true
        else return false
      } else {
        if (!this.customDate || !this.customTime) return true
        else return false
      }
    },
    setCustomDateTime() {
      return (
        this.$moment.utc(this.customDate).format(this.$t('xShortDate')) +
        ' ' +
        this.$moment(this.customTime, 'HH:mm').format(this.$t('xExtraShortTime'))
      )
    },
    setSnooze() {
      this.settingSnooze = true
      if (!this.resumeSnooze) {
        if (this.showCustomDateTime) {
          this.snoozeDateTime = this.setCustomDateTime()
        }
        this.snoozeDateTime = new Date(this.snoozeDateTime).toISOString()
      }
      this.trackAppInsightsEvent('save-snooze-clicked', {
        page: 'statusHistory',
        organizationId: this.accountProfile.organizationId,
        userId: this.accountProfile.userId,
        apparatusId: this.$route.params.apparatusId,
        receiveDisconnectedEmail: this.disconnectedNotification.receiveEmail,
        receiveDisconnectedSms: this.disconnectedNotification.receiveSms,
        receiveDisconnectedPush: this.disconnectedNotification.receivePush,
        snoozeDateTime: this.snoozeDateTime,
        selectedOption: this.selectDateTime
      })
      let request = {
        id: this.disconnectedNotification.id,
        receiveEmail: this.disconnectedNotification.receiveEmail,
        receiveSms: this.disconnectedNotification.receiveSms,
        receivePush: this.disconnectedNotification.receivePush,
        snoozeUntil: this.resumeSnooze ? null : this.snoozeDateTime
      }
      this.$store
        .dispatch('updatePreference', request)
        .then(response => {
          this.$store.dispatch('getApparatusDetails', this.$route.params.apparatusId).finally(() => {
            this.settingSnooze = false
            this.closeSnoozeModal()
            this.closeResumeNotificationModal()
          })
        })
        .catch(error => {
          let failureMessage = ''
          if (!this.resumeSnooze) {
            //failure message is about setting snooze
            failureMessage = this.$t('xSnoozeNotificationFailed')
          } else {
            //failure message is about resuming notification
            failureMessage = this.$t('xResumeNotificationFailed')
          }
          this.$store.dispatch('displayToast', {
            title: this.$t('xError'),
            message: failureMessage,
            variant: 'danger'
          })
          this.settingSnooze = false
        })
    },
    openSnoozeModal() {
      this.selectDateTime = '1Week'
      document.getElementById('snooze-modal').style.display = 'block'
    },
    closeSnoozeModal() {
      document.getElementById('snooze-modal').style.display = 'none'
    },
    hsbWifiDeviceType() {
      if (this.currentApparatusDetails) {
        return this.currentApparatusDetails.deviceType == Constants.HsbWifiDeviceType
      }
    },
    hsbEthDeviceType() {
      if (this.currentApparatusDetails) {
        return this.currentApparatusDetails.deviceType == Constants.HsbEthDeviceType
      }
    },
    hsbLteDeviceType() {
      if (this.currentApparatusDetails) {
        return this.currentApparatusDetails.deviceType == Constants.HsbLteDeviceType
      }
    },
    hsbCdmaDeviceType() {
      if (this.currentApparatusDetails) {
        return this.currentApparatusDetails.deviceType == Constants.HsbCdmaDeviceType
      }
    },
    exportStatusHistory() {
      this.downloadingStatusHistory = true
      let tz = this.$moment.tz.guess().toString()
      this.$store
        .dispatch('exportStatusHistory', {
          apparatusId: this.$route.params.apparatusId,
          dateRange: this.selectedDateRange,
          timeZone: tz
        })
        .then(response => {
          let fileURL = window.URL.createObjectURL(new Blob([response.data]))
          let fileLink = document.createElement('a')
          fileLink.href = fileURL
          fileLink.setAttribute('download', 'statusHistory.csv')
          document.body.appendChild(fileLink)
          fileLink.click()
          this.closeModal('selectStatusHistoryDateRange')
        })
        .catch(error => {
          this.$store.dispatch('displayToast', {
            title: this.$t('xError'),
            message: this.$t('xNoStatusHistory'),
            variant: 'danger'
          })
        })
        .finally(() => {
          this.downloadingStatusHistory = false
        })
    },
    onScroll() {
      if (this.statusHistory) {
        let doc = document.getElementById(this.statusHistory.length - 1)
        if (doc) {
          let rect = doc.getBoundingClientRect()
          if (
            !this.loadingAdditionalStatusHistory &&
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) /* or $(window).height() */ &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */
          ) {
            this.loadingAdditionalStatusHistory = true
            this.pageNumber++
            this.$store
              .dispatch('getApparatusStatusHistory', {
                apparatusId: this.$route.params.apparatusId,
                pageNumber: this.pageNumber,
                pageSize: this.pageSize
              })
              .then(response => {
                if (response.data.statusHistory.length > 0) {
                  this.statusHistory.push(...response.data.statusHistory)
                }
                this.loadingAdditionalStatusHistory = false
              })
              .catch(error => {
                this.loadingAdditionalStatusHistory = false
              })
          }
        }
      }
    }
  },
  watch: {
    selectDateTime: function(newVal, oldVal) {
      if (newVal) {
        if (newVal === '1Day') {
          this.snoozeDateTime = this.$moment().add(1, 'days')
          this.showCustomDateTime = false
        } else if (newVal === '1Week') {
          this.snoozeDateTime = this.$moment().add(7, 'days')
          this.showCustomDateTime = false
        } else if (newVal === 'custom') {
          this.snoozeDateTime = null
          this.showCustomDateTime = true
          this.customDate = this.$moment().format(this.$t('xYear-Month-Day'))
          this.customTime = this.$moment()
            .add(5, 'minutes')
            .format('HH:mm')
        }
      }
    },
    currentApparatusDetails: function(newVal, oldVal) {
      if (newVal && newVal.disconnectedNotification) {
        this.disconnectedNotification = _.cloneDeep(newVal.disconnectedNotification)
        this.hasDisconnectedNotificationsOn = _.cloneDeep(newVal.hasDisconnectedNotificationsOn)
      }
    }
  },
  mounted() {
    if (this.currentApparatusDetails) {
      this.disconnectedNotification = _.cloneDeep(this.currentApparatusDetails.disconnectedNotification)
      this.hasDisconnectedNotificationsOn = _.cloneDeep(this.currentApparatusDetails.hasDisconnectedNotificationsOn)
    } else this.$store.dispatch('getApparatusById', this.$route.params.apparatusId)
    this.$store
      .dispatch('getApparatusStatusHistory', {
        apparatusId: this.$route.params.apparatusId,
        pageNumber: this.pageNumber,
        pageSize: this.pageSize
      })
      .then(response => {
        this.statusHistory = response.data.statusHistory
      })
      .catch(error => {})
    let container = document.getElementById('childRouteContainer')
    container.addEventListener('scroll', this.onScroll)
  }
}
</script>

<style scoped>
.btn-icon-button:not(:disabled):not(.disabled):hover {
  background-color: var(--white) !important;
}
.snooze-card {
  background-color: var(--carbon-300);
}
.snooze-card-title {
  font-size: 18px;
  font-weight: 400;
}
.troubleshoot-link-col {
  line-height: 43px;
  height: 43px;
}
.snooze-troubleshoot-link {
  color: var(--carbon-900) !important;
  text-decoration: underline !important;
}

.snooze-troubleshoot-link:hover {
  color: var(--carbon-700) !important;
}
</style>
