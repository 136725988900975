import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

function loadLocaleMessages() {
  const locales = require.context('./locale/', true, /\bResource.\b[\bNoTranslate.\b]*?[A-Za-z0-9-_,\s]+\b\.json\b$/i)

  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/(\bNoTranslate.\b)?[A-Za-z0-9-_,\s]+?(?=\b\.json\b)/i)

    if (matched && matched.length > 0) {
      const locale = matched[0]
      messages[locale] = locales(key)

      if (urlParams.get('overrideLanguage') === 'xx-XX') {
        //Intentionally breaking all keys when xx-XX is detected
        messages[locale] = 'xx-XX'
      }
    }
  })
  return messages
}

const urlParams = new URLSearchParams(window.location.search)

export default new VueI18n({
  locale: navigator.language,
  fallbackLocale: [
    navigator.language.toString().substring(0, 2),
    'en-US',
    'NoTranslate',
    'NoTranslate.en-US',
    'NoTranslate.' + navigator.language,
    'NoTranslate.' + navigator.language.toString().substring(0, 2)
  ],
  messages: loadLocaleMessages(),
  silentFallbackWarn: true
})
