import Axios from 'axios'
import router from '@/router/'
import { vueAppApiBaseAddress } from '@/utils/mixins'

export const state = {
  hasUnreadMessages: false,
  messages: null,
  messageCenterPreferences: null
}

export const getters = {}

export const mutations = {
  setHasUnreadMessages(state, value) {
    state.hasUnreadMessages = value
  },
  setMessages(state, messages) {
    state.messages = messages
  },
  setMessageCenterPreferences(state, value) {
    state.messageCenterPreferences = value
  }
}

export const actions = {
  async getMessages({ commit }) {
    await Axios.get(vueAppApiBaseAddress() + `v2/MessageCenter/messages`, {
      withCredentials: true
    })
      .then(response => {
        if (response.status == 204) commit('setMessages', null)
        else commit('setMessages', response.data)
      })
      .catch(error => {
        commit('setMessages', null)
      })
  },
  async getHasUnreadMessages({ getters, commit, dispatch }) {
    await Axios.get(vueAppApiBaseAddress() + `v1/MessageCenter/unread`, {
      withCredentials: true
    })
      .then(response => {
        commit('setHasUnreadMessages', response.data)
      })
      .catch(error => {})
  },
  async markMessagesAsRead({ getters, commit, dispatch }, request) {
    return await Axios.put(
      vueAppApiBaseAddress() + `v1/MessageCenter/mark/read`,
      { messageIds: request },
      {
        withCredentials: true
      }
    )
  },
  async deleteMessages({ getters, commit, dispatch }, request) {
    return await Axios.delete(
      vueAppApiBaseAddress() + `v1/MessageCenter/delete?messageIds=${encodeURIComponent(request)}`,
      {
        withCredentials: true
      }
    )
  },
  async getMessageCenterPreferences({ commit }) {
    await Axios.get(vueAppApiBaseAddress() + `v1/MessageCenter/getMessagePreferences`, {
      withCredentials: true
    })
      .then(response => {
        commit('setMessageCenterPreferences', response.data)
      })
      .catch(error => {
        commit('setMessageCenterPreferences', null)
      })
  },
  async updateMessagePreferences({ getters, commit, dispatch }, request) {
    return await Axios.put(vueAppApiBaseAddress() + `v1/MessageCenter/updateMessagePreference`, request, {
      withCredentials: true
    })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
