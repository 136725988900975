var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "notifications" }, [
    _vm.notifications
      ? _c("div", { staticClass: "card no-border" }, [
          _c(
            "div",
            { staticClass: "card-header", attrs: { tabs: "", fill: "" } },
            [
              _c(
                "ul",
                { staticClass: "nav nav-tabs card-header-tabs nav-fill" },
                [
                  _c(
                    "li",
                    {
                      staticClass: "nav-item hoverPointer",
                      on: {
                        click: function($event) {
                          _vm.tabIndex = 0
                        }
                      }
                    },
                    [
                      _c(
                        "a",
                        { staticClass: "nav-link", class: _vm.isActive(0) },
                        [_vm._v("Email")]
                      )
                    ]
                  ),
                  _c(
                    "li",
                    {
                      staticClass: "nav-item hoverPointer",
                      on: {
                        click: function($event) {
                          _vm.tabIndex = 1
                        }
                      }
                    },
                    [
                      _c(
                        "a",
                        { staticClass: "nav-link", class: _vm.isActive(1) },
                        [_vm._v("SMS")]
                      )
                    ]
                  )
                ]
              )
            ]
          ),
          _vm.tabIndex == 0
            ? _c("div", [
                _vm.notifications.emailNotifications &&
                _vm.notifications.emailNotifications.length > 0
                  ? _c("div", [
                      _c(
                        "table",
                        {
                          staticClass:
                            "search-table table table-striped table-hover",
                          attrs: {
                            "head-variant": "light",
                            "sticky-header": "600px"
                          }
                        },
                        [
                          _vm._m(0),
                          _c(
                            "tbody",
                            _vm._l(
                              _vm.notifications.emailNotifications,
                              function(emailNotification) {
                                return _c(
                                  "tr",
                                  { key: emailNotification.index },
                                  [
                                    _c("td", [
                                      _vm._v(_vm._s(emailNotification.to))
                                    ]),
                                    _c("td", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.displayShortDate(
                                            emailNotification.sent
                                          )
                                        )
                                      )
                                    ]),
                                    _c("td", [
                                      _vm._v(
                                        _vm._s(emailNotification.emailSubject)
                                      )
                                    ]),
                                    _c("td", [
                                      _vm._v(
                                        _vm._s(emailNotification.templateName)
                                      )
                                    ])
                                  ]
                                )
                              }
                            ),
                            0
                          )
                        ]
                      )
                    ])
                  : _c(
                      "div",
                      [
                        _c(
                          "b-row",
                          [
                            _c("b-col", [
                              _vm._v(" No available email notifications. ")
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    )
              ])
            : _vm._e(),
          _vm.tabIndex == 1
            ? _c("div", [
                _vm.notifications.smsNotifications &&
                _vm.notifications.smsNotifications.length > 0
                  ? _c("div", [
                      _c(
                        "table",
                        {
                          staticClass:
                            "search-table table table-striped table-hover",
                          attrs: {
                            "head-variant": "light",
                            "sticky-header": "600px"
                          }
                        },
                        [
                          _vm._m(1),
                          _c(
                            "tbody",
                            _vm._l(_vm.notifications.smsNotifications, function(
                              smsNotification
                            ) {
                              return _c("tr", { key: smsNotification.sent }, [
                                _c("td", [_vm._v(_vm._s(smsNotification.to))]),
                                _c("td", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.displayShortDate(smsNotification.sent)
                                    )
                                  )
                                ]),
                                _c("td", [
                                  _vm._v(_vm._s(smsNotification.templateName))
                                ])
                              ])
                            }),
                            0
                          )
                        ]
                      )
                    ])
                  : _c("div", [_vm._m(2)])
              ])
            : _vm._e()
        ])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Recipient")]),
        _c("th", [_vm._v("Date Sent")]),
        _c("th", [_vm._v("Email Subject")]),
        _c("th", [_vm._v("Notification Type")])
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Recipient")]),
        _c("th", [_vm._v("Date Sent")]),
        _c("th", [_vm._v("Notification Type")])
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col" }, [
        _vm._v(" No available sms notifications. ")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }