import Axios from 'axios'
import router from '@/router/'
import i18n from '@/lang/'
import { vueAppApiBaseAddress } from '@/utils/mixins'

var initialState = {
  csSearchResults: null,
  searchPending: false,
  searchCriteriaText: 'Serial Number'
}

export const state = _.cloneDeep(initialState)

export const getters = {}

export const mutations = {
  clearResults(state) {
    state.csSearchResults = null
  },
  setSearchPending(state, pending) {
    state.searchPending = pending
  },
  csResultsReset(state) {
    state.csSearchResults = null
  },
  setCSSearchResults(state, results) {
    state.csSearchResults = results
  },
  setSearchCriteria(state, request) {
    state.searchCriteriaText = request.criteria
  }
}

export const actions = {
  async setSearchCriteria({ commit }, request) {
    commit('setSearchCriteria', request)
  },
  async getSerialNumberResults({ getters, commit, dispatch }, serialNumber) {
    commit('setSearchPending', true)
    await Axios.get(vueAppApiBaseAddress() + `CustomerSupport/SerialNumber/${serialNumber}`, {
      withCredentials: true
    })
      .then(response => {
        commit('setCSSearchResults', response.data)
        commit('setSearchPending', false)
      })
      .catch(error => {
        if (error.response.data.status == 401) {
          dispatch('displayToast', {
            title: i18n.t('xError'),
            message: i18n.t('xYourSessionHasExpiredPleaseReLogin'),
            variant: 'danger'
          })
          dispatch('clearAll')
          window.location.replace(vueAppApiBaseAddress() + `Auth/SignOut`)
        } else {
          commit('setSearchPending', false)
        }
      })
  },
  async getDeviceIdResults({ getters, commit, dispatch }, deviceId) {
    commit('setSearchPending', true)
    await Axios.get(vueAppApiBaseAddress() + `CustomerSupport/DeviceId/${deviceId}`, {
      withCredentials: true
    })
      .then(response => {
        commit('setCSSearchResults', response.data)
        commit('setSearchPending', false)
      })
      .catch(error => {
        if (error.response.data.status == 401) {
          dispatch('displayToast', {
            title: i18n.t('xError'),
            message: i18n.t('xYourSessionHasExpiredPleaseReLogin'),
            variant: 'danger'
          })
          dispatch('clearAll')
          window.location.replace(vueAppApiBaseAddress() + `Auth/SignOut`)
        } else {
          commit('setSearchPending', false)
        }
      })
  },
  async getMdnResults({ getters, commit, dispatch }, mdn) {
    commit('setSearchPending', true)
    await Axios.get(vueAppApiBaseAddress() + `CustomerSupport/MDN/${mdn}`, {
      withCredentials: true
    })
      .then(response => {
        commit('setCSSearchResults', response.data)
        commit('setSearchPending', false)
      })
      .catch(error => {
        if (error.response.data.status == 401) {
          dispatch('displayToast', {
            title: i18n.t('xError'),
            message: i18n.t('xYourSessionHasExpiredPleaseReLogin'),
            variant: 'danger'
          })
          dispatch('clearAll')
          window.location.replace(vueAppApiBaseAddress() + `Auth/SignOut`)
        } else {
          commit('setSearchPending', false)
        }
      })
  },
  async getEmailResults({ getters, commit, dispatch }, email) {
    commit('setSearchPending', true)
    await Axios.get(vueAppApiBaseAddress() + `CustomerSupport/Email?email=${encodeURIComponent(email)}`, {
      withCredentials: true
    })
      .then(response => {
        commit('setCSSearchResults', response.data)
        commit('setSearchPending', false)
      })
      .catch(error => {
        if (error.response.data.status == 401) {
          dispatch('displayToast', {
            title: i18n.t('xError'),
            message: i18n.t('xYourSessionHasExpiredPleaseReLogin'),
            variant: 'danger'
          })
          dispatch('clearAll')
          window.location.replace(vueAppApiBaseAddress() + `Auth/SignOut`)
        } else {
          commit('setSearchPending', false)
        }
      })
  },
  async getLastNameResults({ getters, commit, dispatch }, lastName) {
    commit('setSearchPending', true)
    await Axios.get(vueAppApiBaseAddress() + `CustomerSupport/LastName/${lastName}`, {
      withCredentials: true
    })
      .then(response => {
        commit('setCSSearchResults', response.data)
        commit('setSearchPending', false)
      })
      .catch(error => {
        if (error.response.data.status == 401) {
          dispatch('displayToast', {
            title: i18n.t('xError'),
            message: i18n.t('xYourSessionHasExpiredPleaseReLogin'),
            variant: 'danger'
          })
          dispatch('clearAll')
          window.location.replace(vueAppApiBaseAddress() + `Auth/SignOut`)
        } else {
          commit('setSearchPending', false)
        }
      })
  },
  async getMacAddressResults({ getters, commit, dispatch }, macAddress) {
    commit('setSearchPending', true)
    await Axios.get(vueAppApiBaseAddress() + `CustomerSupport/MacAddress/${macAddress}`, {
      withCredentials: true
    })
      .then(response => {
        commit('setCSSearchResults', response.data)
        commit('setSearchPending', false)
      })
      .catch(error => {
        if (error.response.data.status == 401) {
          dispatch('displayToast', {
            title: i18n.t('xError'),
            message: i18n.t('xYourSessionHasExpiredPleaseReLogin'),
            variant: 'danger'
          })
          dispatch('clearAll')
          window.location.replace(vueAppApiBaseAddress() + `Auth/SignOut`)
        } else {
          commit('setSearchPending', false)
        }
      })
  },
  async getSearchLabelResults({ getters, commit, dispatch }, request) {
    commit('setSearchPending', true)
    await Axios.get(
      vueAppApiBaseAddress() + `CustomerSupport/SearchLabel/${request.searchLabel}?deviceType=${request.deviceType}`,
      {
        withCredentials: true
      }
    )
      .then(response => {
        commit('setCSSearchResults', response.data)
        commit('setSearchPending', false)
      })
      .catch(error => {
        if (error.response.data.status == 401) {
          dispatch('displayToast', {
            title: i18n.t('xError'),
            message: i18n.t('xYourSessionHasExpiredPleaseReLogin'),
            variant: 'danger'
          })
          dispatch('clearAll')
          window.location.replace(vueAppApiBaseAddress() + `Auth/SignOut`)
        } else {
          commit('setSearchPending', false)
        }
      })
  },
  async getSearchResultStatus({ getters, commit, dispatch }, value) {
    return await Axios.get(
      vueAppApiBaseAddress() + `CustomerSupport/Status/${value.apparatusId}/${value.deviceType}/${value.deviceId}`,
      {
        withCredentials: true
      }
    )
  },
  async issueCommand({ getters, commit, dispatch }, value) {
    return await Axios.post(vueAppApiBaseAddress() + `CustomerSupport/Command/SendCommand`, value, {
      withCredentials: true
    })
  },
  async getOwnerApparatuses({ getters, commit, dispatch }, orgId) {
    return await Axios.get(vueAppApiBaseAddress() + `CustomerSupport/Owner/Apparatuses/${orgId}`, {
      withCredentials: true
    })
  },
  async clearSearchResults({ getters, commit, dispatch }) {
    commit('clearResults')
  },
  async swapDevice({ commit }, request) {
    return await Axios.put(vueAppApiBaseAddress() + `CustomerSupport/SwapDevice`, request, {
      withCredentials: true
    })
  },
  async removeApparatus({ commit }, request) {
    return await Axios.post(vueAppApiBaseAddress() + `CustomerSupport/Apparatus/Remove`, request, {
      withCredentials: true
    })
  },
  async getDestinationOrg({ commit }, request) {
    return await Axios.get(
      vueAppApiBaseAddress() +
        `CustomerSupport/Transfer/Email?email=${encodeURIComponent(request.email)}&transferType=${request.type}`,
      {
        withCredentials: true
      }
    )
  },
  async completeTransfer({ commit }, request) {
    return await Axios.post(
      vueAppApiBaseAddress() + `CustomerSupport/Transfer/Complete?transfertype=${request.type}`,
      request.body,
      {
        withCredentials: true
      }
    )
  },
  async getNotificationAudit({ commit }, request) {
    return await Axios.get(
      vueAppApiBaseAddress() +
        `CustomerSupport/Notifications?apparatusId=${request.apparatusId}&userId=${request.userId}`,
      {
        withCredentials: true
      }
    )
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
