<template>
  <div id="createAccountDiv">
    <b-row align-h="around" class="h-100" align-v="center" no-gutters>
      <b-col cols="11" md="6" lg="4">
        <b-card
          id="createAccountCard"
          class="mt-4"
          header-bg-variant="white"
          footer-bg-variant="white"
          footer-border-variant="white"
        >
          <template #header>
            <div class="d-flex align-items-center">
              <b-button
                variant="icon-button"
                class="btn-icon-button--gray"
                :class="step == 1 ? 'd-none' : 'mr-2'"
                @click="back"
                data-test-hook="back-button-create-account"
              >
                <i class="far fa-chevron-left"></i>
              </b-button>
              <h2><i class="far fa-user colorOrange mr-2"></i>{{ $t('xAccountSetup') }}</h2>
            </div>
          </template>
          <div v-if="step == 1">
            <b-row>
              <b-col>
                <h4>{{ $t('xOwnerInformation') }}</h4>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group id="email-group" :label="$t('xEmailAddress')" label-for="email">
                  <b-form-input
                    id="email"
                    v-model="newUserEmail"
                    maxlength="70"
                    type="text"
                    :placeholder="$t('xEmailAddress')"
                    :disabled="true"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group id="firstName-group" :label="$t('xFirstName')" label-for="firstName">
                  <b-form-input
                    id="firstName"
                    v-model="$v.firstName.$model"
                    maxlength="30"
                    type="text"
                    :placeholder="$t('xFirstName')"
                    :state="validState($v.firstName)"
                    aria-describedby="firstName-invalid"
                  ></b-form-input>
                  <b-form-invalid-feedback id="firstName-invalid">
                    {{ $t('xRequiredField') }}.
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group id="lastName-group" :label="$t('xLastName')" label-for="lastName">
                  <b-form-input
                    id="lastName"
                    v-model="$v.lastName.$model"
                    maxlength="30"
                    type="text"
                    :placeholder="$t('xLastName')"
                    :state="validState($v.lastName)"
                    aria-describedby="lastName-invalid"
                  ></b-form-input>
                  <b-form-invalid-feedback id="lastName-invalid"> {{ $t('xRequiredField') }}. </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group id="phone-group" :label="$t('xPhoneNumber')" label-for="phone">
                  <b-form-input
                    id="phone"
                    v-model="$v.phone.$model"
                    maxlength="12"
                    type="text"
                    :placeholder="$t('xPhoneNumber')"
                    :state="validState($v.phone)"
                    aria-describedby="phone-invalid"
                  ></b-form-input>
                  <b-form-invalid-feedback id="phone-invalid">
                    {{ $t('xRequiredField') }}. {{ $t('xPleaseEnterAValidPhoneNumber') }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group id="businessName-group" :label="$t('xBusinessName')" label-for="businessName">
                  <b-form-input
                    id="businessName"
                    v-model="businessName"
                    maxlength="125"
                    type="text"
                    :placeholder="$t('xBusinessName')"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
          <div v-else>
            <b-row>
              <b-col>
                <h4>Owner Address</h4>
              </b-col>
            </b-row>
            <TypeAheadAddress
              ref="typeAheadAddressCreateAccount"
              :currentAddress="address"
              @newAddress="setNewAddress"
            />
          </div>
          <template #footer>
            <div class="d-flex mb-2 align-items-center justify-content-center">
              <b-avatar text=" " size="sm" :variant="step == 1 ? 'warning' : 'secondary'"></b-avatar>
              <b-avatar text=" " size="sm" :variant="step == 2 ? 'warning' : 'secondary'" class="ml-1"></b-avatar>
            </div>
            <b-row class="box">
              <b-button
                variant="secondary"
                @click="signOutAccountCreation()"
                data-test-hook="logout-button-create-account"
              >
                {{ $t('xLogout') }}
              </b-button>
              <b-button
                variant="warning"
                @click="next"
                style="justify-content: center;"
                :disabled="saving || (step == 2 && (!address.line1 || !address.postalCode)) || addressSaveDisabled"
                data-test-hook="next-button-create-account"
              >
                {{ $t('xNext') }}
                <span>
                  <i class="far fa-arrow-right"></i>
                </span>
              </b-button>
            </b-row>
          </template>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { required, requiredIf, minLength, maxLength, email, numeric } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'
import TypeAheadAddress from '@/components/TypeAheadAddress'
import * as Constants from '@/constants'
import { vueAppApiBaseAddress } from '@/utils/mixins'

export default {
  components: { TypeAheadAddress },
  mixins: [validationMixin],
  data() {
    return {
      step: 1,
      phone: null,
      firstName: null,
      lastName: null,
      businessName: null,
      manualEntry: false,
      freeformAddress: null,
      addressResults: null,
      awaitingSearch: false,
      longitude: null,
      latitude: null,
      address: {
        line1: null,
        line2: null,
        city: null,
        region: null,
        country: null,
        postalCode: null
      },
      saving: false,
      timeout: null,
      addressSaveDisabled: false,
      states: null,
      countries: null
    }
  },
  validations: {
    phone: {
      required,
      minLength: minLength(10),
      maxLength: maxLength(12),
      numeric,
      startsWithZero(phoneNumber) {
        const regex = /^[1-9]{1}[0-9]{9,11}$/
        return regex.test(phoneNumber)
      }
    },
    firstName: {
      required
    },
    lastName: {
      required
    },
    address: {
      line1: {
        required
      },
      city: {
        required
      },
      country: {
        required
      },
      postalCode: {
        required
      }
    }
  },
  computed: {
    ...mapState({
      newUserEmail: state => state.account.newUserEmail,
      countryStates: state => state.application.countryStates,
      isImpersonating: state => state.auth.isImpersonating
    })
  },
  methods: {
    setNewAddress(newAddress) {
      this.address = newAddress
      if (this.$refs.typeAheadAddressCreateAccount.$v.$anyError) {
        this.addressSaveDisabled = true
      } else {
        this.addressSaveDisabled = false
      }
    },
    next() {
      if (this.step == 1) {
        this.$v.phone.$touch()
        this.$v.firstName.$touch()
        this.$v.lastName.$touch()
        if (!this.$v.$anyError) {
          this.step++
        }
      } else {
        this.$v.$touch()
        this.$refs.typeAheadAddressCreateAccount.$v.$touch()
        if (!this.$v.$anyError && !this.$refs.typeAheadAddressCreateAccount.$v.$anyError) {
          this.saving = true
          this.$store
            .dispatch('createNewAccount', {
              firstName: this.firstName,
              lastName: this.lastName,
              email: this.newUserEmail,
              phoneNumber: this.phone,
              address: this.address
            })
            .then(response => {
              if (!this.isImpersonating) this.$store.dispatch('getUser')
              else this.$store.dispatch('getImpersonatedUser')
              this.$store.dispatch('getAccountProfile')
            })
            .catch(error => {
              this.$store.dispatch('displayToast', {
                title: this.$t('xError'),
                message: this.$t('xErrorCreatingAccount'),
                variant: 'danger'
              })
            })
            .finally(() => {
              this.saving = false
            })
        }
      }
    },
    back() {
      if (!this.saving) {
        this.$v.$reset()
        this.step--
      }
    },
    hideResults() {
      this.addressResults = null
    },
    showPosition(position) {
      this.longitude = position.coords.longitude
      this.latitude = position.coords.latitude
    },
    setCurrentAddress(newAddress) {
      this.freeformAddress = null
      this.address.line1 =
        (newAddress.address.streetNumber != null ? newAddress.address.streetNumber + ' ' : '') +
        newAddress.address.streetName
      this.address.line2 = null
      this.address.city = newAddress.address.municipality
      this.address.region = newAddress.address.countrySubdivision
        ? newAddress.address.countrySubdivision
        : newAddress.address.countryCode
      this.address.country = newAddress.address.countryCode
      this.address.postalCode = newAddress.address.postalCode
      this.addressResults = null
    }
  },
  watch: {
    'address.country': function(newVal, oldVal) {
      if (oldVal && newVal != oldVal) {
        if (
          this.states &&
          this.states[newVal] &&
          this.states[newVal].countryRegions &&
          this.states[newVal].countryRegions.length > 0
        ) {
          this.address.region = this.states[newVal][0].code
        } else this.address.region = null
      }
    },
    newUserEmail: function(newVal, oldVal) {
      if (!newVal) this.$store.dispatch('getUser')
    }
  },
  created() {
    let cookie = this.$cookies.get(process.env.VUE_APP_MOBILE_LINK_COOKIE)
    if (cookie != null) {
      if (cookie.hasOwnProperty('given_name')) {
        this.firstName = cookie.given_name
      }
      if (cookie.hasOwnProperty('family_name')) {
        this.lastName = cookie.family_name
      }
      if (cookie.hasOwnProperty('phone_number')) {
        this.phone = cookie.phone_number
      }
      if (cookie.hasOwnProperty('address')) {
        let cookieAddress = JSON.parse(cookie.address)
        this.address.line1 = cookieAddress.street_address
        this.address.city = cookieAddress.locality
        this.address.region = cookieAddress.region
        this.address.postalCode = cookieAddress.postal_code
        this.addressResults = null
      }
    }

    this.$store.dispatch('getAccountInfo').then(response => {
      if (response && response?.data) {
        let info = response.data
        this.firstName ??= info.ownerFirstName
        this.lastName ??= info.ownerLastName
        this.phone ??= info.ownerPhoneNumber
        this.address.line1 ??= info.ownerAddress.line1
        this.address.line2 ??= info.ownerAddress.line2
        this.address.city ??= info.ownerAddress.city
        this.address.region ??= info.ownerAddress.region
        this.address.country ??= info.ownerAddress.country
        this.address.postalCode ??= info.ownerAddress.postalCode
        this.addressResults = null
      }
    })

    if (!this.signInName) {
      this.$store.dispatch('getAccountProfile')
    }
  },
  mounted() {
    let userEmail = window.localStorage.getItem(Constants.UserEmailLocalStorageKey)
    if (userEmail && userEmail != this.newUserEmail && this.newUserEmail) {
      this.$store.dispatch('clearAll')
      window.localStorage.setItem(Constants.ForceSignOutErrorLocalStorageKey, this.$t('xForceSignoutErrorMessage'))
      window.location.replace(vueAppApiBaseAddress() + `Auth/SignOut`)
    }
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(this.showPosition)
    }
    if (!this.countryStates) {
      this.$store.dispatch('getCountrySubdivisions')
    } else {
      this.states = _.cloneDeep(this.countryStates.value)
      this.countries = []
      this.countryStates.countryCodes.forEach(element => {
        this.countries.push({ value: element, text: this.countryStates.value[element].countryName })
      })
    }
  }
}
</script>

<style scoped>
#createAccountDiv {
  min-height: 100vh;
  background: var(--blue-900);
  margin-left: -15px;
  margin-right: -15px;
}

#createAccountCard {
  z-index: 100;
  min-height: 80vh;
}

h2 {
  margin-bottom: 0;
  line-height: 1.5;
  font-weight: normal;
}

.b-avatar-sm {
  width: 0.8rem;
  height: 0.8rem;
}

.box {
  display: flex;
  gap: 12px;
}

.box > * {
  flex: 1 1 0;
}
</style>
