import Axios from 'axios'
import router from '@/router'
import { vueAppApiBaseAddress } from '@/utils/mixins'

var initialState = {}

export const state = {}

export const getters = {}

export const mutations = {}

export const actions = {
  async SearchDealers({ commit }, request) {
    return await Axios.post(vueAppApiBaseAddress() + `v1/Dealer/search`, request, {
      withCredentials: true
    })
  },
  async searchDealersByName({ commit }, request) {
    return await Axios.get(
      vueAppApiBaseAddress() +
        `v2/Dealer/name/search/${request.name}?pageNumber=${request.pageNumber}&pageSize=${request.pageSize}`,
      {
        withCredentials: true
      }
    )
  },
  async setPreferredDealer({ commit }, request) {
    return await Axios.put(
      vueAppApiBaseAddress() + `v1/Dealer/preferred/set/${request.apparatusId}/${request.dealerId}`,
      {},
      {
        withCredentials: true
      }
    )
  },
  async getPreferredDealer({ commit }, apparatusId) {
    return await Axios.get(vueAppApiBaseAddress() + `v2/Dealer/preferred/${apparatusId}`, {
      withCredentials: true
    })
  },
  async deletePreferredDealer({ commit }, request) {
    return await Axios.delete(
      vueAppApiBaseAddress() + `v1/Dealer/preferred/delete/${request.apparatusId}/${request.dealerOrgId}`,
      {
        withCredentials: true
      }
    )
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
