var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.manuals && !_vm.loaded
    ? _c("div", { staticClass: "mb-3" }, [
        _c("div", { staticClass: "row text-center" }, [
          _c(
            "div",
            {
              staticClass: "col",
              attrs: { "data-test-hook": "manualsLoadingMsg" }
            },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t("xYourManualsAreLoadingThankyouForYourPatience")
                  ) +
                  " "
              )
            ]
          )
        ]),
        _vm._m(0)
      ])
    : !_vm.manuals && _vm.loaded
    ? _c("div", [
        _c("span", { attrs: { "data-test-hook": "noManualsMsg" } }, [
          _vm._v(_vm._s(_vm.$t("xNoProductManuals")))
        ])
      ])
    : _c("div", [
        _c(
          "table",
          {
            staticClass: "table table-striped table-hover search-table",
            attrs: {
              "head-variant": "light",
              items: _vm.manuals,
              stacked: "md"
            }
          },
          [
            _c("thead", [
              _c("tr", [
                _c("th", { attrs: { "data-test-hook": "manualTypeHeader" } }, [
                  _vm._v(_vm._s(_vm.$t("xManualType")))
                ]),
                _c("th", { attrs: { "data-test-hook": "manualDescHeader" } }, [
                  _vm._v(_vm._s(_vm.$t("xManualPdf")))
                ])
              ])
            ]),
            _c(
              "tbody",
              _vm._l(_vm.manuals, function(manual, index) {
                return _c("tr", { key: manual.id }, [
                  _c(
                    "td",
                    { attrs: { "data-test-hook": "manualType" + index } },
                    [_vm._v(_vm._s(manual.description))]
                  ),
                  _c(
                    "td",
                    {
                      staticClass: "font-weight-bold",
                      attrs: { "data-test-hook": "manualDesc" + index }
                    },
                    [
                      _c("a", { attrs: { href: manual.uri } }, [
                        _c("i", { staticClass: "fas fa-download mr2" }),
                        _vm._v(" " + _vm._s(manual.name))
                      ])
                    ]
                  )
                ])
              }),
              0
            )
          ]
        )
      ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row mt-3 text-center" }, [
      _c("div", { staticClass: "col" }, [
        _c("span", {
          staticClass: "spinner-border text-warning",
          staticStyle: { width: "5rem", height: "5rem" }
        })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }