<template>
  <div>
    <h1>Owner Information: {{ value.organizationId }}</h1>
    <div class="mb-3">
      <p>{{ 'Name' | formatLabelNoTranslation }}</p>
      <div class="d-flex align-items-center">
        <p class="font-weight-bold">
          {{ value.ownerInformation.firstName + ' ' + value.ownerInformation.lastName }}
        </p>
        <button
          class="btn btn-light btn-sm ml-2"
          @click="copyToClipboard(value.ownerInformation.firstName + ' ' + value.ownerInformation.lastName)"
          data-test-hook="copy-name-button-owner-info-tab"
        >
          <i class="far fa-copy colorOrange" />
        </button>
      </div>
    </div>
    <div class="mb-3">
      <p>{{ 'Phone Number' | formatLabelNoTranslation }}</p>
      <p class="font-weight-bold pb-2">{{ value.ownerInformation.phones[0] }}</p>
    </div>
    <div class="mb-3">
      <p>{{ 'Email Address' | formatLabelNoTranslation }}</p>
      <div class="d-flex align-items-center">
        <p class="font-weight-bold">{{ value.ownerInformation.emails[0] }}</p>
        <button
          variant="light"
          size="sm"
          class="btn btn-light btn-sm ml-2"
          @click="copyToClipboard(value.ownerInformation.emails[0])"
          data-test-hook="copy-email-button-owner-info-tab"
        >
          <i class="far fa-copy colorOrange" />
        </button>
      </div>
    </div>
    <div class="mb-3">
      <p>{{ 'Owner Address' | formatLabelNoTranslation }}</p>
      <div class="d-flex align-items-center">
        <p class="font-weight-bold">{{ DisplayAddress(value.ownerAddress) }}</p>
        <button
          variant="light"
          size="sm"
          class="btn btn-light btn-sm ml-2"
          @click="copyToClipboard(DisplayAddress(value.ownerAddress))"
          data-test-hook="copy-address-button-owner-info-tab"
        >
          <i class="far fa-copy colorOrange" />
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['value']
}
</script>
