import { ApplicationInsights } from '@microsoft/applicationinsights-web'

function initializeAppInsights(Vue, options) {
  const appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: options.key
    }
  })
  appInsights.loadAppInsights()

  // This will add/apply the common data about the user to ALL app insights messages to help with tracking/logging ability
  appInsights.addTelemetryInitializer(envelope => {
    let currentUser = options.store.state.account.user
    if (currentUser) {
      let propertyData = envelope.data || {}

      if (!propertyData.hasOwnProperty('userId')) {
        propertyData['userId'] = currentUser.userId
      }

      if (!propertyData.hasOwnProperty('userName')) {
        propertyData['userName'] = currentUser.emails[0]
      }

      envelope.data = propertyData
    }
  })

  // This will cause the sampling to be applied to everything EXCEPT the "Event" type of logs
  appInsights.addTelemetryInitializer(envelope => {
    if (envelope.baseType == 'EventData') {
      envelope.sampleRate = 100 // This will disable sampling
    }
  })

  const router = options.router

  router.beforeEach((route, from, next) => {
    const name = route.name
    appInsights.startTrackPage(name)
    next()
  })

  router.afterEach(route => {
    const name = route.name
    const url = location.protocol + '//' + location.host + route.fullPath
    appInsights.stopTrackPage(name, url)
  })

  Object.defineProperty(Vue.prototype, '$appInsights', {
    get: () => appInsights
  })
}

export default initializeAppInsights
