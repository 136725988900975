<template>
  <div class="mt-2 ml-2">
    <div class="row no-gutters mb-2">
      <div class="col">
        <h1 class="mb-0 pb-0" data-test-hook="PageTitle">
          <i class="far fa-tank-water colorOrange mr-1"></i>
          {{ $t('xTankSettings') }}
        </h1>
      </div>
    </div>

    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col" v-if="tankFuelType">
            <div class="row">
              <div class="col tile-label" data-test-hook="tank-details-fuel-type-label">
                {{ $t('xFuelType') }}
              </div>
            </div>
            <div class="row">
              <div class="col tile-content" v-if="tankFuelType == PROPANE">
                <strong :data-test-hook="'tank-details-fuel-type-' + $t('xPropane')">{{ $t('xPropane') }}</strong>
              </div>
              <div class="col tile-content" v-else>
                <strong :data-test-hook="'tank-details-fuel-type-' + $t('xOil')">{{ $t('xOil') }}</strong>
              </div>
            </div>
          </div>
          <div class="cols-auto float-right">
            <button
              type="button"
              class="btn btn-icon-button"
              @click="openEditModal()"
              data-test-hook="tank-details-edit-details-button"
            >
              <i class="fas fa-pencil" data-test-hook="tank-details-edit-details-button-icon" />
            </button>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col">
            <div class="row">
              <div class="col tile-label" data-test-hook="tank-details-tank-orientation-label">
                {{ $t('xTankOrientation') }}
              </div>
            </div>
            <div class="row align-items-center">
              <div class="col tile-content">
                <strong :data-test-hook="'tank-details-tank-orientation-' + tankOrientation">
                  {{ tankOrientation }}
                </strong>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col">
            <div class="row">
              <div class="col tile-label" data-test-hook="tank-details-capacity-label">
                {{ $t('xTankCapacity') }}
              </div>
            </div>
            <div class="row">
              <div class="col tile-content" v-if="tankCapacity">
                <strong :data-test-hook="'tank-details-capacity-' + tankCapacity">
                  {{ convertedTankCapacity }} {{ this.abbreviatedLiquidUom }}
                </strong>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Modal modalId="edit-tank-details" size="modal-lg">
      <ModalHeader :title="$t('xEditTankSettings')" icon="fas fa-pen colorOrange mr-1" data-test-hook="ModalTitle">
        <div class="cols-auto">
          <button
            type="button"
            class="btn btn-icon-button btn-icon-button--gray"
            @click="closeModal('edit-tank-details')"
            data-test-hook="edit-tank-details-modal-close-button"
          >
            <i class="fas fa-times" data-test-hook="edit-tank-details-modal-close-button-icon" />
          </button>
        </div>
      </ModalHeader>
      <ModalBody>
        <div class="row">
          <div class="col" data-test-hook="edit-tank-details-modal-fuel-type-label">
            <p>{{ $t('xFuelType') }}</p>
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-12">
            <div role="group" class="btn-group d-flex">
              <button
                type="button"
                class="w-100 btn btn-tank-outline"
                @click=";(userSelectedFuelType = PROPANE), (userSelectedOrientation = null), setCapacities()"
                :class="userSelectedFuelType == PROPANE ? 'active' : ''"
                data-test-hook="edit-tank-details-modal-fuel-type-propane-button"
              >
                {{ $t('xPropane') }}
              </button>
              <button
                type="button"
                class="w-100 btn btn-tank-outline"
                @click=";(userSelectedFuelType = HEATINGOIL), (userSelectedOrientation = HORIZONTAL), setCapacities()"
                :class="userSelectedFuelType == HEATINGOIL ? 'active' : ''"
                data-test-hook="edit-tank-details-modal-fuel-type-oil-button"
              >
                {{ $t('xOil') }}
              </button>
            </div>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col" data-test-hook="edit-tank-details-modal-tank-orientation-label">
            <p>{{ $t('xTankOrientation') }}</p>
          </div>
        </div>
        <div class="row mt-1">
          <div class="col">
            <button
              type="button"
              style="height: 80px;"
              @click=";(userSelectedOrientation = VERTICAL), setCapacities()"
              class="btn py-0 mx-0 btn-tank-outline btn-block"
              :disabled="userSelectedFuelType == HEATINGOIL"
              :class="userSelectedOrientation == VERTICAL ? 'active' : ''"
              data-test-hook="edit-tank-details-modal-vertical-orientation-button"
            >
              <div class="row align-items-center">
                <div class="col-5" />
                <div class="col-auto">
                  <img
                    v-if="userSelectedOrientation == VERTICAL"
                    height="60px"
                    :src="require('@/assets/images/tank-vertical-selected.svg')"
                    data-test-hook="edit-tank-details-modal-vertical-orientation-button-image"
                  />
                  <img
                    v-if="
                      userSelectedOrientation != VERTICAL &&
                        (userSelectedFuelType == PROPANE || userSelectedFuelType == null)
                    "
                    height="60px"
                    :src="require('@/assets/images/tank-vertical-unselected.svg')"
                    data-test-hook="edit-tank-details-modal-vertical-orientation-button-image"
                  />
                  <img
                    v-if="userSelectedOrientation == HEATINGOIL"
                    height="60px"
                    :src="require('@/assets/images/tank-vertical-disabled.svg')"
                    data-test-hook="edit-tank-details-modal-vertical-orientation-button-image"
                  />
                </div>
                <div class="col-auto" data-test-hook="edit-tank-details-modal-vertical-orientation-button-text">
                  {{ $t('xVertical') }}
                </div>
                <div />
              </div>
            </button>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col">
            <button
              type="button"
              style="height: 80px;"
              @click=";(userSelectedOrientation = HORIZONTAL), setCapacities()"
              class="py-0 mx-0 btn btn-tank-outline btn-block"
              :class="userSelectedFuelType == HEATINGOIL || userSelectedOrientation == HORIZONTAL ? 'active' : ''"
              data-test-hook="edit-tank-details-modal-horizontal-orientation-button"
            >
              <div class="row align-items-center">
                <div class="col-4" />
                <div class="col-auto">
                  <img
                    v-if="userSelectedOrientation == HORIZONTAL"
                    height="50px"
                    :src="require('@/assets/images/tank-horizontal-selected.svg')"
                    data-test-hook="edit-tank-details-modal-horizontal-orientation-button-image"
                  />
                  <img
                    v-else
                    height="50px"
                    :src="require('@/assets/images/tank-horizontal-unselected.svg')"
                    data-test-hook="edit-tank-details-modal-horizontal-orientation-button-image"
                  />
                </div>
                <div class="col-auto" data-test-hook="edit-tank-details-modal-horizontal-orientation-button-text">
                  {{ $t('xHorizontal') }}
                </div>
              </div>
            </button>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col" data-test-hook="edit-tank-details-modal-fuel-capacity-label">
            {{ $t('xFuelCapacity') }}
          </div>
        </div>
        <div class="row mt-1">
          <div class="col">
            <fieldset class="form-group">
              <select
                class="custom-select"
                v-model="userSelectedCapacity"
                :placeHolder="$t('xSelectCapacity')"
                data-test-hook="edit-tank-details-modal-fuel-capacity-field"
              >
                <option
                  v-for="(capacity, index) in currentTankCapacities"
                  :key="index"
                  :value="capacity.value"
                  :data-test-hook="`${userSelectedFuelType}-${userSelectedOrientation}-${capacity.value}`"
                >
                  {{ capacity.text }}
                </option>
              </select>
            </fieldset>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <button
          type="button"
          class="btn btn-outline-warning"
          @click="closeModal('edit-tank-details')"
          data-test-hook="edit-tank-details-modal-cancel-button"
        >
          {{ $t('xCancel') }}
        </button>
        <button
          type="button"
          class="btn btn-warning"
          :disabled="!userCanSave() || saving"
          @click="updateTank()"
          data-test-hook="edit-tank-details-modal-save-button"
        >
          <span v-if="!saving">{{ $t('xSave') }}</span>
          <span v-else>
            <span class="spinner-border" />
          </span>
        </button>
      </ModalFooter>
    </Modal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import * as Constants from '@/constants'
import unitMixin from '@/utils/unitMixin'

export default {
  mixins: [unitMixin],
  data() {
    return {
      capacities: [],
      currentTankCapacities: [],
      userSelectedCapacity: null,
      userSelectedOrientation: null,
      userSelectedFuelType: null,
      saving: false,
      gettingNewValues: false
    }
  },
  computed: {
    ...mapState({
      currentApparatusDetails: state => state.apparatus.currentApparatusDetails,
      accountProfile: state => state.account.accountProfile
    }),
    convertedTankCapacity() {
      return this.convertGallonsToPreferredUnit(this.tankCapacity)
    },
    tankFuelType() {
      let fType = this.getPropertyValue(0)
      fType = fType.toLowerCase()
      if (fType) {
        if (fType == Constants.HeatingOilSnmpValue || fType == Constants.TuPropertiesOilValue) {
          return this.HEATINGOIL
        } else fType = fType.charAt(0).toUpperCase() + fType.slice(1)
      }
      return fType
    },
    tankCapacity() {
      return this.getPropertyValue(1)
    },
    tankOrientation() {
      let orientation = this.getPropertyValue(2)
      orientation = orientation.toLowerCase()
      if (orientation) {
        orientation = orientation.charAt(0).toUpperCase() + orientation.slice(1)
      }
      return orientation
    }
  },
  methods: {
    openEditModal() {
      this.userSelectedOrientation = this.tankOrientation
      this.userSelectedFuelType = this.tankFuelType
      this.userSelectedCapacity = this.tankCapacity ? this.tankCapacity : null
      this.setCapacities()
      this.openModal('edit-tank-details')
    },
    userCanSave() {
      if (this.gettingNewValues) return false
      else if (this.userSelectedCapacity && this.userSelectedOrientation && this.userSelectedFuelType) {
        if (
          this.userSelectedCapacity != this.tankCapacity ||
          this.userSelectedOrientation != this.tankOrientation ||
          this.userSelectedFuelType != this.tankFuelType
        ) {
          return true
        }
      }
      return false
    },
    getPropertyValue(type) {
      if (this.currentApparatusDetails && this.currentApparatusDetails.tuProperties) {
        let property = this.currentApparatusDetails.tuProperties.find(p => p.type == type)
        return property != null ? property.value : null
      }
      return null
    },
    updateTank() {
      this.saving = true
      let newCapacity = this.userSelectedCapacity == this.currentCapacity ? null : this.userSelectedCapacity
      let newFuelType = this.userSelectedFuelType == this.fuelType ? null : this.userSelectedFuelType
      let newOrientation = this.userSelectedOrientation == this.orientation ? null : this.userSelectedOrientation
      let request = {
        apparatusId: this.$route.params.apparatusId,
        fuelTankCapacity: newCapacity,
        fuelTankFuelType: newFuelType,
        fuelTankOrientation: newOrientation
      }
      this.$store
        .dispatch('updateTankUtilityApparatus', request)
        .then(response => {
          this.$store.dispatch('displayToast', {
            title: this.$t('xSuccess'),
            message: this.$t('xTankDetailsUpdated'),
            variant: 'success'
          })
          this.gettingNewValues = true
          setTimeout(
            function() {
              this.$store.dispatch('getApparatusDetails', this.$route.params.apparatusId)
              this.gettingNewValues = false
              this.$router.push({ name: 'deviceDetails' })
            }.bind(this),
            2000
          )
        })
        .catch(error => {
          this.$store.dispatch('displayToast', {
            title: this.$t('xError'),
            message: this.$t('xUnableToSaveChanges'),
            variant: 'danger'
          })
        })
        .finally(() => {
          this.saving = false
        })
    },
    loadTankCapacities() {
      this.$store
        .dispatch('getAllCapacities')
        .then(response => {
          if (response.status == 200) {
            // Convert data to display liters, if that's the user's preference
            this.capacities = response.data
            if (this.liquidUom === 'liters') {
              this.capacities = this.capacities.map(obj => ({
                ...obj,
                orientations: obj.orientations.map(orientation => ({
                  ...orientation,
                  sizes: orientation.sizes.map(size => ({
                    ...size,
                    text: this.convertCapacityLabel(size.text)
                  }))
                }))
              }))
            }
            this.setCapacities()
          }
        })
        .catch(error => {
          this.$store.dispatch('displayToast', {
            title: this.$t('xError'),
            message: error.response.data.message,
            variant: 'danger'
          })
        })
    },
    setCapacities() {
      if (this.userSelectedFuelType && this.userSelectedOrientation) {
        let capsByFuelType = this.capacities.filter(o => o.type == this.userSelectedFuelType)
        if (capsByFuelType) {
          let sizes = []
          capsByFuelType.forEach(element => {
            if (element && element.orientations) {
              element.orientations.forEach(x => {
                if (x && x.direction && x.direction == this.userSelectedOrientation) {
                  if (x.sizes) sizes = x.sizes
                }
              })
            }
          })
          this.currentTankCapacities = sizes
        }
      }
    }
  },
  watch: {
    currentApparatusDetails: function(newVal, oldVal) {
      if (newVal) {
        this.userSelectedOrientation = this.tankOrientation
        this.userSelectedFuelType = this.tankFuelType
        this.userSelectedCapacity = this.tankCapacity ? this.tankCapacity : null
      }
    },
    userSelectedFuelType: function(newVal, oldVal) {
      if (newVal && newVal != this.tankFuelType) {
        this.userSelectedCapacity = null
      }
    },
    userSelectedOrientation: function(newVal, oldVal) {
      if (newVal && newVal != this.tankOrientation) {
        this.userSelectedCapacity = null
      }
    }
  },
  created() {
    this.VERTICAL = 'Vertical'
    this.HORIZONTAL = 'Horizontal'
    this.PROPANE = 'Propane'
    this.HEATINGOIL = 'HeatingOil'
  },
  mounted() {
    if (this.currentApparatusDetails) {
      this.loadTankCapacities()
    }
  }
}
</script>
