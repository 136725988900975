var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "card main-card mt-2" },
    [
      _c("div", { staticClass: "card-header" }, [
        _c("ul", { staticClass: "nav nav-tabs card-header-tabs nav-fill" }, [
          _c(
            "li",
            {
              staticClass: "nav-item hoverPointer",
              attrs: { "data-test-hook": "owner-info-tab-email-search-result" },
              on: {
                click: function($event) {
                  _vm.tabIndex = 0
                }
              }
            },
            [
              _c("a", { staticClass: "nav-link", class: _vm.isActive(0) }, [
                _vm._v("Owner Information")
              ])
            ]
          ),
          _c(
            "li",
            {
              staticClass: "nav-item hoverPointer",
              attrs: { "data-test-hook": "generators-tab-email-search-result" },
              on: {
                click: function($event) {
                  _vm.tabIndex = 1
                }
              }
            },
            [
              _c("a", { staticClass: "nav-link", class: _vm.isActive(1) }, [
                !_vm.orgApparatuses
                  ? _c("span", {
                      staticClass: "spinner-border spinner-border-sm"
                    })
                  : _vm._e(),
                _vm._v("Generators")
              ])
            ]
          ),
          _c(
            "li",
            {
              staticClass: "nav-item hoverPointer",
              attrs: {
                "data-test-hook": "notifications-tab-email-search-result"
              },
              on: {
                click: function($event) {
                  _vm.tabIndex = 2
                }
              }
            },
            [
              _c("a", { staticClass: "nav-link", class: _vm.isActive(2) }, [
                _vm.gettingNotifications
                  ? _c("span", {
                      staticClass: "spinner-border spinner-border-sm"
                    })
                  : _vm._e(),
                _vm._v("Notifications")
              ])
            ]
          )
        ])
      ]),
      _c("div", { staticClass: "card-body" }, [
        _vm.tabIndex == 0
          ? _c(
              "div",
              [_c("OwnerInformationTab", { attrs: { value: _vm.value } })],
              1
            )
          : _vm._e(),
        _vm.tabIndex == 1 && !_vm.apparatusesLoaded
          ? _c("span", {
              staticClass:
                "spinner-border text-primary text-center float-center",
              staticStyle: { width: "10rem", height: "10rem" },
              attrs: { role: "status" }
            })
          : _vm.tabIndex == 1 && _vm.apparatusesLoaded
          ? _c(
              "div",
              [
                _c("GeneratorsTab", {
                  attrs: { orgApparatuses: _vm.orgApparatuses }
                })
              ],
              1
            )
          : _vm.tabIndex == 2
          ? _c(
              "div",
              [
                _vm.gettingNotifications
                  ? _c("span", {
                      staticClass:
                        "spinner-border text-primary text-center float-center",
                      staticStyle: { width: "10rem", height: "10rem" },
                      attrs: { role: "status" }
                    })
                  : _c("NotificationsTab", {
                      attrs: {
                        value: _vm.value,
                        notifications: _vm.notifications
                      }
                    })
              ],
              1
            )
          : _vm._e()
      ]),
      _c("div", { staticClass: "card-footer" }, [
        _c("span", { attrs: { id: "impersonate-email" } }, [
          _c(
            "button",
            {
              staticClass: "btn btn-primary mt-2",
              attrs: {
                type: "button",
                disabled: !_vm.hasAssociatedAccount(_vm.value),
                "data-toggle": "tooltip",
                title: _vm.hasAssociatedAccount(_vm.value)
                  ? ""
                  : "No Owner Account",
                "data-test-hook": "impersonate-button-email-search-result"
              },
              on: {
                click: function($event) {
                  return _vm.ImpersonateEmail()
                }
              }
            },
            [_vm._v(" Impersonate User ")]
          )
        ]),
        _c("span", { attrs: { id: "transfer-account-btn" } }, [
          _c(
            "button",
            {
              staticClass: "btn btn-warning ml-2 mt-2",
              attrs: {
                type: "button",
                "data-toggle": "tooltip",
                title:
                  _vm.apparatusesLoaded && _vm.orgApparatuses.length > 1
                    ? "Unable to transfer multiple generators, open an escalation ticket"
                    : _vm.$t("xTransferAccountButtonPopover"),
                "data-test-hook": "transfer-account-button-email-search-result",
                disabled:
                  (_vm.currentAppConfig &&
                    _vm.currentAppConfig.featureFlags &&
                    _vm.currentAppConfig.featureFlags[
                      _vm.billingMigrationFeatureFlag
                    ]) ||
                  !_vm.apparatusesLoaded ||
                  (_vm.apparatusesLoaded && _vm.orgApparatuses.length > 1)
              },
              on: {
                click: function($event) {
                  return _vm.showDestinationOrgSearchModal()
                }
              }
            },
            [_vm._v(" Update Account Email ")]
          )
        ])
      ]),
      _c("TransferAccountModal", {
        attrs: {
          source: _vm.value,
          transferType: _vm.transferType,
          apparatuses: _vm.orgApparatuses
        },
        on: { transferEmission: _vm.TransferCompleteEmission }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }