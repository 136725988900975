import '@/utils/filters'

import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import App from './App.vue'
import router from './router/'
import store from './store/'
import PortalVue from 'portal-vue'
import VueCookies from 'vue-cookies'
import i18n from './lang/'
import _ from 'lodash'
import AppInsights from '@/utils/appInsights'
import Vuelidate from 'vuelidate'
import Axios from 'axios'
import vueMoment from 'vue-moment'
import moment from 'moment-timezone'
import Clipboard from 'v-clipboard'
import { Service } from 'axios-middleware'
import AxiosMiddleWare from '@/utils/axiosMiddleWare'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import vClickOutside from 'v-click-outside'
import VueMask from 'v-mask'
import Popper from 'vue-popperjs'
import 'vue-popperjs/dist/vue-popper.css'
import '@/assets/styles/app.scss'
import Modal from '../src/modules/modals/components/Modal.vue'
import ModalHeader from '../src/modules/modals/components/ModalHeader.vue'
import ModalFooter from '../src/modules/modals/components/ModalFooter.vue'
import ModalBody from '../src/modules/modals/components/ModalBody.vue'
require('@fortawesome/fontawesome-pro/css/all.css')

Vue.component('font-awesome-icon', FontAwesomeIcon)
Object.defineProperty(Vue.prototype, '_', { value: _ })

Vue.config.productionTip = false

export const service = new Service(Axios)
service.register([new AxiosMiddleWare(store)])

Vue.use(VueMask)
Vue.use(vClickOutside)
Vue.use(BootstrapVue)
Vue.use(PortalVue)
Vue.use(vueMoment, {
  moment
})
Vue.use(Vuelidate)
Vue.use(Clipboard)
Vue.use(AppInsights, {
  key: process.env.VUE_APP_INSIGHTS_KEY,
  router,
  store
})
Vue.use(VueCookies)
Vue.component('popper', Popper)
Vue.component('Modal', Modal)
Vue.component('ModalHeader', ModalHeader)
Vue.component('ModalFooter', ModalFooter)
Vue.component('ModalBody', ModalBody)

export const app = new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
