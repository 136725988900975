var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.source && _vm.source.organizationId
    ? _c(
        "div",
        [
          _c(
            "Modal",
            {
              attrs: {
                modalId: "destination-org-search" + _vm.source.organizationId,
                size: "modal-lg"
              }
            },
            [
              _c("ModalHeader", {
                attrs: {
                  title: "Find Destination Email",
                  icon: "fad fa-exchange-alt"
                }
              }),
              _c("ModalBody", [
                !_vm.destinationOwner && !_vm.showSearchError
                  ? _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col" }, [
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col" }, [
                            _c("fieldset", { staticClass: "form-group" }, [
                              _c(
                                "legend",
                                {
                                  staticClass:
                                    "bv-no-focus-ring col-form-label pt-0"
                                },
                                [_vm._v(" User Name (Email Address) ")]
                              ),
                              _c("div", [
                                _c(
                                  "div",
                                  {
                                    staticClass: "input-group",
                                    attrs: { role: "group" }
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.emailToSearch,
                                          expression: "emailToSearch"
                                        }
                                      ],
                                      staticClass: "form-control",
                                      attrs: { type: "text", maxlength: "50" },
                                      domProps: { value: _vm.emailToSearch },
                                      on: {
                                        keyup: function($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "enter",
                                              13,
                                              $event.key,
                                              "Enter"
                                            )
                                          )
                                            return null
                                          return _vm.getDestinationOrg.apply(
                                            null,
                                            arguments
                                          )
                                        },
                                        input: function($event) {
                                          if ($event.target.composing) return
                                          _vm.emailToSearch =
                                            $event.target.value
                                        }
                                      }
                                    })
                                  ]
                                )
                              ])
                            ])
                          ])
                        ]),
                        _c("div", { staticClass: "row align-items-center" }, [
                          _c("div", { staticClass: "col" }, [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-warning btn-block",
                                attrs: {
                                  type: "button",
                                  disabled:
                                    !_vm.emailToSearch || _vm.searchingForOwner,
                                  "data-test-hook":
                                    "search-button-destinationOrgSearch-transfer-acct-modal"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.getDestinationOrg()
                                  }
                                }
                              },
                              [
                                _c("i", { staticClass: "fas fa-search" }),
                                _vm._v(" Search "),
                                _vm.searchingForOwner
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "spinner-border text-warning ml-2",
                                        attrs: { role: "status" }
                                      },
                                      [
                                        _c("span", { staticClass: "sr-only" }, [
                                          _vm._v("Loading...")
                                        ])
                                      ]
                                    )
                                  : _vm._e()
                              ]
                            )
                          ])
                        ]),
                        _c("ul", { staticClass: "mt-3" }, [
                          _c("li", [
                            _vm._v(
                              "Use this feature when the homeowner requests an email change"
                            )
                          ]),
                          _c("li", [
                            _vm._v(
                              "If there is a new homeowner use the Homeowner Change button"
                            )
                          ]),
                          _c("li", [
                            _vm._v(
                              " The owner must have a new Mobile Link account to move the generator to (shown as the destination email) "
                            )
                          ]),
                          _c("li", [
                            _vm._v(
                              " This process will overwrite all current source owner information (name, address, phone, and email) with the destination owner information "
                            )
                          ]),
                          _c("li", [
                            _vm._v(
                              " This process will update the email address for ALL generators to the destination owner email address "
                            )
                          ]),
                          _c("li", [
                            _vm._v(
                              " The billing account information will remain intact - DO NOT USE THIS OPTION IF THE BILLING INFO SHOULD BE CHANGING "
                            )
                          ])
                        ])
                      ])
                    ])
                  : _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col" }, [
                        _vm._v(" " + _vm._s(_vm.searchErrorMessage) + " ")
                      ])
                    ])
              ]),
              _c("ModalFooter", [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-outline-warning btn-block",
                    attrs: {
                      type: "button",
                      "data-test-hook":
                        "cancel-button-destinationOrgSearch-transfer-acct-modal"
                    },
                    on: {
                      click: function($event) {
                        return _vm.resetTransferModals()
                      }
                    }
                  },
                  [_vm._v(" Cancel ")]
                )
              ])
            ],
            1
          ),
          _c(
            "Modal",
            {
              attrs: {
                modalId: "verify-gen-transfer" + _vm.source.organizationId,
                size: "modal-xl"
              }
            },
            [
              _c("ModalHeader", {
                attrs: {
                  title: "Transfer Account",
                  icon: "fad fa-exchange-alt"
                }
              }),
              _c("ModalBody", [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col" }, [
                    _vm.apparatuses && _vm.apparatuses[0]
                      ? _c("div", { staticClass: "card shadow" }, [
                          _c("div", { staticClass: "card-body" }, [
                            _vm.apparatuses.length == 1
                              ? _c("div", [
                                  _c("div", { staticClass: "card-title" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("formatLabelNoTranslation")(
                                          "Generator"
                                        )
                                      )
                                    )
                                  ]),
                                  _c("p", { staticClass: "mt-2 tile-label" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("formatLabelNoTranslation")(
                                          "Generator Name"
                                        )
                                      )
                                    )
                                  ]),
                                  _c("p", { staticClass: "tile-content" }, [
                                    _vm._v(
                                      _vm._s(_vm.apparatuses[0].apparatus.name)
                                    )
                                  ]),
                                  _c("p", { staticClass: "mt-2 tile-label" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("formatLabelNoTranslation")(
                                          "Serial Number"
                                        )
                                      )
                                    )
                                  ]),
                                  _c("p", { staticClass: "tile-content" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.apparatuses[0].apparatus
                                            .serialNumber
                                        ) +
                                        " "
                                    ),
                                    _c(
                                      "button",
                                      {
                                        directives: [
                                          {
                                            name: "clipboard",
                                            rawName: "v-clipboard",
                                            value:
                                              _vm.apparatuses[0].apparatus
                                                .serialNumber,
                                            expression:
                                              "apparatuses[0].apparatus.serialNumber"
                                          }
                                        ],
                                        staticClass: "btn btn-link",
                                        attrs: { type: "button" }
                                      },
                                      [_c("i", { staticClass: "far fa-copy" })]
                                    )
                                  ]),
                                  _c("p", { staticClass: "mt-2 tile-label" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("formatLabelNoTranslation")(
                                          "Device ID"
                                        )
                                      )
                                    )
                                  ]),
                                  _c("p", { staticClass: "tile-content" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.apparatuses[0].devices[0].id
                                        ) +
                                        " "
                                    ),
                                    _c(
                                      "button",
                                      {
                                        directives: [
                                          {
                                            name: "clipboard",
                                            rawName: "v-clipboard",
                                            value:
                                              _vm.apparatuses[0].devices[0].id,
                                            expression:
                                              "apparatuses[0].devices[0].id"
                                          }
                                        ],
                                        staticClass: "btn btn-link",
                                        attrs: { type: "button" }
                                      },
                                      [_c("i", { staticClass: "far fa-copy" })]
                                    )
                                  ]),
                                  _c("p", { staticClass: "mt-2 tile-label" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("formatLabelNoTranslation")(
                                          "Device Type"
                                        )
                                      )
                                    )
                                  ]),
                                  _c("p", { staticClass: "tile-content" }, [
                                    _vm._v(
                                      _vm._s(_vm.apparatuses[0].devices[0].type)
                                    )
                                  ]),
                                  _c("p", { staticClass: "mt-2 tile-label" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("formatLabelNoTranslation")(
                                          "Panel ID"
                                        )
                                      )
                                    )
                                  ]),
                                  _c("p", { staticClass: "tile-content" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.apparatuses[0].apparatus.panelId ||
                                          "N/A"
                                      )
                                    )
                                  ]),
                                  _c("p", { staticClass: "mt-2 tile-label" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("formatLabelNoTranslation")(
                                          "Generator Address"
                                        )
                                      )
                                    )
                                  ]),
                                  _c("p", { staticClass: "tile-content" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.DisplayAddress(
                                            _vm.apparatuses[0].apparatus
                                          )
                                        ) +
                                        " "
                                    ),
                                    _c(
                                      "button",
                                      {
                                        directives: [
                                          {
                                            name: "clipboard",
                                            rawName: "v-clipboard",
                                            value: _vm.DisplayAddress(
                                              _vm.apparatuses[0].apparatus
                                            ),
                                            expression:
                                              "DisplayAddress(apparatuses[0].apparatus)"
                                          }
                                        ],
                                        staticClass: "btn btn-link",
                                        attrs: { type: "button" }
                                      },
                                      [_c("i", { staticClass: "far fa-copy" })]
                                    )
                                  ])
                                ])
                              : _vm.apparatuses.length > 1
                              ? _c("div", [
                                  _c("div", { staticClass: "row" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "tile-label colorRed col-12"
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "xThereAreMultipleGenerators"
                                              )
                                            ) +
                                            " "
                                        )
                                      ]
                                    )
                                  ])
                                ])
                              : _vm._e()
                          ])
                        ])
                      : _vm._e(),
                    _c("div", { staticClass: "mt-2 card-deck" }, [
                      _c("div", { staticClass: "card shadow" }, [
                        _c("div", { staticClass: "card-body" }, [
                          _c("div", { staticClass: "card-title" }, [
                            _vm._v(
                              "Source Owner: " +
                                _vm._s(_vm.source.organizationId)
                            )
                          ]),
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "tile-label col-12" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("formatLabelNoTranslation")("Name")
                                  ) +
                                  " "
                              )
                            ]),
                            _c("div", { staticClass: "tile-content col-12" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.source.ownerInformation.firstName +
                                      " " +
                                      _vm.source.ownerInformation.lastName ||
                                      "N/A"
                                  ) +
                                  " "
                              )
                            ])
                          ]),
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "tile-label col-12" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("formatLabelNoTranslation")(
                                      "Phone Number"
                                    )
                                  ) +
                                  " "
                              )
                            ]),
                            _c("div", { staticClass: "tile-content col-12" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.source.ownerInformation.phones[0] ||
                                      "N/A"
                                  ) +
                                  " "
                              )
                            ])
                          ]),
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "tile-label col-12" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("formatLabelNoTranslation")(
                                      "Email Address"
                                    )
                                  ) +
                                  " "
                              )
                            ]),
                            _c("div", { staticClass: "tile-content col-12" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.source &&
                                      _vm.source.ownerInformation &&
                                      _vm.source.ownerInformation.emails[0]
                                      ? _vm.source.ownerInformation.emails[0]
                                      : "N/A"
                                  ) +
                                  " "
                              )
                            ])
                          ]),
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "tile-label col-12" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("formatLabelNoTranslation")(
                                      "Owner Address"
                                    )
                                  ) +
                                  " "
                              )
                            ]),
                            _c("div", { staticClass: "tile-content col-12" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.DisplayAddress(_vm.source.ownerAddress)
                                  ) +
                                  " "
                              )
                            ])
                          ])
                        ])
                      ]),
                      _c("div", { staticClass: "card" }, [
                        _c("div", { staticClass: "card-body" }, [
                          _c("div", { staticClass: "card-title" }, [
                            _vm._v(
                              " Destination Owner: " +
                                _vm._s(
                                  _vm.destinationOwner &&
                                    _vm.destinationOwner.organizationId
                                    ? _vm.destinationOwner.organizationId
                                    : "N/A"
                                ) +
                                " "
                            )
                          ]),
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "tile-label col-12" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("formatLabelNoTranslation")("Name")
                                  ) +
                                  " "
                              )
                            ]),
                            _c("div", { staticClass: "tile-content col-12" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.destinationOwner &&
                                      _vm.destinationOwner.contact &&
                                      _vm.destinationOwner.contact.firstName &&
                                      _vm.destinationOwner.contact.lastName
                                      ? _vm.destinationOwner.contact.firstName +
                                          " " +
                                          _vm.destinationOwner.contact.lastName
                                      : "N/A"
                                  ) +
                                  " "
                              )
                            ])
                          ]),
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "tile-label col-12" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("formatLabelNoTranslation")(
                                      "Phone Number"
                                    )
                                  ) +
                                  " "
                              )
                            ]),
                            _c("div", { staticClass: "tile-content col-12" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.destinationOwner &&
                                      _vm.destinationOwner.contact &&
                                      _vm.destinationOwner.contact.phones[0]
                                      ? _vm.destinationOwner.contact.phones[0]
                                      : "N/A"
                                  ) +
                                  " "
                              )
                            ])
                          ]),
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "tile-label col-12" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("formatLabelNoTranslation")(
                                      "Email Address"
                                    )
                                  ) +
                                  " "
                              )
                            ]),
                            _c("div", { staticClass: "tile-content col-12" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.destinationOwner &&
                                      _vm.destinationOwner.contact &&
                                      _vm.destinationOwner.contact.emails[0]
                                      ? _vm.destinationOwner.contact.emails[0]
                                      : "N/A"
                                  ) +
                                  " "
                              )
                            ])
                          ]),
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "tile-label col-12" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("formatLabelNoTranslation")(
                                      "Owner Address"
                                    )
                                  ) +
                                  " "
                              )
                            ]),
                            _c("div", { staticClass: "tile-content col-12" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.destinationOwner &&
                                      _vm.destinationOwner.address
                                      ? _vm.DisplayAddress(
                                          _vm.destinationOwner.address
                                        )
                                      : "N/A"
                                  ) +
                                  " "
                              )
                            ])
                          ])
                        ]),
                        _c("div", { staticClass: "card-footer" }, [
                          _c("div", { staticClass: "row" }, [
                            _c(
                              "div",
                              { staticClass: "col" },
                              [
                                _c(
                                  "b-form-checkbox",
                                  {
                                    attrs: {
                                      state:
                                        _vm.$v.destinationOwnerVerified.$model,
                                      "data-test-hook":
                                        "info-verified-with-caller-checkbox-verifyGenTransfer-transfer-gen-modal"
                                    },
                                    model: {
                                      value:
                                        _vm.$v.destinationOwnerVerified.$model,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.$v.destinationOwnerVerified,
                                          "$model",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "$v.destinationOwnerVerified.$model"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " Information Verified With Caller "
                                    ),
                                    !_vm.destinationOwnerVerified
                                      ? _c("span", [
                                          _c(
                                            "i",
                                            {
                                              staticClass:
                                                "asterisk-text colorRed"
                                            },
                                            [_vm._v("required")]
                                          )
                                        ])
                                      : _vm._e()
                                  ]
                                )
                              ],
                              1
                            )
                          ])
                        ])
                      ])
                    ]),
                    _vm.destinationOwnerLoaded
                      ? _c("div", { staticClass: "card shadow" }, [
                          _c("div", { staticClass: "card-body" }, [
                            _c("h4", { staticClass: "card-title" }, [
                              _vm._v("Subscription Information")
                            ]),
                            _c("div", { staticClass: "row" }, [
                              _c(
                                "div",
                                { staticClass: "tile-label colorRed col-12" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("xThereAreNoSubscriptions")
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ])
                          ])
                        ])
                      : _vm._e()
                  ])
                ])
              ]),
              _c("ModalFooter", [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-outline-warning",
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        return _vm.resetTransferModals()
                      }
                    }
                  },
                  [_vm._v(" Cancel ")]
                ),
                _c("div", { attrs: { id: "completeTransferButton" } }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-warning",
                      attrs: {
                        disabled: !_vm.destinationOwnerVerified,
                        "data-test-hook":
                          "complete-transfer-button-verifyGenTranfser-transfer-acct-modal",
                        type: "button"
                      },
                      on: {
                        click: function($event) {
                          return _vm.openModal(
                            "transfer-confirmation" + _vm.source.organizationId
                          )
                        }
                      }
                    },
                    [_vm._v(" Complete Transfer ")]
                  )
                ])
              ])
            ],
            1
          ),
          _c(
            "Modal",
            {
              attrs: {
                modalId: "transfer-confirmation" + _vm.source.organizationId
              }
            },
            [
              _c("ModalHeader", {
                attrs: {
                  title: "Transfer Confirmation",
                  icon: "fad fa-exchange-alt"
                }
              }),
              _c("ModalBody", [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col" }, [
                    _c("h4", [
                      _vm._v("Are you sure you want to complete the transfer?")
                    ])
                  ])
                ])
              ]),
              _c("ModalFooter", [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-outline-warning",
                    attrs: {
                      type: "button",
                      disabled: _vm.transferringGen,
                      "data-test-hook":
                        "cancel-button-transferConfirmation-transfer-acct-modal"
                    },
                    on: {
                      click: function($event) {
                        _vm.closeModal(
                          "transfer-confirmation" + _vm.source.organizationId
                        ),
                          _vm.resetTransferModals()
                      }
                    }
                  },
                  [_vm._v(" Cancel ")]
                ),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-warning",
                    attrs: {
                      type: "button",
                      disabled: _vm.transferringGen,
                      "data-test-hook":
                        "complete-transfer-transferConfirmation-transfer-acct-modal"
                    },
                    on: {
                      click: function($event) {
                        return _vm.completeTransfer()
                      }
                    }
                  },
                  [
                    _vm._v(" Complete Transfer "),
                    _vm.transferringGen
                      ? _c("div", {
                          staticClass: "spinner-border text-muted ml-2"
                        })
                      : _vm._e()
                  ]
                )
              ])
            ],
            1
          ),
          _c(
            "Modal",
            {
              attrs: { modalId: "transfer-success" + _vm.source.organizationId }
            },
            [
              _c("ModalHeader", {
                attrs: {
                  title: "Transfer Successful",
                  icon: "fad fa-exchange-alt"
                }
              }),
              _c("ModalBody", [
                !_vm.transferredWithError
                  ? _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col" }, [
                        _vm._v(" The Transfer completed successfully. ")
                      ])
                    ])
                  : _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col colorRed" }, [
                        _vm._v(" " + _vm._s(_vm.transferFailureMessage) + " ")
                      ])
                    ])
              ]),
              _c("ModalFooter", [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-warning btn-block",
                    attrs: {
                      type: "button",
                      "data-test-hook":
                        "close-button-transferSuccess-transfer-acct-modal"
                    },
                    on: {
                      click: function($event) {
                        _vm.closeModal(
                          "transfer-success" + _vm.source.organizationId
                        ),
                          _vm.emitSuccess()
                      }
                    }
                  },
                  [_vm._v(" Close ")]
                )
              ])
            ],
            1
          ),
          _c(
            "Modal",
            {
              attrs: { modalId: "transfer-failure" + _vm.source.organizationId }
            },
            [
              _c("ModalHeader", {
                attrs: { title: "Transfer Failed", icon: "fad fa-exchange-alt" }
              }),
              _c("ModalBody", [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col" }, [
                    _vm._v(" " + _vm._s(_vm.transferFailureMessage) + " ")
                  ])
                ])
              ]),
              _c("ModalFooter", [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-warning",
                    attrs: {
                      type: "button",
                      block: "",
                      "data-test-hook":
                        "close-button-transferFailure-transfer-acct-modal"
                    },
                    on: {
                      click: function($event) {
                        return _vm.closeModal(
                          "transfer-failure" + _vm.source.organizationId
                        )
                      }
                    }
                  },
                  [_vm._v(" Close ")]
                )
              ])
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }