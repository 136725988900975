<template>
  <div v-if="virtualPowerPlantEnabled(currentAppConfig) && currentApparatusDetails.enrolledInVpp">
    <div v-if="eventsLoading" class="d-flex justify-content-center mb-3 pt-4">
      <span class="spinner-border text-warning" style="width: 5rem; height: 5rem;" label="Spinning"></span>
    </div>
    <div v-else-if="!events" class="mt-3 ml-md-2">
      <div class="row">
        <div class="col text-center">
          <span style="font-size: 3rem; color: var(--carbon-600);">
            <i class="fal fa-plug" data-test-hook="vppNoEventsIcon" />
          </span>
        </div>
      </div>
      <div class="row">
        <div class="col text-center">
          <h1 data-test-hook="vppNoEventsTitle">
            {{ $t('xNoEvents') }}
          </h1>
          <p data-test-hook="vppNoEventsBody">{{ $t('xCurrentlyNoEventHistory') }}</p>
        </div>
      </div>
    </div>
    <div v-else class="mt-2 ml-md-2">
      <div class="row no-gutters mb-2">
        <div class="col" data-test-hook="vppTitleLine">
          <h1 class="mb-0 pb-0"><i class="far fa-plug colorOrange mr-1"></i> {{ $t('xVirtualPowerPlant') }}</h1>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <div class="card" body-class="no-padding">
            <div class="row">
              <div class="col">
                <div class="list-group list-group-flush">
                  <div class="list-group-item" v-for="(event, index) in events" :key="index">
                    <div class="row align-items-center">
                      <div class="col">
                        <div class="row">
                          <div class="col">
                            <h3 :data-test-hook="'vppEventName' + index">
                              <i class="mr-2" :class="getEventIconClass(event)" />{{ getEventText(event) }}
                            </h3>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col ml-4">
                            <p :data-test-hook="'vppOutOutInProgressLine' + index">
                              <i v-if="optOutInProgress(event)" class="red-600">{{ $t('x15MinuteWarning') }}</i>
                            </p>
                            <p :data-test-hook="'vppEventDateLine' + index">
                              <i class="fal fa-calendar-check mr-2" /> {{ displayShortDateTimeWithTZ(event.start) }}
                            </p>
                            <p :data-test-hook="'vppEventDurationLine' + index">
                              <i class="fal fa-clock mr-2" />
                              {{ $t('xDuration') | formatLabel }}
                              {{ event.duration }} hour<span v-if="event.duration > 1 || event.duration === 0">s</span>
                            </p>
                          </div>
                          <div class="col ml-4 col-12 col-md-auto" v-if="showOptOutButton(event)">
                            <button
                              type="button"
                              class="btn btn-outline-warning"
                              :disabled="disableOptOutButton(event)"
                              @click="openOptOutModal(event)"
                              data-test-hook="vppOptOutBtn"
                            >
                              <i class="fas fa-minus-octagon" />
                              {{ $t('xOptOutOfEvent') }}
                            </button>
                          </div>
                        </div>
                      </div>
                      <!-- v-if="showOptOutButton(event)" -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Modal modalId="confirm-event-opt-out" size="modal-lg">
      <ModalHeader :title="$t('xAreYouSure')" icon="fas fa-exclamation-triangle colorOrange mr-1">
        <div class="col-auto float-right">
          <button type="button" class="btn btn-button--gray btn-icon-button" @click="resetModal()">
            <i class="fas fa-times" />
          </button>
        </div>
      </ModalHeader>
      <ModalBody>
        <div class="row mt-3">
          <div class="col">
            {{ $t('xAreYouSureYouWantToOptOut') }}
          </div>
        </div>
        <div class="row mt-2 checkbox-row no-gutters">
          <div class="col">
            <div class="custom-control custom-checkbox">
              <input type="checkbox" class="custom-control-input vpp-checkbox" v-model="confirmOptOut" />
              {{ $t('xCancelVppEvent') }}
            </div>
          </div>
        </div>
        <div class="row mt-5">
          <div class="col">
            <button type="button" class="btn btn-block btn-outline-warning" @click="resetModal()">
              {{ $t('xNoCancel') }}
            </button>
          </div>
          <div class="col">
            <button
              type="button"
              class="btn btn-warning btn-block"
              :disabled="!confirmOptOut || sendingOptOutCommand"
              @click="optOutOfEvent()"
            >
              {{ $t('xYesImSure') }}
              <span class="spinner-border" v-if="sendingOptOutCommand" />
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import * as Constants from '@/constants'

export default {
  data() {
    return {
      eventsLoading: true,
      events: null,
      sendingOptOutCommand: false,
      eventToOptOutOf: null,
      confirmOptOut: false
    }
  },
  computed: {
    ...mapState({
      currentApparatusDetails: state => state.apparatus.currentApparatusDetails,
      currentAppConfig: state => state.application.currentAppConfig,
      bannerMessage: state => state.application.bannerMessage
    }),
    billingMigrationFeatureFlag() {
      return Constants.BillingMigrationFeatureFlag
    }
  },
  methods: {
    optOutInProgress(event) {
      if (event.eventStatus == Constants.GridServicesPendingInactiveStatus) return true
      return false
    },
    optOutOfEvent() {
      this.sendingOptOutCommand = true
      let request = {
        apparatusId: this.$route.params.apparatusId,
        eventId: this.eventToOptOutOf.id,
        eventStatus: this.eventToOptOutOf.status
      }
      this.$store
        .dispatch('optOutOfEvent', request)
        .then(response => {
          setTimeout(
            function() {
              this.sendingOptOutCommand = false
              this.getEvents()
              this.resetModal()
              this.$store.dispatch('displayToast', {
                title: this.$t('xSuccess'),
                message: this.$t('xOptOutSuccess'),
                variant: 'success'
              })
            }.bind(this),
            3000
          )
        })
        .catch(error => {
          this.sendingOptOutCommand = false
          this.$store.dispatch('displayToast', {
            title: this.$t('xError'),
            message: error.response.data.detail,
            variant: 'warning'
          })
        })
    },
    resetModal() {
      this.eventToOptOutOf = null
      this.confirmOptOut = false
      this.closeModal('confirm-event-opt-out')
    },
    openOptOutModal(event) {
      this.eventToOptOutOf = event
      this.openModal('confirm-event-opt-out')
    },
    getEventIconClass(event) {
      if (
        event.eventStatus == Constants.GridServicesPendingActiveStatus ||
        event.eventStatus == Constants.GridServicesActiveStatus ||
        event.eventStatus == Constants.GridServicesPendingInactiveStatus
      )
        return 'fas fa-plug colorOrange'
      else if (event.eventStatus == Constants.GridServicesCompleteStatus) {
        switch (event.eventCompletionType) {
          case Constants.GridServicesCompletionTypeOk:
          case Constants.GridServicesCompletionTypeUnreported:
          case Constants.GridServicesCompletionTypeError:
            return 'fas fa-check-circle statusIsReady'
          case Constants.GridServicesCompletionTypeUnexpected:
            return 'fas fa-exclamation-circle body-text-color'
          case Constants.GridServicesCompletionTypeInactive:
            return 'fas fa-ban body-text-color'
          case Constants.GridServicesCompletionTypeCanceled:
            return 'fas fa-times-circle body-text-color'
          default:
            return ''
        }
      } else return 'fas fa-times-circle body-text-color'
    },
    showOptOutButton(event) {
      if (
        event.eventStatus == Constants.GridServicesPendingActiveStatus ||
        event.eventStatus == Constants.GridServicesActiveStatus ||
        event.eventStatus == Constants.GridServicesPendingInactiveStatus
      ) {
        return true
      }
      return false
    },
    disableOptOutButton(event) {
      if (this.sendingOptOutCommand || event.eventStatus == Constants.GridServicesPendingInactiveStatus) return true
      return false
    },
    getEventText(event) {
      switch (event.eventStatus) {
        case Constants.GridServicesPendingActiveStatus:
          return this.$t('xNewEventScheduled')
        case Constants.GridServicesActiveStatus:
          return this.$t('xEventInProgress')
        case Constants.GridServicesPendingInactiveStatus:
          return this.$t('xOptOutInProgress')
        case Constants.GridServicesPendingCancelStatus:
          return this.$t('xEventCanceled')
        case Constants.GridServicesCompleteStatus:
          switch (event.eventCompletionType) {
            case Constants.GridServicesCompletionTypeOk:
            case Constants.GridServicesCompletionTypeUnreported:
            case Constants.GridServicesCompletionTypeError:
              return this.$t('xEventCompleted')
            case Constants.GridServicesCompletionTypeUnexpected:
              return this.$t('xGeneratorStopped')
            case Constants.GridServicesCompletionTypeInactive:
              return this.$t('xOptedOutOfEvent')
            case Constants.GridServicesCompletionTypeCanceled:
              return this.$t('xEventCanceled')
            default:
              return ''
          }
        default:
          return ''
      }
    },
    getEvents() {
      this.eventsLoading = true
      this.$store
        .dispatch('getEvents', this.$route.params.apparatusId)
        .then(response => {
          if (response.status != 204) this.events = response.data
        })
        .finally(() => {
          this.eventsLoading = false
        })
    }
  },
  mounted() {
    this.getEvents()
  }
}
</script>
<style scoped>
.checkbox-row {
  background-color: #f5f5f5;
}

.vpp-checkbox {
  opacity: unset;
}
</style>
