var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("h5", { staticClass: "m-0" }, [
          _vm._v(_vm._s(_vm.$t("xScheduledExerciseTime")))
        ])
      ])
    ]),
    _vm.statusWithData
      ? _c("div", { staticClass: "row my-3" }, [
          _c("div", { staticClass: "col-12 asterisk-text" }, [
            _vm._v(
              " " +
                _vm._s(_vm.getExerciseInterval(_vm.schedule.interval).text) +
                " "
            )
          ]),
          _c("div", { staticClass: "col-12" }, [
            _c("div", { staticClass: "row no-gutters" }, [
              _vm._m(0),
              _c("div", { staticClass: "col ml-2" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.getExerciseDay().text) +
                    " " +
                    _vm._s(_vm.$t("xAt")) +
                    " " +
                    _vm._s(_vm.getExerciseTime(_vm.schedule.timeUtc)) +
                    " "
                )
              ])
            ])
          ])
        ])
      : _vm.panelNotSupported || _vm.statusWithNoData || _vm.notConfigured
      ? _c(
          "div",
          {
            staticClass: "row",
            attrs: { "data-test-hook": "no-exercise-schedule-data-message" }
          },
          [
            _c("div", { staticClass: "col-12" }, [
              _c("p", { staticClass: "mt-2" }, [
                _c("i", { staticClass: "reason-message" }, [
                  _vm._v(_vm._s(_vm.$t("xNoExerciseScheduleDataAvailable")))
                ])
              ])
            ])
          ]
        )
      : _vm._e(),
    _c("div", { staticClass: "row mt-3" }, [
      _c("div", { staticClass: "col" }, [
        _c("p", [
          _c("strong", [_vm._v(_vm._s(_vm.$t("xExerciseUpdateUnavailable")))])
        ])
      ])
    ]),
    _vm.statusWithData || _vm.statusWithNoData
      ? _c("div", { staticClass: "row mb-3" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("p", { staticClass: "mt-2" }, [
              _c("i", { staticClass: "reason-message" }, [
                _vm._v(
                  _vm._s(_vm._f("formatLabel")("xCurrentStatus")) +
                    " " +
                    _vm._s(_vm.schedule.statusLabel)
                )
              ])
            ])
          ]),
          _c("div", { staticClass: "col-12" }, [
            _c("i", { staticClass: "reason-message" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("xDueToThisStatusYourUpdateAandExercise")) +
                  " "
              )
            ])
          ])
        ])
      : _vm.panelNotSupported || _vm.notConfigured
      ? _c(
          "div",
          {
            staticClass: "row mb-3",
            attrs: { "data-test-hook": "panel-not-supported-div" }
          },
          [
            _c("div", { staticClass: "col-12" }, [
              _c("p", [
                _c("i", { staticClass: "reason-message" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("xYourGeneratorDoesNotSupportThisFeature")
                      ) +
                      " "
                  )
                ])
              ])
            ]),
            _c("div", { staticClass: "col-12 mt-3" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-warning",
                  attrs: {
                    type: "button",
                    to: { name: "technicalSpecs" },
                    "data-test-hook":
                      "tech-specs-button-no-exercise-shcedule-reason"
                  }
                },
                [
                  _c("i", { staticClass: "fas fa-clipboard-list" }),
                  _vm._v(" " + _vm._s(_vm.$t("xTechnicalSpecs")) + " ")
                ]
              )
            ])
          ]
        )
      : !_vm.subscriptionPaid(_vm.currentApparatusDetails.subscription) ||
        !_vm.subscriptionActive(_vm.currentApparatusDetails.subscription)
      ? _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-outline-primary",
                attrs: {
                  type: "button",
                  id: "freeUpdateButton",
                  disabled: "",
                  title: _vm.$t("xThisIsAPremiumFeature")
                }
              },
              [_vm._v(" " + _vm._s(_vm.$t("xUpdate")) + " ")]
            )
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "cols-auto" }, [
      _c("i", { staticClass: "far fa-calendar-check" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }