var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mt-2 ml-md-2" },
    [
      !_vm.technicalSpecs
        ? _c(
            "div",
            { staticClass: "d-flex justify-content-center mb-3 pt-4" },
            [
              _c("span", {
                staticClass: "spinner-border text-warning",
                staticStyle: { width: "5rem", height: "5rem" },
                attrs: { label: "Spinning" }
              })
            ]
          )
        : _c("div", [
            _c("div", { staticClass: "row no-gutters mb-2" }, [
              _c("div", { staticClass: "col" }, [
                _c("h1", { staticClass: "mb-0 pb-0" }, [
                  _c("i", {
                    staticClass: "far fa-clipboard-list colorOrange mr-2",
                    attrs: { "data-test-hook": "PageIcon" }
                  }),
                  _c("span", { attrs: { "data-test-hook": "PageTitle" } }, [
                    _vm._v(_vm._s(_vm.$t("xTechnicalSpecs")))
                  ])
                ])
              ])
            ]),
            _c("div", { staticClass: "card" }, [
              _c("div", { staticClass: "card-body" }, [
                _vm.technicalSpecs && _vm.technicalSpecs.productType
                  ? _c("div", { staticClass: "row mb-3 mt-2" }, [
                      _c("div", { staticClass: "cols-12 col-lg-4" }, [
                        _c(
                          "p",
                          { attrs: { "data-test-hook": "ProductType" } },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("xProductType")) +
                                " (" +
                                _vm._s(_vm.technicalSpecs.productType) +
                                ")"
                            )
                          ]
                        )
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "col-12",
                          attrs: { "data-test-hook": "ProductDescription" }
                        },
                        [
                          _c("p", [
                            _c("strong", [
                              _vm._v(_vm._s(_vm.technicalSpecs.description))
                            ])
                          ])
                        ]
                      )
                    ])
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "row" },
                  _vm._l(_vm.techSpecs, function(prop) {
                    return _c(
                      "div",
                      {
                        key: prop.name,
                        staticClass: "col-12 col-lg-4 mb-3",
                        attrs: {
                          "data-test-hook":
                            prop.name.replace(/\s/g, ``) + `Label`
                        }
                      },
                      [
                        _c("p", [_vm._v(_vm._s(prop.name))]),
                        _c(
                          "p",
                          {
                            attrs: {
                              "data-test-hook":
                                prop.name.replace(/\s/g, ``) + `Value`
                            }
                          },
                          [_c("strong", [_vm._v(_vm._s(prop.value))])]
                        )
                      ]
                    )
                  }),
                  0
                ),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col" }, [
                    _c(
                      "button",
                      {
                        staticClass: "mt-2 btn btn-outline-warning",
                        attrs: {
                          type: "button",
                          "data-test-hook": "manuals-button-tech-specs"
                        },
                        on: {
                          click: function($event) {
                            return _vm.getManuals()
                          }
                        }
                      },
                      [
                        _c("i", { staticClass: "fas fa-file-alt" }),
                        _vm._v(" " + _vm._s(_vm.$t("xManuals")) + " ")
                      ]
                    )
                  ])
                ])
              ])
            ]),
            _c("div", { staticClass: "card my-4" }, [
              _c("div", { staticClass: "card-body" }, [
                _c("div", { staticClass: "row mb-2" }, [
                  _c("div", { staticClass: "col" }, [
                    _c(
                      "h2",
                      { attrs: { "data-test-hook": "ProductOverviewHeading" } },
                      [_vm._v(" " + _vm._s(_vm.$t("xProductOverview")) + " ")]
                    )
                  ])
                ]),
                _vm.technicalSpecs && _vm.technicalSpecs.attributes
                  ? _c(
                      "div",
                      { staticClass: "row" },
                      _vm._l(_vm.attributeChunks, function(chunk, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "col-12 col-lg-4" },
                          _vm._l(chunk, function(attribute) {
                            return _c(
                              "div",
                              {
                                key: attribute.name,
                                staticClass: "mb-3",
                                attrs: {
                                  "data-test-hook":
                                    "LabelValueGroup-" +
                                    _vm.removeExtraChars(attribute.name)
                                }
                              },
                              [
                                _c(
                                  "p",
                                  {
                                    attrs: {
                                      "data-test-hook":
                                        "AttributeName-" +
                                        _vm.removeExtraChars(attribute.name)
                                    }
                                  },
                                  [_vm._v(_vm._s(attribute.name))]
                                ),
                                _c(
                                  "p",
                                  {
                                    attrs: {
                                      "data-test-hook":
                                        "AttributeValue-" +
                                        _vm.removeExtraChars(attribute.name)
                                    }
                                  },
                                  [
                                    _c("strong", [
                                      _vm._v(_vm._s(attribute.value))
                                    ])
                                  ]
                                )
                              ]
                            )
                          }),
                          0
                        )
                      }),
                      0
                    )
                  : _vm._e()
              ])
            ])
          ]),
      _vm.technicalSpecs
        ? _c(
            "Modal",
            {
              ref: "manual-modal",
              attrs: { modalId: "manualModal", size: "modal-xl" }
            },
            [
              _c(
                "ModalHeader",
                {
                  attrs: {
                    title:
                      _vm.$t("xManualModalTitle") +
                      ": " +
                      _vm.serialNumber[0].value,
                    "data-test-hook": "modalTitle"
                  }
                },
                [
                  _c("div", { staticClass: "col-auto" }, [
                    _c(
                      "button",
                      {
                        staticClass:
                          "btn btn-icon-button btn-icon-button--gray",
                        attrs: {
                          type: "button",
                          "data-test-hook": "x-button-manualModal-tech-specs"
                        },
                        on: {
                          click: function($event) {
                            return _vm.closeModal("manualModal")
                          }
                        }
                      },
                      [_c("i", { staticClass: "fas fa-times" })]
                    )
                  ])
                ]
              ),
              _c(
                "ModalBody",
                [
                  _c("ProductManuals", {
                    attrs: { manuals: _vm.manuals, loaded: _vm.manualsLoaded }
                  })
                ],
                1
              ),
              _c("ModalFooter", [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-warning",
                    attrs: {
                      type: "button",
                      "data-test-hook": "ok-button-manualModal-tech-specs"
                    },
                    on: {
                      click: function($event) {
                        return _vm.hideManualModal()
                      }
                    }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("xOK")) + " ")]
                )
              ])
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }