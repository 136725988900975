var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "Modal",
        {
          attrs: {
            modalId:
              "deviceSwapModal-newDeviceInfo" + _vm.oldDevice.apparatusId,
            size: "modal-xl"
          }
        },
        [
          _c("ModalBody", [
            _c("div", { staticClass: "card no-border" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col" }, [
                  _c("h1", [_vm._v("Replace Device")])
                ])
              ]),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col" }, [
                  _c("p", [
                    _vm._v(
                      " Please specify the new device type below. Depending on the New Device Type Specified, the MAC address and (For Wi-Fi) or Device Serial Number (For 4G LTE Accessories or Wi-Fi/Ethernet Accessories) will be required. "
                    ),
                    _c("span", { staticClass: "pre-swap-warning" }, [
                      _vm._v(
                        " Please note: the new device MUST be installed and communicating prior to performing the device swap. "
                      )
                    ])
                  ])
                ])
              ]),
              _c("div", { staticClass: "row mt-2 mb-2" }, [
                _c("div", { staticClass: "col" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._f("formatLabelNoTranslation")("Old Device Type")
                      ) +
                      " " +
                      _vm._s(_vm.DisplayOldDeviceType()) +
                      " "
                  )
                ])
              ]),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12 col-md-6" }, [
                  _c(
                    "div",
                    {
                      staticClass: "form-group",
                      attrs: { role: "group", id: "newDeviceType-group" }
                    },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "d-block",
                          attrs: { for: "newDevicetype" }
                        },
                        [_vm._v("New Device Type")]
                      ),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.newDeviceType,
                              expression: "form.newDeviceType"
                            }
                          ],
                          staticClass: "custom-select",
                          attrs: { id: "newDevicetype" },
                          on: {
                            change: function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.form,
                                "newDeviceType",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            }
                          }
                        },
                        _vm._l(_vm.deviceTypes, function(deviceType, index) {
                          return _c(
                            "option",
                            {
                              key: index,
                              domProps: { value: deviceType.value }
                            },
                            [_vm._v(" " + _vm._s(deviceType.text) + " ")]
                          )
                        }),
                        0
                      )
                    ]
                  )
                ])
              ]),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12 col-md-6" }, [
                  _c(
                    "div",
                    {
                      staticClass: "form-group",
                      attrs: {
                        role: "group",
                        id: "mobileLinkDeviceIdentifier-group"
                      }
                    },
                    [
                      _c(
                        "label",
                        { attrs: { for: "mobileLinkDeviceIdentifier" } },
                        [_vm._v("Mobile Link Device Identifier")]
                      ),
                      _c("div", [
                        _vm.form.newDeviceType == "wifi"
                          ? _c("input", {
                              directives: [
                                {
                                  name: "mask",
                                  rawName: "v-mask",
                                  value: "NN:NN:NN:NN:NN:NN",
                                  expression: "'NN:NN:NN:NN:NN:NN'"
                                },
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.form.mobileLinkDeviceIdentifier,
                                  expression: "form.mobileLinkDeviceIdentifier"
                                }
                              ],
                              staticClass: "form-control",
                              attrs: {
                                id: "mobileLinkDeviceIdenifier",
                                type: "text",
                                maxlength: "17"
                              },
                              domProps: {
                                value: _vm.form.mobileLinkDeviceIdentifier
                              },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.form,
                                    "mobileLinkDeviceIdentifier",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          : _vm.form.newDeviceType == _vm.hsbG3DeviceType
                          ? _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.form.mobileLinkDeviceIdentifier,
                                  expression: "form.mobileLinkDeviceIdentifier"
                                }
                              ],
                              staticClass: "form-control",
                              attrs: {
                                id: "mobileLinkDeviceIdentifier",
                                type: "text",
                                maxlength: "9"
                              },
                              domProps: {
                                value: _vm.form.mobileLinkDeviceIdentifier
                              },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.form,
                                    "mobileLinkDeviceIdentifier",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          : _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.form.mobileLinkDeviceIdentifier,
                                  expression: "form.mobileLinkDeviceIdentifier"
                                }
                              ],
                              staticClass: "form-control",
                              attrs: {
                                id: "mobileLinkDeviceIdenifier",
                                type: "text",
                                maxlength: "10"
                              },
                              domProps: {
                                value: _vm.form.mobileLinkDeviceIdentifier
                              },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.form,
                                    "mobileLinkDeviceIdentifier",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                      ])
                    ]
                  )
                ])
              ])
            ])
          ]),
          _c("ModalFooter", [
            _c(
              "button",
              {
                staticClass: "btn btn-secondary mr-2",
                attrs: {
                  type: "button",
                  "data-test-hook": "cancel-button-newDeviceInfo-device-swap"
                },
                on: {
                  click: function($event) {
                    return _vm.ResetModal()
                  }
                }
              },
              [_vm._v(" Cancel ")]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                attrs: {
                  type: "button",
                  disabled: _vm.CanSwap(),
                  "data-test-hook": "submit-button-newDeviceInfo-device-swap"
                },
                on: {
                  click: function($event) {
                    return _vm.validateDevice()
                  }
                }
              },
              [
                _vm._v(" Submit "),
                _vm.validatingDevice
                  ? _c("div", { staticClass: "spinner-border text-muted ml-2" })
                  : _vm._e()
              ]
            )
          ])
        ],
        1
      ),
      _c(
        "Modal",
        {
          attrs: {
            modalId:
              "deviceSwapModal-confirmDeviceInfo" + _vm.oldDevice.apparatusId
          }
        },
        [
          _c("div", { staticClass: "modal-header" }, [
            _c("div", { staticClass: "row align-items-center" }, [
              _c("div", { staticClass: "col-auto" }, [
                _c("i", {
                  staticClass:
                    "fas fa-chevron-left back-chevron-button hoverPointer",
                  on: {
                    click: function($event) {
                      _vm.closeModal(
                        "deviceSwapModal-confirmDeviceInfo" +
                          _vm.oldDevice.apparatusId
                      ),
                        _vm.openModal(
                          "deviceSwapModal-newDeviceInfo" +
                            _vm.oldDevice.apparatusId
                        )
                    }
                  }
                })
              ]),
              _c("div", { staticClass: "col" }, [
                _c("h2", { staticClass: "modal-title" }, [
                  _vm._v(" Confirm Device Information ")
                ])
              ])
            ])
          ]),
          _c("ModalBody", [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col" }, [
                _c("h5", [
                  _vm._v(
                    "Device Type: " +
                      _vm._s(
                        _vm.form.newDeviceType === _vm.hsbG3DeviceType
                          ? "G3"
                          : _vm.form.newDeviceType
                      )
                  )
                ])
              ])
            ]),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col" }, [
                _c("h5", [
                  _vm._v(
                    "Device Identifier: " +
                      _vm._s(_vm.form.mobileLinkDeviceIdentifier)
                  )
                ])
              ])
            ])
          ]),
          _c("ModalFooter", [
            _c(
              "button",
              {
                staticClass: "btn btn-outline-danger",
                attrs: {
                  type: "button",
                  "data-test-hook":
                    "cancel-button-confirmDeviceInfo-device-swap"
                },
                on: {
                  click: function($event) {
                    return _vm.ResetModal()
                  }
                }
              },
              [_vm._v(" CANCEL ")]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                attrs: {
                  type: "button",
                  "data-test-hook":
                    "confirm-button-confirmDeviceInfo-device-swap"
                },
                on: {
                  click: function($event) {
                    return _vm.submitSwap()
                  }
                }
              },
              [
                _vm._v(" CONFIRM "),
                _vm.swapping
                  ? _c("div", { staticClass: "spinner-border text-muted ml-2" })
                  : _vm._e()
              ]
            )
          ])
        ],
        1
      ),
      _c(
        "Modal",
        {
          attrs: {
            modalId: "deviceSwapModal-swapSuccess" + _vm.oldDevice.apparatusId
          }
        },
        [
          _c("div", { staticClass: "modal-header" }, [
            _c("div", { staticClass: "row align-items-center" }, [
              _c("div", { staticClass: "col-auto" }, [
                _c("i", {
                  staticClass:
                    "fas fa-chevron-left back-chevron-button hoverPointer",
                  on: {
                    click: function($event) {
                      _vm.closeModal(
                        "deviceSwapModal-swapSuccess" +
                          _vm.oldDevice.apparatusId
                      ),
                        _vm.openModal(
                          "deviceSwapModal-paymentInformation" +
                            _vm.oldDevice.apparatusId
                        )
                    }
                  }
                })
              ]),
              _c("div", { staticClass: "col" }, [
                _c("h2", { staticClass: "modal-title" }, [_vm._v(" Success! ")])
              ])
            ])
          ]),
          _c("ModalBody", [
            _c("div", { staticClass: "col" }, [
              _c("h5", [
                _vm._v(
                  "Please clear this search, and search for the new device:"
                )
              ])
            ]),
            _c("div", { staticClass: "row" }, [
              _vm.validatedDeviceInfo
                ? _c("div", { staticClass: "col" }, [
                    _c("h3", [
                      _vm._v(
                        " " + _vm._s(_vm.validatedDeviceInfo.deviceId) + " "
                      )
                    ])
                  ])
                : _vm._e()
            ])
          ]),
          _c("ModalFooter", [
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                attrs: {
                  type: "button",
                  "data-test-hook": "close-button-swapSuccess-device-swap"
                },
                on: {
                  click: function($event) {
                    return _vm.ResetModal()
                  }
                }
              },
              [_vm._v(" Close ")]
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }