var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mt-2 ml-md-2" },
    [
      _vm.maintenanceScheduleLoading
        ? _c(
            "div",
            { staticClass: "d-flex justify-content-center mb-3 pt-4" },
            [
              _c("div", {
                staticClass: "spinner-border ml-2 text-warning",
                staticStyle: { width: "5rem", height: "5rem" },
                attrs: { label: "Spinning" }
              })
            ]
          )
        : _c(
            "div",
            [
              _c(
                "div",
                {
                  staticClass: "row no-gutters mb-2",
                  attrs: { "data-test-hook": "maintenance-page-header" }
                },
                [
                  _c("div", { staticClass: "col" }, [
                    _c("h1", { staticClass: "mb-0 pb-0" }, [
                      _c("i", {
                        staticClass: "far fa-toolbox colorOrange pr-2"
                      }),
                      _vm._v(" " + _vm._s(_vm.$t("xMaintenance")) + " ")
                    ])
                  ])
                ]
              ),
              _c("div", { staticClass: "card" }, [
                _vm.currentApparatusDetails.apparatusClassification !=
                _vm.portableGenApparatusType
                  ? _c("div", { staticClass: "card-body" }, [
                      _c("div", { staticClass: "row align-items-center" }, [
                        _c(
                          "div",
                          { staticClass: "col d-flex align-items-center" },
                          [
                            _c(
                              "h2",
                              {
                                staticClass: "mb-0 pb-0 mr-2 d-inline-block",
                                attrs: {
                                  "data-test-hook": "maintenance-sched-header"
                                }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("xMaintenanceSchedule")) +
                                    " "
                                )
                              ]
                            ),
                            _c("i", {
                              staticClass:
                                "fas fa-question-circle hoverPointer",
                              attrs: { id: "schedule-tool-tip" }
                            }),
                            _c(
                              "b-popover",
                              {
                                attrs: {
                                  target: "schedule-tool-tip",
                                  triggers: "hover",
                                  placement: "top"
                                }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("xMaintenanceScheduleToolTip")
                                    ) +
                                    " "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ])
                    ])
                  : _vm._e(),
                _vm.maintenanceSchedule &&
                _vm.maintenanceSchedule.length > 0 &&
                _vm.maintenanceSchedule[0]?.date &&
                _vm.maintenanceSchedule[0]?.hours
                  ? _c(
                      "div",
                      { attrs: { "data-test-hook": "maintenance-schedule" } },
                      [
                        _vm.maintenanceSchedule.length > 0
                          ? _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col" }, [
                                _c(
                                  "div",
                                  { staticClass: "list-group-flush" },
                                  _vm._l(_vm.maintenanceSchedule, function(
                                    schedule,
                                    index
                                  ) {
                                    return _c(
                                      "div",
                                      {
                                        key: index,
                                        staticClass: "list-group-item",
                                        staticStyle: { "padding-left": "5px" }
                                      },
                                      [
                                        schedule.hours || schedule.date
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "col-12 col-sm-auto"
                                              },
                                              [
                                                schedule.date
                                                  ? _c("span", [
                                                      _c("i", {
                                                        staticClass:
                                                          "fal fa-calendar-star fa-sm pr-2"
                                                      }),
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.displayShortDate(
                                                            schedule.date
                                                          )
                                                        ) + " "
                                                      )
                                                    ])
                                                  : _vm._e(),
                                                schedule.hours
                                                  ? _c("span", [
                                                      _vm._v(
                                                        "/ " +
                                                          _vm._s(
                                                            schedule.hours
                                                          ) +
                                                          " " +
                                                          _vm._s(
                                                            _vm.$t("xRunHours")
                                                          ) +
                                                          " "
                                                      )
                                                    ])
                                                  : _vm._e(),
                                                _c("h5", [
                                                  _vm._v(_vm._s(schedule.type))
                                                ])
                                              ]
                                            )
                                          : _vm._e()
                                      ]
                                    )
                                  }),
                                  0
                                )
                              ])
                            ])
                          : _vm._e()
                      ]
                    )
                  : _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col" }, [
                        _c("div", { staticClass: "card-body py-0" }, [
                          _vm._v(
                            " " + _vm._s(_vm.$t("xNoMaintenanceSchedule")) + " "
                          )
                        ])
                      ])
                    ]),
                _vm.subscriptionActive(_vm.subscription)
                  ? _c("div", [
                      _c(
                        "div",
                        {
                          staticClass: "card-body pb-0",
                          attrs: {
                            "data-test-hook": "maintenance-history-headers"
                          }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "row mb-2 align-items-center" },
                            [
                              _c("div", { staticClass: "col" }, [
                                _c("h2", { staticClass: "mb-0" }, [
                                  _vm._v(_vm._s(_vm.$t("xMaintenanceHistory")))
                                ])
                              ]),
                              _c(
                                "div",
                                { staticClass: "cols-auto" },
                                [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-icon-button",
                                      attrs: {
                                        type: "button",
                                        id: "add-maintenance-button",
                                        "data-test-hook":
                                          "add-maintenance-button-maintenance"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.openAddRecordModal()
                                        }
                                      }
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "fas fa-calendar-plus"
                                      })
                                    ]
                                  ),
                                  _c(
                                    "b-popover",
                                    {
                                      attrs: {
                                        target: "add-maintenance-button",
                                        triggers: "hover",
                                        placement: "bottom",
                                        "data-test-hook":
                                          "add-record-popover-maintenance"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.$t("xAddRecord")) + " "
                                      )
                                    ]
                                  ),
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-icon-button ml-3",
                                      attrs: {
                                        id: "export-maintenance-button",
                                        type: "button",
                                        disabled:
                                          !_vm.maintenanceHistory ||
                                          _vm.maintenanceHistory.length == 0,
                                        "data-test-hook":
                                          "export-maintenance-button-maintenance"
                                      },
                                      on: {
                                        click: function($event) {
                                          _vm.openModal(
                                            "selectMaintenanceHistoryDateRange"
                                          ),
                                            _vm.trackAppInsightsEvent(
                                              "open-export-maintenance-history-modal",
                                              {
                                                page: "Maintenance",
                                                organizationId:
                                                  _vm.accountProfile
                                                    .organizationId,
                                                userId:
                                                  _vm.accountProfile.userId,
                                                apparatusId:
                                                  _vm.$route.params.apparatusId
                                              }
                                            )
                                        }
                                      }
                                    },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "fas fa-file-export colorOrange"
                                      })
                                    ]
                                  ),
                                  _c(
                                    "b-popover",
                                    {
                                      attrs: {
                                        target: "export-maintenance-button",
                                        triggers: "hover",
                                        placement: "bottom",
                                        "data-test-hook":
                                          "export-maintenance-popover-maintenance"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.$t("xExport")) + " "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ]
                          )
                        ]
                      ),
                      _vm.maintenanceHistory &&
                      _vm.maintenanceHistory.length > 0
                        ? _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col" }, [
                              _c(
                                "div",
                                { staticClass: "list-group-flush" },
                                _vm._l(_vm.maintenanceHistory, function(
                                  history,
                                  index
                                ) {
                                  return _c(
                                    "div",
                                    {
                                      key: index,
                                      staticClass: "list-group-item",
                                      attrs: {
                                        id: index,
                                        "data-test-hook":
                                          "history-record-maintenance" + index
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "row no-gutters mb-2" },
                                        [
                                          _c("div", { staticClass: "col" }, [
                                            _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "font-weight-light text-small mb-2"
                                              },
                                              [
                                                history.maintenanceType == "0"
                                                  ? _c(
                                                      "span",
                                                      {
                                                        attrs: {
                                                          "data-test-hook":
                                                            "userRecord" + index
                                                        }
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "far fa-user fa-sm"
                                                        }),
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm._f(
                                                                "formatLabel"
                                                              )("xUserRecord")
                                                            ) +
                                                            " "
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _c(
                                                  "span",
                                                  {
                                                    attrs: {
                                                      "data-test-hook":
                                                        "maintenanceDate" +
                                                        index
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm
                                                          .$moment(
                                                            history.properties
                                                              .eventOccurred
                                                          )
                                                          .format(
                                                            _vm.$t("xShortDate")
                                                          )
                                                      )
                                                    )
                                                  ]
                                                ),
                                                history.properties.runHours
                                                  ? _c(
                                                      "span",
                                                      {
                                                        attrs: {
                                                          "data-test-hook":
                                                            "runHours" + index
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          " / " +
                                                            _vm._s(
                                                              history.properties
                                                                .runHours
                                                            ) +
                                                            " " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "xRunHours"
                                                              )
                                                            ) +
                                                            " "
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                history.properties.totalCost
                                                  ? _c(
                                                      "span",
                                                      {
                                                        attrs: {
                                                          "data-test-hook":
                                                            "totalCost" + index
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          " / " +
                                                            _vm._s(
                                                              _vm._f(
                                                                "formatCurrency"
                                                              )(
                                                                history
                                                                  .properties
                                                                  .totalCost
                                                              )
                                                            ) +
                                                            " "
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e()
                                              ]
                                            ),
                                            _c("p", [
                                              _c("strong", [
                                                _c("i", {
                                                  staticClass:
                                                    "far fa-tools mr-2"
                                                }),
                                                _vm._v(
                                                  " " + _vm._s(history.title)
                                                )
                                              ])
                                            ]),
                                            history.properties.description
                                              ? _c(
                                                  "p",
                                                  { staticClass: "mt-2" },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          history.properties
                                                            .description
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              : _vm._e()
                                          ]),
                                          history.maintenanceType == "0"
                                            ? _c(
                                                "button",
                                                {
                                                  staticClass:
                                                    "btn btn-icon-button",
                                                  attrs: {
                                                    type: "button",
                                                    "data-test-hook":
                                                      "pen-button-maintenance" +
                                                      index
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.showEditMaintenanceModal(
                                                        history
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass: "fas fa-pencil"
                                                  })
                                                ]
                                              )
                                            : _vm._e(),
                                          history.maintenanceType == "0"
                                            ? _c(
                                                "button",
                                                {
                                                  staticClass:
                                                    "btn btn-icon-button",
                                                  attrs: {
                                                    type: "button",
                                                    "data-test-hook":
                                                      "trash-button-maintenance" +
                                                      index
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.showDeleteMaintenanceModal(
                                                        history
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "fas fa-trash-alt"
                                                  })
                                                ]
                                              )
                                            : _vm._e()
                                        ]
                                      )
                                    ]
                                  )
                                }),
                                0
                              )
                            ])
                          ])
                        : _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col" }, [
                              _c(
                                "div",
                                { staticClass: "card-body pt-0 pb-3" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("xNoMaintenanceHistory")) +
                                      " "
                                  )
                                ]
                              )
                            ])
                          ])
                    ])
                  : _vm._e()
              ]),
              _c(
                "Modal",
                {
                  attrs: {
                    modalId: "deleteMaintenanceRecordModal",
                    size: "modal-lg"
                  }
                },
                [
                  _c(
                    "ModalHeader",
                    {
                      attrs: {
                        title: _vm.$t("xDeleteMaintenanceRecord"),
                        icon: "fas fa-calendar-plus colorOrange mr-1"
                      }
                    },
                    [
                      _c("div", { staticClass: "cols-auto" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-icon-button",
                            attrs: {
                              type: "button",
                              "data-test-hook":
                                "x-button-deleteMaintenanceRecordModal-maintenance"
                            },
                            on: {
                              click: function($event) {
                                return _vm.hideDeleteMaintenanceRecordModal()
                              }
                            }
                          },
                          [_c("i", { staticClass: "fas fa-times" })]
                        )
                      ])
                    ]
                  ),
                  _c("ModalBody", [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("xDeleteMaintenanceMessage")) +
                            " "
                        )
                      ])
                    ])
                  ]),
                  _c("ModalFooter", [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-outline-warning",
                        attrs: {
                          type: "button",
                          "data-test-hook":
                            "cancel-button-deleteMaintenanceRecordModal-maintenance"
                        },
                        on: {
                          click: function($event) {
                            return _vm.closeModal(
                              "deleteMaintenanceRecordModal"
                            )
                          }
                        }
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("xCancel")) + " ")]
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-warning",
                        attrs: {
                          type: "button",
                          "data-test-hook":
                            "yes-im-sure-button-deleteMaintenanceRecordModal-maintenance"
                        },
                        on: {
                          click: function($event) {
                            return _vm.deleteMaintenanceRecord()
                          }
                        }
                      },
                      [
                        _vm._v(" " + _vm._s(_vm.$t("xYesImSure")) + " "),
                        _vm.deletingMaintenanceRecord
                          ? _c("span", {
                              staticClass: "spinner-border spinner-border-sm"
                            })
                          : _vm._e()
                      ]
                    )
                  ])
                ],
                1
              ),
              _c("AddMaintenanceModal", {
                attrs: {
                  editedMaintenanceRecord: _vm.editedMaintenanceRecord,
                  disableCancel: false,
                  editingRecord: _vm.editingRecord,
                  sendClearMaintenance: false,
                  apparatus: _vm.currentApparatusDetails
                },
                on: { maintenanceHistoryChanged: _vm.updateMaintenanceHistory }
              })
            ],
            1
          ),
      _c(
        "Modal",
        {
          attrs: {
            modalId: "selectMaintenanceHistoryDateRange",
            size: "modal-md"
          }
        },
        [
          _c(
            "ModalHeader",
            {
              attrs: {
                title: _vm.$t("xExportMaintenanceHistory"),
                icon: "fas fa-file-export colorOrange mr-1"
              }
            },
            [
              _c("div", { staticClass: "cols-auto float-right" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-icon-button",
                    attrs: {
                      type: "button",
                      "data-test-hook":
                        "x-button-selectMaintenanceHistoryDateRange-maintenance"
                    },
                    on: {
                      click: function($event) {
                        return _vm.hideSelectMaintenanceHistoryDateRangeModal()
                      }
                    }
                  },
                  [_c("i", { staticClass: "fas fa-times colorOrange" })]
                )
              ])
            ]
          ),
          _c("ModalBody", [
            _c("div", { staticClass: "row mb-4" }, [
              _c("div", { staticClass: "col" }, [
                _c("strong", [
                  _vm._v(
                    _vm._s(_vm._f("formatLabel")("xSelectYourHistoryRange"))
                  )
                ])
              ])
            ]),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col" }, [
                _c("div", { staticClass: "list-group list-group-flush" }, [
                  _c(
                    "div",
                    { staticClass: "custom-control custom-radio ml-3" },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.selectedDateRange,
                            expression: "selectedDateRange"
                          }
                        ],
                        staticClass: "custom-control-input",
                        attrs: {
                          id: "months6",
                          type: "radio",
                          value: "Months6",
                          "data-test-hook":
                            "6-months-radio-selectMaintenanceHistoryDateRange-maintenance"
                        },
                        domProps: {
                          checked: _vm._q(_vm.selectedDateRange, "Months6")
                        },
                        on: {
                          change: function($event) {
                            _vm.selectedDateRange = "Months6"
                          }
                        }
                      }),
                      _c(
                        "label",
                        {
                          staticClass: "custom-control-label",
                          attrs: { for: "months6" }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("x6Months")) + " ")]
                      )
                    ]
                  ),
                  _c("div", [_c("hr")]),
                  _c(
                    "div",
                    { staticClass: "custom-control custom-radio ml-3" },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.selectedDateRange,
                            expression: "selectedDateRange"
                          }
                        ],
                        staticClass: "custom-control-input",
                        attrs: {
                          id: "years1",
                          type: "radio",
                          value: "Years1",
                          "data-test-hook":
                            "1-year-radio-selectMaintenanceHistoryDateRange-maintenance"
                        },
                        domProps: {
                          checked: _vm._q(_vm.selectedDateRange, "Years1")
                        },
                        on: {
                          change: function($event) {
                            _vm.selectedDateRange = "Years1"
                          }
                        }
                      }),
                      _c(
                        "label",
                        {
                          staticClass: "custom-control-label",
                          attrs: { for: "years1" }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("x1Year")) + " ")]
                      )
                    ]
                  ),
                  _c("div", [_c("hr")]),
                  _c(
                    "div",
                    { staticClass: "custom-control custom-radio ml-3" },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.selectedDateRange,
                            expression: "selectedDateRange"
                          }
                        ],
                        staticClass: "custom-control-input",
                        attrs: {
                          id: "years2",
                          type: "radio",
                          value: "Years2",
                          "data-test-hook":
                            "2-years-radio-selectMaintenanceHistoryDateRange-maintenance"
                        },
                        domProps: {
                          checked: _vm._q(_vm.selectedDateRange, "Years2")
                        },
                        on: {
                          change: function($event) {
                            _vm.selectedDateRange = "Years2"
                          }
                        }
                      }),
                      _c(
                        "label",
                        {
                          staticClass: "custom-control-label",
                          attrs: { for: "years2" }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("x2Years")) + " ")]
                      )
                    ]
                  ),
                  _c("div", [_c("hr")]),
                  _c(
                    "div",
                    { staticClass: "custom-control custom-radio ml-3" },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.selectedDateRange,
                            expression: "selectedDateRange"
                          }
                        ],
                        staticClass: "custom-control-input",
                        attrs: {
                          id: "years3",
                          type: "radio",
                          value: "Years3",
                          "data-test-hook":
                            "3-years-radio-selectMaintenanceHistoryDateRange-maintenance"
                        },
                        domProps: {
                          checked: _vm._q(_vm.selectedDateRange, "Years3")
                        },
                        on: {
                          change: function($event) {
                            _vm.selectedDateRange = "Years3"
                          }
                        }
                      }),
                      _c(
                        "label",
                        {
                          staticClass: "custom-control-label",
                          attrs: { for: "years3" }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("x3Years")) + " ")]
                      )
                    ]
                  ),
                  _c("div", [_c("hr")]),
                  _c(
                    "div",
                    { staticClass: "custom-control custom-radio ml-3" },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.selectedDateRange,
                            expression: "selectedDateRange"
                          }
                        ],
                        staticClass: "custom-control-input",
                        attrs: {
                          id: "all",
                          type: "radio",
                          value: "All",
                          "data-test-hook":
                            "all-radio-selectMaintenanceHistoryDateRange-maintenance"
                        },
                        domProps: {
                          checked: _vm._q(_vm.selectedDateRange, "All")
                        },
                        on: {
                          change: function($event) {
                            _vm.selectedDateRange = "All"
                          }
                        }
                      }),
                      _c(
                        "label",
                        {
                          staticClass: "custom-control-label",
                          attrs: { for: "all" }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("xAll")) + " ")]
                      )
                    ]
                  )
                ])
              ])
            ])
          ]),
          _c("ModalFooter", [
            _c(
              "button",
              {
                staticClass: "btn btn-outline-warning",
                attrs: {
                  type: "button",
                  "data-test-hook":
                    "cancel-button-selectMaintenanceHistoryDateRange-maintenance"
                },
                on: {
                  click: function($event) {
                    return _vm.hideSelectMaintenanceHistoryDateRangeModal()
                  }
                }
              },
              [_vm._v(" " + _vm._s(_vm.$t("xCancel")) + " ")]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-warning",
                attrs: {
                  type: "button",
                  disabled:
                    _vm.downloadingMaintenanceHistory || !_vm.selectedDateRange,
                  "data-test-hook":
                    "download-button-selectMaintenanceHistoryDateRange-maintenance"
                },
                on: {
                  click: function($event) {
                    _vm.exportMaintenanceHistory(),
                      _vm.trackAppInsightsEvent(
                        "download-maintenance-history",
                        {
                          page: "Maintenance",
                          organizationId: _vm.accountProfile.organizationId,
                          userId: _vm.accountProfile.userId,
                          apparatusId: _vm.$route.params.apparatusId,
                          dateRange: _vm.selectedDateRange
                        }
                      )
                  }
                }
              },
              [
                _c("i", { staticClass: "fas fa-file-csv" }),
                _vm._v(" " + _vm._s(_vm.$t("xDownload")) + " "),
                _vm.downloadingMaintenanceHistory
                  ? _c("span", {
                      staticClass: "spinner-border spinner-border-sm"
                    })
                  : _vm._e()
              ]
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }