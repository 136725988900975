var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "b-modal",
    {
      attrs: { id: "ForgotEmailModal", "hide-footer": "", centered: "" },
      scopedSlots: _vm._u([
        {
          key: "modal-header",
          fn: function() {
            return [
              _c(
                "b-row",
                {
                  staticClass: "w-100",
                  attrs: { "align-v": "center", "no-gutters": "" }
                },
                [
                  _c("b-col", [
                    _c("h2", { staticClass: "modal-title" }, [
                      _c("i", {
                        staticClass: "fas fa-question-circle colorOrange mr-1"
                      }),
                      _vm._v(" " + _vm._s(_vm.$t("xForgotEmail")))
                    ])
                  ]),
                  _c(
                    "b-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "btn-icon-button",
                          attrs: {
                            variant: "icon-button--gray",
                            "data-test-hook": "x-button-forgot-email-modal"
                          },
                          on: {
                            click: function($event) {
                              return _vm.$bvModal.hide("ForgotEmailModal")
                            }
                          }
                        },
                        [_c("i", { staticClass: "fas fa-times" })]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "b-row",
        [
          _c("b-col", [
            _vm._v(" " + _vm._s(_vm.$t("xForHelpManagingOrResetting")) + " ")
          ])
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mt-4" },
        [
          _c("b-col", [
            _c(
              "p",
              { staticClass: "headerInline font-weight-bold mb-0 pb-0" },
              [
                _c("i", { staticClass: "fas fa-phone-alt colorOrange" }),
                _vm._v(" 855-436-8439 (USA) ")
              ]
            )
          ])
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mt-3 mb-2" },
        [
          _c("b-col", [
            _c(
              "p",
              { staticClass: "headerInline font-weight-bold mb-0 pb-0" },
              [
                _c("i", { staticClass: "fas fa-phone-alt colorOrange" }),
                _vm._v(" 844-843-9436 (Canada) ")
              ]
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }