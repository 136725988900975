<template>
  <div>
    <div class="overlay" :show="loadingNotifications" rounded="lg" id="overlay">
      <div class="card" header-bg-variant="white">
        <div class="card-header">
          <h1 class="d-inline-block mb-0">
            <i class="far fa-bell colorOrange" />
            <span class="ml-2" data-test-hook="notifications-card-title-account-settings">
              {{ $t('xNotifications') }}
            </span>
          </h1>
        </div>
        <div class="card-body">
          <div v-for="(apparatusList, idx) in listOfApparatusLists" :key="idx">
            <div v-if="apparatusList.length > 0">
              <div class="row">
                <div class="col my-2">
                  <span data-test-hook="hsb-heading-account-settings">
                    {{ $t(getTypeText(apparatusList)) }}
                  </span>
                </div>
              </div>
            </div>
            <div
              v-for="(apparatus, prefIndex) in apparatusList"
              :key="apparatus.apparatusId"
              class="accordion"
              role="tablist"
              style="width:100%"
            >
              <div class="apparatus-wrapper">
                <div>
                  <div
                    role="button"
                    tabindex="0"
                    aria-expanded="false"
                    :aria-controls="'accordion-' + apparatus.apparatusId"
                    style="overflow-anchor: none;"
                    @click="getPreferenceInfo(apparatus)"
                    :data-toggle="'collapse-' + apparatus.apparatusId"
                    :data-target="'#accordion-' + apparatus.apparatusId"
                    class="row noClickOutline details-header"
                    :data-test-hook="'apparatus-button-' + apparatus.apparatusId + '-account-settings'"
                  >
                    <div v-if="!apparatusPreference || apparatusPreference.id != apparatus.apparatusId" class="col">
                      <div class="row align-items-center no-gutters">
                        <div class="col-auto" data-test-hook="notifications-apparatus-name-account-settings">
                          <strong>{{ apparatus.apparatusName }}</strong>
                        </div>
                        <div class="col ml-2"></div>
                        <div class="col-auto">
                          <i class="fas fa-chevron-down"></i>
                        </div>
                      </div>
                    </div>
                    <div v-else-if="apparatusPreference && apparatusPreference.id == apparatus.apparatusId" class="col">
                      <div class="row align-items-center no-gutters">
                        <div class="col-auto" data-test-hook="notifications-apparatus-name-account-settings">
                          <strong>{{ apparatus.apparatusName }}</strong>
                        </div>
                        <div class="col ml-2"></div>
                        <div class="col-auto">
                          <i class="fas fa-chevron-up"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    :id="'accordion-' + apparatus.apparatusId"
                    :class="'collapse-' + apparatus.apparatusId"
                    role="tabpanel"
                    :visible="loadingApparatusId == apparatus.apparatusId"
                    v-if="loadingApparatusId == apparatus.apparatusId"
                  >
                    <div
                      v-if="apparatusPreference && apparatusPreference.id == apparatus.apparatusId"
                      class="pt-2 mt-3 border-top"
                    >
                      <div class="row pb-1 justify-content-between">
                        <div class="col align-self-center">
                          <h5 data-test-hook="notifications-primary-contact-title-account-settings" class="mb-0">
                            <i class="far fa-user-circle colorOrange mr-2"></i>{{ $t('xPrimaryContact') }}
                          </h5>
                        </div>
                        <div class="col text-right">
                          <button
                            class="btn btn-icon-button"
                            @click="showEditPrimaryModal(apparatusPreference, apparatus.apparatusId)"
                            data-test-hook="notifications-edit-primary-contact-button-account-settings"
                          >
                            <i class="far fa-pencil colorOrange hoverPointer fa-md"></i>
                          </button>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col">
                          <p data-test-hook="notifications-primary-contact-phone-account-settings">
                            {{ apparatusPreference.phones[0] | phone }}
                          </p>
                          <p data-test-hook="notifications-primary-contact-email-account-settings">
                            {{ apparatusPreference.emails[0] }}
                          </p>
                        </div>
                      </div>
                      <div class="row pt-3 align-items-center no-gutters">
                        <div class="col align-self-center">
                          <h5 class="mb-0" data-test-hook="notifications-email-prefs-title-account-settings">
                            <i class="far fa-envelope mr-2 colorOrange"></i>
                            {{ $t('xEmailPreferences') }}
                          </h5>
                        </div>
                        <div class="col-auto text-right">
                          <button
                            class="btn btn-icon-button"
                            @click="
                              showEditEmailPreferencesModal(
                                apparatusPreference,
                                apparatus.apparatusId,
                                apparatus.apparatusType,
                                apparatusPreference.threshold1,
                                apparatusPreference.deviceType
                              )
                            "
                            data-test-hook="notifications-edit-email-prefs-button-account-settings"
                          >
                            <i class="far fa-cog colorOrange"></i>
                          </button>
                        </div>
                      </div>
                      <div class="row pt-3 no-gutters align-items-center">
                        <div class="col">
                          <h5 class="mb-0" data-test-hook="notifications-sms-prefs-title-account-settings">
                            <i class="far fa-mobile-android-alt mr-2 colorOrange"></i>
                            {{ $t('xTextSmsPreferences') }}
                          </h5>
                        </div>
                        <div class="col-auto float-right">
                          <button
                            class="btn btn-icon-button"
                            @click="
                              showEditTextSmsPreferencesModal(
                                apparatusPreference,
                                apparatus.apparatusId,
                                apparatus.apparatusType,
                                apparatusPreference.threshold1
                              )
                            "
                            data-test-hook="notifications-edit-sms-prefs-button-account-settings"
                          >
                            <i class="far fa-cog colorOrange"></i>
                          </button>
                        </div>
                      </div>

                      <div
                        class="row no-gutters pt-4 pb-2 align-items-center justify-content-between"
                        data-test-hook="notifications-expand-additional-contacts-btn-account-settings"
                      >
                        <div class="col-auto align-self-center">
                          <h5 data-test-hook="notifications-additional-contacts-title-account-settings" class="mb-0">
                            <i class="far fa-users mr-2 colorOrange"></i>
                            {{ $t('xAdditionalContacts') }}
                          </h5>
                        </div>
                      </div>
                      <div v-for="(email, index) in apparatusPreference.emails" :key="email">
                        <div v-if="index != 0" class="row mb-2 align-items-center no-gutters">
                          <div class="col">
                            <div class="row">
                              <div class="col">
                                <small>{{ $t('xEmail') }}&nbsp;{{ index }}</small>
                              </div>
                            </div>
                            <div class="row">
                              <div
                                class="col"
                                data-test-hook="notifications-additional-contacts-email-account-settings"
                              >
                                {{ email }}
                              </div>
                            </div>
                          </div>

                          <div class="col-auto">
                            <button
                              class="btn btn-icon-button"
                              @click="
                                showDeleteModal(
                                  email,
                                  'email',
                                  index,
                                  apparatusPreference,
                                  prefIndex,
                                  apparatus.apparatusId
                                )
                              "
                              data-test-hook="notifications-additional-contacts-email-delete-btn-account-settings"
                            >
                              <i class="far fa-trash-can"></i>
                            </button>
                          </div>
                          <div class="col-auto">
                            <button
                              class="btn btn-icon-button"
                              @click="
                                showAddEditModal(
                                  email,
                                  'email',
                                  index,
                                  'edit',
                                  apparatusPreference,
                                  prefIndex,
                                  apparatus.apparatusId
                                )
                              "
                              data-test-hook="notifications-additional-contacts-email-edit-btn-account-settings"
                            >
                              <i class="far fa-pencil"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div v-for="(phone, index) in apparatusPreference.phones" :key="phone">
                        <div
                          v-if="phone != apparatusPreference.phones[0]"
                          class="mb-2 row align-items-center no-gutters"
                        >
                          <div class="col">
                            <div class="row">
                              <div class="col">
                                <small>{{ $t('xPhoneNumber') }}&nbsp;{{ index }}</small>
                              </div>
                            </div>
                            <div class="row">
                              <div
                                class="col"
                                data-test-hook="notifications-additional-contacts-phone-account-settings"
                              >
                                {{ phone | phone }}
                              </div>
                            </div>
                          </div>
                          <div class="col-auto">
                            <button
                              class="btn btn-icon-button"
                              @click="
                                showDeleteModal(
                                  phone,
                                  'phone',
                                  index,
                                  apparatusPreference,
                                  prefIndex,
                                  apparatus.apparatusId
                                )
                              "
                              data-test-hook="notifications-additional-contacts-phone-delete-btn-account-settings"
                            >
                              <i class="far fa-trash-can"></i>
                            </button>
                          </div>
                          <div class="col-auto">
                            <button
                              class="btn btn-icon-button"
                              @click="
                                showAddEditModal(
                                  phone,
                                  'phone',
                                  index,
                                  'edit',
                                  apparatusPreference,
                                  prefIndex,
                                  apparatus.apparatusId
                                )
                              "
                              data-test-hook="notifications-additional-contacts-phone-edit-btn-account-settings"
                            >
                              <i class="far fa-pencil"></i>
                            </button>
                          </div>
                        </div>
                      </div>

                      <div class="row py-2">
                        <div class="col">
                          <button
                            class="btn btn-outline-warning"
                            @click="
                              showAddEditModal(
                                '',
                                'email',
                                0,
                                'add',
                                apparatusPreference,
                                prefIndex,
                                apparatus.apparatusId
                              )
                            "
                            :disabled="apparatusPreference.emails.length >= 4"
                            data-test-hook="notifications-additional-contacts-email-add-btn-account-settings"
                          >
                            <i class="fas fa-plus" />
                            {{ $t('xAddEmail') }}
                          </button>

                          <button
                            class="mt-2 mt-md-0 ml-2 btn btn-outline-warning"
                            @click="
                              showAddEditModal(
                                '',
                                'phone',
                                0,
                                'add',
                                apparatusPreference,
                                prefIndex,
                                apparatus.apparatusId
                              )
                            "
                            :disabled="apparatusPreference.phones.length >= 4"
                            data-test-hook="notifications-additional-contacts-phone-add-btn-account-settings"
                          >
                            <i class="fas fa-plus"></i>
                            {{ $t('xAddPhoneNumber') }}
                          </button>
                        </div>
                      </div>
                    </div>
                    <div v-else-if="!apparatusPreference">
                      <div class="row text-center float-center">
                        <div class="col">
                          <div
                            style="width: 5rem; height: 5rem;"
                            class="spinner-border text-warning"
                            label="Spinning"
                          ></div>
                        </div>
                      </div>
                    </div>
                    <div v-else-if="loadingApparatusId == apparatus.apparatusId">
                      <div class="row text-center float-center">
                        <div class="col">
                          <div
                            style="width: 5rem; height: 5rem;"
                            class="spinner-border text-warning"
                            label="Spinning"
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <EditPrimaryInfoModal v-if="modalInfo" :modalInfo="modalInfo" @saveEditPrimary="saveEditPrimary" />
    <EditEmailPreferencesModal
      v-if="editEmailPreferencesModalInfo"
      :editEmailPreferencesModalInfo="editEmailPreferencesModalInfo"
      @buildPreferenceInfo="buildPreferenceInfo"
    />
    <EditTextSmsPreferencesModal
      v-if="editTextSmsPreferencesModalInfo"
      :editTextSmsPreferencesModalInfo="editTextSmsPreferencesModalInfo"
      @buildPreferenceInfo="buildPreferenceInfo"
    />
    <ConfirmDeleteModal
      v-if="confirmDeleteModalInfo"
      :confirmDeleteModalInfo="confirmDeleteModalInfo"
      @confirmDelete="confirmDelete"
    />
    <AddEditContactInfoModal
      v-if="addEditContactModalInfo"
      :addEditContactModalInfo="addEditContactModalInfo"
      @saveAddEdit="saveAddEdit"
    />
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import EditPrimaryInfoModal from '@/components/AccountSettings/EditPrimaryInfoModal'
import EditEmailPreferencesModal from '@/components/AccountSettings/EditEmailPreferencesModal'
import EditTextSmsPreferencesModal from '@/components/AccountSettings/EditTextSmsPreferencesModal'
import ConfirmDeleteModal from '@/components/AccountSettings/ConfirmDeleteModal'
import AddEditContactInfoModal from '@/components/AccountSettings/AddEditContactInfoModal'
import * as Constants from '@/constants'
import { openModal } from '../../utils/mixins'

export default {
  components: {
    EditPrimaryInfoModal,
    EditEmailPreferencesModal,
    EditTextSmsPreferencesModal,
    AddEditContactInfoModal,
    ConfirmDeleteModal
  },
  data() {
    return {
      modalInfo: null,
      editEmailPreferencesModalInfo: null,
      editTextSmsPreferencesModalInfo: null,
      currentApparatus: null,
      currentPreferences: [],
      currentApparatusesPreferences: [],
      emailPreferenceSaving: [],
      smsPreferenceSaving: [],
      preferences: [],
      hsbApparatuses: [],
      tankApparatuses: [],
      listOfApparatusLists: [],
      apparatusPreference: null,
      loadingNotifications: false,
      addEditContactModalInfo: null,
      confirmDeleteModalInfo: null,
      loadingApparatusId: null
    }
  },
  computed: {
    ...mapState({
      notificationApparatuses: state => state.account.notificationApparatuses
    })
  },
  methods: {
    async getPreferenceInfo(apparatus) {
      if (!apparatus) return
      if (
        !this.apparatusPreference ||
        (this.apparatusPreference && this.apparatusPreference.id != apparatus.apparatusId)
      ) {
        this.loadingApparatusId = apparatus.apparatusId
        await this.$store
          .dispatch('getApparatusPreferences', apparatus)
          .then(response => {
            this.apparatusPreference = response.data
          })
          .catch(error => {})
      } else {
        this.apparatusPreference = null
        this.loadingApparatusId = null
      }
    },
    async buildPreferenceInfo() {
      let apparatusesPrefs = []
      this.notificationApparatuses.forEach(apparatus => {
        apparatusesPrefs.push({
          apparatusId: apparatus.apparatusId,
          apparatusType: apparatus.apparatusType,
          name: apparatus.apparatusName
        })
      })
      this.loadingApparatusId = null
    },
    async showEditPrimaryModal(contacts, apparatusId) {
      this.currentPreferences = _.cloneDeep(contacts)
      this.modalInfo = {
        phone: contacts.phones[0],
        email: contacts.emails[0],
        apparatusId: apparatusId
      }
      setTimeout(() => {
        this.openModal('EditPrimaryInfoModal-' + apparatusId)
      }, 10)
    },
    showEditEmailPreferencesModal(preferences, apparatusId, apparatusType, threshold1) {
      this.currentPreferences = _.cloneDeep(preferences)
      this.editEmailPreferencesModalInfo = {
        currentPreferences: preferences,
        apparatusId: apparatusId,
        apparatusType: apparatusType,
        threshold1: threshold1
      }
      setTimeout(() => {
        this.openModal('EditEmailPreferencesModal-' + apparatusId)
      }, 10)
    },
    showEditTextSmsPreferencesModal(preferences, apparatusId, apparatusType, threshold1) {
      this.editTextSmsPreferencesModalInfo = {
        currentPreferences: preferences,
        apparatusId: apparatusId,
        apparatusType: apparatusType,
        threshold1: threshold1
      }
      setTimeout(() => {
        this.openModal('EditTextSmsPreferencesModal-' + apparatusId)
      }, 10)
    },
    saveEditPrimary(info) {
      let contactInfo = _.cloneDeep(this.currentPreferences)
      contactInfo.emails[0] = info.email

      this.$store
        .dispatch('updateContactInfo', {
          phones: [info.phone],
          emails: contactInfo.emails,
          changePrimary: true
        })
        .then(response => {
          this.apparatusPreference.phones[0] = info.phone
          this.apparatusPreference.emails = contactInfo.emails
          this.$store.dispatch('getNotificationApparatuses').then(response => {
            this.buildPreferenceInfo()
          })
        })
        .catch(_ => {
          this.$store.dispatch('displayToast', {
            title: this.$t('xError'),
            message: this.$t('xUpdatingYourInformationFailedPleaseTryAgain'),
            variant: 'danger'
          })
        })
        .finally(() => {
          this.closeModal('EditPrimaryInfoModal-' + info.apparatusId)
        })
    },
    confirmDelete(info) {
      let contactInfo = _.cloneDeep(info.contactInfo)
      if (info.infoType == 'phone') {
        contactInfo.phones.splice(info.index, 1)
      }
      if (info.infoType == 'email') {
        contactInfo.emails.splice(info.index, 1)
      }
      this.$store
        .dispatch('updateContactInfo', {
          phones: contactInfo.phones,
          emails: contactInfo.emails
        })
        .then(response => {
          this.apparatusPreference.phones = contactInfo.phones
          this.apparatusPreference.emails = contactInfo.emails
          this.buildPreferenceInfo()
        })
    },
    showDeleteModal(info, infoType, index, contactInfo, prefIndex, apparatusId) {
      this.confirmDeleteModalInfo = {
        value: info,
        infoType: infoType,
        index: index,
        contactInfo: contactInfo,
        prefIndex: prefIndex,
        apparatusId: apparatusId
      }
      setTimeout(() => {
        this.openModal('ConfirmDeleteModal-' + apparatusId)
      }, 10)
    },
    showAddEditModal(info, infoType, index, actionType, contacts, preferenceIndex, apparatusId) {
      this.addEditContactModalInfo = {
        value: info,
        actionType: actionType,
        infoType: infoType,
        index: index,
        contacts: contacts,
        prefIndex: preferenceIndex,
        apparatusId: apparatusId
      }
      if (infoType == 'email') {
        if (actionType == 'add' && contacts.emails.length == 4) {
          this.$store.dispatch('displayToast', {
            title: this.$t('xError'),
            message: this.$t('xYouCanOnlyAddFourEmailAddresses'),
            variant: 'danger'
          })
        } else {
          setTimeout(() => {
            this.openModal('AddEditContactInfoModal-' + apparatusId)
          }, 10)
        }
      }

      if (infoType == 'phone') {
        if (actionType == 'add' && contacts.phones.length == 4) {
          this.$store.dispatch('displayToast', {
            title: this.$t('xError'),
            message: this.$t('xYouCanOnlyAddFourPhoneNumbers'),
            variant: 'danger'
          })
        } else {
          setTimeout(() => {
            this.openModal('AddEditContactInfoModal-' + apparatusId)
          }, 10)
        }
      }
    },
    saveAddEdit(info) {
      let contactInfo = _.cloneDeep(info.contacts)
      if (info.actionType == 'edit') {
        if (info.infoType == 'phone') {
          contactInfo.phones[info.index] = info.value
        }
        if (info.infoType == 'email') {
          contactInfo.emails[info.index] = info.value
        }
      }
      if (info.actionType == 'add') {
        if (info.infoType == 'phone') {
          contactInfo.phones.push(info.value)
        }
        if (info.infoType == 'email') {
          contactInfo.emails.push(info.value)
        }
      }

      this.$store
        .dispatch('updateContactInfo', {
          phones: contactInfo.phones,
          emails: contactInfo.emails
        })
        .then(response => {
          this.apparatusPreference = contactInfo.phones
          this.apparatusPreference = contactInfo.emails
          this.buildPreferenceInfo()
        })
    },
    isTankType(type) {
      if (type == Constants.FuelTankApparatusType) {
        return true
      }
      return false
    },
    getTypeText(list) {
      switch (list[0]?.apparatusType) {
        case 0:
          return 'xHSBGenerators'
        case 2:
          return 'xTanks'
        default:
          return null
      }
    }
  },
  async mounted() {
    this.loadingNotifications = true
    if (!this.notificationApparatuses || this.notificationApparatuses == null) {
      await this.$store.dispatch('getNotificationApparatuses')
    }
    this.notificationApparatuses.forEach(apparatus => {
      if (apparatus.apparatusType == Constants.HsbApparatusType) {
        this.hsbApparatuses.push(apparatus)
      } else if (apparatus.apparatusType == Constants.FuelTankApparatusType) {
        this.tankApparatuses.push(apparatus)
      }
    })
    this.listOfApparatusLists.push(this.hsbApparatuses)
    this.listOfApparatusLists.push(this.tankApparatuses)
    await this.buildPreferenceInfo()
    this.loadingNotifications = false
  }
}
</script>
<style scoped lang="scss">
.apparatus-wrapper {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  padding: 0.75em;
  margin-bottom: 0.5em;
}
.statusText {
  display: inline;
  text-transform: capitalize;
}
.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}
</style>
