var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          attrs: {
            id: "serial-number-step-1",
            size: "lg",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            "no-fade": "",
            "no-stacking": "",
            centered: ""
          },
          scopedSlots: _vm._u([
            {
              key: "modal-header",
              fn: function() {
                return [
                  _c("h2", { staticClass: "modal-title" }, [
                    _c("i", { staticClass: "fas fa-plus colorOrange mr-1" }),
                    _vm._v(" " + _vm._s(_vm.$t("xAddAGen")))
                  ])
                ]
              },
              proxy: true
            },
            {
              key: "modal-footer",
              fn: function() {
                return [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        variant: "outline-warning",
                        "data-test-hook": "cancel-button-step1-enrollment"
                      },
                      on: {
                        click: function($event) {
                          return _vm.closeEnrollmentModals("sn-verification")
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("xCancel")) + " ")]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: {
                        variant: "primary",
                        disabled: _vm.validatingSN,
                        "data-test-hook": "ContinueBtn-step1-enrollment"
                      },
                      on: {
                        click: function($event) {
                          return _vm.validateSerialNumber()
                        }
                      }
                    },
                    [
                      _vm._v(" " + _vm._s(_vm.$t("xContinue")) + " "),
                      _c("i", { staticClass: "fas fa-arrow-right fa-lg" }),
                      _vm.validatingSN
                        ? _c("b-spinner", { staticClass: "ml-2" })
                        : _vm._e()
                    ],
                    1
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c(
            "b-row",
            { staticClass: "mb-2", attrs: { "align-v": "center" } },
            [
              _c("b-col", [
                _c("h5", [
                  _c("strong", [_vm._v(_vm._s(_vm.$t("xEnterYourGenSN")))])
                ])
              ]),
              _c(
                "b-col",
                {
                  staticClass: "float-right text-right",
                  attrs: { cols: "auto" }
                },
                [
                  _c("i", {
                    directives: [
                      {
                        name: "b-modal",
                        rawName: "v-b-modal.generator-serial-number-help",
                        modifiers: { "generator-serial-number-help": true }
                      }
                    ],
                    staticClass:
                      "fas fa-info-circle fa-lg colorOrange hoverPointer"
                  })
                ]
              )
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _c(
                    "b-input-group",
                    [
                      _c("b-form-input", {
                        attrs: {
                          maxlength: "30",
                          autocomplete: "new-password",
                          "data-test-hook": "SerialNumberField"
                        },
                        model: {
                          value: _vm.userEnteredSerialNumber,
                          callback: function($$v) {
                            _vm.userEnteredSerialNumber = $$v
                          },
                          expression: "userEnteredSerialNumber"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm.showSerialNumberValidationError &&
          _vm.serialNumberValidationMessage
            ? _c(
                "div",
                {
                  staticClass: "alert alert-danger alert--validation",
                  attrs: {
                    show: _vm.showSerialNumberValidationError,
                    "data-test-hook": "SerialNumberInvalidText"
                  }
                },
                [_vm._v(" " + _vm._s(_vm.serialNumberValidationMessage) + " ")]
              )
            : _vm._e(),
          _c(
            "b-row",
            { staticClass: "mt-5", attrs: { "align-v": "center" } },
            [
              _c(
                "b-col",
                { staticClass: "mr-0 pr-0" },
                [
                  _c("b-progress", {
                    attrs: { value: 16.67, variant: "primary" }
                  })
                ],
                1
              ),
              _c(
                "b-col",
                { staticClass: "ml-0 pl-0", attrs: { cols: "auto" } },
                [
                  _c("i", {
                    staticClass:
                      "fas fa-shield-check fa-lg inProgress ml-1 pl-0"
                  })
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "generator-serial-number-help",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            "no-fade": "",
            "no-stacking": "",
            "hide-footer": "",
            centered: ""
          },
          scopedSlots: _vm._u([
            {
              key: "modal-header",
              fn: function() {
                return [
                  _c(
                    "b-row",
                    { attrs: { "align-v": "center" } },
                    [
                      _c(
                        "b-col",
                        { staticClass: "pl-0", attrs: { cols: "auto" } },
                        [
                          _c(
                            "b-button",
                            {
                              directives: [
                                {
                                  name: "b-modal",
                                  rawName: "v-b-modal.serial-number-step-1",
                                  modifiers: { "serial-number-step-1": true }
                                }
                              ],
                              attrs: {
                                variant: "icon-button",
                                "data-test-hook": "x-button-snHelp-enrollment"
                              }
                            },
                            [
                              _c("i", {
                                staticClass:
                                  "fas fa-chevron-left back-chevron-button"
                              })
                            ]
                          )
                        ],
                        1
                      ),
                      _c("b-col", [
                        _c("h2", { staticClass: "modal-title" }, [
                          _vm._v(" " + _vm._s(_vm.$t("xSNHelp")) + " ")
                        ])
                      ])
                    ],
                    1
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c(
            "b-row",
            { staticClass: "my-3" },
            [
              _c(
                "b-col",
                { staticClass: "d-flex justify-content-center" },
                [
                  _c("b-img", {
                    staticClass: "sn-tag-img",
                    attrs: {
                      alt: "Serial Number Illustration",
                      fluid: "",
                      src: require("@/assets/images/serial-number-tag.svg")
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "mt-5" },
            [
              _c("b-col", [
                _c("p", [
                  _c("strong", [
                    _vm._v(_vm._s(_vm.$t("xLocateTheGeneratorSerialNumber")))
                  ])
                ]),
                _c("p", [
                  _vm._v(_vm._s(_vm.$t("x1BehindTheFrontPanelOfYourGenerator")))
                ]),
                _c("p", [
                  _vm._v(
                    _vm._s(
                      _vm.$t("x2OnTheBackOfTheBoxOfYourGeneratorPackaging")
                    )
                  )
                ])
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "gen-name-step-2",
            size: "lg",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            "no-fade": "",
            "no-stacking": "",
            centered: ""
          },
          scopedSlots: _vm._u([
            {
              key: "modal-header",
              fn: function() {
                return [
                  _c(
                    "b-row",
                    { attrs: { "align-v": "center" } },
                    [
                      _c(
                        "b-col",
                        { staticClass: "pr-0", attrs: { cols: "auto" } },
                        [
                          _c(
                            "b-button",
                            {
                              directives: [
                                {
                                  name: "b-modal",
                                  rawName: "v-b-modal.serial-number-step-1",
                                  modifiers: { "serial-number-step-1": true }
                                }
                              ],
                              attrs: {
                                variant: "icon-button",
                                "data-test-hook": "back-button-step2-enrollment"
                              }
                            },
                            [
                              _c("i", {
                                staticClass:
                                  "fas fa-chevron-left back-chevron-button"
                              })
                            ]
                          )
                        ],
                        1
                      ),
                      _c("b-col", [
                        _c("h2", { staticClass: "modal-title" }, [
                          _c("i", {
                            staticClass: "fas fa-plus colorOrange mr-1"
                          }),
                          _vm._v(" " + _vm._s(_vm.$t("xAddAGen")))
                        ])
                      ])
                    ],
                    1
                  )
                ]
              },
              proxy: true
            },
            {
              key: "modal-footer",
              fn: function() {
                return [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        variant: "outline-warning",
                        "data-test-hook": "cancel-button-step2-enrollment"
                      },
                      on: {
                        click: function($event) {
                          return _vm.closeEnrollmentModals("set-apparatus-name")
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("xCancel")) + " ")]
                  ),
                  _c(
                    "b-button",
                    {
                      directives: [
                        {
                          name: "b-modal",
                          rawName: "v-b-modal.generator-fuel-type-step-3",
                          modifiers: { "generator-fuel-type-step-3": true }
                        }
                      ],
                      attrs: {
                        variant: "primary",
                        disabled: !_vm.generatorName,
                        "data-test-hook": "ContinueBtn-step2-enrollment"
                      }
                    },
                    [
                      _vm._v(" " + _vm._s(_vm.$t("xContinue")) + " "),
                      _c("i", { staticClass: "fas fa-arrow-right" })
                    ]
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c(
            "b-row",
            [
              _c("b-col", [
                _c("h5", [
                  _c("strong", [_vm._v(_vm._s(_vm.$t("xNameYourGen")))])
                ])
              ])
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _c(
                    "b-input-group",
                    [
                      _c("b-form-input", {
                        attrs: {
                          maxlength: "50",
                          autocomplete: "new-password",
                          "data-test-hook": "GeneratorNameField"
                        },
                        model: {
                          value: _vm.generatorName,
                          callback: function($$v) {
                            _vm.generatorName = $$v
                          },
                          expression: "generatorName"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "mt-5", attrs: { "align-v": "center" } },
            [
              _c(
                "b-col",
                { staticClass: "mr-0 pr-0" },
                [
                  _c("b-progress", {
                    attrs: { value: 33.33, variant: "primary" }
                  })
                ],
                1
              ),
              _c(
                "b-col",
                { staticClass: "ml-0 pl-0", attrs: { cols: "auto" } },
                [
                  _c("i", {
                    staticClass:
                      "fas fa-shield-check fa-lg inProgress ml-1 pl-0"
                  })
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "generator-fuel-type-step-3",
            size: "lg",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            "no-fade": "",
            "no-stacking": "",
            centered: ""
          },
          scopedSlots: _vm._u([
            {
              key: "modal-header",
              fn: function() {
                return [
                  _c(
                    "b-row",
                    { attrs: { "align-v": "center" } },
                    [
                      _c(
                        "b-col",
                        { staticClass: "pr-0", attrs: { cols: "auto" } },
                        [
                          _c(
                            "b-button",
                            {
                              directives: [
                                {
                                  name: "b-modal",
                                  rawName:
                                    "v-b-modal.v-b-modal.gen-name-step-2",
                                  modifiers: {
                                    "v-b-modal": true,
                                    "gen-name-step-2": true
                                  }
                                }
                              ],
                              attrs: {
                                variant: "icon-button",
                                "data-test-hook": "back-button-step3-enrollment"
                              }
                            },
                            [
                              _c("i", {
                                staticClass:
                                  "fas fa-chevron-left back-chevron-button"
                              })
                            ]
                          )
                        ],
                        1
                      ),
                      _c("b-col", [
                        _c("h2", { staticClass: "modal-title" }, [
                          _c("i", {
                            staticClass: "fas fa-plus colorOrange mr-1"
                          }),
                          _vm._v(" " + _vm._s(_vm.$t("xSelectFuelType")))
                        ])
                      ])
                    ],
                    1
                  )
                ]
              },
              proxy: true
            },
            {
              key: "modal-footer",
              fn: function() {
                return [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        variant: "outline-warning",
                        "data-test-hook": "cancel-button-step3-enrollment"
                      },
                      on: {
                        click: function($event) {
                          return _vm.closeEnrollmentModals(
                            "add-apparatus-fuel-type"
                          )
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("xCancel")) + " ")]
                  ),
                  _c(
                    "b-button",
                    {
                      directives: [
                        {
                          name: "b-modal",
                          rawName: "v-b-modal.generator-address-step-4",
                          modifiers: { "generator-address-step-4": true }
                        }
                      ],
                      attrs: {
                        variant: "primary",
                        "data-test-hook": "ContinueBtn-step3-enrollment",
                        disabled: _vm.selectedFuelType == null
                      },
                      on: {
                        click: function($event) {
                          return _vm.setFuelType()
                        }
                      }
                    },
                    [
                      _vm._v(" " + _vm._s(_vm.$t("xContinue")) + " "),
                      _c("i", { staticClass: "fas fa-arrow-right" })
                    ]
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c(
            "b-row",
            [
              _c("b-col", [
                _c("h5", [_c("strong", [_vm._v(_vm._s(_vm.$t("xFuelType")))])])
              ])
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _c("b-form-select", {
                    attrs: { options: _vm.fuelOptions },
                    scopedSlots: _vm._u([
                      {
                        key: "first",
                        fn: function() {
                          return [
                            _c(
                              "b-form-select-option",
                              { attrs: { value: null, disabled: "" } },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("xPleaseSelectYourFuelType"))
                                )
                              ]
                            )
                          ]
                        },
                        proxy: true
                      }
                    ]),
                    model: {
                      value: _vm.selectedFuelType,
                      callback: function($$v) {
                        _vm.selectedFuelType = $$v
                      },
                      expression: "selectedFuelType"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "mt-5", attrs: { "align-v": "center" } },
            [
              _c(
                "b-col",
                { staticClass: "mr-0 pr-0" },
                [
                  _c("b-progress", {
                    attrs: { value: 50.0, variant: "primary" }
                  })
                ],
                1
              ),
              _c(
                "b-col",
                { staticClass: "ml-0 pl-0", attrs: { cols: "auto" } },
                [
                  _c("i", {
                    staticClass:
                      "fas fa-shield-check fa-lg inProgress ml-1 pl-0"
                  })
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "generator-address-step-4",
            size: "lg",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            "no-fade": "",
            "no-stacking": "",
            centered: ""
          },
          scopedSlots: _vm._u([
            {
              key: "modal-header",
              fn: function() {
                return [
                  _c(
                    "b-row",
                    { attrs: { "align-v": "center" } },
                    [
                      _c(
                        "b-col",
                        { staticClass: "pr-0", attrs: { cols: "auto" } },
                        [
                          _c(
                            "b-button",
                            {
                              directives: [
                                {
                                  name: "b-modal",
                                  rawName:
                                    "v-b-modal.v-b-modal.generator-fuel-type-step-3",
                                  modifiers: {
                                    "v-b-modal": true,
                                    "generator-fuel-type-step-3": true
                                  }
                                }
                              ],
                              attrs: {
                                variant: "icon-button",
                                "data-test-hook": "back-button-step4-enrollment"
                              }
                            },
                            [
                              _c("i", {
                                staticClass:
                                  "fas fa-chevron-left back-chevron-button"
                              })
                            ]
                          )
                        ],
                        1
                      ),
                      _c("b-col", [
                        _c("h2", { staticClass: "modal-title" }, [
                          _c("i", {
                            staticClass: "fas fa-plus colorOrange mr-1"
                          }),
                          _vm._v(" " + _vm._s(_vm.$t("xAddAGen")))
                        ])
                      ])
                    ],
                    1
                  )
                ]
              },
              proxy: true
            },
            {
              key: "modal-footer",
              fn: function() {
                return [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        variant: "outline-warning",
                        "data-test-hook": "cancel-button-step4-enrollment"
                      },
                      on: {
                        click: function($event) {
                          return _vm.closeEnrollmentModals(
                            "add-apparatus-address"
                          )
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("xCancel")) + " ")]
                  ),
                  _c(
                    "b-button",
                    {
                      directives: [
                        {
                          name: "b-modal",
                          rawName: "v-b-modal.confirm-gen-enrollment-step-5",
                          modifiers: { "confirm-gen-enrollment-step-5": true }
                        }
                      ],
                      attrs: {
                        variant: "primary",
                        "data-test-hook": "ContinueBtn-step4-enrollment"
                      }
                    },
                    [
                      _vm._v(" " + _vm._s(_vm.$t("xContinue")) + " "),
                      _c("i", { staticClass: "fas fa-arrow-right" })
                    ]
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c(
            "b-row",
            [
              _c("b-col", [
                _c("h5", [
                  _c("strong", [
                    _vm._v(_vm._s(_vm.$t("xSelectYourGenAddress")))
                  ])
                ])
              ])
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "mb-3" },
            [
              _c(
                "b-col",
                [
                  _c(
                    "b-form-checkbox",
                    {
                      staticClass: "font-weight-bold",
                      attrs: {
                        "data-test-hook":
                          "use-acct-address-checkbox-step4-enrollment"
                      },
                      model: {
                        value: _vm.useAccountAddress,
                        callback: function($$v) {
                          _vm.useAccountAddress = $$v
                        },
                        expression: "useAccountAddress"
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("xUseAcctAddress")) + " ")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm.useAccountAddress
            ? _c(
                "b-row",
                { attrs: { "data-test-hook": "GeneratorAddress" } },
                [
                  _c("b-col", [
                    _c("p", [
                      _vm._v(
                        _vm._s(_vm.DisplayAddress(_vm.accountProfile.address))
                      )
                    ])
                  ])
                ],
                1
              )
            : _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    [
                      _c("TypeAheadAddress", {
                        ref: "typeAheadAddressGenAddress",
                        attrs: { currentAddress: _vm.accountData.address },
                        on: { newAddress: _vm.setCurrentAddress }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
          _c(
            "b-row",
            { staticClass: "mt-5", attrs: { "align-v": "center" } },
            [
              _c(
                "b-col",
                { staticClass: "mr-0 pr-0" },
                [
                  _c("b-progress", {
                    attrs: { value: 66.67, variant: "primary" }
                  })
                ],
                1
              ),
              _c(
                "b-col",
                { staticClass: "ml-0 pl-0", attrs: { cols: "auto" } },
                [
                  _c("i", {
                    staticClass:
                      "fas fa-shield-check fa-lg inProgress ml-1 pl-0"
                  })
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "confirm-gen-enrollment-step-5",
            size: "lg",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            "no-fade": "",
            "no-stacking": "",
            "hide-header": "",
            centered: ""
          },
          scopedSlots: _vm._u([
            {
              key: "modal-header",
              fn: function() {
                return [
                  _c(
                    "b-row",
                    { attrs: { "align-v": "center" } },
                    [
                      _c(
                        "b-col",
                        { staticClass: "pr-0", attrs: { cols: "auto" } },
                        [
                          _c(
                            "b-button",
                            {
                              directives: [
                                {
                                  name: "b-modal",
                                  rawName: "v-b-modal.generator-address-step-4",
                                  modifiers: {
                                    "generator-address-step-4": true
                                  }
                                }
                              ],
                              attrs: {
                                variant: "icon-button",
                                "data-test-hook": "back-button-step5-enrollment"
                              }
                            },
                            [
                              _c("i", {
                                staticClass:
                                  "fas fa-chevron-left back-chevron-button"
                              })
                            ]
                          )
                        ],
                        1
                      ),
                      _c("b-col", [
                        _c("h2", { staticClass: "modal-title" }, [
                          _c("i", {
                            staticClass: "fas fa-plus colorOrange mr-1"
                          }),
                          _vm._v(" " + _vm._s(_vm.$t("xConfirmEnrollment")))
                        ])
                      ])
                    ],
                    1
                  )
                ]
              },
              proxy: true
            },
            {
              key: "modal-footer",
              fn: function() {
                return [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        variant: "outline-warning",
                        "data-test-hook": "cancel-button-step5-enrollment"
                      },
                      on: {
                        click: function($event) {
                          return _vm.closeEnrollmentModals("confirm-apparatus")
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("xCancel")) + " ")]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: {
                        variant: "primary",
                        disabled: _vm.enrollingApparatus,
                        "data-test-hook": "EnrollBtn-step5-enrollment"
                      },
                      on: {
                        click: function($event) {
                          return _vm.enrollApparatus()
                        }
                      }
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("xEnrollThisGenerator")) + " "
                      ),
                      _c("i", { staticClass: "fas fa-arrow-right" }),
                      _vm.enrollingApparatus
                        ? _c("b-spinner", { staticClass: "ml-2" })
                        : _vm._e()
                    ],
                    1
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c(
            "b-row",
            { staticClass: "text-center" },
            [
              _c("b-col", [
                _c("h2", [_vm._v(" " + _vm._s(_vm.$t("xYourGenInfo")) + " ")]),
                _c("h5", [_vm._v(_vm._s(_vm.generatorName))]),
                _vm.useAccountAddress
                  ? _c("p", [
                      _vm._v(
                        _vm._s(_vm.DisplayAddress(_vm.accountProfile.address))
                      )
                    ])
                  : _c("p", [
                      _vm._v(_vm._s(_vm.DisplayAddress(_vm.generatorAddress)))
                    ]),
                _c("p", [
                  _vm._v(
                    _vm._s(_vm.$t("xFuelType")) +
                      ": " +
                      _vm._s(_vm.selectedFuelTypeName)
                  )
                ])
              ])
            ],
            1
          ),
          _vm.showEnrollApparatusError
            ? _c(
                "div",
                {
                  staticClass: "alert alert-danger alert--validation",
                  attrs: { show: _vm.showEnrollApparatusError }
                },
                [_vm._v(" " + _vm._s(_vm.$t("xFailedToAddApparatus")) + " ")]
              )
            : _vm._e(),
          _c(
            "b-row",
            { staticClass: "mt-5", attrs: { "align-v": "center" } },
            [
              _c(
                "b-col",
                { staticClass: "mr-0 pr-0" },
                [
                  _c("b-progress", {
                    attrs: { value: 83.33, variant: "primary" }
                  })
                ],
                1
              ),
              _c(
                "b-col",
                { staticClass: "ml-0 pl-0", attrs: { cols: "auto" } },
                [
                  _c("i", {
                    staticClass:
                      "fas fa-shield-check fa-lg inProgress ml-1 pl-0"
                  })
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "generator-enrollment-success",
            size: "lg",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            "no-fade": "",
            "no-stacking": "",
            "hide-header": "",
            centered: ""
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function() {
                return [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        variant: "primary",
                        "data-test-hook": "AddCommBtn"
                      },
                      on: {
                        click: function($event) {
                          _vm.deviceType && (_vm.macAddress || _vm.searchLabel)
                            ? _vm.$bvModal.show("confirm-existing-device")
                            : _vm.$bvModal.show("select-device-type-step-6")
                        }
                      }
                    },
                    [
                      _vm._v(
                        " " + _vm._s("Enroll a communication device") + " "
                      ),
                      _c("i", { staticClass: "fas fa-arrow-right" })
                    ]
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c(
            "b-row",
            { staticClass: "text-center mb-3" },
            [
              _c("b-col", [
                _c("h1", [_vm._v(_vm._s(_vm.$t("xSuccess")) + "!")]),
                _c("h5", [
                  _vm._v(
                    _vm._s(_vm.$t("xYouHaveSuccessfullyEnrolledYourGenerator"))
                  )
                ])
              ])
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "text-center" },
            [
              _c(
                "b-col",
                [
                  _c("b-img", {
                    staticClass: "generator-success-img",
                    attrs: {
                      alt: "Generator Illustration",
                      fluid: "",
                      src: require("@/assets/images/gen-enrollment-success.svg")
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm.showRegistrationFailedError
            ? _c(
                "div",
                { staticClass: "alert alert--validation alert-danger" },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("xYourGeneratorWasSuccessfullyEnrolledBut")
                      ) +
                      " "
                  )
                ]
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "confirm-existing-device",
            size: "lg",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            "no-fade": "",
            "no-stacking": "",
            centered: ""
          },
          scopedSlots: _vm._u([
            {
              key: "modal-header",
              fn: function() {
                return [
                  _c("h2", { staticClass: "modal-title" }, [
                    _c("i", { staticClass: "fas fa-plus colorOrange mr-1" }),
                    _vm._v(" " + _vm._s(_vm.$t("xConfirmCommunicationDevice")))
                  ])
                ]
              },
              proxy: true
            },
            {
              key: "modal-footer",
              fn: function() {
                return [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        variant: "outline-warning",
                        "data-test-hook": "change-device-button"
                      },
                      on: {
                        click: function($event) {
                          return _vm.$bvModal.show("select-device-type-step-6")
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("xChangeDevice")) + " ")]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: {
                        variant: "primary",
                        "data-test-hook": "confirm-device-button"
                      },
                      on: {
                        click: function($event) {
                          return _vm.$bvModal.show(
                            "device-enrollment-success-step-9"
                          )
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("xConfirm")) + " ")]
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { "data-test-hook": "existing-device-text" } },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._f("formatLabel")("xOurRecordsShowExistingDevice")
                      ) +
                      " "
                  )
                ]
              )
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "mt-3" },
            [
              _c("b-col", { attrs: { cols: "12" } }, [
                _c(
                  "i",
                  {
                    staticClass: "asterisk-text",
                    attrs: { "data-test-hook": "device-type-label" }
                  },
                  [_vm._v(_vm._s(_vm._f("formatLabel")("xDeviceType")))]
                )
              ]),
              _c("b-col", { attrs: { cols: "12" } }, [
                _c("strong", { attrs: { "data-test-hook": "device-type" } }, [
                  _vm._v(_vm._s(_vm.deviceType))
                ])
              ])
            ],
            1
          ),
          _vm.deviceType == "wifi"
            ? _c(
                "b-row",
                { staticClass: "mt-3" },
                [
                  _c("b-col", { attrs: { cols: "12" } }, [
                    _c(
                      "i",
                      {
                        staticClass: "asterisk-text",
                        attrs: {
                          "data-test-hook": "device-serial-number-label"
                        }
                      },
                      [_vm._v(_vm._s(_vm._f("formatLabel")("xMacAddress")))]
                    )
                  ]),
                  _c("b-col", { attrs: { cols: "12" } }, [
                    _c(
                      "strong",
                      { attrs: { "data-test-hook": "device-serial-number" } },
                      [_vm._v(_vm._s(_vm.macAddress))]
                    )
                  ])
                ],
                1
              )
            : _c(
                "b-row",
                [
                  _c("b-col", { attrs: { cols: "12" } }, [
                    _c(
                      "i",
                      {
                        staticClass: "asterisk-text",
                        attrs: {
                          "data-test-hook": "device-serial-number-label"
                        }
                      },
                      [
                        _vm._v(
                          _vm._s(_vm._f("formatLabel")("xDeviceSerialNumber"))
                        )
                      ]
                    )
                  ]),
                  _c("b-col", { attrs: { cols: "12" } }, [
                    _vm.deviceType == _vm.hsbG3DeviceType
                      ? _c(
                          "strong",
                          {
                            attrs: { "data-test-hook": "device-serial-number" }
                          },
                          [_vm._v(_vm._s(_vm.shortDeviceId))]
                        )
                      : _c(
                          "strong",
                          {
                            attrs: { "data-test-hook": "device-serial-number" }
                          },
                          [_vm._v(_vm._s(_vm.searchLabel))]
                        )
                  ])
                ],
                1
              )
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "select-device-type-step-6",
            size: "lg",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            "no-fade": "",
            "no-stacking": "",
            centered: ""
          },
          scopedSlots: _vm._u([
            {
              key: "modal-header",
              fn: function() {
                return [
                  _c("h2", { staticClass: "modal-title" }, [
                    _c("i", { staticClass: "fas fa-plus colorOrange mr-1" }),
                    _vm._v(" " + _vm._s(_vm.$t("xAddCommunicationDevice")))
                  ])
                ]
              },
              proxy: true
            },
            {
              key: "modal-footer",
              fn: function() {
                return [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        variant: "outline-warning",
                        "data-test-hook": "cancel-button-step6-enrollment"
                      },
                      on: {
                        click: function($event) {
                          return _vm.closeEnrollmentModals(
                            "select-apparatus-device-type"
                          )
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("xCancel")) + " ")]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: {
                        variant: "primary",
                        disabled: !_vm.deviceType,
                        "data-test-hook": "ConfirmBtn-step6-enrollment"
                      },
                      on: {
                        click: function($event) {
                          return _vm.enterDeviceInformation()
                        }
                      }
                    },
                    [
                      _vm._v(" " + _vm._s(_vm.$t("xConfirm")) + " "),
                      _c("i", { staticClass: "fas fa-arrow-right" })
                    ]
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _c(
                    "b-card",
                    {
                      staticClass: "mb-3 hoverPointer",
                      class: _vm.selectedDeviceType("wifi"),
                      attrs: {
                        "data-test-hook": "wifi-clickable-card-step6-enrollment"
                      },
                      on: {
                        click: function($event) {
                          return _vm.setDeviceType("wifi")
                        }
                      }
                    },
                    [
                      _c(
                        "b-card-body",
                        [
                          _c(
                            "b-row",
                            { attrs: { "align-v": "center" } },
                            [
                              _c(
                                "b-col",
                                {
                                  staticClass: "text-center",
                                  attrs: { cols: "2" }
                                },
                                [
                                  _c("b-img", {
                                    staticClass: "device-type-img",
                                    attrs: {
                                      alt:
                                        _vm.$t("xOnBoardWiFi") +
                                        " Illustration",
                                      src: require("@/assets/images/onboard-wifi.svg")
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { attrs: { "data-test-hook": "WiFiBtn" } },
                                [
                                  _c("strong", [
                                    _vm._v(" " + _vm._s(_vm.$t("xOnBoardWiFi")))
                                  ])
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm.g3DeviceFeatureFlagEnabled(_vm.currentAppConfig)
            ? _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-card",
                        {
                          staticClass: "mb-3 hoverPointer",
                          class: _vm.selectedDeviceType(_vm.hsbG3DeviceType),
                          attrs: {
                            "data-test-hook":
                              "g3-clickable-card-step6-enrollment"
                          },
                          on: {
                            click: function($event) {
                              return _vm.setDeviceType(_vm.hsbG3DeviceType)
                            }
                          }
                        },
                        [
                          _c(
                            "b-card-body",
                            [
                              _c(
                                "b-row",
                                { attrs: { "align-v": "center" } },
                                [
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "text-center",
                                      attrs: { cols: "2" }
                                    },
                                    [
                                      _c("b-img", {
                                        staticClass: "device-type-img",
                                        attrs: {
                                          alt:
                                            _vm.$t(
                                              "xConnectivityAccessoryCellular"
                                            ) + " Illustration",
                                          src: require("@/assets/images/G3 device.svg")
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    { attrs: { "data-test-hook": "G3Btn" } },
                                    [
                                      _c("strong", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "xConnectivityAccessoryCellular"
                                              )
                                            )
                                        )
                                      ])
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _c(
                    "b-card",
                    {
                      staticClass: "mb-3 hoverPointer",
                      class: _vm.selectedDeviceType("eth"),
                      attrs: {
                        "data-test-hook": "eth-clickable-card-step6-enrollment"
                      },
                      on: {
                        click: function($event) {
                          return _vm.setDeviceType("eth")
                        }
                      }
                    },
                    [
                      _c(
                        "b-card-body",
                        [
                          _c(
                            "b-row",
                            { attrs: { "align-v": "center" } },
                            [
                              _c(
                                "b-col",
                                {
                                  staticClass: "text-center",
                                  attrs: { cols: "2" }
                                },
                                [
                                  _c("b-img", {
                                    staticClass: "device-type-img",
                                    attrs: {
                                      alt: _vm.$t("xWifiEth") + " Illustration",
                                      src: require("@/assets/images/tether-wifi.svg")
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { attrs: { "data-test-hook": "ETHBtn" } },
                                [
                                  _c("strong", [
                                    _vm._v(" " + _vm._s(_vm.$t("xWifiEth")))
                                  ])
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm.canHavePaidSubscription(_vm.accountData)
            ? _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-card",
                        {
                          staticClass: "mb-0 hoverPointer",
                          class: _vm.selectedDeviceType("lte"),
                          attrs: {
                            "data-test-hook":
                              "lte-clickable-card-step6-enrollment"
                          },
                          on: {
                            click: function($event) {
                              return _vm.setDeviceType("lte")
                            }
                          }
                        },
                        [
                          _c(
                            "b-card-body",
                            [
                              _c(
                                "b-row",
                                { attrs: { "align-v": "center" } },
                                [
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "text-center",
                                      attrs: { cols: "2" }
                                    },
                                    [
                                      _c("b-img", {
                                        staticClass: "device-type-img",
                                        attrs: {
                                          alt:
                                            _vm.$t("xLteCellular") +
                                            " Illustration",
                                          src: require("@/assets/images/lte-cellular.svg")
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    { attrs: { "data-test-hook": "LTEBtn" } },
                                    [
                                      _c("strong", [
                                        _vm._v(
                                          " " + _vm._s(_vm.$t("xLteCellular"))
                                        )
                                      ])
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "b-row",
            { staticClass: "mt-5", attrs: { "align-v": "center" } },
            [
              _c(
                "b-col",
                { staticClass: "mr-0 pr-0" },
                [
                  _c("b-progress", {
                    attrs: { value: 66.66, variant: "primary" }
                  })
                ],
                1
              ),
              _c(
                "b-col",
                { staticClass: "ml-0 pl-0", attrs: { cols: "auto" } },
                [
                  _c("i", {
                    staticClass:
                      "fas fa-shield-check fa-lg inProgress ml-1 pl-0"
                  })
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "no-wifi-module-modal",
            size: "lg",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            "no-fade": "",
            "no-stacking": "",
            centered: ""
          },
          scopedSlots: _vm._u([
            {
              key: "modal-header",
              fn: function() {
                return [
                  _c(
                    "b-row",
                    { attrs: { "align-v": "center" } },
                    [
                      _c(
                        "b-col",
                        { staticClass: "pr-0", attrs: { cols: "auto" } },
                        [
                          _c(
                            "b-button",
                            {
                              directives: [
                                {
                                  name: "b-modal",
                                  rawName:
                                    "v-b-modal.select-device-type-step-6",
                                  modifiers: {
                                    "select-device-type-step-6": true
                                  }
                                }
                              ],
                              attrs: {
                                variant: "icon-button",
                                "data-test-hook":
                                  "back-button-noWifiModule-enrollment"
                              }
                            },
                            [
                              _c("i", {
                                staticClass:
                                  "fas fa-chevron-left back-chevron-button"
                              })
                            ]
                          )
                        ],
                        1
                      ),
                      _c("b-col", [
                        _c("h2", { staticClass: "modal-title" }, [
                          _c("i", {
                            staticClass: "fas fa-plus colorOrange mr-1"
                          }),
                          _vm._v(
                            " " + _vm._s(_vm.$t("xAddCommunicationDevice"))
                          )
                        ])
                      ])
                    ],
                    1
                  )
                ]
              },
              proxy: true
            },
            {
              key: "modal-footer",
              fn: function() {
                return [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        variant: "outline-warning",
                        "data-test-hook":
                          "come-back-later-noWifiModule-enrollment"
                      },
                      on: {
                        click: function($event) {
                          _vm.logComeBackLater(), _vm.closeEnrollmentModals()
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("xComeBackLater")) + " ")]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: {
                        variant: "primary",
                        "data-test-hook":
                          "continue-button-noWifiModule-enrollment"
                      },
                      on: {
                        click: function($event) {
                          _vm.logContinueWithSetup(),
                            _vm.$bvModal.show("enter-device-info-step-7")
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("xContinueWithSetup")) + " ")]
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _vm._v(
                    " " + _vm._s(_vm.$t("xYourGeneratorDoesNotHaveWiFi")) + " "
                  ),
                  _c(
                    "b-link",
                    {
                      attrs: {
                        href: "https://www.generac.com/wifikit",
                        target: "_blank",
                        "data-test-hook": "wifikit-link-noWifiModule-enrollment"
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("xHere")) + " ")]
                  ),
                  _vm._v(
                    " " + _vm._s(_vm.$t("xAndWeWillShipOneToYouWhen")) + " "
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "enter-device-info-step-7",
            size: "lg",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            "no-fade": "",
            "no-stacking": "",
            centered: ""
          },
          scopedSlots: _vm._u([
            {
              key: "modal-header",
              fn: function() {
                return [
                  _c(
                    "b-row",
                    { attrs: { "align-v": "center" } },
                    [
                      _c(
                        "b-col",
                        { staticClass: "pr-0", attrs: { cols: "auto" } },
                        [
                          _c(
                            "b-button",
                            {
                              directives: [
                                {
                                  name: "b-modal",
                                  rawName:
                                    "v-b-modal.select-device-type-step-6",
                                  modifiers: {
                                    "select-device-type-step-6": true
                                  }
                                }
                              ],
                              attrs: {
                                variant: "icon-button",
                                "data-test-hook": "back-button-step7-enrollment"
                              }
                            },
                            [
                              _c("i", {
                                staticClass:
                                  "fas fa-chevron-left back-chevron-button"
                              })
                            ]
                          )
                        ],
                        1
                      ),
                      _c("b-col", [
                        _c("h2", { staticClass: "modal-title" }, [
                          _c("i", {
                            staticClass: "fas fa-plus colorOrange mr-1"
                          }),
                          _vm._v(
                            " " + _vm._s(_vm.$t("xAddCommunicationDevice"))
                          )
                        ])
                      ])
                    ],
                    1
                  )
                ]
              },
              proxy: true
            },
            {
              key: "modal-footer",
              fn: function() {
                return [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        variant: "outline-warning",
                        "data-test-hook": "cancel-button-step7-enrollment"
                      },
                      on: {
                        click: function($event) {
                          return _vm.closeEnrollmentModals(
                            "enter-apparatus-device-info"
                          )
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("xCancel")) + " ")]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: {
                        variant: "primary",
                        disabled: _vm.deviceInfoEntered(),
                        "data-test-hook": "ConfirmBtn-step7-enrollment"
                      },
                      on: {
                        click: function($event) {
                          return _vm.validateDevice()
                        }
                      }
                    },
                    [
                      _vm._v(" " + _vm._s(_vm.$t("xConfirm")) + " "),
                      _c("i", { staticClass: "fas fa-arrow-right" }),
                      _vm.validatingDevice
                        ? _c("b-spinner", { staticClass: "ml-2" })
                        : _vm._e()
                    ],
                    1
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _vm.deviceType == "wifi"
            ? _c(
                "div",
                [
                  _c(
                    "b-row",
                    [
                      _c("b-col", [
                        _c("h5", [
                          _c("strong", [
                            _vm._v(_vm._s(_vm.$t("xEnterYourMacAddress")))
                          ])
                        ])
                      ]),
                      _c(
                        "b-col",
                        {
                          staticClass: "float-right text-right",
                          attrs: { cols: "auto" }
                        },
                        [
                          _c("i", {
                            directives: [
                              {
                                name: "b-modal",
                                rawName: "v-b-modal.mac-address-help",
                                modifiers: { "mac-address-help": true }
                              }
                            ],
                            staticClass:
                              "fas fa-info-circle fa-lg colorOrange hoverPointer"
                          })
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        [
                          _c(
                            "b-input-group",
                            [
                              _c("b-form-input", {
                                directives: [
                                  {
                                    name: "mask",
                                    rawName: "v-mask",
                                    value: "NN:NN:NN:NN:NN:NN",
                                    expression: "'NN:NN:NN:NN:NN:NN'"
                                  }
                                ],
                                attrs: {
                                  maxlength: "17",
                                  autocomplete: "new-password",
                                  "data-test-hook": "MACField"
                                },
                                model: {
                                  value: _vm.macAddress,
                                  callback: function($$v) {
                                    _vm.macAddress = $$v
                                  },
                                  expression: "macAddress"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _c(
                "div",
                [
                  _c(
                    "b-row",
                    [
                      _c("b-col", [
                        _c("h5", [
                          _c("strong", [
                            _vm._v(_vm._s(_vm.$t("xEnterYourDeviceSN")))
                          ])
                        ])
                      ]),
                      _c(
                        "b-col",
                        {
                          staticClass: "float-right text-right",
                          attrs: { cols: "auto" }
                        },
                        [
                          _c("i", {
                            directives: [
                              {
                                name: "b-modal",
                                rawName: "v-b-modal.tether-serial-number-help",
                                modifiers: { "tether-serial-number-help": true }
                              }
                            ],
                            staticClass:
                              "fas fa-info-circle fa-lg colorOrange hoverPointer"
                          })
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        [
                          _c(
                            "b-input-group",
                            [
                              _vm.deviceType == _vm.hsbG3DeviceType
                                ? _c("b-form-input", {
                                    attrs: {
                                      maxlength: "9",
                                      autocomplete: "new-password",
                                      "data-test-hook": "SerialNumberField"
                                    },
                                    model: {
                                      value: _vm.shortDeviceId,
                                      callback: function($$v) {
                                        _vm.shortDeviceId = $$v
                                      },
                                      expression: "shortDeviceId"
                                    }
                                  })
                                : _c("b-form-input", {
                                    attrs: {
                                      maxlength: "10",
                                      autocomplete: "new-password",
                                      "data-test-hook": "SerialNumberField"
                                    },
                                    model: {
                                      value: _vm.searchLabel,
                                      callback: function($$v) {
                                        _vm.searchLabel = $$v
                                      },
                                      expression: "searchLabel"
                                    }
                                  })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
          _vm.showValidateDeviceError
            ? _c(
                "div",
                {
                  staticClass: "alert alert--validation alert-danger",
                  attrs: { show: _vm.showValidateDeviceError }
                },
                [_vm._v(" " + _vm._s(_vm.validateDeviceErrorMessage) + " ")]
              )
            : _vm._e(),
          _c(
            "b-row",
            { staticClass: "mt-5", attrs: { "align-v": "center" } },
            [
              _c(
                "b-col",
                { staticClass: "mr-0 pr-0" },
                [
                  _c("b-progress", {
                    attrs: { value: 77.77, variant: "primary" }
                  })
                ],
                1
              ),
              _c(
                "b-col",
                { staticClass: "ml-0 pl-0", attrs: { cols: "auto" } },
                [
                  _c("i", {
                    staticClass:
                      "fas fa-shield-check fa-lg inProgress ml-1 pl-0"
                  })
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "mac-address-help",
            size: "lg",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            "no-fade": "",
            "no-stacking": "",
            "hide-footer": "",
            centered: ""
          },
          scopedSlots: _vm._u([
            {
              key: "modal-header",
              fn: function() {
                return [
                  _c(
                    "b-row",
                    { attrs: { "align-v": "center" } },
                    [
                      _c(
                        "b-col",
                        { staticClass: "pr-0", attrs: { cols: "auto" } },
                        [
                          _c(
                            "b-button",
                            {
                              directives: [
                                {
                                  name: "b-modal",
                                  rawName: "v-b-modal.enter-device-info-step-7",
                                  modifiers: {
                                    "enter-device-info-step-7": true
                                  }
                                }
                              ],
                              attrs: {
                                variant: "icon-button",
                                "data-test-hook":
                                  "back-button-macAddressHelp-enrollment"
                              }
                            },
                            [
                              _c("i", {
                                staticClass:
                                  "fas fa-chevron-left back-chevron-button"
                              })
                            ]
                          )
                        ],
                        1
                      ),
                      _c("b-col", [
                        _c("h2", { staticClass: "modal-title" }, [
                          _vm._v(" " + _vm._s(_vm.$t("xMacAddressHelp")) + " ")
                        ])
                      ])
                    ],
                    1
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _c(
                    "b-carousel",
                    {
                      staticClass: "py-3 px-3",
                      attrs: {
                        background: "lightgray",
                        controls: true,
                        indicators: true,
                        interval: 0
                      }
                    },
                    [
                      _c("b-carousel-slide", {
                        attrs: {
                          "img-src": require("@/assets/images/gen-onboard-wifi-activate.svg")
                        }
                      }),
                      _c("b-carousel-slide", {
                        attrs: {
                          "img-src": require("@/assets/images/wifi-label.svg")
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "mt-3" },
            [
              _c("b-col", [
                _c("strong", [_vm._v(_vm._s(_vm.$t("xLocateTheMACAddress")))])
              ])
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c("b-col", [
                _vm._v(_vm._s(_vm.$t("x1OpenTheCoverOfYourGenerator")))
              ])
            ],
            1
          ),
          _c(
            "b-row",
            [_c("b-col", [_vm._v(_vm._s(_vm.$t("x2FindTheLabelsLocated")))])],
            1
          ),
          _c(
            "b-row",
            [
              _c("b-col", [
                _vm._v(_vm._s(_vm.$t("x3TheMACAddressAppearsOnTheWIFILabel")))
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "tether-serial-number-help",
            size: "lg",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            "no-fade": "",
            "no-stacking": "",
            "hide-footer": "",
            centered: ""
          },
          scopedSlots: _vm._u([
            {
              key: "modal-header",
              fn: function() {
                return [
                  _c(
                    "b-row",
                    { attrs: { "align-v": "center" } },
                    [
                      _c(
                        "b-col",
                        { staticClass: "pr-0", attrs: { cols: "auto" } },
                        [
                          _c(
                            "b-button",
                            {
                              directives: [
                                {
                                  name: "b-modal",
                                  rawName: "v-b-modal.enter-device-info-step-7",
                                  modifiers: {
                                    "enter-device-info-step-7": true
                                  }
                                }
                              ],
                              attrs: {
                                variant: "icon-button",
                                "data-test-hook":
                                  "back-button-tetherSNHelp-enrollment"
                              }
                            },
                            [
                              _c("i", {
                                staticClass:
                                  "fas fa-chevron-left back-chevron-button"
                              })
                            ]
                          )
                        ],
                        1
                      ),
                      _c("b-col", [
                        _c("h2", { staticClass: "modal-title" }, [
                          _vm._v(" " + _vm._s(_vm.$t("xSNHelp")) + " ")
                        ])
                      ])
                    ],
                    1
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c(
            "b-row",
            {
              staticClass: "py-5 mt-0 px-3",
              staticStyle: { "background-color": "#EEEEEE" }
            },
            [
              _vm.deviceType == "lte"
                ? _c(
                    "b-col",
                    [
                      _c("b-img", {
                        staticClass: "sn-tag-img",
                        attrs: {
                          fluid: "",
                          src: require("@/assets/images/lte-label.svg")
                        }
                      })
                    ],
                    1
                  )
                : _vm.deviceType == _vm.hsbG3DeviceType
                ? _c(
                    "b-col",
                    [
                      _c("b-img", {
                        staticClass: "sn-tag-img",
                        attrs: {
                          fluid: "",
                          src: require("@/assets/images/g3-label.svg")
                        }
                      })
                    ],
                    1
                  )
                : _c(
                    "b-col",
                    [
                      _c("b-img", {
                        staticClass: "sn-tag-img",
                        attrs: {
                          fluid: "",
                          src: require("@/assets/images/wifi-eth-label.svg")
                        }
                      })
                    ],
                    1
                  )
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "mt-3" },
            [
              _c("b-col", [
                _c("strong", [
                  _vm._v(_vm._s(_vm.$t("xLocateTheDeviceSerialNumber")))
                ])
              ])
            ],
            1
          ),
          _vm.deviceType == "lte"
            ? _c(
                "b-row",
                [
                  _c("b-col", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "x1LocateTheLabelOnTheBottomOfYourLTECellularDevice"
                          )
                        ) +
                        " "
                    )
                  ])
                ],
                1
              )
            : _vm.deviceType == _vm.hsbG3DeviceType
            ? _c(
                "b-row",
                [
                  _c("b-col", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "x1TheDeviceIDIsFoundOnTheBackOfTheCellularDevice"
                          )
                        ) +
                        " "
                    )
                  ])
                ],
                1
              )
            : _c(
                "b-row",
                [
                  _c("b-col", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "x1LocateTheLabelOnTheBottomOfYourWiFiEthernetDevice"
                          )
                        ) +
                        " "
                    )
                  ])
                ],
                1
              ),
          _vm.deviceType == "lte"
            ? _c(
                "b-row",
                [
                  _c("b-col", [
                    _vm._v(_vm._s(_vm.$t("x2EnterYourDeviceSerialNumber")))
                  ])
                ],
                1
              )
            : _vm.deviceType == _vm.hsbG3DeviceType
            ? _c(
                "b-row",
                [
                  _c("b-col", [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "x2TheSerialNumberIsLocatedDirectlyBelowTheQRCode"
                        )
                      )
                    )
                  ])
                ],
                1
              )
            : _c(
                "b-row",
                [_c("b-col", [_vm._v(_vm._s(_vm.$t("x2EnterYourDeviceS/N")))])],
                1
              )
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "enroll-device-step-8",
            size: "lg",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            "no-fade": "",
            "no-stacking": "",
            "hide-header": "",
            centered: ""
          },
          scopedSlots: _vm._u([
            {
              key: "modal-header",
              fn: function() {
                return [
                  _c(
                    "b-row",
                    { attrs: { "align-v": "center" } },
                    [
                      _c(
                        "b-col",
                        { staticClass: "pl-0", attrs: { cols: "auto" } },
                        [
                          _c(
                            "b-button",
                            {
                              directives: [
                                {
                                  name: "b-modal",
                                  rawName: "v-b-modal.enter-device-info-step-7",
                                  modifiers: {
                                    "enter-device-info-step-7": true
                                  }
                                }
                              ],
                              attrs: {
                                variant: "icon-button",
                                "data-test-hook": "back-button-step8-enrollment"
                              }
                            },
                            [
                              _c("i", {
                                staticClass:
                                  "fas fa-chevron-left back-chevron-button"
                              })
                            ]
                          )
                        ],
                        1
                      ),
                      _c("b-col", [
                        _c("h2", [
                          _vm._v(_vm._s(_vm.$t("xConfirmDeviceEnrollment")))
                        ])
                      ])
                    ],
                    1
                  )
                ]
              },
              proxy: true
            },
            {
              key: "modal-footer",
              fn: function() {
                return [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        variant: "outline-warning",
                        "data-test-hook": "cancel-button-step8-enrollment"
                      },
                      on: {
                        click: function($event) {
                          return _vm.closeEnrollmentModals(
                            "confirm-apparatus-device"
                          )
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("xCancel")) + " ")]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: {
                        variant: "primary",
                        disabled: _vm.enrollingDevice,
                        "data-test-hook": "EnrollBtn-step8-enrollment"
                      },
                      on: {
                        click: function($event) {
                          return _vm.enrollApparatusDevice()
                        }
                      }
                    },
                    [
                      _vm._v(" " + _vm._s(_vm.$t("xEnrollThisDevice")) + " "),
                      _c("i", { staticClass: "fas fa-arrow-right" }),
                      _vm.enrollingDevice
                        ? _c("b-spinner", { staticClass: "ml-2" })
                        : _vm._e()
                    ],
                    1
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _vm.validatedDeviceInfo
            ? _c(
                "b-row",
                { staticClass: "text-center" },
                [
                  _vm.validatedDeviceInfo.deviceType == "wifi"
                    ? _c(
                        "b-col",
                        [
                          _c("b-img", {
                            attrs: {
                              height: "200px",
                              src: require("@/assets/images/onboard-wifi.svg")
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.validatedDeviceInfo.deviceType == _vm.hsbG3DeviceType
                    ? _c(
                        "b-col",
                        [
                          _c("b-img", {
                            attrs: {
                              height: "200px",
                              src: require("@/assets/images/onboard-wifi.svg")
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.validatedDeviceInfo.deviceType == "lte"
                    ? _c(
                        "b-col",
                        [
                          _c("b-img", {
                            attrs: {
                              height: "200px",
                              src: require("@/assets/images/lte-cellular.svg")
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.validatedDeviceInfo.deviceType == "eth"
                    ? _c(
                        "b-col",
                        [
                          _c("b-img", {
                            attrs: {
                              height: "200px",
                              src: require("@/assets/images/tether-wifi.svg")
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            : _vm._e(),
          _c(
            "b-row",
            { staticClass: "text-center" },
            [
              _c("b-col", { staticClass: "mt-3" }, [
                _c("h2", [
                  _vm._v(_vm._s(_vm.$t("xYourCommunicationDeviceInfo")))
                ])
              ])
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "text-center" },
            [
              _vm.deviceType == "wifi"
                ? _c("b-col", [
                    _c("strong", [
                      _vm._v(
                        " " + _vm._s(_vm._f("formatLabel")("xMacAddress")) + " "
                      )
                    ]),
                    _vm._v(" " + _vm._s(_vm.macAddress) + " ")
                  ])
                : _vm.deviceType == _vm.hsbG3DeviceType
                ? _c("b-col", [
                    _c("strong", [
                      _vm._v(
                        " " +
                          _vm._s(_vm._f("formatLabel")("xDeviceSerialNumber")) +
                          " "
                      )
                    ]),
                    _vm._v(" " + _vm._s(_vm.shortDeviceId) + " ")
                  ])
                : _c("b-col", [
                    _c("strong", [
                      _vm._v(
                        " " +
                          _vm._s(_vm._f("formatLabel")("xDeviceSerialNumber")) +
                          " "
                      )
                    ]),
                    _vm._v(" " + _vm._s(_vm.searchLabel) + " ")
                  ])
            ],
            1
          ),
          _vm.showEnrollDeviceError && _vm.enrollDeviceErrorMessage
            ? _c(
                "div",
                {
                  staticClass: "alert alert-danger alert--validation",
                  attrs: { show: _vm.showEnrollDeviceError }
                },
                [_vm._v(" " + _vm._s(_vm.enrollDeviceErrorMessage) + " ")]
              )
            : _vm._e(),
          _c(
            "b-row",
            { staticClass: "mt-5", attrs: { "align-v": "center" } },
            [
              _c(
                "b-col",
                { staticClass: "mr-0 pr-0" },
                [
                  _c("b-progress", {
                    attrs: { value: 88.88, variant: "primary" }
                  })
                ],
                1
              ),
              _c(
                "b-col",
                { staticClass: "ml-0 pl-0", attrs: { cols: "auto" } },
                [
                  _c("i", {
                    staticClass:
                      "fas fa-shield-check fa-lg inProgress ml-1 pl-0"
                  })
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "device-enrollment-success-step-9",
            size: "lg",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            "no-fade": "",
            "no-stacking": "",
            "hide-header": "",
            centered: ""
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function() {
                return [
                  _c(
                    "b-button",
                    {
                      directives: [
                        {
                          name: "b-modal",
                          rawName: "v-b-modal.add-transfer-switch",
                          modifiers: { "add-transfer-switch": true }
                        }
                      ],
                      attrs: {
                        variant: "outline-warning",
                        "data-test-hook": "add-ts-button-step9-enrollment"
                      },
                      on: {
                        click: function($event) {
                          return _vm.trackAppInsightsEvent(
                            "open-register-transfer-switch-modal",
                            {
                              page: "enrollment",
                              organizationId: _vm.accountData.organizationId,
                              userId: _vm.accountData.userId
                            }
                          )
                        }
                      }
                    },
                    [
                      _c("i", { staticClass: "fas fa-plus" }),
                      _vm._v(" " + _vm._s(_vm.$t("xTransferSwitch")) + " ")
                    ]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: {
                        variant: "primary",
                        "data-test-hook": "SubscriptionBtn"
                      },
                      on: {
                        click: function($event) {
                          return _vm.getSubscriptionInformation()
                        }
                      }
                    },
                    [
                      _c("i", { staticClass: "far fa-badge-dollar" }),
                      _vm._v(" " + _vm._s(_vm.$t("xSubscribe")) + " "),
                      _vm.gettingSubscriptions
                        ? _c("b-spinner", { staticClass: "ml-2" })
                        : _vm._e()
                    ],
                    1
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c(
            "b-row",
            { staticClass: "text-center mb-3" },
            [
              _c("b-col", [
                _c("h1", [_vm._v(_vm._s(_vm.$t("xSuccess")) + "!")]),
                _c("h5", [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "xYourCommunicationDeviceHasSuccessfullyBeenEnrolledOntoYourGenerator"
                      )
                    )
                  )
                ])
              ])
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "text-center" },
            [
              _c(
                "b-col",
                [
                  _c("b-img", {
                    staticClass: "generator-success-img",
                    attrs: {
                      alt: "Generator Illustration",
                      fluid: "",
                      src: require("@/assets/images/gen-enrollment-success.svg")
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "mt-5", attrs: { "align-v": "center" } },
            [
              _c(
                "b-col",
                { staticClass: "mr-0 pr-0" },
                [
                  _c("b-progress", {
                    attrs: { value: 100, variant: "primary" }
                  })
                ],
                1
              ),
              _c(
                "b-col",
                { staticClass: "ml-0 pl-0", attrs: { cols: "auto" } },
                [
                  _c("i", {
                    staticClass:
                      "fas fa-shield-check fa-lg colorOrange ml-2 pl-0"
                  })
                ]
              )
            ],
            1
          ),
          _vm.showGetSubscriptionInfoError &&
          _vm.getSubscriptionInfoErrorMessage
            ? _c(
                "div",
                {
                  staticClass: "alert alert--validation alert-danger",
                  attrs: { show: _vm.showGetSubscriptionInfoError }
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.getSubscriptionInfoErrorMessage) + " "
                  )
                ]
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "add-transfer-switch",
            size: "lg",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            "no-fade": "",
            "no-stacking": "",
            centered: ""
          },
          scopedSlots: _vm._u([
            {
              key: "modal-header",
              fn: function() {
                return [
                  _c(
                    "b-row",
                    { attrs: { "align-v": "center" } },
                    [
                      _c(
                        "b-col",
                        { staticClass: "pr-0", attrs: { cols: "auto" } },
                        [
                          _c(
                            "b-button",
                            {
                              directives: [
                                {
                                  name: "b-modal",
                                  rawName:
                                    "v-b-modal.device-enrollment-success-step-9",
                                  modifiers: {
                                    "device-enrollment-success-step-9": true
                                  }
                                }
                              ],
                              attrs: {
                                variant: "icon-button",
                                "data-test-hook": "back-button-addTS-enrollment"
                              },
                              on: {
                                click: function($event) {
                                  ;(_vm.sns = []), (_vm.snToAdd = null)
                                }
                              }
                            },
                            [
                              _c("i", {
                                staticClass:
                                  "fas fa-chevron-left back-chevron-button"
                              })
                            ]
                          )
                        ],
                        1
                      ),
                      _c("b-col", [
                        _c("h2", { staticClass: "modal-title" }, [
                          _c("i", {
                            staticClass: "far fa-plus colorOrange mr-2"
                          }),
                          _vm._v(_vm._s(_vm.$t("xAddTransferSwitch")))
                        ])
                      ])
                    ],
                    1
                  )
                ]
              },
              proxy: true
            },
            {
              key: "modal-footer",
              fn: function() {
                return [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        block: "",
                        variant: "primary",
                        disabled: !_vm.canRegisterSns(),
                        "data-test-hook": "confirm-button-addTS-enrollment"
                      },
                      on: {
                        click: function($event) {
                          _vm.registerTransferSwitches(),
                            _vm.trackAppInsightsEvent(
                              "register-transfer-switch-serial-numbers",
                              {
                                page: "enrollment",
                                organizationId: _vm.accountData.organizationId,
                                userId: _vm.accountData.userId,
                                serialNumbers: _vm.sns
                              }
                            )
                        }
                      }
                    },
                    [
                      _vm._v(" " + _vm._s(_vm.$t("xConfirm")) + " "),
                      _vm.registeringSerialNumbers
                        ? _c("b-spinner", { staticClass: "mx-1" })
                        : _vm._e(),
                      _c("i", { staticClass: "far fa-arrow-right" })
                    ],
                    1
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _vm._l(_vm.sns, function(sn, index) {
            return _c(
              "b-row",
              { key: index },
              [
                _c(
                  "b-col",
                  [
                    _c(
                      "b-row",
                      [
                        _c("b-col", [
                          _c("h4", [
                            _vm._v(
                              _vm._s(
                                _vm._f("formatLabel")(
                                  _vm.$t("xTransferSwitch") + " " + (index + 1)
                                )
                              )
                            )
                          ])
                        ])
                      ],
                      1
                    ),
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          {
                            staticClass: "asterisk-text",
                            attrs: { cols: "12" }
                          },
                          [_vm._v(_vm._s(_vm.$t("xSerialNumber")))]
                        ),
                        _c("b-col", { attrs: { cols: "12" } }, [
                          _c(
                            "h4",
                            { staticStyle: { "word-wrap": "break-word" } },
                            [_vm._v(_vm._s(sn))]
                          )
                        ])
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          }),
          _c(
            "b-row",
            [
              _c("b-col", { attrs: { cols: "12" } }, [
                _c("h4", [
                  _vm._v(" " + _vm._s(_vm.$t("xEnterTransferSwitch")) + " "),
                  _vm.sns.length > 0
                    ? _c("span", [_vm._v(_vm._s(_vm.sns.length + 1))])
                    : _vm._e()
                ])
              ]),
              _c(
                "b-col",
                [
                  _c(
                    "b-form-group",
                    [
                      _c("b-form-input", {
                        attrs: {
                          maxlength: "20",
                          placeholder: _vm.$t("xSerialNumber")
                        },
                        model: {
                          value: _vm.snToAdd,
                          callback: function($$v) {
                            _vm.snToAdd = $$v
                          },
                          expression: "snToAdd"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        variant: "outline-warning",
                        disabled: !_vm.snToAdd || _vm.snToAdd.length < 7,
                        block: "",
                        "data-test-hook": "add-TS-button-addTS-enrollment"
                      },
                      on: {
                        click: function($event) {
                          return _vm.addSnToRegistrationList()
                        }
                      }
                    },
                    [
                      _c("i", { staticClass: "fas fa-plus" }),
                      _vm._v(" " + _vm._s(_vm.$t("xTransferSwitch")) + " ")
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        2
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "transfer-serial-number-registration-success",
            size: "lg",
            "hide-header": "",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            "no-fade": "",
            "no-stacking": "",
            centered: ""
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function() {
                return [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        block: "",
                        variant: "primary",
                        "data-test-hook": "SubscriptionBtn"
                      },
                      on: {
                        click: function($event) {
                          return _vm.getSubscriptionInformation()
                        }
                      }
                    },
                    [
                      _c("i", { staticClass: "far fa-badge-dollar" }),
                      _vm._v(" " + _vm._s(_vm.$t("xChooseASub")) + " "),
                      _vm.gettingSubscriptions
                        ? _c("b-spinner", { staticClass: "ml-2" })
                        : _vm._e()
                    ],
                    1
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c(
            "b-row",
            { staticClass: "text-center" },
            [
              _c("b-col", [
                _c("span", { staticStyle: { "font-size": "15rem" } }, [
                  _c("i", { staticClass: "far fa-thumbs-up colorOrange" })
                ])
              ])
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "mt-3" },
            [
              _c("b-col", [
                _c("h3", { staticClass: "colorOrange" }, [
                  _vm._v(_vm._s(_vm.$t("xAwesome")))
                ])
              ])
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c("b-col", [
                _vm._v(" " + _vm._s(_vm.$t("xTransferSwitchSuccess")) + " ")
              ])
            ],
            1
          ),
          _vm.showGetSubscriptionInfoError &&
          _vm.getSubscriptionInfoErrorMessage
            ? _c(
                "div",
                {
                  staticClass: "alert alert--validation alert-danger",
                  attrs: { show: _vm.showGetSubscriptionInfoError }
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.getSubscriptionInfoErrorMessage) + " "
                  )
                ]
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "transfer-serial-number-registration-failure",
            size: "lg",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            "no-fade": "",
            "no-stacking": "",
            centered: "",
            "hide-header": ""
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function() {
                return [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        block: "",
                        variant: "primary",
                        "data-test-hook": "SubscriptionBtn"
                      },
                      on: {
                        click: function($event) {
                          return _vm.getSubscriptionInformation()
                        }
                      }
                    },
                    [
                      _c("i", { staticClass: "far fa-badge-dollar" }),
                      _vm._v(" " + _vm._s(_vm.$t("xChooseASub")) + " "),
                      _vm.gettingSubscriptions
                        ? _c("b-spinner", { staticClass: "ml-2" })
                        : _vm._e()
                    ],
                    1
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c(
            "b-row",
            { staticClass: "text-center" },
            [
              _c("b-col", { staticClass: "my-3" }, [
                _c("span", { staticStyle: { "font-size": "15rem" } }, [
                  _c("i", { staticClass: "far fa-sad-tear colorOrange" })
                ])
              ])
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c("b-col", [
                _c("h3", { staticClass: "colorOrange" }, [
                  _vm._v(_vm._s(_vm.$t("xWhoops")))
                ])
              ])
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c("b-col", [
                _vm._v(" " + _vm._s(_vm.transferSwitchFailureMessage) + " ")
              ])
            ],
            1
          ),
          _vm.showGetSubscriptionInfoError &&
          _vm.getSubscriptionInfoErrorMessage
            ? _c(
                "div",
                {
                  staticClass: "alert alert--validation alert-danger",
                  attrs: { show: _vm.showGetSubscriptionInfoError }
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.getSubscriptionInfoErrorMessage) + " "
                  )
                ]
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "select-subscription-type-step-10",
            size: "lg",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            "no-fade": "",
            "no-stacking": "",
            "hide-footer": "",
            centered: ""
          },
          on: {
            shown: _vm.OnShowSubscriptionModal_Selection,
            hidden: _vm.OnHideSubscriptionModal_Selection
          },
          scopedSlots: _vm._u([
            {
              key: "modal-header",
              fn: function() {
                return [
                  _c("div", [
                    _c(
                      "h2",
                      {
                        staticClass: "modal-title",
                        attrs: {
                          "data-test-hook":
                            "select-subscription-type-step-10-header"
                        }
                      },
                      [
                        _c("i", {
                          staticClass: "far fa-badge-dollar colorOrange mr-1",
                          attrs: {
                            "data-test-hook":
                              "select-subscription-type-step-10-header-icon"
                          }
                        }),
                        _vm._v(" " + _vm._s(_vm.$t("xChooseASub")) + " ")
                      ]
                    )
                  ])
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _vm.monthlyPaidProduct &&
          _vm.annualPaidProduct &&
          _vm.canHavePaidSubscription(_vm.accountData)
            ? _c(
                "b-row",
                {
                  staticClass: "mb-0 pb-0",
                  attrs: { "align-h": "center", "no-gutters": "" }
                },
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-card-group",
                        { staticClass: "mx-lg-2", attrs: { deck: "" } },
                        [
                          _c(
                            "b-card",
                            {
                              staticClass:
                                "paid-subs-info-card mb-0 sub-card mx-1",
                              class:
                                _vm.selectedProduct &&
                                (_vm.selectedProduct.id ==
                                  _vm.annualPaidProduct.id ||
                                  _vm.selectedProduct.id ==
                                    _vm.monthlyPaidProduct.id)
                                  ? "selected-sub-info-card"
                                  : "",
                              attrs: {
                                "body-class": "subs-info-card-body",
                                "data-test-hook":
                                  "select-subscription-type-step-10-paid-card"
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "header",
                                    fn: function() {
                                      return [
                                        _vm.yearlyTabActive
                                          ? _c("div", [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "annual-savings-header",
                                                  attrs: {
                                                    "data-test-hook":
                                                      "select-subscription-type-step-10-best-value-header"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t("xBestValueSave")
                                                      ) +
                                                      " " +
                                                      _vm._s(
                                                        _vm.getSubscriptionSavings(
                                                          _vm.annualPaidProduct,
                                                          _vm.monthlyPaidProduct
                                                        )
                                                      ) +
                                                      " % "
                                                  )
                                                ]
                                              )
                                            ])
                                          : _vm._e()
                                      ]
                                    },
                                    proxy: true
                                  }
                                ],
                                null,
                                false,
                                1432524008
                              )
                            },
                            [
                              _c(
                                "b-card-title",
                                [
                                  _c(
                                    "b-row",
                                    { staticClass: "text-center" },
                                    [
                                      _c("b-col", [
                                        _vm.yearlyTabActive
                                          ? _c(
                                              "h1",
                                              {
                                                staticClass: "mb-0 pb-0",
                                                attrs: {
                                                  "data-test-hook":
                                                    "select-subscription-type-step-10-paid-card-title"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t("xPremiumAnnually")
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            )
                                          : _vm.monthlyTabActive
                                          ? _c(
                                              "h1",
                                              {
                                                staticClass: "mb-0 pb-0",
                                                attrs: {
                                                  "data-test-hook":
                                                    "select-subscription-type-step-10-paid-card-title"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t("xPremiumMonthly")
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "b-row",
                                {
                                  staticClass:
                                    "text-center float-center mt-2 subscription-toggles",
                                  attrs: { "align-h": "center" }
                                },
                                [
                                  _c(
                                    "b-tabs",
                                    {
                                      staticClass: "justify-content-center",
                                      attrs: { pills: "" }
                                    },
                                    [
                                      _c(
                                        "b-tab",
                                        {
                                          attrs: {
                                            active: _vm.yearlyTabActive,
                                            "data-test-hook":
                                              "select-subscription-type-step-10-yearly-billing-tab"
                                          },
                                          on: {
                                            click: function($event) {
                                              ;(_vm.monthlyTabActive = false),
                                                (_vm.yearlyTabActive = true)
                                            }
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "title",
                                                fn: function() {
                                                  return [
                                                    _vm.yearlyTabActive
                                                      ? _c("i", {
                                                          staticClass:
                                                            "fa-solid fa-check"
                                                        })
                                                      : _vm._e(),
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "xBilledAnnually"
                                                          )
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                },
                                                proxy: true
                                              }
                                            ],
                                            null,
                                            false,
                                            3739147204
                                          )
                                        },
                                        [
                                          _c(
                                            "b-button",
                                            {
                                              staticClass: "mt-2",
                                              attrs: {
                                                variant: "subscription-radio",
                                                pill: "",
                                                pressed:
                                                  _vm.selectedProduct &&
                                                  _vm.selectedProduct.id ==
                                                    _vm.annualPaidProduct.id,
                                                "data-test-hook":
                                                  "select-subscription-type-step-10-select-yearly-button"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.setSelectedProduct(
                                                    _vm.annualPaidProduct
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "sub-radio-dot align-middle"
                                                },
                                                [
                                                  _c("i", {
                                                    class:
                                                      _vm.selectedProduct &&
                                                      _vm.selectedProduct.id ==
                                                        _vm.annualPaidProduct.id
                                                        ? "fa-solid fa-circle"
                                                        : "fa-regular fa-circle"
                                                  })
                                                ]
                                              ),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm._f("formatCurrency")(
                                                      _vm.annualPaidProduct.cost
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "text-bottom sub-premium-radio-duration"
                                                },
                                                [
                                                  _vm._v(
                                                    "/" +
                                                      _vm._s(_vm.$t("xYear"))
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-tab",
                                        {
                                          attrs: {
                                            active: _vm.monthlyTabActive,
                                            "data-test-hook":
                                              "select-subscription-type-step-10-monthly-billing-tab"
                                          },
                                          on: {
                                            click: function($event) {
                                              ;(_vm.yearlyTabActive = false),
                                                (_vm.monthlyTabActive = true)
                                            }
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "title",
                                                fn: function() {
                                                  return [
                                                    _vm.monthlyTabActive
                                                      ? _c("i", {
                                                          staticClass:
                                                            "fa-solid fa-check"
                                                        })
                                                      : _vm._e(),
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "xBilledMonthly"
                                                          )
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                },
                                                proxy: true
                                              }
                                            ],
                                            null,
                                            false,
                                            3655885330
                                          )
                                        },
                                        [
                                          _c(
                                            "b-button",
                                            {
                                              staticClass: "mt-2",
                                              attrs: {
                                                variant: "subscription-radio",
                                                pill: "",
                                                pressed:
                                                  _vm.selectedProduct &&
                                                  _vm.selectedProduct.id ==
                                                    _vm.monthlyPaidProduct.id,
                                                "data-test-hook":
                                                  "select-subscription-type-step-10-select-monthly-button"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.setSelectedProduct(
                                                    _vm.monthlyPaidProduct
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "sub-radio-dot align-middle"
                                                },
                                                [
                                                  _c("i", {
                                                    class:
                                                      _vm.selectedProduct &&
                                                      _vm.selectedProduct.id ==
                                                        _vm.monthlyPaidProduct
                                                          .id
                                                        ? "fa-solid fa-circle"
                                                        : "fa-regular fa-circle"
                                                  })
                                                ]
                                              ),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm._f("formatCurrency")(
                                                      _vm.monthlyPaidProduct
                                                        .cost
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "text-bottom sub-premium-radio-duration"
                                                },
                                                [
                                                  _vm._v(
                                                    "/" +
                                                      _vm._s(_vm.$t("xMonth"))
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "subscriptionCardText" },
                                [
                                  _c(
                                    "b-row",
                                    [
                                      _c("b-col", [
                                        _c(
                                          "strong",
                                          {
                                            attrs: {
                                              "data-test-hook":
                                                "select-subscription-type-step-10-included-features-text"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("xIncludedFeatures")
                                              )
                                            )
                                          ]
                                        )
                                      ])
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    {
                                      directives: [
                                        {
                                          name: "b-toggle",
                                          rawName: "v-b-toggle.genStatusDesc",
                                          modifiers: { genStatusDesc: true }
                                        }
                                      ],
                                      staticClass: "expandable-click-area",
                                      attrs: { "align-v": "center" }
                                    },
                                    [
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: "pr-0 text-center",
                                          attrs: { cols: "1" }
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "fa-regular fa-circle-check",
                                            attrs: {
                                              "data-test-hook":
                                                "select-subscription-type-step-10-paid-gen-status-icon"
                                            }
                                          })
                                        ]
                                      ),
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: "float-left",
                                          attrs: {
                                            "data-test-hook":
                                              "select-subscription-type-step-10-paid-gen-status-text"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("xGeneratorStatus")
                                              ) +
                                              " "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "b-col",
                                        {
                                          staticClass:
                                            "float-right when-closed",
                                          attrs: { cols: "auto" }
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "fa-solid fa-angle-down"
                                          })
                                        ]
                                      ),
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: "float-right when-open",
                                          attrs: { cols: "auto" }
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fa-solid fa-angle-up"
                                          })
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-collapse",
                                    { attrs: { id: "genStatusDesc" } },
                                    [
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            {
                                              attrs: {
                                                "data-test-hook":
                                                  "select-subscription-type-step-10-paid-gen-status-desc"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t("xGenStatusDesc")
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    {
                                      directives: [
                                        {
                                          name: "b-toggle",
                                          rawName: "v-b-toggle.manualsDesc",
                                          modifiers: { manualsDesc: true }
                                        }
                                      ],
                                      staticClass: "expandable-click-area",
                                      attrs: { "align-v": "center" }
                                    },
                                    [
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: "pr-0 text-center",
                                          attrs: { cols: "1" }
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fa-regular fa-book",
                                            attrs: {
                                              "data-test-hook":
                                                "select-subscription-type-step-10-paid-manuals-icon"
                                            }
                                          })
                                        ]
                                      ),
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: "float-left",
                                          attrs: {
                                            "data-test-hook":
                                              "select-subscription-type-step-10-paid-manuals-text"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t("xManuals")) +
                                              " "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "b-col",
                                        {
                                          staticClass:
                                            "float-right when-closed",
                                          attrs: { cols: "auto" }
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "fa-solid fa-angle-down"
                                          })
                                        ]
                                      ),
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: "float-right when-open",
                                          attrs: { cols: "auto" }
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fa-solid fa-angle-up"
                                          })
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-collapse",
                                    { attrs: { id: "manualsDesc" } },
                                    [
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            {
                                              attrs: {
                                                "data-test-hook":
                                                  "select-subscription-type-step-10-paid-manuals-desc"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t("xManualsDesc")
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    {
                                      directives: [
                                        {
                                          name: "b-toggle",
                                          rawName:
                                            "v-b-toggle.statusHistoryDesc",
                                          modifiers: { statusHistoryDesc: true }
                                        }
                                      ],
                                      staticClass: "expandable-click-area",
                                      attrs: { "align-v": "center" }
                                    },
                                    [
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: "pr-0 text-center",
                                          attrs: { cols: "1" }
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "fa-regular fa-clock-rotate-left",
                                            attrs: {
                                              "data-test-hook":
                                                "select-subscription-type-step-10-paid-status-history-icon"
                                            }
                                          })
                                        ]
                                      ),
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: "float-left",
                                          attrs: {
                                            "data-test-hook":
                                              "select-subscription-type-step-10-paid-status-history-text"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t("xStatusHistory")) +
                                              " "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "b-col",
                                        {
                                          staticClass:
                                            "float-right when-closed",
                                          attrs: { cols: "auto" }
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "fa-solid fa-angle-down"
                                          })
                                        ]
                                      ),
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: "float-right when-open",
                                          attrs: { cols: "auto" }
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fa-solid fa-angle-up"
                                          })
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-collapse",
                                    { attrs: { id: "statusHistoryDesc" } },
                                    [
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            {
                                              attrs: {
                                                "data-test-hook":
                                                  "select-subscription-type-step-10-paid-status-history-desc"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t("xStatusHistoryDesc")
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    {
                                      directives: [
                                        {
                                          name: "b-toggle",
                                          rawName:
                                            "v-b-toggle.monthlySummaryEmailDesc",
                                          modifiers: {
                                            monthlySummaryEmailDesc: true
                                          }
                                        }
                                      ],
                                      staticClass: "expandable-click-area",
                                      attrs: { "align-v": "center" }
                                    },
                                    [
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: "pr-0 text-center",
                                          attrs: { cols: "1" }
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "fa-regular fa-envelope-open-text",
                                            attrs: {
                                              "data-test-hook":
                                                "select-subscription-type-step-10-paid-monthly-email-icon"
                                            }
                                          })
                                        ]
                                      ),
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: "float-left",
                                          attrs: {
                                            "data-test-hook":
                                              "select-subscription-type-step-10-paid-monthly-email-text"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("xMonthlySummaryEmail")
                                              ) +
                                              " "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "b-col",
                                        {
                                          staticClass:
                                            "float-right when-closed",
                                          attrs: { cols: "auto" }
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "fa-solid fa-angle-down"
                                          })
                                        ]
                                      ),
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: "float-right when-open",
                                          attrs: { cols: "auto" }
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fa-solid fa-angle-up"
                                          })
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-collapse",
                                    {
                                      attrs: { id: "monthlySummaryEmailDesc" }
                                    },
                                    [
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            {
                                              attrs: {
                                                "data-test-hook":
                                                  "select-subscription-type-step-10-paid-monthly-email-desc"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "xMonthlySummaryEmailDesc"
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    {
                                      directives: [
                                        {
                                          name: "b-toggle",
                                          rawName:
                                            "v-b-toggle.maintenanceDetailsDesc",
                                          modifiers: {
                                            maintenanceDetailsDesc: true
                                          }
                                        }
                                      ],
                                      staticClass: "expandable-click-area",
                                      attrs: { "align-v": "center" }
                                    },
                                    [
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: "pr-0 text-center",
                                          attrs: { cols: "1" }
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "fa-regular fa-toolbox",
                                            attrs: {
                                              "data-test-hook":
                                                "select-subscription-type-step-10-paid-maintenance-icon"
                                            }
                                          })
                                        ]
                                      ),
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: "float-left",
                                          attrs: {
                                            "data-test-hook":
                                              "select-subscription-type-step-10-paid-maintenance-text"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("xMaintenanceTracking")
                                              ) +
                                              " "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "b-col",
                                        {
                                          staticClass:
                                            "float-right when-closed",
                                          attrs: { cols: "auto" }
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "fa-solid fa-angle-down"
                                          })
                                        ]
                                      ),
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: "float-right when-open",
                                          attrs: { cols: "auto" }
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fa-solid fa-angle-up"
                                          })
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-collapse",
                                    { attrs: { id: "maintenanceDetailsDesc" } },
                                    [
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            {
                                              attrs: {
                                                "data-test-hook":
                                                  "select-subscription-type-step-10-paid-maintenance-desc"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "xMaintenanceTrackingDesc"
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    {
                                      directives: [
                                        {
                                          name: "b-toggle",
                                          rawName:
                                            "v-b-toggle.exerciseOnDemandDesc",
                                          modifiers: {
                                            exerciseOnDemandDesc: true
                                          }
                                        }
                                      ],
                                      staticClass: "expandable-click-area",
                                      attrs: { "align-v": "center" }
                                    },
                                    [
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: "pr-0 text-center",
                                          attrs: { cols: "1" }
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "colorOrange fa-regular fa-bolt",
                                            attrs: {
                                              "data-test-hook":
                                                "select-subscription-type-step-10-paid-demand-exercise-icon"
                                            }
                                          })
                                        ]
                                      ),
                                      _c(
                                        "b-col",
                                        { staticClass: "float-left" },
                                        [
                                          _c(
                                            "strong",
                                            {
                                              attrs: {
                                                "data-test-hook":
                                                  "select-subscription-type-step-10-paid-demand-exercise-text"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("xExerciseOnDemand")
                                                )
                                              )
                                            ]
                                          )
                                        ]
                                      ),
                                      _c(
                                        "b-col",
                                        {
                                          staticClass:
                                            "float-right when-closed",
                                          attrs: { cols: "auto" }
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "fa-solid fa-angle-down"
                                          })
                                        ]
                                      ),
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: "float-right when-open",
                                          attrs: { cols: "auto" }
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fa-solid fa-angle-up"
                                          })
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-collapse",
                                    { attrs: { id: "exerciseOnDemandDesc" } },
                                    [
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            {
                                              attrs: {
                                                "data-test-hook":
                                                  "select-subscription-type-step-10-paid-demand-exercise-desc"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "xExerciseOnDemandDesc"
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    {
                                      directives: [
                                        {
                                          name: "b-toggle",
                                          rawName:
                                            "v-b-toggle.realTimeAlertsDesc",
                                          modifiers: {
                                            realTimeAlertsDesc: true
                                          }
                                        }
                                      ],
                                      staticClass: "expandable-click-area",
                                      attrs: { "align-v": "center" }
                                    },
                                    [
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: "pr-0 text-center",
                                          attrs: { cols: "1" }
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "colorOrange fa-regular fa-bell",
                                            attrs: {
                                              "data-test-hook":
                                                "select-subscription-type-step-10-paid-alerts-icon"
                                            }
                                          })
                                        ]
                                      ),
                                      _c(
                                        "b-col",
                                        { staticClass: "float-left" },
                                        [
                                          _c(
                                            "strong",
                                            {
                                              attrs: {
                                                "data-test-hook":
                                                  "select-subscription-type-step-10-paid-alerts-text"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("xRealTimeAlerts")
                                                ) + "*"
                                              )
                                            ]
                                          )
                                        ]
                                      ),
                                      _c(
                                        "b-col",
                                        {
                                          staticClass:
                                            "float-right when-closed",
                                          attrs: { cols: "auto" }
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "fa-solid fa-angle-down"
                                          })
                                        ]
                                      ),
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: "float-right when-open",
                                          attrs: { cols: "auto" }
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fa-solid fa-angle-up"
                                          })
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-collapse",
                                    { attrs: { id: "realTimeAlertsDesc" } },
                                    [
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            {
                                              attrs: {
                                                "data-test-hook":
                                                  "select-subscription-type-step-10-paid-alerts-desc"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "xRealTimeAlertsDesc"
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    {
                                      directives: [
                                        {
                                          name: "b-toggle",
                                          rawName:
                                            "v-b-toggle.exerciseTimeDesc",
                                          modifiers: { exerciseTimeDesc: true }
                                        }
                                      ],
                                      staticClass: "expandable-click-area",
                                      attrs: { "align-v": "center" }
                                    },
                                    [
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: "pr-0 text-center",
                                          attrs: { cols: "1" }
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "colorOrange fa-regular fa-clock",
                                            attrs: {
                                              "data-test-hook":
                                                "select-subscription-type-step-10-paid-exercise-time-icon"
                                            }
                                          })
                                        ]
                                      ),
                                      _c(
                                        "b-col",
                                        { staticClass: "float-left" },
                                        [
                                          _c(
                                            "strong",
                                            {
                                              attrs: {
                                                "data-test-hook":
                                                  "select-subscription-type-step-10-paid-exercise-time-text"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("xExerciseTime"))
                                              )
                                            ]
                                          )
                                        ]
                                      ),
                                      _c(
                                        "b-col",
                                        {
                                          staticClass:
                                            "float-right when-closed",
                                          attrs: { cols: "auto" }
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "fa-solid fa-angle-down"
                                          })
                                        ]
                                      ),
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: "float-right when-open",
                                          attrs: { cols: "auto" }
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fa-solid fa-angle-up"
                                          })
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-collapse",
                                    { attrs: { id: "exerciseTimeDesc" } },
                                    [
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            {
                                              attrs: {
                                                "data-test-hook":
                                                  "select-subscription-type-step-10-paid-exercise-time-desc"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t("xExerciseTimeDesc")
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            { staticClass: "d-sm-none text-center my-2" },
                            [
                              _c("b-col", [
                                _c("h4", { staticClass: "mb-0 pb-0" }, [
                                  _vm._v(_vm._s(_vm.$t("xOR")))
                                ])
                              ])
                            ],
                            1
                          ),
                          _vm.freeProduct
                            ? _c(
                                "b-card",
                                {
                                  staticClass: "free-sub-info-card sub-card",
                                  class:
                                    _vm.selectedProduct &&
                                    _vm.selectedProduct.id == _vm.freeProduct.id
                                      ? "selected-sub-info-card"
                                      : "",
                                  attrs: {
                                    "body-class": "subs-info-card-body mx-1",
                                    "data-test-hook":
                                      "select-subscription-type-step-10-free-card"
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "header",
                                        fn: function() {
                                          return [
                                            _c(
                                              "div",
                                              { staticClass: "d-sm-none" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "text-center limited-features-header",
                                                    attrs: {
                                                      "data-test-hook":
                                                        "select-subscription-type-step-10-free-limited-features-header"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "xLimitedFeatures"
                                                          )
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ],
                                    null,
                                    false,
                                    3920104901
                                  )
                                },
                                [
                                  _c(
                                    "b-card-title",
                                    [
                                      _c(
                                        "b-row",
                                        { staticClass: "text-center" },
                                        [
                                          _c("b-col", [
                                            _c(
                                              "h1",
                                              {
                                                staticClass: "mb-0 pb-0",
                                                attrs: {
                                                  "data-test-hook":
                                                    "select-subscription-type-step-10-free-title"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(_vm.$t("xFree")) +
                                                    " "
                                                )
                                              ]
                                            )
                                          ])
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    {
                                      staticClass:
                                        "text-center mt-2 d-none d-sm-block"
                                    },
                                    [
                                      _c("b-col", [
                                        _c(
                                          "div",
                                          { staticClass: "limited-features" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("xLimitedFeatures")
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      ])
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { staticClass: "text-center mt-2" },
                                    [
                                      _c(
                                        "b-col",
                                        [
                                          _c(
                                            "b-button",
                                            {
                                              attrs: {
                                                variant: "subscription-radio",
                                                pill: "",
                                                pressed:
                                                  _vm.selectedProduct &&
                                                  _vm.selectedProduct.id ==
                                                    _vm.freeProduct.id,
                                                "data-test-hook":
                                                  "select-subscription-type-step-10-free-select-button"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.setSelectedProduct(
                                                    _vm.freeProduct
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "sub-radio-dot align-middle"
                                                },
                                                [
                                                  _c("i", {
                                                    class:
                                                      _vm.selectedProduct &&
                                                      _vm.selectedProduct.id ==
                                                        _vm.freeProduct.id
                                                        ? "fa-solid fa-circle"
                                                        : "fa-regular fa-circle"
                                                  })
                                                ]
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "sub-free-radio-text align-middle"
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(_vm.$t("xFree"))
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "subscriptionCardText" },
                                    [
                                      _c(
                                        "b-row",
                                        [
                                          _c("b-col", [
                                            _c(
                                              "strong",
                                              {
                                                attrs: {
                                                  "data-test-hook":
                                                    "select-subscription-type-step-10-free-included-features-text"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("xIncludedFeatures")
                                                  )
                                                )
                                              ]
                                            )
                                          ])
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        {
                                          staticClass: "expandable-click-area",
                                          attrs: { "align-v": "center" }
                                        },
                                        [
                                          _c(
                                            "b-col",
                                            {
                                              staticClass: "pr-0 text-center",
                                              attrs: { cols: "1" }
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "fa-regular fa-circle-check",
                                                attrs: {
                                                  "data-test-hook":
                                                    "select-subscription-type-step-10-free-gen-status-icon"
                                                }
                                              })
                                            ]
                                          ),
                                          _c(
                                            "b-col",
                                            {
                                              staticClass: "float-left",
                                              attrs: {
                                                "data-test-hook":
                                                  "select-subscription-type-step-10-free-gen-status-text"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t("xGeneratorStatus")
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        {
                                          staticClass: "expandable-click-area",
                                          attrs: { "align-v": "center" }
                                        },
                                        [
                                          _c(
                                            "b-col",
                                            {
                                              staticClass: "pr-0 text-center",
                                              attrs: { cols: "1" }
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "fa-regular fa-book",
                                                attrs: {
                                                  "data-test-hook":
                                                    "select-subscription-type-step-10-free-manuals-icon"
                                                }
                                              })
                                            ]
                                          ),
                                          _c(
                                            "b-col",
                                            {
                                              staticClass: "float-left",
                                              attrs: {
                                                "data-test-hook":
                                                  "select-subscription-type-step-10-free-manuals-text"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.$t("xManuals")) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        {
                                          staticClass: "expandable-click-area",
                                          attrs: { "align-v": "center" }
                                        },
                                        [
                                          _c(
                                            "b-col",
                                            {
                                              staticClass: "pr-0 text-center",
                                              attrs: { cols: "1" }
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "fa-regular fa-clock-rotate-left",
                                                attrs: {
                                                  "data-test-hook":
                                                    "select-subscription-type-step-10-free-status-history-icon"
                                                }
                                              })
                                            ]
                                          ),
                                          _c(
                                            "b-col",
                                            {
                                              staticClass: "float-left",
                                              attrs: {
                                                "data-test-hook":
                                                  "select-subscription-type-step-10-free-status-history-text"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t("xStatusHistory")
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        {
                                          staticClass: "expandable-click-area",
                                          attrs: { "align-v": "center" }
                                        },
                                        [
                                          _c(
                                            "b-col",
                                            {
                                              staticClass: "pr-0 text-center",
                                              attrs: { cols: "1" }
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "fa-regular fa-envelope-open-text",
                                                attrs: {
                                                  "data-test-hook":
                                                    "select-subscription-type-step-10-free-monthly-email-icon"
                                                }
                                              })
                                            ]
                                          ),
                                          _c(
                                            "b-col",
                                            {
                                              staticClass: "float-left",
                                              attrs: {
                                                "data-test-hook":
                                                  "select-subscription-type-step-10-free-monthly-email-text"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "xMonthlySummaryEmail"
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-row",
                                        {
                                          staticClass: "expandable-click-area",
                                          attrs: { "align-v": "center" }
                                        },
                                        [
                                          _c(
                                            "b-col",
                                            {
                                              staticClass: "pr-0 text-center",
                                              attrs: { cols: "1" }
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "fa-regular fa-toolbox",
                                                attrs: {
                                                  "data-test-hook":
                                                    "select-subscription-type-step-10-free-maintenance-icon"
                                                }
                                              })
                                            ]
                                          ),
                                          _c(
                                            "b-col",
                                            {
                                              staticClass: "float-left",
                                              attrs: {
                                                "data-test-hook":
                                                  "select-subscription-type-step-10-free-maintenance-text"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "xMaintenanceTracking"
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "b-row",
            { staticClass: "mt-3", attrs: { "no-gutters": "" } },
            [
              _c(
                "b-col",
                {
                  staticClass: "float-right text-right pr-2",
                  attrs: { cols: "6" }
                },
                [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        variant: "outline-warning",
                        "data-test-hook":
                          "select-subscription-type-step-10-cancel-button"
                      },
                      on: {
                        click: function($event) {
                          return _vm.closeEnrollmentModals()
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("xCancel")) + " ")]
                  )
                ],
                1
              ),
              _c(
                "b-col",
                { staticClass: "float-left pl-2", attrs: { cols: "6" } },
                [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        variant: "warning",
                        disabled: !_vm.selectedProduct || _vm.paying,
                        "data-test-hook":
                          "select-subscription-type-step-10-confirm-plan-button"
                      },
                      on: {
                        click: function($event) {
                          return _vm.getExistingBillingInfo()
                        }
                      }
                    },
                    [
                      _vm._v(" " + _vm._s(_vm.$t("xConfirmPlan")) + " "),
                      _vm.paying
                        ? _c("b-spinner", { staticClass: "ml-2" })
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "mt-3" },
            [
              _c("b-col", { staticClass: "text-center" }, [
                _c("p", { staticClass: "text-small text-gray-500" }, [
                  _vm._v(
                    "*" +
                      _vm._s(
                        _vm.$t("xPushNotificationsAreAvailableWithInstallation")
                      )
                  )
                ])
              ])
            ],
            1
          ),
          _vm.showSelectSubError
            ? _c(
                "div",
                {
                  staticClass: "alert alert--validation alert-danger",
                  attrs: { show: _vm.showSelectSubError }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("xFailedSetUpGeneratorSubscription")) +
                      " "
                  )
                ]
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "enter-payment-info-step-11",
            size: "lg",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            "no-fade": "",
            "no-stacking": "",
            centered: ""
          },
          on: {
            shown: _vm.OnShowSubscriptionModal_Purchase,
            hidden: _vm.OnHideSubscriptionModal_Purchase
          },
          scopedSlots: _vm._u([
            {
              key: "modal-header",
              fn: function() {
                return [
                  _c(
                    "b-row",
                    { attrs: { "align-v": "center" } },
                    [
                      _c(
                        "b-col",
                        { staticClass: "pr-0", attrs: { cols: "auto" } },
                        [
                          _c(
                            "b-button",
                            {
                              directives: [
                                {
                                  name: "b-modal",
                                  rawName:
                                    "v-b-modal.select-subscription-type-step-10",
                                  modifiers: {
                                    "select-subscription-type-step-10": true
                                  }
                                }
                              ],
                              attrs: {
                                variant: "icon-button",
                                "data-test-hook":
                                  "back-button-step11-enrollment"
                              }
                            },
                            [
                              _c("i", {
                                staticClass:
                                  "fas fa-chevron-left back-chevron-button"
                              })
                            ]
                          )
                        ],
                        1
                      ),
                      _c("b-col", [
                        _c("h2", { staticClass: "modal-title" }, [
                          _c("i", {
                            staticClass: "fal fa-credit-card colorOrange mr-1"
                          }),
                          _vm._v(
                            " " + _vm._s(_vm.$t("xAddAPaymentMethod")) + " "
                          )
                        ])
                      ])
                    ],
                    1
                  )
                ]
              },
              proxy: true
            },
            {
              key: "modal-footer",
              fn: function() {
                return [
                  _vm.useExistingBillingInformation
                    ? _c(
                        "b-button",
                        {
                          attrs: {
                            variant: "primary",
                            block: "",
                            disabled: !_vm.canPay(),
                            "data-test-hook": "PayBtn"
                          },
                          on: {
                            click: function($event) {
                              return _vm.payForProduct()
                            }
                          }
                        },
                        [
                          _vm._v(" " + _vm._s(_vm.$t("xPayNow")) + " "),
                          _vm.paying
                            ? _c("b-spinner", { staticClass: "ml-2" })
                            : _vm._e()
                        ],
                        1
                      )
                    : _c(
                        "b-button",
                        {
                          attrs: {
                            variant: "outline-warning",
                            block: "",
                            disabled: _vm.savingAccountHolder,
                            "data-test-hook":
                              "confirm-billing-info-button-step11-enrollment"
                          },
                          on: {
                            click: function($event) {
                              _vm.saveAccountHolder(),
                                (_vm.savingAccountHolder = true)
                            }
                          }
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("xConfirmBillingInfo")) + " "
                          ),
                          _vm.savingAccountHolder
                            ? _c("b-spinner", { staticClass: "ml-2" })
                            : _vm._e()
                        ],
                        1
                      )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c(
            "b-alert",
            {
              attrs: {
                show: _vm.showCreditCardValidationBanner,
                variant: "danger"
              }
            },
            [
              _c("p", [_vm._v(_vm._s(_vm.$t("xExperiencedTheseErrors")))]),
              _vm._l(_vm.errorMessages, function(message, index) {
                return _c("ul", { key: index }, [
                  _c("li", { attrs: { id: index } }, [
                    _vm._v(" " + _vm._s(message) + " ")
                  ])
                ])
              })
            ],
            2
          ),
          _vm.gettingPaymentInfo
            ? _c(
                "div",
                [
                  _c(
                    "b-col",
                    { staticClass: "float-center text-center" },
                    [
                      _c("b-spinner", {
                        staticStyle: { width: "10rem", height: "10rem" },
                        attrs: { variant: "primary", label: "Spinning" }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _c(
                "div",
                [
                  _vm.existingPaymentInfo
                    ? _c(
                        "div",
                        [
                          _vm.useExistingPaymentInfo
                            ? _c(
                                "b-row",
                                [
                                  _c("b-col", [
                                    _c("h3", [
                                      _c("strong", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "xCurrentCreditCardInformationOnFile"
                                            )
                                          )
                                        )
                                      ]),
                                      _c(
                                        "span",
                                        { staticClass: "body-text-color" },
                                        [_vm._v("*")]
                                      )
                                    ])
                                  ])
                                ],
                                1
                              )
                            : _c(
                                "b-row",
                                [
                                  _c("b-col", [
                                    _c("h3", [
                                      _c("strong", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "xUpdateCreditCardInformation"
                                            )
                                          )
                                        )
                                      ]),
                                      _c(
                                        "span",
                                        { staticClass: "body-text-color" },
                                        [_vm._v("*")]
                                      )
                                    ])
                                  ])
                                ],
                                1
                              ),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                {
                                  attrs: {
                                    "data-test-hook": "CreditCardCheckbox"
                                  }
                                },
                                [
                                  _c(
                                    "b-form-checkbox",
                                    {
                                      staticClass: "font-weight-bold",
                                      model: {
                                        value: _vm.useExistingPaymentInfo,
                                        callback: function($$v) {
                                          _vm.useExistingPaymentInfo = $$v
                                        },
                                        expression: "useExistingPaymentInfo"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "xUseCurrentCreditCardInformation"
                                            )
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm.useExistingPaymentInfo
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "b-row",
                                    { staticClass: "mt-2" },
                                    [
                                      _c("b-col", { attrs: { cols: "12" } }, [
                                        _c("p", [
                                          _c("strong", [
                                            _c("i", {
                                              staticClass: "fal fa-credit-card"
                                            }),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.existingPaymentInfo
                                                    .cardType
                                                ) +
                                                " - " +
                                                _vm._s(
                                                  _vm.existingPaymentInfo
                                                    .cardSuffix
                                                ) +
                                                " "
                                            )
                                          ])
                                        ])
                                      ])
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    [
                                      _c("b-col", { attrs: { cols: "12" } }, [
                                        _c("p", [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t("xExpires")) +
                                              " " +
                                              _vm._s(
                                                _vm.existingPaymentInfo
                                                  .expirationMonth +
                                                  "/" +
                                                  _vm.existingPaymentInfo
                                                    .expirationYear
                                              ) +
                                              " "
                                          )
                                        ])
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _c(
                                "div",
                                [
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                id: "credit-card-number"
                                              }
                                            },
                                            [
                                              _c("b-form-input", {
                                                directives: [
                                                  {
                                                    name: "mask",
                                                    rawName: "v-mask",
                                                    value: "################",
                                                    expression:
                                                      "'################'"
                                                  }
                                                ],
                                                attrs: {
                                                  type: "text",
                                                  maxlength: "16",
                                                  placeholder: _vm.$t(
                                                    "xCreditCardNumber"
                                                  ),
                                                  state: _vm.validState(
                                                    _vm.$v.creditCardNumber
                                                  ),
                                                  "aria-describedby":
                                                    "card-number-invalid"
                                                },
                                                model: {
                                                  value:
                                                    _vm.$v.creditCardNumber
                                                      .$model,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.$v.creditCardNumber,
                                                      "$model",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "$v.creditCardNumber.$model"
                                                }
                                              }),
                                              _c(
                                                "b-form-invalid-feedback",
                                                {
                                                  attrs: {
                                                    id: "card-number-invalid"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "xCCFieldValidation"
                                                        )
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        {
                                          attrs: { lg: "6", md: "t6", sm: "12" }
                                        },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: { id: "expiry-date-month" }
                                            },
                                            [
                                              _c("b-form-input", {
                                                directives: [
                                                  {
                                                    name: "mask",
                                                    rawName: "v-mask",
                                                    value: "##",
                                                    expression: "'##'"
                                                  }
                                                ],
                                                attrs: {
                                                  type: "text",
                                                  placeholder: _vm.$t("xMm"),
                                                  maxlength: "2",
                                                  state: _vm.validState(
                                                    _vm.$v.expirationMonth
                                                  ),
                                                  "aria-describedby":
                                                    "expiry-month-invalid"
                                                },
                                                model: {
                                                  value:
                                                    _vm.$v.expirationMonth
                                                      .$model,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.$v.expirationMonth,
                                                      "$model",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "$v.expirationMonth.$model"
                                                }
                                              }),
                                              _c(
                                                "b-form-invalid-feedback",
                                                {
                                                  attrs: {
                                                    id: "expiry-month-invalid"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "xExpirationMonthValidation"
                                                        )
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        {
                                          attrs: { lg: "6", md: "6", sm: "12" }
                                        },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: { id: "expiry-date-year" }
                                            },
                                            [
                                              _c("b-form-input", {
                                                directives: [
                                                  {
                                                    name: "mask",
                                                    rawName: "v-mask",
                                                    value: "##",
                                                    expression: "'##'"
                                                  }
                                                ],
                                                attrs: {
                                                  type: "text",
                                                  placeholder: _vm.$t("xYy"),
                                                  maxlength: "2",
                                                  state: _vm.validState(
                                                    _vm.$v.expirationYear
                                                  ),
                                                  "aria-describedby":
                                                    "expiry-year-invalid"
                                                },
                                                model: {
                                                  value:
                                                    _vm.$v.expirationYear
                                                      .$model,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.$v.expirationYear,
                                                      "$model",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "$v.expirationYear.$model"
                                                }
                                              }),
                                              _c(
                                                "b-form-invalid-feedback",
                                                {
                                                  attrs: {
                                                    id: "expiry-year-invalid"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "xExpirationYearValidation"
                                                        )
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c(
                                            "b-input-group",
                                            { attrs: { id: "cvv" } },
                                            [
                                              _c("b-form-input", {
                                                directives: [
                                                  {
                                                    name: "mask",
                                                    rawName: "v-mask",
                                                    value: "####",
                                                    expression: "'####'"
                                                  }
                                                ],
                                                attrs: {
                                                  type: "text",
                                                  maxlength: "4",
                                                  placeholder: _vm.$t("xCvv"),
                                                  state: _vm.validState(
                                                    _vm.$v.cvv
                                                  ),
                                                  "aria-describedby":
                                                    "cvv-invalid"
                                                },
                                                model: {
                                                  value: _vm.$v.cvv.$model,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.$v.cvv,
                                                      "$model",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "$v.cvv.$model"
                                                }
                                              }),
                                              _c(
                                                "b-input-group-append",
                                                [
                                                  _c(
                                                    "b-input-group-text",
                                                    [
                                                      _c("b-img", {
                                                        attrs: {
                                                          height: 20,
                                                          src: require("@/assets/images/3-digit-code.svg")
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              ),
                                              _c(
                                                "b-form-invalid-feedback",
                                                {
                                                  attrs: { id: "cvv-invalid" }
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t("xCVVValidation")
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                        ],
                        1
                      )
                    : _c(
                        "div",
                        [
                          _c(
                            "b-row",
                            [
                              _c("b-col", { attrs: { cols: "12" } }, [
                                _c("h3", [
                                  _c("strong", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "xEnterYourCreditCardInformation"
                                        )
                                      )
                                    )
                                  ])
                                ])
                              ])
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c(
                                    "b-form-group",
                                    { attrs: { id: "credit-card-number" } },
                                    [
                                      _c("b-form-input", {
                                        directives: [
                                          {
                                            name: "mask",
                                            rawName: "v-mask",
                                            value: "################",
                                            expression: "'################'"
                                          }
                                        ],
                                        attrs: {
                                          type: "text",
                                          maxlength: "16",
                                          placeholder: _vm.$t(
                                            "xCreditCardNumber"
                                          ),
                                          state: _vm.validState(
                                            _vm.$v.creditCardNumber
                                          ),
                                          "aria-describedby":
                                            "card-number-invalid"
                                        },
                                        model: {
                                          value: _vm.$v.creditCardNumber.$model,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.$v.creditCardNumber,
                                              "$model",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "$v.creditCardNumber.$model"
                                        }
                                      }),
                                      _c(
                                        "b-form-invalid-feedback",
                                        {
                                          attrs: { id: "card-number-invalid" }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("xCCFieldValidation")
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { attrs: { lg: "6", md: "6", sm: "12" } },
                                [
                                  _c(
                                    "b-form-group",
                                    { attrs: { id: "expiry-date-month" } },
                                    [
                                      _c("b-form-input", {
                                        directives: [
                                          {
                                            name: "mask",
                                            rawName: "v-mask",
                                            value: "##",
                                            expression: "'##'"
                                          }
                                        ],
                                        attrs: {
                                          type: "text",
                                          placeholder: _vm.$t("xMm"),
                                          maxlength: "2",
                                          state: _vm.validState(
                                            _vm.$v.expirationMonth
                                          ),
                                          "aria-describedby":
                                            "expiry-month-invalid"
                                        },
                                        model: {
                                          value: _vm.$v.expirationMonth.$model,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.$v.expirationMonth,
                                              "$model",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "$v.expirationMonth.$model"
                                        }
                                      }),
                                      _c(
                                        "b-form-invalid-feedback",
                                        {
                                          attrs: { id: "expiry-month-invalid" }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "xExpirationMonthValidation"
                                                )
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { attrs: { lg: "6", md: "6", sm: "12" } },
                                [
                                  _c(
                                    "b-form-group",
                                    { attrs: { id: "expiry-date-year" } },
                                    [
                                      _c("b-form-input", {
                                        directives: [
                                          {
                                            name: "mask",
                                            rawName: "v-mask",
                                            value: "##",
                                            expression: "'##'"
                                          }
                                        ],
                                        attrs: {
                                          type: "text",
                                          placeholder: _vm.$t("xYy"),
                                          maxlength: "2",
                                          state: _vm.validState(
                                            _vm.$v.expirationYear
                                          ),
                                          "aria-describedby":
                                            "expiry-year-invalid"
                                        },
                                        model: {
                                          value: _vm.$v.expirationYear.$model,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.$v.expirationYear,
                                              "$model",
                                              $$v
                                            )
                                          },
                                          expression: "$v.expirationYear.$model"
                                        }
                                      }),
                                      _c(
                                        "b-form-invalid-feedback",
                                        {
                                          attrs: { id: "expiry-year-invalid" }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "xExpirationYearValidation"
                                                )
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c(
                                    "b-input-group",
                                    { attrs: { id: "cvv" } },
                                    [
                                      _c("b-form-input", {
                                        directives: [
                                          {
                                            name: "mask",
                                            rawName: "v-mask",
                                            value: "####",
                                            expression: "'####'"
                                          }
                                        ],
                                        attrs: {
                                          type: "text",
                                          maxlength: "4",
                                          placeholder: _vm.$t("xCvv"),
                                          state: _vm.validState(_vm.$v.cvv),
                                          "aria-describedby": "cvv-invalid"
                                        },
                                        model: {
                                          value: _vm.$v.cvv.$model,
                                          callback: function($$v) {
                                            _vm.$set(_vm.$v.cvv, "$model", $$v)
                                          },
                                          expression: "$v.cvv.$model"
                                        }
                                      }),
                                      _c(
                                        "b-input-group-append",
                                        [
                                          _c(
                                            "b-input-group-text",
                                            [
                                              _c("b-img", {
                                                attrs: {
                                                  height: 20,
                                                  src: require("@/assets/images/3-digit-code.svg"),
                                                  id: "ccCvvImage"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-form-invalid-feedback",
                                        { attrs: { id: "cvv-invalid" } },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t("xCVVValidation")) +
                                              " "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                  _vm.accountData
                    ? _c(
                        "div",
                        { staticClass: "mt-4" },
                        [
                          _c(
                            "b-row",
                            { staticClass: "mb-2" },
                            [
                              _c(
                                "b-col",
                                [
                                  _c(
                                    "b-form-checkbox",
                                    {
                                      staticClass: "font-weight-bold",
                                      attrs: {
                                        disabled: _vm.savingAccountHolder
                                      },
                                      model: {
                                        value:
                                          _vm.useExistingBillingInformation,
                                        callback: function($$v) {
                                          _vm.useExistingBillingInformation = $$v
                                        },
                                        expression:
                                          "useExistingBillingInformation"
                                      }
                                    },
                                    [
                                      _c("p", [
                                        _c("strong", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("xUseCurrentBillingInfo")
                                            )
                                          )
                                        ])
                                      ])
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm.useExistingBillingInformation
                            ? _c(
                                "b-row",
                                [
                                  _c("b-col", { attrs: { cols: "12" } }, [
                                    _vm._v(
                                      _vm._s(_vm.accountData.firstName) +
                                        " " +
                                        _vm._s(_vm.accountData.lastName)
                                    )
                                  ]),
                                  _c("b-col", { attrs: { cols: "12" } }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.DisplayAddress(
                                            _vm.accountData.address
                                          )
                                        ) +
                                        " "
                                    )
                                  ])
                                ],
                                1
                              )
                            : _vm._e(),
                          !_vm.useExistingBillingInformation
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: { id: "firstName-group" }
                                            },
                                            [
                                              _c("b-form-input", {
                                                attrs: {
                                                  id: "firstName",
                                                  type: "text"
                                                },
                                                model: {
                                                  value:
                                                    _vm.accountData.firstName,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.accountData,
                                                      "firstName",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "accountData.firstName"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            { attrs: { id: "lastName-group" } },
                                            [
                                              _c("b-form-input", {
                                                attrs: {
                                                  id: "lastName",
                                                  type: "text"
                                                },
                                                model: {
                                                  value:
                                                    _vm.accountData.lastName,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.accountData,
                                                      "lastName",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "accountData.lastName"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        [
                                          _c("TypeAheadAddress", {
                                            ref:
                                              "typeAheadAddressBillingAddress",
                                            attrs: {
                                              currentAddress:
                                                _vm.accountData.address
                                            },
                                            on: {
                                              newAddress:
                                                _vm.setNewBillingAddress
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.selectedProduct
                    ? _c(
                        "div",
                        [
                          _c(
                            "b-row",
                            { staticClass: "my-3" },
                            [
                              _c("b-col", { attrs: { cols: "12" } }, [
                                _c(
                                  "div",
                                  { staticClass: "subscription-cost" },
                                  [
                                    _c("h3", { staticClass: "mb-0" }, [
                                      _vm._v(
                                        _vm._s(_vm.$t("xTotalPayment")) +
                                          ": " +
                                          _vm._s(
                                            _vm._f("formatCurrency")(
                                              _vm.selectedProduct.cost.toFixed(
                                                2
                                              )
                                            )
                                          )
                                      )
                                    ]),
                                    _c("p", [
                                      _c("i", [
                                        _vm._v(
                                          " " + _vm._s(_vm.$t("xBilled")) + " "
                                        ),
                                        _vm.selectedProduct.durationInMonths ==
                                        12
                                          ? _c("span", [
                                              _vm._v(_vm._s(_vm.$t("xYearly")))
                                            ])
                                          : _c("span", [
                                              _vm._v(_vm._s(_vm.$t("xMonthly")))
                                            ])
                                      ])
                                    ])
                                  ]
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "b-row",
                    [
                      _c("b-col", [
                        _c("p", [
                          _c("i", { staticClass: "asterisk-text" }, [
                            _vm._v(
                              "*" +
                                _vm._s(
                                  _vm.$t("xUpdatingCCApplyActiveSubscriptions")
                                ) +
                                " "
                            )
                          ])
                        ])
                      ])
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "mt-3" },
                    [
                      _c("b-col", [
                        _c("p", [
                          _c("i", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("xThisSubscriptionWillAutoRenew")
                                ) +
                                " "
                            )
                          ])
                        ])
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
          _vm.showSavingAccountHolderError
            ? _c(
                "div",
                {
                  staticClass: "alert alert--validation alert-danger",
                  attrs: {
                    role: "alert",
                    show: _vm.showSavingAccountHolderError
                  }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("xFailedToUpdateBillingNameAndAddress")) +
                      " "
                  )
                ]
              )
            : _vm._e(),
          _vm.showPayForProductError
            ? _c(
                "div",
                {
                  staticClass: "alert alert--validation alert-danger",
                  attrs: { role: "alert", show: _vm.showPayForProductError }
                },
                [_vm._v(" " + _vm._s(_vm.payForProductErrorMessage) + " ")]
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "Modal",
        { attrs: { modalId: "subscription-success" } },
        [
          _c(
            "ModalHeader",
            {
              attrs: {
                title: _vm.$t("xCongratulations") + "!",
                icon: "colorOrange fal fa-badge-dollar"
              }
            },
            [
              _c(
                "button",
                {
                  staticClass: "btn close",
                  attrs: { "data-test-hook": "modalXCloseBtn" },
                  on: {
                    click: function($event) {
                      _vm.closeEnrollmentModals(),
                        _vm.trackAnalytics(
                          "subscription-success-modal-cancel-button-clicked"
                        )
                    }
                  }
                },
                [_c("i", { staticClass: "fas fa-times" })]
              )
            ]
          ),
          _c("ModalBody", [
            _c("div", { attrs: { "data-test-hook": "modalBodyText1" } }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t("xYourGeneratorIsSubscribedForRemoteMonitoring")
                  ) +
                  " "
              )
            ]),
            _vm.selectedFuelType == "LP"
              ? _c("div", [
                  _c(
                    "div",
                    {
                      staticClass: "mt-3",
                      staticStyle: {
                        "font-size": "1rem",
                        "font-weight": "600"
                      },
                      attrs: { "data-test-hook": "modalBodyText2" }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("xYourGeneratorIsRunningOnPropane")) +
                          " "
                      )
                    ]
                  ),
                  _c("div", { attrs: { "data-test-hook": "modalBodyText3" } }, [
                    _vm._v(
                      " " + _vm._s(_vm.$t("xFeelEvenMoreSecureKnowing")) + " "
                    ),
                    _c("b", [_vm._v(_vm._s(_vm.$t("xFree")))]),
                    _vm._v("! ")
                  ]),
                  _c("div", { staticClass: "row ml-1" }, [
                    _c(
                      "div",
                      {
                        staticClass: "mt-3 btn",
                        staticStyle: {
                          "font-size": "1rem",
                          "font-weight": "600",
                          "padding-top": "0px",
                          "border-top-width": "0px",
                          "padding-left": "0px",
                          "padding-right": "0px",
                          "margin-top": "10px"
                        },
                        on: {
                          click: function($event) {
                            return _vm.beginTankEnrollmentFlow()
                          }
                        }
                      },
                      [
                        _c("span", {
                          staticClass: "colorRed fas fa-plus-circle mr-1",
                          staticStyle: { "font-size": "1rem" }
                        }),
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "text-transform": "none !important"
                            },
                            attrs: { "data-test-hook": "modalAddATankBtn" }
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.$t("xAddATankMonitor")) + " "
                            )
                          ]
                        )
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "ml-2",
                        staticStyle: { "padding-top": "17px" }
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm._f("lowercase")(_vm.$t("xOR"))) + " "
                        )
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "ml-2",
                        staticStyle: { "padding-top": "16px" },
                        attrs: { "data-test-hook": "modalLearnAboutTanksBtn" }
                      },
                      [
                        _c(
                          "a",
                          {
                            staticStyle: { "text-decoration": "underline" },
                            attrs: {
                              href:
                                "https://www.generac.com/for-homeowners/mobile-link/propane-monitoring",
                              target: "_blank"
                            },
                            on: {
                              click: function($event) {
                                return _vm.trackAnalytics(
                                  "subscription-success-modal-tank-monitor-info-clicked"
                                )
                              }
                            }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("xLearnMoreAboutTankMonitors"))
                            ),
                            _c("i", {
                              staticClass: "ml-1 fas fa-external-link"
                            })
                          ]
                        )
                      ]
                    )
                  ])
                ])
              : _vm._e()
          ]),
          _c("ModalFooter", [
            _c(
              "div",
              {
                staticClass: "btn btn-primary",
                attrs: { "data-test-hook": "GenDetailsBtn" },
                on: {
                  click: function($event) {
                    _vm.trackAnalytics(
                      "subscription-success-modal-view-gen-details-clicked"
                    ),
                      _vm.goToNewGenerator()
                  }
                }
              },
              [_vm._v(" " + _vm._s(_vm.$t("xViewGenDetails")) + " ")]
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }