var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mt-2 ml-md-2" },
    [
      _vm.preferredDealerLoading
        ? _c("div", { staticClass: "d-flex justify-content-center" }, [
            _c("span", {
              staticClass: "spinner-border text-warning",
              staticStyle: { width: "5rem", height: "5rem" },
              attrs: { "data-test-hook": "LoadingSpinner" }
            })
          ])
        : _vm._e(),
      !_vm.preferredDealerLoading
        ? _c("div", [
            _vm.preferredDealer &&
            !_vm.editPreferredDealer &&
            (_vm.isHsbApparatus() || _vm.isPortableApparatus())
              ? _c("div", [
                  _c("div", { staticClass: "row no-gutters mb-2" }, [
                    _c("div", { staticClass: "col" }, [
                      _vm.isPortableApparatus()
                        ? _c(
                            "h1",
                            {
                              staticClass: "mb-0 pb-0",
                              attrs: { "data-test-hook": "PortablePageTitle" }
                            },
                            [
                              _c("i", {
                                staticClass:
                                  "far fa-user-hard-hat colorOrange mr-1"
                              }),
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("xPreferredServiceDealer")) +
                                  " "
                              )
                            ]
                          )
                        : _c(
                            "h1",
                            {
                              staticClass: "mb-0 pb-0",
                              attrs: { "data-test-hook": "HsbPageTitle" }
                            },
                            [
                              _c("i", {
                                staticClass:
                                  "far fa-user-hard-hat colorOrange mr-1"
                              }),
                              _vm._v(
                                " " + _vm._s(_vm.$t("xPreferredDealer")) + " "
                              )
                            ]
                          )
                    ])
                  ]),
                  _c("div", { staticClass: "card" }, [
                    _c("div", { staticClass: "card-body" }, [
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col" }, [
                          _vm.preferredDealer.name
                            ? _c(
                                "div",
                                { staticClass: "row align-content-center" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "col d-flex align-items-center"
                                    },
                                    [
                                      _c(
                                        "h5",
                                        {
                                          staticClass: "mb-0",
                                          attrs: {
                                            "data-test-hook": "DealerName"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.preferredDealer.name)
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  !_vm.preferredDealer.isDealerManaged &&
                                  !_vm.preferredDealer.isDealerUnmonitored
                                    ? _c(
                                        "div",
                                        { staticClass: "col float-right" },
                                        [
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "btn btn-icon-button float-right",
                                              attrs: {
                                                type: "button",
                                                "data-test-hook":
                                                  "DeleteDealerBtn"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.openModal(
                                                    "deletePreferredDealer-modal"
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "fas fa-trash-alt"
                                              })
                                            ]
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ]
                              )
                            : _vm._e(),
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col" }, [
                              _vm.preferredDealer.topLineAddress
                                ? _c(
                                    "p",
                                    {
                                      attrs: {
                                        "data-test-hook": "DealerAddress1"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.preferredDealer.topLineAddress
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm.preferredDealer.bottomLineAddress
                                ? _c(
                                    "p",
                                    {
                                      attrs: {
                                        "data-test-hook": "DealerAddress2"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.preferredDealer
                                              .bottomLineAddress
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm.preferredDealer.phoneNumber
                                ? _c(
                                    "h5",
                                    {
                                      attrs: {
                                        "no-gutters": "",
                                        "data-test-hook": "DealerPhoneNumber"
                                      }
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "far fa-fw fa-phone-alt"
                                      }),
                                      _vm._v(" "),
                                      _c("strong", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("phone")(
                                              _vm.preferredDealer.phoneNumber
                                            )
                                          )
                                        )
                                      ])
                                    ]
                                  )
                                : _vm._e(),
                              _vm.preferredDealer.email
                                ? _c(
                                    "p",
                                    {
                                      attrs: { "data-test-hook": "DealerEmail" }
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "far fa-fw fa-envelope"
                                      }),
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.preferredDealer.email) +
                                          " "
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm.preferredDealer.email
                                ? _c(
                                    "a",
                                    {
                                      staticClass:
                                        "mt-3 btn btn-outline-warning",
                                      attrs: {
                                        type: "button",
                                        href:
                                          "mailto:" + _vm.preferredDealer.email,
                                        target: "_self",
                                        "data-test-hook": "EmailDealerBtn"
                                      }
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "fas fa-envelope"
                                      }),
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("xEmailDealer")) +
                                          " "
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ])
                          ])
                        ])
                      ])
                    ])
                  ])
                ])
              : _vm.preferredDealer && _vm.isTankApparatus()
              ? _c("div", { staticClass: "mb-2" }, [
                  _c(
                    "div",
                    {
                      staticClass: "row no-gutters",
                      attrs: { "data-test-hook": "FuelProviderPage" }
                    },
                    [
                      _c("div", { staticClass: "col" }, [
                        _c(
                          "h1",
                          {
                            staticClass: "mb-0 pb-0",
                            attrs: { "data-test-hook": "FuelProviderPageTitle" }
                          },
                          [
                            _c("i", {
                              staticClass:
                                "far fa-user-hard-hat colorOrange mr-1",
                              attrs: {
                                "data-test-hook": "FuelProviderPageTitleIcon"
                              }
                            }),
                            _vm._v(" " + _vm._s(_vm.$t("xFuelProvider")) + " ")
                          ]
                        )
                      ])
                    ]
                  ),
                  _c("div", { staticClass: "card mt-2" }, [
                    _c("div", { staticClass: "card-body" }, [
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col" }, [
                          _c(
                            "p",
                            { attrs: { "data-test-hook": "FuelProviderName" } },
                            [
                              _c("strong", [
                                _vm._v(_vm._s(_vm.preferredDealer.name))
                              ])
                            ]
                          ),
                          _c(
                            "p",
                            {
                              attrs: { "data-test-hook": "FuelProviderRegion" }
                            },
                            [
                              _c("i", {
                                staticClass: "fa-light fa-location-dot",
                                attrs: {
                                  "data-test-hook": "FuelProviderRegionIcon"
                                }
                              }),
                              _vm._v(
                                " " + _vm._s(_vm.preferredDealer.region) + " "
                              )
                            ]
                          )
                        ]),
                        _c("div", { staticClass: "cols-auto float-right" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-icon-button ml-3",
                              attrs: {
                                type: "button",
                                "data-test-hook": "EditFuelProviderBtn"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.openModal("edit-fuel-provider")
                                }
                              }
                            },
                            [
                              _c("i", {
                                staticClass: "fas fa-pencil",
                                attrs: {
                                  "data-test-hook": "EditFuelProviderBtnIcon"
                                }
                              })
                            ]
                          ),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-icon-button",
                              attrs: {
                                type: "button",
                                "data-test-hook": "DeleteFuelProviderBtn"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.openModal(
                                    "deletePreferredDealer-modal"
                                  )
                                }
                              }
                            },
                            [
                              _c("i", {
                                staticClass: "fas fa-trash-alt",
                                attrs: {
                                  "data-test-hook": "DeleteFuelProviderBtnIcon"
                                }
                              })
                            ]
                          )
                        ])
                      ])
                    ])
                  ])
                ])
              : _vm._e(),
            !_vm.preferredDealer
              ? _c("div", [
                  _vm.isHsbApparatus() || _vm.isPortableApparatus()
                    ? _c("div", [
                        _c("div", { staticClass: "row mt-4" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "col d-flex align-items-center flex-column text-center"
                            },
                            [
                              _c("i", {
                                staticClass:
                                  "fal fa-user-hard-hat locate-dealer-hardhat"
                              }),
                              _vm.isPortableApparatus()
                                ? _c(
                                    "h4",
                                    {
                                      staticClass: "mt-3",
                                      attrs: {
                                        "data-test-hook": "PortPageTitle"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("xNoPreferredServiceDealer")
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                : _c(
                                    "h4",
                                    {
                                      staticClass: "mt-3",
                                      attrs: {
                                        "data-test-hook": "NoDealerText"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("xNoPreferredDealer")) +
                                          " "
                                      )
                                    ]
                                  ),
                              _c(
                                "p",
                                {
                                  attrs: {
                                    "data-test-hook": "DealerNotSetUpText"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("xPreferredDealerNotSetup")
                                      ) +
                                      " "
                                  )
                                ]
                              ),
                              _vm.isHsbApparatus()
                                ? _c(
                                    "button",
                                    {
                                      staticClass:
                                        "mb-2 mt-2 preferred-dealer-button btn btn-outline-warning btn-block",
                                      attrs: {
                                        type: "button",
                                        "data-test-hook": "AddDealerBtn"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.openModal(
                                            "dealerNameSearch-modal"
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("i", { staticClass: "fas fa-plus" }),
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("xAddInstaller")) +
                                          " "
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "btn btn-block btn-warning preferred-dealer-button",
                                  attrs: {
                                    type: "button",
                                    "data-test-hook": "LocateDealerBtn"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.openModal(
                                        "locateDealerSearch-modal"
                                      )
                                    }
                                  }
                                },
                                [
                                  _c("i", { staticClass: "fa fa-search" }),
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("xLocateADealer")) + " "
                                  )
                                ]
                              )
                            ]
                          )
                        ])
                      ])
                    : _vm.isTankApparatus()
                    ? _c("div", [
                        _c(
                          "div",
                          {
                            staticClass: "row mt-4",
                            attrs: { "data-test-hook": "FuelProviderPage" }
                          },
                          [
                            _c("div", { staticClass: "col-12 text-center" }, [
                              _c("i", {
                                staticClass:
                                  "fal fa-user-hard-hat locate-dealer-hardhat",
                                attrs: {
                                  "data-test-hook":
                                    "NoFuelProviderPageTitleIcon"
                                }
                              }),
                              _c(
                                "h4",
                                {
                                  staticClass: "mt-3",
                                  attrs: {
                                    "data-test-hook": "NoFuelProviderPageTitle"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("xNoFuelProvider")) +
                                      " "
                                  )
                                ]
                              ),
                              _c(
                                "p",
                                {
                                  attrs: {
                                    "data-test-hook":
                                      "NoFuelProviderPageBodyText"
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("xNoFuelProviderMessage"))
                                  )
                                ]
                              ),
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-warning mt-2",
                                  attrs: {
                                    type: "button",
                                    "data-test-hook": "SelectFuelProviderBtn"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.openModal("edit-fuel-provider")
                                    }
                                  }
                                },
                                [
                                  _c("i", {
                                    staticClass: "fas fa-search",
                                    attrs: {
                                      "data-test-hook":
                                        "SelectFuelProviderBtnIcon"
                                    }
                                  }),
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("xSelectFuelProvider")) +
                                      " "
                                  )
                                ]
                              )
                            ])
                          ]
                        )
                      ])
                    : _vm._e()
                ])
              : _vm._e()
          ])
        : _vm._e(),
      _c(
        "Modal",
        { attrs: { modalId: "deletePreferredDealer-modal", size: "modal-md" } },
        [
          _c("ModalBody", [
            _c("div", { staticClass: "row" }, [
              _vm.isHsbApparatus
                ? _c(
                    "div",
                    {
                      staticClass: "col",
                      attrs: { "data-test-hook": "DeleteDealerWarning" }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("xDeletePreferredDealerWarning")) +
                          " "
                      )
                    ]
                  )
                : _vm.isTankApparatus
                ? _c("div", { staticClass: "col" }, [
                    _vm._v(
                      " " + _vm._s(_vm.$t("xRemoveFuelProviderWarning")) + " "
                    )
                  ])
                : _vm._e()
            ])
          ]),
          _c("ModalFooter", [
            _c(
              "button",
              {
                staticClass: "btn btn-outline-warning",
                attrs: { type: "button", "data-test-hook": "CancelBtn" },
                on: {
                  click: function($event) {
                    return _vm.closeModal("deletePreferredDealer-modal")
                  }
                }
              },
              [_vm._v(" " + _vm._s(_vm.$t("xNoCancel")) + " ")]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-warning",
                attrs: { type: "button", "data-test-hook": "ConfirmBtn" },
                on: {
                  click: function($event) {
                    _vm.deletePreferredDealer(),
                      _vm.closeModal("deletePreferredDealer-modal")
                  }
                }
              },
              [_vm._v(" " + _vm._s(_vm.$t("xYesImSure")) + " ")]
            )
          ])
        ],
        1
      ),
      _c(
        "Modal",
        {
          attrs: {
            modalId: "dealerNameSearch-modal",
            size: "modal-lg",
            "data-test-hook": "LocateDealerModal"
          }
        },
        [
          _c(
            "ModalHeader",
            {
              attrs: {
                title: _vm.$t("xAddInstaller"),
                icon: "fas fa-plus colorOrange mr-1"
              }
            },
            [
              _c("div", { staticClass: "cols-auto" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-icon-button btn-icon-button--gray",
                    attrs: {
                      type: "button",
                      "data-test-hook": "CloseModalBtn"
                    },
                    on: {
                      click: function($event) {
                        return _vm.dealerNameSearchClose()
                      }
                    }
                  },
                  [_c("i", { staticClass: "fas fa-times" })]
                )
              ])
            ]
          ),
          _c("ModalBody", [
            _c("div", { attrs: { id: "searchDealerByName-results" } }, [
              _c("div", { staticClass: "row align-items-center mb-2" }, [
                _c(
                  "div",
                  { staticClass: "cols-auto ml-3" },
                  [
                    _c("i", {
                      staticClass: "fas fa-question-circle",
                      attrs: { id: "dealer-name-search-help" }
                    }),
                    _c(
                      "b-popover",
                      {
                        attrs: {
                          target: "dealer-name-search-help",
                          triggers: "hover focus",
                          placement: "right",
                          "data-test-hook": "AddDealerHelpText"
                        }
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.$t("xDealerNameSearchHelp")) + " "
                        )
                      ]
                    )
                  ],
                  1
                ),
                _c("div", { staticClass: "col" }, [
                  _c(
                    "div",
                    { staticClass: "input-group", attrs: { role: "group" } },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.searchNameCriteria,
                            expression: "searchNameCriteria"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: {
                          placeholder: _vm.$t("xInstallerName"),
                          type: "text",
                          "data-test-hook": "DealerNameSearchField"
                        },
                        domProps: { value: _vm.searchNameCriteria },
                        on: {
                          keyup: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.searchDealersByName()
                          },
                          input: function($event) {
                            if ($event.target.composing) return
                            _vm.searchNameCriteria = $event.target.value
                          }
                        }
                      }),
                      _c("div", { staticClass: "input-group-append" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-warning my-0 py-0",
                            attrs: {
                              type: "button",
                              "data-test-hook": "DealerSearchBtn"
                            },
                            on: {
                              click: function($event) {
                                return _vm.searchDealersByName()
                              }
                            }
                          },
                          [
                            _vm.searchingForDealers
                              ? _c("span", {
                                  staticClass: "spinner-border",
                                  staticStyle: {
                                    width: "1.5rem",
                                    height: "1.5rem"
                                  },
                                  attrs: { "data-test-hook": "SearchSpinner" }
                                })
                              : _vm._e(),
                            _c("i", { staticClass: "fas fa-search" })
                          ]
                        )
                      ])
                    ]
                  )
                ])
              ]),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col" }, [
                  _vm.installingDealers.length > 0
                    ? _c(
                        "div",
                        {
                          staticClass: "card no-border",
                          attrs: { "footer-bg-variant": "white" }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "list-group" },
                            _vm._l(_vm.installingDealers, function(
                              dealer,
                              index
                            ) {
                              return _c(
                                "div",
                                {
                                  key: index,
                                  staticClass:
                                    "list-group-item sidenav hoverPointer searchResult py-4",
                                  attrs: {
                                    "data-test-hook": "DealerSearchResult"
                                  },
                                  on: {
                                    click: function($event) {
                                      _vm.openModal("dealerNameDetail-modal"),
                                        _vm.closeModal(
                                          "dealerNameSearch-modal"
                                        ),
                                        (_vm.installingDealer = dealer)
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "h5",
                                    {
                                      attrs: { "data-test-hook": "DealerName" }
                                    },
                                    [_vm._v(_vm._s(dealer.name))]
                                  ),
                                  _c(
                                    "h6",
                                    {
                                      attrs: { "data-test-hook": "DealerPhone" }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("phone")(dealer.phoneNumber)
                                        )
                                      )
                                    ]
                                  ),
                                  _c(
                                    "h6",
                                    {
                                      attrs: {
                                        "data-test-hook": "DealerTopAddress"
                                      }
                                    },
                                    [_vm._v(_vm._s(dealer.topLineAddress))]
                                  ),
                                  _c(
                                    "h6",
                                    {
                                      attrs: {
                                        "data-test-hook": "DealerBottomAddress"
                                      }
                                    },
                                    [_vm._v(_vm._s(dealer.bottomLineAddress))]
                                  )
                                ]
                              )
                            }),
                            0
                          ),
                          _c("div", { staticClass: "card-footer px-0" }, [
                            _c(
                              "button",
                              {
                                staticClass:
                                  "btn btn-outline-warning btn-block",
                                attrs: {
                                  type: "button",
                                  disabled:
                                    _vm.searchingForDealers ||
                                    _vm.noMoreResults,
                                  "data-test-hook": "ViewMoreBtn"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.searchDealersByName()
                                  }
                                }
                              },
                              [
                                _vm._v(" " + _vm._s(_vm.$t("xViewMore")) + " "),
                                _vm.searchingForDealers
                                  ? _c("span", {
                                      staticClass: "spinner-border"
                                    })
                                  : _vm._e()
                              ]
                            )
                          ])
                        ]
                      )
                    : _vm._e()
                ])
              ])
            ])
          ])
        ],
        1
      ),
      _c(
        "Modal",
        { attrs: { modalId: "closeDealerNameSearch-modal", size: "modal-md" } },
        [
          _c("ModalBody", [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                {
                  staticClass: "col mt-2",
                  attrs: { "data-test-hook": "ExitWarningText" }
                },
                [_vm._v(" " + _vm._s(_vm.$t("xExitWithoutSaving")) + " ")]
              ),
              _c("div", { staticClass: "cols-auto float-right" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-icon-button btn-icon-button--gray",
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        return _vm.closeModal("closeDealerNameSearch-modal")
                      }
                    }
                  },
                  [_c("i", { staticClass: "fas fa-times" })]
                )
              ])
            ])
          ]),
          _c("ModalFooter", [
            _c(
              "button",
              {
                staticClass: "btn btn-outline-warning",
                attrs: { type: "button", "data-test-hook": "CancelBtn" },
                on: {
                  click: function($event) {
                    _vm.closeModal("closeDealerNameSearch-modal"),
                      _vm.openModal("dealerNameDetail-modal")
                  }
                }
              },
              [_vm._v(" " + _vm._s(_vm.$t("xNoCancel")) + " ")]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-warning",
                attrs: { type: "button", "data-test-hook": "ConfirmBtn" },
                on: {
                  click: function($event) {
                    _vm.dealerNameSearchClose(),
                      _vm.closeModal("closeDealerNameSearch-modal")
                  }
                }
              },
              [_vm._v(" " + _vm._s(_vm.$t("xYesImSure")) + " ")]
            )
          ])
        ],
        1
      ),
      _c(
        "Modal",
        { attrs: { modalId: "dealerNameDetail-modal", size: "modal-lg" } },
        [
          _c(
            "ModalHeader",
            {
              attrs: {
                title: _vm.$t("xAddInstaller"),
                icon: "fas fa-plus colorOrange mr-1"
              }
            },
            [
              _c("div", { staticClass: "cols-auto" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-icon-button btn-icon-button--gray",
                    attrs: {
                      type: "button",
                      "data-test-hook": "CloseModalBtn"
                    },
                    on: {
                      click: function($event) {
                        _vm.closeModal("dealerNameDetail-modal"),
                          _vm.openModal("closeDealerNameSearch-modal")
                      }
                    }
                  },
                  [_c("i", { staticClass: "fas fa-times" })]
                )
              ])
            ]
          ),
          _c("ModalBody", [
            _vm.installingDealer
              ? _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col" }, [
                    _c("h5", { attrs: { "data-test-hook": "DealerName" } }, [
                      _vm._v(_vm._s(_vm.installingDealer.name))
                    ]),
                    _c("h5", { attrs: { "data-test-hook": "DealerPhone" } }, [
                      _vm._v(
                        _vm._s(
                          _vm._f("phone")(_vm.installingDealer.phoneNumber)
                        )
                      )
                    ]),
                    _c(
                      "h6",
                      { attrs: { "data-test-hook": "DealerTopAddress" } },
                      [_vm._v(_vm._s(_vm.installingDealer.topLineAddress))]
                    ),
                    _c(
                      "h6",
                      { attrs: { "data-test-hook": "DealerBottomAddress" } },
                      [_vm._v(_vm._s(_vm.installingDealer.bottomLineAddress))]
                    )
                  ])
                ])
              : _vm._e(),
            _c("div", { staticClass: "row mt-3" }, [
              _c("div", { staticClass: "col" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-outline-warning btn-block",
                    attrs: { type: "button", "data-test-hook": "PreviousBtn" },
                    on: {
                      click: function($event) {
                        _vm.openModal("dealerNameSearch-modal"),
                          _vm.closeModal("dealerNameDetail-modal"),
                          (_vm.installingDealer = null)
                      }
                    }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("xSearchAgain")) + " ")]
                ),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-warning btn-block",
                    attrs: { type: "button", "data-test-hook": "SetDealerBtn" },
                    on: {
                      click: function($event) {
                        return _vm.locateDealerDetailSetPreferredDealer(
                          _vm.installingDealer.externalId
                        )
                      }
                    }
                  },
                  [
                    _c("i", { staticClass: "fas fa-user-hard-hat" }),
                    _vm._v(" " + _vm._s(_vm.$t("xSetPreferredDealer")) + " "),
                    _vm.savingNewDealer
                      ? _c("span", {
                          staticClass: "spinner-border ml-2",
                          attrs: { "data-test-hook": "SaveSpinner" }
                        })
                      : _vm._e()
                  ]
                )
              ])
            ])
          ])
        ],
        1
      ),
      _c(
        "Modal",
        {
          ref: "locateDealerSearch-modal",
          attrs: { modalId: "locateDealerSearch-modal", size: "modal-lg" }
        },
        [
          _c(
            "ModalHeader",
            {
              attrs: {
                title: _vm.$t("xLocateADealer"),
                icon: "fa fa-search colorOrange mr-1"
              }
            },
            [
              _c("div", { staticClass: "cols-auto" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-icon-button btn-icon-button--gray",
                    attrs: {
                      type: "button",
                      "data-test-hook": "CloseModalBtn"
                    },
                    on: {
                      click: function($event) {
                        return _vm.locateDealerSearchClose()
                      }
                    }
                  },
                  [_c("i", { staticClass: "fas fa-times" })]
                )
              ])
            ]
          ),
          _c(
            "ModalBody",
            { class: { "modal-height": _vm.dealers.length > 0 } },
            [
              _c(
                "div",
                [
                  _c("b-form-input", {
                    staticClass: "pt-5 mb-0 pb-0",
                    attrs: {
                      type: "range",
                      min: "10",
                      max: "200",
                      step: "10",
                      "data-test-hook": "SearchRadius"
                    },
                    model: {
                      value: _vm.radius,
                      callback: function($$v) {
                        _vm.radius = $$v
                      },
                      expression: "radius"
                    }
                  }),
                  _c("div", { staticClass: "row mt-2" }, [
                    _c(
                      "div",
                      {
                        staticClass: "col float-left asterisk-text",
                        attrs: { "data-test-hook": "MinDistance" }
                      },
                      [_vm._v("10 " + _vm._s(_vm.getDistanceUOMText()))]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "cols-auto float-right asterisk-text mr-3",
                        attrs: { "data-test-hook": "MaxDistance" }
                      },
                      [_vm._v(" 200 " + _vm._s(_vm.getDistanceUOMText()) + " ")]
                    )
                  ]),
                  _c("output", { attrs: { id: "rangevalue" } }, [
                    _vm._v(_vm._s(_vm.radius))
                  ]),
                  _c(
                    "div",
                    { staticClass: "input-group", attrs: { role: "group" } },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.dealerLocatePostalCode,
                            expression: "dealerLocatePostalCode"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: {
                          placeholder: "Postal Code",
                          type: "text",
                          "data-test-hook": "PostalCodeField"
                        },
                        domProps: { value: _vm.dealerLocatePostalCode },
                        on: {
                          keyup: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.searchDealers()
                          },
                          input: function($event) {
                            if ($event.target.composing) return
                            _vm.dealerLocatePostalCode = $event.target.value
                          }
                        }
                      }),
                      _c(
                        "button",
                        {
                          staticClass: "my-0 py-0 btn btn-warning",
                          attrs: { "data-test-hook": "PostalCodeSearchBtn" },
                          on: {
                            click: function($event) {
                              return _vm.searchDealers()
                            }
                          }
                        },
                        [
                          _vm.searchingForDealers
                            ? _c("span", {
                                staticClass: "spinner-border",
                                staticStyle: {
                                  width: "1.5rem",
                                  height: "1.5rem"
                                },
                                attrs: { "data-test-hook": "SearchSpinner" }
                              })
                            : _vm._e(),
                          _c("i", { staticClass: "fas fa-search" })
                        ]
                      )
                    ]
                  )
                ],
                1
              ),
              _c("div", [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col" }, [
                    _c(
                      "div",
                      { staticClass: "list-group" },
                      _vm._l(_vm.dealers, function(dealer, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticClass:
                              "list-group-item sidenav hoverPointer py-4 searchResult",
                            attrs: { "data-test-hook": "DealerSearchResult" },
                            on: {
                              click: function($event) {
                                return _vm.selectDealer(dealer)
                              }
                            }
                          },
                          [
                            _c(
                              "h5",
                              { attrs: { "data-test-hook": "DealerName" } },
                              [_vm._v(_vm._s(dealer.name))]
                            ),
                            _c(
                              "h6",
                              {
                                attrs: { "data-test-hook": "DealerPhoneNumber" }
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm._f("phone")(dealer.phoneNumber))
                                )
                              ]
                            ),
                            _c(
                              "h6",
                              { attrs: { "data-test-hook": "DealerDistance" } },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("xDistance")) +
                                    " " +
                                    _vm._s(
                                      _vm.getDistanceInUom(dealer.distance)
                                    ) +
                                    " " +
                                    _vm._s(_vm.getDistanceUOMText()) +
                                    " "
                                )
                              ]
                            ),
                            _c(
                              "h6",
                              {
                                attrs: {
                                  "data-test-hook": "DealerAddressLine1"
                                }
                              },
                              [_vm._v(_vm._s(dealer.address.addressLine1))]
                            ),
                            _c(
                              "h6",
                              {
                                attrs: {
                                  "data-test-hook": "DealerAddressBottomLine"
                                }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(dealer.address.city) +
                                    ", " +
                                    _vm._s(dealer.address.region) +
                                    " " +
                                    _vm._s(dealer.address.postalCode) +
                                    " "
                                )
                              ]
                            )
                          ]
                        )
                      }),
                      0
                    )
                  ])
                ])
              ])
            ]
          ),
          _c("ModalFooter")
        ],
        1
      ),
      _c(
        "Modal",
        {
          ref: "locateDealerDetail-modal",
          attrs: { modalId: "locateDealerDetail-modal", size: "modal-md" }
        },
        [
          _c(
            "ModalHeader",
            {
              attrs: {
                title: _vm.$t("xLocateADealer"),
                icon: "fa fa-search colorOrange mr-1"
              }
            },
            [
              _c("div", { staticClass: "cols-auto" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-icon-button btn-icon-button--gray",
                    attrs: {
                      type: "button",
                      "data-test-hook": "CloseModalBtn"
                    },
                    on: {
                      click: function($event) {
                        return _vm.dealerLocateDetailClose()
                      }
                    }
                  },
                  [_c("i", { staticClass: "fas fa-times" })]
                )
              ])
            ]
          ),
          _c("ModalBody", [
            _vm.dealerDetail !== null
              ? _c("div", [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "row w-100 mb-2 no-gutters align-items-center"
                        },
                        [
                          _c("div", { staticClass: "col" }, [
                            _c(
                              "p",
                              {
                                staticClass: "mb-0",
                                attrs: { "data-test-hook": "DealerDistance" }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("xDistance")) +
                                    ": " +
                                    _vm._s(
                                      _vm.getDistanceInUom(
                                        _vm.dealerDetail.distance
                                      )
                                    ) +
                                    " " +
                                    _vm._s(_vm.getDistanceUOMText()) +
                                    " "
                                )
                              ]
                            )
                          ]),
                          _c("div", { staticClass: "cols-auto" }, [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-icon-button",
                                attrs: {
                                  type: "button",
                                  "data-test-hook": "DealerMapBtn"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.locateDealerDetailMap(
                                      _vm.dealerDetail.address
                                    )
                                  }
                                }
                              },
                              [
                                _c("i", {
                                  staticClass: "fas fa-map-marker-alt"
                                })
                              ]
                            )
                          ])
                        ]
                      ),
                      _c("p", { attrs: { "data-test-hook": "DealerName" } }, [
                        _c("strong", [_vm._v(_vm._s(_vm.dealerDetail.name))])
                      ]),
                      _c("p", { attrs: { "data-test-hook": "DealerPhone" } }, [
                        _c("strong", [
                          _vm._v(
                            _vm._s(
                              _vm._f("phone")(_vm.dealerDetail.phoneNumber)
                            )
                          )
                        ])
                      ]),
                      _c(
                        "p",
                        { attrs: { "data-test-hook": "DealerAddressTop" } },
                        [_vm._v(_vm._s(_vm.dealerDetail.address.addressLine1))]
                      ),
                      _c(
                        "p",
                        {
                          staticClass: "mb-4",
                          attrs: { "data-test-hook": "DealerAddressBottom" }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.dealerDetail.address.city) +
                              ", " +
                              _vm._s(_vm.dealerDetail.address.region) +
                              " " +
                              _vm._s(_vm.dealerDetail.address.postalCode) +
                              " "
                          )
                        ]
                      ),
                      _vm.dealerDetail.website != ""
                        ? _c(
                            "a",
                            {
                              staticClass: "d-block mb-2",
                              attrs: {
                                href: "http://" + _vm.dealerDetail.website,
                                target: "_blank",
                                "data-test-hook": "DealerWebsite"
                              }
                            },
                            [
                              _c("i", {
                                staticClass: "far fa-globe mr-2 fa-fw"
                              }),
                              _vm._v(_vm._s(_vm.dealerDetail.website) + " ")
                            ]
                          )
                        : _vm._e(),
                      _vm.dealerDetail.email != ""
                        ? _c(
                            "a",
                            {
                              staticClass: "d-block mb-2",
                              attrs: {
                                href: "mailto:" + _vm.dealerDetail.email,
                                "data-test-hook": "EmailDealerLink"
                              }
                            },
                            [
                              _c("i", {
                                staticClass: "far fa-envelope mr-2 fa-fw"
                              }),
                              _vm._v(_vm._s(_vm.dealerDetail.email) + " ")
                            ]
                          )
                        : _vm._e(),
                      _c("br"),
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col-6" },
                          [
                            _c("b-img", {
                              attrs: {
                                src: _vm.locateDealerClass(
                                  _vm.dealerDetail.class
                                )
                              }
                            })
                          ],
                          1
                        )
                      ])
                    ]),
                    _c("div", { attrs: { id: "map" } })
                  ])
                ])
              : _vm._e(),
            _c("div", { staticClass: "mt-5" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-outline-warning btn-block",
                      attrs: {
                        type: "button",
                        "data-test-hook": "PreviousBtn"
                      },
                      on: {
                        click: function($event) {
                          return _vm.locateDealerDetailPrevious()
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("xSearchAgain")) + " ")]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-warning btn-block",
                      attrs: {
                        type: "button",
                        "data-test-hook": "SetDealerBtn"
                      },
                      on: {
                        click: function($event) {
                          return _vm.locateDealerDetailSetPreferredDealer(
                            _vm.dealerDetail.id
                          )
                        }
                      }
                    },
                    [
                      _c("i", { staticClass: "fas fa-user-hard-hat" }),
                      _vm._v(" " + _vm._s(_vm.$t("xSetPreferredDealer")) + " "),
                      _vm.savingNewDealer
                        ? _c("a", {
                            staticClass: "spinner-border ml-2",
                            attrs: { "data-test-hook": "SaveSpinner" }
                          })
                        : _vm._e()
                    ]
                  )
                ])
              ])
            ])
          ])
        ],
        1
      ),
      _c(
        "Modal",
        { attrs: { modalId: "edit-fuel-provider" } },
        [
          _c(
            "ModalHeader",
            {
              attrs: {
                title: _vm.$t("xEditFuelProvider"),
                icon: "fas fa-user-hard-hat colorOrange mr-1",
                "data-test-hook": "ModalTitle"
              }
            },
            [
              _c("div", { staticClass: "cols-auto" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-icon-button btn-icon-button--gray",
                    attrs: {
                      type: "button",
                      "data-test-hook": "ModalCloseBtn"
                    },
                    on: {
                      click: function($event) {
                        return _vm.closeEditFuelProvider()
                      }
                    }
                  },
                  [
                    _c("i", {
                      staticClass: "fas fa-times",
                      attrs: { "data-test-hook": "ModalCloseBtnIcon" }
                    })
                  ]
                )
              ])
            ]
          ),
          _c("ModalBody", [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col" }, [
                _c("h5", { attrs: { "data-test-hook": "ModalBodyText" } }, [
                  _vm._v(_vm._s(_vm.$t("xWhoDeliversYourFuel")))
                ])
              ])
            ]),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col" }, [
                _c(
                  "div",
                  {
                    staticClass: "form-group",
                    attrs: { id: "fuelProvider-group" }
                  },
                  [
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "click-outside",
                            rawName: "v-click-outside",
                            value: _vm.hideResults,
                            expression: "hideResults"
                          }
                        ]
                      },
                      [
                        _c("div", { staticClass: "input-group" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.freeformFuelProvider,
                                expression: "freeformFuelProvider"
                              }
                            ],
                            staticClass: "form-control input-group-append",
                            attrs: {
                              type: "text",
                              autocomplete: "new-password",
                              "data-test-hook": "FuelProviderSearchValue"
                            },
                            domProps: { value: _vm.freeformFuelProvider },
                            on: {
                              keyup: function($event) {
                                return _vm.typeAheadSearch()
                              },
                              input: function($event) {
                                if ($event.target.composing) return
                                _vm.freeformFuelProvider = $event.target.value
                              }
                            }
                          }),
                          _c("div", { staticClass: "input-group-append" }, [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-warning my-0 py-0",
                                attrs: {
                                  type: "button",
                                  "data-test-hook": "FuelProviderSearchBtn"
                                },
                                on: {
                                  click: function($event) {
                                    !_vm.awaitingSearch
                                      ? _vm.typeAheadSearch()
                                      : null
                                  }
                                }
                              },
                              [
                                _vm.awaitingSearch
                                  ? _c("span", {
                                      staticClass: "spinner-border",
                                      staticStyle: {
                                        width: "1.5rem",
                                        height: "1.5rem"
                                      }
                                    })
                                  : _c("i", {
                                      staticClass: "fas fa-search",
                                      attrs: {
                                        "data-test-hook":
                                          "FuelProviderSearchBtnIcon"
                                      }
                                    })
                              ]
                            )
                          ])
                        ]),
                        _c("div", { staticClass: "row" }),
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col" }, [
                            _vm.fuelProviderSearchResults
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "suggested-input-list list-group"
                                  },
                                  _vm._l(
                                    _vm.fuelProviderSearchResults,
                                    function(provider, index) {
                                      return _c(
                                        "div",
                                        {
                                          key: index,
                                          staticClass:
                                            "list-group-item hoverPointer",
                                          attrs: {
                                            "data-test-hook":
                                              "FuelProviderSearchList-" + index
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.setFuelProvider(
                                                provider
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(provider.name) + " "
                                          )
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                )
                              : _vm._e()
                          ])
                        ]),
                        _vm.fuelProviderSearchName
                          ? _c("div", { staticClass: "row mt-3" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "col",
                                  attrs: {
                                    "data-test-hook":
                                      "FuelProviderSearchSelection"
                                  }
                                },
                                [_vm._v(_vm._s(_vm.fuelProviderSearchName))]
                              )
                            ])
                          : _vm._e()
                      ]
                    )
                  ]
                )
              ])
            ])
          ]),
          _c("ModalFooter", [
            _c(
              "button",
              {
                staticClass: "btn btn-outline-warning",
                attrs: { type: "button", "data-test-hook": "ModalCancelBtn" },
                on: {
                  click: function($event) {
                    return _vm.closeModal("edit-fuel-provider")
                  }
                }
              },
              [_vm._v(" " + _vm._s(_vm.$t("xCancel")) + " ")]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-warning",
                attrs: {
                  type: "button",
                  disabled: _vm.savingNewDealer || !_vm.fuelProviderSearchId,
                  "data-test-hook": "ModalSaveBtn"
                },
                on: {
                  click: function($event) {
                    return _vm.saveFuelProvider()
                  }
                }
              },
              [
                !_vm.savingNewDealer
                  ? _c("span", [_vm._v(_vm._s(_vm.$t("xSave")))])
                  : _c("span", [_c("a", { staticClass: "spinner-border" })])
              ]
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }