<template>
  <div class="modal hide-footer no-stacking" id="DeleteAccountModal" data-test-hook="modal-title-delete-account-modal">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h2 class="modal-title" data-test-hook="modal-heading-delete-account-modal">
            <i class="far fa-trash-alt colorOrange mr-1"></i>
            {{ $t('xDeleteAccount') }}
          </h2>
          <button
            class="btn btn-icon-button btn-icon-button--gray"
            @click="closeModal()"
            data-test-hook="x-close-button-delete-account-modal"
          >
            <i class="fas fa-times" />
          </button>
        </div>

        <div class="modal-body">
          <div align-h="around" class="row">
            <div class="col" data-test-hook="modal-body1-text-delete-account-modal">
              {{ $t('xByClickingTheDeleteAccountButtonBelow') }}
              <b>{{ $t('xYourUserProductAndAccountInfo') }}</b>
              {{ $t('xYouWillNoLongerBeAbleToLogIn') }}
            </div>
          </div>
          <div align-h="around" class="row mt-3 mb-3">
            <div class="col" data-test-hook="modal-body2-text-delete-account-modal">
              {{ $t('xAreYouSureYouWantToDeleteYourAccount') }}
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            @click="closeModal()"
            data-test-hook="cancel-button-delete-account-modal"
          >
            {{ $t('xCancel') }}
          </button>
          <button
            class="btn btn-primary"
            @click="confirmDeleteAccount"
            data-test-hook="delete-button-delete-account-modal"
          >
            {{ $t('xDeleteAccount') | uppercase }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: ['deleteAccountModalInfo'],
  data() {
    return {
      confirmDeleteAccountModalInfo: {}
    }
  },
  methods: {
    resetModal() {
      closeModal()
    },
    closeModal() {
      document.getElementById('DeleteAccountModal').style.display = 'none'
    },
    confirmDeleteAccount() {
      this.$emit('showConfirmDeleteAccountModal', {
        orgId: this.deleteAccountModalInfo.orgId
      })
    }
  }
}
</script>
<style scoped></style>
