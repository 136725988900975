var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          attrs: { id: "feedback-modal", centered: "", "hide-footer": "" },
          on: {
            hidden: function($event) {
              return _vm.closeModal()
            }
          },
          scopedSlots: _vm._u([
            {
              key: "modal-header",
              fn: function() {
                return [
                  _c(
                    "b-row",
                    {
                      staticClass: "w-100",
                      attrs: { "align-v": "center", "no-gutters": "" }
                    },
                    [
                      _c("b-col", [
                        _c("h2", { staticClass: "modal-title" }, [
                          _c("i", {
                            staticClass: "fas fa-lightbulb colorOrange mr-1"
                          }),
                          _vm._v(" " + _vm._s(_vm.$t("xSendFeedback")) + " ")
                        ])
                      ]),
                      _c(
                        "b-col",
                        { staticClass: "float-right", attrs: { cols: "auto" } },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "btn-icon-button--gray",
                              attrs: {
                                variant: "icon-button",
                                "data-test-hook": "x-button-feedback-modal"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.closeModal()
                                }
                              }
                            },
                            [_c("i", { staticClass: "fas fa-times" })]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          !_vm.feedbackSent
            ? _c(
                "div",
                [
                  _c(
                    "b-row",
                    [
                      _c("b-col", [
                        _c("p", [
                          _vm._v(
                            _vm._s(_vm.$t("xPleaseProvideADetailedMessage"))
                          )
                        ])
                      ])
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "mt-2" },
                    [
                      _c(
                        "b-col",
                        [
                          _c("b-form-textarea", {
                            staticClass: "feedback-message",
                            attrs: {
                              rows: "5",
                              maxlength: "1000",
                              "no-resize": ""
                            },
                            model: {
                              value: _vm.message,
                              callback: function($$v) {
                                _vm.message = $$v
                              },
                              expression: "message"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "mt-3" },
                    [
                      _c(
                        "b-col",
                        [
                          _c(
                            "b-form-checkbox",
                            {
                              staticClass: "font-weight-bold",
                              attrs: {
                                "data-test-hook":
                                  "be-part-of-a-group-checkbox-feedback-modal"
                              },
                              model: {
                                value: _vm.userGroupParticipation,
                                callback: function($$v) {
                                  _vm.userGroupParticipation = $$v
                                },
                                expression: "userGroupParticipation"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "xIWouldLikeToBePartOfAUserFeedbackGroup"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "mt-5" },
                    [
                      _c(
                        "b-col",
                        { staticClass: "d-flex justify-content-end" },
                        [
                          _c(
                            "b-button",
                            {
                              attrs: {
                                variant: "primary",
                                disabled: !_vm.message,
                                "data-test-hook": "submit-button-feedback-modal"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.sendFeedback()
                                }
                              }
                            },
                            [
                              _vm._v(" " + _vm._s(_vm.$t("xSubmit")) + " "),
                              _vm.sendingFeedback
                                ? _c("b-spinner", { staticClass: "ml-2" })
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _c(
                "div",
                [
                  _c(
                    "b-row",
                    { staticClass: "text-center mt-2" },
                    [
                      _c("b-col", [
                        _c("h1", { staticClass: "mb-0 pb-0" }, [
                          _vm._v(_vm._s(_vm.$t("xFeedbackSent")))
                        ])
                      ])
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "text-center mt-3" },
                    [
                      _c("b-col", { staticClass: "message-text" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("xWeTrulyAppreciateYouTaking")) +
                            " "
                        )
                      ])
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "mt-3" },
                    [
                      _c(
                        "b-col",
                        [
                          _c(
                            "b-button",
                            {
                              attrs: {
                                variant: "primary",
                                block: "",
                                "data-test-hook": "got-it-button-feedback-modal"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.closeModal()
                                }
                              }
                            },
                            [
                              _c("i", { staticClass: "fas fa-thumbs-up" }),
                              _vm._v(" " + _vm._s(_vm.$t("xGotIt")) + " ")
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }