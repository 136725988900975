var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Modal",
    { attrs: { modalId: "RunHoursDetailsModal", size: "modal-md" } },
    [
      _c(
        "ModalHeader",
        {
          attrs: {
            title: _vm.$t("xHoursProtected"),
            icon: "fas fa-shield-check colorOrange mr-1"
          }
        },
        [
          _c("div", { staticClass: "cols-auto float-right" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-icon-button--gray btn-icon-button",
                attrs: {
                  type: "button",
                  "data-test-hook": "x-button-run-hours-details"
                },
                on: {
                  click: function($event) {
                    return _vm.closeModal("RunHoursDetailsModal")
                  }
                }
              },
              [_c("i", { staticClass: "fas fa-times" })]
            )
          ])
        ]
      ),
      _c("ModalBody", [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col" }, [
            _c("h6", { attrs: { "data-test-hook": "ProtectedSince" } }, [
              _c("i", { staticClass: "far fa-fw fa-shield-check colorOrange" }),
              _vm._v(
                " " + _vm._s(_vm._f("formatLabel")("xProtectedSince")) + " "
              )
            ]),
            _c("strong", [
              _vm._v(
                _vm._s(
                  _vm.activationDate
                    ? _vm.$moment
                        .utc(_vm.activationDate)
                        .format(_vm.$t("xShortDate"))
                    : "N/A"
                )
              )
            ])
          ])
        ]),
        _c("div", { staticClass: "row mt-4" }, [
          _c("div", { staticClass: "col" }, [
            _c(
              "h6",
              { attrs: { "data-test-hook": "HoursOfProtectionLabel" } },
              [
                _c("i", { staticClass: "far fa-fw fa-clock colorOrange" }),
                _vm._v(" " + _vm._s(_vm.$t("xTotalHoursOfProtection")) + " ")
              ]
            )
          ])
        ]),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col" }, [
            _vm.standByHours && _vm.standByHours <= 96
              ? _c(
                  "strong",
                  { attrs: { "data-test-hook": "HoursOfProtection" } },
                  [
                    _vm._v(
                      _vm._s(_vm.standByHours ? _vm.standByHours : 0) +
                        " " +
                        _vm._s(_vm.$t("xHours"))
                    )
                  ]
                )
              : _vm.standByHours
              ? _c(
                  "strong",
                  { attrs: { "data-test-hook": "HoursOfProtection" } },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          Math.round(
                            (_vm.standByHours ? _vm.standByHours : 0) / 24
                          ).toLocaleString()
                        ) +
                        " " +
                        _vm._s(_vm.$t("xDays")) +
                        " "
                    )
                  ]
                )
              : _c(
                  "strong",
                  { attrs: { "data-test-hook": "HoursOfProtection" } },
                  [_vm._v("N/A")]
                )
          ])
        ]),
        _c("div", { staticClass: "row mt-4" }, [
          _c("div", { staticClass: "col" }, [
            _c("h6", { attrs: { "data-test-hook": "RunHoursLabel" } }, [
              _c("i", {
                staticClass: "far fa-fw fa-hourglass-clock colorOrange"
              }),
              _vm._v(" " + _vm._s(_vm.$t("xRunHours")) + " ")
            ])
          ])
        ]),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col" }, [
            _c("strong", { attrs: { "data-test-hook": "RunHours" } }, [
              _vm._v(
                _vm._s((_vm.runHours ? _vm.runHours : 0).toLocaleString()) +
                  " " +
                  _vm._s(_vm.$t("xHours"))
              )
            ])
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }