<template>
  <div>
    <div
      v-if="fuelTank"
      @click="!subscribingWifiProduct ? detailsOrEnrollment() : ''"
      data-test-hook="tank-utility-apparatus-card"
      class="card"
      :class="getClass()"
    >
      <!--Header-->
      <div v-if="!enrollmentComplete() && fuelTank.isDealerManaged">
        <div class="card-header dashboard-card-header bg-statusWarning">
          <div data-test-hook="DealerHeader" class="row w-100 no-gutters">
            <div class="float-left col">
              <strong v-if="!tankHasSubscription()" data-test-hook="HeaderText" class="py-0 my-0">
                {{ $t('xUpdateYourSubscription') }}
              </strong>
              <strong v-else data-test-hook="HeaderText" class="py-0 my-0">
                {{ $t('xFinishSettingUpYourProduct') }}
              </strong>
            </div>
            <div class="float-right mr-0 pr-0 col-auto">
              <i class="fa-solid fa-arrow-right" />
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="tankHasSubscription() && !tankHasAllProperties()" class="card-header dashboard-card-header">
        <div data-test-hook="tank-utility-apparatus-card-header" class="row w-100 no-gutters align-items-center">
          <div class="float-left col">
            <p class="py-0 my-0">
              <strong>{{ $t('xWaitingForData') }}</strong>
            </p>
          </div>
        </div>
      </div>
      <div v-else-if="!tankHasSubscription()" class="card-header dashboard-card-header bg-statusWarning">
        <div data-test-hook="tank-utility-apparatus-card-header" class="row w-100 no-gutters align-items-center">
          <div class="float-left col">
            <p data-test-hook="tank-utility-apparatus-card-header-text" class="py-0 my-0">
              <strong>{{ $t('xFinishSetup') }}</strong>
            </p>
          </div>
          <div class="float-right mr-0 pr-0 col-auto">
            <i class="fas fa-arrow-right" />
          </div>
        </div>
      </div>
      <div v-else-if="!isConnected" class="card-header dashboard-card-header bg-statusDisconnected">
        <div data-test-hook="tank-utility-apparatus-card-header" class="row w-100 no-gutters align-items-center">
          <div class="float-left col">
            <p data-test-hook="tank-utility-apparatus-card-header-text" class="py-0 my-0">
              <strong>{{ noSignalMessage() }}</strong>
            </p>
          </div>
          <div class="float-right mr-0 pr-0 col-auto">
            <i class="fas fa-arrow-right" />
          </div>
        </div>
      </div>
      <div v-else class="card-header dashboard-card-header">
        <div data-test-hook="tank-utility-apparatus-card-header" class="row w-100 no-gutters align-items-center">
          <div
            v-if="fuelLevel"
            class="float-left col-auto"
            data-test-hook="tank-utility-apparatus-card-header-fuel-level"
          >
            <i class="far fa-gauge-max" data-test-hook="tank-utility-apparatus-card-header-fuel-level-icon" />
            {{ fuelLevel }}%
          </div>
          <div v-if="fuelType" class="float-left ml-3 col-auto">
            <i data-test-hook="tank-utility-apparatus-card-header-fuel-type-icon" class="far fa-flame mr-1" />
            <span data-test-hook="tank-utility-apparatus-card-header-fuel-type" style="text-transform: capitalize;">{{
              fuelType
            }}</span>
          </div>
          <div class="text-right float-right mr-0 pr-0 col">
            <button
              class="btn card-header-button btn-warning"
              style="margin: -0.75em -0.5em;"
              @click="detailsOrEnrollment()"
            >
              <i class="fas fa-arrow-right"></i>
            </button>
          </div>
        </div>
      </div>
      <!--Card Body-->
      <div class="card-body">
        <div class="overlay"></div>
        <div class="row d-flex">
          <div class="col">
            <h4 class="mb-0 py-2">
              <i
                data-test-hook="tank-utility-apparatus-card-disconnected-icon"
                class="fa-fw mr-1 fas"
                :class="noSignalIcon()"
              ></i>
              {{ getStatusText() }}
            </h4>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-4">
            <img
              v-if="orientation == 'horizontal'"
              :src="require('@/assets/images/tank-image.jpg')"
              :alt="$t('xHeroImage')"
              class="tankImage img-fluid"
              data-test-hook="tank-utility-apparatus-card-hero-image"
            />
            <img
              v-else
              :src="require('@/assets/images/vertical-propane-tank.jpg')"
              :alt="$t('xHeroImage')"
              class="tankImage img-fluid"
              data-test-hook="tank-utility-apparatus-card-hero-image"
            />
          </div>
          <div class="col-8">
            <div class="row mt-2">
              <div data-test-hook="tank-utility-apparatus-card-address" class="ellipsis-overflow col">
                {{ fuelTank.localizedAddress }}
              </div>
            </div>
            <div class="row mt-2">
              <div class="col">
                <h2 data-test-hook="tank-utility-apparatus-tank-name" class="pb-1 mb-0">{{ fuelTank.name }}</h2>
              </div>
            </div>
            <div v-if="fuelTank.isDealerManaged && fuelTank.preferredDealerName" class="row mt-2">
              <div data-test-hook="tank-utility-apparatus-card-dealer" class="ellipsis-overflow col">
                <i class="fa-light fa-user-helmet-safety" data-test-hook="tank-utility-apparatus-card-dealer-icon" />
                {{ $t('xDealerManaged') | formatLabel }}
                {{ fuelTank.preferredDealerName }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--Footer-->
      <div class="card-footer apparatus-card-footer">
        <div class="row">
          <div class="col-4">
            <div v-if="capacity || (!tankHasAllProperties() && tankHasSubscription())" class="row">
              <div data-test-hook="tank-utility-apparatus-card-fuel-capacity-label" class="text-small col">
                {{ $t('xFuelCapacity') }} ({{ this.abbreviatedLiquidUom }})
              </div>
            </div>
            <div v-if="capacity || (!tankHasAllProperties() && tankHasSubscription())" class="row">
              <div class="col">
                <strong data-test-hook="tank-utility-apparatus-card-fuel-capacity">{{
                  capacity ? this.convertGallonsToPreferredUnit(capacity) : 'N/A'
                }}</strong>
              </div>
            </div>
          </div>
          <div class="col-8">
            <div v-if="lastReadingDate || (!tankHasAllProperties() && tankHasSubscription())" class="row">
              <div data-test-hook="tank-utility-apparatus-card-last-reading-date" class="col">
                {{ 'xLastReadingDate' | formatLabel }}
                {{ displayShortDateTimeWithTZ(lastReadingDate) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Dealer Managed Subscription Modal-->
    <div
      v-if="fuelTank && fuelTank.apparatusId"
      :id="'dealer-managed-sub-expired' + fuelTank.apparatusId"
      role="dialog"
      class="modal fade show"
    >
      <div class="modal-dialog modal-dialog-centered">
        <span tabindex="0"></span>
        <div tabindex="-1" class="modal-content">
          <header class="modal-header">
            <div class="row w-100 no-gutters align-items-center">
              <div class="col">
                <h2 class="modal-title">
                  <i class="far fa-badge-dollar colorOrange mr-1" />
                  {{ $t('xSubscriptionExpired') }}
                </h2>
              </div>
              <div class="float-right col-auto">
                <button type="button" class="btn btn-icon-button--gray btn-icon-button" @click="closeDealerModal()">
                  <i class="fas fa-times" />
                </button>
              </div>
            </div>
          </header>
          <div class="modal-body">
            <div class="row">
              <div class="col">
                {{ $t('xYourDealerManagedSubscriptionHasExpiredContactDealer') }}
              </div>
            </div>
            <p class="mt-4">
              {{ 'xYourDealer' | formatLabel }}
            </p>
            <div class="row mt-2">
              <div class="col" data-test-hook="ApparatusPreferredDealerName">
                <strong>{{ fuelTank.preferredDealerName }}</strong>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col" data-test-hook="ApparatusPreferredDealerPhone">
                <i class="fa-regular fa-phone pr-2" />
                {{ fuelTank.preferredDealerPhone | phone }}
              </div>
            </div>
            <div class="row mt-2">
              <div class="col" data-test-hook="ApparatusPreferredDealerEmail">
                <i class="fa-regular fa-envelope pr-2" />
                <a :href="'mailto:' + fuelTank.preferredDealerEmail">{{ fuelTank.preferredDealerEmail }}</a>
              </div>
            </div>
          </div>
          <ModalFooter>
            <button type="button" @click="closeDealerModal()" class="btn btn-primary">
              {{ $t('xDone') }}
            </button>
          </ModalFooter>
        </div>
      </div>
    </div>
    <!--Modal-->
    <div style="position: absolute; top: 1040;">
      <div
        v-if="fuelTank"
        :id="'free-enrollment-success' + fuelTank.apparatusId"
        role="dialog"
        class="modal"
        aria-modal="true"
      >
        <div class="modal-dialog modal-lg modal-dialog centered" style="top: 25%;">
          <span tabindex="0"></span>
          <div tabindex="-1" class="modal-content">
            <header class="modal-header">
              <div class="row w-100 no-gutters align-items-center">
                <div class="col">
                  <h2 class="headerInline mb-0 pb-0">
                    <i class="colorOrange fas fa-plus"></i>
                    {{ $t('xAddATank') }}
                  </h2>
                </div>
              </div>
            </header>
            <div class="modal-body">
              <div class="row no-gutters">
                <div class="col">
                  <lottie
                    :options="successAnimationOptions"
                    v-on:animCreated="handleAnimation"
                    :height="200"
                    :width="200"
                  />
                </div>
              </div>
              <div class="row text-center mt-2">
                <div class="col">
                  <h2 class="colorOrange">
                    <strong>{{ $t('xSuccess') }}!</strong>
                  </h2>
                </div>
              </div>
              <div class="row text-center mt-2">
                <div class="asterisk-text col">{{ $t('xSuccessfullyAddedTank') }}</div>
              </div>
            </div>
            <footer class="modal-footer">
              <button
                data-test-hook="free-enrollment-success-modal-sounds-good-button"
                @click="closeModal()"
                type="button"
                class="btn btn-warning btn-block"
              >
                {{ $t('xSoundsGood') }}
              </button>
            </footer>
          </div>
          <span></span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import * as Constants from '@/constants'
import unitMixin from '@/utils/unitMixin'
import Lottie from '../lottie.vue'
import * as successAnimation from '@/assets/images/manage-subscription-success.json'

export default {
  props: ['apparatusInfo'],
  components: { Lottie },
  mixins: [unitMixin],
  data() {
    return {
      batteryLevel: null,
      capacity: null,
      consumptionTypes: null,
      device: null,
      deviceType: null,
      fuelDealerId: null,
      fuelLevel: null,
      fuelTank: null,
      fuelType: null,
      isConnected: false,
      lastReadingDate: null,
      orientation: null,
      subscription: null,
      subscribingWifiProduct: false,
      successAnimationOptions: { animationData: successAnimation, loop: false }
    }
  },
  computed: {
    ...mapState({
      accountProfile: state => state.account.accountProfile,
      isImpersonating: state => state.auth.isImpersonating,
      currentAppConfig: state => state.application.currentAppConfig
    }),
    billingMigrationFeatureFlag() {
      return Constants.BillingMigrationFeatureFlag
    }
  },
  methods: {
    handleAnimation(anim) {
      this.anim = anim
    },
    batteryLevelClass() {
      if (this.batteryLevel) {
        if (this.batteryLevel == 'good') return 'batteryGood'
        else if (this.batteryLevel == 'warning') return 'batteryWarning'
        else return 'batteryCritical'
      }
    },
    closeModal() {
      document.getElementById('free-enrollment-success' + this.fuelTank.apparatusId).style.display = 'none'
    },
    batteryIconClass() {
      if (this.batteryLevel) {
        if (this.batteryLevel == 'good') return 'far fa-battery-full'
        else if (this.batteryLevel == 'warning') return 'far fa-battery-half'
        else return 'far fa-battery-slash'
      }
    },
    detailsOrEnrollment() {
      if (this.enrollmentComplete()) {
        this.$appInsights.trackEvent({
          name: 'click-apparatus-card',
          properties: { apparatusId: this.fuelTank.apparatusId }
        })
        this.$router.push({
          path: `/details/${this.fuelTank.apparatusId}`
        })
      } else if (this.fuelTank.isDealerManaged && !this.enrollmentComplete()) {
        document.getElementById('dealer-managed-sub-expired' + this.fuelTank.apparatusId).style.display = 'block'
        this.$appInsights.trackEvent({
          name: 'expired-dealer-managed-sub-tank',
          properties: {
            page: 'dashboard',
            apparatusId: this.fuelTank.apparatusId
          }
        })
      } else {
        this.openEnrollmentFlow()
      }
    },
    closeDealerModal() {
      document.getElementById('dealer-managed-sub-expired' + this.fuelTank.apparatusId).style.display = 'none'
    },
    tankHasSubscription() {
      if (!this.fuelTank) return false
      if (this.fuelTank.type != Constants.FuelTankApparatusType) return false
      if (!this.fuelTank.properties) return false
      if (this.subscription && this.subscription.length >= 1) {
        if (
          this.subscription[0].value.status &&
          (this.subscription[0].value.status == Constants.SubscriptionActive ||
            this.subscription[0].value.status == Constants.SubscriptionPendingCancellation ||
            this.subscription[0].value.status == Constants.SubscriptionPendingTermination ||
            this.subscription[0].value.status == Constants.SubscriptionPendingSuspension ||
            this.subscription[0].value.status == Constants.SubscriptionDunning)
        ) {
          return true
        }
      }
      return false
    },
    tankHasAllProperties() {
      if (this.fuelType && this.orientation && this.capacity) {
        return true
      }
      return false
    },
    openEnrollmentFlow() {
      if (
        this.currentAppConfig &&
        this.currentAppConfig.featureFlags &&
        !this.currentAppConfig.featureFlags[this.billingMigrationFeatureFlag]
      ) {
        if (!this.tankHasAllProperties() && this.tankHasSubscription()) return
        if (!this.tankHasAllProperties()) {
          this.$emit('missingProperties', { deviceType: this.deviceType, apparatusId: this.fuelTank.apparatusId })
        } else if (!this.tankHasSubscription()) {
          if (
            this.deviceType == Constants.TankUtilityWifiDeviceType ||
            this.deviceType == Constants.TankUtilityLteDeviceTypeV1
          ) {
            this.subscribeFreeProduct()
          } else {
            this.$emit('noSubscription', { deviceType: this.deviceType, apparatusId: this.fuelTank.apparatusId })
          }
        }
      }
    },
    noSignalMessage() {
      if (this.deviceType == Constants.TankUtilityWifiDeviceType) {
        return this.$t('xNoWifiSignal')
      } else if (
        this.deviceType == Constants.TankUtilityLteDeviceTypeV1 ||
        this.deviceType == Constants.TankUtilityLteDeviceTypeV2
      ) {
        return this.$t('xNoCellularSignal')
      } else return this.$t('xDisconnected')
    },
    noSignalIcon() {
      if (!this.tankHasAllProperties() && this.tankHasSubscription()) return 'far fa-clipboard-list-check colorGray700'
      if (!this.enrollmentComplete() || !this.isConnected) {
        if (this.deviceType == Constants.TankUtilityWifiDeviceType) {
          return 'fas fa-wifi-slash statusIsDisconnected'
        } else if (
          this.deviceType == Constants.TankUtilityLteDeviceTypeV1 ||
          this.deviceType == Constants.TankUtilityLteDeviceTypeV2
        ) {
          return 'fas fa-signal-alt-slash statusIsDisconnected'
        }
      } else return 'fas fa-circle-check statusIsReady'
    },
    getStatusText() {
      if (!this.tankHasAllProperties() && this.tankHasSubscription()) return this.$t('xFinalizingSetup')
      if (!this.enrollmentComplete() || !this.isConnected) {
        return this.$t('xNoSignal')
      } else return this.$t('xConnected')
    },
    getHeaderBGVariant() {
      if (!this.enrollmentComplete()) return 'statusWarning'
      else if (!this.isConnected) return 'statusDisconnected'
    },
    getApparatusCardClass() {
      if (this.apparatus && this.enrollmentComplete()) {
        if (this.apparatusInfo.isConnecting) return ''
        else return this.apparatus.isConnected ? `apparatusCard${this.apparatus.apparatusStatus}` : ''
      }
    },
    getClass() {
      let result = ''
      if (this.tankHasSubscription() && !this.tankHasAllProperties()) {
        result = 'apparatus-card-nohover '
      } else {
        result = 'apparatus-card hoverPointer'
      }
      result += !this.subscribingWifiProduct && this.tankHasAllProperties() ? ' hoverPointer' : ''
      result += !this.isConnected ? ' ' : ''
      return result
    },
    enrollmentComplete() {
      return this.tankHasSubscription() && this.tankHasAllProperties()
    },
    subscribeFreeProduct() {
      this.subscribingWifiProduct = true
      let productId = Constants.TankUtilityFreeProductId
      this.$store
        .dispatch('changeBillingAccountProduct', {
          organizationId: this.accountProfile.organizationId,
          apparatusId: this.fuelTank.apparatusId,
          productId: productId
        })
        .then(response => {
          this.subscribingWifiProduct = false
          document.getElementById('free-enrollment-success' + this.fuelTank.apparatusId).style.display = 'block'
          this.$store.dispatch('getApparatusesSilent')
        })
        .catch(error => {
          let failureMessage = this.$t('xFailedToSetTankSub')
          if (error?.response?.data?.message) {
            try {
              let messageAsJson = JSON.parse(error.response.data.message.replace(/\\"/g, '"'))
              if (messageAsJson?.gatewayResponse?.message) {
                failureMessage = messageAsJson?.gatewayResponse?.message
              }
            } catch (err) {}
          }
          this.subscribingWifiProduct = false
          this.$store.dispatch('displayToast', {
            title: this.$t('xError'),
            message: failureMessage,
            variant: 'danger'
          })
        })
    }
  },
  mounted() {
    if (!this.currentAppConfig) this.$store.dispatch('getApplicationConfiguration', 'Any')
    if (this.apparatusInfo) {
      this.fuelTank = this.apparatusInfo
      this.isConnected = this.fuelTank ? this.fuelTank.isConnected : false
      if (this.fuelTank.properties) {
        if (this.fuelTank.properties.find(x => x.name == 'LastReading')?.value) {
          this.lastReadingDate = this.fuelTank.properties.find(x => x.name == 'LastReading').value
        }
        if (this.fuelTank.properties.find(x => x.name == 'FuelType')?.value) {
          this.fuelType = this.fuelTank.properties.find(x => x.name == 'FuelType').value
        }
        if (this.fuelTank.properties.find(x => x.name == 'Orientation')?.value) {
          this.orientation = this.fuelTank.properties.find(x => x.name == 'Orientation').value
        }
        if (parseInt(this.fuelTank.properties.find(x => x.name == 'Capacity')?.value)) {
          this.capacity = parseInt(this.fuelTank.properties.find(x => x.name == 'Capacity').value)
        }
        if (this.fuelTank.properties.find(x => x.name == 'ConsumptionTypes')?.value) {
          this.consumptionTypes = this.fuelTank.properties.find(x => x.name == 'ConsumptionTypes').value
        }
        if (this.fuelTank.properties.find(x => x.name == 'FuelDealerId')?.value) {
          this.fuelDealerId = this.fuelTank.properties.find(x => x.name == 'FuelDealerId').value
        }
        if (parseInt(this.fuelTank.properties.find(x => x.name == 'FuelLevel')?.value)) {
          this.fuelLevel = parseInt(this.fuelTank.properties.find(x => x.name == 'FuelLevel').value)
        }
        this.subscription = this.fuelTank.properties.filter(x => x.type == Constants.ApparatusPropertySubscription)
        this.device = this.fuelTank.properties.filter(x => x.type == Constants.ApparatusPropertyDevice)
        if (this.device && this.device[0] && this.device[0].value) {
          this.batteryLevel = this.device[0].value.batteryLevel ? this.device[0].value.batteryLevel : 'critical'
          this.deviceType = this.device[0].value.deviceType ? this.device[0].value.deviceType : null
        }

        // open enrollment flow deep link
        const matchesEnrollRouteParams =
          this.$route.params.apparatusId === this.apparatusInfo.apparatusId?.toString() &&
          this.$route.params.apparatusType === this.apparatusInfo.type?.toString()
        if (matchesEnrollRouteParams && !this.enrollmentComplete()) {
          this.openEnrollmentFlow()
        } else if (matchesEnrollRouteParams && this.enrollmentComplete()) {
          this.$router.push({
            path: `/dashboard`
          })
        }
      }
    }
  }
}
</script>

<style>
.tankImage {
  max-height: 100px;
}

.batteryGood {
  color: var(--green-500) !important;
}

.batteryWarning {
  color: var(--yellow900) !important;
}

.batteryCritical {
  color: var(--red-500) !important;
}

.tu-row-2 {
  padding-top: 35px;
}

.colorGray700 {
  color: var(--carbon-600) !important;
}
</style>
