var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.source && _vm.currentSubscription
    ? _c(
        "div",
        [
          _c(
            "Modal",
            {
              attrs: {
                modalId: "destination-org-search" + _vm.source.apparatusId,
                size: "modal-lg"
              }
            },
            [
              _c("ModalHeader", {
                attrs: {
                  title: "Find Destination Email",
                  icon: "fad fa-exchange-alt"
                }
              }),
              _c("ModalBody", [
                !_vm.destinationOwner && !_vm.showSearchError
                  ? _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col" }, [
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col" }, [
                            _c("fieldset", { staticClass: "form-group" }, [
                              _c(
                                "legend",
                                {
                                  staticClass:
                                    "bv-no-focus-ring col-form-label pt-0"
                                },
                                [_vm._v("User Name (Email Address)")]
                              ),
                              _c("div", [
                                _c(
                                  "div",
                                  {
                                    staticClass: "input-group",
                                    attrs: { role: "group" }
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.emailToSearch,
                                          expression: "emailToSearch"
                                        }
                                      ],
                                      staticClass: "form-control",
                                      attrs: {
                                        type: "text",
                                        maxlength: "50",
                                        name: "emailToSearch"
                                      },
                                      domProps: { value: _vm.emailToSearch },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) return
                                          _vm.emailToSearch =
                                            $event.target.value
                                        }
                                      }
                                    })
                                  ]
                                )
                              ])
                            ])
                          ])
                        ]),
                        _c("div", { staticClass: "row align-items-center" }, [
                          _c("div", { staticClass: "col" }, [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-warning btn-block",
                                attrs: {
                                  disabled: !_vm.emailToSearch,
                                  type: "button",
                                  "data-test-hook":
                                    "search-button-destinationOrgSearch-transfer-gen-modal"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.getDestinationOrg()
                                  }
                                }
                              },
                              [
                                _c("i", { staticClass: "fas fa-search" }),
                                _vm._v(" Search "),
                                _vm.searchingForOwner
                                  ? _c("div", {
                                      staticClass:
                                        "spinner-border text-muted ml-2"
                                    })
                                  : _vm._e()
                              ]
                            )
                          ])
                        ]),
                        _c("ul", { staticClass: "mt-3" }, [
                          _c("li", [
                            _vm._v(
                              "Use this feature when the homeowner requests an email change"
                            )
                          ]),
                          _c("li", [
                            _vm._v(
                              "If there is a new homeowner use the Homeowner Change button"
                            )
                          ]),
                          _c("li", [
                            _vm._v(
                              " The owner must have a new Mobile Link account to move the generator to (shown as the destination email) "
                            )
                          ]),
                          _c("li", [
                            _vm._v(
                              " This process will transfer the generator to the new Mobile Link account with the new email address as the login "
                            )
                          ]),
                          _c("li", [
                            _vm._v("Only the one generator will be transferred")
                          ])
                        ])
                      ])
                    ])
                  : _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col" }, [
                        _vm._v(" " + _vm._s(_vm.searchErrorMessage) + " ")
                      ])
                    ])
              ]),
              _c("ModalFooter", [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-outline-warning",
                    attrs: {
                      type: "button",
                      "data-test-hook":
                        "cancel-button-destinationOrgSearch-transfer-gen-modal"
                    },
                    on: {
                      click: function($event) {
                        _vm.closeModal(
                          "destination-org-search" + _vm.source.apparatusId
                        ),
                          _vm.resetTransferModals()
                      }
                    }
                  },
                  [_vm._v(" Cancel ")]
                )
              ])
            ],
            1
          ),
          _c(
            "Modal",
            {
              attrs: {
                modalId: "verify-gen-transfer" + _vm.source.apparatusId,
                size: "modal-xl"
              }
            },
            [
              _c("ModalHeader", {
                attrs: {
                  title: "Transfer Generator",
                  icon: "fad fa-exchange-alt"
                }
              }),
              _c("ModalBody", [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col" }, [
                    _c("div", { staticClass: "card shadow" }, [
                      _c("div", { staticClass: "card-body" }, [
                        _c("h4", { staticClass: "card-title" }, [
                          _vm._v("Generator: " + _vm._s(_vm.source.apparatusId))
                        ]),
                        _c("div", { staticClass: "card-deck" }, [
                          _c("div", { staticClass: "card no-border" }, [
                            _c("div", { staticClass: "card-body" }, [
                              _c("div", { staticClass: "row" }, [
                                _c(
                                  "div",
                                  { staticClass: "col-12 tile-label" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("formatLabelNoTranslation")(
                                          "Generator Name"
                                        )
                                      )
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "col-12 tile-content" },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.source.generatorName || "N/A")
                                    )
                                  ]
                                )
                              ]),
                              _c("div", { staticClass: "row" }, [
                                _c(
                                  "div",
                                  { staticClass: "col-12 tile-label" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("formatLabelNoTranslation")(
                                          "Serial Number"
                                        )
                                      )
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "col-12 tile-content" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.source.serialNumber || "N/A"
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ]),
                              _vm.hasDevice(_vm.source)
                                ? _c("div", { staticClass: "row" }, [
                                    _c(
                                      "div",
                                      { staticClass: "col-12 tile-label" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("formatLabelNoTranslation")(
                                              "Device ID"
                                            )
                                          )
                                        )
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "col-12 tile-content" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.source.deviceInfo.deviceId ||
                                                "N/A"
                                            ) +
                                            " "
                                        )
                                      ]
                                    )
                                  ])
                                : _c("div", { staticClass: "row" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "col-12 tile-label colorOrange"
                                      },
                                      [_vm._v("No Attached Device")]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "col-12 tile-label colorOrange"
                                      },
                                      [
                                        _vm._v(
                                          " This generator has not been fully enrolled. "
                                        )
                                      ]
                                    )
                                  ])
                            ])
                          ]),
                          _c("div", { staticClass: "card no-border" }, [
                            _c("div", { staticClass: "card-body" }, [
                              _vm.hasDevice(_vm.source)
                                ? _c("div", { staticClass: "row" }, [
                                    _c(
                                      "div",
                                      { staticClass: "col-12 tile-label" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("formatLabelNoTranslation")(
                                              "Device Type"
                                            )
                                          )
                                        )
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "col-12 tile-content" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.source.deviceInfo.deviceType ||
                                              "N/A"
                                          )
                                        )
                                      ]
                                    )
                                  ])
                                : _vm._e(),
                              _c("div", { staticClass: "row" }, [
                                _c(
                                  "div",
                                  { staticClass: "col-12 tile-label" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("formatLabelNoTranslation")(
                                            "Generator Address"
                                          )
                                        ) +
                                        " "
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "col-12 tile-content" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.DisplayAddress(
                                            _vm.source.apparatusAddress
                                          )
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ])
                            ])
                          ])
                        ])
                      ])
                    ])
                  ])
                ]),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col" }, [
                    _c("div", { staticClass: "card-deck mt-2" }, [
                      _vm.source
                        ? _c("div", { staticClass: "card shadow" }, [
                            _c("div", { staticClass: "card-body" }, [
                              _c("div", { staticClass: "card-title" }, [
                                _c("h4", [
                                  _vm._v(
                                    "Source Owner: " +
                                      _vm._s(_vm.source.organizationId)
                                  )
                                ])
                              ]),
                              _c("div", { staticClass: "row" }, [
                                _c(
                                  "div",
                                  { staticClass: "col-12 tile-label" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("formatLabelNoTranslation")(
                                          "Name"
                                        )
                                      )
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "col-12 tile-content" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.source.ownerInformation
                                            .firstName +
                                            " " +
                                            _vm.source.ownerInformation
                                              .lastName || "N/A"
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ]),
                              _c("div", { staticClass: "row" }, [
                                _c(
                                  "div",
                                  { staticClass: "col-12 tile-label" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("formatLabelNoTranslation")(
                                          "Phone Number"
                                        )
                                      )
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "col-12 tile-content" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.source.ownerInformation.phones[0] ||
                                          "N/A"
                                      )
                                    )
                                  ]
                                )
                              ]),
                              _c("div", { staticClass: "row" }, [
                                _c(
                                  "div",
                                  { staticClass: "col-12 tile-label" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("formatLabelNoTranslation")(
                                          "Email Address"
                                        )
                                      )
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "col-12 tile-content" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.source.ownerInformation
                                            .emails[0] || "N/A"
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ]),
                              _c("div", { staticClass: "row" }, [
                                _c(
                                  "div",
                                  { staticClass: "col-12 tile-label" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("formatLabelNoTranslation")(
                                          "Owner Address"
                                        )
                                      )
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "col-12 tile-content" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.DisplayAddress(
                                            _vm.source.ownerAddress
                                          )
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ])
                            ])
                          ])
                        : _vm._e(),
                      _vm.destinationOwnerLoaded
                        ? _c("div", { staticClass: "card shadow" }, [
                            _c("div", { staticClass: "card-body" }, [
                              _c("h4", { staticClass: "card-title" }, [
                                _vm._v(
                                  "Destination Owner: " +
                                    _vm._s(_vm.destinationOwner.organizationId)
                                )
                              ]),
                              _c("div", { staticClass: "row" }, [
                                _c(
                                  "div",
                                  { staticClass: "col-12 tile-label" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("formatLabelNoTranslation")(
                                          "Name"
                                        )
                                      )
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "col-12 tile-content" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.destinationOwner.contact
                                            .firstName +
                                            " " +
                                            _vm.destinationOwner.contact
                                              .lastName || "N/A"
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ]),
                              _c("div", { staticClass: "row" }, [
                                _c(
                                  "div",
                                  { staticClass: "col-12 tile-label" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("formatLabelNoTranslation")(
                                          "Phone Number"
                                        )
                                      )
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "col-12 tile-content" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.destinationOwner.contact
                                          .phones[0] || "N/A"
                                      )
                                    )
                                  ]
                                )
                              ]),
                              _c("div", { staticClass: "row" }, [
                                _c(
                                  "div",
                                  { staticClass: "col-12 tile-label" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("formatLabelNoTranslation")(
                                          "Email Address"
                                        )
                                      )
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "col-12 tile-content" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.destinationOwner.contact
                                            .emails[0] || "N/A"
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ]),
                              _c("div", { staticClass: "row" }, [
                                _c(
                                  "div",
                                  { staticClass: "col-12 tile-label" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("formatLabelNoTranslation")(
                                          "Owner Address"
                                        )
                                      )
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "col-12 tile-content" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.DisplayAddress(
                                            _vm.destinationOwner.address
                                          )
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ])
                            ]),
                            _c("div", { staticClass: "card-footer" }, [
                              _c("div", { staticClass: "row" }, [
                                _c(
                                  "div",
                                  { staticClass: "col" },
                                  [
                                    _c(
                                      "b-form-checkbox",
                                      {
                                        attrs: {
                                          state:
                                            _vm.$v.destinationOwnerVerified
                                              .$model,
                                          "data-test-hook":
                                            "info-verified-with-caller-checkbox-verifyGenTransfer-transfer-gen-modal"
                                        },
                                        model: {
                                          value:
                                            _vm.$v.destinationOwnerVerified
                                              .$model,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.$v.destinationOwnerVerified,
                                              "$model",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "$v.destinationOwnerVerified.$model"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " Information Verified With Caller "
                                        ),
                                        !_vm.destinationOwnerVerified
                                          ? _c("span", [
                                              _c(
                                                "i",
                                                {
                                                  staticClass:
                                                    "asterisk-text colorRed"
                                                },
                                                [_vm._v("required")]
                                              )
                                            ])
                                          : _vm._e()
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ])
                            ])
                          ])
                        : _c("div", { staticClass: "card shadow" }, [
                            _c("div", { staticClass: "card-body" }, [
                              _c("div", {
                                staticClass: "spinner-border text-muted ml-2"
                              })
                            ])
                          ])
                    ])
                  ])
                ]),
                _c("div", { staticClass: "mt-2 row" }, [
                  _c("div", { staticClass: "col" }, [
                    _c("div", { staticClass: "card-deck" }, [
                      _c("div", { staticClass: "card shadow" }, [
                        _c("div", { staticClass: "card-body" }, [
                          _c("h4", { staticClass: "card-title" }, [
                            _vm._v("Subscription Information")
                          ]),
                          _vm.currentSubscription &&
                          _vm.currentSubscription.activeProduct &&
                          _vm.currentSubscription.activeProduct[0]
                            ? _c("div", [
                                _c("div", { staticClass: "row" }, [
                                  _c(
                                    "div",
                                    { staticClass: "col-12 tile-label" },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "tile-label" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f(
                                                "formatLabelNoTranslation"
                                              )("Subscription")
                                            )
                                          )
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "tile-content",
                                          class: _vm.getSubscriptionClass()
                                        },
                                        [
                                          _c("strong", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.currentSubscription
                                                    .activeProduct[0]
                                                    .productName
                                                )
                                            )
                                          ])
                                        ]
                                      )
                                    ]
                                  )
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "row" },
                                  [
                                    _c(
                                      "b-col",
                                      {
                                        staticClass: "tile-label",
                                        attrs: { cols: "12" }
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "tile-label" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f(
                                                  "formatLabelNoTranslation"
                                                )("Cost")
                                              ) + " "
                                            )
                                          ]
                                        ),
                                        _c(
                                          "span",
                                          { staticClass: "tile-content" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm._f("formatCurrency")(
                                                    _vm.currentSubscription.activeProduct[0].cost.toFixed(
                                                      2
                                                    )
                                                  )
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "row" },
                                  [
                                    _c(
                                      "b-col",
                                      {
                                        staticClass: "tile-label",
                                        attrs: { cols: "12" }
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "tile-label" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f(
                                                  "formatLabelNoTranslation"
                                                )("Start Date")
                                              ) + " "
                                            )
                                          ]
                                        ),
                                        _c(
                                          "span",
                                          { staticClass: "tile-content" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.currentSubscription
                                                    .activeProduct[0].startDate
                                                )
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "row" },
                                  [
                                    _c(
                                      "b-col",
                                      {
                                        staticClass: "tile-label",
                                        attrs: { cols: "12" }
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "tile-label" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f(
                                                  "formatLabelNoTranslation"
                                                )("Next Billing Date")
                                              ) + " "
                                            )
                                          ]
                                        ),
                                        _c(
                                          "span",
                                          { staticClass: "tile-content" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.currentSubscription
                                                    .activeProduct[0]
                                                    .nextBillingDate
                                                )
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ])
                            : _c("div", [
                                _c(
                                  "div",
                                  { staticClass: "row" },
                                  [
                                    _c("b-col", { attrs: { cols: "12" } }, [
                                      _c(
                                        "h4",
                                        {
                                          staticClass: "inactive-subscription"
                                        },
                                        [_vm._v("Subscription not found")]
                                      )
                                    ])
                                  ],
                                  1
                                )
                              ])
                        ])
                      ]),
                      _c("div", { staticClass: "card shadow" }, [
                        _c("div", { staticClass: "card-body" }, [
                          _vm.canTransferWithSubscription()
                            ? _c("div", [
                                _c("h4", { staticClass: "card-title" }, [
                                  _vm._v("Choose A Subscription Option")
                                ]),
                                _vm.transferWithFreeSubscription == null
                                  ? _c("div", { staticClass: "row" }, [
                                      _c("div", { staticClass: "col" }, [
                                        _c(
                                          "i",
                                          {
                                            staticClass:
                                              "asterisk-text colorRed"
                                          },
                                          [_vm._v("selection required")]
                                        )
                                      ])
                                    ])
                                  : _vm._e(),
                                _c("div", { staticClass: "row" }, [
                                  _c("div", { staticClass: "col" }, [
                                    _c(
                                      "fieldset",
                                      { staticClass: "form-group" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "custom-control custom-radio"
                                          },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.transferWithFreeSubscription,
                                                  expression:
                                                    "transferWithFreeSubscription"
                                                }
                                              ],
                                              staticClass:
                                                "custom-control-input",
                                              class:
                                                _vm.$v
                                                  .transferWithFreeSubscription
                                                  .$model == null
                                                  ? "is-invalid"
                                                  : "is-valid",
                                              attrs: {
                                                type: "radio",
                                                value: "true",
                                                id:
                                                  "transferWithFreeSubscriptionRadioTrue",
                                                "data-test-hook":
                                                  "set-up-free-sub-radio-verifyGenTransfer-transfer-gen-modal"
                                              },
                                              domProps: {
                                                checked: _vm._q(
                                                  _vm.transferWithFreeSubscription,
                                                  "true"
                                                )
                                              },
                                              on: {
                                                change: function($event) {
                                                  _vm.transferWithFreeSubscription =
                                                    "true"
                                                }
                                              }
                                            }),
                                            _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "custom-control-label",
                                                attrs: {
                                                  for:
                                                    "transferWithFreeSubscriptionRadioTrue"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "Set Up Free Subscription"
                                                )
                                              ]
                                            )
                                          ]
                                        ),
                                        _c(
                                          "span",
                                          { staticClass: "asterisk-text" },
                                          [
                                            _c("i", [
                                              _vm._v(
                                                " This option will setup a free Mobile Link subscription for the user. The user will not need to do any setup upon logging in to Mobile Link. "
                                              )
                                            ])
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "custom-control custom-radio"
                                          },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.transferWithFreeSubscription,
                                                  expression:
                                                    "transferWithFreeSubscription"
                                                }
                                              ],
                                              staticClass:
                                                "custom-control-input",
                                              class:
                                                _vm.$v
                                                  .transferWithFreeSubscription
                                                  .$model == null
                                                  ? "is-invalid"
                                                  : "is-valid",
                                              attrs: {
                                                id:
                                                  "transferWithFreeSubscriptionRadioFalse",
                                                type: "radio",
                                                value: "false",
                                                "data-test-hook":
                                                  "sub-prompt-radio-verifyGenTransfer-transfer-gen-modal"
                                              },
                                              domProps: {
                                                checked: _vm._q(
                                                  _vm.transferWithFreeSubscription,
                                                  "false"
                                                )
                                              },
                                              on: {
                                                change: function($event) {
                                                  _vm.transferWithFreeSubscription =
                                                    "false"
                                                }
                                              }
                                            }),
                                            _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "custom-control-label",
                                                attrs: {
                                                  for:
                                                    "transferWithFreeSubscriptionRadioFalse"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "Subscription Prompt in Mobile Link"
                                                )
                                              ]
                                            )
                                          ]
                                        ),
                                        _c(
                                          "span",
                                          { staticClass: "asterisk-text" },
                                          [
                                            _c("i", [
                                              _vm._v(
                                                " This option will prompt the user to select a subscription upon logging into Mobile Link. Choose this option if the user wants a paid subscription. "
                                              )
                                            ])
                                          ]
                                        )
                                      ]
                                    )
                                  ])
                                ])
                              ])
                            : _c("div", [
                                _c("div", { staticClass: "row" }, [
                                  _c("div", { staticClass: "col" }, [
                                    _vm._v(
                                      " This Apparatus Will Be Transferred With No Subscription, this will prompt the user to select a subscription upon logging into Mobile Link. "
                                    )
                                  ])
                                ])
                              ])
                        ])
                      ])
                    ])
                  ])
                ])
              ]),
              _c("ModalFooter", [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-outline-warning",
                    attrs: {
                      type: "button",
                      disabled: _vm.transferringGen,
                      "data-test-hook":
                        "cancel-button-verifyGenTransfer-transfer-gen-modal"
                    },
                    on: {
                      click: function($event) {
                        _vm.closeModal(
                          "verify-gen-transfer" + _vm.source.apparatusId
                        ),
                          _vm.resetTransferModals()
                      }
                    }
                  },
                  [_vm._v(" Cancel ")]
                ),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-warning",
                    attrs: {
                      type: "button",
                      disabled:
                        !_vm.destinationOwnerVerified ||
                        _vm.transferWithFreeSubscription == null,
                      "data-toggle": "tooltip",
                      title: _vm.completeTransferBtnTooltip(),
                      "data-test-hook":
                        "complete-transfer-transferConfirmation-transfer-acct-modal"
                    },
                    on: {
                      click: function($event) {
                        _vm.openModal(
                          "transfer-confirmation" + _vm.source.apparatusId
                        ),
                          _vm.closeModal(
                            "verify-gen-transfer" + _vm.source.apparatusId
                          )
                      }
                    }
                  },
                  [
                    _vm._v(" Complete Transfer "),
                    _vm.transferringGen
                      ? _c("div", {
                          staticClass: "spinner-border text-muted ml-2"
                        })
                      : _vm._e()
                  ]
                )
              ])
            ],
            1
          ),
          _c(
            "Modal",
            {
              attrs: {
                modalId: "transfer-confirmation" + _vm.source.apparatusId
              }
            },
            [
              _c("ModalHeader", {
                attrs: {
                  title: "Transfer Confirmation",
                  icon: "fad fa-exchange-alt"
                }
              }),
              _c("ModalBody", [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col" }, [
                    _c("h4", [
                      _vm._v("Are you sure you want to complete the transfer?")
                    ])
                  ])
                ])
              ]),
              _c("ModalFooter", [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-outline-warning",
                    attrs: {
                      type: "button",
                      disabled: _vm.transferringGen,
                      "data-test-hook":
                        "cancel-button-transferConfirmation-transfer-gen-modal"
                    },
                    on: {
                      click: function($event) {
                        _vm.closeModal(
                          "transfer-confirmation" + _vm.source.apparatusId
                        ),
                          _vm.resetTransferModals()
                      }
                    }
                  },
                  [_vm._v(" Cancel ")]
                ),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-warning",
                    attrs: {
                      type: "button",
                      disabled: _vm.transferringGen,
                      "data-test-hook":
                        "complete-transfer-button-transferConfirmation-transfer-gen-modal"
                    },
                    on: {
                      click: function($event) {
                        return _vm.completeTransfer()
                      }
                    }
                  },
                  [
                    _vm._v(" Complete Transfer "),
                    _vm.transferringGen
                      ? _c("div", {
                          staticClass: "spinner-border text-muted ml-2"
                        })
                      : _vm._e()
                  ]
                )
              ])
            ],
            1
          ),
          _c(
            "Modal",
            { attrs: { modalId: "transfer-success" + _vm.source.apparatusId } },
            [
              _c("ModalHeader", {
                attrs: {
                  title: "Transfer Successful",
                  icon: "fad fa-exchange-alt"
                }
              }),
              _c("ModalBody", [
                !_vm.transferredWithError
                  ? _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col" }, [
                        _vm._v(" The Generator was successfully transferred. ")
                      ])
                    ])
                  : _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col colorRed" }, [
                        _vm._v(" " + _vm._s(_vm.transferFailureMessage) + " ")
                      ])
                    ])
              ]),
              _c("ModalFooter", [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-warning btn-block",
                    attrs: {
                      type: "button",
                      "data-test-hook":
                        "close-button-transferSuccess-transfer-gen-modal"
                    },
                    on: {
                      click: function($event) {
                        _vm.closeModal(
                          "transfer-success" + _vm.source.apparatusId
                        ),
                          _vm.emitSuccess()
                      }
                    }
                  },
                  [_vm._v(" Close ")]
                )
              ])
            ],
            1
          ),
          _c(
            "Modal",
            { attrs: { modalId: "transfer-failure" + _vm.source.apparatusId } },
            [
              _c("ModalHeader", {
                attrs: { title: "Transfer Failed", icon: "fad fa-exchange-alt" }
              }),
              _c("ModalBody", [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col" }, [
                    _vm._v(" " + _vm._s(_vm.transferFailureMessage) + " ")
                  ])
                ])
              ]),
              _c("ModalFooter", [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-warning btn-block",
                    attrs: {
                      type: "button",
                      "data-test-hook":
                        "close-button-transferFailure-transfer-gen-button"
                    },
                    on: {
                      click: function($event) {
                        return _vm.closeModal(
                          "transfer-failure" + _vm.source.apparatusId
                        )
                      }
                    }
                  },
                  [_vm._v(" Close ")]
                )
              ])
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }