<template>
  <div>
    <div
      v-if="apparatus"
      class="card hoverPointer apparatus-card"
      :class="getApparatusCardClass()"
      @click="toDetails()"
      :header-bg-variant="getHeaderBGVariant()"
      header-class="dashboard-card-header"
      footer-class="apparatus-card-footer"
      data-test-hook="apparatus-clickable-card-apparatus-card"
    >
      <!--Header-->
      <div v-if="!generatorEnrollmentComplete() && !apparatus.isDealerManaged">
        <div class="card-header dashboard-card-header bg-statusWarning">
          <div data-test-hook="DisconnectedHeader" class="row w-100 no-gutters">
            <div class="float-left col">
              <strong
                v-if="!generatorHasSubscription() && generatorHasDevice()"
                data-test-hook="HeaderText"
                class="py-0 my-0"
              >
                {{ $t('xUpdateYourSubscription') }}
              </strong>
              <strong v-else-if="generatorIsDunning()" data-test-hook="HeaderText" class="py-0 my-0">
                {{ $t('xUpdateCreditCard') }}
              </strong>
              <strong v-else data-test-hook="HeaderText" class="py-0 my-0">
                {{ $t('xFinishSettingUpYourProduct') }}
              </strong>
            </div>
            <div class="float-right mr-0 pr-0 col-auto">
              <i class="fa-solid fa-arrow-right" />
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="!generatorEnrollmentComplete() && apparatus.isDealerManaged">
        <div class="card-header dashboard-card-header bg-statusWarning">
          <div data-test-hook="NoSubscriptionHeader" class="row w-100 no-gutters">
            <div class="float-left col">
              <strong
                v-if="!generatorHasSubscription() && generatorHasDevice()"
                data-test-hook="HeaderText"
                class="py-0 my-0"
              >
                {{ $t('xYourDealerManagedSubscriptionHasExpired') }}
              </strong>
              <strong v-else data-test-hook="HeaderText" class="py-0 my-0">
                {{ $t('xFinishSettingUpYourProduct') }}
              </strong>
            </div>
            <div class="float-right mr-0 pr-0 col-auto">
              <i class="fa-solid fa-arrow-right" />
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="warningHeader">
        <div class="card-header dashboard-card-header bg-statusWarning">
          <div data-test-hook="WarningHeader" class="row w-100 no-gutters">
            <div class="float-left col">
              <strong data-test-hook="HeaderText" class="py-0 my-0">
                {{ $t('xWarningDetails') }}
              </strong>
            </div>
            <div class="float-right mr-0 pr-0 col-auto">
              <i class="fa-solid fa-arrow-right" />
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="faultHeader">
        <div class="card-header dashboard-card-header bg-statusFault">
          <div data-test-hook="FaultHeader" class="row w-100 no-gutters">
            <div class="float-left col">
              <strong data-test-hook="HeaderText" class="py-0 my-0 colorWhite">
                {{ $t('xFaultDetails') }}
              </strong>
            </div>
            <div class="float-right mr-0 pr-0 col-auto">
              <i class="fa-solid fa-arrow-right" />
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="disconnectedHeader">
        <div class="card-header dashboard-card-header bg-statusDisconnected">
          <div data-test-hook="DisconnectedHeader" class="row w-100 no-gutters">
            <div class="float-left col">
              <strong data-test-hook="HeaderText" class="py-0 my-0">
                {{ noSignalMessage() }}
              </strong>
            </div>
            <div class="float-right mr-0 pr-0 col-auto">
              <i class="fa-solid fa-arrow-right" />
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="switchedOffHeader">
        <div class="card-header dashboard-card-header bg-statusFault">
          <div data-test-hook="switchedOffHeader" class="row w-100 no-gutters">
            <div class="float-left col">
              <strong data-test-hook="HeaderText" class="py-0 my-0 colorWhite">
                {{ $t('xAttentionRequired') }}
              </strong>
            </div>
            <div class="float-right mr-0 pr-0 col-auto">
              <i class="fa-solid fa-arrow-right" style="color: white;" />
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="card-header dashboard-card-header">
          <div data-test-hook="StandardHeader" class="center row w-100 no-gutters">
            <div v-if="apparatus.weather" data-test-hook="ApparatusWeather" class="float-left col-auto">
              <i :class="apparatus.weather.iconCode | weatherIcon"></i>
              {{ temperature }}&deg;{{ temperatureUnits }}
            </div>
            <div
              v-if="device && device[0] && device[0].value && device[0].value.signalStrength"
              class="float-left col-auto ml-2"
            >
              <i v-if="getDeviceType() == wifi" class="fa-solid fa-wifi" data-test-hook="ApparatusSignalStrength" />
              <i v-else class="fal fa-signal-alt" data-test-hook="ApparatusSignalStrength" />
              {{ device[0].value.signalStrength }}
            </div>
            <div class="float-right text-right mr-0 pr-0 col">
              <i class="fa-solid fa-arrow-right" />
            </div>
          </div>
        </div>
      </div>
      <div :class="apparatus.isConnecting ? 'connectingOverlay' : null"></div>
      <div class="card-body">
        <!--Signal-->
        <div class="row d-flex">
          <div class="col-auto">
            <h4 class="mb-0 py-2 pl-1 d-flex align-items-center">
              <StatusIcon
                class="mr-1 statusIcon"
                :apparatusStatus="apparatus.apparatusStatus"
                :isConnected="apparatus.isConnected"
                :showWarning="apparatus.showWarning"
                :isConnecting="apparatus.isConnecting"
                :deviceType="getDeviceType()"
                data-test-hook="ApparatusStatusIcon"
              />
              <span data-test-hook="ApparatusStatusText">{{ getStatusText() }}</span>
            </h4>
          </div>
        </div>
        <!--Hero Image and Address-->
        <div class="row d-flex">
          <div class="col-4">
            <img :src="apparatus.heroImageUrl" data-test-hook="ApparatusHeroImg" alt="Hero Image" class="img-fluid" />
          </div>
          <div class="pt-4 col-8">
            <div class="row">
              <div data-test-hook="ApparatusAddress" class="ellipsis-overflow mb-2 col">
                {{ apparatus.localizedAddress }}
              </div>
            </div>
            <div class="row">
              <div class="col">
                <h2 data-test-hook="ApparatusName" class="pb-1 mb-0">
                  {{ apparatus.name }}
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--Footer-->
      <div class="card-footer apparatus-card-footer">
        <div
          v-if="
            generatorEnrollmentComplete() &&
              subscription &&
              subscription[0] &&
              subscription[0].value &&
              subscription[0].value.type != NoSub
          "
          class="row"
        >
          <div class="col">
            <span v-if="apparatus.isDealerManaged || apparatus.isDealerUnmonitored" class="text-small">{{
              'xDealerManaged' | formatLabel
            }}</span>
            <span v-else class="text-small">{{ 'xPreferredDealer' | formatLabel }}</span>
          </div>
        </div>
        <div
          v-if="
            generatorEnrollmentComplete() &&
              subscription &&
              subscription[0] &&
              subscription[0].value &&
              subscription[0].value.type != NoSub
          "
          class="row"
        >
          <div v-if="apparatus && apparatus.preferredDealerName" class="col">
            <p data-test-hook="ApparatusPreferredName" class="font-weight-bold">
              <i class="fal fa-user-hard-hat"></i>
              {{ apparatus.preferredDealerName }}
            </p>
          </div>
          <div v-else class="col">
            <p data-test-hook="ApparatusPreferredName" class="font-weight-bold">
              <i class="fal fa-user-hard-hat"></i>
              {{ $t('xNoneSelected') }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <!--Dealer Managed Subscription Modal-->
    <div
      v-if="apparatus && apparatus.apparatusId"
      :id="'dealer-managed-sub-expired' + apparatus.apparatusId"
      role="dialog"
      class="modal fade show"
    >
      <div class="modal-dialog modal-dialog-centered">
        <span tabindex="0"></span>
        <div tabindex="-1" class="modal-content">
          <header class="modal-header">
            <div class="row w-100 no-gutters align-items-center">
              <div class="col">
                <h2 class="modal-title">
                  <i class="far fa-badge-dollar colorOrange mr-1" />
                  {{ $t('xSubscriptionExpired') }}
                </h2>
              </div>
              <div class="float-right col-auto">
                <button type="button" class="btn btn-icon-button--gray btn-icon-button" @click="closeModal()">
                  <i class="fas fa-times" />
                </button>
              </div>
            </div>
          </header>
          <div class="modal-body">
            <div class="row">
              <div class="col">
                {{ $t('xYourDealerManagedSubscriptionHasExpiredContactDealer') }}
              </div>
            </div>
            <p class="mt-4">
              {{ 'xYourDealer' | formatLabel }}
            </p>
            <div class="row mt-2">
              <div class="col" data-test-hook="ApparatusPreferredDealerName">
                <strong>{{ apparatus.preferredDealerName }}</strong>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col" data-test-hook="ApparatusPreferredDealerPhone">
                <i class="fa-regular fa-phone pr-2" />
                {{ apparatus.preferredDealerPhone | phone }}
              </div>
            </div>
            <div class="row mt-2">
              <div class="col" data-test-hook="ApparatusPreferredDealerEmail">
                <i class="fa-regular fa-envelope pr-2" />
                <a :href="'mailto:' + apparatus.preferredDealerEmail">{{ apparatus.preferredDealerEmail }}</a>
              </div>
            </div>
          </div>
          <ModalFooter>
            <button type="button" @click="closeModal()" class="btn btn-primary">
              {{ $t('xDone') }}
            </button>
          </ModalFooter>
        </div>
      </div>
    </div>
    <!--Update Payment Modal-->
    <UpdateCreditCardModal
      v-if="generatorIsDunning()"
      :existingPaymentInfo="paymentInfo"
      :accountProfile="accountProfile"
      :apparatusId="apparatusInfo.apparatusId"
      :subscriptionStatus="
        this.subscription && this.subscription[0] && this.subscription[0].value && this.subscription[0].value.status
          ? this.subscription[0].value.status
          : null
      "
    />
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import StatusIcon from '@/components/StatusIcon'
import UpdateCreditCardModal from '@/components/Dashboard/UpdateCreditCardModal'
import * as Constants from '@/constants'

export default {
  components: { StatusIcon, UpdateCreditCardModal },
  props: ['apparatusInfo', 'paymentInfo'],
  data() {
    return {
      device: null,
      subscription: null,
      startSubFlow: false,
      apparatus: null,
      FreeSub: Constants.FreeSubscription,
      NoSub: Constants.NoSubscription,
      temperature: 0,
      temperatureUnits: 'F',
      wifi: Constants.HsbWifiDeviceType
    }
  },
  computed: {
    ...mapState({
      accountProfile: state => state.account.accountProfile,
      user: state => state.account.user,
      currentAppConfig: state => state.application.currentAppConfig
    }),
    billingMigrationFeatureFlag() {
      return Constants.BillingMigrationFeatureFlag
    },
    warningHeader() {
      if (this.apparatus.isConnecting) return false
      if (!this.apparatus.isConnected) return false
      return this.apparatus.apparatusStatus == Constants.StatusIsWarning
    },
    faultHeader() {
      if (this.apparatus.isConnecting) return false
      if (!this.apparatus.isConnected) return false
      return this.apparatus.apparatusStatus == Constants.StatusIsFault
    },
    switchedOffHeader() {
      if (this.apparatus.isConnecting) return false
      if (!this.apparatus.isConnected) return false
      return this.apparatus.apparatusStatus == Constants.StatusIsSwitchedOff
    },
    disconnectedHeader() {
      return (
        !this.apparatus.isConnecting &&
        (!this.apparatus.isConnected || this.apparatus.apparatusStatus == Constants.StatusIsCommsIssue)
      )
    }
  },
  methods: {
    getStatusText() {
      if (!this.apparatus.isConnected) return 'No Signal'
      else if (this.apparatus.apparatusStatus == Constants.StatusIsWarning) return 'Warning'
      else if (this.apparatus.apparatusStatus == Constants.StatusIsFault) return 'Stopped'
      else if (this.apparatus.apparatusStatus == Constants.StatusIsRunning) return 'Running'
      else if (this.apparatus.apparatusStatus == Constants.StatusIsReady) return 'Ready To Run'
      else if (this.apparatus.apparatusStatus == Constants.StatusIsExercising) return 'Exercising'
      else if (this.apparatus.apparatusStatus == Constants.StatusIsCommsIssue) return 'Trouble Communicating'
      else if (this.apparatus.apparatusStatus == Constants.StatusIsSwitchedOff) return 'Switched Off'
      else return 'Status Unknown'
    },
    getHeaderBGVariant() {
      if (!this.generatorEnrollmentComplete()) return 'statusWarning'
      else if (this.disconnectedHeader) return 'statusDisconnected'
      else if (this.warningHeader) return 'statusWarning'
      else if (this.faultHeader) return 'statusFault'
      else if (this.switchedOffHeader) return 'statusSwitchedOff'
    },
    getApparatusCardClass() {
      if (this.apparatus && this.generatorEnrollmentComplete()) {
        if (this.apparatusInfo.isConnecting) return ''
        else return this.apparatus.isConnected ? `apparatusCard${this.apparatus.apparatusStatus}` : ''
      }
    },
    openEnrollmentFlow() {
      if (
        this.currentAppConfig &&
        this.currentAppConfig.featureFlags &&
        !this.currentAppConfig.featureFlags[this.billingMigrationFeatureFlag]
      ) {
        this.$emit('incompleteApparatus', {
          id: this.apparatus.apparatusId,
          device: this.device,
          subscription: this.subscription,
          modelNumber: this.apparatus.modelNumber
        })
      }
    },
    generatorEnrollmentComplete() {
      if (!this.apparatus) return false
      if (this.apparatus.type != Constants.HsbApparatusType) {
        return true
      }
      if (!this.apparatus.properties) return false
      if (this.device && this.subscription && this.device.length >= 1 && this.subscription.length >= 1) {
        if (
          this.subscription[0].value.status &&
          (this.subscription[0].value.status == Constants.SubscriptionActive ||
            this.subscription[0].value.status == Constants.SubscriptionPendingCancellation ||
            this.subscription[0].value.status == Constants.SubscriptionPendingTermination ||
            this.subscription[0].value.status == Constants.SubscriptionPendingSuspension)
        ) {
          return true
        }
      }
      return false
    },
    generatorIsDunning() {
      if (!this.apparatus) return false
      if (this.apparatus.type != Constants.HsbApparatusType) {
        return true
      }
      if (!this.apparatus.properties) return false
      if (this.device && this.subscription && this.device.length >= 1 && this.subscription.length >= 1) {
        if (this.subscription[0].value.status && this.subscription[0].value.isDunning) {
          return true
        }
      }
      return false
    },
    generatorHasDevice() {
      if (!this.apparatus) return false
      if (this.apparatus.type != Constants.HsbApparatusType) {
        return true
      }
      if (!this.apparatus.properties) return false
      if (this.device && this.device.length >= 1) return true
      return false
    },
    generatorHasSubscription() {
      if (!this.apparatus) return false
      if (this.apparatus.type != Constants.HsbApparatusType) return false
      if (!this.apparatus.properties) return false
      if (this.subscription && this.subscription.length >= 1) {
        if (
          this.subscription[0].value.status &&
          (this.subscription[0].value.status == Constants.SubscriptionActive ||
            this.subscription[0].value.status == Constants.SubscriptionPendingCancellation ||
            this.subscription[0].value.status == Constants.SubscriptionPendingTermination ||
            this.subscription[0].value.status == Constants.SubscriptionPendingSuspension ||
            this.subscription[0].value.status == Constants.SubscriptionDunning)
        ) {
          return true
        }
      }
      return false
    },
    toDetails() {
      if (this.generatorEnrollmentComplete()) {
        this.$appInsights.trackEvent({
          name: 'click-apparatus-card',
          properties: { apparatusId: this.apparatus.apparatusId }
        })
        this.$router.push({
          path: `/details/${this.apparatus.apparatusId}`
        })
      } else if (this.apparatusInfo.isDealerManaged && !this.generatorEnrollmentComplete()) {
        document.getElementById('dealer-managed-sub-expired' + this.apparatus.apparatusId).style.display = 'block'
        this.$appInsights.trackEvent({
          name: 'expired-dealer-managed-sub-hsb',
          properties: {
            page: 'dashboard',
            apparatusId: this.apparatus.apparatusId
          }
        })
      } else if (this.generatorIsDunning()) {
        if (
          this.currentAppConfig &&
          this.currentAppConfig.featureFlags &&
          !this.currentAppConfig.featureFlags[this.billingMigrationFeatureFlag]
        ) {
          document.getElementById('fixDunningModal' + this.apparatus.apparatusId).style.display = 'block'
        }
      } else this.openEnrollmentFlow()
    },
    closeModal() {
      document.getElementById('dealer-managed-sub-expired' + this.apparatus.apparatusId).style.display = 'none'
    },
    setTemperatureUnits() {
      if (!this.user.mobileLinkSettings?.displaySettings?.temperatureUom) {
        this.temperatureUnits = 'F'
      } else {
        this.temperatureUnits = this.user.mobileLinkSettings.displaySettings.temperatureUom == 'fahrenheit' ? 'F' : 'C'
      }
    },
    setTemperature() {
      if (
        this.user?.mobileLinkSettings?.displaySettings?.temperatureUom == undefined ||
        this.apparatus?.weather?.temperature.unit == undefined ||
        this.apparatus?.weather?.temperature.value == undefined
      ) {
        return
      }
      if (
        (this.user.mobileLinkSettings.displaySettings.temperatureUom == 'fahrenheit' &&
          this.apparatus.weather.temperature.unit == 'F') ||
        (this.user.mobileLinkSettings.displaySettings.temperatureUom == 'celsius' &&
          this.apparatus.weather.temperature.unit == 'C')
      ) {
        this.temperature = this.apparatus.weather.temperature.value
      } else if (this.user.mobileLinkSettings.displaySettings.temperatureUom == 'fahrenheit') {
        //Celsius to Fahrenheit
        this.temperature = Math.round(this.apparatus.weather.temperature.value * 9) / 5 + 32
      } else if (this.apparatus.weather) {
        //Fahrenheit to Celsius
        this.temperature = Math.round(((this.apparatus.weather.temperature.value - 32) * 5) / 9)
      }
    },
    getDeviceType() {
      if (this.device && this.device[0] && this.device[0].value) {
        let currentDevice = this.device[0].value
        if (currentDevice.deviceType == Constants.HsbG3DeviceType) {
          return currentDevice.networkType
        }
        return currentDevice.deviceType
      }
    },
    noSignalMessage() {
      switch (this.getDeviceType()) {
        case 'wifi':
          return this.$t('xNoWifiSignal')
        case 'eth':
          return this.$t('xNoSignal')
        case 'lte':
        case 'cdma':
          return this.$t('xNoCellularSignal')
        default:
          return this.$t('xDisconnected')
      }
    }
  },
  watch: {
    apparatusInfo: function(newVal, oldVal) {
      if (newVal) {
        this.apparatus = newVal
        this.device = this.apparatus.properties.filter(x => x.type == Constants.ApparatusPropertyDevice)
        this.subscription = this.apparatus.properties.filter(x => x.type == Constants.ApparatusPropertySubscription)
      }
    },
    user: function(newVal, oldVal) {
      if (newVal) {
        this.setTemperature()
        this.setTemperatureUnits()
      }
    }
  },
  mounted() {
    if (this.apparatusInfo) {
      this.apparatus = this.apparatusInfo
      this.device = this.apparatus.properties.filter(x => x.type == Constants.ApparatusPropertyDevice)
      this.subscription = this.apparatus.properties.filter(x => x.type == Constants.ApparatusPropertySubscription)
      this.setTemperature()
      this.setTemperatureUnits()
    }
    if (!this.currentAppConfig) this.$store.dispatch('getApplicationConfiguration', 'Any')
  }
}
</script>

<style scoped>
.statusIcon {
  font-size: 1.25em;
}
</style>
