<template>
  <div>
    <b-card v-if="!messages" class="h-100">
      <b-row class="text-center">
        <b-col>
          <span class="read-message-icon" data-test-hook="msgDtlsBodyIcon">
            <i class="far fa-envelope-open"></i>
          </span>
        </b-col>
      </b-row>
      <b-row class="text-center mt-3">
        <b-col>
          <p data-test-hook="msgDtlsBodyText">{{ $t('xSelectItemToRead') }}</p>
        </b-col>
      </b-row>
    </b-card>
    <b-card v-else header-bg-variant="white" body-class="no-padding">
      <template #header>
        <b-row>
          <b-col class="d-flex align-items-center">
            <span v-if="accountMessages">
              <h2 class="mb-0" data-test-hook="msgDtlsAccountMessagesHeader">{{ $t('xAccountMessages') }}</h2>
            </span>
            <span v-else-if="appUpdateMessages">
              <h2 class="mb-0" data-test-hook="msgDtlsAppUpdatesHeader">{{ $t('xInbox') }}</h2>
            </span>
          </b-col>
          <b-col cols="auto">
            <b-button variant="icon-button" @click="openDeleteConfirmationModal()" data-test-hook="msgDtlsDeleteBtn">
              <i class="fas fa-trash-alt" />
            </b-button>
          </b-col>
        </b-row>
      </template>
      <b-row>
        <b-col class="my-3 align-with-header">
          <b-link
            v-if="accountMessages && documentsStatementsPageEnabled(currentAppConfig)"
            :to="{ name: 'accountSettings', params: { statementTabActive: true } }"
            data-test-hook="msgDtlsViewStatementsBtn"
          >
            <i class="fas fa-file-alt" /> {{ $t('xViewStatements') }}
          </b-link>
          <b-link
            v-else-if="appUpdateMessages"
            href="https://support.generac.com/mobilelink"
            target="_blank"
            data-test-hook="msgDtlsSupportCenterBtn"
          >
            <i class="fas fa-external-link-alt" />
            {{ $t('xSupportCenter') }}
          </b-link>
        </b-col>
      </b-row>
      <hr class="my-0 py-0" />
      <b-list-group flush class="mt-0 pt-0" v-for="(message, index) in messages" :key="index">
        <div v-if="index != lastReadMessageIndex && index != 0" class="message-break" />
        <div v-else-if="index == lastReadMessageIndex" data-test-hook="msgDtlsLastReadText">
          <b-row no-gutters class="my-0 py-0 page-break" align-v="center">
            <b-col class="divider"></b-col>
            <b-col cols="auto" class="mx-2 text-center">
              Last read
            </b-col>
            <b-col class="divider"></b-col>
          </b-row>
        </div>
        <b-list-group-item>
          <b-row class="mt-2 mb-1">
            <b-col class="text-small">
              {{ displayShortDateTime(message.createdDate) }}
            </b-col>
          </b-row>
          <b-row class="mb-2">
            <b-col>
              <h4>{{ message.title }}</h4>
            </b-col>
          </b-row>
          <b-row>
            <b-col v-if="message.textType == 1">
              <VueMarkdown :source="message.body" />
            </b-col>
            <b-col v-else>
              <p>{{ message.body }}</p>
            </b-col>
          </b-row>
        </b-list-group-item>
      </b-list-group>
    </b-card>
    <DeleteMessagesModal v-if="messages" :messages="messages" />
  </div>
</template>

<script>
import VueMarkdown from 'vue-markdown-render'
import * as Constants from '@/constants'
import DeleteMessagesModal from '@/components/MessageCenter/DeleteMessagesModal'
import { mapState } from 'vuex'

export default {
  props: ['value'],
  components: { VueMarkdown, DeleteMessagesModal },
  data() {
    return {
      messages: null,
      lastReadMessageIndex: -1
    }
  },
  computed: {
    ...mapState({
      currentAppConfig: state => state.application.currentAppConfig
    }),
    accountMessages() {
      if (this.messages && this.messages[0]) {
        if (this.messages[0].category == Constants.AccountMessagesMessageCategory) {
          return true
        }
      }
      return false
    },
    appUpdateMessages() {
      if (this.messages && this.messages[0]) {
        if (this.messages[0].category == Constants.AppUpdatesMessageCategory) {
          return true
        }
      }
      return false
    }
  },
  methods: {
    showLastRead(index) {
      if (this.messages) {
        if (index == 0) return false
        if (!this.messages[index - 1].read && this.messages[index].read) {
          return true
        } else return false
      }
    },
    openDeleteConfirmationModal() {
      this.$bvModal.show('delete-all-messages-modal')
    }
  },
  watch: {
    value: function(newVal, oldVal) {
      if (!newVal || newVal.length == 0) this.messages = null
      else {
        this.messages = newVal
        this.lastReadMessageIndex = this.messages.findIndex(element => element.read)
        this.$emit('selectedCategory', this.messages[0].category)
      }
    }
  },
  mounted() {
    if (!this.currentAppConfig) this.$store.dispatch('getApplicationConfiguration', 'Any')
    if (!this.value || this.value.length == 0) this.messages = null
    else {
      this.messages = this.value
      this.lastReadMessageIndex = this.messages.findIndex(element => element.read)
      this.$emit('selectedCategory', this.messages[0].category)
    }
  }
}
</script>
<style scoped lang="scss">
.no-padding {
  padding: 0px;
}
.align-with-header {
  margin-left: 25px;
}

.read-message-icon {
  font-size: 2em;
  color: var(--brand-400);
}
.page-break {
  height: 30px !important;
  font-size: 1rem;
  background-color: var(--carbon-100);
  border-bottom: 1px solid var(--carbon-300);
  color: var(--carbon-600);
  font-style: italic;
  text-align: center !important;
}

.message-break {
  border-bottom: 1px solid var(--carbon-300);
}
.divider {
  height: 30px;
  padding-bottom: 15px;
  width: 100%;
}

.divider:after {
  content: '';
  display: block;
  margin-top: 15px;
  border-bottom: 1px solid var(--carbon-300);
}
</style>
