import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import values from './modules/values'
import auth from './modules/auth'
import account from './modules/account'
import toast from './modules/toast'
import apparatus from './modules/apparatus'
import customerSupport from './modules/customerSupport'
import migration from './modules/migration'
import subscriptions from './modules/subscriptions'
import tankUtility from './modules/tankUtility'
import support from './modules/support'
import application from './modules/application'
import dealer from './modules/dealer'
import map from './modules/map'
import messageCenter from './modules/messageCenter'
import common from './modules/common'
import virtualPowerPlant from './modules/virtualPowerPlant'
import eventLog from './modules/eventLog'
import cdmaBanner from './modules/cdmaBanner'
import mobile from './modules/mobile'

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
      paths: ['auth.user', 'auth.isImpersonating', 'apparatus.currentApparatus']
    })
  ],
  state: {},
  mutations: {},
  actions: {
    clearAll({ commit }) {
      commit('authReset')
      commit('apparatusReset')
      sessionStorage.clear()
    }
  },
  modules: {
    values,
    auth,
    account,
    toast,
    apparatus,
    customerSupport,
    migration,
    subscriptions,
    tankUtility,
    support,
    application,
    dealer,
    map,
    messageCenter,
    common,
    virtualPowerPlant,
    eventLog,
    cdmaBanner,
    mobile
  }
})
