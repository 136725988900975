var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "card no-border" }, [
        _c("div", { staticClass: "card-body" }, [
          _c("h4", { staticClass: "card-title" }, [_vm._v("Commands")]),
          _vm.commandPending
            ? _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12" }, [
                  _vm._v(_vm._s(_vm.commandTypeDescription) + " is sending.")
                ])
              ])
            : _vm._e(),
          _c("div", { staticClass: "btn-group", attrs: { role: "group" } }, [
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                attrs: {
                  type: "button",
                  "data-test-hook": "get-status-button-commands-tab",
                  disabled: _vm.commandPending
                },
                on: {
                  click: function($event) {
                    return _vm.InitiateCommand("GetStatus")
                  }
                }
              },
              [
                _vm.value.deviceInfo.deviceType != "cdma"
                  ? _c("span", [_vm._v("Get Status")])
                  : _c("span", [_vm._v("Update Modem Status")])
              ]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                attrs: {
                  type: "button",
                  "data-test-hook": "update-alarm-log-button-commands-tab",
                  disabled: _vm.commandPending
                },
                on: {
                  click: function($event) {
                    return _vm.InitiateCommand("UpdateAlarmLog")
                  }
                }
              },
              [_vm._v(" Update Alarm Log ")]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                attrs: {
                  type: "button",
                  "data-test-hook": "update-run-log-button-commands-tab",
                  disabled: _vm.commandPending
                },
                on: {
                  click: function($event) {
                    return _vm.InitiateCommand("UpdateRunLog")
                  }
                }
              },
              [_vm._v(" Update Run Log ")]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                attrs: {
                  type: "button",
                  "data-test-hook":
                    "update-maintenance-log-button-commands-tab",
                  disabled: _vm.commandPending
                },
                on: {
                  click: function($event) {
                    return _vm.InitiateCommand("UpdateMaintenanceLog")
                  }
                }
              },
              [_vm._v(" Update Maintenance Log ")]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                attrs: {
                  type: "button",
                  "data-test-hook": "reset-alarm-button-commands-tab",
                  disabled: _vm.commandPending
                },
                on: {
                  click: function($event) {
                    return _vm.InitiateCommand("ResetAlarm")
                  }
                }
              },
              [_vm._v(" Reset Alarm ")]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                attrs: {
                  type: "button",
                  "data-test-hook": "reboot-controller-button-commands-tab",
                  disabled: _vm.commandPending
                },
                on: {
                  click: function($event) {
                    return _vm.InitiateCommand("RebootController")
                  }
                }
              },
              [
                _vm.value.deviceInfo.deviceType != "cdma"
                  ? _c("span", [_vm._v("Reboot Controller")])
                  : _c("span", [_vm._v("Recycle Modem")])
              ]
            )
          ])
        ])
      ]),
      _c(
        "Modal",
        { attrs: { modalId: "remoteDisclaimerModal" } },
        [
          _c("span", { attrs: { tabindex: "0" } }),
          _c("ModalBody", [_c("p", [_vm._v(_vm._s(_vm.DisclaimerText()))])]),
          _c("ModalFooter", [
            _c(
              "button",
              {
                staticClass: "btn btn-secondary",
                attrs: {
                  "data-test-hook":
                    "cancel-button-remoteDisclaimerModal-commands-tab"
                },
                on: {
                  click: function($event) {
                    return _vm.closeCommandModal()
                  }
                }
              },
              [_vm._v(" Cancel ")]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                attrs: {
                  "data-test-hook":
                    "continue-button-remoteDisclaimerModal-commands-tab"
                },
                on: {
                  click: function($event) {
                    return _vm.issueCommand()
                  }
                }
              },
              [_vm._v(" Continue ")]
            )
          ])
        ],
        1
      ),
      _c("div", { staticClass: "modal", attrs: { id: "commandStatusModal" } }, [
        _c("div", { staticClass: "modal-dialog" }, [
          _c("div", { staticClass: "modal-content" }, [
            _c("div", { staticClass: "modal-header" }, [
              _c("h2", { staticClass: "modal-title" }, [
                _vm._v(" " + _vm._s(_vm.commandResponseText) + " ")
              ])
            ]),
            _c("div", { staticClass: "modal-body" }, [
              _vm._v(" " + _vm._s(_vm.commandResponseMessage) + " ")
            ]),
            _c("div", { staticClass: "modal-footer" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  on: {
                    click: function($event) {
                      return _vm.closeCommandStatusModal()
                    }
                  }
                },
                [_vm._v("OK")]
              )
            ])
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }