import router from '@/router/'
import i18n from '@/lang/'

var initialState = {
  cdmaWarning: null
}

export const state = _.cloneDeep(initialState)

export const getters = {
  cdmaWarning: state => state.cdmaWarning
}

export const mutations = {
  setCdmaWarning(state, warning) {
    state.cdmaWarning = warning
  }
}

export const actions = {
  getCdmaWarning({ getters, commit, dispatch }) {
    if (getters['cdmaWarning'] == null) {
      let warning = JSON.parse(sessionStorage.getItem('cdmaWarning'))
      if (warning) {
        commit('setCdmaWarning', warning)
        return warning
      } else return null
    }
  },
  setCdmaWarning({ getters, commit, dispatch }, warning) {
    commit('setCdmaWarning', warning)
    sessionStorage.removeItem('cdmaWarning')
    sessionStorage.setItem('cdmaWarning', JSON.stringify(warning))
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
