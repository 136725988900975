var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "Modal",
        { attrs: { modalId: "addMaintenanceModal", size: "modal-xl" } },
        [
          _c(
            "ModalHeader",
            { attrs: { title: _vm.getTitle(), icon: "fas fa-calendar-plus" } },
            [
              _c(
                "button",
                {
                  staticClass: "btn btn-icon-button--gray",
                  attrs: { "data-test-hook": "x-button-add-maintenance-modal" },
                  on: {
                    click: function($event) {
                      return _vm.closeThisModal()
                    }
                  }
                },
                [_c("i", { staticClass: "fas fa-times" })]
              )
            ]
          ),
          _c("ModalBody", [
            _c("div", { staticClass: "row mb-2" }, [
              _c("div", { staticClass: "col" }, [
                _c("strong", [
                  _vm._v(_vm._s(_vm.$t("xMaintenanceType")) + " ")
                ]),
                _c("i", { staticStyle: { color: "grey" } }, [
                  _vm._v("(" + _vm._s(_vm.$t("xSelectAllThatApply")) + ")")
                ])
              ])
            ]),
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col" },
                _vm._l(_vm.selectedMaintenanceTypes, function(type, index) {
                  return _c(
                    "button",
                    {
                      key: index,
                      staticClass: "btn mx-2 mb-2 rounded-pill",
                      class: _vm.getVariant(type.selected),
                      attrs: {
                        type: "button",
                        "data-test-hook":
                          "add-tag-button-add-maintenance-modal" + index
                      },
                      on: {
                        click: function($event) {
                          return _vm.addTag(type.name, index)
                        }
                      }
                    },
                    [
                      _vm._v(" " + _vm._s(type.name) + " "),
                      type.selected
                        ? _c("i", { staticClass: "fas fa-check fa-md" })
                        : _vm._e()
                    ]
                  )
                }),
                0
              )
            ]),
            _c("div", { staticClass: "row mt-1" }, [
              _c(
                "div",
                { staticClass: "col", staticStyle: { "font-size": "1.15em" } },
                [
                  _vm._v(" " + _vm._s(_vm.$t("xOther")) + " "),
                  _c("div", { staticClass: "input-group mt-2 mb-3" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.otherMaintenanceType,
                          expression: "otherMaintenanceType"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        placeholder: _vm.$t("xEnterMaintenanceType"),
                        pattern: "[^A-Za-z0-9 ]+",
                        maxlength: "30"
                      },
                      domProps: { value: _vm.otherMaintenanceType },
                      on: {
                        keyup: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.addOtherMaintenanceType()
                        },
                        keypress: function($event) {
                          return _vm.validTagChars($event)
                        },
                        input: function($event) {
                          if ($event.target.composing) return
                          _vm.otherMaintenanceType = $event.target.value
                        }
                      }
                    }),
                    _c("div", { staticClass: "input-group-append" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "input-group-text hoverPointer add-button",
                          on: {
                            click: function($event) {
                              return _vm.addOtherMaintenanceType()
                            }
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("xAdd")) + " ")]
                      )
                    ])
                  ])
                ]
              )
            ]),
            _c("div", { staticClass: "row mt-3" }, [
              _c("div", { staticClass: "col-lg col-12" }, [
                _c(
                  "div",
                  { staticClass: "row mb-2", staticStyle: { width: "175px" } },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "col",
                        staticStyle: { "font-size": "1.15em" }
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("xDateCompleted")) + " ")]
                    )
                  ]
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.dateCompleted,
                      expression: "dateCompleted"
                    }
                  ],
                  staticClass: "form-control mt-1 btn-outline-warning",
                  attrs: { type: "date" },
                  domProps: { value: _vm.dateCompleted },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) return
                      _vm.dateCompleted = $event.target.value
                    }
                  }
                })
              ]),
              _c(
                "div",
                {
                  staticClass: "col-lg col-12",
                  staticStyle: { "font-size": "1.15em" }
                },
                [
                  _vm._v(" " + _vm._s(_vm.$t("xTotalCost")) + " "),
                  _c("div", { staticClass: "input-group mt-2 mb-3" }, [
                    _c("div", { staticClass: "input-group-text" }, [
                      _vm._v("$")
                    ]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.totalCost,
                          expression: "totalCost"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "number",
                        min: "0.00",
                        max: "99999.99",
                        step: "0.01",
                        placeholder: "0.00"
                      },
                      domProps: { value: _vm.totalCost },
                      on: {
                        keyup: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.money($event)
                        },
                        input: function($event) {
                          if ($event.target.composing) return
                          _vm.totalCost = $event.target.value
                        }
                      }
                    })
                  ])
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "col-lg col-12",
                  staticStyle: { "font-size": "1.15em" }
                },
                [
                  _vm._v(" " + _vm._s(_vm.$t("xRunHours")) + " "),
                  _c("div", { staticClass: "input-group mt-2 mb-3" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.runHours,
                          expression: "runHours"
                        },
                        {
                          name: "mask",
                          rawName: "v-mask",
                          value: "######",
                          expression: "'######'"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: { type: "text", placeholder: _vm.$t("xRunHours") },
                      domProps: { value: _vm.runHours },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) return
                          _vm.runHours = $event.target.value
                        }
                      }
                    })
                  ])
                ]
              )
            ]),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col" }, [
                _c("label", { attrs: { for: "comments" } }, [
                  _vm._v(_vm._s(_vm.$t("xNotes")))
                ]),
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.comments,
                      expression: "comments"
                    }
                  ],
                  staticClass: "form-control",
                  staticStyle: { resize: "none", "overflow-y": "scroll" },
                  attrs: {
                    id: "comments",
                    placeholder: _vm.$t("xEnterComments"),
                    wrap: "soft",
                    maxlength: "500",
                    rows: "3",
                    "max-rows": "8"
                  },
                  domProps: { value: _vm.comments },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) return
                      _vm.comments = $event.target.value
                    }
                  }
                })
              ])
            ])
          ]),
          _c("ModalFooter", [
            _c(
              "button",
              {
                staticClass: "btn btn-outline-warning",
                attrs: {
                  type: "button",
                  disabled: _vm.disableCancel,
                  "data-test-hook": "cancel-button-add-maintenance-modal"
                },
                on: {
                  click: function($event) {
                    return _vm.closeThisModal()
                  }
                }
              },
              [_vm._v(" " + _vm._s(_vm.$t("xCancel")) + " ")]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-warning",
                attrs: {
                  type: "button",
                  disabled: !_vm.canAddRecord() || _vm.savingRecord,
                  "data-test-hook": "save-button-add-maintenance-modal"
                },
                on: {
                  click: function($event) {
                    return _vm.saveMaintenance()
                  }
                }
              },
              [
                _vm._v(" " + _vm._s(_vm.$t("xSave")) + " "),
                _vm.savingRecord
                  ? _c("div", { staticClass: "spinner-border text-muted ml-2" })
                  : _vm._e()
              ]
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }