import Axios from 'axios'

export const state = {
  toastData: [],
  toastLength: 0
}

export const getters = {}

export const mutations = {
  setToast(state, data) {
    state.toastData.push(data)
    state.toastLength++
  }
}

export const actions = {
  async displayToast({ getters, commit }, data) {
    commit('setToast', data)
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
