var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "Modal",
        { attrs: { modalId: "edit-fuel-consumption-modal", size: "modal-lg" } },
        [
          _c(
            "ModalHeader",
            {
              attrs: {
                title: _vm.$t("xFuelConsumption"),
                icon: "far fa-meter-droplet colorOrange mr-1",
                "data-test-hook": "ModalTitle"
              }
            },
            [
              _c(
                "button",
                {
                  staticClass: "btn btn-icon-button btn-icon-button--gray",
                  attrs: {
                    "data-test-hook": "edit-fuel-consumption-modal-close-button"
                  },
                  on: {
                    click: function($event) {
                      _vm.saving
                        ? null
                        : _vm.closeModal("edit-fuel-consumption-modal"),
                        _vm.resetModal()
                    }
                  }
                },
                [
                  _c("i", {
                    staticClass: "fas fa-times",
                    attrs: {
                      "data-test-hook":
                        "edit-fuel-consumption-modal-close-button-icon"
                    }
                  })
                ]
              )
            ]
          ),
          _c(
            "ModalBody",
            [
              _c("div", { staticClass: "row mb-3" }, [
                _c(
                  "div",
                  {
                    staticClass: "col",
                    attrs: {
                      "data-test-hook": "edit-fuel-consumption-modal-body-text"
                    }
                  },
                  [
                    _c("p", {
                      domProps: {
                        innerHTML: _vm._s(_vm.$t("xWhichDevicesConsumeFuel"))
                      }
                    })
                  ]
                )
              ]),
              _vm._l(_vm.selectedTankUtilFuelUses, function(type, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "row mb-2 align-item-center mr-2"
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "col align-item-center",
                        attrs: {
                          "data-test-hook":
                            "FuelUse-" +
                            _vm.removeExtraChars(_vm.$t(type.name)) +
                            "-Header"
                        }
                      },
                      [_vm._v(" " + _vm._s(_vm.$t(type.name)) + " ")]
                    ),
                    _c(
                      "div",
                      { staticClass: "cols-auto" },
                      [
                        _c(
                          "b-button",
                          {
                            staticClass: "mr-2",
                            attrs: {
                              pill: "",
                              variant: "outline-primary",
                              pressed: type.selected != null && type.selected,
                              "data-test-hook":
                                "FuelUse-" +
                                _vm.removeExtraChars(_vm.$t(type.name)) +
                                "-YesButton"
                            },
                            on: {
                              click: function($event) {
                                return _vm.addTag(index, true)
                              }
                            }
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("xYes")) + " ")]
                        ),
                        _c(
                          "b-button",
                          {
                            attrs: {
                              pill: "",
                              variant: "outline-primary",
                              pressed: type.selected != null && !type.selected,
                              "data-test-hook":
                                "FuelUse-" +
                                _vm.removeExtraChars(_vm.$t(type.name)) +
                                "-NoButton"
                            },
                            on: {
                              click: function($event) {
                                return _vm.addTag(index, false)
                              }
                            }
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("xNo")) + " ")]
                        )
                      ],
                      1
                    )
                  ]
                )
              })
            ],
            2
          ),
          _c(
            "ModalFooter",
            [
              _c(
                "b-button",
                {
                  attrs: {
                    variant: "outline-warning",
                    disabled: _vm.saving,
                    "data-test-hook":
                      "edit-fuel-consumption-modal-cancel-button"
                  },
                  on: {
                    click: function($event) {
                      _vm.closeModal("edit-fuel-consumption-modal"),
                        _vm.resetModal()
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("xCancel")) + " ")]
              ),
              _c(
                "b-button",
                {
                  attrs: {
                    variant: "primary",
                    disabled: !_vm.userCanSave() || _vm.saving,
                    "data-test-hook": "edit-fuel-consumption-modal-save-button"
                  },
                  on: {
                    click: function($event) {
                      return _vm.updateTank()
                    }
                  }
                },
                [
                  !_vm.saving
                    ? _c("span", [_vm._v(_vm._s(_vm.$t("xSave")))])
                    : _c("span", [_c("b-spinner")], 1)
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }