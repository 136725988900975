var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.virtualPowerPlantEnabled(_vm.currentAppConfig) &&
    _vm.currentApparatusDetails.enrolledInVpp
    ? _c(
        "div",
        [
          _vm.eventsLoading
            ? _c(
                "div",
                { staticClass: "d-flex justify-content-center mb-3 pt-4" },
                [
                  _c("span", {
                    staticClass: "spinner-border text-warning",
                    staticStyle: { width: "5rem", height: "5rem" },
                    attrs: { label: "Spinning" }
                  })
                ]
              )
            : !_vm.events
            ? _c("div", { staticClass: "mt-3 ml-md-2" }, [
                _vm._m(0),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col text-center" }, [
                    _c(
                      "h1",
                      { attrs: { "data-test-hook": "vppNoEventsTitle" } },
                      [_vm._v(" " + _vm._s(_vm.$t("xNoEvents")) + " ")]
                    ),
                    _c(
                      "p",
                      { attrs: { "data-test-hook": "vppNoEventsBody" } },
                      [_vm._v(_vm._s(_vm.$t("xCurrentlyNoEventHistory")))]
                    )
                  ])
                ])
              ])
            : _c("div", { staticClass: "mt-2 ml-md-2" }, [
                _c("div", { staticClass: "row no-gutters mb-2" }, [
                  _c(
                    "div",
                    {
                      staticClass: "col",
                      attrs: { "data-test-hook": "vppTitleLine" }
                    },
                    [
                      _c("h1", { staticClass: "mb-0 pb-0" }, [
                        _c("i", {
                          staticClass: "far fa-plug colorOrange mr-1"
                        }),
                        _vm._v(" " + _vm._s(_vm.$t("xVirtualPowerPlant")))
                      ])
                    ]
                  )
                ]),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col" }, [
                    _c(
                      "div",
                      {
                        staticClass: "card",
                        attrs: { "body-class": "no-padding" }
                      },
                      [
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col" }, [
                            _c(
                              "div",
                              { staticClass: "list-group list-group-flush" },
                              _vm._l(_vm.events, function(event, index) {
                                return _c(
                                  "div",
                                  {
                                    key: index,
                                    staticClass: "list-group-item"
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "row align-items-center" },
                                      [
                                        _c("div", { staticClass: "col" }, [
                                          _c("div", { staticClass: "row" }, [
                                            _c("div", { staticClass: "col" }, [
                                              _c(
                                                "h3",
                                                {
                                                  attrs: {
                                                    "data-test-hook":
                                                      "vppEventName" + index
                                                  }
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass: "mr-2",
                                                    class: _vm.getEventIconClass(
                                                      event
                                                    )
                                                  }),
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.getEventText(event)
                                                    ) + " "
                                                  )
                                                ]
                                              )
                                            ])
                                          ]),
                                          _c("div", { staticClass: "row" }, [
                                            _c(
                                              "div",
                                              { staticClass: "col ml-4" },
                                              [
                                                _c(
                                                  "p",
                                                  {
                                                    attrs: {
                                                      "data-test-hook":
                                                        "vppOutOutInProgressLine" +
                                                        index
                                                    }
                                                  },
                                                  [
                                                    _vm.optOutInProgress(event)
                                                      ? _c(
                                                          "i",
                                                          {
                                                            staticClass:
                                                              "red-600"
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "x15MinuteWarning"
                                                                )
                                                              )
                                                            )
                                                          ]
                                                        )
                                                      : _vm._e()
                                                  ]
                                                ),
                                                _c(
                                                  "p",
                                                  {
                                                    attrs: {
                                                      "data-test-hook":
                                                        "vppEventDateLine" +
                                                        index
                                                    }
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "fal fa-calendar-check mr-2"
                                                    }),
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.displayShortDateTimeWithTZ(
                                                            event.start
                                                          )
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "p",
                                                  {
                                                    attrs: {
                                                      "data-test-hook":
                                                        "vppEventDurationLine" +
                                                        index
                                                    }
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "fal fa-clock mr-2"
                                                    }),
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm._f("formatLabel")(
                                                            _vm.$t("xDuration")
                                                          )
                                                        ) +
                                                        " " +
                                                        _vm._s(event.duration) +
                                                        " hour"
                                                    ),
                                                    event.duration > 1 ||
                                                    event.duration === 0
                                                      ? _c("span", [
                                                          _vm._v("s")
                                                        ])
                                                      : _vm._e()
                                                  ]
                                                )
                                              ]
                                            ),
                                            _vm.showOptOutButton(event)
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "col ml-4 col-12 col-md-auto"
                                                  },
                                                  [
                                                    _c(
                                                      "button",
                                                      {
                                                        staticClass:
                                                          "btn btn-outline-warning",
                                                        attrs: {
                                                          type: "button",
                                                          disabled: _vm.disableOptOutButton(
                                                            event
                                                          ),
                                                          "data-test-hook":
                                                            "vppOptOutBtn"
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.openOptOutModal(
                                                              event
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "fas fa-minus-octagon"
                                                        }),
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "xOptOutOfEvent"
                                                              )
                                                            ) +
                                                            " "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              : _vm._e()
                                          ])
                                        ])
                                      ]
                                    )
                                  ]
                                )
                              }),
                              0
                            )
                          ])
                        ])
                      ]
                    )
                  ])
                ])
              ]),
          _c(
            "Modal",
            { attrs: { modalId: "confirm-event-opt-out", size: "modal-lg" } },
            [
              _c(
                "ModalHeader",
                {
                  attrs: {
                    title: _vm.$t("xAreYouSure"),
                    icon: "fas fa-exclamation-triangle colorOrange mr-1"
                  }
                },
                [
                  _c("div", { staticClass: "col-auto float-right" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-button--gray btn-icon-button",
                        attrs: { type: "button" },
                        on: {
                          click: function($event) {
                            return _vm.resetModal()
                          }
                        }
                      },
                      [_c("i", { staticClass: "fas fa-times" })]
                    )
                  ])
                ]
              ),
              _c("ModalBody", [
                _c("div", { staticClass: "row mt-3" }, [
                  _c("div", { staticClass: "col" }, [
                    _vm._v(
                      " " + _vm._s(_vm.$t("xAreYouSureYouWantToOptOut")) + " "
                    )
                  ])
                ]),
                _c("div", { staticClass: "row mt-2 checkbox-row no-gutters" }, [
                  _c("div", { staticClass: "col" }, [
                    _c(
                      "div",
                      { staticClass: "custom-control custom-checkbox" },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.confirmOptOut,
                              expression: "confirmOptOut"
                            }
                          ],
                          staticClass: "custom-control-input vpp-checkbox",
                          attrs: { type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(_vm.confirmOptOut)
                              ? _vm._i(_vm.confirmOptOut, null) > -1
                              : _vm.confirmOptOut
                          },
                          on: {
                            change: function($event) {
                              var $$a = _vm.confirmOptOut,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    (_vm.confirmOptOut = $$a.concat([$$v]))
                                } else {
                                  $$i > -1 &&
                                    (_vm.confirmOptOut = $$a
                                      .slice(0, $$i)
                                      .concat($$a.slice($$i + 1)))
                                }
                              } else {
                                _vm.confirmOptOut = $$c
                              }
                            }
                          }
                        }),
                        _vm._v(" " + _vm._s(_vm.$t("xCancelVppEvent")) + " ")
                      ]
                    )
                  ])
                ]),
                _c("div", { staticClass: "row mt-5" }, [
                  _c("div", { staticClass: "col" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-block btn-outline-warning",
                        attrs: { type: "button" },
                        on: {
                          click: function($event) {
                            return _vm.resetModal()
                          }
                        }
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("xNoCancel")) + " ")]
                    )
                  ]),
                  _c("div", { staticClass: "col" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-warning btn-block",
                        attrs: {
                          type: "button",
                          disabled:
                            !_vm.confirmOptOut || _vm.sendingOptOutCommand
                        },
                        on: {
                          click: function($event) {
                            return _vm.optOutOfEvent()
                          }
                        }
                      },
                      [
                        _vm._v(" " + _vm._s(_vm.$t("xYesImSure")) + " "),
                        _vm.sendingOptOutCommand
                          ? _c("span", { staticClass: "spinner-border" })
                          : _vm._e()
                      ]
                    )
                  ])
                ])
              ])
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col text-center" }, [
        _c(
          "span",
          { staticStyle: { "font-size": "3rem", color: "var(--carbon-600)" } },
          [
            _c("i", {
              staticClass: "fal fa-plug",
              attrs: { "data-test-hook": "vppNoEventsIcon" }
            })
          ]
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }