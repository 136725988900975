<template>
  <div>
    <b-modal id="feedback-modal" centered hide-footer @hidden="closeModal()">
      <template #modal-header>
        <b-row class="w-100" align-v="center" no-gutters>
          <b-col>
            <h2 class="modal-title">
              <i class="fas fa-lightbulb colorOrange mr-1" />
              {{ $t('xSendFeedback') }}
            </h2>
          </b-col>
          <b-col cols="auto" class="float-right">
            <b-button
              class="btn-icon-button--gray"
              variant="icon-button"
              @click="closeModal()"
              data-test-hook="x-button-feedback-modal"
            >
              <i class="fas fa-times" />
            </b-button>
          </b-col>
        </b-row>
      </template>
      <div v-if="!feedbackSent">
        <b-row>
          <b-col>
            <p>{{ $t('xPleaseProvideADetailedMessage') }}</p>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col>
            <b-form-textarea v-model="message" class="feedback-message" rows="5" maxlength="1000" no-resize>
            </b-form-textarea>
          </b-col>
        </b-row>
        <b-row class="mt-3">
          <b-col>
            <b-form-checkbox
              class="font-weight-bold"
              v-model="userGroupParticipation"
              data-test-hook="be-part-of-a-group-checkbox-feedback-modal"
            >
              {{ $t('xIWouldLikeToBePartOfAUserFeedbackGroup') }}
            </b-form-checkbox>
          </b-col>
        </b-row>
        <b-row class="mt-5">
          <b-col class="d-flex justify-content-end">
            <b-button
              variant="primary"
              :disabled="!message"
              @click="sendFeedback()"
              data-test-hook="submit-button-feedback-modal"
            >
              {{ $t('xSubmit') }}
              <b-spinner class="ml-2" v-if="sendingFeedback" />
            </b-button>
          </b-col>
        </b-row>
      </div>
      <div v-else>
        <b-row class="text-center mt-2">
          <b-col>
            <h1 class="mb-0 pb-0">{{ $t('xFeedbackSent') }}</h1>
          </b-col>
        </b-row>
        <b-row class="text-center mt-3">
          <b-col class="message-text">
            {{ $t('xWeTrulyAppreciateYouTaking') }}
          </b-col>
        </b-row>
        <b-row class="mt-3">
          <b-col>
            <b-button variant="primary" block @click="closeModal()" data-test-hook="got-it-button-feedback-modal">
              <i class="fas fa-thumbs-up" />
              {{ $t('xGotIt') }}
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  data() {
    return {
      message: null,
      userGroupParticipation: false,
      deviceTypes: 'Web',
      appVersion: 'Web',
      feedbackSent: false,
      sendingFeedback: false
    }
  },
  computed: {
    ...mapState({
      accountProfile: state => state.account.accountProfile
    })
  },
  methods: {
    sendFeedback() {
      this.sendingFeedback = true
      this.$store.dispatch('sendFeedback', {
        orgId: this.accountProfile && this.accountProfile.organizationId ? this.accountProfile.organizationId : null,
        message: this.message,
        userGroupParticipation: this.userGroupParticipation,
        deviceTypes: this.deviceTypes,
        appVersion: this.appVersion
      })
      this.feedbackSent = true
      this.sendingFeedback = false
      this.$appInsights.trackEvent({
        name: 'feedback-submitted',
        properties: {
          orgId: this.accountProfile && this.accountProfile.organizationId ? this.accountProfile.organizationId : null,
          userId: this.accountProfile && this.accountProfile.userId ? this.accountProfile.userId : null,
          userWantsToParticipate: this.userGroupParticipation,
          deviceTypes: this.deviceTypes,
          appVersion: this.appVersion
        }
      })
    },
    closeModal() {
      this.$bvModal.hide('feedback-modal')
      this.message = null
      this.userGroupParticipation = false
      this.sendingFeedback = false
      this.feedbackSent = false
    }
  }
}
</script>
<style scoped>
.feedback-message {
  max-height: 277px;
  max-width: 594px;
}
</style>
