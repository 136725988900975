var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.modalId
    ? _c(
        "div",
        { staticClass: "modal", attrs: { id: _vm.modalId, role: "dialog" } },
        [
          _c(
            "div",
            {
              staticClass: "modal-dialog modal-dialog-centered",
              class: _vm.size ? _vm.size : ""
            },
            [
              _c(
                "div",
                { staticClass: "modal-content" },
                [_vm._t("default")],
                2
              )
            ]
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }