var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.cdmaWarning
        ? _c(
            "div",
            {
              staticClass: "pt-3",
              on: {
                click: function($event) {
                  return _vm.trackAppInsightsEvent("cdma-banner-click")
                }
              }
            },
            [
              _c(
                "b-link",
                {
                  staticStyle: { "text-decoration": "none !important" },
                  attrs: { href: _vm.cdmaWarning.link, target: "_blank" }
                },
                [
                  _c(
                    "b-alert",
                    {
                      staticClass: "hoverPointer mb-0",
                      attrs: { variant: "warning", show: "" }
                    },
                    [
                      _c(
                        "b-row",
                        [
                          _c("b-col", [
                            _c("h2", [
                              _c("i", {
                                staticClass:
                                  "fas fa-exclamation-triangle banner-category-warning"
                              }),
                              _vm._v(" " + _vm._s(_vm.cdmaWarning.title) + " ")
                            ]),
                            _c("p", [_vm._v(_vm._s(_vm.cdmaWarning.message))]),
                            _c("p", { staticClass: "mb-0 pb-0" }, [
                              _vm._v(
                                " " + _vm._s(_vm.cdmaWarning.linkText) + " "
                              ),
                              _c("i", { staticClass: "fas fa-arrow-right" })
                            ])
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.currentAppConfig &&
      _vm.currentAppConfig.featureFlags &&
      _vm.currentAppConfig.featureFlags[_vm.billingMigrationFeatureFlag]
        ? _c("b-alert", { attrs: { show: "", variant: "warning" } }, [
            _vm._v(" " + _vm._s(_vm.$t("xBillingMaintenanceMessage")) + " ")
          ])
        : _vm._e(),
      _c(
        "div",
        {
          on: {
            click: function($event) {
              return _vm.$bvModal.show("banner-message-modal")
            }
          }
        },
        [
          _vm.showBannerMessage(_vm.bannerMessage)
            ? _c(
                "b-alert",
                {
                  staticClass: "hoverPointer",
                  attrs: {
                    show: "",
                    variant: _vm.getBannerVariant(_vm.bannerMessage)
                  }
                },
                [
                  _c("i", {
                    class: _vm.getBannerIconClassAndColor(_vm.bannerMessage)
                  }),
                  _vm._v(" " + _vm._s(_vm.bannerMessage.BannerMessage) + " "),
                  _c("strong", [
                    _vm._v(" " + _vm._s(_vm.$t("xLearnMore")) + " "),
                    _c("i", { staticClass: "fas fa-arrow-right" })
                  ])
                ]
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "py-4" },
        [
          _c(
            "b-col",
            { staticClass: "mb-3 d-flex pr-md-0", attrs: { lg: "5", xl: "4" } },
            [
              _c(
                "b-card",
                { staticClass: "w-100" },
                [
                  _c(
                    "b-row",
                    [
                      _c("b-col", [
                        _c("h2", [_vm._v(_vm._s(_vm.$t("xHowCanWeHelpYou")))])
                      ])
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    [
                      _c("b-col", [
                        _vm._v(" " + _vm._s(_vm.$t("xHereForYou")) + " ")
                      ])
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "my-3" },
                    [
                      _c(
                        "b-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c(
                            "b-link",
                            {
                              staticClass:
                                "body-text-color d-inline-block mr-2",
                              attrs: { size: "lg" }
                            },
                            [
                              _c("i", {
                                staticClass: "fas fa-phone-alt colorOrange"
                              }),
                              _vm._v(" (855) 436-8439 (USA) ")
                            ]
                          ),
                          _vm._v(" | "),
                          _c(
                            "b-link",
                            {
                              staticClass:
                                "body-text-color d-inline-block ml-2",
                              attrs: { size: "lg" }
                            },
                            [_vm._v(" (844) 843-9436 (Canada) ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "my-3" },
                    [
                      _c(
                        "b-col",
                        [
                          _c(
                            "b-link",
                            {
                              staticClass: "body-text-color d-block",
                              attrs: {
                                href:
                                  "mailto:Support@MobileLinkGen.com?subject=Mobile%20Link%20Support%20Request",
                                size: "lg",
                                "text-decoration-none": ""
                              }
                            },
                            [
                              _c("i", {
                                staticClass: "fas fa-envelope colorOrange"
                              }),
                              _vm._v(" support@mobilelinkgen.com ")
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "my-3" },
                    [
                      _c(
                        "b-col",
                        [
                          _c(
                            "b-link",
                            {
                              staticClass: "d-block body-text-color",
                              attrs: {
                                href: "https://support.generac.com/mobilelink",
                                target: "_blank"
                              }
                            },
                            [
                              _c("i", {
                                staticClass:
                                  "fas fa-external-link fa-lg colorOrange"
                              }),
                              _vm._v(
                                " " + _vm._s(_vm.$t("xSupportCenter")) + " "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "mt-5" },
                    [
                      _c("b-col", [
                        _c("h2", { staticClass: "body-text-color" }, [
                          _vm._v(" " + _vm._s(_vm.$t("xSuggestions")) + " ")
                        ])
                      ])
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "mt-2 mb-3" },
                    [
                      _c(
                        "b-col",
                        [
                          _c(
                            "b-button",
                            {
                              attrs: { variant: "outline-warning" },
                              on: {
                                click: function($event) {
                                  return _vm.$bvModal.show("feedback-modal")
                                }
                              }
                            },
                            [
                              _c("i", { staticClass: "fas fa-lightbulb" }),
                              _vm._v(
                                " " + _vm._s(_vm.$t("xSendFeedback")) + " "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }