var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "card", attrs: { "header-bg-variant": "white" } },
    [
      _c("div", { staticClass: "card-header" }, [
        _c("div", { staticClass: "align-items-center row" }, [
          _c("div", { staticClass: "col" }, [
            _c("h1", { staticClass: "mb-0 pb-0" }, [
              _c("i", { staticClass: "far fa-user-circle colorOrange" }),
              _c(
                "span",
                {
                  staticClass: "ml-2",
                  attrs: {
                    "data-test-hook":
                      "display-prefs-card-title-account-settings"
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("xDisplayPreferences")) + " ")]
              )
            ])
          ]),
          _c("div", { staticClass: "col-auto" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-icon-button",
                attrs: {
                  "data-test-hook": "open-button-display-preferences-card"
                },
                on: {
                  click: function($event) {
                    return _vm.openModal("EditPreferencesModal")
                  }
                }
              },
              [_c("i", { staticClass: "fas fa-pencil" })]
            )
          ])
        ])
      ]),
      _c(
        "div",
        { staticClass: "card-body" },
        [
          _c("div", { staticClass: "d-flex" }, [
            _vm._m(0),
            _c("div", [
              _c(
                "strong",
                {
                  staticClass: "d-block pb-1",
                  attrs: {
                    "data-test-hook":
                      "display-prefs-distance-unit-title-account-settings"
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("xDistanceUnits")) + " ")]
              ),
              _c(
                "p",
                {
                  staticClass: "text-capitalize",
                  attrs: {
                    "data-test-hook":
                      "display-prefs-selected-distance-unit-account-settings"
                  }
                },
                [_vm._v(" " + _vm._s(_vm.distanceUom) + " ")]
              )
            ])
          ]),
          _c("div", { staticClass: "d-flex mt-3" }, [
            _vm._m(1),
            _c("div", [
              _c(
                "strong",
                {
                  staticClass: "d-block pb-1",
                  attrs: {
                    "data-test-hook":
                      "display-prefs-temp-unit-title-account-settings"
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("xTemperatureUnits")) + " ")]
              ),
              _c(
                "p",
                {
                  staticClass: "text-capitalize",
                  attrs: {
                    "data-test-hook":
                      "display-prefs-selected-temp-unit-account-settings"
                  }
                },
                [_vm._v(" " + _vm._s(_vm.temperatureUom) + " ")]
              )
            ])
          ]),
          _c("div", { staticClass: "d-flex mt-3" }, [
            _vm._m(2),
            _c("div", [
              _c(
                "strong",
                {
                  staticClass: "d-block pb-1",
                  attrs: {
                    "data-test-hook":
                      "display-prefs-liquid-unit-title-account-settings"
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("xLiquidUnits")) + " ")]
              ),
              _c(
                "p",
                {
                  staticClass: "text-capitalize",
                  attrs: {
                    "data-test-hook":
                      "display-prefs-selected-liquid-unit-account-settings"
                  }
                },
                [_vm._v(" " + _vm._s(_vm.liquidUom) + " ")]
              )
            ])
          ]),
          _c("EditPreferencesModal")
        ],
        1
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text-center pr-1" }, [
      _c("i", { staticClass: "far fa-fw fa-ruler-combined colorOrange" })
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text-center pr-1" }, [
      _c("i", { staticClass: "far fa-fw fa-thermometer-half colorOrange" })
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text-center pr-1" }, [
      _c("i", { staticClass: "far fa-fw fa-water colorOrange" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }