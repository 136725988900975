var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "d-flex download-app-page" }, [
    _c(
      "div",
      {
        staticClass:
          "d-flex justify-content-center flex-column col-md-7 app-details"
      },
      [
        _c("img", {
          attrs: {
            width: "195px",
            src: require("@/assets/images/MobileLink_Logo_2023_COLOR.svg"),
            alt: "Mobile Link Logo"
          }
        }),
        _c("h1", [_vm._v(_vm._s(_vm.$t("xDownloadOurApp")))]),
        _c("p", [_vm._v(_vm._s(_vm.$t("xDownloadOurAppDesc")))]),
        _c(
          "div",
          {
            staticClass:
              "d-flex align-items-center justify-content-flex-start mt-3"
          },
          [
            _vm.getOs() != "Android"
              ? _c(
                  "a",
                  {
                    attrs: {
                      href:
                        "https://apps.apple.com/us/app/mobile-link-for-generators/id829129497?itsct=apps_box_badge&itscg=30200"
                    }
                  },
                  [
                    _c("img", {
                      staticStyle: { height: "45px" },
                      attrs: {
                        src:
                          "https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&releaseDate=1396137600&h=ec3911bf38aa4d3ad9236dfe163027a8",
                        alt: _vm.$t("xDownloadOnTheAppStore")
                      }
                    })
                  ]
                )
              : _vm._e(),
            _vm.getOs() != "iOS"
              ? _c(
                  "a",
                  {
                    attrs: {
                      href:
                        "https://play.google.com/store/apps/details?id=com.generac.standbystatus&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                    }
                  },
                  [
                    _c("img", {
                      staticStyle: { height: "65px" },
                      attrs: {
                        src:
                          "https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png",
                        alt: _vm.$t("xGetItOnGooglePlay")
                      }
                    })
                  ]
                )
              : _vm._e()
          ]
        )
      ]
    ),
    _c("div", { staticClass: "app-screenshot col-md-5" }, [
      _c("img", {
        staticClass: "app-screenshot--images",
        attrs: {
          src: require("@/assets/images/app-screenshot_1x.png"),
          srcset:
            require("@/assets/images/app-screenshot_1x.png") +
            " 1x, " +
            require("@/assets/images/app-screenshot_2x.png") +
            " 2x, " +
            require("@/assets/images/app-screenshot_3x.png") +
            " 3x, ",
          alt: "Mobile Link Dashboard"
        }
      }),
      _c("img", {
        staticClass: "mt-3",
        attrs: {
          width: "170px",
          src: require("@/assets/images/powered-by-generac-white.svg"),
          alt: "Generac Logo"
        }
      })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }