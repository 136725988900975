<template>
  <div class="mt-2 ml-md-2">
    <div v-if="maintenanceScheduleLoading" class="d-flex justify-content-center mb-3 pt-4">
      <div class="spinner-border ml-2 text-warning" style="width: 5rem; height: 5rem;" label="Spinning"></div>
    </div>
    <div v-else>
      <div class="row no-gutters mb-2" data-test-hook="maintenance-page-header">
        <div class="col">
          <h1 class="mb-0 pb-0">
            <i class="far fa-toolbox colorOrange pr-2"></i>
            {{ $t('xMaintenance') }}
          </h1>
        </div>
      </div>
      <div class="card">
        <div class="card-body" v-if="currentApparatusDetails.apparatusClassification != portableGenApparatusType">
          <div class="row align-items-center">
            <div class="col d-flex align-items-center">
              <h2 class="mb-0 pb-0 mr-2 d-inline-block" data-test-hook="maintenance-sched-header">
                {{ $t('xMaintenanceSchedule') }}
              </h2>
              <i id="schedule-tool-tip" class="fas fa-question-circle hoverPointer" />
              <b-popover target="schedule-tool-tip" triggers="hover" placement="top">
                {{ $t('xMaintenanceScheduleToolTip') }}
              </b-popover>
            </div>
          </div>
        </div>
        <div v-if="maintenanceSchedule && maintenanceSchedule.length > 0 && maintenanceSchedule[0]?.date && maintenanceSchedule[0]?.hours" data-test-hook="maintenance-schedule">
          <div class="row" v-if="maintenanceSchedule.length > 0">
            <div class="col">
              <div class="list-group-flush">
                <div
                  class="list-group-item"
                  style="padding-left:5px"
                  v-for="(schedule, index) in maintenanceSchedule"
                  :key="index"
                >
                  <div class="col-12 col-sm-auto" v-if="schedule.hours || schedule.date">
                    <span v-if="schedule.date">
                      <i class="fal fa-calendar-star fa-sm pr-2" />{{ displayShortDate(schedule.date) }}
                    </span>
                    <span v-if="schedule.hours">/&nbsp;{{ schedule.hours }} {{ $t('xRunHours') }} </span>
                    <h5>{{ schedule.type }}</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row" v-else>
          <div class="col">
            <div class="card-body py-0">
              {{ $t('xNoMaintenanceSchedule') }}
            </div>
          </div>
        </div>
        <div v-if="subscriptionActive(subscription)">
          <div class="card-body pb-0" data-test-hook="maintenance-history-headers">
            <div class="row mb-2 align-items-center">
              <div class="col">
                <h2 class="mb-0">{{ $t('xMaintenanceHistory') }}</h2>
              </div>
              <div class="cols-auto">
                <button
                  type="button"
                  id="add-maintenance-button"
                  class="btn btn-icon-button"
                  @click="openAddRecordModal()"
                  data-test-hook="add-maintenance-button-maintenance"
                >
                  <i class="fas fa-calendar-plus" />
                </button>
                <b-popover
                  target="add-maintenance-button"
                  triggers="hover"
                  placement="bottom"
                  data-test-hook="add-record-popover-maintenance"
                >
                  {{ $t('xAddRecord') }}
                </b-popover>
                <button
                  id="export-maintenance-button"
                  type="button"
                  class="btn btn-icon-button ml-3"
                  @click="
                    openModal('selectMaintenanceHistoryDateRange'),
                      trackAppInsightsEvent('open-export-maintenance-history-modal', {
                        page: 'Maintenance',
                        organizationId: accountProfile.organizationId,
                        userId: accountProfile.userId,
                        apparatusId: $route.params.apparatusId
                      })
                  "
                  :disabled="!maintenanceHistory || maintenanceHistory.length == 0"
                  data-test-hook="export-maintenance-button-maintenance"
                >
                  <i class="fas fa-file-export colorOrange" />
                </button>
                <b-popover
                  target="export-maintenance-button"
                  triggers="hover"
                  placement="bottom"
                  data-test-hook="export-maintenance-popover-maintenance"
                >
                  {{ $t('xExport') }}
                </b-popover>
              </div>
            </div>
          </div>
          <div class="row" v-if="maintenanceHistory && maintenanceHistory.length > 0">
            <div class="col">
              <div class="list-group-flush">
                <div
                  class="list-group-item"
                  v-for="(history, index) in maintenanceHistory"
                  :key="index"
                  :id="index"
                  :data-test-hook="'history-record-maintenance' + index"
                >
                  <div class="row no-gutters mb-2">
                    <div class="col">
                      <p class="font-weight-light text-small mb-2">
                        <span v-if="history.maintenanceType == '0'" :data-test-hook="'userRecord' + index">
                          <i class="far fa-user fa-sm" />
                          {{ 'xUserRecord' | formatLabel }}
                        </span>
                        <span :data-test-hook="'maintenanceDate' + index">{{
                          $moment(history.properties.eventOccurred).format($t('xShortDate'))
                        }}</span>
                        <span v-if="history.properties.runHours" :data-test-hook="'runHours' + index">
                          / {{ history.properties.runHours }} {{ $t('xRunHours') }}
                        </span>
                        <span v-if="history.properties.totalCost" :data-test-hook="'totalCost' + index">
                          / {{ history.properties.totalCost | formatCurrency }}
                        </span>
                      </p>
                      <p>
                        <strong><i class="far fa-tools mr-2" /> {{ history.title }}</strong>
                      </p>
                      <p v-if="history.properties.description" class="mt-2">
                        {{ history.properties.description }}
                      </p>
                    </div>
                    <button
                      v-if="history.maintenanceType == '0'"
                      type="button"
                      class="btn btn-icon-button"
                      @click="showEditMaintenanceModal(history)"
                      :data-test-hook="'pen-button-maintenance' + index"
                    >
                      <i class="fas fa-pencil" />
                    </button>
                    <button
                      v-if="history.maintenanceType == '0'"
                      type="button"
                      class="btn btn-icon-button"
                      @click="showDeleteMaintenanceModal(history)"
                      :data-test-hook="'trash-button-maintenance' + index"
                    >
                      <i class="fas fa-trash-alt" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row" v-else>
            <div class="col">
              <div class="card-body pt-0 pb-3">
                {{ $t('xNoMaintenanceHistory') }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal modalId="deleteMaintenanceRecordModal" size="modal-lg">
        <ModalHeader :title="$t('xDeleteMaintenanceRecord')" icon="fas fa-calendar-plus colorOrange mr-1">
          <div class="cols-auto">
            <button
              type="button"
              class="btn btn-icon-button"
              @click="hideDeleteMaintenanceRecordModal()"
              data-test-hook="x-button-deleteMaintenanceRecordModal-maintenance"
            >
              <i class="fas fa-times" />
            </button>
          </div>
        </ModalHeader>
        <ModalBody>
          <div class="row">
            <div class="col">
              {{ $t('xDeleteMaintenanceMessage') }}
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            class="btn btn-outline-warning"
            @click="closeModal('deleteMaintenanceRecordModal')"
            data-test-hook="cancel-button-deleteMaintenanceRecordModal-maintenance"
          >
            {{ $t('xCancel') }}
          </button>
          <button
            type="button"
            class="btn btn-warning"
            @click="deleteMaintenanceRecord()"
            data-test-hook="yes-im-sure-button-deleteMaintenanceRecordModal-maintenance"
          >
            {{ $t('xYesImSure') }}
            <span class="spinner-border spinner-border-sm" v-if="deletingMaintenanceRecord"></span>
          </button>
        </ModalFooter>
      </Modal>
      <AddMaintenanceModal
        :editedMaintenanceRecord="editedMaintenanceRecord"
        :disableCancel="false"
        :editingRecord="editingRecord"
        :sendClearMaintenance="false"
        :apparatus="currentApparatusDetails"
        @maintenanceHistoryChanged="updateMaintenanceHistory"
      />
    </div>
    <Modal modalId="selectMaintenanceHistoryDateRange" size="modal-md">
      <ModalHeader :title="$t('xExportMaintenanceHistory')" icon="fas fa-file-export colorOrange mr-1">
        <div class="cols-auto float-right">
          <button
            type="button"
            class="btn btn-icon-button"
            @click="hideSelectMaintenanceHistoryDateRangeModal()"
            data-test-hook="x-button-selectMaintenanceHistoryDateRange-maintenance"
          >
            <i class="fas fa-times colorOrange" />
          </button>
        </div>
      </ModalHeader>
      <ModalBody>
        <div class="row mb-4">
          <div class="col">
            <strong>{{ 'xSelectYourHistoryRange' | formatLabel }}</strong>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="list-group list-group-flush">
              <div class="custom-control custom-radio ml-3">
                <input
                  id="months6"
                  class="custom-control-input"
                  type="radio"
                  v-model="selectedDateRange"
                  value="Months6"
                  data-test-hook="6-months-radio-selectMaintenanceHistoryDateRange-maintenance"
                />
                <label class="custom-control-label" for="months6">
                  {{ $t('x6Months') }}
                </label>
              </div>
              <div>
                <hr />
              </div>
              <div class="custom-control custom-radio ml-3">
                <input
                  id="years1"
                  class="custom-control-input"
                  type="radio"
                  v-model="selectedDateRange"
                  value="Years1"
                  data-test-hook="1-year-radio-selectMaintenanceHistoryDateRange-maintenance"
                />
                <label class="custom-control-label" for="years1">
                  {{ $t('x1Year') }}
                </label>
              </div>
              <div>
                <hr />
              </div>
              <div class="custom-control custom-radio ml-3">
                <input
                  id="years2"
                  class="custom-control-input"
                  type="radio"
                  v-model="selectedDateRange"
                  value="Years2"
                  data-test-hook="2-years-radio-selectMaintenanceHistoryDateRange-maintenance"
                />
                <label class="custom-control-label" for="years2">
                  {{ $t('x2Years') }}
                </label>
              </div>
              <div>
                <hr />
              </div>
              <div class="custom-control custom-radio ml-3">
                <input
                  id="years3"
                  class="custom-control-input"
                  type="radio"
                  v-model="selectedDateRange"
                  value="Years3"
                  data-test-hook="3-years-radio-selectMaintenanceHistoryDateRange-maintenance"
                />
                <label class="custom-control-label" for="years3">
                  {{ $t('x3Years') }}
                </label>
              </div>
              <div>
                <hr />
              </div>
              <div class="custom-control custom-radio ml-3">
                <input
                  id="all"
                  class="custom-control-input"
                  type="radio"
                  v-model="selectedDateRange"
                  value="All"
                  data-test-hook="all-radio-selectMaintenanceHistoryDateRange-maintenance"
                />
                <label class="custom-control-label" for="all">
                  {{ $t('xAll') }}
                </label>
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <button
          type="button"
          class="btn btn-outline-warning"
          @click="hideSelectMaintenanceHistoryDateRangeModal()"
          data-test-hook="cancel-button-selectMaintenanceHistoryDateRange-maintenance"
        >
          {{ $t('xCancel') }}
        </button>
        <button
          type="button"
          class="btn btn-warning"
          :disabled="downloadingMaintenanceHistory || !selectedDateRange"
          @click="
            exportMaintenanceHistory(),
              trackAppInsightsEvent('download-maintenance-history', {
                page: 'Maintenance',
                organizationId: accountProfile.organizationId,
                userId: accountProfile.userId,
                apparatusId: $route.params.apparatusId,
                dateRange: selectedDateRange
              })
          "
          data-test-hook="download-button-selectMaintenanceHistoryDateRange-maintenance"
        >
          <i class="fas fa-file-csv" />
          {{ $t('xDownload') }}
          <span class="spinner-border spinner-border-sm" v-if="downloadingMaintenanceHistory"></span>
        </button>
      </ModalFooter>
    </Modal>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import * as Constants from '@/constants'
import AddMaintenanceModal from '@/components/ApparatusDetails/AddMaintenanceModal'

export default {
  components: {
    AddMaintenanceModal
  },
  data() {
    return {
      maintenanceSchedule: null,
      maintenanceHistory: null,
      subscription: null,
      selectedMaintenanceTypes: null,
      loadingAdditionalMaintenanceHistory: false,
      pageNumber: 1,
      pageSize: 10,
      maintenanceScheduleLoading: true,
      recordToDelete: null,
      deletingMaintenanceRecord: false,
      editingRecord: false,
      editedMaintenanceRecord: null,
      selectedDateRange: null,
      downloadingMaintenanceHistory: false
    }
  },
  computed: {
    ...mapState({
      currentApparatusMaintenanceSchedule: state => state.apparatus.currentApparatusMaintenanceSchedule,
      accountProfile: state => state.account.accountProfile,
      currentApparatusDetails: state => state.apparatus.currentApparatusDetails
    }),
    portableGenApparatusType() {
      return Constants.PortableGeneratorApparatusType
    }
  },
  methods: {
    updateMaintenanceHistory() {
      this.$store
        .dispatch('getApparatusMaintenanceDetails', {
          apparatusId: this.$route.params.apparatusId,
          pageNumber: 1,
          pageSize: this.pageSize
        })
        .then(response => {
          this.maintenanceHistory = response.data.maintenanceHistory
          this.maintenanceSchedule = response.data.maintenanceSchedule
          this.subscription = response.data.subscription
        })
        .catch(error => {
          this.$store.dispatch('displayToast', {
            title: this.$t('xError'),
            message: this.$t('xFailedToRetrieveMaintenanceHistory'),
            variant: 'danger'
          })
        })
    },
    exportMaintenanceHistory() {
      this.downloadingMaintenanceHistory = true
      let tz = this.$moment.tz.guess().toString()
      this.$store
        .dispatch('exportMaintenanceHistory', {
          apparatusId: this.$route.params.apparatusId,
          dateRange: this.selectedDateRange,
          timeZone: tz
        })
        .then(response => {
          let fileURL = window.URL.createObjectURL(new Blob([response.data]))
          let fileLink = document.createElement('a')
          fileLink.href = fileURL
          fileLink.setAttribute('download', 'maintenanceHistory.csv')
          document.body.appendChild(fileLink)
          fileLink.click()
          this.hideSelectMaintenanceHistoryDateRangeModal()
        })
        .catch(error => {
          this.$store.dispatch('displayToast', {
            title: this.$t('xError'),
            message: this.$t('xNoMaintenanceHistoryForDateRange'),
            variant: 'danger'
          })
        })
        .finally(() => {
          this.downloadingMaintenanceHistory = false
        })
    },
    showEditMaintenanceModal(record) {
      this.editingRecord = true
      this.$appInsights.trackEvent({
        name: 'user-opening-edit-record-modal',
        properties: {
          page: 'maintenance',
          apparatusId: this.$route.params.apparatusId,
          recordId: record.properties.id
        }
      })
      this.editedMaintenanceRecord = {
        dateCompleted: record.properties.eventOccurred,
        totalCost: record.properties.totalCost,
        runHours: record.properties.runHours,
        comments: record.properties.description,
        recordToEditId: record.properties.id,
        maintenanceTagsToAdd: [],
        selectedMaintenanceTypes: []
      }
      this.editedMaintenanceRecord.maintenanceTagsToAdd = record.title.split(', ')
      this.editedMaintenanceRecord.maintenanceTagsToAdd.forEach(element => {
        if (!this.selectedMaintenanceTypes.some(tag => tag.name === element)) {
          this.selectedMaintenanceTypes.push({ name: element, selected: true })
        }
      })
      this.editedMaintenanceRecord.selectedMaintenanceTypes.forEach(element => {
        if (this.editedMaintenanceRecord.maintenanceTagsToAdd.some(tag => tag === element.name)) {
          element.selected = true
        }
      })
      this.openModal('addMaintenanceModal')
    },
    showDeleteMaintenanceModal(record) {
      this.recordToDelete = record
      this.openModal('deleteMaintenanceRecordModal')
      this.$appInsights.trackEvent({
        name: 'user-opening-delete-record-modal',
        properties: {
          page: 'maintenance',
          apparatusId: this.$route.params.apparatusId,
          recordId: record.properties.id
        }
      })
    },
    deleteMaintenanceRecord() {
      this.deletingMaintenanceRecord = true
      this.$store
        .dispatch('deleteApparatusMaintenanceHistory', {
          apparatusId: this.$route.params.apparatusId,
          serviceHistoryId: this.recordToDelete.properties.id
        })
        .then(response => {
          this.$appInsights.trackEvent({
            name: 'deleted-maintenance-record',
            properties: {
              page: 'maintenance',
              apparatusId: this.$route.params.apparatusId,
              recordId: this.recordToDelete.properties.id
            }
          })
          this.closeModal('deleteMaintenanceRecordModal')
          this.$store.dispatch('displayToast', {
            title: this.$t('xSuccess'),
            message: this.$t('xDeletedMaintenanceSuccess'),
            variant: 'success'
          })
          this.updateMaintenanceHistory()
        })
        .catch(error => {
          this.$store.dispatch('displayToast', {
            title: this.$t('xError'),
            message: this.$t('xFailedToDeleteMaintenance'),
            variant: 'danger'
          })
        })
        .finally(() => {
          this.deletingMaintenanceRecord = false
        })
    },
    openAddRecordModal() {
      this.editingRecord = false
      this.openModal('addMaintenanceModal')
    },
    onScroll() {
      if (this.maintenanceHistory) {
        let doc = document.getElementById(this.maintenanceHistory.length - 1)
        if (doc) {
          let rect = doc.getBoundingClientRect()
          if (
            !this.loadingAdditionalMaintenanceHistory &&
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) /* or $(window).height() */ &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */
          ) {
            this.loadingAdditionalMaintenanceHistory = true
            this.pageNumber++
            this.$store
              .dispatch('getApparatusMaintenanceDetails', {
                apparatusId: this.$route.params.apparatusId,
                pageNumber: this.pageNumber,
                pageSize: this.pageSize
              })
              .then(response => {
                if (response.data.maintenanceHistory.length > 0) {
                  this.maintenanceHistory.push(...response.data.maintenanceHistory)
                }
                this.loadingAdditionalMaintenanceHistory = false
              })
              .catch(error => {
                if (error.response.status && error.response.status == 404) {
                  //do nothing
                } else {
                  this.$store.dispatch('displayToast', {
                    title: this.$t('xError'),
                    message: this.$t('xFailedToRetrieveAdditionalMaintenanceHistory'),
                    variant: 'danger'
                  })
                }
                this.loadingAdditionalMaintenanceHistory = false
              })
          }
        }
      }
    },
    hideDeleteMaintenanceRecordModal() {
      this.closeModal('deleteMaintenanceRecordModal')
      recordToDelete = null
    },
    hideSelectMaintenanceHistoryDateRangeModal() {
      this.closeModal('selectMaintenanceHistoryDateRange')
      this.selectedDateRange = null
    }
  },
  mounted() {
    this.selectedMaintenanceTypes = _.clone(Constants.HsbMaintenanceTypes)
    this.$store
      .dispatch('getApparatusMaintenanceDetails', {
        apparatusId: this.$route.params.apparatusId,
        pageNumber: this.pageNumber,
        pageSize: this.pageSize
      })
      .then(response => {
        this.maintenanceHistory = response.data.maintenanceHistory
        this.maintenanceSchedule = response.data.maintenanceSchedule
        this.subscription = response.data.subscription
      })
      .catch(error => {
        this.$store.dispatch('displayToast', {
          title: this.$t('xError'),
          message: this.$t('xFailedToRetrieveMaintenanceHistory'),
          variant: 'danger'
        })
      })
      .finally(() => {
        this.maintenanceScheduleLoading = false
      })
    let container = document.getElementById('childRouteContainer')
    container.addEventListener('scroll', this.onScroll)
  }
}
</script>
