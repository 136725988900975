import Axios from 'axios'
import router from '@/router'
import { vueAppApiBaseAddress } from '@/utils/mixins'

export const state = {}

export const getters = {}

export const mutations = {}

export const actions = {
  async typeAheadSearch({ getters, commit, dispatch }, address) {
    return await Axios.get(vueAppApiBaseAddress() + `v2/Map?query=${encodeURIComponent(address)}`, {
      withCredentials: true
    })
  },
  async typeAheadSearchWithCoords({ getters, commit, dispatch }, address) {
    return await Axios.get(
      vueAppApiBaseAddress() +
        `v2/Map?latitude=${address.latitude}&longitude=${address.longitude}&query=${encodeURIComponent(
          address.address
        )}`,
      {
        withCredentials: true
      }
    )
  },
  async verifyPostalCode({ getters, commit, dispatch }, countryPostalCode) {
    return await Axios.get(
      vueAppApiBaseAddress() +
        `v1/Map/verifyZip?country=${countryPostalCode.country}&query=${countryPostalCode.postalCode}`,
      {
        withCredentials: true
      }
    )
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
