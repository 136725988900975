var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "Modal",
        {
          attrs: {
            modalId: "manage-sub-paid-step-1",
            size: "modal-lg",
            "hide-footer": ""
          }
        },
        [
          _c(
            "ModalHeader",
            {
              attrs: {
                title: _vm.$t("xManageSubscription"),
                icon: "far fa-badge-dollar colorOrange mr-1"
              }
            },
            [
              _c("div", { staticClass: "cols-auto float-right" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-icon-button btn-icon-button--gray",
                    attrs: {
                      type: "button",
                      "data-test-hook": "CloseModalBtn"
                    },
                    on: {
                      click: function($event) {
                        return _vm.closeModals()
                      }
                    }
                  },
                  [_c("i", { staticClass: "fas fa-times" })]
                )
              ])
            ]
          ),
          _c("ModalBody", [
            _vm.currentSubscription[0].productId == _vm.dealerManaged
              ? _c("div", [
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      {
                        staticClass: "col",
                        attrs: { "data-test-hook": "DealerManagedMsg" }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("xYourDealerHasAManagedSubscription")
                            ) +
                            " "
                        )
                      ]
                    )
                  ])
                ])
              : _vm.availableSubscriptionProducts && !_vm.gettingSubscriptions
              ? _c(
                  "div",
                  [
                    _vm._l(_vm.availableSubscriptionProducts, function(
                      product,
                      index
                    ) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass: "card-group deck mx-0 mb-5"
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "card hoverPointer",
                              attrs: {
                                "data-test-hook":
                                  "subscription-clickable-card-step1-manage-sub-modal" +
                                  index
                              },
                              on: {
                                click: function($event) {
                                  _vm.currentSubscription[0].productId ==
                                    _vm.wifiAnnual ||
                                  _vm.currentSubscription[0].productId ==
                                    _vm.lteAnnual ||
                                  _vm.currentSubscription[0].productId ==
                                    _vm.cdmaAnnual
                                    ? _vm.annualToMonthlyFlow(product)
                                    : _vm.monthlyToAnnualFlow(product)
                                }
                              }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "row w-100 align-item-center" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "col d-flex align-items-center"
                                    },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "far fa-calendar-edit colorOrange pr-2 ml-3"
                                      }),
                                      _c("p", { staticClass: "mb-3 mt-3" }, [
                                        _c("strong", [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t("xSwitchTo")) +
                                              " " +
                                              _vm._s(product.name) +
                                              " (" +
                                              _vm._s(
                                                _vm._f("formatCurrency")(
                                                  product.cost.toFixed(2)
                                                )
                                              ) +
                                              " " +
                                              _vm._s(_vm.$t("xPer")) +
                                              " "
                                          ),
                                          product.durationInMonths == 12
                                            ? _c("span", [
                                                _vm._v(_vm._s(_vm.$t("xYear")))
                                              ])
                                            : _c("span", [
                                                _vm._v(_vm._s(_vm.$t("xMonth")))
                                              ]),
                                          _vm._v(")")
                                        ])
                                      ])
                                    ]
                                  )
                                ]
                              )
                            ]
                          )
                        ]
                      )
                    }),
                    _vm.currentSubscription[0].productId == _vm.wifiAnnual ||
                    _vm.currentSubscription[0].productId == _vm.wifiMonthly ||
                    _vm.currentSubscription[0].productId == _vm.ethAnnual ||
                    _vm.currentSubscription[0].productId == _vm.ethMonthly
                      ? _c("div", { staticClass: "row" }, [
                          !_vm.currentApparatusDetails.enrolledInVpp
                            ? _c("div", { staticClass: "col" }, [
                                _c(
                                  "a",
                                  {
                                    staticClass: "hoverPointer",
                                    attrs: {
                                      href: "#",
                                      target: "_self",
                                      "data-test-hook": "DowngradeToFreeBtn"
                                    },
                                    on: {
                                      click: function($event) {
                                        _vm.openModal(
                                          "downgrade-paid-sub-step-1"
                                        ),
                                          _vm.closeModal(
                                            "manage-sub-paid-step-1"
                                          )
                                      }
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "far fa-arrow-down"
                                    }),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("xDowngradeToAFreePlan")
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ])
                            : _vm._e()
                        ])
                      : _vm._e(),
                    _c("div", { staticClass: "row mt-3" }, [
                      !_vm.currentApparatusDetails.enrolledInVpp
                        ? _c("div", { staticClass: "col asterisk-text" }, [
                            _c(
                              "a",
                              {
                                staticClass: "hoverPointer",
                                attrs: {
                                  href: "#",
                                  target: "_self",
                                  "data-test-hook": "CancelAutoRenewBtn"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.emitCancelFlow()
                                  }
                                }
                              },
                              [
                                _c("i", { staticClass: "fa fa-cancel" }),
                                _vm._v(
                                  " " + _vm._s(_vm.$t("xCancelAutoRenew")) + " "
                                )
                              ]
                            )
                          ])
                        : _vm._e()
                    ]),
                    _vm.currentApparatusDetails.enrolledInVpp
                      ? _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col asterisk-text" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("xVppCannotCancelMessage")) +
                                " "
                            )
                          ])
                        ])
                      : _vm._e()
                  ],
                  2
                )
              : !_vm.availableSubscriptionProducts && _vm.gettingSubscriptions
              ? _c("div", [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "xCouldNotFindAnyAvailableSubscriptionsForYourGenerator"
                          )
                        ) + "'"
                      )
                    ])
                  ])
                ])
              : _c("div", [
                  _c("span", {
                    staticClass:
                      "spinner-border spinner-border-md text-warning",
                    staticStyle: { width: "10rem", height: "10rem" }
                  })
                ])
          ])
        ],
        1
      ),
      _c(
        "Modal",
        { attrs: { modalId: "manage-sub-paid-step-2.1", size: "modal-lg" } },
        [
          _c(
            "ModalHeader",
            {
              attrs: {
                title: _vm.$t("xManageSubscription"),
                icon: "far fa-badge-dollar colorOrange mr-1"
              }
            },
            [
              _c("div", { staticClass: "cols-auto float-right" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-icon-button btn-icon-button--gray",
                    attrs: {
                      type: "button",
                      "data-test-hook": "x-button-step2.1-manage-sub-modal"
                    },
                    on: {
                      click: function($event) {
                        return _vm.closeModals()
                      }
                    }
                  },
                  [_c("i", { staticClass: "fas fa-times" })]
                )
              ])
            ]
          ),
          _c("ModalBody", [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col" }, [
                _c("p", [_c("strong", [_vm._v(_vm._s(_vm.$t("xAreYouSure")))])])
              ])
            ]),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("xYoullSaveAnEstimated")) +
                    " " +
                    _vm._s(_vm.getSubscriptionSavings(_vm.newSubscription)) +
                    "% " +
                    _vm._s(_vm.$t("xIfYouKeepYourCurrentAnnualPlan")) +
                    " "
                )
              ])
            ])
          ]),
          _c("ModalFooter", [
            _c(
              "button",
              {
                staticClass: "btn btn-outline-warning",
                attrs: {
                  type: "button",
                  "data-test-hook": "previous-button-step2.1-manage-sub-modal"
                },
                on: {
                  click: function($event) {
                    _vm.openModal("manage-sub-paid-step-1"),
                      _vm.closeModal("manage-sub-paid-step-2.1")
                  }
                }
              },
              [_vm._v(" " + _vm._s(_vm.$t("xPrevious")) + " ")]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-warning",
                attrs: {
                  type: "button",
                  "data-test-hook":
                    "yes-im-sure-button-step2.1-manage-sub-modal"
                },
                on: {
                  click: function($event) {
                    return _vm.openModal("manage-sub-paid-step-3.1")
                  }
                }
              },
              [_vm._v(" " + _vm._s(_vm.$t("xYesImSure")) + " ")]
            )
          ])
        ],
        1
      ),
      _c(
        "Modal",
        { attrs: { modalId: "manage-sub-paid-step-2.2", size: "modal-lg" } },
        [
          _c(
            "ModalHeader",
            {
              attrs: {
                title: _vm.$t("xManageSubscription"),
                icon: "far fa-badge-dollar colorOrange mr-1"
              }
            },
            [
              _c("div", { staticClass: "cols-auto float-right" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-icon-button btn-icon-button--gray",
                    attrs: {
                      type: "button",
                      "data-test-hook": "x-button-step2.2-manage-sub-modal"
                    },
                    on: {
                      click: function($event) {
                        return _vm.closeModals()
                      }
                    }
                  },
                  [_c("i", { staticClass: "fas fa-times" })]
                )
              ])
            ]
          ),
          _c("ModalBody", [
            _c("div", { staticClass: "row mt-3 ml-1" }, [
              _c("div", { staticClass: "col" }, [
                _c("h4", [_vm._v(_vm._s(_vm.$t("xReviewSubscriptionChanges")))])
              ])
            ]),
            _c("div", { staticClass: "row mt-3 ml-1" }, [
              _c("div", { staticClass: "col" }, [
                _c("div", { staticClass: "row mb-1" }, [
                  _c("div", { staticClass: "col" }, [
                    _c("strong", [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("xYourCurrentSubscription")) +
                          ": " +
                          _vm._s(_vm.currentSubscription[0].productName) +
                          " "
                      )
                    ])
                  ])
                ]),
                _c("div", { staticClass: "row mb-1" }, [
                  _c("div", { staticClass: "col" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("xStartDateLower")) +
                        ": " +
                        _vm._s(
                          _vm
                            .$moment(_vm.currentSubscription[0].startDate)
                            .format(_vm.$t("xShortDate"))
                        ) +
                        " "
                    )
                  ])
                ]),
                _c("div", { staticClass: "row mb-1" }, [
                  _c("div", { staticClass: "col" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("xEndDateLower")) +
                        ": " +
                        _vm._s(
                          _vm
                            .$moment(String(_vm.calculateEnd))
                            .format(_vm.$t("xShortDate"))
                        ) +
                        " "
                    )
                  ])
                ]),
                _vm.coupon[0] != null &&
                _vm
                  .$moment()
                  .isBefore(
                    _vm
                      .$moment(_vm.coupon[0].endDate)
                      .add(23, "hours", 59, "minutes", 59, "seconds")
                  ) &&
                _vm.$moment().isAfter(_vm.$moment(_vm.coupon[0].startDate))
                  ? _c("div", { staticClass: "row mb-1" }, [
                      _c("div", { staticClass: "col" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("xSubscriptionCost")) +
                            ": " +
                            _vm._s(
                              _vm._f("formatCurrency")(
                                _vm.calculatePrice.toFixed(2)
                              )
                            ) +
                            " " +
                            _vm._s(_vm.$t("xPerMonth")) +
                            " (" +
                            _vm._s(_vm.$t("xRegularly")) +
                            " " +
                            _vm._s(
                              _vm._f("formatCurrency")(
                                _vm.currentSubscription[0].cost
                              )
                            ) +
                            " " +
                            _vm._s(_vm.$t("xPerMonth")) +
                            ") "
                        ),
                        _c(
                          "p",
                          {
                            staticClass: "mt-1",
                            staticStyle: { "font-size": "13px" }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.coupon[0].percentage) +
                                "% " +
                                _vm._s(_vm.$t("xDiscountApplied")) +
                                _vm._s(
                                  _vm
                                    .$moment(_vm.coupon[0].endDate)
                                    .format(_vm.$t("xShortDate"))
                                ) +
                                " "
                            )
                          ]
                        ),
                        _c("strong", [
                          _c("i", [
                            _c(
                              "p",
                              {
                                staticClass: "mt-1",
                                staticStyle: { "font-size": "13px" }
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("xCouponCodeWillNotTransfer"))
                                )
                              ]
                            )
                          ])
                        ])
                      ])
                    ])
                  : _c("div", { staticClass: "row mb-1" }, [
                      _c("div", { staticClass: "col" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("xSubscriptionCost")) +
                            ": " +
                            _vm._s(
                              _vm._f("formatCurrency")(
                                _vm.currentSubscription[0].cost.toFixed(2)
                              )
                            ) +
                            " " +
                            _vm._s(_vm.$t("xPerMonth")) +
                            " "
                        )
                      ])
                    ])
              ])
            ]),
            _vm.newSubscription
              ? _c("div", { staticClass: "row my-3 ml-1" }, [
                  _c("div", { staticClass: "col" }, [
                    _c("div", { staticClass: "row mb-1" }, [
                      _c("div", { staticClass: "col" }, [
                        _c("strong", [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("xYourNewSubscription")) +
                              ": " +
                              _vm._s(_vm.$t(_vm.newSubscription.name)) +
                              " "
                          )
                        ])
                      ])
                    ]),
                    _c("div", { staticClass: "row mb-1" }, [
                      _c("div", { staticClass: "col" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("xStartDateLower")) +
                            ": " +
                            _vm._s(
                              _vm
                                .$moment(String(_vm.subscriptionStart))
                                .format(_vm.$t("xShortDate"))
                            ) +
                            " "
                        )
                      ])
                    ]),
                    _c("div", { staticClass: "row mb-1" }, [
                      _c("div", { staticClass: "col" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("xSubscriptionCost")) +
                            ": " +
                            _vm._s(
                              _vm._f("formatCurrency")(
                                _vm.newSubscription.cost.toFixed(2)
                              )
                            ) +
                            " " +
                            _vm._s(_vm.$t("xPerYear")) +
                            " "
                        )
                      ])
                    ])
                  ])
                ])
              : _vm._e()
          ]),
          _c("ModalFooter", [
            _c(
              "button",
              {
                staticClass: "btn btn-outline-warning",
                attrs: {
                  type: "button",
                  "data-test-hook": "previous-button-step2.2-manage-sub-modal"
                },
                on: {
                  click: function($event) {
                    _vm.openModal("manage-sub-paid-step-1"),
                      _vm.closeModal("manage-sub-paid-step-2.2")
                  }
                }
              },
              [_vm._v(" " + _vm._s(_vm.$t("xPrevious")) + " ")]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-warning",
                attrs: {
                  type: "button",
                  "data-test-hook": "next-button-step2.2-manage-sub-modal"
                },
                on: {
                  click: function($event) {
                    _vm.openModal("manage-sub-paid-step-4"),
                      _vm.closeModal("manage-sub-paid-step-2.2")
                  }
                }
              },
              [_vm._v(" " + _vm._s(_vm.$t("xNext")) + " ")]
            )
          ])
        ],
        1
      ),
      _c(
        "Modal",
        { attrs: { modalId: "manage-sub-paid-step-3.1", size: "modal-lg" } },
        [
          _c(
            "ModalHeader",
            {
              attrs: {
                title: _vm.$t("xManageSubscription"),
                icon: "far fa-badge-dollar colorOrange mr-1"
              }
            },
            [
              _c("div", { staticClass: "cols-auto float-right" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-icon-button btn-icon-button--gray",
                    attrs: {
                      type: "button",
                      "data-test-hook": "x-button-step3.1-manage-sub-modal"
                    },
                    on: {
                      click: function($event) {
                        return _vm.closeModals()
                      }
                    }
                  },
                  [_c("i", { staticClass: "fas fa-times" })]
                )
              ])
            ]
          ),
          _c("ModalBody", [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col" }, [
                _c("h4", [_vm._v(_vm._s(_vm.$t("xSubscriptionChangeNotice")))])
              ])
            ]),
            _c("div", { staticClass: "row mb-3" }, [
              _c("div", { staticClass: "col" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("xYourCurrentActiveAnnualSubscriptionBeganOn")
                    ) +
                    " " +
                    _vm._s(
                      _vm
                        .$moment(_vm.currentSubscription[0].startDate)
                        .format(_vm.$t("xShortDate"))
                    ) +
                    " " +
                    _vm._s(_vm.$t("xInTheAmountOf")) +
                    " " +
                    _vm._s(
                      _vm._f("formatCurrency")(
                        _vm.currentSubscription[0].cost.toFixed(2)
                      )
                    ) +
                    ". "
                )
              ])
            ]),
            _c("div", { staticClass: "row mb-3" }, [
              _c("div", { staticClass: "col" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("xYourCurrentSubscriptionIsPaidThrough")) +
                    " " +
                    _vm._s(
                      _vm
                        .$moment(_vm.currentSubscription[0].nextBillingDate)
                        .subtract(1, "d")
                        .format(_vm.$t("xShortDate"))
                    ) +
                    ". "
                )
              ])
            ]),
            _c("div", { staticClass: "row" }, [
              _vm.newSubscription
                ? _c("div", { staticClass: "col" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("xYourNewMonthlyPaymentOf")) +
                        " " +
                        _vm._s(
                          _vm._f("formatCurrency")(
                            _vm.newSubscription.cost.toFixed(2)
                          )
                        ) +
                        " " +
                        _vm._s(_vm.$t("xWillBeginOn")) +
                        " " +
                        _vm._s(
                          _vm
                            .$moment(_vm.currentSubscription[0].nextBillingDate)
                            .format(_vm.$t("xShortDate"))
                        ) +
                        ". "
                    )
                  ])
                : _vm._e()
            ])
          ]),
          _c("ModalFooter", [
            _c(
              "button",
              {
                staticClass: "btn btn-outline-warning",
                attrs: {
                  type: "button",
                  "data-test-hook": "previous-button-step3.1-manage-sub-modal"
                },
                on: {
                  click: function($event) {
                    _vm.closeModal("manage-sub-paid-step-3.1"),
                      _vm.openModal("manage-sub-paid-step-2.1")
                  }
                }
              },
              [_vm._v(" " + _vm._s(_vm.$t("xPrevious")) + " ")]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-warning",
                attrs: {
                  type: "button",
                  "data-test-hook": "next-button-step3.1-manage-sub-modal"
                },
                on: {
                  click: function($event) {
                    return _vm.openModal("manage-sub-paid-step-4")
                  }
                }
              },
              [_vm._v(" " + _vm._s(_vm.$t("xNext")) + " ")]
            )
          ])
        ],
        1
      ),
      _c(
        "Modal",
        { attrs: { modalId: "manage-sub-paid-step-4", size: "modal-lg" } },
        [
          _c(
            "ModalHeader",
            {
              attrs: {
                title: _vm.$t("xManageSubscription"),
                icon: "far fa-badge-dollar colorOrange mr-1"
              }
            },
            [
              _c("div", { staticClass: "cols-auto float-right" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-icon-button btn-icon-button--gray",
                    attrs: {
                      type: "button",
                      "data-test-hook": "x-button-step4-manage-sub-modal"
                    },
                    on: {
                      click: function($event) {
                        return _vm.closeModals()
                      }
                    }
                  },
                  [_c("i", { staticClass: "fas fa-times" })]
                )
              ])
            ]
          ),
          _c("ModalBody", [
            _vm.paymentInfo
              ? _c("div", [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col" }, [
                      _c("h3", [
                        _c("strong", [
                          _vm._v(_vm._s(_vm.$t("xCreditCardInfo")) + "*")
                        ])
                      ])
                    ])
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-12" }, [
                      _c("p", [
                        _c("strong", [
                          _c("i", { staticClass: "fal fa-credit-card" }),
                          _vm._v(
                            " " +
                              _vm._s(_vm.paymentInfo.cardType) +
                              " - " +
                              _vm._s(_vm.paymentInfo.cardSuffix) +
                              " "
                          )
                        ])
                      ]),
                      _c("p", [
                        _vm._v(
                          _vm._s(_vm.$t("xExpires")) +
                            " " +
                            _vm._s(
                              _vm.paymentInfo.expirationMonth +
                                "/" +
                                _vm.paymentInfo.expirationYear
                            )
                        )
                      ])
                    ])
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-12" }, [
                      _vm._v(
                        _vm._s(_vm.accountProfile.firstName) +
                          " " +
                          _vm._s(_vm.accountProfile.lastName)
                      )
                    ]),
                    _c("div", { staticClass: "col-12" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.DisplayAddress(_vm.accountProfile.address)
                          ) +
                          " "
                      )
                    ])
                  ]),
                  _c("div", { staticClass: "row my-3" }, [
                    _c(
                      "div",
                      {
                        staticClass: "col-12",
                        attrs: { "data-test-hook": "TotalPaymentLabel" }
                      },
                      [
                        _c("div", { staticClass: "subscription-cost" }, [
                          _c("h3", { staticClass: "mb-0" }, [
                            _vm._v(
                              " " + _vm._s(_vm.$t("xTotalPayment")) + ": "
                            ),
                            _vm.newSubscription
                              ? _c(
                                  "span",
                                  {
                                    attrs: { "data-test-hook": "TotalPayment" }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("formatCurrency")(
                                            _vm.newSubscription.cost.toFixed(2)
                                          )
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              : _vm._e()
                          ]),
                          _c("p", [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("xStartingOn")) +
                                " " +
                                _vm._s(
                                  _vm
                                    .$moment(
                                      _vm.currentSubscription[0].nextBillingDate
                                    )
                                    .format(_vm.$t("xShortDate"))
                                ) +
                                " "
                            )
                          ]),
                          _c("p", [
                            _c("i", { staticClass: "body-text-color" }, [
                              _vm._v(" " + _vm._s(_vm.$t("xBilled")) + " "),
                              _vm.newSubscription &&
                              _vm.newSubscription.durationInMonths == 12
                                ? _c("span", [
                                    _vm._v(_vm._s(_vm.$t("xYearly")))
                                  ])
                                : _c("span", [
                                    _vm._v(_vm._s(_vm.$t("xMonthly")))
                                  ]),
                              _vm._v("** ")
                            ])
                          ])
                        ])
                      ]
                    )
                  ]),
                  _c("div", { staticClass: "row mb-2" }, [
                    _c("div", { staticClass: "col" }, [
                      _c("p", [
                        _c("i", { staticClass: "asterisk-text" }, [
                          _vm._v(
                            "**" +
                              _vm._s(
                                _vm.$t(
                                  "xYourNextScheduledPaymentWillBeDeducted"
                                )
                              )
                          )
                        ])
                      ]),
                      _c("p", [
                        _c("i", [
                          _vm._v(
                            _vm._s(_vm.$t("xThisSubscriptionWillAutoRenew"))
                          )
                        ])
                      ])
                    ])
                  ])
                ])
              : _vm._e()
          ]),
          _c("ModalFooter", [
            _c(
              "button",
              {
                staticClass: "btn btn-outline-warning",
                attrs: {
                  type: "button",
                  "data-test-hook": "previous-button-step4-manage-sub-modal"
                },
                on: {
                  click: function($event) {
                    _vm.currentSubscription[0].productId == _vm.wifiAnnual ||
                    _vm.currentSubscription[0].productId == _vm.lteAnnual ||
                    _vm.currentSubscription[0].productId == _vm.wifiAnnual
                      ? _vm.openModal("manage-sub-paid-step-3.1")
                      : _vm.openModal("manage-sub-paid-step-2.2"),
                      _vm.closeModal("manage-sub-paid-step-4")
                  }
                }
              },
              [_vm._v(" " + _vm._s(_vm.$t("xPrevious")) + " ")]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-warning",
                attrs: {
                  type: "button",
                  disabled: _vm.changingSubscription,
                  "data-test-hook": "confirm-button-step4-manage-sub-modal"
                },
                on: {
                  click: function($event) {
                    return _vm.changeSubscription()
                  }
                }
              },
              [
                _vm._v(" " + _vm._s(_vm.$t("xConfirm")) + " "),
                _vm.changingSubscription
                  ? _c("b-spinner", { staticClass: "ml-2" })
                  : _vm._e()
              ],
              1
            )
          ])
        ],
        1
      ),
      _c(
        "Modal",
        { attrs: { modalId: "manage-sub-paid-step-5", size: "modal-md" } },
        [
          _c(
            "ModalHeader",
            {
              attrs: {
                title: _vm.$t("xManageSubscription"),
                icon: "far fa-badge-dollar colorOrange mr-1"
              }
            },
            [
              _c("div", { staticClass: "cols-auto float-right" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-icon-button btn-icon-button--gray",
                    attrs: {
                      type: "button",
                      "data-test-hook": "x-button-step5-manage-sub-modal"
                    },
                    on: {
                      click: function($event) {
                        return _vm.closeModals()
                      }
                    }
                  },
                  [_c("i", { staticClass: "fas fa-times" })]
                )
              ])
            ]
          ),
          _c("ModalBody", [
            _c("div", { staticClass: "row no-gutters" }, [
              _c(
                "div",
                { staticClass: "col" },
                [
                  _c("lottie", {
                    attrs: {
                      options: _vm.successAnimationOptions,
                      height: 80,
                      width: 80
                    },
                    on: { animCreated: _vm.handleAnimation }
                  })
                ],
                1
              )
            ]),
            _c("div", { staticClass: "row text-center mt-2" }, [
              _c("div", { staticClass: "col" }, [
                _c("h1", [_vm._v(_vm._s(_vm.$t("xSuccess")) + "!")]),
                _c("p", [
                  _vm._v(
                    _vm._s(
                      _vm.$t("xYourSubscriptionPlanHasBeenSuccessfullyUpdated")
                    )
                  )
                ])
              ])
            ])
          ]),
          _c("ModalFooter", [
            _c(
              "button",
              {
                staticClass: "btn btn-warning",
                attrs: {
                  type: "button",
                  "data-test-hook": "ok-button-step5-manage-sub-modal"
                },
                on: {
                  click: function($event) {
                    _vm.closeModals(), _vm.emitSuccess()
                  }
                }
              },
              [_vm._v(" " + _vm._s(_vm.$t("xOK")) + " ")]
            )
          ])
        ],
        1
      ),
      _c("DowngradeSubscriptionModals", {
        attrs: { currentSubscription: _vm.currentSubscription },
        on: {
          success: function($event) {
            return _vm.emitSuccess()
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }