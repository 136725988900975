<template>
  <div>
    <div class="row">
      <div class="col-12">
        <h5 class="m-0">{{ $t('xScheduledExerciseTime') }}</h5>
      </div>
    </div>
    <div class="row my-3" v-if="statusWithData">
      <div class="col-12 asterisk-text">
        {{ getExerciseInterval(schedule.interval).text }}
      </div>
      <div class="col-12">
        <div class="row no-gutters">
          <div class="cols-auto">
            <i class="far fa-calendar-check"></i>
          </div>
          <div class="col ml-2">
            {{ getExerciseDay().text }} {{ $t('xAt') }} {{ getExerciseTime(schedule.timeUtc) }}
          </div>
        </div>
      </div>
    </div>
    <div
      class="row"
      v-else-if="panelNotSupported || statusWithNoData || notConfigured"
      data-test-hook="no-exercise-schedule-data-message"
    >
      <div class="col-12">
        <p class="mt-2">
          <i class="reason-message">{{ $t('xNoExerciseScheduleDataAvailable') }}</i>
        </p>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col">
        <p>
          <strong>{{ $t('xExerciseUpdateUnavailable') }}</strong>
        </p>
      </div>
    </div>
    <div class="row mb-3" v-if="statusWithData || statusWithNoData">
      <div class="col-12">
        <p class="mt-2">
          <i class="reason-message">{{ 'xCurrentStatus' | formatLabel }} {{ schedule.statusLabel }}</i>
        </p>
      </div>
      <div class="col-12">
        <i class="reason-message">
          {{ $t('xDueToThisStatusYourUpdateAandExercise') }}
        </i>
      </div>
    </div>
    <div class="row mb-3" v-else-if="panelNotSupported || notConfigured" data-test-hook="panel-not-supported-div">
      <div class="col-12">
        <p>
          <i class="reason-message">
            {{ $t('xYourGeneratorDoesNotSupportThisFeature') }}
          </i>
        </p>
      </div>
      <div class="col-12 mt-3">
        <button
          type="button"
          class="btn btn-warning"
          :to="{ name: 'technicalSpecs' }"
          data-test-hook="tech-specs-button-no-exercise-shcedule-reason"
        >
          <i class="fas fa-clipboard-list" /> {{ $t('xTechnicalSpecs') }}
        </button>
      </div>
    </div>
    <div
      class="row"
      v-else-if="
        !subscriptionPaid(currentApparatusDetails.subscription) ||
          !subscriptionActive(currentApparatusDetails.subscription)
      "
    >
      <div class="col">
        <button
          type="button"
          class="btn btn-outline-primary"
          id="freeUpdateButton"
          disabled
          :title="$t('xThisIsAPremiumFeature')"
        >
          {{ $t('xUpdate') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import * as Constants from '@/constants'

export default {
  props: ['schedule'],
  data() {
    return {
      statusWithData: false,
      statusWithNoData: false,
      notConfigured: false,
      panelNotSupported: false
    }
  },
  computed: {
    ...mapState({
      currentApparatusDetails: state => state.apparatus.currentApparatusDetails
    })
  },
  methods: {
    getExerciseInterval(intervalValue) {
      return Constants.intervals.find(interval =>
        intervalValue != null ? interval.value === intervalValue : interval.value === 0
      )
    },
    getExerciseDay() {
      if (this.schedule.interval == Constants.MonthlyInterval) {
        return Constants.daysOfMonth.find(day =>
          this.schedule.day != null ? day.value === this.schedule.day : day.value === 0
        )
      } else {
        return Constants.days.find(day =>
          this.schedule.day != null ? day.value === this.schedule.day : day.value === 0
        )
      }
    },
    getExerciseTime(time) {
      if (time) {
        return this.$moment.utc(time, 'HH:mm').format('hh:mm A')
      }
      return '12:00 am'
    }
  },
  mounted() {
    if (this.schedule) {
      if (
        (this.schedule.apparatusStatus != 1 || !this.schedule.isConnected) &&
        this.schedule.apparatusPanelId &&
        Constants.ManageMaintenancePanels.includes(this.schedule.apparatusPanelId)
      ) {
        if (this.schedule.scheduleIsSet) this.statusWithData = true
        else this.statusWithNoData = true
      }
      if (
        this.schedule.apparatusPanelId &&
        !Constants.ManageMaintenancePanels.includes(this.schedule.apparatusPanelId)
      ) {
        this.panelNotSupported = true
      } else this.notConfigured = true
    } else this.notConfigured = true
  }
}
</script>

<style scoped>
.reason-message {
  color: var(--carbon-600);
}
</style>
