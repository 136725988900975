var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "mt-2 ml-md-2" }, [
    !_vm.gettingWarranty
      ? _c("div", [
          _c("div", { staticClass: "row no-gutters mb-2" }, [
            _c("div", { staticClass: "col" }, [
              _c("h1", { staticClass: "mb-0 pb-0" }, [
                _c("i", { staticClass: "far fa-award colorOrange mr-1" }),
                _vm._v(" " + _vm._s(_vm.$t("xWarranty")))
              ])
            ])
          ]),
          _vm.warrantyInfo
            ? _c(
                "div",
                {
                  staticClass: "card",
                  attrs: { "data-test-hook": "WarrantyCard" }
                },
                [
                  _c("div", { staticClass: "card-body" }, [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col" }, [
                        _c(
                          "p",
                          {
                            attrs: { "data-test-hook": "WarrantyDurationLabel" }
                          },
                          [_vm._v(_vm._s(_vm.$t("xWarrantyLevel")))]
                        ),
                        _c("p", [
                          _c(
                            "strong",
                            { attrs: { "data-test-hook": "WarrantyDuration" } },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.warrantyInfo.duration ||
                                    _vm.$t("xInfoNotAvailable")
                                )
                              )
                            ]
                          )
                        ])
                      ])
                    ]),
                    _c("div", { staticClass: "row mt-3" }, [
                      _c("div", { staticClass: "col" }, [
                        _c(
                          "p",
                          {
                            attrs: {
                              "data-test-hook": "WarrantyStartDateLabel"
                            }
                          },
                          [_vm._v(_vm._s(_vm.$t("xStartDate")))]
                        ),
                        _vm.warrantyInfo.startDate
                          ? _c(
                              "p",
                              {
                                attrs: { "data-test-hook": "WarrantyStartDate" }
                              },
                              [
                                _c("strong", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm
                                          .$moment(_vm.warrantyInfo.startDate)
                                          .format(_vm.$t("xShortDate"))
                                      ) +
                                      " "
                                  )
                                ])
                              ]
                            )
                          : _c(
                              "p",
                              {
                                attrs: { "data-test-hook": "WarrantyStartDate" }
                              },
                              [
                                _c("strong", [
                                  _vm._v(_vm._s(_vm.$t("xInfoNotAvailable")))
                                ])
                              ]
                            )
                      ])
                    ]),
                    _vm._m(0),
                    _c("div", { staticClass: "row mt-3" }, [
                      _c("div", { staticClass: "col" }, [
                        _c(
                          "p",
                          {
                            attrs: { "data-test-hook": "WarrantyEndDateLabel" }
                          },
                          [_vm._v(_vm._s(_vm.$t("xExpirationDate")))]
                        ),
                        _vm.warrantyInfo.expirationDate
                          ? _c(
                              "p",
                              {
                                attrs: { "data-test-hook": "WarrantyEndDate" }
                              },
                              [
                                _c("strong", [
                                  _vm._v(
                                    _vm._s(
                                      _vm
                                        .$moment(
                                          _vm.warrantyInfo.expirationDate
                                        )
                                        .format(_vm.$t("xShortDate"))
                                    )
                                  )
                                ])
                              ]
                            )
                          : _c(
                              "p",
                              {
                                attrs: { "data-test-hook": "WarrantyEndDate" }
                              },
                              [
                                _c("strong", [
                                  _vm._v(_vm._s(_vm.$t("xInfoNotAvailable")))
                                ])
                              ]
                            )
                      ])
                    ]),
                    _c("div", { staticClass: "row mt-3" }, [
                      _c("div", { staticClass: "col" }, [
                        _c(
                          "p",
                          {
                            attrs: {
                              "data-test-hook": "WarrantyDescriptionLabel"
                            }
                          },
                          [_vm._v(_vm._s(_vm.$t("xWarrantyDescription")))]
                        ),
                        _c(
                          "p",
                          {
                            attrs: { "data-test-hook": "WarrantyDescription" }
                          },
                          [
                            _c("strong", [
                              _vm._v(
                                _vm._s(
                                  _vm.warrantyInfo.description ||
                                    _vm.$t("xInfoNotAvailable")
                                )
                              )
                            ])
                          ]
                        )
                      ])
                    ])
                  ]),
                  _c("div", { staticClass: "card-footer" }, [
                    _c("div", { staticClass: "row" }, [
                      _vm.warrantyInfo && _vm.warrantyInfo.lastUpdated
                        ? _c(
                            "div",
                            {
                              staticClass: "col",
                              attrs: {
                                "data-test-hook": "warranty-last-updated"
                              }
                            },
                            [
                              _c("p", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("formatLabel")("xLastUpdated")
                                  ) +
                                    " " +
                                    _vm._s(
                                      _vm.displayShortDateTimeWithTZ(
                                        _vm.warrantyInfo.lastUpdated
                                      )
                                    )
                                )
                              ]),
                              _c("p", [
                                _vm._v(_vm._s(_vm.$t("xUpdatedWeekly")))
                              ])
                            ]
                          )
                        : _vm._e()
                    ])
                  ])
                ]
              )
            : _c(
                "div",
                {
                  staticClass: "card",
                  attrs: { "data-test-hook": "NoInfoWarrantyCard" }
                },
                [
                  _c("div", { staticClass: "card-body" }, [
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        {
                          staticClass: "col",
                          attrs: { "data-test-hook": "NoInfoText" }
                        },
                        [_c("p", [_vm._v(_vm._s(_vm.$t("xInfoNotAvailable")))])]
                      )
                    ])
                  ]),
                  _c("div", { staticClass: "card-footter" }, [
                    _c("div", { staticClass: "row" }, [
                      _vm.warrantyInfo && _vm.warrantyInfo.lastUpdated
                        ? _c(
                            "div",
                            {
                              staticClass: "col",
                              attrs: {
                                "data-test-hook": "warranty-last-updated"
                              }
                            },
                            [
                              _c("p", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("formatLabel")("xLastUpdated")
                                  ) +
                                    " " +
                                    _vm._s(
                                      _vm.displayShortDateTimeWithTZ(
                                        _vm.warrantyInfo.lastUpdated
                                      )
                                    )
                                )
                              ]),
                              _c("p", [
                                _vm._v(_vm._s(_vm.$t("xUpdatedWeekly")))
                              ])
                            ]
                          )
                        : _vm._e()
                    ])
                  ])
                ]
              )
        ])
      : _c("div", { staticClass: "d-flex justify-content-center mb-3 mt-4" }, [
          _c("span", {
            staticClass: "spinner-border text-warning",
            staticStyle: { width: "5rem", height: "5rem" },
            attrs: { label: "Spinning", "data-test-hook": "LoadingSpinner" }
          })
        ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }