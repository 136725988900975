<template>
  <div>
    <div class="card hoverPointer apparatus-card" @click="toDetails()">
      <div class="card-header dashboard-card-header">
        <div class="row w-100 no-gutters">
          <div class="float-left col">
            <p class="py-0 my-0">
              <strong> {{ $t('xViewDetails') }}</strong>
            </p>
          </div>
          <div class="float-right mr-0 pr-0 col-auto">
            <i class="fa-regular fa-arrow-right" />
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="row mt-2">
          <div class="col-sm-12 col-md-4 col-lg-4">
            <img :src="apparatusInfo.heroImageUrl" :alt="$t('xHeroImage')" class="img-fluid" />
          </div>
          <div class="mt-3 col-sm-12 col-md-8 col-lg-8">
            <div class="row">
              <div class="col-auto">
                {{ $t('xSerial#') | formatLabelNoTranslation }} {{ apparatusInfo.serialNumber }}
              </div>
            </div>
            <div class="row">
              <div class="col">
                <h2>{{ apparatusInfo.name }}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import * as Constants from '@/constants'

export default {
  props: ['apparatusInfo'],
  data() {
    return {
      apparatus: null
    }
  },
  methods: {
    toDetails() {
      this.$appInsights.trackEvent({
        name: 'click-apparatus-card',
        properties: { apparatusId: this.apparatusInfo.apparatusId }
      })
      this.$router.push({
        path: `/details/${this.apparatusInfo.apparatusId}`
      })
    }
  },
  mounted() {
    if (this.apparatusInfo) this.apparatus = this.apparatusInfo
  }
}
</script>
