var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Modal",
    { attrs: { modalId: "EditPreferencesModal" } },
    [
      _c(
        "ModalHeader",
        {
          attrs: {
            title: _vm.$t("xEditDisplayPreferences"),
            icon: "fas fa-user-cog"
          }
        },
        [
          _c(
            "button",
            {
              staticClass: "btn btn-icon-button btn-icon-button--gray",
              attrs: { "data-test-hook": "x-button-edit-preferences-modal" },
              on: {
                click: function($event) {
                  return _vm.closeModal("EditPreferencesModal")
                }
              }
            },
            [_c("i", { staticClass: "fas fa-times" })]
          )
        ]
      ),
      _c("ModalBody", [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col" }, [
            _c("h5", { staticClass: "mb-1" }, [
              _vm._v(" " + _vm._s(_vm.$t("xDistanceUnit")) + " ")
            ])
          ])
        ]),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col" }, [
            _c("fieldset", { staticClass: "form-group" }, [
              _c("div", { staticClass: "custom-control custom-radio" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.distanceUom,
                      expression: "distanceUom"
                    }
                  ],
                  staticClass: "custom-control-input",
                  attrs: {
                    type: "radio",
                    id: "milesRadio",
                    value: "miles",
                    "data-test-hook": "mi-radio-edit-preferences-modal"
                  },
                  domProps: { checked: _vm._q(_vm.distanceUom, "miles") },
                  on: {
                    change: function($event) {
                      _vm.distanceUom = "miles"
                    }
                  }
                }),
                _c(
                  "label",
                  {
                    staticClass: "custom-control-label",
                    attrs: { for: "milesRadio" }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("xMiles")) + " ")]
                )
              ]),
              _c("div", { staticClass: "custom-control custom-radio" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.distanceUom,
                      expression: "distanceUom"
                    }
                  ],
                  staticClass: "custom-control-input",
                  attrs: {
                    type: "radio",
                    id: "kiloMetersRadio",
                    value: "kilometers",
                    "data-test-hook": "km-radio-edit-preferences-modal"
                  },
                  domProps: { checked: _vm._q(_vm.distanceUom, "kilometers") },
                  on: {
                    change: function($event) {
                      _vm.distanceUom = "kilometers"
                    }
                  }
                }),
                _c(
                  "label",
                  {
                    staticClass: "custom-control-label",
                    attrs: { for: "kiloMetersRadio" }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("xKilometers")) + " ")]
                )
              ])
            ])
          ])
        ]),
        _c("hr"),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col" }, [
            _c("h5", { staticClass: "headerInline" }, [
              _vm._v(" " + _vm._s(_vm.$t("xTemperatureUnit")) + " ")
            ])
          ])
        ]),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col" }, [
            _c("fieldset", { staticClass: "form-group" }, [
              _c("div", { staticClass: "custom-control custom-radio" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.temperatureUom,
                      expression: "temperatureUom"
                    }
                  ],
                  staticClass: "custom-control-input",
                  attrs: {
                    type: "radio",
                    id: "fahrenheitRadio",
                    value: "fahrenheit",
                    "data-test-hook": "fahrenheit-radio-edit-preferences-modal"
                  },
                  domProps: {
                    checked: _vm._q(_vm.temperatureUom, "fahrenheit")
                  },
                  on: {
                    change: function($event) {
                      _vm.temperatureUom = "fahrenheit"
                    }
                  }
                }),
                _c(
                  "label",
                  {
                    staticClass: "custom-control-label",
                    attrs: { for: "fahrenheitRadio" }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("xFahrenheit")) + " ")]
                )
              ]),
              _c("div", { staticClass: "custom-control custom-radio" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.temperatureUom,
                      expression: "temperatureUom"
                    }
                  ],
                  staticClass: "custom-control-input",
                  attrs: {
                    type: "radio",
                    id: "celsiusRadio",
                    value: "celsius",
                    "data-test-hook": "celsius-radio-edit-preferences-modal"
                  },
                  domProps: { checked: _vm._q(_vm.temperatureUom, "celsius") },
                  on: {
                    change: function($event) {
                      _vm.temperatureUom = "celsius"
                    }
                  }
                }),
                _c(
                  "label",
                  {
                    staticClass: "custom-control-label",
                    attrs: { for: "celsiusRadio" }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("xCelsius")) + " ")]
                )
              ])
            ])
          ])
        ]),
        _c("hr"),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col" }, [
            _c("h5", { staticClass: "headerInline" }, [
              _vm._v(" " + _vm._s(_vm.$t("xLiquidUnit")) + " ")
            ])
          ])
        ]),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col" }, [
            _c("fieldset", { staticClass: "form-group" }, [
              _c("div", { staticClass: "custom-control custom-radio" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.liquidUom,
                      expression: "liquidUom"
                    }
                  ],
                  staticClass: "custom-control-input",
                  attrs: {
                    type: "radio",
                    id: "gallonsRadio",
                    value: "gallons",
                    "data-test-hook": "gallons-radio-edit-preferences-modal"
                  },
                  domProps: { checked: _vm._q(_vm.liquidUom, "gallons") },
                  on: {
                    change: function($event) {
                      _vm.liquidUom = "gallons"
                    }
                  }
                }),
                _c(
                  "label",
                  {
                    staticClass: "custom-control-label",
                    attrs: { for: "gallonsRadio" }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("xGallons")) + " ")]
                )
              ]),
              _c("div", { staticClass: "custom-control custom-radio" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.liquidUom,
                      expression: "liquidUom"
                    }
                  ],
                  staticClass: "custom-control-input",
                  attrs: {
                    type: "radio",
                    id: "litersRadio",
                    value: "liters",
                    "data-test-hook": "liters-radio-edit-preferences-modal"
                  },
                  domProps: { checked: _vm._q(_vm.liquidUom, "liters") },
                  on: {
                    change: function($event) {
                      _vm.liquidUom = "liters"
                    }
                  }
                }),
                _c(
                  "label",
                  {
                    staticClass: "custom-control-label",
                    attrs: { for: "litersRadio" }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("xLiters")) + " ")]
                )
              ])
            ])
          ])
        ])
      ]),
      _c("ModalFooter", [
        _c(
          "button",
          {
            staticClass: "btn btn-outline-warning",
            attrs: { "data-test-hook": "cancel-button-edit-preferences-modal" },
            on: {
              click: function($event) {
                return _vm.cancel()
              }
            }
          },
          [_vm._v(" " + _vm._s(_vm.$t("xCancel")) + " ")]
        ),
        _c(
          "button",
          {
            staticClass: "btn btn-warning",
            attrs: { "data-test-hook": "save-button-edit-preferences-modal" },
            on: {
              click: function($event) {
                return _vm.saveUnits()
              }
            }
          },
          [
            _vm._v(" " + _vm._s(_vm.$t("xSave")) + " "),
            _vm.saving
              ? _c("div", {
                  staticClass: "ml-2 spinner-border text-muted",
                  attrs: { label: "Spinning" }
                })
              : _vm._e()
          ]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }