<template>
  <div>
    <Modal modalId="edit-fuel-consumption-modal" size="modal-lg">
      <ModalHeader
        :title="$t('xFuelConsumption')"
        icon="far fa-meter-droplet colorOrange mr-1"
        data-test-hook="ModalTitle"
      >
        <button
          class="btn btn-icon-button btn-icon-button--gray"
          @click="saving ? null : closeModal('edit-fuel-consumption-modal'), resetModal()"
          data-test-hook="edit-fuel-consumption-modal-close-button"
        >
          <i class="fas fa-times" data-test-hook="edit-fuel-consumption-modal-close-button-icon" />
        </button>
      </ModalHeader>
      <ModalBody>
        <div class="row mb-3">
          <div class="col" data-test-hook="edit-fuel-consumption-modal-body-text">
            <p v-html="$t('xWhichDevicesConsumeFuel')" />
          </div>
        </div>
        <div class="row mb-2 align-item-center mr-2" v-for="(type, index) in selectedTankUtilFuelUses" :key="index">
          <div class="col align-item-center" :data-test-hook="'FuelUse-' + removeExtraChars($t(type.name)) + '-Header'">
            {{ $t(type.name) }}
          </div>
          <div class="cols-auto">
            <b-button
              class="mr-2"
              pill
              variant="outline-primary"
              @click="addTag(index, true)"
              :pressed="type.selected != null && type.selected"
              :data-test-hook="'FuelUse-' + removeExtraChars($t(type.name)) + '-YesButton'"
            >
              {{ $t('xYes') }}
            </b-button>
            <b-button
              pill
              variant="outline-primary"
              @click="addTag(index, false)"
              :pressed="type.selected != null && !type.selected"
              :data-test-hook="'FuelUse-' + removeExtraChars($t(type.name)) + '-NoButton'"
            >
              {{ $t('xNo') }}
            </b-button>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <b-button
          variant="outline-warning"
          :disabled="saving"
          @click="closeModal('edit-fuel-consumption-modal'), resetModal()"
          data-test-hook="edit-fuel-consumption-modal-cancel-button"
        >
          {{ $t('xCancel') }}
        </b-button>
        <b-button
          variant="primary"
          :disabled="!userCanSave() || saving"
          @click="updateTank()"
          data-test-hook="edit-fuel-consumption-modal-save-button"
        >
          <span v-if="!saving">{{ $t('xSave') }}</span>
          <span v-else>
            <b-spinner />
          </span>
        </b-button>
      </ModalFooter>
    </Modal>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import * as Constants from '@/constants'

export default {
  data() {
    return {
      saving: false,
      selectedTankUtilFuelUses: null,
      startingSelectedTankUtilFuelUses: null
    }
  },
  computed: {
    ...mapState({
      currentApparatusDetails: state => state.apparatus.currentApparatusDetails
    })
  },
  mounted() {
    this.loadFuelUses()
  },
  methods: {
    async resetModal() {
      this.saving = false
      await this.loadFuelUses()
    },
    addTag(index, value) {
      this.selectedTankUtilFuelUses[index].selected = value
    },
    getVariant(selected) {
      if (selected) return 'warning'
      else return 'outline-warning'
    },
    async loadFuelUses() {
      let consumptionTypes = null
      await this.$store
        .dispatch('getTankUtilityConsumptionTypes')
        .then(response => {
          if (response.status == 200) {
            consumptionTypes = response.data
          }
        })
        .catch(error => {
          this.validateError = error.response.data
          this.$store.dispatch('displayToast', {
            title: this.$t('xError'),
            message: this.validateError.message,
            variant: 'danger'
          })
        })

      consumptionTypes.forEach(element => (element.selected = false))

      this.selectedTankUtilFuelUses = null
      this.selectedTankUtilFuelUses = _.cloneDeep(consumptionTypes)
      let tuPropertyFuelUses = this.currentApparatusDetails.tuProperties.filter(
        p => p.type == Constants.TuSnmpTypeConsumptionTypes
      )[0].value.split(',');

      if(tuPropertyFuelUses){
        this.selectedTankUtilFuelUses.forEach(fu =>
        tuPropertyFuelUses.includes(fu.type) ? (fu.selected = true) : (fu.selected = false)
      )
      }

      this.startingSelectedTankUtilFuelUses = _.cloneDeep(this.selectedTankUtilFuelUses)
    },
    updateTank() {
      this.saving = true
      let consumptionTypes = {}
      this.selectedTankUtilFuelUses.forEach(element => {
        consumptionTypes[element.type] = element.selected
      })
      let request = {
        apparatusId: this.$route.params.apparatusId,
        consumptionTypes: consumptionTypes
      }

      this.$store
        .dispatch('updateTankUtilityApparatus', request)
        .then(response => {
          this.$store.dispatch('displayToast', {
            title: this.$t('xSuccess'),
            message: this.$t('xTankDetailsUpdated'),
            variant: 'success'
          })
          this.gettingNewValues = true
          setTimeout(
            function() {
              this.$store.dispatch('getApparatusDetails', this.$route.params.apparatusId)
              this.gettingNewValues = false
              this.$router.push({ name: 'deviceDetails' })
            }.bind(this),
            2000
          )
        })
        .catch(error => {
          this.$store.dispatch('displayToast', {
            title: this.$t('xError'),
            message: this.$t('xUnableToSaveChanges'),
            variant: 'danger'
          })
        })
        .finally(() => {
          this.saving = false
        })
    },
    userCanSave() {
      let foundADifference = false
      let index = 0
      while (!foundADifference && index < this.selectedTankUtilFuelUses?.length) {
        foundADifference =
          this.startingSelectedTankUtilFuelUses[index].selected != this.selectedTankUtilFuelUses[index].selected
        index++
      }
      return foundADifference
    },
    removeExtraChars(testhook) {
      return testhook
        .split(' ')
        .join('')
        .split('/')
        .join('')
    }
  }
}
</script>
