import Axios from 'axios'
import router from '@/router/'
import i18n from '@/lang/'
import _ from 'lodash'
import { checkPendingApparatuses } from '@/utils/mixins'
import { vueAppApiBaseAddress } from '@/utils/mixins'
import * as Constants from '@/constants'

var initialState = {
  apparatuses: null,
  currentApparatusDetails: null,
  currentApparatusMaintenanceSchedule: null,
  currentApparatusMaintenanceWarranty: null,
  currentApparatusTechnicalSpecs: null,
  currentApparatusPreferredDealer: null,
  currentApparatusExerciseSchedule: null
}

export const state = _.cloneDeep(initialState)

export const getters = {}

export const mutations = {
  apparatusReset(state) {
    const initial = _.cloneDeep(initialState)
    Object.keys(initial).forEach(key => {
      state[key] = initial[key]
    })
  },
  setApparatuses(state, apparatuses) {
    if (apparatuses) {
      state.apparatuses = apparatuses.filter(apparatus => apparatus.type != Constants.ThermostatApparatusType)
    } else state.apparatuses = apparatuses
  },
  setCurrentApparatusDetails(state, details) {
    state.currentApparatusDetails = details
  },
  setCurrentApparatusMaintenanceSchedule(state, schedule) {
    state.currentApparatusMaintenanceSchedule = schedule
  },
  setCurrentApparatusTechnicalSpecs(state, technicalSpecs) {
    state.currentApparatusTechnicalSpecs = technicalSpecs
  },
  setCurrentApparatusExerciseSchedule(state, exerciseSchedule) {
    state.currentApparatusExerciseSchedule = exerciseSchedule
  },
  pushPendingApparatus(state, apparatusType) {
    state.apparatuses.push({ pending: true, apparatusType: apparatusType })
  }
}

export const actions = {
  apparatusReset({ commit }) {
    commit('apparatusReset')
  },
  async getApparatusById({ getters, commit, dispatch }, apparatusId) {
    return await Axios.get(vueAppApiBaseAddress() + `v1/Apparatus/${parseInt(apparatusId)}`, {
      withCredentials: true
    })
  },
  async getApparatusesSilent({ commit }) {
    await Axios.get(vueAppApiBaseAddress() + `v2/Apparatus/list`, {
      withCredentials: true
    })
      .then(response => {
        if (response.status == 204) commit('setApparatuses', [])
        else {
          commit('setApparatuses', response.data)
          checkPendingApparatuses(
            response.data.map(a => a.apparatusId),
            commit
          )
        }
      })
      .catch(error => {
        commit('setApparatuses', [])
      })
  },
  async getApparatuses({ getters, commit, dispatch }) {
    commit('setApparatuses', null)
    await Axios.get(vueAppApiBaseAddress() + `v2/Apparatus/list`, {
      withCredentials: true
    })
      .then(response => {
        if (response.status == 204) commit('setApparatuses', [])
        else {
          commit('setApparatuses', response.data)
          checkPendingApparatuses(
            response.data.map(a => a.apparatusId),
            commit
          )
        }
      })
      .catch(error => {
        commit('setApparatuses', [])
      })
  },
  async getApparatusDetails({ commit, dispatch }, apparatusId) {
    await Axios.get(vueAppApiBaseAddress() + `v1/Apparatus/details/${parseInt(apparatusId)}`, {
      withCredentials: true
    })
      .then(response => {
        commit('setCurrentApparatusDetails', response.data)
      })
      .catch(error => {
        if (error.response.status == 403) {
          router.push('/dashboard')
          dispatch(
            'displayToast',
            {
              title: i18n.t('xError'),
              message: i18n.t('xCannotAccessDetails'),
              variant: 'danger'
            },
            { root: true }
          )
        }
      })
  },
  async getApparatusMaintenanceDetails({ commit }, value) {
    return await Axios.get(
      vueAppApiBaseAddress() +
        `v1/Apparatus/maintenanceDetails/${value.apparatusId}?pageNumber=${value.pageNumber}&pageSize=${value.pageSize}`,
      {
        withCredentials: true
      }
    )
  },
  async getApparatusMaintenanceSchedule({ commit }, apparatusId) {
    await Axios.get(vueAppApiBaseAddress() + `v1/Apparatus/maintenance/${apparatusId}`, {
      withCredentials: true
    })
      .then(response => {
        commit('setCurrentApparatusMaintenanceSchedule', response.data)
      })
      .catch(error => {})
  },
  async getApparatusMaintenanceHistory({ commit }, value) {
    return await Axios.get(
      vueAppApiBaseAddress() +
        `v1/Apparatus/maintenanceHistory/${value.apparatusId}?pageNumber=${value.pageNumber}&pageSize=${value.pageSize}`,
      {
        withCredentials: true
      }
    )
  },
  async getApparatusStatusHistory({ commit }, value) {
    return await Axios.get(
      vueAppApiBaseAddress() +
        `v1/Apparatus/statusHistory/${value.apparatusId}?pageNumber=${value.pageNumber}&pageSize=${value.pageSize}`,
      {
        withCredentials: true
      }
    )
  },
  async getApparatusWarranty({ commit }, apparatusId) {
    return await Axios.get(vueAppApiBaseAddress() + `v1/Apparatus/${apparatusId}/warranty`, {
      withCredentials: true
    })
  },
  async getApparatusTechnicalSpecs({ commit }, apparatusId) {
    await Axios.get(vueAppApiBaseAddress() + `v1/Apparatus/specifications/${apparatusId}`, {
      withCredentials: true
    })
      .then(response => {
        commit('setCurrentApparatusTechnicalSpecs', response.data)
      })
      .catch(error => {})
  },
  async getApparatusProductManaulsById({ commit }, request) {
    return await Axios.get(vueAppApiBaseAddress() + `v1/Apparatus/${request.apparatusId}/manuals`, {
      withCredentials: true
    })
  },
  async getApparatusExerciseSchedule({ commit }, apparatusId) {
    await Axios.get(vueAppApiBaseAddress() + `v1/Apparatus/exercise/${apparatusId}`, {
      withCredentials: true
    })
      .then(response => {
        commit('setCurrentApparatusExerciseSchedule', response.data)
      })
      .catch(error => {})
  },
  async saveApparatusExerciseSchedule({ commit }, value) {
    return await Axios.put(
      vueAppApiBaseAddress() + `v1/Apparatus/exercise/${value.apparatusId}`,
      { interval: value.interval, day: value.day, timeUtc: value.timeUtc },
      { withCredentials: true }
    )
  },
  async exerciseApparatusNow({ commit }, value) {
    return await Axios.get(vueAppApiBaseAddress() + `v1/Apparatus/exerciseNow/${value}`, {
      withCredentials: true
    })
  },
  async CreateApparatusMaintenanceHistory({ commit }, value) {
    return await Axios.put(
      vueAppApiBaseAddress() + `v1/Apparatus/maintenanceHistory/${value.apparatusId}`,
      value.newMaintenanceRecord,
      { withCredentials: true }
    )
  },
  async ValidateDevice({ commit }, request) {
    return await Axios.post(vueAppApiBaseAddress() + `v1/Apparatus/enrollment/validate/device`, request, {
      withCredentials: true
    })
  },
  async AddApparatusDevice({ commit }, request) {
    return await Axios.post(vueAppApiBaseAddress() + `v1/Apparatus/enrollment/device`, request, {
      withCredentials: true
    })
  },
  async ValidateSerialNumber({ commit }, serialNumber) {
    return await Axios.get(vueAppApiBaseAddress() + `v1/Apparatus/enrollment/validate/serialNumber/${serialNumber}`, {
      withCredentials: true
    })
  },
  async ValidateSerialNumberWithQueryParams({ commit }, request) {
    return await Axios.get(
      vueAppApiBaseAddress() +
        `v1/Apparatus/enrollment/validate/serialNumber/${request.serialNumber}?apparatusType=${request.apparatusType}`,
      {
        withCredentials: true
      }
    )
  },
  async EnrollApparatus({ commit }, request) {
    return await Axios.post(vueAppApiBaseAddress() + `v1/Apparatus/enrollment`, request, {
      withCredentials: true
    })
  },
  async updateApparatusInfo({ commit }, value) {
    return await Axios.put(
      vueAppApiBaseAddress() + `v1/Apparatus/update/${value.apparatusId}`,
      { name: value.name, address: value.address },
      { withCredentials: true }
    )
  },
  async removeGenerator({ commit }, request) {
    return await Axios.delete(
      vueAppApiBaseAddress() + `v1/Apparatus/remove/${request.organizationId}/${request.apparatusId}`,
      {
        withCredentials: true
      }
    )
  },
  async removeDeviceFromApparatus({ commit }, request) {
    return await Axios.delete(vueAppApiBaseAddress() + `v1/Apparatus/device/remove/${request.apparatusId}`, {
      withCredentials: true
    })
  },
  async deleteApparatusMaintenanceHistory({ commit }, request) {
    return await Axios.delete(
      vueAppApiBaseAddress() + `v1/Apparatus/maintenanceHistory/${request.apparatusId}/${request.serviceHistoryId}`,
      {
        withCredentials: true
      }
    )
  },
  async editApparatusMaintenanceHistory({ commit }, request) {
    return await Axios.patch(
      vueAppApiBaseAddress() + `v1/Apparatus/maintenanceHistory/${request.apparatusId}/${request.serviceHistoryId}`,
      request.editedRecord,
      {
        withCredentials: true
      }
    )
  },
  async setApparatusStatus({ commit }, request) {
    return await Axios.patch(
      vueAppApiBaseAddress() + `v1/Apparatus/status/${request.apparatusId}/${request.status}`,
      request.editedRecord,
      {
        withCredentials: true
      }
    )
  },
  async registerSerialNumbers({ commit }, request) {
    return await Axios.post(
      vueAppApiBaseAddress() + `v1/Apparatus/${request.organizationId}/serial/number/register`,
      { serialNumbers: request.serialNumbers, apparatusAddress: request.apparatusAddress },
      {
        withCredentials: true
      }
    )
  },
  async exportStatusHistory({ commit }, request) {
    return await Axios.get(
      vueAppApiBaseAddress() +
        `v1/Apparatus/${request.apparatusId}/export/status/history/${request.dateRange}?timeZone="${unescape(
          request.timeZone
        )}"`,
      { withCredentials: true }
    )
  },
  async clearMaintenance({ commit }, request) {
    return await Axios.delete(vueAppApiBaseAddress() + `v1/Apparatus/${request.apparatusId}/clearMaintenanceAlert`, {
      withCredentials: true
    })
  },
  async exportMaintenanceHistory({ commit }, request) {
    return await Axios.get(
      vueAppApiBaseAddress() +
        `v1/Apparatus/${request.apparatusId}/export/maintenance/history/${request.dateRange}?timeZone="${unescape(
          request.timeZone
        )}"`,
      { withCredentials: true }
    )
  },
  async isRegistered({ commit }, serialNumber) {
    return await Axios.get(vueAppApiBaseAddress() + `v2/Apparatus/isRegistered/${serialNumber}`, {
      withCredentials: true
    })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
