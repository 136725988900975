<template>
  <div class="card" header-bg-variant="white">
    <div class="card-header">
      <div class="align-items-center row">
        <div class="col">
          <h1 class="mb-0 pb-0">
            <i class="far fa-user-circle colorOrange"></i>
            <span class="ml-2" data-test-hook="display-prefs-card-title-account-settings">
              {{ $t('xDisplayPreferences') }}
            </span>
          </h1>
        </div>
        <div class="col-auto">
          <button
            class="btn btn-icon-button"
            @click="openModal('EditPreferencesModal')"
            data-test-hook="open-button-display-preferences-card"
          >
            <i class="fas fa-pencil" />
          </button>
        </div>
      </div>
    </div>

    <div class="card-body">
      <div class="d-flex">
        <div class="text-center pr-1">
          <i class="far fa-fw fa-ruler-combined colorOrange" />
        </div>
        <div>
          <strong class="d-block pb-1" data-test-hook="display-prefs-distance-unit-title-account-settings">
            {{ $t('xDistanceUnits') }}
          </strong>
          <p class="text-capitalize" data-test-hook="display-prefs-selected-distance-unit-account-settings">
            {{ distanceUom }}
          </p>
        </div>
      </div>
      <div class="d-flex mt-3">
        <div class="text-center pr-1">
          <i class="far fa-fw fa-thermometer-half colorOrange" />
        </div>
        <div>
          <strong class="d-block pb-1" data-test-hook="display-prefs-temp-unit-title-account-settings">
            {{ $t('xTemperatureUnits') }}
          </strong>
          <p class="text-capitalize" data-test-hook="display-prefs-selected-temp-unit-account-settings">
            {{ temperatureUom }}
          </p>
        </div>
      </div>
      <div class="d-flex mt-3">
        <div class="text-center pr-1">
          <i class="far fa-fw fa-water colorOrange" />
        </div>
        <div>
          <strong class="d-block pb-1" data-test-hook="display-prefs-liquid-unit-title-account-settings">
            {{ $t('xLiquidUnits') }}
          </strong>
          <p class="text-capitalize" data-test-hook="display-prefs-selected-liquid-unit-account-settings">
            {{ liquidUom }}
          </p>
        </div>
      </div>

      <EditPreferencesModal />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import EditPreferencesModal from '@/components/AccountSettings/EditPreferencesModal'

export default {
  components: {
    EditPreferencesModal
  },
  data() {
    return {
      distanceUom: null,
      temperatureUom: null,
      liquidUom: null
    }
  },
  computed: {
    ...mapState({
      accountProfile: state => state.account.accountProfile,
      user: state => state.account.user
    })
  },
  mounted() {
    this.distanceUom = this.user.mobileLinkSettings?.displaySettings?.distanceUom
      ? this.user.mobileLinkSettings.displaySettings.distanceUom
      : 'miles'
    this.temperatureUom = this.user.mobileLinkSettings?.displaySettings?.temperatureUom
      ? this.user.mobileLinkSettings.displaySettings.temperatureUom
      : 'fahrenheit'
    this.liquidUom = this.user.mobileLinkSettings?.displaySettings?.liquidUom
      ? this.user.mobileLinkSettings.displaySettings.liquidUom
      : 'gallons'
  },
  watch: {
    user: function(newVal, oldVal) {
      if (newVal) {
        this.distanceUom = this.user.mobileLinkSettings?.displaySettings?.distanceUom
          ? this.user.mobileLinkSettings.displaySettings.distanceUom
          : 'miles'
        this.temperatureUom = this.user.mobileLinkSettings?.displaySettings?.temperatureUom
          ? this.user.mobileLinkSettings.displaySettings.temperatureUom
          : 'fahrenheit'
        this.liquidUom = this.user.mobileLinkSettings?.displaySettings?.liquidUom
          ? this.user.mobileLinkSettings.displaySettings.liquidUom
          : 'gallons'
      }
    }
  }
}
</script>

<style scoped>
.topRight {
  float: right;
}
.editLgIconAdjustment {
  margin-left: 22px;
}
.accountItem:hover {
  background-color: #ffc69d;
}
.statusText {
  display: inline !important;
  text-transform: capitalize !important;
}
</style>
