var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "signUpDiv" } },
    [
      _c(
        "b-row",
        {
          staticClass: "h-100 m-0",
          attrs: { "align-h": "center", "align-v": "center" }
        },
        [
          _c(
            "b-col",
            { staticClass: "d-flex justify-content-center" },
            [
              _c(
                "b-card",
                { staticClass: "card-shadow login-cards" },
                [
                  _c(
                    "b-row",
                    { staticClass: "my-3 justify-content-center" },
                    [
                      _c(
                        "b-col",
                        { staticClass: "col-10" },
                        [
                          _c("b-img", {
                            attrs: {
                              src: require("@/assets/images/MobileLink_Logo_2023_COLOR.svg"),
                              fluid: "",
                              alt: "Mobile Link Logo"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: { id: "email-group", "label-for": "email" }
                            },
                            [
                              _c("h5", { staticClass: "text-center pb-4" }, [
                                _vm._v(_vm._s(_vm.$t("xSignUp")))
                              ]),
                              _c("b-form-input", {
                                class: _vm.status(_vm.$v.email),
                                attrs: {
                                  state: _vm.validState(_vm.$v.email),
                                  id: "email",
                                  maxlength: "70",
                                  type: "text",
                                  placeholder: _vm.$t("xEmailAddress"),
                                  "aria-describedby": "email-invalid",
                                  formatter: _vm.lowerCaseFormatter,
                                  disabled: _vm.processing
                                },
                                model: {
                                  value: _vm.$v.email.$model,
                                  callback: function($$v) {
                                    _vm.$set(_vm.$v.email, "$model", $$v)
                                  },
                                  expression: "$v.email.$model"
                                }
                              }),
                              _c(
                                "b-form-invalid-feedback",
                                { attrs: { id: "email-invalid" } },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("xRequiredField")) +
                                      ". " +
                                      _vm._s(_vm.$t("xEmailInvalidError")) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c(
                        "b-row",
                        { staticClass: "mt-1 mb-3" },
                        [
                          _c(
                            "b-col",
                            [
                              _c(
                                "b-button",
                                {
                                  attrs: {
                                    block: "",
                                    variant: "warning",
                                    "data-test-hook":
                                      "next-button-noUserEmailStatus-home"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.next()
                                    }
                                  }
                                },
                                [
                                  _vm._v(" " + _vm._s(_vm.$t("xNext")) + " "),
                                  _vm.processing
                                    ? _c("b-spinner", { staticClass: "ml-2" })
                                    : _vm._e(),
                                  _c("span", { staticClass: "pl-1" }, [
                                    _c("i", {
                                      staticClass: "far fa-arrow-right"
                                    })
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c(
                        "b-row",
                        { staticClass: "mt-1 mb-3" },
                        [
                          _c(
                            "b-col",
                            [
                              _c("span", { staticClass: "font-weight-bold" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("Already have an account?")) +
                                    " "
                                )
                              ]),
                              _c(
                                "b-button",
                                {
                                  staticClass: "mb-1 font-weight-bold",
                                  staticStyle: {
                                    "text-transform": "capitalize !important",
                                    "font-family":
                                      "'Roboto', sans-serif !important"
                                  },
                                  attrs: {
                                    variant: "link",
                                    "data-test-hook":
                                      "signIn-button-noUserEmailStatus-signUp"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.signIn()
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("lowercase")(_vm.$t("xSignIn"))
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }