var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mt-2 ml-md-2" },
    [
      !_vm.statusHistory
        ? _c(
            "div",
            { staticClass: "d-flex justify-content-center mb-3 mt-4" },
            [
              _c("span", {
                staticClass: "spinner-border text-warning",
                staticStyle: { width: "5rem", height: "5rem" },
                attrs: { label: "Spinning" }
              })
            ]
          )
        : _c("div", [
            _c("div", { staticClass: "row no-gutters mb-2" }, [
              _c("div", { staticClass: "col" }, [
                _c(
                  "h1",
                  {
                    staticClass: "mb-0 pb-0",
                    attrs: { "data-test-hook": "stsHistPageTitle" }
                  },
                  [
                    _c("i", { staticClass: "far fa-history colorOrange mr-1" }),
                    _vm._v(" " + _vm._s(_vm.$t("xStatusHistory")) + " ")
                  ]
                )
              ]),
              _c(
                "div",
                { staticClass: "cols-auto d-flex align-content-flex-end" },
                [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-icon-button",
                      attrs: {
                        type: "butotn",
                        "data-test-hook": "export-button-status-history"
                      },
                      on: {
                        click: function($event) {
                          _vm.openModal("selectStatusHistoryDateRange"),
                            _vm.trackAppInsightsEvent(
                              "open-export-status-history-modal",
                              {
                                page: "Status History",
                                organizationId:
                                  _vm.accountProfile.organizationId,
                                userId: _vm.accountProfile.userId,
                                apparatusId: _vm.$route.params.apparatusId
                              }
                            )
                        }
                      }
                    },
                    [_c("i", { staticClass: "fas fa-file-export" })]
                  )
                ]
              )
            ]),
            _vm.hasDisconnectedNotificationsOn &&
            _vm.currentApparatusDetails.apparatusStatus ==
              _vm.disconnectedStatus
              ? _c("div", { staticClass: "row mb-3" }, [
                  _c("div", { staticClass: "col" }, [
                    _c(
                      "div",
                      {
                        staticClass: "card snooze-card",
                        attrs: { "data-test-hook": "snoozeCard" }
                      },
                      [
                        _c("div", { staticClass: "card-body" }, [
                          _c(
                            "div",
                            {
                              staticClass: "snooze-card-title",
                              attrs: { "data-test-hook": "snoozeCardTitle" }
                            },
                            [
                              _vm.hsbWifiDeviceType()
                                ? _c("span", [
                                    _c("i", {
                                      staticClass: "fas fa-wifi-slash"
                                    }),
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("xNoWifiSignal")) +
                                        " "
                                    )
                                  ])
                                : _vm.hsbEthDeviceType()
                                ? _c("span", [
                                    _c("i", {
                                      staticClass: "fas fa-signal-alt-slash"
                                    }),
                                    _vm._v(
                                      " " + _vm._s(_vm.$t("xNoSignal")) + " "
                                    )
                                  ])
                                : _c("span", [
                                    _c("i", {
                                      staticClass: "fas fa-signal-alt-slash"
                                    }),
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("xNoCellularSignal")) +
                                        " "
                                    )
                                  ])
                            ]
                          ),
                          _vm.hsbWifiDeviceType() || _vm.hsbEthDeviceType()
                            ? _c("p", { staticClass: "mt-3" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("xWifiOrEthNotCommunicating", {
                                        0: _vm.currentApparatusDetails.name
                                      })
                                    ) +
                                    " "
                                )
                              ])
                            : _c("p", { staticClass: "mt-3" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("xLteOrCdmaNotCommunicating", {
                                        0: _vm.currentApparatusDetails.name
                                      })
                                    ) +
                                    " "
                                )
                              ]),
                          _vm.showSnoozeMessage()
                            ? _c("p", { staticClass: "mt-3" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "xYourNoSignalNotificationHasBeenSnoozedUntil",
                                        {
                                          0: _vm.displayShortDate(
                                            _vm.disconnectedNotification
                                              .snoozeUntil
                                          ),
                                          1: _vm.displayExtraShortTimeWithTZ(
                                            _vm.disconnectedNotification
                                              .snoozeUntil
                                          )
                                        }
                                      )
                                    ) +
                                    " "
                                )
                              ])
                            : _vm._e(),
                          _c("div", { staticClass: "row align-middle" }, [
                            _c(
                              "div",
                              { staticClass: "col-auto align-middle" },
                              [
                                !_vm.showSnoozeMessage()
                                  ? _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-outline-warning",
                                        attrs: {
                                          type: "button",
                                          "data-test-hook":
                                            "snoozeNotificationBtn"
                                        },
                                        on: {
                                          click: function($event) {
                                            _vm.openSnoozeModal(),
                                              _vm.trackSnoozeAnalytics(
                                                "snooze-notification-button-clicked"
                                              )
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t("xSnoozeNotification")
                                            ) +
                                            " "
                                        )
                                      ]
                                    )
                                  : _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-outline-warning",
                                        attrs: { type: "button" },
                                        on: {
                                          click: function($event) {
                                            _vm.openResumeNotificationModal(),
                                              _vm.trackSnoozeAnalytics(
                                                "resume-notification-button-clicked"
                                              )
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t("xResumeNotification")
                                            ) +
                                            " "
                                        )
                                      ]
                                    )
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "col-auto float-left mr-2 troubleshoot-link-col"
                              },
                              [
                                _c(
                                  "a",
                                  {
                                    staticClass: "snooze-troubleshoot-link",
                                    attrs: {
                                      target: "_blank",
                                      href:
                                        _vm.hsbWifiDeviceType() ||
                                        _vm.hsbEthDeviceType()
                                          ? "https://support.generac.com/mobilelink/s/global-search/wifi%20troubleshooting"
                                          : "https://support.generac.com/mobilelink/s/global-search/LTE"
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "fa-solid fa-circle-question colorOrange"
                                    }),
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("xTroubleshootingHelp")) +
                                        " "
                                    )
                                  ]
                                )
                              ]
                            )
                          ])
                        ])
                      ]
                    )
                  ])
                ])
              : _vm._e(),
            _c(
              "div",
              { staticClass: "card" },
              _vm._l(_vm.statusHistory, function(status, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "list-group list-group-flush" },
                  [
                    _c(
                      "div",
                      { staticClass: "list-group-item", attrs: { id: index } },
                      [
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "cols-auto" }, [
                            _c(
                              "small",
                              {
                                attrs: {
                                  "data-test-hook":
                                    "StatusHistoryItemDateTime" + index
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.displayShortDateTimeWithTZ(
                                      status.eventOccurred
                                    )
                                  )
                                )
                              ]
                            )
                          ])
                        ]),
                        _c(
                          "div",
                          { staticClass: "row no-gutters align-items-center" },
                          [
                            _c(
                              "div",
                              { staticClass: "col-auto" },
                              [
                                _c("StatusIcon", {
                                  attrs: {
                                    apparatusStatus: status.type,
                                    isConnected: true,
                                    showWarning: false,
                                    small: true,
                                    deviceType:
                                      _vm.currentApparatusDetails.deviceType ==
                                      _vm.hsbG3DeviceType
                                        ? _vm.currentApparatusDetails
                                            .networkType
                                        : _vm.currentApparatusDetails
                                            .deviceType,
                                    "data-test-hook":
                                      "StatusHistoryItemIcon" + index
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "col ml-2",
                                attrs: {
                                  "data-test-hook":
                                    "StatusHistoryItemMessage" + index
                                }
                              },
                              [_vm._v(" " + _vm._s(status.message) + " ")]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                )
              }),
              0
            )
          ]),
      _c(
        "Modal",
        {
          attrs: { modalId: "selectStatusHistoryDateRange", size: "modal-md" },
          on: {
            hidden: function($event) {
              _vm.selectedDateRange = null
            }
          }
        },
        [
          _c(
            "ModalHeader",
            {
              attrs: {
                title: _vm.$t("xExportStatusHistory"),
                icon: "fas fa-file-export colorOrange mr-1"
              }
            },
            [
              _c("div", { staticClass: "cols-auto float-right" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-icon-button btn-icon-button--gray",
                    attrs: {
                      type: "button",
                      "data-test-hook":
                        "x-button-selectStatusHistoryDateRange-status-history"
                    },
                    on: {
                      click: function($event) {
                        return _vm.closeModal("selectStatusHistoryDateRange")
                      }
                    }
                  },
                  [_c("i", { staticClass: "fas fa-times" })]
                )
              ])
            ]
          ),
          _c("ModalBody", [
            _c("div", { staticClass: "row mb-3" }, [
              _c("div", { staticClass: "col" }, [
                _c(
                  "strong",
                  { attrs: { "data-test-hook": "SelectDateRangeLabel" } },
                  [
                    _vm._v(
                      _vm._s(_vm._f("formatLabel")("xSelectYourHistoryRange"))
                    )
                  ]
                )
              ])
            ]),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col" }, [
                _c("div", { staticClass: "list-group list-group-flush" }, [
                  _c("div", { staticClass: "list-group-item" }, [
                    _c("div", { staticClass: "custom-radio" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.selectedDateRange,
                            expression: "selectedDateRange"
                          }
                        ],
                        attrs: {
                          type: "radio",
                          value: "Months6",
                          id: "months6",
                          "data-test-hook":
                            "6-months-radio-selectStatusHistoryDateRange-status-history"
                        },
                        domProps: {
                          checked: _vm._q(_vm.selectedDateRange, "Months6")
                        },
                        on: {
                          change: function($event) {
                            _vm.selectedDateRange = "Months6"
                          }
                        }
                      }),
                      _c(
                        "label",
                        {
                          staticClass: "ml-2",
                          staticStyle: { "margin-bottom": "0px" },
                          attrs: { for: "months6" }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("x6Months")) + " ")]
                      )
                    ])
                  ]),
                  _c("div", { staticClass: "list-group-item" }, [
                    _c("div", { staticClass: "custom-radio" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.selectedDateRange,
                            expression: "selectedDateRange"
                          }
                        ],
                        attrs: {
                          type: "radio",
                          value: "Years1",
                          id: "years1",
                          "data-test-hook":
                            "1-year-radio-selectStatusHistoryDateRange-status-history"
                        },
                        domProps: {
                          checked: _vm._q(_vm.selectedDateRange, "Years1")
                        },
                        on: {
                          change: function($event) {
                            _vm.selectedDateRange = "Years1"
                          }
                        }
                      }),
                      _c(
                        "label",
                        {
                          staticClass: "ml-2",
                          staticStyle: { "margin-bottom": "0px" },
                          attrs: { for: "years1" }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("x1Year")) + " ")]
                      )
                    ])
                  ]),
                  _c("div", { staticClass: "list-group-item" }, [
                    _c("div", { staticClass: "custom-radio" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.selectedDateRange,
                            expression: "selectedDateRange"
                          }
                        ],
                        attrs: {
                          type: "radio",
                          value: "Years2",
                          id: "years2",
                          "data-test-hook":
                            "2-years-radio-selectStatusHistoryDateRange-status-history"
                        },
                        domProps: {
                          checked: _vm._q(_vm.selectedDateRange, "Years2")
                        },
                        on: {
                          change: function($event) {
                            _vm.selectedDateRange = "Years2"
                          }
                        }
                      }),
                      _c(
                        "label",
                        {
                          staticClass: "ml-2",
                          staticStyle: { "margin-bottom": "0px" },
                          attrs: { for: "years2" }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("x2Years")) + " ")]
                      )
                    ])
                  ]),
                  _c("div", { staticClass: "list-group-item" }, [
                    _c("div", { staticClass: "custom-radio" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.selectedDateRange,
                            expression: "selectedDateRange"
                          }
                        ],
                        attrs: {
                          type: "radio",
                          value: "Years3",
                          id: "years3",
                          "data-test-hook":
                            "3-years-radio-selectStatusHistoryDateRange-status-history"
                        },
                        domProps: {
                          checked: _vm._q(_vm.selectedDateRange, "Years3")
                        },
                        on: {
                          change: function($event) {
                            _vm.selectedDateRange = "Years3"
                          }
                        }
                      }),
                      _c(
                        "label",
                        {
                          staticClass: "ml-2",
                          staticStyle: { "margin-bottom": "0px" },
                          attrs: { for: "years3" }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("x3Years")) + " ")]
                      )
                    ])
                  ]),
                  _c("div", { staticClass: "list-group-item" }, [
                    _c("div", { staticClass: "custom-radio" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.selectedDateRange,
                            expression: "selectedDateRange"
                          }
                        ],
                        attrs: {
                          type: "radio",
                          value: "All",
                          id: "all",
                          "data-test-hook":
                            "all-radio-selectStatusHistoryDateRange-status-history"
                        },
                        domProps: {
                          checked: _vm._q(_vm.selectedDateRange, "All")
                        },
                        on: {
                          change: function($event) {
                            _vm.selectedDateRange = "All"
                          }
                        }
                      }),
                      _c(
                        "label",
                        {
                          staticClass: "ml-2",
                          staticStyle: { "margin-bottom": "0px" },
                          attrs: { for: "all" }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("xAll")) + " ")]
                      )
                    ])
                  ])
                ])
              ])
            ])
          ]),
          _c("ModalFooter", [
            _c(
              "button",
              {
                staticClass: "btn btn-outline-warning",
                attrs: {
                  type: "button",
                  "data-test-hook":
                    "cancel-button-selectStatusHistoryDateRange-status-history"
                },
                on: {
                  click: function($event) {
                    return _vm.closeModal("selectStatusHistoryDateRange")
                  }
                }
              },
              [_vm._v(" " + _vm._s(_vm.$t("xCancel")) + " ")]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-warning",
                attrs: {
                  type: "button",
                  disabled:
                    _vm.downloadingStatusHistory || !_vm.selectedDateRange,
                  "data-test-hook":
                    "download-button-selectStatusHistoryDateRange-status-history"
                },
                on: {
                  click: function($event) {
                    _vm.exportStatusHistory(),
                      _vm.trackAppInsightsEvent("download-status-history", {
                        page: "Status History",
                        organizationId: _vm.accountProfile.organizationId,
                        userId: _vm.accountProfile.userId,
                        apparatusId: _vm.$route.params.apparatusId,
                        dateRange: _vm.selectedDateRange
                      })
                  }
                }
              },
              [
                _c("i", { staticClass: "fas fa-file-csv mr-1" }),
                _vm._v(" " + _vm._s(_vm.$t("xDownload")) + " "),
                _vm.downloadingStatusHistory
                  ? _c("span", { staticClass: "spinner-border" })
                  : _vm._e()
              ]
            )
          ])
        ],
        1
      ),
      _c(
        "Modal",
        { attrs: { modalId: "snooze-modal" } },
        [
          _c(
            "ModalHeader",
            {
              attrs: {
                title: _vm.$t("xSnoozeNotifications"),
                icon: "fa-regular fa-bell-slash"
              }
            },
            [
              _c(
                "button",
                {
                  staticClass: "btn btn-icon-button btn-icon-button--gray",
                  on: {
                    click: function($event) {
                      _vm.closeSnoozeModal(),
                        _vm.trackSnoozeAnalytics(
                          "snooze-modal-cancel-button-clicked"
                        )
                    }
                  }
                },
                [_c("i", { staticClass: "fas fa-times" })]
              )
            ]
          ),
          _c("ModalBody", [
            _c("p", [
              _vm._v(" " + _vm._s(_vm.$t("xSnoozeNotificationMessage1")) + " ")
            ]),
            _c("p", { staticClass: "mt-3" }, [
              _vm._v(" " + _vm._s(_vm.$t("xSnoozeNotificationMessage2")) + " ")
            ]),
            _c("p", { staticClass: "mt-3" }, [
              _vm._v(" " + _vm._s(_vm.$t("xSnoozeNotificationUntil")) + " ")
            ]),
            _c("fieldset", { staticClass: "form-group" }, [
              _c("div", { staticClass: "mt-3 custom-control custom-radio" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.selectDateTime,
                      expression: "selectDateTime"
                    }
                  ],
                  staticClass: "custom-control-input",
                  attrs: { id: "1DayRadio", type: "radio", value: "1Day" },
                  domProps: { checked: _vm._q(_vm.selectDateTime, "1Day") },
                  on: {
                    change: function($event) {
                      _vm.selectDateTime = "1Day"
                    }
                  }
                }),
                _c(
                  "label",
                  {
                    staticClass: "custom-control-label",
                    attrs: { for: "1DayRadio" }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("xSnoozeUntilTomorrow", {
                            0: _vm.displayShortDate(
                              _vm.$moment().add(1, "days")
                            ),
                            1: _vm.displayExtraShortTimeWithTZ(
                              _vm.$moment().add(1, "days")
                            )
                          })
                        ) +
                        " "
                    )
                  ]
                )
              ]),
              _c("div", { staticClass: "mt-2 custom-control custom-radio" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.selectDateTime,
                      expression: "selectDateTime"
                    }
                  ],
                  staticClass: "custom-control-input",
                  attrs: {
                    id: "1WeekRadio",
                    type: "radio",
                    value: "1Week",
                    checked: ""
                  },
                  domProps: { checked: _vm._q(_vm.selectDateTime, "1Week") },
                  on: {
                    change: function($event) {
                      _vm.selectDateTime = "1Week"
                    }
                  }
                }),
                _c(
                  "label",
                  {
                    staticClass: "custom-control-label",
                    attrs: { for: "1WeekRadio" }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("xSnoozeUntilNextWeek", {
                            0: _vm.displayShortDate(
                              _vm.$moment().add(7, "days")
                            ),
                            1: _vm.displayExtraShortTimeWithTZ(
                              _vm.$moment().add(7, "days")
                            )
                          })
                        ) +
                        " "
                    )
                  ]
                )
              ]),
              _c("div", { staticClass: "mt-2 custom-control custom-radio" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.selectDateTime,
                      expression: "selectDateTime"
                    }
                  ],
                  staticClass: "custom-control-input",
                  attrs: {
                    id: "DateTimeRadio",
                    type: "radio",
                    value: "custom"
                  },
                  domProps: { checked: _vm._q(_vm.selectDateTime, "custom") },
                  on: {
                    change: function($event) {
                      _vm.selectDateTime = "custom"
                    }
                  }
                }),
                _c(
                  "label",
                  {
                    staticClass: "custom-control-label align-middle",
                    attrs: { for: "DateTimeRadio" }
                  },
                  [
                    _c("span", {
                      domProps: { innerHTML: _vm._s(_vm.$t("xDateAndTime")) }
                    })
                  ]
                )
              ])
            ]),
            _vm.showCustomDateTime
              ? _c("div", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.customDate,
                        expression: "customDate"
                      }
                    ],
                    staticClass: "form-control mt-2",
                    attrs: {
                      id: "customDate",
                      type: "date",
                      min: _vm.datePickerMinDate(),
                      max: _vm.datePickerMaxDate()
                    },
                    domProps: { value: _vm.customDate },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) return
                        _vm.customDate = $event.target.value
                      }
                    }
                  }),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.customTime,
                        expression: "customTime"
                      }
                    ],
                    staticClass: "form-control mt-2",
                    attrs: { id: "customTime", type: "time" },
                    domProps: { value: _vm.customTime },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) return
                        _vm.customTime = $event.target.value
                      }
                    }
                  })
                ])
              : _vm._e()
          ]),
          _c("ModalFooter", [
            _c(
              "button",
              {
                staticClass: "btn btn-outline-warning",
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    _vm.closeSnoozeModal(),
                      _vm.trackSnoozeAnalytics(
                        "snooze-modal-cancel-button-clicked"
                      )
                  }
                }
              },
              [_vm._v(" " + _vm._s(_vm.$t("xCancel")) + " ")]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                attrs: {
                  type: "button",
                  disabled: _vm.saveSnoozeDisabled() || _vm.settingSnooze
                },
                on: {
                  click: function($event) {
                    ;(_vm.resumeSnooze = false), _vm.setSnooze()
                  }
                }
              },
              [
                _vm._v(" " + _vm._s(_vm.$t("xSave")) + " "),
                _vm.settingSnooze
                  ? _c("b-spinner", { staticClass: "ml-2" })
                  : _vm._e()
              ],
              1
            )
          ])
        ],
        1
      ),
      _c(
        "Modal",
        { attrs: { modalId: "resume-modal" } },
        [
          _c(
            "ModalHeader",
            {
              attrs: {
                title: _vm.$t("xResumeNotification"),
                icon: "fa-regular fa-bell-on"
              }
            },
            [
              _c(
                "button",
                {
                  staticClass: "btn btn-icon-button btn-icon-button--gray",
                  on: {
                    click: function($event) {
                      return _vm.closeResumeNotificationModal()
                    }
                  }
                },
                [_c("i", { staticClass: "fas fa-times" })]
              )
            ]
          ),
          _c("ModalBody", [
            _c("p", [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t("xResumeNotificationMessage", {
                      0: _vm.currentApparatusDetails.name
                    })
                  ) +
                  " "
              )
            ]),
            _c("p", { staticClass: "mt-3" }, [
              _vm._v(" " + _vm._s(_vm.$t("xResumeNotificationMessage2")) + " ")
            ])
          ]),
          _c("ModalFooter", [
            _c(
              "button",
              {
                staticClass: "btn btn-outline-warning",
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    return _vm.closeResumeNotificationModal()
                  }
                }
              },
              [_vm._v(" " + _vm._s(_vm.$t("xNoCancel")) + " ")]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                attrs: { type: "button", disabled: _vm.settingSnooze },
                on: {
                  click: function($event) {
                    ;(_vm.resumeSnooze = true), _vm.setSnooze()
                  }
                }
              },
              [
                _vm._v(" " + _vm._s(_vm.$t("xYesImSure")) + " "),
                _vm.settingSnooze
                  ? _c("b-spinner", { staticClass: "ml-2" })
                  : _vm._e()
              ],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }