<template>
  <div v-if="messageCenterEnabled(currentAppConfig)">
    <div v-if="cdmaWarning" @click="trackAppInsightsEvent('cdma-banner-click')" class="pt-3">
      <b-link :href="cdmaWarning.link" target="_blank" style="text-decoration: none !important;">
        <b-alert variant="warning" class="hoverPointer mb-0" show>
          <b-row>
            <b-col>
              <h2>
                <i class="fas fa-exclamation-triangle banner-category-warning" />
                {{ cdmaWarning.title }}
              </h2>
              <p>{{ cdmaWarning.message }}</p>
              <p class="mb-0 pb-0">
                {{ cdmaWarning.linkText }}
                <i class="fas fa-arrow-right" />
              </p>
            </b-col>
          </b-row>
        </b-alert>
      </b-link>
    </div>
    <div @click="$bvModal.show('banner-message-modal')">
      <b-alert
        v-if="showBannerMessage(bannerMessage)"
        class="hoverPointer"
        show
        :variant="getBannerVariant(bannerMessage)"
      >
        <i :class="getBannerIconClassAndColor(bannerMessage)" />
        {{ bannerMessage.BannerMessage }}
        <strong>
          {{ $t('xLearnMore') }}
          <i class="fas fa-arrow-right" />
        </strong>
      </b-alert>
    </div>

    <b-row class="h-100 py-4" v-if="editPreferencesValue">
      <b-col xl="4" md="5" sm="12" class="pr-md-0">
        <b-card class="h-100" body-class="no-padding" header-bg-variant="white">
          <template #header>
            <b-row align-v="center" no-gutters>
              <b-col>
                <h1 class="mb-0 pb-0" data-test-hook="msgCenterPageHeader">{{ $t('xMessageCenterInbox') }}</h1>
              </b-col>
              <b-col class="float-right" cols="auto">
                <b-button
                  variant="icon-button"
                  @click="$bvModal.show('message-center-settings-modal')"
                  data-test-hook="msgCenterSettingsBtn"
                >
                  <i class="far fa-cog m-0 px-1" data-test-hook="msgCenterSettingsBtnIcon" />
                </b-button>
              </b-col>
            </b-row>
          </template>
          <b-row no-gutters>
            <b-col>
              <MessagePreview @messageDetails="setMessageDetails" />
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col xl="8" md="7" sm="12">
        <MessageDetails @selectedCategory="setSelectedCategory" :value="messageDetails" />
      </b-col>
    </b-row>

    <b-modal id="message-center-settings-modal" centered>
      <template #modal-header>
        <b-row class="w-100" align-v="center" no-gutters>
          <b-col>
            <h2 class="modal-title" data-test-hook="modalTitle">
              <i class="far fa-bell-on colorOrange mr-1" data-test-hook="modalIcon" />
              <span class="ml-2">Message Center Notification Settings</span>
            </h2>
          </b-col>
        </b-row>
      </template>
      <b-row>
        <b-col>
          <p data-test-hook="modalBodyHeader">Receive message center notifications via:</p>
          <div class="d-flex align-items-center mt-3">
            <div class="notification-type-icon">
              <i class="far fa-envelope" data-test-hook="modalEmailToggleIcon" />
            </div>
            <div class="flex-grow-1 ml-2 mr-4" data-test-hook="modalEmailToggleText">{{ $t('xEmail') }}</div>
            <div>
              <b-form-checkbox
                class="switch"
                :class="editPreferencesValue.receiveEmail ? 'switch-success' : null"
                v-model="editPreferencesValue.receiveEmail"
                switch
                size="lg"
                :disabled="savingEmailPreference"
              >
              </b-form-checkbox>
            </div>
          </div>
          <div class="d-flex align-items-center mt-3">
            <div class="notification-type-icon">
              <i class="far fa-mobile-android-alt" data-test-hook="modalTextSmsToggleIcon" />
            </div>
            <div class="flex-grow-1 ml-2 mr-4" data-test-hook="modalTextSmsToggleText">{{ $t('xTextSms') }}</div>
            <div>
              <b-form-checkbox
                class="switch"
                :class="editPreferencesValue.receiveSms ? 'switch-success' : null"
                v-model="editPreferencesValue.receiveSms"
                size="lg"
                switch
                :disabled="savingSmsPreference"
              >
              </b-form-checkbox>
            </div>
          </div>
        </b-col>
      </b-row>
      <template #modal-footer>
        <b-button
          variant="primary"
          @click="$bvModal.hide('message-center-settings-modal')"
          data-test-hook="modalDoneBtn"
        >
          Done
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import MessagePreview from '@/components/MessageCenter/MessagePreview'
import MessageDetails from '@/components/MessageCenter/MessageDetails'
import * as Constants from '@/constants'

export default {
  components: { MessagePreview, MessageDetails },
  data() {
    return {
      messageDetails: null,
      messageCenterNotificationSettingsModalInfo: null,
      editValue: {
        receiveEmail: true,
        receiveSms: true,
        receivePush: true
      },
      savingEmailPreference: false,
      savingSmsPreference: false,
      editPreferencesValue: null,
      preferenceSaving: [],
      selectedCategory: -1
    }
  },
  computed: {
    ...mapState({
      currentAppConfig: state => state.application.currentAppConfig,
      messages: state => state.account.messages,
      bannerMessage: state => state.application.bannerMessage,
      currentMessageCenterPreferences: state => state.messageCenter.messageCenterPreferences,
      cdmaWarning: state => state.cdmaBanner.cdmaWarning
    })
  },
  methods: {
    setSelectedCategory(value) {
      if (this.selectedCategory == -1) {
        this.selectedCategory = value
        return
      } else {
        if (this.selectedCategory == value) return
        else {
          this.selectedCategory = value
          this.$store.dispatch('getMessages')
          this.$store.dispatch('getHasUnreadMessages')
        }
      }
    },
    goTo(routeName) {
      this.$router.push({ name: routeName })
    },
    setMessageDetails(value) {
      this.messageDetails = value
    }
  },
  watch: {
    'editPreferencesValue.receiveEmail': function(newVal, oldVal) {
      if (oldVal == undefined) return //disable watcher for first load
      this.savingEmailPreference = true
      this.$store
        .dispatch('updateMessagePreferences', {
          type: this.editPreferencesValue.type,
          receiveEmail: this.editPreferencesValue.receiveEmail,
          receiveSms: this.editPreferencesValue.receiveSms,
          receivePush: this.editPreferencesValue.receivePush
        })
        .then(response => {})
        .catch(error => {})
        .finally(() => {
          this.savingEmailPreference = false
        })
    },
    'editPreferencesValue.receiveSms': function(newVal, oldVal) {
      if (oldVal == undefined) return //disable watcher for first load
      this.savingSmsPreference = true
      this.$store
        .dispatch('updateMessagePreferences', {
          type: this.editPreferencesValue.type,
          receiveEmail: this.editPreferencesValue.receiveEmail,
          receiveSms: this.editPreferencesValue.receiveSms,
          receivePush: this.editPreferencesValue.receivePush
        })
        .then(response => {})
        .catch(error => {})
        .finally(() => {
          this.savingSmsPreference = false
        })
    },
    currentMessageCenterPreferences: function(newVal, oldVal) {
      this.editPreferencesValue = _.cloneDeep(newVal)
    },
    deep: true
  },
  beforeMount() {
    if (!this.cdmaWarning) this.$store.dispatch('getCdmaWarning')
  },
  async mounted() {
    if (!this.currentAppConfig) this.$store.dispatch('getApplicationConfiguration', 'Any')
    if (!this.currentMessageCenterPreferences) await this.$store.dispatch('getMessageCenterPreferences')
    this.editPreferencesValue = _.cloneDeep(this.currentMessageCenterPreferences)
    this.$store.dispatch('getMessages')
  }
}
</script>
<style scoped>
.notification-type-icon {
  width: 1.25em;
  text-align: center;
}
</style>
