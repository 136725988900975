<template>
  <Modal modalId="EditFuelLevelWarningModal">
    <ModalHeader
      :title="$t('xEditFuelLevelWarning')"
      icon="far fa-warning"
      data-test-hook="editFuelLevelModalTitle"
    ></ModalHeader>
    <ModalBody>
      <div class="row" style="margin-bottom: 8px;">
        <div class="col" data-test-hook="warningLevelHeading">
          {{ $t('xWarningLevel') }}
        </div>
        <div class="col">
          <strong class="float-right smaller-text" data-test-hook="fuel-level-warning-value">{{ level }}%</strong>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <b-input
            id="fuel-level-range"
            v-model="level"
            type="range"
            min="20"
            max="80"
            step="1"
            data-test-hook="fuel-level-warning"
          />
        </div>
      </div>
      <div class="row" style="margin-bottom: 36px;">
        <div class="col" data-test-hook="min-fuel-level-warning">
          <strong class="smaller-text">
            20%
          </strong>
        </div>
        <div class="col" data-test-hook="max-fuel-level-warning">
          <strong class="float-right smaller-text">
            80%
          </strong>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <span class="smaller-text" style="color: var(--carbon-700);" data-test-hook="modalBodyText">
            {{ $t('xEditFuelLevelWarningNotice') }}
          </span>
        </div>
      </div>
    </ModalBody>
    <ModalFooter>
      <button
        class="btn btn-secondary"
        @click="closeModal('EditFuelLevelWarningModal')"
        data-test-hook="cancel-fuel-level-warning-button"
      >
        {{ $t('xCancel') }}
      </button>
      <button
        class="btn btn-warning"
        :disabled="saving"
        @click="onSave()"
        data-test-hook="save-fuel-level-warning-button"
      >
        {{ $t('xSave') }}
        <div class="spinner-border text-muted ml-2" label="Spinning" v-if="saving"></div>
      </button>
    </ModalFooter>
  </Modal>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: ['threashold'],
  computed: {
    ...mapState({
      currentApparatusDetails: state => state.apparatus.currentApparatusDetails,
      currentFuelWarning: state => state.tankUtility.fuelLevelWarning
    })
  },
  data() {
    return {
      level: this.threashold,
      saving: false
    }
  },
  methods: {
    async onSave() {
      this.saving = true
      await this.$store
        .dispatch('updateTankUtilityApparatus', {
          apparatusId: this.currentApparatusDetails.apparatusId,
          threshold1: this.level
        })
        .then(resopnse => {
          this.saving = false
          this.closeModal('EditFuelLevelWarningModal')
          this.$store.dispatch('displayToast', {
            title: this.$t('xSuccess'),
            message: this.$t('xTankDetailsUpdated'),
            variant: 'success'
          })
        })
    }
  }
}
</script>

<style scoped>
.notification-information {
  color: var(--carbon-700) !important;
}
.smaller-text {
  color: var(--carbon-900);
  font-size: 14px !important;
  line-height: 16px !important;
}
</style>
