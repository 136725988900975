import Axios from 'axios'
import router from '@/router/'
import i18n from '@/lang/'
import { vueAppApiBaseAddress } from '@/utils/mixins'

var initialState = {
  impersonatedEmail: null,
  isImpersonating: false
}

export const state = Object.assign({}, initialState)

export const getters = {
  email: state => state.user.email,
  impersonatedEmail: state => state.impersonatedEmail,
  isImpersonating: state => state.isImpersonating
}

export const mutations = {
  authReset(state) {
    const initial = initialState
    Object.keys(initial).forEach(key => {
      state[key] = initial[key]
    })
  },
  setUser(state, user) {
    state.user = user
  },
  setEmail(state, email) {
    state.user.email = email
  },
  setImpersonatedEmail(state, email) {
    state.impersonatedEmail = email
  },
  setIsImpersonating(state, val) {
    state.isImpersonating = val
  }
}

export const actions = {
  async signout({ getters, commit, dispatch }) {
    await Axios.get(vueAppApiBaseAddress() + `Auth/SignOut`, {
      withCredentials: true
    })
      .then(response => {})
      .catch(error => {})
  },
  async authorize({ getters, commit, dispatch }, routeName) {
    return await Axios.get(vueAppApiBaseAddress() + `Auth/Authorize/${routeName}`, {
      withCredentials: true
    })
  },
  async setEmail({ commit, dispatch }, email) {
    await Axios.get(vueAppApiBaseAddress() + `Auth/Impersonate?email=${encodeURIComponent(email)}`, {
      withCredentials: true
    })
      .then(response => {
        commit('setImpersonatedEmail', email)
        commit('setIsImpersonating', true)
        router.push('/dashboard')
      })
      .catch(error => {
        if (error.response.data.status == 401) {
          dispatch('displayToast', {
            title: i18n.t('xError'),
            message: i18n.t('xYourSessionHasExpiredPleaseReLogin'),
            variant: 'danger'
          })
          dispatch('clearAll')
          window.location.replace(vueAppApiBaseAddress() + `Auth/SignOut`)
        } else {
          dispatch('displayToast', {
            title: i18n.t('xError'),
            message: i18n.t('xNoOwnerAccount'),
            variant: 'danger'
          })
        }
      })
  },
  async removeEmail({ commit }) {
    await Axios.delete(vueAppApiBaseAddress() + `Auth/Impersonate`, {
      withCredentials: true
    })
      .then(response => {
        commit('setImpersonatedEmail', null)
        commit('setIsImpersonating', false)
      })
      .catch(error => {
        if (error.response.data.status == 401) {
          dispatch('displayToast', {
            title: i18n.t('xError'),
            message: i18n.t('xYourSessionHasExpiredPleaseReLogin'),
            variant: 'danger'
          })
          dispatch('clearAll')
          window.location.replace(vueAppApiBaseAddress() + `Auth/SignOut`)
        }
      })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
