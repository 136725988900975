var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal hide-footer no-stacking",
      attrs: {
        id: "DeleteAccountModal",
        "data-test-hook": "modal-title-delete-account-modal"
      }
    },
    [
      _c("div", { staticClass: "modal-dialog modal-dialog-centered" }, [
        _c("div", { staticClass: "modal-content" }, [
          _c("div", { staticClass: "modal-header" }, [
            _c(
              "h2",
              {
                staticClass: "modal-title",
                attrs: {
                  "data-test-hook": "modal-heading-delete-account-modal"
                }
              },
              [
                _c("i", { staticClass: "far fa-trash-alt colorOrange mr-1" }),
                _vm._v(" " + _vm._s(_vm.$t("xDeleteAccount")) + " ")
              ]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-icon-button btn-icon-button--gray",
                attrs: {
                  "data-test-hook": "x-close-button-delete-account-modal"
                },
                on: {
                  click: function($event) {
                    return _vm.closeModal()
                  }
                }
              },
              [_c("i", { staticClass: "fas fa-times" })]
            )
          ]),
          _c("div", { staticClass: "modal-body" }, [
            _c("div", { staticClass: "row", attrs: { "align-h": "around" } }, [
              _c(
                "div",
                {
                  staticClass: "col",
                  attrs: {
                    "data-test-hook": "modal-body1-text-delete-account-modal"
                  }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("xByClickingTheDeleteAccountButtonBelow")) +
                      " "
                  ),
                  _c("b", [
                    _vm._v(_vm._s(_vm.$t("xYourUserProductAndAccountInfo")))
                  ]),
                  _vm._v(
                    " " + _vm._s(_vm.$t("xYouWillNoLongerBeAbleToLogIn")) + " "
                  )
                ]
              )
            ]),
            _c(
              "div",
              { staticClass: "row mt-3 mb-3", attrs: { "align-h": "around" } },
              [
                _c(
                  "div",
                  {
                    staticClass: "col",
                    attrs: {
                      "data-test-hook": "modal-body2-text-delete-account-modal"
                    }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("xAreYouSureYouWantToDeleteYourAccount")
                        ) +
                        " "
                    )
                  ]
                )
              ]
            )
          ]),
          _c("div", { staticClass: "modal-footer" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-secondary",
                attrs: {
                  type: "button",
                  "data-test-hook": "cancel-button-delete-account-modal"
                },
                on: {
                  click: function($event) {
                    return _vm.closeModal()
                  }
                }
              },
              [_vm._v(" " + _vm._s(_vm.$t("xCancel")) + " ")]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                attrs: {
                  "data-test-hook": "delete-button-delete-account-modal"
                },
                on: { click: _vm.confirmDeleteAccount }
              },
              [
                _vm._v(
                  " " +
                    _vm._s(_vm._f("uppercase")(_vm.$t("xDeleteAccount"))) +
                    " "
                )
              ]
            )
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }