<template>
  <div>
    <div v-if="fetching" class="d-flex justify-content-center mt-5 pt-4">
      <span class="spinner-border text-warning" style="width: 5rem; height: 5rem;" label="Spinning"></span>
    </div>
    <div v-if="!fetching" class="mt-2 ml-2">
      <div class="row mb-2">
        <div class="col">
          <h1 class="mb-0 pb-0">
            <i class="far fa-warning colorOrange mr-1" data-test-hook="fuel-level-warning-icon" />
            <span data-test-hook="fuel-level-warning-title-text">{{ $t('xFuelLevelWarning') }}</span>
          </h1>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col">
              <span style="size: 16px; line-height: 20px;" data-test-hook="fuel-level-warning-heading">
                {{ $t('xWarningLevel') }}
              </span>
              <strong>
                <p
                  style="padding-top: 4px;
                  size: 16px;
                  line-height: 20px;"
                  data-test-hook="fuel-level-warning-threshold-value"
                >
                  {{ threashold() + '%' }}
                </p>
              </strong>
            </div>
            <div class="cols float-right">
              <button
                type="button"
                class="btn btn-icon-button"
                @click="openEditModal()"
                data-test-hook="tank-fuel-level-warning-edit-button"
              >
                <i class="far fa-pencil" data-test-hook="fuel-level-warning-edit-button-icon" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <EditFuelLevelWarningModal :threashold="this.threashold()" />
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import EditFuelLevelWarningModal from './EditFuelLevelWarningModal.vue'

export default {
  components: { EditFuelLevelWarningModal },
  computed: {
    ...mapState({
      currentApparatusDetails: state => state.apparatus.currentApparatusDetails
    })
  },
  data() {
    return {
      fetching: false
    }
  },
  methods: {
    openEditModal() {
      this.openModal('EditFuelLevelWarningModal')
    },
    threashold() {
      return this.currentApparatusDetails.tuProperties.find(p => p.type == 7).value ?? 30
    },
    fetchData() {
      this.fetching = true
      this.$store.dispatch('getApparatusDetails', this.currentApparatusDetails.apparatusId).then(response => {
        this.fetching = false
      })
    }
  },
  mounted() {
    this.fetchData()
  }
}
</script>
