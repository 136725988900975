<template>
  <div></div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  computed: {
    ...mapState({
      toastData: state => state.toast.toastData,
      toastLength: state => state.toast.toastLength
    })
  },
  methods: {
    toast(title, message, variant) {
      if (!message) {
        message = 'An unexpected error occurred'
      }
      if (variant == 'danger' || variant == 'warning') {
        this.$bvToast.toast(message, {
          title: title,
          toaster: 'b-toaster-top-center',
          noautohide: true,
          variant: variant,
          solid: true
        })
      } else {
        this.$bvToast.toast(message, {
          title: title,
          toaster: 'b-toaster-top-center',
          autohidedisplay: 3000,
          variant: variant,
          solid: true
        })
      }
    }
  },
  watch: {
    toastLength: function(newVal, oldVal) {
      let old = oldVal
      while (old < newVal) {
        this.toast(this.toastData[old].title, this.toastData[old].message, this.toastData[old].variant)
        old++
      }
    }
  }
}
</script>
