var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.fuelTank
      ? _c(
          "div",
          {
            staticClass: "card",
            class: _vm.getClass(),
            attrs: { "data-test-hook": "tank-utility-apparatus-card" },
            on: {
              click: function($event) {
                !_vm.subscribingWifiProduct ? _vm.detailsOrEnrollment() : ""
              }
            }
          },
          [
            !_vm.enrollmentComplete() && _vm.fuelTank.isDealerManaged
              ? _c("div", [
                  _c(
                    "div",
                    {
                      staticClass:
                        "card-header dashboard-card-header bg-statusWarning"
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "row w-100 no-gutters",
                          attrs: { "data-test-hook": "DealerHeader" }
                        },
                        [
                          _c("div", { staticClass: "float-left col" }, [
                            !_vm.tankHasSubscription()
                              ? _c(
                                  "strong",
                                  {
                                    staticClass: "py-0 my-0",
                                    attrs: { "data-test-hook": "HeaderText" }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("xUpdateYourSubscription")
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              : _c(
                                  "strong",
                                  {
                                    staticClass: "py-0 my-0",
                                    attrs: { "data-test-hook": "HeaderText" }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("xFinishSettingUpYourProduct")
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                          ]),
                          _vm._m(0)
                        ]
                      )
                    ]
                  )
                ])
              : _vm.tankHasSubscription() && !_vm.tankHasAllProperties()
              ? _c(
                  "div",
                  { staticClass: "card-header dashboard-card-header" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "row w-100 no-gutters align-items-center",
                        attrs: {
                          "data-test-hook": "tank-utility-apparatus-card-header"
                        }
                      },
                      [
                        _c("div", { staticClass: "float-left col" }, [
                          _c("p", { staticClass: "py-0 my-0" }, [
                            _c("strong", [
                              _vm._v(_vm._s(_vm.$t("xWaitingForData")))
                            ])
                          ])
                        ])
                      ]
                    )
                  ]
                )
              : !_vm.tankHasSubscription()
              ? _c(
                  "div",
                  {
                    staticClass:
                      "card-header dashboard-card-header bg-statusWarning"
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "row w-100 no-gutters align-items-center",
                        attrs: {
                          "data-test-hook": "tank-utility-apparatus-card-header"
                        }
                      },
                      [
                        _c("div", { staticClass: "float-left col" }, [
                          _c(
                            "p",
                            {
                              staticClass: "py-0 my-0",
                              attrs: {
                                "data-test-hook":
                                  "tank-utility-apparatus-card-header-text"
                              }
                            },
                            [
                              _c("strong", [
                                _vm._v(_vm._s(_vm.$t("xFinishSetup")))
                              ])
                            ]
                          )
                        ]),
                        _vm._m(1)
                      ]
                    )
                  ]
                )
              : !_vm.isConnected
              ? _c(
                  "div",
                  {
                    staticClass:
                      "card-header dashboard-card-header bg-statusDisconnected"
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "row w-100 no-gutters align-items-center",
                        attrs: {
                          "data-test-hook": "tank-utility-apparatus-card-header"
                        }
                      },
                      [
                        _c("div", { staticClass: "float-left col" }, [
                          _c(
                            "p",
                            {
                              staticClass: "py-0 my-0",
                              attrs: {
                                "data-test-hook":
                                  "tank-utility-apparatus-card-header-text"
                              }
                            },
                            [
                              _c("strong", [
                                _vm._v(_vm._s(_vm.noSignalMessage()))
                              ])
                            ]
                          )
                        ]),
                        _vm._m(2)
                      ]
                    )
                  ]
                )
              : _c(
                  "div",
                  { staticClass: "card-header dashboard-card-header" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "row w-100 no-gutters align-items-center",
                        attrs: {
                          "data-test-hook": "tank-utility-apparatus-card-header"
                        }
                      },
                      [
                        _vm.fuelLevel
                          ? _c(
                              "div",
                              {
                                staticClass: "float-left col-auto",
                                attrs: {
                                  "data-test-hook":
                                    "tank-utility-apparatus-card-header-fuel-level"
                                }
                              },
                              [
                                _c("i", {
                                  staticClass: "far fa-gauge-max",
                                  attrs: {
                                    "data-test-hook":
                                      "tank-utility-apparatus-card-header-fuel-level-icon"
                                  }
                                }),
                                _vm._v(" " + _vm._s(_vm.fuelLevel) + "% ")
                              ]
                            )
                          : _vm._e(),
                        _vm.fuelType
                          ? _c(
                              "div",
                              { staticClass: "float-left ml-3 col-auto" },
                              [
                                _c("i", {
                                  staticClass: "far fa-flame mr-1",
                                  attrs: {
                                    "data-test-hook":
                                      "tank-utility-apparatus-card-header-fuel-type-icon"
                                  }
                                }),
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      "text-transform": "capitalize"
                                    },
                                    attrs: {
                                      "data-test-hook":
                                        "tank-utility-apparatus-card-header-fuel-type"
                                    }
                                  },
                                  [_vm._v(_vm._s(_vm.fuelType))]
                                )
                              ]
                            )
                          : _vm._e(),
                        _c(
                          "div",
                          {
                            staticClass: "text-right float-right mr-0 pr-0 col"
                          },
                          [
                            _c(
                              "button",
                              {
                                staticClass:
                                  "btn card-header-button btn-warning",
                                staticStyle: { margin: "-0.75em -0.5em" },
                                on: {
                                  click: function($event) {
                                    return _vm.detailsOrEnrollment()
                                  }
                                }
                              },
                              [_c("i", { staticClass: "fas fa-arrow-right" })]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
            _c("div", { staticClass: "card-body" }, [
              _c("div", { staticClass: "overlay" }),
              _c("div", { staticClass: "row d-flex" }, [
                _c("div", { staticClass: "col" }, [
                  _c("h4", { staticClass: "mb-0 py-2" }, [
                    _c("i", {
                      staticClass: "fa-fw mr-1 fas",
                      class: _vm.noSignalIcon(),
                      attrs: {
                        "data-test-hook":
                          "tank-utility-apparatus-card-disconnected-icon"
                      }
                    }),
                    _vm._v(" " + _vm._s(_vm.getStatusText()) + " ")
                  ])
                ])
              ]),
              _c("div", { staticClass: "row mt-3" }, [
                _c("div", { staticClass: "col-4" }, [
                  _vm.orientation == "horizontal"
                    ? _c("img", {
                        staticClass: "tankImage img-fluid",
                        attrs: {
                          src: require("@/assets/images/tank-image.jpg"),
                          alt: _vm.$t("xHeroImage"),
                          "data-test-hook":
                            "tank-utility-apparatus-card-hero-image"
                        }
                      })
                    : _c("img", {
                        staticClass: "tankImage img-fluid",
                        attrs: {
                          src: require("@/assets/images/vertical-propane-tank.jpg"),
                          alt: _vm.$t("xHeroImage"),
                          "data-test-hook":
                            "tank-utility-apparatus-card-hero-image"
                        }
                      })
                ]),
                _c("div", { staticClass: "col-8" }, [
                  _c("div", { staticClass: "row mt-2" }, [
                    _c(
                      "div",
                      {
                        staticClass: "ellipsis-overflow col",
                        attrs: {
                          "data-test-hook":
                            "tank-utility-apparatus-card-address"
                        }
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.fuelTank.localizedAddress) + " "
                        )
                      ]
                    )
                  ]),
                  _c("div", { staticClass: "row mt-2" }, [
                    _c("div", { staticClass: "col" }, [
                      _c(
                        "h2",
                        {
                          staticClass: "pb-1 mb-0",
                          attrs: {
                            "data-test-hook": "tank-utility-apparatus-tank-name"
                          }
                        },
                        [_vm._v(_vm._s(_vm.fuelTank.name))]
                      )
                    ])
                  ]),
                  _vm.fuelTank.isDealerManaged &&
                  _vm.fuelTank.preferredDealerName
                    ? _c("div", { staticClass: "row mt-2" }, [
                        _c(
                          "div",
                          {
                            staticClass: "ellipsis-overflow col",
                            attrs: {
                              "data-test-hook":
                                "tank-utility-apparatus-card-dealer"
                            }
                          },
                          [
                            _c("i", {
                              staticClass: "fa-light fa-user-helmet-safety",
                              attrs: {
                                "data-test-hook":
                                  "tank-utility-apparatus-card-dealer-icon"
                              }
                            }),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("formatLabel")(
                                    _vm.$t("xDealerManaged")
                                  )
                                ) +
                                " " +
                                _vm._s(_vm.fuelTank.preferredDealerName) +
                                " "
                            )
                          ]
                        )
                      ])
                    : _vm._e()
                ])
              ])
            ]),
            _c("div", { staticClass: "card-footer apparatus-card-footer" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-4" }, [
                  _vm.capacity ||
                  (!_vm.tankHasAllProperties() && _vm.tankHasSubscription())
                    ? _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          {
                            staticClass: "text-small col",
                            attrs: {
                              "data-test-hook":
                                "tank-utility-apparatus-card-fuel-capacity-label"
                            }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("xFuelCapacity")) +
                                " (" +
                                _vm._s(this.abbreviatedLiquidUom) +
                                ") "
                            )
                          ]
                        )
                      ])
                    : _vm._e(),
                  _vm.capacity ||
                  (!_vm.tankHasAllProperties() && _vm.tankHasSubscription())
                    ? _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col" }, [
                          _c(
                            "strong",
                            {
                              attrs: {
                                "data-test-hook":
                                  "tank-utility-apparatus-card-fuel-capacity"
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.capacity
                                    ? this.convertGallonsToPreferredUnit(
                                        _vm.capacity
                                      )
                                    : "N/A"
                                )
                              )
                            ]
                          )
                        ])
                      ])
                    : _vm._e()
                ]),
                _c("div", { staticClass: "col-8" }, [
                  _vm.lastReadingDate ||
                  (!_vm.tankHasAllProperties() && _vm.tankHasSubscription())
                    ? _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          {
                            staticClass: "col",
                            attrs: {
                              "data-test-hook":
                                "tank-utility-apparatus-card-last-reading-date"
                            }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("formatLabel")("xLastReadingDate")
                                ) +
                                " " +
                                _vm._s(
                                  _vm.displayShortDateTimeWithTZ(
                                    _vm.lastReadingDate
                                  )
                                ) +
                                " "
                            )
                          ]
                        )
                      ])
                    : _vm._e()
                ])
              ])
            ])
          ]
        )
      : _vm._e(),
    _vm.fuelTank && _vm.fuelTank.apparatusId
      ? _c(
          "div",
          {
            staticClass: "modal fade show",
            attrs: {
              id: "dealer-managed-sub-expired" + _vm.fuelTank.apparatusId,
              role: "dialog"
            }
          },
          [
            _c("div", { staticClass: "modal-dialog modal-dialog-centered" }, [
              _c("span", { attrs: { tabindex: "0" } }),
              _c(
                "div",
                { staticClass: "modal-content", attrs: { tabindex: "-1" } },
                [
                  _c("header", { staticClass: "modal-header" }, [
                    _c(
                      "div",
                      {
                        staticClass: "row w-100 no-gutters align-items-center"
                      },
                      [
                        _c("div", { staticClass: "col" }, [
                          _c("h2", { staticClass: "modal-title" }, [
                            _c("i", {
                              staticClass:
                                "far fa-badge-dollar colorOrange mr-1"
                            }),
                            _vm._v(
                              " " + _vm._s(_vm.$t("xSubscriptionExpired")) + " "
                            )
                          ])
                        ]),
                        _c("div", { staticClass: "float-right col-auto" }, [
                          _c(
                            "button",
                            {
                              staticClass:
                                "btn btn-icon-button--gray btn-icon-button",
                              attrs: { type: "button" },
                              on: {
                                click: function($event) {
                                  return _vm.closeDealerModal()
                                }
                              }
                            },
                            [_c("i", { staticClass: "fas fa-times" })]
                          )
                        ])
                      ]
                    )
                  ]),
                  _c("div", { staticClass: "modal-body" }, [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "xYourDealerManagedSubscriptionHasExpiredContactDealer"
                              )
                            ) +
                            " "
                        )
                      ])
                    ]),
                    _c("p", { staticClass: "mt-4" }, [
                      _vm._v(
                        " " + _vm._s(_vm._f("formatLabel")("xYourDealer")) + " "
                      )
                    ]),
                    _c("div", { staticClass: "row mt-2" }, [
                      _c(
                        "div",
                        {
                          staticClass: "col",
                          attrs: {
                            "data-test-hook": "ApparatusPreferredDealerName"
                          }
                        },
                        [
                          _c("strong", [
                            _vm._v(_vm._s(_vm.fuelTank.preferredDealerName))
                          ])
                        ]
                      )
                    ]),
                    _c("div", { staticClass: "row mt-2" }, [
                      _c(
                        "div",
                        {
                          staticClass: "col",
                          attrs: {
                            "data-test-hook": "ApparatusPreferredDealerPhone"
                          }
                        },
                        [
                          _c("i", { staticClass: "fa-regular fa-phone pr-2" }),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("phone")(
                                  _vm.fuelTank.preferredDealerPhone
                                )
                              ) +
                              " "
                          )
                        ]
                      )
                    ]),
                    _c("div", { staticClass: "row mt-2" }, [
                      _c(
                        "div",
                        {
                          staticClass: "col",
                          attrs: {
                            "data-test-hook": "ApparatusPreferredDealerEmail"
                          }
                        },
                        [
                          _c("i", {
                            staticClass: "fa-regular fa-envelope pr-2"
                          }),
                          _c(
                            "a",
                            {
                              attrs: {
                                href:
                                  "mailto:" + _vm.fuelTank.preferredDealerEmail
                              }
                            },
                            [_vm._v(_vm._s(_vm.fuelTank.preferredDealerEmail))]
                          )
                        ]
                      )
                    ])
                  ]),
                  _c("ModalFooter", [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary",
                        attrs: { type: "button" },
                        on: {
                          click: function($event) {
                            return _vm.closeDealerModal()
                          }
                        }
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("xDone")) + " ")]
                    )
                  ])
                ],
                1
              )
            ])
          ]
        )
      : _vm._e(),
    _c("div", { staticStyle: { position: "absolute", top: "1040" } }, [
      _vm.fuelTank
        ? _c(
            "div",
            {
              staticClass: "modal",
              attrs: {
                id: "free-enrollment-success" + _vm.fuelTank.apparatusId,
                role: "dialog",
                "aria-modal": "true"
              }
            },
            [
              _c(
                "div",
                {
                  staticClass: "modal-dialog modal-lg modal-dialog centered",
                  staticStyle: { top: "25%" }
                },
                [
                  _c("span", { attrs: { tabindex: "0" } }),
                  _c(
                    "div",
                    { staticClass: "modal-content", attrs: { tabindex: "-1" } },
                    [
                      _c("header", { staticClass: "modal-header" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "row w-100 no-gutters align-items-center"
                          },
                          [
                            _c("div", { staticClass: "col" }, [
                              _c(
                                "h2",
                                { staticClass: "headerInline mb-0 pb-0" },
                                [
                                  _c("i", {
                                    staticClass: "colorOrange fas fa-plus"
                                  }),
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("xAddATank")) + " "
                                  )
                                ]
                              )
                            ])
                          ]
                        )
                      ]),
                      _c("div", { staticClass: "modal-body" }, [
                        _c("div", { staticClass: "row no-gutters" }, [
                          _c(
                            "div",
                            { staticClass: "col" },
                            [
                              _c("lottie", {
                                attrs: {
                                  options: _vm.successAnimationOptions,
                                  height: 200,
                                  width: 200
                                },
                                on: { animCreated: _vm.handleAnimation }
                              })
                            ],
                            1
                          )
                        ]),
                        _c("div", { staticClass: "row text-center mt-2" }, [
                          _c("div", { staticClass: "col" }, [
                            _c("h2", { staticClass: "colorOrange" }, [
                              _c("strong", [
                                _vm._v(_vm._s(_vm.$t("xSuccess")) + "!")
                              ])
                            ])
                          ])
                        ]),
                        _c("div", { staticClass: "row text-center mt-2" }, [
                          _c("div", { staticClass: "asterisk-text col" }, [
                            _vm._v(_vm._s(_vm.$t("xSuccessfullyAddedTank")))
                          ])
                        ])
                      ]),
                      _c("footer", { staticClass: "modal-footer" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-warning btn-block",
                            attrs: {
                              "data-test-hook":
                                "free-enrollment-success-modal-sounds-good-button",
                              type: "button"
                            },
                            on: {
                              click: function($event) {
                                return _vm.closeModal()
                              }
                            }
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("xSoundsGood")) + " ")]
                        )
                      ])
                    ]
                  ),
                  _c("span")
                ]
              )
            ]
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "float-right mr-0 pr-0 col-auto" }, [
      _c("i", { staticClass: "fa-solid fa-arrow-right" })
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "float-right mr-0 pr-0 col-auto" }, [
      _c("i", { staticClass: "fas fa-arrow-right" })
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "float-right mr-0 pr-0 col-auto" }, [
      _c("i", { staticClass: "fas fa-arrow-right" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }