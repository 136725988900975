var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-row",
        { staticClass: "text-center float-center" },
        [
          _c(
            "b-col",
            [
              _c("b-spinner", {
                staticStyle: { width: "10rem", height: "10rem" },
                attrs: { variant: "warning", label: "Spinning" }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }