var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.isLoading
      ? _c(
          "div",
          { staticClass: "d-flex justify-content-center mb-3 pt-4" },
          [
            _c("b-spinner", {
              staticStyle: { width: "5rem", height: "5rem" },
              attrs: { variant: "warning", label: "Spinning" }
            })
          ],
          1
        )
      : _c("div", [
          _c(
            "div",
            {
              staticClass: "ml-3 mr-3",
              attrs: { rounded: "lg", id: "overlay" }
            },
            [
              _c("div", { staticClass: "row no-gutters mb-2 mt-4" }, [
                _c("div", { staticClass: "col" }, [
                  _c(
                    "h1",
                    {
                      staticClass: "mb-0 pb-0",
                      attrs: { "data-test-hook": "pageTitle" }
                    },
                    [
                      _c("i", {
                        staticClass: "far fa-badge-dollar pr-1",
                        attrs: { "data-test-hook": "titleIcon" }
                      }),
                      _c(
                        "span",
                        {
                          attrs: {
                            "data-test-hook": "subscription-plan-details"
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("xManageYourPlan")))]
                      )
                    ]
                  )
                ])
              ]),
              !_vm.doesAccountHaveApparatus()
                ? _c("div", [
                    _c(
                      "div",
                      {
                        staticClass: "card mt-3",
                        attrs: { "header-bg-variant": "white" }
                      },
                      [
                        _c("div", { staticClass: "card-body" }, [
                          _c("div", { staticClass: "row mb-2" }, [
                            _c("div", { staticClass: "col" }, [
                              _c(
                                "span",
                                {
                                  attrs: {
                                    "data-test-hook": "plan-cost-header-text"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("xPlanStatus")) + " "
                                  )
                                ]
                              )
                            ])
                          ]),
                          _c("div", { staticClass: "row mb-1" }, [
                            _c("div", { staticClass: "col" }, [
                              _c(
                                "strong",
                                { attrs: { "data-test-hook": "plan-cost" } },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "xCurrentlyNotSubscribedToPlanPleaseAddProduct"
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ])
                          ])
                        ])
                      ]
                    )
                  ])
                : _vm._e(),
              _vm.singleHomePremiumApparatuses?.length > 0
                ? _c(
                    "div",
                    _vm._l(_vm.singleHomePremiumApparatuses, function(
                      apparatus,
                      index
                    ) {
                      return _c("div", { key: index }, [
                        apparatus.subscriptionProductId ===
                          _vm.dealerManagedProductId ||
                        apparatus.subscriptionProductId ===
                          _vm.tankUtilityDealerManagedProductId
                          ? _c("div", [
                              _c(
                                "div",
                                {
                                  staticClass: "card mt-3",
                                  attrs: { "header-bg-variant": "white" }
                                },
                                [
                                  _c("div", { staticClass: "card-body" }, [
                                    _c("div", { staticClass: "row mb-4" }, [
                                      _c("div", { staticClass: "col-auto" }, [
                                        _c("h5", { staticClass: "mb-0 pb-0" }, [
                                          _c(
                                            "span",
                                            {
                                              attrs: {
                                                "data-test-hook": "product-name"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    apparatus.subscriptionProductName
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ])
                                      ])
                                    ]),
                                    _c("div", { staticClass: "row mb-2" }, [
                                      _c("div", { staticClass: "col" }, [
                                        _c(
                                          "span",
                                          {
                                            attrs: {
                                              "data-test-hook":
                                                "plan-cost-header-text"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.$t("xPlanStatus")) +
                                                " "
                                            )
                                          ]
                                        )
                                      ])
                                    ]),
                                    _c("h5", { staticClass: "mb-3" }, [
                                      _vm._v(
                                        _vm._s(apparatus.apparatuses[0].name) +
                                          " " +
                                          _vm._s(
                                            _vm.$t("xIsManagedByYourDealer")
                                          )
                                      )
                                    ]),
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-warning",
                                        attrs: {
                                          type: "button",
                                          "data-test-hook": "OpenDealerInfoBtn"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.openDealerInfo(
                                              apparatus.apparatuses[0].id
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t("xViewDealerInformation")
                                            ) +
                                            " "
                                        )
                                      ]
                                    )
                                  ])
                                ]
                              )
                            ])
                          : _vm._e()
                      ])
                    }),
                    0
                  )
                : _vm._e(),
              _vm.hasProductsNoSubscription
                ? _c("div", [
                    _c(
                      "div",
                      {
                        staticClass: "card mt-3",
                        attrs: { "header-bg-variant": "white" }
                      },
                      [
                        _c("div", { staticClass: "card-body" }, [
                          _c("div", { staticClass: "row mb-2" }, [
                            _c("div", { staticClass: "col" }, [
                              _c(
                                "span",
                                {
                                  attrs: {
                                    "data-test-hook": "plan-cost-header-text"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("xPlanStatus")) + " "
                                  )
                                ]
                              )
                            ])
                          ]),
                          _c("div", { staticClass: "row mb-4" }, [
                            _c("div", { staticClass: "col" }, [
                              _c(
                                "strong",
                                { attrs: { "data-test-hook": "plan-cost" } },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("xCurrentlyNotSubscribedToPlan")
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ])
                          ]),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-warning",
                              attrs: {
                                type: "button",
                                "data-test-hook":
                                  "openSubscribePremiumMonitoringBtn"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.openSubscribePremiumMonitoring()
                                }
                              }
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("xUpgradePremium")) + " "
                              )
                            ]
                          )
                        ])
                      ]
                    )
                  ])
                : _vm._e(),
              _vm._l(_vm.legacySubscriptionList, function(apparatus, index) {
                return _c("div", { key: index }, [
                  apparatus != undefined && apparatus.isDealerManaged
                    ? _c("div", [
                        _c(
                          "div",
                          {
                            staticClass: "card mt-3",
                            attrs: { "header-bg-variant": "white" }
                          },
                          [
                            _c("div", { staticClass: "card-body" }, [
                              _c("div", { staticClass: "row mb-4" }, [
                                _c("div", { staticClass: "col-auto" }, [
                                  _c("h5", { staticClass: "mb-0 pb-0" }, [
                                    _c(
                                      "span",
                                      {
                                        attrs: {
                                          "data-test-hook": "product-name"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              apparatus.subscriptionProductName
                                            ) +
                                            " "
                                        )
                                      ]
                                    )
                                  ])
                                ])
                              ]),
                              _c("div", { staticClass: "row mb-2" }, [
                                _c("div", { staticClass: "col" }, [
                                  _c(
                                    "span",
                                    {
                                      attrs: {
                                        "data-test-hook":
                                          "plan-cost-header-text"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("xPlanStatus")) +
                                          " "
                                      )
                                    ]
                                  )
                                ])
                              ]),
                              _c("h5", { staticClass: "mb-3" }, [
                                _vm._v(
                                  _vm._s(apparatus.apparatusName) +
                                    " " +
                                    _vm._s(_vm.$t("xIsManagedByYourDealer"))
                                )
                              ]),
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-warning",
                                  attrs: {
                                    type: "button",
                                    "data-test-hook": "OpenDealerInfoBtn"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.openDealerInfo(
                                        apparatus.apparatusId
                                      )
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("xViewDealerInformation")) +
                                      " "
                                  )
                                ]
                              )
                            ])
                          ]
                        )
                      ])
                    : _vm._e(),
                  apparatus != undefined && !apparatus.isDealerManaged
                    ? _c("div", [
                        _c(
                          "div",
                          {
                            staticClass: "card mt-3",
                            attrs: { "header-bg-variant": "white" }
                          },
                          [
                            _c("div", { staticClass: "card-body" }, [
                              _c("div", { staticClass: "row mb-4" }, [
                                _c("div", { staticClass: "col-auto" }, [
                                  _c("h5", { staticClass: "mb-0 pb-0" }, [
                                    _c(
                                      "span",
                                      {
                                        attrs: {
                                          "data-test-hook": "product-name"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              apparatus.subscriptionProductName
                                            ) +
                                            " "
                                        )
                                      ]
                                    )
                                  ])
                                ])
                              ]),
                              apparatus.subscriptionStatus === 2
                                ? _c("div", [
                                    _c("div", { staticClass: "row mb-2" }, [
                                      _c("div", { staticClass: "col" }, [
                                        _c(
                                          "span",
                                          {
                                            attrs: {
                                              "data-test-hook":
                                                "billing-date-header-text"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.$t("xPlanStatus")) +
                                                " "
                                            )
                                          ]
                                        )
                                      ])
                                    ]),
                                    _c("div", { staticClass: "row mb-4" }, [
                                      _c("div", { staticClass: "col" }, [
                                        _c(
                                          "strong",
                                          {
                                            attrs: {
                                              "data-test-hook": "billing-date"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("xPendingCancellation")
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      ])
                                    ])
                                  ])
                                : _vm._e(),
                              _c("div", { staticClass: "row mb-2" }, [
                                _c("div", { staticClass: "col" }, [
                                  _c(
                                    "span",
                                    {
                                      attrs: {
                                        "data-test-hook":
                                          "plan-cost-header-text"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.$t("xPlanCost")) + " "
                                      )
                                    ]
                                  )
                                ])
                              ]),
                              _c("div", { staticClass: "row mb-4" }, [
                                _c("div", { staticClass: "col" }, [
                                  _c(
                                    "strong",
                                    {
                                      attrs: { "data-test-hook": "plan-cost" }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            "$" +
                                              apparatus.subscriptionCost +
                                              " " +
                                              _vm.displayLegacyPlanCostCycle(
                                                apparatus.subscriptionDuration
                                              )
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                ])
                              ]),
                              _c("div", [
                                _c("div", { staticClass: "row mb-2" }, [
                                  _c("div", { staticClass: "col" }, [
                                    apparatus.subscriptionStatus === 2
                                      ? _c(
                                          "span",
                                          {
                                            attrs: {
                                              "data-test-hook":
                                                "billing-date-header-text"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.$t("xPlanEndDate")) +
                                                " "
                                            )
                                          ]
                                        )
                                      : _c(
                                          "span",
                                          {
                                            attrs: {
                                              "data-test-hook":
                                                "billing-date-header-text"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("xNextBillingDate")
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                  ])
                                ]),
                                _c("div", { staticClass: "row mb-4" }, [
                                  _c("div", { staticClass: "col" }, [
                                    _c(
                                      "strong",
                                      {
                                        attrs: {
                                          "data-test-hook": "billing-date"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm
                                                .$moment(
                                                  apparatus.subscriptionNextBillingDate
                                                )
                                                .format("MM/DD/YYYY")
                                            ) +
                                            " "
                                        )
                                      ]
                                    )
                                  ])
                                ])
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass: "accordion",
                                  staticStyle: { width: "100%" },
                                  attrs: { role: "tablist" }
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "accordion-wrapper" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "b-toggle",
                                              rawName: "v-b-toggle",
                                              value:
                                                "collapse-legacy-" +
                                                apparatus.apparatusId,
                                              expression:
                                                "'collapse-legacy-' + apparatus.apparatusId"
                                            }
                                          ]
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "row align-items-center no-gutters"
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "col align-items-center"
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "fa-regular fa-circle-info pr-2"
                                                  }),
                                                  _c("strong", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "xProductsAndFeatures"
                                                        )
                                                      )
                                                    )
                                                  ])
                                                ]
                                              ),
                                              _vm._m(0, true)
                                            ]
                                          )
                                        ]
                                      ),
                                      _c(
                                        "b-collapse",
                                        {
                                          attrs: {
                                            id:
                                              "collapse-legacy-" +
                                              apparatus.apparatusId
                                          }
                                        },
                                        [
                                          _c("div", { staticClass: "row" }, [
                                            _c("div", { staticClass: "col" }, [
                                              _c("hr", {
                                                staticClass: "mx-0 px-0"
                                              })
                                            ])
                                          ]),
                                          _c(
                                            "div",
                                            { staticClass: "row mb-2" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "col" },
                                                [
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass: "mb-0 pb-0"
                                                    },
                                                    [
                                                      _c(
                                                        "strong",
                                                        {
                                                          attrs: {
                                                            "data-test-hook":
                                                              "products-included-text"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "xProductsIncludedInPlan"
                                                                )
                                                              ) +
                                                              " " +
                                                              _vm._s(
                                                                apparatus.subscriptionProductName
                                                              ) +
                                                              " "
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            ]
                                          ),
                                          _c("div", { staticClass: "row" }, [
                                            _c(
                                              "div",
                                              { staticClass: "col-0" },
                                              [
                                                _c(
                                                  "ul",
                                                  {
                                                    staticStyle: {
                                                      "list-style-type": "none",
                                                      "padding-inline-start":
                                                        "13px"
                                                    },
                                                    attrs: {
                                                      id: "productsCoveredTanks"
                                                    }
                                                  },
                                                  [
                                                    _c("i", {
                                                      class: _vm.getApparatusIcon(
                                                        apparatus.apparatusType
                                                      )
                                                    }),
                                                    _vm._v(
                                                      "  " +
                                                        _vm._s(
                                                          apparatus.apparatusName
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ]),
                                          _c(
                                            "div",
                                            { staticClass: "row mt-0 mb-2" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "col" },
                                                [
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass: "mb-0 pb-0"
                                                    },
                                                    [
                                                      _c(
                                                        "strong",
                                                        {
                                                          attrs: {
                                                            "data-test-hook":
                                                              "features-included-text"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "xFeaturesIncludedInPlan"
                                                                )
                                                              ) +
                                                              " " +
                                                              _vm._s(
                                                                apparatus.subscriptionProductName
                                                              ) +
                                                              " "
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            ]
                                          ),
                                          apparatus.subscriptionProductId ==
                                          _vm.tankUtilityPremiumProductId
                                            ? _c("div", [
                                                _c(
                                                  "div",
                                                  { staticClass: "row mb-3" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "col icon-and-text"
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "fa-kit fa-fw fa-tank"
                                                        }),
                                                        _c(
                                                          "span",
                                                          {
                                                            attrs: {
                                                              "data-test-hook":
                                                                "features-included-list"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "xFeaturesIncludedTankUtilitySubscription1"
                                                                  )
                                                                ) +
                                                                " "
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  { staticClass: "row" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "col icon-and-text"
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "far fa-fw fa-circle-check"
                                                        }),
                                                        _c(
                                                          "span",
                                                          {
                                                            attrs: {
                                                              "data-test-hook":
                                                                "features-included-monitor-list"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "xFeaturesIncludedTankUtilityAnnuallySubscriptionFeature2"
                                                                  )
                                                                ) +
                                                                " "
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ])
                                            : apparatus.subscriptionProductId ==
                                              _vm.wiFiAnnualProductId
                                            ? _c("div", [
                                                _c(
                                                  "div",
                                                  { staticClass: "row mb-3" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "col icon-and-text"
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "fa-kit fa-fw fa-generator"
                                                        }),
                                                        _c(
                                                          "span",
                                                          {
                                                            attrs: {
                                                              "data-test-hook":
                                                                "features-included-list"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "xFeaturesIncludedWiFiAnnuallySubscriptionFeature1"
                                                                  )
                                                                ) +
                                                                " "
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  { staticClass: "row" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "col icon-and-text"
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "far fa-fw fa-circle-check"
                                                        }),
                                                        _c(
                                                          "span",
                                                          {
                                                            attrs: {
                                                              "data-test-hook":
                                                                "features-included-monitor-list"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "xFeaturesIncludedWiFiAnnuallySubscriptionFeature2"
                                                                  )
                                                                ) +
                                                                " "
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ])
                                            : apparatus.subscriptionProductId ==
                                              _vm.wiFiMonthlyProductId
                                            ? _c("div", [
                                                _c(
                                                  "div",
                                                  { staticClass: "row mb-3" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "col icon-and-text"
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "fa-kit fa-fw fa-generator"
                                                        }),
                                                        _c(
                                                          "span",
                                                          {
                                                            attrs: {
                                                              "data-test-hook":
                                                                "features-included-list"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "xFeaturesIncludedWiFiAnnuallySubscriptionFeature1"
                                                                  )
                                                                ) +
                                                                " "
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  { staticClass: "row" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "col icon-and-text"
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "far fa-fw fa-circle-check"
                                                        }),
                                                        _c(
                                                          "span",
                                                          {
                                                            attrs: {
                                                              "data-test-hook":
                                                                "features-included-monitor-list"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "xFeaturesIncludedWiFiAnnuallySubscriptionFeature2"
                                                                  )
                                                                ) +
                                                                " "
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ])
                                            : _vm._e()
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  !_vm.purchasedOnWeb
                                    ? _c("div", [
                                        _c("div", { staticClass: "row" }, [
                                          _c("div", { staticClass: "col" }, [
                                            _c(
                                              "span",
                                              {
                                                attrs: {
                                                  "data-test-hook":
                                                    "manage-plan-text"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "xManagePlanOnApple"
                                                      )
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            )
                                          ])
                                        ]),
                                        _c("div", { staticClass: "row mb-3" }, [
                                          _c("div", { staticClass: "col" }, [
                                            _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href:
                                                    "https://account.apple.com/account/manage/section/subscriptions"
                                                }
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    attrs: {
                                                      "data-test-hook":
                                                        "manage-plan-text"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          "https://account.apple.com/account/manage/section/subscriptions."
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ])
                                        ]),
                                        _c(
                                          "button",
                                          {
                                            staticClass: "btn btn-warning",
                                            attrs: {
                                              type: "button",
                                              "data-test-hook":
                                                "OpenMobileAppBtn"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.openMobileApp()
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("xOpenMobileApp")
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      ])
                                    : apparatus.subscriptionNextBillingDate !=
                                        null &&
                                      apparatus.subscriptionStatus !== 2
                                    ? _c("div", { staticClass: "mt-3" }, [
                                        _c(
                                          "button",
                                          {
                                            staticClass: "btn btn-warning",
                                            attrs: {
                                              type: "button",
                                              title: _vm.$t(
                                                "xManageYourPlanBtn"
                                              ),
                                              "data-test-hook":
                                                "ManageYourPlanBtn"
                                            },
                                            on: {
                                              click: function($event) {
                                                _vm.isCancelLegacy = true
                                                _vm.endDate =
                                                  apparatus.subscriptionNextBillingDate
                                                _vm.cancelLegacyApparatusId =
                                                  apparatus.apparatusId
                                                _vm.cancelLegacyApparatusType =
                                                  apparatus.apparatusType
                                                _vm.openModal(
                                                  "cancel-subscription-method"
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("xManageYourPlanBtn")
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      ])
                                    : _vm._e(),
                                  _c("div", { staticClass: "row mt-3" }, [
                                    _c("div", { staticClass: "col" }, [
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "auto-renew-message text-small",
                                          attrs: {
                                            "data-test-hook": "AutoRenewMsg"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "xThisSubscriptionWillAutoRenewShortVersion"
                                                )
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    ])
                                  ])
                                ]
                              ),
                              apparatus.subscriptionStatus === 2
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "b-badge",
                                        {
                                          staticClass:
                                            "mb-2 mt-4 pending-change-badge",
                                          attrs: { variant: "primary" }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t("xPendingChange"))
                                          )
                                        ]
                                      ),
                                      _c("div", { staticClass: "row mb-2" }, [
                                        _c("div", { staticClass: "col" }, [
                                          _c(
                                            "span",
                                            {
                                              attrs: {
                                                "data-test-hook":
                                                  "no-plan-status"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("xPlanStatus"))
                                              )
                                            ]
                                          )
                                        ])
                                      ]),
                                      _c("div", { staticClass: "row mb-4" }, [
                                        _c("div", { staticClass: "col" }, [
                                          _c("strong", [
                                            _vm._v(_vm._s(_vm.$t("xNoPlan")))
                                          ])
                                        ])
                                      ]),
                                      _c("div", { staticClass: "row mb-2" }, [
                                        _c("div", { staticClass: "col" }, [
                                          _c(
                                            "span",
                                            {
                                              attrs: {
                                                "data-test-hook":
                                                  "effective-cancel-date"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("xChangeEffectiveDate")
                                                )
                                              )
                                            ]
                                          )
                                        ])
                                      ]),
                                      _c("div", { staticClass: "row mb-4" }, [
                                        _c("div", { staticClass: "col" }, [
                                          _c("strong", [
                                            _vm._v(
                                              _vm._s(
                                                _vm
                                                  .$moment(
                                                    apparatus.subscriptionNextBillingDate
                                                  )
                                                  .format("MM/DD/YYYY")
                                              )
                                            )
                                          ])
                                        ])
                                      ])
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ])
                          ]
                        )
                      ])
                    : _vm._e()
                ])
              }),
              _vm.singleHomePremiumApparatuses?.length > 0 &&
              _vm.singleHomePremiumApparatuses[0].subscriptionProductId !==
                _vm.dealerManagedProductId &&
              _vm.singleHomePremiumApparatuses[0].subscriptionProductId !==
                _vm.tankUtilityDealerManagedProductId
                ? _c("div", [
                    _c(
                      "div",
                      {
                        staticClass: "card mt-3",
                        attrs: { "header-bg-variant": "white" }
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "card-body" },
                          [
                            _vm.singleHomePremiumApparatuses[0]
                              .subscriptionStatus === 2
                              ? _c(
                                  "b-badge",
                                  {
                                    staticClass:
                                      "current-plan-badge mb-2 border-primary text-primary",
                                    attrs: { variant: "light" }
                                  },
                                  [_vm._v(_vm._s(_vm.$t("xCurrentPlan")))]
                                )
                              : _vm._e(),
                            _c("div", { staticClass: "row mb-4" }, [
                              _c("div", { staticClass: "col-auto" }, [
                                _c("h5", { staticClass: "mb-0 pb-0" }, [
                                  _c(
                                    "span",
                                    {
                                      attrs: {
                                        "data-test-hook": "product-name"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.singleHomePremiumApparatuses[0]
                                              .subscriptionProductName
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                ])
                              ])
                            ]),
                            _vm.singleHomePremiumApparatuses[0]
                              .subscriptionStatus === 2
                              ? _c("div", [
                                  _c("div", { staticClass: "row mb-2" }, [
                                    _c("div", { staticClass: "col" }, [
                                      _c(
                                        "span",
                                        {
                                          attrs: {
                                            "data-test-hook":
                                              "billing-date-header-text"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t("xPlanStatus")) +
                                              " "
                                          )
                                        ]
                                      )
                                    ])
                                  ]),
                                  _c("div", { staticClass: "row mb-4" }, [
                                    _c("div", { staticClass: "col" }, [
                                      _c(
                                        "strong",
                                        {
                                          attrs: {
                                            "data-test-hook": "billing-date"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("xPendingCancellation")
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    ])
                                  ])
                                ])
                              : _vm._e(),
                            _c("div", { staticClass: "row mb-2" }, [
                              _c("div", { staticClass: "col" }, [
                                _c(
                                  "span",
                                  {
                                    attrs: {
                                      "data-test-hook": "plan-cost-header-text"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.$t("xPlanCost")) + " "
                                    )
                                  ]
                                )
                              ])
                            ]),
                            _c("div", { staticClass: "row mb-4" }, [
                              _c("div", { staticClass: "col" }, [
                                _c(
                                  "strong",
                                  { attrs: { "data-test-hook": "plan-cost" } },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          "$" +
                                            _vm.singleHomePremiumApparatuses[0]
                                              .subscriptionCost +
                                            " " +
                                            _vm.displayPlanCostCycle(
                                              _vm
                                                .singleHomePremiumApparatuses[0]
                                                .subscriptionDurationInMonths
                                            )
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ])
                            ]),
                            _c("div", { staticClass: "row mb-2" }, [
                              _c("div", { staticClass: "col" }, [
                                _vm.singleHomePremiumApparatuses[0]
                                  .subscriptionStatus === 2
                                  ? _c(
                                      "span",
                                      {
                                        attrs: {
                                          "data-test-hook":
                                            "billing-date-header-text"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("xPlanEndDate")) +
                                            " "
                                        )
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        attrs: {
                                          "data-test-hook":
                                            "billing-date-header-text"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("xNextBillingDate")) +
                                            " "
                                        )
                                      ]
                                    )
                              ])
                            ]),
                            _c("div", { staticClass: "row mb-4" }, [
                              _c("div", { staticClass: "col" }, [
                                _c(
                                  "strong",
                                  {
                                    attrs: { "data-test-hook": "billing-date" }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm
                                            .$moment(
                                              _vm
                                                .singleHomePremiumApparatuses[0]
                                                .subscriptionRenewalDate
                                            )
                                            .format("MM/DD/YYYY")
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ])
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "accordion",
                                staticStyle: { width: "100%" },
                                attrs: { role: "tablist" }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "accordion-wrapper",
                                    staticStyle: {
                                      border: "1px solid lightgrey",
                                      padding: "10px",
                                      "border-radius": "10px"
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "b-toggle",
                                            rawName:
                                              "v-b-toggle.collapse-singleHomePremium",
                                            modifiers: {
                                              "collapse-singleHomePremium": true
                                            }
                                          }
                                        ]
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "row align-items-center no-gutters"
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "col align-items-center"
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "fa-regular fa-circle-info pr-2"
                                                }),
                                                _c("strong", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "xProductsAndFeatures"
                                                      )
                                                    )
                                                  )
                                                ])
                                              ]
                                            ),
                                            _vm._m(1)
                                          ]
                                        )
                                      ]
                                    ),
                                    _c(
                                      "b-collapse",
                                      {
                                        attrs: {
                                          id: "collapse-singleHomePremium"
                                        }
                                      },
                                      [
                                        _c("div", { staticClass: "row" }, [
                                          _c("div", { staticClass: "col" }, [
                                            _c("hr", {
                                              staticClass: "mx-0 px-0"
                                            })
                                          ])
                                        ]),
                                        _c("div", { staticClass: "row mb-2" }, [
                                          _c("div", { staticClass: "col" }, [
                                            _c(
                                              "p",
                                              { staticClass: "mb-0 pb-0" },
                                              [
                                                _c(
                                                  "strong",
                                                  {
                                                    attrs: {
                                                      "data-test-hook":
                                                        "products-included-text"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "xProductsIncludedInPlan"
                                                          )
                                                        ) +
                                                        " " +
                                                        _vm._s(
                                                          _vm
                                                            .singleHomePremiumApparatuses[0]
                                                            .subscriptionProductName
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ])
                                        ]),
                                        _c("div", { staticClass: "row" }, [
                                          _c("div", { staticClass: "col-0" }, [
                                            _c(
                                              "ul",
                                              {
                                                staticStyle: {
                                                  "list-style-type": "none",
                                                  "padding-inline-start": "13px"
                                                },
                                                attrs: {
                                                  id:
                                                    "productsCoveredSingleHomePremium"
                                                }
                                              },
                                              _vm._l(
                                                _vm
                                                  .singleHomePremiumApparatuses[0]
                                                  .apparatuses,
                                                function(apparatus, index) {
                                                  return _c(
                                                    "li",
                                                    { key: index },
                                                    [
                                                      _c("i", {
                                                        class: _vm.getApparatusIcon(
                                                          apparatus.type
                                                        )
                                                      }),
                                                      _vm._v(
                                                        "  " +
                                                          _vm._s(
                                                            apparatus.name
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            )
                                          ])
                                        ]),
                                        _c(
                                          "div",
                                          { staticClass: "row mt-0 mb-2" },
                                          [
                                            _c("div", { staticClass: "col" }, [
                                              _c(
                                                "p",
                                                { staticClass: "mb-0 pb-0" },
                                                [
                                                  _c(
                                                    "strong",
                                                    {
                                                      attrs: {
                                                        "data-test-hook":
                                                          "features-included-text"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "xFeaturesIncludedInPlan"
                                                            )
                                                          ) +
                                                          " " +
                                                          _vm._s(
                                                            _vm
                                                              .singleHomePremiumApparatuses[0]
                                                              .subscriptionProductName
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            ])
                                          ]
                                        ),
                                        _c("div", { staticClass: "row mb-3" }, [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "col icon-and-text"
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "fa-kit fa-fw fa-generator"
                                              }),
                                              _c(
                                                "span",
                                                {
                                                  attrs: {
                                                    "data-test-hook":
                                                      "features-included-list"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "xFeatureSingleHomePremiumSubscription1"
                                                        )
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ]),
                                        _c("div", { staticClass: "row mb-3" }, [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "col icon-and-text"
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "fa-kit fa-fw fa-tank"
                                              }),
                                              _c(
                                                "span",
                                                {
                                                  attrs: {
                                                    "data-test-hook":
                                                      "features-included-monitor-list"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "xFeatureSingleHomePremiumSubscription2"
                                                        )
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ]),
                                        _c("div", { staticClass: "row" }, [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "col icon-and-text"
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "far fa-fw fa-circle-check"
                                              }),
                                              _c(
                                                "span",
                                                {
                                                  attrs: {
                                                    "data-test-hook":
                                                      "features-included-monitor-list"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "xFeatureSingleHomePremiumSubscription3"
                                                        )
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ])
                                      ]
                                    )
                                  ],
                                  1
                                ),
                                _vm.singleHomePremiumApparatuses[0]
                                  .subscriptionStore ==
                                _vm.subscriptionStoreTypesApple
                                  ? _c("div", { staticClass: "mt-4" }, [
                                      _c("div", { staticClass: "row" }, [
                                        _c("div", { staticClass: "col" }, [
                                          _c(
                                            "span",
                                            {
                                              attrs: {
                                                "data-test-hook":
                                                  "manage-plan-text"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t("xManagePlanOnApple")
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ])
                                      ]),
                                      _c("div", { staticClass: "row mb-3" }, [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              href:
                                                "https://account.apple.com/account/manage/section/subscriptions",
                                              target: "_blank"
                                            }
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass: "ml-3",
                                                attrs: {
                                                  "data-test-hook":
                                                    "manage-plan-text"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      "https://account.apple.com/account/manage/section/subscriptions."
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ])
                                    ])
                                  : _vm.singleHomePremiumApparatuses[0]
                                      .subscriptionStore ==
                                    _vm.subscriptionStoreTypesGoogle
                                  ? _c("div", { staticClass: "mt-4" }, [
                                      _c("div", { staticClass: "row" }, [
                                        _c("div", { staticClass: "col" }, [
                                          _c(
                                            "span",
                                            {
                                              attrs: {
                                                "data-test-hook":
                                                  "manage-plan-text"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "xManagePlanOnGoogle"
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ])
                                      ]),
                                      _c("div", { staticClass: "row mb-3" }, [
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              href:
                                                "https://play.google.com/store/account/subscriptions",
                                              target: "_blank"
                                            }
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass: "ml-3",
                                                attrs: {
                                                  "data-test-hook":
                                                    "manage-plan-text"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      "https://play.google.com/store/account/subscriptions"
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ])
                                    ])
                                  : _vm.singleHomePremiumApparatuses[0]
                                      .subscriptionStatus !== 2
                                  ? _c("div", { staticClass: "mt-4" }, [
                                      _c(
                                        "button",
                                        {
                                          staticClass: "btn btn-warning",
                                          attrs: {
                                            type: "button",
                                            title: _vm.$t("xManageYourPlanBtn"),
                                            "data-test-hook":
                                              "ManageYourPlanBtn"
                                          },
                                          on: {
                                            click: function($event) {
                                              _vm.isCancelLegacy = false
                                              _vm.cancelSubscriptionProductId =
                                                _vm.singleHomePremiumApparatuses[0].subscriptionProductId
                                              _vm.cancelSubscriptionStore =
                                                _vm.singleHomePremiumApparatuses[0].subscriptionStore
                                              _vm.cancelSubscriptionApparatuses =
                                                _vm.singleHomePremiumApparatuses[0].apparatuses
                                              _vm.openModal(
                                                "cancel-subscription-method"
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("xManageYourPlanBtn")
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    ])
                                  : _vm._e(),
                                _c("div", { staticClass: "row mt-4" }, [
                                  _c("div", { staticClass: "col" }, [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "auto-renew-message text-small",
                                        attrs: {
                                          "data-test-hook": "AutoRenewMsg"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "xThisSubscriptionWillAutoRenewShortVersion"
                                              )
                                            ) +
                                            " "
                                        )
                                      ]
                                    )
                                  ])
                                ])
                              ]
                            ),
                            _vm.singleHomePremiumApparatuses[0]
                              .subscriptionStatus === 2
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "b-badge",
                                      {
                                        staticClass:
                                          "mb-2 mt-4 pending-change-badge",
                                        attrs: { variant: "primary" }
                                      },
                                      [_vm._v(_vm._s(_vm.$t("xPendingChange")))]
                                    ),
                                    _c("div", { staticClass: "row mb-2" }, [
                                      _c("div", { staticClass: "col" }, [
                                        _c(
                                          "span",
                                          {
                                            attrs: {
                                              "data-test-hook": "no-plan-status"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.$t("xPlanStatus"))
                                            )
                                          ]
                                        )
                                      ])
                                    ]),
                                    _c("div", { staticClass: "row mb-4" }, [
                                      _c("div", { staticClass: "col" }, [
                                        _c("strong", [
                                          _vm._v(_vm._s(_vm.$t("xNoPlan")))
                                        ])
                                      ])
                                    ]),
                                    _c("div", { staticClass: "row mb-2" }, [
                                      _c("div", { staticClass: "col" }, [
                                        _c(
                                          "span",
                                          {
                                            attrs: {
                                              "data-test-hook":
                                                "effective-cancel-date"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("xChangeEffectiveDate")
                                              )
                                            )
                                          ]
                                        )
                                      ])
                                    ]),
                                    _c("div", { staticClass: "row mb-4" }, [
                                      _c("div", { staticClass: "col" }, [
                                        _c("strong", [
                                          _vm._v(
                                            _vm._s(
                                              _vm
                                                .$moment(
                                                  _vm
                                                    .singleHomePremiumApparatuses[0]
                                                    .subscriptionNextBillingDate
                                                )
                                                .format("MM/DD/YYYY")
                                            )
                                          )
                                        ])
                                      ])
                                    ])
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      ]
                    )
                  ])
                : _vm._e(),
              _c("div", { staticClass: "row mt-2 mb-2" }),
              _vm.existingPaymentInfo && _vm.purchasedOnWeb
                ? _c("div", [
                    _c(
                      "div",
                      {
                        staticClass: "card",
                        attrs: { "header-bg-variant": "white" }
                      },
                      [
                        _c("div", { staticClass: "card-body" }, [
                          _c(
                            "div",
                            { staticClass: "row mb-1 ml-0" },
                            [
                              _c("i", {
                                staticClass: "far fa-credit-card colorOrange"
                              }),
                              _c(
                                "strong",
                                {
                                  staticClass: "ml-2",
                                  attrs: {
                                    "data-test-hook":
                                      "account-holder-payment-information-account-settings"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("xPaymentInformation")) +
                                      " "
                                  )
                                ]
                              ),
                              _c(
                                "popper",
                                {
                                  staticClass: "ml-4",
                                  attrs: {
                                    trigger: "hover",
                                    options: { placement: "right" }
                                  }
                                },
                                [
                                  _c("div", { staticClass: "popper" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "xPrimaryPaymentInformationMessage"
                                          )
                                        ) +
                                        " "
                                    )
                                  ]),
                                  _c("i", {
                                    staticClass: "far fa-question-circle",
                                    attrs: { slot: "reference" },
                                    slot: "reference"
                                  })
                                ]
                              )
                            ],
                            1
                          ),
                          _c("div", { staticClass: "row mt-1" }, [
                            _c("div", { staticClass: "col-auto" }, [
                              _c(
                                "p",
                                {
                                  attrs: {
                                    "data-test-hook":
                                      "account-holder-payment-information-card-account-settings"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.existingPaymentInfo.cardType.toUpperCase() +
                                          " - " +
                                          _vm.existingPaymentInfo.cardSuffix
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ])
                          ]),
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-auto" }, [
                              _c(
                                "p",
                                {
                                  attrs: {
                                    "data-test-hook":
                                      "account-holder-payment-information-expiration-account-settings"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("xExpires") +
                                          " " +
                                          _vm.displayMonth(
                                            _vm.existingPaymentInfo
                                              .expirationMonth
                                          ) +
                                          "/" +
                                          _vm.existingPaymentInfo.expirationYear
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ])
                          ]),
                          _vm.shouldShowUpdatePayment
                            ? _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col" }, [
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn btn-outline-warning mt-3",
                                      attrs: {
                                        type: "button",
                                        title: _vm.$t(
                                          "xUpdatePaymentInformation"
                                        ),
                                        "data-test-hook":
                                          "update-payment-information-button-account-settings"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.openModal(
                                            "update-payment-method"
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("xUpdatePaymentInformation")
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                ])
                              ])
                            : _vm._e(),
                          _vm.shouldShowRemovePayment
                            ? _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col" }, [
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn btn-outline-danger rounded-pill mt-3",
                                      attrs: {
                                        type: "button",
                                        title: _vm.$t(
                                          "xRemovePaymentInformation"
                                        ),
                                        "data-test-hook":
                                          "remove-payment-information-button-account-settings"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.removePaymentInformation()
                                        }
                                      }
                                    },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "fas fa-trash-alt colorOrange"
                                      }),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("xRemovePaymentInformation")
                                          ) +
                                          " "
                                      ),
                                      _vm.removing
                                        ? _c("span", {
                                            staticClass: "spinner-border ml-2"
                                          })
                                        : _vm._e()
                                    ]
                                  )
                                ])
                              ])
                            : _vm._e()
                        ])
                      ]
                    )
                  ])
                : _vm._e(),
              _c(
                "Modal",
                {
                  attrs: { modalId: "update-payment-method", size: "modal-md" },
                  on: {
                    hidden: function($event) {
                      return _vm.closePaymentModal()
                    }
                  }
                },
                [
                  _c(
                    "ModalHeader",
                    {
                      attrs: {
                        title: _vm.$t("xUpdatePaymentMethod"),
                        icon: "fal fa-credit-card colorOrange mr-1"
                      }
                    },
                    [
                      _c("div", { staticClass: "cols-auto" }, [
                        _c(
                          "button",
                          {
                            staticClass:
                              "btn btn-icon-button btn-icon-button--gray",
                            attrs: {
                              type: "button",
                              "data-test-hook": "CloseModalBtn"
                            },
                            on: {
                              click: function($event) {
                                return _vm.closePaymentModal()
                              }
                            }
                          },
                          [_c("i", { staticClass: "fas fa-times" })]
                        )
                      ])
                    ]
                  ),
                  _c("ModalBody", [
                    _vm.showValidationBanner
                      ? _c(
                          "div",
                          {
                            staticClass: "alert alert-danger",
                            attrs: {
                              role: "alert",
                              "data-test-hook": "ValidationAlert"
                            }
                          },
                          [
                            _c("p", [
                              _vm._v(_vm._s(_vm.$t("xExperiencedTheseErrors")))
                            ]),
                            _vm._l(_vm.errorMessages, function(message, index) {
                              return _c("ul", { key: index }, [
                                _c(
                                  "li",
                                  {
                                    attrs: {
                                      id: index,
                                      "data-test-hook": "ValidationMsg"
                                    }
                                  },
                                  [_vm._v(" " + _vm._s(message) + " ")]
                                )
                              ])
                            })
                          ],
                          2
                        )
                      : _vm._e(),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-12" }, [
                        _c("h3", [
                          _c(
                            "strong",
                            { attrs: { "data-test-hook": "CcInfoHeader" } },
                            [_vm._v(_vm._s(_vm.$t("xCreditCardInfo")))]
                          ),
                          _c("span", { staticClass: "body-text-color" }, [
                            _vm._v("*")
                          ])
                        ])
                      ])
                    ]),
                    _c("div", { staticClass: "row mb-3" }, [
                      _c("div", { staticClass: "col" }, [
                        _c(
                          "i",
                          {
                            staticClass: "asterisk-text",
                            attrs: {
                              "data-test-hook": "UpdatePaymentWarningMsg"
                            }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("xUpdatingPaymentWarning")) +
                                " "
                            )
                          ]
                        )
                      ])
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-12" }, [
                        _c(
                          "div",
                          {
                            staticClass: "form-group",
                            attrs: { id: "credit-card-number" }
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.$v.creditCardNumber.$model,
                                  expression: "$v.creditCardNumber.$model"
                                },
                                {
                                  name: "mask",
                                  rawName: "v-mask",
                                  value: "################",
                                  expression: "'################'"
                                }
                              ],
                              staticClass: "form-control",
                              class: _vm.status(_vm.$v.creditCardNumber),
                              attrs: {
                                id: "creditCardNumber",
                                maxlength: "16",
                                type: "text",
                                placeholder: _vm.$t("xCreditCardNumber"),
                                "aria-describedby": "card-number-invalid",
                                "data-test-hook": "CcNumber"
                              },
                              domProps: {
                                value: _vm.$v.creditCardNumber.$model
                              },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.$v.creditCardNumber,
                                    "$model",
                                    $event.target.value
                                  )
                                }
                              }
                            }),
                            _c(
                              "div",
                              {
                                staticClass: "invalid-feedback",
                                attrs: {
                                  id: "card-number-invalid",
                                  "data-test-hook": "invalidCcNo"
                                }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("xCCFieldValidation")) +
                                    " "
                                )
                              ]
                            )
                          ]
                        )
                      ])
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col col-lg-6 col-md-6 col-sm-12" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "form-group",
                              attrs: { id: "expiry-date-month" }
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.$v.expirationMonth.$model,
                                    expression: "$v.expirationMonth.$model"
                                  },
                                  {
                                    name: "mask",
                                    rawName: "v-mask",
                                    value: "##",
                                    expression: "'##'"
                                  }
                                ],
                                staticClass: "form-control",
                                class: _vm.status(_vm.$v.expirationMonth),
                                attrs: {
                                  id: "xNm",
                                  maxlength: "2",
                                  type: "text",
                                  placeholder: _vm.$t("xMm"),
                                  "aria-describedby": "expiry-month-invalid",
                                  "data-test-hook": "CcExpireMonth"
                                },
                                domProps: {
                                  value: _vm.$v.expirationMonth.$model
                                },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.$v.expirationMonth,
                                      "$model",
                                      $event.target.value
                                    )
                                  }
                                }
                              }),
                              _c(
                                "div",
                                {
                                  staticClass: "invalid-feedback",
                                  attrs: {
                                    id: "expiry-month-invalid",
                                    "data-test-hook": "invalidCcMth"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("xExpirationMonthValidation")
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ]
                          )
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "col col-lg-6 col-md-t6 col-sm-12" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "form-group",
                              attrs: { id: "expiry-date-year" }
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.$v.expirationYear.$model,
                                    expression: "$v.expirationYear.$model"
                                  },
                                  {
                                    name: "mask",
                                    rawName: "v-mask",
                                    value: "##",
                                    expression: "'##'"
                                  }
                                ],
                                staticClass: "form-control",
                                class: _vm.status(_vm.$v.expirationYear),
                                attrs: {
                                  type: "text",
                                  placeholder: _vm.$t("xYy"),
                                  maxlength: "2",
                                  "aria-describedby": "expiry-year-invalid",
                                  "data-test-hook": "CcExpireYear"
                                },
                                domProps: {
                                  value: _vm.$v.expirationYear.$model
                                },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.$v.expirationYear,
                                      "$model",
                                      $event.target.value
                                    )
                                  }
                                }
                              }),
                              _c(
                                "div",
                                {
                                  staticClass: "invalid-feedback",
                                  attrs: {
                                    id: "expiry-year-invalid",
                                    "data-test-hook": "invalidCcYear"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("xExpirationYearValidation")
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ]
                          )
                        ]
                      ),
                      _c("div", { staticClass: "col-12" }, [
                        _c(
                          "div",
                          { staticClass: "input-group", attrs: { id: "cvv" } },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.$v.cvv.$model,
                                  expression: "$v.cvv.$model"
                                },
                                {
                                  name: "mask",
                                  rawName: "v-mask",
                                  value: "####",
                                  expression: "'####'"
                                }
                              ],
                              staticClass: "form-control",
                              class: _vm.status(_vm.$v.cvv),
                              attrs: {
                                type: "text",
                                maxlength: "4",
                                placeholder: _vm.$t("xCvv"),
                                "aria-describedby": "cvv-invalid",
                                "data-test-hook": "CcCvv"
                              },
                              domProps: { value: _vm.$v.cvv.$model },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.$v.cvv,
                                    "$model",
                                    $event.target.value
                                  )
                                }
                              }
                            }),
                            _c("div", { staticClass: "input-group-append" }, [
                              _c("div", { staticClass: "input-group-text" }, [
                                _c("img", {
                                  attrs: {
                                    height: 20,
                                    src: require("@/assets/images/3-digit-code.svg"),
                                    "data-test-hook": "ccCvvImage"
                                  }
                                })
                              ])
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "invalid-feedback",
                                attrs: {
                                  id: "cvv-invalid",
                                  "data-test-hook": "invalidCcCvv"
                                }
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("xCVVValidation")) + " "
                                )
                              ]
                            )
                          ]
                        )
                      ])
                    ]),
                    _vm.accountData
                      ? _c("div", { staticClass: "mt-4" }, [
                          _c("div", { staticClass: "row mb-2" }, [
                            _c("div", { staticClass: "col" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "font-weight-bold custom-control custom-checkbox"
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value:
                                          _vm.useExistingBillingInformation,
                                        expression:
                                          "useExistingBillingInformation"
                                      }
                                    ],
                                    staticClass: "custom-control-input",
                                    attrs: {
                                      id: "useExistingBillingInformation",
                                      type: "checkbox",
                                      disabled: _vm.savingAccountHolder,
                                      "data-test-hook":
                                        "UseCurrentBillingAddressChkBox"
                                    },
                                    domProps: {
                                      checked: Array.isArray(
                                        _vm.useExistingBillingInformation
                                      )
                                        ? _vm._i(
                                            _vm.useExistingBillingInformation,
                                            null
                                          ) > -1
                                        : _vm.useExistingBillingInformation
                                    },
                                    on: {
                                      change: function($event) {
                                        var $$a =
                                            _vm.useExistingBillingInformation,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              (_vm.useExistingBillingInformation = $$a.concat(
                                                [$$v]
                                              ))
                                          } else {
                                            $$i > -1 &&
                                              (_vm.useExistingBillingInformation = $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1)))
                                          }
                                        } else {
                                          _vm.useExistingBillingInformation = $$c
                                        }
                                      }
                                    }
                                  }),
                                  _c(
                                    "label",
                                    {
                                      staticClass: "custom-control-label",
                                      attrs: {
                                        for: "useExistingBillingInformation",
                                        "data-test-hook":
                                          "useCurrBillingInfoText"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("xUseCurrentBillingInfo")
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                ]
                              )
                            ])
                          ]),
                          _vm.useExistingBillingInformation
                            ? _c("div", { staticClass: "row" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "col-12",
                                    attrs: {
                                      "data-test-hook": "ExistingBillingName"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.accountData.firstName) +
                                        " " +
                                        _vm._s(_vm.accountData.lastName) +
                                        " "
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "col-12",
                                    attrs: {
                                      "data-test-hook": "ExistingBillingAddress"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.DisplayAddress(
                                            _vm.accountData.address
                                          )
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ])
                            : _vm._e(),
                          !_vm.useExistingBillingInformation
                            ? _c("div", [
                                _c("div", { staticClass: "row" }, [
                                  _c("div", { staticClass: "col-12" }, [
                                    _c(
                                      "fieldset",
                                      {
                                        staticClass: "form-group",
                                        attrs: { id: "firstName-group" }
                                      },
                                      [
                                        _c("div", [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.accountData.firstName,
                                                expression:
                                                  "accountData.firstName"
                                              }
                                            ],
                                            staticClass: "form-control",
                                            attrs: {
                                              type: "text",
                                              id: "firstName",
                                              "data-test-hook": "firstName"
                                            },
                                            domProps: {
                                              value: _vm.accountData.firstName
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.$set(
                                                  _vm.accountData,
                                                  "firstName",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          })
                                        ])
                                      ]
                                    )
                                  ])
                                ]),
                                _c("div", { staticClass: "row" }, [
                                  _c("div", { staticClass: "col-12" }, [
                                    _c(
                                      "fieldset",
                                      {
                                        staticClass: "form-group",
                                        attrs: { id: "lastName-group" }
                                      },
                                      [
                                        _c("div", [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.accountData.lastName,
                                                expression:
                                                  "accountData.lastName"
                                              }
                                            ],
                                            staticClass: "form-control",
                                            attrs: {
                                              id: "lastName",
                                              type: "text",
                                              "data-test-hook": "lastName"
                                            },
                                            domProps: {
                                              value: _vm.accountData.lastName
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.$set(
                                                  _vm.accountData,
                                                  "lastName",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          })
                                        ])
                                      ]
                                    )
                                  ])
                                ]),
                                _c("div", { staticClass: "row" }, [
                                  _c(
                                    "div",
                                    { staticClass: "col" },
                                    [
                                      _c("TypeAheadAddress", {
                                        ref: "typeAheadAddressSubscription",
                                        attrs: {
                                          currentAddress:
                                            _vm.accountData.address
                                        },
                                        on: {
                                          newAddress: _vm.setNewBillingAddress
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ])
                              ])
                            : _vm._e()
                        ])
                      : _vm._e()
                  ]),
                  _c("ModalFooter", [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-outline-warning",
                        attrs: {
                          type: "button",
                          "data-test-hook": "CancelBtn"
                        },
                        on: {
                          click: function($event) {
                            _vm.closeModal("update-payment-method"),
                              _vm.closePaymentModal()
                          }
                        }
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("xCancel")) + " ")]
                    ),
                    _vm.useExistingBillingInformation
                      ? _c(
                          "button",
                          {
                            staticClass: "btn btn-primary",
                            attrs: {
                              type: "button",
                              disabled: !_vm.canPay(),
                              "data-test-hook": "SaveChangesBtn"
                            },
                            on: {
                              click: function($event) {
                                return _vm.payForProduct()
                              }
                            }
                          },
                          [
                            _vm._v(" " + _vm._s(_vm.$t("xSave")) + " "),
                            _vm.paying
                              ? _c("span", {
                                  staticClass: "spinner-border ml-2"
                                })
                              : _vm._e()
                          ]
                        )
                      : _c(
                          "button",
                          {
                            staticClass: "btn btn-warning",
                            attrs: {
                              type: "button",
                              disabled: _vm.savingAccountHolder,
                              "data-test-hook": "SaveChangesBtn"
                            },
                            on: {
                              click: function($event) {
                                _vm.saveAccountHolder(),
                                  (_vm.savingAccountHolder = true)
                              }
                            }
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.$t("xConfirmBillingInfo")) + " "
                            ),
                            _vm.savingAccountHolder
                              ? _c("span", {
                                  staticClass: "spinner-border ml-2"
                                })
                              : _vm._e()
                          ]
                        )
                  ])
                ],
                1
              ),
              _c(
                "Modal",
                {
                  attrs: {
                    modalId: "cancel-subscription-method",
                    size: "modal-md"
                  },
                  on: {
                    hidden: function($event) {
                      return _vm.closeManagePlanModal()
                    }
                  }
                },
                [
                  _c(
                    "ModalHeader",
                    {
                      style: { padding: "4px 12px" },
                      attrs: {
                        title: _vm.$t("xCancelPlan"),
                        icon: "far fa-circle-dollar colorOrange mr-1"
                      }
                    },
                    [
                      _c("div", { staticClass: "cols-auto" }, [
                        _c(
                          "button",
                          {
                            staticClass:
                              "btn btn-icon-button btn-icon-button--gray",
                            attrs: {
                              type: "button",
                              "data-test-hook": "CloseModalBtn"
                            },
                            on: {
                              click: function($event) {
                                return _vm.closeManagePlanModal()
                              }
                            }
                          },
                          [_c("i", { staticClass: "fas fa-times" })]
                        )
                      ])
                    ]
                  ),
                  !_vm.cancelingSubscription
                    ? _c("ModalBody", [
                        _c("div", { staticClass: "row mb-3" }, [
                          _c("div", { staticClass: "col" }, [
                            _c(
                              "p",
                              {
                                attrs: {
                                  "data-test-hook": "EndOfSubscriptionText"
                                }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("xCurrentPlanDateText")) +
                                    " " +
                                    _vm._s(
                                      _vm
                                        .$moment(_vm.endDate)
                                        .format("MM/DD/YYYY") + "."
                                    ) +
                                    " " +
                                    _vm._s(_vm.$t("xCurrentPlanText")) +
                                    " "
                                )
                              ]
                            )
                          ])
                        ]),
                        _vm.doesAccountHaveGen() &&
                        !_vm.doesAccountHaveFuelMonitor()
                          ? _c("div", [
                              _c("div", { staticClass: "row mb-3" }, [
                                _c("div", { staticClass: "col" }, [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "text",
                                      attrs: {
                                        "data-test-hook":
                                          "BasicFeaturesTextGenOnly"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "xGenToReceiveBasicFeaturesText"
                                            )
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                ])
                              ]),
                              _c("strong", [
                                _vm._v(
                                  _vm._s(_vm.$t("xPremiumGenFeaturesHeader"))
                                )
                              ]),
                              _c("ul", [
                                _c("li", [
                                  _vm._v(
                                    _vm._s(_vm.$t("xGenPremiumFeaturesItem1"))
                                  )
                                ]),
                                _c("li", [
                                  _vm._v(
                                    _vm._s(_vm.$t("xGenPremiumFeaturesItem2"))
                                  )
                                ]),
                                _c("li", [
                                  _vm._v(
                                    _vm._s(_vm.$t("xGenPremiumFeaturesItem3"))
                                  )
                                ]),
                                _c("li", [
                                  _vm._v(
                                    _vm._s(_vm.$t("xGenPremiumFeaturesItem4"))
                                  )
                                ])
                              ])
                            ])
                          : !_vm.doesAccountHaveGen() &&
                            _vm.doesAccountHaveFuelMonitor()
                          ? _c("div", [
                              _c("div", { staticClass: "row mb-3" }, [
                                _c("div", { staticClass: "col" }, [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "text",
                                      attrs: {
                                        "data-test-hook":
                                          "BasicFeaturesTextMonitorOnly"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "xMonitorToReceiveBasicFeaturesText"
                                            )
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                ])
                              ]),
                              _c("strong", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("xPremiumFuelMonitorFeaturesHeader")
                                  )
                                )
                              ]),
                              _c("ul", [
                                _c("li", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("xMonitorPremiumFeaturesItem1")
                                    )
                                  )
                                ]),
                                _c("li", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("xMonitorPremiumFeaturesItem2")
                                    )
                                  )
                                ]),
                                _c("li", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("xMonitorPremiumFeaturesItem3")
                                    )
                                  )
                                ]),
                                _c("li", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("xMonitorPremiumFeaturesItem4")
                                    )
                                  )
                                ])
                              ])
                            ])
                          : _c("div", [
                              _c("div", { staticClass: "row mb-3" }, [
                                _c("div", { staticClass: "col" }, [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "text",
                                      attrs: {
                                        "data-test-hook":
                                          "BasicFeaturesTextGenAndMonitor"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "xGenAndMonitorBasicFeaturesText"
                                            )
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                ])
                              ]),
                              _c("strong", [
                                _vm._v(
                                  _vm._s(_vm.$t("xPremiumGenFeaturesHeader"))
                                )
                              ]),
                              _c("ul", [
                                _c("li", [
                                  _vm._v(
                                    _vm._s(_vm.$t("xGenPremiumFeaturesItem1"))
                                  )
                                ]),
                                _c("li", [
                                  _vm._v(
                                    _vm._s(_vm.$t("xGenPremiumFeaturesItem2"))
                                  )
                                ]),
                                _c("li", [
                                  _vm._v(
                                    _vm._s(_vm.$t("xGenPremiumFeaturesItem3"))
                                  )
                                ]),
                                _c("li", [
                                  _vm._v(
                                    _vm._s(_vm.$t("xGenPremiumFeaturesItem4"))
                                  )
                                ])
                              ]),
                              _c("strong", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("xPremiumFuelMonitorFeaturesHeader")
                                  )
                                )
                              ]),
                              _c("ul", [
                                _c("li", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("xMonitorPremiumFeaturesItem1")
                                    )
                                  )
                                ]),
                                _c("li", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("xMonitorPremiumFeaturesItem2")
                                    )
                                  )
                                ]),
                                _c("li", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("xMonitorPremiumFeaturesItem3")
                                    )
                                  )
                                ]),
                                _c("li", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("xMonitorPremiumFeaturesItem4")
                                    )
                                  )
                                ])
                              ])
                            ]),
                        _c(
                          "div",
                          {
                            staticClass: "row",
                            attrs: {
                              "data-test-hook": "AreYouSureYouWantToCancelText"
                            }
                          },
                          [
                            _c("div", { staticClass: "col" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("xAreYouSureYouWantToCancelText")
                                  ) +
                                  " "
                              )
                            ])
                          ]
                        )
                      ])
                    : _c("ModalBody", [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex justify-content-center align-items-center"
                          },
                          [
                            _c(
                              "span",
                              [_c("b-spinner", { attrs: { size: "lg" } })],
                              1
                            )
                          ]
                        )
                      ]),
                  _c("ModalFooter", [
                    _c("div", { staticClass: "modal-buttons" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-outline-warning",
                          attrs: {
                            type: "button",
                            title: _vm.$t("xManageYourPlan"),
                            "data-test-hook": "KeepPlanBtn",
                            disabled: _vm.cancelingSubscription
                          },
                          on: {
                            click: function($event) {
                              return _vm.closeManagePlanModal()
                            }
                          }
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("xKeepPlanButtonText")) + " "
                          )
                        ]
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-warning ml-2",
                          attrs: {
                            type: "button",
                            title: _vm.$t("xManageYourPlan"),
                            "data-test-hook": "CancelPlanBtn",
                            disabled: _vm.cancelingSubscription
                          },
                          on: {
                            click: function($event) {
                              return _vm.cancelSubscription()
                            }
                          }
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("xCancelPlanButtonText")) + " "
                          )
                        ]
                      )
                    ])
                  ])
                ],
                1
              ),
              _c(
                "Modal",
                {
                  attrs: {
                    modalId: "account-needs-attention",
                    size: "modal-md"
                  }
                },
                [
                  _c(
                    "ModalHeader",
                    {
                      style: { padding: "10px 10px 10px 10px" },
                      attrs: {
                        title: _vm.$t("xYourAccountNeedsAttention"),
                        icon: "far fa-exclamation-triangle"
                      }
                    },
                    [
                      _c("div", { staticClass: "cols-auto" }, [
                        _c(
                          "button",
                          {
                            staticClass:
                              "btn btn-icon-button btn-icon-button--gray",
                            attrs: { type: "button" },
                            on: {
                              click: function($event) {
                                return _vm.closeModal("account-needs-attention")
                              }
                            }
                          },
                          [_c("i", { staticClass: "fas fa-times" })]
                        )
                      ])
                    ]
                  ),
                  _c("ModalBody", [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col" }, [
                        _vm._v(
                          " " + _vm._s(_vm.$t("xHomePremiumLimitText")) + " "
                        )
                      ])
                    ])
                  ]),
                  _c("ModalFooter", [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary",
                        attrs: { type: "button" },
                        on: {
                          click: function($event) {
                            return _vm.navToSupportPage()
                          }
                        }
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.$t("xContactSupportUpgrade")) + " "
                        )
                      ]
                    )
                  ])
                ],
                1
              ),
              _c(
                "b-modal",
                {
                  attrs: {
                    id: "premium-enrollment-modal-step-1",
                    size: "md",
                    "no-fade": "",
                    "no-stacking": "",
                    centered: "",
                    "no-close-on-esc": "",
                    "no-close-on-backdrop": "",
                    "hide-footer": ""
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "modal-header",
                      fn: function() {
                        return [
                          _c("div", [
                            _c(
                              "h2",
                              {
                                staticClass: "modal-title",
                                attrs: {
                                  "data-test-hook":
                                    "premium-enrollment-modal-step-1-header"
                                }
                              },
                              [
                                _c("div", [
                                  _c("i", {
                                    staticClass:
                                      "far fa-badge-dollar colorOrange mr-1",
                                    attrs: {
                                      "data-test-hook":
                                        "premium-enrollment-modal-step-1-header-icon"
                                    }
                                  }),
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("xSubscribeToPremium")) +
                                      " "
                                  )
                                ])
                              ]
                            )
                          ])
                        ]
                      },
                      proxy: true
                    }
                  ])
                },
                [
                  _vm.loadingSubscriptionModal
                    ? _c(
                        "div",
                        [
                          _c(
                            "b-row",
                            { staticClass: "text-center" },
                            [
                              _c(
                                "b-col",
                                [
                                  _c("b-spinner", {
                                    staticStyle: {
                                      width: "10rem",
                                      height: "10rem"
                                    },
                                    attrs: {
                                      variant: "warning",
                                      label: "Spinning"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _c(
                        "b-card",
                        {
                          attrs: {
                            "data-test-hook":
                              "premium-enrollment-modal-step-1-paid-card"
                          }
                        },
                        [
                          _c(
                            "b-card-title",
                            [
                              _c(
                                "b-row",
                                { staticClass: "text-center" },
                                [
                                  _c("b-col", [
                                    _c(
                                      "h1",
                                      {
                                        staticClass: "mb-0 pb-0 pt-0 mt-0",
                                        attrs: {
                                          "data-test-hook":
                                            "premium-enrollment-modal-step-1-paid-card-title"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.premiumSubscriptionName
                                            ) +
                                            " "
                                        )
                                      ]
                                    )
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-card-body",
                            { staticClass: "subs-info-card-body" },
                            [
                              _c(
                                "b-row",
                                { staticClass: "text-center mt-2" },
                                [
                                  _c(
                                    "b-col",
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          attrs: {
                                            variant: "subscription-radio",
                                            pill: "",
                                            pressed: true,
                                            "data-test-hook":
                                              "select-subscription-type-step-10-existing-subscription-select-yearly-button"
                                          },
                                          on: { click: () => {} }
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "sub-radio-dot align-middle"
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "fa-solid fa-circle"
                                              })
                                            ]
                                          ),
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm._f("formatCurrency")(
                                                  _vm.premiumSubscriptionCost
                                                )
                                              ) +
                                              " "
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "text-bottom sub-premium-radio-duration"
                                            },
                                            [
                                              _vm._v(
                                                "/" +
                                                  _vm._s(
                                                    _vm
                                                      .$t("xYear")
                                                      .toLowerCase()
                                                  )
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "mt-2 subscriptionCardText" },
                                [
                                  _c(
                                    "b-row",
                                    [
                                      _c("b-col", [
                                        _c(
                                          "strong",
                                          {
                                            attrs: {
                                              "data-test-hook":
                                                "select-subscription-type-step-10-existing-subscription-premium-monitoring-max-products-text"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "xPremiumMonitoringMaxProducts"
                                                  )
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      ])
                                    ],
                                    1
                                  ),
                                  _vm._l(_vm.apparatuses, function(
                                    apparatus,
                                    index
                                  ) {
                                    return _c(
                                      "b-row",
                                      { key: index },
                                      [
                                        _vm.isApparatusEligibleForUpgrade(
                                          apparatus
                                        )
                                          ? _c(
                                              "b-col",
                                              { staticClass: "mt-2" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "accordion",
                                                    staticStyle: {
                                                      width: "100%"
                                                    },
                                                    attrs: { role: "tablist" }
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "accordion-wrapper"
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            directives: [
                                                              {
                                                                name:
                                                                  "b-toggle",
                                                                rawName:
                                                                  "v-b-toggle",
                                                                value:
                                                                  "collapse-upgrade-" +
                                                                  apparatus.apparatusId,
                                                                expression:
                                                                  "'collapse-upgrade-' + apparatus.apparatusId"
                                                              }
                                                            ]
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "row align-items-center no-gutters"
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "col d-flex"
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "d-flex"
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "i",
                                                                          {
                                                                            staticClass:
                                                                              "fas fa-fw fa-check-circle check-icon mr-2"
                                                                          }
                                                                        ),
                                                                        _c(
                                                                          "p",
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                apparatus.name
                                                                              )
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "col-auto"
                                                                  },
                                                                  [
                                                                    _c("i", {
                                                                      staticClass:
                                                                        "fas fa-chevron-down when-closed"
                                                                    }),
                                                                    _c("i", {
                                                                      staticClass:
                                                                        "fas fa-chevron-up when-open"
                                                                    })
                                                                  ]
                                                                )
                                                              ]
                                                            ),
                                                            _c(
                                                              "div",
                                                              [
                                                                _c(
                                                                  "b-row",
                                                                  [
                                                                    _c(
                                                                      "b-col",
                                                                      [
                                                                        _c(
                                                                          "p",
                                                                          {
                                                                            staticClass:
                                                                              "receives-features-text"
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "        " +
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "xReceivesPremiumFeatures"
                                                                                  )
                                                                                ) +
                                                                                " "
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ]
                                                        ),
                                                        apparatus.type === 0
                                                          ? _c(
                                                              "b-collapse",
                                                              {
                                                                attrs: {
                                                                  id:
                                                                    "collapse-upgrade-" +
                                                                    apparatus.apparatusId
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "features"
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "basic"
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "p",
                                                                          {
                                                                            staticClass:
                                                                              "mb-1"
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "strong",
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    _vm.$t(
                                                                                      "xFeaturesIncludedInPlan"
                                                                                    )
                                                                                  ) +
                                                                                    _vm._s(
                                                                                      _vm.$t(
                                                                                        "xBasic"
                                                                                      )
                                                                                    )
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "feature"
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "i",
                                                                              {
                                                                                staticClass:
                                                                                  "far fa-fw fa-clipboard-list"
                                                                              }
                                                                            ),
                                                                            _c(
                                                                              "div",
                                                                              [
                                                                                _c(
                                                                                  "p",
                                                                                  {
                                                                                    staticClass:
                                                                                      "basic-features"
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      " " +
                                                                                        _vm._s(
                                                                                          _vm.$t(
                                                                                            "xFeaturesIncludedWiFiAnnuallySubscriptionFeature1"
                                                                                          )
                                                                                        ) +
                                                                                        " "
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "premium"
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "p",
                                                                          {
                                                                            staticClass:
                                                                              "mb-1"
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "strong",
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    _vm.$t(
                                                                                      "xAdditionalPremiumFeatures"
                                                                                    )
                                                                                  )
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "feature"
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "i",
                                                                              {
                                                                                staticClass:
                                                                                  "far fa-fw fa-bell"
                                                                              }
                                                                            ),
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "mb-2"
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "p",
                                                                                  {
                                                                                    staticClass:
                                                                                      "premium-feature-text"
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      " " +
                                                                                        _vm._s(
                                                                                          _vm.$t(
                                                                                            "xRealTimeAlerts"
                                                                                          )
                                                                                        ) +
                                                                                        " "
                                                                                    )
                                                                                  ]
                                                                                ),
                                                                                _c(
                                                                                  "p",
                                                                                  {
                                                                                    staticClass:
                                                                                      "premium-feature-supporting-text"
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      " " +
                                                                                        _vm._s(
                                                                                          _vm.$t(
                                                                                            "xRealTimeAlertsDesc"
                                                                                          )
                                                                                        ) +
                                                                                        " "
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "feature"
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "i",
                                                                              {
                                                                                staticClass:
                                                                                  "far fa-fw fa-calendar"
                                                                              }
                                                                            ),
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "mb-2"
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "p",
                                                                                  {
                                                                                    staticClass:
                                                                                      "premium-feature-text"
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      " " +
                                                                                        _vm._s(
                                                                                          _vm.$t(
                                                                                            "xExerciseSettings"
                                                                                          )
                                                                                        ) +
                                                                                        " "
                                                                                    )
                                                                                  ]
                                                                                ),
                                                                                _c(
                                                                                  "p",
                                                                                  {
                                                                                    staticClass:
                                                                                      "premium-feature-supporting-text"
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      " " +
                                                                                        _vm._s(
                                                                                          _vm.$t(
                                                                                            "xExerciseSettingsDesc"
                                                                                          )
                                                                                        ) +
                                                                                        " "
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "feature"
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "i",
                                                                              {
                                                                                staticClass:
                                                                                  "far fa-fw fa-arrows-spin"
                                                                              }
                                                                            ),
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "mb-2"
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "p",
                                                                                  {
                                                                                    staticClass:
                                                                                      "premium-feature-text"
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      " " +
                                                                                        _vm._s(
                                                                                          _vm.$t(
                                                                                            "xExerciseOnDemand"
                                                                                          )
                                                                                        ) +
                                                                                        " "
                                                                                    )
                                                                                  ]
                                                                                ),
                                                                                _c(
                                                                                  "p",
                                                                                  {
                                                                                    staticClass:
                                                                                      "premium-feature-supporting-text"
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      " " +
                                                                                        _vm._s(
                                                                                          _vm.$t(
                                                                                            "xExerciseOnDemandDesc"
                                                                                          )
                                                                                        ) +
                                                                                        " "
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "feature"
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "i",
                                                                              {
                                                                                staticClass:
                                                                                  "far fa-fw fa-warning"
                                                                              }
                                                                            ),
                                                                            _c(
                                                                              "div",
                                                                              [
                                                                                _c(
                                                                                  "p",
                                                                                  {
                                                                                    staticClass:
                                                                                      "premium-feature-text"
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      " " +
                                                                                        _vm._s(
                                                                                          _vm.$t(
                                                                                            "xDismissWarnings"
                                                                                          )
                                                                                        ) +
                                                                                        " "
                                                                                    )
                                                                                  ]
                                                                                ),
                                                                                _c(
                                                                                  "p",
                                                                                  {
                                                                                    staticClass:
                                                                                      "premium-feature-supporting-text"
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      " " +
                                                                                        _vm._s(
                                                                                          _vm.$t(
                                                                                            "xDismissWarningsDesc"
                                                                                          )
                                                                                        ) +
                                                                                        " "
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                )
                                                              ]
                                                            )
                                                          : apparatus.type === 2
                                                          ? _c(
                                                              "b-collapse",
                                                              {
                                                                attrs: {
                                                                  id:
                                                                    "collapse-upgrade-" +
                                                                    apparatus.apparatusId
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "features"
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "premium"
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "p",
                                                                          {
                                                                            staticClass:
                                                                              "mb-1"
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "strong",
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    _vm.$t(
                                                                                      "xFeaturesIncluded"
                                                                                    )
                                                                                  )
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "feature"
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "i",
                                                                              {
                                                                                staticClass:
                                                                                  "fa-regular fa-gas-pump"
                                                                              }
                                                                            ),
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "mb-2"
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "p",
                                                                                  {
                                                                                    attrs: {
                                                                                      "data-test-hook":
                                                                                        "premium-enrollment-modal-step-1-fuel-monitor-feature-title-1"
                                                                                    }
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "strong",
                                                                                      [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            _vm.$t(
                                                                                              "xCurrentFuelLevel"
                                                                                            )
                                                                                          )
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  ]
                                                                                ),
                                                                                _c(
                                                                                  "p",
                                                                                  {
                                                                                    staticClass:
                                                                                      "supporting-text",
                                                                                    attrs: {
                                                                                      "data-test-hook":
                                                                                        "premium-enrollment-modal-step-1-fuel-monitor-feature-description-1"
                                                                                    }
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      " " +
                                                                                        _vm._s(
                                                                                          _vm.$t(
                                                                                            "xFuelLevelDescription"
                                                                                          )
                                                                                        ) +
                                                                                        " "
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "feature"
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "i",
                                                                              {
                                                                                staticClass:
                                                                                  "fa-regular fa-chart-line"
                                                                              }
                                                                            ),
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "mb-2"
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "p",
                                                                                  {
                                                                                    attrs: {
                                                                                      "data-test-hook":
                                                                                        "premium-enrollment-modal-step-1-fuel-monitor-feature-title-1"
                                                                                    }
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "strong",
                                                                                      [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            _vm.$t(
                                                                                              "xFuelUsageHistory"
                                                                                            )
                                                                                          )
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  ]
                                                                                ),
                                                                                _c(
                                                                                  "p",
                                                                                  {
                                                                                    staticClass:
                                                                                      "supporting-text",
                                                                                    attrs: {
                                                                                      "data-test-hook":
                                                                                        "premium-enrollment-modal-step-1-fuel-monitor-feature-description-1"
                                                                                    }
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      " " +
                                                                                        _vm._s(
                                                                                          _vm.$t(
                                                                                            "xFuelUsageDescription"
                                                                                          )
                                                                                        ) +
                                                                                        " "
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "feature"
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "i",
                                                                              {
                                                                                staticClass:
                                                                                  "fa-regular fa-warning"
                                                                              }
                                                                            ),
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "mb-2"
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "p",
                                                                                  {
                                                                                    attrs: {
                                                                                      "data-test-hook":
                                                                                        "premium-enrollment-modal-step-1-fuel-monitor-feature-title-1"
                                                                                    }
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "strong",
                                                                                      [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            _vm.$t(
                                                                                              "xFuelLevelWarnings"
                                                                                            )
                                                                                          )
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  ]
                                                                                ),
                                                                                _c(
                                                                                  "p",
                                                                                  {
                                                                                    staticClass:
                                                                                      "supporting-text",
                                                                                    attrs: {
                                                                                      "data-test-hook":
                                                                                        "premium-enrollment-modal-step-1-fuel-monitor-feature-description-1"
                                                                                    }
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      " " +
                                                                                        _vm._s(
                                                                                          _vm.$t(
                                                                                            "xFuelLevelWarningsDescription"
                                                                                          )
                                                                                        ) +
                                                                                        " "
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "feature"
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "i",
                                                                              {
                                                                                staticClass:
                                                                                  "fa-regular fa-battery-full"
                                                                              }
                                                                            ),
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "mb-2"
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "p",
                                                                                  {
                                                                                    attrs: {
                                                                                      "data-test-hook":
                                                                                        "premium-enrollment-modal-step-1-fuel-monitor-feature-title-1"
                                                                                    }
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "strong",
                                                                                      [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            _vm.$t(
                                                                                              "xBatteryLevel"
                                                                                            )
                                                                                          )
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  ]
                                                                                ),
                                                                                _c(
                                                                                  "p",
                                                                                  {
                                                                                    staticClass:
                                                                                      "supporting-text",
                                                                                    attrs: {
                                                                                      "data-test-hook":
                                                                                        "premium-enrollment-modal-step-1-fuel-monitor-feature-description-1"
                                                                                    }
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      " " +
                                                                                        _vm._s(
                                                                                          _vm.$t(
                                                                                            "xBatteryLevelDescription"
                                                                                          )
                                                                                        ) +
                                                                                        " "
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                )
                                                              ]
                                                            )
                                                          : _vm._e()
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  })
                                ],
                                2
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                  _c(
                    "div",
                    [
                      _c(
                        "b-row",
                        { staticClass: "mt-3", attrs: { "no-gutters": "" } },
                        [
                          _c("b-col", [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-outline-warning mr-2",
                                staticStyle: { width: "45%" },
                                attrs: {
                                  type: "button",
                                  "data-test-hook":
                                    "previous-button-step1-downgrade-sub-modal"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.$bvModal.hide(
                                      "premium-enrollment-modal-step-1"
                                    )
                                  }
                                }
                              },
                              [_vm._v(" " + _vm._s(_vm.$t("xCancel")) + " ")]
                            ),
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-warning ml-2",
                                staticStyle: { width: "48%" },
                                attrs: {
                                  type: "button",
                                  disabled: _vm.subscribingProduct,
                                  "data-test-hook":
                                    "yes-downgrade-button-step1-downgrade-sub-modal"
                                },
                                on: {
                                  click: function($event) {
                                    _vm.$bvModal.hide(
                                      "premium-enrollment-modal-step-1"
                                    ),
                                      _vm.$bvModal.show(
                                        "premium-enrollment-modal-step-2"
                                      )
                                  }
                                }
                              },
                              [
                                _vm.subscribingProduct
                                  ? _c("span", [_c("b-spinner")], 1)
                                  : _c("span", [
                                      _vm._v(_vm._s(_vm.$t("xContinue")))
                                    ])
                              ]
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "mt-3" },
                    [
                      _c("b-col", { staticClass: "text-center" }, [
                        _c("p", { staticClass: "legal-disclaimer" }, [
                          _vm._v(
                            "*" +
                              _vm._s(
                                _vm.$t(
                                  "xPushNotificationsAreAvailableWithInstallation"
                                )
                              )
                          )
                        ])
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-modal",
                {
                  attrs: {
                    id: "premium-enrollment-modal-step-2",
                    size: "lg",
                    "no-fade": "",
                    "no-stacking": "",
                    centered: "",
                    "no-close-on-esc": "",
                    "no-close-on-backdrop": ""
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "modal-header",
                      fn: function() {
                        return [
                          _c("div", [
                            _c(
                              "h2",
                              {
                                staticClass: "modal-title",
                                attrs: {
                                  "data-test-hook":
                                    "premium-enrollment-modal-step-1-header"
                                }
                              },
                              [
                                _c("div", [
                                  _c("i", {
                                    staticClass:
                                      "fal fa-credit-card colorOrange mr-1",
                                    attrs: {
                                      "data-test-hook":
                                        "premium-enrollment-modal-step-1-header-icon"
                                    }
                                  }),
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("xAddAPaymentMethod")) +
                                      " "
                                  )
                                ])
                              ]
                            )
                          ])
                        ]
                      },
                      proxy: true
                    },
                    {
                      key: "modal-footer",
                      fn: function() {
                        return [
                          !_vm.useExistingPaymentInfo ||
                          !_vm.existingPaymentInfo
                            ? _c(
                                "b-button",
                                {
                                  attrs: {
                                    variant: "warning",
                                    disabled: _vm.paying,
                                    block: "",
                                    "data-test-hook":
                                      "premium-enrollment-modal-step-2-save-payment-info-button"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.payForProduct()
                                    }
                                  }
                                },
                                [
                                  _vm.paying
                                    ? _c("span", [_c("b-spinner")], 1)
                                    : _c("span", [
                                        _vm._v(_vm._s(_vm.$t("xPayNow")))
                                      ])
                                ]
                              )
                            : _c(
                                "b-button",
                                {
                                  attrs: {
                                    variant: "warning",
                                    disabled: _vm.subscribingProduct,
                                    block: "",
                                    "data-test-hook":
                                      "premium-enrollment-modal-step-2-confirm-subscription-button"
                                  },
                                  on: {
                                    click: function($event) {
                                      _vm.subscribeProduct(),
                                        _vm.closeModal(
                                          "downgrade-paid-sub-step-1"
                                        )
                                    }
                                  }
                                },
                                [
                                  _vm.subscribingProduct
                                    ? _c("span", [_c("b-spinner")], 1)
                                    : _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.$t("xConfirmSubscription"))
                                        )
                                      ])
                                ]
                              )
                        ]
                      },
                      proxy: true
                    }
                  ])
                },
                [
                  _vm.loadingSubscriptionModal
                    ? _c(
                        "div",
                        [
                          _c(
                            "b-row",
                            { staticClass: "text-center" },
                            [
                              _c(
                                "b-col",
                                [
                                  _c("b-spinner", {
                                    staticStyle: {
                                      width: "10rem",
                                      height: "10rem"
                                    },
                                    attrs: {
                                      variant: "warning",
                                      label: "Spinning"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _c(
                        "div",
                        [
                          _c(
                            "b-alert",
                            {
                              attrs: {
                                show: _vm.showValidationBanner,
                                variant: "danger"
                              }
                            },
                            [
                              _c("p", [
                                _vm._v(
                                  _vm._s(_vm.$t("xExperiencedTheseErrors"))
                                )
                              ]),
                              _vm._l(_vm.errorMessages, function(
                                message,
                                index
                              ) {
                                return _c("ul", { key: index }, [
                                  _c("li", { attrs: { id: index } }, [
                                    _vm._v(" " + _vm._s(message) + " ")
                                  ])
                                ])
                              })
                            ],
                            2
                          ),
                          _vm.existingPaymentInfo
                            ? _c(
                                "div",
                                { staticClass: "mt-4" },
                                [
                                  _c("h5", [
                                    _vm._v(_vm._s(_vm.$t("xCreditCardInfo")))
                                  ]),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "custom-control custom-checkbox"
                                    },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.useExistingBillingInformation,
                                            expression:
                                              "useExistingBillingInformation"
                                          }
                                        ],
                                        staticClass: "custom-control-input",
                                        attrs: {
                                          type: "checkbox",
                                          id: "customCheck",
                                          "data-test-hook":
                                            "UseCurrentBillingAddressBtn"
                                        },
                                        domProps: {
                                          checked: Array.isArray(
                                            _vm.useExistingBillingInformation
                                          )
                                            ? _vm._i(
                                                _vm.useExistingBillingInformation,
                                                null
                                              ) > -1
                                            : _vm.useExistingBillingInformation
                                        },
                                        on: {
                                          change: function($event) {
                                            var $$a =
                                                _vm.useExistingBillingInformation,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? true : false
                                            if (Array.isArray($$a)) {
                                              var $$v = null,
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  (_vm.useExistingBillingInformation = $$a.concat(
                                                    [$$v]
                                                  ))
                                              } else {
                                                $$i > -1 &&
                                                  (_vm.useExistingBillingInformation = $$a
                                                    .slice(0, $$i)
                                                    .concat($$a.slice($$i + 1)))
                                              }
                                            } else {
                                              _vm.useExistingBillingInformation = $$c
                                            }
                                          }
                                        }
                                      }),
                                      _c(
                                        "label",
                                        {
                                          staticClass: "custom-control-label",
                                          attrs: {
                                            for: "customCheck",
                                            "data-test-hook":
                                              "useCurrBillingInfoText"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("xUseCurrentBillingInfo")
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _c("div", { staticClass: "row mt-2" }, [
                                    _c("div", { staticClass: "col-12" }, [
                                      _vm._v(
                                        _vm._s(_vm.accountProfile.firstName) +
                                          " " +
                                          _vm._s(_vm.accountProfile.lastName)
                                      )
                                    ]),
                                    _c("div", { staticClass: "col-12" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.DisplayAddress(
                                              _vm.accountProfile.address
                                            )
                                          ) +
                                          " "
                                      )
                                    ])
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "subscription-cost mt-3" },
                                    [
                                      _c("div", { staticClass: "row mt-2" }, [
                                        _c(
                                          "div",
                                          { staticClass: "col-12 text-left" },
                                          [
                                            _c("strong", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("xTotalPayment")
                                                ) + ": "
                                              )
                                            ]),
                                            _c("strong", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f("formatCurrency")(
                                                    _vm.premiumSubscriptionCost
                                                  )
                                                )
                                              )
                                            ])
                                          ]
                                        )
                                      ]),
                                      _c("div", { staticClass: "row" }, [
                                        _c("div", {
                                          staticClass: "col-12 text-left"
                                        })
                                      ]),
                                      _c("div", { staticClass: "row" }, [
                                        _c("div", { staticClass: "col" }, [
                                          _c(
                                            "p",
                                            { staticClass: "body-text-color" },
                                            [
                                              _vm.premiumSubscriptionDuration ===
                                              12
                                                ? _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t("xBilledYearly")
                                                      )
                                                    )
                                                  ])
                                                : _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t("xBilledMonthly")
                                                      )
                                                    )
                                                  ])
                                            ]
                                          )
                                        ])
                                      ])
                                    ]
                                  ),
                                  _c(
                                    "b-row",
                                    { staticClass: "mt-3" },
                                    [
                                      _c("b-col", [
                                        _c("p", [
                                          _vm._v(
                                            "*" +
                                              _vm._s(
                                                _vm.$t(
                                                  "xUpdatingCCApplyActiveSubscriptions"
                                                )
                                              )
                                          )
                                        ])
                                      ])
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    [
                                      _c("b-col", [
                                        _vm.useExistingPaymentInfo
                                          ? _c(
                                              "div",
                                              [
                                                _c(
                                                  "b-row",
                                                  { staticClass: "mt-2" },
                                                  [
                                                    _c(
                                                      "b-col",
                                                      { attrs: { cols: "12" } },
                                                      [
                                                        _c("p", [
                                                          _c("strong", [
                                                            _c("i", {
                                                              staticClass:
                                                                "fal fa-credit-card"
                                                            }),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm
                                                                    .existingPaymentInfo
                                                                    .cardType
                                                                ) +
                                                                " - " +
                                                                _vm._s(
                                                                  _vm
                                                                    .existingPaymentInfo
                                                                    .cardSuffix
                                                                ) +
                                                                " "
                                                            )
                                                          ])
                                                        ])
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "b-row",
                                                  [
                                                    _c(
                                                      "b-col",
                                                      { attrs: { cols: "12" } },
                                                      [
                                                        _c("p", [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "xExpires"
                                                                )
                                                              ) +
                                                              " " +
                                                              _vm._s(
                                                                _vm
                                                                  .existingPaymentInfo
                                                                  .expirationMonth +
                                                                  "/" +
                                                                  _vm
                                                                    .existingPaymentInfo
                                                                    .expirationYear
                                                              ) +
                                                              " "
                                                          )
                                                        ])
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          !_vm.existingPaymentInfo ||
                          !_vm.useExistingPaymentInfo
                            ? _c(
                                "div",
                                { staticClass: "mt-4" },
                                [
                                  _c(
                                    "b-row",
                                    [
                                      _c("b-col", [
                                        _c(
                                          "h4",
                                          {
                                            attrs: {
                                              "data-test-hook":
                                                "premium-enrollment-modal-step-2-enter-billing-info-text"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("xEnterBillingInfo")
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      ])
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    [
                                      _c("b-col", [
                                        _c(
                                          "i",
                                          { staticClass: "asterisk-text" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "xUpdatingPaymentWarning"
                                                  )
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      ])
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { staticClass: "mt-2" },
                                    [
                                      _c(
                                        "b-col",
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                id: "credit-card-number"
                                              }
                                            },
                                            [
                                              _c("b-form-input", {
                                                directives: [
                                                  {
                                                    name: "mask",
                                                    rawName: "v-mask",
                                                    value: "################",
                                                    expression:
                                                      "'################'"
                                                  }
                                                ],
                                                attrs: {
                                                  type: "text",
                                                  maxlength: "16",
                                                  placeholder: _vm.$t(
                                                    "xCreditCardNumber"
                                                  ),
                                                  state: _vm.validState(
                                                    _vm.$v.creditCardNumber
                                                  ),
                                                  "aria-describedby":
                                                    "card-number-invalid",
                                                  "data-test-hook":
                                                    "premium-enrollment-modal-step-2-credit-card-number-field"
                                                },
                                                model: {
                                                  value:
                                                    _vm.$v.creditCardNumber
                                                      .$model,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.$v.creditCardNumber,
                                                      "$model",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "$v.creditCardNumber.$model"
                                                }
                                              }),
                                              _c(
                                                "b-form-invalid-feedback",
                                                {
                                                  attrs: {
                                                    id: "card-number-invalid"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "xCCFieldValidation"
                                                        )
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { staticClass: "mt-2" },
                                    [
                                      _c(
                                        "b-col",
                                        {
                                          attrs: { lg: "6", md: "6", sm: "12" }
                                        },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: { id: "expiry-date-month" }
                                            },
                                            [
                                              _c("b-form-input", {
                                                directives: [
                                                  {
                                                    name: "mask",
                                                    rawName: "v-mask",
                                                    value: "##",
                                                    expression: "'##'"
                                                  }
                                                ],
                                                attrs: {
                                                  type: "text",
                                                  placeholder: _vm.$t("xMm"),
                                                  maxlength: "2",
                                                  state: _vm.validState(
                                                    _vm.$v.expirationMonth
                                                  ),
                                                  "aria-describedby":
                                                    "expiry-month-invalid",
                                                  "data-test-hook":
                                                    "premium-enrollment-modal-step-2-expiration-month-field"
                                                },
                                                model: {
                                                  value:
                                                    _vm.$v.expirationMonth
                                                      .$model,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.$v.expirationMonth,
                                                      "$model",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "$v.expirationMonth.$model"
                                                }
                                              }),
                                              _c(
                                                "b-form-invalid-feedback",
                                                {
                                                  attrs: {
                                                    id: "expiry-month-invalid"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "xExpirationMonthValidation"
                                                        )
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        {
                                          attrs: { lg: "6", md: "6", sm: "12" }
                                        },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: { id: "expiry-date-year" }
                                            },
                                            [
                                              _c("b-form-input", {
                                                directives: [
                                                  {
                                                    name: "mask",
                                                    rawName: "v-mask",
                                                    value: "##",
                                                    expression: "'##'"
                                                  }
                                                ],
                                                attrs: {
                                                  type: "text",
                                                  placeholder: _vm.$t("xYy"),
                                                  maxlength: "2",
                                                  state: _vm.validState(
                                                    _vm.$v.expirationYear
                                                  ),
                                                  "aria-describedby":
                                                    "expiry-year-invalid",
                                                  "data-test-hook":
                                                    "premium-enrollment-modal-step-2-expiration-year-field"
                                                },
                                                model: {
                                                  value:
                                                    _vm.$v.expirationYear
                                                      .$model,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.$v.expirationYear,
                                                      "$model",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "$v.expirationYear.$model"
                                                }
                                              }),
                                              _c(
                                                "b-form-invalid-feedback",
                                                {
                                                  attrs: {
                                                    id: "expiry-year-invalid"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "xExpirationYearValidation"
                                                        )
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { staticClass: "mt-2" },
                                    [
                                      _c(
                                        "b-col",
                                        [
                                          _c(
                                            "b-input-group",
                                            { attrs: { id: "cvv" } },
                                            [
                                              _c("b-form-input", {
                                                directives: [
                                                  {
                                                    name: "mask",
                                                    rawName: "v-mask",
                                                    value: "####",
                                                    expression: "'####'"
                                                  }
                                                ],
                                                attrs: {
                                                  type: "text",
                                                  maxlength: "4",
                                                  placeholder: _vm.$t("xCvv"),
                                                  state: _vm.validState(
                                                    _vm.$v.cvv
                                                  ),
                                                  "aria-describedby":
                                                    "cvv-invalid",
                                                  "data-test-hook":
                                                    "premium-enrollment-modal-step-2-cvv-field"
                                                },
                                                model: {
                                                  value: _vm.$v.cvv.$model,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.$v.cvv,
                                                      "$model",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "$v.cvv.$model"
                                                }
                                              }),
                                              _c(
                                                "b-input-group-append",
                                                [
                                                  _c(
                                                    "b-input-group-text",
                                                    [
                                                      _c("b-img", {
                                                        attrs: {
                                                          height: 20,
                                                          src: require("@/assets/images/3-digit-code.svg"),
                                                          "data-test-hook":
                                                            "premium-enrollment-modal-step-2-cvv-image"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              ),
                                              _c(
                                                "b-form-invalid-feedback",
                                                {
                                                  attrs: { id: "cvv-invalid" }
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t("xCVVValidation")
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    [
                                      _c("b-col", { staticClass: "mt-3" }, [
                                        _c(
                                          "div",
                                          { staticClass: "subscription-cost" },
                                          [
                                            _c(
                                              "h4",
                                              {
                                                attrs: {
                                                  "data-test-hook":
                                                    "premium-enrollment-modal-step-2-subscription-cost-and-term"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm._f("formatCurrency")(
                                                        _vm.premiumSubscriptionCost
                                                      )
                                                    ) +
                                                    " " +
                                                    _vm._s(
                                                      _vm.$t("xBilledYearly")
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ])
                                    ],
                                    1
                                  ),
                                  _c("b-row", [_c("b-col")], 1)
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "b-row",
                            { staticClass: "mt-4" },
                            [
                              _c("b-col", [
                                _c(
                                  "p",
                                  {
                                    attrs: {
                                      "data-test-hook":
                                        "premium-enrollment-modal-step-2-auto-renew-message"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "xThisSubscriptionWillAutoRenew"
                                          )
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ])
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            { staticClass: "mt-3" },
                            [
                              _c(
                                "b-col",
                                [
                                  _c(
                                    "b-form-checkbox",
                                    {
                                      attrs: {
                                        state: _vm.validState(_vm.$v.consent)
                                      },
                                      model: {
                                        value: _vm.$v.consent.$model,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.$v.consent,
                                            "$model",
                                            $$v
                                          )
                                        },
                                        expression: "$v.consent.$model"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("xConsentCheckboxText")
                                          ) +
                                          " "
                                      )
                                    ]
                                  ),
                                  _vm.consent == false
                                    ? _c(
                                        "p",
                                        { staticClass: "colorRed ml-4" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "xConsentNotCheckedError"
                                                )
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                ]
              )
            ],
            2
          )
        ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-auto" }, [
      _c("i", { staticClass: "fas fa-chevron-down when-closed" }),
      _c("i", { staticClass: "fas fa-chevron-up when-open" })
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-auto" }, [
      _c("i", { staticClass: "fas fa-chevron-down when-closed" }),
      _c("i", { staticClass: "fas fa-chevron-up when-open" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }