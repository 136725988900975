<template>
  <div class="mt-2 ml-md-2">
    <div v-if="!technicalSpecs" class="d-flex justify-content-center mb-3 pt-4">
      <span class="spinner-border text-warning" style="width: 5rem; height: 5rem;" label="Spinning"></span>
    </div>
    <div v-else>
      <div class="row no-gutters mb-2">
        <div class="col">
          <h1 class="mb-0 pb-0">
            <i class="far fa-clipboard-list colorOrange mr-2" data-test-hook="PageIcon"></i>
            <span data-test-hook="PageTitle">{{ $t('xTechnicalSpecs') }}</span>
          </h1>
        </div>
      </div>

      <div class="card">
        <div class="card-body">
          <div class="row mb-3 mt-2" v-if="technicalSpecs && technicalSpecs.productType">
            <div class="cols-12 col-lg-4">
              <p data-test-hook="ProductType">{{ $t('xProductType') }} ({{ technicalSpecs.productType }})</p>
            </div>
            <div class="col-12" data-test-hook="ProductDescription">
              <p>
                <strong>{{ technicalSpecs.description }}</strong>
              </p>
            </div>
          </div>
          <div class="row">
            <div
              class="col-12 col-lg-4 mb-3"
              v-for="prop in techSpecs"
              :key="prop.name"
              :data-test-hook="prop.name.replace(/\s/g, ``) + `Label`"
            >
              <p>{{ prop.name }}</p>
              <p :data-test-hook="prop.name.replace(/\s/g, ``) + `Value`">
                <strong>{{ prop.value }}</strong>
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <button
                type="button"
                class="mt-2 btn btn-outline-warning"
                @click="getManuals()"
                data-test-hook="manuals-button-tech-specs"
              >
                <i class="fas fa-file-alt"></i>
                {{ $t('xManuals') }}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="card my-4">
        <div class="card-body">
          <div class="row mb-2">
            <div class="col">
              <h2 data-test-hook="ProductOverviewHeading">
                {{ $t('xProductOverview') }}
              </h2>
            </div>
          </div>
          <div class="row" v-if="technicalSpecs && technicalSpecs.attributes">
            <div class="col-12 col-lg-4" v-for="(chunk, index) in attributeChunks" :key="index">
              <div
                v-for="attribute in chunk"
                :key="attribute.name"
                :data-test-hook="'LabelValueGroup-' + removeExtraChars(attribute.name)"
                class="mb-3"
              >
                <p :data-test-hook="'AttributeName-'  + removeExtraChars(attribute.name)">{{ attribute.name }}</p>
                <p :data-test-hook="'AttributeValue-' + removeExtraChars(attribute.name)">
                  <strong>{{ attribute.value }}</strong>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Modal v-if="technicalSpecs" modalId="manualModal" ref="manual-modal" size="modal-xl">
      <ModalHeader :title="$t('xManualModalTitle') + ': ' + serialNumber[0].value" data-test-hook="modalTitle">
        <div class="col-auto">
          <button
            type="button"
            class="btn btn-icon-button btn-icon-button--gray"
            @click="closeModal('manualModal')"
            data-test-hook="x-button-manualModal-tech-specs"
          >
            <i class="fas fa-times" />
          </button>
        </div>
      </ModalHeader>
      <ModalBody>
        <ProductManuals :manuals="manuals" :loaded="manualsLoaded"></ProductManuals>
      </ModalBody>
      <ModalFooter>
        <button
          type="button"
          class="btn btn-warning"
          @click="hideManualModal()"
          data-test-hook="ok-button-manualModal-tech-specs"
        >
          {{ $t('xOK') }}
        </button>
      </ModalFooter>
    </Modal>
  </div>
</template>

<script>
import _ from 'lodash'
import { mapState, mapGetters, mapActions } from 'vuex'
import ProductManuals from '@/components/ApparatusDetails/ProductManuals'

export default {
  components: {
    ProductManuals
  },
  data() {
    return {
      technicalSpecs: null,
      manuals: null,
      manualsLoaded: false,
      serialNumber: null,
      techSpecs: null
    }
  },
  computed: {
    ...mapState({
      currentApparatusTechnicalSpecs: state => state.apparatus.currentApparatusTechnicalSpecs
    }),
    attributeChunks() {
      return _.chunk(this.technicalSpecs.attributes, this.technicalSpecs.attributes.length / 2)
    },
    getPartsURL() {
      var encodedURL =
        'https://generac.ordertree.com/search/?q=' + encodeURIComponent(this.technicalSpecs.description)
      return encodedURL
    }
  },
  methods: {
    hideManualModal() {
      this.closeModal('manualModal')
    },
    getManuals() {
      this.openModal('manualModal')
      this.$appInsights.trackEvent({
        page: 'Technical Specs',
        name: 'getting-product-manuals',
        properties: {
          apparatusId: this.$route.params.apparatusId
        }
      })
      if (!this.manualsLoaded) {
        this.$store
          .dispatch('getApparatusProductManaulsById', { apparatusId: this.$route.params.apparatusId })
          .then(response => {
            this.manuals = response.data.manuals
            this.$appInsights.trackEvent({
              page: 'Technical Specs',
              name: 'manuals-found-successfully',
              properties: {
                apparatusId: this.$route.params.apparatusId
              }
            })
          })
          .catch(error => {
            this.$appInsights.trackEvent({
              page: 'Technical Specs',
              name: 'manuals-not-found',
              properties: {
                apparatusId: this.$route.params.apparatusId
              }
            })
          })
          .finally(() => {
            this.manualsLoaded = true
          })
      }
    },
    removeExtraChars(testhook) {
      return testhook
        .split(' ')
        .join('')
        .split('/')
        .join('')
        .split('.')
        .join('')
        .split('(')
        .join('')
        .split(')')
        .join('')
        .split('#')
        .join('')
        .split('%')
        .join('')
    }
  },
  watch: {
    currentApparatusTechnicalSpecs: {
      handler: function(newVal, oldVal) {
        this.technicalSpecs = _.cloneDeep(newVal)
        this.techSpecs = this.technicalSpecs.properties.filter(function(prop) {
          if (prop.value != null) {
            return prop
          }
        })
        this.serialNumber = this.technicalSpecs.properties.filter(function(prop) {
          return prop.name == 'Serial Number'
        })
      },
      deep: true
    }
  },
  mounted() {
    this.$store.dispatch('getApparatusTechnicalSpecs', this.$route.params.apparatusId)
    if (this.technicalSpecs) {
      this.serialNumber = this.technicalSpecs.properties.filter(function(prop) {
        return prop.name == 'Serial Number'
      })
    }
  }
}
</script>

<style scoped>
.tech-specs-footer {
  border: none;
  background-color: white;
}
</style>
