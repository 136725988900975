<template>
  <div id="homeDiv">
    <b-row align-h="center" class="h-100 m-0" align-v="center">
      <b-col class="d-flex justify-content-center">
        <b-card class="card-shadow login-cards" v-if="expiredLinkError">
          <b-row>
            <b-col :class="step == 'email' ? 'invisible' : null">
              <span class="hoverPointer" @click="back()" data-test-hook="cancel-button-expiredLink-home">
                <i class="fas fa-chevron-left"></i> {{ $t('xCancel') }}
              </span>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="col-10">
              <b-img
                :src="require('@/assets/images/MobileLink_Logo_2023_COLOR.svg')"
                fluid
                alt="Mobile Link Logo"
              ></b-img>
            </b-col>
          </b-row>
          <b-row no-gutters class="mx-0 my-2 p-2">
            <b-col>
              {{ $t('xThisLinkHasExpired') }}
            </b-col>
          </b-row>
        </b-card>
        <b-card class="card-shadow login-cards" v-else-if="userEmailStatus == null">
          <b-row>
            <b-col :class="step == 'email' ? 'invisible' : null">
              <span class="hoverPointer" @click="back()" data-test-hook="cancel-button-noUserEmailStatus-home">
                <i class="fas fa-chevron-left"></i> {{ $t('xCancel') }}
              </span>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="col-10">
              <b-img
                :src="require('@/assets/images/MobileLink_Logo_2023_COLOR.svg')"
                fluid
                alt="Mobile Link Logo"
              ></b-img>
            </b-col>
          </b-row>
          <b-row align-v="center" no-gutters class="errorBanner mx-0 my-2 p-2" v-if="loginError">
            <b-col>
              {{ $t('xAnErrorHasOccurred') }}
            </b-col>
          </b-row>
          <b-row align-v="center" no-gutters class="errorBanner mx-0 my-2 p-2" v-if="inviteError">
            <b-col>
              {{ $t('xItSeemsAsThoughYouHave') }}
            </b-col>
          </b-row>
          <b-row align-v="center" no-gutters class="errorBanner mx-0 my-2 p-2" v-if="correlationError">
            <b-col>
              {{ $t('xMobileLinkExperiencedAnErrorSigning') }}
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-alert :show="showValidationBanner" variant="danger" data-test-hook="ValidationAlert">
                {{ emailVerificationFailureMessage }}
              </b-alert>
            </b-col>
          </b-row>
          <b-row v-if="step == 'email'">
            <b-col>
              <h2>{{ $t('xSignInOrSignUp') }}</h2>
              <b-form-group id="email-group" label-for="email" :label="$t('xEmailAddress')">
                <b-form-input
                  id="email"
                  v-model="$v.email.$model"
                  maxlength="70"
                  type="text"
                  :placeholder="$t('xEmailAddress')"
                  :state="validState($v.email)"
                  aria-describedby="email-invalid"
                  :disabled="processing"
                  v-on:keyup.enter="next"
                  :formatter="lowerCaseFormatter"
                ></b-form-input>
                <b-form-invalid-feedback id="email-invalid">
                  {{ $t('xRequiredField') }}. {{ $t('xEmailInvalidError') }}
                </b-form-invalid-feedback>
                <span class="hoverPointer btn-link d-block" @click="$bvModal.show('ForgotEmailModal')">
                  {{ $t('xForgotYourEmail') }}
                </span>
              </b-form-group>
            </b-col>
          </b-row>
          <div v-if="step != 'verify'">
            <b-row class="mt-1 mb-3">
              <b-col>
                <b-button
                  block
                  variant="warning"
                  @click="next()"
                  :disabled="processing"
                  data-test-hook="next-button-noUserEmailStatus-home"
                >
                  {{ $t('xNext') }}
                  <b-spinner class="ml-2" v-if="processing"></b-spinner>
                  <span class="pl-1">
                    <i class="far fa-arrow-right"></i>
                  </span>
                </b-button>
              </b-col>
            </b-row>
          </div>
        </b-card>
        <b-card class="card-shadow login-cards" v-else>
          <b-row>
            <b-col :class="step == 'email' ? 'invisible' : null">
              <span class="hoverPointer" @click="back()" data-test-hook="cancel-button-else-home">
                <i class="fas fa-chevron-left"></i> {{ $t('xCancel') }}
              </span>
            </b-col>
          </b-row>
          <b-row class="my-3">
            <b-col>
              <b-img
                :src="require('@/assets/images/MobileLink_Logo_2023_COLOR.svg')"
                fluid
                alt="Mobile Link Logo"
              ></b-img>
            </b-col>
          </b-row>
          <b-row align-v="center" no-gutters class="mx-0 my-2 p-2" v-if="userEmailStatus == 3">
            <b-col>
              {{ $t('xYourEmailAddressHasAlreadyBeenInvited') }}
            </b-col>
          </b-row>
          <b-row align-v="center" no-gutters class="mx-0 my-2 p-2" v-else-if="userEmailStatus == 5">
            <b-col>
              {{ $t('xItLooksLikeWeEncounteredAProblem') }}
            </b-col>
            <b-button
              class="mx-0 my-3 p-2"
              block
              variant="warning"
              @click="retryLogin()"
              data-test-hook="retry-button-else-home"
            >
              {{ $t('xRetry') }}
              <span class="float-right">
                <i class="far fa-arrow-right"></i>
              </span>
            </b-button>
          </b-row>
          <b-row align-v="center" no-gutters class="mx-0 my-2 p-2" v-else>
            <b-col>
              {{ $t('xThereIsAnIssueWithYourAccount') }}
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <ForgotEmailModal />
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { required, email } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'
import ForgotEmailModal from '@/components/Home/ForgotEmailModal'
import { vueAppApiBaseAddress } from '@/utils/mixins'
import * as Constants from '@/constants'

export default {
  components: { ForgotEmailModal },
  mixins: [validationMixin],
  data() {
    return {
      email: '',
      password: '',
      step: 'email',
      processing: false,
      users: null,
      termsConditionsUrl: process.env.VUE_APP_TERMS_CONDITIONS_URL,
      eulaUrl: process.env.VUE_APP_EULA_URL,
      privacyPolicyUrl: process.env.VUE_APP_PRIVACY_POLICY_URL,
      userEmailStatus: null,
      emailVerificationFailureMessage: null,
      showValidationBanner: false
    }
  },
  validations: {
    email: {
      required,
      email
    }
  },
  computed: {
    ...mapState({
      loginError: state => state.account.loginError,
      correlationError: state => state.account.correlationError,
      expiredLinkError: state => state.account.expiredLinkError,
      inviteError: state => state.account.inviteError,
      hasUnreadMessage: state => state.account.hasUnreadMessages
    })
  },
  methods: {
    next() {
      window.localStorage.removeItem(Constants.ForceSignOutErrorLocalStorageKey)
      this.showValidationBanner = false
      this.emailVerificationFailureMessage = null
      if (this.step == 'email') {
        this.$v.email.$touch()
        if (!this.$v.$anyError) {
          window.localStorage.setItem(Constants.UserEmailLocalStorageKey, this.email)
          this.processing = true
          this.$store
            .dispatch('migrationStatus', this.email)
            .then(response => {
              if (response.data.status == 4) {
                window.location.assign(vueAppApiBaseAddress() + `Auth/Migrate?email=${encodeURIComponent(this.email)}`)
              } else if (response.data.status == 2) {
                window.location.assign(vueAppApiBaseAddress() + `Auth/SignUp?email=${encodeURIComponent(this.email)}`)
              } else if (response.data.status == 1) {
                window.location.assign(vueAppApiBaseAddress() + `Auth/SignIn?email=${encodeURIComponent(this.email)}`)
              } else {
                // This is for AutoEnroll, Retry and Unknown cases
                this.userEmailStatus = response.data.status
              }
            })
            .catch(error => {
              if (error.response.status == 503 || error.response.data.status == 503) {
                //TODO: display maintenance error message in login modal
                this.emailVerificationFailureMessage = this.$t('xBillingMaintenanceMessage')
                this.showValidationBanner = true
              }
            })
            .finally(() => {
              this.processing = false
            })
        }
      }
    },
    retryLogin() {
      this.userEmailStatus = null
    },
    lowerCaseFormatter(value) {
      return value.toLowerCase()
    }
  },
  created() {
    if (this.$route.query.singleSignOnEmail) {
      this.email = this.$route.query.singleSignOnEmail
      this.processing = true
    }
  },
  mounted() {
    let signOutErrorMessage = window.localStorage.getItem(Constants.ForceSignOutErrorLocalStorageKey)
    window.localStorage.removeItem(Constants.ForceSignOutErrorLocalStorageKey)
    window.localStorage.removeItem(Constants.UserEmailLocalStorageKey)
    if (signOutErrorMessage) {
      this.emailVerificationFailureMessage = signOutErrorMessage
      this.showValidationBanner = true
    }
    if (document.cookie.indexOf('MobileLinkClientCookie=') == -1 && !this.processing) {
      let loginInterval = window.setInterval(() => {
        if (document.cookie.indexOf('MobileLinkClientCookie=') != -1) {
          clearInterval(loginInterval)
          this.$store.dispatch('getUser')

          this.$store.dispatch('getNewAnnouncements')
          this.$store.dispatch('getCountrySubdivisions')
          if (!this.hasUnreadMessage) this.$store.dispatch('getHasUnreadMessages')
          this.$store.dispatch('getApplicationConfiguration', 'Any')
        }
      }, 3000)
    }
  }
}
</script>

<style scoped>
h2 {
  margin: 0.5em 0 1em 0;
  font-weight: 500;
  font-size: 1.25em;
}
.btn-link {
  outline: none !important;
  box-shadow: none !important;
  text-decoration: underline;
  font-size: 1em;
  padding: 0.75em 0;
}
.btn-link:hover {
  color: var(--blue-900);
  background-color: transparent !important;
  text-decoration: underline;
}
.errorIcon {
  color: var(--red-500);
}
.login-cards {
  z-index: 100;
}
@media only screen and (max-width: 600px) {
  .login-cards {
    width: 100%;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .login-cards {
    width: 414px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .login-cards {
    width: 414px;
  }
}

.card-body {
  padding: 3rem 2rem;
}

#homeDiv {
  height: 100vh;
  color: var(--carbon-900);
  background: var(--blue-900);
  margin-left: -15px;
  margin-right: -15px;
}

#backgroundImage {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 75%;
  z-index: 1;
}
</style>
