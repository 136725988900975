import Axios from 'axios'
import _ from 'lodash'
import { vueAppApiBaseAddress } from '@/utils/mixins'

var initialState = {}

export const state = _.cloneDeep(initialState)

export const getters = {}

export const mutations = {}

export const actions = {
  async getTankUtilityDevices({ commit }, identity) {
    return await Axios.get(vueAppApiBaseAddress() + `v1/TankUtility/devices`, {
      withCredentials: true,
      headers: {
        identity: identity
      }
    })
  },
  async enrollTankUtility({ commit }, tankEnrollmentData) {
    return await Axios.put(vueAppApiBaseAddress() + `v1/TankUtility/enroll`, tankEnrollmentData, {
      withCredentials: true
    })
  },
  async refreshTankUtility({ commit }, value) {
    return await Axios.put(vueAppApiBaseAddress() + `v1/TankUtility/refresh`, value.request, {
      withCredentials: true,
      headers: {
        identity: value.identity
      }
    })
  },
  async getTankReading({ commit }, apparatusId) {
    return await Axios.get(vueAppApiBaseAddress() + `v2/TankUtility/getTankReading/apparatusId/${apparatusId}`, {
      withCredentials: true
    })
  },
  async validateTankDeviceId({ commit }, deviceId) {
    return await Axios.get(vueAppApiBaseAddress() + `v2/TankUtility/validate/deviceId/${deviceId}`, {
      withCredentials: true
    })
  },
  async enrollTankUtilityApparatus({ commit }, request) {
    return await Axios.post(vueAppApiBaseAddress() + `v2/TankUtility/enroll`, request, {
      withCredentials: true
    })
  },
  async getTankUtilityTankSizes({ commit }, request) {
    return await Axios.get(
      vueAppApiBaseAddress() +
        `v2/TankUtility/getTankSizes/fuelType/${request.fuelType}/orientation/${request.orientation}`,
      {
        withCredentials: true
      }
    )
  },
  async typeAheadFuelProviderSearch({ getters, commit, dispatch }, request) {
    return await Axios.get(
      vueAppApiBaseAddress() +
        `v2/TankUtility/fuel/provider/${request.name}?pageNumber=${request.pageNumber}&pageSize=${request.pageSize}`,
      {
        withCredentials: true
      }
    )
  },
  async updateTankUtilityApparatus({ commit }, request) {
    return await Axios.put(vueAppApiBaseAddress() + `v2/TankUtility/update/${request.apparatusId}`, request, {
      withCredentials: true
    })
  },
  async getTankUtilityConsumptionTypes({ commit }) {
    return await Axios.get(vueAppApiBaseAddress() + `v2/TankUtility/consumptionTypes`, {
      withCredentials: true
    })
  },
  async getAllCapacities() {
    return await Axios.get(vueAppApiBaseAddress() + `v2/TankUtility/getAllCapacities`, {
      withCredentials: true
    })
  },
  async getFuelProvider({ commit }, id) {
    return await Axios.get(vueAppApiBaseAddress() + `v2/TankUtility/fuel/dealer/${encodeURIComponent(id)}`, {
      withCredentials: true
    })
  },
  async removeFuelProvider({ commit }, apparatusId) {
    return await Axios.delete(vueAppApiBaseAddress() + `v2/TankUtility/fuel/provider/${apparatusId}`, {
      withCredentials: true
    })
  },
  async getTankSnmp({ commit }, request) {
    return await Axios.get(
      vueAppApiBaseAddress() + `v2/TankUtility/getSnmpValue/${request.id}/snmp/${request.snmpName}`,
      {
        withCredentials: true
      }
    )
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
