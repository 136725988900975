<template>
  <div>
    <b-modal
      id="tank-enrollment-step-1"
      size="md"
      no-fade
      no-stacking
      centered
      no-close-on-esc
      no-close-on-backdrop
      @shown="activeModal = 'verify-tank-id'"
    >
      <template #modal-header>
        <b-row class="w-100" align-v="center" no-gutters>
          <b-col>
            <h2 class="modal-title" data-test-hook="tank-enrollment-step-1-header">
              <i class="colorOrange fas fa-plus mr-1" data-test-hook="tank-enrollment-step-1-header-icon"></i>
              {{ $t('xAddATank') }}
            </h2>
          </b-col>
          <b-col cols="auto">
            <b-button
              class="btn-icon-button"
              variant="icon-button--gray"
              @click="areYouSure('tank-enrollment-step-1')"
              data-test-hook="tank-enrollment-step-1-close-button"
            >
              <i class="fas fa-times" />
            </b-button>
          </b-col>
        </b-row>
      </template>
      <template #modal-footer>
        <b-button
          variant="primary"
          @click="validateDeviceId(), helpStep2++"
          :disabled="!validState($v.userEnteredTankDeviceId) || validatingId"
          data-test-hook="tank-enrollment-step-1-next-button"
        >
          {{ $t('xNext') }}
          <b-spinner class="ml-2" v-if="validatingId"></b-spinner>
        </b-button>
      </template>
      <b-row v-if="helpStep1 > 1">
        <b-col>
          <b-alert class="tu-help-alert" show>
            <b-row>
              <b-col>
                <h3>
                  <i class="fas fa-phone-alt" />
                  {{ $t('xNeedHelpContactSupport') }}
                </h3>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                {{ $t('xUsAndCanadaPhoneNumbers') }}
              </b-col>
            </b-row>
          </b-alert>
        </b-col>
      </b-row>
      <b-row align-v="center" class="mb-0">
        <b-col>
          <h5 data-test-hook="tank-enrollment-step-1-enter-tank-id-text">
            {{ $t('xEnterTankDeviceId') }}
          </h5>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group id="deviceId-group">
            <b-form-input
              id="deviceId"
              v-model="$v.userEnteredTankDeviceId.$model"
              autocomplete="new-password"
              :state="validState($v.userEnteredTankDeviceId) && validFuelMonitorId($v.userEnteredTankDeviceId.$model)"
              aria-describedby="deviceId-invalid"
              v-on:keyup.enter="validateDeviceId(), helpStep1++, helpStep2++"
              data-test-hook="tank-enrollment-step-1-tank-id-field"
            />
            <b-form-invalid-feedback id="deviceId-invalid">
              <span data-test-hook="tank-enrollment-step-1-invalid-tank-id">{{ $t('xYourTankDeviceIDMust') }}</span>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
      <div
        v-if="showValidateDeviceIdError"
        role="alert"
        class="alert alert--validation alert-danger"
        :show="showValidateDeviceIdError"
      >
        {{ validateDeviceIdErrorMessage }}
      </div>
      <b-row class="mt-5" align-v="center">
        <b-col class="mr-3 pr-0">
          <b-progress
            :value="11.11"
            variant="primary"
            data-test-hook="tank-enrollment-step-1-progress-bar"
          ></b-progress>
        </b-col>
      </b-row>
    </b-modal>

    <b-modal
      id="tank-enrollment-step-2"
      size="md"
      no-fade
      no-stacking
      centered
      no-close-on-esc
      no-close-on-backdrop
      @shown="activeModal = 'enter-name-and-address'"
    >
      <template #modal-header>
        <b-row class="w-100" align-v="center" no-gutters>
          <b-col>
            <h2 class="modal-title" data-test-hook="tank-enrollment-step-2-header">
              <i class="colorOrange fas fa-plus mr-1" data-test-hook="tank-enrollment-step-2-header-icon"></i>
              {{ $t('xAddATank') }}
            </h2>
          </b-col>
          <b-col cols="auto">
            <b-button
              class="btn-icon-button"
              variant="icon-button--gray"
              @click="areYouSure('tank-enrollment-step-2')"
              data-test-hook="tank-enrollment-step-2-close-button"
            >
              <i class="fas fa-times" />
            </b-button>
          </b-col>
        </b-row>
      </template>
      <template #modal-footer>
        <b-button
          variant="outline-warning"
          @click="$bvModal.show('tank-enrollment-step-1'), (editingTankAddress = false), trackClick('VerifyTankName')"
          data-test-hook="tank-enrollment-step-2-previous-button"
        >
          {{ $t('xPrevious') }}
        </b-button>
        <b-button
          variant="primary"
          @click="createApparatusAndProceed(), (editingTankAddress = false), helpStep3++"
          :disabled="enrollingTank || !isValidAddress || !tankName"
          data-test-hook="tank-enrollment-step-2-next-button"
        >
          <span v-if="!enrollingTank">{{ $t('xNext') }}</span>
          <b-spinner v-else />
        </b-button>
      </template>
      <b-row v-if="helpStep2 > 2">
        <b-col>
          <b-alert class="tu-help-alert" show>
            <b-row>
              <b-col>
                <h3>
                  <i class="fas fa-phone-alt" />
                  {{ $t('xNeedHelpContactSupport') }}
                </h3>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                {{ $t('xUsAndCanadaPhoneNumbers') }}
              </b-col>
            </b-row>
          </b-alert>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <h5 data-test-hook="tank-enrollment-step-2-verify-tank-name-text">{{ $t('xVerifyTankNameAndAddress') }}</h5>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group>
            <b-form-input
              id="tankTame"
              :placeholder="$t('xTankName')"
              maxLength="50"
              v-model="tankName"
              data-test-hook="tank-enrollment-step-2-tank-name-field"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="mt-3" v-if="!editingTankAddress">
        <b-col>
          <p>
            <strong data-test-hook="tank-enrollment-step-2-tank-address-label">
              {{ 'xTankAddress' | formatLabel }}
            </strong>
          </p>
        </b-col>
      </b-row>
      <b-row v-if="!editingTankAddress">
        <b-col>
          <p data-test-hook="tank-enrollment-step-2-tank-address">{{ DisplayAddress(tankAddress) }}</p>
        </b-col>
        <b-col cols="auto">
          <b-button
            variant="icon-button"
            @click="editingTankAddress = !editingTankAddress"
            data-test-hook="tank-enrollment-step-2-edit-address-button"
          >
            <i class="fas fa-pencil" />
          </b-button>
        </b-col>
      </b-row>
      <b-row v-else>
        <b-col>
          <TypeAheadAddress
            ref="typeAheadAddressEditTankAddress"
            :currentAddress="tankAddress"
            @newAddress="setCurrentAddress"
          />
        </b-col>
      </b-row>
      <div
        v-if="showEnrollTankApparatusError"
        role="alert"
        class="alert alert--validation alert-danger"
        :show="showEnrollTankApparatusError"
      >
        {{ enrollTankApparatusErrorMessage }}
      </div>
      <b-row class="mt-5" align-v="center">
        <b-col class="mr-3 pr-0">
          <b-progress
            :value="22.22"
            variant="primary"
            data-test-hook="tank-enrollment-step-2-progress-bar"
          ></b-progress>
        </b-col>
      </b-row>
    </b-modal>

    <b-modal
      id="tank-enrollment-step-3"
      size="md"
      no-fade
      no-stacking
      centered
      no-close-on-esc
      no-close-on-backdrop
      @shown="activeModal = 'enter-fueltype-and-orientation'"
    >
      <template #modal-header>
        <b-row class="w-100" align-v="center" no-gutters>
          <b-col>
            <h2 class="modal-title" data-test-hook="tank-enrollment-step-3-header">
              <i class="colorOrange fas fa-plus mr-1" data-test-hook="tank-enrollment-step-3-header-icon"></i>
              {{ $t('xAddATank') }}
            </h2>
          </b-col>
          <b-col cols="auto">
            <b-button
              class="btn-icon-button"
              variant="icon-button--gray"
              @click="areYouSure('tank-enrollment-step-3')"
              data-test-hook="tank-enrollment-step-3-close-button"
            >
              <i class="fas fa-times" />
            </b-button>
          </b-col>
        </b-row>
      </template>
      <template #modal-footer>
        <b-button
          variant="primary"
          @click="loadTankCapacities(), helpStep4++"
          :disabled="userSelectedFuelType == null || userSelectedTankOrien == null"
          data-test-hook="tank-enrollment-step-3-next-button"
        >
          {{ $t('xNext') }}
          <b-spinner v-if="loadingTankCapacities" style="width: 1.5rem; height: 1.5rem" />
        </b-button>
      </template>
      <b-row v-if="helpStep3 > 2">
        <b-col>
          <b-alert class="tu-help-alert" show>
            <b-row>
              <b-col>
                <h3>
                  <i class="fas fa-phone-alt" />
                  {{ $t('xNeedHelpContactSupport') }}
                </h3>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                {{ $t('xUsAndCanadaPhoneNumbers') }}
              </b-col>
            </b-row>
          </b-alert>
        </b-col>
      </b-row>
      <b-row align-v="center" class="mb-0">
        <b-col>
          <h5 data-test-hook="tank-enrollment-step-3-enter-tank-details-text">
            {{ $t('xEnterYourTankDetails') }}
          </h5>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <p data-test-hook="tank-enrollment-step-3-select-fuel-type-text">{{ $t('xSelectYourFuelType') }}</p>
        </b-col>
      </b-row>
      <b-row class="w-100 mb-3" align-v="center" no-gutters>
        <b-col>
          <b-button-group class="w-100">
            <b-button
              @click=";(userSelectedFuelType = PROPANE), (userSelectedTankOrien = null)"
              :class="{ active: userSelectedFuelType == PROPANE }"
              variant="tank-outline"
              data-test-hook="tank-enrollment-step-3-fuel-type-propane-button"
            >
              {{ $t('xPropane') }}
            </b-button>
            <b-button
              @click=";(userSelectedFuelType = HEATINGOIL), (userSelectedTankOrien = HORIZONTAL)"
              :class="{ active: userSelectedFuelType == HEATINGOIL }"
              variant="tank-outline"
              data-test-hook="tank-enrollment-step-3-fuel-type-oil-button"
            >
              {{ $t('xOil') }}
            </b-button>
          </b-button-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <h6 data-test-hook="tank-enrollment-step-3-tank-orientation-text">{{ $t('xSelectTankOrien') }}</h6>
        </b-col>
      </b-row>
      <b-row class="w-100 mb-3" align-v="center" no-gutters>
        <b-col>
          <b-button
            style="height: 80px;"
            block
            @click="userSelectedTankOrien = VERTICAL"
            class="py-0 mx-0"
            variant="tank-outline"
            :disabled="userSelectedFuelType == HEATINGOIL"
            :pressed="userSelectedTankOrien == VERTICAL"
            data-test-hook="tank-enrollment-step-3-tank-orientation-vertical-button"
          >
            <b-row align-v="center">
              <b-col>
                <b-img
                  v-if="userSelectedTankOrien == VERTICAL"
                  height="50px"
                  :src="require('@/assets/images/tank-vertical-selected.svg')"
                  data-test-hook="tank-enrollment-step-3-tank-orientation-vertical-image"
                />
                <b-img
                  v-if="
                    userSelectedTankOrien != VERTICAL &&
                      (userSelectedFuelType == PROPANE || userSelectedFuelType == null)
                  "
                  height="50px"
                  :src="require('@/assets/images/tank-vertical-unselected.svg')"
                  data-test-hook="tank-enrollment-step-3-tank-orientation-vertical-image"
                />
                <b-img
                  v-if="userSelectedFuelType == HEATINGOIL"
                  height="50px"
                  :src="require('@/assets/images/tank-vertical-disabled.svg')"
                  data-test-hook="tank-enrollment-step-3-tank-orientation-vertical-image"
                />
              </b-col>
              <b-col data-test-hook="tank-enrollment-step-3-tank-orientation-vertical-button-text">
                {{ $t('xVertical') }}
              </b-col>
              <b-col></b-col>
            </b-row>
          </b-button>
        </b-col>
      </b-row>
      <b-row class="w-100" align-v="center" no-gutters>
        <b-col>
          <b-button
            style="height: 80px;"
            id="tank-orien-horz"
            block
            variant="tank-outline"
            @click="userSelectedTankOrien = HORIZONTAL"
            class="py-0 mx-0"
            :pressed="userSelectedFuelType == HEATINGOIL || userSelectedTankOrien == HORIZONTAL"
            data-test-hook="tank-enrollment-step-3-tank-orientation-horizontal-button"
          >
            <b-row align-v="center">
              <b-col>
                <b-img
                  v-if="userSelectedTankOrien == HORIZONTAL"
                  height="50px"
                  :src="require('@/assets/images/tank-horizontal-selected.svg')"
                  data-test-hook="tank-enrollment-step-3-tank-orientation-horizontal-image"
                />
                <b-img
                  v-else
                  height="50px"
                  :src="require('@/assets/images/tank-horizontal-unselected.svg')"
                  data-test-hook="tank-enrollment-step-3-tank-orientation-horizontal-image"
                />
              </b-col>
              <b-col data-test-hook="tank-enrollment-step-3-tank-orientation-horizontal-button-text">
                {{ $t('xHorizontal') }}
              </b-col>
              <b-col></b-col>
            </b-row>
          </b-button>
        </b-col>
      </b-row>
      <b-row class="mt-5" align-v="center">
        <b-col class="mr-3 pr-0">
          <b-progress
            :value="33.33"
            variant="primary"
            data-test-hook="tank-enrollment-step-3-progress-bar"
          ></b-progress>
        </b-col>
      </b-row>
    </b-modal>

    <b-modal
      id="tank-enrollment-step-4"
      size="md"
      no-fade
      no-stacking
      centered
      no-close-on-esc
      no-close-on-backdrop
      @shown="activeModal = 'enter-capacity'"
    >
      <template #modal-header>
        <b-row class="w-100" align-v="center" no-gutters>
          <b-col>
            <h2 class="modal-title" data-test-hook="tank-enrollment-step-4-header">
              <i class="colorOrange fas fa-plus mr-1" data-test-hook="tank-enrollment-step-4-header-icon"></i>
              {{ $t('xAddATank') }}
            </h2>
          </b-col>
          <b-col cols="auto">
            <b-button
              class="btn-icon-button"
              variant="icon-button--gray"
              @click="areYouSure('tank-enrollment-step-4')"
              data-test-hook="tank-enrollment-step-4-close-button"
            >
              <i class="fas fa-times" />
            </b-button>
          </b-col>
        </b-row>
      </template>
      <template #modal-footer>
        <b-button
          variant="outline-warning"
          @click="$bvModal.show('tank-enrollment-step-3'), trackClick('SelectTankCapacity'), helpStep3++"
          data-test-hook="tank-enrollment-step-4-previous-button"
        >
          {{ $t('xPrevious') }}
        </b-button>
        <b-button
          variant="primary"
          @click="wifiSetupNavigation()"
          :disabled="userSelectedTankCapacity == null"
          data-test-hook="tank-enrollment-step-4-next-button"
        >
          {{ $t('xNext') }}
        </b-button>
      </template>
      <b-row v-if="helpStep4 > 2">
        <b-col>
          <b-alert class="tu-help-alert" show>
            <b-row>
              <b-col>
                <h3>
                  <i class="fas fa-phone-alt" />
                  {{ $t('xNeedHelpContactSupport') }}
                </h3>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                {{ $t('xUsAndCanadaPhoneNumbers') }}
              </b-col>
            </b-row>
          </b-alert>
        </b-col>
      </b-row>
      <b-row align-v="center" class="mb-0">
        <b-col>
          <h5 data-test-hook="tank-enrollment-step-4-select-tank-capacity-text">
            {{ $t('xSelectYourTankCapacity') }}
          </h5>
        </b-col>
      </b-row>
      <div class="row">
        <div class="col">
          <fieldset id="tank-capacity-group" class="form-group">
            <select
              class="custom-select"
              id="tank-capacity"
              v-model="$v.userSelectedTankCapacity.$model"
              :placeholder="$t('xSelectCapacity')"
              :state="validState($v.userSelectedTankCapacity)"
              aria-describedby="capacity-invalid"
              data-test-hook="tank-enrollment-step-4-tank-capacity-field"
            >
              <option v-if="!userSelectedTankCapacity" :value="null" disabled></option>
              <option
                v-for="(capacity, index) in tankCapacities"
                :key="index"
                :value="capacity.value"
                :data-test-hook="'tankCapacity' + userSelectedFuelType + userSelectedTankOrien + capacity.value"
              >
                {{ capacity.text }}
              </option>
            </select>
            <b-form-invalid-feedback id="capacity-invalid"> {{ $t('xRequiredField') }}. </b-form-invalid-feedback>
          </fieldset>
        </div>
      </div>
      <b-row class="w-100 mb-3" align-v="center" no-gutters>
        <b-col> </b-col>
      </b-row>
      <b-row class="mt-5" align-v="center">
        <b-col class="mr-3 pr-0">
          <b-progress
            :value="44.44"
            variant="primary"
            data-test-hook="tank-enrollment-step-4-progress-bar"
          ></b-progress>
        </b-col>
      </b-row>
    </b-modal>

    <b-modal
      id="tank-enrollment-step-5"
      size="md"
      no-fade
      no-stacking
      centered
      no-close-on-esc
      no-close-on-backdrop
      @shown="activeModal = 'confirm-tank-install'"
    >
      <template #modal-header>
        <b-row class="w-100" align-v="center" no-gutters>
          <b-col>
            <h2 class="modal-title" data-test-hook="tank-enrollment-step-5-header">
              <i class="colorOrange fas fa-plus mr-1" data-test-hook="tank-enrollment-step-5-header-icon"></i>
              {{ $t('xAddATank') }}
            </h2>
          </b-col>
          <b-col cols="auto">
            <b-button
              class="btn-icon-button"
              variant="icon-button--gray"
              @click="areYouSure('tank-enrollment-step-5')"
              data-test-hook="tank-enrollment-step-5-close-button"
            >
              <i class="fas fa-times" />
            </b-button>
          </b-col>
        </b-row>
      </template>
      <template #modal-footer>
        <b-button
          variant="outline-warning"
          @click="$bvModal.show('tank-enrollment-step-4'), trackClick('ConfirmMonitorInstallation'), helpStep4++"
          data-test-hook="tank-enrollment-step-5-previous-button"
        >
          {{ $t('xPrevious') }}
        </b-button>
        <b-button
          variant="primary"
          @click="getTankReading(), $bvModal.show('tank-enrollment-step-6'), helpStep6++"
          data-test-hook="tank-enrollment-step-5-next-button"
        >
          {{ $t('xNext') }}
        </b-button>
      </template>
      <b-row v-if="helpStep5 > 2">
        <b-col>
          <b-alert class="tu-help-alert" show>
            <b-row>
              <b-col>
                <h3>
                  <i class="fas fa-phone-alt" />
                  {{ $t('xNeedHelpContactSupport') }}
                </h3>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                {{ $t('xUsAndCanadaPhoneNumbers') }}
              </b-col>
            </b-row>
          </b-alert>
        </b-col>
      </b-row>
      <b-row class="my-0">
        <b-col>
          <b-embed
            type="iframe"
            :src="tankMonitorInstallVideoUrl()"
            allowfullscreen
            data-test-hook="tank-enrollment-step-5-monitor-install-video"
          ></b-embed>
        </b-col>
      </b-row>
      <b-row class="mb-0 mt-3">
        <b-col>
          <h5 data-test-hook="tank-enrollment-step-5-confirm-monitor-install-text">
            {{ $t('xConfirmTankMonitorInstallation') }}
          </h5>
          <ul class="mt-3 bullet-points">
            <li data-test-hook="tank-enrollment-step-5-sensor-attached-text">
              {{ $t('xEnsureTankMonitorSensorIsAttached') }}
            </li>
            <li data-test-hook="tank-enrollment-step-5-magnet-to-trigger-reading-text">
              {{ $t('xUseTheMagnetToTriggerAReading') }}
            </li>
            <li data-test-hook="tank-enrollment-step-5-you-will-know-text">
              {{ $t('xYouWillKnowAReading') }}
            </li>
            <li data-test-hook="tank-enrollment-step-5-tap-next-to-continue-text">
              {{ $t('xTapNextBelowToContinue') }}
            </li>
          </ul>
        </b-col>
      </b-row>
      <div v-if="showTankReadingError" :show="showTankReadingError" class="alert alert-danger alert--validation">
        {{ tankReadingErrorMessage }}
      </div>
      <b-row align-v="center">
        <b-col class="mr-3 pr-0">
          <b-progress
            :value="55.55"
            variant="primary"
            data-test-hook="tank-enrollment-step-5-progress-bar"
          ></b-progress>
        </b-col>
      </b-row>
    </b-modal>

    <b-modal
      id="tank-enrollment-step-6"
      size="md"
      hide-footer
      no-fade
      no-stacking
      centered
      no-close-on-esc
      no-close-on-backdrop
      @shown="activeModal = 'wait-for-tank-reading'"
    >
      <template #modal-header>
        <b-row class="w-100" align-v="center" no-gutters>
          <b-col>
            <h2 class="modal-title" data-test-hook="tank-enrollment-step-6-header">
              <i class="colorOrange fas fa-plus mr-1" data-test-hook="tank-enrollment-step-6-header-icon"></i>
              {{ $t('xAddATank') }}
            </h2>
          </b-col>
          <b-col cols="auto">
            <b-button
              class="btn-icon-button"
              variant="icon-button--gray"
              @click="areYouSure('tank-enrollment-step-6')"
              data-test-hook="tank-enrollment-step-6-close-button"
            >
              <i class="fas fa-times" />
            </b-button>
          </b-col>
        </b-row>
      </template>
      <b-row v-if="helpStep6 > 2">
        <b-col>
          <b-alert class="tu-help-alert" show>
            <b-row>
              <b-col>
                <h3>
                  <i class="fas fa-phone-alt" />
                  {{ $t('xNeedHelpContactSupport') }}
                </h3>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                {{ $t('xUsAndCanadaPhoneNumbers') }}
              </b-col>
            </b-row>
          </b-alert>
        </b-col>
      </b-row>
      <b-row align-v="center">
        <b-col>
          <h5 class="text-center" data-test-hook="tank-enrollment-step-6-wait-for-reading-text">
            {{ $t('xPleaseWaitForYourTankMonitorToReceiveAReading') }}
          </h5>
        </b-col>
      </b-row>
      <b-row no-gutters>
        <b-col>
          <lottie
            :options="waitingForTankReadingAnimationOptions"
            v-on:animCreated="handleAnimation"
            :height="200"
            :width="200"
            data-test-hook="tank-enrollment-step-6-wait-for-reading-animation"
          />
        </b-col>
      </b-row>
      <b-row
        align-h="center"
        class="shouldnt-take-long"
        data-test-hook="tank-enrollment-step-6-shouldnt-take-long-text"
      >
        {{ $t('xThisShouldntTakeLong') }}
      </b-row>
    </b-modal>

    <b-modal
      id="tank-enrollment-step-7"
      size="md"
      no-fade
      no-stacking
      centered
      no-close-on-esc
      no-close-on-backdrop
      @shown="activeModal = 'tank-reading-received'"
    >
      <template #modal-header>
        <b-row class="w-100" align-v="center" no-gutters>
          <b-col>
            <h2 class="modal-title" data-test-hook="tank-enrollment-step-7-header">
              <i class="colorOrange fas fa-plus mr-1" data-test-hook="tank-enrollment-step-7-header-icon"></i>
              {{ $t('xAddATank') }}
            </h2>
          </b-col>
          <b-col cols="auto">
            <b-button
              class="btn-icon-button"
              variant="icon-button--gray"
              @click="areYouSure('tank-enrollment-step-7')"
              data-test-hook="tank-enrollment-step-7-close-button"
            >
              <i class="fas fa-times" />
            </b-button>
          </b-col>
        </b-row>
      </template>
      <template #modal-footer>
        <b-button
          variant="outline-warning"
          @click="$bvModal.show('tank-enrollment-step-8'), helpStep8++"
          data-test-hook="tank-enrollment-step-7-verify-reading-button"
        >
          {{ $t('xVerifyTankReading') }}
        </b-button>
        <b-button
          variant="primary"
          @click="$bvModal.show('tank-enrollment-step-9'), helpStep9++"
          data-test-hook="tank-enrollment-step-7-looks-good-button"
        >
          {{ $t('xLooksGood') }}
        </b-button>
      </template>
      <b-row v-if="helpStep7 > 2">
        <b-col>
          <b-alert class="tu-help-alert" show>
            <b-row>
              <b-col>
                <h3>
                  <i class="fas fa-phone-alt" />
                  {{ $t('xNeedHelpContactSupport') }}
                </h3>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                {{ $t('xUsAndCanadaPhoneNumbers') }}
              </b-col>
            </b-row>
          </b-alert>
        </b-col>
      </b-row>
      <b-row align-v="center">
        <b-col>
          <h1 class="text-center" data-test-hook="tank-enrollment-step-7-reading-received-text">
            {{ $t('xReadingReceived') }}
          </h1>
        </b-col>
      </b-row>
      <b-row align-h="center" class="my-2">
        <b-col
          cols="auto"
          style="font-size: 6rem;padding-right:5px"
          class="colorGray"
          data-test-hook="tank-enrollment-step-7-tank-level"
        >
          {{ tankReadingLevel }}
        </b-col>
        <b-col cols="auto" style="font-size: 3rem;padding-top: 25px;margin-top:0px" class="pl-0 colorGray">%</b-col>
      </b-row>
      <b-row align-h="center" class="shouldnt-take-long">
        {{ $t('xCurrentTankReading') }}
      </b-row>
      <b-row align-h="center" class="mt-3 mb-2 mx-3 verify-reading">
        <p>{{ $t('xIfThisReadingDoesntLookRight') }}</p>
      </b-row>
      <b-row align-v="center">
        <b-col class="mr-3 pr-0">
          <b-progress
            :value="66.66"
            variant="primary"
            data-test-hook="tank-enrollment-step-7-progress-bar"
          ></b-progress>
        </b-col>
      </b-row>
    </b-modal>

    <b-modal
      id="tank-enrollment-step-8"
      size="md"
      no-fade
      no-stacking
      centered
      no-close-on-esc
      no-close-on-backdrop
      @shown="activeModal = 'user-verify-tank-reading'"
    >
      <template #modal-header>
        <b-row class="w-100" align-v="center" no-gutters>
          <b-col>
            <h2 class="modal-title" data-test-hook="tank-enrollment-step-8-header">
              <i class="colorOrange fas fa-plus mr-1" data-test-hook="tank-enrollment-step-8-header-icon"></i>
              {{ $t('xAddATank') }}
            </h2>
          </b-col>
          <b-col cols="auto">
            <b-button
              class="btn-icon-button"
              variant="icon-button--gray"
              @click="areYouSure('tank-enrollment-step-8')"
              data-test-hook="tank-enrollment-step-8-close-button"
            >
              <i class="fas fa-times" />
            </b-button>
          </b-col>
        </b-row>
      </template>
      <template #modal-footer v-if="verifyTankReadingSuccess == false">
        <b-button
          variant="outline-warning"
          @click="$bvModal.show('tank-enrollment-step-9'), helpStep9++"
          data-test-hook="tank-enrollment-step-8-try-later-button"
        >
          {{ $t('xTryLater') }}
        </b-button>
        <b-button
          variant="primary"
          @click="tryEnterTankLevelAgain"
          :disabled="!tankLevelValidationDone"
          data-test-hook="tank-enrollment-step-8-try-again-button"
        >
          <span>{{ $t('xTryAgain') }}</span>
        </b-button>
      </template>
      <template #modal-footer v-else>
        <b-button
          variant="outline-warning"
          @click="$bvModal.show('tank-enrollment-step-7'), trackClick('Verify-Tank-Level-Reading'), helpStep7++"
          data-test-hook="tank-enrollment-step-8-previous-button"
        >
          {{ $t('xPrevious') }}
        </b-button>
        <b-button
          variant="primary"
          @click="$bvModal.show('tank-enrollment-step-9'), helpStep9++"
          :disabled="!tankLevelValidationDone"
          data-test-hook="tank-enrollment-step-8-next-button"
        >
          <span>{{ $t('xNext') }}</span>
        </b-button>
      </template>
      <b-row v-if="helpStep8 > 2">
        <b-col>
          <b-alert class="tu-help-alert" show>
            <b-row>
              <b-col>
                <h3 data-test-hook="tank-enrollment-step-8-help-alert">
                  <i class="fas fa-phone-alt" />
                  {{ $t('xNeedHelpContactSupport') }}
                </h3>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                {{ $t('xUsAndCanadaPhoneNumbers') }}
              </b-col>
            </b-row>
          </b-alert>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div v-if="verifyTankReadingSuccess == null">
            <h5>
              <span data-test-hook="tank-enrollment-step-8-body-header">
                {{ $t('xEnterYourCurrentTankLevel') }}
              </span>
            </h5>
            <p data-test-hook="tank-enrollment-step-8-body-text">{{ $t('xWhileStandingNextToYourTank') }}</p>
          </div>
          <b-alert v-if="verifyTankReadingSuccess == true" show variant="success">
            <h5 data-test-hook="tank-enrollment-step-8-success-header">
              <i class="colorGreen fas fa-check-circle mr-1"></i>{{ $t('xSuccess') }}
            </h5>
            <b-row class="ml-0">
              <span data-test-hook="tank-enrollment-step-8-success-body">
                {{ $t('xYourTankMonitorIsProperlyInstalled') }}
              </span>
            </b-row>
          </b-alert>
        </b-col>
      </b-row>
      <div v-if="verifyTankReadingSuccess == false">
        <b-alert show variant="danger">
          <h5 data-test-hook="tank-enrollment-step-8-error-header">
            <i class="colorRed fas fa-exclamation-triangle mr-1" />{{ $t('xError') }}
          </h5>
          <b-row class="ml-0">
            <span data-test-hook="tank-enrollment-step-8-error-body">
              {{ $t('xThereWasAnErrorVerifyingYourTankReading') }}
            </span>
          </b-row>
        </b-alert>
        <b-row class="ml-1 mt-2">
          <b data-test-hook="tank-enrollment-step-8-best-results-header">{{ $t('xForBestResults') }}</b>
        </b-row>
        <ul class="bullet-points mr-1">
          <li data-test-hook="tank-enrollment-step-8-best-results-help1">
            {{ $t('xEnsureTankMonitorSensorIsAttached') }}
          </li>
          <li data-test-hook="tank-enrollment-step-8-best-results-help2">{{ $t('xUseTheMagnetToTriggerAReading') }}</li>
        </ul>
      </div>
      <b-row class="mt-4 bold-text">
        <b-col class="text-center">
          {{ $t('xTankDialLevel') }}
        </b-col>
        <b-col class="text-center">
          {{ $t('xTankMonitorReading') }}
        </b-col>
      </b-row>
      <b-row class="mt-2 mb-2">
        <b-col cols="6" class="d-flex align-items-center">
          <b-input-group id="tankDialLevel-group" append="%">
            <b-form-input
              id="tankDialLevel"
              v-model="$v.userEnteredTankDialLevel.$model"
              type="text"
              size="lg"
              autofocus
              :state="validState($v.userEnteredTankDialLevel)"
              aria-describedby="tankDialLevel-invalid"
              v-mask="'##'"
              @keydown="verifyTankReadingSuccess = null"
              data-test-hook="tank-enrollment-step-8-dial-level-field"
            />
            <b-form-invalid-feedback id="tankDialLevel-invalid">
              {{ $t('xEnterValueDisplayedOnTankDial') }}
            </b-form-invalid-feedback>
          </b-input-group>
        </b-col>
        <b-col cols="6" class="text-center">
          <b-input-group id="tankReadingLevel-group" append="%">
            <b-form-input
              id="tankReadingLevel"
              type="text"
              size="lg"
              disabled
              autofocus
              :value="tankReadingLevel"
              data-test-hook="tank-enrollment-step-8-tank-reading-level-field"
            />
          </b-input-group>
        </b-col>
        <b-col cols="auto" class="mt-3 pl-0"></b-col>
      </b-row>
      <b-row class="mb-3" v-if="!verifyTankReadingSuccess">
        <b-col>
          <b-button
            block
            variant="primary"
            @click="verifyTankReading(), (tankLevelValidationDone = true)"
            :disabled="!userEnteredTankDialLevel || userEnteredTankDialLevel < 1 || userEnteredTankDialLevel > 100"
            data-test-hook="tank-enrollment-step-8-verify-button"
          >
            <span>{{ $t('xVerify') }}</span>
          </b-button>
        </b-col>
      </b-row>
      <b-row align-v="center">
        <b-col class="mr-3 pr-0">
          <b-progress
            :value="77.77"
            variant="primary"
            data-test-hook="tank-enrollment-step-8-progress-bar"
          ></b-progress>
        </b-col>
      </b-row>
    </b-modal>

    <b-modal
      id="tank-enrollment-step-9"
      size="md"
      no-fade
      no-stacking
      centered
      no-close-on-esc
      no-close-on-backdrop
      @shown="activeModal = 'specify-tank-usage'"
    >
      <template #modal-header>
        <b-row class="w-100" align-v="center" no-gutters>
          <b-col>
            <h2 class="modal-title" data-test-hook="tank-enrollment-step-9-header">
              <i class="colorOrange fas fa-plus mr-1" data-test-hook="tank-enrollment-step-9-header-icon"></i>
              {{ $t('xAddATank') }}
            </h2>
          </b-col>
          <b-col cols="auto">
            <b-button
              class="btn-icon-button"
              variant="icon-button--gray"
              @click="areYouSure('tank-enrollment-step-9')"
              data-test-hook="tank-enrollment-step-9-close-button"
            >
              <i class="fas fa-times" />
            </b-button>
          </b-col>
        </b-row>
      </template>
      <template #modal-footer>
        <b-button
          v-if="tankDeviceType && tankDeviceType != wifiTankDeviceType"
          variant="outline-warning"
          @click="
            ;(userEnteredTankDeviceId = null),
              (verifyTankReadingSuccess = null),
              $bvModal.show('tank-enrollment-step-7'),
              helpStep7++
          "
          data-test-hook="tank-enrollment-step-9-previous-button"
        >
          {{ $t('xPrevious') }}
        </b-button>
        <b-button
          variant="primary"
          @click="checkFuelUseSelected(), helpStep10++"
          data-test-hook="tank-enrollment-step-9-next-button"
        >
          <span>{{ $t('xNext') }}</span>
        </b-button>
      </template>
      <b-row v-if="helpStep9 > 2">
        <b-col>
          <b-alert class="tu-help-alert" show>
            <b-row>
              <b-col>
                <h3>
                  <i class="fas fa-phone-alt" />
                  {{ $t('xNeedHelpContactSupport') }}
                </h3>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                {{ $t('xUsAndCanadaPhoneNumbers') }}
              </b-col>
            </b-row>
          </b-alert>
        </b-col>
      </b-row>
      <b-row class="mb-3">
        <b-col cols="auto" class="pr-0 pb-0">
          <p v-html="$t('xWhichDevicesConsumeFuel')" />
        </b-col>
      </b-row>
      <b-row class="mb-2" align-v="center" v-for="(type, index) in selectedTankUtilFuelUses" :key="index">
        <b-col align-v="center" :data-test-hook="'fuelUse-' + removeExtraChars($t(type.name)) + '-Header'">
          {{ $t(type.name) }}
        </b-col>
        <b-col cols="auto">
          <b-button
            class="mr-2"
            pill
            variant="outline-primary"
            @click="addTag(index, true)"
            :pressed="type.selected != null && type.selected"
            :data-test-hook="'fuelUse-' + removeExtraChars($t(type.name)) + '-YesBtn'"
          >
            {{ $t('xYes') }}
          </b-button>
          <b-button
            pill
            variant="outline-primary"
            @click="addTag(index, false)"
            :pressed="type.selected != null && !type.selected"
            :data-test-hook="'fuelUse-' + removeExtraChars($t(type.name)) + '-NoBtn'"
          >
            {{ $t('xNo') }}
          </b-button>
        </b-col>
      </b-row>
      <b-row v-if="showTankUsageUnfinishedErrorMessage" class="mt-2 mb-2">
        <b-col>
          <p data-test-hook="tank-enrollment-step-9-unfinished-message" class="colorRed">
            {{ $t('xTankUsageUnfinishedErrorMessage') }}
          </p>
        </b-col>
      </b-row>
      <b-row align-v="center">
        <b-col class="mr-3 pr-0">
          <b-progress
            :value="88.88"
            variant="primary"
            data-test-hook="tank-enrollment-step-9-progress-bar"
          ></b-progress>
        </b-col>
      </b-row>
    </b-modal>

    <b-modal
      id="tank-enrollment-step-10"
      size="md"
      no-fade
      no-stacking
      centered
      no-close-on-esc
      no-close-on-backdrop
      @shown="activeModal = 'specify-fuel-provider'"
    >
      <template #modal-header>
        <b-row class="w-100" align-v="center" no-gutters>
          <b-col>
            <h2 class="modal-title" data-test-hook="tank-enrollment-step-10-header">
              <i class="colorOrange fas fa-plus mr-1" data-test-hook="tank-enrollment-step-10-header-icon"></i>
              {{ $t('xAddATank') }}
            </h2>
          </b-col>
          <b-col cols="auto">
            <b-button
              class="btn-icon-button--gray"
              variant="icon-button"
              @click="areYouSure('tank-enrollment-step-10')"
              data-test-hook="tank-enrollment-step-10-close-button"
            >
              <i class="fas fa-times" />
            </b-button>
          </b-col>
        </b-row>
      </template>
      <template #modal-footer>
        <b-button
          variant="outline-warning"
          @click="$bvModal.show('tank-enrollment-step-9'), trackClick('tank-enrollment-step-10'), helpStep9++"
          data-test-hook="tank-enrollment-step-10-previous-button"
        >
          {{ $t('xPrevious') }}
        </b-button>
        <b-button
          @click="finishSetup()"
          variant="primary"
          :disabled="finishingSetup"
          data-test-hook="tank-enrollment-step-10-finish-button"
        >
          <span v-if="finishingSetup"><b-spinner /></span>
          <span v-else>{{ $t('xFinishSetup') }}</span>
        </b-button>
      </template>
      <b-row v-if="helpStep10 > 2">
        <b-col>
          <b-alert class="tu-help-alert" show>
            <b-row>
              <b-col>
                <h3>
                  <i class="fas fa-phone-alt" />
                  {{ $t('xNeedHelpContactSupport') }}
                </h3>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                {{ $t('xUsAndCanadaPhoneNumbers') }}
              </b-col>
            </b-row>
          </b-alert>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="auto" class="pb-0 pr-0">
          <h5 class="mb-0 pb-0" data-test-hook="tank-enrollment-step-10-who-delivers-fuel-text">
            {{ $t('xWhoDeliversYourFuel') }}
          </h5>
        </b-col>
      </b-row>
      <b-form-group id="fuelprovider-group">
        <span v-click-outside="hideResults">
          <b-input-group>
            <b-form-input
              v-model="freeformFuelProvider"
              type="text"
              v-on:keyup="typeAheadSearch()"
              autocomplete="new-password"
              data-test-hook="tank-enrollment-step-10-fuel-dealer-search-field"
            >
            </b-form-input>
            <b-input-group-append>
              <b-button
                class="my-0 py-0"
                variant="warning"
                @click="!awaitingSearch ? typeAheadSearch() : null"
                data-test-hook="tank-enrollment-step-10-fuel-dealer-search-button"
              >
                <b-spinner v-if="awaitingSearch" style="width: 1.5rem; height: 1.5rem" />
                <i v-else class="fas fa-search" />
              </b-button>
            </b-input-group-append>
          </b-input-group>
          <b-row> </b-row>
          <b-row>
            <b-col>
              <b-list-group class="suggested-input-list" v-if="fuelProviderResults">
                <b-list-group-item
                  class="hoverPointer"
                  v-for="(provider, index) in fuelProviderResults"
                  :key="index"
                  @click="setFuelProvider(provider)"
                  :data-test-hook="'FuelProviderSuggestion-' + removeExtraChars(provider.name)"
                >
                  {{ provider.name }}
                </b-list-group-item>
              </b-list-group>
            </b-col>
          </b-row>
          <b-row class="mt-3" v-if="fuelProviderName">
            <b-col data-test-hook="tank-enrollment-step-10-selected-fuel-dealer">{{ fuelProviderName }}</b-col>
          </b-row>
        </span>
      </b-form-group>
      <div v-if="showFinishSetupError" :show="showFinishSetupError" class="alert alert-danger alert--validation">
        {{ finishSetupErrorMessage }}
      </div>
      <b-row align-v="center">
        <b-col class="mr-3 pr-0">
          <b-progress
            :value="88.88"
            variant="primary"
            data-test-hook="tank-enrollment-step-10-progress-bar"
          ></b-progress>
        </b-col>
      </b-row>
    </b-modal>

    <b-modal
      id="tank-enrollment-step-11"
      size="lg"
      no-fade
      no-stacking
      centered
      no-close-on-esc
      no-close-on-backdrop
      @shown="OnShowSubscriptionModal"
      @hidden="OnHideSubscriptionModal"
    >
      <template #modal-header>
        <b-row class="w-100" align-v="center" no-gutters>
          <b-col>
            <h2 class="modal-title" data-test-hook="tank-enrollment-step-11-header">
              <i class="colorOrange fas fa-plus mr-1" data-test-hook="tank-enrollment-step-11-header-icon"></i>
              {{ $t('xAddATank') }}
            </h2>
          </b-col>
        </b-row>
      </template>
      <template #modal-footer>
        <b-button
          v-if="!useExistingPaymentInfo || !existingPaymentInfo"
          variant="warning"
          @click="updatePaymentInfo()"
          :disabled="updatingPaymentInfo"
          block
          data-test-hook="tank-enrollment-step-11-save-payment-info-button"
        >
          <span v-if="updatingPaymentInfo"><b-spinner /></span>
          <span v-else>{{ $t('xSavePaymentInfo') }}</span>
        </b-button>
        <b-button
          v-else
          variant="warning"
          @click="subscribeProduct()"
          :disabled="subscribingProduct"
          block
          data-test-hook="tank-enrollment-step-11-confirm-subscription-button"
        >
          <span v-if="subscribingProduct"><b-spinner /></span>
          <span v-else>{{ $t('xConfirmSubscription') }}</span>
        </b-button>
      </template>
      <div v-if="loadingSubscriptionModal">
        <b-row class="text-center">
          <b-col>
            <b-spinner style="width: 10rem; height: 10rem;" variant="warning" label="Spinning"></b-spinner>
          </b-col>
        </b-row>
      </div>
      <div v-else>
        <b-alert :show="showValidationBanner" variant="danger">
          <p>{{ $t('xExperiencedTheseErrors') }}</p>
          <ul v-for="(message, index) in errorMessages" :key="index">
            <li :id="index">
              {{ message }}
            </li>
          </ul>
        </b-alert>
        <b-row>
          <b-col>
            <h4>{{ $t('xEnjoyAYearOfTank') }}</h4>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            {{ $t('xEnrolledInTankFreeYear') }}
          </b-col>
        </b-row>
        <b-row class="mt-4">
          <b-col>
            {{ $t('xTankSubWillAutoRenew') }}
            <strong data-test-hook="tank-enrollment-step-11-subscription-cost">
              {{ tankSubscriptionCost | formatCurrency }}
            </strong>
            {{ $t('xBeginning') }}
            <strong data-test-hook="tank-enrollment-step-11-auto-renew-date">
              {{ displayShortDate($moment().add(1, 'y')) }}.
            </strong>
          </b-col>
        </b-row>
        <b-row class="mt-4" v-if="existingPaymentInfo">
          <b-col>
            <b-form-checkbox
              class="font-weight-bold"
              v-model="useExistingPaymentInfo"
              data-test-hook="tank-enrollment-step-11-use-existing-payment-info-checkbox"
            >
              {{ $t('xUseExistingBillingInfo') }}
            </b-form-checkbox>
          </b-col>
        </b-row>
        <div v-if="!existingPaymentInfo || !useExistingPaymentInfo" class="mt-4">
          <b-row>
            <b-col>
              <h4 data-test-hook="tank-enrollment-step-11-enter-billing-info-text">{{ $t('xEnterBillingInfo') }}</h4>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <i class="asterisk-text">
                {{ $t('xUpdatingPaymentWarning') }}
              </i>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col>
              <b-form-group id="credit-card-number">
                <b-form-input
                  v-model="$v.creditCardNumber.$model"
                  type="text"
                  maxlength="16"
                  v-mask="'################'"
                  :placeholder="$t('xCreditCardNumber')"
                  :state="validState($v.creditCardNumber)"
                  aria-describedby="card-number-invalid"
                  data-test-hook="tank-enrollment-step-11-credit-card-number-field"
                ></b-form-input>
                <b-form-invalid-feedback id="card-number-invalid">
                  {{ $t('xCCFieldValidation') }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col lg="6" md="6" sm="12">
              <b-form-group id="expiry-date-month">
                <b-form-input
                  v-model="$v.expirationMonth.$model"
                  type="text"
                  :placeholder="$t('xMm')"
                  maxlength="2"
                  v-mask="'##'"
                  :state="validState($v.expirationMonth)"
                  aria-describedby="expiry-month-invalid"
                  data-test-hook="tank-enrollment-step-11-expiration-month-field"
                ></b-form-input>
                <b-form-invalid-feedback id="expiry-month-invalid">
                  {{ $t('xExpirationMonthValidation') }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col lg="6" md="6" sm="12">
              <b-form-group id="expiry-date-year">
                <b-form-input
                  v-model="$v.expirationYear.$model"
                  type="text"
                  :placeholder="$t('xYy')"
                  maxlength="2"
                  v-mask="'##'"
                  :state="validState($v.expirationYear)"
                  aria-describedby="expiry-year-invalid"
                  data-test-hook="tank-enrollment-step-11-expiration-year-field"
                ></b-form-input>
                <b-form-invalid-feedback id="expiry-year-invalid">
                  {{ $t('xExpirationYearValidation') }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col>
              <b-input-group id="cvv">
                <b-form-input
                  v-model="$v.cvv.$model"
                  type="text"
                  v-mask="'####'"
                  maxlength="4"
                  :placeholder="$t('xCvv')"
                  :state="validState($v.cvv)"
                  aria-describedby="cvv-invalid"
                  data-test-hook="tank-enrollment-step-11-cvv-field"
                ></b-form-input>
                <b-input-group-append>
                  <b-input-group-text>
                    <b-img
                      :height="20"
                      :src="require('@/assets/images/3-digit-code.svg')"
                      data-test-hook="tank-enrollment-step-11-cvv-image"
                    />
                  </b-input-group-text>
                </b-input-group-append>
                <b-form-invalid-feedback id="cvv-invalid">
                  {{ $t('xCVVValidation') }}
                </b-form-invalid-feedback>
              </b-input-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="mt-3">
              <div class="subscription-cost">
                <h4 data-test-hook="tank-enrollment-step-11-subscription-cost-and-term">
                  {{ tankSubscriptionCost | formatCurrency }} {{ $t('xBilledYearly') }}
                </h4>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col> </b-col>
          </b-row>
        </div>
        <b-row class="mt-4">
          <b-col>
            <p>
              <i data-test-hook="tank-enrollment-step-11-auto-renew-message">
                {{ $t('xThisSubscriptionWillAutoRenew') }}
              </i>
            </p>
          </b-col>
        </b-row>
        <div
          v-if="showUpdatePaymentInfoError && updatePaymentInfoErrorMessage"
          :show="showUpdatePaymentInfoError"
          class="alert alert-danger alert--validation"
        >
          {{ updatePaymentInfoErrorMessage }}
        </div>
        <div
          v-if="showSubscribeProductError"
          :show="showSubscribeProductError"
          class="alert alert-danger alert--validation"
        >
          {{ subscribeProductErrorMessage }}
        </div>
      </div>
    </b-modal>

    <b-modal id="tank-enrollment-success" size="lg" no-fade no-stacking centered no-close-on-esc no-close-on-backdrop>
      <template #modal-header>
        <b-row class="w-100" align-v="center" no-gutters>
          <b-col>
            <h2 class="modal-title" data-test-hook="tank-enrollment-success-title">
              <i class="colorOrange fas fa-plus mr-1" data-test-hook="tank-enrollment-success-title-icon"></i>
              {{ $t('xAddATank') }}
            </h2>
          </b-col>
        </b-row>
      </template>
      <template #modal-footer>
        <b-button
          variant="warning"
          @click="closeEnrollmentModals()"
          data-test-hook="tank-enrollment-success-go-to-dashboard-button"
        >
          {{ $t('xGoToDashboard') }}
        </b-button>
      </template>
      <b-row class="text-center mt-2 mb-2">
        <b-col>
          <h1 data-test-hook="tank-enrollment-success-body-header">{{ $t('xSuccess') }}!</h1>
          <p data-test-hook="tank-enrollment-success-added-and-subscribed-text">{{ $t('xTankAddedAndSubscribed') }}</p>
        </b-col>
      </b-row>
      <b-row no-gutters>
        <b-col>
          <lottie
            :options="successAnimationOptions"
            v-on:animCreated="handleAnimation"
            :height="80"
            :width="80"
            data-test-hook="tank-enrollment-success-animation"
          />
        </b-col>
      </b-row>
    </b-modal>

    <b-modal
      id="tank-enrollment-tank-reading-not-received"
      size="md"
      no-fade
      no-stacking
      centered
      no-close-on-esc
      no-close-on-backdrop
    >
      <template #modal-header>
        <b-row class="w-100" align-v="center" no-gutters>
          <b-col>
            <h2 class="modal-title" data-test-hook="tank-enrollment-tank-reading-not-received-header">
              <i
                class="colorOrange fas fa-plus mr-1"
                data-test-hook="tank-enrollment-tank-reading-not-received-header-icon"
              ></i>
              {{ $t('xAddATank') }}
            </h2>
          </b-col>
          <b-col cols="auto">
            <b-button
              class="btn-icon-button--gray"
              variant="icon-button"
              @click="areYouSure('tank-enrollment-tank-reading-not-received')"
              data-test-hook="tank-enrollment-tank-reading-not-received-close-button"
            >
              <i class="fas fa-times" />
            </b-button>
          </b-col>
        </b-row>
      </template>
      <template #modal-footer>
        <b-row class="w-100">
          <b-col>
            <b-button
              block
              variant="warning"
              @click="getTankReading(), $bvModal.show('tank-enrollment-step-6'), helpStep6++"
              data-test-hook="tank-enrollment-tank-reading-not-received-try-again-button"
            >
              {{ $t('xTryAgain') }}
            </b-button>
          </b-col>
        </b-row>
        <b-row class="w-100">
          <b-col>
            <b-button
              block
              variant="outline-warning"
              @click="$bvModal.show('tank-enrollment-step-5'), helpStep5++"
              data-test-hook="tank-enrollment-tank-reading-not-received-confirm-install-button"
            >
              {{ $t('xConfirmInstallation') }}
            </b-button>
          </b-col>
        </b-row>
      </template>
      <b-row v-if="helpReadingNotReceived > 2">
        <b-col>
          <b-alert class="tu-help-alert" show>
            <b-row>
              <b-col>
                <h3>
                  <i class="fas fa-phone-alt" />
                  {{ $t('xNeedHelpContactSupport') }}
                </h3>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                {{ $t('xUsAndCanadaPhoneNumbers') }}
              </b-col>
            </b-row>
          </b-alert>
        </b-col>
      </b-row>
      <b-row align-v="center">
        <b-col>
          <h5 class="text-center" data-test-hook="tank-enrollment-tank-reading-not-received-no-reading-text">
            {{ $t('xTankReadingNotReceived') }}
          </h5>
        </b-col>
      </b-row>
      <b-row align-h="center" class="mt-2 mb-2">
        <b-col cols="auto">
          <i class="colorOrange far fa-sad-tear fa-2x"></i>
        </b-col>
      </b-row>
      <b-row align-h="center" class="mt-4 ml-1 mr-1 check-cellular">
        {{ $t('xPleaseCheckYourCellularConnection') }}
      </b-row>
    </b-modal>

    <b-modal id="wifi-tank-setup" size="md" no-fade no-stacking centered no-close-on-esc no-close-on-backdrop>
      <template #modal-header>
        <b-row class="w-100" align-v="center" no-gutters>
          <b-col>
            <h2 class="modal-title" data-test-hook="wifi-tank-setup-header">
              <i class="colorOrange fas fa-plus mr-1" data-test-hook="wifi-tank-setup-header-icon"></i>
              {{ $t('xAddATank') }}
            </h2>
          </b-col>
          <b-col cols="auto">
            <b-button
              class="btn-icon-button--gray"
              variant="icon-button"
              @click="$bvModal.hide('wifi-tank-setup'), resetInfo()"
              data-test-hook="wifi-tank-setup-close-button"
            >
              <i class="fas fa-times" />
            </b-button>
          </b-col>
        </b-row>
      </template>
      <template #modal-footer>
        <b-button
          variant="warning"
          block
          @click="$bvModal.hide('wifi-tank-setup'), resetInfo()"
          data-test-hook="wifi-tank-setup-ok-button"
        >
          {{ $t('xOK') }}
        </b-button>
      </template>
      <b-row>
        <b-col>
          <h4>{{ $t('xWifiTankSetup') }}</h4>
        </b-col>
      </b-row>
      <b-row>
        <b-col v-html="$t('xWifiTankSetupMessage')" data-test-hook="wifi-tank-setup-message"></b-col>
      </b-row>
    </b-modal>

    <b-modal id="confirm-close-wizard" size="md" centered no-close-on-backdrop no-close-on-esc>
      <template #modal-header>
        <b-row class="w-100" align-v="center" no-gutters>
          <b-col>
            <h2 class="modal-title" data-test-hook="confirm-close-wizard-header">
              <i
                class="fas fa-exclamation-triangle colorOrange mr-1"
                data-test-hook="confirm-close-wizard-header-icon"
              />
              {{ $t('xAreYouSure') }}
            </h2>
          </b-col>
        </b-row>
      </template>
      <b-row class="mt-1" style="font-size: 1rem">
        <b-col data-test-hook="confirm-close-wizard-are-you-sure-text">
          {{ $t('xAreYouSureYouWantToExitBeforeFinishing') }}
        </b-col>
      </b-row>
      <b-row class="mt-2 checkbox-row" no-gutters> </b-row>
      <template #modal-footer>
        <b-button
          variant="outline-warning"
          @click="$bvModal.hide('confirm-close-wizard'), $bvModal.show(currentWizardWindow)"
          data-test-hook="confirm-close-wizard-cancel-button"
        >
          {{ $t('xNoCancel') }}
        </b-button>
        <b-button
          variant="primary"
          @click="closeEnrollmentModals()"
          data-test-hook="confirm-close-wizard-confirm-button"
        >
          {{ $t('xYesImSure') }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { required, minLength, maxLength, alphaNum, numeric, between } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'
import * as Constants from '@/constants'
import unitMixin from '@/utils/unitMixin'
import Lottie from '../lottie.vue'
import * as waitingForTankReadingAnimation from '@/assets/images/clock-blue500.json'
import TypeAheadAddress from '@/components/TypeAheadAddress'
import { mapState, mapGetters, mapActions } from 'vuex'
import * as successAnimation from '@/assets/images/manage-subscription-success.json'

const monthInRange = (value, vm) => value >= 1 && value <= 12

export default {
  props: ['enrollmentNoSubscription', 'deviceType', 'apparatusId'],
  mixins: [unitMixin, validationMixin],
  components: { Lottie, TypeAheadAddress },
  created() {
    this.VERTICAL = 'Vertical'
    this.HORIZONTAL = 'Horizontal'
    this.PROPANE = 'Propane'
    this.HEATINGOIL = 'HeatingOil'
  },
  data() {
    return {
      awaitingSearch: false,
      bGClassVerifyTankReading: null,
      capsToLoad: null,
      currentWizardWindow: null,
      editingTankAddress: false,
      enrollingTank: false,
      finishingSetup: false,
      freeformFuelProvider: null,
      fuelProviderId: null,
      fuelProviderName: null,
      fuelProviderResults: null,
      fuelUseHeating: false,
      fullTankDeviceId: null,
      initialAddress: null,
      isValidAddress: true,
      loadingTankCapacities: false,
      selectedTankUtilFuelUses: null,
      tankAddress: null,
      tankApparatusId: null,
      tankCapacities: [],
      tankName: null,
      tankReadingLevel: null,
      tankLevelValidationDone: null,
      userEnteredTankDeviceId: null,
      userEnteredTankDialLevel: null,
      userSelectedFuelType: null,
      userSelectedTankCapacity: null,
      userSelectedTankOrien: null,
      validatingId: false,
      verifyTankReadingSuccess: null,
      waitingForTankReadingAnimationOptions: { animationData: waitingForTankReadingAnimation, loop: true },
      tankDeviceType: null,
      helpStep1: 0,
      helpStep2: 0,
      helpStep3: 0,
      helpStep4: 0,
      helpStep5: 0,
      helpStep6: 0,
      helpStep7: 0,
      helpStep8: 0,
      helpStep9: 0,
      helpStep10: 0,
      helpReadingNotReceived: 0,
      existingPaymentInfo: null,
      useExistingPaymentInfo: true,
      creditCardNumber: null,
      expirationMonth: null,
      expirationYear: null,
      cvv: null,
      updatingPaymentInfo: false,
      subscribingProduct: false,
      errorMessages: [],
      showValidationBanner: false,
      tankSubscriptionCost: null,
      successAnimationOptions: { animationData: successAnimation, loop: false },
      loadingSubscriptionModal: false,
      activeModal: '',
      showEnrollTankApparatusError: false,
      enrollTankApparatusErrorMessage: null,
      showValidateDeviceIdError: false,
      validateDeviceIdErrorMessage: null,
      showTankReadingError: false,
      tankReadingErrorMessage: null,
      showFinishSetupError: false,
      finishSetupErrorMessage: null,
      showUpdatePaymentInfoError: false,
      updatePaymentInfoErrorMessage: null,
      showSubscribeProductError: false,
      subscribeProductErrorMessage: null,
      showTankUsageUnfinishedErrorMessage: false
    }
  },
  computed: {
    ...mapState({
      accountProfile: state => state.account.accountProfile,
      currentAppConfig: state => state.application.currentAppConfig
    }),
    wifiTankDeviceType() {
      return Constants.TankUtilityWifiDeviceType
    },
    lteV1TankDeviceType() {
      return Constants.TankUtilityLteDeviceTypeV1
    },
    lteV2TankDeviceType() {
      return Constants.TankUtilityLteDeviceTypeV2
    }
  },
  validations: {
    userEnteredTankDeviceId: {
      required,
      alphaNum,
      minLength: minLength(8),
      maxLength: maxLength(10)
    },
    userSelectedTankCapacity: {
      required
    },
    userEnteredTankDialLevel: {
      numeric,
      between: between(1, 99)
    },
    cvv: {
      required,
      minLength: minLength(3),
      maxLength: maxLength(4),
      numeric
    },
    expirationMonth: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(2),
      numeric,
      monthInRange
    },
    expirationYear: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(2),
      numeric
    },
    creditCardNumber: {
      required,
      minLength: minLength(15),
      maxLength: maxLength(16),
      numeric
    }
  },
  methods: {
    validFuelMonitorId(fuelMonitorDeviceId) {
      return fuelMonitorDeviceId.length === 8 || fuelMonitorDeviceId.length === 10
    },
    wifiSetupNavigation() {
      if (
        this.tankDeviceType == Constants.TankUtilityWifiDeviceType ||
        this.tankDeviceType == Constants.TankUtilityLteDeviceTypeV1
      ) {
        this.$bvModal.show('tank-enrollment-step-9')
        this.helpStep9++
      } else {
        this.$bvModal.show('tank-enrollment-step-5')
        this.helpStep5++
      }
    },
    createApparatusAndProceed() {
      this.enrollingTank = true
      let enrollRequest = {
        organizationId: this.accountProfile.organizationId,
        deviceId: this.fullTankDeviceId,
        name: this.tankName,
        address: this.tankAddress,
        deviceType: this.tankDeviceType
      }
      this.$store
        .dispatch('enrollTankUtilityApparatus', enrollRequest)
        .then(response => {
          this.showEnrollTankApparatusError = false
          this.$bvModal.show('tank-enrollment-step-3')
          this.enrollingTank = false
          this.tankApparatusId = response.data.apparatusId
        })
        .catch(error => {
          this.enrollingTank = false
          if (error.response.status == 409) {
            this.enrollTankApparatusErrorMessage = this.$t('xTankHasAlreadyBeenEnrolled')
          } else {
            this.enrollTankApparatusErrorMessage = this.$t('xErrorEnrollingTankTryAgain')
          }
          this.showEnrollTankApparatusError = true
        })
    },
    setCurrentAddress(newAddress) {
      this.tankAddress = _.cloneDeep(newAddress)
      if (this.$refs.typeAheadAddressEditTankAddress.$v.$anyError || !this.validAddress(newAddress)) {
        this.isValidAddress = false
      } else {
        this.isValidAddress = true
      }
    },
    validateDeviceId() {
      if (!this.userEnteredTankDeviceId) {
        this.validateDeviceIdErrorMessage = this.$t('xYouMustEnterAValidDeviceIdToContinue')
        this.showValidateDeviceIdError = true
        return false
      } else this.showValidateDeviceIdError = false
      this.validatingId = true
      this.$store
        .dispatch('validateTankDeviceId', this.userEnteredTankDeviceId)
        .then(response => {
          this.validatingId = false
          if (response.status == 200 && response.data.isValid) {
            if (response.data.fullDeviceId) this.fullTankDeviceId = response.data.fullDeviceId
            if (response.data.tankName) this.tankName = response.data.tankName
            if (response.data.tankAddress) this.tankAddress = _.cloneDeep(response.data.tankAddress)
            if (response.data.deviceType) this.tankDeviceType = response.data.deviceType
            this.$bvModal.show('tank-enrollment-step-2')
            this.showValidateDeviceIdError = false
          } else {
            let errorMessage = ''
            switch (response.data.validationCode) {
              case Constants.TankDeviceIdValidationCode_AlreadyEnrolled:
                this.validateDeviceIdErrorMessage = this.$t('xDeviceIdBelongsToAnExistingAccount')
                this.showValidateDeviceIdError = true
                break
              case Constants.TankDeviceIdValidationCode_NotFound:
                this.validateDeviceIdErrorMessage = this.$t('xDeviceIdDoesnotExist')
                this.showValidateDeviceIdError = true
                break
              case Constants.TankDeviceIdValidationCode_WiFiNotDeployed:
                this.showValidateDeviceIdError = false
                this.$bvModal.show('wifi-tank-setup')
                break
              case Constants.TankDeviceIdValidationCode_DisabledDealerManaged:
                this.validateDeviceIdErrorMessage = response.data.message
                this.showValidateDeviceIdError = true
                break
              case Constants.TankDeviceIdValidationCode_DisabledNonDealerManaged:
                this.validateDeviceIdErrorMessage = response.data.message
                this.showValidateDeviceIdError = true
                break
            }
          }
          this.helpStep1++
        })
        .catch(error => {
          this.validatingId = false
          this.validateDeviceIdErrorMessage = error.response.data
          this.showValidateDeviceIdError = true
        })
    },
    resetInfo() {
      this.bGClassVerifyTankReading = null
      this.editingTankAddress = false
      this.finishingSetup = false
      this.fullTankDeviceId = null
      this.isValidAddress = true
      this.tankAddress = this.accountProfile && this.accountProfile.address ? this.accountProfile.address : null
      this.tankLevelValidationDone = null
      this.tankName = null
      this.userEnteredTankDeviceId = null
      this.userEnteredTankDialLevel = null
      this.userSelectedFuelType = null
      this.userSelectedTankCapacity = null
      this.userSelectedTankOrien = null
      this.validatingId = false
      this.verifyTankReadingSuccess = null
      this.tankDeviceType = null
      this.helpStep1 = 0
      this.helpStep2 = 0
      this.helpStep3 = 0
      this.helpStep4 = 0
      this.helpStep5 = 0
      this.helpStep6 = 0
      this.helpStep7 = 0
      this.helpStep8 = 0
      this.helpStep9 = 0
      this.helpStep10 = 0
      this.helpReadingNotReceived = 0
      this.existingPaymentInfo = null
      this.useExistingPaymentInfo = true
      this.creditCardNumber = null
      this.expirationMonth = null
      this.expirationYear = null
      this.cvv = null
      this.updatingPaymentInfo = false
      this.subscribingProduct = false
      this.errorMessages = []
      this.showValidationBanner = false
      this.tankSubscriptionCost = null
      this.showEnrollTankApparatusError = false
      this.enrollTankApparatusErrorMessage = null
      this.showValidateDeviceIdError = false
      this.validateDeviceIdErrorMessage = null
      this.showTankReadingError = false
      this.tankReadingErrorMessage = null
      this.showFinishSetupError = false
      this.finishSetupErrorMessage = null
      this.showUpdatePaymentInfoError = false
      this.updatePaymentInfoErrorMessage = null
      this.showSubscribeProductError = false
      this.subscribeProductErrorMessage = null
      this.showTankUsageUnfinishedErrorMessage = false
      //reset tank use dictionary
      this.loadFuelUses()

      this.$v.$reset()
    },
    async loadFuelUses() {
      let consumptionTypes = null
      await this.$store
        .dispatch('getTankUtilityConsumptionTypes')
        .then(response => {
          if (response.status == 200) {
            consumptionTypes = response.data
          }
        })
        .catch(error => {
          //This toast is not being replaced with an alert,
          //because this function is not called from a modal
          this.validateError = error.response.data
          this.$store.dispatch('displayToast', {
            title: this.$t('xError'),
            message: this.validateError.message,
            variant: 'danger'
          })
        })

      consumptionTypes.forEach(element => (element.selected = null))
      this.selectedTankUtilFuelUses = null
      this.selectedTankUtilFuelUses = _.clone(consumptionTypes)
    },
    checkFuelUseSelected() {
      const isNull = element => element.selected === null
      let isError = this.selectedTankUtilFuelUses.some(isNull)
      this.showTankUsageUnfinishedErrorMessage = isError
      if (!isError) {
        this.$bvModal.show('tank-enrollment-step-10')
      }
    },
    areYouSure(currentWizardWindow) {
      this.currentWizardWindow = currentWizardWindow
      this.$bvModal.show('confirm-close-wizard')
    },
    closeEnrollmentModals() {
      if (this.activeModal) {
        this.$appInsights.trackEvent({
          name: 'apparatus-enrollment-cancel',
          properties: { type: 'tankUtility', section: this.activeModal }
        })
      }

      this.resetInfo()
      this.$store.dispatch('getApparatuses')

      //close all enrollment modals:
      this.$bvModal.hide('tank-enrollment-step-1')
      this.$bvModal.hide('tank-enrollment-step-2')
      this.$bvModal.hide('tank-enrollment-step-3')
      this.$bvModal.hide('tank-enrollment-step-4')
      this.$bvModal.hide('tank-enrollment-step-5')
      this.$bvModal.hide('tank-enrollment-step-6')
      this.$bvModal.hide('tank-enrollment-step-7')
      this.$bvModal.hide('tank-enrollment-step-8')
      this.$bvModal.hide('tank-enrollment-step-9')
      this.$bvModal.hide('tank-enrollment-step-10')
      this.$bvModal.hide('tank-enrollment-step-11')
      this.$bvModal.hide('tank-enrollment-success')
      this.$bvModal.hide('confirm-close-wizard')

      this.activeModal = ''
    },
    getTankReading() {
      // Show reading not received message if waiting for reading for more than 60 seconds.
      let showTimeoutMessage = true
      setTimeout(() => {
        if (showTimeoutMessage) {
          this.helpReadingNotReceived++
          this.$bvModal.show('tank-enrollment-tank-reading-not-received')
          showTimeoutMessage = false
        }
      }, 60000)

      this.$store
        .dispatch('getTankReading', this.tankApparatusId)
        .then(response => {
          showTimeoutMessage = false
          this.showTankReadingError = false
          this.tankReadingLevel = response.data.reading
          this.$bvModal.show('tank-enrollment-step-7')
        })
        .catch(error => {
          showTimeoutMessage = false
          if (error.response.status != 408) {
            this.tankReadingErrorMessage = error.response.data
            this.showTankReadingError = true
          }
          this.helpReadingNotReceived++
          this.$bvModal.show('tank-enrollment-tank-reading-not-received')
        })
    },
    async loadTankCapacities() {
      this.loadingTankCapacities = true
      this.capsToLoad = []
      this.tankCapacities = []
      await this.getTankCapacities(this.userSelectedFuelType, this.userSelectedTankOrien)
      this.capsToLoad.forEach(element => {
        this.tankCapacities.push({ value: element.value, text: this.convertCapacityLabel(element.text) })
      })
      this.loadingTankCapacities = false
      this.$bvModal.show('tank-enrollment-step-4')
    },
    async getTankCapacities(fuelType, orientation) {
      await this.$store
        .dispatch('getTankUtilityTankSizes', {
          fuelType: fuelType,
          orientation: orientation
        })
        .then(response => {
          if (response.status == 200) {
            this.capsToLoad = response.data
          }
        })
        .catch(error => {
          //This toast is staying due to user not able to do
          //anything if cosmos fails to return capacities
          this.validateSNError = error.response.data
          this.$store.dispatch('displayToast', {
            title: this.$t('xError'),
            message: this.validateSNError.message,
            variant: 'danger'
          })
        })
    },
    handleAnimation(anim) {
      this.anim = anim
    },
    tankMonitorInstallVideoUrl() {
      if (
        this.currentAppConfig &&
        this.currentAppConfig.configurations &&
        this.currentAppConfig.configurations[Constants.TankMonitorInstallVideoUrlConfig]
      ) {
        return this.currentAppConfig.configurations[Constants.TankMonitorInstallVideoUrlConfig]
      }
      return ''
    },
    trackClick(actionToTrack) {
      this.$appInsights.trackEvent({
        name: 'tank-enrollment-previous-button-click',
        properties: {
          action: actionToTrack
        }
      })
    },
    verifyTankReading() {
      this.verifyTankReadingSuccess =
        this.userEnteredTankDialLevel >= this.tankReadingLevel - 5 &&
        this.userEnteredTankDialLevel <= this.tankReadingLevel + 5
    },
    tryEnterTankLevelAgain() {
      this.verifyTankReadingSuccess = null
      this.tankLevelValidationDone = null
      this.userEnteredTankDialLevel = null
      this.$v.$reset()
      document.getElementById('tankDialLevel').focus()
    },
    addTag(index, value) {
      this.selectedTankUtilFuelUses[index].selected = value
    },
    getVariant(selected) {
      if (selected) return 'warning'
      else return 'outline-warning'
    },
    typeAheadSearch() {
      if (this.timeout) {
        this.awaitingSearch = false
        clearTimeout(this.timeout)
      }
      if (this.freeformFuelProvider.length < 3) {
        this.fuelProviderSearchResults = []
        return
      }
      if (!this.awaitingSearch) {
        var request = {
          name: this.freeformFuelProvider,
          //region: this.accountProfile.address.region,
          pageNumber: 1,
          pageSize: 10
        }
        this.timeout = setTimeout(() => {
          this.$store
            .dispatch('typeAheadFuelProviderSearch', request)
            .then(response => {
              if (response.status == 204) this.fuelProviderResults = []
              else this.fuelProviderResults = response.data
            })
            .catch(error => {
              this.fuelProviderResults = []
            })
            .finally(() => {
              this.awaitingSearch = false
            })
        }, 1000)
      }
      this.awaitingSearch = true
    },
    setFuelProvider(provider) {
      this.fuelProviderId = provider.id
      this.fuelProviderName = provider.name
      this.freeformFuelProvider = null
      this.hideResults()
    },
    hideResults() {
      this.fuelProviderResults = null
    },
    finishSetup() {
      this.finishingSetup = true
      let consumptionTypes = {}
      this.selectedTankUtilFuelUses.forEach(element => {
        consumptionTypes[element.type] = element.selected
      })

      let request = {
        apparatusId: this.tankApparatusId,
        fuelTankCapacity: this.userSelectedTankCapacity,
        fuelTankFuelType: this.userSelectedFuelType,
        fuelTankOrientation: this.userSelectedTankOrien,
        consumptionTypes: consumptionTypes,
        fuelDealerId: this.fuelProviderId
      }
      this.$store
        .dispatch('updateTankUtilityApparatus', request)
        .then(response => {
          if (
            this.tankDeviceType == Constants.TankUtilityWifiDeviceType ||
            this.tankDeviceType == Constants.TankUtilityLteDeviceTypeV1
          ) {
            this.subscribingProduct = true
            let productId = ''
            if (this.tankDeviceType == Constants.TankUtilityLteDeviceTypeV2) {
              productId = Constants.TankUtilityPremiumProductId
            } else productId = Constants.TankUtilityFreeProductId
            this.$store
              .dispatch('changeBillingAccountProduct', {
                organizationId: this.accountProfile.organizationId,
                apparatusId: this.tankApparatusId,
                productId: productId
              })
              .then(response => {
                this.showFinishSetupError = false
                this.subscribingProduct = false
                this.$bvModal.show('tank-enrollment-success')
              })
              .catch(error => {
                let failureMessage = this.$t('xFailedToSetTankSub')
                if (error?.response?.data?.message) {
                  try {
                    let messageAsString = JSON.stringify(error.response.data.message)
                    let messageAsJson = JSON.parse(messageAsString.replace(/\\"/g, '"'))
                    if (messageAsJson) {
                      failureMessage += '\n\u2011' + messageAsJson
                    }
                  } catch (err) {}
                }
                this.subscribingProduct = false
                this.finishSetupErrorMessage = failureMessage
                this.showFinishSetupError = true
              })
          } else {
            this.$store
              .dispatch('getActivePaymentMethod', this.accountProfile.organizationId)
              .then(response => {
                this.existingPaymentInfo = response.data
                this.useExistingPaymentInfo = true
                this.$store
                  .dispatch('getSubscriptionProductByApparatusId', this.tankApparatusId)
                  .then(response => {
                    if (response?.data[0]?.cost != undefined) {
                      this.tankSubscriptionCost = response.data[0].cost
                      this.showFinishSetupError = false
                      this.$bvModal.show('tank-enrollment-step-11')
                    } else {
                      this.finishSetupErrorMessage = this.$t('xSomethingUnexpectedWentWrong')
                      this.showFinishSetupError = true
                    }
                    this.finishingSetup = false
                  })
                  .catch(error => {
                    this.finishingSetup = false
                    this.finishSetupErrorMessage = this.$t('xSomethingUnexpectedWentWrong')
                    this.showFinishSetupError = true
                  })
              })
              .catch(error => {
                this.$store
                  .dispatch('getSubscriptionProductByApparatusId', this.tankApparatusId)
                  .then(response => {
                    if (response?.data[0]?.cost != undefined) {
                      this.tankSubscriptionCost = response.data[0].cost
                      this.showFinishSetupError = false
                      this.$bvModal.show('tank-enrollment-step-11')
                    } else {
                      this.finishSetupErrorMessage = this.$t('xSomethingUnexpectedWentWrong')
                      this.showFinishSetupError = true
                    }
                    this.finishingSetup = false
                  })
                  .catch(error => {
                    this.finishingSetup = false
                    this.finishSetupErrorMessage = this.$t('xSomethingUnexpectedWentWrong')
                    this.showFinishSetupError = true
                  })
                this.finishingSetup = false
                this.existingPaymentInfo = null
                this.useExistingPaymentInfo = false
                this.showFinishSetupError = false
                this.$bvModal.show('tank-enrollment-step-11')
              })
          }
        })
        .catch(error => {
          this.finishingSetup = false
          this.finishSetupErrorMessage = this.$t('xErrorUpdatingTankPleaseTryAgain')
          this.showFinishSetupError = true
        })
    },
    updatePaymentInfo() {
      this.updatingPaymentInfo = true
      let addPaymentModel = {
        organizationId: this.accountProfile.organizationId,
        cardNumber: this.creditCardNumber,
        cvv: this.cvv,
        expirationMonth: this.expirationMonth,
        expirationYear: '20' + this.expirationYear
      }
      if (this.existingPaymentInfo) {
        let removePaymentModel = {
          organizationId: this.accountProfile.organizationId,
          paymentId: this.existingPaymentInfo.id
        }
        this.$store
          .dispatch('addBillingAccountPaymentMethod', addPaymentModel)
          .then(response => {
            this.$store
              .dispatch('removeBillingAccountPaymentMethod', removePaymentModel)
              .then(response => {
                this.useExistingPaymentInfo = true
                this.updatingPaymentInfo = false
                this.showUpdatePaymentInfoError = false
              })
              .catch(error => {
                this.updatePaymentInfoErrorMessage = this.$t('xFailedToRemoveExistingPaymentInformation')
                this.showUpdatePaymentInfoError = true
                this.updatingPaymentInfo = false
              })
          })
          .catch(error => {
            this.errorMessages = []
            if (error.response.data.errors) {
              if (error.response.data.errors.CardNumber || error.response.data.errors.cardNumber) {
                this.showValidationBanner = true
                this.errorMessages.push(this.$t('xCCIsInvalid'))
              }
              if (error.response.data.errors.Cvv || error.response.data.errors.cvv) {
                this.showValidationBanner = true
                this.errorMessages.push(this.$t('xCvvInvalid'))
              }
              if (error.response.data.errors.ExpirationMonth || error.response.data.errors.expirationMonth) {
                this.showValidationBanner = true
                this.errorMessages.push(this.$t('xExpirationMonthInvalid'))
              }
              if (error.response.data.errors.ExpirationYear || error.response.data.errors.expirationYear) {
                this.showValidationBanner = true
                this.errorMessages.push(this.$t('xExpirationYearInvalid'))
              }
              if (error.response.data.errors.billing || error.response.data.errors.Billing) {
                this.updatePaymentInfoErrorMessage = error.response.data.errors.billing
                  ? error.response.data.errors.billing
                  : error.data.response.errors.Billing
                this.showUpdatePaymentInfoError = true
              }
            } else {
              this.updatePaymentInfoErrorMessage = this.$t('xFailedToAddPaymentMethod')
              this.showUpdatePaymentInfoError = true
            }
            this.updatingPaymentInfo = false
          })
      } else {
        this.$store
          .dispatch('addBillingAccountPaymentMethod', addPaymentModel)
          .then(response => {
            this.$store
              .dispatch('getActivePaymentMethod', this.accountProfile.organizationId)
              .then(response => {
                this.updatingPaymentInfo = false
                this.existingPaymentInfo = response.data
                this.useExistingPaymentInfo = true
                this.showUpdatePaymentInfoError = false
              })
              .catch(error => {
                this.updatingPaymentInfo = false
                this.finishingSetup = false
                this.existingPaymentInfo = null
                this.useExistingPaymentInfo = false
              })
          })
          .catch(error => {
            this.errorMessages = []
            if (error.response.data.errors) {
              if (error.response.data.errors.CardNumber || error.response.data.errors.cardNumber) {
                this.showValidationBanner = true
                this.errorMessages.push(this.$t('xCCIsInvalid'))
              }
              if (error.response.data.errors.Cvv || error.response.data.errors.cvv) {
                this.showValidationBanner = true
                this.errorMessages.push(this.$t('xCvvInvalid'))
              }
              if (error.response.data.errors.ExpirationMonth || error.response.data.errors.expirationMonth) {
                this.showValidationBanner = true
                this.errorMessages.push(this.$t('xExpirationMonthInvalid'))
              }
              if (error.response.data.errors.ExpirationYear || error.response.data.errors.expirationYear) {
                this.showValidationBanner = true
                this.errorMessages.push(this.$t('xExpirationYearInvalid'))
              }
              if (error.response.data.errors.billing || error.response.data.errors.Billing) {
                this.updatePaymentInfoErrorMessage = error.response.data.errors.billing
                  ? error.response.data.errors.billing
                  : error.data.response.errors.Billing
                this.showUpdatePaymentInfoError = true
              }
            } else {
              this.updatePaymentInfoErrorMessage = this.$t('xFailedToAddPaymentMethod')
              this.showUpdatePaymentInfoError = true
            }
            this.updatingPaymentInfo = false
          })
      }
    },
    subscribeProduct() {
      this.subscribingProduct = true
      let productId = ''
      if (this.tankDeviceType == Constants.TankUtilityLteDeviceTypeV2) {
        productId = Constants.TankUtilityPremiumProductId
      } else productId = Constants.TankUtilityFreeProductId
      this.$store
        .dispatch('changeBillingAccountProduct', {
          organizationId: this.accountProfile.organizationId,
          apparatusId: this.tankApparatusId,
          productId: productId
        })
        .then(response => {
          this.subscribingProduct = false
          this.showSubscribeProductError = false
          this.$bvModal.show('tank-enrollment-success')
        })
        .catch(error => {
          let failureMessage = this.$t('xFailedToSetTankSub')
          if (error?.response?.data?.message) {
            try {
              let messageAsJson = JSON.parse(error.response.data.message.replace(/\\"/g, '"'))
              if (messageAsJson?.gatewayResponse?.message) {
                failureMessage = messageAsJson?.gatewayResponse?.message
              }
            } catch (err) {}
          }
          this.subscribingProduct = false
          this.subscribeProductErrorMessage = failureMessage
          this.showSubscribeProductError = true
        })
    },
    loadSubscriptionModal() {
      this.$store
        .dispatch('getActivePaymentMethod', this.accountProfile.organizationId)
        .then(response => {
          this.existingPaymentInfo = response.data
          this.useExistingPaymentInfo = true
          this.$store
            .dispatch('getSubscriptionProductByApparatusId', this.tankApparatusId)
            .then(response => {
              if (
                response &&
                response.data &&
                response.data[0] &&
                response.data[0].cost != null &&
                response.data[0].cost >= 0
              ) {
                this.tankSubscriptionCost = response.data[0].cost
                this.loadingSubscriptionModal = false
              } else {
                //This toast is staying because it is not called from a modal
                this.$store.dispatch('displayToast', {
                  title: this.$t('xError'),
                  message: this.$t('xSomethingUnexpectedWentWrong'),
                  variant: 'danger'
                })
              }
              this.finishingSetup = false
            })
            .catch(error => {
              this.finishingSetup = false
              //This toast is staying because it is not called from a modal
              this.$store.dispatch('displayToast', {
                title: this.$t('xError'),
                message: this.$t('xSomethingUnexpectedWentWrong'),
                variant: 'danger'
              })
            })
        })
        .catch(error => {
          this.$store
            .dispatch('getSubscriptionProductByApparatusId', this.tankApparatusId)
            .then(response => {
              if (
                response &&
                response.data &&
                response.data[0] &&
                response.data[0].cost != null &&
                response.data[0].cost >= 0
              ) {
                this.tankSubscriptionCost = response.data[0].cost
                this.loadingSubscriptionModal = false
              } else {
                //This toast is staying because it is not called from a modal
                this.$store.dispatch('displayToast', {
                  title: this.$t('xError'),
                  message: this.$t('xSomethingUnexpectedWentWrong'),
                  variant: 'danger'
                })
              }
              this.finishingSetup = false
            })
            .catch(error => {
              this.finishingSetup = false
              //This toast is staying because it is not called from a modal
              this.$store.dispatch('displayToast', {
                title: this.$t('xError'),
                message: this.$t('xSomethingUnexpectedWentWrong'),
                variant: 'danger'
              })
            })
          this.finishingSetup = false
          this.existingPaymentInfo = null
          this.useExistingPaymentInfo = false
          this.loadingSubscriptionModal = false
        })
    },

    // NOTE - this functions are used to track/monitor when the user (somehow) exists the enrollment process before completing the subscription application
    // portion of the enrollment
    // NOTE - for more information about the beforeunload event, please refer to this: https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event#usage_notes
    onLeaveBeforeSubscriptionApplication() {
      window.removeEventListener('beforeunload', this.onLeaveBeforeSubscriptionApplication)
      this.$appInsights.trackEvent({
        name: 'apparatus-enrollment-cancel',
        properties: { type: 'tankUtility', section: 'subscription-purchase' }
      })
    },
    OnShowSubscriptionModal() {
      this.activeModal = 'subscription-purchase'
      window.addEventListener('beforeunload', this.onLeaveBeforeSubscriptionApplication)
    },
    OnHideSubscriptionModal() {
      window.removeEventListener('beforeunload', this.onLeaveBeforeSubscriptionApplication)
    },
    removeExtraChars(testhook) {
      return testhook
        .split(' ')
        .join('')
        .split('/')
        .join('')
    }
  },
  watch: {
    accountProfile: function(newVal, oldVal) {
      if (newVal) {
        this.tankAddress = _.cloneDeep(newVal.address)
        this.initialAddress = _.cloneDeep(newVal.address)
      }
    },
    deviceType: function(newVal, oldVal) {
      this.tankDeviceType = newVal
    },
    enrollmentNoSubscription: function(newVal, oldVal) {
      this.loadingSubscriptionModal = true
      this.loadSubscriptionModal()
    },
    apparatusId: function(newVal, oldVal) {
      this.tankApparatusId = newVal
    }
  },
  mounted() {
    this.loadFuelUses()
    if (this.accountProfile) {
      this.tankAddress = _.cloneDeep(this.accountProfile.address)
      this.initialAddress = _.cloneDeep(this.accountProfile.address)
    }
    if (!this.countryStates || !this.countryStates.value) this.$store.dispatch('getCountrySubdivisions')
    if (!this.currentAppConfig) this.$store.dispatch('getApplicationConfiguration', 'Any')
    if (this.deviceType) {
      this.tankDeviceType = this.deviceType
    }
    if (this.apparatusId) this.tankApparatusId = this.apparatusId
    if (this.enrollmentNoSubscription) {
      this.loadingSubscriptionModal = true
      this.loadSubscriptionModal()
    }
  }
}
</script>
<style scoped lang="scss">
.inProgress {
  color: lightgray;
}

.bullet-points {
  li {
    margin-bottom: 0.35em;
  }
}

.shouldnt-take-long {
  text-align: center;
}

.verify-reading {
  font-style: italic;
  text-align: center;
}

.check-cellular {
  text-align: center;
}

.font-07 {
  font-size: 0.7rem;
  line-height: 1rem;
}

.font-weight-400 {
  font-weight: 400;
}

.modal-header {
  padding: 160px 160px;
}

.bold-text {
  font-weight: bold;
}

li {
  margin: 0px 0;
}

.tu-help-alert {
  background-color: var(--yellow-200);
  color: var(--carbon-900);
  border: solid 1px var(--yellow-400);
}

.invalid-feedback {
  order: 2;
}
</style>
