<template>
  <div>
    <b-modal id="serial-number-step-1" size="lg" no-close-on-backdrop no-close-on-esc no-fade no-stacking centered>
      <template #modal-header>
        <h2 class="modal-title"><i class="fas fa-plus colorOrange mr-1" /> {{ $t('xAddAGen') }}</h2>
      </template>
      <template #modal-footer>
        <b-button
          variant="outline-warning"
          @click="closeEnrollmentModals('sn-verification')"
          data-test-hook="cancel-button-step1-enrollment"
        >
          {{ $t('xCancel') }}
        </b-button>
        <b-button
          variant="primary"
          @click="validateSerialNumber()"
          :disabled="validatingSN"
          data-test-hook="ContinueBtn-step1-enrollment"
        >
          {{ $t('xContinue') }} <i class="fas fa-arrow-right fa-lg" />
          <b-spinner class="ml-2" v-if="validatingSN"></b-spinner>
        </b-button>
      </template>
      <b-row align-v="center" class="mb-2">
        <b-col>
          <h5>
            <strong>{{ $t('xEnterYourGenSN') }}</strong>
          </h5>
        </b-col>
        <b-col cols="auto" class="float-right text-right">
          <i class="fas fa-info-circle fa-lg colorOrange hoverPointer" v-b-modal.generator-serial-number-help />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-input-group>
            <b-form-input
              maxlength="30"
              v-model="userEnteredSerialNumber"
              autocomplete="new-password"
              data-test-hook="SerialNumberField"
            />
          </b-input-group>
        </b-col>
      </b-row>
      <div
        v-if="showSerialNumberValidationError && serialNumberValidationMessage"
        :show="showSerialNumberValidationError"
        class="alert alert-danger alert--validation"
        data-test-hook="SerialNumberInvalidText"
      >
        {{ serialNumberValidationMessage }}
      </div>
      <b-row class="mt-5" align-v="center">
        <b-col class="mr-0 pr-0">
          <b-progress :value="16.67" variant="primary"></b-progress>
        </b-col>
        <b-col cols="auto" class="ml-0 pl-0">
          <i class="fas fa-shield-check fa-lg inProgress ml-1 pl-0" />
        </b-col>
      </b-row>
    </b-modal>

    <b-modal
      id="generator-serial-number-help"
      no-close-on-backdrop
      no-close-on-esc
      no-fade
      no-stacking
      hide-footer
      centered
    >
      <template #modal-header>
        <b-row align-v="center">
          <b-col cols="auto" class="pl-0">
            <b-button variant="icon-button" v-b-modal.serial-number-step-1 data-test-hook="x-button-snHelp-enrollment">
              <i class="fas fa-chevron-left back-chevron-button" />
            </b-button>
          </b-col>
          <b-col>
            <h2 class="modal-title">
              {{ $t('xSNHelp') }}
            </h2>
          </b-col>
        </b-row>
      </template>
      <b-row class="my-3">
        <b-col class="d-flex justify-content-center">
          <b-img
            class="sn-tag-img"
            alt="Serial Number Illustration"
            fluid
            :src="require('@/assets/images/serial-number-tag.svg')"
          />
        </b-col>
      </b-row>
      <b-row class="mt-5">
        <b-col>
          <p>
            <strong>{{ $t('xLocateTheGeneratorSerialNumber') }}</strong>
          </p>
          <p>{{ $t('x1BehindTheFrontPanelOfYourGenerator') }}</p>
          <p>{{ $t('x2OnTheBackOfTheBoxOfYourGeneratorPackaging') }}</p>
        </b-col>
      </b-row>
    </b-modal>

    <b-modal id="gen-name-step-2" size="lg" no-close-on-backdrop no-close-on-esc no-fade no-stacking centered>
      <template #modal-header>
        <b-row align-v="center">
          <b-col cols="auto" class="pr-0">
            <b-button
              variant="icon-button"
              v-b-modal.serial-number-step-1
              data-test-hook="back-button-step2-enrollment"
            >
              <i class="fas fa-chevron-left back-chevron-button" />
            </b-button>
          </b-col>
          <b-col>
            <h2 class="modal-title"><i class="fas fa-plus colorOrange mr-1" /> {{ $t('xAddAGen') }}</h2>
          </b-col>
        </b-row>
      </template>
      <template #modal-footer>
        <b-button
          variant="outline-warning"
          @click="closeEnrollmentModals('set-apparatus-name')"
          data-test-hook="cancel-button-step2-enrollment"
        >
          {{ $t('xCancel') }}
        </b-button>
        <b-button
          variant="primary"
          v-b-modal.generator-fuel-type-step-3
          :disabled="!generatorName"
          data-test-hook="ContinueBtn-step2-enrollment"
        >
          {{ $t('xContinue') }} <i class="fas fa-arrow-right" />
        </b-button>
      </template>
      <b-row>
        <b-col>
          <h5>
            <strong>{{ $t('xNameYourGen') }}</strong>
          </h5>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-input-group>
            <b-form-input
              maxlength="50"
              v-model="generatorName"
              autocomplete="new-password"
              data-test-hook="GeneratorNameField"
            />
          </b-input-group>
        </b-col>
      </b-row>
      <b-row class="mt-5" align-v="center">
        <b-col class="mr-0 pr-0">
          <b-progress :value="33.33" variant="primary"></b-progress>
        </b-col>
        <b-col cols="auto" class="ml-0 pl-0">
          <i class="fas fa-shield-check fa-lg inProgress ml-1 pl-0" />
        </b-col>
      </b-row>
    </b-modal>

    <b-modal
      id="generator-fuel-type-step-3"
      size="lg"
      no-close-on-backdrop
      no-close-on-esc
      no-fade
      no-stacking
      centered
    >
      <template #modal-header>
        <b-row align-v="center">
          <b-col cols="auto" class="pr-0">
            <b-button
              variant="icon-button"
              v-b-modal.v-b-modal.gen-name-step-2
              data-test-hook="back-button-step3-enrollment"
            >
              <i class="fas fa-chevron-left back-chevron-button" />
            </b-button>
          </b-col>
          <b-col>
            <h2 class="modal-title"><i class="fas fa-plus colorOrange mr-1" /> {{ $t('xSelectFuelType') }}</h2>
          </b-col>
        </b-row>
      </template>
      <template #modal-footer>
        <b-button
          variant="outline-warning"
          @click="closeEnrollmentModals('add-apparatus-fuel-type')"
          data-test-hook="cancel-button-step3-enrollment"
        >
          {{ $t('xCancel') }}
        </b-button>
        <b-button
          variant="primary"
          v-b-modal.generator-address-step-4
          data-test-hook="ContinueBtn-step3-enrollment"
          @click="setFuelType()"
          :disabled="selectedFuelType == null"
        >
          {{ $t('xContinue') }} <i class="fas fa-arrow-right" />
        </b-button>
      </template>
      <b-row>
        <b-col>
          <h5>
            <strong>{{ $t('xFuelType') }}</strong>
          </h5>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-select v-model="selectedFuelType" :options="fuelOptions">
            <template #first>
              <b-form-select-option :value="null" disabled>{{ $t('xPleaseSelectYourFuelType') }}</b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
      </b-row>
      <b-row class="mt-5" align-v="center">
        <b-col class="mr-0 pr-0">
          <b-progress :value="50.0" variant="primary"></b-progress>
        </b-col>
        <b-col cols="auto" class="ml-0 pl-0">
          <i class="fas fa-shield-check fa-lg inProgress ml-1 pl-0" />
        </b-col>
      </b-row>
    </b-modal>

    <b-modal id="generator-address-step-4" size="lg" no-close-on-backdrop no-close-on-esc no-fade no-stacking centered>
      <template #modal-header>
        <b-row align-v="center">
          <b-col cols="auto" class="pr-0">
            <b-button
              variant="icon-button"
              v-b-modal.v-b-modal.generator-fuel-type-step-3
              data-test-hook="back-button-step4-enrollment"
            >
              <i class="fas fa-chevron-left back-chevron-button" />
            </b-button>
          </b-col>
          <b-col>
            <h2 class="modal-title"><i class="fas fa-plus colorOrange mr-1" /> {{ $t('xAddAGen') }}</h2>
          </b-col>
        </b-row>
      </template>
      <template #modal-footer>
        <b-button
          variant="outline-warning"
          @click="closeEnrollmentModals('add-apparatus-address')"
          data-test-hook="cancel-button-step4-enrollment"
        >
          {{ $t('xCancel') }}
        </b-button>
        <b-button
          variant="primary"
          v-b-modal.confirm-gen-enrollment-step-5
          data-test-hook="ContinueBtn-step4-enrollment"
        >
          {{ $t('xContinue') }} <i class="fas fa-arrow-right" />
        </b-button>
      </template>
      <b-row>
        <b-col>
          <h5>
            <strong>{{ $t('xSelectYourGenAddress') }}</strong>
          </h5>
        </b-col>
      </b-row>
      <b-row class="mb-3">
        <b-col>
          <b-form-checkbox
            class="font-weight-bold"
            v-model="useAccountAddress"
            data-test-hook="use-acct-address-checkbox-step4-enrollment"
          >
            {{ $t('xUseAcctAddress') }}
          </b-form-checkbox>
        </b-col>
      </b-row>
      <b-row v-if="useAccountAddress" data-test-hook="GeneratorAddress">
        <b-col>
          <p>{{ DisplayAddress(accountProfile.address) }}</p>
        </b-col>
      </b-row>
      <b-row v-else>
        <b-col>
          <TypeAheadAddress
            ref="typeAheadAddressGenAddress"
            :currentAddress="accountData.address"
            @newAddress="setCurrentAddress"
          />
        </b-col>
      </b-row>
      <b-row class="mt-5" align-v="center">
        <b-col class="mr-0 pr-0">
          <b-progress :value="66.67" variant="primary"></b-progress>
        </b-col>
        <b-col cols="auto" class="ml-0 pl-0">
          <i class="fas fa-shield-check fa-lg inProgress ml-1 pl-0" />
        </b-col>
      </b-row>
    </b-modal>

    <b-modal
      id="confirm-gen-enrollment-step-5"
      size="lg"
      no-close-on-backdrop
      no-close-on-esc
      no-fade
      no-stacking
      hide-header
      centered
    >
      <template #modal-header>
        <b-row align-v="center">
          <b-col cols="auto" class="pr-0">
            <b-button
              variant="icon-button"
              v-b-modal.generator-address-step-4
              data-test-hook="back-button-step5-enrollment"
            >
              <i class="fas fa-chevron-left back-chevron-button" />
            </b-button>
          </b-col>
          <b-col>
            <h2 class="modal-title"><i class="fas fa-plus colorOrange mr-1" /> {{ $t('xConfirmEnrollment') }}</h2>
          </b-col>
        </b-row>
      </template>
      <template #modal-footer>
        <b-button
          variant="outline-warning"
          @click="closeEnrollmentModals('confirm-apparatus')"
          data-test-hook="cancel-button-step5-enrollment"
        >
          {{ $t('xCancel') }}
        </b-button>
        <b-button
          variant="primary"
          @click="enrollApparatus()"
          :disabled="enrollingApparatus"
          data-test-hook="EnrollBtn-step5-enrollment"
        >
          {{ $t('xEnrollThisGenerator') }} <i class="fas fa-arrow-right" />
          <b-spinner class="ml-2" v-if="enrollingApparatus"></b-spinner>
        </b-button>
      </template>
      <b-row class="text-center">
        <b-col>
          <h2>
            {{ $t('xYourGenInfo') }}
          </h2>

          <h5>{{ generatorName }}</h5>
          <p v-if="useAccountAddress">{{ DisplayAddress(accountProfile.address) }}</p>

          <p v-else>{{ DisplayAddress(generatorAddress) }}</p>
          <p>{{ $t('xFuelType') }}: {{ selectedFuelTypeName }}</p>
        </b-col>
      </b-row>
      <div
        v-if="showEnrollApparatusError"
        :show="showEnrollApparatusError"
        class="alert alert-danger alert--validation"
      >
        {{ $t('xFailedToAddApparatus') }}
      </div>
      <b-row class="mt-5" align-v="center">
        <b-col class="mr-0 pr-0">
          <b-progress :value="83.33" variant="primary"></b-progress>
        </b-col>
        <b-col cols="auto" class="ml-0 pl-0">
          <i class="fas fa-shield-check fa-lg inProgress ml-1 pl-0" />
        </b-col>
      </b-row>
    </b-modal>

    <b-modal
      id="generator-enrollment-success"
      size="lg"
      no-close-on-backdrop
      no-close-on-esc
      no-fade
      no-stacking
      hide-header
      centered
    >
      <template #modal-footer>
        <b-button
          variant="primary"
          @click="
            deviceType && (macAddress || searchLabel)
              ? $bvModal.show('confirm-existing-device')
              : $bvModal.show('select-device-type-step-6')
          "
          data-test-hook="AddCommBtn"
        >
          {{ 'Enroll a communication device' }} <i class="fas fa-arrow-right" />
        </b-button>
      </template>
      <b-row class="text-center mb-3">
        <b-col>
          <h1>{{ $t('xSuccess') }}!</h1>
          <h5>{{ $t('xYouHaveSuccessfullyEnrolledYourGenerator') }}</h5>
        </b-col>
      </b-row>
      <b-row class="text-center">
        <b-col>
          <b-img
            class="generator-success-img"
            alt="Generator Illustration"
            fluid
            :src="require('@/assets/images/gen-enrollment-success.svg')"
          />
        </b-col>
      </b-row>
      <div v-if="showRegistrationFailedError" class="alert alert--validation alert-danger">
        {{ $t('xYourGeneratorWasSuccessfullyEnrolledBut') }}
      </div>
    </b-modal>

    <b-modal id="confirm-existing-device" size="lg" no-close-on-backdrop no-close-on-esc no-fade no-stacking centered>
      <template #modal-header>
        <h2 class="modal-title"><i class="fas fa-plus colorOrange mr-1" /> {{ $t('xConfirmCommunicationDevice') }}</h2>
      </template>
      <template #modal-footer>
        <b-button
          variant="outline-warning"
          data-test-hook="change-device-button"
          @click="$bvModal.show('select-device-type-step-6')"
        >
          {{ $t('xChangeDevice') }}
        </b-button>
        <b-button
          variant="primary"
          data-test-hook="confirm-device-button"
          @click="$bvModal.show('device-enrollment-success-step-9')"
        >
          {{ $t('xConfirm') }}
        </b-button>
      </template>
      <b-row>
        <b-col data-test-hook="existing-device-text">
          {{ 'xOurRecordsShowExistingDevice' | formatLabel }}
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col cols="12">
          <i class="asterisk-text" data-test-hook="device-type-label">{{ 'xDeviceType' | formatLabel }}</i>
        </b-col>
        <b-col cols="12">
          <strong data-test-hook="device-type">{{ deviceType }}</strong>
        </b-col>
      </b-row>
      <b-row class="mt-3" v-if="deviceType == 'wifi'">
        <b-col cols="12">
          <i class="asterisk-text" data-test-hook="device-serial-number-label">{{ 'xMacAddress' | formatLabel }}</i>
        </b-col>
        <b-col cols="12">
          <strong data-test-hook="device-serial-number">{{ macAddress }}</strong>
        </b-col>
      </b-row>
      <b-row v-else>
        <b-col cols="12">
          <i class="asterisk-text" data-test-hook="device-serial-number-label">{{
            'xDeviceSerialNumber' | formatLabel
          }}</i>
        </b-col>
        <b-col cols="12">
          <strong v-if="deviceType == hsbG3DeviceType" data-test-hook="device-serial-number">{{
            shortDeviceId
          }}</strong>
          <strong v-else data-test-hook="device-serial-number">{{ searchLabel }}</strong>
        </b-col>
      </b-row>
    </b-modal>

    <b-modal id="select-device-type-step-6" size="lg" no-close-on-backdrop no-close-on-esc no-fade no-stacking centered>
      <template #modal-header>
        <h2 class="modal-title"><i class="fas fa-plus colorOrange mr-1" /> {{ $t('xAddCommunicationDevice') }}</h2>
      </template>
      <template #modal-footer>
        <b-button
          variant="outline-warning"
          @click="closeEnrollmentModals('select-apparatus-device-type')"
          data-test-hook="cancel-button-step6-enrollment"
        >
          {{ $t('xCancel') }}
        </b-button>
        <b-button
          variant="primary"
          @click="enterDeviceInformation()"
          :disabled="!deviceType"
          data-test-hook="ConfirmBtn-step6-enrollment"
        >
          {{ $t('xConfirm') }} <i class="fas fa-arrow-right" />
        </b-button>
      </template>
      <b-row>
        <b-col>
          <b-card
            class="mb-3 hoverPointer"
            :class="selectedDeviceType('wifi')"
            @click="setDeviceType('wifi')"
            data-test-hook="wifi-clickable-card-step6-enrollment"
          >
            <b-card-body>
              <b-row align-v="center">
                <b-col cols="2" class="text-center">
                  <b-img
                    class="device-type-img"
                    :alt="$t('xOnBoardWiFi') + ' Illustration'"
                    :src="require('@/assets/images/onboard-wifi.svg')"
                  />
                </b-col>
                <b-col data-test-hook="WiFiBtn">
                  <strong> {{ $t('xOnBoardWiFi') }}</strong>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
      <b-row v-if="g3DeviceFeatureFlagEnabled(currentAppConfig)">
        <b-col>
          <b-card
            class="mb-3 hoverPointer"
            :class="selectedDeviceType(hsbG3DeviceType)"
            @click="setDeviceType(hsbG3DeviceType)"
            data-test-hook="g3-clickable-card-step6-enrollment"
          >
            <b-card-body>
              <b-row align-v="center">
                <b-col cols="2" class="text-center">
                  <b-img
                    class="device-type-img"
                    :alt="$t('xConnectivityAccessoryCellular') + ' Illustration'"
                    :src="require('@/assets/images/G3 device.svg')"
                  />
                </b-col>
                <b-col data-test-hook="G3Btn">
                  <strong> {{ $t('xConnectivityAccessoryCellular') }}</strong>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-card
            class="mb-3 hoverPointer"
            :class="selectedDeviceType('eth')"
            @click="setDeviceType('eth')"
            data-test-hook="eth-clickable-card-step6-enrollment"
          >
            <b-card-body>
              <b-row align-v="center">
                <b-col cols="2" class="text-center">
                  <b-img
                    class="device-type-img"
                    :alt="$t('xWifiEth') + ' Illustration'"
                    :src="require('@/assets/images/tether-wifi.svg')"
                  />
                </b-col>
                <b-col data-test-hook="ETHBtn">
                  <strong> {{ $t('xWifiEth') }}</strong>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
      <b-row v-if="canHavePaidSubscription(accountData)">
        <b-col>
          <b-card
            class="mb-0 hoverPointer"
            :class="selectedDeviceType('lte')"
            @click="setDeviceType('lte')"
            data-test-hook="lte-clickable-card-step6-enrollment"
          >
            <b-card-body>
              <b-row align-v="center">
                <b-col cols="2" class="text-center">
                  <b-img
                    class="device-type-img"
                    :alt="$t('xLteCellular') + ' Illustration'"
                    :src="require('@/assets/images/lte-cellular.svg')"
                  />
                </b-col>
                <b-col data-test-hook="LTEBtn">
                  <strong> {{ $t('xLteCellular') }}</strong>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
      <b-row class="mt-5" align-v="center">
        <b-col class="mr-0 pr-0">
          <b-progress :value="66.66" variant="primary"></b-progress>
        </b-col>
        <b-col cols="auto" class="ml-0 pl-0">
          <i class="fas fa-shield-check fa-lg inProgress ml-1 pl-0" />
        </b-col>
      </b-row>
    </b-modal>

    <b-modal id="no-wifi-module-modal" size="lg" no-close-on-backdrop no-close-on-esc no-fade no-stacking centered>
      <template #modal-header>
        <b-row align-v="center">
          <b-col cols="auto" class="pr-0">
            <b-button
              variant="icon-button"
              v-b-modal.select-device-type-step-6
              data-test-hook="back-button-noWifiModule-enrollment"
            >
              <i class="fas fa-chevron-left back-chevron-button" />
            </b-button>
          </b-col>
          <b-col>
            <h2 class="modal-title"><i class="fas fa-plus colorOrange mr-1" /> {{ $t('xAddCommunicationDevice') }}</h2>
          </b-col>
        </b-row>
      </template>
      <template #modal-footer>
        <b-button
          variant="outline-warning"
          @click="logComeBackLater(), closeEnrollmentModals()"
          data-test-hook="come-back-later-noWifiModule-enrollment"
        >
          {{ $t('xComeBackLater') }}
        </b-button>
        <b-button
          variant="primary"
          @click="logContinueWithSetup(), $bvModal.show('enter-device-info-step-7')"
          data-test-hook="continue-button-noWifiModule-enrollment"
        >
          {{ $t('xContinueWithSetup') }}
        </b-button>
      </template>
      <b-row>
        <b-col>
          {{ $t('xYourGeneratorDoesNotHaveWiFi') }}
          <b-link
            href="https://www.generac.com/wifikit"
            target="_blank"
            data-test-hook="wifikit-link-noWifiModule-enrollment"
          >
            {{ $t('xHere') }}
          </b-link>
          {{ $t('xAndWeWillShipOneToYouWhen') }}
        </b-col>
      </b-row>
    </b-modal>

    <b-modal id="enter-device-info-step-7" size="lg" no-close-on-backdrop no-close-on-esc no-fade no-stacking centered>
      <template #modal-header>
        <b-row align-v="center">
          <b-col cols="auto" class="pr-0">
            <b-button
              variant="icon-button"
              v-b-modal.select-device-type-step-6
              data-test-hook="back-button-step7-enrollment"
            >
              <i class="fas fa-chevron-left back-chevron-button" />
            </b-button>
          </b-col>
          <b-col>
            <h2 class="modal-title"><i class="fas fa-plus colorOrange mr-1" /> {{ $t('xAddCommunicationDevice') }}</h2>
          </b-col>
        </b-row>
      </template>
      <template #modal-footer>
        <b-button
          variant="outline-warning"
          @click="closeEnrollmentModals('enter-apparatus-device-info')"
          data-test-hook="cancel-button-step7-enrollment"
        >
          {{ $t('xCancel') }}
        </b-button>
        <b-button
          variant="primary"
          @click="validateDevice()"
          :disabled="deviceInfoEntered()"
          data-test-hook="ConfirmBtn-step7-enrollment"
        >
          {{ $t('xConfirm') }} <i class="fas fa-arrow-right" />
          <b-spinner class="ml-2" v-if="validatingDevice"></b-spinner>
        </b-button>
      </template>
      <div v-if="deviceType == 'wifi'">
        <b-row>
          <b-col>
            <h5>
              <strong>{{ $t('xEnterYourMacAddress') }}</strong>
            </h5>
          </b-col>
          <b-col cols="auto" class="float-right text-right">
            <i class="fas fa-info-circle fa-lg colorOrange hoverPointer" v-b-modal.mac-address-help />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-input-group>
              <b-form-input
                maxlength="17"
                v-model="macAddress"
                v-mask="'NN:NN:NN:NN:NN:NN'"
                autocomplete="new-password"
                data-test-hook="MACField"
              />
            </b-input-group>
          </b-col>
        </b-row>
      </div>
      <div v-else>
        <b-row>
          <b-col>
            <h5>
              <strong>{{ $t('xEnterYourDeviceSN') }}</strong>
            </h5>
          </b-col>
          <b-col cols="auto" class="float-right text-right">
            <i class="fas fa-info-circle fa-lg colorOrange hoverPointer" v-b-modal.tether-serial-number-help />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-input-group>
              <b-form-input
                v-if="deviceType == hsbG3DeviceType"
                maxlength="9"
                v-model="shortDeviceId"
                autocomplete="new-password"
                data-test-hook="SerialNumberField"
              />
              <b-form-input
                v-else
                maxlength="10"
                v-model="searchLabel"
                autocomplete="new-password"
                data-test-hook="SerialNumberField"
              />
            </b-input-group>
          </b-col>
        </b-row>
      </div>
      <div v-if="showValidateDeviceError" :show="showValidateDeviceError" class="alert alert--validation alert-danger">
        {{ validateDeviceErrorMessage }}
      </div>
      <b-row class="mt-5" align-v="center">
        <b-col class="mr-0 pr-0">
          <b-progress :value="77.77" variant="primary"></b-progress>
        </b-col>
        <b-col cols="auto" class="ml-0 pl-0">
          <i class="fas fa-shield-check fa-lg inProgress ml-1 pl-0" />
        </b-col>
      </b-row>
    </b-modal>

    <b-modal
      id="mac-address-help"
      size="lg"
      no-close-on-backdrop
      no-close-on-esc
      no-fade
      no-stacking
      hide-footer
      centered
    >
      <template #modal-header>
        <b-row align-v="center">
          <b-col cols="auto" class="pr-0">
            <b-button
              variant="icon-button"
              v-b-modal.enter-device-info-step-7
              data-test-hook="back-button-macAddressHelp-enrollment"
            >
              <i class="fas fa-chevron-left back-chevron-button" />
            </b-button>
          </b-col>
          <b-col>
            <h2 class="modal-title">
              {{ $t('xMacAddressHelp') }}
            </h2>
          </b-col>
        </b-row>
      </template>
      <b-row>
        <b-col>
          <b-carousel class="py-3 px-3" background="lightgray" :controls="true" :indicators="true" :interval="0">
            <b-carousel-slide :img-src="require('@/assets/images/gen-onboard-wifi-activate.svg')" />
            <b-carousel-slide :img-src="require('@/assets/images/wifi-label.svg')" />
          </b-carousel>
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col>
          <strong>{{ $t('xLocateTheMACAddress') }}</strong>
        </b-col>
      </b-row>
      <b-row>
        <b-col>{{ $t('x1OpenTheCoverOfYourGenerator') }}</b-col>
      </b-row>
      <b-row>
        <b-col>{{ $t('x2FindTheLabelsLocated') }}</b-col>
      </b-row>
      <b-row>
        <b-col>{{ $t('x3TheMACAddressAppearsOnTheWIFILabel') }}</b-col>
      </b-row>
    </b-modal>

    <b-modal
      id="tether-serial-number-help"
      size="lg"
      no-close-on-backdrop
      no-close-on-esc
      no-fade
      no-stacking
      hide-footer
      centered
    >
      <template #modal-header>
        <b-row align-v="center">
          <b-col cols="auto" class="pr-0">
            <b-button
              variant="icon-button"
              v-b-modal.enter-device-info-step-7
              data-test-hook="back-button-tetherSNHelp-enrollment"
            >
              <i class="fas fa-chevron-left back-chevron-button" />
            </b-button>
          </b-col>
          <b-col>
            <h2 class="modal-title">
              {{ $t('xSNHelp') }}
            </h2>
          </b-col>
        </b-row>
      </template>
      <b-row class="py-5 mt-0 px-3" style="background-color: #EEEEEE;">
        <b-col v-if="deviceType == 'lte'">
          <b-img class="sn-tag-img" fluid :src="require('@/assets/images/lte-label.svg')" />
        </b-col>
        <b-col v-else-if="deviceType == hsbG3DeviceType">
          <b-img class="sn-tag-img" fluid :src="require('@/assets/images/g3-label.svg')" />
        </b-col>
        <b-col v-else>
          <b-img class="sn-tag-img" fluid :src="require('@/assets/images/wifi-eth-label.svg')" />
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col>
          <strong>{{ $t('xLocateTheDeviceSerialNumber') }}</strong>
        </b-col>
      </b-row>
      <b-row v-if="deviceType == 'lte'">
        <b-col>
          {{ $t('x1LocateTheLabelOnTheBottomOfYourLTECellularDevice') }}
        </b-col>
      </b-row>
      <b-row v-else-if="deviceType == hsbG3DeviceType">
        <b-col>
          {{ $t('x1TheDeviceIDIsFoundOnTheBackOfTheCellularDevice') }}
        </b-col>
      </b-row>
      <b-row v-else>
        <b-col>
          {{ $t('x1LocateTheLabelOnTheBottomOfYourWiFiEthernetDevice') }}
        </b-col>
      </b-row>
      <b-row v-if="deviceType == 'lte'">
        <b-col>{{ $t('x2EnterYourDeviceSerialNumber') }}</b-col>
      </b-row>
      <b-row v-else-if="deviceType == hsbG3DeviceType">
        <b-col>{{ $t('x2TheSerialNumberIsLocatedDirectlyBelowTheQRCode') }}</b-col>
      </b-row>
      <b-row v-else>
        <b-col>{{ $t('x2EnterYourDeviceS/N') }}</b-col>
      </b-row>
    </b-modal>

    <b-modal
      id="enroll-device-step-8"
      size="lg"
      no-close-on-backdrop
      no-close-on-esc
      no-fade
      no-stacking
      hide-header
      centered
    >
      <template #modal-header>
        <b-row align-v="center">
          <b-col cols="auto" class="pl-0">
            <b-button
              variant="icon-button"
              v-b-modal.enter-device-info-step-7
              data-test-hook="back-button-step8-enrollment"
            >
              <i class="fas fa-chevron-left back-chevron-button" />
            </b-button>
          </b-col>
          <b-col>
            <h2>{{ $t('xConfirmDeviceEnrollment') }}</h2>
          </b-col>
        </b-row>
      </template>
      <template #modal-footer>
        <b-button
          variant="outline-warning"
          @click="closeEnrollmentModals('confirm-apparatus-device')"
          data-test-hook="cancel-button-step8-enrollment"
        >
          {{ $t('xCancel') }}
        </b-button>
        <b-button
          variant="primary"
          @click="enrollApparatusDevice()"
          :disabled="enrollingDevice"
          data-test-hook="EnrollBtn-step8-enrollment"
        >
          {{ $t('xEnrollThisDevice') }} <i class="fas fa-arrow-right" />
          <b-spinner class="ml-2" v-if="enrollingDevice"></b-spinner>
        </b-button>
      </template>
      <b-row class="text-center" v-if="validatedDeviceInfo">
        <b-col v-if="validatedDeviceInfo.deviceType == 'wifi'">
          <b-img height="200px" :src="require('@/assets/images/onboard-wifi.svg')" />
        </b-col>
        <b-col v-if="validatedDeviceInfo.deviceType == hsbG3DeviceType">
          <b-img height="200px" :src="require('@/assets/images/onboard-wifi.svg')" />
        </b-col>
        <b-col v-if="validatedDeviceInfo.deviceType == 'lte'">
          <b-img height="200px" :src="require('@/assets/images/lte-cellular.svg')" />
        </b-col>
        <b-col v-if="validatedDeviceInfo.deviceType == 'eth'">
          <b-img height="200px" :src="require('@/assets/images/tether-wifi.svg')" />
        </b-col>
      </b-row>
      <b-row class="text-center">
        <b-col class="mt-3">
          <h2>{{ $t('xYourCommunicationDeviceInfo') }}</h2>
        </b-col>
      </b-row>
      <b-row class="text-center">
        <b-col v-if="deviceType == 'wifi'">
          <strong>
            {{ 'xMacAddress' | formatLabel }}
          </strong>
          {{ macAddress }}
        </b-col>
        <b-col v-else-if="deviceType == hsbG3DeviceType">
          <strong>
            {{ 'xDeviceSerialNumber' | formatLabel }}
          </strong>
          {{ shortDeviceId }}
        </b-col>
        <b-col v-else>
          <strong>
            {{ 'xDeviceSerialNumber' | formatLabel }}
          </strong>
          {{ searchLabel }}
        </b-col>
      </b-row>
      <div
        v-if="showEnrollDeviceError && enrollDeviceErrorMessage"
        :show="showEnrollDeviceError"
        class="alert alert-danger alert--validation"
      >
        {{ enrollDeviceErrorMessage }}
      </div>
      <b-row class="mt-5" align-v="center">
        <b-col class="mr-0 pr-0">
          <b-progress :value="88.88" variant="primary"></b-progress>
        </b-col>
        <b-col cols="auto" class="ml-0 pl-0">
          <i class="fas fa-shield-check fa-lg inProgress ml-1 pl-0" />
        </b-col>
      </b-row>
    </b-modal>

    <b-modal
      id="device-enrollment-success-step-9"
      size="lg"
      no-close-on-backdrop
      no-close-on-esc
      no-fade
      no-stacking
      hide-header
      centered
    >
      <template #modal-footer>
        <b-button
          variant="outline-warning"
          v-b-modal.add-transfer-switch
          @click="
            trackAppInsightsEvent('open-register-transfer-switch-modal', {
              page: 'enrollment',
              organizationId: accountData.organizationId,
              userId: accountData.userId
            })
          "
          data-test-hook="add-ts-button-step9-enrollment"
        >
          <i class="fas fa-plus" />
          {{ $t('xTransferSwitch') }}
        </b-button>
        <b-button variant="primary" @click="getSubscriptionInformation()" data-test-hook="SubscriptionBtn">
          <i class="far fa-badge-dollar" />
          {{ $t('xSubscribe') }}
          <b-spinner class="ml-2" v-if="gettingSubscriptions"></b-spinner>
        </b-button>
      </template>
      <b-row class="text-center mb-3">
        <b-col>
          <h1>{{ $t('xSuccess') }}!</h1>
          <h5>{{ $t('xYourCommunicationDeviceHasSuccessfullyBeenEnrolledOntoYourGenerator') }}</h5>
        </b-col>
      </b-row>
      <b-row class="text-center">
        <b-col>
          <b-img
            class="generator-success-img"
            alt="Generator Illustration"
            fluid
            :src="require('@/assets/images/gen-enrollment-success.svg')"
          />
        </b-col>
      </b-row>

      <b-row class="mt-5" align-v="center">
        <b-col class="mr-0 pr-0">
          <b-progress :value="100" variant="primary"></b-progress>
        </b-col>
        <b-col cols="auto" class="ml-0 pl-0">
          <i class="fas fa-shield-check fa-lg colorOrange ml-2 pl-0" />
        </b-col>
      </b-row>
      <div
        v-if="showGetSubscriptionInfoError && getSubscriptionInfoErrorMessage"
        :show="showGetSubscriptionInfoError"
        class="alert alert--validation alert-danger"
      >
        {{ getSubscriptionInfoErrorMessage }}
      </div>
    </b-modal>

    <b-modal id="add-transfer-switch" size="lg" no-close-on-backdrop no-close-on-esc no-fade no-stacking centered>
      <template #modal-header>
        <b-row align-v="center">
          <b-col cols="auto" class="pr-0">
            <b-button
              variant="icon-button"
              v-b-modal.device-enrollment-success-step-9
              @click=";(sns = []), (snToAdd = null)"
              data-test-hook="back-button-addTS-enrollment"
            >
              <i class="fas fa-chevron-left back-chevron-button" />
            </b-button>
          </b-col>
          <b-col>
            <h2 class="modal-title"><i class="far fa-plus colorOrange mr-2"></i>{{ $t('xAddTransferSwitch') }}</h2>
          </b-col>
        </b-row>
      </template>
      <template #modal-footer>
        <b-button
          block
          variant="primary"
          :disabled="!canRegisterSns()"
          @click="
            registerTransferSwitches(),
              trackAppInsightsEvent('register-transfer-switch-serial-numbers', {
                page: 'enrollment',
                organizationId: accountData.organizationId,
                userId: accountData.userId,
                serialNumbers: sns
              })
          "
          data-test-hook="confirm-button-addTS-enrollment"
        >
          {{ $t('xConfirm') }}
          <b-spinner class="mx-1" v-if="registeringSerialNumbers" />
          <i class="far fa-arrow-right" />
        </b-button>
      </template>
      <b-row v-for="(sn, index) in sns" :key="index">
        <b-col>
          <b-row>
            <b-col>
              <h4>{{ ($t('xTransferSwitch') + ' ' + (index + 1)) | formatLabel }}</h4>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" class="asterisk-text">{{ $t('xSerialNumber') }}</b-col>
            <b-col cols="12">
              <h4 style="word-wrap: break-word;">{{ sn }}</h4>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <h4>
            {{ $t('xEnterTransferSwitch') }}
            <span v-if="sns.length > 0">{{ sns.length + 1 }}</span>
          </h4>
        </b-col>
        <b-col>
          <b-form-group>
            <b-form-input v-model="snToAdd" maxlength="20" :placeholder="$t('xSerialNumber')" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-button
            variant="outline-warning"
            :disabled="!snToAdd || snToAdd.length < 7"
            block
            @click="addSnToRegistrationList()"
            data-test-hook="add-TS-button-addTS-enrollment"
          >
            <i class="fas fa-plus" />
            {{ $t('xTransferSwitch') }}
          </b-button>
        </b-col>
      </b-row>
    </b-modal>

    <b-modal
      id="transfer-serial-number-registration-success"
      size="lg"
      hide-header
      no-close-on-backdrop
      no-close-on-esc
      no-fade
      no-stacking
      centered
    >
      <template #modal-footer>
        <b-button block variant="primary" @click="getSubscriptionInformation()" data-test-hook="SubscriptionBtn">
          <i class="far fa-badge-dollar" />
          {{ $t('xChooseASub') }}
          <b-spinner class="ml-2" v-if="gettingSubscriptions"></b-spinner>
        </b-button>
      </template>
      <b-row class="text-center">
        <b-col>
          <span style="font-size: 15rem;"><i class="far fa-thumbs-up colorOrange"/></span>
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col>
          <h3 class="colorOrange">{{ $t('xAwesome') }}</h3>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          {{ $t('xTransferSwitchSuccess') }}
        </b-col>
      </b-row>
      <div
        v-if="showGetSubscriptionInfoError && getSubscriptionInfoErrorMessage"
        :show="showGetSubscriptionInfoError"
        class="alert alert--validation alert-danger"
      >
        {{ getSubscriptionInfoErrorMessage }}
      </div>
    </b-modal>

    <b-modal
      id="transfer-serial-number-registration-failure"
      size="lg"
      no-close-on-backdrop
      no-close-on-esc
      no-fade
      no-stacking
      centered
      hide-header
    >
      <template #modal-footer>
        <b-button block variant="primary" @click="getSubscriptionInformation()" data-test-hook="SubscriptionBtn">
          <i class="far fa-badge-dollar" />
          {{ $t('xChooseASub') }}
          <b-spinner class="ml-2" v-if="gettingSubscriptions"></b-spinner>
        </b-button>
      </template>
      <b-row class="text-center">
        <b-col class="my-3">
          <span style="font-size: 15rem;"><i class="far fa-sad-tear colorOrange"/></span>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <h3 class="colorOrange">{{ $t('xWhoops') }}</h3>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          {{ transferSwitchFailureMessage }}
        </b-col>
      </b-row>
      <div
        v-if="showGetSubscriptionInfoError && getSubscriptionInfoErrorMessage"
        :show="showGetSubscriptionInfoError"
        class="alert alert--validation alert-danger"
      >
        {{ getSubscriptionInfoErrorMessage }}
      </div>
    </b-modal>

    <b-modal
      id="select-subscription-type-step-10"
      size="lg"
      no-close-on-backdrop
      no-close-on-esc
      no-fade
      no-stacking
      hide-footer
      centered
      @shown="OnShowSubscriptionModal_Selection"
      @hidden="OnHideSubscriptionModal_Selection"
    >
      <template #modal-header>
        <div>
          <h2 class="modal-title" data-test-hook="select-subscription-type-step-10-header">
            <i
              class="far fa-badge-dollar colorOrange mr-1"
              data-test-hook="select-subscription-type-step-10-header-icon"
            />
            {{ $t('xChooseASub') }}
          </h2>
        </div>
      </template>
      <b-row
        v-if="monthlyPaidProduct && annualPaidProduct && canHavePaidSubscription(accountData)"
        align-h="center"
        class="mb-0 pb-0"
        no-gutters
      >
        <b-col>
          <b-card-group deck class="mx-lg-2">
            <b-card
              class="paid-subs-info-card mb-0 sub-card mx-1"
              :class="
                selectedProduct &&
                (selectedProduct.id == annualPaidProduct.id || selectedProduct.id == monthlyPaidProduct.id)
                  ? 'selected-sub-info-card'
                  : ''
              "
              body-class="subs-info-card-body"
              data-test-hook="select-subscription-type-step-10-paid-card"
            >
              <template #header>
                <div v-if="yearlyTabActive">
                  <div
                    class="annual-savings-header"
                    data-test-hook="select-subscription-type-step-10-best-value-header"
                  >
                    {{ $t('xBestValueSave') }}
                    {{ getSubscriptionSavings(annualPaidProduct, monthlyPaidProduct) }}
                    %
                  </div>
                </div>
              </template>
              <b-card-title>
                <b-row class="text-center">
                  <b-col>
                    <h1
                      v-if="yearlyTabActive"
                      class="mb-0 pb-0"
                      data-test-hook="select-subscription-type-step-10-paid-card-title"
                    >
                      {{ $t('xPremiumAnnually') }}
                    </h1>
                    <h1
                      v-else-if="monthlyTabActive"
                      class="mb-0 pb-0"
                      data-test-hook="select-subscription-type-step-10-paid-card-title"
                    >
                      {{ $t('xPremiumMonthly') }}
                    </h1>
                  </b-col>
                </b-row>
              </b-card-title>
              <b-row class="text-center float-center mt-2 subscription-toggles" align-h="center">
                <b-tabs pills class="justify-content-center">
                  <b-tab
                    :active="yearlyTabActive"
                    @click=";(monthlyTabActive = false), (yearlyTabActive = true)"
                    data-test-hook="select-subscription-type-step-10-yearly-billing-tab"
                  >
                    <template #title>
                      <i v-if="yearlyTabActive" class="fa-solid fa-check" />
                      {{ $t('xBilledAnnually') }}
                    </template>
                    <b-button
                      variant="subscription-radio"
                      class="mt-2"
                      pill
                      @click="setSelectedProduct(annualPaidProduct)"
                      :pressed="selectedProduct && selectedProduct.id == annualPaidProduct.id"
                      data-test-hook="select-subscription-type-step-10-select-yearly-button"
                    >
                      <span class="sub-radio-dot align-middle">
                        <i
                          :class="
                            selectedProduct && selectedProduct.id == annualPaidProduct.id
                              ? 'fa-solid fa-circle'
                              : 'fa-regular fa-circle'
                          "
                        />
                      </span>
                      {{ annualPaidProduct.cost | formatCurrency }}
                      <span class="text-bottom sub-premium-radio-duration">/{{ $t('xYear') }}</span>
                    </b-button>
                  </b-tab>
                  <b-tab
                    :active="monthlyTabActive"
                    @click=";(yearlyTabActive = false), (monthlyTabActive = true)"
                    data-test-hook="select-subscription-type-step-10-monthly-billing-tab"
                  >
                    <template #title>
                      <i v-if="monthlyTabActive" class="fa-solid fa-check" />
                      {{ $t('xBilledMonthly') }}
                    </template>
                    <b-button
                      variant="subscription-radio"
                      class="mt-2"
                      pill
                      @click="setSelectedProduct(monthlyPaidProduct)"
                      :pressed="selectedProduct && selectedProduct.id == monthlyPaidProduct.id"
                      data-test-hook="select-subscription-type-step-10-select-monthly-button"
                    >
                      <span class="sub-radio-dot align-middle">
                        <i
                          :class="
                            selectedProduct && selectedProduct.id == monthlyPaidProduct.id
                              ? 'fa-solid fa-circle'
                              : 'fa-regular fa-circle'
                          "
                        />
                      </span>
                      {{ monthlyPaidProduct.cost | formatCurrency }}
                      <span class="text-bottom sub-premium-radio-duration">/{{ $t('xMonth') }}</span>
                    </b-button>
                  </b-tab>
                </b-tabs>
              </b-row>
              <div class="subscriptionCardText">
                <b-row>
                  <b-col>
                    <strong data-test-hook="select-subscription-type-step-10-included-features-text">{{
                      $t('xIncludedFeatures')
                    }}</strong>
                  </b-col>
                </b-row>
                <b-row align-v="center" class="expandable-click-area" v-b-toggle.genStatusDesc>
                  <b-col cols="1" class="pr-0 text-center">
                    <i
                      class="fa-regular fa-circle-check"
                      data-test-hook="select-subscription-type-step-10-paid-gen-status-icon"
                    />
                  </b-col>
                  <b-col class="float-left" data-test-hook="select-subscription-type-step-10-paid-gen-status-text">
                    {{ $t('xGeneratorStatus') }}
                  </b-col>
                  <b-col cols="auto" class="float-right when-closed">
                    <i class="fa-solid fa-angle-down" />
                  </b-col>
                  <b-col cols="auto" class="float-right when-open">
                    <i class="fa-solid fa-angle-up" />
                  </b-col>
                </b-row>
                <b-collapse id="genStatusDesc">
                  <b-row>
                    <b-col data-test-hook="select-subscription-type-step-10-paid-gen-status-desc">
                      {{ $t('xGenStatusDesc') }}
                    </b-col>
                  </b-row>
                </b-collapse>
                <b-row align-v="center" class="expandable-click-area" v-b-toggle.manualsDesc>
                  <b-col cols="1" class="pr-0 text-center">
                    <i class="fa-regular fa-book" data-test-hook="select-subscription-type-step-10-paid-manuals-icon" />
                  </b-col>
                  <b-col class="float-left" data-test-hook="select-subscription-type-step-10-paid-manuals-text">
                    {{ $t('xManuals') }}
                  </b-col>
                  <b-col cols="auto" class="float-right when-closed">
                    <i class="fa-solid fa-angle-down" />
                  </b-col>
                  <b-col cols="auto" class="float-right when-open">
                    <i class="fa-solid fa-angle-up" />
                  </b-col>
                </b-row>
                <b-collapse id="manualsDesc">
                  <b-row>
                    <b-col data-test-hook="select-subscription-type-step-10-paid-manuals-desc">
                      {{ $t('xManualsDesc') }}
                    </b-col>
                  </b-row>
                </b-collapse>
                <b-row align-v="center" class="expandable-click-area" v-b-toggle.statusHistoryDesc>
                  <b-col cols="1" class="pr-0 text-center">
                    <i
                      class="fa-regular fa-clock-rotate-left"
                      data-test-hook="select-subscription-type-step-10-paid-status-history-icon"
                    />
                  </b-col>
                  <b-col class="float-left" data-test-hook="select-subscription-type-step-10-paid-status-history-text">
                    {{ $t('xStatusHistory') }}
                  </b-col>
                  <b-col cols="auto" class="float-right when-closed">
                    <i class="fa-solid fa-angle-down" />
                  </b-col>
                  <b-col cols="auto" class="float-right when-open">
                    <i class="fa-solid fa-angle-up" />
                  </b-col>
                </b-row>
                <b-collapse id="statusHistoryDesc">
                  <b-row>
                    <b-col data-test-hook="select-subscription-type-step-10-paid-status-history-desc">
                      {{ $t('xStatusHistoryDesc') }}
                    </b-col>
                  </b-row>
                </b-collapse>
                <b-row align-v="center" class="expandable-click-area" v-b-toggle.monthlySummaryEmailDesc>
                  <b-col cols="1" class="pr-0 text-center">
                    <i
                      class="fa-regular fa-envelope-open-text"
                      data-test-hook="select-subscription-type-step-10-paid-monthly-email-icon"
                    />
                  </b-col>
                  <b-col class="float-left" data-test-hook="select-subscription-type-step-10-paid-monthly-email-text">
                    {{ $t('xMonthlySummaryEmail') }}
                  </b-col>
                  <b-col cols="auto" class="float-right when-closed">
                    <i class="fa-solid fa-angle-down" />
                  </b-col>
                  <b-col cols="auto" class="float-right when-open">
                    <i class="fa-solid fa-angle-up" />
                  </b-col>
                </b-row>
                <b-collapse id="monthlySummaryEmailDesc">
                  <b-row>
                    <b-col data-test-hook="select-subscription-type-step-10-paid-monthly-email-desc">
                      {{ $t('xMonthlySummaryEmailDesc') }}
                    </b-col>
                  </b-row>
                </b-collapse>
                <b-row align-v="center" class="expandable-click-area" v-b-toggle.maintenanceDetailsDesc>
                  <b-col cols="1" class="pr-0 text-center">
                    <i
                      class="fa-regular fa-toolbox"
                      data-test-hook="select-subscription-type-step-10-paid-maintenance-icon"
                    />
                  </b-col>
                  <b-col class="float-left" data-test-hook="select-subscription-type-step-10-paid-maintenance-text">
                    {{ $t('xMaintenanceTracking') }}
                  </b-col>
                  <b-col cols="auto" class="float-right when-closed">
                    <i class="fa-solid fa-angle-down" />
                  </b-col>
                  <b-col cols="auto" class="float-right when-open">
                    <i class="fa-solid fa-angle-up" />
                  </b-col>
                </b-row>
                <b-collapse id="maintenanceDetailsDesc">
                  <b-row>
                    <b-col data-test-hook="select-subscription-type-step-10-paid-maintenance-desc">
                      {{ $t('xMaintenanceTrackingDesc') }}
                    </b-col>
                  </b-row>
                </b-collapse>
                <b-row align-v="center" class="expandable-click-area" v-b-toggle.exerciseOnDemandDesc>
                  <b-col cols="1" class="pr-0 text-center">
                    <i
                      class="colorOrange fa-regular fa-bolt"
                      data-test-hook="select-subscription-type-step-10-paid-demand-exercise-icon"
                    />
                  </b-col>
                  <b-col class="float-left">
                    <strong data-test-hook="select-subscription-type-step-10-paid-demand-exercise-text">{{
                      $t('xExerciseOnDemand')
                    }}</strong>
                  </b-col>
                  <b-col cols="auto" class="float-right when-closed">
                    <i class="fa-solid fa-angle-down" />
                  </b-col>
                  <b-col cols="auto" class="float-right when-open">
                    <i class="fa-solid fa-angle-up" />
                  </b-col>
                </b-row>
                <b-collapse id="exerciseOnDemandDesc">
                  <b-row>
                    <b-col data-test-hook="select-subscription-type-step-10-paid-demand-exercise-desc">
                      {{ $t('xExerciseOnDemandDesc') }}
                    </b-col>
                  </b-row>
                </b-collapse>
                <b-row align-v="center" class="expandable-click-area" v-b-toggle.realTimeAlertsDesc>
                  <b-col cols="1" class="pr-0 text-center">
                    <i
                      class="colorOrange fa-regular fa-bell"
                      data-test-hook="select-subscription-type-step-10-paid-alerts-icon"
                    />
                  </b-col>
                  <b-col class="float-left">
                    <strong data-test-hook="select-subscription-type-step-10-paid-alerts-text"
                      >{{ $t('xRealTimeAlerts') }}*</strong
                    >
                  </b-col>
                  <b-col cols="auto" class="float-right when-closed">
                    <i class="fa-solid fa-angle-down" />
                  </b-col>
                  <b-col cols="auto" class="float-right when-open">
                    <i class="fa-solid fa-angle-up" />
                  </b-col>
                </b-row>
                <b-collapse id="realTimeAlertsDesc">
                  <b-row>
                    <b-col data-test-hook="select-subscription-type-step-10-paid-alerts-desc">
                      {{ $t('xRealTimeAlertsDesc') }}
                    </b-col>
                  </b-row>
                </b-collapse>
                <b-row align-v="center" class="expandable-click-area" v-b-toggle.exerciseTimeDesc>
                  <b-col cols="1" class="pr-0 text-center">
                    <i
                      class="colorOrange fa-regular fa-clock"
                      data-test-hook="select-subscription-type-step-10-paid-exercise-time-icon"
                    />
                  </b-col>
                  <b-col class="float-left">
                    <strong data-test-hook="select-subscription-type-step-10-paid-exercise-time-text">{{
                      $t('xExerciseTime')
                    }}</strong>
                  </b-col>
                  <b-col cols="auto" class="float-right when-closed">
                    <i class="fa-solid fa-angle-down" />
                  </b-col>
                  <b-col cols="auto" class="float-right when-open">
                    <i class="fa-solid fa-angle-up" />
                  </b-col>
                </b-row>
                <b-collapse id="exerciseTimeDesc">
                  <b-row>
                    <b-col data-test-hook="select-subscription-type-step-10-paid-exercise-time-desc">
                      {{ $t('xExerciseTimeDesc') }}
                    </b-col>
                  </b-row>
                </b-collapse>
              </div>
            </b-card>
            <b-row class="d-sm-none text-center my-2">
              <b-col>
                <h4 class="mb-0 pb-0">{{ $t('xOR') }}</h4>
              </b-col>
            </b-row>
            <b-card
              v-if="freeProduct"
              class="free-sub-info-card sub-card"
              :class="selectedProduct && selectedProduct.id == freeProduct.id ? 'selected-sub-info-card' : ''"
              body-class="subs-info-card-body mx-1"
              data-test-hook="select-subscription-type-step-10-free-card"
            >
              <template #header>
                <div class="d-sm-none">
                  <div
                    class="text-center limited-features-header"
                    data-test-hook="select-subscription-type-step-10-free-limited-features-header"
                  >
                    {{ $t('xLimitedFeatures') }}
                  </div>
                </div>
              </template>
              <b-card-title>
                <b-row class="text-center">
                  <b-col>
                    <h1 class="mb-0 pb-0" data-test-hook="select-subscription-type-step-10-free-title">
                      {{ $t('xFree') }}
                    </h1>
                  </b-col>
                </b-row>
              </b-card-title>
              <b-row class="text-center mt-2 d-none d-sm-block">
                <b-col>
                  <div class="limited-features">
                    {{ $t('xLimitedFeatures') }}
                  </div>
                </b-col>
              </b-row>
              <b-row class="text-center mt-2">
                <b-col>
                  <b-button
                    variant="subscription-radio"
                    pill
                    @click="setSelectedProduct(freeProduct)"
                    :pressed="selectedProduct && selectedProduct.id == freeProduct.id"
                    data-test-hook="select-subscription-type-step-10-free-select-button"
                  >
                    <span class="sub-radio-dot align-middle">
                      <i
                        :class="
                          selectedProduct && selectedProduct.id == freeProduct.id
                            ? 'fa-solid fa-circle'
                            : 'fa-regular fa-circle'
                        "
                      />
                    </span>
                    <span class="sub-free-radio-text align-middle"> {{ $t('xFree') }}</span>
                  </b-button>
                </b-col>
              </b-row>
              <div class="subscriptionCardText">
                <b-row>
                  <b-col>
                    <strong data-test-hook="select-subscription-type-step-10-free-included-features-text">{{
                      $t('xIncludedFeatures')
                    }}</strong>
                  </b-col>
                </b-row>
                <b-row align-v="center" class="expandable-click-area">
                  <b-col cols="1" class="pr-0 text-center">
                    <i
                      class="fa-regular fa-circle-check"
                      data-test-hook="select-subscription-type-step-10-free-gen-status-icon"
                    />
                  </b-col>
                  <b-col class="float-left" data-test-hook="select-subscription-type-step-10-free-gen-status-text">
                    {{ $t('xGeneratorStatus') }}
                  </b-col>
                </b-row>
                <b-row align-v="center" class="expandable-click-area">
                  <b-col cols="1" class="pr-0 text-center">
                    <i class="fa-regular fa-book" data-test-hook="select-subscription-type-step-10-free-manuals-icon" />
                  </b-col>
                  <b-col class="float-left" data-test-hook="select-subscription-type-step-10-free-manuals-text">
                    {{ $t('xManuals') }}
                  </b-col>
                </b-row>
                <b-row align-v="center" class="expandable-click-area">
                  <b-col cols="1" class="pr-0 text-center">
                    <i
                      class="fa-regular fa-clock-rotate-left"
                      data-test-hook="select-subscription-type-step-10-free-status-history-icon"
                    />
                  </b-col>
                  <b-col class="float-left" data-test-hook="select-subscription-type-step-10-free-status-history-text">
                    {{ $t('xStatusHistory') }}
                  </b-col>
                </b-row>
                <b-row align-v="center" class="expandable-click-area">
                  <b-col cols="1" class="pr-0 text-center">
                    <i
                      class="fa-regular fa-envelope-open-text"
                      data-test-hook="select-subscription-type-step-10-free-monthly-email-icon"
                    />
                  </b-col>
                  <b-col class="float-left" data-test-hook="select-subscription-type-step-10-free-monthly-email-text">
                    {{ $t('xMonthlySummaryEmail') }}
                  </b-col>
                </b-row>
                <b-row align-v="center" class="expandable-click-area">
                  <b-col cols="1" class="pr-0 text-center">
                    <i
                      class="fa-regular fa-toolbox"
                      data-test-hook="select-subscription-type-step-10-free-maintenance-icon"
                    />
                  </b-col>
                  <b-col class="float-left" data-test-hook="select-subscription-type-step-10-free-maintenance-text">
                    {{ $t('xMaintenanceTracking') }}
                  </b-col>
                </b-row>
              </div>
            </b-card>
          </b-card-group>
        </b-col>
      </b-row>
      <b-row no-gutters class="mt-3">
        <b-col cols="6" class="float-right text-right pr-2">
          <b-button
            variant="outline-warning"
            @click="closeEnrollmentModals()"
            data-test-hook="select-subscription-type-step-10-cancel-button"
          >
            {{ $t('xCancel') }}
          </b-button>
        </b-col>
        <b-col cols="6" class="float-left pl-2">
          <b-button
            variant="warning"
            :disabled="!selectedProduct || paying"
            @click="getExistingBillingInfo()"
            data-test-hook="select-subscription-type-step-10-confirm-plan-button"
          >
            {{ $t('xConfirmPlan') }}
            <b-spinner class="ml-2" v-if="paying" />
          </b-button>
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col class="text-center">
          <p class="text-small text-gray-500">*{{ $t('xPushNotificationsAreAvailableWithInstallation') }}</p>
        </b-col>
      </b-row>
      <div v-if="showSelectSubError" class="alert alert--validation alert-danger" :show="showSelectSubError">
        {{ $t('xFailedSetUpGeneratorSubscription') }}
      </div>
    </b-modal>

    <b-modal
      id="enter-payment-info-step-11"
      size="lg"
      no-close-on-backdrop
      no-close-on-esc
      no-fade
      no-stacking
      centered
      @shown="OnShowSubscriptionModal_Purchase"
      @hidden="OnHideSubscriptionModal_Purchase"
    >
      <template #modal-header>
        <b-row align-v="center">
          <b-col cols="auto" class="pr-0">
            <b-button
              variant="icon-button"
              v-b-modal.select-subscription-type-step-10
              data-test-hook="back-button-step11-enrollment"
            >
              <i class="fas fa-chevron-left back-chevron-button" />
            </b-button>
          </b-col>
          <b-col>
            <h2 class="modal-title">
              <i class="fal fa-credit-card colorOrange mr-1" /> {{ $t('xAddAPaymentMethod') }}
            </h2>
          </b-col>
        </b-row>
      </template>
      <template #modal-footer>
        <b-button
          v-if="useExistingBillingInformation"
          variant="primary"
          block
          @click="payForProduct()"
          :disabled="!canPay()"
          data-test-hook="PayBtn"
        >
          {{ $t('xPayNow') }}
          <b-spinner class="ml-2" v-if="paying"></b-spinner>
        </b-button>
        <b-button
          v-else
          variant="outline-warning"
          block
          @click="saveAccountHolder(), (savingAccountHolder = true)"
          :disabled="savingAccountHolder"
          data-test-hook="confirm-billing-info-button-step11-enrollment"
        >
          {{ $t('xConfirmBillingInfo') }}
          <b-spinner class="ml-2" v-if="savingAccountHolder" />
        </b-button>
      </template>
      <b-alert :show="showCreditCardValidationBanner" variant="danger">
        <p>{{ $t('xExperiencedTheseErrors') }}</p>
        <ul v-for="(message, index) in errorMessages" :key="index">
          <li :id="index">
            {{ message }}
          </li>
        </ul>
      </b-alert>
      <div v-if="gettingPaymentInfo">
        <b-col class="float-center text-center">
          <b-spinner style="width: 10rem; height: 10rem;" variant="primary" label="Spinning"></b-spinner>
        </b-col>
      </div>
      <div v-else>
        <div v-if="existingPaymentInfo">
          <b-row v-if="useExistingPaymentInfo">
            <b-col>
              <h3>
                <strong>{{ $t('xCurrentCreditCardInformationOnFile') }}</strong>
                <span class="body-text-color">*</span>
              </h3>
            </b-col>
          </b-row>
          <b-row v-else>
            <b-col>
              <h3>
                <strong>{{ $t('xUpdateCreditCardInformation') }}</strong>
                <span class="body-text-color">*</span>
              </h3>
            </b-col>
          </b-row>
          <b-row>
            <b-col data-test-hook="CreditCardCheckbox">
              <b-form-checkbox class="font-weight-bold" v-model="useExistingPaymentInfo">
                {{ $t('xUseCurrentCreditCardInformation') }}
              </b-form-checkbox>
            </b-col>
          </b-row>
          <div v-if="useExistingPaymentInfo">
            <b-row class="mt-2">
              <b-col cols="12">
                <p>
                  <strong>
                    <i class="fal fa-credit-card" />
                    {{ existingPaymentInfo.cardType }} -
                    {{ existingPaymentInfo.cardSuffix }}
                  </strong>
                </p>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <p>
                  {{ $t('xExpires') }}
                  {{ existingPaymentInfo.expirationMonth + '/' + existingPaymentInfo.expirationYear }}
                </p>
              </b-col>
            </b-row>
          </div>
          <div v-else>
            <b-row>
              <b-col cols="12">
                <b-form-group id="credit-card-number">
                  <b-form-input
                    v-model="$v.creditCardNumber.$model"
                    type="text"
                    maxlength="16"
                    v-mask="'################'"
                    :placeholder="$t('xCreditCardNumber')"
                    :state="validState($v.creditCardNumber)"
                    aria-describedby="card-number-invalid"
                  ></b-form-input>
                  <b-form-invalid-feedback id="card-number-invalid">
                    {{ $t('xCCFieldValidation') }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="6" md="t6" sm="12">
                <b-form-group id="expiry-date-month">
                  <b-form-input
                    v-model="$v.expirationMonth.$model"
                    type="text"
                    :placeholder="$t('xMm')"
                    maxlength="2"
                    v-mask="'##'"
                    :state="validState($v.expirationMonth)"
                    aria-describedby="expiry-month-invalid"
                  ></b-form-input>
                  <b-form-invalid-feedback id="expiry-month-invalid">
                    {{ $t('xExpirationMonthValidation') }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col lg="6" md="6" sm="12">
                <b-form-group id="expiry-date-year">
                  <b-form-input
                    v-model="$v.expirationYear.$model"
                    type="text"
                    :placeholder="$t('xYy')"
                    maxlength="2"
                    v-mask="'##'"
                    :state="validState($v.expirationYear)"
                    aria-describedby="expiry-year-invalid"
                  ></b-form-input>
                  <b-form-invalid-feedback id="expiry-year-invalid">
                    {{ $t('xExpirationYearValidation') }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-input-group id="cvv">
                  <b-form-input
                    v-model="$v.cvv.$model"
                    type="text"
                    v-mask="'####'"
                    maxlength="4"
                    :placeholder="$t('xCvv')"
                    :state="validState($v.cvv)"
                    aria-describedby="cvv-invalid"
                  ></b-form-input>
                  <b-input-group-append>
                    <b-input-group-text>
                      <b-img :height="20" :src="require('@/assets/images/3-digit-code.svg')" />
                    </b-input-group-text>
                  </b-input-group-append>
                  <b-form-invalid-feedback id="cvv-invalid">
                    {{ $t('xCVVValidation') }}
                  </b-form-invalid-feedback>
                </b-input-group>
              </b-col>
            </b-row>
          </div>
        </div>
        <div v-else>
          <b-row>
            <b-col cols="12">
              <h3>
                <strong>{{ $t('xEnterYourCreditCardInformation') }}</strong>
              </h3>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-form-group id="credit-card-number">
                <b-form-input
                  v-model="$v.creditCardNumber.$model"
                  type="text"
                  maxlength="16"
                  v-mask="'################'"
                  :placeholder="$t('xCreditCardNumber')"
                  :state="validState($v.creditCardNumber)"
                  aria-describedby="card-number-invalid"
                ></b-form-input>
                <b-form-invalid-feedback id="card-number-invalid">
                  {{ $t('xCCFieldValidation') }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="6" md="6" sm="12">
              <b-form-group id="expiry-date-month">
                <b-form-input
                  v-model="$v.expirationMonth.$model"
                  type="text"
                  :placeholder="$t('xMm')"
                  maxlength="2"
                  v-mask="'##'"
                  :state="validState($v.expirationMonth)"
                  aria-describedby="expiry-month-invalid"
                ></b-form-input>
                <b-form-invalid-feedback id="expiry-month-invalid">
                  {{ $t('xExpirationMonthValidation') }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col lg="6" md="6" sm="12">
              <b-form-group id="expiry-date-year">
                <b-form-input
                  v-model="$v.expirationYear.$model"
                  type="text"
                  :placeholder="$t('xYy')"
                  maxlength="2"
                  v-mask="'##'"
                  :state="validState($v.expirationYear)"
                  aria-describedby="expiry-year-invalid"
                ></b-form-input>
                <b-form-invalid-feedback id="expiry-year-invalid">
                  {{ $t('xExpirationYearValidation') }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-input-group id="cvv">
                <b-form-input
                  v-model="$v.cvv.$model"
                  type="text"
                  v-mask="'####'"
                  maxlength="4"
                  :placeholder="$t('xCvv')"
                  :state="validState($v.cvv)"
                  aria-describedby="cvv-invalid"
                ></b-form-input>
                <b-input-group-append>
                  <b-input-group-text>
                    <b-img :height="20" :src="require('@/assets/images/3-digit-code.svg')" id="ccCvvImage" />
                  </b-input-group-text>
                </b-input-group-append>
                <b-form-invalid-feedback id="cvv-invalid">
                  {{ $t('xCVVValidation') }}
                </b-form-invalid-feedback>
              </b-input-group>
            </b-col>
          </b-row>
        </div>
        <div class="mt-4" v-if="accountData">
          <b-row class="mb-2">
            <b-col>
              <b-form-checkbox
                class="font-weight-bold"
                v-model="useExistingBillingInformation"
                :disabled="savingAccountHolder"
              >
                <p>
                  <strong>{{ $t('xUseCurrentBillingInfo') }}</strong>
                </p>
              </b-form-checkbox>
            </b-col>
          </b-row>
          <b-row v-if="useExistingBillingInformation">
            <b-col cols="12">{{ accountData.firstName }} {{ accountData.lastName }}</b-col>
            <b-col cols="12">
              {{ DisplayAddress(accountData.address) }}
            </b-col>
          </b-row>
          <div v-if="!useExistingBillingInformation">
            <b-row>
              <b-col cols="12">
                <b-form-group id="firstName-group">
                  <b-form-input id="firstName" v-model="accountData.firstName" type="text"></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-form-group id="lastName-group">
                  <b-form-input id="lastName" v-model="accountData.lastName" type="text"></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <TypeAheadAddress
                  ref="typeAheadAddressBillingAddress"
                  :currentAddress="accountData.address"
                  @newAddress="setNewBillingAddress"
                />
              </b-col>
            </b-row>
          </div>
        </div>
        <div v-if="selectedProduct">
          <b-row class="my-3">
            <b-col cols="12">
              <div class="subscription-cost">
                <h3 class="mb-0">{{ $t('xTotalPayment') }}: {{ selectedProduct.cost.toFixed(2) | formatCurrency }}</h3>
                <p>
                  <i>
                    {{ $t('xBilled') }}
                    <span v-if="selectedProduct.durationInMonths == 12">{{ $t('xYearly') }}</span>
                    <span v-else>{{ $t('xMonthly') }}</span>
                  </i>
                </p>
              </div>
            </b-col>
          </b-row>
        </div>
        <b-row>
          <b-col>
            <p>
              <i class="asterisk-text">*{{ $t('xUpdatingCCApplyActiveSubscriptions') }} </i>
            </p>
          </b-col>
        </b-row>
        <b-row class="mt-3">
          <b-col>
            <p>
              <i>
                {{ $t('xThisSubscriptionWillAutoRenew') }}
              </i>
            </p>
          </b-col>
        </b-row>
      </div>
      <div
        v-if="showSavingAccountHolderError"
        role="alert"
        class="alert alert--validation alert-danger"
        :show="showSavingAccountHolderError"
      >
        {{ $t('xFailedToUpdateBillingNameAndAddress') }}
      </div>
      <div
        v-if="showPayForProductError"
        role="alert"
        class="alert alert--validation alert-danger"
        :show="showPayForProductError"
      >
        {{ payForProductErrorMessage }}
      </div>
    </b-modal>

    <Modal modalId="subscription-success">
      <ModalHeader :title="$t('xCongratulations') + '!'" icon="colorOrange fal fa-badge-dollar">
        <button
          class="btn close"
          @click="closeEnrollmentModals(), trackAnalytics('subscription-success-modal-cancel-button-clicked')"
          data-test-hook="modalXCloseBtn"
        >
          <i class="fas fa-times" />
        </button>
      </ModalHeader>
      <ModalBody>
        <div data-test-hook="modalBodyText1">
          {{ $t('xYourGeneratorIsSubscribedForRemoteMonitoring') }}
        </div>
        <div v-if="selectedFuelType == 'LP'">
          <div style="font-size:1rem;font-weight:600" class="mt-3" data-test-hook="modalBodyText2">
            {{ $t('xYourGeneratorIsRunningOnPropane') }}
          </div>
          <div data-test-hook="modalBodyText3">
            {{ $t('xFeelEvenMoreSecureKnowing') }}&nbsp;<b>{{ $t('xFree') }}</b
            >!
          </div>
          <div class="row ml-1">
            <div
              class="mt-3 btn"
              @click="beginTankEnrollmentFlow()"
              style="font-size:1rem;font-weight:600; padding-top:0px; border-top-width:0px; padding-left:0px; padding-right:0px; margin-top:10px;"
            >
              <span class="colorRed fas fa-plus-circle mr-1" style="font-size: 1rem;"></span>
              <span style="text-transform:none !important" data-test-hook="modalAddATankBtn">
                {{ $t('xAddATankMonitor') }}
              </span>
            </div>
            <div class="ml-2" style="padding-top:17px">
              {{ $t('xOR') | lowercase }}
            </div>
            <div class="ml-2" style="padding-top:16px" data-test-hook="modalLearnAboutTanksBtn">
              <a
                href="https://www.generac.com/for-homeowners/mobile-link/propane-monitoring"
                target="_blank"
                style="text-decoration: underline"
                @click="trackAnalytics('subscription-success-modal-tank-monitor-info-clicked')"
              >
                {{ $t('xLearnMoreAboutTankMonitors') }}<i class="ml-1 fas fa-external-link"></i
              ></a>
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <div
          class="btn btn-primary"
          @click="trackAnalytics('subscription-success-modal-view-gen-details-clicked'), goToNewGenerator()"
          data-test-hook="GenDetailsBtn"
        >
          {{ $t('xViewGenDetails') }}
        </div>
      </ModalFooter>
    </Modal>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import TypeAheadAddress from '@/components/TypeAheadAddress'
import * as Constants from '@/constants'
import { required, requiredIf, minLength, maxLength, email, numeric } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'

const monthInRange = (value, vm) => value >= 1 && value <= 12

export default {
  props: ['apparatusId', 'modelNumber'],
  components: { TypeAheadAddress },
  mixins: [validationMixin],
  data() {
    return {
      userEnteredSerialNumber: null,
      generatorName: null,
      useAccountAddress: true,
      generatorAddress: null,
      deviceType: null,
      macAddress: null,
      searchLabel: null,
      dualGenSerialNumber: null,
      validatingDevice: false,
      validatedDeviceInfo: null,
      newApparatusInfo: null,
      enrollingDevice: false,
      validatingSN: false,
      enrollingApparatus: false,
      validateSNError: null,
      enrollDeviceError: null,
      gettingSubscriptions: false,
      annualPaidProduct: null,
      monthlyPaidProduct: null,
      freeProduct: null,
      selectedProduct: null,
      planIsSelected: false,
      selectedProductCostPreview: null,
      gettingPaymentInfo: false,
      paying: false,
      existingPaymentInfo: null,
      useExistingPaymentInfo: true,
      creditCardNumber: null,
      expirationMonth: null,
      expirationYear: null,
      cvv: null,
      showCreditCardValidationBanner: false,
      accountData: null,
      useExistingBillingInformation: true,
      editingAddress: false,
      savingAccountHolder: false,
      apparatusModelNumber: null,
      apparatusDescription: null,
      addressSaveDisabled: false,
      errorMessages: [],
      registeringSerialNumbers: false,
      sns: [],
      snToAdd: null,
      transferSwitchFailureMessage: null,
      activeSubscriptionModal: null,
      yearlyTabActive: true,
      monthlyTabActive: false,
      showSavingAccountHolderError: false,
      showPayForProductError: false,
      payForProductErrorMessage: null,
      showSelectSubError: false,
      showGetSubscriptionInfoError: false,
      getSubscriptionInfoErrorMessage: null,
      showSerialNumberValidationError: false,
      serialNumberValidationMessage: null,
      showEnrollApparatusError: false,
      showRegistrationFailedError: false,
      showValidateDeviceError: false,
      validateDeviceErrorMessage: null,
      showEnrollDeviceError: false,
      enrollDeviceErrorMessage: null,
      shortDeviceId: null,
      selectedFuelType: null,
      selectedFuelTypeName: null,
      selectedFuelTypeNumber: null,
      fuelOptions: [
        { value: 'LP', text: 'Liquid Propane (LP)' },
        { value: 'NaturalGas', text: 'Natural Gas' },
        { value: 'Diesel', text: 'Diesel' }
      ]
    }
  },
  validations: {
    cvv: {
      required,
      minLength: minLength(3),
      maxLength: maxLength(4),
      numeric
    },
    expirationMonth: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(2),
      numeric,
      monthInRange
    },
    expirationYear: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(2),
      numeric
    },
    creditCardNumber: {
      required,
      minLength: minLength(15),
      maxLength: maxLength(16),
      numeric
    }
  },
  computed: {
    ...mapState({
      accountProfile: state => state.account.accountProfile,
      freeFeatures: state => state.application.freeFeatures,
      paidFeatures: state => state.application.paidFeatures,
      isImpersonating: state => state.auth.isImpersonating,
      currentAppConfig: state => state.application.currentAppConfig
    }),
    freeWifi() {
      return Constants.FreeWifiEthProductId
    },
    g3FeatureFlag() {
      return Constants.G3DeviceFeatureFlag
    },
    hsbG3DeviceType() {
      return Constants.HsbG3DeviceType
    }
  },
  methods: {
    setFuelType() {
      if (this.selectedFuelType == 'LP') {
        this.selectedFuelTypeName = 'Liquid Propane (LP)'
        this.selectedFuelTypeNumber = 0
      } else if (this.selectedFuelType == 'NaturalGas') {
        this.selectedFuelTypeName = 'Natural Gas'
        this.selectedFuelTypeNumber = 1
      } else {
        this.selectedFuelTypeName = 'Diesel'
        this.selectedFuelTypeNumber = 2
      }
    },
    logContinueWithSetup() {
      let appId = null
      if (this.apparatusId) {
        appId = this.apparatusId
      } else {
        appId = this.newApparatusInfo.apparatusId
      }
      this.$appInsights.trackEvent({
        name: 'user-continuing-with-enrollment',
        properties: {
          page: 'dashboard',
          modal: 'no-wifi-module-modal',
          apparatusId: appId,
          organizationId: this.accountProfile.organizationId,
          userId: this.accountProfile.userId,
          userId: this.accountProfile.userId,
          modelNumber: this.apparatusModelNumber
        }
      })
    },
    logComeBackLater() {
      let appId = null
      if (this.apparatusId) {
        appId = this.apparatusId
      } else {
        appId = this.newApparatusInfo.apparatusId
      }
      this.$appInsights.trackEvent({
        name: 'user-coming-back-later',
        properties: {
          page: 'dashboard',
          modal: 'no-wifi-module-modal',
          apparatusId: appId,
          organizationId: this.accountProfile.organizationId,
          userId: this.accountProfile.userId,
          modelNumber: this.apparatusModelNumber
        }
      })
    },
    enterDeviceInformation() {
      if (
        this.deviceType == 'wifi' &&
        this.apparatusModelNumber &&
        this.genWithoutWifiModule(this.apparatusModelNumber)
      ) {
        let appId = null
        if (this.apparatusId) {
          appId = this.apparatusId
        } else {
          appId = this.newApparatusInfo.apparatusId
        }
        this.$appInsights.trackEvent({
          name: 'modal-for-gen-with-no-wifi-module',
          properties: {
            page: 'dashboard',
            modal: 'no-wifi-module-modal',
            apparatusId: appId,
            organizationId: this.accountProfile.organizationId,
            userId: this.accountProfile.userId,
            modelNumber: this.apparatusModelNumber
          }
        })
        this.$bvModal.show('no-wifi-module-modal')
      } else {
        this.$bvModal.show('enter-device-info-step-7')
      }
    },
    canPay() {
      if (this.existingPaymentInfo && this.useExistingPaymentInfo) {
        return true
      } else if (!this.useExistingPaymentInfo || !this.existingPaymentInfo) {
        if (!this.creditCardNumber || !this.cvv || !this.expirationMonth || !this.expirationYear) {
          return false
        }
        if (!this.$v.$anyError && !this.paying) {
          return true
        } else return false
      } else return false
    },
    goToNewGenerator() {
      let appId = null
      if (this.apparatusId) {
        appId = this.apparatusId
      } else {
        appId = this.newApparatusInfo.apparatusId
      }
      this.$store.dispatch('setDefaultPreferences', { apparatusId: appId, productId: this.selectedProduct.id })

      this.$router.push({
        path: `/details/${appId}`
      })
    },
    saveAccountHolder() {
      this.savingAccountHolder = true
      this.$store
        .dispatch('updateAccountHolder', {
          firstName: this.accountData.firstName,
          lastName: this.accountData.lastName,
          organizationAddress: {
            ...this.accountData.address,
            organizationId: this.accountData.organizationId
          }
        })
        .then(response => {
          this.showSavingAccountHolderError = false
          if (!this.isImpersonating) this.$store.dispatch('getUser')
          else this.$store.dispatch('getImpersonatedUser')
          this.$store.dispatch('getAccountProfile')
          this.timeTheAccountChange()
        })
        .catch(error => {
          this.showSavingAccountHolderError = true
          this.savingAccountHolder = false
        })
    },
    timeTheAccountChange() {
      setTimeout(
        function() {
          this.savingAccountHolder = false
          this.useExistingBillingInformation = true
        }.bind(this),
        5000
      )
    },
    payForProduct() {
      this.paying = true
      let appId = null
      if (this.apparatusId) {
        appId = this.apparatusId
      } else {
        appId = this.newApparatusInfo.apparatusId
      }
      if (this.existingPaymentInfo && this.useExistingPaymentInfo) {
        this.$store
          .dispatch('changeBillingAccountProduct', {
            organizationId: this.accountProfile.organizationId,
            apparatusId: appId,
            productId: this.selectedProduct.id
          })
          .then(response => {
            this.showPayForProductError = false
            this.payForProductErrorMessage = null
            this.paying = false
            this.$bvModal.hide('enter-payment-info-step-11')
            this.openModal('subscription-success')
          })
          .catch(error => {
            let failureMessage = this.$t('xFailedSetUpGeneratorSubscription')
            if (error.response?.data?.message) {
              try {
                let messageAsJson = JSON.parse(error.response.data.message.replace(/\\"/g, '"'))
                if (messageAsJson?.gatewayResponse?.message) {
                  failureMessage = messageAsJson.gatewayResponse.message
                }
              } catch (err) {}
            }
            this.paying = false
            this.payForProductErrorMessage = failureMessage
            this.showPayForProductError = true
          })
      } else if (this.existingPaymentInfo && !this.useExistingPaymentInfo) {
        //delete/cancel/remove existing payment info,
        //add new user entered credit card info to account,
        //add apparatus to the selected product, then proceed to success page (if all calls succeed)
        let removePaymentModel = {
          organizationId: this.accountProfile.organizationId,
          paymentId: this.existingPaymentInfo.id
        }
        let addPaymentModel = {
          organizationId: this.accountProfile.organizationId,
          cardNumber: this.creditCardNumber,
          cvv: this.cvv,
          expirationMonth: this.expirationMonth,
          expirationYear: '20' + this.expirationYear
        }
        let changeSubscriptionMode = {
          organizationId: this.accountProfile.organizationId,
          apparatusId: appId,
          productId: this.selectedProduct.id
        }
        this.$store
          .dispatch('addBillingAccountPaymentMethod', addPaymentModel)
          .then(response => {
            this.$store
              .dispatch('removeBillingAccountPaymentMethod', removePaymentModel)
              .then(response => {
                this.$store
                  .dispatch('changeBillingAccountProduct', changeSubscriptionMode)
                  .then(response => {
                    this.paying = false
                    this.showPayForProductError = false
                    this.payForProductErrorMessage = null
                    this.$bvModal.hide('enter-payment-info-step-11')
                    this.openModal('subscription-success')
                  })
                  .catch(error => {
                    this.paying = false
                    this.payForProductErrorMessage = this.$t('xFailedSetUpGeneratorSubscription')
                    this.showPayForProductError = true
                  })
              })
              .catch(error => {
                this.paying = false
                this.payForProductErrorMessage = this.$t('xFailedToRemoveExistingPaymentInformation')
                this.showPayForProductError = true
              })
          })
          .catch(error => {
            this.paying = false
            this.gettingPaymentInfo = false
            this.errorMessages = []
            if (error.response.data.errors) {
              if (error.response.data.errors.CardNumber || error.response.data.errors.cardNumber) {
                this.showCreditCardValidationBanner = true
                this.errorMessages.push(this.$t('xCCIsInvalid'))
              }
              if (error.response.data.errors.Cvv || error.response.data.errors.cvv) {
                this.showCreditCardValidationBanner = true
                this.errorMessages.push(this.$t('xCvvInvalid'))
              }
              if (error.response.data.errors.ExpirationMonth || error.response.data.errors.expirationMonth) {
                this.showCreditCardValidationBanner = true
                this.errorMessages.push(this.$t('xExpirationMonthInvalid'))
              }
              if (error.response.data.errors.ExpirationYear || error.response.data.errors.expirationYear) {
                this.showCreditCardValidationBanner = true
                this.errorMessages.push(this.$t('xExpirationYearInvalid'))
              }
              if (error.response.data.errors.billing || error.response.data.errors.Billing) {
                this.errorMessages.push(
                  error.response.data.errors.billing
                    ? error.response.data.errors.billing
                    : error.data.response.errors.Billing
                )
                this.showCreditCardValidationBanner = true
              }
            } else {
              this.payForProductErrorMessage = this.$t('xFailedToAddPaymentMethod')
              this.showPayForProductError = true
            }
          })
      } else {
        // no existing payment info, add new cc and subscribe
        let addPaymentModel = {
          organizationId: this.accountProfile.organizationId,
          cardNumber: this.creditCardNumber,
          cvv: this.cvv,
          expirationMonth: this.expirationMonth,
          expirationYear: '20' + this.expirationYear
        }
        let changeSubscriptionMode = {
          organizationId: this.accountProfile.organizationId,
          apparatusId: appId,
          productId: this.selectedProduct.id
        }
        this.$store
          .dispatch('addBillingAccountPaymentMethod', addPaymentModel)
          .then(response => {
            this.$store
              .dispatch('changeBillingAccountProduct', changeSubscriptionMode)
              .then(response => {
                this.paying = false
                this.showPayForProductError = false
                this.payForProductErrorMessage = null
                this.$bvModal.hide('enter-payment-info-step-11')
                this.openModal('subscription-success')
              })
              .catch(error => {
                this.paying = false
                this.payForProductErrorMessage = this.$t('xFailedSetUpGeneratorSubscription')
                this.showPayForProductError = true
              })
          })
          .catch(error => {
            this.paying = false
            this.gettingPaymentInfo = false
            this.errorMessages = []
            if (error.response.data.errors) {
              if (error.response.data.errors.CardNumber || error.response.data.errors.cardNumber) {
                this.showCreditCardValidationBanner = true
                this.errorMessages.push(this.$t('xCCIsInvalid'))
              }
              if (error.response.data.errors.Cvv || error.response.data.errors.cvv) {
                this.showCreditCardValidationBanner = true
                this.errorMessages.push(this.$t('xCvvInvalid'))
              }
              if (error.response.data.errors.ExpirationMonth || error.response.data.errors.expirationMonth) {
                this.showCreditCardValidationBanner = true
                this.errorMessages.push(this.$t('xExpirationMonthInvalid'))
              }
              if (error.response.data.errors.ExpirationYear || error.response.data.errors.expirationYear) {
                this.showCreditCardValidationBanner = true
                this.errorMessages.push(this.$t('xExpirationYearInvalid'))
              }
              if (error.response.data.errors.billing || error.response.data.errors.Billing) {
                this.errorMessages.push(
                  error.response.data.errors.billing
                    ? error.response.data.errors.billing
                    : error.data.response.errors.Billing
                )
                this.showCreditCardValidationBanner = true
              }
            } else {
              this.payForProductErrorMessage = this.$t('xFailedToAddPaymentMethod')
              this.showPayForProductError = true
            }
          })
      }
    },
    getExistingBillingInfo() {
      if (this.selectedProduct && this.selectedProduct.cost > 0) {
        this.gettingPaymentInfo = true
        this.$bvModal.show('enter-payment-info-step-11')
        this.$store
          .dispatch('getActivePaymentMethod', this.accountProfile.organizationId)
          .then(response => {
            this.existingPaymentInfo = response.data
            this.gettingPaymentInfo = false
          })
          .catch(error => {
            this.existingPaymentInfo = null
            this.gettingPaymentInfo = false
          })
      } else if (this.selectedProduct && this.selectedProduct.cost == 0) {
        this.paying = true
        let appId = null
        if (this.apparatusId) {
          appId = this.apparatusId
        } else {
          appId = this.newApparatusInfo.apparatusId
        }
        this.$store
          .dispatch('changeBillingAccountProduct', {
            organizationId: this.accountProfile.organizationId,
            apparatusId: appId,
            productId: this.selectedProduct.id
          })
          .then(response => {
            this.paying = false
            this.showSelectSubError = false
            this.$bvModal.hide('enter-payment-info-step-11')
            this.closeModal('select-subscription-type-step-10')
            this.openModal('subscription-success')
          })
          .catch(error => {
            this.paying = false
            this.showSelectSubError = true
          })
      }
    },
    getSubscriptionSavings(annual, monthly) {
      if (annual && monthly) return Math.round(((monthly.cost * 12 - annual.cost) / (monthly.cost * 12)) * 100)
      return 0
    },
    setSelectedProduct(product) {
      this.selectedProduct = product
    },
    getSubscriptionInformation() {
      this.gettingSubscriptions = true
      let appId = null
      if (this.apparatusId) {
        appId = this.apparatusId
      } else if (this.newApparatusInfo && this.newApparatusInfo.apparatusId) {
        appId = this.newApparatusInfo.apparatusId
      }
      if (!appId) {
        this.showGetSubscriptionInfoError = true
        this.getSubscriptionInfoErrorMessage =
          this.$t('xFailedToGetSubscriptionProducts') + '. ' + this.$t('xNotEnoughInformationProvided')
        this.gettingSubscriptions = false
        return
      } else {
        this.$store
          .dispatch('getSubscriptionProductByApparatusId', appId)
          .then(response => {
            let subscriptionProducts = response.data
            this.annualPaidProduct = subscriptionProducts.filter(x => x.id.includes(Constants.ANNUAL) && x.cost > 0)[0]
            this.setSelectedProduct(this.annualPaidProduct)
            this.monthlyPaidProduct = subscriptionProducts.filter(
              x => x.id.includes(Constants.MONTHLY) && x.durationInMonths == 1
            )[0]
            this.freeProduct = subscriptionProducts.filter(x => x.id.includes(Constants.FREE))[0]
            if (
              this.accountProfile?.address?.country &&
              Constants.CountriesGetPaidSubs.includes(this.accountProfile.address.country.toUpperCase())
            ) {
              this.gettingSubscriptions = false
              this.$bvModal.show('select-subscription-type-step-10')
            } else {
              this.setupFreeSubscription()
            }
          })
          .catch(error => {
            this.gettingSubscriptions = false
            this.showGetSubscriptionInfoError = true
            this.getSubscriptionInfoErrorMessage = this.$t('xFailedToGetSubscriptionProducts')
          })
      }
    },
    validateSerialNumber() {
      if (!this.userEnteredSerialNumber) {
        this.serialNumberValidationMessage = this.$t('xYouMustEnterAValidSerialNumberToContinue')
        this.showSerialNumberValidationError = true
        return false
      }
      this.validatingSN = true
      this.$store
        .dispatch('ValidateSerialNumber', this.userEnteredSerialNumber)
        .then(response => {
          this.validatingSN = false
          this.showSerialNumberValidationError = false
          this.serialNumberValidationMessage = null
          if (response.data.success) {
            this.apparatusModelNumber = response.data.modelNumber
            this.apparatusDescription = response.data.description
            this.$bvModal.show('gen-name-step-2')
          } else {
            this.serialNumberValidationMessage = response.data.message
            this.showSerialNumberValidationError = true
          }
        })
        .catch(error => {
          this.validatingSN = false
          this.validateSNError = error.response.data
          this.serialNumberValidationMessage = this.$t('xDisabledOwnerOrgNotDealerManagedError')
          this.showSerialNumberValidationError = true
        })
    },
    enrollApparatus() {
      this.enrollingApparatus = true
      let address = this.useAccountAddress ? this.accountProfile.address : this.generatorAddress
      this.$store
        .dispatch('EnrollApparatus', {
          organizationId: this.accountProfile.organizationId,
          serialNumber: this.userEnteredSerialNumber,
          apparatusName: this.generatorName,
          apparatusAddress: address,
          apparatusType: 0,
          apparatusModelNumber: this.apparatusModelNumber,
          apparatusDescription: this.apparatusDescription,
          fuelType: this.selectedFuelTypeNumber
        })
        .then(response => {
          this.enrollingApparatus = false
          this.newApparatusInfo = response.data
          if (!response.data.registrationSuccess) {
            this.showRegistrationFailedError = true
            this.$appInsights.trackEvent({
              name: 'enrollment-registration-failure',
              properties: {
                orgId:
                  this.accountProfile && this.accountProfile.organizationId ? this.accountProfile.organizationId : null,
                userId: this.accountProfile.userId,
                apparatusId: response.data.apparatusId,
                serialNumber: this.userEnteredSerialNumber
              }
            })
          }
          if (response.data.hasValidDevice) {
            this.deviceType = response.data.device.deviceType
            if (this.deviceType == 'wifi') this.macAddress = response.data.device.deviceId
            else if (this.deviceType == Constants.HsbG3DeviceType) this.shortDeviceId = response.data.device.deviceId
            else this.searchLabel = response.data.device.deviceId
          }
          this.$bvModal.show('generator-enrollment-success')
        })
        .catch(error => {
          this.enrollingApparatus = false
          this.enrollDeviceError = error.response.data
          this.showEnrollApparatusError = true
        })
    },
    validateDevice() {
      let appId = null
      if (this.apparatusId) {
        //enrolling a device on an apparatus that has been previously enrolled
        appId = this.apparatusId
      } else if (
        this.newApparatusInfo &&
        this.newApparatusInfo.apparatusId &&
        this.newApparatusInfo.enrollmentSuccess
      ) {
        //enrolling a device on an apparatus that the user just enrolled
        appId = this.newApparatusInfo.apparatusId
      }
      if (appId) {
        this.validatingDevice = true
        this.$store
          .dispatch('ValidateDevice', {
            deviceType: this.deviceType,
            macAddress: this.macAddress,
            searchLabel: this.searchLabel,
            shortDeviceId: this.shortDeviceId,
            apparatusId: appId
          })
          .then(response => {
            this.validatedDeviceInfo = response.data
            this.validatingDevice = false
            if (this.validatedDeviceInfo && this.validatedDeviceInfo.isValid) {
              this.$bvModal.show('enroll-device-step-8')
            }
          })
          .catch(error => {
            this.validatingDevice = false
            if (error && error.response) {
              this.validatedDeviceInfo = error.response.data
              this.validateDeviceErrorMessage = this.validatedDeviceInfo.message
              this.showValidateDeviceError = true
            }
          })
      }
    },
    enrollApparatusDevice() {
      this.enrollingDevice = true
      if (!this.validatedDeviceInfo) {
        this.enrollDeviceErrorMessage = this.$t('xInsufficientDeviceInformation')
        this.showEnrollDeviceError = true
        this.enrollingDevice = false
        return
      }
      let appId = null
      if (this.apparatusId) {
        //enrolling a device on an apparatus that has been previously enrolled
        appId = this.apparatusId
      } else if (
        this.newApparatusInfo &&
        this.newApparatusInfo.apparatusId &&
        this.newApparatusInfo.enrollmentSuccess
      ) {
        //enrolling a device on an apparatus that the user just enrolled
        appId = this.newApparatusInfo.apparatusId
      }
      if (appId) {
        this.$store
          .dispatch('AddApparatusDevice', {
            apparatusId: appId,
            deviceType: this.validatedDeviceInfo.deviceType,
            deviceId: this.validatedDeviceInfo.deviceId
          })
          .then(response => {
            this.enrollingDevice = false
            this.$bvModal.show('device-enrollment-success-step-9')
          })
          .catch(error => {
            this.enrollingDevice = false
            this.enrollDeviceErrorMessage = this.$t('xFailedToAddDevice')
            this.showEnrollDeviceError = true
          })
      }
    },
    deviceInfoEntered() {
      if (this.deviceType == 'wifi') {
        if (this.macAddress) return false
        else return true
      } else if (this.deviceType == Constants.HsbG3DeviceType) {
        if (this.shortDeviceId && this.shortDeviceId.length == 9) return false
        else return true
      } else {
        if (this.searchLabel && this.searchLabel.length == 10) return false
        else return true
      }
    },
    selectedDeviceType(type) {
      if (this.deviceType && type == this.deviceType) {
        return 'selectedType'
      } else return
    },
    setDeviceType(type) {
      this.deviceType = type
    },
    setCurrentAddress(newAddress) {
      this.generatorAddress = newAddress
      if (this.$refs.typeAheadAddressGenAddress.$v.$anyError) {
        this.addressSaveDisabled = true
      } else {
        this.addressSaveDisabled = false
      }
    },
    setNewBillingAddress(newAddress) {
      this.accountData.address = newAddress
      if (this.$refs.typeAheadAddressBillingAddress.$v.$anyError) {
        this.addressSaveDisabled = true
      } else {
        this.addressSaveDisabled = false
      }
    },
    addSnToRegistrationList() {
      this.sns.push(this.snToAdd)
      this.snToAdd = null
    },
    registerTransferSwitches() {
      this.registeringSerialNumbers = true
      if (this.snToAdd && this.snToAdd.length >= 7) this.sns.push(this.snToAdd)
      this.$store
        .dispatch('registerSerialNumbers', {
          organizationId: this.accountData.organizationId,
          serialNumbers: this.sns,
          apparatusAddress: this.generatorAddress
        })
        .then(response => {
          this.$bvModal.show('transfer-serial-number-registration-success')
        })
        .catch(error => {
          this.transferSwitchFailureMessage = error.response.data
          this.$bvModal.show('transfer-serial-number-registration-failure')
        })
        .finally(() => {
          this.registeringSerialNumbers = false
        })
    },
    canRegisterSns() {
      if (this.sns.length <= 0 && (!this.snToAdd || this.snToAdd.length < 7)) return false
      else if (this.sns.length > 0 || (this.snToAdd && this.snToAdd.length >= 7)) return true
    },
    async setupFreeSubscription() {
      this.setSelectedProduct(this.freeProduct)
      let appId = null
      if (this.apparatusId) {
        appId = this.apparatusId
      } else {
        appId = this.newApparatusInfo.apparatusId
      }
      await this.$store
        .dispatch('changeBillingAccountProduct', {
          organizationId: this.accountProfile.organizationId,
          apparatusId: appId,
          productId: this.selectedProduct.id
        })
        .then(response => {
          this.paying = false
          this.showSelectSubError = false
          this.gettingSubscriptions = false
          this.$bvModal.hide('device-enrollment-success-step-9')
          this.openModal('subscription-success')
        })
        .catch(error => {
          this.paying = false
          this.showSelectSubError = true
        })
    },
    closeEnrollmentModals(enrollmentSection = null) {
      if (enrollmentSection) {
        this.$appInsights.trackEvent({
          name: 'apparatus-enrollment-cancel',
          properties: { type: 'generator', section: enrollmentSection }
        })
      }

      //reset all info entered in enrollment modals
      this.userEnteredSerialNumber = null
      this.generatorName = null
      this.useAccountAddress = true
      this.generatorAddress = null
      this.deviceType = null
      this.macAddress = null
      this.searchLabel = null
      this.shortDeviceId = null
      this.validatingDevice = false
      this.validatedDeviceInfo = null
      this.newApparatusInfo = null
      this.enrollingDevice = false
      this.validatingSN = false
      this.enrollingApparatus = false
      this.validateSNError = null
      this.enrollDeviceError = null
      this.gettingSubscriptions = false
      this.annualPaidProduct = null
      this.monthlyPaidProduct = null
      this.freeProduct = null
      this.selectedProductCostPreview = null
      this.selectedProduct = null
      this.planIsSelected = false
      this.gettingPaymentInfo = false
      this.paying = false
      this.existingPaymentInfo = null
      this.useExistingPaymentInfo = true
      this.creditCardNumber = null
      this.expirationMonth = null
      this.expirationYear = null
      this.cvv = null
      this.showCreditCardValidationBanner = false
      this.errorMessages = []
      this.$v.$reset()
      this.useExistingBillingInformation = true
      this.editingAddress = false
      this.registeringSerialNumbers = false
      this.sns = []
      this.snToAdd = null
      this.transferSwitchFailureMessage = null
      this.yearlyTabActive = true
      this.monthlyTabActive = false
      this.showSavingAccountHolderError = false
      this.showPayForProductError = false
      this.payForProductErrorMessage = null
      this.showSelectSubError = false
      this.showGetSubscriptionInfoError = false
      this.getSubscriptionInfoErrorMessage = null
      this.showSerialNumberValidationError = false
      this.serialNumberValidationMessage = null
      this.showEnrollApparatusError = false
      this.showRegistrationFailedError = false
      this.showValidateDeviceError = false
      this.validateDeviceErrorMessage = null
      this.showEnrollDeviceError = false
      this.enrollDeviceErrorMessage = null
      this.selectedFuelType = null
      //close all enrollment modals:
      this.$bvModal.hide('serial-number-step-1')
      this.$bvModal.hide('gen-name-step-2')
      this.$bvModal.hide('generator-fuel-type-step-3')
      this.$bvModal.hide('generator-address-step-4')
      this.$bvModal.hide('confirm-gen-enrollment-step-5')
      this.$bvModal.hide('generator-enrollment-success')
      this.$bvModal.hide('confirm-existing-device')
      this.$bvModal.hide('select-device-type-step-6')
      this.$bvModal.hide('no-wifi-module-modal')
      this.$bvModal.hide('enter-device-info-step-7')
      this.$bvModal.hide('enroll-device-step-8')
      this.$bvModal.hide('device-enrollment-success-step-9')
      this.$bvModal.hide('generator-serial-number-help')
      this.$bvModal.hide('mac-address-help')
      this.$bvModal.hide('tether-serial-number-help')
      this.$bvModal.hide('select-subscription-type-step-10')
      this.$bvModal.hide('enter-payment-info-step-11')
      this.closeModal('subscription-success')
      this.$store.dispatch('getApparatuses')
    },
    // NOTE - this functions are used to track/monitor when the user (somehow) exists the enrollment process before completing the subscription application
    // portion of the enrollment
    // NOTE - for more information about the beforeunload event, please refer to this: https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event#usage_notes
    onLeaveBeforeSubscriptionApplication() {
      this.CleanupEventListeners()
      this.$appInsights.trackEvent({
        name: 'apparatus-enrollment-cancel',
        properties: { type: 'generator', section: this.activeSubscriptionModal }
      })
    },
    OnShowSubscriptionModal_Selection() {
      this.activeSubscriptionModal = 'subscription-selection'
      window.addEventListener('beforeunload', this.onLeaveBeforeSubscriptionApplication)
    },
    OnHideSubscriptionModal_Selection() {
      if (this.selectedProduct && this.selectedProduct.cost == 0) {
        this.CleanupEventListeners()
      }
    },
    OnShowSubscriptionModal_Purchase() {
      this.activeSubscriptionModal = 'subscription-purchase'
    },
    OnHideSubscriptionModal_Purchase() {
      this.CleanupEventListeners()
    },
    CleanupEventListeners() {
      window.removeEventListener('beforeunload', this.onLeaveBeforeSubscriptionApplication)
    },
    trackAnalytics(eventName) {
      this.trackAppInsightsEvent(eventName, {
        page: 'EnrollmentModals',
        organizationId: this.accountProfile.organizationId,
        userId: this.accountProfile.userId
      })
    },
    beginTankEnrollmentFlow() {
      this.trackAnalytics('subscription-success-modal-begin-tank-enrollment-button-clicked')
      this.closeModal('subscription-success')
      this.$bvModal.show('tank-enrollment-step-1')
    }
  },
  watch: {
    modelNumber: function(newVal, oldVal) {
      if (newVal) {
        this.apparatusModelNumber = this.modelNumber
      }
    },
    accountProfile: function(newVal, oldVal) {
      if (newVal) this.accountData = _.cloneDeep(newVal)
    }
  },
  mounted() {
    if (!this.freeFeatures) this.$store.dispatch('getFreeFeatures')
    if (!this.paidFeatures) this.$store.dispatch('getPaidFeatures')
    if (this.modelNumber) {
      this.apparatusModelNumber = this.modelNumber
    }
    this.generatorAddress = this.accountProfile.address
    this.accountData = _.cloneDeep(this.accountProfile)
  }
}
</script>
<style lang="scss">
#select-subscription-type-step-10 {
  @media (min-width: 576px) {
    .modal-lg {
      max-width: calc(100% - 3rem) !important;
    }
  }
  @media (min-width: 800px) {
    .modal-lg {
      max-width: 800px !important;
    }
  }
}
.subscription-toggles {
  .nav-pills {
    justify-content: center;
  }
  .nav-link {
    border: solid 2px var(--blue-700) !important;
    color: var(--carbon-900);
    height: 44px;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .nav-item:first-of-type .nav-link {
    border-radius: 9999px 0 0 9999px;
  }

  .nav-item:last-of-type .nav-link {
    border-radius: 0 9999px 9999px 0;
  }

  .active i {
    display: inline-block !important;
  }
}
</style>
<style scoped lang="scss">
.card {
  &:hover {
    background-color: var(--carbon-100);
    color: var(--carbon-900);
    border: solid 1px var(--carbon-500) !important;
  }
}
h1 {
  font-weight: normal;
  line-height: 1.15;
}

.expandable-click-area {
  height: 40px !important;
}
.subscriptionCardText {
  font-size: 16px !important;
}

.sub-options-row {
  border-top: 2px solid var(--brand-400);
}
.annual-sub-col {
  border-right: 2px solid var(--brand-400);
}
.sub-radio-dot {
  font-size: 0.875rem !important;
}
.paid-subs-info-card {
  border: 2px solid var(--carbon-300) !important;
  &:hover {
    border: 2px solid var(--brand-400) !important;
    background-color: transparent;
  }
}
.subs-info-card-body {
  padding: 0px 25px 25px !important;
}
.free-sub-info-card {
  border: 2px solid var(--carbon-300) !important;
  &:hover {
    border: 2px solid var(--brand-400) !important;
    background-color: transparent;
  }
}
.selected-sub-info-card {
  border: 2px solid var(--brand-400) !important;
}
.card-header {
  align-items: stretch;
  padding: 0;
  border-bottom: 0;
  height: 40px;
  margin-bottom: 10px;
}

.card-title {
  margin-bottom: 0;
}
.limited-features-header {
  height: 40px;
  background-color: var(--carbon-200);
  display: flex;
  justify-content: center;
  font-weight: bold;
  align-items: center;
}

.limited-features {
  background-color: var(--carbon-200);
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 41px;
  font-weight: bold;
}

.annual-savings-header {
  background-color: var(--brand-400) !important;
  font-size: 18px !important;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--carbon-900) !important;
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
  height: 40px;
  width: 100%;
}
.free-card-title {
  padding-top: 5px;
}
.large-icon-header {
  background-color: #eeeeee;
}
.subscription-card {
  max-width: 360px;
}
.selected {
  border: 1px solid var(--brand-400);
  background: white;
  box-shadow: 0px 0px 10px var(--brand-400);
}
.device-type-img {
  max-height: 80px;
}
.generator-success-img {
  max-height: 200px;
}
.sn-tag-img {
  max-height: 200px;
}
.selectedType {
  background-color: var(--blue-100);
  color: var(--carbon-900);
  border: solid 1px var(--blue-700) !important;
}
.inProgress {
  color: lightgray;
}
.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}
.sub-premium-radio-duration {
  font-size: 16px !important;
  text-transform: lowercase !important;
}
.sub-free-radio-text {
  font-size: 16px !important;
}

.btn-subscription-selector {
  font-size: 1.125em !important;
  box-shadow: none !important;
  border: 2px solid var(--blue-700);
  background-color: transparent;
  color: var(--carbon-900);
  text-transform: none;
}
.btn-subscription-selector.disabled {
  background-color: #e5e7eb;
  color: #4f5b6c !important;
  border: none;
}
.btn-subscription-selector:not(:disabled):not(.disabled):focus:active {
  color: var(--white);
  background-color: var(--blue-700);
}
.btn-subscription-selector.active {
  color: var(--white);
  background-color: var(--blue-700);
}

.btn-subscription-radio {
  color: var(--carbon-900);
  font-size: 30px;
  font-weight: bold;
  text-transform: uppercase;
  padding: 0.375rem 2rem;
  border-color: var(--brand-600);
  border-width: 2px;
  margin-bottom: 10px;
}
.btn-subscription-radio:not(:disabled):not(.disabled):hover {
  background-color: rgba(255, 107, 0, 0.2);
}
.btn-subscription-radio:not(:disabled):not(.disabled):focus:active {
  box-shadow: 0px 0px 8px rgba(255, 107, 0, 0.25) !important;
  border-width: 2px;
}
.btn-subscription-radio.active {
  box-shadow: 0 0 0 0.2rem rgba(255, 107, 0, 0.25) !important;
}

.subscription-feature-description {
  font-family: 'Roboto Condensed', sans-serif !important;
  font-size: 16px !important;
  color: var(--carbon-900);
}
</style>
