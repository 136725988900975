var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass: "card pending-change-card no-border",
          attrs: { "data-test-hook": "PendingPlanChangeCard" }
        },
        [
          _c("div", { staticClass: "card-body ml-3" }, [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col", staticStyle: { "padding-left": "0px" } },
                [
                  _vm.pendingCancellation()
                    ? _c(
                        "h4",
                        {
                          staticClass: "pending-title-color",
                          attrs: { "data-test-hook": "NewPlanCardTitle" }
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("xPendingCancellation")) + " "
                          )
                        ]
                      )
                    : _c(
                        "h4",
                        {
                          staticClass: "pending-title-color",
                          attrs: { "data-test-hook": "NewPlanCardTitle" }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("xPendingSubscriptionChanges")) +
                              " "
                          )
                        ]
                      )
                ]
              )
            ]),
            _vm.pendingFreeToPaidOrPaidToPaid()
              ? _c("div", [
                  _c("div", { staticClass: "row align-items-center" }, [
                    _c("div", { staticClass: "cols-auto" }, [
                      _c("div", { staticClass: "row mb-0 pb-0" }, [
                        _c(
                          "div",
                          {
                            staticClass: "col pending-labels ml-3 mb-0 pb-0",
                            attrs: { "data-test-hook": "NewPlanTypeLabel" }
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("xNewPlanType")) + " ")]
                        )
                      ]),
                      _c("div", { staticClass: "row mb-2" }, [
                        _c("div", { staticClass: "col ml-3" }, [
                          _c(
                            "strong",
                            { attrs: { "data-test-hook": "NewPlanType" } },
                            [
                              _vm._v(
                                _vm._s(_vm.pendingSubscription.productName)
                              )
                            ]
                          )
                        ])
                      ])
                    ])
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "row mb-0 pb-0",
                      attrs: { "data-test-hook": "NewPlanStartDateLabel" }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "col pending-labels mb-0 pb-0" },
                        [_vm._v(" " + _vm._s(_vm.$t("xPlanStartDate")) + " ")]
                      )
                    ]
                  ),
                  _c("div", { staticClass: "row mb-2" }, [
                    _c("div", { staticClass: "col" }, [
                      _c(
                        "strong",
                        { attrs: { "data-test-hook": "NewPlanStartDate" } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm
                                  .$moment(
                                    _vm.pendingSubscription.effectiveDate
                                  )
                                  .format(_vm.$t("xShortDate"))
                              ) +
                              " "
                          )
                        ]
                      )
                    ])
                  ]),
                  _c("div", { staticClass: "row mb-0 pb-0" }, [
                    _c(
                      "div",
                      {
                        staticClass: "col pending-labels mb-0 pb-0",
                        attrs: { "data-test-hook": "NewPlanAmountLabel" }
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("xNewPlanAmount")) + " ")]
                    )
                  ]),
                  _c("div", { staticClass: "row mb-2" }, [
                    _c("div", { staticClass: "col" }, [
                      _c(
                        "strong",
                        { attrs: { "data-test-hook": "NewPlanCost" } },
                        [
                          _vm.pendingSubscription.durationInMonths == 12
                            ? _c(
                                "span",
                                {
                                  attrs: {
                                    "data-test-hook": "NewPlanCostInterval"
                                  }
                                },
                                [_vm._v(" " + _vm._s(_vm.$t("xYearly")) + " ")]
                              )
                            : _c(
                                "span",
                                {
                                  attrs: {
                                    "data-test-hook": "NewPlanCostInterval"
                                  }
                                },
                                [_vm._v(" " + _vm._s(_vm.$t("xMonthly")) + " ")]
                              ),
                          _vm._v(
                            " - " +
                              _vm._s(
                                _vm._f("formatCurrency")(
                                  _vm.pendingSubscription.cost.toFixed(2)
                                )
                              ) +
                              " "
                          )
                        ]
                      )
                    ])
                  ]),
                  _c("div", { staticClass: "cols-auto float-left ml-0" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-outline-warning",
                        attrs: {
                          type: "button",
                          "data-test-hook":
                            "revertChangesButtonPendingPlanChangeCard"
                        },
                        on: {
                          click: function($event) {
                            return _vm.openModal(
                              "confirm-revert-pending-subscription"
                            )
                          }
                        }
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("xRevertChanges")) + " ")]
                    )
                  ])
                ])
              : _vm.pendingPaidToFree()
              ? _c("div", [
                  _c("div", { staticClass: "row align-items-center" }, [
                    _c("div", { staticClass: "cols-auto" }, [
                      _c("div", { staticClass: "row mb-0 pb-0" }, [
                        _c(
                          "div",
                          {
                            staticClass: "col pending-labels mb-0 pb-0 ml-3",
                            attrs: { "data-test-hook": "NewPlanTypeLabel" }
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("xNewPlanType")) + " ")]
                        )
                      ]),
                      _c("div", { staticClass: "row mb-2 ml-0" }, [
                        _c("div", { staticClass: "col" }, [
                          _c(
                            "strong",
                            { attrs: { "data-test-hook": "NewPlanType" } },
                            [
                              _vm._v(
                                _vm._s(_vm.pendingSubscription.productName)
                              )
                            ]
                          )
                        ])
                      ])
                    ])
                  ]),
                  _c("div", { staticClass: "row mb-0 pb-0" }, [
                    _c(
                      "div",
                      {
                        staticClass: "col pending-labels mb-0 pb-0",
                        attrs: { "data-test-hook": "NewPlanStartDateLabel" }
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("xPlanStartDate")) + " ")]
                    )
                  ]),
                  _c("div", { staticClass: "row mb-2" }, [
                    _c("div", { staticClass: "col" }, [
                      _c(
                        "strong",
                        { attrs: { "data-test-hook": "NewPlanStartDate" } },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm
                                  .$moment(
                                    _vm.pendingSubscription.effectiveDate
                                  )
                                  .format(_vm.$t("xShortDate"))
                              ) +
                              " "
                          )
                        ]
                      )
                    ])
                  ]),
                  _c("div", { staticClass: "cols-auto float-left" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-outline-warning",
                        attrs: {
                          type: "button",
                          "data-test-hook":
                            "revertChangesButtonPendingPlanChangeCard"
                        },
                        on: {
                          click: function($event) {
                            return _vm.openModal(
                              "confirm-revert-pending-subscription"
                            )
                          }
                        }
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("xRevertChanges")) + " ")]
                    )
                  ])
                ])
              : _vm.pendingCancellation()
              ? _c("div", [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "cols-auto" }, [
                      _c("div", { staticClass: "row mb-0 pb-0" }, [
                        _c(
                          "div",
                          {
                            staticClass: "col pending-labels mb-0 pb-0",
                            attrs: { "data-test-hook": "PlanCancelDateLabel" }
                          },
                          [
                            _vm.pendingCancellation()
                              ? _c("p", [
                                  _vm._v(_vm._s(_vm.$t("xSubscriptionEndsOn")))
                                ])
                              : _c("p", [
                                  _vm._v(_vm._s(_vm.$t("xEffectiveDate")))
                                ])
                          ]
                        )
                      ]),
                      _c("div", { staticClass: "row mb-2" }, [
                        _c("div", { staticClass: "col" }, [
                          _c(
                            "strong",
                            { attrs: { "data-test-hook": "PlanCancelDate" } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm
                                      .$moment(
                                        _vm.currentSubscription.nextBillingDate
                                      )
                                      .format(_vm.$t("xShortDate"))
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ])
                      ]),
                      _c("div", { staticClass: "cols-auto float-left ml-0" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-outline-warning",
                            attrs: {
                              type: "button",
                              "data-test-hook":
                                "revertChangesButtonPendingPlanChangeCard"
                            },
                            on: {
                              click: function($event) {
                                return _vm.openModal(
                                  "confirm-revert-pending-subscription"
                                )
                              }
                            }
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.$t("xUndoCancellation")) + " "
                            )
                          ]
                        )
                      ])
                    ])
                  ])
                ])
              : _vm._e()
          ])
        ]
      ),
      _c(
        "Modal",
        {
          attrs: {
            modalId: "confirm-revert-pending-subscription",
            size: "modal-lg"
          }
        },
        [
          _c(
            "ModalHeader",
            {
              attrs: {
                title: _vm.$t("xAreYouSure"),
                icon: "fas fa-exclamation-triangle colorOrange mr-1",
                "data-test-hook": "modalTitleTextRevertChangesModal"
              }
            },
            [
              _c("div", { staticClass: "cols-auto float-right" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-icon-button btn-icon-button--gray",
                    attrs: {
                      type: "button",
                      "data-test-hook": "xCloseButtonRevertChangesModal"
                    },
                    on: {
                      click: function($event) {
                        return _vm.closeModal(
                          "confirm-revert-pending-subscription"
                        )
                      }
                    }
                  },
                  [_c("i", { staticClass: "fas fa-times colorOrange" })]
                )
              ])
            ]
          ),
          _c("ModalBody", [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                {
                  staticClass: "col",
                  attrs: { "data-test-hook": "modalBodyText1" }
                },
                [
                  _vm._v(" " + _vm._s(_vm.$t("xThisActionWillRevert")) + " "),
                  _c("strong", [
                    _vm._v(_vm._s(_vm.currentSubscription.productName) + ".")
                  ])
                ]
              )
            ]),
            _c("div", { staticClass: "row mt-3" }, [
              _c(
                "div",
                {
                  staticClass: "col",
                  attrs: { "data-test-hook": "modalBodyText2" }
                },
                [
                  _vm._v(" " + _vm._s(_vm.$t("xYourPlanWillRenew")) + " "),
                  _c("strong", [
                    _vm._v(
                      _vm._s(
                        _vm
                          .$moment(_vm.currentSubscription.nextBillingDate)
                          .format(_vm.$t("xShortDate"))
                      )
                    )
                  ]),
                  _vm._v(" " + _vm._s(_vm.$t("xInTheAmountOf")) + " "),
                  _c("strong", [
                    _vm._v(
                      _vm._s(
                        _vm._f("formatCurrency")(
                          _vm.currentSubscription.cost.toFixed(2)
                        )
                      ) + "."
                    )
                  ])
                ]
              )
            ])
          ]),
          _c("ModalFooter", [
            _c(
              "button",
              {
                staticClass: "btn btn-outline-warning",
                attrs: {
                  type: "button",
                  "data-test-hook": "noCancelButtonRevertChangesModal"
                },
                on: {
                  click: function($event) {
                    return _vm.closeModal("confirm-revert-pending-subscription")
                  }
                }
              },
              [_vm._v(" " + _vm._s(_vm.$t("xNoCancel")) + " ")]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-warning",
                attrs: {
                  type: "button",
                  disabled: _vm.revertingPendingChanges,
                  "data-test-hook": "yesImSureButtonRevertChangesModal"
                },
                on: {
                  click: function($event) {
                    return _vm.revertPendingChanges()
                  }
                }
              },
              [
                !_vm.revertingPendingChanges
                  ? _c("span", [
                      _vm._v(" " + _vm._s(_vm.$t("xYesImSure")) + " ")
                    ])
                  : _c("span", [_c("span", { staticClass: "spinner-border" })])
              ]
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }