var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "Modal",
        {
          attrs: { modalId: "homeowner-change-step-1" + _vm.value.apparatusId }
        },
        [
          _c("span", { attrs: { tabindex: "0" } }),
          _c("ModalHeader", { attrs: { title: _vm.$t("xHomeownerChange") } }),
          _c("ModalBody", [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("xHasHomeownerInfoBeenVerifiedInSAP")) +
                    " "
                )
              ])
            ])
          ]),
          _c("ModalFooter", [
            _c(
              "button",
              {
                staticClass: "btn btn-outline-warning",
                on: {
                  click: function($event) {
                    return _vm.closeModals()
                  }
                }
              },
              [_vm._v(_vm._s(_vm.$t("xNo")))]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-warning",
                on: {
                  click: function($event) {
                    return _vm.homeownerChangeStep2Modal()
                  }
                }
              },
              [_vm._v(_vm._s(_vm.$t("xYes")))]
            )
          ])
        ],
        1
      ),
      _c(
        "Modal",
        {
          attrs: {
            modalId: "homeowner-change-step-2" + _vm.value.apparatusId,
            size: "modal-xl"
          }
        },
        [
          _c("span", { attrs: { tabindex: "0" } }),
          _c("ModalHeader", { attrs: { title: _vm.$t("xHomeownerChange") } }),
          _c("ModalBody", [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col" }, [
                _c("div", { staticClass: "card shadow" }, [
                  _c("div", { staticClass: "card-body" }, [
                    _c("h4", { staticClass: "card-title" }, [
                      _vm._v("Previous Homeowner Information")
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "tile-label col-12" }, [
                        _vm._v(
                          _vm._s(_vm._f("formatLabelNoTranslation")("Name"))
                        )
                      ]),
                      _c("div", { staticClass: "tile-content col-12" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.value.ownerInformation.firstName +
                                " " +
                                _vm.value.ownerInformation.lastName || "N/A"
                            ) +
                            " "
                        )
                      ])
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "tile-label col-12" }, [
                        _vm._v(
                          _vm._s(
                            _vm._f("formatLabelNoTranslation")("Phone Number")
                          )
                        )
                      ]),
                      _c("div", { staticClass: "tile-content col-12" }, [
                        _vm._v(
                          _vm._s(_vm.value.ownerInformation.phones[0] || "N/A")
                        )
                      ])
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "tile-label col-12" }, [
                        _vm._v(
                          _vm._s(
                            _vm._f("formatLabelNoTranslation")("Email Address")
                          )
                        )
                      ]),
                      _c("div", { staticClass: "tile-content col-12" }, [
                        _vm._v(
                          _vm._s(_vm.value.ownerInformation.emails[0] || "N/A")
                        )
                      ])
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "tile-label col-12" }, [
                        _vm._v(
                          _vm._s(
                            _vm._f("formatLabelNoTranslation")("Owner Address")
                          )
                        )
                      ]),
                      _c("div", { staticClass: "tile-content col-12" }, [
                        _vm._v(
                          _vm._s(_vm.DisplayAddress(_vm.value.ownerAddress))
                        )
                      ])
                    ])
                  ])
                ])
              ])
            ]),
            _c("div", { staticClass: "row mt-3" }, [
              _c("div", { staticClass: "col" }, [
                _c("div", { staticClass: "card shadow" }, [
                  _c("div", { staticClass: "card-body" }, [
                    _c("h4", { staticClass: "card-title" }, [
                      _vm._v("Previous Homeowner's Subscription Information")
                    ]),
                    _c("div", [
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "tile-label col-12" }, [
                          _c("span", { staticClass: "tile-label" }, [
                            _vm._v(
                              _vm._s(
                                _vm._f("formatLabelNoTranslation")(
                                  "Subscription"
                                )
                              )
                            )
                          ]),
                          _vm.currentSubscription &&
                          _vm.currentSubscription.activeProduct &&
                          _vm.currentSubscription.activeProduct[0]
                            ? _c(
                                "span",
                                {
                                  staticClass: "tile-content",
                                  class: _vm.getSubscriptionClass()
                                },
                                [
                                  _c("strong", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.currentSubscription
                                            .activeProduct[0].productName
                                        )
                                    )
                                  ])
                                ]
                              )
                            : _vm._e()
                        ])
                      ]),
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "tile-label col-12" }, [
                          _c("span", { staticClass: "tile-label" }, [
                            _vm._v(
                              _vm._s(
                                _vm._f("formatLabelNoTranslation")("Cost")
                              ) + " "
                            )
                          ]),
                          _vm.currentSubscription &&
                          _vm.currentSubscription.activeProduct &&
                          _vm.currentSubscription.activeProduct[0]
                            ? _c("span", { staticClass: "tile-content" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("formatCurrency")(
                                        _vm.currentSubscription.activeProduct[0].cost.toFixed(
                                          2
                                        )
                                      )
                                    ) +
                                    " "
                                )
                              ])
                            : _vm._e()
                        ])
                      ]),
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "tile-label col-12" }, [
                          _c("span", { staticClass: "tile-label" }, [
                            _vm._v(
                              _vm._s(
                                _vm._f("formatLabelNoTranslation")("Start Date")
                              ) + " "
                            )
                          ]),
                          _vm.currentSubscription &&
                          _vm.currentSubscription.activeProduct &&
                          _vm.currentSubscription.activeProduct[0]
                            ? _c("span", { staticClass: "tile-content" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.currentSubscription.activeProduct[0]
                                        .startDate
                                    )
                                )
                              ])
                            : _vm._e()
                        ])
                      ]),
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "tile-label col-12" }, [
                          _c("span", { staticClass: "tile-label" }, [
                            _vm._v(
                              _vm._s(
                                _vm._f("formatLabelNoTranslation")(
                                  "Next Billing Date"
                                )
                              ) + " "
                            )
                          ]),
                          _vm.currentSubscription &&
                          _vm.currentSubscription.activeProduct &&
                          _vm.currentSubscription.activeProduct[0]
                            ? _c("span", { staticClass: "tile-content" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.currentSubscription.activeProduct[0]
                                        .nextBillingDate
                                    )
                                )
                              ])
                            : _vm._e()
                        ])
                      ])
                    ])
                  ])
                ])
              ])
            ]),
            _c("div", { staticClass: "row mt-3" }, [
              _c("div", { staticClass: "col" }, [
                _vm._v(
                  " Are you sure you want to remove this generator from the above owner and cancel the above subscription? "
                ),
                _c("strong", [
                  _vm._v(
                    "Note that, once removed, the generator will not be able to be re-added to the previous owner."
                  )
                ]),
                _vm._v(
                  " previous owner will also not receive credit for any time remaining on the subscription. "
                )
              ])
            ])
          ]),
          _c("ModalFooter", [
            _c(
              "button",
              {
                staticClass: "btn btn-outline-warning",
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    return _vm.closeModals()
                  }
                }
              },
              [_vm._v(_vm._s(_vm.$t("xNo")))]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-warning",
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    return _vm.removeApparatus()
                  }
                }
              },
              [_vm._v(_vm._s(_vm.$t("xYes")))]
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }