var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-list-group",
        { attrs: { flush: "" } },
        [
          _vm.hasAccountMessages() &&
          _vm.documentsStatementsPageEnabled(_vm.currentAppConfig)
            ? _c(
                "b-list-group-item",
                {
                  staticClass: "py-3 hoverPointer",
                  class: _vm.getPreviewClass(_vm.messages.accountMessages[0]),
                  attrs: { "data-test-hook": "msgPreviewAcctMsgsBtn" },
                  on: {
                    click: function($event) {
                      return _vm.viewMessageDetails(
                        _vm.messages.accountMessages[0].category
                      )
                    }
                  }
                },
                [
                  _c(
                    "b-row",
                    { attrs: { "align-v": "center", "no-gutters": "" } },
                    [
                      _c(
                        "b-col",
                        [
                          _c(
                            "b-row",
                            { staticClass: "preview-header" },
                            [
                              _c("b-col", [
                                _c("i", {
                                  staticClass: "fas fa-user-circle",
                                  attrs: {
                                    "data-test-hook":
                                      "msgPreviewAcctMsgsBtnIcon"
                                  }
                                }),
                                _c(
                                  "span",
                                  {
                                    staticClass: "pl-1",
                                    attrs: {
                                      "data-test-hook":
                                        "msgPreviewAcctMsgsBtnText"
                                    }
                                  },
                                  [_vm._v(_vm._s(_vm.$t("xAccountMessages")))]
                                ),
                                _c("i", {
                                  staticClass:
                                    "fa fa-circle colorOrange text-small ml-1",
                                  class: _vm.showNewMessages(
                                    _vm.messages.accountMessages[0]
                                  ),
                                  attrs: {
                                    "data-test-hook":
                                      "msgPreviewUnreadAcctMsgsCircle"
                                  }
                                })
                              ])
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c("b-col", { staticClass: "pt-2" }, [
                                _c(
                                  "span",
                                  {
                                    attrs: {
                                      "data-test-hook":
                                        "msgPreviewAcctMsgsCountValue"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.messages.accountMessages.length
                                      )
                                    )
                                  ]
                                ),
                                _vm.messages.accountMessages.length > 1
                                  ? _c(
                                      "span",
                                      {
                                        attrs: {
                                          "data-test-hook":
                                            "msgPreviewAcctMsgsText"
                                        }
                                      },
                                      [_vm._v("Messages")]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        attrs: {
                                          "data-test-hook":
                                            "msgPreviewAcctMsgText"
                                        }
                                      },
                                      [_vm._v("Message")]
                                    )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm.documentsStatementsPageEnabled(_vm.currentAppConfig)
            ? _c(
                "b-list-group-item",
                { staticClass: "pl-2 pr-0" },
                [
                  _c(
                    "b-row",
                    { staticClass: "pl-3", attrs: { "align-v": "center" } },
                    [
                      _c(
                        "b-col",
                        [
                          _c(
                            "b-row",
                            { staticClass: "preview-header" },
                            [
                              _c(
                                "b-col",
                                { staticClass: "no-unread-messages-color" },
                                [
                                  _c("i", {
                                    staticClass:
                                      "fas fa-user-circle preview-header-label",
                                    attrs: {
                                      "data-test-hook": "msgPreviewAcctMsgsIcon"
                                    }
                                  }),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "pl-1 preview-header-label",
                                      attrs: {
                                        "data-test-hook":
                                          "msgPreviewAcctMsgsText"
                                      }
                                    },
                                    [_vm._v(_vm._s(_vm.$t("xAccountMessages")))]
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            { staticClass: "mt-2 preview-header" },
                            [
                              _c(
                                "b-col",
                                [
                                  _c("p", [
                                    _c(
                                      "i",
                                      {
                                        attrs: {
                                          "data-test-hook":
                                            "msgPreviewAcctMsgsNoUnreadMsgsText"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("xNoUnreadMessages"))
                                        )
                                      ]
                                    )
                                  ]),
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "py-0 mt-2",
                                      attrs: {
                                        variant: "link",
                                        to: {
                                          name: "accountSettings",
                                          params: { statementTabActive: true }
                                        },
                                        "data-test-hook":
                                          "msgPreviewAcctMsgsViewStmtsBtn"
                                      }
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "preview-header-label",
                                          attrs: {
                                            "data-test-hook":
                                              "msgPreviewAcctMsgsViewStmtsBtnText"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t("xViewStatements"))
                                          )
                                        ]
                                      ),
                                      _c("i", {
                                        staticClass: "fas fa-arrow-right ml-1",
                                        attrs: {
                                          "data-test-hook":
                                            "msgPreviewAcctMsgsViewStmtsBtnIcon"
                                        }
                                      })
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.hasAppUpdateMessages()
            ? _c(
                "b-list-group-item",
                {
                  staticClass: "py-3 hoverPointer",
                  class: _vm.getPreviewClass(_vm.messages.appUpdates[0]),
                  attrs: { "data-test-hook": "msgPreviewAppUpdsBtn" },
                  on: {
                    click: function($event) {
                      return _vm.viewMessageDetails(
                        _vm.messages.appUpdates[0].category
                      )
                    }
                  }
                },
                [
                  _c(
                    "b-row",
                    { attrs: { "align-v": "center", "no-gutters": "" } },
                    [
                      _c(
                        "b-col",
                        [
                          _c(
                            "b-row",
                            { staticClass: "preview-header" },
                            [
                              _c("b-col", [
                                _c("i", {
                                  staticClass: "fas fa-newspaper",
                                  attrs: {
                                    "data-test-hook": "msgPreviewAppUpdsBtnIcon"
                                  }
                                }),
                                _c(
                                  "span",
                                  {
                                    attrs: {
                                      "data-test-hook":
                                        "msgPreviewAppUpdsBtnText"
                                    }
                                  },
                                  [_vm._v(_vm._s(_vm.$t("xInbox")))]
                                ),
                                _c("i", {
                                  staticClass:
                                    "fa fa-circle colorOrange text-small ml-1",
                                  class: _vm.showNewMessages(
                                    _vm.messages.appUpdates[0]
                                  ),
                                  attrs: {
                                    "data-test-hook": "msgPreviewAppUpdsCircle"
                                  }
                                }),
                                _vm.messages.appUpdates[0].createdDate
                                  ? _c("span")
                                  : _c("span", [
                                      _c("i", [
                                        _vm._v(
                                          _vm._s(_vm.$t("xNoUnreadMessages"))
                                        )
                                      ])
                                    ])
                              ])
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c("b-col", { staticClass: "pt-2" }, [
                                _c(
                                  "span",
                                  {
                                    attrs: {
                                      "data-test-hook":
                                        "msgPreviewAppUpdsCountValue"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.messages.appUpdates.length)
                                    )
                                  ]
                                ),
                                _vm.messages.appUpdates.length > 1
                                  ? _c(
                                      "span",
                                      {
                                        attrs: {
                                          "data-test-hook":
                                            "msgPreviewAppUpdsText"
                                        }
                                      },
                                      [_vm._v("Messages")]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        attrs: {
                                          "data-test-hook":
                                            "msgPreviewAppUpdText"
                                        }
                                      },
                                      [_vm._v("Message")]
                                    )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _c(
                "b-list-group-item",
                { staticClass: "py-3" },
                [
                  _c(
                    "b-row",
                    { attrs: { "align-v": "center", "no-gutters": "" } },
                    [
                      _c(
                        "b-col",
                        [
                          _c(
                            "b-row",
                            { staticClass: "preview-header" },
                            [
                              _c(
                                "b-col",
                                { staticClass: "no-unread-messages-color" },
                                [
                                  _c("i", {
                                    staticClass:
                                      "fas fa-newspaper preview-header-label",
                                    attrs: {
                                      "data-test-hook": "msgPreviewAppUpdsIcon"
                                    }
                                  }),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "pl-1 preview-header-label",
                                      attrs: {
                                        "data-test-hook":
                                          "msgPreviewAppUpdsText"
                                      }
                                    },
                                    [_vm._v(_vm._s(_vm.$t("xInbox")))]
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            { staticClass: "mt-2 preview-header" },
                            [
                              _c(
                                "b-col",
                                [
                                  _c(
                                    "p",
                                    {
                                      attrs: {
                                        "data-test-hook":
                                          "msgPreviewNoAppUpdsText"
                                      }
                                    },
                                    [
                                      _c("i", [
                                        _vm._v(
                                          _vm._s(_vm.$t("xNoUnreadMessages"))
                                        )
                                      ])
                                    ]
                                  ),
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "py-0 mt-2",
                                      attrs: {
                                        variant: "link",
                                        href:
                                          "https://support.generac.com/mobilelink",
                                        target: "_blank",
                                        "data-test-hook":
                                          "msgPreviewSupportCenterBtn"
                                      }
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "preview-header-label",
                                          attrs: {
                                            "data-test-hook":
                                              "msgPreviewSupportCenterBtnText"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t("xSupportCenter"))
                                          )
                                        ]
                                      ),
                                      _c("i", {
                                        staticClass: "fas fa-arrow-right ml-1",
                                        attrs: {
                                          "data-test-hook":
                                            "msgPreviewSupportCenterBtnIcon"
                                        }
                                      })
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
        ],
        1
      ),
      _vm.messagesToDelete
        ? _c("DeleteMessagesModal", {
            attrs: { messages: _vm.messagesToDelete },
            on: { clearMessages: _vm.clearMessageDetails }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }