<template>
  <div>
    <Modal modalId="cancel-paid-sub-step-1" size="modal-lg" data-test-hook="modalTitle">
      <ModalHeader :title="$t('xCancelSubscription')" icon="far fa-ban colorOrange mr-1">
        <button
          class="btn-icon-button--gray btn-icon-button"
          @click="closeModals()"
          data-test-hook="x-button-step1-cancel-sub-modal"
        >
          <i class="fas fa-times" data-test-hook="modalCloseIcon" />
        </button>
      </ModalHeader>
      <ModalBody>
        <div v-if="currentSubscription[0].productId == dealerManaged">
          <div class="row">
            <div class="col">
              <span data-test-hook="subModalDealerManagedSubText">
                {{ $t('xYourGeneratorHasADealerManagedSubscription') }}
              </span>
            </div>
          </div>
        </div>
        <div v-else-if="isTankType()" class="mr-4">
          <div class="row">
            <div class="col" data-test-hook="modalBodyText1">
              {{ $t('xIfYouCancelYourSubscription') }}
            </div>
          </div>
          <div class="row">
            <ul>
              <li class="mt-2" data-test-hook="modalBodyBullet1">
                {{ $t('xRemoteMonitoringViewTheStatusTank') }}
              </li>
              <li class="mt-2" data-test-hook="modalBodyBullet2">
                {{ $t('xViewTankDetails') }}
              </li>
              <li class="mt-2" data-test-hook="modalBodyBullet3">
                {{ $t('xReceiveNotificationAboutTanks') }}
              </li>
              <li class="mt-2" data-test-hook="modalBodyBullet4">
                {{ $t('xViewTankOnEcobee') }}
              </li>
            </ul>
          </div>
          <div class="row mt-3" data-test-hook="modalBodyText2">
            <div class="col">
              {{ $t('xWeHopeThatYouWillReconsiderTank') }}
            </div>
          </div>
          <div class="row mt-3" data-test-hook="modalBodyText3">
            <div class="col">
              {{ $t('xAreYouSureYouWishToCancel') }}
            </div>
          </div>
        </div>
        <div v-else class="mr-4">
          <div class="row">
            <div class="col" data-test-hook="modalBodyText1">
              {{ $t('xIfYouCancelYourSubscription') }}
            </div>
          </div>
          <div class="row">
            <ul>
              <li class="mt-2" data-test-hook="modalBodyBullet1">
                {{ $t('xGeneratorStatusIntuitiveAtAGlance') }}
              </li>
              <li class="mt-2" data-test-hook="modalBodyBullet2">
                {{ $t('xManualsGetAccessToEvenMore') }}
              </li>
              <li class="mt-2" data-test-hook="modalBodyBullet3">
                {{ $t('xStatusHistoryQuicklyAccess') }}
              </li>
              <li class="mt-2" data-test-hook="modalBodyBullet4">
                {{ $t('xMonthlyEmailSummarySaveTime') }}
              </li>
              <li class="mt-2" data-test-hook="modalBodyBullet5">
                {{ $t('xMaintenanceTrackingKeepTrackOf') }}
              </li>
              <li class="mt-2" v-if="!cancellingFreeSubscription()" data-test-hook="modalBodyBullet6">
                {{ $t('xRealTimeAlertsAchieveInstant') }}
              </li>
              <li class="mt-2" v-if="!cancellingFreeSubscription()" data-test-hook="modalBodyBullet7">
                {{ $t('xExerciseSettingsEasilyModify') }}
              </li>
              <li class="mt-2" data-test-hook="modalBodyBullet8">
                {{ $t('xViewGeneratorStatusOnEcobee') }}
              </li>
            </ul>
          </div>
          <div class="row mt-3">
            <div class="col" data-test-hook="modalBodyText2">
              {{ $t('xWeHopeThatYouWillReconsider') }}
            </div>
          </div>
          <div class="row mt-3">
            <div class="col" data-test-hook="modalBodyText3">
              {{ $t('xAreYouSureYouWishToCancel') }}
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <button
          type="button"
          class="btn btn-outline-warning"
          @click="closeModal('cancel-paid-sub-step-1'), closeModal('manage-sub-paid-step-1')"
          data-test-hook="previous-button-step1-cancel-sub-modal"
        >
          {{ $t('xNoKeepSubscription') }}
        </button>
        <button
          type="button"
          class="btn btn-warning"
          @click="yesCancelPaidSub()"
          data-test-hook="yes-cancel-button-step1-cancel-sub-modal"
        >
          {{ $t('xYesCancelSubscription') }}
        </button>
      </ModalFooter>
    </Modal>

    <Modal modalId="cancel-paid-sub-step-2" size="modal-lg">
      <ModalHeader :title="$t('xCancelSubscription')" icon="far fa-ban colorOrange mr-1"> </ModalHeader>
      <ModalBody>
        <div class="row">
          <div class="col">
            <p data-test-hook="CancelPlanMessage"></p>
            {{ $t('xWereSorryToHearThatThe') }} {{ currentSubscription[0].productName }}
            {{ $t('xPlanIsNoLongerSatisfyingYourNeeds') }}
            <div v-if="!cancellingFreeSubscription()">
              <p style="font-weight:bold" v-if="isTankType()">
                {{ $t('xYouWillBeAbleToMonitorYourTank') }}
                {{ $moment(currentSubscription[0].nextBillingDate).format($t('xShortDate')) }}.
              </p>
              <p style="font-weight:bold" v-else>
                {{ $t('xYouWillBeAbleToMonitor') }}
                {{ $moment(currentSubscription[0].nextBillingDate).format($t('xShortDate')) }}.
              </p>
            </div>
            <p class="mt-4 mb-3">{{ $t('xPleaseLetUsKnowWhy') }}</p>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col">
            <div class="form-group">
              <div v-if="isTankType()">
                <input
                  name="cancelSelection"
                  type="radio"
                  v-model="cancelReason"
                  value="I no longer want to monitor my tank"
                  id="noLongerWantToMonitorTank"
                  data-test-hook="no-longer-want-monitoring-radio-step2-cancel-sub-modal"
                />
                <label for="noLongerWantToMonitorTank" class="ml-1" style="margin-bottom: 0px;">
                  {{ $t('xINoLongerWantToMonitorMyTank') }}
                </label>
              </div>
              <div v-else>
                <input
                  name="cancelSelection"
                  type="radio"
                  v-model="cancelReason"
                  value="I no longer want to monitor my generator"
                  id="noLongerWantToMonitor"
                  data-test-hook="no-longer-want-monitoring-radio-step2-cancel-sub-modal"
                />
                <label for="noLongerWantToMonitor" class="ml-1" style="margin-bottom: 0px;">
                  {{ $t('xINoLongerWantToMonitorMyGenerator') }}
                </label>
              </div>
              <div class="mt-2">
                <input
                  name="cancelSelection"
                  type="radio"
                  v-model="cancelReason"
                  value="I'm having too many techincal problems"
                  id="tooManyTechnicalProblems"
                  data-test-hook="too-many-tech-problems-radio-step2-cancel-sub-modal"
                />
                <label for="tooManyTechnicalProblems" class="ml-1" style="margin-bottom:0px;">
                  {{ $t('xIAmHavingTooManyTechnicalProblems') }}
                </label>
              </div>
              <div class="mt-2">
                <input
                  name="cancelSelection"
                  type="radio"
                  v-model="cancelReason"
                  value="Could not Connect"
                  id="iAmHavingConnectionIssues"
                  data-test-hook="connection-issues-radio-step2-cancel-sub-modal"
                />
                <label for="iAmHavingConnectionIssues" class="ml-1" style="margin-bottom:0px;">
                  {{ $t('xIAmHavingConnectionIssues') }}
                </label>
              </div>
              <div class="mb-1" id="could-not-connect-specification" v-if="cancelReason == 'Could not Connect'">
                <input
                  type="text"
                  class="form-control"
                  v-model="cancelReasonSpecification"
                  maxlength="500"
                  data-test-hook="CannotConnectCancelReasonField"
                  :placeholder="$t('xPleaseGiveDetails')"
                />
              </div>
              <div class="mt-2">
                <input
                  name="cancelSelection"
                  type="radio"
                  v-model="cancelReason"
                  value="The features aren't valuable enough for the price"
                  id="theFeaturesAreNotValuableEnoughForPrice"
                  data-test-hook="features-are-bad-radio-step2-cancel-sub-modal"
                />
                <label for="theFeaturesAreNotValuableEnoughForPrice" class="ml-1" style="margin-bottom:0px;">
                  {{ $t('xTheFeaturesAreNotValuableEnoughForThePrice') }}
                </label>
              </div>
              <div class="mt-2">
                <input
                  name="cancelSelection"
                  type="radio"
                  v-model="cancelReason"
                  value="There are not enough features for the price"
                  id="thereAreNotEnoughFeaturesForPrice"
                  data-test-hook="no-features-for-price-radio-step2-cancel-sub-modal"
                />
                <label for="thereAreNotEnoughFeaturesForPrice" class="ml-1" style="margin-bottom:0px;">
                  {{ $t('xThereAreNotEnoughFeaturesForThePrice') }}
                </label>
              </div>
              <div class="mt-2">
                <input
                  name="cancelSelection"
                  type="radio"
                  v-model="cancelReason"
                  value="RemovalFlow"
                  id="iHaveMoved"
                  data-test-hook="i-have-moved-radio-step2-cancel-sub-modal"
                />
                <label for="iHaveMoved" class="ml-1" style="margin-bottom:0px;">
                  {{ $t('xIHaveMovedRemoveImmediately') }}
                </label>
              </div>
              <div class="mt-2">
                <input
                  name="cancelSelection"
                  type="radio"
                  v-model="cancelReason"
                  value="Other"
                  id="otherReason"
                  data-test-hook="other-radio-step2-cancel-sub-modal"
                />
                <label for="otherReason" class="ml-1" style="margin-bottom:0px;">
                  {{ $t('xOther') }}
                </label>
              </div>
              <div id="other-specification" v-if="cancelReason == 'Other'">
                <input
                  v-model="cancelReasonSpecification"
                  maxlength="500"
                  type="text"
                  class="form-control"
                  data-test-hook="OtherCancelReasonField"
                  :placeholder="$t('xPleaseGiveDetails')"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="row colorRed">
          <div class="col">
            <p v-if="submitClicked && cancelReason == null" data-test-hook="cancelSubModalYouMustSelectAReasonText">
              {{ $t('xYouMustSelectAReason') }}
            </p>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <button
          type="button"
          class="btn btn-warning"
          @click="cancelSubscription()"
          data-test-hook="confirm-cancel-button-step2-cancel-sub-modal"
        >
          {{ $t('xSubmit') }}
          <span class="spinner-border spinner-border-sm ml-2" v-if="cancellingSubscription"></span>
        </button>
      </ModalFooter>
    </Modal>

    <Modal modalId="remove-gen-step-1" size="modal-lg">
      <ModalHeader :title="$t('xRemoveGenerator')" icon="far fa-trash-alt colorOrange mr-1"> </ModalHeader>
      <ModalBody>
        <div class="row mt-2">
          <div class="col">
            {{ $t('xYouHaveIndicatedThatYouWant') }}
          </div>
        </div>
        <div class="row mt-4">
          <div class="col">
            {{ $t('xRemoveGeneratorFromMobileLink') }}
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <button
          type="button"
          class="btn btn-outline-warning"
          @click="openModal('cancel-paid-sub-step-2'), closeModal('remove-gen-step-1'), cleanReasons()"
          data-test-hook="previous-button-removeGen1-cancel-sub-modal"
        >
          {{ $t('xNoSelectDifferentOption') }}
        </button>
        <button
          type="button"
          class="btn btn-warning"
          @click="removeGenerator()"
          :disabled="removing"
          data-test-hook="yes-im-sure-button-removeGen1-cancel-sub-modal"
        >
          {{ $t('xYesRemove') }}
          <span class="spinner-border spinner-border-sm ml-2" v-if="removing"></span>
        </button>
      </ModalFooter>
    </Modal>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import * as successAnimation from '@/assets/images/manage-subscription-success.json'
import * as Constants from '@/constants'

export default {
  props: ['currentSubscription'],
  data() {
    return {
      cancelReason: null,
      cancelReasonSpecification: null,
      cancellingSubscription: false,
      submitClicked: false,
      removing: false,
      successAnimationOptions: { animationData: successAnimation, loop: false }
    }
  },
  computed: {
    ...mapState({
      accountProfile: state => state.account.accountProfile,
      currentApparatusDetails: state => state.apparatus.currentApparatusDetails,
      isImpersonating: state => state.auth.isImpersonating
    }),
    dealerManaged() {
      return Constants.DealerManagedProductId
    }
  },
  methods: {
    closeCancelFlowOnSuccess() {
      if (this.cancellingFreeSubscription()) this.reRouteToDashboard()
      else {
        this.closeModals()
        this.emitSuccess()
      }
    },
    isTankType() {
      return (
        this.currentApparatusDetails.deviceType == Constants.TankUtilityWifiDeviceType ||
        this.currentApparatusDetails.deviceType == Constants.TankUtilityLteDeviceTypeV1 ||
        this.currentApparatusDetails.deviceType == Constants.TankUtilityLteDeviceTypeV2
      )
    },
    previousButtonRouting() {
      if (this.cancellingFreeSubscription()) {
        this.openModal('upgrade-free-sub-step-1')
      } else this.openModal('manage-sub-paid-step-1')
      this.closeModal('cancel-paid-sub-step-1')
    },
    cancellingFreeSubscription() {
      if (
        this.currentSubscription &&
        this.currentSubscription[0] &&
        this.currentSubscription[0].productId == Constants.FreeWifiEthProductId
      )
        return true
      return false
    },
    reRouteToDashboard() {
      this.$store.dispatch('apparatusReset')
      this.$router.push({
        path: `/dashboard`
      })
    },
    removeGenerator() {
      this.removing = true
      this.$store
        .dispatch('removeGenerator', {
          organizationId: this.accountProfile.organizationId,
          apparatusId: this.currentApparatusDetails.apparatusId
        })
        .then(response => {
          this.closeModal('remove-gen-step-1')
          this.$router.push({
            path: `/dashboard`
          })
          let event = this.createEventLogObjectFromSubscriptionsPage(
            this.currentApparatusDetails,
            this.currentSubscription,
            this.accountProfile,
            this.isImpersonating,
            Constants.RemoveEventType,
            null,
            'I have moved'
          )
          this.$store.dispatch('logEvent', event)
        })
        .catch(error => {
          this.$store.dispatch('displayToast', {
            title: this.$t('xError'),
            message: this.$t('xFailedToRemoveTheGeneratorFromYourAccount'),
            variant: 'danger'
          })
        })
        .finally(() => {
          this.removing = false
        })
    },
    emitSuccess() {
      this.$emit('success')
    },
    cleanReasons() {
      this.cancelReason = null
      this.cancelReasonSpecification = null
      this.submitClicked = false
    },
    async cancelSubscription() {
      this.submitClicked = true
      if (this.cancelReason == null) {
        return
      }
      if (this.cancelReason == 'RemovalFlow') {
        //go to removal flow
        this.closeModal('cancel-paid-sub-step-2')
        this.openModal('remove-gen-step-1')
      } else {
        this.cancellingSubscription = true
        await this.$store
          .dispatch('cancelApparatusSubscription', {
            organizationId: this.accountProfile.organizationId,
            apparatusId: this.currentApparatusDetails.apparatusId
          })
          .then(response => {
            this.cancellingSubscription = false
            this.closeModal('cancel-paid-sub-step-2')
            this.$emit('success')
            if (this.currentSubscription[0].productId == Constants.FreeWifiEthProductId) {
              this.$router.push({
                path: `/dashboard`
              })
            }
            let event = this.createEventLogObjectFromSubscriptionsPage(
              this.currentApparatusDetails,
              this.currentSubscription,
              this.accountProfile,
              this.isImpersonating,
              Constants.CancelEventType,
              null,
              this.cancelReason,
              this.cancelReasonSpecification
            )
            this.$store.dispatch('logEvent', event)
          })
          .catch(error => {
            this.cancellingSubscription = false
            this.$store.dispatch('displayToast', {
              title: this.$t('xError'),
              message: this.$t('xFailedToCancelDeviceSubscription'),
              variant: 'danger'
            })
          })
          .finally(() => {
            this.cleanReasons()
          })
      }
    },
    closeModals() {
      this.closeModal('manage-sub-paid-step-1')
      this.closeModal('cancel-paid-sub-step-1')
      this.closeModal('cancel-paid-sub-step-2')
      this.closeModal('remove-gen-step-1')
      this.cancellingSubscription = false
      this.cancelReason = null
      this.cancelReasonSpecification = null
      this.removing = false
    },
    yesCancelPaidSub() {
      this.closeModal('manage-sub-paid-step-1')
      this.closeModal('cancel-paid-sub-step-1')
      this.openModal('cancel-paid-sub-step-2')
    }
  },
  watch: {
    cancelReason: function(newVal, oldVal) {
      if (newVal) this.cancelReasonSpecification = null
    }
  }
}
</script>
