var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("i", { class: _vm.statusIcon }),
    _vm.showWarning && _vm.isConnected
      ? _c("i", {
          staticClass:
            "fa-solid fa-triangle-exclamation statusIsWarning float-right fa-sm pl-1",
          class: _vm.inverse ? "colorWhite" : null,
          attrs: { "data-test-hook": "StatusIcon" }
        })
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }