import Axios from 'axios'
import { vueAppApiBaseAddress } from '@/utils/mixins'

const XSRF_COOKIE_NAME = 'XSRF-TOKEN'
const XSRF_HEADER_NAME = 'X-XSRF-TOKEN'

class XsrfClient {
  async post(url, body) {
    return await Axios.get(vueAppApiBaseAddress() + 'v1/Antiforgery/cookie', { withCredentials: true })
      .then(async response => {
        return await Axios.post(url, body, {
          withCredentials: true,
          xsrfCookieName: XSRF_COOKIE_NAME,
          xsrfHeaderName: XSRF_HEADER_NAME
        })
      })
      .then(response => {
        return response
      })
      .catch(error => {
        return error
      })
  }
}

export const xsrfPost = XsrfClient.prototype.post
