<template>
  <div class="modal-header align-items-center" v-if="title">
    <h2 class="modal-title">
      <i v-if="icon" :class="icon" class="colorOrange mr-1" />
      {{ title }}
    </h2>
    <slot />
  </div>
</template>

<script>
export default {
  props: ['title', 'icon']
}
</script>
