<template>
  <div>
    <div v-if="isLoading" class="d-flex justify-content-center mb-3 pt-4">
      <b-spinner style="width: 5rem; height: 5rem" variant="warning" label="Spinning"></b-spinner>
    </div>
    <div v-else>
      <b-row align-v="center" class="pt-1">
        <!-- <b-col>
          <LocateDealer />
        </b-col> -->
        <b-col>
          <h1 class="mb-0">All Products</h1>
        </b-col>
        <b-col>
          <b-dropdown
            variant="link"
            right
            v-if="apparatuses.length > 0 && accountProfile"
            class="my-0 float-right"
            no-caret
            data-test-hook="enrollment-dropdown-dashboard"
            :disabled="
              currentAppConfig &&
                currentAppConfig.featureFlags &&
                currentAppConfig.featureFlags[billingMigrationFeatureFlag]
            "
          >
            <template #button-content>
              <span
                class=" d-flex justify-content-center align-items-center add-product-button"
                data-test-hook="dashboard-add-device-menu-button"
              >
                <i class="fas fa-circle-plus fa-lg my-2 p-0 mr-2" />
                <p class="mb-0">{{ $t('xAddProduct') }}</p>
              </span>
            </template>
            <b-dropdown-item @click="beginEnrollmentFlow()" data-test-hook="dashboard-add-device-menu-add-gen-button">
              <i class="fas fa-plus fa-lg colorOrange" />
              {{ $t('xAddAGen') }}
            </b-dropdown-item>
            <b-dropdown-divider v-if="PortablesEnabledFF(currentAppConfig)" />
            <b-dropdown-item
              @click="beginPortableGenEnrollmentFlow()"
              v-if="PortablesEnabledFF(currentAppConfig)"
              data-test-hook="dashboard-add-device-menu-add-portable-button"
            >
              <i class="fas fa-plus fa-lg colorOrange" /> {{ $t('xAddPortableGenerator') }}
            </b-dropdown-item>
            <b-dropdown-divider />
            <b-dropdown-item
              @click="beginTankEnrollmentFlow()"
              data-test-hook="dashboard-add-device-menu-add-tank-button"
            >
              <i class="fas fa-plus fa-lg colorOrange" /> {{ $t('xAddTankMonitor') }}
            </b-dropdown-item>
          </b-dropdown>
        </b-col>
      </b-row>
      <div v-if="cdmaWarning" @click="trackAppInsightsEvent('cdma-banner-click')">
        <b-link :href="cdmaWarning.link" target="_blank" style="text-decoration: none !important;">
          <b-alert variant="warning" class="hoverPointer" show>
            <b-row>
              <b-col>
                <h2>
                  <i class="fas fa-exclamation-triangle banner-category-warning" />
                  {{ cdmaWarning.title }}
                </h2>
                <p>{{ cdmaWarning.message }}</p>
                <p class="mb-0 pb-0">
                  {{ cdmaWarning.linkText }}
                  <i class="fas fa-arrow-right" />
                </p>
              </b-col>
            </b-row>
          </b-alert>
        </b-link>
      </div>
      <b-alert
        variant="success"
        v-if="showRefreshSuccessBanner"
        dismissible
        :show="refreshSuccessCountdown"
        @dismissed="showRefreshSuccessBanner = false"
      >
        <i class="fas fa-check-circle fa-lg" />
        {{ $t('xYouSuccessfullyLoggedIntoTankUtility') }}
      </b-alert>
      <b-alert
        v-if="
          currentAppConfig &&
            currentAppConfig.featureFlags &&
            currentAppConfig.featureFlags[billingMigrationFeatureFlag]
        "
        show
        variant="warning"
      >
        {{ $t('xBillingMaintenanceMessage') }}
      </b-alert>
      <div @click="$bvModal.show('banner-message-modal')">
        <b-alert
          v-if="showBannerMessage(bannerMessage)"
          class="hoverPointer"
          show
          :variant="getBannerVariant(bannerMessage)"
        >
          <i :class="getBannerIconClassAndColor(bannerMessage)" />
          {{ bannerMessage.BannerMessage }}
          <strong>
            {{ $t('xLearnMore') }}
            <i class="fas fa-arrow-right" />
          </strong>
        </b-alert>
      </div>
      <div v-if="apparatuses.length == 0">
        <b-row>
          <b-col cols="12" class="pt-3">
            <h2>{{ $t('xLetsGetStarted') }}</h2>
          </b-col>
        </b-row>
        <b-row align-v="stretch">
          <b-col cols="12" md="6" lg="4">
            <b-card
              class="card-shadow add-generator-card hoverPointer mb-3"
              @click="beginEnrollmentFlow()"
              data-test-hook="dashboard-add-generator-button"
            >
              <b-row align-v="center">
                <b-col class="mr-0 pr-0" cols="2">
                  <b-img
                    :width="200"
                    class="generator-success-img"
                    fluid
                    :src="require('@/assets/images/enroll-a-generator.svg')"
                  />
                </b-col>
                <b-col class="d-flex align-items-center">
                  <h3><i class="colorOrange fas fa-plus mr-2" /></h3>
                  <h3 class="mb-0">{{ $t('xAddAGen') }}</h3>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <b-col v-if="PortablesEnabledFF(currentAppConfig)" cols="12" md="6" lg="4">
            <b-card
              class="card-shadow add-generator-card hoverPointer mb-3"
              @click="beginPortableGenEnrollmentFlow()"
              data-test-hook="dashboard-add-portable-button"
            >
              <b-row align-v="center">
                <b-col class="mr-0 pr-0" cols="2">
                  <b-img
                    :width="200"
                    class="generator-success-img"
                    fluid
                    :src="require('@/assets/images/enroll-a-portable-gen.svg')"
                  />
                </b-col>
                <b-col class="d-flex align-items-center">
                  <h3><i class="colorOrange fas fa-plus mr-2" /></h3>
                  <h3 class="mb-0">{{ $t('xAddPortableGenerator') }}</h3>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <b-col cols="12" md="6" lg="4">
            <b-card
              class="card-shadow add-generator-card hoverPointer mb-3"
              @click="beginTankEnrollmentFlow()"
              data-test-hook="dashboard-add-tank-button"
            >
              <b-row align-v="center">
                <b-col class="mr-0 pr-0" cols="2">
                  <b-img
                    :width="200"
                    class="generator-success-img"
                    fluid
                    :src="require('@/assets/images/enroll-a-tank.svg')"
                  />
                </b-col>
                <b-col class="d-flex align-items-center">
                  <h3><i class="colorOrange fas fa-plus mr-2" /></h3>
                  <h3 class="mb-0">{{ $t('xAddTankMonitor') }}</h3>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </div>
      <b-row v-else class="px-2">
        <b-col v-for="apparatus in apparatuses" :key="apparatus.id" cols="12" md="6" lg="6" xl="4" align-v="stretch">
          <ApparatusCard
            class="dashboard-card"
            v-if="apparatus.type == Constants.HsbApparatusType"
            :apparatusInfo="apparatus"
            :paymentInfo="existingPaymentInfo"
            :organizationSubscriptionDetailsData="organizationSubscriptionDetailsData"
            @incompleteApparatus="incompleteApparatus"
            data-test-hook="dashboard-generator-card"
          />
          <TankUtilityCard
            class="dashboard-card"
            v-if="apparatus.type == Constants.FuelTankApparatusType"
            :apparatusInfo="apparatus"
            :paymentInfo="existingPaymentInfo"
            :organizationSubscriptionDetailsData="organizationSubscriptionDetailsData"
            @missingProperties="tankEnrollmentMissingProperties"
            @noSubscription="tankEnrollmentNoSubscription"
            data-test-hook="dashboard-tank-card"
          />
          <PendingCard
            class="dashboard-card"
            v-if="apparatus.pending == true"
            :apparatusType="apparatus.apparatusType"
            data-test-hook="dashboard-pending-card"
          />
          <PortableGenCard
            class="dashboard-card"
            v-if="apparatus.type == Constants.PortableGeneratorApparatusType"
            :apparatusInfo="apparatus"
            data-test-hook="dashboard-portable-card"
          />
        </b-col>
      </b-row>
    </div>
    <EnrollmentModals
      v-if="accountProfile"
      :apparatusId="apparatusIdForEnrollment"
      :modelNumber="apparatusModelNumberForEnrollment"
    />
    <TankUtilityEnrollmentModals
      :enrollmentNoSubscription="enrollmentNoSubscription"
      :deviceType="tankDeviceType"
      :apparatusId="tankApparatusId"
    />
    <NewAnnouncementModal v-if="featureAnnouncement" :announcement="featureAnnouncement" />
    <PortablesEnrollmentModals />
    <b-modal
      id="cdmaRemindMessage"
      ref="cdma-remind-me"
      no-close-on-backdrop
      no-close-on-esc
      :ok-disabled="true"
      hide-footer
      centered
    >
      <template #modal-header>
        <b-row class="w-100" align-v="center" no-gutters>
          <b-col>
            <h2 class="modal-title">
              <i class="fas fa-exclamation-triangle colorOrange mr-1" />
              {{ $t('xCDMADeviceSupport') }}
            </h2>
          </b-col>
        </b-row>
      </template>
      <b-row>
        <b-col align-v="center">
          <h5 class="headerInline">
            <h5><i class="fas fa-sm" />{{ $t('xMobileLinkLoseCellular') }}</h5>
          </h5>
        </b-col>
      </b-row>
      <br />
      <b-row align-v="center">
        <b-col align-v="center">
          <b-button class="feedback-button" variant="outline-warning" block @click="cdmaRemindMeLater()">
            <i class="fas fa-alarm-snooze fa-sm colorOrange" />
            Remind me in
            {{
              currentAppConfig != null ? this.currentAppConfig.configurations[Constants.CdmaUpdateRemindMeDays] : '30'
            }}
            days
          </b-button>
        </b-col>
      </b-row>
      <br />
      <b-row align-v="center">
        <b-col align-v="center">
          <b-button
            block
            class="feedback-button"
            variant="warning"
            href="https://support.generac.com/s/article/How-Do-I-Upgrade-My-CDMA-Device-to-the-LTE-Device"
            target="_blank"
            @click="cdmaUpgradeLearnMoreDate()"
          >
            <i class="fas fa-external-link" />
            {{ $t('xLearnMore') }}
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal size="md" id="has-subscription-from-other-platform" no-close-on-backdrop no-close-on-esc centered>
      <template #modal-header>
        <b-row class="w-100 mb-n3 mt-n2 pl-2" align-v="center" no-gutters>
          <b-col>
            <h2 class="modal-title">
              <i class="far fa-exclamation-triangle colorOrange mr-2" />
              <strong>{{ $t('xActionRequired') }}</strong>
            </h2>
          </b-col>
          <b-col cols="auto" class="float-right mr-n3">
            <b-button
              class="btn-icon-button--gray"
              variant="icon-button"
              @click="$bvModal.hide('has-subscription-from-other-platform')"
            >
              <i class="fas fa-times" />
            </b-button>
          </b-col>
        </b-row>
      </template>
      <b-row>
        <b-col v-if="subscriptionTypeApple()">
          {{ $t('xAppleSubscriptionText') }}
        </b-col>
        <b-col v-else-if="subscriptionTypeGoogle()">
          {{ $t('xAndroidSubscriptionText') }}
        </b-col>
      </b-row>
      <template #modal-footer>
        <b-row align-v="center">
          <b-col cols="auto" class="pt-0 mr-n3 mb-n2 mt-n2">
            <b-button
              class="feedback-button"
              variant="warning"
              @click="$bvModal.hide('has-subscription-from-other-platform')"
            >
              {{ $t('xDone') }}
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import ApparatusCard from '@/components/Dashboard/ApparatusCard'
import PendingCard from '@/components/Dashboard/PendingCard'
import EnrollmentModals from '@/components/Enrollment/EnrollmentModals'
import TankUtilityEnrollmentModals from '@/components/Enrollment/TankUtilityEnrollmentModals'
import TankUtilityCard from '@/components/Dashboard/TankUtilityCard'
import NewAnnouncementModal from '@/components/NewAnnouncementModal'
import PortablesEnrollmentModals from '@/components/Enrollment/PortablesEnrollmentModals'
import * as Constants from '@/constants'
import { subscriptionActive, vueAppBaseAddress, tankCanHavePaidSubscription } from '../utils/mixins'
import PortableGenCard from '@/components/Dashboard/PortableGenCard'

export default {
  components: {
    ApparatusCard,
    PendingCard,
    EnrollmentModals,
    NewAnnouncementModal,
    TankUtilityEnrollmentModals,
    TankUtilityCard,
    PortablesEnrollmentModals,
    PortableGenCard
  },
  data() {
    return {
      Constants: Constants,
      apparatusIdForEnrollment: null,
      apparatusModelNumberForEnrollment: null,
      showRefreshSuccessBanner: false,
      refreshSuccessCountdown: 10,
      existingPaymentInfo: null,
      isLoading: false,
      enrollmentNoSubscription: false,
      tankDeviceType: null,
      tankApparatusId: null,
      partialEnrollApparatusId: null,
      partialEnrollToProcess: false,
      organizationSubscriptionDetailsData: null,
    }
  },
  computed: {
    ...mapState({
      apparatuses: state => state.apparatus.apparatuses,
      accountProfile: state => state.account.accountProfile,
      user: state => state.account.user,
      isImpersonating: state => state.auth.isImpersonating,
      featureAnnouncement: state => state.application.featureAnnouncement,
      currentAppConfig: state => state.application.currentAppConfig,
      bannerMessage: state => state.application.bannerMessage,
      hasUnreadMessage: state => state.account.hasUnreadMessages,
      cdmaWarning: state => state.cdmaBanner.cdmaWarning
    }),
    billingMigrationFeatureFlag() {
      return Constants.BillingMigrationFeatureFlag
    }
  },
  methods: {
    hasLegacySub() {
      if (
        this.organizationSubscriptionDetailsData.legacyBillingAccount?.products.filter(
          product =>
            product.isLegacy &&
            subscriptionActive(product) &&
            !product.productId.includes('free-wifi') &&
            !product.productId.includes('dealermanaged') &&
            this.apparatuses
              .filter(apparatus => apparatus.apparatusId === product.apparatusId)[0]
              ?.properties.filter(
                property => property.name === 'Device' && property.deviceType?.includes('tankutility-v1')
              ).length === 0
        ).length > 0
      ) {
        this.$bvModal.show('not-allowed-to-enroll-legacy-sub')
        return true
      }
      return false
    },
    tankEnrollmentMissingProperties(info) {
      this.tankDeviceType = info.deviceType
      this.tankApparatusId = info.apparatusId
      this.$bvModal.show('tank-enrollment-step-3')
    },
    tankEnrollmentNoSubscription(info) {
      this.tankDeviceType = info.deviceType
      this.tankApparatusId = info.apparatusId
      this.enrollmentNoSubscription = true
    },
    showAnnouncementModal() {
      if (this.user && this.featureAnnouncement && this.featureAnnouncement.showAnnouncement) {
        if (this.user.settings && (!this.user.settings.showAnnouncement || !this.user.settings.announcementId)) {
          this.$bvModal.show('announcement-modal')
          return
        } else if (!this.user.settings) {
          this.$bvModal.show('announcement-modal')
          return
        }
        if (this.user.settings.announcementId == this.featureAnnouncement.releaseId) {
          if (this.user.settings.showAnnouncement == 'true') {
            this.$bvModal.show('announcement-modal')
            return
          }
        } else if (this.user.settings.announcementId != this.featureAnnouncement.releaseId) {
          this.$bvModal.show('announcement-modal')
          return
        }
      }
    },
    subscriptionTypeApple() {
      if (this.organizationSubscriptionDetailsData?.subscriptions) {
        for (const subscription of this.organizationSubscriptionDetailsData?.subscriptions) {
          if (
            subscription.store === Object.values(Constants.SubscriptionStoreTypes).indexOf('Apple') &&
            subscription.status !== Constants.SubscriptionDetailCanceled
          ) {
            return true
          }
        }
      }
      return false
    },
    subscriptionTypeGoogle() {
      if (this.organizationSubscriptionDetailsData?.subscriptions) {
        for (const subscription of this.organizationSubscriptionDetailsData?.subscriptions) {
          if (
            subscription.store === Object.values(Constants.SubscriptionStoreTypes).indexOf('Google') &&
            subscription.status !== Constants.SubscriptionDetailCanceled
          ) {
            return true
          }
        }
      }
      return false
    },
    async beginEnrollmentFlow() {
      if (!this.hasLegacySub()) {
        let hsbs = this.apparatuses.filter(x => x.type == Constants.HsbApparatusType && !x.isDealerManaged)
        if (hsbs.length > 0) {
          this.$bvModal.show('hsb-limit-reached')
        } else {
          let hasSubFromOtherPlatform = false
          if (this.organizationSubscriptionDetailsData?.subscriptions) {
            for (const subscription of this.organizationSubscriptionDetailsData?.subscriptions) {
              if (
                subscription.store !== Object.values(Constants.SubscriptionStoreTypes).indexOf('Web') &&
                subscription.status !== Constants.SubscriptionDetailCanceled &&
                !subscription.product.productId.includes('dealermanaged')
              ) {
                hasSubFromOtherPlatform = true
                break
              }
            }
          }
          if (hasSubFromOtherPlatform) {
            this.$bvModal.show('has-subscription-from-other-platform')
          } else {
            this.$bvModal.show('serial-number-step-1')
          }
        }
      }
    },
    beginTankEnrollmentFlow() {
      if (!this.hasLegacySub()) {
        if (tankCanHavePaidSubscription(this.accountProfile)) {
          let tanks = this.apparatuses.filter(x => x.type == Constants.FuelTankApparatusType && !x.isDealerManaged)
          if (tanks.length > 1) {
            this.$bvModal.show('fuel-monitor-limit-reached')
          } else {
            let hasSubFromOtherPlatform = false
            if (this.organizationSubscriptionDetailsData?.subscriptions) {
              for (const subscription of this.organizationSubscriptionDetailsData?.subscriptions) {
                if (
                  subscription.store !== Object.values(Constants.SubscriptionStoreTypes).indexOf('Web') &&
                  subscription.status !== Constants.SubscriptionDetailCanceled &&
                  !subscription.product.productId.includes('dealermanaged')
                ) {
                  hasSubFromOtherPlatform = true
                  break
                }
              }
            }
            if (hasSubFromOtherPlatform) {
              this.$bvModal.show('has-subscription-from-other-platform')
            } else {
              this.$bvModal.show('tank-enrollment-step-1')
            }
          }
        } else {
          this.$bvModal.show('fuel-monitors-not-allowed-for-your-region')
        }
      }
    },
    beginPortableGenEnrollmentFlow() {
      if (!this.hasLegacySub()) {
        this.$bvModal.show('portables-enrollment')
      }
    },
    incompleteApparatus(value) {
      this.apparatusIdForEnrollment = value.id
      this.apparatusModelNumberForEnrollment = value.modelNumber
      if (!value.device || value.device.length == 0) {
        //begin enrollment flow at device selection
        this.$bvModal.show('select-device-type-step-6')
      } else {
        //begin enrollment flow at subscription selection
        this.$bvModal.show('device-enrollment-success-step-9')
      }
    },
    tankRefreshSuccess() {
      this.$store.dispatch('getApparatuses')
      this.showRefreshSuccessBanner = true
    },
    showCdmaReminder() {
      if (
        !this.currentAppConfig.featureFlags ||
        !this.currentAppConfig.configurations ||
        !this.user ||
        !this.user.settings
      ) {
        return
      }

      // feature flag check
      if (!this.currentAppConfig.featureFlags[Constants.CdmaUpgradeShowPopUp]) {
        return
      }

      //is user past Remind Me date
      if (this.user.settings[Constants.CdmaUpgradeRemindMeDate] != null) {
        let lastRemindDateClicked = new Date(this.user.settings[Constants.CdmaUpgradeRemindMeDate])
        let showRemindAfterDate = new Date(this.user.settings[Constants.CdmaUpgradeRemindMeDate])
        showRemindAfterDate.setDate(
          lastRemindDateClicked.getDate() +
            parseInt(this.currentAppConfig.configurations[Constants.CdmaUpdateRemindMeDays])
        )
        if (new Date() < showRemindAfterDate) {
          return
        }
      }

      //Cdma Device check
      //Fleet Managed check
      //Panel Id check
      let apparatusesIndex = 0
      let cdmaApparatusFound = false
      let panelOk = true
      let noPreferredDealer = false
      while (apparatusesIndex < this.apparatuses.length) {
        cdmaApparatusFound = false
        panelOk = true
        noPreferredDealer = false
        if (
          this.apparatuses[apparatusesIndex].preferredDealerEmail == null &&
          this.apparatuses[apparatusesIndex].preferredDealerName == null &&
          this.apparatuses[apparatusesIndex].preferredDealerPhone == null
        ) {
          noPreferredDealer = true
        }
        if (
          this.apparatuses[apparatusesIndex].panelId == '02' ||
          this.apparatuses[apparatusesIndex].panelId == '2' ||
          this.apparatuses[apparatusesIndex].panelId == '18'
        ) {
          panelOk = false
        }

        if (noPreferredDealer && panelOk) {
          let propertiesIndex = 0
          while (!cdmaApparatusFound && propertiesIndex < this.apparatuses[apparatusesIndex].properties.length) {
            if (
              this.apparatuses[apparatusesIndex].properties[propertiesIndex].name == 'Device' &&
              this.apparatuses[apparatusesIndex].properties[propertiesIndex].value.deviceType == 'cdma'
            ) {
              cdmaApparatusFound = true
            }
            propertiesIndex++
          }
        }
        if (cdmaApparatusFound && panelOk && noPreferredDealer) {
          break
        } else {
          apparatusesIndex++
        }
      }
      if (!cdmaApparatusFound || !panelOk || !noPreferredDealer) {
        return
      }

      this.$appInsights.trackEvent({
        name: 'cdma-upgrade-popup',
        properties: {
          organizationId:
            this.accountProfile && this.accountProfile.organizationId ? this.accountProfile.organizationId : null,
          userId: this.accountProfile && this.accountProfile.userId ? this.accountProfile.userId : null
        }
      })
      this.$refs['cdma-remind-me'].show()
    },
    cdmaRemindMeLater() {
      this.$appInsights.trackEvent({
        name: 'cdma-upgrade-remindme',
        properties: {
          organizationId: this.accountProfile.organizationId,
          userId: this.accountProfile.userId
        }
      })
      let request = {
        settings: {
          cdmaUpgradeRemindMeDate: this.$moment.utc().format('M/D/YYYY')
        }
      }
      this.$store
        .dispatch('updateAccountSettings', request)
        .then(response => {
          this.$refs['cdma-remind-me'].hide()
          this.$store.dispatch('getUser')
        })
        .catch(error => {
          this.$store.dispatch('displayToast', {
            title: this.$t('xError'),
            message: this.$t('xFailedToUpdateRemindMeSetting'),
            variant: 'danger'
          })
        })
        .finally(() => {
          this.savingSetting = false
        })
    },
    cdmaUpgradeLearnMoreDate() {
      this.$appInsights.trackEvent({
        name: 'cdma-upgrade-learn-more',
        properties: {
          organizationId: this.accountProfile.organizationId,
          userId: this.accountProfile.userId
        }
      })

      let request = {
        settings: {
          cdmaUpgradeLearnMoreDate: this.$moment.utc().format('M/D/YYYY')
        }
      }
      this.$store
        .dispatch('updateAccountSettings', request)
        .then(response => {
          this.$refs['cdma-remind-me'].hide()
          if (!this.isImpersonating) this.$store.dispatch('getUser')
          else this.$store.dispatch('getImpersonatedUser')
        })
        .catch(error => {
          this.$store.dispatch('displayToast', {
            title: this.$t('xError'),
            message: this.$t('xFailedToUpdateRemindMeSetting'),
            variant: 'danger'
          })
        })
        .finally(() => {
          this.savingSetting = false
        })
    },
    setCdmaBanner() {
      let totalValidCdmaApparatuses = 0
      let exactly1CdmaWithPreferredDealer = 0
      let cdmaPreferredDealerName = ''
      let cdmaPreferredDealerApparatusId = 0
      this.apparatuses.forEach(element => {
        if (element.type == Constants.HsbApparatusType && !Constants.CDMADoNotUpgradePanels.includes(element.panelId)) {
          let device = element.properties.filter(x => x.type == Constants.ApparatusPropertyDevice)[0]
          if (device?.value?.deviceType == Constants.HsbCdmaDeviceType) {
            totalValidCdmaApparatuses++
            cdmaPreferredDealerApparatusId = element.apparatusId
            if (element.preferredDealerName) {
              exactly1CdmaWithPreferredDealer++
              cdmaPreferredDealerName = element.preferredDealerName
              cdmaPreferredDealerApparatusId = element.apparatusId
            }
          }
        }
      })
      if (totalValidCdmaApparatuses == 1) {
        if (exactly1CdmaWithPreferredDealer == 1) {
          let dealerWarning = {
            title: this.$t('xCellularServiceAlert'),
            message:
              this.$t('xCellServiceDealerEndingSoonP1') +
              cdmaPreferredDealerName +
              ' ' +
              this.$t('xCellServiceDealerEndingSoonP2'),
            link: vueAppBaseAddress() + `details/${cdmaPreferredDealerApparatusId}/preferredDealer`,
            linkText: this.$t('xDealerDetails')
          }
          this.$store.dispatch('setCdmaWarning', dealerWarning)
        } else {
          let exactly1CdmaWarning = {
            title: this.$t('xCellularServiceAlert'),
            message: this.$t('xCellServiceEndingSoon'),
            link: Constants.HowToUpgradeHelpUrl,
            linkText: this.$t('xLearnMore')
          }
          this.$store.dispatch('setCdmaWarning', exactly1CdmaWarning)
        }
      } else if (totalValidCdmaApparatuses > 1) {
        let multipleCdmaWarning = {
          title: this.$t('xCellularServiceAlert'),
          message: this.$t('xMultipleCellServiceEndingSoon'),
          link: Constants.HowToUpgradeHelpUrl,
          linkText: this.$t('xLearnMore')
        }
        this.$store.dispatch('setCdmaWarning', multipleCdmaWarning)
      } else {
        this.$store.dispatch('setCdmaWarning', null)
      }
      this.$store.dispatch('getCdmaWarning')
    },
    processPartialEnroll() {
      this.partialEnrollToProcess = false
      var apparatus = this.apparatuses?.find(x => x.apparatusId == this.partialEnrollApparatusId)
      var device = apparatus?.properties.find(x => x.type == Constants.ApparatusPropertyDevice)
      this.incompleteApparatus({
        id: this.$route.params.apparatusId,
        device: device,
        modelNumber: apparatus?.modelNumber
      })
    }
  },
  watch: {
    featureAnnouncement: function(newVal, oldVal) {
      if (newVal) this.showAnnouncementModal()
    },
    user: function(newVal, oldVal) {
      if (newVal) this.showAnnouncementModal()
    },
    currentAppConfig: function(newVal, oldVal) {
      if (newVal && this.apparatuses && this.accountProfile) this.showCdmaReminder()
    },
    apparatuses: function(newVal, oldVal) {
      if (newVal && this.currentAppConfig && this.accountProfile) {
        this.showCdmaReminder()
        if (!this.cdmaWarning) this.setCdmaBanner()
      }
      if (newVal && this.partialEnrollToProcess) {
        this.processPartialEnroll()
      }
      if (this.accountProfile?.organizationId && this.accountProfile?.organizationId != 0) {
        this.isLoading = true
        setTimeout(() => {
          this.$store
            .dispatch('getSubscriptionDetails', this.accountProfile.organizationId)
            .then(response => {
              this.organizationSubscriptionDetailsData = response.data
            })
            .finally(() => {
              this.isLoading = false
            })
        }, 1500)
      }
    },
    accountProfile: function(newVal, oldVal) {
      if (newVal) {
        if (this.organizationSubscriptionDetailsData !== null) {
          this.isLoading = false
        } 
        this.$store
          .dispatch('getSubscriptionDetails', this.accountProfile.organizationId)
          .then(response => {
            this.organizationSubscriptionDetailsData = response.data
          })
          .finally(() => {
            this.isLoading = false
          })
        if (this.apparatuses && this.currentAppConfig) this.showCdmaReminder()
        if (!oldVal && !this.existingPaymentInfo) {
          this.$store
            .dispatch('getActivePaymentMethod', newVal.organizationId)
            .then(response => {
              this.existingPaymentInfo = response.data ? response.data : null
            })
            .catch(error => {
              this.existingPaymentInfo = null
            })
        }
        if (this.$route.params.actionType && this.$route.params.actionType.toLowerCase() == 'enrollpartial') {
          this.partialEnrollApparatusId = this.$route.params.apparatusId
          this.partialEnrollToProcess = true
        }

        if (this.$route.params.apparatusType == Constants.HsbApparatusType) {
          setTimeout(() => {
            this.$bvModal.show('serial-number-step-1')
          }, 500)
        }
      }
    }
  },
  created() {
    this.isLoading = true
    if (this.accountProfile) {
      this.$store.dispatch('getSubscriptionDetails', this.accountProfile.organizationId).then(response => {
        this.organizationSubscriptionDetailsData = response.data
      })
    } else {
      this.$store.dispatch('getAccountProfile')
    }
    if (this.user.groups.includes(process.env.VUE_APP_ADMIN_GROUP) && !this.isImpersonating) {
      this.$router.push({ path: '/admin' })
    } else {
      this.$store.dispatch('getAccountStatus').then(response => {
        if (response.data.status == 1) {
          this.$router.push({ path: '/createAccount' })
        } else if (response.data.status == 2) {
          if (!this.accountProfile) {
            this.$store.dispatch('getAccountProfile')
          }
          this.$store.dispatch('getApparatuses')
          if (this.isImpersonating && (!this.user || !this.user.mobileLinkSettings || !this.user.settings)) {
            this.$store.dispatch('getUserSettings')
          }
        } else {
          // user should typically never hit this
          this.$store.dispatch('displayToast', {
            title: this.$t('xError'),
            message: this.$t('xAccountWasNotCreatedSuccessfully'),
            variant: 'danger'
          })
        }
      })
    }
    if (!this.currentAppConfig) this.$store.dispatch('getApplicationConfiguration', 'Any')
  },
  beforeMount() {
    if (!this.cdmaWarning) this.$store.dispatch('getCdmaWarning')
  },
  async mounted() {
    window.localStorage.removeItem(Constants.UserEmailLocalStorageKey)
    if (!this.user) {
      if (!this.isImpersonating) this.$store.dispatch('getUser')
      else this.$store.dispatch('getImpersonatedUser')
    }
    if (!this.featureAnnouncement) this.$store.dispatch('getNewAnnouncements')
    if (!this.hasUnreadMessage) this.$store.dispatch('getHasUnreadMessages')
    this.showAnnouncementModal()
    if (this.$route.params.apparatusType == Constants.HsbApparatusType && this.accountProfile) {
      setTimeout(() => {
        this.$bvModal.show('serial-number-step-1')
      }, 500)
    }
  }
}
</script>

<style scoped lang="scss">
.add-generator-card {
  h3 {
    line-height: 1;
    margin-bottom: 0;
  }
}
</style>

<style lang="scss">
.dashboard-card {
  height: 100%;
  padding-bottom: 0.75rem;
  margin-left: calc(-15px / 2);
  margin-right: calc(-15px / 2);

  .card-body {
    padding: 0.5rem 1.25rem;
  }
}

.dashboard-card-header {
  margin: 0 !important;
  padding: 0.75em !important;
  background-color: white !important;
  font-size: 1.125em;
}

.bg-statusWarning {
  background-color: var(--yellow-200) !important;
  color: var(--carbon-900);
}
.bg-statusFault {
  background-color: var(--red-600) !important;

  i {
    color: var(--white);
  }
}
.bg-statusDisconnected {
  background-color: var(--carbon-300) !important;
  color: var(--carbon-900);
}

.apparatus-card {
  height: 100%;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);
  &:hover {
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);

    .dashboard-card-header {
      background-color: var(--white);
      color: var(--carbon-700);
    }

    .bg-statusWarning {
      background-color: var(--yellow-300) !important;
      color: var(--carbon-900);
    }
    .bg-statusFault {
      background-color: var(--red-500) !important;
      color: var(--white);
    }
    .bg-statusDisconnected {
      background-color: var(--carbon-200) !important;
      color: var(--carbon-900);
    }
  }
}
.apparatus-card-footer {
  border: none !important;
  background-color: var(--white) !important;
}
</style>
