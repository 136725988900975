<template>
  <div>
    <div v-if="!apparatusDetails" class="d-flex justify-content-center mb-3 pt-5">
      <b-spinner style="width: 5rem; height: 5rem;" variant="warning" label="Spinning"></b-spinner>
    </div>
    <div v-else>
      <b-row class="d-md-none pt-2" no-gutters>
        <b-col class="hoverPointer pr-0 d-flex align-items-center">
          <p v-if="$route.name === 'deviceDetails'" @click="goTo('dashboard')" class="mb-0 py-2 font-weight-bold">
            <i class="fas fa-arrow-left colorOrange mr-2"></i>
            Dashboard
          </p>
          <p v-else @click="$router.go(-1)" class="mb-0 py-2 font-weight-bold">
            <i class="fas fa-arrow-left colorOrange mr-2"></i>
            Back
          </p>
        </b-col>
        <b-col cols="auto" class="ml-auto my-auto">
          <b-dropdown right class="d-md-none" variant="outline-warning">
            <template v-slot:button-content>
              Details
            </template>
            <b-list-group flush>
              <b-dropdown-item
                v-for="route in childRoutes"
                :key="route.name"
                :active="$route.name == route.name"
                :to="{ name: route.name }"
                :class="route.class"
                :data-test-hook="'route-dropdown-apparatus-details' + route.name"
              >
                <i class="fa-fw" :class="route.icon"></i>
                <p class="ml-2 d-inline-block mb-0">{{ route.label }}</p>
              </b-dropdown-item>
              <b-dropdown-item
                key="orderParts"
                :href="getPartsURL"
                target="_blank"
                data-test-hook="route-dropdown-apparatus-details-orderParts"
                v-if="isHsbApparatus() && technicalSpecs?.description != null"
              >
                <i class="fa fa-fw fa-tools"></i>
                <p class="ml-2 d-inline-block mb-0" data-test-hook="OrderPartsBtn">
                  Order Parts {{ apparatusDetails.type }}
                </p>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="apparatusDetails.apparatusClassification == portableGenApparatusType"
                class="sidenav hoverPointer"
                @click="$bvModal.show('removeGeneratorModal'), (removeApparatusModalOpen = !removeApparatusModalOpen)"
              >
                <div v-if="removeApparatusModalOpen" class="activeListItemCutout"></div>
                <div v-if="removeApparatusModalOpen" class="activeListItemCutout2"></div>
                <p>
                  <i class="fa-solid fa-trash-can" />
                  {{ $t('xRemoveGenerator') }}
                </p>
              </b-dropdown-item>
            </b-list-group>
          </b-dropdown>
        </b-col>
      </b-row>

      <b-alert
        class="mt-2"
        v-if="
          currentAppConfig &&
            currentAppConfig.featureFlags &&
            currentAppConfig.featureFlags[billingMigrationFeatureFlag]
        "
        show
        variant="warning"
      >
        {{ $t('xBillingMaintenanceMessage') }}
      </b-alert>
      <div @click="$bvModal.show('banner-message-modal')">
        <b-alert
          v-if="showBannerMessage(bannerMessage)"
          class="hoverPointer"
          show
          :variant="getBannerVariant(bannerMessage)"
        >
          <i :class="getBannerIconClassAndColor(bannerMessage)" />
          {{ bannerMessage.BannerMessage }}
          <strong>
            {{ $t('xLearnMore') }}
            <i class="fas fa-arrow-right" />
          </strong>
        </b-alert>
      </div>
      <b-row align-h="between">
        <b-col cols="0" md="4" lg="3" class="d-none d-md-block px-0">
          <b-list-group flush class="h-100" id="detailsMenu">
            <b-list-group-item
              v-for="route in childRoutes"
              :key="route.name"
              :to="{ name: route.name }"
              class="sidenav"
              :class="route.class"
              :data-test-hook="'route-list-apparatus-details' + route.name"
            >
              <p :data-test-hook="removeExtraChars(route.label) + `Btn`">
                <i :class="route.icon"></i> {{ route.label }}
              </p>
            </b-list-group-item>
            <b-list-group-item
              class="sidenav"
              key="orderParts"
              :href="getPartsURL"
              target="_blank"
              v-if="isHsbApparatus() && technicalSpecs?.description != null"
            >
              <p data-test-hook="OrderPartsBtn">
                <i class="fa fa-fw fa-tools"></i>
                {{ $t('xOrderParts') }}
                {{ apparatusDetails.type }}
              </p>
            </b-list-group-item>
            <b-list-group-item
              v-if="apparatusDetails.apparatusClassification == portableGenApparatusType"
              class="sidenav hoverPointer"
              @click="$bvModal.show('removeGeneratorModal'), (removeApparatusModalOpen = !removeApparatusModalOpen)"
            >
              <div v-if="removeApparatusModalOpen" class="activeListItemCutout"></div>
              <div v-if="removeApparatusModalOpen" class="activeListItemCutout2"></div>
              <p>
                <i class="fa-solid fa-trash-can" />
                {{ $t('xRemoveGenerator') }}
              </p>
            </b-list-group-item>
          </b-list-group>
        </b-col>
        <b-col sm="12" md="8" lg="9">
          <b-row no-gutters v-if="cdmaWarning" @click="trackAppInsightsEvent('cdma-banner-click')" class="pt-3 pl-md-2">
            <b-col>
              <b-link :href="cdmaWarning.link" target="_blank" style="text-decoration: none !important;">
                <b-alert variant="warning" class="hoverPointer mb-0" show>
                  <b-row>
                    <b-col>
                      <h2>
                        <i class="fas fa-exclamation-triangle banner-category-warning" />
                        {{ cdmaWarning.title }}
                      </h2>
                      <p>{{ cdmaWarning.message }}</p>
                      <p class="mb-0 pb-0">
                        {{ cdmaWarning.linkText }}
                        <i class="fas fa-arrow-right" />
                      </p>
                    </b-col>
                  </b-row>
                </b-alert>
              </b-link>
            </b-col>
          </b-row>
          <b-row>
            <b-col md id="childRouteContainer" :class="$route.name == 'deviceDetails' ? 'mt-2 mt-md-4 ml-md-2' : null">
              <b-container fluid class="p-0">
                <transition name="fade" mode="out-in">
                  <router-view />
                </transition>
              </b-container>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>
    <b-modal id="removeGeneratorModal" centered>
      <template #modal-header>
        <b-row align-v="center" class="w-100" no-gutters>
          <b-col>
            <h2 class="modal-title">
              <i class="fa-regular fa-trash colorOrange mr-1" />
              {{ $t('xRemoveGenerator') }}
            </h2>
          </b-col>
          <b-col cols="auto">
            <b-button
              variant="icon-button"
              class="btn-icon-button--gray"
              @click="$bvModal.hide('removeGeneratorModal')"
            >
              <i class="fas fa-times" />
            </b-button>
          </b-col>
        </b-row>
      </template>
      <template #modal-footer>
        <b-button variant="outline-warning" @click="$bvModal.hide('removeGeneratorModal')" :disabled="removing">
          {{ $t('xCancel') }}
        </b-button>
        <b-button variant="warning" @click="removeGenerator()" :disabled="removing">
          {{ $t('xYesRemove') }}
          <b-spinner class="ml-2" v-if="removing" />
        </b-button>
      </template>
      <p>{{ $t('xRemoveGenModalMessage1') }}</p>
      <p class="mt-2">{{ $t('xRemoveGenModalMessage2') }}</p>
    </b-modal>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import * as Constants from '@/constants'

export default {
  data() {
    return {
      apparatusDetails: null,
      technicalSpecs: null,
      removeApparatusModalOpen: false,
      removing: false,
      childRoutes: [
        {
          name: 'deviceDetails',
          icon: 'fas fa-fw fa-house',
          label: 'Generator Details'
        },
        {
          name: 'preferredDealer',
          icon: 'fas fa-fw fa-user-hard-hat',
          label: this.$t('xPreferredDealer')
        },
        {
          name: 'warranty',
          icon: 'fas fa-fw fa-award',
          label: this.$t('xWarranty')
        },
        {
          name: 'subscription',
          icon: 'fas fa-fw fa-badge-dollar',
          label: this.$t('xSubscription')
        },
        {
          name: 'statusHistory',
          icon: 'fas fa-fw fa-history',
          label: this.$t('xStatusHistory')
        },
        {
          name: 'exerciseSchedule',
          icon: 'fas fa-fw fa-calendar-check',
          label: this.$t('xExerciseSchedule')
        },
        {
          name: 'maintenance',
          icon: 'fas fa-fw fa-toolbox',
          label: this.$t('xMaintenance')
        },
        {
          name: 'technicalSpecs',
          icon: 'fas fa-fw fa-clipboard-list',
          label: this.$t('xTechnicalSpecs')
        }
      ],
      paidFuelTankChildRoutes: [
        {
          name: 'deviceDetails',
          icon: 'fas fa-fw fa-house',
          label: this.$t('xTankDetails')
        },
        {
          name: 'tankDetails',
          icon: 'fas fa-fw fa-tank-water',
          label: this.$t('xTankSettings')
        },
        {
          name: 'fuelLevelWarning',
          icon: 'far fa-fw fa-warning',
          label: this.$t('xFuelLevelWarning')
        },
        {
          name: 'subscription',
          icon: 'fas fa-fw fa-badge-dollar',
          label: this.$t('xSubscription')
        },
        {
          name: 'fuelProvider',
          icon: 'fas fa-fw fa-user-hard-hat',
          label: this.$t('xFuelProvider')
        },
        {
          name: 'fuelConsumption',
          icon: 'fas fa-fw fa-meter-droplet',
          label: this.$t('xFuelConsumption')
        }
      ],
      freeFuelTankChildRoutes: [
        {
          name: 'deviceDetails',
          icon: 'fas fa-fw fa-house',
          label: this.$t('xTankDetails')
        },
        {
          name: 'tankDetails',
          icon: 'fas fa-fw fa-tank-water',
          label: this.$t('xTankSettings')
        },
        {
          name: 'fuelLevelWarning',
          icon: 'far fa-fw fa-warning',
          label: this.$t('xFuelLevelWarning')
        },
        {
          name: 'fuelProvider',
          icon: 'fas fa-fw fa-user-hard-hat',
          label: this.$t('xFuelProvider')
        },
        {
          name: 'fuelConsumption',
          icon: 'fas fa-fw fa-meter-droplet',
          label: this.$t('xFuelConsumption')
        }
      ],
      portableGenChildRoutes: [
        {
          name: 'deviceDetails',
          icon: 'fas fa-house fa-fw',
          label: this.$t('xDeviceDetails')
        },
        {
          name: 'preferredServiceDealer',
          icon: 'fas fa-user-hard-hat fa-lg',
          label: this.$t('xPreferredServiceDealer')
        },
        {
          name: 'warranty',
          icon: 'fas fa-award fa-fw',
          label: this.$t('xWarranty')
        },
        {
          name: 'maintenance',
          icon: 'fas fa-toolbox fa-fw',
          label: this.$t('xMaintenance')
        },
        {
          name: 'technicalSpecs',
          icon: 'fas fa-clipboard-list fa-fw',
          label: this.$t('xTechnicalSpecs')
        }
      ]
    }
  },
  computed: {
    ...mapState({
      currentApparatusDetails: state => state.apparatus.currentApparatusDetails,
      currentApparatusTechnicalSpecs: state => state.apparatus.currentApparatusTechnicalSpecs,
      currentAppConfig: state => state.application.currentAppConfig,
      bannerMessage: state => state.application.bannerMessage,
      cdmaWarning: state => state.cdmaBanner.cdmaWarning,
      accountProfile: state => state.account.accountProfile
    }),
    billingMigrationFeatureFlag() {
      return Constants.BillingMigrationFeatureFlag
    },
    currentRoute() {
      return this.childRoutes.find(r => r.name == this.$route.name)
    },
    hsbApparatusType() {
      return Constants.HsbApparatusType
    },
    portableGenApparatusType() {
      return Constants.PortableGeneratorApparatusType
    },
    getPartsURL() {
      var encodedURL = 'https://generac.ordertree.com/search/?q=' + encodeURIComponent(this.technicalSpecs.description)
      return encodedURL
    }
  },
  methods: {
    removeGenerator() {
      this.removing = true
      this.$store
        .dispatch('removeGenerator', {
          organizationId: this.accountProfile.organizationId,
          apparatusId: this.apparatusDetails.apparatusId
        })
        .then(response => {
          this.$store.dispatch('displayToast', {
            title: this.$t('xSuccess'),
            message: this.$t('xWasRemoved', { 0: this.apparatusDetails.name }),
            variant: 'success'
          })
          this.$store.dispatch('apparatusReset')
          this.$router.push({
            path: `/dashboard`
          })
        })
        .catch(error => {
          this.$store.dispatch('displayToast', {
            title: this.$t('xError'),
            message: this.$t('xWasNotRemoved', { 0: this.apparatusDetails.name }),
            variant: 'danger'
          })
        })
        .finally(() => {
          this.removing = false
        })
    },
    goTo(routeName) {
      this.$router.push({ name: routeName })
    },
    isHsbApparatus() {
      if (
        this.currentApparatusDetails &&
        this.currentApparatusDetails.apparatusClassification == this.hsbApparatusType
      ) {
        return true
      } else return false
    },
    removeExtraChars(testhook) {
      return testhook
        .split(' ')
        .join('')
        .split('/')
        .join('')
    }
  },
  watch: {
    currentApparatusDetails: {
      handler: function(newVal, oldVal) {
        this.apparatusDetails = _.cloneDeep(newVal)
        if (this.apparatusDetails.apparatusClassification == Constants.HsbApparatusType) {
          if (this.virtualPowerPlantEnabled(this.currentAppConfig) && this.apparatusDetails.enrolledInVpp) {
            let alreadyHasVppPushed = false
            this.childRoutes.forEach(element => {
              if (element.name === 'virtualPowerPlant') alreadyHasVppPushed = true
            })
            if (!alreadyHasVppPushed) {
              this.childRoutes.push({
                name: 'virtualPowerPlant',
                icon: 'fas fa-plug fa-lg',
                label: this.$t('xVirtualPowerPlant')
              })
            }
          }
        } else if (this.apparatusDetails.apparatusClassification == Constants.FuelTankApparatusType) {
          this.childRoutes = []
          if (this.apparatusDetails.deviceType == Constants.TankUtilityLteDeviceTypeV2)
            this.childRoutes = _.cloneDeep(this.paidFuelTankChildRoutes)
          else this.childRoutes = _.cloneDeep(this.freeFuelTankChildRoutes)
        } else if (
          this.PortablesEnabledFF(this.currentAppConfig) &&
          this.apparatusDetails.apparatusClassification == Constants.PortableGeneratorApparatusType
        ) {
          this.childRoutes = _.cloneDeep(this.portableGenChildRoutes)
        } else this.childRoutes = []
      },
      deep: true
    },
    currentApparatusTechnicalSpecs: {
      handler: function(newVal, oldVal) {
        this.technicalSpecs = _.cloneDeep(newVal)
      },
      deep: true
    }
  },
  beforeMount() {
    if (!this.cdmaWarning) this.$store.dispatch('getCdmaWarning')
  },
  mounted() {
    this.$store.dispatch('getApparatusDetails', this.$route.params.apparatusId)
    if (!this.currentAppConfig) this.$store.dispatch('getApplicationConfiguration', 'Any')
    this.$store.dispatch('getApparatusTechnicalSpecs', this.$route.params.apparatusId)
    if (!this.accountProfile) this.$store.dispatch('getAccountProfile')
  }
}
</script>

<style scoped lang="scss">
@media (min-width: 768px) {
  #childRouteContainer {
    height: calc(100vh - 72px - 96px) !important;
    overflow-y: auto;
  }
}
#detailsMenu {
  height: calc(100vh - 160px);
  background-color: var(--blue-900);
  padding-top: 1em;

  .list-group-item {
    background-color: var(--blue-900);
    padding: 1rem 1.25rem;
    //border-left: solid 5px var(--carbon-600);
    display: flex;
    align-items: center;
    color: var(--white);
    border-radius: 9999px;
    margin: 0 1rem;
    border-width: 0;

    p {
      margin-bottom: 0;
      line-height: 1;
    }

    i {
      color: var(--white);
      margin-right: 5px;
    }

    &:hover {
      background-color: var(--blue-700);
      color: var(--white);

      i {
        color: var(--white);
      }
    }
  }
  .list-group-item-action {
    width: auto;
  }

  .router-link-exact-active {
    background-color: var(--blue-800);
    color: var(--white);

    i {
      color: var(--white) !important;
    }

    &:hover {
      background-color: var(--blue-800);
    }
  }
}
</style>
