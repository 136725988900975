var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Modal",
    { attrs: { modalId: "EditPrimaryInfoModal-" + _vm.modalInfo.apparatusId } },
    [
      _c(
        "ModalHeader",
        {
          attrs: {
            title: _vm.$t("xEditPrimaryContactInfo"),
            icon: "fas fa-user-circle"
          }
        },
        [
          _c(
            "button",
            {
              staticClass: "btn btn-icon-button btn-icon-button--gray",
              attrs: { "data-test-hook": "x-button-edit-primary" },
              on: {
                click: function($event) {
                  _vm.saving
                    ? null
                    : (_vm.resetModal(),
                      _vm.closeModal(
                        "EditPrimaryInfoModal-" + _vm.modalInfo.apparatusId
                      ))
                }
              }
            },
            [_c("i", { staticClass: "fas fa-times" })]
          )
        ]
      ),
      _vm.editValue
        ? _c("ModalBody", [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col" }, [
                _c(
                  "div",
                  { staticClass: "form-group", attrs: { id: "phone-group" } },
                  [
                    _c(
                      "label",
                      { staticClass: "d-block", attrs: { for: "phone" } },
                      [_vm._v(_vm._s(_vm.$t("xPhoneNumber")))]
                    ),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.$v.editValue.phone.$model,
                          expression: "$v.editValue.phone.$model"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        id: "phone",
                        maxlength: "12",
                        type: "text",
                        state: _vm.validState(_vm.$v.editValue.phone),
                        "aria-describedby": "phoneNumber-invalid"
                      },
                      domProps: { value: _vm.$v.editValue.phone.$model },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.$v.editValue.phone,
                            "$model",
                            $event.target.value
                          )
                        }
                      }
                    }),
                    _c(
                      "div",
                      {
                        staticClass: "invalid-feedback",
                        attrs: { id: "phoneNumber-invalid" }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("xRequiredField")) +
                            ". " +
                            _vm._s(_vm.$t("xPleaseEnterAValidPhoneNumber")) +
                            " "
                        )
                      ]
                    )
                  ]
                )
              ])
            ]),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col" }, [
                _c(
                  "div",
                  { staticClass: "form-group", attrs: { id: "email-group" } },
                  [
                    _c(
                      "label",
                      { staticClass: "d-block", attrs: { for: "email" } },
                      [_vm._v(_vm._s(_vm.$t("xEmailAddress")))]
                    ),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.$v.editValue.email.$model,
                          expression: "$v.editValue.email.$model"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        id: "email",
                        disabled: "",
                        maxlength: "70",
                        type: "text",
                        state: _vm.validState(_vm.$v.editValue.email),
                        "aria-describedby": "email-invalid"
                      },
                      domProps: { value: _vm.$v.editValue.email.$model },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.$v.editValue.email,
                            "$model",
                            $event.target.value
                          )
                        }
                      }
                    }),
                    _c(
                      "div",
                      {
                        staticClass: "invalid-feedback",
                        attrs: { id: "email-invalid" }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("xRequiredField")) +
                            ". " +
                            _vm._s(_vm.$t("xEmailInvalidError")) +
                            " "
                        )
                      ]
                    )
                  ]
                )
              ])
            ])
          ])
        : _vm._e(),
      _c("ModalFooter", [
        _c(
          "button",
          {
            staticClass: "btn btn-outline-warning",
            attrs: {
              type: "button",
              disabled: _vm.saving,
              "data-test-hook": "cancel-button-edit-primary"
            },
            on: {
              click: function($event) {
                _vm.resetModal(),
                  _vm.closeModal(
                    "EditPrimaryInfoModal-" + _vm.modalInfo.apparatusId
                  )
              }
            }
          },
          [_vm._v(" " + _vm._s(_vm.$t("xCancel")) + " ")]
        ),
        _c(
          "button",
          {
            staticClass: "btn btn-warning",
            attrs: {
              type: "button",
              disabled: _vm.canSave(),
              "data-test-hook": "save-button-edit-primary"
            },
            on: {
              click: function($event) {
                return _vm.savePrimaryContactInfo()
              }
            }
          },
          [
            _vm._v(" " + _vm._s(_vm.$t("xSave")) + " "),
            _vm.saving
              ? _c("div", {
                  staticClass: "ml-2 spinner-border text-muted",
                  attrs: {
                    "data-test-hook": "save-button-spinner-edit-primary"
                  }
                })
              : _vm._e()
          ]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }