<template>
  <div>
    <div v-if="!accountProfile" class="d-flex justify-content-center mb-3">
      <div class="spinner-border text-warning ml-2" style="width: 10rem; height: 10rem;"></div>
    </div>
    <div v-else>
      <div v-if="!accountProfile.hasActiveSubscription" class="row noActiveSubscription py-3">
        <div class="col-11 breadcrumb-link hoverPointer" @click="goTo('dashboard')">
          {{ $t('xSomeAccountFeaturesAreCurrentlyDisabled') }}
          <i class="far fa-arrow-right"></i>
        </div>
        <div class="col-1">
          <i class="fas fa-times hoverPointer mr-2" @click="hideBanner = true"></i>
        </div>
      </div>

      <div v-if="cdmaWarning" @click="trackAppInsightsEvent('cdma-banner-click')" class="pt-3">
        <a :href="cdmaWarning.link" target="_blank" style="text-decoration: none !important;">
          <div role="alert" class="alert alert-warning hoverPointer mb-0" show>
            <h2>
              <i class="fas fa-exclamation-triangle banner-category-warning"></i>
              {{ cdmaWarning.title }}
            </h2>
            <p>
              {{ cdmaWarning.message }}
            </p>
            <p class="mb-0 pb-0">
              {{ cdmaWarning.linkText }}
              <i class="fas fa-arrow-right" />
            </p>
          </div>
        </a>
      </div>
      <div
        role="alert"
        v-if="
          currentAppConfig &&
            currentAppConfig.featureFlags &&
            currentAppConfig.featureFlags[billingMigrationFeatureFlag]
        "
        show
        class="alert alert-warning"
      >
        {{ $t('xBillingMaintenanceMessage') }}
      </div>
      <div @click="$bvModal.show('banner-message-modal')">
        <div
          role="alert"
          v-if="showBannerMessage(bannerMessage)"
          class="hoverPointer alert"
          show
          :class="getBannerVariant(bannerMessage)"
        >
          <i :class="getBannerIconClassAndColor(bannerMessage)" />
          {{ bannerMessage.BannerMessage }}
          <strong>
            {{ $t('xLearnMore') }}
            <i class="fas fa-arrow-right" />
          </strong>
        </div>
      </div>

      <div class="row justify-content-between">
        <!-- Web view -->
        <div class="d-none d-md-block px-0 col-md-4 col-lg-3 col-0">
          <b-list-group class="nav account-nav-wrapper flex-column" id="settingsMenu">
            <b-list-group-item
              class="nav-item"
              v-for="route in childRoutes"
              :key="route.name"
              :to="{ name: route.name }"
              :class="$route.name == route.name"
              :data-test-hook="
                route.label
                  .toLowerCase()
                  .replace(/\s/g, '-')
                  .concat('-nav-btn-account-settings')
              "
            >
              <p :data-test-hook="route.label.replace(` `, ``) + `Btn`">
                <i :class="route.icon"></i> {{ route.label }}
              </p>
            </b-list-group-item>
          </b-list-group>
        </div>
        <div class="col-md" id="childRouteContainer">
          <transition name="fade" mode="out-in">
            <router-view />
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import * as Constants from '@/constants'

export default {
  data() {
    return {
      defaulted: false,
      saving: null,
      hideBanner: false,
      expandedMenu: false,
      childRoutes: [],
      childRoutesNoDocsOrStmnts: [
        {
          menuNo: 0,
          name: 'accountInformation',
          icon: 'far fa-fw fa-user-circle ',
          label: this.$t('xAccountInformation')
        },
        {
          menuNo: 2,
          name: 'settingsNotifications',
          icon: 'far fa-fw fa-bell  ',
          label: this.$t('xNotifications')
        },
        {
          menuNo: 3,
          name: 'displayPreferences',
          icon: 'far fa-fw fa-tv ',
          label: this.$t('xDisplayPreferences')
        }
      ],
      childRoutesWithDocsAndStmnts: [
        {
          menuNo: 0,
          name: 'accountInformation',
          icon: 'far fa-fw fa-user-circle ',
          label: this.$t('xAccountInformation')
        },
        {
          menuNo: 1,
          name: 'documentsStatements',
          icon: 'far fa-fw fa-file-alt ',
          label: this.$t('xDocumentsStatements')
        },
        {
          menuNo: 2,
          name: 'settingsNotifications',
          icon: 'far fa-fw fa-bell  ',
          label: this.$t('xNotifications')
        },
        {
          menuNo: 3,
          name: 'displayPreferences',
          icon: 'far fa-fw fa-tv ',
          label: this.$t('xDisplayPreferences')
        }
      ]
    }
  },
  computed: {
    ...mapState({
      accountProfile: state => state.account.accountProfile,
      currentAppConfig: state => state.application.currentAppConfig,
      bannerMessage: state => state.application.bannerMessage,
      cdmaWarning: state => state.cdmaBanner.cdmaWarning
    }),
    billingMigrationFeatureFlag() {
      return Constants.BillingMigrationFeatureFlag
    }
  },
  methods: {
    goTo(routeName) {
      this.$router.push({ name: routeName })
    },
    setMenuSize() {
      if (document.documentElement.clientWidth < 768) {
        this.expandedMenu = false
      } else {
        this.expandedMenu = true
      }
    },
    async prepSignOut() {
      let prevChoice = _.cloneDeep(this.selected)
      this.selected = this.logoutMenuNo
      await this.signOut()
      this.selected = prevChoice
    }
  },
  watch: {
    currentAppConfig: function(newVal, oldVal) {
      if (newVal && this.documentsStatementsPageEnabled(newVal)) {
        this.childRoutes = this.childRoutesWithDocsAndStmnts
      } else this.childRoutes = this.childRoutesNoDocsOrStmnts
    }
  },
  beforeMount() {
    if (!this.cdmaWarning) this.$store.dispatch('getCdmaWarning')
  },
  async mounted() {
    if (!this.accountProfile) this.$store.dispatch('getAccountProfile')
    if (!this.currentAppConfig) await this.$store.dispatch('getApplicationConfiguration', 'Any')
    else if (this.documentsStatementsPageEnabled(this.currentAppConfig)) {
      this.childRoutes = this.childRoutesWithDocsAndStmnts
    } else this.childRoutes = this.childRoutesNoDocsOrStmnts
    window.addEventListener('resize', this.setMenuSize)
    this.setMenuSize()

    if (this.$route.params.statementTabActive && this.documentsStatementsPageEnabled(this.currentAppConfig)) {
      this.$router.push({ name: 'documentsStatements' })
    }
  },
  unmount() {
    window.removeEventListener('resize', this.setMenuSize)
  },
  updated() {
    if (this.$route.params.statementTabActive && this.documentsStatementsPageEnabled(this.currentAppConfig)) {
      this.$router.push({ name: 'documentsStatements' })
    }
  }
}
</script>

<style scoped lang="scss">
.noActiveSubscription {
  background-color: var(--yellow-200);

  .breadcrumb-link {
    color: var(--carbon-900);
    font-weight: 500;
  }
}
@media (min-width: 768px) {
  #childRouteContainer {
    height: calc(100vh - 72px - 96px) !important;
    overflow-y: auto;
  }
}
#settingsMenu {
  height: calc(100vh - 160px);
  background-color: var(--blue-900);
  padding-top: 1em;

  .list-group-item {
    background-color: var(--blue-900);
    padding: 1rem 1.25rem;
    //border-left: solid 5px var(--carbon-600);
    display: flex;
    align-items: center;
    color: var(--white);
    border-radius: 9999px;
    margin: 0 1rem;
    border-width: 0;

    p {
      margin-bottom: 0;
      line-height: 1;
    }

    i {
      color: var(--white);
      margin-right: 5px;
    }

    &:hover {
      background-color: var(--blue-700);
      color: var(--white);

      i {
        color: var(--white);
      }
    }
  }
  .list-group-item-action {
    width: auto;
  }

  .router-link-exact-active {
    background-color: var(--blue-800);
    color: var(--white);

    i {
      color: var(--white) !important;
    }

    &:hover {
      background-color: var(--blue-800);
    }
  }
}
</style>
