<template>
  <Modal modalId="EditPreferencesModal">
    <ModalHeader :title="$t('xEditDisplayPreferences')" icon="fas fa-user-cog">
      <button
        class="btn btn-icon-button btn-icon-button--gray"
        @click="closeModal('EditPreferencesModal')"
        data-test-hook="x-button-edit-preferences-modal"
      >
        <i class="fas fa-times" />
      </button>
    </ModalHeader>
    <ModalBody>
      <div class="row">
        <div class="col">
          <h5 class="mb-1">
            {{ $t('xDistanceUnit') }}
          </h5>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <fieldset class="form-group">
            <div class="custom-control custom-radio">
              <input
                class="custom-control-input"
                type="radio"
                id="milesRadio"
                v-model="distanceUom"
                value="miles"
                data-test-hook="mi-radio-edit-preferences-modal"
              />
              <label class="custom-control-label" for="milesRadio">
                {{ $t('xMiles') }}
              </label>
            </div>
            <div class="custom-control custom-radio">
              <input
                class="custom-control-input"
                type="radio"
                id="kiloMetersRadio"
                v-model="distanceUom"
                value="kilometers"
                data-test-hook="km-radio-edit-preferences-modal"
              />
              <label class="custom-control-label" for="kiloMetersRadio">
                {{ $t('xKilometers') }}
              </label>
            </div>
          </fieldset>
        </div>
      </div>
      <hr />
      <div class="row">
        <div class="col">
          <h5 class="headerInline">
            {{ $t('xTemperatureUnit') }}
          </h5>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <fieldset class="form-group">
            <div class="custom-control custom-radio">
              <input
                class="custom-control-input"
                type="radio"
                id="fahrenheitRadio"
                v-model="temperatureUom"
                value="fahrenheit"
                data-test-hook="fahrenheit-radio-edit-preferences-modal"
              />
              <label class="custom-control-label" for="fahrenheitRadio">
                {{ $t('xFahrenheit') }}
              </label>
            </div>
            <div class="custom-control custom-radio">
              <input
                class="custom-control-input"
                type="radio"
                id="celsiusRadio"
                v-model="temperatureUom"
                value="celsius"
                data-test-hook="celsius-radio-edit-preferences-modal"
              />
              <label class="custom-control-label" for="celsiusRadio">
                {{ $t('xCelsius') }}
              </label>
            </div>
          </fieldset>
        </div>
      </div>
      <hr />
      <div class="row">
        <div class="col">
          <h5 class="headerInline">
            {{ $t('xLiquidUnit') }}
          </h5>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <fieldset class="form-group">
            <div class="custom-control custom-radio">
              <input
                class="custom-control-input"
                type="radio"
                id="gallonsRadio"
                v-model="liquidUom"
                value="gallons"
                data-test-hook="gallons-radio-edit-preferences-modal"
              />
              <label class="custom-control-label" for="gallonsRadio">
                {{ $t('xGallons') }}
              </label>
            </div>
            <div class="custom-control custom-radio">
              <input
                class="custom-control-input"
                type="radio"
                id="litersRadio"
                v-model="liquidUom"
                value="liters"
                data-test-hook="liters-radio-edit-preferences-modal"
              />
              <label class="custom-control-label" for="litersRadio">
                {{ $t('xLiters') }}
              </label>
            </div>
          </fieldset>
        </div>
      </div>
    </ModalBody>

    <ModalFooter>
      <button class="btn btn-outline-warning" @click="cancel()" data-test-hook="cancel-button-edit-preferences-modal">
        {{ $t('xCancel') }}
      </button>
      <button class="btn btn-warning" @click="saveUnits()" data-test-hook="save-button-edit-preferences-modal">
        {{ $t('xSave') }}
        <div class="ml-2 spinner-border text-muted" label="Spinning" v-if="saving"></div>
      </button>
    </ModalFooter>
  </Modal>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data() {
    return {
      saving: false,
      distanceUom: 'miles',
      temperatureUom: 'fahrenheit',
      liquidUom: 'gallons',
      startingDistanceUom: null,
      startingTemperatureUom: null,
      startingLiquidUom: null
    }
  },
  computed: {
    ...mapState({
      accountProfile: state => state.account.accountProfile,
      user: state => state.account.user,
      isImpersonating: state => state.auth.isImpersonating
    })
  },
  methods: {
    saveUnits() {
      if (
        this.startingDistanceUom == this.distanceUom &&
        this.startingTemperatureUom == this.temperatureUom &&
        this.startingLiquidUom == this.liquidUom
      ) {
        this.closeModal('EditPreferencesModal')
        return
      }
      this.saving = true
      let mobileLinkSettings = _.cloneDeep(this.user.mobileLinkSettings)
      mobileLinkSettings.displaySettings.distanceUom = this.distanceUom
      mobileLinkSettings.displaySettings.temperatureUom = this.temperatureUom
      mobileLinkSettings.displaySettings.liquidUom = this.liquidUom
      let request = {
        displaySettings: mobileLinkSettings.displaySettings
      }
      this.$store
        .dispatch('updateMobileLinkSettings', request)
        .then(response => {
          if (!this.isImpersonating) {
            this.$store.dispatch('getUser').then(response => {
              this.closeModal('EditPreferencesModal')
            })
          } else {
            this.$store.dispatch('getImpersonatedUser').then(response => {
              this.closeModal('EditPreferencesModal')
            })
          }
        })
        .catch(error => {
          this.$store.dispatch('displayToast', {
            title: this.$t('xError'),
            message: this.$t('xFailedToUpdateSettings'),
            variant: 'danger'
          })
        })
        .finally(() => {
          this.saving = false
        })
    },
    cancel() {
      this.closeModal('EditPreferencesModal')
      this.distanceUom = this.user.mobileLinkSettings?.displaySettings?.distanceUom
        ? this.user.mobileLinkSettings.displaySettings.distanceUom
        : 'miles'
      this.temperatureUom = this.user.mobileLinkSettings?.displaySettings?.temperatureUom
        ? this.user.mobileLinkSettings.displaySettings.temperatureUom
        : 'fahrenheit'
      this.liquidUom = this.user.mobileLinkSettings?.displaySettings?.liquidUom
        ? this.user.mobileLinkSettings.displaySettings.liquidUom
        : 'gallons'
    }
  },
  mounted() {
    this.distanceUom = this.user.mobileLinkSettings?.displaySettings?.distanceUom
      ? this.user.mobileLinkSettings.displaySettings.distanceUom
      : 'miles'
    this.startingDistanceUom = _.cloneDeep(this.distanceUom)

    this.temperatureUom = this.user.mobileLinkSettings?.displaySettings?.temperatureUom
      ? this.user.mobileLinkSettings.displaySettings.temperatureUom
      : 'fahrenheit'
    this.startingTemperatureUom = _.cloneDeep(this.temperatureUom)

    this.liquidUom = this.user.mobileLinkSettings?.displaySettings?.liquidUom
      ? this.user.mobileLinkSettings.displaySettings.liquidUom
      : 'gallons'
    this.startingLiquidUom = _.cloneDeep(this.liquidUom)
  }
}
</script>

<style scoped>
.editBottom {
  bottom: 0;
}
</style>
