<template>
  <div v-if="!manuals && !loaded" class="mb-3">
    <div class="row text-center">
      <div class="col" data-test-hook="manualsLoadingMsg">
        {{ $t('xYourManualsAreLoadingThankyouForYourPatience') }}
      </div>
    </div>
    <div class="row mt-3 text-center">
      <div class="col">
        <span class="spinner-border text-warning" style="width: 5rem; height: 5rem;"></span>
      </div>
    </div>
  </div>
  <div v-else-if="!manuals && loaded">
    <span data-test-hook="noManualsMsg">{{ $t('xNoProductManuals') }}</span>
  </div>
  <div v-else>
    <table head-variant="light" class="table table-striped table-hover search-table" :items="manuals" stacked="md">
      <thead>
        <tr>
          <th data-test-hook="manualTypeHeader">{{ $t('xManualType') }}</th>
          <th data-test-hook="manualDescHeader">{{ $t('xManualPdf') }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(manual, index) in manuals" :key="manual.id">
          <td :data-test-hook="'manualType' + index">{{ manual.description }}</td>
          <td class="font-weight-bold" :data-test-hook="'manualDesc' + index">
            <a :href="manual.uri"><i class="fas fa-download mr2"></i> {{ manual.name }}</a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  props: ['manuals', 'loaded'],
  data() {
    return {
      currentPage: 1,
      perPage: 100,
      totalRows: 1
    }
  },
  mounted() {
    if (this.manuals) {
      this.totalRows = this.manuals.length
      this.perPage = this.manuals.length
    }
  }
}
</script>
