var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.messageCenterEnabled(_vm.currentAppConfig)
    ? _c(
        "div",
        [
          _vm.cdmaWarning
            ? _c(
                "div",
                {
                  staticClass: "pt-3",
                  on: {
                    click: function($event) {
                      return _vm.trackAppInsightsEvent("cdma-banner-click")
                    }
                  }
                },
                [
                  _c(
                    "b-link",
                    {
                      staticStyle: { "text-decoration": "none !important" },
                      attrs: { href: _vm.cdmaWarning.link, target: "_blank" }
                    },
                    [
                      _c(
                        "b-alert",
                        {
                          staticClass: "hoverPointer mb-0",
                          attrs: { variant: "warning", show: "" }
                        },
                        [
                          _c(
                            "b-row",
                            [
                              _c("b-col", [
                                _c("h2", [
                                  _c("i", {
                                    staticClass:
                                      "fas fa-exclamation-triangle banner-category-warning"
                                  }),
                                  _vm._v(
                                    " " + _vm._s(_vm.cdmaWarning.title) + " "
                                  )
                                ]),
                                _c("p", [
                                  _vm._v(_vm._s(_vm.cdmaWarning.message))
                                ]),
                                _c("p", { staticClass: "mb-0 pb-0" }, [
                                  _vm._v(
                                    " " + _vm._s(_vm.cdmaWarning.linkText) + " "
                                  ),
                                  _c("i", { staticClass: "fas fa-arrow-right" })
                                ])
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            {
              on: {
                click: function($event) {
                  return _vm.$bvModal.show("banner-message-modal")
                }
              }
            },
            [
              _vm.showBannerMessage(_vm.bannerMessage)
                ? _c(
                    "b-alert",
                    {
                      staticClass: "hoverPointer",
                      attrs: {
                        show: "",
                        variant: _vm.getBannerVariant(_vm.bannerMessage)
                      }
                    },
                    [
                      _c("i", {
                        class: _vm.getBannerIconClassAndColor(_vm.bannerMessage)
                      }),
                      _vm._v(
                        " " + _vm._s(_vm.bannerMessage.BannerMessage) + " "
                      ),
                      _c("strong", [
                        _vm._v(" " + _vm._s(_vm.$t("xLearnMore")) + " "),
                        _c("i", { staticClass: "fas fa-arrow-right" })
                      ])
                    ]
                  )
                : _vm._e()
            ],
            1
          ),
          _vm.editPreferencesValue
            ? _c(
                "b-row",
                { staticClass: "h-100 py-4" },
                [
                  _c(
                    "b-col",
                    {
                      staticClass: "pr-md-0",
                      attrs: { xl: "4", md: "5", sm: "12" }
                    },
                    [
                      _c(
                        "b-card",
                        {
                          staticClass: "h-100",
                          attrs: {
                            "body-class": "no-padding",
                            "header-bg-variant": "white"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "header",
                                fn: function() {
                                  return [
                                    _c(
                                      "b-row",
                                      {
                                        attrs: {
                                          "align-v": "center",
                                          "no-gutters": ""
                                        }
                                      },
                                      [
                                        _c("b-col", [
                                          _c(
                                            "h1",
                                            {
                                              staticClass: "mb-0 pb-0",
                                              attrs: {
                                                "data-test-hook":
                                                  "msgCenterPageHeader"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("xMessageCenterInbox")
                                                )
                                              )
                                            ]
                                          )
                                        ]),
                                        _c(
                                          "b-col",
                                          {
                                            staticClass: "float-right",
                                            attrs: { cols: "auto" }
                                          },
                                          [
                                            _c(
                                              "b-button",
                                              {
                                                attrs: {
                                                  variant: "icon-button",
                                                  "data-test-hook":
                                                    "msgCenterSettingsBtn"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.$bvModal.show(
                                                      "message-center-settings-modal"
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "far fa-cog m-0 px-1",
                                                  attrs: {
                                                    "data-test-hook":
                                                      "msgCenterSettingsBtnIcon"
                                                  }
                                                })
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                },
                                proxy: true
                              }
                            ],
                            null,
                            false,
                            2960914072
                          )
                        },
                        [
                          _c(
                            "b-row",
                            { attrs: { "no-gutters": "" } },
                            [
                              _c(
                                "b-col",
                                [
                                  _c("MessagePreview", {
                                    on: {
                                      messageDetails: _vm.setMessageDetails
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { xl: "8", md: "7", sm: "12" } },
                    [
                      _c("MessageDetails", {
                        attrs: { value: _vm.messageDetails },
                        on: { selectedCategory: _vm.setSelectedCategory }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "b-modal",
            {
              attrs: { id: "message-center-settings-modal", centered: "" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "modal-header",
                    fn: function() {
                      return [
                        _c(
                          "b-row",
                          {
                            staticClass: "w-100",
                            attrs: { "align-v": "center", "no-gutters": "" }
                          },
                          [
                            _c("b-col", [
                              _c(
                                "h2",
                                {
                                  staticClass: "modal-title",
                                  attrs: { "data-test-hook": "modalTitle" }
                                },
                                [
                                  _c("i", {
                                    staticClass:
                                      "far fa-bell-on colorOrange mr-1",
                                    attrs: { "data-test-hook": "modalIcon" }
                                  }),
                                  _c("span", { staticClass: "ml-2" }, [
                                    _vm._v(
                                      "Message Center Notification Settings"
                                    )
                                  ])
                                ]
                              )
                            ])
                          ],
                          1
                        )
                      ]
                    },
                    proxy: true
                  },
                  {
                    key: "modal-footer",
                    fn: function() {
                      return [
                        _c(
                          "b-button",
                          {
                            attrs: {
                              variant: "primary",
                              "data-test-hook": "modalDoneBtn"
                            },
                            on: {
                              click: function($event) {
                                return _vm.$bvModal.hide(
                                  "message-center-settings-modal"
                                )
                              }
                            }
                          },
                          [_vm._v(" Done ")]
                        )
                      ]
                    },
                    proxy: true
                  }
                ],
                null,
                false,
                3123584599
              )
            },
            [
              _c(
                "b-row",
                [
                  _c("b-col", [
                    _c(
                      "p",
                      { attrs: { "data-test-hook": "modalBodyHeader" } },
                      [_vm._v("Receive message center notifications via:")]
                    ),
                    _c(
                      "div",
                      { staticClass: "d-flex align-items-center mt-3" },
                      [
                        _c("div", { staticClass: "notification-type-icon" }, [
                          _c("i", {
                            staticClass: "far fa-envelope",
                            attrs: { "data-test-hook": "modalEmailToggleIcon" }
                          })
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "flex-grow-1 ml-2 mr-4",
                            attrs: { "data-test-hook": "modalEmailToggleText" }
                          },
                          [_vm._v(_vm._s(_vm.$t("xEmail")))]
                        ),
                        _c(
                          "div",
                          [
                            _c("b-form-checkbox", {
                              staticClass: "switch",
                              class: _vm.editPreferencesValue.receiveEmail
                                ? "switch-success"
                                : null,
                              attrs: {
                                switch: "",
                                size: "lg",
                                disabled: _vm.savingEmailPreference
                              },
                              model: {
                                value: _vm.editPreferencesValue.receiveEmail,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.editPreferencesValue,
                                    "receiveEmail",
                                    $$v
                                  )
                                },
                                expression: "editPreferencesValue.receiveEmail"
                              }
                            })
                          ],
                          1
                        )
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "d-flex align-items-center mt-3" },
                      [
                        _c("div", { staticClass: "notification-type-icon" }, [
                          _c("i", {
                            staticClass: "far fa-mobile-android-alt",
                            attrs: {
                              "data-test-hook": "modalTextSmsToggleIcon"
                            }
                          })
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "flex-grow-1 ml-2 mr-4",
                            attrs: {
                              "data-test-hook": "modalTextSmsToggleText"
                            }
                          },
                          [_vm._v(_vm._s(_vm.$t("xTextSms")))]
                        ),
                        _c(
                          "div",
                          [
                            _c("b-form-checkbox", {
                              staticClass: "switch",
                              class: _vm.editPreferencesValue.receiveSms
                                ? "switch-success"
                                : null,
                              attrs: {
                                size: "lg",
                                switch: "",
                                disabled: _vm.savingSmsPreference
                              },
                              model: {
                                value: _vm.editPreferencesValue.receiveSms,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.editPreferencesValue,
                                    "receiveSms",
                                    $$v
                                  )
                                },
                                expression: "editPreferencesValue.receiveSms"
                              }
                            })
                          ],
                          1
                        )
                      ]
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }