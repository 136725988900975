<template>
  <div class="card main-card mt-2">
    <div class="card-header">
      <ul class="nav nav-tabs card-header-tabs nav-fill">
        <li class="nav-item hoverPointer" @click="tabIndex = 0" data-test-hook="owner-info-tab-email-search-result">
          <a class="nav-link" :class="isActive(0)">Owner Information</a>
        </li>
        <li class="nav-item hoverPointer" @click="tabIndex = 1" data-test-hook="generators-tab-email-search-result">
          <a class="nav-link" :class="isActive(1)"
            ><span class="spinner-border spinner-border-sm" v-if="!orgApparatuses"></span>Generators</a
          >
        </li>
        <li class="nav-item hoverPointer" @click="tabIndex = 2" data-test-hook="notifications-tab-email-search-result">
          <a class="nav-link" :class="isActive(2)"
            ><span class="spinner-border spinner-border-sm" v-if="gettingNotifications"></span>Notifications</a
          >
        </li>
      </ul>
    </div>

    <div class="card-body">
      <div v-if="tabIndex == 0">
        <OwnerInformationTab :value="value" />
      </div>
      <span
        role="status"
        v-if="tabIndex == 1 && !apparatusesLoaded"
        class="spinner-border text-primary text-center float-center"
        style="width: 10rem; height: 10rem;"
      ></span>
      <div v-else-if="tabIndex == 1 && apparatusesLoaded">
        <GeneratorsTab :orgApparatuses="orgApparatuses" />
      </div>
      <div v-else-if="tabIndex == 2">
        <span
          role="status"
          v-if="gettingNotifications"
          class="spinner-border text-primary text-center float-center"
          style="width: 10rem; height: 10rem;"
        />
        <NotificationsTab v-else :value="value" :notifications="notifications" />
      </div>
    </div>

    <div class="card-footer">
      <span id="impersonate-email">
        <button
          type="button"
          class="btn btn-primary mt-2"
          @click="ImpersonateEmail()"
          :disabled="!hasAssociatedAccount(value)"
          data-toggle="tooltip"
          :title="hasAssociatedAccount(value) ? '' : 'No Owner Account'"
          data-test-hook="impersonate-button-email-search-result"
        >
          Impersonate User
        </button>
      </span>
      <span id="transfer-account-btn">
        <button
          type="button"
          class="btn btn-warning ml-2 mt-2"
          @click="showDestinationOrgSearchModal()"
          data-toggle="tooltip"
          :title="
            apparatusesLoaded && orgApparatuses.length > 1
              ? 'Unable to transfer multiple generators, open an escalation ticket'
              : $t('xTransferAccountButtonPopover')
          "
          data-test-hook="transfer-account-button-email-search-result"
          :disabled="
            (currentAppConfig &&
              currentAppConfig.featureFlags &&
              currentAppConfig.featureFlags[billingMigrationFeatureFlag]) ||
              !apparatusesLoaded ||
              (apparatusesLoaded && orgApparatuses.length > 1)
          "
        >
          Update Account Email
        </button>
      </span>
    </div>

    <TransferAccountModal
      :source="value"
      :transferType="transferType"
      @transferEmission="TransferCompleteEmission"
      :apparatuses="orgApparatuses"
    />
  </div>
</template>

<script>
import OwnerInformationTab from '@/components/Admin/OwnerInformationTab'
import GeneratorsTab from '@/components/Admin/GeneratorsTab'
import NotificationsTab from '@/components/Admin/NotificationsTab'
import TransferAccountModal from '@/components/Admin/TransferAccountModal'
import * as Constants from '@/constants'
import { mapState } from 'vuex'

export default {
  components: { OwnerInformationTab, GeneratorsTab, NotificationsTab, TransferAccountModal },
  props: ['value'],
  data() {
    return {
      tabIndex: 0,
      orgApparatuses: null,
      apparatusesLoaded: false,
      gettingNotifications: false,
      notifications: null,
      transferType: null
    }
  },
  computed: {
    ...mapState({
      currentAppConfig: state => state.application.currentAppConfig
    }),
    billingMigrationFeatureFlag() {
      return Constants.BillingMigrationFeatureFlag
    }
  },
  methods: {
    showDestinationOrgSearchModal() {
      this.openModal('destination-org-search' + this.value.organizationId)
    },
    TransferCompleteEmission(transferEmission) {
      this.$emit('transferComplete', transferEmission)
    },
    ImpersonateEmail() {
      // this.$store.dispatch('apparatusReset')
      this.$store.dispatch('clearNotificationApparatuses')
      this.$store.dispatch('setEmail', this.value.ownerInformation.emails[0])
    },
    getNotifications() {
      if (this.value.userId) {
        this.gettingNotifications = true
        this.$store
          .dispatch('getNotificationAudit', {
            userId: this.value.userId,
            apparatusId: 0
          })
          .then(response => {
            this.notifications = response.data
          })
          .catch(error => {
            if (error.response.data.status == 401) {
              this.$store.dispatch('displayToast', {
                title: this.$t('xError'),
                message: 'Your session has expired, please re-log in.',
                variant: 'danger'
              })
              this.forceSignOut()
            }
          })
          .finally(() => {
            this.gettingNotifications = false
          })
      }
    },
    isActive(index) {
      if (index === this.tabIndex) {
        return 'active'
      }
      return ''
    }
  },
  watch: {
    value: function(newVal, oldVal) {
      if (newVal) {
        this.$store
          .dispatch('getOwnerApparatuses', this.value.organizationId)
          .then(response => {
            this.orgApparatuses = response.data
            this.apparatusesLoaded = true
          })
          .catch(error => {
            if (error.response.data.status == 401) {
              this.$store.dispatch('displayToast', {
                title: this.$t('xError'),
                message: 'Your session has expired, please re-log in.',
                variant: 'danger'
              })
              this.forceSignOut()
            } else {
              this.apparatusesLoaded = true
            }
          })
        this.getNotifications()
      }
    }
  },
  mounted() {
    if (!this.currentAppConfig) this.$store.dispatch('getApplicationConfiguration', 'Any')
    this.transferType = Constants.TransferTypeAccount
    if (this.value) {
      this.$store
        .dispatch('getOwnerApparatuses', this.value.organizationId)
        .then(response => {
          this.orgApparatuses = response.data
          this.apparatusesLoaded = true
        })
        .catch(error => {
          if (error.response.data.status == 401) {
            this.$store.dispatch('displayToast', {
              title: this.$t('xError'),
              message: 'Your session has expired, please re-log in.',
              variant: 'danger'
            })
            this.forceSignOut()
          } else {
            this.apparatusesLoaded = true
          }
        })
      this.getNotifications()
    }
  }
}
</script>

<style scoped>
.main-card {
  box-shadow: 0px -3px 20px rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  border: none;
}
</style>
