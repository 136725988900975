<template>
  <div class="row">
    <div class="col-12 col-md-6">
      <h1>Status</h1>
      <div class="d-flex align-items-center mb-3">
        {{ 'Status' | formatLabelNoTranslation }}
        <StatusIcon
          :apparatusStatus="currentStatus.apparatusStatus"
          :isConnected="currentStatus.isConnected"
          :showWarning="currentStatus.showWarning"
          :small="true"
          :deviceType="deviceType == null ? hsbLteDeviceType : deviceType == hsbG3DeviceType ? networkType : deviceType"
        />
      </div>
      <div class="mb-3">
        <p>
          {{ 'Alarm' | formatLabelNoTranslation }}
          <span class="font-weight-bold"> {{ currentStatus.alarm || 'N/A' }}</span>
        </p>
      </div>
      <div class="mb-3">
        <p>
          {{ 'ECode' | formatLabelNoTranslation }}
          <span class="font-weight-bold"> {{ currentStatus.eCode || 'N/A' }}</span>
        </p>
      </div>
      <div class="mb-3">
        <p>
          {{ 'Battery Voltage' | formatLabelNoTranslation }}
          <span class="font-weight-bold"> {{ currentStatus.batteryVoltage || 'N/A' }}</span>
        </p>
      </div>
      <div class="mb-3">
        <p>
          {{ 'RPM' | formatLabelNoTranslation }}
          <span class="font-weight-bold"> {{ currentStatus.rpm || 'N/A' }}</span>
        </p>
      </div>
      <div class="mb-3">
        <p>
          {{ 'Frequency' | formatLabelNoTranslation }}
          <span class="font-weight-bold"> {{ currentStatus.frequency || 'N/A' }}</span>
        </p>
      </div>
      <div class="mb-3">
        <p>
          {{ 'Utility Voltage' | formatLabelNoTranslation }}
          <span class="font-weight-bold"> {{ currentStatus.utilityVoltage || 'N/A' }}</span>
        </p>
      </div>
      <div class="mb-3">
        <p>
          {{ 'Generator Voltage' | formatLabelNoTranslation }}
          <span class="font-weight-bold"> {{ currentStatus.generatorVoltage || 'N/A' }}</span>
        </p>
      </div>
      <div class="mb-3">
        <p>
          {{ 'Run Hours' | formatLabelNoTranslation }}
          <span class="font-weight-bold"> {{ currentStatus.runHours || 'N/A' }}</span>
        </p>
      </div>
    </div>
    <div class="col-12 col-md-6">
      <h1>Maintenance</h1>
      <div class="mb-3">
        <p>
          {{ 'Inspect Air Filter Date' | formatLabelNoTranslation }}
          <span class="font-weight-bold"> {{ displayShortDate(currentStatus.airFilterDate) }}</span>
        </p>
      </div>
      <div class="mb-3">
        <p>
          {{ 'Inspect Air Filter Hours' | formatLabelNoTranslation }}
          <span class="font-weight-bold"> {{ currentStatus.airFilterHours || 'N/A' }}</span>
        </p>
      </div>
      <div class="mb-3">
        <p>
          {{ 'Inspect Oil Filter Date' | formatLabelNoTranslation }}
          <span class="font-weight-bold"> {{ displayShortDate(currentStatus.oilFilterDate) }}</span>
        </p>
      </div>
      <div class="mb-3">
        <p>
          {{ 'Inspect Oil Filter Hours' | formatLabelNoTranslation }}
          <span class="font-weight-bold"> {{ currentStatus.oilFilterHours || 'N/A' }}</span>
        </p>
      </div>
      <div class="mb-3">
        <p>
          {{ 'Inspect Spark Plugs Date' | formatLabelNoTranslation }}
          <span class="font-weight-bold"> {{ displayShortDate(currentStatus.sparkPlugDate) }}</span>
        </p>
      </div>
      <div class="mb-3">
        <p>
          {{ 'Inspect Spark Plugs Hours' | formatLabelNoTranslation }}
          <span class="font-weight-bold"> {{ currentStatus.sparkPlugHours || 'N/A' }}</span>
        </p>
      </div>
      <div class="mb-3">
        <p>
          {{ 'Inspect Battery Date' | formatLabelNoTranslation }}
          <span class="font-weight-bold"> {{ displayShortDate(currentStatus.batteryDate) }}</span>
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import StatusIcon from '@/components/StatusIcon'
import * as Constants from '@/constants'

export default {
  components: { StatusIcon },
  props: ['currentStatus', 'deviceType', 'networkType'],
  data() {
    return {}
  },
  computed: {
    hsbG3DeviceType() {
      return Constants.HsbG3DeviceType
    }
  }
}
</script>
