var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Modal",
    {
      attrs: {
        modalId:
          "EditEmailPreferencesModal-" +
          _vm.editEmailPreferencesModalInfo.apparatusId
      }
    },
    [
      _c(
        "ModalHeader",
        {
          attrs: {
            title: _vm.$t("xEditEmailPreferences"),
            icon: "fas fa-user-circle"
          }
        },
        [
          _c(
            "button",
            {
              staticClass: "btn btn-icon-button btn-icon-button--gray",
              attrs: { "data-test-hook": "x-button-edit-primary" },
              on: {
                click: function($event) {
                  _vm.saving
                    ? null
                    : _vm.closeModal(
                        "EditEmailPreferencesModal-" + _vm.editValue.apparatusId
                      )
                }
              }
            },
            [_c("i", { staticClass: "fas fa-times" })]
          )
        ]
      ),
      _vm.editValue
        ? _c("ModalBody", [
            _c("div", { staticClass: "row collapseBackground" }, [
              _vm.editValue &&
              _vm.editValue.currentPreferences &&
              _vm.editValue.currentPreferences.subscription &&
              (_vm.editValue.currentPreferences.subscription.type !=
                _vm.freeSubscriptionType ||
                _vm.isTankType(_vm.editEmailPreferencesModalInfo.apparatusType))
                ? _c(
                    "div",
                    { staticClass: "col" },
                    _vm._l(_vm.emailSettings, function(setting) {
                      return _c(
                        "div",
                        { key: setting.type, class: setting.rowClass },
                        [
                          !setting.first &&
                          _vm.settingTypeList(
                            _vm.editValue.currentPreferences,
                            setting.type
                          )
                            ? _c("hr", { staticClass: "row" })
                            : _vm._e(),
                          _vm.settingTypeList(
                            _vm.editValue.currentPreferences,
                            setting.type
                          ) &&
                          _vm.isEnrolledInVPP(
                            setting,
                            _vm.editEmailPreferencesModalInfo
                          )
                            ? _c(
                                "div",
                                {
                                  staticClass: "row",
                                  attrs: { "align-v": "center" }
                                },
                                [
                                  _c("div", { staticClass: "col-1" }, [
                                    _c("i", {
                                      class: _vm.iconToDisplay(
                                        setting.type,
                                        setting.icon
                                      ),
                                      attrs: {
                                        "data-test-hook": setting.title
                                          .toLowerCase()
                                          .replace(/\s/g, "-")
                                          .concat(
                                            "-icon-edit-email-prefs-modal"
                                          )
                                      }
                                    })
                                  ]),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "col pl-0",
                                      attrs: {
                                        "data-test-hook": setting.title
                                          .toLowerCase()
                                          .replace(/\s/g, "-")
                                          .concat(
                                            "-name-edit-email-prefs-modal"
                                          )
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(setting.title) + ": "
                                      ),
                                      _c(
                                        "b",
                                        {
                                          attrs: {
                                            "data-test-hook": setting.title
                                              .toLowerCase()
                                              .replace(/\s/g, "-")
                                              .concat(
                                                "-status-edit-email-prefs-modal"
                                              )
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.editValue.currentPreferences.preferences.find(
                                                  p => p.type == setting.type
                                                ).receiveEmail
                                                  ? "ON"
                                                  : "OFF"
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _c("div", { staticClass: "col-auto ml-1" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "custom-control custom-switch b-custom-control-lg",
                                        class: _vm.editValue.currentPreferences.preferences.find(
                                          p => p.type == setting.type
                                        ).receiveEmail
                                          ? "switch-success"
                                          : null
                                      },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.editValue.currentPreferences.preferences.find(
                                                p => p.type == setting.type
                                              ).receiveEmail,
                                              expression:
                                                "editValue.currentPreferences.preferences.find(p => p.type == setting.type).receiveEmail"
                                            }
                                          ],
                                          staticClass: "custom-control-input",
                                          attrs: {
                                            type: "checkbox",
                                            id: _vm.message + setting.title,
                                            "data-test-hook": setting.title
                                              .toLowerCase()
                                              .replace(/\s/g, "-")
                                              .concat(
                                                "-switch-edit-email-prefs-modal"
                                              )
                                          },
                                          domProps: {
                                            checked: Array.isArray(
                                              _vm.editValue.currentPreferences.preferences.find(
                                                p => p.type == setting.type
                                              ).receiveEmail
                                            )
                                              ? _vm._i(
                                                  _vm.editValue.currentPreferences.preferences.find(
                                                    p => p.type == setting.type
                                                  ).receiveEmail,
                                                  null
                                                ) > -1
                                              : _vm.editValue.currentPreferences.preferences.find(
                                                  p => p.type == setting.type
                                                ).receiveEmail
                                          },
                                          on: {
                                            change: function($event) {
                                              var $$a = _vm.editValue.currentPreferences.preferences.find(
                                                  p => p.type == setting.type
                                                ).receiveEmail,
                                                $$el = $event.target,
                                                $$c = $$el.checked
                                                  ? true
                                                  : false
                                              if (Array.isArray($$a)) {
                                                var $$v = null,
                                                  $$i = _vm._i($$a, $$v)
                                                if ($$el.checked) {
                                                  $$i < 0 &&
                                                    _vm.$set(
                                                      _vm.editValue.currentPreferences.preferences.find(
                                                        p =>
                                                          p.type == setting.type
                                                      ),
                                                      "receiveEmail",
                                                      $$a.concat([$$v])
                                                    )
                                                } else {
                                                  $$i > -1 &&
                                                    _vm.$set(
                                                      _vm.editValue.currentPreferences.preferences.find(
                                                        p =>
                                                          p.type == setting.type
                                                      ),
                                                      "receiveEmail",
                                                      $$a
                                                        .slice(0, $$i)
                                                        .concat(
                                                          $$a.slice($$i + 1)
                                                        )
                                                    )
                                                }
                                              } else {
                                                _vm.$set(
                                                  _vm.editValue.currentPreferences.preferences.find(
                                                    p => p.type == setting.type
                                                  ),
                                                  "receiveEmail",
                                                  $$c
                                                )
                                              }
                                            }
                                          }
                                        }),
                                        _c("label", {
                                          staticClass: "custom-control-label",
                                          attrs: {
                                            for: _vm.message + setting.title
                                          }
                                        })
                                      ]
                                    )
                                  ])
                                ]
                              )
                            : _vm._e(),
                          setting.type == _vm.preferenceTypeTankFuelWarning &&
                          _vm.settingTypeList(
                            _vm.editValue.currentPreferences,
                            setting.type
                          )
                            ? _c(
                                "div",
                                { staticClass: "row align-items-center mt-3" },
                                [
                                  _c("div", { staticClass: "col-auto ml-3" }, [
                                    _c("i", {
                                      staticClass:
                                        "fa-light fa-triangle-exclamation"
                                    })
                                  ]),
                                  _c("div", { staticClass: "col-5 pl-0" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("xWarningLevel")) +
                                        " "
                                    )
                                  ]),
                                  _c("div", { staticClass: "col-5 ml-auto" }, [
                                    _c("div", { staticClass: "input-group" }, [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.$v.editValue
                                                .currentPreferences.threshold1
                                                .$model,
                                            expression:
                                              "$v.editValue.currentPreferences.threshold1.$model"
                                          },
                                          {
                                            name: "mask",
                                            rawName: "v-mask",
                                            value: "##",
                                            expression: "'##'"
                                          }
                                        ],
                                        staticClass: "form-control text-right",
                                        attrs: {
                                          type: "text",
                                          state: _vm.validState(
                                            _vm.$v.editValue.currentPreferences
                                              .threshold1
                                          ),
                                          "aria-describedby":
                                            "tankThreshold-invalid",
                                          maxlength: "2",
                                          disabled: !_vm.editValue.currentPreferences.preferences.find(
                                            p => p.type == setting.type
                                          ).receiveEmail
                                        },
                                        domProps: {
                                          value:
                                            _vm.$v.editValue.currentPreferences
                                              .threshold1.$model
                                        },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              _vm.$v.editValue
                                                .currentPreferences.threshold1,
                                              "$model",
                                              $event.target.value
                                            )
                                          }
                                        }
                                      }),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "input-group-append",
                                          attrs: { "is-text": true }
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "input-group-text" },
                                            [_vm._v("%")]
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "invalid-feedback",
                                          attrs: { id: "tankThreshold-invalid" }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("xInvalidTankThreshold")
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    ])
                                  ])
                                ]
                              )
                            : _vm._e()
                        ]
                      )
                    }),
                    0
                  )
                : _c("div", { staticClass: "col" }, [
                    _c(
                      "div",
                      { staticClass: "row", attrs: { "align-v": "center" } },
                      [
                        _c("div", { staticClass: "col-auto" }, [
                          _c("i", {
                            class: _vm.monthlyEmailSummary.icon,
                            attrs: {
                              "data-test-hook": _vm.monthlyEmailSummary.title
                                .toLowerCase()
                                .replace(/\s/g, "-")
                                .concat("-icon-edit-email-prefs-modal")
                            }
                          })
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "col pl-0",
                            attrs: {
                              "data-test-hook": _vm.monthlyEmailSummary.title
                                .toLowerCase()
                                .replace(/\s/g, "-")
                                .concat("-name-edit-email-prefs-modal")
                            }
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.monthlyEmailSummary.title) + ": "
                            ),
                            _c(
                              "b",
                              {
                                attrs: {
                                  "data-test-hook": _vm.monthlyEmailSummary.title
                                    .toLowerCase()
                                    .replace(/\s/g, "-")
                                    .concat("-status-edit-email-prefs-modal")
                                }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.editValue.currentPreferences.preferences.find(
                                        p =>
                                          p.type == _vm.monthlyEmailSummary.type
                                      ).receiveEmail
                                        ? "ON"
                                        : "OFF"
                                    ) +
                                    " "
                                )
                              ]
                            )
                          ]
                        ),
                        _c("div", { staticClass: "col-auto ml-1" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "custom-control custom-switch b-custom-control-lg",
                              class: _vm.editValue.currentPreferences.preferences.find(
                                p => p.type == _vm.monthlyEmailSummary.type
                              ).receiveEmail
                                ? "switch-success"
                                : null
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.editValue.currentPreferences.preferences.find(
                                      p =>
                                        p.type == _vm.monthlyEmailSummary.type
                                    ).receiveEmail,
                                    expression:
                                      "\n                  editValue.currentPreferences.preferences.find(p => p.type == monthlyEmailSummary.type).receiveEmail\n                "
                                  }
                                ],
                                staticClass: "custom-control-input",
                                attrs: {
                                  type: "checkbox",
                                  id: "monthlySummarySwitch",
                                  "data-test-hook": _vm.monthlyEmailSummary.title
                                    .toLowerCase()
                                    .replace(/\s/g, "-")
                                    .concat("-switch-edit-email-prefs-modal")
                                },
                                domProps: {
                                  checked: Array.isArray(
                                    _vm.editValue.currentPreferences.preferences.find(
                                      p =>
                                        p.type == _vm.monthlyEmailSummary.type
                                    ).receiveEmail
                                  )
                                    ? _vm._i(
                                        _vm.editValue.currentPreferences.preferences.find(
                                          p =>
                                            p.type ==
                                            _vm.monthlyEmailSummary.type
                                        ).receiveEmail,
                                        null
                                      ) > -1
                                    : _vm.editValue.currentPreferences.preferences.find(
                                        p =>
                                          p.type == _vm.monthlyEmailSummary.type
                                      ).receiveEmail
                                },
                                on: {
                                  change: function($event) {
                                    var $$a = _vm.editValue.currentPreferences.preferences.find(
                                        p =>
                                          p.type == _vm.monthlyEmailSummary.type
                                      ).receiveEmail,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            _vm.editValue.currentPreferences.preferences.find(
                                              p =>
                                                p.type ==
                                                _vm.monthlyEmailSummary.type
                                            ),
                                            "receiveEmail",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            _vm.editValue.currentPreferences.preferences.find(
                                              p =>
                                                p.type ==
                                                _vm.monthlyEmailSummary.type
                                            ),
                                            "receiveEmail",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(
                                        _vm.editValue.currentPreferences.preferences.find(
                                          p =>
                                            p.type ==
                                            _vm.monthlyEmailSummary.type
                                        ),
                                        "receiveEmail",
                                        $$c
                                      )
                                    }
                                  }
                                }
                              }),
                              _c("label", {
                                staticClass: "custom-control-label",
                                attrs: { for: "monthlySummarySwitch" }
                              })
                            ]
                          )
                        ])
                      ]
                    ),
                    _c("hr"),
                    _c(
                      "div",
                      { staticClass: "row", attrs: { "align-v": "center" } },
                      [
                        _c("div", { staticClass: "col-auto" }, [
                          _c("i", {
                            class: _vm.iconToDisplay(
                              _vm.disconnected.type,
                              _vm.disconnected.icon
                            ),
                            attrs: {
                              "data-test-hook": _vm.disconnected.title
                                .toLowerCase()
                                .replace(/\s/g, "-")
                                .concat("-icon-edit-email-prefs-modal")
                            }
                          })
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "col pl-0",
                            attrs: {
                              "data-test-hook": _vm.disconnected.title
                                .toLowerCase()
                                .replace(/\s/g, "-")
                                .concat("-name-edit-email-prefs-modal")
                            }
                          },
                          [
                            _vm._v(" " + _vm._s(_vm.disconnected.title) + ": "),
                            _c(
                              "b",
                              {
                                attrs: {
                                  "data-test-hook": _vm.disconnected.title
                                    .toLowerCase()
                                    .replace(/\s/g, "-")
                                    .concat("-status-edit-email-prefs-modal")
                                }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.editValue.currentPreferences.preferences.find(
                                        p => p.type == _vm.disconnected.type
                                      ).receiveEmail
                                        ? "ON"
                                        : "OFF"
                                    ) +
                                    " "
                                )
                              ]
                            )
                          ]
                        ),
                        _c("div", { staticClass: "ml-1 col-auto" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "custom-control custom-switch b-custom-control-lg",
                              class: _vm.editValue.currentPreferences.preferences.find(
                                p => p.type == _vm.disconnected.type
                              ).receiveEmail
                                ? "switch-success"
                                : null
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.editValue.currentPreferences.preferences.find(
                                      p => p.type == _vm.disconnected.type
                                    ).receiveEmail,
                                    expression:
                                      "editValue.currentPreferences.preferences.find(p => p.type == disconnected.type).receiveEmail"
                                  }
                                ],
                                staticClass: "custom-control-input",
                                attrs: {
                                  type: "checkbox",
                                  id: "disconnectedNotificationSwitch",
                                  "data-test-hook": _vm.disconnected.title
                                    .toLowerCase()
                                    .replace(/\s/g, "-")
                                    .concat("-switch-edit-email-prefs-modal")
                                },
                                domProps: {
                                  checked: Array.isArray(
                                    _vm.editValue.currentPreferences.preferences.find(
                                      p => p.type == _vm.disconnected.type
                                    ).receiveEmail
                                  )
                                    ? _vm._i(
                                        _vm.editValue.currentPreferences.preferences.find(
                                          p => p.type == _vm.disconnected.type
                                        ).receiveEmail,
                                        null
                                      ) > -1
                                    : _vm.editValue.currentPreferences.preferences.find(
                                        p => p.type == _vm.disconnected.type
                                      ).receiveEmail
                                },
                                on: {
                                  change: function($event) {
                                    var $$a = _vm.editValue.currentPreferences.preferences.find(
                                        p => p.type == _vm.disconnected.type
                                      ).receiveEmail,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            _vm.editValue.currentPreferences.preferences.find(
                                              p =>
                                                p.type == _vm.disconnected.type
                                            ),
                                            "receiveEmail",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            _vm.editValue.currentPreferences.preferences.find(
                                              p =>
                                                p.type == _vm.disconnected.type
                                            ),
                                            "receiveEmail",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(
                                        _vm.editValue.currentPreferences.preferences.find(
                                          p => p.type == _vm.disconnected.type
                                        ),
                                        "receiveEmail",
                                        $$c
                                      )
                                    }
                                  }
                                }
                              }),
                              _c("label", {
                                staticClass: "custom-control-label",
                                attrs: { for: "disconnectedNotificationSwitch" }
                              })
                            ]
                          )
                        ])
                      ]
                    )
                  ])
            ])
          ])
        : _vm._e(),
      _c("ModalFooter", [
        _c(
          "button",
          {
            staticClass: "btn btn-outline-warning",
            attrs: {
              type: "button",
              disabled: _vm.saving,
              "data-test-hook": "cancel-button-edit-primary"
            },
            on: {
              click: function($event) {
                return _vm.closeModal(
                  "EditEmailPreferencesModal-" +
                    _vm.editEmailPreferencesModalInfo.apparatusId
                )
              }
            }
          },
          [_vm._v(" " + _vm._s(_vm.$t("xCancel")) + " ")]
        ),
        _c(
          "button",
          {
            staticClass: "btn btn-warning",
            attrs: {
              type: "button",
              disabled: !_vm.validThreshold || _vm.saving,
              "data-test-hook": "save-button-edit-primary"
            },
            on: {
              click: function($event) {
                return _vm.save()
              }
            }
          },
          [
            _vm._v(" " + _vm._s(_vm.$t("xSave")) + " "),
            _vm.saving
              ? _c("div", {
                  staticClass: "ml-2 spinner-border text-muted",
                  attrs: {
                    "data-test-hook": "save-button-spinner-edit-primary"
                  }
                })
              : _vm._e()
          ]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }