<template>
  <div v-if="bannerMessage">
    <b-modal id="banner-message-modal" centered hide-footer>
      <template #modal-header>
        <b-row class="w-100" align-v="center" no-gutters>
          <b-col>
            <h2 class="modal-title">
              <i v-if="bannerMessage.Category == 0" class="fas fa-exclamation-triangle colorOrange mr-1" />
              {{ bannerMessage.MessagePageTitle }}
            </h2>
          </b-col>
          <b-col cols="auto" class="float-right">
            <b-button
              class="btn-icon-button--gray"
              variant="icon-button"
              @click="$bvModal.hide('banner-message-modal')"
            >
              <i class="fas fa-times" />
            </b-button>
          </b-col>
        </b-row>
      </template>
      <b-row>
        <b-col>
          {{ bannerMessage.MessageBody1 }}
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col>
          <i>{{ bannerMessage.MessageBody2 }}</i>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import * as Constants from '@/constants'

export default {
  data() {
    return {}
  },
  computed: {
    ...mapState({
      bannerMessage: state => state.application.bannerMessage
    })
  },
  mounted() {
    if (!this.bannerMessage) this.$store.dispatch('getApplicationConfiguration', 'Any')
  }
}
</script>
