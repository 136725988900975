var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal show fade",
      staticStyle: { bottom: "25%" },
      attrs: { id: "fixDunningModal" + _vm.apparatusId }
    },
    [
      _c("div", { staticClass: "modal-dialog modal-dialog-centered" }, [
        _c("div", { staticClass: "modal-content" }, [
          _c("header", { staticClass: "modal-header" }, [
            _c(
              "div",
              { staticClass: "row w-100 no-gutters align-items-center" },
              [
                _c("div", { staticClass: "col" }, [
                  _c(
                    "h2",
                    {
                      staticClass: "modal-title",
                      attrs: { "data-test-hook": "ModalTitle" }
                    },
                    [
                      _c("i", { staticClass: "fal fa-credit-card mr-1" }),
                      _vm._v(" " + _vm._s(_vm.$t("xUpdatePaymentMethod")) + " ")
                    ]
                  )
                ]),
                _c("div", { staticClass: "col-auto" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-icon-button--gray btn-icon-button",
                      attrs: {
                        "data-test-hook": "CloseModalBtn",
                        type: "button"
                      },
                      on: {
                        click: function($event) {
                          return _vm.closeModal()
                        }
                      }
                    },
                    [_c("i", { staticClass: "fas fa-times" })]
                  )
                ])
              ]
            )
          ]),
          _vm.subscriptionLoading
            ? _c(
                "div",
                {
                  staticClass: "spinner-border text-warning",
                  staticStyle: { width: "5rem", height: "5rem" },
                  attrs: { role: "status" }
                },
                [_c("span", { staticClass: "sr-only" }, [_vm._v("Loading...")])]
              )
            : _c("div", { staticClass: "modal-body" }, [
                _c("div", [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-12" }, [
                      _c("h3", [
                        _c(
                          "strong",
                          { attrs: { "data-test-hook": "CcInfoTitle" } },
                          [_vm._v(_vm._s(_vm.$t("xCreditCardInfo")))]
                        ),
                        _c("span", { staticClass: "body-text-color" }, [
                          _vm._v("*")
                        ])
                      ])
                    ])
                  ]),
                  _c("div", { staticClass: "row mb-2" }, [
                    _c("div", { staticClass: "col" }, [
                      _c("i", { staticClass: "asterisk-text" }, [
                        _vm._v(_vm._s(_vm.$t("xProblemProcessingPayment")))
                      ])
                    ])
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-12" }, [
                      _c(
                        "fieldset",
                        {
                          staticClass: "form-group",
                          attrs: { id: "credit-card-number" }
                        },
                        [
                          _c("div", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.$v.creditCardNumber.$model,
                                  expression: "$v.creditCardNumber.$model"
                                },
                                {
                                  name: "mask",
                                  rawName: "v-mask",
                                  value: "################",
                                  expression: "'################'"
                                }
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "text",
                                maxlength: "16",
                                placeholder: _vm.$t("xCreditCardNumber"),
                                state: _vm.validState(_vm.$v.creditCardNumber),
                                "aria-describedby": "card-number-invalid",
                                "data-test-hook": "CcNumber"
                              },
                              domProps: {
                                value: _vm.$v.creditCardNumber.$model
                              },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.$v.creditCardNumber,
                                    "$model",
                                    $event.target.value
                                  )
                                }
                              }
                            }),
                            _c(
                              "div",
                              {
                                staticClass: "invalid-feedback",
                                attrs: { id: "card-number-invalid" }
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("xProblemProcessingPayment"))
                                )
                              ]
                            )
                          ])
                        ]
                      )
                    ])
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-sm-12 col-md-t6 col-lg-6" }, [
                      _c(
                        "fieldset",
                        {
                          staticClass: "form-group",
                          attrs: { id: "expiry-date-month" }
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.$v.expirationMonth.$model,
                                expression: "$v.expirationMonth.$model"
                              },
                              {
                                name: "mask",
                                rawName: "v-mask",
                                value: "##",
                                expression: "'##'"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: {
                              type: "text",
                              placeholder: _vm.$t("xMm"),
                              maxlength: "2",
                              state: _vm.validState(_vm.$v.expirationMonth),
                              "aria-describedby": "expiry-month-invalid",
                              "data-test-hook": "CcExpireMonth"
                            },
                            domProps: { value: _vm.$v.expirationMonth.$model },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.$v.expirationMonth,
                                  "$model",
                                  $event.target.value
                                )
                              }
                            }
                          }),
                          _c(
                            "div",
                            {
                              staticClass: "invalid-feedback",
                              attrs: { id: "expiry-month-invalid" }
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("xExpirationMonthValidation"))
                              )
                            ]
                          )
                        ]
                      )
                    ]),
                    _c("div", { staticClass: "col-sm-12 col-md-t6 col-lg-6" }, [
                      _c(
                        "fieldset",
                        {
                          staticClass: "form-group",
                          attrs: { id: "expiry-date-year" }
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.$v.expirationYear.$model,
                                expression: "$v.expirationYear.$model"
                              },
                              {
                                name: "mask",
                                rawName: "v-mask",
                                value: "##",
                                expression: "'##'"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: {
                              type: "text",
                              placeholder: _vm.$t("xYy"),
                              maxlength: "2",
                              state: _vm.validState(_vm.$v.expirationYear),
                              "aria-describedby": "expiry-year-invalid",
                              "data-test-hook": "CcExpireYear"
                            },
                            domProps: { value: _vm.$v.expirationYear.$model },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.$v.expirationYear,
                                  "$model",
                                  $event.target.value
                                )
                              }
                            }
                          }),
                          _c(
                            "div",
                            {
                              staticClass: "invalid-feedback",
                              attrs: { id: "expiry-year-invalid" }
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("xExpirationYearValidation"))
                              )
                            ]
                          )
                        ]
                      )
                    ]),
                    _c("div", { staticClass: "col-12" }, [
                      _c(
                        "div",
                        {
                          staticClass: "input-group",
                          attrs: { id: "cvv", role: "group" }
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.$v.cvv.$model,
                                expression: "$v.cvv.$model"
                              },
                              {
                                name: "mask",
                                rawName: "v-mask",
                                value: "####",
                                expression: "'####'"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: {
                              type: "text",
                              maxlength: "4",
                              placeholder: _vm.$t("xCvv"),
                              state: _vm.validState(_vm.$v.cvv),
                              "aria-describedby": "cvv-invalid",
                              "data-test-hook": "CcCvv"
                            },
                            domProps: { value: _vm.$v.cvv.$model },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.$v.cvv,
                                  "$model",
                                  $event.target.value
                                )
                              }
                            }
                          }),
                          _c("div", { staticClass: "input-group-append" }, [
                            _c("div", { staticClass: "input-group-text" }, [
                              _c("img", {
                                attrs: {
                                  src: require("@/assets/images/3-digit-code.svg"),
                                  height: "20"
                                }
                              })
                            ])
                          ]),
                          _c(
                            "div",
                            {
                              staticClass: "invalid-feedback",
                              attrs: { id: "cvv-invalid" }
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("xExpirationMonthValidation"))
                              )
                            ]
                          )
                        ]
                      )
                    ])
                  ]),
                  _vm.accountData
                    ? _c("div", { staticClass: "mt-4" }, [
                        _c("div", { staticClass: "row mb-2" }, [
                          _c("div", { staticClass: "col" }, [
                            _c(
                              "div",
                              { staticClass: "custom-control custom-checkbox" },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.useExistingBillingInformation,
                                      expression:
                                        "useExistingBillingInformation"
                                    }
                                  ],
                                  staticClass: "custom-control-input",
                                  attrs: {
                                    type: "checkbox",
                                    id: "customCheck",
                                    "data-test-hook":
                                      "UseCurrentBillingAddressBtn"
                                  },
                                  domProps: {
                                    checked: Array.isArray(
                                      _vm.useExistingBillingInformation
                                    )
                                      ? _vm._i(
                                          _vm.useExistingBillingInformation,
                                          null
                                        ) > -1
                                      : _vm.useExistingBillingInformation
                                  },
                                  on: {
                                    change: function($event) {
                                      var $$a =
                                          _vm.useExistingBillingInformation,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            (_vm.useExistingBillingInformation = $$a.concat(
                                              [$$v]
                                            ))
                                        } else {
                                          $$i > -1 &&
                                            (_vm.useExistingBillingInformation = $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1)))
                                        }
                                      } else {
                                        _vm.useExistingBillingInformation = $$c
                                      }
                                    }
                                  }
                                }),
                                _c(
                                  "label",
                                  {
                                    staticClass: "custom-control-label",
                                    attrs: {
                                      for: "customCheck",
                                      "data-test-hook": "useCurrBillingInfoText"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("xUseCurrentBillingInfo")
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ]
                            )
                          ])
                        ]),
                        _vm.useExistingBillingInformation
                          ? _c("div", { staticClass: "row" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "col-12",
                                  attrs: {
                                    "data-test-hook": "ExistingBillingName"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.accountData.firstName) +
                                      " " +
                                      _vm._s(_vm.accountData.lastName) +
                                      " "
                                  )
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "col-12",
                                  attrs: {
                                    "data-test-hook": "ExistingBillingAddress"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.DisplayAddress(
                                          _vm.accountData.address
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ])
                          : _vm._e(),
                        !_vm.useExistingBillingInformation
                          ? _c("div", [
                              _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col-12" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "form-group",
                                      attrs: {
                                        role: "group",
                                        id: "firstName-group"
                                      }
                                    },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.accountData.firstName,
                                            expression: "accountData.firstName"
                                          }
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          type: "text",
                                          id: "firstName"
                                        },
                                        domProps: {
                                          value: _vm.accountData.firstName
                                        },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              _vm.accountData,
                                              "firstName",
                                              $event.target.value
                                            )
                                          }
                                        }
                                      })
                                    ]
                                  )
                                ])
                              ]),
                              _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col-12" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "form-group",
                                      attrs: {
                                        role: "group",
                                        id: "lastName-group"
                                      }
                                    },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.accountData.lastName,
                                            expression: "accountData.lastName"
                                          }
                                        ],
                                        staticClass: "form-control",
                                        attrs: { type: "text", id: "lastName" },
                                        domProps: {
                                          value: _vm.accountData.lastName
                                        },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              _vm.accountData,
                                              "lastName",
                                              $event.target.value
                                            )
                                          }
                                        }
                                      })
                                    ]
                                  )
                                ])
                              ]),
                              _c("div", { staticClass: "row" }, [
                                _c(
                                  "div",
                                  { staticClass: "col" },
                                  [
                                    _c("TypeAheadAddress", {
                                      ref: "typeAheadAddressSubscription",
                                      attrs: {
                                        currentAddress: _vm.accountData.address
                                      },
                                      on: {
                                        newAddress: _vm.setNewBillingAddress
                                      }
                                    })
                                  ],
                                  1
                                )
                              ])
                            ])
                          : _vm._e()
                      ])
                    : _vm._e(),
                  _c("div", { staticClass: "row mt-3" }, [
                    _c("div", { staticClass: "asterisk-text col" }, [
                      _c(
                        "i",
                        {
                          staticClass: "colorOrange",
                          attrs: { "data-test-hook": "UpdatePaymentWarningMsg" }
                        },
                        [
                          _vm._v(
                            "*" + _vm._s(_vm.$t("xUpdatingPaymentWarning"))
                          )
                        ]
                      )
                    ])
                  ])
                ])
              ]),
          _c("footer", { staticClass: "modal-footer" }, [
            _vm.useExistingBillingInformation
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-warning btn-block",
                    attrs: {
                      variant: "warning",
                      block: "",
                      disabled: !_vm.canPay(),
                      "data-test-hook": "SaveChangesBtn",
                      type: "button"
                    },
                    on: {
                      click: function($event) {
                        return _vm.payForProduct()
                      }
                    }
                  },
                  [
                    _vm._v(" " + _vm._s(_vm.$t("xSaveAndClose")) + " "),
                    _vm.paying
                      ? _c("div", { staticClass: "spinner-border" })
                      : _vm._e()
                  ]
                )
              : _c(
                  "button",
                  {
                    attrs: {
                      variant: "outline-warning",
                      block: "",
                      disabled: _vm.savingAccountHolder,
                      "data-test-hook": "SaveChangesBtn"
                    },
                    on: {
                      click: function($event) {
                        _vm.saveAccountHolder(),
                          (_vm.savingAccountHolder = true)
                      }
                    }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("xConfirmBillingInfo")) + " ")]
                )
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }