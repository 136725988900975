import Axios from 'axios'
import _ from 'lodash'
import { vueAppApiBaseAddress } from '@/utils/mixins'

var initialState = {}
export const state = _.cloneDeep(initialState)
export const getters = {}
export const mutations = {}

export const actions = {
  async sendFeedback({ commit }, request) {
    return await Axios.post(vueAppApiBaseAddress() + `v1/Support/feedback`, request, {
      withCredentials: true
    })
  }
}
export default {
  state,
  getters,
  actions,
  mutations
}
