const unitMixin = {
  computed: {
    abbreviatedLiquidUom() {
      if (this.user.mobileLinkSettings.displaySettings.liquidUom === 'liters') {
        return this.$t('xLiter')
      }
      return this.$t('xGal')
    },
    liquidUom() {
      return this.user?.mobileLinkSettings?.displaySettings?.liquidUom
    },
    user() {
      return this.$store.state?.account?.user
    }
  },
  methods: {
    convertGallonsToPreferredUnit(gallons) {
      if (this.liquidUom === 'liters') {
        const LITERS_PER_GALLON = 3.785
        return Math.round(gallons * LITERS_PER_GALLON)
      }
      return gallons
    },
    convertCapacityLabel(label) {
      if (this.liquidUom === 'liters') {
        const words = label.split(' ')
        if (words.length === 2) {
          return `${this.convertGallonsToPreferredUnit(+words[0])} ${this.$t('xLiter').toLowerCase()}`
        }
        if (words.length === 3) {
          const parenthesesParts = words[1].replace(/\(|\)/g, '').split('x')
          return `${this.convertGallonsToPreferredUnit(+words[0])} (${
            parenthesesParts[0]
          }x${this.convertGallonsToPreferredUnit(+parenthesesParts[1])}) ${this.$t('xLiter').toLowerCase()}`
        }
        return label
      }
      return label
    }
  }
}

export default unitMixin
