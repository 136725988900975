<template>
  <div class="d-flex download-app-page">
    <div class="d-flex justify-content-center flex-column col-md-7 app-details">
      <img width="195px" :src="require('@/assets/images/MobileLink_Logo_2023_COLOR.svg')" alt="Mobile Link Logo" />
      <h1>{{ $t('xDownloadOurApp') }}</h1>
      <p>{{ $t('xDownloadOurAppDesc') }}</p>

      <div class="d-flex align-items-center justify-content-flex-start mt-3">
        <a
          v-if="getOs() != 'Android'"
          href="https://apps.apple.com/us/app/mobile-link-for-generators/id829129497?itsct=apps_box_badge&amp;itscg=30200"
          ><img
            src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1396137600&h=ec3911bf38aa4d3ad9236dfe163027a8"
            :alt="$t('xDownloadOnTheAppStore')"
            style="height: 45px;"
        /></a>
        <a
          v-if="getOs() != 'iOS'"
          href="https://play.google.com/store/apps/details?id=com.generac.standbystatus&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
          ><img
            src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
            :alt="$t('xGetItOnGooglePlay')"
            style="height: 65px;"
        /></a>
      </div>
    </div>
    <div class="app-screenshot col-md-5">
      <img
        :src="require('@/assets/images/app-screenshot_1x.png')"
        :srcset="
          require('@/assets/images/app-screenshot_1x.png') +
            ' 1x, ' +
            require('@/assets/images/app-screenshot_2x.png') +
            ' 2x, ' +
            require('@/assets/images/app-screenshot_3x.png') +
            ' 3x, '
        "
        alt="Mobile Link Dashboard"
        class="app-screenshot--images"
      />
      <img
        width="170px"
        :src="require('@/assets/images/powered-by-generac-white.svg')"
        alt="Generac Logo"
        class="mt-3"
      />
    </div>
  </div>
</template>

<script>
import * as Constants from '@/constants'

export default {
  components: {},
  data() {
    return {
      longitude: 0,
      latitude: 0
    }
  },
  computed: {
    platform() {
      return navigator.userAgent
    }
  },
  methods: {
    async trackEvent(platform) {
      let browserNameWithVersion = ''
      let browserSearchString = ''
      let browserName = (function(agent) {
        switch (true) {
          case agent.indexOf('edge') > -1:
            browserSearchString = 'edge'
            return 'MS Edge'
          case agent.indexOf('edg/') > -1:
            browserSearchString = 'edg/'
            return 'Edge (chromium based)'
          case agent.indexOf('opr') > -1 && !!window.opr:
            browserSearchString = 'opr'
            return 'Opera'
          case agent.indexOf('chrome') > -1 && !!window.chrome:
            browserSearchString = 'chrome'
            return 'Chrome'
          case agent.indexOf('trident') > -1:
            browserSearchString = 'trident'
            return 'MS IE'
          case agent.indexOf('firefox') > -1:
            browserSearchString = 'firefox'
            return 'Mozilla Firefox'
          case agent.indexOf('safari') > -1:
            browserSearchString = 'safari'
            return 'Safari'
          default:
            browserSearchString = 'other'
            return 'other'
        }
      })(window.navigator.userAgent.toLowerCase())

      browserNameWithVersion = this.getBrowserNameAndVersion(browserSearchString)
      await this.getCurrentPosition()
      let campaignId = this.$route.query.campaign_ID ? this.$route.query.campaign_ID : 'none'

      let event = {
        event: Constants.GetStartedVisit,
        properties: {
          name: 'marketing-landing-getStarted',
          platform: platform,
          campaignId: campaignId,
          browserType: browserName,
          browserTypeWithVersion: browserNameWithVersion,
          location: this.latitude + ', ' + this.longitude
        }
      }
      await this.$store.dispatch('logEvent', event)
    },
    getBrowserNameAndVersion(browserSearchCode) {
      let browserNameWithVersion = 'none'
      let browserNameStartPosition = navigator.userAgent.toLowerCase().indexOf(browserSearchCode)
      let browserNameEndPosition =
        navigator.userAgent.indexOf(' ', browserNameStartPosition) != -1
          ? navigator.userAgent.indexOf(' ', browserNameStartPosition)
          : navigator.userAgent.length
      if (browserNameEndPosition > browserNameStartPosition) {
        browserNameWithVersion = navigator.userAgent.slice(browserNameStartPosition, browserNameEndPosition)
        return browserNameWithVersion
      }
      return ''
    },
    getCurrentPosition() {
      return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
          position => {
            this.latitude = position.coords.latitude
            this.longitude = position.coords.longitude
            resolve(position)
          },
          error => {
            this.latitude = 0
            this.longitude = 0
            resolve(error)
          }
        )
      })
    },
    getOs() {
      if (navigator.userAgent.toLowerCase().includes('windows')) {
        return 'Windows'
      } else if (navigator.userAgent.toLowerCase().includes('android')) {
        return 'Android'
      } else if (navigator.userAgent.toLowerCase().match(/(ios)|(ipad)|(iphone)/)) {
        return 'iOS'
      } else {
        return 'Unknown'
      }
    }
  },
  async mounted() {
    await this.trackEvent(this.getOs())
  }
}
</script>
<style scoped lang="scss">
.download-app-page {
  height: 100vh;
  margin-right: -15px;
  margin-left: -15px;
  justify-content: space-evenly;
  flex-direction: column;
  @media (min-width: 768px) {
    flex-direction: row;
  }
}

h1 {
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 700;
  line-height: 1.25;
  font-size: 2.75em;
  color: var(--carbon-900);

  @media (min-width: 768px) {
    font-size: 4.125em;
    margin-left: -3px;
  }
}

p {
  font-size: 1.125em;
  line-height: 1.35;
}

.app-details {
  text-align: center;
  align-items: center;
  padding: 0 1em;
  @media (min-width: 768px) {
    text-align: left;
    align-items: flex-start;
    padding: 0 3em;
  }
}
.app-screenshot {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &--images {
    display: none;
  }

  @media (min-width: 768px) {
    background-color: rgba(255, 107, 0, 0.7);
    align-items: center;

    &--images {
      display: block;
    }
  }
}
</style>
