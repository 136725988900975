var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.title
    ? _c(
        "div",
        { staticClass: "modal-header align-items-center" },
        [
          _c("h2", { staticClass: "modal-title" }, [
            _vm.icon
              ? _c("i", { staticClass: "colorOrange mr-1", class: _vm.icon })
              : _vm._e(),
            _vm._v(" " + _vm._s(_vm.title) + " ")
          ]),
          _vm._t("default")
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }