<template>
  <div v-if="modalId" class="modal" :id="modalId" role="dialog">
    <div class="modal-dialog modal-dialog-centered" :class="size ? size : ''">
      <div class="modal-content">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['modalId', 'size']
}
</script>
