import Axios from 'axios'
import router from '@/router'
import { vueAppApiBaseAddress } from '@/utils/mixins'

var initialState = {}

export const state = {}

export const getters = {}

export const mutations = {}

export const actions = {
  async getEvents({ commit }, apparatusId) {
    return await Axios.get(vueAppApiBaseAddress() + `v2/VirtualPowerPlant/events/${apparatusId}`, {
      withCredentials: true
    })
  },
  async optOutOfEvent({ commit }, request) {
    return await Axios.post(vueAppApiBaseAddress() + `v2/VirtualPowerPlant/event/optOut`, request, {
      withCredentials: true
    })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
