var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-12 col-md-6" }, [
      _c("h1", [_vm._v("Device Connection")]),
      _c("div", { staticClass: "mb-3" }, [
        _c("p", [
          _vm._v(
            _vm._s(_vm._f("formatLabelNoTranslation")("Connection Status"))
          )
        ]),
        _vm.currentStatus.isConnected
          ? _c("p", { staticClass: "font-weight-bold pb-2" }, [
              _vm._v("Online")
            ])
          : _c("p", { staticClass: "font-weight-bold pb-2" }, [
              _vm._v("Offline")
            ])
      ]),
      _c("div", { staticClass: "mb-3" }, [
        _c("p", [
          _vm._v(_vm._s(_vm._f("formatLabelNoTranslation")("Signal Strength")))
        ]),
        _c("p", { staticClass: "font-weight-bold pb-2" }, [
          _vm._v(_vm._s(_vm.currentStatus.signalStrength || "N/A"))
        ])
      ]),
      _c("div", { staticClass: "mb-3" }, [
        _c("p", [
          _vm._v(
            _vm._s(_vm._f("formatLabelNoTranslation")("Device Identifier"))
          )
        ]),
        _c("div", { staticClass: "d-flex align-items-center" }, [
          _c(
            "p",
            {
              staticClass: "font-weight-bold",
              attrs: { id: "deviceIdentifier" }
            },
            [
              _vm._v(
                " " + _vm._s(_vm.currentStatus.deviceIdentifier || "N/A") + " "
              )
            ]
          ),
          _c(
            "button",
            {
              staticClass: "btn ml-2 btn-light btn-sm",
              attrs: {
                "data-test-hook":
                  "copy-device-id-button-connections-and-subs-tab"
              },
              on: {
                click: function($event) {
                  return _vm.copyToClipboard(_vm.currentStatus.deviceIdentifier)
                }
              }
            },
            [_c("i", { staticClass: "far fa-copy" })]
          )
        ])
      ]),
      _c("div", { staticClass: "mb-3" }, [
        _c("p", [
          _vm._v(_vm._s(_vm._f("formatLabelNoTranslation")("Last Seen Date")))
        ]),
        _c("p", { staticClass: "font-weight-bold" }, [
          _vm._v(
            _vm._s(
              _vm.displayShortDateTimeWithTZ(_vm.currentStatus.lastSeenDate) ||
                "N/A"
            )
          )
        ])
      ])
    ]),
    _vm.currentSubscription &&
    _vm.currentSubscription[0] &&
    !_vm.BillingMaintenanceEnabled()
      ? _c("div", { staticClass: "col-12 col-md-6" }, [
          _c("h1", [_vm._v("Current Subscription")]),
          _c("div", { staticClass: "mb-3" }, [
            _c("p", [
              _vm._v(_vm._s(_vm._f("formatLabelNoTranslation")("Subscription")))
            ]),
            _c(
              "p",
              {
                staticClass: "font-weight-bold",
                class: _vm.getSubscriptionClass(_vm.currentSubscription)
              },
              [
                _c("strong", [
                  _vm._v(" " + _vm._s(_vm.currentSubscription[0].productName))
                ])
              ]
            )
          ]),
          _c("div", { staticClass: "mb-3" }, [
            _c("p", [
              _vm._v(_vm._s(_vm._f("formatLabelNoTranslation")("Cost")))
            ]),
            _c("p", { staticClass: "font-weight-bold" }, [
              _vm._v(
                _vm._s(
                  _vm._f("formatCurrency")(
                    _vm.currentSubscription[0].cost.toFixed(2)
                  )
                )
              )
            ])
          ]),
          _c("div", { staticClass: "mb-3" }, [
            _c("p", [
              _vm._v(_vm._s(_vm._f("formatLabelNoTranslation")("Start Date")))
            ]),
            _c("p", { staticClass: "font-weight-bold" }, [
              _vm._v(_vm._s(_vm.currentSubscription[0].startDate))
            ])
          ]),
          _c("div", { staticClass: "mb-3" }, [
            _c("p", [
              _vm._v(
                _vm._s(_vm._f("formatLabelNoTranslation")("Next Billing Date"))
              )
            ]),
            _c("p", { staticClass: "font-weight-bold" }, [
              _vm._v(_vm._s(_vm.currentSubscription[0].nextBillingDate))
            ])
          ]),
          _vm.billingAccountId
            ? _c("div", { staticClass: "mb-3" }, [
                _c("p", [
                  _vm._v(
                    _vm._s(
                      _vm._f("formatLabelNoTranslation")("Billing Account #")
                    )
                  )
                ]),
                _c("p", { staticClass: "font-weight-bold" }, [
                  _vm._v(_vm._s(_vm.billingAccountId))
                ])
              ])
            : _vm.BillingMaintenanceEnabled()
            ? _c("div", { staticClass: "col-12 col-md-6" }, [
                _c("h1", [_vm._v("Current Subscription")]),
                _vm._m(0)
              ])
            : _c(
                "div",
                {
                  staticClass: "col-12 col-md-6",
                  attrs: { title: "Current Subscription" }
                },
                [
                  _c("h4", { staticClass: "inactive-subscription" }, [
                    _vm._v("Subscription not found")
                  ])
                ]
              ),
          _c(
            "div",
            { staticClass: "col-12 col-md-6", attrs: { title: "Dealer Info" } },
            [
              _c("p", [
                _vm._v(_vm._s(_vm._f("formatLabelNoTranslation")("Dealer ID")))
              ]),
              _c("div", { staticClass: "d-flex align-items-center" }, [
                _c("p", { staticClass: "font-weight-bold" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.dealerInfo && _vm.dealerInfo.externalId
                          ? _vm.dealerInfo.externalId
                          : "N/A"
                      ) +
                      " "
                  )
                ]),
                _c(
                  "button",
                  {
                    staticClass: "btn ml-2 btn-light btn-sm",
                    attrs: {
                      id: "btnCopyDealerIdToClipboard",
                      "data-test-hook":
                        "copy-dealer-id-button-connection-and-subs-tab"
                    },
                    on: {
                      click: function($event) {
                        _vm.copyToClipboard(
                          _vm.dealerInfo && _vm.dealerInfo.externalId
                            ? _vm.dealerInfo.externalId.split("-")[1]
                            : "N/A"
                        )
                      }
                    }
                  },
                  [_c("i", { staticClass: "far fa-copy" })]
                )
              ])
            ]
          ),
          _c("div", { staticClass: "mb-3" }, [
            _c("p", [
              _vm._v(_vm._s(_vm._f("formatLabelNoTranslation")("Dealer Name")))
            ]),
            _c("p", { staticClass: "font-weight-bold pb-2" }, [
              _vm._v(
                _vm._s(
                  _vm.dealerInfo && _vm.dealerInfo.name
                    ? _vm.dealerInfo.name
                    : "N/A"
                )
              )
            ])
          ]),
          _c("div", { staticClass: "mb-3" }, [
            _c("p", [
              _vm._v(_vm._s(_vm._f("formatLabelNoTranslation")("Location")))
            ]),
            _c("p", { staticClass: "font-weight-bold" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.dealerInfo && _vm.dealerInfo.topLineAddress
                      ? _vm.dealerInfo.topLineAddress
                      : "N/A"
                  ) +
                  " "
              )
            ]),
            _c("p", { staticClass: "font-weight-bold pb-2" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.dealerInfo && _vm.dealerInfo.bottomLineAddress
                      ? _vm.dealerInfo.bottomLineAddress
                      : "N/A"
                  ) +
                  " "
              )
            ])
          ]),
          _c("div", { staticClass: "mb-3" }, [
            _c("p", [
              _vm._v(_vm._s(_vm._f("formatLabelNoTranslation")("Email")))
            ]),
            _c("p", { staticClass: "font-weight-bold pb-2" }, [
              _vm._v(
                _vm._s(
                  _vm.dealerInfo && _vm.dealerInfo.email
                    ? _vm.dealerInfo.email
                    : "N/A"
                )
              )
            ])
          ]),
          _c("div", { staticClass: "mb-3" }, [
            _c("p", [
              _vm._v(_vm._s(_vm._f("formatLabelNoTranslation")("Phone")))
            ]),
            _c("p", { staticClass: "font-weight-bold pb-2" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.dealerInfo && _vm.dealerInfo.phoneNumber
                      ? _vm.dealerInfo.phoneNumber
                      : "N/A"
                  ) +
                  " "
              )
            ])
          ])
        ])
      : _vm.dealerInfoLoading
      ? _c("div", { staticClass: "mt-4" }, [_vm._m(1)])
      : !_vm.dealerInfoLoading && !_vm.dealerInfo
      ? _c("div", { staticClass: "col-12 col-md-6", attrs: { title: "" } }, [
          _c("h1", [_vm._v("Dealer Info")]),
          _c("p", [_vm._v("Dealer not found")])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("h4", { staticClass: "inactive-subscription" }, [
        _vm._v(
          " Some Mobile Link features are currently disabled due to website maintenance. Thank you for your patience, please check back soon! "
        )
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "span",
      {
        staticClass: "spinner-border text-primary",
        staticStyle: { width: "5rem", height: "5rem" },
        attrs: { role: "status" }
      },
      [_c("span", { staticClass: "sr-only" })]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }