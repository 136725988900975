var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "homeDiv" } },
    [
      _c(
        "b-row",
        {
          staticClass: "h-100 m-0",
          attrs: { "align-h": "center", "align-v": "center" }
        },
        [
          _c(
            "b-col",
            { staticClass: "d-flex justify-content-center" },
            [
              _vm.expiredLinkError
                ? _c(
                    "b-card",
                    { staticClass: "card-shadow login-cards" },
                    [
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { class: _vm.step == "email" ? "invisible" : null },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "hoverPointer",
                                  attrs: {
                                    "data-test-hook":
                                      "cancel-button-expiredLink-home"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.back()
                                    }
                                  }
                                },
                                [
                                  _c("i", {
                                    staticClass: "fas fa-chevron-left"
                                  }),
                                  _vm._v(" " + _vm._s(_vm.$t("xCancel")) + " ")
                                ]
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { staticClass: "col-10" },
                            [
                              _c("b-img", {
                                attrs: {
                                  src: require("@/assets/images/MobileLink_Logo_2023_COLOR.svg"),
                                  fluid: "",
                                  alt: "Mobile Link Logo"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        {
                          staticClass: "mx-0 my-2 p-2",
                          attrs: { "no-gutters": "" }
                        },
                        [
                          _c("b-col", [
                            _vm._v(
                              " " + _vm._s(_vm.$t("xThisLinkHasExpired")) + " "
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm.userEmailStatus == null
                ? _c(
                    "b-card",
                    { staticClass: "card-shadow login-cards" },
                    [
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { class: _vm.step == "email" ? "invisible" : null },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "hoverPointer",
                                  attrs: {
                                    "data-test-hook":
                                      "cancel-button-noUserEmailStatus-home"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.back()
                                    }
                                  }
                                },
                                [
                                  _c("i", {
                                    staticClass: "fas fa-chevron-left"
                                  }),
                                  _vm._v(" " + _vm._s(_vm.$t("xCancel")) + " ")
                                ]
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { staticClass: "col-10" },
                            [
                              _c("b-img", {
                                attrs: {
                                  src: require("@/assets/images/MobileLink_Logo_2023_COLOR.svg"),
                                  fluid: "",
                                  alt: "Mobile Link Logo"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm.loginError
                        ? _c(
                            "b-row",
                            {
                              staticClass: "errorBanner mx-0 my-2 p-2",
                              attrs: { "align-v": "center", "no-gutters": "" }
                            },
                            [
                              _c("b-col", [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("xAnErrorHasOccurred")) +
                                    " "
                                )
                              ])
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.inviteError
                        ? _c(
                            "b-row",
                            {
                              staticClass: "errorBanner mx-0 my-2 p-2",
                              attrs: { "align-v": "center", "no-gutters": "" }
                            },
                            [
                              _c("b-col", [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("xItSeemsAsThoughYouHave")) +
                                    " "
                                )
                              ])
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.correlationError
                        ? _c(
                            "b-row",
                            {
                              staticClass: "errorBanner mx-0 my-2 p-2",
                              attrs: { "align-v": "center", "no-gutters": "" }
                            },
                            [
                              _c("b-col", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "xMobileLinkExperiencedAnErrorSigning"
                                      )
                                    ) +
                                    " "
                                )
                              ])
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            [
                              _c(
                                "b-alert",
                                {
                                  attrs: {
                                    show: _vm.showValidationBanner,
                                    variant: "danger",
                                    "data-test-hook": "ValidationAlert"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.emailVerificationFailureMessage
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm.step == "email"
                        ? _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                [
                                  _c("h2", [
                                    _vm._v(_vm._s(_vm.$t("xSignInOrSignUp")))
                                  ]),
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        id: "email-group",
                                        "label-for": "email",
                                        label: _vm.$t("xEmailAddress")
                                      }
                                    },
                                    [
                                      _c("b-form-input", {
                                        attrs: {
                                          id: "email",
                                          maxlength: "70",
                                          type: "text",
                                          placeholder: _vm.$t("xEmailAddress"),
                                          state: _vm.validState(_vm.$v.email),
                                          "aria-describedby": "email-invalid",
                                          disabled: _vm.processing,
                                          formatter: _vm.lowerCaseFormatter
                                        },
                                        on: {
                                          keyup: function($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "enter",
                                                13,
                                                $event.key,
                                                "Enter"
                                              )
                                            )
                                              return null
                                            return _vm.next.apply(
                                              null,
                                              arguments
                                            )
                                          }
                                        },
                                        model: {
                                          value: _vm.$v.email.$model,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.$v.email,
                                              "$model",
                                              $$v
                                            )
                                          },
                                          expression: "$v.email.$model"
                                        }
                                      }),
                                      _c(
                                        "b-form-invalid-feedback",
                                        { attrs: { id: "email-invalid" } },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t("xRequiredField")) +
                                              ". " +
                                              _vm._s(
                                                _vm.$t("xEmailInvalidError")
                                              ) +
                                              " "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "hoverPointer btn-link d-block",
                                          on: {
                                            click: function($event) {
                                              return _vm.$bvModal.show(
                                                "ForgotEmailModal"
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("xForgotYourEmail")
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.step != "verify"
                        ? _c(
                            "div",
                            [
                              _c(
                                "b-row",
                                { staticClass: "mt-1 mb-3" },
                                [
                                  _c(
                                    "b-col",
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          attrs: {
                                            block: "",
                                            variant: "warning",
                                            disabled: _vm.processing,
                                            "data-test-hook":
                                              "next-button-noUserEmailStatus-home"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.next()
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(_vm.$t("xNext")) + " "
                                          ),
                                          _vm.processing
                                            ? _c("b-spinner", {
                                                staticClass: "ml-2"
                                              })
                                            : _vm._e(),
                                          _c("span", { staticClass: "pl-1" }, [
                                            _c("i", {
                                              staticClass: "far fa-arrow-right"
                                            })
                                          ])
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                : _c(
                    "b-card",
                    { staticClass: "card-shadow login-cards" },
                    [
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { class: _vm.step == "email" ? "invisible" : null },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "hoverPointer",
                                  attrs: {
                                    "data-test-hook": "cancel-button-else-home"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.back()
                                    }
                                  }
                                },
                                [
                                  _c("i", {
                                    staticClass: "fas fa-chevron-left"
                                  }),
                                  _vm._v(" " + _vm._s(_vm.$t("xCancel")) + " ")
                                ]
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        { staticClass: "my-3" },
                        [
                          _c(
                            "b-col",
                            [
                              _c("b-img", {
                                attrs: {
                                  src: require("@/assets/images/MobileLink_Logo_2023_COLOR.svg"),
                                  fluid: "",
                                  alt: "Mobile Link Logo"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm.userEmailStatus == 3
                        ? _c(
                            "b-row",
                            {
                              staticClass: "mx-0 my-2 p-2",
                              attrs: { "align-v": "center", "no-gutters": "" }
                            },
                            [
                              _c("b-col", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "xYourEmailAddressHasAlreadyBeenInvited"
                                      )
                                    ) +
                                    " "
                                )
                              ])
                            ],
                            1
                          )
                        : _vm.userEmailStatus == 5
                        ? _c(
                            "b-row",
                            {
                              staticClass: "mx-0 my-2 p-2",
                              attrs: { "align-v": "center", "no-gutters": "" }
                            },
                            [
                              _c("b-col", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "xItLooksLikeWeEncounteredAProblem"
                                      )
                                    ) +
                                    " "
                                )
                              ]),
                              _c(
                                "b-button",
                                {
                                  staticClass: "mx-0 my-3 p-2",
                                  attrs: {
                                    block: "",
                                    variant: "warning",
                                    "data-test-hook": "retry-button-else-home"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.retryLogin()
                                    }
                                  }
                                },
                                [
                                  _vm._v(" " + _vm._s(_vm.$t("xRetry")) + " "),
                                  _c("span", { staticClass: "float-right" }, [
                                    _c("i", {
                                      staticClass: "far fa-arrow-right"
                                    })
                                  ])
                                ]
                              )
                            ],
                            1
                          )
                        : _c(
                            "b-row",
                            {
                              staticClass: "mx-0 my-2 p-2",
                              attrs: { "align-v": "center", "no-gutters": "" }
                            },
                            [
                              _c("b-col", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("xThereIsAnIssueWithYourAccount")
                                    ) +
                                    " "
                                )
                              ])
                            ],
                            1
                          )
                    ],
                    1
                  )
            ],
            1
          )
        ],
        1
      ),
      _c("ForgotEmailModal")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }