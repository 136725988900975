var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.orgApparatuses
      ? _c(
          "table",
          {
            staticClass: "table table-striped table-hover search-table",
            attrs: {
              "head-variant": "light",
              items: _vm.orgApparatuses,
              stacked: "md"
            }
          },
          [
            _vm._m(0),
            _c(
              "tbody",
              _vm._l(_vm.orgApparatuses, function(apparatus) {
                return _c("tr", { key: apparatus.apparatus.serialNumber }, [
                  _c("td", [_vm._v(_vm._s(apparatus.apparatus.name))]),
                  _c("td", [
                    _vm._v(
                      " " + _vm._s(apparatus.apparatus.serialNumber) + " "
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "btn ml-2 btn-light btn-sm",
                        on: {
                          click: function($event) {
                            return _vm.copyToClipboard(
                              apparatus.apparatus.serialNumber
                            )
                          }
                        }
                      },
                      [_c("i", { staticClass: "far fa-copy colorOrange" })]
                    )
                  ]),
                  _c("td", [
                    _vm._v(
                      " " +
                        _vm._s(_vm.DisplayAddress(apparatus.apparatus)) +
                        " "
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "btn ml-2 btn-light btn-sm",
                        on: {
                          click: function($event) {
                            _vm.copyToClipboard(
                              _vm.DisplayAddress(apparatus.apparatus)
                            )
                          }
                        }
                      },
                      [_c("i", { staticClass: "far fa-copy colorOrange" })]
                    )
                  ])
                ])
              }),
              0
            )
          ]
        )
      : _c("div", [_vm._v(" This user has no generators in their account. ")])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Generator Name")]),
        _c("th", [_vm._v("Serial Number")]),
        _c("th", [_vm._v("Generator Address")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }