<template>
  <div>
    <Modal :modalId="'deviceSwapModal-newDeviceInfo' + oldDevice.apparatusId" size="modal-xl">
      <ModalBody>
        <div class="card no-border">
          <div class="row">
            <div class="col">
              <h1>Replace Device</h1>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <p>
                Please specify the new device type below. Depending on the New Device Type Specified, the MAC address
                and (For Wi-Fi) or Device Serial Number (For 4G LTE Accessories or Wi-Fi/Ethernet Accessories) will be
                required.
                <span class="pre-swap-warning">
                  Please note: the new device MUST be installed and communicating prior to performing the device swap.
                </span>
              </p>
            </div>
          </div>
          <div class="row mt-2 mb-2">
            <div class="col">
              {{ 'Old Device Type' | formatLabelNoTranslation }}
              {{ DisplayOldDeviceType() }}
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-6">
              <div class="form-group" role="group" id="newDeviceType-group">
                <label for="newDevicetype" class="d-block">New Device Type</label>
                <select id="newDevicetype" class="custom-select" v-model="form.newDeviceType">
                  <option v-for="(deviceType, index) in deviceTypes" :key="index" :value="deviceType.value">
                    {{ deviceType.text }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-6">
              <div class="form-group" role="group" id="mobileLinkDeviceIdentifier-group">
                <label for="mobileLinkDeviceIdentifier">Mobile Link Device Identifier</label>
                <div>
                  <input
                    v-if="form.newDeviceType == 'wifi'"
                    id="mobileLinkDeviceIdenifier"
                    type="text"
                    class="form-control"
                    maxlength="17"
                    v-mask="'NN:NN:NN:NN:NN:NN'"
                    v-model="form.mobileLinkDeviceIdentifier"
                  />
                  <input
                    v-else-if="form.newDeviceType == hsbG3DeviceType"
                    id="mobileLinkDeviceIdentifier"
                    type="text"
                    class="form-control"
                    maxlength="9"
                    v-model="form.mobileLinkDeviceIdentifier"
                  />
                  <input
                    v-else
                    id="mobileLinkDeviceIdenifier"
                    type="text"
                    class="form-control"
                    maxlength="10"
                    v-model="form.mobileLinkDeviceIdentifier"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </ModalBody>

      <ModalFooter>
        <button
          type="button"
          class="btn btn-secondary mr-2"
          @click="ResetModal()"
          data-test-hook="cancel-button-newDeviceInfo-device-swap"
        >
          Cancel
        </button>
        <button
          type="button"
          class="btn btn-primary"
          :disabled="CanSwap()"
          @click="validateDevice()"
          data-test-hook="submit-button-newDeviceInfo-device-swap"
        >
          Submit
          <div v-if="validatingDevice" class="spinner-border text-muted ml-2"></div>
        </button>
      </ModalFooter>
    </Modal>

    <!-- Confirm Device Information -->
    <Modal :modalId="'deviceSwapModal-confirmDeviceInfo' + oldDevice.apparatusId">
      <div class="modal-header">
        <div class="row align-items-center">
          <div class="col-auto">
            <i
              class="fas fa-chevron-left back-chevron-button hoverPointer"
              @click="
                closeModal('deviceSwapModal-confirmDeviceInfo' + oldDevice.apparatusId),
                  openModal('deviceSwapModal-newDeviceInfo' + oldDevice.apparatusId)
              "
            />
          </div>
          <div class="col">
            <h2 class="modal-title">
              Confirm Device Information
            </h2>
          </div>
        </div>
      </div>
      <ModalBody>
        <div class="row">
          <div class="col">
            <h5>Device Type: {{ form.newDeviceType === hsbG3DeviceType ? 'G3' : form.newDeviceType }}</h5>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <h5>Device Identifier: {{ form.mobileLinkDeviceIdentifier }}</h5>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <button
          type="button"
          class="btn btn-outline-danger"
          @click="ResetModal()"
          data-test-hook="cancel-button-confirmDeviceInfo-device-swap"
        >
          CANCEL
        </button>
        <button
          type="button"
          class="btn btn-primary"
          @click="getAvailableSubscriptions()"
          data-test-hook="confirm-button-confirmDeviceInfo-device-swap"
        >
          CONFIRM
          <div v-if="gettingSubscriptions" class="spinner-border text-muted ml-2"></div>
        </button>
      </ModalFooter>
    </Modal>

    <!-- Choose A Subscription Modal -->
    <Modal :modalId="'deviceSwapModal-newSubscriptionInfo' + oldDevice.apparatusId" size="modal-xl">
      <div class="modal-header">
        <div class="row">
          <div class="col-auto">
            <i
              class="fas fa-chevron-left back-chevron-button hoverPointer"
              @click="
                closeModal('deviceSwapModal-newSubscriptionInfo' + oldDevice.apparatusId),
                  openModal('deviceSwapModal-confirmDeviceInfo' + oldDevice.apparatusId)
              "
            />
          </div>
          <div class="col">
            <h2 class="modal-title"><i class="far fa-badge-dollar colorOrange mr-1" /> Choose A Subscription</h2>
          </div>
        </div>
      </div>

      <ModalBody>
        <div v-if="gettingSubscriptions">
          <div class="col float-center text-center">
            <b-spinner style="width: 10rem; height: 10rem;" variant="warning" label="Spinning"></b-spinner>
          </div>
        </div>
        <div v-else>
          <div class="row" v-if="availableSubscriptions" align-h="center">
            <div v-for="(product, index) in availableSubscriptions" :key="index" class="mx-auto card-deck">
              <div
                class="card mx-0 mb-3 subscription-card hoverPointer"
                :class="getSubscriptionVariant(product)"
                @click="setSelectedProduct(product)"
              >
                <div class="card-header bg-warning text-white" v-if="product.durationInMonths == 12">
                  <div class="row text-center">
                    <div class="col">
                      <h3 class="colorWhite">Save {{ getSubscriptionSavings(product) }}%</h3>
                    </div>
                  </div>
                </div>
                <h4 class="card-title text-center">
                  <h2>{{ getProductCost(product) }}</h2>
                </h4>
                <div class="card-body text-center">
                  <div class="row mb-2">
                    <h2>{{ product.name }}</h2>
                  </div>
                </div>
                <div class="footer">
                  <button
                    type="button"
                    class="btn btn-warning btn-block"
                    :disabled="getSubscriptionVariant(product) != 'selected'"
                    @click="SwapOrPay()"
                    data-test-hook="select-button-newSubscriptionInfo-device-swap"
                  >
                    SELECT
                    <div
                      class="spinner-border text-muted ml-2"
                      v-if="getSubscriptionVariant(product) == 'selected' && paying"
                    ></div>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="row" v-else>
            <div class="col">
              <h5>No Subscription types available.</h5>
            </div>
          </div>
        </div>
      </ModalBody>

      <ModalFooter>
        <button
          type-="button"
          class="btn btn-outline-danger"
          @click="ResetModal()"
          data-test-hook="cancel-button-newSubscriptionInfo-device-swap"
        >
          CANCEL
        </button>
      </ModalFooter>
    </Modal>

    <!-- Add A Payment Method modal -->
    <Modal :modalId="'deviceSwapModal-paymentInformation' + oldDevice.apparatusId" size="modal-lg">
      <div class="modal-header">
        <div class="row align-items-center">
          <div class="col-auto">
            <i
              class="fas fa-chevron-left back-chevron-button hoverPointer"
              @click="
                closeModal('deviceSwapModal-paymentInformation' + oldDevice.apparatusId),
                  openModal('deviceSwapModal-newSubscriptionInfo' + oldDevice.apparatusId)
              "
            />
          </div>
          <div class="col">
            <h2 class="modal-title"><i class="far fa-credit-card colorOrange mr-1"></i> Add A Payment Method</h2>
          </div>
        </div>
      </div>
      <ModalBody>
        <div v-if="gettingPaymentInfo">
          <div class="col float-center text-center">
            <div class="spinner-border spinner-warning ml-2" style="width: 10rem; height: 10rem;"></div>
          </div>
        </div>
        <div v-else>
          <div v-if="existingPaymentInfo">
            <div class="row" v-if="useExistingPaymentInfo">
              <div class="col">
                <h3><strong>Current Credit Card Information On File</strong><span class="body-text-color">*</span></h3>
              </div>
            </div>
            <div class="row" v-else>
              <div class="col">
                <h3><strong>Update Credit Card Information</strong><span class="body-text-color">*</span></h3>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="font-weight-bold custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    v-model="useExistingPaymentInfo"
                    value="true"
                    id="customCheck"
                    data-test-hook="use-current-cc-info-checkbox-paymentInformation-device-swap"
                  />
                  <label class="custom-control-check" for="customCheck">Use Current Credit Card Information</label>
                </div>
              </div>
            </div>
            <div v-if="useExistingPaymentInfo">
              <div class="row">
                <div class="col-12">
                  <p>
                    <strong>
                      <i class="fal fa-credit-card" />
                      {{ existingPaymentInfo.cardType }} -
                      {{ existingPaymentInfo.cardSuffix }}
                    </strong>
                  </p>
                  <p>Expires {{ existingPaymentInfo.expirationMonth + '/' + existingPaymentInfo.expirationYear }}</p>
                </div>
              </div>
            </div>
            <div v-else>
              <div class="row">
                <div class="col-12">
                  <fieldset class="form-group" id="credit-card-number">
                    <div>
                      <input
                        type="text"
                        placeholder="Credit Card Number"
                        class="form-control"
                        v-model="creditCardNumber"
                        aria-describedby="card-number-invalid"
                        @keypress="creditCardValidation($event, creditCardNumber)"
                        @input="creditCardIsValid = null"
                        :state="creditCardIsValid"
                      />
                      <div id="card-number-invalid" class="invalid-feedback">
                        Please enter a valid credit card number.
                      </div>
                    </div>
                  </fieldset>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12 col-md-6 col-lg-6">
                  <fieldset class="form-group" id="expiry-date">
                    <input
                      type="text"
                      placeholder="MM/YYYY"
                      class="form-control"
                      maxlength="7"
                      aria-describedby="expiry-invalid"
                      v-model="fullExpiryDate"
                      v-mask="'##/####'"
                      :state="expiryDateIsValid"
                      @input="expiryDateIsValid = null"
                    />
                    <div class="invalid-feedback" id="expiry-invalid">
                      Please enter a valid expiration date.
                    </div>
                  </fieldset>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6">
                  <div class="input-group" role="group" id="cvv">
                    <input
                      type="text"
                      placeholder="CVV"
                      v-model="cvv"
                      class="form-control"
                      maxlength="4"
                      aria-describedby="cvv-invalid"
                      @keypress="cvvValidation($event, cvv)"
                      :state="cvvIsValid"
                      @input="cvvIsValid = null"
                    />
                    <div class="input-group-append">
                      <div class="input-group-text">
                        <img :height="20" :src="require('@/assets/images/3-digit-code.svg')" />
                      </div>
                    </div>
                    <div class="invalid-feedback" id="cvv-invalid">
                      Please enter a valid security code.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <div class="row">
              <div class="col-12">
                <h3><strong>Enter Your Credit Card Information</strong></h3>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <fieldset class="form-group" id="credit-card-number">
                  <div>
                    <input
                      type="text"
                      placeholder="Credit Card Number"
                      class="form-control"
                      v-model="creditCardNumber"
                      aria-describedby="card-number-invalid"
                      @keypress="creditCardValidation($event, creditCardNumber)"
                      @input="creditCardIsValid = null"
                      :state="creditCardIsValid"
                    />
                    <div id="card-number-invalid" class="invalid-feedback">
                      Please enter a valid credit card number.
                    </div>
                  </div>
                </fieldset>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 col-md-6 col-lg-6">
                <fieldset class="form-group" id="expiry-date">
                  <input
                    type="text"
                    placeholder="MM/YYYY"
                    class="form-control"
                    maxlength="7"
                    aria-describedby="expiry-invalid"
                    v-model="fullExpiryDate"
                    v-mask="'##/####'"
                    :state="expiryDateIsValid"
                    @input="expiryDateIsValid = null"
                  />
                  <div class="invalid-feedback" id="expiry-invalid">
                    Please enter a valid expiration date.
                  </div>
                </fieldset>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-6">
                <div class="input-group" role="group" id="cvv">
                  <input
                    type="text"
                    placeholder="CVV"
                    v-model="cvv"
                    class="form-control"
                    maxlength="4"
                    aria-describedby="cvv-invalid"
                    @keypress="cvvValidation($event, cvv)"
                    :state="cvvIsValid"
                    @input="cvvIsValid = null"
                  />
                  <div class="input-group-append">
                    <div class="input-group-text">
                      <img :height="20" :src="require('@/assets/images/3-digit-code.svg')" />
                    </div>
                  </div>
                  <div class="invalid-feedback" id="cvv-invalid">
                    Please enter a valid security code.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="selectedProduct">
            <div class="row mt-5">
              <div class="col-12 text-left text-secondary" style="font-size: 1.25rem;">
                Total Payment:
              </div>
            </div>
            <div class="row">
              <div class="col-12 text-left">
                <h3>
                  <strong>{{ selectedProduct.cost.toFixed(2) | formatCurrency }}</strong>
                </h3>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <i class="body-text-color">
                  Billed
                  <span v-if="selectedProduct.durationInMonths == 12">Yearly</span>
                  <span v-else>Monthly</span>
                </i>
              </div>
            </div>
          </div>
          <div class="row text-left asterisk-text">
            <div class="col">
              *Updating your credit card information will apply to all active subscriptions.
            </div>
          </div>
          <div class="row text-left asterisk-text">
            <div class="col">
              {{ $t('xThisSubscriptionWillAutoRenew') }}
            </div>
          </div>
        </div>
      </ModalBody>

      <ModalFooter>
        <button
          type="button"
          class="btn btn-outline-danger"
          @click="ResetModal()"
          data-test-hook="cancel-button-paymentInformation-device-swap"
        >
          CANCEL
        </button>
        <button
          type="button"
          class="btn btn-primary"
          @click="submitSwap()"
          :disabled="!canPay()"
          data-test-hook="submit-swap-button-paymentInformation-device-swap"
        >
          {{ 'Submit Swap' }}
          <div class="spinner-border text-muted ml-2" v-if="swapping"></div>
        </button>
      </ModalFooter>
    </Modal>

    <!-- Success modal -->
    <Modal :modalId="'deviceSwapModal-swapSuccess' + oldDevice.apparatusId">
      <div class="modal-header">
        <div class="row align-items-center">
          <div class="col-auto">
            <i
              class="fas fa-chevron-left back-chevron-button hoverPointer"
              @click="
                closeModal('deviceSwapModal-swapSuccess' + oldDevice.apparatusId),
                  openModal('deviceSwapModal-paymentInformation' + oldDevice.apparatusId)
              "
            />
          </div>
          <div class="col">
            <h2 class="modal-title">
              Success!
            </h2>
          </div>
        </div>
      </div>
      <ModalBody>
        <div class="col">
          <h5>Please clear this search, and search for the new device:</h5>
        </div>
        <div class="row">
          <div class="col" v-if="validatedDeviceInfo">
            <h3>
              {{ validatedDeviceInfo.deviceId }}
            </h3>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <button
          type="button"
          class="btn btn-primary"
          @click="ResetModal()"
          data-test-hook="close-button-swapSuccess-device-swap"
        >
          Close
        </button>
      </ModalFooter>
    </Modal>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import * as Constants from '@/constants'

export default {
  props: ['oldDevice'],
  data() {
    return {
      form: {
        newDeviceType: null,
        mobileLinkDeviceIdentifier: null
      },
      validatingDevice: false,
      validatedDeviceInfo: null,
      gettingSubscriptions: false,
      availableSubscriptions: null,
      existingPaymentInfo: null,
      selectedProduct: null,
      paying: false,
      useExistingPaymentInfo: true,
      creditCardNumber: null,
      fullExpiryDate: null,
      cvv: null,
      creditCardIsValid: null,
      cvvIsValid: null,
      expiryDateIsValid: null,
      gettingPaymentInfo: true,
      swapping: false
    }
  },
  computed: {
    deviceTypes: function() {
      let devices = [
        { value: 'wifi', text: 'WiFi' },
        { value: 'lte', text: 'Lte' },
        { value: 'eth', text: 'WiFi/Ethernet' },
        { value: Constants.HsbG3DeviceType, text: 'G3' }
      ]
      return devices
    },
    hsbG3DeviceType() {
      return Constants.HsbG3DeviceType
    }
  },
  methods: {
    async SwapOrPay() {
      this.paying = true
      if (this.selectedProduct.cost == 0) {
        this.submitSwap()
      } else {
        this.openModal('deviceSwapModal-paymentInformation' + this.oldDevice.apparatusId)
      }
      this.paying = false
    },
    submitSwap() {
      this.swapping = true
      let swapRequest = {
        organizationId: this.oldDevice.organizationId,
        apparatusId: this.oldDevice.apparatusId,
        deviceId: this.validatedDeviceInfo.deviceId,
        deviceType: this.validatedDeviceInfo.deviceType,
        productId: this.selectedProduct.id
      }

      if (this.selectedProduct.cost == 0 || (this.existingPaymentInfo && this.useExistingPaymentInfo)) {
        this.$store
          .dispatch('swapDevice', swapRequest)
          .then(response => {
            this.swapping = false
            this.paying = false
            if (response.data.success) {
              this.closeModal('deviceSwapModal-newSubscriptionInfo' + this.oldDevice.apparatusId)
              this.closeModal('deviceSwapModal-paymentInformation' + this.oldDevice.apparatusId)
              this.openModal('deviceSwapModal-swapSuccess' + this.oldDevice.apparatusId)
            } else {
              this.$store.dispatch('displayToast', {
                title: this.$t('xError'),
                message: 'Something unexpected went wrong.',
                variant: 'danger'
              })
            }
          })
          .catch(error => {
            if (error.response.data.status == 401) {
              this.$store.dispatch('displayToast', {
                title: this.$t('xError'),
                message: 'Your session has expired, please re-log in.',
                variant: 'danger'
              })
              this.forceSignOut()
            } else {
              this.paying = false
              this.swapping = false
              this.$store.dispatch('displayToast', {
                title: this.$t('xError'),
                message: error.response.data.message,
                variant: 'danger'
              })
            }
          })
      } else if (this.existingPaymentInfo && !this.useExistingPaymentInfo) {
        let removePaymentModel = {
          organizationId: this.oldDevice.organizationId,
          paymentId: this.existingPaymentInfo.id
        }
        let expiryDateSplit = this.fullExpiryDate.split('/')
        let addPaymentModel = {
          organizationId: this.oldDevice.organizationId,
          cardNumber: this.creditCardNumber,
          cvv: this.cvv,
          expirationMonth: expiryDateSplit[0],
          expirationYear: expiryDateSplit[1]
        }
        this.$store
          .dispatch('addBillingAccountPaymentMethod', addPaymentModel)
          .then(response => {
            this.$store
              .dispatch('removeBillingAccountPaymentMethod', removePaymentModel)
              .then(response => {
                this.$store
                  .dispatch('swapDevice', swapRequest)
                  .then(response => {
                    this.swapping = false
                    this.paying = false
                    if (response.data.success) {
                      this.closeModal('deviceSwapModal-newSubscriptionInfo' + this.oldDevice.apparatusId)
                      this.closeModal('deviceSwapModal-paymentInformation' + this.oldDevice.apparatusId)
                      this.openModal('deviceSwapModal-swapSuccess' + this.oldDevice.apparatusId)
                    } else {
                      this.$store.dispatch('displayToast', {
                        title: this.$t('xError'),
                        message: 'Something unexpected went wrong.',
                        variant: 'danger'
                      })
                    }
                  })
                  .catch(error => {
                    if (error.response.data.status == 401) {
                      this.$store.dispatch('displayToast', {
                        title: this.$t('xError'),
                        message: 'Your session has expired, please re-log in.',
                        variant: 'danger'
                      })
                      this.forceSignOut()
                    } else {
                      this.paying = false
                      this.swapping = false
                      this.$store.dispatch('displayToast', {
                        title: this.$t('xError'),
                        message: error.response.data.message,
                        variant: 'danger'
                      })
                    }
                  })
              })
              .catch(error => {
                if (error.response.data.status == 401) {
                  this.$store.dispatch('displayToast', {
                    title: this.$t('xError'),
                    message: 'Your session has expired, please re-log in.',
                    variant: 'danger'
                  })
                  this.forceSignOut()
                } else {
                  this.paying = false
                  this.$store.dispatch('displayToast', {
                    title: this.$t('xError'),
                    message: 'Failed to remove existing payment information.',
                    variant: 'danger'
                  })
                }
              })
          })
          .catch(error => {
            if (error.response.data.status == 401) {
              this.$store.dispatch('displayToast', {
                title: this.$t('xError'),
                message: 'Your session has expired, please re-log in.',
                variant: 'danger'
              })
              this.forceSignOut()
            } else {
              this.paying = false
              this.gettingPaymentInfo = false
              if (error.response.data.errors) {
                if (error.response.data.errors.CardNumber || error.response.data.errors.cardNumber) {
                  this.creditCardIsValid = false
                }
                if (error.response.data.errors.Cvv || error.response.data.errors.cvv) {
                  this.cvvIsValid = false
                }
                if (
                  error.response.data.errors.ExpirationMonth ||
                  error.response.data.errors.ExpirationYear ||
                  error.response.data.errors.expirationMonth ||
                  error.response.data.errors.expirationYear
                ) {
                  this.expiryDateIsValid = false
                }
                if (error.response.data.errors.billing || error.response.data.errors.Billing) {
                  this.$store.dispatch('displayToast', {
                    title: this.$t('xError'),
                    message: error.response.data.errors.billing
                      ? error.response.data.errors.billing
                      : error.data.response.errors.Billing,
                    variant: 'danger'
                  })
                }
              } else {
                this.$store.dispatch('displayToast', {
                  title: this.$t('xError'),
                  message: 'Failed to add payment method.',
                  variant: 'danger'
                })
              }
            }
          })
      } else {
        let expiryDateSplit = this.fullExpiryDate.split('/')
        let addPaymentModel = {
          organizationId: this.oldDevice.organizationId,
          cardNumber: this.creditCardNumber,
          cvv: this.cvv,
          expirationMonth: expiryDateSplit[0],
          expirationYear: expiryDateSplit[1]
        }
        this.$store
          .dispatch('addBillingAccountPaymentMethod', addPaymentModel)
          .then(response => {
            this.$store
              .dispatch('swapDevice', swapRequest)
              .then(response => {
                this.swapping = false
                this.paying = false
                if (response.data.success) {
                  this.closeModal('deviceSwapModal-newSubscriptionInfo' + this.oldDevice.apparatusId)
                  this.closeModal('deviceSwapModal-paymentInformation' + this.oldDevice.apparatusId)
                  this.openModal('deviceSwapModal-swapSuccess' + this.oldDevice.apparatusId)
                } else {
                  this.$store.dispatch('displayToast', {
                    title: this.$t('xError'),
                    message: 'Something unexpected went wrong.',
                    variant: 'danger'
                  })
                }
              })
              .catch(error => {
                if (error.response.data.status == 401) {
                  this.$store.dispatch('displayToast', {
                    title: this.$t('xError'),
                    message: 'Your session has expired, please re-log in.',
                    variant: 'danger'
                  })
                  this.forceSignOut()
                } else {
                  this.paying = false
                  this.swapping = false
                  this.$store.dispatch('displayToast', {
                    title: this.$t('xError'),
                    message: error.response.data.message,
                    variant: 'danger'
                  })
                }
              })
          })
          .catch(error => {
            if (error.response.data.status == 401) {
              this.$store.dispatch('displayToast', {
                title: this.$t('xError'),
                message: 'Your session has expired, please re-log in.',
                variant: 'danger'
              })
              this.forceSignOut()
            } else {
              this.paying = false
              this.gettingPaymentInfo = false
              if (error.response.data.errors) {
                if (error.response.data.errors.CardNumber || error.response.data.errors.cardNumber) {
                  this.creditCardIsValid = false
                }
                if (error.response.data.errors.Cvv || error.response.data.errors.cvv) {
                  this.cvvIsValid = false
                }
                if (
                  error.response.data.errors.ExpirationMonth ||
                  error.response.data.errors.ExpirationYear ||
                  error.response.data.errors.expirationMonth ||
                  error.response.data.errors.expirationYear
                ) {
                  this.expiryDateIsValid = false
                }
                if (error.response.data.errors.billing || error.response.data.errors.Billing) {
                  this.$store.dispatch('displayToast', {
                    title: this.$t('xError'),
                    message: error.response.data.errors.billing
                      ? error.response.data.errors.billing
                      : error.data.response.errors.Billing,
                    variant: 'danger'
                  })
                }
              } else {
                this.$store.dispatch('displayToast', {
                  title: this.$t('xError'),
                  message: 'Failed to add payment method.',
                  variant: 'danger'
                })
              }
            }
          })
      }
    },
    canPay() {
      if (this.existingPaymentInfo && this.useExistingPaymentInfo) {
        return true
      } else if (!this.useExistingPaymentInfo || !this.existingPaymentInfo) {
        if (
          this.creditCardNumber &&
          (this.creditCardNumber.length == 16 || this.creditCardNumber.length == 15) &&
          this.fullExpiryDate &&
          this.fullExpiryDate.length == 7 &&
          this.cvv &&
          (this.cvv.length == 3 || this.cvv.length == 4)
        ) {
          return true
        }
      } else return false
    },
    validateDevice() {
      this.validatingDevice = true
      this.$store
        .dispatch('ValidateDevice', {
          deviceType: this.form.newDeviceType,
          macAddress: this.form.mobileLinkDeviceIdentifier,
          searchLabel: this.form.mobileLinkDeviceIdentifier,
          shortDeviceId: this.form.mobileLinkDeviceIdentifier
        })
        .then(response => {
          this.validatedDeviceInfo = _.cloneDeep({ ...response.data })
          this.validatingDevice = false
          if (response.data.isValid) {
            this.closeModal('deviceSwapModal-newDeviceInfo' + this.oldDevice.apparatusId)
            this.openModal('deviceSwapModal-confirmDeviceInfo' + this.oldDevice.apparatusId)
          } else {
            this.$store.dispatch('displayToast', {
              title: this.$t('xError'),
              message: 'An unexpected error occurred',
              variant: 'danger'
            })
          }
        })
        .catch(error => {
          if (error.response.data.status == 401) {
            this.$store.dispatch('displayToast', {
              title: this.$t('xError'),
              message: 'Your session has expired, please re-log in.',
              variant: 'danger'
            })
            this.forceSignOut()
          } else {
            this.validatingDevice = false
            if (error && error.response) {
              this.validatedDeviceInfo = error.response.data
              this.$store.dispatch('displayToast', {
                title: this.$t('xError'),
                message: this.validatedDeviceInfo.message,
                variant: 'danger'
              })
            }
          }
        })
    },
    getAvailableSubscriptions() {
      this.gettingSubscriptions = true
      this.$store
        .dispatch('getSubscriptionProductsByDeviceType', this.form.newDeviceType)
        .then(response => {
          this.gettingSubscriptions = false
          this.availableSubscriptions = response.data
          let initialProduct = this.availableSubscriptions.filter(x => x.durationInMonths == 12)
          this.selectedProduct = initialProduct[0]
          this.openModal('deviceSwapModal-newSubscriptionInfo' + this.oldDevice.apparatusId)
        })
        .catch(error => {
          if (error.response.data.status == 401) {
            this.$store.dispatch('displayToast', {
              title: this.$t('xError'),
              message: 'Your session has expired, please re-log in.',
              variant: 'danger'
            })
            this.forceSignOut()
          } else {
            this.gettingSubscriptions = false
            this.$store.dispatch('displayToast', {
              title: this.$t('xError'),
              message: 'Failed to get subscription products',
              variant: 'danger'
            })
          }
        })
    },
    CanSwap() {
      if (this.form.newDeviceType && this.form.mobileLinkDeviceIdentifier) {
        return false
      }
      return true
    },
    SwapDevice() {
      this.$store.dispatch('displayToast', { title: this.$t('xError'), message: null, variant: 'danger' })
    },
    ResetModal() {
      this.form = {
        newDeviceType: null,
        mobileLinkDeviceIdentifier: null
      }
      this.validatingDevice = false
      this.validatedDeviceInfo = null
      this.gettingSubscriptions = false
      this.availableSubscriptions = null
      this.existingPaymentInfo = null
      this.selectedProduct = null
      this.paying = false
      this.useExistingPaymentInfo = true
      this.creditCardNumber = null
      this.fullExpiryDate = null
      this.cvv = null
      this.creditCardIsValid = null
      this.cvvIsValid = null
      this.expiryDateIsValid = null
      this.gettingPaymentInfo = false
      this.swapping = false
      this.closeModal('deviceSwapModal-newDeviceInfo' + this.oldDevice.apparatusId)
      this.closeModal('deviceSwapModal-confirmDeviceInfo' + this.oldDevice.apparatusId)
      this.closeModal('deviceSwapModal-newSubscriptionInfo' + this.oldDevice.apparatusId)
      this.closeModal('deviceSwapModal-paymentInformation' + this.oldDevice.apparatusId)
      this.closeModal('deviceSwapModal-swapSuccess' + this.oldDevice.apparatusId)
    },
    DisplayOldDeviceType() {
      if (this.oldDevice.deviceInfo.deviceType === 'lte') {
        return 'Lte'
      } else if (this.oldDevice.deviceInfo.deviceType === 'wifi') {
        return 'WiFi'
      } else if (this.oldDevice.deviceInfo.deviceType === 'eth') {
        return 'WiFi/Ethernet'
      } else if (this.oldDevice.deviceInfo.deviceType === 'cdma') {
        return 'CDMA'
      } else if (this.oldDevice.deviceInfo.deviceType === Constants.HsbG3DeviceType) {
        return 'G3'
      }
    },
    getSubscriptionVariant(product) {
      if (this.selectedProduct) {
        if (this.selectedProduct.id == product.id) return 'selected'
      } else if (product.durationInMonths == 12) {
        return 'selected'
      }
    },
    setSelectedProduct(product) {
      this.selectedProduct = product
    },
    getProductCost(product) {
      if (product.durationInMonths == 12) {
        return '$' + product.cost.toFixed(2) + '/Year'
      } else if (product.durationInMonths == 1) {
        return '$' + product.cost.toFixed(2) + '/Month'
      } else return 'Free'
    },
    getSubscriptionSavings(product) {
      let monthlySubscription = this.availableSubscriptions.filter(x => x.durationInMonths == 1)
      return Math.round(((monthlySubscription[0].cost * 12 - product.cost) / (monthlySubscription[0].cost * 12)) * 100)
    }
  },
  watch: {
    oldDevice: function(newVal, oldVal) {
      if (newVal) {
        this.$store
          .dispatch('getActivePaymentMethod', this.oldDevice.organizationId)
          .then(response => {
            this.gettingPaymentInfo = false
            this.existingPaymentInfo = response.data
          })
          .catch(error => {
            if (error.response.data.status == 401) {
              this.$store.dispatch('displayToast', {
                title: this.$t('xError'),
                message: 'Your session has expired, please re-log in.',
                variant: 'danger'
              })
              this.forceSignOut()
            } else {
              this.gettingPaymentInfo = false
              this.existingPaymentInfo = null
            }
          })
      }
    }
  },
  mounted() {
    if (this.oldDevice) {
      this.$store
        .dispatch('getActivePaymentMethod', this.oldDevice.organizationId)
        .then(response => {
          this.gettingPaymentInfo = false
          this.existingPaymentInfo = response.data
        })
        .catch(error => {
          if (error.response.data.status == 401) {
            this.$store.dispatch('displayToast', {
              title: this.$t('xError'),
              message: 'Your session has expired, please re-log in.',
              variant: 'danger'
            })
            this.forceSignOut()
          } else {
            this.gettingPaymentInfo = false
            this.existingPaymentInfo = null
          }
        })
    }
  }
}
</script>
<style scoped>
.pre-swap-warning {
  font-weight: bold;
  font-style: italic;
  color: var(--yellow-900);
}
.subscription-card {
  max-width: 300px;
}
</style>
