import Axios from 'axios'
import router from '@/router/'
import _ from 'lodash'
import { vueAppApiBaseAddress } from '@/utils/mixins'

var initialState = {
  user: null,
  userLoaded: false,
  accountProfile: null,
  accountInfo: null,
  notificationApparatuses: null,
  newUserEmail: null,
  loginError: false,
  expiredLinkError: false,
  correlationError: false,
  hasUnreadMessages: false,
  messages: null
}

export const state = _.cloneDeep(initialState)

export const getters = {}

export const mutations = {
  accountProfileReset(state) {
    state.accountProfile = initialState.accountProfile
  },
  setUser(state, user) {
    state.user = user
  },
  setAccountInfo(state, accountInfo) {
    state.accountInfo = accountInfo
  },
  setUserSettings(state, user) {
    state.user.mobileLinkSettings = user.mobileLinkSettings
    state.user.settings = user.settings
  },
  setUserLoaded(state, loaded) {
    state.userLoaded = loaded
  },
  setAccountProfile(state, profile) {
    state.accountProfile = profile
  },
  setNotificationApparatuses(state, apparatuses) {
    state.notificationApparatuses = apparatuses
  },
  setNewUserEmail(state, email) {
    state.newUserEmail = email
  },
  setLoginError(state, loginError) {
    state.loginError = loginError
  },
  setExpiredLinkError(state, expiredLinkError) {
    state.expiredLinkError = expiredLinkError
  },
  setCorrelationError(state, correlationError) {
    state.correlationError = correlationError
  },
  setInviteError(state, inviteError) {
    state.inviteError = inviteError
  },
  setHasUnreadMessages(state, value) {
    state.hasUnreadMessages = value
  },
  setMessages(state, messages) {
    state.messages = messages
  }
}

export const actions = {
  accountProfileReset({ commit }) {
    commit('accountProfileReset')
  },
  async getUser({ getters, commit, dispatch, rootGetters }) {
    if (rootGetters.currentRouteName == 'getstarted' || rootGetters.currentRouteName == 'landingDownload') {
      return
    }
    if (document.cookie.indexOf('MobileLinkClientCookie=') != -1) {
      return await Axios.get(vueAppApiBaseAddress() + `v1/Account/`, {
        withCredentials: true
      })
        .then(response => {
          commit('setUser', response.data)
          commit('setNewUserEmail', response.data.emails[0])
          var toRoute = JSON.parse(sessionStorage.getItem('toRoute'))
          if (toRoute != null) {
            router.push(toRoute.path)
            sessionStorage.setItem('toRoute', null)
          } else if (router.history.current.name == 'home' || router.history.current.name == 'createAccount') {
            commit('setNewUserEmail', response.data.emails[0])
            router.push('/dashboard')
          }
          window.setInterval(() => {
            if (document.cookie.indexOf('MobileLinkClientCookie=') == -1 && router.history.current.name != 'home') {
              router.push('/')
            }
          }, 3000)
        })
        .catch(error => {
          if (error.response.status == 404) {
            commit('setNewUserEmail', error.response.data)
            router.push('/createAccount')
          } else if (error.response.status == 418) {
            commit('setUser', error.response.data)
            if (router.history.current.name != 'admin') {
              router.push('/admin')
            }
          } else {
            document.cookie = 'MobileLinkClientCookie=; Max-Age=0; path=/; domain=' + location.host
            if (window.location.pathname.includes('loginError')) {
              commit('setLoginError', true)
            } else if (window.location.pathname.includes('expiredLink')) {
              commit('setExpiredLinkError', true)
            } else if (window.location.pathname.includes('correlationError')) {
              commit('setCorrelationError', true)
            } else if (window.location.pathname.includes('inviteError')) {
              commit('setInviteError', true)
            }
            router.push('/')
          }
        })
        .finally(() => {
          commit('setUserLoaded', true)
        })
    } else {
      if (window.location.pathname.includes('loginError')) {
        commit('setLoginError', true)
      } else if (window.location.pathname.includes('expiredLink')) {
        commit('setExpiredLinkError', true)
      } else if (window.location.pathname.includes('correlationError')) {
        commit('setCorrelationError', true)
      } else if (window.location.pathname.includes('inviteError')) {
        commit('setInviteError', true)
      }
      commit('setUserLoaded', true)
    }
  },
  async getImpersonatedUser({ getters, commit, dispatch }) {
    return await Axios.get(vueAppApiBaseAddress() + `v1/Account/impersonated/user`, {
      withCredentials: true
    })
      .then(response => {
        commit('setUser', response.data)
      })
      .catch(error => {
        dispatch('displayToast', {
          title: i18n.t('xError'),
          message: i18n.t('xErrorGettingUser'),
          variant: 'danger'
        })
      })
      .finally(() => {
        commit('setUserLoaded', true)
      })
  },
  async getUserSettings({ getters, commit, dispatch }) {
    await Axios.get(vueAppApiBaseAddress() + `v1/Account/settings`, {
      withCredentials: true
    })
      .then(response => {
        commit('setUserSettings', response.data)
      })
      .catch(error => {})
      .finally(() => {})
  },
  async getAccountStatus({ getters, commit, dispatch }) {
    return await Axios.get(vueAppApiBaseAddress() + `v1/Account/status`, { withCredentials: true })
  },
  async getAccountInfo({ getters, commit, dispatch }) {
    return await Axios.get(vueAppApiBaseAddress() + `v1/Account/info`, { withCredentials: true })
      .then(resp => {
        commit('setAccountInfo', resp.data)
      })
      .catch(error => {})
      .finally(() => {})
  },
  async getAccountProfile({ getters, commit, dispatch }) {
    return await Axios.get(vueAppApiBaseAddress() + `v1/Account/profile`, {
      withCredentials: true
    })
      .then(response => {
        commit('setAccountProfile', response.data)
      })
      .catch(error => {})
      .finally(() => {})
  },
  async toggleNotificationType({ getters, commit, dispatch }, data) {
    return await Axios.put(vueAppApiBaseAddress() + `v1/Account/notificationType`, data, {
      withCredentials: true
    })
  },
  async updateAccountHolder({ getters, commit, dispatch }, accountHolder) {
    return await Axios.put(vueAppApiBaseAddress() + `v1/Account/accountHolder`, accountHolder, {
      withCredentials: true
    })
  },
  async getNotificationApparatuses({ getters, commit, dispatch }) {
    return await Axios.get(vueAppApiBaseAddress() + `v2/Account/notificationApparatuses`, {
      withCredentials: true
    })
      .then(response => {
        commit('setNotificationApparatuses', response.data)
      })
      .catch(error => {})
      .finally(() => {})
  },
  async getApparatusPreferencesById({ getters, commit, dispatch }, apparatusId) {
    return await Axios.get(vueAppApiBaseAddress() + `v1/Account/apparatusPreferences/${apparatusId}`, {
      withCredentials: true
    })
  },
  async getApparatusPreferences({ getters, commit, dispatch }, apparatus) {
    return await Axios.get(
      vueAppApiBaseAddress() +
        `v1/Account/apparatusPreferences/${apparatus.apparatusId}?apparatusType=${apparatus.apparatusType}`,
      {
        withCredentials: true
      }
    )
  },
  async getApparatusesPreferences({ getters, commit, dispatch }, apparatuses) {
    return await Axios.post(
      vueAppApiBaseAddress() + `v1/Account/apparatusesPreferences/`,
      { apparatuses: apparatuses.apparatusesPrefs },
      { withCredentials: true }
    )
  },
  async updateApparatusPreference({ getters, commit, dispatch }, updatePrefInfo) {
    return await Axios.put(
      vueAppApiBaseAddress() + `v1/Account/updatePreference/${updatePrefInfo.apparatusId}`,
      updatePrefInfo.preference,
      {
        withCredentials: true
      }
    )
  },
  async updatePreference({ getters, commit, dispatch }, request) {
    return await Axios.put(vueAppApiBaseAddress() + `v2/Account/update/preference/${request.id}`, request, {
      withCredentials: true
    })
  },
  async updateContactInfo({ getters, commit, dispatch }, updateContactInfo) {
    return await Axios.put(vueAppApiBaseAddress() + `v4/Account/updateContactInfo`, updateContactInfo, {
      withCredentials: true
    })
  },
  async createNewAccount({ getters, commit, dispatch }, newAccountInfo) {
    return await Axios.post(vueAppApiBaseAddress() + `v1/Account/create`, newAccountInfo, {
      withCredentials: true
    })
  },
  async updateAccountSettings({ getters, commit, dispatch }, request) {
    return await Axios.post(vueAppApiBaseAddress() + `v1/Account/update/settings`, request, {
      withCredentials: true
    })
  },
  async updateMobileLinkSettings({ getters, commit, dispatch }, request) {
    return await Axios.post(vueAppApiBaseAddress() + `v1/Account/update/mobileLinkSettings`, request, {
      withCredentials: true
    })
  },
  async setDefaultPreferences({ getters, commit, dispatch }, request) {
    await Axios.post(vueAppApiBaseAddress() + `v1/Account/default/preferences`, request, {
      withCredentials: true
    })
  },
  async managePaidPreferences({ getters, commit, dispatch }, request) {
    await Axios.post(vueAppApiBaseAddress() + `v1/Account/manage/notifications`, request, {
      withCredentials: true
    })
  },
  async clearNotificationApparatuses({ getters, commit, dispatch }) {
    commit('setNotificationApparatuses', null)
  },
  async disableAccount({ getters, commit, dispatch }, request) {
    return await Axios.delete(vueAppApiBaseAddress() + `v2/Account/${request.orgId}/disable`, {
      withCredentials: true
    })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
