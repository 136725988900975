<template>
  <div>
    <div v-if="!statusHistory" class="d-flex justify-content-center mb-3 pt-4">
      <div class="spinner-border text-warning" style="width: 5rem; height: 5rem;" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div v-else>
      <div class="row my-4 d-md-none no-gutters">
        <div class="col-auto">
          <i class="fas fa-history fa-2x colorOrange"></i>
        </div>
        <div class="ml-2 col">
          <h3>{{ $t('xStatusHistory') }}</h3>
        </div>
      </div>
      <div class="card">
        <div class="list-group list-group-flush">
          <div v-for="(status, index) in statusHistory" :key="index" :id="index" class="list-group-item">
            <div class="row">
              <div class="col-auto">
                <small>{{ displayShortDateTimeWithTZ(status.eventOccurred) }}</small>
              </div>
            </div>
            <div class="row no-gutters center">
              <div class="col-auto">
                <StatusIcon
                  :apparatusStatus="status.type"
                  :isConnected="true"
                  :showWarning="false"
                  :small="true"
                  :deviceType="
                    deviceInfo == null
                      ? hsbLteDeviceType
                      : deviceInfo.deviceType == hsbG3DeviceType
                      ? deviceInfo.networkType
                      : deviceInfo.deviceType
                  "
                />
              </div>
              <div class="col ml-2">
                {{ status.message }}
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <button
            class="btn btn-outline-warning"
            @click="viewMore()"
            :disabled="noMoreHistory"
            type="button"
            data-test-hook="view-more-button-status-history"
          >
            View More
            <div v-if="loadingAdditionalStatusHistory" class="ml-2 spinner-border text-dark" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import StatusIcon from '@/components/StatusIcon'
import * as Constants from '@/constants'

export default {
  components: { StatusIcon },
  props: ['apparatusId'],
  data() {
    return {
      deviceInfo: null,
      loadingAdditionalStatusHistory: false,
      noMoreHistory: false,
      pageNumber: 1,
      pageSize: 15,
      statusHistory: null
    }
  },
  computed: {
    ...mapState({
      currentApparatusStatusHistory: state => state.apparatus.currentApparatusStatusHistory
    }),
    hsbG3DeviceType() {
      return Constants.HsbG3DeviceType
    },
    hsbLteDeviceType() {
      return Constants.HsbLteDeviceType
    }
  },
  methods: {
    viewMore() {
      if (this.statusHistory) {
        this.loadingAdditionalStatusHistory = true
        this.pageNumber++
        this.$store
          .dispatch('getApparatusStatusHistory', {
            apparatusId: this.apparatusId,
            pageNumber: this.pageNumber,
            pageSize: this.pageSize
          })
          .then(response => {
            if (response.data.statusHistory.length > 0) {
              this.statusHistory.push(...response.data.statusHistory)
            } else this.noMoreHistory = true
            this.loadingAdditionalStatusHistory = false
          })
          .catch(error => {
            this.loadingAdditionalStatusHistory = false
            if (error.response.data.status == 401) {
              this.$store.dispatch('displayToast', {
                title: this.$t('xError'),
                message: 'Your session has expired, please re-log in.',
                variant: 'danger'
              })
              this.forceSignOut()
            } else {
              this.$store.dispatch('displayToast', {
                title: this.$t('xError'),
                message: 'Failed to get any status history',
                variant: 'danger'
              })
            }
          })
      }
    }
  },
  async mounted() {
    var apparatusInfo = await this.$store.dispatch('getApparatusById', this.apparatusId)
    if (apparatusInfo?.data?.devices != null) {
      this.deviceInfo = apparatusInfo.data.devices[0]
    }

    this.$store
      .dispatch('getApparatusStatusHistory', {
        apparatusId: this.apparatusId,
        pageNumber: this.pageNumber,
        pageSize: this.pageSize
      })
      .then(response => {
        this.statusHistory = response.data.statusHistory
      })
      .catch(error => {
        if (error.response.data.status == 401) {
          this.$store.dispatch('displayToast', {
            title: this.$t('xError'),
            message: 'Your session has expired, please re-log in.',
            variant: 'danger'
          })
          this.forceSignOut()
        } else {
          this.$store.dispatch('displayToast', {
            title: this.$t('xError'),
            message: 'Failed to get any status history',
            variant: 'danger'
          })
        }
      })
  }
}
</script>
