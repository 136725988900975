<template>
  <div v-if="exerciseSchedule" class="mt-2 ml-md-2">
    <div v-if="delayDisabled">
      <div class="row mb-2 no-gutters">
        <div class="col">
          <h1 class="mb-0 pb-0">
            <i class="far fa-calendar-check colorOrange mr-1"></i> {{ $t('xExerciseSchedule') }}
          </h1>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <h2 class="m-0">{{ $t('xScheduledExerciseTime') }}</h2>
            </div>
          </div>
          <div class="row">
            <div class="cols-auto mt-3">
              <i class="fas fa-hourglass-start fa-2x text-gray-600" />
            </div>
            <div class="col mt-3">
              <div class="row">
                <div class="col">
                  <i class="fas fa-sync-alt fa-lg colorOrange" /> {{ $t('xExerciseUpdateInProgress') }}
                </div>
              </div>
              <div class="row mt-2">
                <div class="col">
                  <i>{{ $t('x15MinuteWarning') }}</i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="row no-gutters mb-2">
        <div class="col">
          <h1 class="mb-0 pb-0">
            <i class="far fa-calendar-check colorOrange mr-1"></i> {{ $t('xExerciseSchedule') }}
          </h1>
        </div>
      </div>
      <div class="card" v-if="exerciseSchedule.scheduleIsSet && canEditExerciseSchedule()">
        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <h5 class="m-0">{{ $t('xScheduledExerciseTime') }}</h5>
            </div>
            <div class="col-12">
              <p class="asterisk-text mt-2">{{ getExerciseInterval(exerciseSchedule.interval).text }}</p>
            </div>
            <div class="col-12">
              <div class="row no-gutters">
                <div class="cols-auto">
                  <i class="far fa-calendar-check"></i>
                </div>
                <div class="col ml-2">
                  {{ getExerciseDay().text }} {{ $t('xAt') }} {{ getExerciseTime(exerciseSchedule.timeUtc) }}
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-3" v-if="currentApparatusDetails">
            <div class="row">
              <div id="scheduleDiv">
                <button
                  class="btn btn-outline-warning ml-4 mr-2"
                  id="updateScheduleButton"
                  @click="openExerciseScheduleModal()"
                  :disabled="
                    !canEditExerciseSchedule() ||
                      delayDisabled ||
                      !subscriptionPaid(currentApparatusDetails.subscription) ||
                      !subscriptionActive(currentApparatusDetails.subscription)
                  "
                  data-test-hook="update-button-exercise-schedule"
                >
                  <i class="fas fa-calendar-alt"></i>
                  {{ $t('xUpdate') }}
                </button>
                <b-popover v-if="!canEditExerciseSchedule()" target="scheduleDiv" triggers="hover" placement="top">
                  {{ $t('xBecauseYourGeneratorIsCurrentlyInA') }} {{ currentApparatusDetails.statusLabel }}
                  {{ $t('xStateYourUpdateExerciseScheduleOptionIsUnavailable') }}
                </b-popover>
                <b-popover
                  v-if="
                    !subscriptionPaid(currentApparatusDetails.subscription) ||
                      !subscriptionActive(currentApparatusDetails.subscription)
                  "
                  target="scheduleDiv"
                  triggers="hover"
                  placement="top"
                >
                  {{ $t('xThisIsAPremiumFeature') }}
                </b-popover>
              </div>
              <div id="exerciseNowDiv">
                <button
                  type="button"
                  class="btn btn-outline-warning"
                  v-show="canSendActionCommands()"
                  id="exerciseNowButton"
                  @click="openExerciseNowModal()"
                  :disabled="
                    !canExerciseNow() ||
                      delayDisabled ||
                      !subscriptionPaid(currentApparatusDetails.subscription) ||
                      !subscriptionActive(currentApparatusDetails.subscription)
                  "
                  data-test-hook="exercise-now-button-exercise-schedule"
                >
                  <i class="fas fa-bolt"></i>
                  {{ $t('xExerciseNow') }}
                </button>
                <b-popover v-if="!canExerciseNow()" target="exerciseNowDiv" triggers="hover" placement="top">
                  {{ $t('xBecauseYourGeneratorIsCurrentlyInA') }} {{ currentApparatusDetails.statusLabel }}
                  {{ $t('xStateYourExerciseNowOptionIsUnavailable') }}
                </b-popover>
                <b-popover
                  v-if="
                    !subscriptionPaid(currentApparatusDetails.subscription) ||
                      !subscriptionActive(currentApparatusDetails.subscription)
                  "
                  target="exerciseNowDiv"
                  triggers="hover"
                  placement="top"
                >
                  {{ $t('xThisIsAPremiumFeature') }}
                </b-popover>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card" v-else-if="!exerciseSchedule.scheduleIsSet && canEditExerciseSchedule()">
        <div class="card-body">
          <div class="row">
            <div class="col-12 mb-3">
              <h3 class="m-0">{{ $t('xScheduledExerciseTime') }}</h3>
            </div>
            <div class="col-12">
              <i class="reason-message">
                {{ $t('xNoExerciseScheduleDataAvailable') }}
              </i>
            </div>
          </div>
          <div class="row mt-3" v-if="currentApparatusDetails">
            <div class="col-4">
              <div id="setUpScheduleDiv">
                <button
                  type="button"
                  class="btn btn-warning"
                  id="setUpScheduleButton"
                  @click="openExerciseScheduleModal()"
                  :disabled="
                    !canEditExerciseSchedule() ||
                      delayDisabled ||
                      !subscriptionPaid(currentApparatusDetails.subscription) ||
                      !subscriptionActive(currentApparatusDetails.subscription)
                  "
                  data-test-hook="set-up-schedule-button-exercise-schedule"
                >
                  <i class="fas fa-calendar-alt"></i>
                  {{ $t('xSetUpExerciseSchedule') }}
                </button>
                <b-popover
                  v-if="
                    !subscriptionPaid(currentApparatusDetails.subscription) ||
                      !subscriptionActive(currentApparatusDetails.subscription)
                  "
                  target="setUpScheduleDiv"
                  triggers="hover"
                  placement="top"
                >
                  {{ $t('xThisIsAPremiumFeature') }}
                </b-popover>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card" v-else data-test-hook="no-exercise-schedule-reason-card">
        <div class="card-body">
          <NoExerciseScheduleReason :schedule="exerciseSchedule" />
        </div>
      </div>

      <Modal v-if="modalInfo" modalId="exerciseModal">
        <ModalHeader :title="$t('xUpdateSchedule')" icon="fas fa-calendar-alt colorOrange mr-1"> </ModalHeader>
        <ModalBody>
          <div class="row">
            <div class="col">
              <fieldset class="form-group text-left">
                <div class="mb-2">
                  {{ 'xFrequency' | formatLabel }}
                </div>
                <div v-if="nexusPanel()">
                  <button
                    type="button"
                    class="btn btn-block btn-warning"
                    data-test-hook="weekly-nexus-button-exerciseModal-exercise-schedule"
                  >
                    {{ $t('xWeekly') }}
                  </button>
                </div>
                <div v-else role="group" class="btn-group">
                  <button
                    type="button"
                    class="btn"
                    :class="getIntervalButtonVariant(weeklyInterval)"
                    @click="setExerciseInterval(weeklyInterval)"
                    data-test-hook="weekly-button-exerciseModal-exercise-schedule"
                  >
                    {{ $t('xWeekly') }}
                  </button>
                  <button
                    type="button"
                    class="btn"
                    :class="getIntervalButtonVariant(biWeeklyInterval)"
                    @click="setExerciseInterval(biWeeklyInterval)"
                    data-test-hook="bi-weekly-button-exerciseModal-exercise-schedule"
                  >
                    {{ $t('xBiWeekly') }}
                  </button>
                  <button
                    type="button"
                    class="btn"
                    :class="getIntervalButtonVariant(monthlyInterval)"
                    @click="setExerciseInterval(monthlyInterval)"
                    data-test-hook="monthly-button-exerciseModal-exercise-schedule"
                  >
                    {{ $t('xMonthly') }}
                  </button>
                </div>
              </fieldset>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <fieldset v-if="modalInfo.interval == monthlyInterval" class="form-group text-left">
                <div>
                  {{ $t('xDayOfMonth') | formatLabel }}
                </div>
                <div class="row" v-if="!editDayOfMonth">
                  <div class="col">
                    <div class="day-of-month">{{ this.modalInfo.day }}</div>
                    <button
                      type="button"
                      class="btn btn-icon-button"
                      @click="setEditDayOfMonth()"
                      data-test-hook="pen-button-exerciseModal-exercise-schedule"
                    >
                      <i class="fas fa-pencil"></i>
                    </button>
                  </div>
                </div>
                <div class="row no-gutters" v-else>
                  <div class="row no-gutters mt-2">
                    <div class="col">
                      <button
                        type="button"
                        class="btn month-button mr-2"
                        :class="getDayVariant(1)"
                        @click="setExerciseDay(1)"
                        data-test-hook="1-button-exerciseModal-exercise-schedule"
                      >
                        1
                      </button>
                    </div>
                    <div class="col">
                      <button
                        type="button"
                        class="btn month-button mr-2"
                        :class="getDayVariant(2)"
                        @click="setExerciseDay(2)"
                        data-test-hook="2-button-exerciseModal-exercise-schedule"
                      >
                        2
                      </button>
                    </div>
                    <div class="col">
                      <button
                        type="button"
                        class="btn month-button mr-2"
                        :class="getDayVariant(3)"
                        @click="setExerciseDay(3)"
                        data-test-hook="3-button-exerciseModal-exercise-schedule"
                      >
                        3
                      </button>
                    </div>
                    <div class="col">
                      <button
                        type="button"
                        class="btn month-button mr-2"
                        :class="getDayVariant(4)"
                        @click="setExerciseDay(4)"
                        data-test-hook="4-button-exerciseModal-exercise-schedule"
                      >
                        4
                      </button>
                    </div>
                    <div class="col">
                      <button
                        type="button"
                        class="btn month-button mr-2"
                        :class="getDayVariant(5)"
                        @click="setExerciseDay(5)"
                        data-test-hook="5-button-exerciseModal-exercise-schedule"
                      >
                        5
                      </button>
                    </div>
                    <div class="col">
                      <button
                        type="button"
                        class="btn month-button mr-2"
                        :class="getDayVariant(6)"
                        @click="setExerciseDay(6)"
                        data-test-hook="6-button-exerciseModal-exercise-schedule"
                      >
                        6
                      </button>
                    </div>
                    <div class="col">
                      <button
                        type="button"
                        class="btn month-button mr-2"
                        :class="getDayVariant(7)"
                        @click="setExerciseDay(7)"
                        data-test-hook="7-button-exerciseModal-exercise-schedule"
                      >
                        7
                      </button>
                    </div>
                  </div>
                  <div class="row no-gutters mt-2">
                    <div class="col">
                      <button
                        type="button"
                        class="btn month-button mr-2"
                        :class="getDayVariant(8)"
                        @click="setExerciseDay(8)"
                        data-test-hook="8-button-exerciseModal-exercise-schedule"
                      >
                        8
                      </button>
                    </div>
                    <div class="col">
                      <button
                        type="button"
                        class="btn month-button mr-2"
                        :class="getDayVariant(9)"
                        @click="setExerciseDay(9)"
                        data-test-hook="9-button-exerciseModal-exercise-schedule"
                      >
                        9
                      </button>
                    </div>
                    <div class="col">
                      <button
                        type="button"
                        class="btn month-button mr-2"
                        :class="getDayVariant(10)"
                        @click="setExerciseDay(10)"
                        data-test-hook="10-button-exerciseModal-exercise-schedule"
                      >
                        10
                      </button>
                    </div>
                    <div class="col">
                      <button
                        type="button"
                        class="btn month-button mr-2"
                        :class="getDayVariant(11)"
                        @click="setExerciseDay(11)"
                        data-test-hook="11-button-exerciseModal-exercise-schedule"
                      >
                        11
                      </button>
                    </div>
                    <div class="col">
                      <button
                        type="button"
                        class="btn month-button mr-2"
                        :class="getDayVariant(12)"
                        @click="setExerciseDay(12)"
                        data-test-hook="12-button-exerciseModal-exercise-schedule"
                      >
                        12
                      </button>
                    </div>
                    <div class="col">
                      <button
                        type="button"
                        class="btn month-button mr-2"
                        :class="getDayVariant(13)"
                        @click="setExerciseDay(13)"
                        data-test-hook="13-button-exerciseModal-exercise-schedule"
                      >
                        13
                      </button>
                    </div>
                    <div class="col">
                      <button
                        type="button"
                        class="btn month-button mr-2"
                        :class="getDayVariant(14)"
                        @click="setExerciseDay(14)"
                        data-test-hook="14-button-exerciseModal-exercise-schedule"
                      >
                        14
                      </button>
                    </div>
                  </div>
                  <div class="row no-gutters mt-2">
                    <div class="col">
                      <button
                        type="button"
                        class="btn month-button mr-2"
                        :class="getDayVariant(15)"
                        @click="setExerciseDay(15)"
                        data-test-hook="15-button-exerciseModal-exercise-schedule"
                      >
                        15
                      </button>
                    </div>
                    <div class="col">
                      <button
                        type="button"
                        class="btn month-button mr-2"
                        :class="getDayVariant(16)"
                        @click="setExerciseDay(16)"
                        data-test-hook="16-button-exerciseModal-exercise-schedule"
                      >
                        16
                      </button>
                    </div>
                    <div class="col">
                      <button
                        type="button"
                        class="btn month-button mr-2"
                        :class="getDayVariant(17)"
                        @click="setExerciseDay(17)"
                        data-test-hook="17-button-exerciseModal-exercise-schedule"
                      >
                        17
                      </button>
                    </div>
                    <div class="col">
                      <button
                        type="button"
                        class="btn month-button mr-2"
                        :class="getDayVariant(18)"
                        @click="setExerciseDay(18)"
                        data-test-hook="18-button-exerciseModal-exercise-schedule"
                      >
                        18
                      </button>
                    </div>
                    <div class="col">
                      <button
                        type="button"
                        class="btn month-button mr-2"
                        :class="getDayVariant(19)"
                        @click="setExerciseDay(19)"
                        data-test-hook="19-button-exerciseModal-exercise-schedule"
                      >
                        19
                      </button>
                    </div>
                    <div class="col">
                      <button
                        type="button"
                        class="btn month-button mr-2"
                        :class="getDayVariant(20)"
                        @click="setExerciseDay(20)"
                        data-test-hook="20-button-exerciseModal-exercise-schedule"
                      >
                        20
                      </button>
                    </div>
                    <div class="col">
                      <button
                        class="btn month-button mr-2"
                        :class="getDayVariant(21)"
                        @click="setExerciseDay(21)"
                        data-test-hook="21-button-exerciseModal-exercise-schedule"
                      >
                        21
                      </button>
                    </div>
                  </div>
                  <div class="row no-gutters mt-2">
                    <div class="col">
                      <button
                        class="btn month-button mr-2"
                        :class="getDayVariant(22)"
                        @click="setExerciseDay(22)"
                        data-test-hook="22-button-exerciseModal-exercise-schedule"
                      >
                        22
                      </button>
                    </div>
                    <div class="col">
                      <button
                        class="btn month-button mr-2"
                        :class="getDayVariant(23)"
                        @click="setExerciseDay(23)"
                        data-test-hook="23-button-exerciseModal-exercise-schedule"
                      >
                        23
                      </button>
                    </div>
                    <div class="col">
                      <button
                        class="btn month-button mr-2"
                        :class="getDayVariant(24)"
                        @click="setExerciseDay(24)"
                        data-test-hook="24-button-exerciseModal-exercise-schedule"
                      >
                        24
                      </button>
                    </div>
                    <div class="col">
                      <button
                        class="btn month-button mr-2"
                        :class="getDayVariant(25)"
                        @click="setExerciseDay(25)"
                        data-test-hook="25-button-exerciseModal-exercise-schedule"
                      >
                        25
                      </button>
                    </div>
                    <div class="col">
                      <button
                        class="btn month-button mr-2"
                        :class="getDayVariant(26)"
                        @click="setExerciseDay(26)"
                        data-test-hook="26-button-exerciseModal-exercise-schedule"
                      >
                        26
                      </button>
                    </div>
                    <div class="col">
                      <button
                        class="btn month-button mr-2"
                        :class="getDayVariant(27)"
                        @click="setExerciseDay(27)"
                        data-test-hook="27-button-exerciseModal-exercise-schedule"
                      >
                        27
                      </button>
                    </div>
                    <div class="col">
                      <button
                        class="btn month-button mr-2"
                        :class="getDayVariant(28)"
                        @click="setExerciseDay(28)"
                        data-test-hook="28-button-exerciseModal-exercise-schedule"
                      >
                        28
                      </button>
                    </div>
                  </div>
                </div>
              </fieldset>
              <fieldset v-else class="form-group text-left">
                <a>{{ 'xDayOfWeek' | formatLabel }}</a>
                <div class="row mt-2">
                  <div class="col">
                    <div class="day-buttons">
                      <button
                        type="button"
                        class="btn month-button mr-2"
                        :class="getDayVariant(0)"
                        @click="setExerciseDay(0)"
                        data-test-hook="sunday-button-exerciseModal-exercise-schedule"
                      >
                        {{ $t('xSundayAbbr') }}
                      </button>
                      <button
                        type="button"
                        class="btn month-button mr-2"
                        :class="getDayVariant(1)"
                        @click="setExerciseDay(1)"
                        data-test-hook="monday-button-exerciseModal-exercise-schedule"
                      >
                        {{ $t('xMondayAbbr') }}
                      </button>
                      <button
                        type="button"
                        class="btn month-button mr-2"
                        :class="getDayVariant(2)"
                        @click="setExerciseDay(2)"
                        data-test-hook="tuesday-button-exerciseModal-exercise-schedule"
                      >
                        {{ $t('xTuesdayAbbr') }}
                      </button>
                      <button
                        type="button"
                        class="btn month-button mr-2"
                        :class="getDayVariant(3)"
                        @click="setExerciseDay(3)"
                        data-test-hook="wednesday-button-exerciseModal-exercise-schedule"
                      >
                        {{ $t('xWednesdayAbbr') }}
                      </button>
                      <button
                        type="button"
                        class="btn month-button mr-2"
                        :class="getDayVariant(4)"
                        @click="setExerciseDay(4)"
                        data-test-hook="thursday-button-exerciseModal-exercise-schedule"
                      >
                        {{ $t('xThursdayAbbr') }}
                      </button>
                      <button
                        type="button"
                        class="btn month-button mr-2"
                        :class="getDayVariant(5)"
                        @click="setExerciseDay(5)"
                        data-test-hook="friday-button-exerciseModal-exercise-schedule"
                      >
                        {{ $t('xFridayAbbr') }}
                      </button>
                      <button
                        type="button"
                        class="btn month-button mr-2"
                        :class="getDayVariant(6)"
                        @click="setExerciseDay(6)"
                        data-test-hook="saturday-button-exerciseModal-exercise-schedule"
                      >
                        {{ $t('xSaturdayAbbr') }}
                      </button>
                    </div>
                  </div>
                </div>
              </fieldset>
              <div>
                <label for="appt">{{ 'xTimeOfDay' | formatLabel }}</label>
                <div>
                  <input
                    type="time"
                    class="form-control"
                    id="appt"
                    v-model="modalInfo.timeUtc"
                    data-test-hook="time-entry-exerciseModal-exercise-schedule"
                  />
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            class="btn btn-outline-warning"
            @click="closeModal('exerciseModal')"
            data-test-hook="cancel-button-exerciseModal-exercise-schedule"
          >
            {{ $t('xCancel') }}
          </button>
          <button
            type="button"
            class="btn btn-warning"
            @click="saveExerciseSchedule()"
            data-test-hook="save-button-exerciseModal-exercise-schedule"
          >
            {{ $t('xSave') }}
          </button>
        </ModalFooter>
      </Modal>
    </div>

    <Modal modalId="exercise-now-modal" ref="exercise-now-modal" size="modal-lg">
      <ModalHeader :title="$t('xAreYouSure')" icon="fas fa-question-circle  colorOrange mr-1">
        <div>
          <i
            class="fas fa-times hoverPointer"
            @click="closeModal('exercise-now-modal')"
            data-test-hook="x-button-exerciseNowModal-exercise-schedule"
          ></i>
        </div>
      </ModalHeader>
      <ModalBody>
        <span>
          {{ $t('xThisActionWillScheduleAnExerciseCycle') }}
        </span>
      </ModalBody>
      <ModalFooter>
        <button
          type="button"
          class="btn btn-outline-warning"
          @click="closeModal('exercise-now-modal')"
          data-test-hook="no-cancel-button-exerciseNowModal-exercise-schedule"
        >
          <i class="fas fa-times"></i>
          {{ $t('xNoCancel') }}
        </button>
        <button
          type="button"
          class="btn btn-warning mr-2"
          @click="exerciseGen()"
          data-test-hook="yes-im-sure-button-exerciseNowModal-exercise-schedule"
        >
          <i class="fas fa-check"></i>
          {{ $t('xYesImSure') }}
        </button>
      </ModalFooter>
    </Modal>
  </div>
  <div v-else class="d-flex justify-content-center mt-5">
    <div class="spinner-border ml-2 text-warning" style="width: 10rem; height: 10rem;" label="Spinning"></div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import * as Constants from '@/constants'
import NoExerciseScheduleReason from '@/components/ApparatusDetails/NoExerciseScheduleReason'

export default {
  components: {
    NoExerciseScheduleReason
  },
  data() {
    return {
      exerciseSchedule: null,
      modalInfo: {},
      weeklyInterval: Constants.WeeklyInterval,
      biWeeklyInterval: Constants.BiWeeklyInterval,
      monthlyInterval: Constants.MonthlyInterval,
      editDayOfMonth: false,
      editTime: false,
      updateExerciseSchedulePending: false,
      exerciseNowPending: false,
      delay: null,
      delayDisabled: false
    }
  },
  computed: {
    ...mapState({
      currentApparatusExerciseSchedule: state => state.apparatus.currentApparatusExerciseSchedule,
      currentApparatusDetails: state => state.apparatus.currentApparatusDetails
    })
  },
  methods: {
    scheduleUpdated() {
      if (
        this.modalInfo &&
        this.exerciseSchedule &&
        (this.exerciseSchedule.interval != this.modalInfo.interval ||
          this.exerciseSchedule.day != this.modalInfo.day ||
          this.exerciseSchedule.timeUtc != this.modalInfo.timeUtc)
      ) {
        return false
      } else return true
    },
    nexusPanel() {
      if (Constants.NexusPanels.includes(this.exerciseSchedule.apparatusPanelId)) {
        this.modalInfo.interval = this.weeklyInterval
        return true
      } else return false
    },
    canEditExerciseSchedule() {
      if (
        this.exerciseSchedule &&
        this.exerciseSchedule.apparatusStatus == 1 &&
        this.exerciseSchedule.isConnected &&
        Constants.ManageMaintenancePanels.includes(this.exerciseSchedule.apparatusPanelId)
      ) {
        return true
      } else return false
    },
    canSendActionCommands() {
      const panelId = this.currentApparatusDetails.panelId
      return Constants.CanSendActionCommands.includes(panelId)
    },
    canExerciseNow() {
      if (
        this.currentApparatusDetails.apparatusStatus == 1 &&
        this.currentApparatusDetails.isConnected &&
        this.exerciseSchedule
      ) {
        return true
      } else return false
    },
    exerciseGen() {
      this.closeModal('exercise-now-modal')
      this.exerciseNowPending = true
      this.delayDisabled = true
      this.$store
        .dispatch('exerciseApparatusNow', this.currentApparatusDetails.apparatusId)
        .then(response => {
          window.localStorage.setItem(
            this.currentApparatusDetails.apparatusId.toString(),
            this.$moment()
              .add(15, 'minutes')
              .valueOf()
          )
          this.delay = window.localStorage.getItem(this.currentApparatusDetails.apparatusId)
          this.exerciseNowPending = false
          let diff = this.delay - this.$moment.utc().valueOf()
          setTimeout(
            function() {
              this.delay = null
              this.delayDisabled = false
            }.bind(this),
            diff
          )
        })
        .catch(error => {
          this.exerciseNowPending = false
          this.delayDisabled = false
          this.$store.dispatch('displayToast', {
            title: this.$t('xError'),
            message: error.response.data.detail,
            variant: 'warning'
          })
        })
    },
    saveExerciseSchedule() {
      this.updateExerciseSchedulePending = true
      this.delayDisabled = true
      this.$store
        .dispatch('saveApparatusExerciseSchedule', this.modalInfo)
        .then(response => {
          window.localStorage.setItem(
            this.exerciseSchedule.apparatusId.toString(),
            this.$moment()
              .add(15, 'minutes')
              .valueOf()
          )
          this.delay = window.localStorage.getItem(this.exerciseSchedule.apparatusId.toString())
          this.updateExerciseSchedulePending = false
          let diff = this.delay - this.$moment.utc().valueOf()
          setTimeout(
            function() {
              this.delay = null
              this.delayDisabled = false
            }.bind(this),
            diff
          )
        })
        .catch(error => {
          this.updateExerciseSchedulePending = false
          this.delayDisabled = false
          this.$store.dispatch('displayToast', {
            title: this.$t('xError'),
            message: error.response.data.detail,
            variant: 'warning'
          })
        })
    },
    setExerciseInterval(interval) {
      this.modalInfo.interval = interval
      if (this.modalInfo.interval == this.monthlyInterval) {
        this.modalInfo.day = 1
      } else this.modalInfo.day = 0
    },
    setExerciseDay(day) {
      this.modalInfo.day = day
      if (this.modalInfo.interval == this.monthlyInterval) {
        this.editDayOfMonth = false
      }
    },
    setEditTime() {
      this.editTime = true
    },
    setEditDayOfMonth() {
      this.editDayOfMonth = true
    },
    getDayVariant(day) {
      if (day == this.modalInfo.day) return 'btn-warning'
      else return 'btn-outline-warning'
    },
    getIntervalButtonVariant(interval) {
      if (this.modalInfo.interval == interval) return 'btn-warning'
      else return 'btn-outline-warning'
    },
    openExerciseScheduleModal() {
      this.modalInfo = { ...this.exerciseSchedule }
      this.openModal('exerciseModal')
    },
    openExerciseNowModal() {
      this.openModal('exercise-now-modal')
    },
    handleHide(event) {
      if (event.trigger == 'ok') {
        this.saveExerciseSchedule()
      }
    },
    getExerciseInterval(intervalValue) {
      return Constants.intervals.find(interval =>
        intervalValue != null ? interval.value === intervalValue : interval.value === 0
      )
    },
    getExerciseDay() {
      if (this.exerciseSchedule.interval == Constants.MonthlyInterval) {
        return Constants.daysOfMonth.find(day =>
          this.exerciseSchedule.day != null ? day.value === this.exerciseSchedule.day : day.value === 0
        )
      } else {
        return Constants.days.find(day =>
          this.exerciseSchedule.day != null ? day.value === this.exerciseSchedule.day : day.value === 0
        )
      }
    },
    getExerciseTime(time) {
      if (time) {
        return this.$moment.utc(time, 'HH:mm').format('hh:mm A')
      }
      this.modalInfo.timeUtc = '00:00:00'
      return '12:00 am'
    },
    updateButtonTitle() {
      if (
        !this.subscriptionPaid(this.currentApparatusDetails.subscription) ||
        !this.subscriptionActive(this.currentApparatusDetails.subscription)
      ) {
        return this.$t('xThisIsAPremiumFeature')
      }
    }
  },
  watch: {
    currentApparatusExerciseSchedule: {
      handler: function(newVal, oldVal) {
        this.exerciseSchedule = _.cloneDeep(newVal)
        this.delay = window.localStorage.getItem(this.exerciseSchedule.apparatusId.toString())
        if (this.delay) {
          this.delayDisabled = true
          let diff = this.delay - this.$moment.utc().valueOf()
          setTimeout(
            function() {
              this.delay = null
              this.delayDisabled = false
            }.bind(this),
            diff
          )
        }
      },
      deep: true
    },
    'modalInfo.exerciseInterval': function(newVal, oldVal) {
      if (!newVal) {
        this.modalInfo.exerciseInterval = 0
      }
    },
    'modalInfo.exerciseDay': function(newVal, oldVal) {
      if (!newVal) {
        this.modalInfo.exerciseDay = 0
      }
    }
  },
  mounted() {
    this.$store.dispatch('getApparatusExerciseSchedule', this.$route.params.apparatusId)
    if (this.exerciseSchedule) {
      this.delay = window.localStorage.getItem(this.exerciseSchedule.apparatusId.toString())
      if (this.delay) {
        this.delayDisabled = true
        let diff = this.delay - this.$moment.utc().valueOf()
        setTimeout(
          function() {
            this.delay = null
            this.delayDisabled = false
          }.bind(this),
          diff
        )
      }
    }
  }
}
</script>

<style scoped>
.day-buttons {
  flex-direction: row;
  display: flex;
}
.month-button {
  width: 48px;
  height: 48px;
  font-size: 1.1rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}

.day-of-month {
  display: inline-flex;
  background: var(--carbon-100);
  border-radius: 4px;
  padding: 0.5rem;
  height: 44px;
  min-width: 44px;
  justify-content: center;
  align-items: center;
  margin-right: 0.5em;
}
.thursday-button {
  border-radius: 50%;
  font-size: 1.25rem;
  width: 55px;
}
.reason-message {
  color: var(--carbon-500);
  font-size: 1rem;
}
</style>
