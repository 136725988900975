import Vue from 'vue'
import Router from 'vue-router'
import store from '../store/'
import Home from '../views/Home.vue'
import SignUp from '../views/SignUp.vue'
import Dashboard from '../views/Dashboard.vue'
import Admin from '../views/Admin.vue'
import MessageCenter from '../views/MessageCenter.vue'
import Support from '../views/Support.vue'
import ApparatusDetails from '../views/ApparatusDetails.vue'
import DeviceDetails from '@/components/ApparatusDetails/DeviceDetails.vue'
import StatusHistory from '@/components/ApparatusDetails/StatusHistory.vue'
import ExerciseSchedule from '@/components/ApparatusDetails/ExerciseSchedule.vue'
import VirtualPowerPlant from '@/components/ApparatusDetails/VirtualPowerPlant.vue'
import Maintenance from '@/components/ApparatusDetails/Maintenance.vue'
import Warranty from '@/components/ApparatusDetails/Warranty.vue'
import TechnicalSpecs from '@/components/ApparatusDetails/TechnicalSpecs.vue'
import PreferredDealer from '@/components/ApparatusDetails/PreferredDealer.vue'
import Subscription from '@/components/ApparatusDetails/Subscription.vue'
import TankDetails from '@/components/ApparatusDetails/TankDetails.vue'
import FuelConsumption from '@/components/ApparatusDetails/FuelConsumption.vue'
import FuelLevelWarning from '@/components/ApparatusDetails/FuelLevelWarning.vue'
import AccountInformation from '@/components/AccountSettings/AccountInformation.vue'
import DocumentsStatements from '@/components/AccountSettings/DocumentsStatements.vue'
import DisplayPreferences from '@/components/AccountSettings/DisplayPreferences.vue'
import SettingsNotifications from '@/components/AccountSettings/SettingsNotifications.vue'
import AccountSettings from '../views/AccountSettings.vue'
import CreateAccount from '../views/CreateAccount.vue'
import GetStarted from '../views/GetStarted.vue'
import QrScan from '../views/QrScan.vue'
import LandingDownload from '../views/LandingDownload.vue'
import { vueAppApiBaseAddress, vueAppBaseAddress } from '@/utils/mixins'

Vue.use(Router)
const CHALLENGE_URL = '../api/Auth/SignIn'
const DESTINATION_QUERY_PARAM = 'destination'
const EMAIL_QUERY_PARAM = 'email'
const SINGLESIGNONEMAIL_QUERY_PARAM = 'singleSignOnEmail'

let router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
      beforeEnter: (to, from, next) => {
        if (document.cookie.indexOf('MobileLinkClientCookie=') != -1) {
          next({ path: '/dashboard' })
        } else if (to.query.singleSignOnEmail) {
          let params = new URLSearchParams()
          params.append(EMAIL_QUERY_PARAM, to.query.singleSignOnEmail)

          let destination = new URL(to.fullPath, vueAppBaseAddress())
          let destinationParams = new URLSearchParams(destination.search)
          destinationParams.delete(SINGLESIGNONEMAIL_QUERY_PARAM)

          let destinationPath = `${destination.pathname}`
          if (destinationParams.toString()) {
            destinationPath = `${destination.pathname}?${destinationParams}`
          }
          params.append(DESTINATION_QUERY_PARAM, destinationPath)

          let authPath = new URL(CHALLENGE_URL, vueAppApiBaseAddress())
          window.location.assign(`${authPath}?${params}`)
        } else {
          next()
        }
      },
      meta: {
        protected: false
      },
      alias: ['/correlationError', '/expiredLink', '/loginError', '/inviteError']
    },
    {
      path: '/dashboard/:apparatusId/:actionType',
      name: 'incompleteApparatus',
      component: Dashboard,
      meta: {
        protected: true
      }
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: Dashboard,
      meta: {
        protected: true
      }
    },
    {
      path: '/dashboard/:apparatusType/:apparatusId',
      name: 'beginEnrollmentFlow',
      component: Dashboard,
      meta: {
        protected: true
      }
    },
    {
      path: '/details/:apparatusId',
      component: ApparatusDetails,
      children: [
        {
          path: '',
          name: 'deviceDetails',
          component: DeviceDetails
        },
        {
          path: 'statusHistory',
          name: 'statusHistory',
          component: StatusHistory,
          meta: {
            label: 'Status History'
          }
        },
        {
          path: 'exerciseSchedule',
          name: 'exerciseSchedule',
          component: ExerciseSchedule,
          meta: {
            label: 'Exercise Schedule'
          }
        },
        {
          path: 'maintenance',
          name: 'maintenance',
          component: Maintenance,
          meta: {
            label: 'Maintenance'
          }
        },
        {
          path: 'warranty',
          name: 'warranty',
          component: Warranty,
          meta: {
            label: 'Warranty'
          }
        },
        {
          path: 'technicalSpecs',
          name: 'technicalSpecs',
          component: TechnicalSpecs,
          meta: {
            label: 'Technical Specs'
          }
        },
        {
          path: 'preferredDealer',
          name: 'preferredDealer',
          component: PreferredDealer,
          meta: {
            label: 'Preferred Dealer'
          }
        },
        {
          path: 'subscription',
          name: 'subscription',
          component: Subscription,
          meta: {
            label: 'Subscription'
          }
        },
        {
          path: 'virtualPowerPlant',
          name: 'virtualPowerPlant',
          component: VirtualPowerPlant,
          meta: {
            label: 'VirtualPowerPlant'
          }
        },
        {
          path: 'tankDetails',
          name: 'tankDetails',
          component: TankDetails,
          meta: {
            label: 'Tank Details'
          }
        },
        {
          path: 'fuelConsumption',
          name: 'fuelConsumption',
          component: FuelConsumption,
          meta: {
            label: 'Fuel Consumption'
          }
        },
        {
          path: 'fuelLevelWarning',
          name: 'fuelLevelWarning',
          component: FuelLevelWarning,
          meta: {
            label: 'Fuel Level Warning'
          }
        },
        {
          path: 'fuelProvider',
          name: 'fuelProvider',
          component: PreferredDealer,
          meta: {
            label: 'Fuel Provider'
          }
        },
        {
          path: 'preferredServiceDealer',
          name: 'preferredServiceDealer',
          component: PreferredDealer,
          meta: {
            label: 'Preferred Service Dealer'
          }
        }
      ],
      meta: {
        protected: true
      }
    },
    {
      path: '/messageCenter',
      name: 'messageCenter',
      component: MessageCenter,
      meta: {
        protected: true
      }
    },
    {
      path: '/support',
      name: 'support',
      component: Support,
      meta: {
        protected: true
      }
    },
    {
      path: '/accountSettings',
      name: 'accountSettings',
      component: AccountSettings,
      children: [
        {
          path: 'accountInformation',
          name: 'accountInformation',
          component: AccountInformation,
          meta: {
            label: 'Account Information'
          }
        },
        {
          path: 'documentsStatements',
          name: 'documentsStatements',
          component: DocumentsStatements,
          meta: {
            label: 'Documents and Statements'
          }
        },
        {
          path: 'settingsNotifications',
          name: 'settingsNotifications',
          component: SettingsNotifications,
          meta: {
            label: 'Notifications'
          }
        },
        {
          path: 'displayPreferences',
          name: 'displayPreferences',
          component: DisplayPreferences,
          meta: {
            label: 'Display Preferences'
          }
        }
      ],
      meta: {
        protected: true
      }
    },
    {
      path: '/createAccount',
      name: 'createAccount',
      component: CreateAccount,
      meta: {
        protected: true
      }
    },
    {
      path: '/getstarted',
      name: 'getstarted',
      component: GetStarted,
      meta: {
        protected: false
      }
    },
    {
      path: '/qrscan/:serialNumber/:modelNumber/:btId',
      name: 'qrScan',
      component: QrScan,
      meta: {
        protected: false
      }
    },
    {
      path: '/admin',
      name: 'admin',
      component: Admin,
      meta: {
        protected: true
      }
    },
    {
      path: '/download',
      name: 'landingDownload',
      component: LandingDownload,
      meta: {
        protected: false
      }
    },
    {
      path: '/signup',
      name: 'signup',
      component: SignUp,
      meta: {
        protected: false
      }
    },
    // Deep Links - starting
    {
      path: '/link/virtualPowerPlant/:apparatusId',
      redirect: to => {
        return { name: 'virtualPowerPlant', params: { apparatusId: to.params.apparatusId } }
      }
    },
    {
      path: '/link/enroll/:apparatusType',
      redirect: to => {
        return {
          name: 'beginEnrollmentFlow',
          params: { apparatusType: to.params.apparatusType, apparatusId: 0 }
        }
      }
    },
    {
      path: '/link/enroll/:apparatusType/:apparatusId',
      redirect: to => {
        return {
          name: 'beginEnrollmentFlow',
          params: { apparatusType: to.params.apparatusType, apparatusId: to.params.apparatusId }
        }
      }
    },
    {
      path: '/link/enrollment/complete/:apparatusId',
      redirect: to => {
        return { name: 'deviceDetails', params: { apparatusId: to.params.apparatusId } }
      }
    },
    {
      path: '/link/enrollment/cancel',
      redirect: '/'
    },
    {
      path: '/link/qrscan',
      redirect: to => {
        return {
          name: 'qrScan',
          params: { serialNumber: to.query.sn, modelNumber: to.query.mn, btId: to.query.btid }
        }
      }
    },
    {
      path: '/link/ecobee',
      redirect: to => {
        return {
          name: 'getstarted'
        }
      }
    },
    // Deep Links - ending
    { path: '*', redirect: '/' }
  ]
})

router.beforeEach((to, from, next) => {
  store.commit('setCurrentRoute', to)
  if (to.matched.some(record => record.meta.protected)) {
    if (to.name == 'admin') {
      store
        .dispatch('authorize', to.name)
        .then(response => {
          next()
        })
        .catch(error => {
          window.location.assign(vueAppApiBaseAddress() + 'Auth/Internal')
        })
    } else if (document.cookie.indexOf('MobileLinkClientCookie=') != -1) {
      next()
    } else if (!store.state.account.user && !store.state.account.newUserEmail) {
      sessionStorage.setItem('toRoute', JSON.stringify(to))

      let params = new URLSearchParams()
      if (to.query.singleSignOnEmail) {
        params.append(EMAIL_QUERY_PARAM, to.query.singleSignOnEmail)
      }

      let destination = new URL(to.fullPath, vueAppBaseAddress())
      let destinationParams = new URLSearchParams(destination.search)
      destinationParams.delete(SINGLESIGNONEMAIL_QUERY_PARAM)

      let destinationPath = `${destination.pathname}`
      if (destinationParams.toString()) {
        destinationPath = `${destination.pathname}?${destinationParams}`
      }
      params.append(DESTINATION_QUERY_PARAM, destinationPath)

      let authPath = new URL(CHALLENGE_URL, vueAppApiBaseAddress())
      window.location.assign(`${authPath}?${params}`)
      next()
    } else {
      store
        .dispatch('authorize', to.name)
        .then(response => {
          next()
        })
        .catch(error => {
          sessionStorage.setItem('toRoute', JSON.stringify(to))
          next('/')
        })
    }
  } else {
    next()
  }
})

export default router
