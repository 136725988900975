var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "createAccountDiv" } },
    [
      _c(
        "b-row",
        {
          staticClass: "h-100",
          attrs: { "align-h": "around", "align-v": "center", "no-gutters": "" }
        },
        [
          _c(
            "b-col",
            { attrs: { cols: "11", md: "6", lg: "4" } },
            [
              _c(
                "b-card",
                {
                  staticClass: "mt-4",
                  attrs: {
                    id: "createAccountCard",
                    "header-bg-variant": "white",
                    "footer-bg-variant": "white",
                    "footer-border-variant": "white"
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "header",
                      fn: function() {
                        return [
                          _c(
                            "div",
                            { staticClass: "d-flex align-items-center" },
                            [
                              _c(
                                "b-button",
                                {
                                  staticClass: "btn-icon-button--gray",
                                  class: _vm.step == 1 ? "d-none" : "mr-2",
                                  attrs: {
                                    variant: "icon-button",
                                    "data-test-hook":
                                      "back-button-create-account"
                                  },
                                  on: { click: _vm.back }
                                },
                                [
                                  _c("i", {
                                    staticClass: "far fa-chevron-left"
                                  })
                                ]
                              ),
                              _c("h2", [
                                _c("i", {
                                  staticClass: "far fa-user colorOrange mr-2"
                                }),
                                _vm._v(_vm._s(_vm.$t("xAccountSetup")))
                              ])
                            ],
                            1
                          )
                        ]
                      },
                      proxy: true
                    },
                    {
                      key: "footer",
                      fn: function() {
                        return [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex mb-2 align-items-center justify-content-center"
                            },
                            [
                              _c("b-avatar", {
                                attrs: {
                                  text: " ",
                                  size: "sm",
                                  variant:
                                    _vm.step == 1 ? "warning" : "secondary"
                                }
                              }),
                              _c("b-avatar", {
                                staticClass: "ml-1",
                                attrs: {
                                  text: " ",
                                  size: "sm",
                                  variant:
                                    _vm.step == 2 ? "warning" : "secondary"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            { staticClass: "box" },
                            [
                              _c(
                                "b-button",
                                {
                                  attrs: {
                                    variant: "secondary",
                                    "data-test-hook":
                                      "logout-button-create-account"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.signOutAccountCreation()
                                    }
                                  }
                                },
                                [_vm._v(" " + _vm._s(_vm.$t("xLogout")) + " ")]
                              ),
                              _c(
                                "b-button",
                                {
                                  staticStyle: { "justify-content": "center" },
                                  attrs: {
                                    variant: "warning",
                                    disabled:
                                      _vm.saving ||
                                      (_vm.step == 2 &&
                                        (!_vm.address.line1 ||
                                          !_vm.address.postalCode)) ||
                                      _vm.addressSaveDisabled,
                                    "data-test-hook":
                                      "next-button-create-account"
                                  },
                                  on: { click: _vm.next }
                                },
                                [
                                  _vm._v(" " + _vm._s(_vm.$t("xNext")) + " "),
                                  _c("span", [
                                    _c("i", {
                                      staticClass: "far fa-arrow-right"
                                    })
                                  ])
                                ]
                              )
                            ],
                            1
                          )
                        ]
                      },
                      proxy: true
                    }
                  ])
                },
                [
                  _vm.step == 1
                    ? _c(
                        "div",
                        [
                          _c(
                            "b-row",
                            [
                              _c("b-col", [
                                _c("h4", [
                                  _vm._v(_vm._s(_vm.$t("xOwnerInformation")))
                                ])
                              ])
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        id: "email-group",
                                        label: _vm.$t("xEmailAddress"),
                                        "label-for": "email"
                                      }
                                    },
                                    [
                                      _c("b-form-input", {
                                        attrs: {
                                          id: "email",
                                          maxlength: "70",
                                          type: "text",
                                          placeholder: _vm.$t("xEmailAddress"),
                                          disabled: true
                                        },
                                        model: {
                                          value: _vm.newUserEmail,
                                          callback: function($$v) {
                                            _vm.newUserEmail = $$v
                                          },
                                          expression: "newUserEmail"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        id: "firstName-group",
                                        label: _vm.$t("xFirstName"),
                                        "label-for": "firstName"
                                      }
                                    },
                                    [
                                      _c("b-form-input", {
                                        attrs: {
                                          id: "firstName",
                                          maxlength: "30",
                                          type: "text",
                                          placeholder: _vm.$t("xFirstName"),
                                          state: _vm.validState(
                                            _vm.$v.firstName
                                          ),
                                          "aria-describedby":
                                            "firstName-invalid"
                                        },
                                        model: {
                                          value: _vm.$v.firstName.$model,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.$v.firstName,
                                              "$model",
                                              $$v
                                            )
                                          },
                                          expression: "$v.firstName.$model"
                                        }
                                      }),
                                      _c(
                                        "b-form-invalid-feedback",
                                        { attrs: { id: "firstName-invalid" } },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t("xRequiredField")) +
                                              ". "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        id: "lastName-group",
                                        label: _vm.$t("xLastName"),
                                        "label-for": "lastName"
                                      }
                                    },
                                    [
                                      _c("b-form-input", {
                                        attrs: {
                                          id: "lastName",
                                          maxlength: "30",
                                          type: "text",
                                          placeholder: _vm.$t("xLastName"),
                                          state: _vm.validState(
                                            _vm.$v.lastName
                                          ),
                                          "aria-describedby": "lastName-invalid"
                                        },
                                        model: {
                                          value: _vm.$v.lastName.$model,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.$v.lastName,
                                              "$model",
                                              $$v
                                            )
                                          },
                                          expression: "$v.lastName.$model"
                                        }
                                      }),
                                      _c(
                                        "b-form-invalid-feedback",
                                        { attrs: { id: "lastName-invalid" } },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t("xRequiredField")) +
                                              ". "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        id: "phone-group",
                                        label: _vm.$t("xPhoneNumber"),
                                        "label-for": "phone"
                                      }
                                    },
                                    [
                                      _c("b-form-input", {
                                        attrs: {
                                          id: "phone",
                                          maxlength: "12",
                                          type: "text",
                                          placeholder: _vm.$t("xPhoneNumber"),
                                          state: _vm.validState(_vm.$v.phone),
                                          "aria-describedby": "phone-invalid"
                                        },
                                        model: {
                                          value: _vm.$v.phone.$model,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.$v.phone,
                                              "$model",
                                              $$v
                                            )
                                          },
                                          expression: "$v.phone.$model"
                                        }
                                      }),
                                      _c(
                                        "b-form-invalid-feedback",
                                        { attrs: { id: "phone-invalid" } },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t("xRequiredField")) +
                                              ". " +
                                              _vm._s(
                                                _vm.$t(
                                                  "xPleaseEnterAValidPhoneNumber"
                                                )
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        id: "businessName-group",
                                        label: _vm.$t("xBusinessName"),
                                        "label-for": "businessName"
                                      }
                                    },
                                    [
                                      _c("b-form-input", {
                                        attrs: {
                                          id: "businessName",
                                          maxlength: "125",
                                          type: "text",
                                          placeholder: _vm.$t("xBusinessName")
                                        },
                                        model: {
                                          value: _vm.businessName,
                                          callback: function($$v) {
                                            _vm.businessName = $$v
                                          },
                                          expression: "businessName"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _c(
                        "div",
                        [
                          _c(
                            "b-row",
                            [
                              _c("b-col", [_c("h4", [_vm._v("Owner Address")])])
                            ],
                            1
                          ),
                          _c("TypeAheadAddress", {
                            ref: "typeAheadAddressCreateAccount",
                            attrs: { currentAddress: _vm.address },
                            on: { newAddress: _vm.setNewAddress }
                          })
                        ],
                        1
                      )
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }