<template>
  <Modal :modalId="'ConfirmDeleteModal-' + confirmDeleteModalInfo.apparatusId">
    <ModalHeader :title="$t('xDeleteEntry')" icon="fas fa-trash-can">
      <button
        class="btn btn-icon-button btn-icon-button--gray"
        @click="closeModal('ConfirmDeleteModal-' + confirmDeleteModalInfo.apparatusId)"
        :disabled="deleting"
        data-test-hook="close-btn-confirm-delete-modal"
      >
        <i class="fas fa-times" />
      </button>
    </ModalHeader>
    <ModalBody>
      <div class="mt-2 row" align-h="around" v-if="confirmDeleteModalInfo">
        <div class="col" data-test-hook="body-content-confirm-delete-modal">
          {{ $t('xAreYouSureYouWantToDelete') }}
          <span v-if="confirmDeleteModalInfo.infoType == 'phone'" data-test-hook="delete-value-confirm-delete-modal">
            {{ confirmDeleteModalInfo.value | phone }}
          </span>
          <span v-else data-test-hook="delete-value-confirm-delete-modal">
            {{ confirmDeleteModalInfo.value + '?' }}
          </span>
        </div>
      </div>
    </ModalBody>
    <ModalFooter>
      <button
        type="button"
        class="btn btn-outline-warning"
        @click="closeModal('ConfirmDeleteModal-' + confirmDeleteModalInfo.apparatusId)"
        data-test-hook="cancel-btn-confirm-delete-modal"
      >
        {{ $t('xCancel') }}
      </button>
      <button
        type="button"
        class="btn btn-primary"
        @click="deleteInfo"
        :disabled="deleting"
        data-test-hook="yes-btn-confirm-delete-modal"
      >
        {{ $t('xYesImSure') }}
        <div
          class="ml-2 spinner-border text-muted"
          v-if="deleting"
          data-test-hook="yes-btn-spinner-confirm-delete-modal"
        />
      </button>
    </ModalFooter>
  </Modal>
</template>

<script>
export default {
  props: ['confirmDeleteModalInfo'],
  data() {
    return {
      deleting: false
    }
  },
  methods: {
    resetModal() {
      this.deleting = false
    },
    deleteInfo() {
      this.deleting = true
      this.$emit('confirmDelete', {
        infoType: this.confirmDeleteModalInfo.infoType,
        index: this.confirmDeleteModalInfo.index,
        value: this.editValue,
        prefIndex: this.confirmDeleteModalInfo.prefIndex,
        contactInfo: this.confirmDeleteModalInfo.contactInfo,
        apparatusId: this.confirmDeleteModalInfo.apparatusId
      })
      this.resetModal()
      this.closeModal('ConfirmDeleteModal-' + this.confirmDeleteModalInfo.apparatusId)
    }
  }
}
</script>
<style scoped></style>
