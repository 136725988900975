var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "Modal",
        {
          attrs: {
            modalId: "cancel-paid-sub-step-1",
            size: "modal-lg",
            "data-test-hook": "modalTitle"
          }
        },
        [
          _c(
            "ModalHeader",
            {
              attrs: {
                title: _vm.$t("xCancelSubscription"),
                icon: "far fa-ban colorOrange mr-1"
              }
            },
            [
              _c(
                "button",
                {
                  staticClass: "btn-icon-button--gray btn-icon-button",
                  attrs: {
                    "data-test-hook": "x-button-step1-cancel-sub-modal"
                  },
                  on: {
                    click: function($event) {
                      return _vm.closeModals()
                    }
                  }
                },
                [
                  _c("i", {
                    staticClass: "fas fa-times",
                    attrs: { "data-test-hook": "modalCloseIcon" }
                  })
                ]
              )
            ]
          ),
          _c("ModalBody", [
            _vm.currentSubscription[0].productId == _vm.dealerManaged
              ? _c("div", [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col" }, [
                      _c(
                        "span",
                        {
                          attrs: {
                            "data-test-hook": "subModalDealerManagedSubText"
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "xYourGeneratorHasADealerManagedSubscription"
                                )
                              ) +
                              " "
                          )
                        ]
                      )
                    ])
                  ])
                ])
              : _vm.isTankType()
              ? _c("div", { staticClass: "mr-4" }, [
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      {
                        staticClass: "col",
                        attrs: { "data-test-hook": "modalBodyText1" }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("xIfYouCancelYourSubscription")) +
                            " "
                        )
                      ]
                    )
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c("ul", [
                      _c(
                        "li",
                        {
                          staticClass: "mt-2",
                          attrs: { "data-test-hook": "modalBodyBullet1" }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("xRemoteMonitoringViewTheStatusTank")
                              ) +
                              " "
                          )
                        ]
                      ),
                      _c(
                        "li",
                        {
                          staticClass: "mt-2",
                          attrs: { "data-test-hook": "modalBodyBullet2" }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("xViewTankDetails")) + " ")]
                      ),
                      _c(
                        "li",
                        {
                          staticClass: "mt-2",
                          attrs: { "data-test-hook": "modalBodyBullet3" }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("xReceiveNotificationAboutTanks")) +
                              " "
                          )
                        ]
                      ),
                      _c(
                        "li",
                        {
                          staticClass: "mt-2",
                          attrs: { "data-test-hook": "modalBodyBullet4" }
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("xViewTankOnEcobee")) + " "
                          )
                        ]
                      )
                    ])
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "row mt-3",
                      attrs: { "data-test-hook": "modalBodyText2" }
                    },
                    [
                      _c("div", { staticClass: "col" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("xWeHopeThatYouWillReconsiderTank")) +
                            " "
                        )
                      ])
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "row mt-3",
                      attrs: { "data-test-hook": "modalBodyText3" }
                    },
                    [
                      _c("div", { staticClass: "col" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("xAreYouSureYouWishToCancel")) +
                            " "
                        )
                      ])
                    ]
                  )
                ])
              : _c("div", { staticClass: "mr-4" }, [
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      {
                        staticClass: "col",
                        attrs: { "data-test-hook": "modalBodyText1" }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("xIfYouCancelYourSubscription")) +
                            " "
                        )
                      ]
                    )
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c("ul", [
                      _c(
                        "li",
                        {
                          staticClass: "mt-2",
                          attrs: { "data-test-hook": "modalBodyBullet1" }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("xGeneratorStatusIntuitiveAtAGlance")
                              ) +
                              " "
                          )
                        ]
                      ),
                      _c(
                        "li",
                        {
                          staticClass: "mt-2",
                          attrs: { "data-test-hook": "modalBodyBullet2" }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("xManualsGetAccessToEvenMore")) +
                              " "
                          )
                        ]
                      ),
                      _c(
                        "li",
                        {
                          staticClass: "mt-2",
                          attrs: { "data-test-hook": "modalBodyBullet3" }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("xStatusHistoryQuicklyAccess")) +
                              " "
                          )
                        ]
                      ),
                      _c(
                        "li",
                        {
                          staticClass: "mt-2",
                          attrs: { "data-test-hook": "modalBodyBullet4" }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("xMonthlyEmailSummarySaveTime")) +
                              " "
                          )
                        ]
                      ),
                      _c(
                        "li",
                        {
                          staticClass: "mt-2",
                          attrs: { "data-test-hook": "modalBodyBullet5" }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("xMaintenanceTrackingKeepTrackOf")
                              ) +
                              " "
                          )
                        ]
                      ),
                      !_vm.cancellingFreeSubscription()
                        ? _c(
                            "li",
                            {
                              staticClass: "mt-2",
                              attrs: { "data-test-hook": "modalBodyBullet6" }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("xRealTimeAlertsAchieveInstant")
                                  ) +
                                  " "
                              )
                            ]
                          )
                        : _vm._e(),
                      !_vm.cancellingFreeSubscription()
                        ? _c(
                            "li",
                            {
                              staticClass: "mt-2",
                              attrs: { "data-test-hook": "modalBodyBullet7" }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("xExerciseSettingsEasilyModify")
                                  ) +
                                  " "
                              )
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "li",
                        {
                          staticClass: "mt-2",
                          attrs: { "data-test-hook": "modalBodyBullet8" }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("xViewGeneratorStatusOnEcobee")) +
                              " "
                          )
                        ]
                      )
                    ])
                  ]),
                  _c("div", { staticClass: "row mt-3" }, [
                    _c(
                      "div",
                      {
                        staticClass: "col",
                        attrs: { "data-test-hook": "modalBodyText2" }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("xWeHopeThatYouWillReconsider")) +
                            " "
                        )
                      ]
                    )
                  ]),
                  _c("div", { staticClass: "row mt-3" }, [
                    _c(
                      "div",
                      {
                        staticClass: "col",
                        attrs: { "data-test-hook": "modalBodyText3" }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("xAreYouSureYouWishToCancel")) +
                            " "
                        )
                      ]
                    )
                  ])
                ])
          ]),
          _c("ModalFooter", [
            _c(
              "button",
              {
                staticClass: "btn btn-outline-warning",
                attrs: {
                  type: "button",
                  "data-test-hook": "previous-button-step1-cancel-sub-modal"
                },
                on: {
                  click: function($event) {
                    _vm.closeModal("cancel-paid-sub-step-1"),
                      _vm.closeModal("manage-sub-paid-step-1")
                  }
                }
              },
              [_vm._v(" " + _vm._s(_vm.$t("xNoKeepSubscription")) + " ")]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-warning",
                attrs: {
                  type: "button",
                  "data-test-hook": "yes-cancel-button-step1-cancel-sub-modal"
                },
                on: {
                  click: function($event) {
                    return _vm.yesCancelPaidSub()
                  }
                }
              },
              [_vm._v(" " + _vm._s(_vm.$t("xYesCancelSubscription")) + " ")]
            )
          ])
        ],
        1
      ),
      _c(
        "Modal",
        { attrs: { modalId: "cancel-paid-sub-step-2", size: "modal-lg" } },
        [
          _c("ModalHeader", {
            attrs: {
              title: _vm.$t("xCancelSubscription"),
              icon: "far fa-ban colorOrange mr-1"
            }
          }),
          _c("ModalBody", [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col" }, [
                _c("p", { attrs: { "data-test-hook": "CancelPlanMessage" } }),
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("xWereSorryToHearThatThe")) +
                    " " +
                    _vm._s(_vm.currentSubscription[0].productName) +
                    " " +
                    _vm._s(_vm.$t("xPlanIsNoLongerSatisfyingYourNeeds")) +
                    " "
                ),
                !_vm.cancellingFreeSubscription()
                  ? _c("div", [
                      _vm.isTankType()
                        ? _c("p", { staticStyle: { "font-weight": "bold" } }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("xYouWillBeAbleToMonitorYourTank")
                                ) +
                                " " +
                                _vm._s(
                                  _vm
                                    .$moment(
                                      _vm.currentSubscription[0].nextBillingDate
                                    )
                                    .format(_vm.$t("xShortDate"))
                                ) +
                                ". "
                            )
                          ])
                        : _c("p", { staticStyle: { "font-weight": "bold" } }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("xYouWillBeAbleToMonitor")) +
                                " " +
                                _vm._s(
                                  _vm
                                    .$moment(
                                      _vm.currentSubscription[0].nextBillingDate
                                    )
                                    .format(_vm.$t("xShortDate"))
                                ) +
                                ". "
                            )
                          ])
                    ])
                  : _vm._e(),
                _c("p", { staticClass: "mt-4 mb-3" }, [
                  _vm._v(_vm._s(_vm.$t("xPleaseLetUsKnowWhy")))
                ])
              ])
            ]),
            _c("div", { staticClass: "row mt-2" }, [
              _c("div", { staticClass: "col" }, [
                _c("div", { staticClass: "form-group" }, [
                  _vm.isTankType()
                    ? _c("div", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.cancelReason,
                              expression: "cancelReason"
                            }
                          ],
                          attrs: {
                            name: "cancelSelection",
                            type: "radio",
                            value: "I no longer want to monitor my tank",
                            id: "noLongerWantToMonitorTank",
                            "data-test-hook":
                              "no-longer-want-monitoring-radio-step2-cancel-sub-modal"
                          },
                          domProps: {
                            checked: _vm._q(
                              _vm.cancelReason,
                              "I no longer want to monitor my tank"
                            )
                          },
                          on: {
                            change: function($event) {
                              _vm.cancelReason =
                                "I no longer want to monitor my tank"
                            }
                          }
                        }),
                        _c(
                          "label",
                          {
                            staticClass: "ml-1",
                            staticStyle: { "margin-bottom": "0px" },
                            attrs: { for: "noLongerWantToMonitorTank" }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("xINoLongerWantToMonitorMyTank")
                                ) +
                                " "
                            )
                          ]
                        )
                      ])
                    : _c("div", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.cancelReason,
                              expression: "cancelReason"
                            }
                          ],
                          attrs: {
                            name: "cancelSelection",
                            type: "radio",
                            value: "I no longer want to monitor my generator",
                            id: "noLongerWantToMonitor",
                            "data-test-hook":
                              "no-longer-want-monitoring-radio-step2-cancel-sub-modal"
                          },
                          domProps: {
                            checked: _vm._q(
                              _vm.cancelReason,
                              "I no longer want to monitor my generator"
                            )
                          },
                          on: {
                            change: function($event) {
                              _vm.cancelReason =
                                "I no longer want to monitor my generator"
                            }
                          }
                        }),
                        _c(
                          "label",
                          {
                            staticClass: "ml-1",
                            staticStyle: { "margin-bottom": "0px" },
                            attrs: { for: "noLongerWantToMonitor" }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("xINoLongerWantToMonitorMyGenerator")
                                ) +
                                " "
                            )
                          ]
                        )
                      ]),
                  _c("div", { staticClass: "mt-2" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.cancelReason,
                          expression: "cancelReason"
                        }
                      ],
                      attrs: {
                        name: "cancelSelection",
                        type: "radio",
                        value: "I'm having too many techincal problems",
                        id: "tooManyTechnicalProblems",
                        "data-test-hook":
                          "too-many-tech-problems-radio-step2-cancel-sub-modal"
                      },
                      domProps: {
                        checked: _vm._q(
                          _vm.cancelReason,
                          "I'm having too many techincal problems"
                        )
                      },
                      on: {
                        change: function($event) {
                          _vm.cancelReason =
                            "I'm having too many techincal problems"
                        }
                      }
                    }),
                    _c(
                      "label",
                      {
                        staticClass: "ml-1",
                        staticStyle: { "margin-bottom": "0px" },
                        attrs: { for: "tooManyTechnicalProblems" }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("xIAmHavingTooManyTechnicalProblems")
                            ) +
                            " "
                        )
                      ]
                    )
                  ]),
                  _c("div", { staticClass: "mt-2" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.cancelReason,
                          expression: "cancelReason"
                        }
                      ],
                      attrs: {
                        name: "cancelSelection",
                        type: "radio",
                        value: "Could not Connect",
                        id: "iAmHavingConnectionIssues",
                        "data-test-hook":
                          "connection-issues-radio-step2-cancel-sub-modal"
                      },
                      domProps: {
                        checked: _vm._q(_vm.cancelReason, "Could not Connect")
                      },
                      on: {
                        change: function($event) {
                          _vm.cancelReason = "Could not Connect"
                        }
                      }
                    }),
                    _c(
                      "label",
                      {
                        staticClass: "ml-1",
                        staticStyle: { "margin-bottom": "0px" },
                        attrs: { for: "iAmHavingConnectionIssues" }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("xIAmHavingConnectionIssues")) +
                            " "
                        )
                      ]
                    )
                  ]),
                  _vm.cancelReason == "Could not Connect"
                    ? _c(
                        "div",
                        {
                          staticClass: "mb-1",
                          attrs: { id: "could-not-connect-specification" }
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.cancelReasonSpecification,
                                expression: "cancelReasonSpecification"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: {
                              type: "text",
                              maxlength: "500",
                              "data-test-hook":
                                "CannotConnectCancelReasonField",
                              placeholder: _vm.$t("xPleaseGiveDetails")
                            },
                            domProps: { value: _vm.cancelReasonSpecification },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) return
                                _vm.cancelReasonSpecification =
                                  $event.target.value
                              }
                            }
                          })
                        ]
                      )
                    : _vm._e(),
                  _c("div", { staticClass: "mt-2" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.cancelReason,
                          expression: "cancelReason"
                        }
                      ],
                      attrs: {
                        name: "cancelSelection",
                        type: "radio",
                        value:
                          "The features aren't valuable enough for the price",
                        id: "theFeaturesAreNotValuableEnoughForPrice",
                        "data-test-hook":
                          "features-are-bad-radio-step2-cancel-sub-modal"
                      },
                      domProps: {
                        checked: _vm._q(
                          _vm.cancelReason,
                          "The features aren't valuable enough for the price"
                        )
                      },
                      on: {
                        change: function($event) {
                          _vm.cancelReason =
                            "The features aren't valuable enough for the price"
                        }
                      }
                    }),
                    _c(
                      "label",
                      {
                        staticClass: "ml-1",
                        staticStyle: { "margin-bottom": "0px" },
                        attrs: {
                          for: "theFeaturesAreNotValuableEnoughForPrice"
                        }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "xTheFeaturesAreNotValuableEnoughForThePrice"
                              )
                            ) +
                            " "
                        )
                      ]
                    )
                  ]),
                  _c("div", { staticClass: "mt-2" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.cancelReason,
                          expression: "cancelReason"
                        }
                      ],
                      attrs: {
                        name: "cancelSelection",
                        type: "radio",
                        value: "There are not enough features for the price",
                        id: "thereAreNotEnoughFeaturesForPrice",
                        "data-test-hook":
                          "no-features-for-price-radio-step2-cancel-sub-modal"
                      },
                      domProps: {
                        checked: _vm._q(
                          _vm.cancelReason,
                          "There are not enough features for the price"
                        )
                      },
                      on: {
                        change: function($event) {
                          _vm.cancelReason =
                            "There are not enough features for the price"
                        }
                      }
                    }),
                    _c(
                      "label",
                      {
                        staticClass: "ml-1",
                        staticStyle: { "margin-bottom": "0px" },
                        attrs: { for: "thereAreNotEnoughFeaturesForPrice" }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("xThereAreNotEnoughFeaturesForThePrice")
                            ) +
                            " "
                        )
                      ]
                    )
                  ]),
                  _c("div", { staticClass: "mt-2" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.cancelReason,
                          expression: "cancelReason"
                        }
                      ],
                      attrs: {
                        name: "cancelSelection",
                        type: "radio",
                        value: "RemovalFlow",
                        id: "iHaveMoved",
                        "data-test-hook":
                          "i-have-moved-radio-step2-cancel-sub-modal"
                      },
                      domProps: {
                        checked: _vm._q(_vm.cancelReason, "RemovalFlow")
                      },
                      on: {
                        change: function($event) {
                          _vm.cancelReason = "RemovalFlow"
                        }
                      }
                    }),
                    _c(
                      "label",
                      {
                        staticClass: "ml-1",
                        staticStyle: { "margin-bottom": "0px" },
                        attrs: { for: "iHaveMoved" }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("xIHaveMovedRemoveImmediately")) +
                            " "
                        )
                      ]
                    )
                  ]),
                  _c("div", { staticClass: "mt-2" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.cancelReason,
                          expression: "cancelReason"
                        }
                      ],
                      attrs: {
                        name: "cancelSelection",
                        type: "radio",
                        value: "Other",
                        id: "otherReason",
                        "data-test-hook": "other-radio-step2-cancel-sub-modal"
                      },
                      domProps: { checked: _vm._q(_vm.cancelReason, "Other") },
                      on: {
                        change: function($event) {
                          _vm.cancelReason = "Other"
                        }
                      }
                    }),
                    _c(
                      "label",
                      {
                        staticClass: "ml-1",
                        staticStyle: { "margin-bottom": "0px" },
                        attrs: { for: "otherReason" }
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("xOther")) + " ")]
                    )
                  ]),
                  _vm.cancelReason == "Other"
                    ? _c("div", { attrs: { id: "other-specification" } }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.cancelReasonSpecification,
                              expression: "cancelReasonSpecification"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: {
                            maxlength: "500",
                            type: "text",
                            "data-test-hook": "OtherCancelReasonField",
                            placeholder: _vm.$t("xPleaseGiveDetails")
                          },
                          domProps: { value: _vm.cancelReasonSpecification },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) return
                              _vm.cancelReasonSpecification =
                                $event.target.value
                            }
                          }
                        })
                      ])
                    : _vm._e()
                ])
              ])
            ]),
            _c("div", { staticClass: "row colorRed" }, [
              _c("div", { staticClass: "col" }, [
                _vm.submitClicked && _vm.cancelReason == null
                  ? _c(
                      "p",
                      {
                        attrs: {
                          "data-test-hook":
                            "cancelSubModalYouMustSelectAReasonText"
                        }
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.$t("xYouMustSelectAReason")) + " "
                        )
                      ]
                    )
                  : _vm._e()
              ])
            ])
          ]),
          _c("ModalFooter", [
            _c(
              "button",
              {
                staticClass: "btn btn-warning",
                attrs: {
                  type: "button",
                  "data-test-hook":
                    "confirm-cancel-button-step2-cancel-sub-modal"
                },
                on: {
                  click: function($event) {
                    return _vm.cancelSubscription()
                  }
                }
              },
              [
                _vm._v(" " + _vm._s(_vm.$t("xSubmit")) + " "),
                _vm.cancellingSubscription
                  ? _c("span", {
                      staticClass: "spinner-border spinner-border-sm ml-2"
                    })
                  : _vm._e()
              ]
            )
          ])
        ],
        1
      ),
      _c(
        "Modal",
        { attrs: { modalId: "remove-gen-step-1", size: "modal-lg" } },
        [
          _c("ModalHeader", {
            attrs: {
              title: _vm.$t("xRemoveGenerator"),
              icon: "far fa-trash-alt colorOrange mr-1"
            }
          }),
          _c("ModalBody", [
            _c("div", { staticClass: "row mt-2" }, [
              _c("div", { staticClass: "col" }, [
                _vm._v(
                  " " + _vm._s(_vm.$t("xYouHaveIndicatedThatYouWant")) + " "
                )
              ])
            ]),
            _c("div", { staticClass: "row mt-4" }, [
              _c("div", { staticClass: "col" }, [
                _vm._v(
                  " " + _vm._s(_vm.$t("xRemoveGeneratorFromMobileLink")) + " "
                )
              ])
            ])
          ]),
          _c("ModalFooter", [
            _c(
              "button",
              {
                staticClass: "btn btn-outline-warning",
                attrs: {
                  type: "button",
                  "data-test-hook":
                    "previous-button-removeGen1-cancel-sub-modal"
                },
                on: {
                  click: function($event) {
                    _vm.openModal("cancel-paid-sub-step-2"),
                      _vm.closeModal("remove-gen-step-1"),
                      _vm.cleanReasons()
                  }
                }
              },
              [_vm._v(" " + _vm._s(_vm.$t("xNoSelectDifferentOption")) + " ")]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-warning",
                attrs: {
                  type: "button",
                  disabled: _vm.removing,
                  "data-test-hook":
                    "yes-im-sure-button-removeGen1-cancel-sub-modal"
                },
                on: {
                  click: function($event) {
                    return _vm.removeGenerator()
                  }
                }
              },
              [
                _vm._v(" " + _vm._s(_vm.$t("xYesRemove")) + " "),
                _vm.removing
                  ? _c("span", {
                      staticClass: "spinner-border spinner-border-sm ml-2"
                    })
                  : _vm._e()
              ]
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }