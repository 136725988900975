import Axios from 'axios'
import { vueAppApiBaseAddress } from '@/utils/mixins'

export const state = {}

export const getters = {}

export const mutations = {}

export const actions = {
  async getValues({ getters, commit, dispatch }) {
    await Axios.get(vueAppApiBaseAddress() + `Values`, { withCredentials: true })
      .then(response => {})
      .catch(error => {})
  },
  async signout({ getters, commit, dispatch }) {
    await Axios.get(vueAppApiBaseAddress() + `Auth/SignOut`, { withCredentials: true })
      .then(response => {})
      .catch(error => {})
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
