<template>
  <div></div>
</template>

<script>
import * as Constants from '@/constants'

export default {
  components: {},
  data() {
    return {
      longitude: 0,
      latitude: 0
    }
  },
  computed: {
    platform() {
      return navigator.userAgent
    }
  },
  methods: {
    async trackEvent(platform) {
      let browserNameWithVersion = ''
      let browserSearchString = ''
      let browserName = (function(agent) {
        switch (true) {
          case agent.indexOf('edge') > -1:
            browserSearchString = 'edge'
            return 'MS Edge'
          case agent.indexOf('edg/') > -1:
            browserSearchString = 'edg/'
            return 'Edge (chromium based)'
          case agent.indexOf('opr') > -1 && !!window.opr:
            browserSearchString = 'opr'
            return 'Opera'
          case agent.indexOf('chrome') > -1 && !!window.chrome:
            browserSearchString = 'chrome'
            return 'Chrome'
          case agent.indexOf('trident') > -1:
            browserSearchString = 'trident'
            return 'MS IE'
          case agent.indexOf('firefox') > -1:
            browserSearchString = 'firefox'
            return 'Mozilla Firefox'
          case agent.indexOf('safari') > -1:
            browserSearchString = 'safari'
            return 'Safari'
          default:
            browserSearchString = 'other'
            return 'other'
        }
      })(window.navigator.userAgent.toLowerCase())

      browserNameWithVersion = this.getBrowserNameAndVersion(browserSearchString)
      await this.getCurrentPosition()
      let campaignId = this.$route.query.campaign_ID ? this.$route.query.campaign_ID : 'none'

      let event = {
        event: Constants.GetStartedVisit,
        properties: {
          name: 'marketing-landing-getStarted',
          platform: platform,
          campaignId: campaignId,
          browserType: browserName,
          browserTypeWithVersion: browserNameWithVersion,
          location: this.latitude + ', ' + this.longitude
        }
      }
      await this.$store.dispatch('logEvent', event)
    },
    getBrowserNameAndVersion(browserSearchCode) {
      let browserNameWithVersion = 'none'
      let browserNameStartPosition = navigator.userAgent.toLowerCase().indexOf(browserSearchCode)
      let browserNameEndPosition =
        navigator.userAgent.indexOf(' ', browserNameStartPosition) != -1
          ? navigator.userAgent.indexOf(' ', browserNameStartPosition)
          : navigator.userAgent.length
      if (browserNameEndPosition > browserNameStartPosition) {
        browserNameWithVersion = navigator.userAgent.slice(browserNameStartPosition, browserNameEndPosition)
        return browserNameWithVersion
      }
      return ''
    },
    getCurrentPosition() {
      return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
          position => {
            this.latitude = position.coords.latitude
            this.longitude = position.coords.longitude
            resolve(position)
          },
          error => {
            this.latitude = 0
            this.longitude = 0
            resolve(error)
          }
        )
      })
    }
  },
  async mounted() {
    if (navigator.userAgent.toLowerCase().includes('windows')) {
      await this.trackEvent('Windows')
      window.location.href = '/download'
    } else if (navigator.userAgent.toLowerCase().includes('android')) {
      await this.trackEvent('Android')
      window.location.href = 'https://play.google.com/store/apps/details?id=com.generac.standbystatus'
    } else if (navigator.userAgent.toLowerCase().match(/(ios)|(ipad)|(iphone)/)) {
      await this.trackEvent('iOS')
      window.location.href = 'itms-apps://apps.apple.com/us/app/mobile-link-for-generators/id829129497'
    } else {
      await this.trackEvent('Unknown:' + navigator.userAgent)
      window.location.href = '/download'
    }
  }
}
</script>
<style scoped></style>
