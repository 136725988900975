var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "card hoverPointer apparatus-card",
        on: {
          click: function($event) {
            return _vm.toDetails()
          }
        }
      },
      [
        _c("div", { staticClass: "card-header dashboard-card-header" }, [
          _c("div", { staticClass: "row w-100 no-gutters" }, [
            _c("div", { staticClass: "float-left col" }, [
              _c("p", { staticClass: "py-0 my-0" }, [
                _c("strong", [_vm._v(" " + _vm._s(_vm.$t("xViewDetails")))])
              ])
            ]),
            _vm._m(0)
          ])
        ]),
        _c("div", { staticClass: "card-body" }, [
          _c("div", { staticClass: "row mt-2" }, [
            _c("div", { staticClass: "col-sm-12 col-md-4 col-lg-4" }, [
              _c("img", {
                staticClass: "img-fluid",
                attrs: {
                  src: _vm.apparatusInfo.heroImageUrl,
                  alt: _vm.$t("xHeroImage")
                }
              })
            ]),
            _c("div", { staticClass: "mt-3 col-sm-12 col-md-8 col-lg-8" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-auto" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._f("formatLabelNoTranslation")(_vm.$t("xSerial#"))
                      ) +
                      " " +
                      _vm._s(_vm.apparatusInfo.serialNumber) +
                      " "
                  )
                ])
              ]),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col" }, [
                  _c("h2", [_vm._v(_vm._s(_vm.apparatusInfo.name))])
                ])
              ])
            ])
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "float-right mr-0 pr-0 col-auto" }, [
      _c("i", { staticClass: "fa-regular fa-arrow-right" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }