<template>
  <div class="row">
    <div class="col-12 col-md-6">
      <h1>Generator: {{ value.apparatusId }}</h1>
      <div class="mb-3">
        <p>{{ 'Generator Name' | formatLabelNoTranslation }}</p>
        <p class="font-weight-bold pb-2">{{ value.generatorName || 'N/A' }}</p>
      </div>
      <div class="mb-3">
        <p>{{ 'Serial Number' | formatLabelNoTranslation }}</p>
        <div class="d-flex align-items-center">
          <p class="font-weight-bold">{{ value.serialNumber || 'N/A' }}</p>
          <button
            class="btn btn-light btn-sm ml-2"
            @click="copyToClipboard(value.serialNumber)"
            data-test-hook="copy-SN-button-general-info-tab"
          >
            <i class="far colorOrange fa-copy" />
          </button>
        </div>
      </div>
      <div v-if="hasDevice(value)" class="mb-3">
        <p>{{ 'Device ID' | formatLabelNoTranslation }}</p>
        <div class="d-flex align-items-center">
          <p class="font-weight-bold">
            {{ value.deviceInfo.deviceId || 'N/A' }}
          </p>
          <button
            class="btn btn-light btn-sm ml-2"
            @click="copyToClipboard(value.deviceInfo.deviceId)"
            data-test-hook="copy-device-id-button-general-info-tab"
          >
            <i class="far colorOrange fa-copy" />
          </button>
        </div>
      </div>
      <div v-else class="mb-3">
        <p>No Attached Device</p>
        <p class="pb-2">This generator has not been fully enrolled.</p>
      </div>
      <div v-if="hasDevice(value)" class="mb-3">
        <p>
          {{ 'Device Type' | formatLabelNoTranslation }}
        </p>
        <p class="font-weight-bold pb-2">{{ toLongDeviceType(value.deviceInfo.deviceType) }}</p>
      </div>
      <div class="mb-3">
        <p>
          {{ 'Panel ID' | formatLabelNoTranslation }}
        </p>
        <p class="font-weight-bold pb-2">{{ value.panelId || 'N/A' }}</p>
      </div>
      <div class="mb-3">
        <p>{{ 'Generator Address' | formatLabelNoTranslation }}</p>
        <div class="d-flex align-items-center">
          <p class="font-weight-bold">
            {{ DisplayAddress(value.apparatusAddress) }}
          </p>
          <button
            class="btn btn-light btn-sm ml-2"
            @click="copyToClipboard(DisplayAddress(value.apparatusAddress))"
            data-test-hook="copy-apparatus-address-button-general-info-tab"
          >
            <i class="far colorOrange fa-copy" />
          </button>
        </div>
      </div>
    </div>
    <div v-if="hasAssociatedAccount(value)" class="col-12 col-md-6">
      <h1>Owner: {{ value.organizationId || 'N/A' }}</h1>
      <div v-if="value.enrolledInVpp" class="mb-3">
        <p class="colorRed">
          <i>Enrolled In VPP Program</i>
        </p>
      </div>
      <div class="mb-3">
        <p>{{ 'Name' | formatLabelNoTranslation }}</p>
        <div class="d-flex align-items-center">
          <p class="font-weight-bold">
            {{ value.ownerInformation.firstName + ' ' + value.ownerInformation.lastName || 'N/A' }}
          </p>
          <button
            class="btn btn-light btn-sm ml-2"
            @click="copyToClipboard(value.ownerInformation.firstName + ' ' + value.ownerInformation.lastName)"
            data-test-hook="copy-name-button-general-info-tab"
          >
            <i class="far colorOrange fa-copy" />
          </button>
        </div>
      </div>
      <div class="mb-3">
        <p>{{ 'Phone Number' | formatLabelNoTranslation }}</p>
        <p class="font-weight-bold pb-2">{{ value.ownerInformation.phones[0] || 'N/A' }}</p>
      </div>
      <div class="mb-3">
        <p>{{ 'Email Address' | formatLabelNoTranslation }}</p>
        <div class="d-flex align-items-center">
          <p class="font-weight-bold">
            {{ value.ownerInformation.emails[0] || 'N/A' }}
          </p>
          <button
            variant="light"
            size="sm"
            class="btn btn-light btn-sm ml-2"
            @click="copyToClipboard(value.ownerInformation.emails[0])"
            data-test-hook="copy-email-button-general-info-tab"
          >
            <i class="far colorOrange fa-copy" />
          </button>
        </div>
      </div>
      <div class="mb-3">
        <p>{{ 'Owner Address' | formatLabelNoTranslation }}</p>
        <div class="d-flex align-items-center">
          <p class="font-weight-bold">
            {{ DisplayAddress(value.ownerAddress) }}
          </p>
          <button
            variant="light"
            size="sm"
            class="btn btn-light btn-sm ml-2"
            @click="copyToClipboard(DisplayAddress(value.ownerAddress))"
            data-test-hook="copy-owner-address-general-info-tab"
          >
            <i class="far colorOrange fa-copy" />
          </button>
        </div>
      </div>
    </div>
    <div v-else title="Owner" class="ml-3 mb-3">
      <h4 class="inactive-subscription">No Owner Info Found</h4>
    </div>
  </div>
</template>

<script>
import * as Constants from '@/constants'

export default {
  props: ['value'],
  data() {
    return {}
  },
  methods: {
    toLongDeviceType(deviceType) {
      switch (deviceType) {
        case Constants.HsbG3DeviceType:
          return 'Dual Generator V1'
        case Constants.TankUtilityWifiDeviceType:
          return 'Tank Utility Wifi V1'
        case Constants.TankUtilityLteDeviceTypeV1:
          return 'Tank Utility LTE V1'
        case Constants.TankUtilityLteDeviceTypeV2:
          return 'Tank Utility LTE V2'
        case Constants.HsbWifiDeviceType:
          return 'On-board Wifi'
        case Constants.HsbLteDeviceType:
          return 'Tether LTE'
        case Constants.HsbEthDeviceType:
          return 'Tether Ethernet/WiFi'
        case Constants.HsbCdmaDeviceType:
          return 'CDMA'
        default:
          return 'N/A'
      }
    }
  }
}
</script>
