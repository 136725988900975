<template>
  <div>
    <div v-if="!currentApparatusDetails" class="d-flex justify-content-center mb-3 pt-4">
      <span
        class="spinner-border spinner-border-xl text-warning"
        style="width: 5rem; height: 5rem"
        data-test-hook="LoadingSpinner"
        label="Spinning"
      ></span>
    </div>
    <div class="row" v-else>
      <div class="col">
        <div class="card" v-if="currentApparatusDetails.apparatusClassification == hsbGeneratorApparatus">
          <div
            class="card-header mb-4"
            :class="
              currentApparatusDetails.isConnected && !currentApparatusDetails.isConnecting
                ? `status${currentApparatusDetails.apparatusStatus}`
                : 'statusNoSignal'
            "
          >
            <div>
              <div
                style="overflow-anchor: none;"
                class="row noClickOutline details-header"
                :class="getHeaderTextClass()"
                data-test-hook="StatusHeader"
              >
                <div class="col">
                  <div
                    class="row align-center no-gutters"
                    type="button"
                    @click="statusDetailsVisible = !statusDetailsVisible"
                  >
                    <div class="col d-flex align-items-center">
                      <h2
                        class="statusText mb-0 d-flex align-items-center"
                        :class="getHeaderTextClass()"
                        data-test-hook="StatusLabel"
                      >
                        <StatusIcon
                          v-if="!isSwitchedOff()"
                          class="mr-2"
                          :apparatusStatus="currentApparatusDetails.apparatusStatus"
                          :isConnected="currentApparatusDetails.isConnected"
                          :showWarning="currentApparatusDetails.showWarning"
                          :isConnecting="currentApparatusDetails.isConnecting"
                          :deviceType="
                            currentApparatusDetails.deviceType == null
                              ? hsbLteDeviceType
                              : currentApparatusDetails.deviceType == hsbG3DeviceType
                              ? currentApparatusDetails.networkType
                              : currentApparatusDetails.deviceType
                          "
                          :inverse="true"
                        />
                        <i v-else class="mr-2 fas fa-fw fa-octagon-exclamation"></i>
                        {{
                          currentApparatusDetails.isConnecting
                            ? $t('xWorkingOnIt')
                            : currentApparatusDetails.statusLabel
                        }}
                      </h2>
                    </div>
                    <div class="col-auto">
                      <i v-if="!statusDetailsVisible" class="fas fa-lg fa-chevron-down"></i>
                      <i v-if="statusDetailsVisible" class="fas fa-lg fa-chevron-up"></i>
                    </div>
                  </div>
                </div>
              </div>
              <div
                id="statusCollapse"
                class="p-0 mt-2"
                :class="
                  currentApparatusDetails.isConnected && !currentApparatusDetails.isConnecting
                    ? `status-message${currentApparatusDetails.apparatusStatus}`
                    : 'statusNoSignal'
                "
                v-if="statusDetailsVisible"
              >
                <div class="p-4" :class="getHeaderTextClass()">
                  <div class="row">
                    <div class="col">
                      <span class="status-date" data-test-hook="LastSeen">
                        {{ displayLongDateTime(currentApparatusDetails.lastSeen) }}
                      </span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col" data-test-hook="ECodeLabel">
                      {{ currentApparatusDetails.alarmEcodeLabel ?? currentApparatusDetails.eCodeLabel }}
                    </div>
                  </div>
                  <div class="row" v-if="!currentApparatusDetails.isConnecting && currentApparatusDetails.isConnected">
                    <div class="col" data-test-hook="StatusText">
                      <p v-if="currentApparatusDetails.apparatusStatus != 8">
                        {{ currentApparatusDetails.statusText }}
                      </p>
                      <p v-else v-html="getSwitchedOffStatus(currentApparatusDetails.statusText)"></p>
                    </div>
                  </div>
                  <div class="row mt-2" v-if="currentApparatusDetails.isConnecting">
                    <div class="col">
                      {{ $t('xMobileLinkIsWorkingToGetYourGeneratorData') }}
                    </div>
                  </div>
                  <div class="row mt-2" v-else-if="!currentApparatusDetails.isConnected">
                    <div
                      class="col"
                      v-if="currentApparatusDetails.deviceType == 'lte' || currentApparatusDetails.deviceType == 'cdma'"
                    >
                      <p>{{ $t('xCellularNoSignalHelpMessage') }}</p>
                      <a
                        type="button"
                        class="btn btn-outline-warning mt-3"
                        href="https://support.generac.com/mobilelink/s/global-search/LTE"
                        target="_blank"
                        data-test-hook="TroubleshootingLink"
                      >
                        {{ $t('xGetHelp') }}
                      </a>
                    </div>
                    <div class="col" v-else>
                      <p>{{ $t('xNoSignalHelpMessage') }}</p>
                      <a
                        type="button"
                        class="btn btn-outline-warning mt-3"
                        href="https://support.generac.com/mobilelink/s/global-search/wifi%20troubleshooting"
                        target="_blank"
                        data-test-hook="TroubleshootingLink"
                      >
                        {{ $t('xGetHelp') }}
                      </a>
                    </div>
                  </div>
                  <div
                    class="row mt-3"
                    v-if="
                      subscriptionPaidAndNotManaged(currentApparatusDetails.subscription) &&
                        currentApparatusDetails.hasMaintenanceAlert
                    "
                  >
                    <div class="col" v-if="currentApparatusDetails.isConnected">
                      <button
                        type="button"
                        class="btn"
                        :class="clearMaintenanceButtonClass()"
                        @click="
                          openModal('clearMaintenanceModal'),
                            trackAppInsightsEvent('open-clear-maintenance-modal', {
                              page: 'device details',
                              organizationId: accountProfile.organizationId,
                              userId: user.userId,
                              apparatusId: currentApparatusDetails.apparatusId
                            })
                        "
                        data-test-hook="dismiss-warning-button-device-details"
                      >
                        <i class="fas fa-clipboard-check" />
                        {{ $t('xDismiss') }}
                        {{ $t('xWarning') }}
                      </button>
                    </div>
                  </div>
                  <div
                    class="row mt-3"
                    v-if="
                      virtualPowerPlantEnabled(currentAppConfig) &&
                        apparatusIsRunning &&
                        currentApparatusDetails.enrolledInVpp &&
                        currentApparatusDetails.hasActiveVppEvent
                    "
                  >
                    <div class="col">
                      <button
                        type="button"
                        class="btn btn-block btn-outline-light"
                        @click="$router.push({ name: 'virtualPowerPlant' })"
                      >
                        <i class="fas fa-eye" />
                        {{ $t('xViewEventInProgress') }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="card-body" style="padding-top:0px">
            <div class="row">
              <div class="col-12 col-lg-5">
                <div class="row" v-if="currentApparatusDetails.weather">
                  <div class="col">
                    <h3 class="headerInline ml-1" data-test-hook="Temperature">
                      <i :class="currentApparatusDetails.weather.iconCode | weatherIcon" class="mr-1"></i>
                      {{ temperature }}&deg;{{ temperatureUnits }}
                    </h3>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <img
                      class="img-fluid"
                      :src="currentApparatusDetails.heroImageUrl"
                      :alt="$t('xHeroImage')"
                      data-test-hook="HeroImg"
                    />
                  </div>
                </div>
                <div class="row mt-2 mb-2">
                  <div class="col">
                    <span v-if="currentApparatusDetails.isConnected">{{ $t('xOnline') }}</span>
                    <span v-else>{{ $t('xOffline') }}</span>
                    {{ $t('xSince') }} {{ displayShortDateTimeWithTZ(currentApparatusDetails.connectionTimestamp) }}
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="row align-center">
                  <div class="col">
                    <div class="row">
                      <div class="col-12">
                        <h2 data-test-hook="ApparatusName">{{ currentApparatusDetails.name }}</h2>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12" data-test-hook="ApparatusAddress">
                        {{ DisplayAddress(currentApparatusDetails.address) }}
                      </div>
                    </div>
                  </div>
                  <div class="col-auto">
                    <button
                      type="button"
                      class="btn btn-icon-button"
                      @click="openUpdateApparatusNameAddress()"
                      data-test-hook="UpdateApparatusBtn"
                    >
                      <i class="fas fa-pencil" />
                    </button>
                  </div>
                </div>
                <div
                  v-if="subscriptionActive(currentApparatusDetails.subscription)"
                  class="row detailsCardAction hoverPointer my-3 d-md-none align-items-center no-gutters"
                  @click="toStatusHistory"
                  data-test-hook="status-history-clickable-row-device-details"
                >
                  <div class="col ml-2">
                    <h5 class="headerInline">
                      <i class="fas fa-history colorOrange pr-2"></i>
                      {{ $t('xStatusHistory') }}
                    </h5>
                  </div>
                  <div class="col-auto">
                    <button type="button" class="btn btn-outline">
                      <i class="fas fa-lg fa-chevron-right"></i>
                    </button>
                  </div>
                </div>
                <div
                  class="row no-gutters align-items-center detailsCardAction hoverPointer my-3"
                  @click="openModal('RunHoursDetailsModal')"
                  data-test-hook="run-hours-clickable-row-device-details"
                >
                  <div class="col ml-2">
                    <h5 class="headerInline mb-0 pb-0">
                      <i class="fas fa-shield-check colorOrange pr-2" />
                      {{ $t('xHoursProtected') }}
                    </h5>
                  </div>
                  <div class="col-auto">
                    <button type="button" class="btn btn-outline">
                      <i class="fas fa-arrow-alt-square-up"></i>
                    </button>
                  </div>
                </div>
                <div
                  class="row no-gutters my-4 align-items-center"
                  @click="$bvModal.show('FuelLevelDetailsModal')"
                  v-if="fuelLevel"
                  data-test-hook="fuel-level-clickable-row-device-details"
                >
                  <div class="col ml-2">
                    <h5 class="headerInline mb-0 pb-0">
                      <i class="fas fa-gas-pump colorOrange pr-2"></i>
                      {{ 'xFuelLevel' | formatLabel }}
                    </h5>
                    <strong class="ml-1"> {{ fuelLevel }}% </strong>
                  </div>
                </div>
                <div class="row my-4 no-gutters" v-if="batteryVoltage">
                  <div class="col-auto ml-2">
                    <h5 class="headerInline">
                      <i class="fas fa-battery-bolt colorOrange pr-2"></i>
                      {{ 'xBatteryVoltage' | formatLabel }}
                      <strong class="ml-1" :class="getBatteryVoltageClass()" data-test-hook="BatteryVoltage">
                        {{ batteryVoltage }}{{ $t('xVoltageAbbr') }}
                      </strong>
                    </h5>
                  </div>
                  <div class="col float-right text-right mr-3">
                    <popper trigger="hover" :options="{ placement: 'bottom' }">
                      <div class="popper">
                        {{ $t('xYourBatteryLevelIs') }}
                        <strong>{{ getBatteryVoltageClassification() }}.</strong>
                        ( {{ $t('xValuesOf11.2VOrHigherAreGood') }} )
                      </div>
                      <i class="fas fa-question-circle hoverPointer" slot="reference"></i>
                    </popper>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card" v-else-if="currentApparatusDetails.apparatusClassification == fuelTankApparatus">
          <div
            class="card-header"
            :background-color="currentApparatusDetails.isConnected ? 'white' : ''"
            :class="currentApparatusDetails.isConnected ? '' : 'statusNoSignal'"
          >
            <div v-if="currentApparatusDetails.isConnected">
              <div class="row w-100 align-items-center no-gutters">
                <div class="col-auto float-left" text-right v-if="tankFuelLevel">
                  <i class="fa-light fa-gauge-max mr-1" />
                  {{ tankFuelLevel }}%
                </div>
                <div class="col-auto float-left ml-3" v-if="tankFuelType">
                  <i class="fal fa-flame mr-2" />
                  <span style="text-transform: capitalize;">{{ tankFuelType }}</span>
                </div>
              </div>
            </div>
            <div v-else>
              <div
                class="row noClickOutline details-header"
                :class="getHeaderTextClass()"
                data-test-hook="StatusHeader"
              >
                <div class="col">
                  <div
                    class="row align-items-center no-gutters hoverPointer"
                    @click="fuelTankHeaderExpanded = !fuelTankHeaderExpanded"
                  >
                    <div class="col-auto">
                      <i
                        v-if="currentApparatusDetails.deviceType == tankUtilityWifiDeviceType"
                        class="fa-solid fa-wifi-slash"
                      />
                      <i v-else class="fa-solid fa-signal-bars-slash" />
                    </div>
                    <div class="col ml-2">
                      <h2 class="statusText mb-0" :class="getHeaderTextClass()" data-test-hook="StatusLabel">
                        <span v-if="currentApparatusDetails.deviceType == tankUtilityWifiDeviceType">
                          {{ $t('xNoWifiSignal') }}
                        </span>
                        <span v-else>{{ $t('xNoCellularSignal') }}</span>
                      </h2>
                    </div>
                    <div class="col-auto">
                      <i v-if="!fuelTankHeaderExpanded" class="fas fa-lg fa-chevron-down"></i>
                      <i v-if="fuelTankHeaderExpanded" class="fas fa-lg fa-chevron-up"></i>
                    </div>
                  </div>
                </div>
              </div>
              <div id="statusCollapse" class="p-0 statusNoSignal" v-if="fuelTankHeaderExpanded">
                <div class="p-4" :class="getHeaderTextClass()">
                  <div class="row">
                    <div class="col">
                      <span class="status-date" data-test-hook="LastSeen">
                        {{ displayLongDateTime(tankLastReadingDate) }}
                      </span>
                    </div>
                  </div>
                  <div class="row mt-2">
                    <div class="col" v-if="currentApparatusDetails.deviceType != tankUtilityWifiDeviceType">
                      {{ $t('xCellularTankDeviceNotCommunicating') }}
                    </div>
                    <div class="col" v-else>
                      {{ $t('xWifiTankDeviceNotCommunicating') }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body row">
            <div class="col-12 col-lg-5">
              <div class="row">
                <div class="col">
                  <img
                    v-if="tankOrientation == 'horizontal'"
                    class="tankImage img-fluid"
                    :src="require('@/assets/images/tank-image.jpg')"
                    :alt="$t('xHeroImage')"
                    data-test-hook="HeroImg"
                  />
                  <img
                    v-else
                    class="tankImage img-fluid"
                    :src="require('@/assets/images/vertical-propane-tank.jpg')"
                    :alt="$t('xHeroImage')"
                    data-test-hook="HeroImg"
                  />
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-12" data-test-hook="apparatus-details-tu-fuel-capacity-label">
                  {{ $t('xFuelCapacity') }} ({{ abbreviatedLiquidUom }})
                </div>
                <div class="col-12">
                  <strong data-test-hook="apparatus-details-tu-fuel-capacity">{{ tankCapacity }}</strong>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-12" data-test-hook="apparatus-details-tu-id-label">
                  {{ $t('xTankDeviceId') }}
                </div>
                <div class="col-12">
                  <strong data-test-hook="apparatus-details-tu-id">{{ currentApparatusDetails.shortDeviceId }}</strong>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="row align-items-center">
                <div class="col">
                  <div class="row">
                    <div class="col-12">
                      <h2 data-test-hook="ApparatusName">{{ currentApparatusDetails.name }}</h2>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12" data-test-hook="ApparatusAddress">
                      {{ DisplayAddress(currentApparatusDetails.address) }}
                    </div>
                  </div>
                </div>
                <div class="col-auto">
                  <button
                    type="button"
                    class="btn btn-icon-button"
                    @click="openUpdateApparatusNameAddress()"
                    data-test-hook="UpdateApparatusBtn"
                  >
                    <i class="fas fa-pencil" />
                  </button>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col" data-test-hook="apparatus-details-tu-last-reading-date-time">
                  {{ 'xLastReadingDate' | formatLabel }}
                  <strong>{{ displayShortDateTimeWithTZ(tankLastReadingDate) }}</strong>
                </div>
              </div>
              <div class="row mt-3 align-items-center">
                <div class="col-auto" data-test-hook="apparatus-details-tu-battery-level-label">
                  {{ $t('xDeviceBattery') }}:
                </div>
                <div class="col tankBatteryLevelGood" v-if="tankBatteryLevel == 'good'">
                  <i class="fa-solid fa-battery-full mr-2" data-test-hook="apparatus-details-tu-battery-level-icon" />
                  <strong
                    style="text-transform: capitalize;"
                    data-test-hook="apparatus-details-tu-battery-level-text"
                    >{{ $t('xGood') }}</strong
                  >
                </div>
                <div class="col" v-else-if="tankBatteryLevel == 'warning'">
                  <span class="tankBatteryLevelWarning px-2">
                    <i class="fa-solid fa-battery-half mr-2" data-test-hook="apparatus-details-tu-battery-level-icon" />
                    <strong
                      style="text-transform: capitalize;"
                      data-test-hook="apparatus-details-tu-battery-level-text"
                      >{{ $t('xWarning') }}</strong
                    >
                  </span>
                </div>
                <div class="col" v-else>
                  <span class="tankBatteryLevelCritical px-2">
                    <i
                      class="fa-solid fa-battery-exclamation mr-2"
                      data-test-hook="apparatus-details-tu-battery-level-icon"
                    />
                    <strong
                      style="text-transform: capitalize;"
                      data-test-hook="apparatus-details-tu-battery-level-text"
                      >{{ $t('xCritical') }}</strong
                    >
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="card"
          v-if="
            PortablesEnabledFF(currentAppConfig) &&
              currentApparatusDetails.apparatusClassification == portableGenApparatus
          "
        >
          <div class="card-header portable-details-header">
            <div class="row">
              <div class="col">
                <h2 class="mb-0">{{ $t('xConnectViaBluetoothOnMobile') }}</h2>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="col">
              <div class="row">
                <div class="col-sm-12 col-md-4 col-lg-4">
                  <img
                    class="img-fluid"
                    :src="currentApparatusDetails.heroImageUrl"
                    :alt="$t('xHeroImage')"
                    data-test-hook="HeroImg"
                  />
                </div>
                <div class="col-sm-12 col-md-8 col-lg-8 mt-3">
                  <div class="row">
                    <div class="col">
                      <p>{{ $t('xSerial#') | formatLabelNoTranslation }} {{ currentApparatusDetails.serialNumber }}</p>
                      <h2>{{ currentApparatusDetails.name }}</h2>
                    </div>
                    <div class="col-auto float-right">
                      <button
                        type="button"
                        class="btn btn-icon-button"
                        @click="openUpdateApparatusNameAddress()"
                        data-test-hook="UpdateApparatusBtn"
                      >
                        <i class="fas fa-pencil" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <FuelLevelDetailsModal :fuelDetails="apparatusDetails.fuel" /> -->
      <RunHoursDetailsModal
        :standByHours="standByHours"
        :runHours="runHours"
        :activationDate="currentApparatusDetails.activationDate"
      />
    </div>
    <Modal modalId="UpdateApparatusNameAddress">
      <ModalHeader
        :title="$t('xEditInformation')"
        icon="fas fa-address-card colorOrange mr-1"
        data-test-hook="ModalTitle"
      />
      <ModalBody>
        <div class="row">
          <div class="col">
            <div class="form-group" id="apparatusName-group">
              <label for="apparatusName" class="d-block" data-test-hook="ApparatusNameHeader">
                {{ $t('xApparatusName') }}
              </label>
              <input
                class="form-control"
                id="apparatusName"
                v-model="$v.apparatusName.$model"
                type="text"
                :class="status($v.apparatusName)"
                maxlength="50"
                aria-describedby="apparatusName-invalid"
                data-test-hook="ApparatusNameValue"
              />
              <div class="invalid-feedback" id="apparatusName-invalid">
                {{ $t('xRequiredField') }}
              </div>
            </div>
          </div>
        </div>
        <div class="row" v-if="apparatus">
          <div class="col">
            <TypeAheadAddress
              ref="typeAheadAddressDeviceDetails"
              :currentAddress="apparatus.address"
              @newAddress="setCurrentAddress"
            />
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <button
          type="button"
          class="btn btn-outline-warning"
          @click="closeUpdateApparatusNameAddress()"
          data-test-hook="CancelBtn"
        >
          {{ $t('xCancel') }}
        </button>
        <button
          type="button"
          class="btn btn-warning"
          @click="UpdateApparatusInfo()"
          :disabled="updatingApparatusInfo"
          data-test-hook="UpdateBtn"
        >
          {{ $t('xUpdate') }}
          <span class="spinner-border spinner-border-sm" v-if="updatingApparatusInfo"></span>
        </button>
      </ModalFooter>
    </Modal>

    <Modal modalId="clearMaintenanceModal" size="modal-md">
      <ModalHeader :title="$t('xAreYouSure')" icon="fas fa-exclamation-triangle colorOrange">
        <div class="col-auto float-right">
          <button
            type="button"
            class="btn btn-icon-button btn-icon-button--gray"
            @click="closeModal('clearMaintenanceModal')"
            data-test-hook="x-button-clearMaintenanceModal-device-details"
          >
            <i class="fas fa-times" />
          </button>
        </div>
      </ModalHeader>
      <ModalBody>
        <div class="row">
          <div class="col">
            {{ $t('xClearMaintenanceModalP1') }}
          </div>
        </div>
        <div class="row mt-2">
          <div class="col">
            {{ $t('xClearMaintenanceModalP2') }}
          </div>
        </div>
        <div class="row mt-3">
          <div class="col">
            <strong>{{ $t('xClearCommand15MinuteMessage') }}</strong>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <button
          type="button"
          class="btn btn-warning"
          @click="
            closeModal('clearMaintenanceModal'),
              openModal('addMaintenanceModal'),
              trackAppInsightsEvent('user-is-clearing-maintenance', {
                page: 'device details',
                organizationId: accountProfile.organizationId,
                userId: user.userId,
                apparatusId: currentApparatusDetails.apparatusId
              })
          "
          data-test-hook="yes-im-sure-button-clearMaintenanceModal-device-details"
        >
          {{ $t('xYesImSure') }}
        </button>
        <button
          type="button"
          class="btn btn-outline-warning"
          @click="closeModal('clearMaintenanceModal')"
          data-test-hook="no-cancel-button-clearMaintenanceModal-device-details"
        >
          {{ $t('xNoCancel') }}
        </button>
      </ModalFooter>
    </Modal>
    <AddMaintenanceModal
      :editedMaintenanceRecord="null"
      :disableCancel="true"
      :editingRecord="false"
      :sendClearMaintenance="true"
      :apparatus="currentApparatusDetails"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import RunHoursDetailsModal from './RunHoursDetailsModal.vue'
import StatusIcon from '@/components/StatusIcon'
import TypeAheadAddress from '@/components/TypeAheadAddress'
import unitMixin from '@/utils/unitMixin'
import { required } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'
import * as Constants from '@/constants'
import AddMaintenanceModal from '@/components/ApparatusDetails/AddMaintenanceModal'

export default {
  mixins: [unitMixin, validationMixin],
  components: {
    //   FuelLevelDetailsModal,
    RunHoursDetailsModal,
    StatusIcon,
    TypeAheadAddress,
    AddMaintenanceModal
  },
  data() {
    return {
      detailMenuVisible: false,
      apparatusName: null,
      apparatus: null,
      updatingApparatusInfo: false,
      temperature: 0,
      temperatureUnits: 'F',
      statusDetailsVisible: false,
      fuelTankHeaderExpanded: false
    }
  },
  validations: {
    apparatusName: {
      required
    }
  },
  computed: {
    ...mapState({
      currentApparatusDetails: state => state.apparatus.currentApparatusDetails,
      user: state => state.account.user,
      accountProfile: state => state.account.accountProfile,
      currentAppConfig: state => state.application.currentAppConfig
    }),
    runHours() {
      return this.getPropertyValue(70)
    },
    fuelLevel() {
      return this.getPropertyValue(73)
    },
    batteryVoltage() {
      return this.getPropertyValue(69) ? this.getPropertyValue(69) : this.getPropertyValue(80)
    },
    standByHours() {
      return this.getPropertyValue(31)
    },
    apparatusIsRunning() {
      return this.currentApparatusDetails
        ? this.currentApparatusDetails.apparatusStatus == Constants.StatusIsRunning
        : false
    },
    hsbGeneratorApparatus() {
      return Constants.HsbApparatusType
    },
    fuelTankApparatus() {
      return Constants.FuelTankApparatusType
    },
    portableGenApparatus() {
      return Constants.PortableGeneratorApparatusType
    },
    tankFuelLevel() {
      return this.getPropertyValue(Constants.TuSnmpTypeFuelLevel)
    },
    tankFuelType() {
      return this.getPropertyName(Constants.TuSnmpTypeFuelType)
    },
    tankLastReadingDate() {
      return this.getPropertyValue(Constants.TuSnmpTypeLastReading)
    },
    tankCapacity() {
      const capacityInGallons = this.getPropertyValue(Constants.TuSnmpTypeCapacity)
      return this.convertGallonsToPreferredUnit(capacityInGallons)
    },
    tankBatteryLevel() {
      return this.getPropertyValue(Constants.TuSnmpTypeBatteryLevel)
    },
    tankOrientation() {
      return this.getPropertyValue(Constants.TuSnmpTypeOrientation)
    },
    tankUtilityLteDeviceTypeV1() {
      return Constants.TankUtilityLteDeviceTypeV1
    },
    tankUtilityLteDeviceTypeV2() {
      return Constants.TankUtilityLteDeviceTypeV2
    },
    tankUtilityWifiDeviceType() {
      return Constants.TankUtilityWifiDeviceType
    },
    hsbG3DeviceType() {
      return Constants.HsbG3DeviceType
    }
  },
  methods: {
    isSwitchedOff() {
      if (this.currentApparatusDetails.isConnecting) return false
      if (!this.currentApparatusDetails.isConnected) return false
      return this.currentApparatusDetails.apparatusStatus == Constants.StatusIsSwitchedOff
    },
    getSwitchedOffStatus(inputString) {
      const wordsToFind = 'Set your generator to AUTO'
      const wordsToFindRegex = new RegExp(wordsToFind, 'gi')
      return inputString.replace(wordsToFindRegex, match => {
        return `<u><a href=\"https://support.generac.com/s/article/How-Do-I-Put-My-Home-Standby-Generator-Into-Auto-Mode\" style=\"color: inherit\" target=\"_blank\">${match}</a></u>`
      })
    },
    clearMaintenanceButtonClass() {
      if (!this.currentApparatusDetails.isConnected) return 'btn-outline-dark'
      else if (
        this.currentApparatusDetails.apparatusStatus == Constants.StatusIsWarning ||
        this.currentApparatusDetails.apparatusStatus == Constants.StatusIsUnknown ||
        this.currentApparatusDetails.apparatusStatus == Constants.StatusIsCommsIssue
      ) {
        return 'btn-outline-dark'
      } else return 'btn-outline-light'
    },
    UpdateApparatusInfo() {
      this.updatingApparatusInfo = true
      let request = {
        apparatusId: this.$route.params.apparatusId,
        name: this.apparatusName,
        address: this.apparatus.address
      }
      this.$store
        .dispatch('updateApparatusInfo', request)
        .then(response => {
          this.$store
            .dispatch('getApparatusDetails', this.$route.params.apparatusId)
            .then(response => {
              this.updatingApparatusInfo = false
              this.closeUpdateApparatusNameAddress()
            })
            .catch(error => {
              this.updatingApparatusInfo = false
              this.closeUpdateApparatusNameAddress()
              this.$store.dispatch('displayToast', {
                title: this.$t('xError'),
                message: this.$t('xFailedToLoadNewInformationPleaseRefreshThisPage'),
                variant: 'danger'
              })
            })
        })
        .catch(error => {
          this.updatingApparatusInfo = false
          this.$store.dispatch('displayToast', {
            title: this.$t('xError'),
            message: this.$t('xFailedToUpdate'),
            variant: 'danger'
          })
        })
    },
    getHeaderTextClass() {
      if (this.currentApparatusDetails.isConnecting) return 'colorBlack'
      else if (!this.currentApparatusDetails.isConnected) return 'bodyText'
      else if (
        this.currentApparatusDetails.apparatusStatus == Constants.StatusIsWarning ||
        this.currentApparatusDetails.apparatusStatus == Constants.StatusIsUnknown ||
        this.currentApparatusDetails.apparatusStatus == Constants.StatusIsCommsIssue ||
        this.currentApparatusDetails.apparatusStatus == Constants.StatusIsDisconnected
      ) {
        return 'colorBlack'
      } else return 'colorWhite'
    },
    getBatteryVoltageClassification() {
      if (this.batteryVoltage >= 11.2) return this.$t('xGood')
      else return this.$t('xLow')
    },
    getBatteryVoltageClass() {
      if (this.batteryVoltage >= 11.2) {
        return 'battery-good'
      } else return 'battery-low'
    },
    toStatusHistory() {
      this.$router.push({ name: 'statusHistory' })
    },
    showRunHourDetails() {
      this.$router.push({ name: 'statusHistory' })
    },
    showFuelLevelDetails() {
      this.$router.push({ name: 'statusHistory' })
    },
    getPropertyValue(type) {
      if (this.currentApparatusDetails) {
        if (this.currentApparatusDetails.apparatusClassification == this.hsbGeneratorApparatus) {
          let property = this.currentApparatusDetails.properties.find(p => p.type == type)
          return property != null ? property.value : null
        } else if (this.currentApparatusDetails.apparatusClassification == this.fuelTankApparatus) {
          let property = this.currentApparatusDetails.tuProperties.find(p => p.type == type)
          return property != null ? property.value : null
        }
      }
      return null
    },
    getPropertyName(type) {
      if (this.currentApparatusDetails) {
        if (this.currentApparatusDetails.apparatusClassification == this.hsbGeneratorApparatus) {
          let property = this.currentApparatusDetails.properties.find(p => p.type == type)
          return property != null ? property.name : null
        } else if (this.currentApparatusDetails.apparatusClassification == this.fuelTankApparatus) {
          let property = this.currentApparatusDetails.tuProperties.find(p => p.type == type)
          return property != null ? property.name : null
        }
      }
      return null
    },
    setUpdateModal() {
      this.apparatus = _.cloneDeep(this.currentApparatusDetails)
      this.apparatusName = this.currentApparatusDetails.name
    },
    resetUpdateModal() {
      this.apparatus = null
      this.updatingApparatusInfo = false
      this.apparatusName = null
    },
    openUpdateApparatusNameAddress() {
      this.setUpdateModal()
      this.openModal('UpdateApparatusNameAddress')
    },
    closeUpdateApparatusNameAddress() {
      this.closeModal('UpdateApparatusNameAddress')
      this.resetUpdateModal()
    },
    setCurrentAddress(newAddress) {
      this.apparatus.address = newAddress
      if (this.$refs.typeAheadAddressDeviceDetails.$v.$anyError) {
        this.addressSaveDisabled = true
      } else {
        this.addressSaveDisabled = false
      }
    },
    setTemperatureUnits() {
      if (this.user.mobileLinkSettings.displaySettings.temperatureUom) {
        this.temperatureUnits =
          this.user.mobileLinkSettings.displaySettings.temperatureUom == 'fahrenheit'
            ? this.$t('xFahrenheitAbbr')
            : this.$t('xCelsiusAbbr')
      } else {
        this.temperatureUnits = this.$t('xFahrenheitAbbr')
      }
    },
    setTemperature() {
      if (
        ((this.user.mobileLinkSettings.displaySettings.temperatureUom == 'fahrenheit' ||
          this.user.mobileLinkSettings.displaySettings.temperatureUom == null) &&
          this.currentApparatusDetails.weather.temperature.unit == 'F') ||
        (this.user.mobileLinkSettings.displaySettings.temperatureUom == 'celsius' &&
          this.currentApparatusDetails.weather.temperature.unit == 'C')
      ) {
        this.temperature = this.currentApparatusDetails.weather.temperature.value
      } else if (this.user.mobileLinkSettings.displaySettings.temperatureUom == 'fahrenheit') {
        //Celsius to Fahrenheit
        this.temperature = Math.round((this.currentApparatusDetails.weather.temperature.value * 9) / 5) + 32
      } else {
        //Fahrenheit to Celsius
        this.temperature = Math.round(((this.currentApparatusDetails.weather.temperature.value - 32) * 5) / 9)
      }
    }
  },
  watch: {
    user: function(newVal, oldVal) {
      if (newVal) {
        this.setTemperature()
        this.setTemperatureUnits()
      }
    }
  },
  mounted() {
    if (this.currentApparatusDetails) {
      if (this.currentApparatusDetails.apparatusClassification == this.hsbGeneratorApparatus) {
        this.setTemperature()
        this.setTemperatureUnits()
        if (
          this.currentApparatusDetails.apparatusStatus &&
          (this.currentApparatusDetails.apparatusStatus == 4 || this.currentApparatusDetails.apparatusStatus == 5)
        ) {
          this.detailMenuVisible = true
        }
      }
    }
    if (this.isSwitchedOff()) {
      this.statusDetailsVisible = true
    }
    if (!this.currentAppConfig) this.$store.dispatch('getApplicationConfiguration', 'Any')
  }
}
</script>

<style scoped lang="scss">
.portable-details-header {
  background-color: var(--carbon-100);
  font-weight: 700;
  font-size: 24px;
  line-height: 26px;
  color: var(-gray-800);
}
.status-date-row {
  font-size: 0.75rem;
}

h3 {
  line-height: 1.125;
}
.status-date {
  font-size: 1rem;
  line-height: 1.25rem;
  text-transform: uppercase;
}

#statusCollapse {
  position: absolute;
  width: 100%;
  z-index: 30;
  left: 0;
  padding: 20px;
  color: var(--carbon-900);
}

.statusText {
  text-transform: capitalize;
}
.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}
.detailsCardAction {
  border: 1px solid var(--carbon-300);
  border-radius: 4px;

  &:hover {
    background: var(--carbon-200);
  }
}
.tankBatteryLevelGood {
  color: var(--green-500) !important;
}
.tankBatteryLevelWarning {
  color: var(--yellow-500) !important;
}
.tankBatteryLevelCritical {
  color: var(--red-600) !important;
}
</style>
