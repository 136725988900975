<template>
  <div>
    <b-modal id="delete-all-messages-modal" centered size="md" no-close-on-backdrop no-close-on-esc>
      <template #modal-header>
        <b-row class="w-100" align-v="center" no-gutters>
          <b-col>
            <h2 class="modal-title" data-test-hook="modalTitle">
              <i class="fas fa-exclamation-triangle colorOrange mr-1" data-test-hook="modalTitleIcon"></i>
              <span class="ml-2" data-test-hook="modalTitleText">{{ $t('xAreYouSure') }}</span>
            </h2>
          </b-col>
          <b-col cols="auto" class="float-right">
            <b-button
              class="btn-icon-button--gray"
              variant="icon-button"
              @click="$bvModal.hide('delete-all-messages-modal'), (messageTypeToDelete = null)"
              data-test-hook="modalXCloseBtn"
            >
              <i class="fas fa-times" />
            </b-button>
          </b-col>
        </b-row>
      </template>
      <template #modal-footer>
        <b-button
          variant="outline-warning"
          @click="$bvModal.hide('delete-all-messages-modal'), (messageTypeToDelete = null)"
          data-test-hook="modalNoCancelBtn"
        >
          <span data-test-hook="modalNoCancelBtnText">{{ $t('xNoCancel') }}</span>
        </b-button>
        <b-button variant="warning" @click="deleteMessages()" data-test-hook="modalYesImSureBtn">
          <span v-if="!deletingMessages" data-test-hook="modalYesImSureBtnText">{{ $t('xYesImSure') }}</span>
          <b-spinner v-if="deletingMessages" />
        </b-button>
      </template>
      <b-row data-test-hook="modalBodyText">
        <b-col>{{ $t('xAreYouSureYouWantToDeleteAllMessages') }} {{ messageCategory }}?</b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import * as Constants from '@/constants'

export default {
  props: ['messages'],
  data() {
    return {
      messageIds: null,
      deletingMessages: false
    }
  },
  computed: {
    messageCategory() {
      if (this.messages && this.messages[0]) {
        if (this.messages[0].category == Constants.AccountMessagesMessageCategory) {
          return this.$t('xAccountMessages')
        } else if (this.messages[0].category == Constants.AppUpdatesMessageCategory) {
          return this.$t('xInbox')
        }
      }
      return ''
    }
  },
  methods: {
    async deleteMessages() {
      if (this.messages) {
        this.messageIds = this.messages.map(function(x) {
          return x.id
        })
        this.deletingMessages = true
        await this.$store
          .dispatch('deleteMessages', this.messageIds)
          .then(response => {
            this.messageIds = null
            this.$store.dispatch('getMessages')
            this.$store.dispatch('getHasUnreadMessages')
            this.deletingMessages = false
            this.$bvModal.hide('delete-all-messages-modal')
            this.$emit('clearMessages', true)
            this.$store.dispatch('displayToast', {
              title: this.$t('xSuccess'),
              message: this.$t('xMessagesSuccessfullyDeleted'),
              variant: 'success'
            })
          })
          .catch(error => {
            this.deletingMessages = false
            this.$store.dispatch('displayToast', {
              title: this.$t('xError'),
              message: this.$t('xMessageDeleteFailure'),
              variant: 'danger'
            })
          })
      }
    }
  }
}
</script>
