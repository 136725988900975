var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mt-2 ml-2" },
    [
      _c("div", { staticClass: "row no-gutters mb-2" }, [
        _c("div", { staticClass: "col" }, [
          _c("h1", { staticClass: "mb-0 pb-0" }, [
            _c("i", {
              staticClass: "far fa-meter-droplet colorOrange mr-1",
              attrs: { "data-test-hook": "fuel-consumption-page-title-icon" }
            }),
            _c(
              "span",
              {
                attrs: { "data-test-hook": "fuel-consumption-page-title-text" }
              },
              [_vm._v(_vm._s(_vm.$t("xFuelConsumption")))]
            )
          ])
        ])
      ]),
      _c("div", { staticClass: "card" }, [
        _c(
          "div",
          { staticClass: "card-body" },
          [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col" }, [
                    _c(
                      "h3",
                      { attrs: { "data-test-hook": "fuelUsageListHeader" } },
                      [
                        _vm._v(
                          _vm._s(
                            _vm._f("formatLabel")(
                              "xDevicesThatUseFuelFromThisTank"
                            )
                          )
                        )
                      ]
                    )
                  ])
                ]),
                !_vm.hasSelectedFuelUses
                  ? _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col" }, [
                        _c(
                          "span",
                          {
                            attrs: {
                              "data-test-hook":
                                "fuel-consumption-no-devices-selected"
                            }
                          },
                          [_vm._v(_vm._s(_vm.$t("xNoDevicesSelected")))]
                        )
                      ])
                    ])
                  : _vm._e()
              ]),
              _c("div", { staticClass: "cols-auto float-right" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-icon-button",
                    attrs: {
                      type: "button",
                      "data-test-hook": "fuel-consumption-edit-devices-button"
                    },
                    on: {
                      click: function($event) {
                        return _vm.openModal("edit-fuel-consumption-modal")
                      }
                    }
                  },
                  [
                    _c("i", {
                      staticClass: "fas fa-pencil",
                      attrs: {
                        "data-test-hook":
                          "fuel-consumption-edit-devices-button-icon"
                      }
                    })
                  ]
                )
              ])
            ]),
            _vm._l(_vm.selectedTankUtilFuelUses, function(type, index) {
              return _c(
                "ul",
                {
                  key: index,
                  attrs: {
                    "data-test-hook":
                      "FuelUse-" + _vm.removeExtraChars(_vm.$t(type.name))
                  }
                },
                [
                  type.selected
                    ? _c(
                        "li",
                        {
                          attrs: {
                            id: index,
                            "data-test-hook":
                              "FuelUse-" +
                              _vm.removeExtraChars(_vm.$t(type.name)) +
                              "-isVisible"
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t(type.name)) + " ")]
                      )
                    : _vm._e()
                ]
              )
            })
          ],
          2
        )
      ]),
      _c("EditFuelConsumptionModal")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }