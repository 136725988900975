<template>
  <div>
    <b-alert
      v-if="
        currentAppConfig && currentAppConfig.featureFlags && currentAppConfig.featureFlags[billingMigrationFeatureFlag]
      "
      show
      variant="warning"
    >
      {{ $t('xBillingMaintenanceMessage') }}
    </b-alert>
    <b-row>
      <b-col>
        <b-input-group class="mt-2">
          <b-input-group-prepend>
            <b-dropdown
              variant="primary"
              class="input-height search-in"
              data-test-hook="search-criteria-dropdown-admin"
            >
              <template #button-content>
                <span class="dropdown-text">
                  {{ searchCriteriaText }}
                </span>
              </template>
              <b-dropdown-item>
                <b-button
                  variant="link"
                  block
                  class="text-left input-height"
                  @click="SetSearchCriteria('Serial Number')"
                >
                  Serial Number
                </b-button>
              </b-dropdown-item>
              <b-dropdown-item>
                <b-button variant="link" block class="text-left input-height" @click="SetSearchCriteria('Email')">
                  Email
                </b-button>
              </b-dropdown-item>
              <b-dropdown-item>
                <b-button variant="link" block class="text-left input-height" @click="SetSearchCriteria('LastName')">
                  Last Name
                </b-button>
              </b-dropdown-item>
              <b-dropdown-item>
                <b-button variant="link" block class="text-left input-height" @click="SetSearchCriteria('Device ID')">
                  Device ID
                </b-button>
              </b-dropdown-item>
              <b-dropdown-item>
                <b-button variant="link" block class="text-left input-height" @click="SetSearchCriteria('Mac Address')">
                  Mac Address
                </b-button>
              </b-dropdown-item>
              <b-dropdown-item>
                <b-button
                  variant="link"
                  block
                  class="text-left input-height"
                  @click="SetSearchCriteria('Lte Tether Serial')"
                >
                  Lte Tether Serial
                </b-button>
              </b-dropdown-item>
              <b-dropdown-item>
                <b-button
                  variant="link"
                  block
                  class="text-left input-height"
                  @click="SetSearchCriteria('Ethernet Tether Serial')"
                >
                  Ethernet Tether Serial
                </b-button>
              </b-dropdown-item>
              <b-dropdown-item>
                <b-button variant="link" block class="text-left input-height" @click="SetSearchCriteria('MDN')">
                  MDN
                </b-button>
              </b-dropdown-item>
            </b-dropdown>
          </b-input-group-prepend>
          <b-form-input
            style="height: 50px;"
            v-model="searchCriteria"
            maxlength="255"
            v-on:keyup.enter="Search"
            size="lg"
          ></b-form-input>
          <b-input-group-append class="my-0 py-0">
            <b-button variant="primary" v-on:click="Search" class="input-height" data-test-hook="go-button-admin">
              <i class="fas fa-search" />
            </b-button>
            <b-button variant="light" v-on:click="ClearSearch" class="input-height" data-test-hook="clear-button-admin">
              <i class="fas fa-times" />
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
    </b-row>
    <b-row v-if="searchPending">
      <b-col cols="12" class="d-flex justify-content-center my-3 mt-4">
        <b-spinner style="width: 5rem; height:5rem;" variant="warning" label="Spinning"></b-spinner>
      </b-col>
    </b-row>
    <div
      class="search-results"
      v-else-if="
        !searchPending &&
          csSearchResults &&
          csSearchResults.length > 0 &&
          searchCriteriaText != 'Email' &&
          searchCriteriaText != 'LastName'
      "
    >
      <div v-for="(result, index) in csSearchResults" :key="result.apparatusId" :id="'result' + index" class="result">
        <SearchResult
          v-if="result.apparatusId"
          :value="result"
          @transferComplete="TransferCompleteSearch"
          @successfulRemoval="successfulRemoval"
        />
      </div>
    </div>
    <div
      class="search-results"
      v-else-if="
        !searchPending &&
          csSearchResults &&
          csSearchResults.length > 0 &&
          (searchCriteriaText == 'Email' || searchCriteriaText == 'LastName')
      "
    >
      <div
        v-for="(result, index) in csSearchResults"
        :key="result.organizationId"
        :id="'result' + index"
        class="result"
      >
        <EmailSearchResult :value="result" @transferComplete="TransferCompleteSearch" />
      </div>
    </div>
    <b-row v-else-if="csSearchResults && csSearchResults.length == 0">
      <b-col cols="auto"><h4>This search returned no results</h4></b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import SearchResult from '@/components/Admin/SearchResult'
import EmailSearchResult from '@/components/Admin/EmailSearchResult'
import * as Constants from '@/constants'

export default {
  components: {
    SearchResult,
    EmailSearchResult
  },
  data() {
    return {
      csSearchResults: null,
      searchCriteria: null
    }
  },
  computed: {
    ...mapState({
      searchResults: state => state.customerSupport.csSearchResults,
      searchPending: state => state.customerSupport.searchPending,
      searchCriteriaText: state => state.customerSupport.searchCriteriaText,
      currentAppConfig: state => state.application.currentAppConfig
    }),
    billingMigrationFeatureFlag() {
      return Constants.BillingMigrationFeatureFlag
    }
  },
  methods: {
    successfulRemoval() {
      this.ClearSearch()
      this.searchCriteria = null
      this.csSearchResults = null
    },
    TransferCompleteSearch(completedTransferData) {
      this.ClearSearchSoft()
      if (completedTransferData.criteria == 'Serial Number') {
        this.SetSearchCriteria('Serial Number')
        this.searchCriteria = completedTransferData.value
        this.Search()
      } else if (completedTransferData.criteria == 'Device ID') {
        this.SetSearchCriteria('Device ID')
        this.searchCriteria = completedTransferData.value
        this.Search()
      } else if (completedTransferData.criteria == 'Email') {
        this.SetSearchCriteria('Email')
        this.searchCriteria = completedTransferData.value
        this.Search()
      }
    },
    ClearSearchSoft() {
      this.$store.dispatch('clearSearchResults')
    },
    ClearSearch() {
      this.$store.dispatch('setSearchCriteria', { criteria: 'Serial Number' })
      this.$store.dispatch('clearSearchResults')
      this.RemoveImpersonation()
    },
    Search() {
      if (this.searchCriteriaText == 'Email') {
        this.SearchByEmail()
      } else if (this.searchCriteriaText == 'LastName') {
        this.SearchByLastName()
      } else if (this.searchCriteriaText == 'Serial Number') {
        this.SearchBySerialNumber()
      } else if (this.searchCriteriaText == 'Device ID') {
        this.SearchByDeviceId()
      } else if (this.searchCriteriaText == 'MDN') {
        this.SearchByMdn()
      } else if (this.searchCriteriaText == 'Mac Address') {
        this.SearchByMac()
      } else if (this.searchCriteriaText == 'Lte Tether Serial') {
        this.SearchByLteSearchLabel()
      } else if (this.searchCriteriaText == 'Ethernet Tether Serial') {
        this.SearchByEthSearchLabel()
      }
    },
    SearchByEmail() {
      this.$store.dispatch('getEmailResults', this.searchCriteria)
    },
    SearchByDeviceId() {
      this.$store.dispatch('getDeviceIdResults', this.searchCriteria)
    },
    SearchBySerialNumber() {
      this.$store.dispatch('getSerialNumberResults', this.searchCriteria)
    },
    SearchByLastName() {
      this.$store.dispatch('getLastNameResults', this.searchCriteria)
    },
    SearchByMdn() {
      this.$store.dispatch('getMdnResults', this.searchCriteria)
    },
    SearchByMac() {
      this.$store.dispatch('getMacAddressResults', this.searchCriteria)
    },
    SearchByLteSearchLabel() {
      this.$store.dispatch('getSearchLabelResults', { searchLabel: this.searchCriteria, deviceType: 'lte' })
    },
    SearchByEthSearchLabel() {
      this.$store.dispatch('getSearchLabelResults', { searchLabel: this.searchCriteria, deviceType: 'eth' })
    },
    SetSearchCriteria(criteria) {
      this.$store.dispatch('setSearchCriteria', { criteria: criteria })
    },
    ImpersonateEmail() {
      this.$store.dispatch('apparatusReset')
      this.$store.dispatch('setEmail', this.searchCriteria)
    },
    RemoveImpersonation() {
      this.$store.dispatch('accountProfileReset')
      this.$store.dispatch('apparatusReset')
      this.$store.dispatch('removeEmail')
      this.$store.dispatch('getUser')
    }
  },
  watch: {
    searchResults: function(newVal, oldVal) {
      if (newVal) {
        this.csSearchResults = _.cloneDeep(this.searchResults)
      }
    }
  },
  watch: {
    searchResults: function(newVal, oldVal) {
      if (newVal) {
        this.csSearchResults = _.cloneDeep(this.searchResults)
      }
    }
  },
  mounted() {
    if (this.searchResults) {
      this.csSearchResults = _.cloneDeep(this.searchResults)
    }
    this.RemoveImpersonation()
    if (!this.currentAppConfig) this.$store.dispatch('getApplicationConfiguration', 'Any')
  }
}
</script>
<style scoped lang="scss">
.dropdown-text {
  font-size: 1rem !important;
  padding-top: 0px !important;
  line-height: 30px !important;
  vertical-align: 0.4em !important;
}
.input-height {
  max-height: 50px !important;
  padding-top: 0px;
  padding-bottom: 0px;
}
.btn {
  font-size: 1rem !important;
  line-height: 30px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.input-group-append {
  max-height: 50px !important;
}
.input-group-prepend {
  font-size: 1rem;
  line-height: 30px;
}
.btn-light {
  border-color: var(--carbon-200);
  border-width: 2px;
}
.search-results {
  display: flex;
  flex-wrap: wrap;
  .result {
    flex-basis: calc(50% - 2%);
    flex-grow: 1;
    flex-shrink: 0;
    margin-bottom: 15px;

    &:nth-child(2n) {
      margin-left: 2%;
    }
  }
}
.btn-link {
  outline: none !important;
  box-shadow: none !important;
  text-decoration: none;
  font-size: 1em;
  padding: 0.2em 0.5em !important;
}
</style>

<style>
.search-in .dropdown-toggle {
  display: flex !important;
  justify-content: center;
  flex-direction: row;
  align-items: center;
}
</style>
