<template>
  <div v-if="!subscriptionLoading" class="mt-2 ml-md-2">
    <div class="row no-gutters mb-2">
      <div class="col">
        <h1 class="mb-0 pb-0" data-test-hook="subPageTitle">
          <i class="far fa-badge-dollar colorOrange mr-1" data-test-hook="subPageTitleIcon" />
          <span data-test-hook="subPageTitleText">{{ $t('xSubscription') }}</span>
        </h1>
      </div>
    </div>
    <!--Paid Subscription && Active Product-->
    <div
      class="card"
      v-if="
        subscription &&
          this.subscription.activeProduct &&
          subscription.activeProduct.length > 0 &&
          !DealerManagedProductId() &&
          subscription.activeProduct[0].productId != FreeProductId &&
          currentAppConfig &&
          currentAppConfig.featureFlags &&
          !currentAppConfig.featureFlags[billingMigrationFeatureFlag]
      "
    >
      <div class="card-body">
        <div class="row">
          <div class="col">
            <h2 data-test-hook="subPageHeadingText">{{ $t('xSubscription') }}</h2>
          </div>
        </div>
        <div class="row mt-1">
          <div class="col">
            <p data-test-hook="subPageSubHeadingText">Current Subscription</p>
            <p data-test-hook="ProductName">
              <strong>{{ subscription.activeProduct[0].productName }}</strong>
            </p>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col">
            <div v-if="!pendingSubscriptionChanges()" data-test-hook="BillingDateTitle">
              {{ $t('xNextBillingDate') }}
              <div class="mt-1">
                <p data-test-hook="NextBillingDate">
                  <strong>
                    {{
                      subscription.activeProduct[0].nextBillingDate
                        ? $moment(subscription.activeProduct[0].nextBillingDate).format($t('xShortDate'))
                        : $moment(String(calculateNextBilling)).format($t('xShortDate'))
                    }}
                  </strong>
                </p>
              </div>
            </div>
            <div v-else-if="!pendingSubscriptionCancellation() && !pendingSubscriptionChanges()">
              <p data-test-hook="PlanEndDate">{{ $t('xPlanEndDate') }}</p>
              <p data-test-hook="NextBillingDate">
                <strong>
                  {{
                    subscription.activeProduct[0].nextBillingDate
                      ? $moment(subscription.activeProduct[0].nextBillingDate).format($t('xShortDate'))
                      : ''
                  }}
                </strong>
              </p>
            </div>
            <p
              v-if="
                subscription.discount[0] != null && !pendingSubscriptionChanges() && !pendingSubscriptionCancellation()
              "
              style="font-size: 14px"
              class="mt-1"
              data-test-hook="subPageBodyText1"
            >
              {{ $t('xYouWillNotRecieveAnInvoice') }}
            </p>
          </div>
        </div>
        <div class="row mt-3" v-if="!pendingSubscriptionChanges()">
          <div class="col" data-test-hook="PlanCostLabel">
            <p data-test-hook="PlanCostLabelText">{{ $t('xSubscriptionCost') }}</p>
            <div data-test-hook="PlanCost">
              <div
                v-if="
                  subscription.discount[0] != null &&
                    $moment().isBefore(
                      $moment(subscription.discount[0].endDate).add(23, 'hours', 59, 'minutes', 59, 'seconds')
                    ) &&
                    $moment().isAfter($moment(subscription.discount[0].startDate))
                "
                class="mt-1"
              >
                <strong>
                  {{ calculatePrice.toFixed(2) | formatCurrency }}
                  <span v-if="subscription.activeProduct[0].durationInMonths == 12" data-test-hook="PlanCostInterval">
                    {{ $t('xPerYear') }} ({{ $t('xRegularly') }}
                    {{ subscription.activeProduct[0].cost.toFixed(2) | formatCurrency }} {{ $t('xPerYear') }})
                  </span>
                  <span v-else data-test-hook="PlanCostInterval"
                    >{{ $t('xPerMonth') }} ({{ $t('xRegularly') }}
                    {{ subscription.activeProduct[0].cost.toFixed(2) | formatCurrency }} {{ $t('xPerMonth') }})</span
                  >
                </strong>
                <p style="font-size: 14px" class="mt-1" data-test-hook="PlanCostIntervalBodyText">
                  {{ subscription.discount[0].percentage }}% {{ $t('xDiscountApplied') }}{{ formattedDate }}
                </p>
              </div>
              <div v-else>
                <strong>
                  {{ this.subscription.activeProduct[0].cost.toFixed(2) | formatCurrency }}
                  <span v-if="subscription.activeProduct[0].durationInMonths == 12" data-test-hook="PlanCostInterval">
                    {{ $t('xPerYear') }}
                  </span>
                  <span v-else data-test-hook="PlanCostInterval">{{ $t('xPerMonth') }}</span>
                </strong>
              </div>
            </div>
          </div>
        </div>
        <div class="row" v-else>
          <div class="col">
            <PendingSubscriptionChangeCard
              :currentSubscription="subscription.activeProduct[0]"
              :pendingSubscription="subscription.pendingProduct[0]"
              @pendingSubscriptionReverted="getSubscription()"
            />
          </div>
        </div>
        <div v-if="!pendingSubscriptionChanges() && !pendingSubscriptionCancellation()" class="row mt-3">
          <div class="col">
            <button
              type="button"
              class="btn btn-warning"
              v-if="
                !DealerManagedProductId() &&
                  subscription.activeProduct[0].productId != FreeProductId &&
                  currentApparatusDetails.apparatusClassification == hsbGeneratorApparatus
              "
              @click="openModal('manage-sub-paid-step-1')"
              :disabled="
                currentAppConfig &&
                  currentAppConfig.featureFlags &&
                  currentAppConfig.featureFlags[billingMigrationFeatureFlag]
              "
              data-test-hook="ManagePlanBtn"
            >
              {{ $t('xManagePlan') }}
            </button>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col">
            <i data-test-hook="AutoRenewMsg">{{ $t('xThisSubscriptionWillAutoRenewShortVersion') }}</i>
          </div>
        </div>

        <div v-if="!pendingSubscriptionChanges() && !pendingSubscriptionCancellation()" class="row mt-3">
          <div class="col">
            <button
              type="button"
              class="btn btn-warning"
              v-if="
                !DealerManagedProductId() &&
                  subscription.activeProduct[0].productId != FreeProductId &&
                  currentApparatusDetails.apparatusClassification == fuelTankApparatusType
              "
              @click="openModal('cancel-paid-sub-step-1')"
              :disabled="
                currentAppConfig &&
                  currentAppConfig.featureFlags &&
                  currentAppConfig.featureFlags[billingMigrationFeatureFlag]
              "
              data-test-hook="subPageCancelSubBtn"
            >
              {{ $t('xCancelSubscription') }}
            </button>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col">
            <hr class="mx-0 px-0" />
          </div>
        </div>
        <div v-if="!pendingSubscriptionCancellation()">
          <div class="row mt-1">
            <div class="col d-flex align-items-center">
              <h2 data-test-hook="PaymentMethodLabel" class="m-0">
                {{ $t('xPaymentMethod') }}
              </h2>
            </div>
            <div
              v-if="existingPaymentInfo && !billingInfoLoading"
              class="cols-auto d-flex align-items-center align-content-flex-end"
            >
              <button
                type="button"
                data-test-hook="EditPaymentMethodBtn"
                class="btn btn-icon-button"
                @click="openModal('update-payment-method')"
              >
                <i class="fas fa-pencil" data-test-hook="EditPaymentMethodBtnIcon" />
              </button>
            </div>
          </div>
          <div class="row" v-if="billingInfoLoading">
            <div class="col">
              <span
                class="spinner-border text-warning"
                style="width: 5rem; height: 5rem;"
                label="Spinning"
                data-test-hook="LoadingSpinner"
              ></span>
            </div>
          </div>
          <div class="row mt-3" v-else-if="existingPaymentInfo">
            <div class="col-12">
              <p data-test-hook="ExistingCardInfo">
                <i class="far fa-credit-card mr-1" data-test-hook="ExistingCardInfoIcon" />
                <span data-test-hook="ExistingCardInfoType">{{ existingPaymentInfo.cardType }}</span> -
                <span data-test-hook="ExistingCardInfoSuffix">{{ existingPaymentInfo.cardSuffix }}</span>
              </p>
              <p data-test-hook="CardExpDate">
                <span data-test-hook="ExistingCardInfoExpireHeading">{{ $t('xExpires') }}</span>
                <span data-test-hook="ExistingCardInfoExpireDate">
                  {{ existingPaymentInfo.expirationMonth + '/' + existingPaymentInfo.expirationYear }}
                </span>
              </p>
            </div>
          </div>
          <div class="row mt-1" v-else>
            <div class="col">
              <button
                type="button"
                class="btn btn-warning"
                @click="openModal('update-payment-method')"
                data-test-hook="AddPaymentMethodBtn"
              >
                {{ 'Add Payment Method' }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Dealer Managed-->
    <div
      class="card"
      v-else-if="
        subscription &&
          subscription.activeProduct &&
          subscription.activeProduct.length > 0 &&
          DealerManagedProductId() &&
          currentAppConfig &&
          currentAppConfig.featureFlags &&
          !currentAppConfig.featureFlags[billingMigrationFeatureFlag]
      "
    >
      <div class="card-body">
        <div class="row">
          <div class="col">
            <p data-test-hook="subPageSubHeadingText">Current Subscription</p>
            <p>
              <strong class="colorBlack" data-test-hook="ProductName">{{
                subscription.activeProduct[0].productName
              }}</strong>
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col" data-test-hook="DealerManagedMsg">
            {{ $t('xDealerManagedSubMessage') }}
          </div>
        </div>
      </div>
    </div>
    <!--Free Subscription-->
    <div
      class="card"
      v-else-if="
        subscription &&
          subscription.activeProduct &&
          subscription.activeProduct.length > 0 &&
          subscription.activeProduct[0].productId == FreeProductId &&
          currentAppConfig &&
          currentAppConfig.featureFlags &&
          !currentAppConfig.featureFlags[billingMigrationFeatureFlag]
      "
    >
      <div class="card-body">
        <div class="row">
          <div class="col">
            <p data-test-hook="subPageSubHeadingText">Current Subscription</p>
            <p data-test-hook="ProductName">
              <strong>{{ subscription.activeProduct[0].productName }}</strong>
            </p>
          </div>
        </div>
        <div class="row mt-2" v-if="canHavePaidSubscription(accountData)">
          <div class="col">
            <b-button variant="primary" @click="openModal('upgrade-free-sub-step-1')" data-test-hook="UpgradeBtn">
              {{ $t('xManagePlan') }}
            </b-button>
          </div>
        </div>
      </div>
    </div>

    <div v-else class="card-shadow card">
      <div class="row">
        <div class="col">
          <h4 class="colorBlack">{{ $t('xSubscriptionInfoUnavailable') }}</h4>
        </div>
      </div>
    </div>

    <Modal modalId="update-payment-method" size="modal-md" @hidden="closePaymentModal()">
      <ModalHeader :title="$t('xUpdatePaymentMethod')" icon="fal fa-credit-card colorOrange mr-1">
        <div class="cols-auto">
          <button
            type="button"
            class="btn btn-icon-button btn-icon-button--gray"
            @click="closePaymentModal()"
            data-test-hook="CloseModalBtn"
          >
            <i class="fas fa-times" />
          </button>
        </div>
      </ModalHeader>
      <ModalBody>
        <div role="alert" v-if="showValidationBanner" class="alert alert-danger" data-test-hook="ValidationAlert">
          <p>{{ $t('xExperiencedTheseErrors') }}</p>
          <ul v-for="(message, index) in errorMessages" :key="index">
            <li :id="index" data-test-hook="ValidationMsg">
              {{ message }}
            </li>
          </ul>
        </div>
        <div class="row">
          <div class="col-12">
            <h3>
              <strong data-test-hook="CcInfoHeader">{{ $t('xCreditCardInfo') }}</strong>
              <span class="body-text-color">*</span>
            </h3>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col">
            <i class="asterisk-text" data-test-hook="UpdatePaymentWarningMsg">
              {{ $t('xUpdatingPaymentWarning') }}
            </i>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="form-group" id="credit-card-number">
              <input
                class="form-control"
                id="creditCardNumber"
                v-model="$v.creditCardNumber.$model"
                maxlength="16"
                type="text"
                :class="status($v.creditCardNumber)"
                v-mask="'################'"
                :placeholder="$t('xCreditCardNumber')"
                aria-describedby="card-number-invalid"
                data-test-hook="CcNumber"
              />
              <div class="invalid-feedback" id="card-number-invalid" data-test-hook="invalidCcNo">
                {{ $t('xCCFieldValidation') }}
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col col-lg-6 col-md-6 col-sm-12">
            <div class="form-group" id="expiry-date-month">
              <input
                class="form-control"
                id="xNm"
                v-model="$v.expirationMonth.$model"
                maxlength="2"
                type="text"
                :class="status($v.expirationMonth)"
                v-mask="'##'"
                :placeholder="$t('xMm')"
                aria-describedby="expiry-month-invalid"
                data-test-hook="CcExpireMonth"
              />
              <div class="invalid-feedback" id="expiry-month-invalid" data-test-hook="invalidCcMth">
                {{ $t('xExpirationMonthValidation') }}
              </div>
            </div>
          </div>
          <div class="col col-lg-6 col-md-t6 col-sm-12">
            <div class="form-group" id="expiry-date-year">
              <input
                class="form-control"
                v-model="$v.expirationYear.$model"
                type="text"
                :placeholder="$t('xYy')"
                maxlength="2"
                v-mask="'##'"
                :class="status($v.expirationYear)"
                aria-describedby="expiry-year-invalid"
                data-test-hook="CcExpireYear"
              />
              <div class="invalid-feedback" id="expiry-year-invalid" data-test-hook="invalidCcYear">
                {{ $t('xExpirationYearValidation') }}
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="input-group" id="cvv">
              <input
                class="form-control"
                v-model="$v.cvv.$model"
                type="text"
                v-mask="'####'"
                maxlength="4"
                :placeholder="$t('xCvv')"
                :class="status($v.cvv)"
                aria-describedby="cvv-invalid"
                data-test-hook="CcCvv"
              />
              <div class="input-group-append">
                <div class="input-group-text">
                  <img :height="20" :src="require('@/assets/images/3-digit-code.svg')" data-test-hook="ccCvvImage" />
                </div>
              </div>
              <div class="invalid-feedback" id="cvv-invalid" data-test-hook="invalidCcCvv">
                {{ $t('xCVVValidation') }}
              </div>
            </div>
          </div>
        </div>
        <div class="mt-4" v-if="accountData">
          <div class="row mb-2">
            <div class="col">
              <div class="font-weight-bold custom-control custom-checkbox">
                <input
                  id="useExistingBillingInformation"
                  type="checkbox"
                  class="custom-control-input"
                  v-model="useExistingBillingInformation"
                  :disabled="savingAccountHolder"
                  data-test-hook="UseCurrentBillingAddressChkBox"
                />
                <label
                  class="custom-control-label"
                  for="useExistingBillingInformation"
                  data-test-hook="useCurrBillingInfoText"
                >
                  {{ $t('xUseCurrentBillingInfo') }}
                </label>
              </div>
            </div>
          </div>
          <div class="row" v-if="useExistingBillingInformation">
            <div class="col-12" data-test-hook="ExistingBillingName">
              {{ accountData.firstName }} {{ accountData.lastName }}
            </div>
            <div class="col-12" data-test-hook="ExistingBillingAddress">
              {{ DisplayAddress(accountData.address) }}
            </div>
          </div>
          <div v-if="!useExistingBillingInformation">
            <div class="row">
              <div class="col-12">
                <fieldset id="firstName-group" class="form-group">
                  <div>
                    <input
                      class="form-control"
                      type="text"
                      id="firstName"
                      v-model="accountData.firstName"
                      data-test-hook="firstName"
                    />
                  </div>
                </fieldset>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <fieldset class="form-group" id="lastName-group">
                  <div>
                    <input
                      class="form-control"
                      id="lastName"
                      v-model="accountData.lastName"
                      type="text"
                      data-test-hook="lastName"
                    />
                  </div>
                </fieldset>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <TypeAheadAddress
                  ref="typeAheadAddressSubscription"
                  :currentAddress="accountData.address"
                  @newAddress="setNewBillingAddress"
                />
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <button
          type="button"
          class="btn btn-outline-warning"
          @click="closeModal('update-payment-method'), closePaymentModal()"
          data-test-hook="CancelBtn"
        >
          {{ $t('xCancel') }}
        </button>
        <button
          type="button"
          v-if="useExistingBillingInformation"
          class="btn btn-primary"
          @click="payForProduct()"
          :disabled="!canPay()"
          data-test-hook="SaveChangesBtn"
        >
          {{ $t('xSave') }}
          <span class="spinner-borderr ml-2" v-if="paying"></span>
        </button>
        <button
          v-else
          type="button"
          class="btn btn-warning"
          @click="saveAccountHolder(), (savingAccountHolder = true)"
          :disabled="savingAccountHolder"
          data-test-hook="SaveChangesBtn"
        >
          {{ $t('xConfirmBillingInfo') }}
          <span class="spinner-border ml-2" v-if="savingAccountHolder"></span>
        </button>
      </ModalFooter>
    </Modal>

    <ManageSubscriptionModals
      v-if="
        subscription &&
          subscription.activeProduct &&
          existingPaymentInfo &&
          !DealerManagedProductId() &&
          subscription.activeProduct[0].productId != FreeProductId
      "
      @success="getSubscription()"
      @cancelSubscription="openModal('cancel-paid-sub-step-1')"
      :currentSubscription="subscription.activeProduct"
      :paymentInfo="existingPaymentInfo"
      :coupon="subscription.discount"
    />
    <UpgradeFreeSubscriptionModals
      v-if="subscription && subscription.activeProduct && subscription.activeProduct[0].productId == FreeProductId"
      @success="upgradeFreeSubscription()"
      @cancelSubscription="openModal('cancel-paid-sub-step-1')"
      :currentSubscription="subscription.activeProduct"
      :paymentInfo="existingPaymentInfo"
    />
    <CancelSubscriptionModals :currentSubscription="subscription.activeProduct" @success="getSubscription()" />
  </div>
  <div v-else class="d-flex justify-content-center mt-5 pt-4">
    <span class="spinner-border text-warning" style="width: 5rem; height: 5rem;" label="Spinning"></span>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { required, requiredIf, minLength, maxLength, email, numeric } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'
import * as Constants from '@/constants'
import TypeAheadAddress from '@/components/TypeAheadAddress'
import ManageSubscriptionModals from '@/components/ApparatusDetails/ManageSubscriptionModals'
import UpgradeFreeSubscriptionModals from '@/components/ApparatusDetails/UpgradeFreeSubscriptionModals.vue'
import PendingSubscriptionChangeCard from '@/components/ApparatusDetails/PendingSubscriptionChangeCard.vue'
import CancelSubscriptionModals from '@/components/ApparatusDetails/CancelSubscriptionModals'

import { max, min } from 'moment'
import { getSubscriptionClass } from '../../utils/mixins'

const monthInRange = (value, vm) => value >= 1 && value <= 12

export default {
  components: {
    TypeAheadAddress,
    ManageSubscriptionModals,
    UpgradeFreeSubscriptionModals,
    PendingSubscriptionChangeCard,
    CancelSubscriptionModals
  },
  mixins: [validationMixin],
  data() {
    return {
      subscription: null,
      subscriptionLoading: true,
      billingInfoLoading: true,
      existingPaymentInfo: null,
      paying: false,
      creditCardNumber: null,
      expirationMonth: null,
      expirationYear: null,
      cvv: null,
      showValidationBanner: false,
      FreeProductId: Constants.FreeWifiEthProductId,
      accountData: null,
      useExistingBillingInformation: true,
      savingAccountHolder: false,
      errorMessages: []
    }
  },
  validations: {
    cvv: {
      required,
      minLength: minLength(3),
      maxLength: maxLength(4),
      numeric
    },
    expirationMonth: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(2),
      numeric,
      monthInRange
    },
    expirationYear: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(2),
      numeric
    },
    creditCardNumber: {
      required,
      min: minLength(15),
      max: maxLength(16),
      minLength: minLength(15),
      maxLength: maxLength(16),
      numeric
    }
  },
  computed: {
    ...mapState({
      accountProfile: state => state.account.accountProfile,
      currentApparatusDetails: state => state.apparatus.currentApparatusDetails,
      currentAppConfig: state => state.application.currentAppConfig,
      isImpersonating: state => state.auth.isImpersonating
    }),
    billingMigrationFeatureFlag() {
      return Constants.BillingMigrationFeatureFlag
    },
    formattedDate() {
      const [year, month, day] = this.subscription.discount[0].endDate.split('-')
      const formatted = `${parseInt(month)}/${parseInt(day)}/${year}`
      return formatted
    },
    calculatePrice() {
      if (this.subscription.activeProduct[0].productId != this.FreeProductId) {
        return (
          this.subscription.activeProduct[0].cost -
          this.subscription.activeProduct[0].cost * (this.subscription.discount[0].percentage / 100)
        )
      } else {
        return 0
      }
    },
    hsbGeneratorApparatus() {
      return Constants.HsbApparatusType
    },
    fuelTankApparatusType() {
      return Constants.FuelTankApparatusType
    },
    calculateNextBilling() {
      const endDate = new Date(this.subscription.activeProduct[0].startDate)
      endDate.setMonth(endDate.getMonth() + 1)
      return endDate
    }
  },
  methods: {
    emitCancelFlow() {
      this.$emit('cancelSubscription')
    },
    DealerManagedProductId() {
      if (
        this.subscription &&
        this.subscription.activeProduct &&
        this.subscription.activeProduct[0] &&
        this.subscription.activeProduct[0].productId
      ) {
        if (
          this.subscription.activeProduct[0].productId == Constants.DealerManagedProductId ||
          this.subscription.activeProduct[0].productId == Constants.TankUtilityDealerManagedProductId
        )
          return true
      }
      return false
    },
    pendingSubscriptionChanges() {
      if (this.subscription) {
        if (this.subscription.activeProduct && this.subscription.activeProduct.length > 0) {
          if (
            this.subscription.activeProduct[0].status == Constants.PendingCancellationStatus ||
            this.subscription.activeProduct[0].status == Constants.PendingSuspensionStatus
          )
            return true
        }
        if (this.subscription.pendingProduct && this.subscription.pendingProduct.length > 0) return true
      }
      return false
    },
    pendingSubscriptionCancellation() {
      if (this.subscription) {
        if (this.subscription.activeProduct && this.subscription.activeProduct.length > 0) {
          if (this.subscription.activeProduct[0].status == Constants.PendingCancellationStatus) return true
        }
      }
      return false
    },
    setNewBillingAddress(newAddress) {
      this.accountData.address = newAddress
      if (this.$refs.typeAheadAddressSubscription.$v.$anyError) {
        this.addressSaveDisabled = true
      } else {
        this.addressSaveDisabled = false
      }
    },
    saveAccountHolder() {
      this.savingAccountHolder = true
      this.$store
        .dispatch('updateAccountHolder', {
          firstName: this.accountData.firstName,
          lastName: this.accountData.lastName,
          organizationAddress: {
            ...this.accountData.address,
            organizationId: this.accountData.organizationId
          }
        })
        .then(response => {
          if (!this.isImpersonating) this.$store.dispatch('getUser')
          else this.$store.dispatch('getImpersonatedUser')
          this.$store.dispatch('getAccountProfile')
          this.timeTheAccountChange()
        })
        .catch(error => {
          this.$store.dispatch('displayToast', {
            title: this.$t('xError'),
            message: this.$t('xFailedToUpdateBillingNameAndAddress'),
            variant: 'danger'
          })
        })
    },
    timeTheAccountChange() {
      setTimeout(
        function() {
          this.savingAccountHolder = false
          this.useExistingBillingInformation = true
        }.bind(this),
        5000
      )
    },
    payForProduct() {
      this.paying = true
      let addPaymentModel = {
        organizationId: this.accountProfile.organizationId,
        cardNumber: this.creditCardNumber,
        cvv: this.cvv,
        expirationMonth: this.expirationMonth,
        expirationYear: '20' + this.expirationYear
      }
      this.$store
        .dispatch('addBillingAccountPaymentMethod', addPaymentModel)
        .then(response => {
          this.paying = false
          this.closeModal('update-payment-method')
          this.closePaymentModal()
          this.$store
            .dispatch('getActivePaymentMethod', this.accountProfile.organizationId)
            .then(paymentResponse => {
              this.existingPaymentInfo = paymentResponse.data
              this.billingInfoLoading = false
            })
            .catch(error => {
              this.existingPaymentInfo = null
              this.billingInfoLoading = false
            })
        })
        .catch(error => {
          this.paying = false
          this.errorMessages = []
          if (error.response.data.errors) {
            if (error.response.data.errors.CardNumber || error.response.data.errors.cardNumber) {
              this.showValidationBanner = true
              this.errorMessages.push(this.$t('xCCIsInvalid'))
            }
            if (error.response.data.errors.Cvv || error.response.data.errors.cvv) {
              this.showValidationBanner = true
              this.errorMessages.push(this.$t('xCvvInvalid'))
            }
            if (error.response.data.errors.ExpirationMonth || error.response.data.errors.expirationMonth) {
              this.showValidationBanner = true
              this.errorMessages.push(this.$t('xExpirationMonthInvalid'))
            }
            if (error.response.data.errors.ExpirationYear || error.response.data.errors.expirationYear) {
              this.showValidationBanner = true
              this.errorMessages.push(this.$t('xExpirationYearInvalid'))
            }
            if (error.response.data.errors.billing || error.response.data.errors.Billing) {
              this.$store.dispatch('displayToast', {
                title: this.$t('xError'),
                message: error.response.data.errors.billing
                  ? error.response.data.errors.billing
                  : error.data.response.errors.Billing,
                variant: 'danger'
              })
            }
          } else {
            this.$store.dispatch('displayToast', {
              title: this.$t('xError'),
              message: this.$t('xFailedToAddPaymentMethod'),
              variant: 'danger'
            })
          }
        })
    },
    closePaymentModal() {
      this.creditCardNumber = null
      this.expirationMonth = null
      this.expirationYear = null
      this.cvv = null
      this.paying = false
      this.showValidationBanner = false
      this.errorMessages = []
      this.$v.$reset()
      this.closeModal('update-payment-method')
    },
    canPay() {
      if (!this.creditCardNumber || !this.cvv || !this.expirationMonth || !this.expirationYear) {
        return false
      }
      if (!this.$v.$anyError && !this.paying) {
        return true
      } else return false
    },
    async upgradeFreeSubscription() {
      this.subscriptionLoading = true
      await this.getSubscription()
      if (this.subscription.discount[0] != null) {
        this.$store.dispatch('displayToast', {
          message: this.$t('xYourSubscriptionHasBeenSuccessfullyUpdated'),
          variant: 'success'
        })
      } else {
        this.$store.dispatch('displayToast', {
          message: this.$t('xYourSubscriptionPlanHasBeenSuccessfullyUpdated'),
          variant: 'success'
        })
      }
    },
    async getSubscription() {
      await this.$store
        .dispatch('getApparatusSubscription', {
          organizationId: this.accountProfile.organizationId,
          apparatusId: this.$route.params.apparatusId
        })
        .then(response => {
          this.subscription = response.data
          this.subscriptionLoading = false
        })
        .catch(error => {
          this.subscriptionLoading = false
          this.$store.dispatch('displayToast', {
            title: this.$t('xError'),
            message: this.$t('xNoSubscriptionsFoundForThisGenerator'),
            variant: 'danger'
          })
        })
    }
  },
  watch: {
    accountProfile: function(newVal, oldVal) {
      if (newVal) {
        this.accountData = _.cloneDeep(this.accountProfile)
        if (
          this.currentAppConfig &&
          this.currentAppConfig.featureFlags &&
          this.currentAppConfig.featureFlags[this.billingMigrationFeatureFlag]
        ) {
          this.getSubscription()
        } else {
          this.$store
            .dispatch('getActivePaymentMethod', this.accountProfile.organizationId)
            .then(response => {
              this.existingPaymentInfo = response.data
              this.billingInfoLoading = false
            })
            .catch(error => {
              this.existingPaymentInfo = null
              this.billingInfoLoading = false
            })
          this.getSubscription()
        }
      }
    }
  },
  mounted() {
    if (this.accountProfile) {
      this.accountData = _.cloneDeep(this.accountProfile)
      if (
        this.currentAppConfig &&
        this.currentAppConfig.featureFlags &&
        this.currentAppConfig.featureFlags[this.billingMigrationFeatureFlag]
      ) {
        this.getSubscription()
      } else {
        this.$store
          .dispatch('getActivePaymentMethod', this.accountProfile.organizationId)
          .then(response => {
            this.existingPaymentInfo = response.data
            this.billingInfoLoading = false
          })
          .catch(error => {
            this.existingPaymentInfo = null
            this.billingInfoLoading = false
          })
        this.getSubscription()
      }
    } else {
      this.$store.dispatch('getAccountProfile')
    }
  }
}
</script>
