<template>
  <div>
    <i :class="statusIcon"></i>
    <i
      v-if="showWarning && isConnected"
      class="fa-solid fa-triangle-exclamation statusIsWarning float-right fa-sm pl-1"
      :class="inverse ? 'colorWhite' : null"
      data-test-hook="StatusIcon"
    ></i>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import * as Constants from '@/constants'

export default {
  props: ['apparatusStatus', 'isConnected', 'showWarning', 'isConnecting', 'deviceType', 'inverse', 'small'],
  computed: {
    statusIcon() {
      let iconClass = 'fas fa-fw '
      if (this.isConnecting) iconClass += 'fa-sync'
      else if (this.apparatusStatus == Constants.StatusIsUnknown) iconClass += 'fa-question-circle statusIsUnknown'
      else if (!this.isConnected || this.apparatusStatus == Constants.StatusIsDisconnected) {
        switch (this.deviceType) {
          case 'wifi':
            iconClass += 'fa-wifi-slash statusIsDisconnected'
          case 'eth':
          case 'lte':
          case 'cdma':
            iconClass += 'fa-signal-alt-slash statusIsDisconnected'
          default:
            iconClass += 'fa-comment-slash statusIsDisconnected'
        }
      } else if (this.apparatusStatus == Constants.StatusIsReady) iconClass += 'fa-check-circle statusIsReady'
      else if (this.apparatusStatus == Constants.StatusIsRunning) iconClass += 'fa-circle-bolt statusIsRunning'
      else if (this.apparatusStatus == Constants.StatusIsExercising)
        iconClass += 'fas fa-arrows-spin statusIsExercising'
      else if (this.apparatusStatus == Constants.StatusIsWarning) iconClass += 'fa-exclamation-triangle statusIsWarning'
      else if (this.apparatusStatus == Constants.StatusIsSwitchedOff) return 'fa-regular fa-circle statusIsSwitchedOff'
      else if (this.apparatusStatus == Constants.StatusIsFault) iconClass += 'fa-octagon-exclamation statusIsFault'
      else if (this.apparatusStatus == Constants.StatusIsCommsIssue)
        iconClass += 'fa-comment-exclamation statusIsCommsIssue'
      else iconClass += 'fa-question-circle statusIsUnknown'
      if (this.inverse && (!this.isConnected || this.isConnecting)) iconClass += ' colorBlack'
      else if (
        (this.inverse &&
          (this.apparatusStatus == Constants.StatusIsWarning ||
            this.apparatusStatus == Constants.StatusIsUnknown ||
            this.apparatusStatus == Constants.StatusIsCommsIssue ||
            this.apparatusStatus == Constants.StatusIsDisconnected)) ||
        !this.isConnected ||
        this.isConnecting
      )
        iconClass += ' colorBlack'
      else if (this.inverse) iconClass += ' colorWhite'
      return iconClass
    },
    statusIsExercising() {
      return Constants.StatusIsExercising
    },
    statusIsRunning() {
      return Constants.StatusIsRunning
    }
  }
}
</script>

<style scoped lang="scss"></style>
