var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.messages
        ? _c(
            "b-card",
            { staticClass: "h-100" },
            [
              _c(
                "b-row",
                { staticClass: "text-center" },
                [
                  _c("b-col", [
                    _c(
                      "span",
                      {
                        staticClass: "read-message-icon",
                        attrs: { "data-test-hook": "msgDtlsBodyIcon" }
                      },
                      [_c("i", { staticClass: "far fa-envelope-open" })]
                    )
                  ])
                ],
                1
              ),
              _c(
                "b-row",
                { staticClass: "text-center mt-3" },
                [
                  _c("b-col", [
                    _c(
                      "p",
                      { attrs: { "data-test-hook": "msgDtlsBodyText" } },
                      [_vm._v(_vm._s(_vm.$t("xSelectItemToRead")))]
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        : _c(
            "b-card",
            {
              attrs: {
                "header-bg-variant": "white",
                "body-class": "no-padding"
              },
              scopedSlots: _vm._u([
                {
                  key: "header",
                  fn: function() {
                    return [
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { staticClass: "d-flex align-items-center" },
                            [
                              _vm.accountMessages
                                ? _c("span", [
                                    _c(
                                      "h2",
                                      {
                                        staticClass: "mb-0",
                                        attrs: {
                                          "data-test-hook":
                                            "msgDtlsAccountMessagesHeader"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("xAccountMessages"))
                                        )
                                      ]
                                    )
                                  ])
                                : _vm.appUpdateMessages
                                ? _c("span", [
                                    _c(
                                      "h2",
                                      {
                                        staticClass: "mb-0",
                                        attrs: {
                                          "data-test-hook":
                                            "msgDtlsAppUpdatesHeader"
                                        }
                                      },
                                      [_vm._v(_vm._s(_vm.$t("xInbox")))]
                                    )
                                  ])
                                : _vm._e()
                            ]
                          ),
                          _c(
                            "b-col",
                            { attrs: { cols: "auto" } },
                            [
                              _c(
                                "b-button",
                                {
                                  attrs: {
                                    variant: "icon-button",
                                    "data-test-hook": "msgDtlsDeleteBtn"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.openDeleteConfirmationModal()
                                    }
                                  }
                                },
                                [_c("i", { staticClass: "fas fa-trash-alt" })]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  },
                  proxy: true
                }
              ])
            },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { staticClass: "my-3 align-with-header" },
                    [
                      _vm.accountMessages &&
                      _vm.documentsStatementsPageEnabled(_vm.currentAppConfig)
                        ? _c(
                            "b-link",
                            {
                              attrs: {
                                to: {
                                  name: "accountSettings",
                                  params: { statementTabActive: true }
                                },
                                "data-test-hook": "msgDtlsViewStatementsBtn"
                              }
                            },
                            [
                              _c("i", { staticClass: "fas fa-file-alt" }),
                              _vm._v(
                                " " + _vm._s(_vm.$t("xViewStatements")) + " "
                              )
                            ]
                          )
                        : _vm.appUpdateMessages
                        ? _c(
                            "b-link",
                            {
                              attrs: {
                                href: "https://support.generac.com/mobilelink",
                                target: "_blank",
                                "data-test-hook": "msgDtlsSupportCenterBtn"
                              }
                            },
                            [
                              _c("i", {
                                staticClass: "fas fa-external-link-alt"
                              }),
                              _vm._v(
                                " " + _vm._s(_vm.$t("xSupportCenter")) + " "
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _c("hr", { staticClass: "my-0 py-0" }),
              _vm._l(_vm.messages, function(message, index) {
                return _c(
                  "b-list-group",
                  {
                    key: index,
                    staticClass: "mt-0 pt-0",
                    attrs: { flush: "" }
                  },
                  [
                    index != _vm.lastReadMessageIndex && index != 0
                      ? _c("div", { staticClass: "message-break" })
                      : index == _vm.lastReadMessageIndex
                      ? _c(
                          "div",
                          {
                            attrs: { "data-test-hook": "msgDtlsLastReadText" }
                          },
                          [
                            _c(
                              "b-row",
                              {
                                staticClass: "my-0 py-0 page-break",
                                attrs: { "no-gutters": "", "align-v": "center" }
                              },
                              [
                                _c("b-col", { staticClass: "divider" }),
                                _c(
                                  "b-col",
                                  {
                                    staticClass: "mx-2 text-center",
                                    attrs: { cols: "auto" }
                                  },
                                  [_vm._v(" Last read ")]
                                ),
                                _c("b-col", { staticClass: "divider" })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "b-list-group-item",
                      [
                        _c(
                          "b-row",
                          { staticClass: "mt-2 mb-1" },
                          [
                            _c("b-col", { staticClass: "text-small" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.displayShortDateTime(
                                      message.createdDate
                                    )
                                  ) +
                                  " "
                              )
                            ])
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          { staticClass: "mb-2" },
                          [
                            _c("b-col", [
                              _c("h4", [_vm._v(_vm._s(message.title))])
                            ])
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          [
                            message.textType == 1
                              ? _c(
                                  "b-col",
                                  [
                                    _c("VueMarkdown", {
                                      attrs: { source: message.body }
                                    })
                                  ],
                                  1
                                )
                              : _c("b-col", [
                                  _c("p", [_vm._v(_vm._s(message.body))])
                                ])
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              })
            ],
            2
          ),
      _vm.messages
        ? _c("DeleteMessagesModal", { attrs: { messages: _vm.messages } })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }