import Vue from 'vue'
import i18n from '@/lang/'

Vue.filter('uppercase', function(stringToUppercase) {
  if (stringToUppercase != null) {
    return stringToUppercase.toUpperCase()
  }
})

Vue.filter('lowercase', function(stringToLowercase) {
  if (stringToLowercase != null) {
    return stringToLowercase.toLowerCase()
  }
})

Vue.filter('phone', function(phone) {
  if (phone != null) {
    return phone.replace(/[^0-9]/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
  }
})

Vue.filter('formatLabel', function(stringToLabel) {
  if (!stringToLabel) return 'N/A'
  return i18n.t(stringToLabel) + ':'
})

Vue.filter('formatLabelNoTranslation', function(stringToLabel) {
  if (!stringToLabel) return 'N/A'
  return stringToLabel + ':'
})

Vue.filter('formatCurrency', function(value) {
  if (!value) return 'N/A'
  return Intl.NumberFormat(i18n.t('xLocale'), {
    style: 'currency',
    currency: i18n.t('xCurrency')
  }).format(value)
})

Vue.filter('weatherIcon', function(iconCode) {
  if (iconCode == 1) return 'fal fa-sun'
  if (iconCode == 2) return 'fal fa-sun-cloud'
  if (iconCode == 3) return 'fal fa-cloud-sun'
  if (iconCode == 4) return 'fal fa-clouds-sun' //No difference from Mostly Cloudy
  if (iconCode == 5) return 'fal fa-sun-haze'
  if (iconCode == 6) return 'fal fa-clouds-sun' //No difference from Intermittent Clouds
  if (iconCode == 7) return 'fal fa-cloud'
  if (iconCode == 8) return 'fal fa-clouds'
  //No iconCode 9 & 10
  if (iconCode == 11) return 'fal fa-fog'
  if (iconCode == 12) return 'fal fa-cloud-showers' //No showers icon
  if (iconCode == 13) return 'fal fa-cloud-showers-heavy'
  if (iconCode == 14) return 'fal fa-cloud-sun-rain'
  if (iconCode == 15) return 'fal fa-thunderstorm'
  if (iconCode == 16) return 'fal fa-thunderstorm-sun'
  if (iconCode == 17) return 'fal fa-thunderstorm-sun'
  if (iconCode == 18) return 'fal fa-cloud-rain'
  if (iconCode == 19) return 'fal fa-snowflake'
  if (iconCode == 20) return 'fal fa-cloud-snow'
  if (iconCode == 21) return 'fal fa-cloud-snow'
  if (iconCode == 22) return 'fal fa-snowflakes'
  if (iconCode == 23) return 'fal fa-cloud-snow' //No Mostly Cloudy with Snow icon
  if (iconCode == 24) return 'fal fa-icicles' //No ice icon
  if (iconCode == 25) return 'fal fa-cloud-hail'
  if (iconCode == 26) return 'fal fa-cloud-hail-mixed' //No freezing Rain icon
  //No iconCode 27 & 28
  if (iconCode == 29) return 'fal fa-cloud-sleet' //No Rain and Snow icon
  if (iconCode == 30) return 'fal fa-thermometer-full'
  if (iconCode == 31) return 'fal fa-thermometer-empty'
  if (iconCode == 32) return 'fal fa-wind'
  if (iconCode == 33) return 'fal fa-moon'
  if (iconCode == 34) return 'fal fa-moon-cloud'
  if (iconCode == 35) return 'fal fa-cloud-moon'
  if (iconCode == 36) return 'fal fa-clouds-moon'
  if (iconCode == 37) return 'fal fa-smog' //No Hazy Moonlight icon
  if (iconCode == 38) return 'fal fa-clouds-moon'
  if (iconCode == 39) return 'fal fa-cloud-moon-rain'
  if (iconCode == 40) return 'fal fa-cloud-moon-rain'
  if (iconCode == 41) return 'fal fa-thunderstorm-moon'
  if (iconCode == 42) return 'fal fa-thunderstorm-moon'
  if (iconCode == 43) return 'fal fa-cloud-snow'
  if (iconCode == 44) return 'fal fa-cloud-snow'
})
