<template>
  <div class="row">
    <div class="col-12 col-md-6">
      <h1>Device Connection</h1>
      <div class="mb-3">
        <p>{{ 'Connection Status' | formatLabelNoTranslation }}</p>
        <p v-if="currentStatus.isConnected" class="font-weight-bold pb-2">Online</p>
        <p v-else class="font-weight-bold pb-2">Offline</p>
      </div>
      <div class="mb-3">
        <p>{{ 'Signal Strength' | formatLabelNoTranslation }}</p>
        <p class="font-weight-bold pb-2">{{ currentStatus.signalStrength || 'N/A' }}</p>
      </div>
      <div class="mb-3">
        <p>{{ 'Device Identifier' | formatLabelNoTranslation }}</p>
        <div class="d-flex align-items-center">
          <p id="deviceIdentifier" class="font-weight-bold">
            {{ currentStatus.deviceIdentifier || 'N/A' }}
          </p>
          <button
            class="btn ml-2 btn-light btn-sm"
            @click="copyToClipboard(currentStatus.deviceIdentifier)"
            data-test-hook="copy-device-id-button-connections-and-subs-tab"
          >
            <i class="far fa-copy" />
          </button>
        </div>
      </div>
      <div class="mb-3">
        <p>{{ 'Last Seen Date' | formatLabelNoTranslation }}</p>
        <p class="font-weight-bold">{{ displayShortDateTimeWithTZ(currentStatus.lastSeenDate) || 'N/A' }}</p>
      </div>
    </div>
    <div v-if="currentSubscription && currentSubscription[0] && !BillingMaintenanceEnabled()" class="col-12 col-md-6">
      <h1>Current Subscription</h1>
      <div class="mb-3">
        <p>{{ 'Subscription' | formatLabelNoTranslation }}</p>
        <p :class="getSubscriptionClass(currentSubscription)" class="font-weight-bold">
          <strong> {{ currentSubscription[0].productName }}</strong>
        </p>
      </div>
      <div class="mb-3">
        <p>{{ 'Cost' | formatLabelNoTranslation }}</p>
        <p class="font-weight-bold">{{ currentSubscription[0].cost.toFixed(2) | formatCurrency }}</p>
      </div>
      <div class="mb-3">
        <p>{{ 'Start Date' | formatLabelNoTranslation }}</p>
        <p class="font-weight-bold">{{ currentSubscription[0].startDate }}</p>
      </div>
      <div class="mb-3">
        <p>{{ 'Next Billing Date' | formatLabelNoTranslation }}</p>
        <p class="font-weight-bold">{{ currentSubscription[0].nextBillingDate }}</p>
      </div>
      <div class="mb-3" v-if="billingAccountId">
        <p>{{ 'Billing Account #' | formatLabelNoTranslation }}</p>
        <p class="font-weight-bold">{{ billingAccountId }}</p>
      </div>
      <div v-else-if="BillingMaintenanceEnabled()" class="col-12 col-md-6">
        <h1>Current Subscription</h1>
        <div>
          <h4 class="inactive-subscription">
            Some Mobile Link features are currently disabled due to website maintenance. Thank you for your patience,
            please check back soon!
          </h4>
        </div>
      </div>
      <div v-else title="Current Subscription" class="col-12 col-md-6">
        <h4 class="inactive-subscription">Subscription not found</h4>
      </div>
      <div title="Dealer Info" class="col-12 col-md-6">
        <p>{{ 'Dealer ID' | formatLabelNoTranslation }}</p>
        <div class="d-flex align-items-center">
          <p class="font-weight-bold">
            {{ dealerInfo && dealerInfo.externalId ? dealerInfo.externalId : 'N/A' }}
          </p>
          <button
            id="btnCopyDealerIdToClipboard"
            class="btn ml-2 btn-light btn-sm"
            @click="copyToClipboard(dealerInfo && dealerInfo.externalId ? dealerInfo.externalId.split('-')[1] : 'N/A')"
            data-test-hook="copy-dealer-id-button-connection-and-subs-tab"
          >
            <i class="far fa-copy" />
          </button>
        </div>
      </div>
      <div class="mb-3">
        <p>{{ 'Dealer Name' | formatLabelNoTranslation }}</p>
        <p class="font-weight-bold pb-2">{{ dealerInfo && dealerInfo.name ? dealerInfo.name : 'N/A' }}</p>
      </div>
      <div class="mb-3">
        <p>{{ 'Location' | formatLabelNoTranslation }}</p>
        <p class="font-weight-bold">
          {{ dealerInfo && dealerInfo.topLineAddress ? dealerInfo.topLineAddress : 'N/A' }}
        </p>
        <p class="font-weight-bold pb-2">
          {{ dealerInfo && dealerInfo.bottomLineAddress ? dealerInfo.bottomLineAddress : 'N/A' }}
        </p>
      </div>
      <div class="mb-3">
        <p>{{ 'Email' | formatLabelNoTranslation }}</p>
        <p class="font-weight-bold pb-2">{{ dealerInfo && dealerInfo.email ? dealerInfo.email : 'N/A' }}</p>
      </div>
      <div class="mb-3">
        <p>{{ 'Phone' | formatLabelNoTranslation }}</p>
        <p class="font-weight-bold pb-2">
          {{ dealerInfo && dealerInfo.phoneNumber ? dealerInfo.phoneNumber : 'N/A' }}
        </p>
      </div>
    </div>
    <div v-else-if="dealerInfoLoading" class="mt-4">
      <span role="status" class="spinner-border text-primary" style="width: 5rem; height: 5rem;">
        <span class="sr-only"></span>
      </span>
    </div>
    <div v-else-if="!dealerInfoLoading && !dealerInfo" title="" class="col-12 col-md-6">
      <h1>Dealer Info</h1>
      <p>Dealer not found</p>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import * as Constants from '@/constants'

export default {
  props: ['value', 'currentStatus', 'currentSubscription', 'billingAccountId'],
  data() {
    return {
      dealerInfoLoading: true,
      dealerInfo: null
    }
  },
  computed: {
    ...mapState({
      currentAppConfig: state => state.application.currentAppConfig
    })
  },
  methods: {
    BillingMaintenanceEnabled() {
      return (
        this.currentAppConfig &&
        this.currentAppConfig.featureFlags &&
        this.currentAppConfig.featureFlags[Constants.BillingMigrationFeatureFlag]
      )
    }
  },
  mounted() {
    this.$store
      .dispatch('getPreferredDealer', this.value.apparatusId)
      .then(response => {
        this.dealerInfo = response.data
      })
      .catch(error => {
        if (error.response.data.status == 401) {
          this.$store.dispatch('displayToast', {
            title: this.$t('xError'),
            message: 'Your session has expired, please re-log in.',
            variant: 'danger'
          })
          this.forceSignOut()
        }
      })
      .finally(() => {
        this.dealerInfoLoading = false
      })
    if (!this.currentAppConfig) this.$store.dispatch('getApplicationConfiguration', 'Any')
  },
  updated() {
    var el = document.getElementById('btnCopyDealerIdToClipboard')
    if (el) el.style.display = this.dealerInfo && this.dealerInfo.externalId ? 'block' : 'none'
  }
}
</script>
