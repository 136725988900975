var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "main-card mt-2" }, [
        _c("div", { staticClass: "card-header" }, [
          _c("ul", { staticClass: "nav nav-tabs card-header-tabs nav-fill" }, [
            _c(
              "li",
              {
                staticClass: "nav-item hoverPointer",
                on: {
                  click: function($event) {
                    _vm.tabIndex = 0
                  }
                }
              },
              [
                _c("a", { staticClass: "nav-link", class: _vm.isActive(0) }, [
                  _vm._v("General Information")
                ])
              ]
            ),
            _vm.hasDevice(_vm.value) && !_vm.currentStatus
              ? _c(
                  "li",
                  {
                    staticClass: "nav-item hoverPointer",
                    on: {
                      click: function($event) {
                        _vm.tabIndex = 1
                      }
                    }
                  },
                  [
                    _c(
                      "a",
                      { staticClass: "nav-link", class: _vm.isActive(1) },
                      [
                        _c("span", {
                          staticClass: "spinner-border spinner-border-sm mr-1"
                        }),
                        _vm._v("Status and Maintenance")
                      ]
                    )
                  ]
                )
              : _vm.hasDevice(_vm.value) &&
                _vm.currentStatus &&
                _vm.currentStatus.apparatusStatus
              ? _c("li", { staticClass: "nav-item hoverPointer" }, [
                  _c(
                    "a",
                    {
                      staticClass: "nav-link",
                      class: _vm.isActive(1),
                      on: {
                        click: function($event) {
                          _vm.tabIndex = 1
                        }
                      }
                    },
                    [
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col-auto" },
                          [
                            _c("StatusIcon", {
                              attrs: {
                                apparatusStatus:
                                  _vm.currentStatus.apparatusStatus,
                                isConnected: _vm.currentStatus.isConnected,
                                showWarning: _vm.currentStatus.showWarning,
                                small: true,
                                deviceType:
                                  _vm.value.deviceInfo.deviceType == null
                                    ? _vm.hsbLteDeviceType
                                    : _vm.value.deviceInfo.deviceType ==
                                      _vm.hsbG3DeviceType
                                    ? _vm.value.networkType
                                    : _vm.value.deviceInfo.deviceType
                              }
                            })
                          ],
                          1
                        ),
                        _c("div", { staticClass: "col" }, [
                          _vm._v(" Status and Maintenance ")
                        ])
                      ])
                    ]
                  )
                ])
              : _vm._e(),
            _vm.hasDevice(_vm.value)
              ? _c(
                  "li",
                  {
                    staticClass: "nav-item hoverPointer",
                    on: {
                      click: function($event) {
                        _vm.tabIndex = 2
                      }
                    }
                  },
                  [
                    _c(
                      "a",
                      { staticClass: "nav-link", class: _vm.isActive(2) },
                      [
                        !_vm.currentStatus
                          ? _c("span", {
                              staticClass: "spinner-border spinner-border-sm"
                            })
                          : _vm._e(),
                        _vm._v("Connections and Subscription")
                      ]
                    )
                  ]
                )
              : _vm._e(),
            _vm.hasDevice(_vm.value)
              ? _c(
                  "li",
                  {
                    staticClass: "nav-item hoverPointer",
                    on: {
                      click: function($event) {
                        _vm.tabIndex = 3
                      }
                    }
                  },
                  [
                    _c(
                      "a",
                      { staticClass: "nav-link", class: _vm.isActive(3) },
                      [_vm._v("Commands")]
                    )
                  ]
                )
              : _vm._e(),
            _c(
              "li",
              {
                staticClass: "nav-item hoverPointer",
                on: {
                  click: function($event) {
                    _vm.tabIndex = 4
                  }
                }
              },
              [
                _c("a", { staticClass: "nav-link", class: _vm.isActive(4) }, [
                  _vm.gettingNotifications
                    ? _c("span", {
                        staticClass: "spinner-border spinner-border-sm"
                      })
                    : _vm._e(),
                  _vm._v("Notifications")
                ])
              ]
            )
          ])
        ]),
        _c("div", { staticClass: "card-body" }, [
          _vm.tabIndex == 0
            ? _c(
                "div",
                { attrs: { deck: "" } },
                [_c("GeneralInfoTab", { attrs: { value: _vm.value } })],
                1
              )
            : _vm._e(),
          _vm.tabIndex == 1 && !_vm.currentStatus
            ? _c("span", {
                staticClass:
                  "text-center float-center spinner-border text-primary",
                staticStyle: { width: "10rem", height: "10rem" },
                attrs: { role: "status", label: "Spinning" }
              })
            : _vm.tabIndex == 1 && _vm.currentStatus
            ? _c(
                "div",
                { attrs: { deck: "" } },
                [
                  _c("CurrentStatusTab", {
                    attrs: {
                      currentStatus: _vm.currentStatus,
                      deviceType: _vm.value.deviceInfo.deviceType,
                      networkType: _vm.value.networkType
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.tabIndex == 2 && !_vm.currentStatus
            ? _c("span", {
                staticClass:
                  "text-center float-center spinner-border text-primary",
                staticStyle: { width: "10rem", height: "10rem" },
                attrs: { role: "status", label: "Spinning" }
              })
            : _vm.tabIndex == 2 && _vm.currentStatus
            ? _c(
                "div",
                [
                  _c("ConnectionAndSubscriptionTab", {
                    attrs: {
                      value: _vm.value,
                      currentStatus: _vm.currentStatus,
                      currentSubscription: _vm.currentSubscription
                        ? _vm.currentSubscription.activeProduct
                        : null,
                      billingAccountId: _vm.currentSubscription
                        ? _vm.currentSubscription.billingAccountId
                        : null
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.tabIndex == 3
            ? _c("div", [_c("CommandsTab", { attrs: { value: _vm.value } })], 1)
            : _vm._e(),
          _vm.tabIndex == 4
            ? _c(
                "div",
                [
                  _vm.gettingNotifications
                    ? _c("span", {
                        staticClass:
                          "text-center float-center spinner-border text-primary",
                        staticStyle: { width: "10rem", height: "10rem" },
                        attrs: { role: "status", label: "Spinning" }
                      })
                    : _c("NotificationsTab", {
                        attrs: {
                          value: _vm.value,
                          notifications: _vm.notifications
                        }
                      })
                ],
                1
              )
            : _vm._e()
        ]),
        _c("div", { staticClass: "card-footer" }, [
          _c(
            "span",
            { attrs: { id: "impersonate-gen" + _vm.value.apparatusId } },
            [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary mt-2",
                  attrs: {
                    type: "button",
                    disabled: !_vm.hasAssociatedAccount(_vm.value),
                    "data-toggle": "tooltip",
                    title: _vm.hasAssociatedAccount(_vm.value)
                      ? ""
                      : "No Owner Account",
                    "data-test-hook": "go-to-dashboard-button-search-result"
                  },
                  on: {
                    click: function($event) {
                      return _vm.ImpersonateEmail()
                    }
                  }
                },
                [_vm._v(" Go To Dashboard ")]
              )
            ]
          ),
          _c("span", { attrs: { id: "swapDevice" } }, [
            _c(
              "button",
              {
                staticClass: "btn btn-secondary ml-2 mt-2",
                attrs: {
                  type: "button",
                  disabled:
                    !_vm.hasAssociatedAccount(_vm.value) ||
                    _vm.value.apparatusClassification ==
                      _vm.fuelTankApparatus ||
                    !_vm.hasDevice(_vm.value) ||
                    _vm.DealerManagedOrBillingMaintenance ||
                    _vm.hasRestrictedPanels(_vm.value),
                  "data-toggle": "tooltip",
                  title: _vm.hasRestrictedPanels(_vm.value)
                    ? "No devices are supported for this panel ID"
                    : ""
                },
                on: {
                  click: function($event) {
                    return _vm.openModal(
                      "deviceSwapModal-newDeviceInfo" + _vm.value.apparatusId
                    )
                  }
                }
              },
              [_vm._v(" Swap Device ")]
            )
          ]),
          _c("span", { attrs: { id: "cancelSubButton" } }, [
            _c(
              "button",
              {
                staticClass: "btn btn-danger ml-2 mt-2",
                attrs: {
                  type: "button",
                  disabled:
                    !_vm.hasAssociatedAccount(_vm.value) ||
                    _vm.value.apparatusClassification ==
                      _vm.fuelTankApparatus ||
                    !_vm.hasSubscription ||
                    _vm.DealerManagedOrBillingMaintenance ||
                    _vm.value.enrolledInVpp,
                  "data-toggle": "tooltip",
                  title: _vm.tooltipForHomeownerChangeButton(_vm.value),
                  "data-test-hook": "cancel-sub-button-search-result"
                },
                on: {
                  click: function($event) {
                    return _vm.openModal("cancelSubscription")
                  }
                }
              },
              [_vm._v(" Cancel Subscription ")]
            )
          ]),
          _c(
            "button",
            {
              staticClass: "btn btn-outline-primary ml-2 mt-2",
              attrs: {
                type: "button",
                "data-test-hook": "status-history-button-search-result",
                disabled:
                  _vm.value.apparatusClassification == _vm.fuelTankApparatus
              },
              on: {
                click: function($event) {
                  return _vm.openModal("statusHistory" + _vm.value.apparatusId)
                }
              }
            },
            [_vm._v(" Status History ")]
          ),
          _c("span", { attrs: { id: "transferSubButton" } }, [
            _c(
              "button",
              {
                staticClass: "btn btn-secondary ml-2 mt-2",
                attrs: {
                  type: "button",
                  disabled:
                    !_vm.hasAssociatedAccount(_vm.value) ||
                    _vm.value.apparatusClassification ==
                      _vm.fuelTankApparatus ||
                    _vm.cannotTransferGen() ||
                    _vm.DealerManagedOrBillingMaintenance,
                  "data-toggle": "tooltip",
                  title:
                    _vm.value.apparatusClassification !=
                      _vm.fuelTankApparatus &&
                    (_vm.isDealerManagedDevice() || _vm.cannotTransferGen())
                      ? "Search by owner to use Transfer Account option"
                      : "",
                  "data-test-hook": "transfer-gen-button-search-result"
                },
                on: {
                  click: function($event) {
                    return _vm.openModal(
                      "destination-org-search" + _vm.value.apparatusId
                    )
                  }
                }
              },
              [_vm._v(" Transfer Generator ")]
            )
          ]),
          _c("span", { attrs: { id: "homeownerChangeButton" } }, [
            _c(
              "button",
              {
                staticClass: "btn btn-danger ml-2 mt-2",
                attrs: {
                  type: "button",
                  disabled:
                    _vm.value.apparatusClassification ==
                      _vm.fuelTankApparatus ||
                    _vm.DealerManagedOrBillingMaintenance ||
                    _vm.value.enrolledInVpp,
                  "data-toggle": "tooltip",
                  title: _vm.tooltipForHomeownerChangeButton(_vm.value)
                },
                on: {
                  click: function($event) {
                    _vm.openModal(
                      "homeowner-change-step-1" + _vm.value.apparatusId
                    ),
                      _vm.trackAppInsightsEvent("open-removal-flow", {
                        page: "admin",
                        organizationId: _vm.value.organizationId,
                        apparatusId: _vm.value.apparatusId
                      })
                  }
                }
              },
              [_vm._v(" " + _vm._s(_vm.$t("xHomeownerChange")) + " ")]
            )
          ])
        ])
      ]),
      _c(
        "div",
        {
          staticClass: "modal fade show",
          staticStyle: { "padding-right": "11px" },
          attrs: { id: "cancelSubscription", role: "dialog" }
        },
        [
          _c("div", { staticClass: "modal-dialog modal-md" }, [
            _c("span"),
            _c("div", { staticClass: "modal-content" }, [
              _c("div", { staticClass: "modal-body" }, [
                _vm._v(
                  ' Are you sure you want to cancel this device\'s subscription? Click "CONTINUE" to cancel this device\'s subscription, click "CLOSE" to exit '
                )
              ]),
              _c("footer", { staticClass: "modal-footer" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-secondary",
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        return _vm.closeModal("cancelSubscription")
                      }
                    }
                  },
                  [_vm._v(" CLOSE ")]
                ),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        return _vm.cancelDeviceSubscription()
                      }
                    }
                  },
                  [_vm._v(" CONTINUE ")]
                )
              ])
            ])
          ])
        ]
      ),
      _vm.hasDevice(_vm.value)
        ? _c("DeviceSwap", { attrs: { oldDevice: _vm.value } })
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "modal fade show",
          staticStyle: { "padding-right": "11px" },
          attrs: { id: "statusHistory" + _vm.value.apparatusId, role: "dialog" }
        },
        [
          _c("div", { staticClass: "modal-dialog modal-md" }, [
            _c("div", { staticClass: "modal-content" }, [
              _c("header", { staticClass: "modal-header" }, [
                _c("h5", { staticClass: "modal-title" }, [
                  _vm._v("Status History: " + _vm._s(_vm.value.apparatusId))
                ]),
                _c(
                  "button",
                  {
                    staticClass: "close",
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        return _vm.closeModal(
                          "statusHistory" + _vm.value.apparatusId
                        )
                      }
                    }
                  },
                  [_vm._v(" x ")]
                )
              ]),
              _c(
                "div",
                { staticClass: "modal-body" },
                [
                  _c("StatusHistory", {
                    attrs: { apparatusId: _vm.value.apparatusId }
                  })
                ],
                1
              ),
              _c("footer", { staticClass: "modal-footer" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        return _vm.closeModal(
                          "statusHistory" + _vm.value.apparatusId
                        )
                      }
                    }
                  },
                  [_vm._v(" Ok ")]
                )
              ])
            ])
          ])
        ]
      ),
      _vm.value
        ? _c("TransferGeneratorModal", {
            attrs: {
              currentSubscription: _vm.currentSubscription,
              source: _vm.value,
              transferType: _vm.transferType
            },
            on: { transferEmission: _vm.TransferCompleteEmission }
          })
        : _vm._e(),
      _vm.value
        ? _c("HomeownerChangeModals", {
            attrs: {
              value: _vm.value,
              currentSubscription: _vm.currentSubscription
            },
            on: { successfulRemoval: _vm.successfulRemoval }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }