var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "dashboard-card",
      attrs: { "data-test-hook": "dashboard-pending-card" }
    },
    [
      _c("div", { staticClass: "card hoverPointer apparatus-card" }, [
        _c("div", { staticClass: "card-body" }, [
          _c("div", { staticClass: "row mt-5 justify-content-around" }, [
            _c("div", { staticClass: "col-auto" }, [
              _c("h5", [
                _vm._v(
                  _vm._s(_vm.apparatusType) +
                    " " +
                    _vm._s(
                      _vm.$t("xInfoUnavailableAtThisTimePleaseCheckBackLater")
                    )
                )
              ])
            ])
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }