var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          attrs: {
            id: "delete-all-messages-modal",
            centered: "",
            size: "md",
            "no-close-on-backdrop": "",
            "no-close-on-esc": ""
          },
          scopedSlots: _vm._u([
            {
              key: "modal-header",
              fn: function() {
                return [
                  _c(
                    "b-row",
                    {
                      staticClass: "w-100",
                      attrs: { "align-v": "center", "no-gutters": "" }
                    },
                    [
                      _c("b-col", [
                        _c(
                          "h2",
                          {
                            staticClass: "modal-title",
                            attrs: { "data-test-hook": "modalTitle" }
                          },
                          [
                            _c("i", {
                              staticClass:
                                "fas fa-exclamation-triangle colorOrange mr-1",
                              attrs: { "data-test-hook": "modalTitleIcon" }
                            }),
                            _c(
                              "span",
                              {
                                staticClass: "ml-2",
                                attrs: { "data-test-hook": "modalTitleText" }
                              },
                              [_vm._v(_vm._s(_vm.$t("xAreYouSure")))]
                            )
                          ]
                        )
                      ]),
                      _c(
                        "b-col",
                        { staticClass: "float-right", attrs: { cols: "auto" } },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "btn-icon-button--gray",
                              attrs: {
                                variant: "icon-button",
                                "data-test-hook": "modalXCloseBtn"
                              },
                              on: {
                                click: function($event) {
                                  _vm.$bvModal.hide(
                                    "delete-all-messages-modal"
                                  ),
                                    (_vm.messageTypeToDelete = null)
                                }
                              }
                            },
                            [_c("i", { staticClass: "fas fa-times" })]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              },
              proxy: true
            },
            {
              key: "modal-footer",
              fn: function() {
                return [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        variant: "outline-warning",
                        "data-test-hook": "modalNoCancelBtn"
                      },
                      on: {
                        click: function($event) {
                          _vm.$bvModal.hide("delete-all-messages-modal"),
                            (_vm.messageTypeToDelete = null)
                        }
                      }
                    },
                    [
                      _c(
                        "span",
                        { attrs: { "data-test-hook": "modalNoCancelBtnText" } },
                        [_vm._v(_vm._s(_vm.$t("xNoCancel")))]
                      )
                    ]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: {
                        variant: "warning",
                        "data-test-hook": "modalYesImSureBtn"
                      },
                      on: {
                        click: function($event) {
                          return _vm.deleteMessages()
                        }
                      }
                    },
                    [
                      !_vm.deletingMessages
                        ? _c(
                            "span",
                            {
                              attrs: {
                                "data-test-hook": "modalYesImSureBtnText"
                              }
                            },
                            [_vm._v(_vm._s(_vm.$t("xYesImSure")))]
                          )
                        : _vm._e(),
                      _vm.deletingMessages ? _c("b-spinner") : _vm._e()
                    ],
                    1
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c(
            "b-row",
            { attrs: { "data-test-hook": "modalBodyText" } },
            [
              _c("b-col", [
                _vm._v(
                  _vm._s(_vm.$t("xAreYouSureYouWantToDeleteAllMessages")) +
                    " " +
                    _vm._s(_vm.messageCategory) +
                    "?"
                )
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }