import { render, staticRenderFns } from "./NotificationsTab.vue?vue&type=template&id=7b274c1d&scoped=true"
import script from "./NotificationsTab.vue?vue&type=script&lang=js"
export * from "./NotificationsTab.vue?vue&type=script&lang=js"
import style0 from "./NotificationsTab.vue?vue&type=style&index=0&id=7b274c1d&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b274c1d",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\a\\1\\s\\Generac.Application.MobileLink\\ClientApp\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7b274c1d')) {
      api.createRecord('7b274c1d', component.options)
    } else {
      api.reload('7b274c1d', component.options)
    }
    module.hot.accept("./NotificationsTab.vue?vue&type=template&id=7b274c1d&scoped=true", function () {
      api.rerender('7b274c1d', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Admin/NotificationsTab.vue"
export default component.exports