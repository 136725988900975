<template>
  <div>
    <!-- Homeowner Info Been Verfied Modal -->
    <Modal :modalId="'homeowner-change-step-1' + value.apparatusId">
      <span tabindex="0"></span>
      <ModalHeader :title="$t('xHomeownerChange')"></ModalHeader>
      <ModalBody>
        <div class="row">
          <div class="col">
            {{ $t('xHasHomeownerInfoBeenVerifiedInSAP') }}
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <button class="btn btn-outline-warning" @click="closeModals()">{{ $t('xNo') }}</button>
        <button class="btn btn-warning" @click="homeownerChangeStep2Modal()">{{ $t('xYes') }}</button>
      </ModalFooter>
    </Modal>

    <!-- Homeowner Change Modal -->
    <Modal :modalId="'homeowner-change-step-2' + value.apparatusId" size="modal-xl">
      <span tabindex="0"></span>
      <ModalHeader :title="$t('xHomeownerChange')"></ModalHeader>
      <ModalBody>
        <div class="row">
          <div class="col">
            <div class="card shadow">
              <div class="card-body">
                <h4 class="card-title">Previous Homeowner Information</h4>
                <div class="row">
                  <div class="tile-label col-12">{{ 'Name' | formatLabelNoTranslation }}</div>
                  <div class="tile-content col-12">
                    {{ value.ownerInformation.firstName + ' ' + value.ownerInformation.lastName || 'N/A' }}
                  </div>
                </div>
                <div class="row">
                  <div class="tile-label col-12">{{ 'Phone Number' | formatLabelNoTranslation }}</div>
                  <div class="tile-content col-12">{{ value.ownerInformation.phones[0] || 'N/A' }}</div>
                </div>
                <div class="row">
                  <div class="tile-label col-12">{{ 'Email Address' | formatLabelNoTranslation }}</div>
                  <div class="tile-content col-12">{{ value.ownerInformation.emails[0] || 'N/A' }}</div>
                </div>
                <div class="row">
                  <div class="tile-label col-12">{{ 'Owner Address' | formatLabelNoTranslation }}</div>
                  <div class="tile-content col-12">{{ DisplayAddress(value.ownerAddress) }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col">
            <div class="card shadow">
              <div class="card-body">
                <h4 class="card-title">Previous Homeowner's Subscription Information</h4>
                <div>
                  <div class="row">
                    <div class="tile-label col-12">
                      <span class="tile-label">{{ 'Subscription' | formatLabelNoTranslation }}</span>
                      <span
                        :class="getSubscriptionClass()"
                        class="tile-content"
                        v-if="
                          currentSubscription &&
                            currentSubscription.activeProduct &&
                            currentSubscription.activeProduct[0]
                        "
                      >
                        <strong> {{ currentSubscription.activeProduct[0].productName }}</strong>
                      </span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="tile-label col-12">
                      <span class="tile-label">{{ 'Cost' | formatLabelNoTranslation }} </span>
                      <span
                        class="tile-content"
                        v-if="
                          currentSubscription &&
                            currentSubscription.activeProduct &&
                            currentSubscription.activeProduct[0]
                        "
                      >
                        {{ currentSubscription.activeProduct[0].cost.toFixed(2) | formatCurrency }}
                      </span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="tile-label col-12">
                      <span class="tile-label">{{ 'Start Date' | formatLabelNoTranslation }} </span>
                      <span
                        class="tile-content"
                        v-if="
                          currentSubscription &&
                            currentSubscription.activeProduct &&
                            currentSubscription.activeProduct[0]
                        "
                      >
                        {{ currentSubscription.activeProduct[0].startDate }}</span
                      >
                    </div>
                  </div>
                  <div class="row">
                    <div class="tile-label col-12">
                      <span class="tile-label">{{ 'Next Billing Date' | formatLabelNoTranslation }} </span>
                      <span
                        class="tile-content"
                        v-if="
                          currentSubscription &&
                            currentSubscription.activeProduct &&
                            currentSubscription.activeProduct[0]
                        "
                      >
                        {{ currentSubscription.activeProduct[0].nextBillingDate }}</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col">
            Are you sure you want to remove this generator from the above owner and cancel the above subscription?
            <strong
              >Note that, once removed, the generator will not be able to be re-added to the previous owner.</strong
            >
            previous owner will also not receive credit for any time remaining on the subscription.
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <button class="btn btn-outline-warning" type="button" @click="closeModals()">{{ $t('xNo') }}</button>
        <button class="btn btn-warning" type="button" @click="removeApparatus()">{{ $t('xYes') }}</button>
      </ModalFooter>
    </Modal>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import * as Constants from '@/constants'

export default {
  props: ['value', 'currentSubscription'],
  data() {
    return {
      removing: false
    }
  },
  computed: {
    ...mapState({
      user: state => state.account.user
    })
  },
  methods: {
    homeownerChangeStep2Modal() {
      this.openModal('homeowner-change-step-2' + this.value.apparatusId)
    },
    closeModals() {
      this.closeModal('homeowner-change-step-1' + this.value.apparatusId)
      this.closeModal('homeowner-change-step-2' + this.value.apparatusId)
    },
    removeApparatus() {
      this.removing = true
      this.$store
        .dispatch('removeApparatus', {
          organizationId: this.value.organizationId,
          apparatusId: this.value.apparatusId
        })
        .then(response => {
          this.$emit('successfulRemoval')
          let event = this.createEventLogObjectFromAdmin(
            this.value,
            this.currentSubscription,
            this.user,
            Constants.RemoveEventType,
            null
          )
          this.$store.dispatch('logEvent', event)
          this.closeModals()
          this.$store.dispatch('displayToast', {
            title: this.$t('xSuccess'),
            message: this.$t('xSuccessfullyRemoved'),
            variant: 'success'
          })
        })
        .catch(error => {
          this.$store.dispatch('displayToast', {
            title: this.$t('xError'),
            message: this.$t('xFailedToRemoveTheGeneratorFromYourAccount'),
            variant: 'danger'
          })
        })
        .finally(() => {
          this.removing = false
        })
    }
  }
}
</script>
