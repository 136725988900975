import { xsrfPost } from '@/utils/xsrfClient'
import { vueAppApiBaseAddress } from '@/utils/mixins'

var initialState = {}

export const state = Object.assign({}, initialState)

export const getters = {}

export const mutations = {}

export const actions = {
  async migrationStatus({ commit }, email) {
    return xsrfPost(vueAppApiBaseAddress() + `v1/Migration/email/status`, { Email: email })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
