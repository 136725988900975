var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          attrs: {
            id: "portables-enrollment",
            size: "lg",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            "no-fade": "",
            "no-stacking": "",
            centered: ""
          },
          scopedSlots: _vm._u([
            {
              key: "modal-header",
              fn: function() {
                return [
                  _c("h3", [
                    _c("strong", { staticClass: "colorOrange" }, [
                      _c("i", { staticClass: "fas fa-plus" }),
                      _vm._v(" " + _vm._s(_vm.$t("xAddPortableGenerator")))
                    ])
                  ])
                ]
              },
              proxy: true
            },
            {
              key: "modal-footer",
              fn: function() {
                return [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        variant: "outline-warning",
                        "data-test-hook": "cancel-button-step1-enrollment"
                      },
                      on: {
                        click: function($event) {
                          return _vm.$bvModal.show(
                            "close-portable-enrollment-flow"
                          )
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("xCancel")) + " ")]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: {
                        variant: "warning",
                        disabled:
                          _vm.validatingAndEnrolling ||
                          !_vm.userEnteredSerialNumber ||
                          !_vm.generatorName ||
                          _vm.validationFailed,
                        "data-test-hook": "ContinueBtn"
                      },
                      on: {
                        click: function($event) {
                          return _vm.validateAndEnrollGen()
                        }
                      }
                    },
                    [
                      _vm._v(" " + _vm._s(_vm.$t("xContinue")) + " "),
                      _c("i", { staticClass: "fas fa-arrow-right fa-lg" }),
                      _vm.validatingAndEnrolling
                        ? _c("b-spinner", { staticClass: "ml-2" })
                        : _vm._e()
                    ],
                    1
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c(
            "b-row",
            { staticClass: "mb-2", attrs: { "align-v": "center" } },
            [
              _c("b-col", [
                _c("h5", [
                  _c("strong", [_vm._v(_vm._s(_vm.$t("xEnterYourGenSN")))])
                ])
              ])
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _c(
                    "b-input-group",
                    [
                      _c("b-form-input", {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: "NNNNNNNNNNNNNNNNNN",
                            expression: "'NNNNNNNNNNNNNNNNNN'"
                          }
                        ],
                        attrs: {
                          maxlength: "18",
                          autocomplete: "new-password",
                          "data-test-hook": "SerialNumberField"
                        },
                        model: {
                          value: _vm.userEnteredSerialNumber,
                          callback: function($$v) {
                            _vm.userEnteredSerialNumber = $$v
                          },
                          expression: "userEnteredSerialNumber"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "mt-4" },
            [
              _c("b-col", [
                _c("h5", [
                  _c("strong", [_vm._v(_vm._s(_vm.$t("xNameYourGen")))])
                ])
              ])
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _c(
                    "b-input-group",
                    [
                      _c("b-form-input", {
                        attrs: {
                          maxlength: "50",
                          autocomplete: "new-password",
                          "data-test-hook": "GeneratorNameField"
                        },
                        model: {
                          value: _vm.generatorName,
                          callback: function($$v) {
                            _vm.generatorName = $$v
                          },
                          expression: "generatorName"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm.showValidateAndEnrollError
            ? _c(
                "div",
                {
                  staticClass: "alert alert-danger alert--validation",
                  attrs: { show: _vm.showValidateAndEnrollError }
                },
                [_vm._v(" " + _vm._s(_vm.validateAndEnrollErrorMessage) + " ")]
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "portables-enrollment-success",
            size: "lg",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            "no-fade": "",
            "no-stacking": "",
            "hide-header": "",
            centered: ""
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function() {
                return [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        variant: "outline-warning",
                        block: "",
                        "data-test-hook": "GenDetailsBtn"
                      },
                      on: {
                        click: function($event) {
                          return _vm.goToDashboard()
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("xGoToDashboard")) + " ")]
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c(
            "b-row",
            { staticClass: "text-center" },
            [
              _c("b-col", [
                _c("h2", { staticClass: "success-color" }, [
                  _vm._v(_vm._s(_vm.$t("xSuccess")) + "!")
                ])
              ])
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "text-center" },
            [
              _c("b-col", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("xPortableEnrollmentSuccessMessage", {
                        0: _vm.generatorName
                      })
                    ) +
                    " "
                )
              ])
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "text-center mt-4" },
            [
              _c(
                "b-col",
                [
                  _c("b-img", {
                    attrs: {
                      height: "202.13px",
                      src: require("@/assets/images/PGenGraphic.svg")
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "close-portable-enrollment-flow",
            centered: "",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            "no-fade": "",
            "no-stacking": ""
          },
          scopedSlots: _vm._u([
            {
              key: "modal-header",
              fn: function() {
                return [
                  _c(
                    "b-row",
                    {
                      staticClass: "w-100",
                      attrs: { "align-v": "center", "no-gutters": "" }
                    },
                    [
                      _c("b-col", [
                        _c("h3", [
                          _c("strong", { staticClass: "colorOrange" }, [
                            _c("i", {
                              staticClass:
                                "fa-solid fa-triangle-exclamation colorOrange"
                            }),
                            _vm._v(" " + _vm._s(_vm.$t("xAreYouSure")) + " ")
                          ])
                        ])
                      ]),
                      _c(
                        "b-col",
                        { staticClass: "float-right", attrs: { cols: "auto" } },
                        [
                          _c(
                            "b-button",
                            {
                              attrs: { variant: "icon-button" },
                              on: {
                                click: function($event) {
                                  return _vm.$bvModal.show(
                                    "portables-enrollment"
                                  )
                                }
                              }
                            },
                            [_c("i", { staticClass: "fas fa-times" })]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              },
              proxy: true
            },
            {
              key: "modal-footer",
              fn: function() {
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { variant: "outline-warning" },
                      on: {
                        click: function($event) {
                          return _vm.$bvModal.show("portables-enrollment")
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("xNoContinue")) + " ")]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { variant: "warning" },
                      on: {
                        click: function($event) {
                          return _vm.exitFlow()
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("xYesIAmSure")) + " ")]
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c(
            "b-row",
            [
              _c("b-col", [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("xExitPortablesEnrollmentFlowMessage")) +
                    " "
                )
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }