var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.apparatusDetails
        ? _c(
            "div",
            { staticClass: "d-flex justify-content-center mb-3 pt-5" },
            [
              _c("b-spinner", {
                staticStyle: { width: "5rem", height: "5rem" },
                attrs: { variant: "warning", label: "Spinning" }
              })
            ],
            1
          )
        : _c(
            "div",
            [
              _c(
                "b-row",
                { staticClass: "d-md-none pt-2", attrs: { "no-gutters": "" } },
                [
                  _c(
                    "b-col",
                    {
                      staticClass: "hoverPointer pr-0 d-flex align-items-center"
                    },
                    [
                      _vm.$route.name === "deviceDetails"
                        ? _c(
                            "p",
                            {
                              staticClass: "mb-0 py-2 font-weight-bold",
                              on: {
                                click: function($event) {
                                  return _vm.goTo("dashboard")
                                }
                              }
                            },
                            [
                              _c("i", {
                                staticClass:
                                  "fas fa-arrow-left colorOrange mr-2"
                              }),
                              _vm._v(" Dashboard ")
                            ]
                          )
                        : _c(
                            "p",
                            {
                              staticClass: "mb-0 py-2 font-weight-bold",
                              on: {
                                click: function($event) {
                                  return _vm.$router.go(-1)
                                }
                              }
                            },
                            [
                              _c("i", {
                                staticClass:
                                  "fas fa-arrow-left colorOrange mr-2"
                              }),
                              _vm._v(" Back ")
                            ]
                          )
                    ]
                  ),
                  _c(
                    "b-col",
                    { staticClass: "ml-auto my-auto", attrs: { cols: "auto" } },
                    [
                      _c(
                        "b-dropdown",
                        {
                          staticClass: "d-md-none",
                          attrs: { right: "", variant: "outline-warning" },
                          scopedSlots: _vm._u([
                            {
                              key: "button-content",
                              fn: function() {
                                return [_vm._v(" Details ")]
                              },
                              proxy: true
                            }
                          ])
                        },
                        [
                          _c(
                            "b-list-group",
                            { attrs: { flush: "" } },
                            [
                              _vm._l(_vm.childRoutes, function(route) {
                                return _c(
                                  "b-dropdown-item",
                                  {
                                    key: route.name,
                                    class: route.class,
                                    attrs: {
                                      active: _vm.$route.name == route.name,
                                      to: { name: route.name },
                                      "data-test-hook":
                                        "route-dropdown-apparatus-details" +
                                        route.name
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "fa-fw",
                                      class: route.icon
                                    }),
                                    _c(
                                      "p",
                                      {
                                        staticClass: "ml-2 d-inline-block mb-0"
                                      },
                                      [_vm._v(_vm._s(route.label))]
                                    )
                                  ]
                                )
                              }),
                              _vm.isHsbApparatus() &&
                              _vm.technicalSpecs?.description != null
                                ? _c(
                                    "b-dropdown-item",
                                    {
                                      key: "orderParts",
                                      attrs: {
                                        href: _vm.getPartsURL,
                                        target: "_blank",
                                        "data-test-hook":
                                          "route-dropdown-apparatus-details-orderParts"
                                      }
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "fa fa-fw fa-tools"
                                      }),
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "ml-2 d-inline-block mb-0",
                                          attrs: {
                                            "data-test-hook": "OrderPartsBtn"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " Order Parts " +
                                              _vm._s(
                                                _vm.apparatusDetails.type
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm.apparatusDetails.apparatusClassification ==
                              _vm.portableGenApparatusType
                                ? _c(
                                    "b-dropdown-item",
                                    {
                                      staticClass: "sidenav hoverPointer",
                                      on: {
                                        click: function($event) {
                                          _vm.$bvModal.show(
                                            "removeGeneratorModal"
                                          ),
                                            (_vm.removeApparatusModalOpen = !_vm.removeApparatusModalOpen)
                                        }
                                      }
                                    },
                                    [
                                      _vm.removeApparatusModalOpen
                                        ? _c("div", {
                                            staticClass: "activeListItemCutout"
                                          })
                                        : _vm._e(),
                                      _vm.removeApparatusModalOpen
                                        ? _c("div", {
                                            staticClass: "activeListItemCutout2"
                                          })
                                        : _vm._e(),
                                      _c("p", [
                                        _c("i", {
                                          staticClass: "fa-solid fa-trash-can"
                                        }),
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("xRemoveGenerator")) +
                                            " "
                                        )
                                      ])
                                    ]
                                  )
                                : _vm._e()
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.currentAppConfig &&
              _vm.currentAppConfig.featureFlags &&
              _vm.currentAppConfig.featureFlags[_vm.billingMigrationFeatureFlag]
                ? _c(
                    "b-alert",
                    {
                      staticClass: "mt-2",
                      attrs: { show: "", variant: "warning" }
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("xBillingMaintenanceMessage")) + " "
                      )
                    ]
                  )
                : _vm._e(),
              _c(
                "div",
                {
                  on: {
                    click: function($event) {
                      return _vm.$bvModal.show("banner-message-modal")
                    }
                  }
                },
                [
                  _vm.showBannerMessage(_vm.bannerMessage)
                    ? _c(
                        "b-alert",
                        {
                          staticClass: "hoverPointer",
                          attrs: {
                            show: "",
                            variant: _vm.getBannerVariant(_vm.bannerMessage)
                          }
                        },
                        [
                          _c("i", {
                            class: _vm.getBannerIconClassAndColor(
                              _vm.bannerMessage
                            )
                          }),
                          _vm._v(
                            " " + _vm._s(_vm.bannerMessage.BannerMessage) + " "
                          ),
                          _c("strong", [
                            _vm._v(" " + _vm._s(_vm.$t("xLearnMore")) + " "),
                            _c("i", { staticClass: "fas fa-arrow-right" })
                          ])
                        ]
                      )
                    : _vm._e()
                ],
                1
              ),
              _c(
                "b-row",
                { attrs: { "align-h": "between" } },
                [
                  _c(
                    "b-col",
                    {
                      staticClass: "d-none d-md-block px-0",
                      attrs: { cols: "0", md: "4", lg: "3" }
                    },
                    [
                      _c(
                        "b-list-group",
                        {
                          staticClass: "h-100",
                          attrs: { flush: "", id: "detailsMenu" }
                        },
                        [
                          _vm._l(_vm.childRoutes, function(route) {
                            return _c(
                              "b-list-group-item",
                              {
                                key: route.name,
                                staticClass: "sidenav",
                                class: route.class,
                                attrs: {
                                  to: { name: route.name },
                                  "data-test-hook":
                                    "route-list-apparatus-details" + route.name
                                }
                              },
                              [
                                _c(
                                  "p",
                                  {
                                    attrs: {
                                      "data-test-hook":
                                        _vm.removeExtraChars(route.label) +
                                        `Btn`
                                    }
                                  },
                                  [
                                    _c("i", { class: route.icon }),
                                    _vm._v(" " + _vm._s(route.label) + " ")
                                  ]
                                )
                              ]
                            )
                          }),
                          _vm.isHsbApparatus() &&
                          _vm.technicalSpecs?.description != null
                            ? _c(
                                "b-list-group-item",
                                {
                                  key: "orderParts",
                                  staticClass: "sidenav",
                                  attrs: {
                                    href: _vm.getPartsURL,
                                    target: "_blank"
                                  }
                                },
                                [
                                  _c(
                                    "p",
                                    {
                                      attrs: {
                                        "data-test-hook": "OrderPartsBtn"
                                      }
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "fa fa-fw fa-tools"
                                      }),
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("xOrderParts")) +
                                          " " +
                                          _vm._s(_vm.apparatusDetails.type) +
                                          " "
                                      )
                                    ]
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm.apparatusDetails.apparatusClassification ==
                          _vm.portableGenApparatusType
                            ? _c(
                                "b-list-group-item",
                                {
                                  staticClass: "sidenav hoverPointer",
                                  on: {
                                    click: function($event) {
                                      _vm.$bvModal.show("removeGeneratorModal"),
                                        (_vm.removeApparatusModalOpen = !_vm.removeApparatusModalOpen)
                                    }
                                  }
                                },
                                [
                                  _vm.removeApparatusModalOpen
                                    ? _c("div", {
                                        staticClass: "activeListItemCutout"
                                      })
                                    : _vm._e(),
                                  _vm.removeApparatusModalOpen
                                    ? _c("div", {
                                        staticClass: "activeListItemCutout2"
                                      })
                                    : _vm._e(),
                                  _c("p", [
                                    _c("i", {
                                      staticClass: "fa-solid fa-trash-can"
                                    }),
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("xRemoveGenerator")) +
                                        " "
                                    )
                                  ])
                                ]
                              )
                            : _vm._e()
                        ],
                        2
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { sm: "12", md: "8", lg: "9" } },
                    [
                      _vm.cdmaWarning
                        ? _c(
                            "b-row",
                            {
                              staticClass: "pt-3 pl-md-2",
                              attrs: { "no-gutters": "" },
                              on: {
                                click: function($event) {
                                  return _vm.trackAppInsightsEvent(
                                    "cdma-banner-click"
                                  )
                                }
                              }
                            },
                            [
                              _c(
                                "b-col",
                                [
                                  _c(
                                    "b-link",
                                    {
                                      staticStyle: {
                                        "text-decoration": "none !important"
                                      },
                                      attrs: {
                                        href: _vm.cdmaWarning.link,
                                        target: "_blank"
                                      }
                                    },
                                    [
                                      _c(
                                        "b-alert",
                                        {
                                          staticClass: "hoverPointer mb-0",
                                          attrs: {
                                            variant: "warning",
                                            show: ""
                                          }
                                        },
                                        [
                                          _c(
                                            "b-row",
                                            [
                                              _c("b-col", [
                                                _c("h2", [
                                                  _c("i", {
                                                    staticClass:
                                                      "fas fa-exclamation-triangle banner-category-warning"
                                                  }),
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.cdmaWarning.title
                                                      ) +
                                                      " "
                                                  )
                                                ]),
                                                _c("p", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.cdmaWarning.message
                                                    )
                                                  )
                                                ]),
                                                _c(
                                                  "p",
                                                  { staticClass: "mb-0 pb-0" },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.cdmaWarning
                                                            .linkText
                                                        ) +
                                                        " "
                                                    ),
                                                    _c("i", {
                                                      staticClass:
                                                        "fas fa-arrow-right"
                                                    })
                                                  ]
                                                )
                                              ])
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            {
                              class:
                                _vm.$route.name == "deviceDetails"
                                  ? "mt-2 mt-md-4 ml-md-2"
                                  : null,
                              attrs: { md: "", id: "childRouteContainer" }
                            },
                            [
                              _c(
                                "b-container",
                                { staticClass: "p-0", attrs: { fluid: "" } },
                                [
                                  _c(
                                    "transition",
                                    { attrs: { name: "fade", mode: "out-in" } },
                                    [_c("router-view")],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
      _c(
        "b-modal",
        {
          attrs: { id: "removeGeneratorModal", centered: "" },
          scopedSlots: _vm._u([
            {
              key: "modal-header",
              fn: function() {
                return [
                  _c(
                    "b-row",
                    {
                      staticClass: "w-100",
                      attrs: { "align-v": "center", "no-gutters": "" }
                    },
                    [
                      _c("b-col", [
                        _c("h2", { staticClass: "modal-title" }, [
                          _c("i", {
                            staticClass: "fa-regular fa-trash colorOrange mr-1"
                          }),
                          _vm._v(" " + _vm._s(_vm.$t("xRemoveGenerator")) + " ")
                        ])
                      ]),
                      _c(
                        "b-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "btn-icon-button--gray",
                              attrs: { variant: "icon-button" },
                              on: {
                                click: function($event) {
                                  return _vm.$bvModal.hide(
                                    "removeGeneratorModal"
                                  )
                                }
                              }
                            },
                            [_c("i", { staticClass: "fas fa-times" })]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              },
              proxy: true
            },
            {
              key: "modal-footer",
              fn: function() {
                return [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        variant: "outline-warning",
                        disabled: _vm.removing
                      },
                      on: {
                        click: function($event) {
                          return _vm.$bvModal.hide("removeGeneratorModal")
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("xCancel")) + " ")]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { variant: "warning", disabled: _vm.removing },
                      on: {
                        click: function($event) {
                          return _vm.removeGenerator()
                        }
                      }
                    },
                    [
                      _vm._v(" " + _vm._s(_vm.$t("xYesRemove")) + " "),
                      _vm.removing
                        ? _c("b-spinner", { staticClass: "ml-2" })
                        : _vm._e()
                    ],
                    1
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c("p", [_vm._v(_vm._s(_vm.$t("xRemoveGenModalMessage1")))]),
          _c("p", { staticClass: "mt-2" }, [
            _vm._v(_vm._s(_vm.$t("xRemoveGenModalMessage2")))
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }