<template>
  <div class="mt-2 ml-md-2">
    <div v-if="!gettingWarranty">
      <div class="row no-gutters mb-2">
        <div class="col">
          <h1 class="mb-0 pb-0"><i class="far fa-award colorOrange mr-1"></i> {{ $t('xWarranty') }}</h1>
        </div>
      </div>

      <div class="card" v-if="warrantyInfo" data-test-hook="WarrantyCard">
        <div class="card-body">
          <div class="row">
            <div class="col">
              <p data-test-hook="WarrantyDurationLabel">{{ $t('xWarrantyLevel') }}</p>
              <p>
                <strong data-test-hook="WarrantyDuration">{{
                  warrantyInfo.duration || $t('xInfoNotAvailable')
                }}</strong>
              </p>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col">
              <p data-test-hook="WarrantyStartDateLabel">{{ $t('xStartDate') }}</p>
              <p v-if="warrantyInfo.startDate" data-test-hook="WarrantyStartDate">
                <strong>
                  {{ $moment(warrantyInfo.startDate).format($t('xShortDate')) }}
                </strong>
              </p>
              <p v-else data-test-hook="WarrantyStartDate">
                <strong>{{ $t('xInfoNotAvailable') }}</strong>
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col"></div>
          </div>
          <div class="row mt-3">
            <div class="col">
              <p data-test-hook="WarrantyEndDateLabel">{{ $t('xExpirationDate') }}</p>
              <p v-if="warrantyInfo.expirationDate" data-test-hook="WarrantyEndDate">
                <strong>{{ $moment(warrantyInfo.expirationDate).format($t('xShortDate')) }}</strong>
              </p>
              <p v-else data-test-hook="WarrantyEndDate">
                <strong>{{ $t('xInfoNotAvailable') }}</strong>
              </p>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col">
              <p data-test-hook="WarrantyDescriptionLabel">{{ $t('xWarrantyDescription') }}</p>
              <p data-test-hook="WarrantyDescription">
                <strong>{{ warrantyInfo.description || $t('xInfoNotAvailable') }}</strong>
              </p>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <div class="row">
            <div class="col" v-if="warrantyInfo && warrantyInfo.lastUpdated" data-test-hook="warranty-last-updated">
              <p>{{ 'xLastUpdated' | formatLabel }} {{ displayShortDateTimeWithTZ(warrantyInfo.lastUpdated) }}</p>
              <p>{{ $t('xUpdatedWeekly') }}</p>
            </div>
          </div>
        </div>
      </div>

      <div class="card" v-else data-test-hook="NoInfoWarrantyCard">
        <div class="card-body">
          <div class="row">
            <div class="col" data-test-hook="NoInfoText">
              <p>{{ $t('xInfoNotAvailable') }}</p>
            </div>
          </div>
        </div>
        <div class="card-footter">
          <div class="row">
            <div class="col" v-if="warrantyInfo && warrantyInfo.lastUpdated" data-test-hook="warranty-last-updated">
              <p>{{ 'xLastUpdated' | formatLabel }} {{ displayShortDateTimeWithTZ(warrantyInfo.lastUpdated) }}</p>
              <p>{{ $t('xUpdatedWeekly') }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="d-flex justify-content-center mb-3 mt-4">
      <span
        class="spinner-border text-warning"
        style="width: 5rem; height: 5rem"
        label="Spinning"
        data-test-hook="LoadingSpinner"
      ></span>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  data() {
    return {
      warrantyInfo: null,
      gettingWarranty: false
    }
  },
  mounted() {
    this.gettingWarranty = true
    this.$store
      .dispatch('getApparatusWarranty', this.$route.params.apparatusId)
      .then(response => {
        this.warrantyInfo = response.data
      })
      .catch(error => {})
      .finally(() => {
        this.gettingWarranty = false
      })
  }
}
</script>

<style scoped lang="scss">
.card-footer {
  background-color: var(--carbon-100) !important;
}
</style>
