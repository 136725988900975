var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("h1", [
      _vm._v("Owner Information: " + _vm._s(_vm.value.organizationId))
    ]),
    _c("div", { staticClass: "mb-3" }, [
      _c("p", [_vm._v(_vm._s(_vm._f("formatLabelNoTranslation")("Name")))]),
      _c("div", { staticClass: "d-flex align-items-center" }, [
        _c("p", { staticClass: "font-weight-bold" }, [
          _vm._v(
            " " +
              _vm._s(
                _vm.value.ownerInformation.firstName +
                  " " +
                  _vm.value.ownerInformation.lastName
              ) +
              " "
          )
        ]),
        _c(
          "button",
          {
            staticClass: "btn btn-light btn-sm ml-2",
            attrs: { "data-test-hook": "copy-name-button-owner-info-tab" },
            on: {
              click: function($event) {
                return _vm.copyToClipboard(
                  _vm.value.ownerInformation.firstName +
                    " " +
                    _vm.value.ownerInformation.lastName
                )
              }
            }
          },
          [_c("i", { staticClass: "far fa-copy colorOrange" })]
        )
      ])
    ]),
    _c("div", { staticClass: "mb-3" }, [
      _c("p", [
        _vm._v(_vm._s(_vm._f("formatLabelNoTranslation")("Phone Number")))
      ]),
      _c("p", { staticClass: "font-weight-bold pb-2" }, [
        _vm._v(_vm._s(_vm.value.ownerInformation.phones[0]))
      ])
    ]),
    _c("div", { staticClass: "mb-3" }, [
      _c("p", [
        _vm._v(_vm._s(_vm._f("formatLabelNoTranslation")("Email Address")))
      ]),
      _c("div", { staticClass: "d-flex align-items-center" }, [
        _c("p", { staticClass: "font-weight-bold" }, [
          _vm._v(_vm._s(_vm.value.ownerInformation.emails[0]))
        ]),
        _c(
          "button",
          {
            staticClass: "btn btn-light btn-sm ml-2",
            attrs: {
              variant: "light",
              size: "sm",
              "data-test-hook": "copy-email-button-owner-info-tab"
            },
            on: {
              click: function($event) {
                return _vm.copyToClipboard(_vm.value.ownerInformation.emails[0])
              }
            }
          },
          [_c("i", { staticClass: "far fa-copy colorOrange" })]
        )
      ])
    ]),
    _c("div", { staticClass: "mb-3" }, [
      _c("p", [
        _vm._v(_vm._s(_vm._f("formatLabelNoTranslation")("Owner Address")))
      ]),
      _c("div", { staticClass: "d-flex align-items-center" }, [
        _c("p", { staticClass: "font-weight-bold" }, [
          _vm._v(_vm._s(_vm.DisplayAddress(_vm.value.ownerAddress)))
        ]),
        _c(
          "button",
          {
            staticClass: "btn btn-light btn-sm ml-2",
            attrs: {
              variant: "light",
              size: "sm",
              "data-test-hook": "copy-address-button-owner-info-tab"
            },
            on: {
              click: function($event) {
                _vm.copyToClipboard(_vm.DisplayAddress(_vm.value.ownerAddress))
              }
            }
          },
          [_c("i", { staticClass: "far fa-copy colorOrange" })]
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }