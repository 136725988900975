var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-12 col-md-6" }, [
      _c("h1", [_vm._v("Status")]),
      _c(
        "div",
        { staticClass: "d-flex align-items-center mb-3" },
        [
          _vm._v(
            " " + _vm._s(_vm._f("formatLabelNoTranslation")("Status")) + " "
          ),
          _c("StatusIcon", {
            attrs: {
              apparatusStatus: _vm.currentStatus.apparatusStatus,
              isConnected: _vm.currentStatus.isConnected,
              showWarning: _vm.currentStatus.showWarning,
              small: true,
              deviceType:
                _vm.deviceType == null
                  ? _vm.hsbLteDeviceType
                  : _vm.deviceType == _vm.hsbG3DeviceType
                  ? _vm.networkType
                  : _vm.deviceType
            }
          })
        ],
        1
      ),
      _c("div", { staticClass: "mb-3" }, [
        _c("p", [
          _vm._v(
            " " + _vm._s(_vm._f("formatLabelNoTranslation")("Alarm")) + " "
          ),
          _c("span", { staticClass: "font-weight-bold" }, [
            _vm._v(" " + _vm._s(_vm.currentStatus.alarm || "N/A"))
          ])
        ])
      ]),
      _c("div", { staticClass: "mb-3" }, [
        _c("p", [
          _vm._v(
            " " + _vm._s(_vm._f("formatLabelNoTranslation")("ECode")) + " "
          ),
          _c("span", { staticClass: "font-weight-bold" }, [
            _vm._v(" " + _vm._s(_vm.currentStatus.eCode || "N/A"))
          ])
        ])
      ]),
      _c("div", { staticClass: "mb-3" }, [
        _c("p", [
          _vm._v(
            " " +
              _vm._s(_vm._f("formatLabelNoTranslation")("Battery Voltage")) +
              " "
          ),
          _c("span", { staticClass: "font-weight-bold" }, [
            _vm._v(" " + _vm._s(_vm.currentStatus.batteryVoltage || "N/A"))
          ])
        ])
      ]),
      _c("div", { staticClass: "mb-3" }, [
        _c("p", [
          _vm._v(" " + _vm._s(_vm._f("formatLabelNoTranslation")("RPM")) + " "),
          _c("span", { staticClass: "font-weight-bold" }, [
            _vm._v(" " + _vm._s(_vm.currentStatus.rpm || "N/A"))
          ])
        ])
      ]),
      _c("div", { staticClass: "mb-3" }, [
        _c("p", [
          _vm._v(
            " " + _vm._s(_vm._f("formatLabelNoTranslation")("Frequency")) + " "
          ),
          _c("span", { staticClass: "font-weight-bold" }, [
            _vm._v(" " + _vm._s(_vm.currentStatus.frequency || "N/A"))
          ])
        ])
      ]),
      _c("div", { staticClass: "mb-3" }, [
        _c("p", [
          _vm._v(
            " " +
              _vm._s(_vm._f("formatLabelNoTranslation")("Utility Voltage")) +
              " "
          ),
          _c("span", { staticClass: "font-weight-bold" }, [
            _vm._v(" " + _vm._s(_vm.currentStatus.utilityVoltage || "N/A"))
          ])
        ])
      ]),
      _c("div", { staticClass: "mb-3" }, [
        _c("p", [
          _vm._v(
            " " +
              _vm._s(_vm._f("formatLabelNoTranslation")("Generator Voltage")) +
              " "
          ),
          _c("span", { staticClass: "font-weight-bold" }, [
            _vm._v(" " + _vm._s(_vm.currentStatus.generatorVoltage || "N/A"))
          ])
        ])
      ]),
      _c("div", { staticClass: "mb-3" }, [
        _c("p", [
          _vm._v(
            " " + _vm._s(_vm._f("formatLabelNoTranslation")("Run Hours")) + " "
          ),
          _c("span", { staticClass: "font-weight-bold" }, [
            _vm._v(" " + _vm._s(_vm.currentStatus.runHours || "N/A"))
          ])
        ])
      ])
    ]),
    _c("div", { staticClass: "col-12 col-md-6" }, [
      _c("h1", [_vm._v("Maintenance")]),
      _c("div", { staticClass: "mb-3" }, [
        _c("p", [
          _vm._v(
            " " +
              _vm._s(
                _vm._f("formatLabelNoTranslation")("Inspect Air Filter Date")
              ) +
              " "
          ),
          _c("span", { staticClass: "font-weight-bold" }, [
            _vm._v(
              " " +
                _vm._s(_vm.displayShortDate(_vm.currentStatus.airFilterDate))
            )
          ])
        ])
      ]),
      _c("div", { staticClass: "mb-3" }, [
        _c("p", [
          _vm._v(
            " " +
              _vm._s(
                _vm._f("formatLabelNoTranslation")("Inspect Air Filter Hours")
              ) +
              " "
          ),
          _c("span", { staticClass: "font-weight-bold" }, [
            _vm._v(" " + _vm._s(_vm.currentStatus.airFilterHours || "N/A"))
          ])
        ])
      ]),
      _c("div", { staticClass: "mb-3" }, [
        _c("p", [
          _vm._v(
            " " +
              _vm._s(
                _vm._f("formatLabelNoTranslation")("Inspect Oil Filter Date")
              ) +
              " "
          ),
          _c("span", { staticClass: "font-weight-bold" }, [
            _vm._v(
              " " +
                _vm._s(_vm.displayShortDate(_vm.currentStatus.oilFilterDate))
            )
          ])
        ])
      ]),
      _c("div", { staticClass: "mb-3" }, [
        _c("p", [
          _vm._v(
            " " +
              _vm._s(
                _vm._f("formatLabelNoTranslation")("Inspect Oil Filter Hours")
              ) +
              " "
          ),
          _c("span", { staticClass: "font-weight-bold" }, [
            _vm._v(" " + _vm._s(_vm.currentStatus.oilFilterHours || "N/A"))
          ])
        ])
      ]),
      _c("div", { staticClass: "mb-3" }, [
        _c("p", [
          _vm._v(
            " " +
              _vm._s(
                _vm._f("formatLabelNoTranslation")("Inspect Spark Plugs Date")
              ) +
              " "
          ),
          _c("span", { staticClass: "font-weight-bold" }, [
            _vm._v(
              " " +
                _vm._s(_vm.displayShortDate(_vm.currentStatus.sparkPlugDate))
            )
          ])
        ])
      ]),
      _c("div", { staticClass: "mb-3" }, [
        _c("p", [
          _vm._v(
            " " +
              _vm._s(
                _vm._f("formatLabelNoTranslation")("Inspect Spark Plugs Hours")
              ) +
              " "
          ),
          _c("span", { staticClass: "font-weight-bold" }, [
            _vm._v(" " + _vm._s(_vm.currentStatus.sparkPlugHours || "N/A"))
          ])
        ])
      ]),
      _c("div", { staticClass: "mb-3" }, [
        _c("p", [
          _vm._v(
            " " +
              _vm._s(
                _vm._f("formatLabelNoTranslation")("Inspect Battery Date")
              ) +
              " "
          ),
          _c("span", { staticClass: "font-weight-bold" }, [
            _vm._v(
              " " + _vm._s(_vm.displayShortDate(_vm.currentStatus.batteryDate))
            )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }