<template>
  <div>
    <Modal :modalId="'deviceSwapModal-newDeviceInfo' + oldDevice.apparatusId" size="modal-xl">
      <ModalBody>
        <div class="card no-border">
          <div class="row">
            <div class="col">
              <h1>Replace Device</h1>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <p>
                Please specify the new device type below. Depending on the New Device Type Specified, the MAC address
                and (For Wi-Fi) or Device Serial Number (For 4G LTE Accessories or Wi-Fi/Ethernet Accessories) will be
                required.
                <span class="pre-swap-warning">
                  Please note: the new device MUST be installed and communicating prior to performing the device swap.
                </span>
              </p>
            </div>
          </div>
          <div class="row mt-2 mb-2">
            <div class="col">
              {{ 'Old Device Type' | formatLabelNoTranslation }}
              {{ DisplayOldDeviceType() }}
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-6">
              <div class="form-group" role="group" id="newDeviceType-group">
                <label for="newDevicetype" class="d-block">New Device Type</label>
                <select id="newDevicetype" class="custom-select" v-model="form.newDeviceType">
                  <option v-for="(deviceType, index) in deviceTypes" :key="index" :value="deviceType.value">
                    {{ deviceType.text }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-6">
              <div class="form-group" role="group" id="mobileLinkDeviceIdentifier-group">
                <label for="mobileLinkDeviceIdentifier">Mobile Link Device Identifier</label>
                <div>
                  <input
                    v-if="form.newDeviceType == 'wifi'"
                    id="mobileLinkDeviceIdenifier"
                    type="text"
                    class="form-control"
                    maxlength="17"
                    v-mask="'NN:NN:NN:NN:NN:NN'"
                    v-model="form.mobileLinkDeviceIdentifier"
                  />
                  <input
                    v-else-if="form.newDeviceType == hsbG3DeviceType"
                    id="mobileLinkDeviceIdentifier"
                    type="text"
                    class="form-control"
                    maxlength="9"
                    v-model="form.mobileLinkDeviceIdentifier"
                  />
                  <input
                    v-else
                    id="mobileLinkDeviceIdenifier"
                    type="text"
                    class="form-control"
                    maxlength="10"
                    v-model="form.mobileLinkDeviceIdentifier"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </ModalBody>

      <ModalFooter>
        <button
          type="button"
          class="btn btn-secondary mr-2"
          @click="ResetModal()"
          data-test-hook="cancel-button-newDeviceInfo-device-swap"
        >
          Cancel
        </button>
        <button
          type="button"
          class="btn btn-primary"
          :disabled="CanSwap()"
          @click="validateDevice()"
          data-test-hook="submit-button-newDeviceInfo-device-swap"
        >
          Submit
          <div v-if="validatingDevice" class="spinner-border text-muted ml-2"></div>
        </button>
      </ModalFooter>
    </Modal>

    <!-- Confirm Device Information -->
    <Modal :modalId="'deviceSwapModal-confirmDeviceInfo' + oldDevice.apparatusId">
      <div class="modal-header">
        <div class="row align-items-center">
          <div class="col-auto">
            <i
              class="fas fa-chevron-left back-chevron-button hoverPointer"
              @click="
                closeModal('deviceSwapModal-confirmDeviceInfo' + oldDevice.apparatusId),
                  openModal('deviceSwapModal-newDeviceInfo' + oldDevice.apparatusId)
              "
            />
          </div>
          <div class="col">
            <h2 class="modal-title">
              Confirm Device Information
            </h2>
          </div>
        </div>
      </div>
      <ModalBody>
        <div class="row">
          <div class="col">
            <h5>Device Type: {{ form.newDeviceType === hsbG3DeviceType ? 'G3' : form.newDeviceType }}</h5>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <h5>Device Identifier: {{ form.mobileLinkDeviceIdentifier }}</h5>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <button
          type="button"
          class="btn btn-outline-danger"
          @click="ResetModal()"
          data-test-hook="cancel-button-confirmDeviceInfo-device-swap"
        >
          CANCEL
        </button>
        <button
          type="button"
          class="btn btn-primary"
          @click="submitSwap()"
          data-test-hook="confirm-button-confirmDeviceInfo-device-swap"
        >
          CONFIRM
          <div v-if="swapping" class="spinner-border text-muted ml-2"></div>
        </button>
      </ModalFooter>
    </Modal>

    <!-- Success modal -->
    <Modal :modalId="'deviceSwapModal-swapSuccess' + oldDevice.apparatusId">
      <div class="modal-header">
        <div class="row align-items-center">
          <div class="col-auto">
            <i
              class="fas fa-chevron-left back-chevron-button hoverPointer"
              @click="
                closeModal('deviceSwapModal-swapSuccess' + oldDevice.apparatusId),
                  openModal('deviceSwapModal-paymentInformation' + oldDevice.apparatusId)
              "
            />
          </div>
          <div class="col">
            <h2 class="modal-title">
              Success!
            </h2>
          </div>
        </div>
      </div>
      <ModalBody>
        <div class="col">
          <h5>Please clear this search, and search for the new device:</h5>
        </div>
        <div class="row">
          <div class="col" v-if="validatedDeviceInfo">
            <h3>
              {{ validatedDeviceInfo.deviceId }}
            </h3>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <button
          type="button"
          class="btn btn-primary"
          @click="ResetModal()"
          data-test-hook="close-button-swapSuccess-device-swap"
        >
          Close
        </button>
      </ModalFooter>
    </Modal>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import * as Constants from '@/constants'

export default {
  props: ['oldDevice'],
  data() {
    return {
      form: {
        newDeviceType: null,
        mobileLinkDeviceIdentifier: null
      },
      validatingDevice: false,
      validatedDeviceInfo: null,
      gettingSubscriptions: false,
      availableSubscriptions: null,
      existingPaymentInfo: null,
      selectedProduct: null,
      paying: false,
      useExistingPaymentInfo: true,
      creditCardNumber: null,
      fullExpiryDate: null,
      cvv: null,
      creditCardIsValid: null,
      cvvIsValid: null,
      expiryDateIsValid: null,
      gettingPaymentInfo: true,
      swapping: false,
      organizationSubscriptionDetailsData: null
    }
  },
  computed: {
    deviceTypes: function() {
      let devices = [
        { value: 'wifi', text: 'WiFi' },
        { value: 'lte', text: 'Lte' },
        { value: 'eth', text: 'WiFi/Ethernet' },
        { value: Constants.HsbG3DeviceType, text: 'G3' }
      ]
      return devices
    },
    hsbG3DeviceType() {
      return Constants.HsbG3DeviceType
    }
  },
  methods: {
    submitSwap() {
      this.swapping = true
      let swapRequest = {
        organizationId: this.oldDevice.organizationId,
        apparatusId: this.oldDevice.apparatusId,
        deviceId: this.validatedDeviceInfo.deviceId,
        deviceType: this.validatedDeviceInfo.deviceType
      }

      this.$store
        .dispatch('getSubscriptionDetails', this.oldDevice.organizationId)
        .then(response => {
          var organizationSubscriptionDetailsData = response.data
          if (organizationSubscriptionDetailsData?.legacyBillingAccount?.products?.some(
            product => product?.apparatusId === this.oldDevice.apparatusId && product?.status !== Constants.SubscriptionCancelled)) {
            this.$store.dispatch('displayToast', {
              title: this.$t('xError'),
              message: 'Device Swaps are not able to be performed on legacy subscriptions at this time. Open a ML Zendesk ticket with the customer and device information and let the customer know that someone will contact them with the available options.',
              variant: 'danger'
            })
          } else {
            this.$store
              .dispatch('swapDevice', swapRequest)
              .then(response => {
                this.swapping = false
                this.paying = false
                if (response.data.success) {
                  this.closeModal('deviceSwapModal-newSubscriptionInfo' + this.oldDevice.apparatusId)
                  this.closeModal('deviceSwapModal-paymentInformation' + this.oldDevice.apparatusId)
                  this.openModal('deviceSwapModal-swapSuccess' + this.oldDevice.apparatusId)
                } else {
                  this.$store.dispatch('displayToast', {
                    title: this.$t('xError'),
                    message: 'Something unexpected went wrong.',
                    variant: 'danger'
                  })
                }
              })
              .catch(error => {
                if (error.response.data.status == 401) {
                  this.$store.dispatch('displayToast', {
                    title: this.$t('xError'),
                    message: 'Your session has expired, please re-log in.',
                    variant: 'danger'
                  })
                  this.forceSignOut()
                } else {
                  this.paying = false
                  this.swapping = false
                  this.$store.dispatch('displayToast', {
                    title: this.$t('xError'),
                    message: error.response.data.message,
                    variant: 'danger'
                  })
                }
              })
          }
        })
    },
    validateDevice() {
      this.validatingDevice = true
      this.$store
        .dispatch('ValidateDevice', {
          deviceType: this.form.newDeviceType,
          macAddress: this.form.mobileLinkDeviceIdentifier,
          searchLabel: this.form.mobileLinkDeviceIdentifier,
          shortDeviceId: this.form.mobileLinkDeviceIdentifier
        })
        .then(response => {
          this.validatedDeviceInfo = _.cloneDeep({ ...response.data })
          this.validatingDevice = false
          if (response.data.isValid) {
            this.closeModal('deviceSwapModal-newDeviceInfo' + this.oldDevice.apparatusId)
            this.openModal('deviceSwapModal-confirmDeviceInfo' + this.oldDevice.apparatusId)
          } else {
            this.$store.dispatch('displayToast', {
              title: this.$t('xError'),
              message: 'An unexpected error occurred',
              variant: 'danger'
            })
          }
        })
        .catch(error => {
          if (error.response.data.status == 401) {
            this.$store.dispatch('displayToast', {
              title: this.$t('xError'),
              message: 'Your session has expired, please re-log in.',
              variant: 'danger'
            })
            this.forceSignOut()
          } else {
            this.validatingDevice = false
            if (error && error.response) {
              this.validatedDeviceInfo = error.response.data
              this.$store.dispatch('displayToast', {
                title: this.$t('xError'),
                message: this.validatedDeviceInfo.message,
                variant: 'danger'
              })
            }
          }
        })
    },
    CanSwap() {
      if (this.form.newDeviceType && this.form.mobileLinkDeviceIdentifier) {
        return false
      }
      return true
    },
    SwapDevice() {
      this.$store.dispatch('displayToast', { title: this.$t('xError'), message: null, variant: 'danger' })
    },
    ResetModal() {
      this.form = {
        newDeviceType: null,
        mobileLinkDeviceIdentifier: null
      }
      this.validatingDevice = false
      this.validatedDeviceInfo = null
      this.gettingSubscriptions = false
      this.availableSubscriptions = null
      this.existingPaymentInfo = null
      this.selectedProduct = null
      this.paying = false
      this.useExistingPaymentInfo = true
      this.creditCardNumber = null
      this.fullExpiryDate = null
      this.cvv = null
      this.creditCardIsValid = null
      this.cvvIsValid = null
      this.expiryDateIsValid = null
      this.gettingPaymentInfo = false
      this.swapping = false
      this.closeModal('deviceSwapModal-newDeviceInfo' + this.oldDevice.apparatusId)
      this.closeModal('deviceSwapModal-confirmDeviceInfo' + this.oldDevice.apparatusId)
      this.closeModal('deviceSwapModal-newSubscriptionInfo' + this.oldDevice.apparatusId)
      this.closeModal('deviceSwapModal-paymentInformation' + this.oldDevice.apparatusId)
      this.closeModal('deviceSwapModal-swapSuccess' + this.oldDevice.apparatusId)
    },
    DisplayOldDeviceType() {
      if (this.oldDevice.deviceInfo.deviceType === 'lte') {
        return 'Lte'
      } else if (this.oldDevice.deviceInfo.deviceType === 'wifi') {
        return 'WiFi'
      } else if (this.oldDevice.deviceInfo.deviceType === 'eth') {
        return 'WiFi/Ethernet'
      } else if (this.oldDevice.deviceInfo.deviceType === 'cdma') {
        return 'CDMA'
      } else if (this.oldDevice.deviceInfo.deviceType === Constants.HsbG3DeviceType) {
        return 'G3'
      }
    },
  },
}
</script>
<style scoped>
.pre-swap-warning {
  font-weight: bold;
  font-style: italic;
  color: var(--yellow-900);
}
.subscription-card {
  max-width: 300px;
}
</style>
