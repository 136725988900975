var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    !_vm.manualEntry
      ? _c(
          "div",
          [
            _c(
              "b-form-group",
              {
                attrs: {
                  id: "address-group",
                  label: _vm.$t("xAddress"),
                  "label-for": "address",
                  "data-test-hook": "addressSearchHeader"
                }
              },
              [
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "click-outside",
                        rawName: "v-click-outside",
                        value: _vm.hideResults,
                        expression: "hideResults"
                      }
                    ]
                  },
                  [
                    _c(
                      "b-input-group",
                      [
                        _c("b-form-input", {
                          attrs: {
                            type: "text",
                            autocomplete: "new-password",
                            "data-test-hook": "AddressInputField"
                          },
                          on: {
                            keyup: function($event) {
                              return _vm.typeAheadSearch()
                            }
                          },
                          model: {
                            value: _vm.freeformAddress,
                            callback: function($$v) {
                              _vm.freeformAddress = $$v
                            },
                            expression: "freeformAddress"
                          }
                        }),
                        _c(
                          "b-input-group-append",
                          [
                            _c(
                              "b-button",
                              {
                                staticClass: "my-0 py-0",
                                attrs: {
                                  variant: "warning",
                                  "data-test-hook": "AddressSearchBtn"
                                },
                                on: {
                                  click: function($event) {
                                    !_vm.awaitingSearch
                                      ? _vm.typeAheadSearch()
                                      : null
                                  }
                                }
                              },
                              [
                                _vm.awaitingSearch
                                  ? _c("b-spinner", {
                                      staticStyle: {
                                        width: "1.5rem",
                                        height: "1.5rem"
                                      },
                                      attrs: {
                                        "data-test-hook": "AddressSearchSpinner"
                                      }
                                    })
                                  : _c("i", { staticClass: "fas fa-search" })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          [
                            _vm.addressResults
                              ? _c(
                                  "b-list-group",
                                  { staticClass: "suggested-input-list" },
                                  [
                                    _vm._l(_vm.addressResults, function(
                                      address,
                                      index
                                    ) {
                                      return _c(
                                        "b-list-group-item",
                                        {
                                          key: index,
                                          staticClass: "hoverPointer",
                                          attrs: {
                                            "data-test-hook": "AddressResult"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.setSelectedAddress(
                                                address
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                address.address.freeformAddress
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    }),
                                    _c(
                                      "b-list-group-item",
                                      {
                                        staticClass:
                                          "hoverPointer text-blue-500",
                                        attrs: {
                                          "data-test-hook": "ManualEntryOption"
                                        },
                                        on: {
                                          click: function($event) {
                                            ;(_vm.selectedPostalCode = null),
                                              (_vm.selectedCountry = null),
                                              (_vm.manualEntry = true)
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("xDontSeeAddress")) +
                                            " "
                                        )
                                      ]
                                    )
                                  ],
                                  2
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            ),
            _vm.selectedAddress
              ? _c(
                  "b-row",
                  { staticClass: "mt-2" },
                  [
                    _c(
                      "b-col",
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              id: "line2-group",
                              label: _vm.$t("xAddressLine2Optional"),
                              "label-for": "line2",
                              "data-test-hook": "addressLine2Header"
                            }
                          },
                          [
                            _c("b-form-input", {
                              attrs: {
                                id: "line2",
                                type: "text",
                                "data-test-hook": "addressLine2"
                              },
                              model: {
                                value: _vm.selectedAddress.line2,
                                callback: function($$v) {
                                  _vm.$set(_vm.selectedAddress, "line2", $$v)
                                },
                                expression: "selectedAddress.line2"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm.selectedAddress &&
            _vm.selectedAddress.line1 &&
            _vm.selectedAddress.city &&
            _vm.selectedAddress.region &&
            _vm.selectedAddress.postalCode
              ? _c(
                  "div",
                  [
                    _c(
                      "b-row",
                      [
                        _c("b-col", [
                          _c(
                            "h5",
                            {
                              staticClass: "mb-2 mt-3",
                              attrs: { "data-test-hook": "selectedAddrHeader" }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm._f("formatLabel")("xSelectedAddress")
                                )
                              )
                            ]
                          ),
                          _c("div", [
                            _c(
                              "p",
                              {
                                attrs: { "data-test-hook": "selectedAddrLine1" }
                              },
                              [_vm._v(_vm._s(_vm.selectedAddress.line1))]
                            ),
                            _c(
                              "p",
                              {
                                attrs: {
                                  "data-test-hook":
                                    "selectedAddrCityRegionPostalCode"
                                }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.selectedAddress.city +
                                        ", " +
                                        _vm.selectedAddress.region +
                                        " " +
                                        _vm.selectedAddress.postalCode
                                    ) +
                                    " "
                                )
                              ]
                            )
                          ])
                        ])
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e()
          ],
          1
        )
      : _c(
          "div",
          [
            _c(
              "b-row",
              [
                _c(
                  "b-col",
                  [
                    _c(
                      "b-form-group",
                      {
                        attrs: {
                          id: "country-group",
                          label: _vm.$t("xCountry"),
                          "label-for": "country"
                        }
                      },
                      [
                        _c("b-form-select", {
                          attrs: {
                            id: "country",
                            options: _vm.countries,
                            state: _vm.validState(_vm.$v.selectedCountry),
                            placeholder: _vm.$t("xCountry"),
                            "aria-describedby": "country-invalid"
                          },
                          model: {
                            value: _vm.$v.selectedCountry.$model,
                            callback: function($$v) {
                              _vm.$set(_vm.$v.selectedCountry, "$model", $$v)
                            },
                            expression: "$v.selectedCountry.$model"
                          }
                        }),
                        _c(
                          "b-form-invalid-feedback",
                          { attrs: { id: "country-invalid" } },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.$t("xRequiredField")) + ". "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "b-row",
              [
                _c(
                  "b-col",
                  [
                    _c(
                      "b-form-group",
                      {
                        attrs: {
                          id: "postalCode-group",
                          label: _vm.$t("xZipCode"),
                          "label-for": "postalCode"
                        }
                      },
                      [
                        _c("b-form-input", {
                          attrs: {
                            id: "postalCode",
                            maxlength: _vm.postalCodeLimit,
                            type: "text",
                            state: _vm.validState(_vm.$v.selectedPostalCode),
                            placeholder: _vm.$t("xZipCode"),
                            debounce: "500",
                            "aria-describedby": "postalCode-invalid"
                          },
                          model: {
                            value: _vm.$v.selectedPostalCode.$model,
                            callback: function($$v) {
                              _vm.$set(_vm.$v.selectedPostalCode, "$model", $$v)
                            },
                            expression: "$v.selectedPostalCode.$model"
                          }
                        }),
                        _c(
                          "b-form-invalid-feedback",
                          { attrs: { id: "postalCode-invalid" } },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.$t("xZipCodeInvalid")) + ". "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _vm.showVerifyPostalCode
              ? _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      [
                        _c(
                          "b-button",
                          {
                            attrs: {
                              variant: "outline-warning",
                              disabled:
                                !_vm.selectedCountry || !_vm.selectedPostalCode,
                              block: ""
                            },
                            on: {
                              click: function($event) {
                                return _vm.verifyPostalCode(
                                  _vm.selectedPostalCode,
                                  _vm.selectedCountry
                                )
                              }
                            }
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.$t("xVerifyPostalCode")) + " "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm.showRegionStateAddress &&
            _vm.countryStates.value[_vm.selectedAddress.country].countryRegions
              .length > 0
              ? _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              id: "region-group",
                              label: "State / Region",
                              "label-for": "region"
                            }
                          },
                          [
                            _c("b-form-select", {
                              attrs: {
                                id: "region",
                                options:
                                  _vm.selectedCountry == "US" ||
                                  _vm.selectedCountry == "CA"
                                    ? _vm.countryRegions.map(s => ({
                                        text: s.name,
                                        value: s.abbreviation
                                      }))
                                    : _vm.countryStates.value[
                                        _vm.selectedCountry
                                      ].countryRegions.map(s => ({
                                        text: s.name,
                                        value: s.abbreviation
                                      })),
                                placeholder: _vm.$t("xStateRegion"),
                                "aria-describedby": "region-invalid"
                              },
                              model: {
                                value: _vm.selectedAddress.region,
                                callback: function($$v) {
                                  _vm.$set(_vm.selectedAddress, "region", $$v)
                                },
                                expression: "selectedAddress.region"
                              }
                            }),
                            _c(
                              "b-form-invalid-feedback",
                              { attrs: { id: "region-invalid" } },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("xRequiredField")) + ". "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm.showRegionStateAddress
              ? _c(
                  "b-row",
                  [
                    _vm.selectedCountry == "US" || _vm.selectedCountry == "CA"
                      ? _c(
                          "b-col",
                          [
                            _c(
                              "b-form-group",
                              {
                                attrs: {
                                  id: "city-group",
                                  label: _vm.$t("xCity"),
                                  "label-for": "city"
                                }
                              },
                              [
                                _c("b-form-select", {
                                  attrs: {
                                    id: "city",
                                    options: _vm.citySelectList.map(s => ({
                                      text: s,
                                      value: s
                                    })),
                                    placeholder: _vm.$t("xCity")
                                  },
                                  model: {
                                    value: _vm.$v.selectedAddress.city.$model,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.$v.selectedAddress.city,
                                        "$model",
                                        $$v
                                      )
                                    },
                                    expression: "$v.selectedAddress.city.$model"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _c(
                          "b-col",
                          [
                            _c(
                              "b-form-group",
                              {
                                attrs: {
                                  id: "city-group",
                                  label: _vm.$t("xCity"),
                                  "label-for": "city"
                                }
                              },
                              [
                                _c("b-form-input", {
                                  attrs: {
                                    id: "city",
                                    maxlength: "50",
                                    type: "text",
                                    state: _vm.validState(
                                      _vm.$v.selectedAddress.city
                                    ),
                                    "aria-describedby": "city-invalid"
                                  },
                                  model: {
                                    value: _vm.$v.selectedAddress.city.$model,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.$v.selectedAddress.city,
                                        "$model",
                                        $$v
                                      )
                                    },
                                    expression: "$v.selectedAddress.city.$model"
                                  }
                                }),
                                _c(
                                  "b-form-invalid-feedback",
                                  { attrs: { id: "city-invalid" } },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("xRequiredField")) +
                                        ". "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                  ],
                  1
                )
              : _vm._e(),
            _vm.showRegionStateAddress
              ? _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              id: "line1-group",
                              label: _vm.$t("xAddressLine1"),
                              "label-for": "line1"
                            }
                          },
                          [
                            _c("b-form-input", {
                              attrs: {
                                id: "line1",
                                maxlength: "50",
                                type: "text",
                                state: _vm.validState(
                                  _vm.$v.selectedAddress.line1
                                ),
                                "aria-describedby": "line1-invalid"
                              },
                              model: {
                                value: _vm.$v.selectedAddress.line1.$model,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.$v.selectedAddress.line1,
                                    "$model",
                                    $$v
                                  )
                                },
                                expression: "$v.selectedAddress.line1.$model"
                              }
                            }),
                            _c(
                              "b-form-invalid-feedback",
                              { attrs: { id: "line1-invalid" } },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("xRequiredField")) + ". "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm.showRegionStateAddress
              ? _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              id: "line2-group",
                              label: _vm.$t("xAddressLine2"),
                              "label-for": "line2"
                            }
                          },
                          [
                            _c("b-form-input", {
                              attrs: { id: "line2", type: "text" },
                              model: {
                                value: _vm.selectedAddress.line2,
                                callback: function($$v) {
                                  _vm.$set(_vm.selectedAddress, "line2", $$v)
                                },
                                expression: "selectedAddress.line2"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e()
          ],
          1
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }