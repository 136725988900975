<template>
  <div>
    <div v-if="isLoading" class="d-flex justify-content-center mb-3 pt-4">
      <b-spinner style="width: 5rem; height: 5rem" variant="warning" label="Spinning"></b-spinner>
    </div>
    <div v-else>
      <div class="ml-3 mr-3" rounded="lg" id="overlay">
        <div class="row no-gutters mb-2 mt-4">
          <div class="col">
            <h1 class="mb-0 pb-0" data-test-hook="pageTitle">
              <i class="far fa-badge-dollar pr-1" data-test-hook="titleIcon" />
              <span data-test-hook="subscription-plan-details">{{ $t('xManageYourPlan') }}</span>
            </h1>
          </div>
        </div>

        <div v-if="!doesAccountHaveApparatus()">
          <div class="card mt-3" header-bg-variant="white">
            <div class="card-body">
              <div class="row mb-2">
                <div class="col">
                  <span data-test-hook="plan-cost-header-text">
                    {{ $t('xPlanStatus') }}
                  </span>
                </div>
              </div>
              <div class="row mb-1">
                <div class="col">
                  <strong data-test-hook="plan-cost">
                    {{ $t('xCurrentlyNotSubscribedToPlanPleaseAddProduct') }}
                  </strong>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="singleHomePremiumApparatuses?.length > 0">
          <div v-for="(apparatus, index) in singleHomePremiumApparatuses" :key="index">
            <div
              v-if="
                apparatus.subscriptionProductId === dealerManagedProductId ||
                  apparatus.subscriptionProductId === tankUtilityDealerManagedProductId
              "
            >
              <div class="card mt-3" header-bg-variant="white">
                <div class="card-body">
                  <div class="row mb-4">
                    <div class="col-auto">
                      <h5 class="mb-0 pb-0">
                        <span data-test-hook="product-name">
                          {{ apparatus.subscriptionProductName }}
                        </span>
                      </h5>
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col">
                      <span data-test-hook="plan-cost-header-text">
                        {{ $t('xPlanStatus') }}
                      </span>
                    </div>
                  </div>
                  <h5 class="mb-3">{{ apparatus.apparatuses[0].name }}&nbsp;{{ $t('xIsManagedByYourDealer') }}</h5>
                  <button
                    type="button"
                    class="btn btn-warning"
                    @click="openDealerInfo(apparatus.apparatuses[0].id)"
                    data-test-hook="OpenDealerInfoBtn"
                  >
                    {{ $t('xViewDealerInformation') }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="hasProductsNoSubscription">
          <div class="card mt-3" header-bg-variant="white">
            <div class="card-body">
              <div class="row mb-2">
                <div class="col">
                  <span data-test-hook="plan-cost-header-text">
                    {{ $t('xPlanStatus') }}
                  </span>
                </div>
              </div>
              <div class="row mb-4">
                <div class="col">
                  <strong data-test-hook="plan-cost">
                    {{ $t('xCurrentlyNotSubscribedToPlan') }}
                  </strong>
                </div>
              </div>
              <button
                type="button"
                class="btn btn-warning"
                @click="openSubscribePremiumMonitoring()"
                data-test-hook="openSubscribePremiumMonitoringBtn"
              >
                {{ $t('xUpgradePremium') }}
              </button>
            </div>
          </div>
        </div>

        <div v-for="(apparatus, index) in legacySubscriptionList" :key="index">
          <div v-if="apparatus != undefined && apparatus.isDealerManaged">
            <div class="card mt-3" header-bg-variant="white">
              <div class="card-body">
                <div class="row mb-4">
                  <div class="col-auto">
                    <h5 class="mb-0 pb-0">
                      <span data-test-hook="product-name">
                        {{ apparatus.subscriptionProductName }}
                      </span>
                    </h5>
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col">
                    <span data-test-hook="plan-cost-header-text">
                      {{ $t('xPlanStatus') }}
                    </span>
                  </div>
                </div>
                <h5 class="mb-3">{{ apparatus.apparatusName }}&nbsp;{{ $t('xIsManagedByYourDealer') }}</h5>
                <button
                  type="button"
                  class="btn btn-warning"
                  @click="openDealerInfo(apparatus.apparatusId)"
                  data-test-hook="OpenDealerInfoBtn"
                >
                  {{ $t('xViewDealerInformation') }}
                </button>
              </div>
            </div>
          </div>
          <div v-if="apparatus != undefined && !apparatus.isDealerManaged">
            <div class="card mt-3" header-bg-variant="white">
              <div class="card-body">
                <div class="row mb-4">
                  <div class="col-auto">
                    <h5 class="mb-0 pb-0">
                      <span data-test-hook="product-name">
                        {{ apparatus.subscriptionProductName }}
                      </span>
                    </h5>
                  </div>
                </div>
                <div v-if="apparatus.subscriptionStatus === 2">
                  <div class="row mb-2">
                    <div class="col">
                      <span data-test-hook="billing-date-header-text">
                        {{ $t('xPlanStatus') }}
                      </span>
                    </div>
                  </div>
                  <div class="row mb-4">
                    <div class="col">
                      <strong data-test-hook="billing-date">
                        {{ $t('xPendingCancellation') }}
                      </strong>
                    </div>
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col">
                    <span data-test-hook="plan-cost-header-text">
                      {{ $t('xPlanCost') }}
                    </span>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col">
                    <strong data-test-hook="plan-cost">
                      {{
                        '$' +
                          apparatus.subscriptionCost +
                          ' ' +
                          displayLegacyPlanCostCycle(apparatus.subscriptionDuration)
                      }}
                    </strong>
                  </div>
                </div>
                <div>
                  <div class="row mb-2">
                    <div class="col">
                      <span v-if="apparatus.subscriptionStatus === 2" data-test-hook="billing-date-header-text">
                        {{ $t('xPlanEndDate') }}
                      </span>
                      <span v-else data-test-hook="billing-date-header-text">
                        {{ $t('xNextBillingDate') }}
                      </span>
                    </div>
                  </div>
                  <div class="row mb-4">
                    <div class="col">
                      <strong data-test-hook="billing-date">
                        {{ $moment(apparatus.subscriptionNextBillingDate).format('MM/DD/YYYY') }}
                      </strong>
                    </div>
                  </div>
                </div>
                <div class="accordion" role="tablist" style="width:100%">
                  <div class="accordion-wrapper">
                    <div v-b-toggle="'collapse-legacy-' + apparatus.apparatusId">
                      <div class="row align-items-center no-gutters">
                        <div class="col align-items-center">
                          <i class="fa-regular fa-circle-info pr-2"></i
                          ><strong>{{ $t('xProductsAndFeatures') }}</strong>
                        </div>
                        <div class="col-auto">
                          <i class="fas fa-chevron-down when-closed"></i>
                          <i class="fas fa-chevron-up when-open"></i>
                        </div>
                      </div>
                    </div>
                    <b-collapse :id="'collapse-legacy-' + apparatus.apparatusId">
                      <div class="row">
                        <div class="col">
                          <hr class="mx-0 px-0" />
                        </div>
                      </div>
                      <div class="row mb-2">
                        <div class="col">
                          <p class="mb-0 pb-0">
                            <strong data-test-hook="products-included-text">
                              {{ $t('xProductsIncludedInPlan') }}
                              {{ apparatus.subscriptionProductName }}
                            </strong>
                          </p>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-0">
                          <ul id="productsCoveredTanks" style="list-style-type: none; padding-inline-start: 13px;">
                            <i :class="getApparatusIcon(apparatus.apparatusType)"></i
                            >&nbsp;&nbsp;{{
                              apparatus.apparatusName
                            }}
                          </ul>
                        </div>
                      </div>
                      <div class="row mt-0 mb-2">
                        <div class="col">
                          <p class="mb-0 pb-0">
                            <strong data-test-hook="features-included-text">
                              {{ $t('xFeaturesIncludedInPlan') }}
                              {{ apparatus.subscriptionProductName }}
                            </strong>
                          </p>
                        </div>
                      </div>
                      <div v-if="apparatus.subscriptionProductId == tankUtilityPremiumProductId">
                        <div class="row mb-3">
                          <div class="col icon-and-text">
                            <i class="fa-kit fa-fw fa-tank"></i>
                            <span data-test-hook="features-included-list">
                              {{ $t('xFeaturesIncludedTankUtilitySubscription1') }}
                            </span>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col icon-and-text">
                            <!-- need to change to a list -->
                            <i class="far fa-fw fa-circle-check"></i>
                            <span data-test-hook="features-included-monitor-list">
                              {{ $t('xFeaturesIncludedTankUtilityAnnuallySubscriptionFeature2') }}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div v-else-if="apparatus.subscriptionProductId == wiFiAnnualProductId">
                        <div class="row mb-3">
                          <div class="col icon-and-text">
                            <i class="fa-kit fa-fw fa-generator"></i>
                            <span data-test-hook="features-included-list">
                              {{ $t('xFeaturesIncludedWiFiAnnuallySubscriptionFeature1') }}
                            </span>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col icon-and-text">
                            <i class="far fa-fw fa-circle-check"></i>
                            <span data-test-hook="features-included-monitor-list">
                              {{ $t('xFeaturesIncludedWiFiAnnuallySubscriptionFeature2') }}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div v-else-if="apparatus.subscriptionProductId == wiFiMonthlyProductId">
                        <div class="row mb-3">
                          <div class="col icon-and-text">
                            <i class="fa-kit fa-fw fa-generator"></i>
                            <span data-test-hook="features-included-list">
                              {{ $t('xFeaturesIncludedWiFiAnnuallySubscriptionFeature1') }}
                            </span>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col icon-and-text">
                            <i class="far fa-fw fa-circle-check"></i>
                            <span data-test-hook="features-included-monitor-list">
                              {{ $t('xFeaturesIncludedWiFiAnnuallySubscriptionFeature2') }}
                            </span>
                          </div>
                        </div>
                      </div>
                    </b-collapse>
                  </div>
                  <div v-if="!purchasedOnWeb">
                    <div class="row">
                      <div class="col">
                        <span data-test-hook="manage-plan-text">
                          <!-- need to decide text based on subscription -->
                          {{ $t('xManagePlanOnApple') }}
                        </span>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col">
                        <!-- need to decide link based on subscription -->
                        <a href="https://account.apple.com/account/manage/section/subscriptions">
                          <span data-test-hook="manage-plan-text">
                            {{ 'https://account.apple.com/account/manage/section/subscriptions.' }}
                          </span>
                        </a>
                      </div>
                    </div>
                    <button
                      type="button"
                      class="btn btn-warning"
                      @click="openMobileApp()"
                      data-test-hook="OpenMobileAppBtn"
                    >
                      {{ $t('xOpenMobileApp') }}
                    </button>
                  </div>
                  <div
                    v-else-if="apparatus.subscriptionNextBillingDate != null && apparatus.subscriptionStatus !== 2"
                    class="mt-3"
                  >
                    <button
                      type="button"
                      class="btn btn-warning"
                      @click="
                        isCancelLegacy = true
                        endDate = apparatus.subscriptionNextBillingDate
                        cancelLegacyApparatusId = apparatus.apparatusId
                        cancelLegacyApparatusType = apparatus.apparatusType
                        openModal('cancel-subscription-method')
                      "
                      :title="$t('xManageYourPlanBtn')"
                      data-test-hook="ManageYourPlanBtn"
                    >
                      {{ $t('xManageYourPlanBtn') }}
                    </button>
                  </div>
                  <div class="row mt-3">
                    <div class="col">
                      <p data-test-hook="AutoRenewMsg" class="auto-renew-message text-small">
                        {{ $t('xThisSubscriptionWillAutoRenewShortVersion') }}
                      </p>
                    </div>
                  </div>
                </div>
                <div v-if="apparatus.subscriptionStatus === 2">
                  <b-badge variant="primary" class="mb-2 mt-4 pending-change-badge">{{ $t('xPendingChange') }}</b-badge>
                  <div class="row mb-2">
                    <div class="col">
                      <span data-test-hook="no-plan-status">{{ $t('xPlanStatus') }}</span>
                    </div>
                  </div>
                  <div class="row mb-4">
                    <div class="col">
                      <strong>{{ $t('xNoPlan') }}</strong>
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col">
                      <span data-test-hook="effective-cancel-date">{{ $t('xChangeEffectiveDate') }}</span>
                    </div>
                  </div>
                  <div class="row mb-4">
                    <div class="col">
                      <strong>{{ $moment(apparatus.subscriptionNextBillingDate).format('MM/DD/YYYY') }}</strong>
                    </div>
                  </div>
                  <!-- <div class="accordion" role="tablist" style="width:100%">
                    <div class="accordion-wrapper">
                      <div v-b-toggle.collapse-noPlan>
                        <div class="row align-items-center no-gutters">
                          <div class="col align-items-center">
                            <i class="fa-regular fa-circle-info pr-2"></i
                            ><strong>{{ $t('xProductsAndFeatures') }}</strong>
                          </div>
                          <div class="col-auto">
                            <i class="fas fa-chevron-down when-closed"></i>
                            <i class="fas fa-chevron-up when-open"></i>
                          </div>
                        </div>
                      </div>
                      <b-collapse id="collapse-noPlan">
                        <div class="row">
                          <div class="col">
                            <hr class="mx-0 px-0" />
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col">
                            <p class="mb-0 pb-0">
                              <strong data-test-hook="products-not-included-text">
                                {{ $t('xProductsNotSupported') }}
                              </strong>
                            </p>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-0">
                            <i :class="getApparatusIcon(apparatus.type)"></i>&nbsp;&nbsp;{{ apparatus.name }}
                          </div>
                        </div>
                        <div class="row mt-0 mb-2">
                          <div class="col">
                            <p class="mb-0 pb-0">
                              <strong data-test-hook="features-not-included-text">
                                {{ $t('xFeaturesNotIncluded') }}
                              </strong>
                            </p>
                          </div>
                        </div>
                        <div class="row mb-3">
                          <div class="col icon-and-text">
                            <i class="fa-kit fa-fw fa-generator"></i>
                            <span data-test-hook="features-not-included-list">
                              {{ $t('xFeatureSingleHomePremiumSubscription1') }}
                            </span>
                          </div>
                        </div>
                        <div class="row mb-3">
                          <div class="col icon-and-text">
                            <i class="fa-kit fa-fw fa-tank"></i>
                            <span data-test-hook="features-included-monitor-list">
                              {{ $t('xFeatureSingleHomePremiumSubscription2') }}
                            </span>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col icon-and-text">
                            <i class="far fa-fw fa-circle-check"></i>
                            <span data-test-hook="features-included-monitor-list">
                              {{ $t('xFeatureSingleHomePremiumSubscription3') }}
                            </span>
                          </div>
                        </div>
                      </b-collapse>
                    </div>
                    <div class="row mt-4">
                      <div class="col">
                        <p data-test-hook="AutoRenewMsg" class="auto-renew-message text-small">
                          {{ $t('xThisSubscriptionWillAutoRenewShortVersion') }}
                        </p>
                      </div>
                    </div>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="
            singleHomePremiumApparatuses?.length > 0 &&
              singleHomePremiumApparatuses[0].subscriptionProductId !== dealerManagedProductId &&
              singleHomePremiumApparatuses[0].subscriptionProductId !== tankUtilityDealerManagedProductId
          "
        >
          <div class="card mt-3" header-bg-variant="white">
            <div class="card-body">
              <b-badge
                v-if="singleHomePremiumApparatuses[0].subscriptionStatus === 2"
                variant="light"
                class="current-plan-badge mb-2 border-primary text-primary"
                >{{ $t('xCurrentPlan') }}</b-badge
              >
              <div class="row mb-4">
                <div class="col-auto">
                  <h5 class="mb-0 pb-0">
                    <span data-test-hook="product-name">
                      {{ singleHomePremiumApparatuses[0].subscriptionProductName }}
                    </span>
                  </h5>
                </div>
              </div>
              <div v-if="singleHomePremiumApparatuses[0].subscriptionStatus === 2">
                <div class="row mb-2">
                  <div class="col">
                    <span data-test-hook="billing-date-header-text">
                      {{ $t('xPlanStatus') }}
                    </span>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col">
                    <strong data-test-hook="billing-date">
                      {{ $t('xPendingCancellation') }}
                    </strong>
                  </div>
                </div>
              </div>
              <div class="row mb-2">
                <div class="col">
                  <span data-test-hook="plan-cost-header-text">
                    {{ $t('xPlanCost') }}
                  </span>
                </div>
              </div>
              <div class="row mb-4">
                <div class="col">
                  <strong data-test-hook="plan-cost">
                    {{
                      '$' +
                        singleHomePremiumApparatuses[0].subscriptionCost +
                        ' ' +
                        displayPlanCostCycle(singleHomePremiumApparatuses[0].subscriptionDurationInMonths)
                    }}
                  </strong>
                </div>
              </div>
              <div class="row mb-2">
                <div class="col">
                  <span
                    v-if="singleHomePremiumApparatuses[0].subscriptionStatus === 2"
                    data-test-hook="billing-date-header-text"
                  >
                    {{ $t('xPlanEndDate') }}
                  </span>
                  <span v-else data-test-hook="billing-date-header-text">
                    {{ $t('xNextBillingDate') }}
                  </span>
                </div>
              </div>
              <div class="row mb-4">
                <div class="col">
                  <strong data-test-hook="billing-date">
                    {{ $moment(singleHomePremiumApparatuses[0].subscriptionRenewalDate).format('MM/DD/YYYY') }}
                  </strong>
                </div>
              </div>
              <div class="accordion" role="tablist" style="width:100%;">
                <div class="accordion-wrapper" style="border: 1px solid lightgrey; padding: 10px; border-radius: 10px;">
                  <div v-b-toggle.collapse-singleHomePremium>
                    <div class="row align-items-center no-gutters">
                      <div class="col align-items-center">
                        <i class="fa-regular fa-circle-info pr-2"></i><strong>{{ $t('xProductsAndFeatures') }}</strong>
                      </div>
                      <div class="col-auto">
                        <i class="fas fa-chevron-down when-closed"></i>
                        <i class="fas fa-chevron-up when-open"></i>
                      </div>
                    </div>
                  </div>
                  <b-collapse id="collapse-singleHomePremium">
                    <div class="row">
                      <div class="col">
                        <hr class="mx-0 px-0" />
                      </div>
                    </div>
                    <div class="row mb-2">
                      <div class="col">
                        <p class="mb-0 pb-0">
                          <strong data-test-hook="products-included-text">
                            {{ $t('xProductsIncludedInPlan') }}
                            {{ singleHomePremiumApparatuses[0].subscriptionProductName }}
                          </strong>
                        </p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-0">
                        <ul
                          id="productsCoveredSingleHomePremium"
                          style="list-style-type: none; padding-inline-start: 13px;"
                        >
                          <li v-for="(apparatus, index) in singleHomePremiumApparatuses[0].apparatuses" :key="index">
                            <i :class="getApparatusIcon(apparatus.type)"></i>&nbsp;&nbsp;{{ apparatus.name }}
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="row mt-0 mb-2">
                      <div class="col">
                        <p class="mb-0 pb-0">
                          <strong data-test-hook="features-included-text">
                            {{ $t('xFeaturesIncludedInPlan') }}
                            {{ singleHomePremiumApparatuses[0].subscriptionProductName }}
                          </strong>
                        </p>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col icon-and-text">
                        <i class="fa-kit fa-fw fa-generator"></i>
                        <span data-test-hook="features-included-list">
                          {{ $t('xFeatureSingleHomePremiumSubscription1') }}
                        </span>
                      </div>
                    </div>
                    <div class="row mb-3">
                      <div class="col icon-and-text">
                        <i class="fa-kit fa-fw fa-tank"></i>
                        <span data-test-hook="features-included-monitor-list">
                          {{ $t('xFeatureSingleHomePremiumSubscription2') }}
                        </span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col icon-and-text">
                        <i class="far fa-fw fa-circle-check"></i>
                        <span data-test-hook="features-included-monitor-list">
                          {{ $t('xFeatureSingleHomePremiumSubscription3') }}
                        </span>
                      </div>
                    </div>
                  </b-collapse>
                </div>
                <div
                  class="mt-4"
                  v-if="singleHomePremiumApparatuses[0].subscriptionStore == subscriptionStoreTypesApple"
                >
                  <div class="row">
                    <div class="col">
                      <span data-test-hook="manage-plan-text">
                        {{ $t('xManagePlanOnApple') }}
                      </span>
                    </div>
                  </div>
                  <div class="row mb-3">
                    <a href="https://account.apple.com/account/manage/section/subscriptions" target="_blank">
                      <span data-test-hook="manage-plan-text" class="ml-3">
                        {{ 'https://account.apple.com/account/manage/section/subscriptions.' }}
                      </span>
                    </a>
                  </div>
                </div>
                <div
                  class="mt-4"
                  v-else-if="singleHomePremiumApparatuses[0].subscriptionStore == subscriptionStoreTypesGoogle"
                >
                  <div class="row">
                    <div class="col">
                      <span data-test-hook="manage-plan-text">
                        {{ $t('xManagePlanOnGoogle') }}
                      </span>
                    </div>
                  </div>
                  <div class="row mb-3">
                    <a href="https://play.google.com/store/account/subscriptions" target="_blank">
                      <span data-test-hook="manage-plan-text" class="ml-3">
                        {{ 'https://play.google.com/store/account/subscriptions' }}
                      </span>
                    </a>
                  </div>
                </div>
                <div v-else-if="singleHomePremiumApparatuses[0].subscriptionStatus !== 2" class="mt-4">
                  <button
                    type="button"
                    class="btn btn-warning"
                    @click="
                      isCancelLegacy = false
                      cancelSubscriptionProductId = singleHomePremiumApparatuses[0].subscriptionProductId
                      cancelSubscriptionStore = singleHomePremiumApparatuses[0].subscriptionStore
                      cancelSubscriptionApparatuses = singleHomePremiumApparatuses[0].apparatuses
                      openModal('cancel-subscription-method')
                    "
                    :title="$t('xManageYourPlanBtn')"
                    data-test-hook="ManageYourPlanBtn"
                  >
                    {{ $t('xManageYourPlanBtn') }}
                  </button>
                </div>
                <div class="row mt-4">
                  <div class="col">
                    <p data-test-hook="AutoRenewMsg" class="auto-renew-message text-small">
                      {{ $t('xThisSubscriptionWillAutoRenewShortVersion') }}
                    </p>
                  </div>
                </div>
              </div>
              <div v-if="singleHomePremiumApparatuses[0].subscriptionStatus === 2">
                <b-badge variant="primary" class="mb-2 mt-4 pending-change-badge">{{ $t('xPendingChange') }}</b-badge>
                <div class="row mb-2">
                  <div class="col">
                    <span data-test-hook="no-plan-status">{{ $t('xPlanStatus') }}</span>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col">
                    <strong>{{ $t('xNoPlan') }}</strong>
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col">
                    <span data-test-hook="effective-cancel-date">{{ $t('xChangeEffectiveDate') }}</span>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col">
                    <strong>{{
                      $moment(singleHomePremiumApparatuses[0].subscriptionNextBillingDate).format('MM/DD/YYYY')
                    }}</strong>
                  </div>
                </div>
                <!-- <div class="accordion" role="tablist" style="width:100%">
                  <div class="accordion-wrapper">
                    <div v-b-toggle.collapse-noPlan>
                      <div class="row align-items-center no-gutters">
                        <div class="col align-items-center">
                          <i class="fa-regular fa-circle-info pr-2"></i
                          ><strong>{{ $t('xProductsAndFeatures') }}</strong>
                        </div>
                        <div class="col-auto">
                          <i class="fas fa-chevron-down when-closed"></i>
                          <i class="fas fa-chevron-up when-open"></i>
                        </div>
                      </div>
                    </div>
                    <b-collapse id="collapse-noPlan">
                      <div class="row">
                        <div class="col">
                          <hr class="mx-0 px-0" />
                        </div>
                      </div>
                      <div class="row mb-2" v-if="singleHomePremiumApparatuses[0]?.apparatuses?.length > 0">
                        <div class="col">
                          <p class="mb-0 pb-0">
                            <strong data-test-hook="products-not-included-text">
                              {{ $t('xProductsNotSupported') }}
                            </strong>
                          </p>
                        </div>
                      </div>
                      <div class="row" v-if="singleHomePremiumApparatuses[0]?.apparatuses?.length > 0">
                        <div class="col-0">
                          <ul id="productsNoLongerCovered" style="list-style-type: none; padding-inline-start: 13px;">
                            <li v-for="(apparatus, index) in singleHomePremiumApparatuses[0].apparatuses" :key="index">
                              <i :class="getApparatusIcon(apparatus.type)"></i>&nbsp;&nbsp;{{ apparatus.name }}
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="row mt-0 mb-2">
                        <div class="col">
                          <p class="mb-0 pb-0">
                            <strong data-test-hook="features-not-included-text">
                              {{ $t('xFeaturesNotIncluded') }}
                            </strong>
                          </p>
                        </div>
                      </div>
                      <div class="row mb-3">
                        <div class="col icon-and-text">
                          <i class="fa-kit fa-fw fa-generator"></i>
                          <span data-test-hook="features-not-included-list">
                            {{ $t('xFeatureSingleHomePremiumSubscription1') }}
                          </span>
                        </div>
                      </div>
                      <div class="row mb-3">
                        <div class="col icon-and-text">
                          <i class="fa-kit fa-fw fa-tank"></i>
                          <span data-test-hook="features-included-monitor-list">
                            {{ $t('xFeatureSingleHomePremiumSubscription2') }}
                          </span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col icon-and-text">
                          <i class="far fa-fw fa-circle-check"></i>
                          <span data-test-hook="features-included-monitor-list">
                            {{ $t('xFeatureSingleHomePremiumSubscription3') }}
                          </span>
                        </div>
                      </div>
                    </b-collapse>
                  </div>
                  <div class="row mt-4">
                    <div class="col">
                      <p data-test-hook="AutoRenewMsg" class="auto-renew-message text-small">
                        {{ $t('xThisSubscriptionWillAutoRenewShortVersion') }}
                      </p>
                    </div>
                  </div>
                </div> -->
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-2 mb-2" />
        <div v-if="existingPaymentInfo && purchasedOnWeb">
          <div class="card" header-bg-variant="white">
            <div class="card-body">
              <div class="row mb-1 ml-0">
                <i class="far fa-credit-card colorOrange"></i>
                <strong class="ml-2" data-test-hook="account-holder-payment-information-account-settings">
                  {{ $t('xPaymentInformation') }}
                </strong>
                <popper trigger="hover" :options="{ placement: 'right' }" class="ml-4">
                  <div class="popper">
                    {{ $t('xPrimaryPaymentInformationMessage') }}
                  </div>
                  <i slot="reference" class="far fa-question-circle "></i>
                </popper>
              </div>
              <div class="row mt-1">
                <div class="col-auto">
                  <p data-test-hook="account-holder-payment-information-card-account-settings">
                    {{ existingPaymentInfo.cardType.toUpperCase() + ' - ' + existingPaymentInfo.cardSuffix }}
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col-auto">
                  <p data-test-hook="account-holder-payment-information-expiration-account-settings">
                    {{
                      $t('xExpires') +
                        ' ' +
                        displayMonth(existingPaymentInfo.expirationMonth) +
                        '/' +
                        existingPaymentInfo.expirationYear
                    }}
                  </p>
                </div>
              </div>
              <div v-if="shouldShowUpdatePayment" class="row">
                <div class="col">
                  <button
                    type="button"
                    class="btn btn-outline-warning mt-3"
                    @click="openModal('update-payment-method')"
                    :title="$t('xUpdatePaymentInformation')"
                    data-test-hook="update-payment-information-button-account-settings"
                  >
                    {{ $t('xUpdatePaymentInformation') }}
                  </button>
                </div>
              </div>
              <div v-if="shouldShowRemovePayment" class="row">
                <div class="col">
                  <button
                    type="button"
                    class="btn btn-outline-danger rounded-pill mt-3"
                    @click="removePaymentInformation()"
                    :title="$t('xRemovePaymentInformation')"
                    data-test-hook="remove-payment-information-button-account-settings"
                  >
                    <i class="fas fa-trash-alt colorOrange"></i>
                    {{ $t('xRemovePaymentInformation') }}
                    <span class="spinner-border ml-2" v-if="removing"></span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal modalId="update-payment-method" size="modal-md" @hidden="closePaymentModal()">
          <ModalHeader :title="$t('xUpdatePaymentMethod')" icon="fal fa-credit-card colorOrange mr-1">
            <div class="cols-auto">
              <button
                type="button"
                class="btn btn-icon-button btn-icon-button--gray"
                @click="closePaymentModal()"
                data-test-hook="CloseModalBtn"
              >
                <i class="fas fa-times" />
              </button>
            </div>
          </ModalHeader>
          <ModalBody>
            <div role="alert" v-if="showValidationBanner" class="alert alert-danger" data-test-hook="ValidationAlert">
              <p>{{ $t('xExperiencedTheseErrors') }}</p>
              <ul v-for="(message, index) in errorMessages" :key="index">
                <li :id="index" data-test-hook="ValidationMsg">
                  {{ message }}
                </li>
              </ul>
            </div>
            <div class="row">
              <div class="col-12">
                <h3>
                  <strong data-test-hook="CcInfoHeader">{{ $t('xCreditCardInfo') }}</strong>
                  <span class="body-text-color">*</span>
                </h3>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col">
                <i class="asterisk-text" data-test-hook="UpdatePaymentWarningMsg">
                  {{ $t('xUpdatingPaymentWarning') }}
                </i>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="form-group" id="credit-card-number">
                  <input
                    class="form-control"
                    id="creditCardNumber"
                    v-model="$v.creditCardNumber.$model"
                    maxlength="16"
                    type="text"
                    :class="status($v.creditCardNumber)"
                    v-mask="'################'"
                    :placeholder="$t('xCreditCardNumber')"
                    aria-describedby="card-number-invalid"
                    data-test-hook="CcNumber"
                  />
                  <div class="invalid-feedback" id="card-number-invalid" data-test-hook="invalidCcNo">
                    {{ $t('xCCFieldValidation') }}
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col col-lg-6 col-md-6 col-sm-12">
                <div class="form-group" id="expiry-date-month">
                  <input
                    class="form-control"
                    id="xNm"
                    v-model="$v.expirationMonth.$model"
                    maxlength="2"
                    type="text"
                    :class="status($v.expirationMonth)"
                    v-mask="'##'"
                    :placeholder="$t('xMm')"
                    aria-describedby="expiry-month-invalid"
                    data-test-hook="CcExpireMonth"
                  />
                  <div class="invalid-feedback" id="expiry-month-invalid" data-test-hook="invalidCcMth">
                    {{ $t('xExpirationMonthValidation') }}
                  </div>
                </div>
              </div>
              <div class="col col-lg-6 col-md-t6 col-sm-12">
                <div class="form-group" id="expiry-date-year">
                  <input
                    class="form-control"
                    v-model="$v.expirationYear.$model"
                    type="text"
                    :placeholder="$t('xYy')"
                    maxlength="2"
                    v-mask="'##'"
                    :class="status($v.expirationYear)"
                    aria-describedby="expiry-year-invalid"
                    data-test-hook="CcExpireYear"
                  />
                  <div class="invalid-feedback" id="expiry-year-invalid" data-test-hook="invalidCcYear">
                    {{ $t('xExpirationYearValidation') }}
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="input-group" id="cvv">
                  <input
                    class="form-control"
                    v-model="$v.cvv.$model"
                    type="text"
                    v-mask="'####'"
                    maxlength="4"
                    :placeholder="$t('xCvv')"
                    :class="status($v.cvv)"
                    aria-describedby="cvv-invalid"
                    data-test-hook="CcCvv"
                  />
                  <div class="input-group-append">
                    <div class="input-group-text">
                      <img
                        :height="20"
                        :src="require('@/assets/images/3-digit-code.svg')"
                        data-test-hook="ccCvvImage"
                      />
                    </div>
                  </div>
                  <div class="invalid-feedback" id="cvv-invalid" data-test-hook="invalidCcCvv">
                    {{ $t('xCVVValidation') }}
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-4" v-if="accountData">
              <div class="row mb-2">
                <div class="col">
                  <div class="font-weight-bold custom-control custom-checkbox">
                    <input
                      id="useExistingBillingInformation"
                      type="checkbox"
                      class="custom-control-input"
                      v-model="useExistingBillingInformation"
                      :disabled="savingAccountHolder"
                      data-test-hook="UseCurrentBillingAddressChkBox"
                    />
                    <label
                      class="custom-control-label"
                      for="useExistingBillingInformation"
                      data-test-hook="useCurrBillingInfoText"
                    >
                      {{ $t('xUseCurrentBillingInfo') }}
                    </label>
                  </div>
                </div>
              </div>
              <div class="row" v-if="useExistingBillingInformation">
                <div class="col-12" data-test-hook="ExistingBillingName">
                  {{ accountData.firstName }} {{ accountData.lastName }}
                </div>
                <div class="col-12" data-test-hook="ExistingBillingAddress">
                  {{ DisplayAddress(accountData.address) }}
                </div>
              </div>
              <div v-if="!useExistingBillingInformation">
                <div class="row">
                  <div class="col-12">
                    <fieldset id="firstName-group" class="form-group">
                      <div>
                        <input
                          class="form-control"
                          type="text"
                          id="firstName"
                          v-model="accountData.firstName"
                          data-test-hook="firstName"
                        />
                      </div>
                    </fieldset>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <fieldset class="form-group" id="lastName-group">
                      <div>
                        <input
                          class="form-control"
                          id="lastName"
                          v-model="accountData.lastName"
                          type="text"
                          data-test-hook="lastName"
                        />
                      </div>
                    </fieldset>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <TypeAheadAddress
                      ref="typeAheadAddressSubscription"
                      :currentAddress="accountData.address"
                      @newAddress="setNewBillingAddress"
                    />
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              class="btn btn-outline-warning"
              @click="closeModal('update-payment-method'), closePaymentModal()"
              data-test-hook="CancelBtn"
            >
              {{ $t('xCancel') }}
            </button>
            <button
              type="button"
              v-if="useExistingBillingInformation"
              class="btn btn-primary"
              @click="payForProduct()"
              :disabled="!canPay()"
              data-test-hook="SaveChangesBtn"
            >
              {{ $t('xSave') }}
              <span class="spinner-border ml-2" v-if="paying"></span>
            </button>
            <button
              v-else
              type="button"
              class="btn btn-warning"
              @click="saveAccountHolder(), (savingAccountHolder = true)"
              :disabled="savingAccountHolder"
              data-test-hook="SaveChangesBtn"
            >
              {{ $t('xConfirmBillingInfo') }}
              <span class="spinner-border ml-2" v-if="savingAccountHolder"></span>
            </button>
          </ModalFooter>
        </Modal>

        <Modal modalId="cancel-subscription-method" size="modal-md" @hidden="closeManagePlanModal()">
          <ModalHeader
            :title="$t('xCancelPlan')"
            icon="far fa-circle-dollar colorOrange mr-1"
            :style="{ padding: '4px 12px' }"
          >
            <div class="cols-auto">
              <button
                type="button"
                class="btn btn-icon-button btn-icon-button--gray"
                @click="closeManagePlanModal()"
                data-test-hook="CloseModalBtn"
              >
                <i class="fas fa-times" />
              </button>
            </div>
          </ModalHeader>
          <ModalBody v-if="!cancelingSubscription">
            <div class="row mb-3">
              <div class="col">
                <p data-test-hook="EndOfSubscriptionText">
                  {{ $t('xCurrentPlanDateText') }}
                  {{ $moment(endDate).format('MM/DD/YYYY') + '.' }}
                  {{ $t('xCurrentPlanText') }}
                </p>
              </div>
            </div>
            <div v-if="doesAccountHaveGen() && !doesAccountHaveFuelMonitor()">
              <div class="row mb-3">
                <div class="col">
                  <span class="text" data-test-hook="BasicFeaturesTextGenOnly">
                    {{ $t('xGenToReceiveBasicFeaturesText') }}
                  </span>
                </div>
              </div>
              <strong>{{ $t('xPremiumGenFeaturesHeader') }}</strong>
              <ul>
                <li>{{ $t('xGenPremiumFeaturesItem1') }}</li>
                <li>{{ $t('xGenPremiumFeaturesItem2') }}</li>
                <li>{{ $t('xGenPremiumFeaturesItem3') }}</li>
                <li>{{ $t('xGenPremiumFeaturesItem4') }}</li>
              </ul>
            </div>
            <div v-else-if="!doesAccountHaveGen() && doesAccountHaveFuelMonitor()">
              <div class="row mb-3">
                <div class="col">
                  <span class="text" data-test-hook="BasicFeaturesTextMonitorOnly">
                    {{ $t('xMonitorToReceiveBasicFeaturesText') }}
                  </span>
                </div>
              </div>
              <strong>{{ $t('xPremiumFuelMonitorFeaturesHeader') }}</strong>
              <ul>
                <li>{{ $t('xMonitorPremiumFeaturesItem1') }}</li>
                <li>{{ $t('xMonitorPremiumFeaturesItem2') }}</li>
                <li>{{ $t('xMonitorPremiumFeaturesItem3') }}</li>
                <li>{{ $t('xMonitorPremiumFeaturesItem4') }}</li>
              </ul>
            </div>
            <div v-else>
              <div class="row mb-3">
                <div class="col">
                  <span class="text" data-test-hook="BasicFeaturesTextGenAndMonitor">
                    {{ $t('xGenAndMonitorBasicFeaturesText') }}
                  </span>
                </div>
              </div>
              <strong>{{ $t('xPremiumGenFeaturesHeader') }}</strong>
              <ul>
                <li>{{ $t('xGenPremiumFeaturesItem1') }}</li>
                <li>{{ $t('xGenPremiumFeaturesItem2') }}</li>
                <li>{{ $t('xGenPremiumFeaturesItem3') }}</li>
                <li>{{ $t('xGenPremiumFeaturesItem4') }}</li>
              </ul>
              <strong>{{ $t('xPremiumFuelMonitorFeaturesHeader') }}</strong>
              <ul>
                <li>{{ $t('xMonitorPremiumFeaturesItem1') }}</li>
                <li>{{ $t('xMonitorPremiumFeaturesItem2') }}</li>
                <li>{{ $t('xMonitorPremiumFeaturesItem3') }}</li>
                <li>{{ $t('xMonitorPremiumFeaturesItem4') }}</li>
              </ul>
            </div>
            <div class="row" data-test-hook="AreYouSureYouWantToCancelText">
              <div class="col">
                {{ $t('xAreYouSureYouWantToCancelText') }}
              </div>
            </div>
          </ModalBody>
          <ModalBody v-else>
            <div class="d-flex justify-content-center align-items-center">
              <span><b-spinner size="lg"/></span>
            </div>
          </ModalBody>
          <ModalFooter>
            <div class="modal-buttons">
              <button
                type="button"
                class="btn btn-outline-warning"
                @click="closeManagePlanModal()"
                :title="$t('xManageYourPlan')"
                data-test-hook="KeepPlanBtn"
                :disabled="cancelingSubscription"
              >
                {{ $t('xKeepPlanButtonText') }}
              </button>
              <button
                type="button"
                class="btn btn-warning ml-2"
                @click="cancelSubscription()"
                :title="$t('xManageYourPlan')"
                data-test-hook="CancelPlanBtn"
                :disabled="cancelingSubscription"
              >
                {{ $t('xCancelPlanButtonText') }}
              </button>
            </div>
          </ModalFooter>
        </Modal>

        <Modal modalId="account-needs-attention" size="modal-md">
          <ModalHeader
            :title="$t('xYourAccountNeedsAttention')"
            icon="far fa-exclamation-triangle"
            :style="{ padding: '10px 10px 10px 10px' }"
          >
            <div class="cols-auto">
              <button
                type="button"
                class="btn btn-icon-button btn-icon-button--gray"
                @click="closeModal('account-needs-attention')"
              >
                <i class="fas fa-times" />
              </button>
            </div>
          </ModalHeader>
          <ModalBody>
            <div class="row">
              <div class="col">
                {{ $t('xHomePremiumLimitText') }}
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button type="button" @click="navToSupportPage()" class="btn btn-primary">
              {{ $t('xContactSupportUpgrade') }}
            </button>
          </ModalFooter>
        </Modal>

        <b-modal
          id="premium-enrollment-modal-step-1"
          size="md"
          no-fade
          no-stacking
          centered
          no-close-on-esc
          no-close-on-backdrop
          hide-footer
        >
          <template #modal-header>
            <div>
              <h2 class="modal-title" data-test-hook="premium-enrollment-modal-step-1-header">
                <div>
                  <i
                    class="far fa-badge-dollar colorOrange mr-1"
                    data-test-hook="premium-enrollment-modal-step-1-header-icon"
                  />
                  {{ $t('xSubscribeToPremium') }}
                </div>
              </h2>
            </div>
          </template>
          <div v-if="loadingSubscriptionModal">
            <b-row class="text-center">
              <b-col>
                <b-spinner style="width: 10rem; height: 10rem;" variant="warning" label="Spinning"></b-spinner>
              </b-col>
            </b-row>
          </div>
          <b-card v-else data-test-hook="premium-enrollment-modal-step-1-paid-card">
            <b-card-title>
              <b-row class="text-center">
                <b-col>
                  <h1 class="mb-0 pb-0 pt-0 mt-0" data-test-hook="premium-enrollment-modal-step-1-paid-card-title">
                    {{ premiumSubscriptionName }}
                  </h1>
                </b-col>
              </b-row>
            </b-card-title>
            <b-card-body class="subs-info-card-body">
              <b-row class="text-center mt-2">
                <b-col>
                  <b-button
                    variant="subscription-radio"
                    pill
                    @click="() => {}"
                    :pressed="true"
                    data-test-hook="select-subscription-type-step-10-existing-subscription-select-yearly-button"
                  >
                    <span class="sub-radio-dot align-middle">
                      <i class="fa-solid fa-circle" />
                    </span>
                    {{ premiumSubscriptionCost | formatCurrency }}
                    <span class="text-bottom sub-premium-radio-duration">/{{ $t('xYear').toLowerCase() }}</span>
                  </b-button>
                </b-col>
              </b-row>
              <div class="mt-2 subscriptionCardText">
                <b-row>
                  <b-col>
                    <strong
                      data-test-hook="select-subscription-type-step-10-existing-subscription-premium-monitoring-max-products-text"
                    >
                      {{ $t('xPremiumMonitoringMaxProducts') }}
                    </strong>
                  </b-col>
                </b-row>
                <b-row v-for="(apparatus, index) in apparatuses" :key="index">
                  <b-col v-if="isApparatusEligibleForUpgrade(apparatus)" class="mt-2">
                    <div class="accordion" role="tablist" style="width:100%">
                      <div class="accordion-wrapper">
                        <div v-b-toggle="'collapse-upgrade-' + apparatus.apparatusId">
                          <div class="row align-items-center no-gutters">
                            <div class="col d-flex">
                              <div class="d-flex">
                                <i class="fas fa-fw fa-check-circle check-icon mr-2" />
                                <p>{{ apparatus.name }}</p>
                              </div>
                            </div>
                            <div class="col-auto">
                              <i class="fas fa-chevron-down when-closed"></i>
                              <i class="fas fa-chevron-up when-open"></i>
                            </div>
                          </div>
                          <div>
                            <b-row>
                              <b-col>
                                <p class="receives-features-text">
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ $t('xReceivesPremiumFeatures') }}
                                </p>
                              </b-col>
                            </b-row>
                          </div>
                        </div>
                        <b-collapse v-if="apparatus.type === 0" :id="'collapse-upgrade-' + apparatus.apparatusId">
                          <div class="features">
                            <div class="basic">
                              <p class="mb-1">
                                <strong>{{ $t('xFeaturesIncludedInPlan') }}{{ $t('xBasic') }}</strong>
                              </p>
                              <div class="feature">
                                <i class="far fa-fw fa-clipboard-list"></i>
                                <div>
                                  <p class="basic-features">
                                    {{ $t('xFeaturesIncludedWiFiAnnuallySubscriptionFeature1') }}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div class="premium">
                              <p class="mb-1">
                                <strong>{{ $t('xAdditionalPremiumFeatures') }}</strong>
                              </p>
                              <div class="feature">
                                <i class="far fa-fw fa-bell"></i>
                                <div class="mb-2">
                                  <p class="premium-feature-text">
                                    {{ $t('xRealTimeAlerts') }}
                                  </p>
                                  <p class="premium-feature-supporting-text">
                                    {{ $t('xRealTimeAlertsDesc') }}
                                  </p>
                                </div>
                              </div>
                              <div class="feature">
                                <i class="far fa-fw fa-calendar"></i>
                                <div class="mb-2">
                                  <p class="premium-feature-text">
                                    {{ $t('xExerciseSettings') }}
                                  </p>
                                  <p class="premium-feature-supporting-text">
                                    {{ $t('xExerciseSettingsDesc') }}
                                  </p>
                                </div>
                              </div>
                              <div class="feature">
                                <i class="far fa-fw fa-arrows-spin"></i>
                                <div class="mb-2">
                                  <p class="premium-feature-text">
                                    {{ $t('xExerciseOnDemand') }}
                                  </p>
                                  <p class="premium-feature-supporting-text">
                                    {{ $t('xExerciseOnDemandDesc') }}
                                  </p>
                                </div>
                              </div>
                              <div class="feature">
                                <i class="far fa-fw fa-warning"></i>
                                <div>
                                  <p class="premium-feature-text">
                                    {{ $t('xDismissWarnings') }}
                                  </p>
                                  <p class="premium-feature-supporting-text">
                                    {{ $t('xDismissWarningsDesc') }}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </b-collapse>
                        <b-collapse v-else-if="apparatus.type === 2" :id="'collapse-upgrade-' + apparatus.apparatusId">
                          <div class="features">
                            <div class="premium">
                              <p class="mb-1">
                                <strong>{{ $t('xFeaturesIncluded') }}</strong>
                              </p>
                              <div class="feature">
                                <i class="fa-regular fa-gas-pump"></i>
                                <div class="mb-2">
                                  <p data-test-hook="premium-enrollment-modal-step-1-fuel-monitor-feature-title-1">
                                    <strong>{{ $t('xCurrentFuelLevel') }}</strong>
                                  </p>
                                  <p
                                    class="supporting-text"
                                    data-test-hook="premium-enrollment-modal-step-1-fuel-monitor-feature-description-1"
                                  >
                                    {{ $t('xFuelLevelDescription') }}
                                  </p>
                                </div>
                              </div>
                              <div class="feature">
                                <i class="fa-regular fa-chart-line"></i>
                                <div class="mb-2">
                                  <p data-test-hook="premium-enrollment-modal-step-1-fuel-monitor-feature-title-1">
                                    <strong>{{ $t('xFuelUsageHistory') }}</strong>
                                  </p>
                                  <p
                                    class="supporting-text"
                                    data-test-hook="premium-enrollment-modal-step-1-fuel-monitor-feature-description-1"
                                  >
                                    {{ $t('xFuelUsageDescription') }}
                                  </p>
                                </div>
                              </div>
                              <div class="feature">
                                <i class="fa-regular fa-warning"></i>
                                <div class="mb-2">
                                  <p data-test-hook="premium-enrollment-modal-step-1-fuel-monitor-feature-title-1">
                                    <strong>{{ $t('xFuelLevelWarnings') }}</strong>
                                  </p>
                                  <p
                                    class="supporting-text"
                                    data-test-hook="premium-enrollment-modal-step-1-fuel-monitor-feature-description-1"
                                  >
                                    {{ $t('xFuelLevelWarningsDescription') }}
                                  </p>
                                </div>
                              </div>
                              <div class="feature">
                                <i class="fa-regular fa-battery-full"></i>
                                <div class="mb-2">
                                  <p data-test-hook="premium-enrollment-modal-step-1-fuel-monitor-feature-title-1">
                                    <strong>{{ $t('xBatteryLevel') }}</strong>
                                  </p>
                                  <p
                                    class="supporting-text"
                                    data-test-hook="premium-enrollment-modal-step-1-fuel-monitor-feature-description-1"
                                  >
                                    {{ $t('xBatteryLevelDescription') }}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </b-collapse>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </b-card-body>
          </b-card>

          <div>
            <b-row no-gutters class="mt-3">
              <b-col>
                <button
                  type="button"
                  class="btn btn-outline-warning mr-2"
                  style="width: 45%"
                  @click="$bvModal.hide('premium-enrollment-modal-step-1')"
                  data-test-hook="previous-button-step1-downgrade-sub-modal"
                >
                  {{ $t('xCancel') }}
                </button>
                <button
                  type="button"
                  class="btn btn-warning ml-2"
                  style="width: 48%"
                  @click="
                    $bvModal.hide('premium-enrollment-modal-step-1'), $bvModal.show('premium-enrollment-modal-step-2')
                  "
                  :disabled="subscribingProduct"
                  data-test-hook="yes-downgrade-button-step1-downgrade-sub-modal"
                >
                  <span v-if="subscribingProduct"><b-spinner /></span>
                  <span v-else>{{ $t('xContinue') }}</span>
                </button>
              </b-col>
            </b-row>
          </div>
          <b-row class="mt-3">
            <b-col class="text-center">
              <p class="legal-disclaimer">*{{ $t('xPushNotificationsAreAvailableWithInstallation') }}</p>
            </b-col>
          </b-row>
        </b-modal>

        <!-- @shown="OnShowSubscriptionModal"
          @hidden="OnHideSubscriptionModal" -->
        <b-modal
          id="premium-enrollment-modal-step-2"
          size="lg"
          no-fade
          no-stacking
          centered
          no-close-on-esc
          no-close-on-backdrop
        >
          <template #modal-header>
            <div>
              <h2 class="modal-title" data-test-hook="premium-enrollment-modal-step-1-header">
                <div>
                  <i
                    class="fal fa-credit-card colorOrange mr-1"
                    data-test-hook="premium-enrollment-modal-step-1-header-icon"
                  />
                  {{ $t('xAddAPaymentMethod') }}
                </div>
              </h2>
            </div>
          </template>
          <template #modal-footer>
            <b-button
              v-if="!useExistingPaymentInfo || !existingPaymentInfo"
              variant="warning"
              @click="payForProduct()"
              :disabled="paying"
              block
              data-test-hook="premium-enrollment-modal-step-2-save-payment-info-button"
            >
              <span v-if="paying"><b-spinner /></span>
              <span v-else>{{ $t('xPayNow') }}</span>
            </b-button>
            <b-button
              v-else
              variant="warning"
              @click="subscribeProduct(), closeModal('downgrade-paid-sub-step-1')"
              :disabled="subscribingProduct"
              block
              data-test-hook="premium-enrollment-modal-step-2-confirm-subscription-button"
            >
              <span v-if="subscribingProduct"><b-spinner /></span>
              <span v-else>{{ $t('xConfirmSubscription') }}</span>
            </b-button>
          </template>
          <div v-if="loadingSubscriptionModal">
            <b-row class="text-center">
              <b-col>
                <b-spinner style="width: 10rem; height: 10rem;" variant="warning" label="Spinning"></b-spinner>
              </b-col>
            </b-row>
          </div>
          <div v-else>
            <b-alert :show="showValidationBanner" variant="danger">
              <p>{{ $t('xExperiencedTheseErrors') }}</p>
              <ul v-for="(message, index) in errorMessages" :key="index">
                <li :id="index">
                  {{ message }}
                </li>
              </ul>
            </b-alert>

            <div class="mt-4" v-if="existingPaymentInfo">
              <h5>{{ $t('xCreditCardInfo') }}</h5>
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  v-model="useExistingBillingInformation"
                  id="customCheck"
                  data-test-hook="UseCurrentBillingAddressBtn"
                />
                <label class="custom-control-label" for="customCheck" data-test-hook="useCurrBillingInfoText">
                  {{ $t('xUseCurrentBillingInfo') }}
                </label>
              </div>
              <div class="row mt-2">
                <div class="col-12">{{ accountProfile.firstName }} {{ accountProfile.lastName }}</div>
                <div class="col-12">
                  {{ DisplayAddress(accountProfile.address) }}
                </div>
              </div>
              <div class="subscription-cost mt-3">
                <div class="row mt-2">
                  <div class="col-12 text-left">
                    <strong>{{ $t('xTotalPayment') }}:&nbsp;</strong>
                    <!-- <strong>{{ selectedProduct.cost.toFixed(2) | formatCurrency }}</strong> -->
                    <strong>{{ premiumSubscriptionCost | formatCurrency }}</strong>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 text-left"></div>
                </div>
                <div class="row">
                  <div class="col">
                    <p class="body-text-color">
                      <span v-if="premiumSubscriptionDuration === 12">{{ $t('xBilledYearly') }}</span>
                      <span v-else>{{ $t('xBilledMonthly') }}</span>
                    </p>
                  </div>
                </div>
              </div>
              <b-row class="mt-3">
                <b-col>
                  <p>*{{ $t('xUpdatingCCApplyActiveSubscriptions') }}</p>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <div v-if="useExistingPaymentInfo">
                    <b-row class="mt-2">
                      <b-col cols="12">
                        <p>
                          <strong>
                            <i class="fal fa-credit-card" />
                            {{ existingPaymentInfo.cardType }} -
                            {{ existingPaymentInfo.cardSuffix }}
                          </strong>
                        </p>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="12">
                        <p>
                          {{ $t('xExpires') }}
                          {{ existingPaymentInfo.expirationMonth + '/' + existingPaymentInfo.expirationYear }}
                        </p>
                      </b-col>
                    </b-row>
                  </div>
                </b-col>
              </b-row>
            </div>
            <div v-if="!existingPaymentInfo || !useExistingPaymentInfo" class="mt-4">
              <b-row>
                <b-col>
                  <h4 data-test-hook="premium-enrollment-modal-step-2-enter-billing-info-text">
                    {{ $t('xEnterBillingInfo') }}
                  </h4>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <i class="asterisk-text">
                    {{ $t('xUpdatingPaymentWarning') }}
                  </i>
                </b-col>
              </b-row>
              <b-row class="mt-2">
                <b-col>
                  <b-form-group id="credit-card-number">
                    <b-form-input
                      v-model="$v.creditCardNumber.$model"
                      type="text"
                      maxlength="16"
                      v-mask="'################'"
                      :placeholder="$t('xCreditCardNumber')"
                      :state="validState($v.creditCardNumber)"
                      aria-describedby="card-number-invalid"
                      data-test-hook="premium-enrollment-modal-step-2-credit-card-number-field"
                    ></b-form-input>
                    <b-form-invalid-feedback id="card-number-invalid">
                      {{ $t('xCCFieldValidation') }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="mt-2">
                <b-col lg="6" md="6" sm="12">
                  <b-form-group id="expiry-date-month">
                    <b-form-input
                      v-model="$v.expirationMonth.$model"
                      type="text"
                      :placeholder="$t('xMm')"
                      maxlength="2"
                      v-mask="'##'"
                      :state="validState($v.expirationMonth)"
                      aria-describedby="expiry-month-invalid"
                      data-test-hook="premium-enrollment-modal-step-2-expiration-month-field"
                    ></b-form-input>
                    <b-form-invalid-feedback id="expiry-month-invalid">
                      {{ $t('xExpirationMonthValidation') }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col lg="6" md="6" sm="12">
                  <b-form-group id="expiry-date-year">
                    <b-form-input
                      v-model="$v.expirationYear.$model"
                      type="text"
                      :placeholder="$t('xYy')"
                      maxlength="2"
                      v-mask="'##'"
                      :state="validState($v.expirationYear)"
                      aria-describedby="expiry-year-invalid"
                      data-test-hook="premium-enrollment-modal-step-2-expiration-year-field"
                    ></b-form-input>
                    <b-form-invalid-feedback id="expiry-year-invalid">
                      {{ $t('xExpirationYearValidation') }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="mt-2">
                <b-col>
                  <b-input-group id="cvv">
                    <b-form-input
                      v-model="$v.cvv.$model"
                      type="text"
                      v-mask="'####'"
                      maxlength="4"
                      :placeholder="$t('xCvv')"
                      :state="validState($v.cvv)"
                      aria-describedby="cvv-invalid"
                      data-test-hook="premium-enrollment-modal-step-2-cvv-field"
                    ></b-form-input>
                    <b-input-group-append>
                      <b-input-group-text>
                        <b-img
                          :height="20"
                          :src="require('@/assets/images/3-digit-code.svg')"
                          data-test-hook="premium-enrollment-modal-step-2-cvv-image"
                        />
                      </b-input-group-text>
                    </b-input-group-append>
                    <b-form-invalid-feedback id="cvv-invalid">
                      {{ $t('xCVVValidation') }}
                    </b-form-invalid-feedback>
                  </b-input-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col class="mt-3">
                  <div class="subscription-cost">
                    <h4 data-test-hook="premium-enrollment-modal-step-2-subscription-cost-and-term">
                      {{ premiumSubscriptionCost | formatCurrency }} {{ $t('xBilledYearly') }}
                    </h4>
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col> </b-col>
              </b-row>
            </div>
            <b-row class="mt-4">
              <b-col>
                <p data-test-hook="premium-enrollment-modal-step-2-auto-renew-message">
                  {{ $t('xThisSubscriptionWillAutoRenew') }}
                </p>
              </b-col>
            </b-row>
            <b-row class="mt-3">
              <b-col>
                <b-form-checkbox v-model="$v.consent.$model" :state="validState($v.consent)">
                  {{ $t('xConsentCheckboxText') }}
                </b-form-checkbox>
                <p v-if="consent == false" class="colorRed ml-4">
                  {{ $t('xConsentNotCheckedError') }}
                </p>
              </b-col>
            </b-row>
          </div>
        </b-modal>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.icon-and-text {
  flex-direction: row;
  display: flex;
  gap: 0.25em;
  align-items: center;
}

.collapsed .when-open,
.not-collapsed .when-closed {
  display: none;
}

.check-icon {
  color: var(--blue-700);
}

.receives-features-text {
  color: var(--carbon-600);
  font-size: 0.875em;
  line-height: 1.35;
}

.subs-info-card-body {
  padding: 0px !important;
}
.card-title {
  margin-bottom: 0;
}
.sub-radio-dot {
  font-size: 0.875rem !important;
}

.current-plan-badge {
  border: 2px solid;
  padding: 5px;
}

.pending-change-badge {
  padding: 5px;
}

#premium-enrollment-modal-step-1 {
  @media (min-width: 576px) {
    .modal-dialog {
      max-width: 200px !important;
    }
  }
  @media (min-width: 800px) {
    .modal-dialog {
      max-width: 400px !important;
    }
  }
}

.subscriptionCardText {
  font-size: 16px !important;
}

.features {
  margin-top: 0.5em;
  gap: 0.75em;
  display: flex;
  flex-direction: column;
}

.supporting-text {
  font-size: 0.875em;
  color: var(--gray-600);
  line-height: 1.25;
}

.legal-disclaimer {
  font-size: 0.875em;
  color: var(--carbon-600);
}

.bullet-points {
  li {
    margin-bottom: 0.35em;
  }
}

.shouldnt-take-long {
  text-align: center;
}

.verify-reading {
  font-style: italic;
  text-align: center;
}

.check-cellular {
  text-align: center;
}

.modal-template-header {
  padding: 160px 160px;
}

.bold-text {
  font-weight: bold;
}

li {
  margin: 0px 0;
}

.tu-help-alert {
  background-color: var(--yellow-200);
  color: var(--carbon-900);
  border: solid 1px var(--yellow-400);
}

.invalid-feedback {
  order: 2;
}
</style>
<style scoped lang="scss">
h1 {
  font-weight: normal;
  line-height: 1.15;
}

.paid-subs-info-card {
  border: 2px solid var(--carbon-300) !important;
  &:hover {
    border: 2px solid var(--brand-400) !important;
    background-color: transparent;
  }
  padding: 0 30px;
  border-radius: 8px;
}

.selected-sub-info-card {
  border: 2px solid var(--brand-400) !important;
  border-radius: 8px;
}

.card-title {
  margin-bottom: 0;
}

.selected {
  border: 1px solid var(--brand-400);
  background: white;
  box-shadow: 0px 0px 10px var(--brand-400);
}

.btn-subscription-radio {
  color: var(--carbon-900);
  font-size: 30px;
  font-weight: bold;
  padding: 0.375rem 2rem;
  border-color: var(--brand-600);
  border-width: 2px;
  margin-bottom: 10px;
}
.btn-subscription-radio:not(:disabled):not(.disabled):hover {
  background-color: var(--orange-200);
}
.btn-subscription-radio:not(:disabled):not(.disabled):focus:active {
  box-shadow: 0px 0px 8px rgba(255, 107, 0, 0.25) !important;
  border-width: 2px;
}
.btn-subscription-radio.active {
  box-shadow: 0 0 0 0.2rem rgba(255, 107, 0, 0.25) !important;
}

.sub-free-radio-text {
  font-size: 16px !important;
}

.feature {
  display: flex;
  align-items: center;
  gap: 0.5em;

  i {
    font-size: 1.125em;
    color: var(--carbon-600);
  }
  div {
    flex: 1;
  }
}
.basic-features {
  font-size: 0.875em;
  line-height: 1.35;
  margin-bottom: 0;
}

.premium-feature-text {
  font-weight: 600;
  font-size: 0.875em;
  line-height: 1.35;
  margin-bottom: 2px;
}
.premium-feature-supporting-text {
  font-size: 0.875em;
  line-height: 1.35;
  margin-bottom: 0;
  color: var(--carbon-600);
}
</style>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { required, minLength, maxLength, numeric } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'
import * as Constants from '@/constants'
import TypeAheadAddress from '@/components/TypeAheadAddress'

import { closeModal, displayShortDate } from '../../utils/mixins'
import { PremiumStoreTypes } from '../../constants'

const monthInRange = (value, vm) => value >= 1 && value <= 12

export default {
  components: {
    TypeAheadAddress
  },
  mixins: [validationMixin],
  data() {
    return {
      consent: null,
      accountData: null,
      billingInfoLoading: true,
      cancelLegacyApparatusId: null,
      cancelLegacyApparatusType: null,
      cancelSubscriptionProductId: null,
      cancelSubscriptionStore: null,
      cancelSubscriptionApparatuses: [],
      creditCardNumber: null,
      cvv: null,
      currentApparatus: null,
      currentApparatusDetail: null,
      endDate: null,
      errorMessages: [],
      existingPaymentInfo: null,
      expirationMonth: null,
      expirationYear: null,
      FreeProductId: Constants.FreeWifiEthProductId,
      hasProductsNoSubscription: false,
      isCancelLegacy: false,
      isLoading: true,
      legacySubscriptionList: [],
      loadingSubscriptionId: null,
      loadingSubscriptionModal: false,
      organizationSubscriptionDetails: null,
      paying: false,
      premiumSubscriptionCost: 0,
      premiumSubscriptionDuration: 0,
      premiumSubscriptionName: null,
      premiumSubscriptionProductId: null,
      purchasedOnWeb: true,
      removing: false,
      savingAccountHolder: false,
      shouldShowRemovePayment: false,
      shouldShowUpdatePayment: false,
      showValidationBanner: false,
      singleHomePremiumApparatuses: [],
      subscribingProduct: null,
      subscription: null,
      subscriptionDetails: null,
      subscriptionListLoading: true,
      subscriptionLoading: false,
      cancelingSubscription: false,
      tuAnnualNames: [],
      useExistingBillingInformation: true,
      useExistingPaymentInfo: true,
      wifiHsbAnnualNames: [],
      wifiHsbMonthlyNames: []
    }
  },
  validations: {
    cvv: {
      required,
      minLength: minLength(3),
      maxLength: maxLength(4),
      numeric
    },
    expirationMonth: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(2),
      numeric,
      monthInRange
    },
    expirationYear: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(2),
      numeric
    },
    creditCardNumber: {
      required,
      min: minLength(15),
      max: maxLength(16),
      minLength: minLength(15),
      maxLength: maxLength(16),
      numeric
    },
    consent: {
      required,
      Boolean
    }
  },
  computed: {
    ...mapState({
      currentApparatusDetails: state => state.apparatus.currentApparatusDetails,
      apparatuses: state => state.apparatus.apparatuses,
      accountProfile: state => state.account.accountProfile,
      apparatuses: state => state.apparatus.apparatuses,
      currentAppConfig: state => state.application.currentAppConfig,
      isImpersonating: state => state.auth.isImpersonating
    }),
    billingMigrationFeatureFlag() {
      return Constants.BillingMigrationFeatureFlag
    },
    calculatePrice() {
      if (this.subscription.activeProduct[0].productId != this.FreeProductId) {
        return (
          this.subscription.activeProduct[0].cost -
          this.subscription.activeProduct[0].cost * (this.subscription.discount[0].percentage / 100)
        )
      } else {
        return 0
      }
    },
    hsbGeneratorApparatus() {
      return Constants.HsbApparatusType
    },
    fuelTankApparatusType() {
      return Constants.FuelTankApparatusType
    },
    tankUtilityPremiumProductId() {
      return Constants.TankUtilityPremiumProductId
    },
    wiFiAnnualProductId() {
      return Constants.WiFiAnnualProductId
    },
    wiFiMonthlyProductId() {
      return Constants.WiFiMonthlyProductId
    },
    homePremiumOneYearlyProductId() {
      return Constants.HomePremiumOneYearlyProductId
    },
    dealerManagedProductId() {
      return Constants.DealerManagedProductId
    },
    tankUtilityDealerManagedProductId() {
      return Constants.TankUtilityDealerManagedProductId
    },
    calculateNextBilling() {
      const endDate = new Date(this.subscription.activeProduct[0].startDate)
      endDate.setMonth(endDate.getMonth() + 1)
      return endDate
    },
    subscriptionStoreTypesApple() {
      return Object.values(Constants.SubscriptionStoreTypes).indexOf('Apple')
    },
    subscriptionStoreTypesGoogle() {
      return Object.values(Constants.SubscriptionStoreTypes).indexOf('Google')
    }
  },
  methods: {
    openMobileApp() {},
    managePlan() {},
    openSubscribePremiumMonitoring() {
      if (
        this.apparatuses?.filter(x => x.type == Constants.HsbApparatusType && x.isDealerManaged === false).length > 1 ||
        this.apparatuses.filter(apparatus => apparatus.type == Constants.FuelTankApparatusType).length > 2
      ) {
        this.openModal('account-needs-attention')
      } else {
        this.loadSubscriptionModal()
        this.$bvModal.show('premium-enrollment-modal-step-1')
      }
    },
    openDealerInfo(apparatusId) {
      this.$router.push({
        path: `/details/` + apparatusId + `/preferredDealer`
      })
    },
    // async getSubscriptionInfo(apparatus) {
    //   if (!apparatus) return
    //   if (
    //     !this.subscription ||
    //     (this.subscription && this.subscription.activeProduct[0].apparatusId != apparatus.apparatusId)
    //   ) {
    //     this.subscriptionLoading = true
    //     this.loadingSubscriptionId = true
    //     this.currentApparatus = apparatus
    //     Object.assign(this.$route.params, {apparatusId: apparatus.apparatusId});
    //     await this.$store.dispatch('getApparatusDetails', apparatus.apparatusId)
    //     this.subscriptionClicked()
    //   } else {
    //     this.subscription = null
    //     this.loadingSubscriptionId = null
    //   }
    // },
    cancelSubscription() {
      this.cancelingSubscription = true
      if (this.isCancelLegacy) {
        this.cancelLegacySubscription(this.accountData.organizationId, this.cancelLegacyApparatusId)
      } else {
        this.cancelSubscriptionProduct(
          this.accountData.organizationId,
          this.cancelSubscriptionStore,
          this.cancelSubscriptionProductId
        )
      }
    },
    isLegacySub(sub) {
      if (sub.product?.productId && sub.product.productId.indexOf('p-premium') !== -1) return true
      if (sub.productId && sub.productId.indexOf('p-premium') !== -1) return true
      return false
    },
    doesAccountHaveLegacySub() {
      if (!this.subscriptionDetails?.subscriptions) return false
      for (const sub of this.subscriptionDetails.subscriptions) {
        if (this.isLegacySub(sub) && sub.status !== Constants.SubscriptionDetailCanceled) return true
      }
      if (this.subscriptionDetails?.legacyBillingAccount?.products) {
        for (const sub of this.subscriptionDetails.legacyBillingAccount.products) {
          if (this.isLegacySub(sub) && sub.status !== Constants.SubscriptionCancelled) return true
        }
      }
      return false
    },
    isApparatusEligibleForUpgrade(apparatus) {
      if (this.isApparatusV1FuelTank(apparatus)) return false
      var sub = this.subscriptionDetails?.subscriptions?.find(x =>
        x.apparatuses.find(y => y.apparatusId == apparatus.apparatusId)
      )
      if (!sub) return true
      if (sub?.status === Constants.SubscriptionDetailCanceled) return true
      return false
    },
    isApparatusV1FuelTank(apparatus) {
      const deviceType = apparatus.properties.find(x => x.type === Constants.ApparatusPropertyDevice)?.value?.deviceType
      if (!deviceType) return false
      if (deviceType === Constants.TankUtilityWifiDeviceType || deviceType === Constants.TankUtilityLteDeviceTypeV1)
        return true
      return false
    },
    async cancelSubscriptionProduct(orgId, store, productId) {
      await this.$store
        .dispatch('cancelSubscriptionProduct', {
          organizationId: orgId,
          store: store,
          productId: productId
        })
        .then(() => {
          this.pollForSubscriptionCancelDataAndRefresh()
        })
        .catch(e => {
          this.cancelingSubscription = false
          this.$store.dispatch('displayToast', {
            title: this.$t('xError'),
            message: this.$t('xFailedToCancelSubscriptionProduct'),
            variant: 'danger'
          })
        })
    },

    async cancelLegacySubscription(orgId, apparatusId) {
      await this.$store
        .dispatch('cancelApparatusSubscription', {
          organizationId: orgId,
          apparatusId: apparatusId
        })
        .then(() => {
          this.pollForSubscriptionCancelDataAndRefresh()
        })
        .catch(e => {
          this.cancelingSubscription = false
          this.$store.dispatch('displayToast', {
            title: this.$t('xError'),
            message: this.$t('xFailedToCancelDeviceSubscription'),
            variant: 'danger'
          })
        })
    },

    async pollForSubscriptionCancelDataAndRefresh() {
      // Check for apparatus data repeatedly or for 5 seconds, if it doesn't show up, display toast
      const operationStart = Date.now()
      const delay = ms => new Promise(res => setTimeout(res, ms))
      while (
        Date.now() - operationStart < 5000 &&
        this.subscriptionDetails?.subscriptions[0]?.status !== Constants.SubscriptionDetailPendingCancel
      ) {
        const response = await this.$store
          .dispatch('getSubscriptionDetails', this.accountProfile.organizationId)
          .catch(() => {})
        this.subscriptionDetails = response.data
        await delay(1000)
      }
      // Different toast shown depending on if data has been updated
      if (this.subscriptionDetails?.subscriptions[0]?.status !== Constants.SubscriptionDetailPendingCancel) {
        this.cancelingSubscription = false
        this.closeModal('cancel-subscription-method')
        this.$store.dispatch('displayToast', {
          title: this.$t('xSuccess'),
          message: this.$t('xSuccessfullyCancelledSubscriptionProductPleaseRefresh'),
          variant: 'success'
        })
      } else {
        this.cancelingSubscription = false
        this.closeModal('cancel-subscription-method')
        const toast = this.$store.dispatch('displayToast', {
          title: this.$t('xSuccess'),
          message: this.$t('xSuccessfullyCancelledSubscriptionProduct'),
          variant: 'success'
        })
        setTimeout(() => {
          window.location.reload()
        }, 3000)
      }
    },

    emitCancelFlow() {
      this.$emit('cancelSubscription')
    },

    DealerManagedProductId() {
      if (
        this.subscription &&
        this.subscription.activeProduct &&
        this.subscription.activeProduct[0] &&
        this.subscription.activeProduct[0].productId
      ) {
        if (
          this.subscription.activeProduct[0].productId == Constants.DealerManagedProductId ||
          this.subscription.activeProduct[0].productId == Constants.TankUtilityDealerManagedProductId
        )
          return true
      }
      return false
    },
    pendingSubscriptionChanges() {
      if (this.subscription) {
        if (this.subscription.activeProduct && this.subscription.activeProduct.length > 0) {
          if (
            this.subscription.activeProduct[0].status == Constants.PendingCancellationStatus ||
            this.subscription.activeProduct[0].status == Constants.PendingSuspensionStatus
          )
            return true
        }
        if (this.subscription.pendingProduct && this.subscription.pendingProduct.length > 0) return true
      }
      return false
    },
    pendingSubscriptionCancellation() {
      if (this.subscription) {
        if (this.subscription.activeProduct && this.subscription.activeProduct.length > 0) {
          if (this.subscription.activeProduct[0].status == Constants.PendingCancellationStatus) return true
        }
      }
      return false
    },
    setNewBillingAddress(newAddress) {
      this.accountData.address = newAddress
      if (this.$refs.typeAheadAddressSubscription.$v.$anyError) {
        this.addressSaveDisabled = true
      } else {
        this.addressSaveDisabled = false
      }
    },
    saveAccountHolder() {
      this.savingAccountHolder = true
      this.$store
        .dispatch('updateAccountHolder', {
          firstName: this.accountData.firstName,
          lastName: this.accountData.lastName,
          organizationAddress: {
            ...this.accountData.address,
            organizationId: this.accountData.organizationId
          }
        })
        .then(response => {
          if (!this.isImpersonating) this.$store.dispatch('getUser')
          else this.$store.dispatch('getImpersonatedUser')
          this.$store.dispatch('getAccountProfile')
          this.timeTheAccountChange()
        })
        .catch(error => {
          this.$store.dispatch('displayToast', {
            title: this.$t('xError'),
            message: this.$t('xFailedToUpdateBillingNameAndAddress'),
            variant: 'danger'
          })
        })
    },
    timeTheAccountChange() {
      setTimeout(
        function() {
          this.savingAccountHolder = false
          this.useExistingBillingInformation = true
        }.bind(this),
        5000
      )
    },
    payForProduct() {
      this.paying = true
      let addPaymentModel = {
        organizationId: this.accountProfile.organizationId,
        cardNumber: this.creditCardNumber,
        cvv: this.cvv,
        expirationMonth: this.expirationMonth,
        expirationYear: '20' + this.expirationYear
      }
      this.$store
        .dispatch('addBillingAccountPaymentMethod', addPaymentModel)
        .then(response => {
          this.paying = false
          this.closeModal('update-payment-method')
          this.closePaymentModal()
          this.$store
            .dispatch('getActivePaymentMethod', this.accountProfile.organizationId)
            .then(paymentResponse => {
              if (paymentResponse.data) {
                this.existingPaymentInfo = paymentResponse.data
                this.useExistingPaymentInfo = true
                this.billingInfoLoading = false
              } else {
                this.existingPaymentInfo = null
                this.useExistingPaymentInfo = false
                this.billingInfoLoading = false
              }
            })
            .catch(error => {
              this.existingPaymentInfo = null
              this.useExistingPaymentInfo = false
              this.billingInfoLoading = false
            })
        })
        .catch(error => {
          this.paying = false
          this.errorMessages = []
          if (error.response.data.errors) {
            if (error.response.data.errors.CardNumber || error.response.data.errors.cardNumber) {
              this.showValidationBanner = true
              this.errorMessages.push(this.$t('xCCIsInvalid'))
            }
            if (error.response.data.errors.Cvv || error.response.data.errors.cvv) {
              this.showValidationBanner = true
              this.errorMessages.push(this.$t('xCvvInvalid'))
            }
            if (error.response.data.errors.ExpirationMonth || error.response.data.errors.expirationMonth) {
              this.showValidationBanner = true
              this.errorMessages.push(this.$t('xExpirationMonthInvalid'))
            }
            if (error.response.data.errors.ExpirationYear || error.response.data.errors.expirationYear) {
              this.showValidationBanner = true
              this.errorMessages.push(this.$t('xExpirationYearInvalid'))
            }
            if (error.response.data.errors.billing || error.response.data.errors.Billing) {
              this.$store.dispatch('displayToast', {
                title: this.$t('xError'),
                message: error.response.data.errors.billing
                  ? error.response.data.errors.billing
                  : error.data.response.errors.Billing,
                variant: 'danger'
              })
            }
          } else {
            this.$store.dispatch('displayToast', {
              title: this.$t('xError'),
              message: this.$t('xFailedToAddPaymentMethod'),
              variant: 'danger'
            })
          }
        })
    },
    closePaymentModal() {
      this.creditCardNumber = null
      this.expirationMonth = null
      this.expirationYear = null
      this.cvv = null
      this.paying = false
      this.showValidationBanner = false
      this.errorMessages = []
      this.$v.$reset()
      this.closeModal('update-payment-method')
    },
    closeManagePlanModal() {
      this.closeModal('cancel-subscription-method')
    },
    doesAccountHaveApparatus() {
      return !!this.apparatuses?.find(
        x =>
          x.type === Constants.HsbApparatusType ||
          (x.type === Constants.FuelTankApparatusType &&
            x.properties.find(x => x.type === Constants.ApparatusPropertyDevice)?.value?.deviceType ===
              Constants.TankUtilityLteDeviceTypeV2)
      )
    },
    doesAccountHaveGen() {
      return (
        (this.isCancelLegacy && this.cancelLegacyApparatusType === Constants.HsbApparatusType) ||
        (!this.isCancelLegacy &&
          this.cancelSubscriptionApparatuses.find(apparatus => apparatus.type === Constants.HsbApparatusType))
      )
    },
    doesAccountHaveFuelMonitor() {
      return (
        (this.isCancelLegacy && this.cancelLegacyApparatusType === Constants.FuelTankApparatusType) ||
        (!this.isCancelLegacy &&
          this.cancelSubscriptionApparatuses.find(apparatus => apparatus.type === Constants.FuelTankApparatusType))
      )
    },
    addMonths(purchasedDate, subscriptionDuration) {
      const shortDate = displayShortDate(purchasedDate)
      const dateParts = shortDate.split('/')
      let month = parseInt(dateParts[0])
      let day = parseInt(dateParts[1])
      let year = parseInt(dateParts[2])
      let subscriptionDurationInt = Number(subscriptionDuration)

      month = month + subscriptionDurationInt
      if (month > 12) {
        month -= 12
        year += 1
      }
      const monthString = String(month).padStart(2, '0')
      const dayString = String(day).padStart(2, '0')
      const yearString = String(year)

      return `${monthString}/${dayString}/${yearString}.`
    },
    canPay() {
      if (!this.creditCardNumber || !this.cvv || !this.expirationMonth || !this.expirationYear) {
        return false
      }
      if (!this.$v.$anyError && !this.paying) {
        return true
      } else return false
    },
    async subscriptionClicked() {
      this.subscription = null
      this.accountData = _.cloneDeep(this.accountProfile)
      if (
        this.currentAppConfig &&
        this.currentAppConfig.featureFlags &&
        this.currentAppConfig.featureFlags[this.billingMigrationFeatureFlag]
      ) {
        await this.getSubscription()
      } else {
        await this.$store
          .dispatch('getActivePaymentMethod', this.accountProfile.organizationId)
          .then(response => {
            this.existingPaymentInfo = response.data
            this.billingInfoLoading = false
          })
          .catch(error => {
            this.existingPaymentInfo = null
            this.billingInfoLoading = false
          })
        await this.getSubscription()
      }
    },
    // async upgradeFreeSubscription() {
    //   this.subscriptionLoading = true
    //   await this.getSubscription()
    //   if (this.subscription.discount[0] != null) {
    //     this.$store.dispatch('displayToast', {
    //       message: this.$t('xYourSubscriptionHasBeenSuccessfullyUpdated'),
    //       variant: 'success'
    //     })
    //   } else {
    //     this.$store.dispatch('displayToast', {
    //       message: this.$t('xYourSubscriptionPlanHasBeenSuccessfullyUpdated'),
    //       variant: 'success'
    //     })
    //   }
    // },
    updatePaymentInformation() {
      this.paying = true
      let addPaymentModel = {
        organizationId: this.accountProfile.organizationId,
        cardNumber: this.creditCardNumber,
        cvv: this.cvv,
        expirationMonth: this.expirationMonth,
        expirationYear: '20' + this.expirationYear
      }
      if (this.existingPaymentInfo) {
        let removePaymentModel = {
          organizationId: this.accountProfile.organizationId,
          paymentId: this.existingPaymentInfo.id
        }
        this.$store
          .dispatch('addBillingAccountPaymentMethod', addPaymentModel)
          .then(response => {
            this.$store
              .dispatch('getActivePaymentMethod', this.accountProfile.organizationId)
              .then(response => {
                this.existingPaymentInfo = response.data ? response.data : null
              })
              .catch(error => {
                this.existingPaymentInfo = null
              })
            this.$store.dispatch('displayToast', {
              title: this.$t('xSuccess'),
              message: this.$t('xPaymentMethodUpdated'),
              variant: 'success'
            })
            this.closePaymentModal()
          })
          .catch(error => {
            this.paying = false
            this.errorMessages = []
            if (error.response.data.errors) {
              if (error.response.data.errors.CardNumber || error.response.data.errors.cardNumber) {
                this.showValidationBanner = true
                this.errorMessages.push(this.$t('xCCIsInvalid'))
              }
              if (error.response.data.errors.Cvv || error.response.data.errors.cvv) {
                this.showValidationBanner = true
                this.errorMessages.push(this.$t('xCvvInvalid'))
              }
              if (error.response.data.errors.ExpirationMonth || error.response.data.errors.expirationMonth) {
                this.showValidationBanner = true
                this.errorMessages.push(this.$t('xExpirationMonthInvalid'))
              }
              if (error.response.data.errors.ExpirationYear || error.response.data.errors.expirationYear) {
                this.showValidationBanner = true
                this.errorMessages.push(this.$t('xExpirationYearInvalid'))
              }
              if (error.response.data.errors.billing || error.response.data.errors.Billing) {
                this.$store.dispatch('displayToast', {
                  title: this.$t('xError'),
                  message: error.response.data.errors.billing
                    ? error.response.data.errors.billing
                    : error.data.response.errors.Billing,
                  variant: 'danger'
                })
              }
            } else {
              this.$store.dispatch('displayToast', {
                title: this.$t('xUpdateFailed'),
                message: this.$t('xUnableToUpdatePaymentInfo'),
                variant: 'danger'
              })
            }
          })
      }
    },
    displayMonth(month) {
      let monthString = String(month)
      if (monthString.length === 1) return '0' + month
      else return month
    },
    async removePaymentInformation() {
      this.removing = true
      let removePaymentModel = {
        organizationId: this.accountProfile.organizationId,
        paymentId: this.existingPaymentInfo.id
      }
      this.$store
        .dispatch('removeBillingAccountPaymentMethod', removePaymentModel)
        .then(response => {
          this.existingPaymentInfo = null
          this.$store.dispatch('displayToast', {
            title: this.$t('xSuccess'),
            message: this.$t('xPaymentMethodRemoved'),
            variant: 'success'
          })
          this.removing = false
        })
        .catch(error => {
          this.$store.dispatch('displayToast', {
            title: this.$t('xRemoveFailed'),
            message: this.$t('xUnableToRemovePaymentInfo'),
            variant: 'danger'
          })
          this.removing = false
        })
    },
    async getSubscription() {
      this.loadingSubscriptionId = this.currentApparatus.apparatusId
      await this.$store
        .dispatch('getApparatusSubscription', {
          organizationId: this.accountProfile.organizationId,
          apparatusId: this.currentApparatus.apparatusId
        })
        .then(response => {
          this.subscription = response.data
        })
        .catch(error => {
          this.$store.dispatch('displayToast', {
            title: this.$t('xError'),
            message: this.$t('xNoSubscriptionsFoundForThisGenerator'),
            variant: 'danger'
          })
        })
      this.subscriptionLoading = false
    },
    async getPaymentMethod() {
      await this.$store
        .dispatch('getActivePaymentMethod', this.accountProfile.organizationId)
        .then(response => {
          this.existingPaymentInfo = response.data ? response.data : null
          this.billingInfoLoading = false
        })
        .catch(error => {
          this.existingPaymentInfo = null
          this.billingInfoLoading = false
        })
    },
    displayPlanCostCycle(duration) {
      if (duration == 1) return this.$t('xPerMonth')
      if (duration == 12) return this.$t('xPerYear')
    },
    displayLegacyPlanCostCycle(duration) {
      if (duration == 1) return this.$t('xPerMonth')
      if (duration == 12) return this.$t('xPerYear')
    },
    getApparatusIcon(type) {
      switch (type) {
        case Constants.HsbApparatusType:
          return 'fa-kit fa-fw fa-generator'
        case Constants.FuelTankApparatusType:
          return 'fa-kit fa-fw fa-tank'
        default:
          return 'fa-kit fa-fw fa-generator'
      }
    },
    loadSubscriptionModal() {
      this.loadingSubscriptionModal = true
      const subscriptionLoadActions = []
      if (this.accountProfile?.organizationId) {
        subscriptionLoadActions.push(
          this.$store
            .dispatch('getSubscriptionProductsByStoreOrganizationApparatus', {
              organizationId: this.accountProfile?.organizationId,
              apparatusTypeToAdd: Constants.HsbApparatusStringType //TODO: this needs to to be changed to account for the apparatus/es that is/are being upgraded to premium
            })
            .then(subResponse => {
              if (subResponse.data?.products) {
                const premiumHomeSub = subResponse.data.products.find(
                  prod => prod.productId === Constants.HomePremiumOneYearlyProductId
                ) // this is all we're offering temperarily. TODO: display more of the given options
                this.premiumSubscriptionCost = premiumHomeSub?.cost
                this.premiumSubscriptionProductId = premiumHomeSub?.productId
                this.premiumSubscriptionDuration = premiumHomeSub?.durationInMonths
                this.premiumSubscriptionName = premiumHomeSub?.name
              }

              this.$store.dispatch('getSubscriptionDetails', this.accountProfile.organizationId).then(response => {
                if (response?.data?.length) {
                  // get old sub display data
                  const oldSubs = []
                }
              })
            })
            .catch(error => {
              this.$store.dispatch('displayToast', {
                title: this.$t('xError'),
                message: this.$t('xSomethingUnexpectedWentWrong'),
                variant: 'danger'
              })
            })
        )
      }
      subscriptionLoadActions.push(
        this.$store
          .dispatch('getActivePaymentMethod', this.accountProfile.organizationId)
          .then(response => {
            if (response.data) {
              this.existingPaymentInfo = response.data
              this.useExistingPaymentInfo = true
            } else {
              this.existingPaymentInfo = null
              this.useExistingPaymentInfo = false
            }
          })
          .catch(error => {
            this.existingPaymentInfo = null
            this.useExistingPaymentInfo = false
          })
      )

      Promise.all(subscriptionLoadActions).then(() => {
        this.finishingSetup = false
        this.loadingSubscriptionModal = false
      })
    },
    subscribeProduct() {
      if (this.consent) {
        this.subscribingProduct = true
        let apparatusesEligibleForUpgrade = []
        this.apparatuses.forEach(apparatus => {
          if (this.isApparatusEligibleForUpgrade(apparatus)) {
            apparatusesEligibleForUpgrade.push(apparatus.apparatusId)
          }
        })
        this.$store.dispatch('storeSubscriptionTermsConsent', this.accountProfile.organizationId).then(response => {})
        this.$store.dispatch('getSubscriptionDetails', this.accountProfile.organizationId).then(currentSubResponse => {
          if (apparatusesEligibleForUpgrade.length > 0) {
            // create purchase intent
            this.$store
              .dispatch('createPurchaseIntent', {
                productId: this.premiumSubscriptionProductId,
                organizationId: this.accountProfile.organizationId,
                apparatusIds: apparatusesEligibleForUpgrade
              })
              .then(response => {
                this.$store
                  .dispatch('updateSubscriptionProduct', {
                    organizationId: this.accountProfile.organizationId,
                    productId: this.premiumSubscriptionProductId
                  })
                  .catch(error => {
                    this.subscribingProduct = false
                    this.$store.dispatch('displayToast', {
                      title: this.$t('xError'),
                      message: this.$t('xPlanChangesFailed'),
                      variant: 'danger'
                    })
                  })
                  .finally(updateresult => {
                    this.pollForSubscriptionDataAndRefresh()
                  })
              })
              .catch(error => {
                this.subscribingProduct = false
                this.$store.dispatch('displayToast', {
                  title: this.$t('xError'),
                  message: this.$t('xPlanChangesFailed'),
                  variant: 'danger'
                })
                this.$bvModal.hide('premium-enrollment-modal-step-2')
              })
          }
        })
      } else {
        this.consent = false
      }
    },
    async pollForSubscriptionDataAndRefresh() {
      // Check for apparatus data repeatedly or for 5 seconds, if it doesn't show up, display toast
      const operationStart = Date.now()
      const delay = ms => new Promise(res => setTimeout(res, ms))
      let subscription = this.subscriptionDetails?.subscriptions.find(subscription => subscription?.productId === Constants.HomePremiumOneYearlyProductId && subscription?.status !== Constants.SubscriptionDetailCanceled)
      while (Date.now() - operationStart < 5000 && !subscription) {
        const response = await this.$store
          .dispatch('getSubscriptionDetails', this.accountProfile.organizationId)
          .catch(() => {})
        this.subscriptionDetails = response.data
        subscription = this.subscriptionDetails?.subscriptions.find(subscription => subscription?.productId === Constants.HomePremiumOneYearlyProductId && subscription?.status !== Constants.SubscriptionDetailCanceled)
        const purchaseDate = subscription?.purchasedDate
        const duration = subscription?.product?.durationInMonths
        if (purchaseDate && duration) {
          this.endDate = this.addMonths(purchaseDate, duration)
        } else this.endDate == null
        await delay(1000)
      }
      if (!subscription) {
        this.$store.dispatch('displayToast', {
          title: this.$t('xProcessingSubscriptionInformation'),
          message: this.$t('xGettingPremiumFeaturesReady'),
          variant: 'success'
        })
      }
      this.subscribingProduct = false
      this.$bvModal.hide('premium-enrollment-modal-step-2')
    },
    navToSupportPage() {
      this.$appInsights.trackEvent({
        name: 'click-contact-support-settings-subscription-modal',
        properties: {}
      })
      this.$router.push({
        path: `/support`
      })
    }
  },
  async mounted() {
    if (this.apparatuses == null || this.apparatuses?.length == 0) {
      await this.$store.dispatch('getApparatuses')
    }
    if (this.accountProfile) {
      this.accountData = _.cloneDeep(this.accountProfile)
      await this.$store
        .dispatch('getSubscriptionDetails', this.accountProfile.organizationId)
        .then(response => {
          this.subscriptionDetails = response.data
          const purchaseDate = this.subscriptionDetails?.subscriptions[0]?.purchasedDate
          const duration = this.subscriptionDetails?.subscriptions[0]?.product?.durationInMonths
          if (purchaseDate && duration) {
            this.endDate = this.addMonths(purchaseDate, duration)
          } else this.endDate == null
        })
        .catch(() => {})

      await this.getPaymentMethod()
      await this.$store
        .dispatch('getAllApparatusSubscriptions', {
          organizationId: this.accountProfile.organizationId
        })
        .then(response => {
          if (response.data?.activeProduct.length > 0) this.shouldShowUpdatePayment = true
          else this.shouldShowRemovePayment = true
          this.legacySubscriptionList = response.data?.activeProduct
          this.subscriptionListLoading = false
        })
        .catch(() => {})

      await this.$store
        .dispatch('getOrganizationSubscriptionDetails', {
          organizationId: this.accountProfile.organizationId
        })
        .then(response => {
          this.organizationSubscriptionDetails = response.data
        })
        .catch(() => {})

      //collect a list of apparatus ids in the subscriptions.apparatuses
      var subscriptionApparatusIds = this.subscriptionDetails?.subscriptions
        ?.map(
          subscription =>
            subscription.status !== Constants.SubscriptionDetailCanceled &&
            subscription.apparatuses.map(apparatus => apparatus.apparatusId)
        )
        .reduce((acc, val) => acc.concat(val), [])

      this.legacySubscriptionList = this.apparatuses
        ?.filter(apparatus => !subscriptionApparatusIds.includes(apparatus?.apparatusId)) //this weeds out any ids that will be in the new subs list
        .map(apparatus => {
          const subscription = this.subscriptionDetails?.legacyBillingAccount?.products.find(
            product => product.apparatusId === apparatus.apparatusId
          )
          if (
            subscription != null &&
            subscription != undefined &&
            (subscription?.status === Constants.SubscriptionActive ||
              subscription?.status === Constants.SubscriptionPendingCancellation ||
              subscription?.status === Constants.SubscriptionPendingTermination ||
              subscription?.status === Constants.SubscriptionPendingSuspension ||
              subscription?.status === Constants.SubscriptionDunning)
          ) {
            return {
              apparatusId: apparatus.apparatusId,
              apparatusName: apparatus.name,
              apparatusType: apparatus.type,
              isDealerManaged: apparatus.isDealerManaged,
              dealerEmail: apparatus.preferredDealerEmail,
              subscriptionProductId: subscription?.productId,
              subscriptionProductName: subscription?.productName || '',
              subscriptionCost: subscription?.cost || 0,
              subscriptionDuration: subscription?.durationInMonths || 0,
              subscriptionNextBillingDate: subscription?.nextBillingDate || '',
              subscriptionStatus: subscription?.status
            }
          }
        })

      this.singleHomePremiumApparatuses = this.subscriptionDetails?.subscriptions
        ?.filter(subscription => subscription?.status !== Constants.SubscriptionDetailCanceled)
        .map(subscription => {
          var apparatusList = []
          subscription?.apparatuses.forEach(subApparatus => {
            const apparatus = this.apparatuses.find(apparatus => apparatus.apparatusId == subApparatus.apparatusId)
            apparatusList.push({
              id: apparatus?.apparatusId,
              name: apparatus?.name,
              type: apparatus?.type
            })
          })
          return {
            apparatuses: apparatusList,
            subscriptionCost: subscription?.product.cost || 0,
            subscriptionDurationInMonths: subscription?.product.durationInMonths || 0,
            subscriptionProductId: subscription?.product.productId,
            subscriptionProductName: subscription?.product.name || '',
            subscriptionRenewalDate: subscription?.renewalDate || '',
            subscriptionStore: subscription?.store,
            subscriptionStatus: subscription?.status
          }
        })

      // next block determines when the Upgrade window is shown
      var allowSubUpgradeLegacyNoBillingAccount =
        this.subscriptionDetails?.legacyBillingAccount === null ||
        this.subscriptionDetails?.legacyBillingAccount?.products === null ||
        this.subscriptionDetails?.legacyBillingAccount?.products?.filter(
          product => !subscriptionApparatusIds.includes(product?.apparatusId)
        ).length === 0 ||
        this.subscriptionDetails?.legacyBillingAccount?.products?.filter(
          product =>
            product?.status === Constants.SubscriptionActive ||
            product?.status === Constants.SubscriptionPendingCancellation ||
            product?.status === Constants.SubscriptionPendingTermination ||
            product?.status === Constants.SubscriptionPendingSuspension ||
            product?.status === Constants.SubscriptionDunning
        ).length === 0

      var allowSubUpgradeLegacyNoActiveSubscriptions =
        this.subscriptionDetails?.legacyBillingAccount?.products?.filter(
          product =>
            (!subscriptionApparatusIds.includes(product?.apparatusId) &&
              product?.status === Constants.SubscriptionActive) ||
            product?.status === Constants.SubscriptionPendingCancellation ||
            product?.status === Constants.SubscriptionPendingTermination ||
            product?.status === Constants.SubscriptionPendingSuspension ||
            product?.status === Constants.SubscriptionDunning
        ).length === 0 || this.subscriptionDetails?.legacyBillingAccount == null

      var allowSubUpgradeLegacyDealerManagedButNoActiveSubscriptions =
        this.subscriptionDetails?.legacyBillingAccount?.products?.filter(
          product =>
            !subscriptionApparatusIds.includes(product?.apparatusId) &&
            product?.productId === Constants.DealerManagedProductId &&
            (product?.status === Constants.SubscriptionActive ||
              product?.status === Constants.SubscriptionPendingCancellation ||
              product?.status === Constants.SubscriptionPendingTermination ||
              product?.status === Constants.SubscriptionPendingSuspension ||
              product?.status === Constants.SubscriptionDunning)
        ).length === 0 ||
        (this.subscriptionDetails?.legacyBillingAccount == null &&
          this.subscriptionDetails?.legacyBillingAccount?.products?.filter(
            product =>
              !subscriptionApparatusIds.includes(product?.apparatusId) &&
              product?.productId === Constants.TankUtilityDealerManagedProductId &&
              (product?.status === Constants.SubscriptionActive ||
                product?.status === Constants.SubscriptionPendingCancellation ||
                product?.status === Constants.SubscriptionPendingTermination ||
                product?.status === Constants.SubscriptionPendingSuspension ||
                product?.status === Constants.SubscriptionDunning)
          ).length === 0) ||
        this.subscriptionDetails?.legacyBillingAccount == null

      var allowSubUpgradeSubscriptionDealerManagedButNoActiveSubscriptions =
        this.subscriptionDetails?.subscriptions?.filter(
          subscription =>
            subscription?.productId === Constants.DealerManagedProductId &&
            subscription?.status === Constants.SubscriptionDetailCanceled
        ).length > 0
      var allowSubUpgradeSubscriptionDealerManagedButNoActiveSubscriptionsTanks =
        this.subscriptionDetails?.subscriptions?.filter(
          subscription =>
            subscription?.productId === Constants.TankUtilityDealerManagedProductId &&
            subscription?.status === Constants.SubscriptionDetailCanceled
        ).length > 0

      var allowSubUpgradeLegacy =
        this.subscriptionDetails?.legacyBillingAccount != null &&
        allowSubUpgradeLegacyNoBillingAccount &&
        allowSubUpgradeLegacyNoActiveSubscriptions &&
        allowSubUpgradeLegacyDealerManagedButNoActiveSubscriptions

      var allowSubNewPremium =
        this.subscriptionDetails?.subscriptions?.filter(
          subscription =>
            (subscription?.status === Constants.SubscriptionDetailPurchaseIntent ||
            subscription?.status === Constants.SubscriptionDetailActive ||
            subscription?.status === Constants.SubscriptionDetailPendingCancel ||
            subscription?.status === Constants.SubscriptionDetailPendingProductChange) && subscription?.store !== PremiumStoreTypes.DEALER
        ).length === 0

      var allDealerManaged = 
        this.apparatuses?.every(
          apparatus =>
           apparatus.isDealerManaged
        )

      this.hasProductsNoSubscription =
        !this.doesAccountHaveLegacySub() && this.apparatuses.find(x => this.isApparatusEligibleForUpgrade(x)) && allowSubNewPremium && !allDealerManaged
      this.isLoading = false
    } else {
      this.$store.dispatch('getAccountProfile')
    }
  }
}
</script>
