var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "Modal",
        {
          attrs: {
            modalId:
              "deviceSwapModal-newDeviceInfo" + _vm.oldDevice.apparatusId,
            size: "modal-xl"
          }
        },
        [
          _c("ModalBody", [
            _c("div", { staticClass: "card no-border" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col" }, [
                  _c("h1", [_vm._v("Replace Device")])
                ])
              ]),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col" }, [
                  _c("p", [
                    _vm._v(
                      " Please specify the new device type below. Depending on the New Device Type Specified, the MAC address and (For Wi-Fi) or Device Serial Number (For 4G LTE Accessories or Wi-Fi/Ethernet Accessories) will be required. "
                    ),
                    _c("span", { staticClass: "pre-swap-warning" }, [
                      _vm._v(
                        " Please note: the new device MUST be installed and communicating prior to performing the device swap. "
                      )
                    ])
                  ])
                ])
              ]),
              _c("div", { staticClass: "row mt-2 mb-2" }, [
                _c("div", { staticClass: "col" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._f("formatLabelNoTranslation")("Old Device Type")
                      ) +
                      " " +
                      _vm._s(_vm.DisplayOldDeviceType()) +
                      " "
                  )
                ])
              ]),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12 col-md-6" }, [
                  _c(
                    "div",
                    {
                      staticClass: "form-group",
                      attrs: { role: "group", id: "newDeviceType-group" }
                    },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "d-block",
                          attrs: { for: "newDevicetype" }
                        },
                        [_vm._v("New Device Type")]
                      ),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.newDeviceType,
                              expression: "form.newDeviceType"
                            }
                          ],
                          staticClass: "custom-select",
                          attrs: { id: "newDevicetype" },
                          on: {
                            change: function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.form,
                                "newDeviceType",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            }
                          }
                        },
                        _vm._l(_vm.deviceTypes, function(deviceType, index) {
                          return _c(
                            "option",
                            {
                              key: index,
                              domProps: { value: deviceType.value }
                            },
                            [_vm._v(" " + _vm._s(deviceType.text) + " ")]
                          )
                        }),
                        0
                      )
                    ]
                  )
                ])
              ]),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12 col-md-6" }, [
                  _c(
                    "div",
                    {
                      staticClass: "form-group",
                      attrs: {
                        role: "group",
                        id: "mobileLinkDeviceIdentifier-group"
                      }
                    },
                    [
                      _c(
                        "label",
                        { attrs: { for: "mobileLinkDeviceIdentifier" } },
                        [_vm._v("Mobile Link Device Identifier")]
                      ),
                      _c("div", [
                        _vm.form.newDeviceType == "wifi"
                          ? _c("input", {
                              directives: [
                                {
                                  name: "mask",
                                  rawName: "v-mask",
                                  value: "NN:NN:NN:NN:NN:NN",
                                  expression: "'NN:NN:NN:NN:NN:NN'"
                                },
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.form.mobileLinkDeviceIdentifier,
                                  expression: "form.mobileLinkDeviceIdentifier"
                                }
                              ],
                              staticClass: "form-control",
                              attrs: {
                                id: "mobileLinkDeviceIdenifier",
                                type: "text",
                                maxlength: "17"
                              },
                              domProps: {
                                value: _vm.form.mobileLinkDeviceIdentifier
                              },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.form,
                                    "mobileLinkDeviceIdentifier",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          : _vm.form.newDeviceType == _vm.hsbG3DeviceType
                          ? _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.form.mobileLinkDeviceIdentifier,
                                  expression: "form.mobileLinkDeviceIdentifier"
                                }
                              ],
                              staticClass: "form-control",
                              attrs: {
                                id: "mobileLinkDeviceIdentifier",
                                type: "text",
                                maxlength: "9"
                              },
                              domProps: {
                                value: _vm.form.mobileLinkDeviceIdentifier
                              },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.form,
                                    "mobileLinkDeviceIdentifier",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          : _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.form.mobileLinkDeviceIdentifier,
                                  expression: "form.mobileLinkDeviceIdentifier"
                                }
                              ],
                              staticClass: "form-control",
                              attrs: {
                                id: "mobileLinkDeviceIdenifier",
                                type: "text",
                                maxlength: "10"
                              },
                              domProps: {
                                value: _vm.form.mobileLinkDeviceIdentifier
                              },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.form,
                                    "mobileLinkDeviceIdentifier",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                      ])
                    ]
                  )
                ])
              ])
            ])
          ]),
          _c("ModalFooter", [
            _c(
              "button",
              {
                staticClass: "btn btn-secondary mr-2",
                attrs: {
                  type: "button",
                  "data-test-hook": "cancel-button-newDeviceInfo-device-swap"
                },
                on: {
                  click: function($event) {
                    return _vm.ResetModal()
                  }
                }
              },
              [_vm._v(" Cancel ")]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                attrs: {
                  type: "button",
                  disabled: _vm.CanSwap(),
                  "data-test-hook": "submit-button-newDeviceInfo-device-swap"
                },
                on: {
                  click: function($event) {
                    return _vm.validateDevice()
                  }
                }
              },
              [
                _vm._v(" Submit "),
                _vm.validatingDevice
                  ? _c("div", { staticClass: "spinner-border text-muted ml-2" })
                  : _vm._e()
              ]
            )
          ])
        ],
        1
      ),
      _c(
        "Modal",
        {
          attrs: {
            modalId:
              "deviceSwapModal-confirmDeviceInfo" + _vm.oldDevice.apparatusId
          }
        },
        [
          _c("div", { staticClass: "modal-header" }, [
            _c("div", { staticClass: "row align-items-center" }, [
              _c("div", { staticClass: "col-auto" }, [
                _c("i", {
                  staticClass:
                    "fas fa-chevron-left back-chevron-button hoverPointer",
                  on: {
                    click: function($event) {
                      _vm.closeModal(
                        "deviceSwapModal-confirmDeviceInfo" +
                          _vm.oldDevice.apparatusId
                      ),
                        _vm.openModal(
                          "deviceSwapModal-newDeviceInfo" +
                            _vm.oldDevice.apparatusId
                        )
                    }
                  }
                })
              ]),
              _c("div", { staticClass: "col" }, [
                _c("h2", { staticClass: "modal-title" }, [
                  _vm._v(" Confirm Device Information ")
                ])
              ])
            ])
          ]),
          _c("ModalBody", [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col" }, [
                _c("h5", [
                  _vm._v(
                    "Device Type: " +
                      _vm._s(
                        _vm.form.newDeviceType === _vm.hsbG3DeviceType
                          ? "G3"
                          : _vm.form.newDeviceType
                      )
                  )
                ])
              ])
            ]),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col" }, [
                _c("h5", [
                  _vm._v(
                    "Device Identifier: " +
                      _vm._s(_vm.form.mobileLinkDeviceIdentifier)
                  )
                ])
              ])
            ])
          ]),
          _c("ModalFooter", [
            _c(
              "button",
              {
                staticClass: "btn btn-outline-danger",
                attrs: {
                  type: "button",
                  "data-test-hook":
                    "cancel-button-confirmDeviceInfo-device-swap"
                },
                on: {
                  click: function($event) {
                    return _vm.ResetModal()
                  }
                }
              },
              [_vm._v(" CANCEL ")]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                attrs: {
                  type: "button",
                  "data-test-hook":
                    "confirm-button-confirmDeviceInfo-device-swap"
                },
                on: {
                  click: function($event) {
                    return _vm.getAvailableSubscriptions()
                  }
                }
              },
              [
                _vm._v(" CONFIRM "),
                _vm.gettingSubscriptions
                  ? _c("div", { staticClass: "spinner-border text-muted ml-2" })
                  : _vm._e()
              ]
            )
          ])
        ],
        1
      ),
      _c(
        "Modal",
        {
          attrs: {
            modalId:
              "deviceSwapModal-newSubscriptionInfo" + _vm.oldDevice.apparatusId,
            size: "modal-xl"
          }
        },
        [
          _c("div", { staticClass: "modal-header" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-auto" }, [
                _c("i", {
                  staticClass:
                    "fas fa-chevron-left back-chevron-button hoverPointer",
                  on: {
                    click: function($event) {
                      _vm.closeModal(
                        "deviceSwapModal-newSubscriptionInfo" +
                          _vm.oldDevice.apparatusId
                      ),
                        _vm.openModal(
                          "deviceSwapModal-confirmDeviceInfo" +
                            _vm.oldDevice.apparatusId
                        )
                    }
                  }
                })
              ]),
              _c("div", { staticClass: "col" }, [
                _c("h2", { staticClass: "modal-title" }, [
                  _c("i", {
                    staticClass: "far fa-badge-dollar colorOrange mr-1"
                  }),
                  _vm._v(" Choose A Subscription")
                ])
              ])
            ])
          ]),
          _c("ModalBody", [
            _vm.gettingSubscriptions
              ? _c("div", [
                  _c(
                    "div",
                    { staticClass: "col float-center text-center" },
                    [
                      _c("b-spinner", {
                        staticStyle: { width: "10rem", height: "10rem" },
                        attrs: { variant: "warning", label: "Spinning" }
                      })
                    ],
                    1
                  )
                ])
              : _c("div", [
                  _vm.availableSubscriptions
                    ? _c(
                        "div",
                        { staticClass: "row", attrs: { "align-h": "center" } },
                        _vm._l(_vm.availableSubscriptions, function(
                          product,
                          index
                        ) {
                          return _c(
                            "div",
                            { key: index, staticClass: "mx-auto card-deck" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "card mx-0 mb-3 subscription-card hoverPointer",
                                  class: _vm.getSubscriptionVariant(product),
                                  on: {
                                    click: function($event) {
                                      return _vm.setSelectedProduct(product)
                                    }
                                  }
                                },
                                [
                                  product.durationInMonths == 12
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "card-header bg-warning text-white"
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "row text-center" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "col" },
                                                [
                                                  _c(
                                                    "h3",
                                                    {
                                                      staticClass: "colorWhite"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "Save " +
                                                          _vm._s(
                                                            _vm.getSubscriptionSavings(
                                                              product
                                                            )
                                                          ) +
                                                          "%"
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "h4",
                                    { staticClass: "card-title text-center" },
                                    [
                                      _c("h2", [
                                        _vm._v(
                                          _vm._s(_vm.getProductCost(product))
                                        )
                                      ])
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "card-body text-center" },
                                    [
                                      _c("div", { staticClass: "row mb-2" }, [
                                        _c("h2", [_vm._v(_vm._s(product.name))])
                                      ])
                                    ]
                                  ),
                                  _c("div", { staticClass: "footer" }, [
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "btn btn-warning btn-block",
                                        attrs: {
                                          type: "button",
                                          disabled:
                                            _vm.getSubscriptionVariant(
                                              product
                                            ) != "selected",
                                          "data-test-hook":
                                            "select-button-newSubscriptionInfo-device-swap"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.SwapOrPay()
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(" SELECT "),
                                        _vm.getSubscriptionVariant(product) ==
                                          "selected" && _vm.paying
                                          ? _c("div", {
                                              staticClass:
                                                "spinner-border text-muted ml-2"
                                            })
                                          : _vm._e()
                                      ]
                                    )
                                  ])
                                ]
                              )
                            ]
                          )
                        }),
                        0
                      )
                    : _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col" }, [
                          _c("h5", [_vm._v("No Subscription types available.")])
                        ])
                      ])
                ])
          ]),
          _c("ModalFooter", [
            _c(
              "button",
              {
                staticClass: "btn btn-outline-danger",
                attrs: {
                  "type-": "button",
                  "data-test-hook":
                    "cancel-button-newSubscriptionInfo-device-swap"
                },
                on: {
                  click: function($event) {
                    return _vm.ResetModal()
                  }
                }
              },
              [_vm._v(" CANCEL ")]
            )
          ])
        ],
        1
      ),
      _c(
        "Modal",
        {
          attrs: {
            modalId:
              "deviceSwapModal-paymentInformation" + _vm.oldDevice.apparatusId,
            size: "modal-lg"
          }
        },
        [
          _c("div", { staticClass: "modal-header" }, [
            _c("div", { staticClass: "row align-items-center" }, [
              _c("div", { staticClass: "col-auto" }, [
                _c("i", {
                  staticClass:
                    "fas fa-chevron-left back-chevron-button hoverPointer",
                  on: {
                    click: function($event) {
                      _vm.closeModal(
                        "deviceSwapModal-paymentInformation" +
                          _vm.oldDevice.apparatusId
                      ),
                        _vm.openModal(
                          "deviceSwapModal-newSubscriptionInfo" +
                            _vm.oldDevice.apparatusId
                        )
                    }
                  }
                })
              ]),
              _c("div", { staticClass: "col" }, [
                _c("h2", { staticClass: "modal-title" }, [
                  _c("i", {
                    staticClass: "far fa-credit-card colorOrange mr-1"
                  }),
                  _vm._v(" Add A Payment Method")
                ])
              ])
            ])
          ]),
          _c("ModalBody", [
            _vm.gettingPaymentInfo
              ? _c("div", [
                  _c("div", { staticClass: "col float-center text-center" }, [
                    _c("div", {
                      staticClass: "spinner-border spinner-warning ml-2",
                      staticStyle: { width: "10rem", height: "10rem" }
                    })
                  ])
                ])
              : _c("div", [
                  _vm.existingPaymentInfo
                    ? _c("div", [
                        _vm.useExistingPaymentInfo
                          ? _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col" }, [
                                _c("h3", [
                                  _c("strong", [
                                    _vm._v(
                                      "Current Credit Card Information On File"
                                    )
                                  ]),
                                  _c(
                                    "span",
                                    { staticClass: "body-text-color" },
                                    [_vm._v("*")]
                                  )
                                ])
                              ])
                            ])
                          : _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col" }, [
                                _c("h3", [
                                  _c("strong", [
                                    _vm._v("Update Credit Card Information")
                                  ]),
                                  _c(
                                    "span",
                                    { staticClass: "body-text-color" },
                                    [_vm._v("*")]
                                  )
                                ])
                              ])
                            ]),
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "font-weight-bold custom-control custom-checkbox"
                              },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.useExistingPaymentInfo,
                                      expression: "useExistingPaymentInfo"
                                    }
                                  ],
                                  staticClass: "custom-control-input",
                                  attrs: {
                                    type: "checkbox",
                                    value: "true",
                                    id: "customCheck",
                                    "data-test-hook":
                                      "use-current-cc-info-checkbox-paymentInformation-device-swap"
                                  },
                                  domProps: {
                                    checked: Array.isArray(
                                      _vm.useExistingPaymentInfo
                                    )
                                      ? _vm._i(
                                          _vm.useExistingPaymentInfo,
                                          "true"
                                        ) > -1
                                      : _vm.useExistingPaymentInfo
                                  },
                                  on: {
                                    change: function($event) {
                                      var $$a = _vm.useExistingPaymentInfo,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = "true",
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            (_vm.useExistingPaymentInfo = $$a.concat(
                                              [$$v]
                                            ))
                                        } else {
                                          $$i > -1 &&
                                            (_vm.useExistingPaymentInfo = $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1)))
                                        }
                                      } else {
                                        _vm.useExistingPaymentInfo = $$c
                                      }
                                    }
                                  }
                                }),
                                _c(
                                  "label",
                                  {
                                    staticClass: "custom-control-check",
                                    attrs: { for: "customCheck" }
                                  },
                                  [
                                    _vm._v(
                                      "Use Current Credit Card Information"
                                    )
                                  ]
                                )
                              ]
                            )
                          ])
                        ]),
                        _vm.useExistingPaymentInfo
                          ? _c("div", [
                              _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col-12" }, [
                                  _c("p", [
                                    _c("strong", [
                                      _c("i", {
                                        staticClass: "fal fa-credit-card"
                                      }),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.existingPaymentInfo.cardType
                                          ) +
                                          " - " +
                                          _vm._s(
                                            _vm.existingPaymentInfo.cardSuffix
                                          ) +
                                          " "
                                      )
                                    ])
                                  ]),
                                  _c("p", [
                                    _vm._v(
                                      "Expires " +
                                        _vm._s(
                                          _vm.existingPaymentInfo
                                            .expirationMonth +
                                            "/" +
                                            _vm.existingPaymentInfo
                                              .expirationYear
                                        )
                                    )
                                  ])
                                ])
                              ])
                            ])
                          : _c("div", [
                              _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col-12" }, [
                                  _c(
                                    "fieldset",
                                    {
                                      staticClass: "form-group",
                                      attrs: { id: "credit-card-number" }
                                    },
                                    [
                                      _c("div", [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.creditCardNumber,
                                              expression: "creditCardNumber"
                                            }
                                          ],
                                          staticClass: "form-control",
                                          attrs: {
                                            type: "text",
                                            placeholder: "Credit Card Number",
                                            "aria-describedby":
                                              "card-number-invalid",
                                            state: _vm.creditCardIsValid
                                          },
                                          domProps: {
                                            value: _vm.creditCardNumber
                                          },
                                          on: {
                                            keypress: function($event) {
                                              return _vm.creditCardValidation(
                                                $event,
                                                _vm.creditCardNumber
                                              )
                                            },
                                            input: [
                                              function($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.creditCardNumber =
                                                  $event.target.value
                                              },
                                              function($event) {
                                                _vm.creditCardIsValid = null
                                              }
                                            ]
                                          }
                                        }),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "invalid-feedback",
                                            attrs: { id: "card-number-invalid" }
                                          },
                                          [
                                            _vm._v(
                                              " Please enter a valid credit card number. "
                                            )
                                          ]
                                        )
                                      ])
                                    ]
                                  )
                                ])
                              ]),
                              _c("div", { staticClass: "row" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "col-sm-12 col-md-6 col-lg-6"
                                  },
                                  [
                                    _c(
                                      "fieldset",
                                      {
                                        staticClass: "form-group",
                                        attrs: { id: "expiry-date" }
                                      },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.fullExpiryDate,
                                              expression: "fullExpiryDate"
                                            },
                                            {
                                              name: "mask",
                                              rawName: "v-mask",
                                              value: "##/####",
                                              expression: "'##/####'"
                                            }
                                          ],
                                          staticClass: "form-control",
                                          attrs: {
                                            type: "text",
                                            placeholder: "MM/YYYY",
                                            maxlength: "7",
                                            "aria-describedby":
                                              "expiry-invalid",
                                            state: _vm.expiryDateIsValid
                                          },
                                          domProps: {
                                            value: _vm.fullExpiryDate
                                          },
                                          on: {
                                            input: [
                                              function($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.fullExpiryDate =
                                                  $event.target.value
                                              },
                                              function($event) {
                                                _vm.expiryDateIsValid = null
                                              }
                                            ]
                                          }
                                        }),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "invalid-feedback",
                                            attrs: { id: "expiry-invalid" }
                                          },
                                          [
                                            _vm._v(
                                              " Please enter a valid expiration date. "
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "col-sm-12 col-md-6 col-lg-6"
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "input-group",
                                        attrs: { role: "group", id: "cvv" }
                                      },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.cvv,
                                              expression: "cvv"
                                            }
                                          ],
                                          staticClass: "form-control",
                                          attrs: {
                                            type: "text",
                                            placeholder: "CVV",
                                            maxlength: "4",
                                            "aria-describedby": "cvv-invalid",
                                            state: _vm.cvvIsValid
                                          },
                                          domProps: { value: _vm.cvv },
                                          on: {
                                            keypress: function($event) {
                                              return _vm.cvvValidation(
                                                $event,
                                                _vm.cvv
                                              )
                                            },
                                            input: [
                                              function($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.cvv = $event.target.value
                                              },
                                              function($event) {
                                                _vm.cvvIsValid = null
                                              }
                                            ]
                                          }
                                        }),
                                        _c(
                                          "div",
                                          { staticClass: "input-group-append" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "input-group-text"
                                              },
                                              [
                                                _c("img", {
                                                  attrs: {
                                                    height: 20,
                                                    src: require("@/assets/images/3-digit-code.svg")
                                                  }
                                                })
                                              ]
                                            )
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "invalid-feedback",
                                            attrs: { id: "cvv-invalid" }
                                          },
                                          [
                                            _vm._v(
                                              " Please enter a valid security code. "
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ])
                            ])
                      ])
                    : _c("div", [
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-12" }, [
                            _c("h3", [
                              _c("strong", [
                                _vm._v("Enter Your Credit Card Information")
                              ])
                            ])
                          ])
                        ]),
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-12" }, [
                            _c(
                              "fieldset",
                              {
                                staticClass: "form-group",
                                attrs: { id: "credit-card-number" }
                              },
                              [
                                _c("div", [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.creditCardNumber,
                                        expression: "creditCardNumber"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "text",
                                      placeholder: "Credit Card Number",
                                      "aria-describedby": "card-number-invalid",
                                      state: _vm.creditCardIsValid
                                    },
                                    domProps: { value: _vm.creditCardNumber },
                                    on: {
                                      keypress: function($event) {
                                        return _vm.creditCardValidation(
                                          $event,
                                          _vm.creditCardNumber
                                        )
                                      },
                                      input: [
                                        function($event) {
                                          if ($event.target.composing) return
                                          _vm.creditCardNumber =
                                            $event.target.value
                                        },
                                        function($event) {
                                          _vm.creditCardIsValid = null
                                        }
                                      ]
                                    }
                                  }),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "invalid-feedback",
                                      attrs: { id: "card-number-invalid" }
                                    },
                                    [
                                      _vm._v(
                                        " Please enter a valid credit card number. "
                                      )
                                    ]
                                  )
                                ])
                              ]
                            )
                          ])
                        ]),
                        _c("div", { staticClass: "row" }, [
                          _c(
                            "div",
                            { staticClass: "col-sm-12 col-md-6 col-lg-6" },
                            [
                              _c(
                                "fieldset",
                                {
                                  staticClass: "form-group",
                                  attrs: { id: "expiry-date" }
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.fullExpiryDate,
                                        expression: "fullExpiryDate"
                                      },
                                      {
                                        name: "mask",
                                        rawName: "v-mask",
                                        value: "##/####",
                                        expression: "'##/####'"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "text",
                                      placeholder: "MM/YYYY",
                                      maxlength: "7",
                                      "aria-describedby": "expiry-invalid",
                                      state: _vm.expiryDateIsValid
                                    },
                                    domProps: { value: _vm.fullExpiryDate },
                                    on: {
                                      input: [
                                        function($event) {
                                          if ($event.target.composing) return
                                          _vm.fullExpiryDate =
                                            $event.target.value
                                        },
                                        function($event) {
                                          _vm.expiryDateIsValid = null
                                        }
                                      ]
                                    }
                                  }),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "invalid-feedback",
                                      attrs: { id: "expiry-invalid" }
                                    },
                                    [
                                      _vm._v(
                                        " Please enter a valid expiration date. "
                                      )
                                    ]
                                  )
                                ]
                              )
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "col-sm-12 col-md-6 col-lg-6" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "input-group",
                                  attrs: { role: "group", id: "cvv" }
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.cvv,
                                        expression: "cvv"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "text",
                                      placeholder: "CVV",
                                      maxlength: "4",
                                      "aria-describedby": "cvv-invalid",
                                      state: _vm.cvvIsValid
                                    },
                                    domProps: { value: _vm.cvv },
                                    on: {
                                      keypress: function($event) {
                                        return _vm.cvvValidation(
                                          $event,
                                          _vm.cvv
                                        )
                                      },
                                      input: [
                                        function($event) {
                                          if ($event.target.composing) return
                                          _vm.cvv = $event.target.value
                                        },
                                        function($event) {
                                          _vm.cvvIsValid = null
                                        }
                                      ]
                                    }
                                  }),
                                  _c(
                                    "div",
                                    { staticClass: "input-group-append" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "input-group-text" },
                                        [
                                          _c("img", {
                                            attrs: {
                                              height: 20,
                                              src: require("@/assets/images/3-digit-code.svg")
                                            }
                                          })
                                        ]
                                      )
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "invalid-feedback",
                                      attrs: { id: "cvv-invalid" }
                                    },
                                    [
                                      _vm._v(
                                        " Please enter a valid security code. "
                                      )
                                    ]
                                  )
                                ]
                              )
                            ]
                          )
                        ])
                      ]),
                  _vm.selectedProduct
                    ? _c("div", [
                        _c("div", { staticClass: "row mt-5" }, [
                          _c(
                            "div",
                            {
                              staticClass: "col-12 text-left text-secondary",
                              staticStyle: { "font-size": "1.25rem" }
                            },
                            [_vm._v(" Total Payment: ")]
                          )
                        ]),
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-12 text-left" }, [
                            _c("h3", [
                              _c("strong", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("formatCurrency")(
                                      _vm.selectedProduct.cost.toFixed(2)
                                    )
                                  )
                                )
                              ])
                            ])
                          ])
                        ]),
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col" }, [
                            _c("i", { staticClass: "body-text-color" }, [
                              _vm._v(" Billed "),
                              _vm.selectedProduct.durationInMonths == 12
                                ? _c("span", [_vm._v("Yearly")])
                                : _c("span", [_vm._v("Monthly")])
                            ])
                          ])
                        ])
                      ])
                    : _vm._e(),
                  _c("div", { staticClass: "row text-left asterisk-text" }, [
                    _c("div", { staticClass: "col" }, [
                      _vm._v(
                        " *Updating your credit card information will apply to all active subscriptions. "
                      )
                    ])
                  ]),
                  _c("div", { staticClass: "row text-left asterisk-text" }, [
                    _c("div", { staticClass: "col" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("xThisSubscriptionWillAutoRenew")) +
                          " "
                      )
                    ])
                  ])
                ])
          ]),
          _c("ModalFooter", [
            _c(
              "button",
              {
                staticClass: "btn btn-outline-danger",
                attrs: {
                  type: "button",
                  "data-test-hook":
                    "cancel-button-paymentInformation-device-swap"
                },
                on: {
                  click: function($event) {
                    return _vm.ResetModal()
                  }
                }
              },
              [_vm._v(" CANCEL ")]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                attrs: {
                  type: "button",
                  disabled: !_vm.canPay(),
                  "data-test-hook":
                    "submit-swap-button-paymentInformation-device-swap"
                },
                on: {
                  click: function($event) {
                    return _vm.submitSwap()
                  }
                }
              },
              [
                _vm._v(" " + _vm._s("Submit Swap") + " "),
                _vm.swapping
                  ? _c("div", { staticClass: "spinner-border text-muted ml-2" })
                  : _vm._e()
              ]
            )
          ])
        ],
        1
      ),
      _c(
        "Modal",
        {
          attrs: {
            modalId: "deviceSwapModal-swapSuccess" + _vm.oldDevice.apparatusId
          }
        },
        [
          _c("div", { staticClass: "modal-header" }, [
            _c("div", { staticClass: "row align-items-center" }, [
              _c("div", { staticClass: "col-auto" }, [
                _c("i", {
                  staticClass:
                    "fas fa-chevron-left back-chevron-button hoverPointer",
                  on: {
                    click: function($event) {
                      _vm.closeModal(
                        "deviceSwapModal-swapSuccess" +
                          _vm.oldDevice.apparatusId
                      ),
                        _vm.openModal(
                          "deviceSwapModal-paymentInformation" +
                            _vm.oldDevice.apparatusId
                        )
                    }
                  }
                })
              ]),
              _c("div", { staticClass: "col" }, [
                _c("h2", { staticClass: "modal-title" }, [_vm._v(" Success! ")])
              ])
            ])
          ]),
          _c("ModalBody", [
            _c("div", { staticClass: "col" }, [
              _c("h5", [
                _vm._v(
                  "Please clear this search, and search for the new device:"
                )
              ])
            ]),
            _c("div", { staticClass: "row" }, [
              _vm.validatedDeviceInfo
                ? _c("div", { staticClass: "col" }, [
                    _c("h3", [
                      _vm._v(
                        " " + _vm._s(_vm.validatedDeviceInfo.deviceId) + " "
                      )
                    ])
                  ])
                : _vm._e()
            ])
          ]),
          _c("ModalFooter", [
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                attrs: {
                  type: "button",
                  "data-test-hook": "close-button-swapSuccess-device-swap"
                },
                on: {
                  click: function($event) {
                    return _vm.ResetModal()
                  }
                }
              },
              [_vm._v(" Close ")]
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }