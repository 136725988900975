import Axios from 'axios'
import _ from 'lodash'
import { vueAppApiBaseAddress } from '@/utils/mixins'

var initialState = {}
export const state = _.cloneDeep(initialState)
export const getters = {}
export const mutations = {}

export const actions = {
  async getSubscriptionProductByApparatusId({ commit }, apparatusId) {
    return await Axios.get(vueAppApiBaseAddress() + `v1/Subscription/products/${apparatusId}`, {
      withCredentials: true
    })
  },
  async getSubscriptionProductsByDeviceType({ commit }, deviceType) {
    return await Axios.get(vueAppApiBaseAddress() + `v1/Subscription/products/Generator/${deviceType}`, {
      withCredentials: true
    })
  },
  async getActivePaymentMethod({ commit }, organizationId) {
    return await Axios.get(vueAppApiBaseAddress() + `v1/Subscription/payment/${organizationId}`, {
      withCredentials: true
    })
  },
  async changeBillingAccountProduct({ commit }, request) {
    return await Axios.put(vueAppApiBaseAddress() + `v1/Subscription/products/change`, request, {
      withCredentials: true
    })
  },
  async removeBillingAccountPaymentMethod({ commit }, request) {
    return await Axios.put(
      vueAppApiBaseAddress() + `v1/Subscription/payment/${parseInt(request.organizationId)}/${request.paymentId}`,
      {},
      {
        withCredentials: true
      }
    )
  },
  async addBillingAccountPaymentMethod({ commit }, request) {
    return await Axios.post(
      vueAppApiBaseAddress() + `v1/Subscription/payment/${parseInt(request.organizationId)}`,
      {
        cardNumber: request.cardNumber,
        cvv: request.cvv,
        expirationMonth: request.expirationMonth,
        expirationYear: request.expirationYear
      },
      {
        withCredentials: true
      }
    )
  },
  async getApparatusSubscription({ commit }, request) {
    return await Axios.get(
      vueAppApiBaseAddress() + `v1/Subscription/account/${request.organizationId}/${request.apparatusId}`,
      {
        withCredentials: true
      }
    )
  },
  async getAllApparatusSubscriptions({ commit }, request) {
    return await Axios.get(vueAppApiBaseAddress() + `v1/Subscription/account/active/${request.organizationId}`, {
      withCredentials: true
    }
    )
  },
  async cancelApparatusSubscription({ commit }, request) {
    return await Axios.delete(
      vueAppApiBaseAddress() + `v1/Subscription/products/${request.organizationId}/${request.apparatusId}`,
      {
        withCredentials: true
      }
    )
  },
  async findAccountInvoices({ commit }, request) {
    return await Axios.get(vueAppApiBaseAddress() + `v1/Subscription/find/invoices/${request.organizationId}`, {
      withCredentials: true
    })
  },
  async downloadInvoice({ commit }, request) {
    return await Axios.get(
      vueAppApiBaseAddress() + `v1/Subscription/download/invoice/${request.organizationId}/${request.invoiceId}`,
      {
        withCredentials: true,
        responseType: 'blob'
      }
    )
  }
}
export default {
  state,
  getters,
  actions,
  mutations
}
