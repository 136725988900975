import { deepTrim } from '@/utils/mixins'
import { getHeaders } from '@/utils/headers'

export default class MobileLinkMiddleWare {
  constructor(store) {
    this.store = store
  }

  onRequest(config) {
    if (
      config.data != undefined &&
      (config.method == 'post' || config.method == 'put') &&
      config.headers['Content-Type'] != 'multipart/form-data'
    ) {
      var obj = JSON.parse(config.data)
      deepTrim(obj)
      config.data = JSON.stringify(obj)
    }
    // var header = getHeaders()
    // config.headers = header
    return config
  }
  onSync(promise) {
    return promise
  }
  onResponse(response) {
    return response
  }
}
