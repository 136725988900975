var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Modal",
    {
      attrs: {
        modalId: "ConfirmDeleteModal-" + _vm.confirmDeleteModalInfo.apparatusId
      }
    },
    [
      _c(
        "ModalHeader",
        { attrs: { title: _vm.$t("xDeleteEntry"), icon: "fas fa-trash-can" } },
        [
          _c(
            "button",
            {
              staticClass: "btn btn-icon-button btn-icon-button--gray",
              attrs: {
                disabled: _vm.deleting,
                "data-test-hook": "close-btn-confirm-delete-modal"
              },
              on: {
                click: function($event) {
                  return _vm.closeModal(
                    "ConfirmDeleteModal-" +
                      _vm.confirmDeleteModalInfo.apparatusId
                  )
                }
              }
            },
            [_c("i", { staticClass: "fas fa-times" })]
          )
        ]
      ),
      _c("ModalBody", [
        _vm.confirmDeleteModalInfo
          ? _c(
              "div",
              { staticClass: "mt-2 row", attrs: { "align-h": "around" } },
              [
                _c(
                  "div",
                  {
                    staticClass: "col",
                    attrs: {
                      "data-test-hook": "body-content-confirm-delete-modal"
                    }
                  },
                  [
                    _vm._v(
                      " " + _vm._s(_vm.$t("xAreYouSureYouWantToDelete")) + " "
                    ),
                    _vm.confirmDeleteModalInfo.infoType == "phone"
                      ? _c(
                          "span",
                          {
                            attrs: {
                              "data-test-hook":
                                "delete-value-confirm-delete-modal"
                            }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("phone")(
                                    _vm.confirmDeleteModalInfo.value
                                  )
                                ) +
                                " "
                            )
                          ]
                        )
                      : _c(
                          "span",
                          {
                            attrs: {
                              "data-test-hook":
                                "delete-value-confirm-delete-modal"
                            }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.confirmDeleteModalInfo.value + "?") +
                                " "
                            )
                          ]
                        )
                  ]
                )
              ]
            )
          : _vm._e()
      ]),
      _c("ModalFooter", [
        _c(
          "button",
          {
            staticClass: "btn btn-outline-warning",
            attrs: {
              type: "button",
              "data-test-hook": "cancel-btn-confirm-delete-modal"
            },
            on: {
              click: function($event) {
                return _vm.closeModal(
                  "ConfirmDeleteModal-" + _vm.confirmDeleteModalInfo.apparatusId
                )
              }
            }
          },
          [_vm._v(" " + _vm._s(_vm.$t("xCancel")) + " ")]
        ),
        _c(
          "button",
          {
            staticClass: "btn btn-primary",
            attrs: {
              type: "button",
              disabled: _vm.deleting,
              "data-test-hook": "yes-btn-confirm-delete-modal"
            },
            on: { click: _vm.deleteInfo }
          },
          [
            _vm._v(" " + _vm._s(_vm.$t("xYesImSure")) + " "),
            _vm.deleting
              ? _c("div", {
                  staticClass: "ml-2 spinner-border text-muted",
                  attrs: {
                    "data-test-hook": "yes-btn-spinner-confirm-delete-modal"
                  }
                })
              : _vm._e()
          ]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }