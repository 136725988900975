<template>
  <div>
    <div class="main-card mt-2">
      <div class="card-header">
        <ul class="nav nav-tabs card-header-tabs nav-fill">
          <li class="nav-item hoverPointer" @click="tabIndex = 0">
            <a class="nav-link" :class="isActive(0)">General Information</a>
          </li>
          <li v-if="hasDevice(value) && !currentStatus" class="nav-item hoverPointer" @click="tabIndex = 1">
            <a class="nav-link" :class="isActive(1)"
              ><span class="spinner-border spinner-border-sm mr-1"></span>Status and Maintenance</a
            >
          </li>
          <li
            v-else-if="hasDevice(value) && currentStatus && currentStatus.apparatusStatus"
            class="nav-item hoverPointer"
          >
            <a class="nav-link" :class="isActive(1)" @click="tabIndex = 1">
              <div class="row">
                <div class="col-auto">
                  <StatusIcon
                    :apparatusStatus="currentStatus.apparatusStatus"
                    :isConnected="currentStatus.isConnected"
                    :showWarning="currentStatus.showWarning"
                    :small="true"
                    :deviceType="
                      value.deviceInfo.deviceType == null
                        ? hsbLteDeviceType
                        : value.deviceInfo.deviceType == hsbG3DeviceType
                        ? value.networkType
                        : value.deviceInfo.deviceType
                    "
                  />
                </div>
                <div class="col">
                  Status and Maintenance
                </div>
              </div></a
            >
          </li>
          <li class="nav-item hoverPointer" v-if="hasDevice(value)" @click="tabIndex = 2">
            <a class="nav-link" :class="isActive(2)"
              ><span class="spinner-border spinner-border-sm" v-if="!currentStatus"></span>Connections and
              Subscription</a
            >
          </li>
          <li class="nav-item hoverPointer" v-if="hasDevice(value)" @click="tabIndex = 3">
            <a class="nav-link" :class="isActive(3)">Commands</a>
          </li>
          <li class="nav-item hoverPointer" @click="tabIndex = 4">
            <a class="nav-link" :class="isActive(4)"
              ><span class="spinner-border spinner-border-sm" v-if="gettingNotifications"></span>Notifications</a
            >
          </li>
        </ul>
      </div>
      <div class="card-body">
        <div deck v-if="tabIndex == 0">
          <GeneralInfoTab :value="value" />
        </div>
        <span
          role="status"
          v-if="tabIndex == 1 && !currentStatus"
          class="text-center float-center spinner-border text-primary"
          style="width: 10rem; height: 10rem"
          label="Spinning"
        ></span>
        <div deck v-else-if="tabIndex == 1 && currentStatus">
          <CurrentStatusTab
            :currentStatus="currentStatus"
            :deviceType="value.deviceInfo.deviceType"
            :networkType="value.networkType"
          />
        </div>
        <span
          role="status"
          v-if="tabIndex == 2 && !currentStatus"
          class="text-center float-center spinner-border text-primary"
          style="width: 10rem; height: 10rem"
          label="Spinning"
        ></span>
        <div v-else-if="tabIndex == 2 && currentStatus">
          <ConnectionAndSubscriptionTab
            :value="value"
            :currentStatus="currentStatus"
            :currentSubscription="currentSubscription ? currentSubscription.activeProduct : null"
            :billingAccountId="currentSubscription ? currentSubscription.billingAccountId : null"
          />
        </div>
        <div v-if="tabIndex == 3">
          <CommandsTab :value="value" />
        </div>
        <div v-if="tabIndex == 4">
          <span
            role="status"
            v-if="gettingNotifications"
            class="text-center float-center spinner-border text-primary"
            style="width: 10rem; height: 10rem"
            label="Spinning"
          />
          <NotificationsTab v-else :value="value" :notifications="notifications" />
        </div>
      </div>

      <div class="card-footer">
        <span :id="'impersonate-gen' + value.apparatusId">
          <button
            type="button"
            class="btn btn-primary mt-2"
            @click="ImpersonateEmail()"
            :disabled="!hasAssociatedAccount(value)"
            data-toggle="tooltip"
            :title="hasAssociatedAccount(value) ? '' : 'No Owner Account'"
            data-test-hook="go-to-dashboard-button-search-result"
          >
            Go To Dashboard
          </button>
        </span>
        <span id="swapDevice">
          <button
            type="button"
            class="btn btn-secondary ml-2 mt-2"
            @click="openModal('deviceSwapModal-newDeviceInfo' + value.apparatusId)"
            :disabled="
              !hasAssociatedAccount(value) ||
                value.apparatusClassification == fuelTankApparatus ||
                !hasDevice(value) ||
                DealerManagedOrBillingMaintenance ||
                hasRestrictedPanels(value)
            "
            data-toggle="tooltip"
            :title="hasRestrictedPanels(value) ? 'No devices are supported for this panel ID' : ''"
          >
            Swap Device
          </button>
        </span>
        <span id="cancelSubButton">
          <button
            type="button"
            class="btn btn-danger ml-2 mt-2"
            @click="openModal('cancelSubscription')"
            :disabled="
              !hasAssociatedAccount(value) ||
                value.apparatusClassification == fuelTankApparatus ||
                !hasSubscription ||
                DealerManagedOrBillingMaintenance ||
                value.enrolledInVpp
            "
            data-toggle="tooltip"
            :title="tooltipForHomeownerChangeButton(value)"
            data-test-hook="cancel-sub-button-search-result"
          >
            Cancel Subscription
          </button>
        </span>
        <button
          type="button"
          class="btn btn-outline-primary ml-2 mt-2"
          @click="openModal('statusHistory' + value.apparatusId)"
          data-test-hook="status-history-button-search-result"
          :disabled="value.apparatusClassification == fuelTankApparatus"
        >
          Status History
        </button>
        <span id="transferSubButton">
          <button
            type="button"
            class="btn btn-secondary ml-2 mt-2"
            :disabled="
              !hasAssociatedAccount(value) ||
                value.apparatusClassification == fuelTankApparatus ||
                cannotTransferGen() ||
                DealerManagedOrBillingMaintenance
            "
            @click="openModal('destination-org-search' + value.apparatusId)"
            data-toggle="tooltip"
            :title="
              value.apparatusClassification != fuelTankApparatus && (isDealerManagedDevice() || cannotTransferGen())
                ? 'Search by owner to use Transfer Account option'
                : ''
            "
            data-test-hook="transfer-gen-button-search-result"
          >
            Transfer Generator
          </button>
        </span>
        <span id="homeownerChangeButton">
          <button
            type="button"
            class="btn btn-danger ml-2 mt-2"
            :disabled="
              value.apparatusClassification == fuelTankApparatus ||
                DealerManagedOrBillingMaintenance ||
                value.enrolledInVpp
            "
            @click="
              openModal('homeowner-change-step-1' + value.apparatusId),
                trackAppInsightsEvent('open-removal-flow', {
                  page: 'admin',
                  organizationId: value.organizationId,
                  apparatusId: value.apparatusId
                })
            "
            data-toggle="tooltip"
            :title="tooltipForHomeownerChangeButton(value)"
          >
            {{ $t('xHomeownerChange') }}
          </button>
        </span>
      </div>
    </div>
    <div id="cancelSubscription" role="dialog" class="modal fade show" style="padding-right: 11px">
      <div class="modal-dialog modal-md">
        <span></span>
        <div class="modal-content">
          <div class="modal-body">
            Are you sure you want to cancel this device's subscription? Click "CONTINUE" to cancel this device's
            subscription, click "CLOSE" to exit
          </div>
          <footer class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="closeModal('cancelSubscription')">
              CLOSE
            </button>
            <button type="button" class="btn btn-primary" @click="cancelDeviceSubscription()">
              CONTINUE
            </button>
          </footer>
        </div>
      </div>
    </div>
    <DeviceSwap v-if="hasDevice(value)" :oldDevice="value" />
    <div :id="'statusHistory' + value.apparatusId" role="dialog" class="modal fade show" style="padding-right: 11px">
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <header class="modal-header">
            <h5 class="modal-title">Status History: {{ value.apparatusId }}</h5>
            <button type="button" class="close" @click="closeModal('statusHistory' + value.apparatusId)">
              x
            </button>
          </header>
          <div class="modal-body">
            <StatusHistory :apparatusId="value.apparatusId" />
          </div>
          <footer class="modal-footer">
            <button type="button" class="btn btn-primary" @click="closeModal('statusHistory' + value.apparatusId)">
              Ok
            </button>
          </footer>
        </div>
      </div>
    </div>
    <TransferGeneratorModal
      v-if="value"
      :currentSubscription="currentSubscription"
      :source="value"
      :transferType="transferType"
      @transferEmission="TransferCompleteEmission"
    />
    <HomeownerChangeModals
      v-if="value"
      :value="value"
      :currentSubscription="currentSubscription"
      @successfulRemoval="successfulRemoval"
    />
  </div>
</template>

<script>
import * as Constants from '@/constants'
import DeviceSwap from '@/components/Admin/DeviceSwap'
import StatusIcon from '@/components/StatusIcon'
import StatusHistory from '@/components/Admin/StatusHistory'
import TransferGeneratorModal from '@/components/Admin/TransferGeneratorModal'
import GeneralInfoTab from '@/components/Admin/GeneralInfoTab'
import CurrentStatusTab from '@/components/Admin/CurrentStatusTab'
import ConnectionAndSubscriptionTab from '@/components/Admin/ConnectionAndSubscriptionTab'
import CommandsTab from '@/components/Admin/CommandsTab'
import NotificationsTab from '@/components/Admin/NotificationsTab'
import HomeownerChangeModals from '@/components/Admin/HomeownerChangeModals'
import { mapState } from 'vuex'

export default {
  components: {
    DeviceSwap,
    StatusIcon,
    StatusHistory,
    TransferGeneratorModal,
    GeneralInfoTab,
    CurrentStatusTab,
    ConnectionAndSubscriptionTab,
    CommandsTab,
    NotificationsTab,
    HomeownerChangeModals
  },
  props: ['value'],
  data() {
    return {
      tabIndex: 0,
      currentStatus: null,
      currentSubscription: null,
      hasSubscription: false,
      cancelling: false,
      removing: false,
      subscriptionCallReturned: false,
      notifications: null,
      gettingNotifications: false,
      transferType: null,
      preferredDealer: null
    }
  },
  computed: {
    ...mapState({
      currentAppConfig: state => state.application.currentAppConfig,
      user: state => state.account.user
    }),
    DealerManagedOrBillingMaintenance() {
      if (this.isDealerManagedDevice() || this.BillingMaintenanceEnabled()) return true
      return false
    },
    hsbGeneratorApparatus() {
      return Constants.HsbApparatusType
    },
    fuelTankApparatus() {
      return Constants.FuelTankApparatusType
    },
    hsbG3DeviceType() {
      return Constants.HsbG3DeviceType
    }
  },
  methods: {
    isActive(index) {
      if (index === this.tabIndex) {
        return 'active'
      }
      return ''
    },
    TransferCompleteEmission(transferEmission) {
      this.$emit('transferComplete', transferEmission)
    },
    successfulRemoval() {
      this.$emit('successfulRemoval')
    },
    cannotTransferGen() {
      if (!this.subscriptionCallReturned) {
        return true
      }
      //no subs can transfer
      if (!this.hasSubscription) {
        this.transferType = Constants.TransferTypeGenerator
        return false
      } else if (
        this.currentSubscription &&
        this.currentSubscription.activeProduct &&
        this.currentSubscription.activeProduct[0] &&
        this.currentSubscription.activeProduct[0].productId == Constants.FreeWifiEthProductId
      ) {
        this.transferType = Constants.TransferTypeGenerator
        return false
      } else {
        return true
      }
    },
    isDealerManagedDevice() {
      return this.preferredDealer?.isDealerManaged
    },
    getSubscriptionClass() {
      if (this.currentSubscription && this.currentSubscription.activeProduct) {
        if (this.currentSubscription.activeProduct[0].status == 'Active') {
          return 'active-subscription'
        } else return 'inactive-subscription'
      }
    },
    cancelDeviceSubscription() {
      this.cancelling = true
      this.$store
        .dispatch('cancelApparatusSubscription', {
          organizationId: this.value.organizationId,
          apparatusId: this.value.apparatusId
        })
        .then(response => {
          this.cancelling = false
          this.$bvModal.hide('cancelSubscription')
          this.$store.dispatch('displayToast', {
            title: 'Success',
            message: this.$t('xSuccessfullyCancelledSubscription'),
            variant: 'success'
          })
          let event = this.createEventLogObjectFromAdmin(
            this.value,
            this.currentSubscription,
            this.user,
            Constants.CancelEventType,
            null
          )
          this.$store.dispatch('logEvent', event)
        })
        .catch(error => {
          this.cancelling = false

          if (error.response.data.status == 401) {
            this.$store.dispatch('displayToast', {
              title: this.$t('xError'),
              message: this.$t('xYourSessionHasExpiredPleaseReLogin'),
              variant: 'danger'
            })
            this.forceSignOut()
          } else {
            this.$store.dispatch('displayToast', {
              title: this.$t('xError'),
              message: 'Failed to cancel device subscription, it was either just cancelled, or cannot be found',
              variant: 'danger'
            })
          }
        })
    },
    getDeviceSubscription() {
      this.$store
        .dispatch('getApparatusSubscription', {
          organizationId: this.value.organizationId,
          apparatusId: this.value.apparatusId
        })
        .then(response => {
          this.subscriptionCallReturned = true
          this.currentSubscription = response.data
          if (
            this.currentSubscription &&
            this.currentSubscription.activeProduct[0] &&
            (this.currentSubscription.activeProduct[0].status == Constants.ActiveStatus ||
              this.currentSubscription.activeProduct[0].status == Constants.PendingCancellationStatus ||
              this.currentSubscription.activeProduct[0].status == Constants.PendingTermination ||
              this.currentSubscription.activeProduct[0].status == Constants.PendingSuspensionStatus)
          ) {
            this.hasSubscription = true
          } else this.hasSubscription = false
        })
        .catch(error => {
          this.subscriptionCallReturned = true
          this.hasSubscription = false
          if (error.response.data.status == 401) {
            this.$store.dispatch('displayToast', {
              title: this.$t('xError'),
              message: this.$t('xYourSessionHasExpiredPleaseReLogin'),
              variant: 'danger'
            })
            this.forceSignOut()
          }
          if (error.response.data.status == 503) {
            this.$store.dispatch('displayToast', {
              title: this.$t('xError'),
              message: this.$t('xBillingMaintenanceMessage'),
              variant: 'danger'
            })
          }
        })
    },
    getNotifications() {
      if (this.value.apparatusId) {
        this.gettingNotifications = true
        this.$store
          .dispatch('getNotificationAudit', {
            apparatusId: this.value.apparatusId
          })
          .then(response => {
            this.notifications = response.data
          })
          .catch(error => {
            if (error.response.data.status == 401) {
              this.$store.dispatch('displayToast', {
                title: this.$t('xError'),
                message: this.$t('xYourSessionHasExpiredPleaseReLogin'),
                variant: 'danger'
              })
              this.forceSignOut()
            }
          })
          .finally(() => {
            this.gettingNotifications = false
          })
      }
    },
    DisplayStatus() {
      if (!this.currentStatus.isConnected) {
        return require('@/assets/images/DisconnectedStatus.svg')
      }
      if (this.currentStatus.severityRanking == Constants.SeverityRanking.Ready) {
        return require('@/assets/images/ReadyStatus.svg')
      } else if (
        this.currentStatus.severityRanking == Constants.SeverityRanking.RunningOther ||
        this.currentStatus.severityRanking == Constants.SeverityRanking.RunningUtilityLoss
      ) {
        return require('@/assets/images/RunningStatus.svg')
      } else if (this.currentStatus.severityRanking == Constants.SeverityRanking.RunningExercise) {
        return require('@/assets/images/ExercisingStatus.svg')
      } else if (this.currentStatus.severityRanking == Constants.SeverityRanking.Warning) {
        return require('@/assets/images/WarningStatus.svg')
      } else if (
        this.currentStatus.severityRanking == Constants.SeverityRanking.Fault ||
        this.currentStatus.severityRanking == Constants.SeverityRanking.FaultOff
      ) {
        return require('@/assets/images/FaultStatus.svg')
      } else return require('@/assets/images/UnknownStatus.svg')
    },
    ImpersonateEmail() {
      // this.$store.dispatch('apparatusReset')
      this.$store.dispatch('clearNotificationApparatuses')
      this.$store.dispatch('setEmail', this.value.ownerInformation.emails[0])
    },
    BillingMaintenanceEnabled() {
      return (
        this.currentAppConfig &&
        this.currentAppConfig.featureFlags &&
        this.currentAppConfig.featureFlags[Constants.BillingMigrationFeatureFlag]
      )
    },
    hasRestrictedPanels(val) {
      let panelId = val.panelId
      if (panelId == 18 || panelId == 19 || panelId == 20) {
        return true
      }
      return false
    },
    tooltipForHomeownerChangeButton(value) {
      if (this.isDealerManagedDevice()) return 'Dealer Managed subscriptions must be managed via FLEET'
      if (value.enrolledInVpp) return 'Cannot Cancel Subscription'
      return ''
    }
  },
  watch: {
    value: function(newVal, oldVal) {
      if (newVal && this.hasDevice(newVal)) {
        this.$store
          .dispatch('getSearchResultStatus', {
            apparatusId: newVal.apparatusId,
            deviceType: newVal.deviceInfo.deviceType,
            deviceId: newVal.deviceInfo.deviceId
          })
          .then(response => {
            this.currentStatus = response.data
          })
          .catch(error => {
            this.currentStatus.severityRanking = Constants.SeverityRanking.Unknown
            this.currentStatus.isConnected = false
            if (error.response.data.status == 401) {
              this.$store.dispatch('displayToast', {
                title: this.$t('xError'),
                message: this.$t('xYourSessionHasExpiredPleaseReLogin'),
                variant: 'danger'
              })
              this.forceSignOut()
            }
          })
        this.getDeviceSubscription()
        this.getNotifications()
      }
    }
  },
  async mounted() {
    if (this.value && this.hasDevice(this.value)) {
      await this.$store
        .dispatch('getPreferredDealer', this.value.apparatusId)
        .then(response => {
          if (response.status == 204) this.preferredDealer = null
          else {
            this.preferredDealer = response.data
          }
        })
      this.$store
        .dispatch('getSearchResultStatus', {
          apparatusId: this.value.apparatusId,
          deviceType: this.value.deviceInfo.deviceType,
          deviceId: this.value.deviceInfo.deviceId
        })
        .then(response => {
          this.currentStatus = response.data
        })
        .catch(error => {
          if (error.response.data.status == 401) {
            this.$store.dispatch('displayToast', {
              title: this.$t('xError'),
              message: this.$t('xYourSessionHasExpiredPleaseReLogin'),
              variant: 'danger'
            })
            this.forceSignOut()
          } else {
            this.currentStatus.severityRanking = Constants.SeverityRanking.Unknown
            this.currentStatus.isConnected = false
          }
        })
      this.getDeviceSubscription()
      this.getNotifications()
      if (!this.currentAppConfig) this.$store.dispatch('getApplicationConfiguration', 'Any')
    }
  }
}
</script>

<style scoped>
.btn {
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 400;
}
.card-title {
  font-size: 1.5rem;
  text-transform: uppercase;
  line-height: 1.25em;
  font-weight: 400;
}
.main-card {
  box-shadow: 0px -3px 20px rgba(0, 0, 0, 0.2);
  border-radius: 0.25rem;
  border: none;
}
.card-body {
  background-color: #ffffff;
}
.btn-danger.disabled,
.btn-danger:disabled {
  opacity: 0.65 !important;
}
</style>
