import Axios from 'axios'
import router from '@/router'
import * as Constants from '@/constants'
import { vueAppApiBaseAddress } from '@/utils/mixins'

export const state = {
  featureAnnouncement: null,
  freeFeatures: null,
  paidFeatures: null,
  countryStates: null,
  currentAppConfig: null,
  bannerMessage: null
}

export const getters = {}

export const mutations = {
  setFeatureAnnouncement(state, announcement) {
    state.featureAnnouncement = announcement
  },
  setFreeFeatures(state, value) {
    state.freeFeatures = value
  },
  setPaidFeatures(state, value) {
    state.paidFeatures = value
  },
  setCountryStates(state, value) {
    state.countryStates = value
  },
  setAppConfiguration(state, value) {
    state.currentAppConfig = value
  },
  setBannerMessage(state, value) {
    state.bannerMessage = JSON.parse(value)
  }
}

export const actions = {
  async getNewAnnouncements({ getters, commit, dispatch }) {
    return await Axios.get(vueAppApiBaseAddress() + `v2/Application/Web/announcement/new`, {
      withCredentials: true,
      headers: {
        'Cache-Control': 'public, max-age=900, immutable;'
      }
    })
      .then(response => {
        commit('setFeatureAnnouncement', response.data)
      })
      .catch(error => {})
  },
  async getFreeFeatures({ getters, commit, dispatch }) {
    return await Axios.get(vueAppApiBaseAddress() + `v2/Application/Web/subscription/features/Free`, {
      withCredentials: true,
      headers: {
        'Cache-Control': 'public, max-age=3600, immutable;'
      }
    })
      .then(response => {
        commit('setFreeFeatures', response.data)
      })
      .catch(error => {})
  },
  async getPaidFeatures({ getters, commit, dispatch }) {
    return await Axios.get(vueAppApiBaseAddress() + `v2/Application/Web/subscription/features/Paid`, {
      withCredentials: true,
      headers: {
        'Cache-Control': 'public, max-age=3600, immutable;'
      }
    })
      .then(response => {
        commit('setPaidFeatures', response.data)
      })
      .catch(error => {})
  },
  async getCountrySubdivisions({ getters, commit, dispatch }) {
    await Axios.get(vueAppApiBaseAddress() + `v2/Application/country/subdivisions`, {
      withCredentials: true,
      headers: {
        'Cache-Control': 'public, max-age=3600, immutable;'
      }
    })
      .then(response => {
        commit('setCountryStates', response.data)
      })
      .catch(error => {})
  },
  async getApplicationConfiguration({ getters, commit, dispatch }, scope) {
    return await Axios.get(vueAppApiBaseAddress() + `v1/Application/${scope}/configuration`, {
      withCredentials: true,
      headers: {
        'Cache-Control': 'public, max-age=3600, immutable;'
      }
    })
      .then(response => {
        commit('setAppConfiguration', response.data)
        if (response.data.configurations && response.data.configurations[Constants.WebBannerMessageConfig]) {
          commit('setBannerMessage', response.data.configurations[Constants.WebBannerMessageConfig])
        }
      })
      .catch(error => {})
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
