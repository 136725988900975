<template>
  <div>
    <Modal modalId="upgrade-free-sub-step-1" size="modal-lg">
      <ModalHeader :title="$t('xManageSubscription')" icon="far fa-badge-dollar colorOrange mr-1">
        <!-- <div class="d-flex justify-content-between align-items-center w-100">
          <h2 class="modal-title">
            <i class="far fa-badge-dollar colorOrange mr-1" />
            {{ $t('xManageSubscription') }}
          </h2> -->
        <button
          type="button"
          class="btn btn-icon-button btn-icon-button--gray"
          @click="closeModals()"
          data-test-hook="x-button-step1-upgrade-free-sub"
        >
          <i class="fas fa-times" />
        </button>
        <!-- </div> -->
      </ModalHeader>
      <ModalBody>
        <div v-if="currentSubscription[0].productId == dealerManaged">
          <div class="row">
            <div class="col">
              {{ $t('xYourDealerHasAManagedSubscription') }}
            </div>
          </div>
        </div>
        <div v-else-if="availableSubscriptionProducts && !gettingSubscriptions">
          <div class="row">
            <div
              class="col-12 col-md-6 mb-3"
              v-for="(product, index) in availableSubscriptionProducts"
              :key="index"
              :data-test-hook="'set-selected-product-clickable-card-step1-upgrade-free-sub' + index"
            >
              <div v-if="product.durationInMonths > 0" class="available-subscription">
                <div class="d-flex flex-column align-items-center">
                  <div v-if="product.durationInMonths == 12">
                    <h3 class="mb-0 save-banner">{{ $t('xBestValueSave') }} {{ getSubscriptionSavings(product) }}%</h3>
                  </div>
                  <div v-else class="banner-spacer"></div>
                  <h1 class="mb-0">{{ product.name }}</h1>
                  <h2>{{ getProductCost(product) }}</h2>
                  <button
                    type="button"
                    @click="setSelectedProduct(product)"
                    class="btn btn-primary"
                    :data-test-hook="'select-button-step1-upgrade-free-sub' + index"
                  >
                    {{ $t('xSelect') }}
                    <span class="spinner-borderer ml-2" v-if="paying && product.cost == 0"></span>
                  </button>
                </div>
                <div v-if="paidFeatures">
                  <div class="row mb-2 mt-3">
                    <div class="col">
                      <p>
                        <strong>{{ $t('xIncludedFeatures') }}</strong>
                      </p>
                      <p>{{ paidFeatures.subscriptionDescription }}</p>
                    </div>
                  </div>
                  <div class="row mb-2" v-for="(feature, index) in paidFeatures.features" :key="index">
                    <div class="col">
                      <p>{{ feature }}</p>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <span class="spinner-border text-warning" style="width: 5rem; height: 5rem;" />
                </div>
              </div>
              <div v-else class="available-subscription">
                <div v-if="freeFeatures">
                  <div class="row mb-2 mt-3">
                    <div class="col">
                      <p>
                        <strong>{{ $t('xIncludedFeatures') }}</strong>
                      </p>
                      <p>{{ freeFeatures.subscriptionDescription }}</p>
                    </div>
                  </div>
                  <div class="row mb-2" v-for="(feature, index) in freeFeatures.features" :key="index">
                    <div class="col">
                      <p>{{ feature }}</p>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <span class="spinner-border text-warning" style="width: 5rem; height: 5rem;" />
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-auto">
              <a
                href="#"
                target="_self"
                v-if="!currentApparatusDetails.enrolledInVpp"
                data-test-hook="cancel-remote-monitoring-link"
                @click="emitCancelFlow()"
                >{{ $t('xCancelRemoteMonitoring') }} &#187;</a
              >
              <p v-else class="asterisk-text">{{ $t('xVppCannotCancelMessage') }}</p>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col">
              <p class="asterisk-text">*{{ $t('xPushNotificationsAreAvailableWithInstallation') }}</p>
              <p class="asterisk-text">**{{ $t('xFuellevelIsAvailableOnCompatibleDieselGenerators') }}</p>
            </div>
          </div>
        </div>
        <div v-else-if="!availableSubscriptionProducts && gettingSubscriptions">
          <div class="row">
            <div class="col">
              {{ $t('xCouldNotFindAnyAvailableSubscriptionsForYourGenerator') }}
            </div>
          </div>
        </div>
        <div v-else class="pt-4">
          <span class="spinner-border text-warning" style="width: 5rem; height: 5rem;" label="Spinning"></span>
        </div>
      </ModalBody>
    </Modal>

    <Modal modalId="upgrade-free-sub-step-2" size="modal-lg">
      <ModalHeader :title="$t('xManageSubscription')" icon="far fa-badge-dollar colorOrange mr-1">
        <button
          type="button"
          class="btn btn-icon-button btn-icon-button--gray"
          @click="closeModals()"
          data-test-hook="x-button-step1-upgrade-free-sub"
        >
          <i class="fas fa-times" />
        </button>
      </ModalHeader>
      <ModalBody>
        <div v-if="showValidationBanner" role="alert" class="alert alert-danger">
          <p>{{ $t('xExperiencedTheseErrors') }}</p>
          <ul v-for="(message, index) in errorMessages" :key="index">
            <li :id="index">
              {{ message }}
            </li>
          </ul>
        </div>
        <div v-if="paymentInfo">
          <div class="row" v-if="useExistingPaymentInfo">
            <div class="col">
              <h3>
                <strong>{{ $t('xCurrentCreditCardInformationOnFile') }}</strong
                ><span class="body-text-color">*</span>
              </h3>
            </div>
          </div>
          <div class="row" v-else>
            <div class="col">
              <h3>
                <strong>{{ $t('xUpdateCreditCardInformation') }}</strong
                ><span class="body-text-color">*</span>
              </h3>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col">
              <div class="font-weight-bold custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="useExistingPaymentInfo"
                  v-model="useExistingPaymentInfo"
                />
                <label for="useExistingPaymentInfo" class="custom-control-label">{{
                  $t('xUseCurrentCreditCardInformation')
                }}</label>
              </div>
            </div>
          </div>
          <div v-if="useExistingPaymentInfo">
            <div class="row">
              <div class="col-12">
                <p>
                  <strong>
                    <i class="fal fa-credit-card" />
                    {{ paymentInfo.cardType }} -
                    {{ paymentInfo.cardSuffix }}
                  </strong>
                </p>
                <p>{{ $t('xExpires') }} {{ paymentInfo.expirationMonth + '/' + paymentInfo.expirationYear }}</p>
              </div>
            </div>
          </div>
          <div v-else>
            <div class="row">
              <div class="col-12">
                <fieldset class="form-group" id="credit-card-number">
                  <div>
                    <input
                      class="form-control"
                      v-model="$v.creditCardNumber.$model"
                      type="text"
                      maxlength="16"
                      v-mask="'################'"
                      :placeholder="$t('xCreditCardNumber')"
                      :class="status($v.creditCardNumber)"
                      aria-describedby="card-number-invalid"
                    />
                    <div class="invalid-feedback" id="card-number-invalid">
                      {{ $t('xCCFieldValidation') }}
                    </div>
                  </div>
                </fieldset>
              </div>
            </div>
            <div class="row">
              <div class="col col-lg-6 col-md-t6 col-sm-12">
                <fieldset class="form-group" id="expiry-date-month">
                  <div>
                    <input
                      class="form-control"
                      v-model="$v.expirationMonth.$model"
                      type="text"
                      maxlength="2"
                      v-mask="'##'"
                      :placeholder="$t('xMm')"
                      :class="status($v.expirationMonth)"
                      aria-describedby="expiry-month-invalid"
                    />
                    <div class="invalid-feedback" id="expiry-month-invalid">
                      {{ $t('xExpirationMonthValidation') }}
                    </div>
                  </div>
                </fieldset>
              </div>
              <div class="col col-lg-6 col-md-6 col-sm-12">
                <fieldset class="form-group" id="expiry-date-year">
                  <div>
                    <input
                      class="form-control"
                      v-model="$v.expirationYear.$model"
                      type="text"
                      maxlength="2"
                      v-mask="'##'"
                      :placeholder="$t('xYy')"
                      :class="status($v.expirationYear)"
                      aria-describedby="expiry-year-invalid"
                    />
                    <div class="invalid-feedback" id="expiry-year-invalid">
                      {{ $t('xExpirationYearValidation') }}
                    </div>
                  </div>
                </fieldset>
              </div>
              <div class="col-12">
                <div class="input-group" id="cvv">
                  <input
                    class="form-control"
                    v-model="$v.cvv.$model"
                    type="text"
                    v-mask="'####'"
                    maxlength="4"
                    :placeholder="$t('xCvv')"
                    :class="status($v.cvv)"
                    aria-describedby="cvv-invalid"
                    data-test-hook="CcCvv"
                  />
                  <div class="input-group-append">
                    <div class="input-group-text">
                      <img :height="20" :src="require('@/assets/images/3-digit-code.svg')" />
                    </div>
                  </div>
                  <div class="invalid-feedback" id="cvv-invalid">
                    {{ $t('xCVVValidation') }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="row">
            <div class="col-12">
              <h3>
                <strong>{{ $t('xEnterYourCreditCardInformation') }}</strong>
              </h3>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="form-group" id="credit-card-number">
                <div>
                  <input
                    class="form-control"
                    v-model="$v.creditCardNumber.$model"
                    type="text"
                    maxlength="16"
                    v-mask="'################'"
                    :placeholder="$t('xCreditCardNumber')"
                    :class="status($v.creditCardNumber)"
                    aria-describedby="card-number-invalid"
                  />
                  <div class="invalid-feedback" id="card-number-invalid">
                    {{ $t('xCCFieldValidation') }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col col-lg-6 col-md-t6 col-sm-12">
              <div class="form-group" id="expiry-date-month">
                <div>
                  <input
                    class="form-control"
                    v-model="$v.expirationMonth.$model"
                    type="text"
                    maxlength="2"
                    v-mask="'##'"
                    :placeholder="$t('xMm')"
                    :class="status($v.expirationMonth)"
                    aria-describedby="expiry-month-invalid"
                  />
                  <div class="invalid-feedback" id="expiry-month-invalid">
                    {{ $t('xExpirationMonthValidation') }}
                  </div>
                </div>
              </div>
            </div>
            <div class="col col-lg-6 col-md-t6 col-sm-12">
              <div class="form-group" id="expiry-date-year">
                <div>
                  <input
                    class="form-control"
                    v-model="$v.expirationYear.$model"
                    type="text"
                    maxlength="2"
                    v-mask="'##'"
                    :placeholder="$t('xYy')"
                    :class="status($v.expirationYear)"
                    aria-describedby="expiry-year-invalid"
                  />
                  <div class="invalid-feedback" id="expiry-year-invalid">
                    {{ $t('xExpirationYearValidation') }}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="input-group" id="cvv">
                <input
                  class="form-control"
                  v-model="$v.cvv.$model"
                  type="text"
                  v-mask="'####'"
                  maxlength="4"
                  :placeholder="$t('xCvv')"
                  :class="status($v.cvv)"
                  aria-describedby="cvv-invalid"
                  data-test-hook="CcCvv"
                />
                <div class="input-group-append">
                  <div class="input-group-text">
                    <img :height="20" :src="require('@/assets/images/3-digit-code.svg')" />
                  </div>
                </div>
                <div class="invalid-feedback" id="cvv-invalid">
                  {{ $t('xCVVValidation') }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-2">
          <div class="row">
            <div class="col-12">
              <div class="form-group" id="optional-coupon-code">
                <div>
                  <input
                    class="form-control"
                    v-model="couponCode"
                    type="text"
                    :placeholder="$t('xCouponCode')"
                    @input="couponCodeIsValid = null"
                    :class="{ 'is-invalid': couponCodeIsValid != null }"
                    aria-describedby="coupon-code-invalid"
                  />
                  <div class="invalid-feedback" id="coupon-code-invalid">
                    {{ $t('xPleaseEnterValidCouponCode') }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-2" v-if="accountData">
          <div class="row mb-2">
            <div class="col">
              <div class="font-weight-bold custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  v-model="useExistingBillingInformation"
                  id="useExistingBillingInformationId"
                  :disabled="savingAccountHolder"
                  data-test-hook="use-current-billing-info-checkbox-step2-upgrade-free-sub"
                />
                <label for="useExistingBillingInformationId" class="custom-control-label">
                  {{ $t('xUseCurrentBillingInfo') }}
                </label>
              </div>
            </div>
          </div>
          <div class="row" v-if="useExistingBillingInformation">
            <div class="col-12">
              <p>{{ accountData.firstName }} {{ accountData.lastName }}</p>
              <p>{{ DisplayAddress(accountData.address) }}</p>
            </div>
          </div>
          <div v-if="!useExistingBillingInformation">
            <div class="row">
              <div class="col-12">
                <div class="form-group" id="firstName-group">
                  <input id="firstName" class="form-control" v-model="accountData.firstName" type="text" />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="form-group" id="lastName-group">
                  <input class="form-control" id="lastName" v-model="accountData.lastName" type="text" />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <TypeAheadAddress
                  ref="typeAheadAddressUpgradeFreeSub"
                  :currentAddress="accountData.address"
                  @newAddress="setNewBillingAddress"
                />
              </div>
            </div>
          </div>
        </div>
        <div v-if="selectedProduct" class="my-3">
          <div class="row">
            <div class="col-12">
              <div class="subscription-cost">
                <h3 class="mb-0">{{ $t('xTotalPayment') }}: {{ selectedProduct.cost.toFixed(2) | formatCurrency }}</h3>
                <p>
                  <i>
                    {{ $t('xBilled') }}
                    <span v-if="selectedProduct.durationInMonths == 12">{{ $t('xYearly') }}</span>
                    <span v-else>{{ $t('xMonthly') }}</span>
                  </i>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col">
            <p>
              <i class="asterisk-text">*{{ $t('xUpdatingCCApplyActiveSubscriptions') }}</i>
            </p>
            <p>
              <i>{{ $t('xThisSubscriptionWillAutoRenew') }}</i>
            </p>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <button
          type="button"
          v-if="useExistingBillingInformation"
          class="btn btn-outline-warning"
          @click="openModal('upgrade-free-sub-step-1'), closeModal('upgrade-free-sub-step-2')"
          data-test-hook="previous-button-step2-upgrade-free-sub"
        >
          {{ $t('xPrevious') }}
        </button>
        <button
          type="button"
          v-if="useExistingBillingInformation"
          class="btn btn-warning"
          @click="payForProduct()"
          :disabled="!canPay() || paying"
          data-test-hook="pay-now-button-step2-upgrade-free-sub"
        >
          {{ $t('xPayNow') }}
          <span class="spinner-border ml-2" v-if="paying"></span>
        </button>
        <button
          v-else
          type="button"
          class="btn btn-outline-warning btn-block"
          @click="saveAccountHolder(), (savingAccountHolder = true)"
          :disabled="savingAccountHolder || addressSaveDisabled"
          data-test-hook="confirm-billing-info-step2-upgrade-free-sub"
        >
          {{ $t('xConfirmBillingInfo') }}
          <span class="spinner-border ml-2" v-if="savingAccountHolder" />
        </button>
      </ModalFooter>
    </Modal>

    <Modal modalId="upgrade-free-sub-step-3" size="modal-md">
      <ModalHeader :title="$t('xManageSubscription')" icon="far fa-badge-dollar colorOrange mr-1">
        <div class="col-auto float-right">
          <button
            type="button"
            class="btm btn-icon-button btn-icon-button--gray"
            @click="closeModals()"
            data-test-hook="x-button-step3-upgrade-free-sub"
          >
            <i class="fas fa-times" />
          </button>
        </div>
      </ModalHeader>
      <ModalBody>
        <div class="row no-gutters">
          <div class="col">
            <lottie :options="successAnimationOptions" v-on:animCreated="handleAnimation" :height="80" :width="80" />
          </div>
        </div>
        <div class="row text-center mt-2">
          <div class="col">
            <h1>{{ $t('xSuccess') }}!</h1>
            <p>{{ $t('xYourSubscriptionPlanHasBeenSuccessfullyUpdated') }}</p>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <button
          type="button"
          class="btn btn-warning"
          @click="closeModals(), emitSuccess()"
          data-test-hook="ok-button-step3-upgrade-free-sub"
        >
          {{ $t('xOK') }}
        </button>
      </ModalFooter>
    </Modal>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import Lottie from '../lottie.vue'
import * as successAnimation from '@/assets/images/manage-subscription-success.json'
import TypeAheadAddress from '@/components/TypeAheadAddress'
import { required, requiredIf, minLength, maxLength, email, numeric } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'

import * as Constants from '@/constants'

const monthInRange = (value, vm) => value >= 1 && value <= 12

export default {
  components: { lottie: Lottie, TypeAheadAddress },
  mixins: [validationMixin],
  props: ['currentSubscription', 'paymentInfo'],
  data() {
    return {
      availableSubscriptionProducts: null,
      gettingSubscriptions: true,
      selectedProduct: null,
      useExistingPaymentInfo: true,
      successAnimationOptions: { animationData: successAnimation, loop: false },
      paying: false,
      useExistingBillingInformation: true,
      creditCardNumber: null,
      expirationMonth: null,
      expirationYear: null,
      cvv: null,
      showValidationBanner: false,
      accountData: null,
      savingAccountHolder: false,
      addressSaveDisabled: false,
      couponCode: null,
      couponCodeIsValid: null,
      errorMessages: []
    }
  },
  validations: {
    cvv: {
      required,
      minLength: minLength(3),
      maxLength: maxLength(4),
      numeric
    },
    expirationMonth: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(2),
      numeric,
      monthInRange
    },
    expirationYear: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(2),
      numeric
    },
    creditCardNumber: {
      required,
      minLength: minLength(15),
      maxLength: maxLength(16),
      numeric
    }
  },
  computed: {
    ...mapState({
      accountProfile: state => state.account.accountProfile,
      currentApparatusDetails: state => state.apparatus.currentApparatusDetails,
      freeFeatures: state => state.application.freeFeatures,
      paidFeatures: state => state.application.paidFeatures,
      isImpersonating: state => state.auth.isImpersonating
    }),
    freeWifiEth() {
      return Constants.FreeWifiEthProductId
    },
    wifiMonthly() {
      return Constants.WiFiMonthlyProductId
    },
    wifiAnnual() {
      return Constants.WiFiAnnualProductId
    },
    ethMonthly() {
      return Constants.EthMonthlyProductId
    },
    ethAnnual() {
      return Constants.EthAnnualProductId
    },
    lteMonthly() {
      return Constants.LteMonthlyProductId
    },
    lteAnnual() {
      return Constants.LteAnnualProductId
    },
    cdmaMonthly() {
      return Constants.CdmaMonthlyProductId
    },
    cdmaAnnual() {
      return Constants.CdmaAnnualProductId
    },
    dealerManaged() {
      return Constants.DealerManagedProductId
    }
  },
  methods: {
    emitSuccess() {
      this.$emit('success')
    },
    emitCancelFlow() {
      this.closeModal('upgrade-free-sub-step-1')
      this.$emit('cancelSubscription')
    },
    getProductCost(product) {
      if (product.durationInMonths == 12) {
        return '$' + product.cost.toFixed(2) + '/Year'
      } else if (product.durationInMonths == 1) {
        return '$' + product.cost.toFixed(2) + '/Month'
      } else return 'Free'
    },
    setNewBillingAddress(newAddress) {
      this.accountData.address = newAddress
      if (this.$refs.typeAheadAddressUpgradeFreeSub.$v.$anyError) {
        this.addressSaveDisabled = true
      } else {
        this.addressSaveDisabled = false
      }
    },
    setSelectedProduct(product) {
      this.selectedProduct = product
      this.openModal('upgrade-free-sub-step-2')
      this.closeModal('upgrade-free-sub-step-1')
    },
    getSubscriptionVariant(product) {
      if (this.selectedProduct) {
        if (this.selectedProduct.id == product.id) return 'selected'
      } else if (product.durationInMonths == 12) {
        return 'selected'
      }
    },
    handleAnimation(anim) {
      this.anim = anim
    },
    logCouponCodeUsed() {
      this.$appInsights.trackEvent({
        name: 'free-to-paid-sub-mgmt-coupon-used',
        properties: {
          page: 'subscriptions',
          organizationId: this.accountProfile.organizationId,
          userId: this.accountProfile.userId,
          apparatusId: this.currentApparatusDetails.apparatusId,
          couponCode: this.couponCode
        }
      })
    },
    payForProduct() {
      this.paying = true
      let managePaidNotificationsRequest = {
        apparatusId: this.currentApparatusDetails.apparatusId,
        newProductId: this.selectedProduct.id,
        oldProductId: this.currentSubscription[0].productId
      }
      if (this.paymentInfo && this.useExistingPaymentInfo) {
        this.$store
          .dispatch('changeBillingAccountProduct', {
            organizationId: this.accountProfile.organizationId,
            apparatusId: this.currentApparatusDetails.apparatusId,
            couponCode: this.couponCode,
            productId: this.selectedProduct.id
          })
          .then(response => {
            this.$store.dispatch('managePaidPreferences', managePaidNotificationsRequest)
            this.paying = false
            this.closeModals()
            this.emitSuccess()
            let event = this.createEventLogObjectFromSubscriptionsPage(
              this.currentApparatusDetails,
              this.currentSubscription,
              this.accountProfile,
              this.isImpersonating,
              Constants.UpgradeEventType,
              this.selectedProduct.id,
              this.couponCode
            )
            this.$store.dispatch('logEvent', event)
            if (this.couponCode) {
              this.logCouponCodeUsed()
            }
          })
          .catch(error => {
            this.paying = false
            if (!error.response.data.couponCodeValid) {
              this.couponCodeIsValid = false
            } else {
              this.$store.dispatch('displayToast', {
                title: this.$t('xError'),
                message: this.$t('xFailedSetUpGeneratorSubscription'),
                variant: 'danger'
              })
            }
          })
      } else if (this.paymentInfo && !this.useExistingPaymentInfo) {
        //delete/cancel/remove existing payment info,
        //add new user entered credit card info to account,
        //add apparatus to the selected product, then proceed to success page (if all calls succeed)
        let removePaymentModel = {
          organizationId: this.accountProfile.organizationId,
          paymentId: this.paymentInfo.id
        }
        let addPaymentModel = {
          organizationId: this.accountProfile.organizationId,
          cardNumber: this.creditCardNumber,
          cvv: this.cvv,
          expirationMonth: this.expirationMonth,
          expirationYear: '20' + this.expirationYear
        }
        let changeSubscriptionMode = {
          organizationId: this.accountProfile.organizationId,
          apparatusId: this.currentApparatusDetails.apparatusId,
          productId: this.selectedProduct.id,
          couponCode: this.couponCode
        }
        this.$store
          .dispatch('addBillingAccountPaymentMethod', addPaymentModel)
          .then(response => {
            this.$store
              .dispatch('removeBillingAccountPaymentMethod', removePaymentModel)
              .then(response => {
                this.$store
                  .dispatch('changeBillingAccountProduct', changeSubscriptionMode)
                  .then(response => {
                    this.$store.dispatch('managePaidPreferences', managePaidNotificationsRequest)
                    this.paying = false
                    this.closeModals()
                    this.emitSuccess()
                    let event = this.createEventLogObjectFromSubscriptionsPage(
                      this.currentApparatusDetails,
                      this.currentSubscription,
                      this.accountProfile,
                      this.isImpersonating,
                      Constants.UpgradeEventType,
                      this.selectedProduct.id,
                      this.couponCode
                    )
                    this.$store.dispatch('logEvent', event)
                    if (this.couponCode) {
                      this.logCouponCodeUsed()
                    }
                  })
                  .catch(error => {
                    this.paying = false
                    if (!error.response.data.couponCodeValid) {
                      this.couponCodeIsValid = false
                    } else {
                      this.$store.dispatch('displayToast', {
                        title: this.$t('xError'),
                        message: this.$t('xFailedSetUpGeneratorSubscription'),
                        variant: 'danger'
                      })
                    }
                  })
              })
              .catch(error => {
                this.paying = false
                this.$store.dispatch('displayToast', {
                  title: this.$t('xError'),
                  message: this.$t('xFailedToRemoveExistingPaymentInformation'),
                  variant: 'danger'
                })
              })
          })
          .catch(error => {
            this.paying = false
            this.gettingPaymentInfo = false
            this.errorMessages = []
            if (error.response.data.errors) {
              if (error.response.data.errors.CardNumber || error.response.data.errors.cardNumber) {
                this.showValidationBanner = true
                this.errorMessages.push(this.$t('xCCIsInvalid'))
              }
              if (error.response.data.errors.Cvv || error.response.data.errors.cvv) {
                this.showValidationBanner = true
                this.errorMessages.push(this.$t('xCvvInvalid'))
              }
              if (error.response.data.errors.ExpirationMonth || error.response.data.errors.expirationMonth) {
                this.showValidationBanner = true
                this.errorMessages.push(this.$t('xExpirationMonthInvalid'))
              }
              if (error.response.data.errors.ExpirationYear || error.response.data.errors.expirationYear) {
                this.showValidationBanner = true
                this.errorMessages.push(this.$t('xExpirationYearInvalid'))
              }
              if (error.response.data.errors.billing || error.response.data.errors.Billing) {
                this.$store.dispatch('displayToast', {
                  title: this.$t('xError'),
                  message: error.response.data.errors.billing
                    ? error.response.data.errors.billing
                    : error.data.response.errors.Billing,
                  variant: 'danger'
                })
              }
            } else {
              this.$store.dispatch('displayToast', {
                title: this.$t('xError'),
                message: this.$t('xFailedToAddPaymentMethod'),
                variant: 'danger'
              })
            }
          })
      } else {
        let addPaymentModel = {
          organizationId: this.accountProfile.organizationId,
          cardNumber: this.creditCardNumber,
          cvv: this.cvv,
          expirationMonth: this.expirationMonth,
          expirationYear: '20' + this.expirationYear
        }
        let changeSubscriptionMode = {
          organizationId: this.accountProfile.organizationId,
          apparatusId: this.currentApparatusDetails.apparatusId,
          productId: this.selectedProduct.id,
          couponCode: this.couponCode
        }
        this.$store
          .dispatch('addBillingAccountPaymentMethod', addPaymentModel)
          .then(response => {
            this.$store
              .dispatch('changeBillingAccountProduct', changeSubscriptionMode)
              .then(response => {
                this.$store.dispatch('managePaidPreferences', managePaidNotificationsRequest)
                this.paying = false
                this.closeModals()
                this.emitSuccess()
                let event = this.createEventLogObjectFromSubscriptionsPage(
                  this.currentApparatusDetails,
                  this.currentSubscription,
                  this.accountProfile,
                  this.isImpersonating,
                  Constants.UpgradeEventType,
                  this.selectedProduct.id,
                  this.couponCode
                )
                this.$store.dispatch('logEvent', event)
                if (this.couponCode) {
                  this.logCouponCodeUsed()
                }
              })
              .catch(error => {
                this.paying = false
                if (!error.response.data.couponCodeValid) {
                  this.couponCodeIsValid = false
                } else {
                  this.$store.dispatch('displayToast', {
                    title: this.$t('xError'),
                    message: this.$t('xFailedSetUpGeneratorSubscription'),
                    variant: 'danger'
                  })
                }
              })
          })
          .catch(error => {
            this.paying = false
            this.gettingPaymentInfo = false
            this.errorMessages = []
            if (error.response.data.errors) {
              if (error.response.data.errors.CardNumber || error.response.data.errors.cardNumber) {
                this.showValidationBanner = true
                this.errorMessages.push(this.$t('xCCIsInvalid'))
              }
              if (error.response.data.errors.Cvv || error.response.data.errors.cvv) {
                this.showValidationBanner = true
                this.errorMessages.push(this.$t('xCvvInvalid'))
              }
              if (error.response.data.errors.ExpirationMonth || error.response.data.errors.expirationMonth) {
                this.showValidationBanner = true
                this.errorMessages.push(this.$t('xExpirationMonthInvalid'))
              }
              if (error.response.data.errors.ExpirationYear || error.response.data.errors.expirationYear) {
                this.showValidationBanner = true
                this.errorMessages.push(this.$t('xExpirationYearInvalid'))
              }
              if (error.response.data.errors.billing || error.response.data.errors.Billing) {
                this.$store.dispatch('displayToast', {
                  title: this.$t('xError'),
                  message: error.response.data.errors.billing
                    ? error.response.data.errors.billing
                    : error.data.response.errors.Billing,
                  variant: 'danger'
                })
              }
            } else {
              this.$store.dispatch('displayToast', {
                title: this.$t('xError'),
                message: this.$t('xFailedToAddPaymentMethod'),
                variant: 'danger'
              })
            }
          })
      }
    },
    closeModals() {
      this.closeModal('upgrade-free-sub-step-1')
      this.closeModal('upgrade-free-sub-step-2')
      this.closeModal('upgrade-free-sub-step-3')
      this.selectedProduct = null
      this.newSubscription = null
      this.useExistingBillingInformation = true
      this.useExistingPaymentInfo = true
      this.creditCardNumber = null
      this.expirationMonth = null
      this.expirationYear = null
      this.cvv = null
      this.paying = false
      this.showValidationBanner = false
      this.errorMessages = []
      this.$v.$reset()
      this.couponCode = null
      this.couponCodeIsValid = null
    },
    getPremiumSubscriptions(product) {
      if (product.id != this.currentSubscription[0].productId) {
        return product
      }
    },
    getSubscriptionSavings(product) {
      let monthlySubscription = this.availableSubscriptionProducts.filter(x => x.durationInMonths == 1)
      return Math.round(((monthlySubscription[0].cost * 12 - product.cost) / (monthlySubscription[0].cost * 12)) * 100)
    },
    canPay() {
      if (this.paymentInfo && this.useExistingPaymentInfo) {
        return true
      } else if (!this.useExistingPaymentInfo || !this.paymentInfo) {
        if (!this.creditCardNumber || !this.cvv || !this.expirationMonth || !this.expirationYear) {
          return false
        }
        if (!this.$v.$anyError && !this.paying) {
          return true
        } else return false
      } else return false
    },
    saveAccountHolder() {
      this.savingAccountHolder = true
      this.$store
        .dispatch('updateAccountHolder', {
          firstName: this.accountData.firstName,
          lastName: this.accountData.lastName,
          organizationAddress: {
            ...this.accountData.address,
            organizationId: this.accountData.organizationId
          }
        })
        .then(response => {
          if (!this.isImpersonating) this.$store.dispatch('getUser')
          else this.$store.dispatch('getImpersonatedUser')
          this.$store.dispatch('getAccountProfile')
          this.useExistingBillingInformation = true
          this.savingAccountHolder = false
          this.timeTheAccountChange()
        })
        .catch(error => {
          this.savingAccountHolder = false
          this.$store.dispatch('displayToast', {
            title: this.$t('xError'),
            message: this.$t('xFailedToUpdateBillingNameAndAddress'),
            variant: 'danger'
          })
        })
    },
    timeTheAccountChange() {
      setTimeout(
        function() {
          this.savingAccountHolder = false
        }.bind(this),
        5000
      )
    }
  },
  watch: {
    accountProfile: function(newVal, oldVal) {
      if (newVal) {
        this.accountData = _.cloneDeep(this.accountProfile)
      }
    }
  },
  mounted() {
    if (!this.freeFeatures) this.$store.dispatch('getFreeFeatures')
    if (!this.paidFeatures) this.$store.dispatch('getPaidFeatures')
    if (this.accountProfile) {
      this.accountData = _.cloneDeep(this.accountProfile)
    }
    this.$store
      .dispatch('getSubscriptionProductByApparatusId', this.$route.params.apparatusId)
      .then(response => {
        this.availableSubscriptionProducts = response.data.filter(this.getPremiumSubscriptions)
        let initialProduct = this.availableSubscriptionProducts.filter(x => x.durationInMonths == 12)
        this.selectedProduct = initialProduct[0]
      })
      .catch(error => {
        this.$store.dispatch('displayToast', {
          title: this.$t('xError'),
          message: this.$t('xFailedToGetSubscriptionProducts'),
          variant: 'danger'
        })
      })
      .finally(() => {
        this.gettingSubscriptions = false
      })
  }
}
</script>
<style lang="scss">
#upgrade-free-sub-step-1 {
  @media (min-width: 576px) {
    .modal-lg {
      max-width: calc(100% - 3rem) !important;
    }
  }
  @media (min-width: 800px) {
    .modal-lg {
      max-width: 800px !important;
    }
  }
}
</style>
<style scoped lang="scss">
.subscription-card {
  max-width: 360px;
}
.selected {
  border: 1px solid #ff6b00;
  background: white;
  box-shadow: 0px 0px 10px #ff6b00;
}
.selectedType {
  border-color: #ff6b00;
}
.featureDescription {
  font-size: 0.75rem;
  line-height: 1rem;
}
.cancel-arrows {
  font-size: 0.75rem;
}
.available-subscription {
  border: solid 1px var(--carbon-200);
  border-radius: 0.25em;
  padding: 0.75em 2em;
}
.save-banner {
  background: var(--brand-400);
  text-align: center;
  color: var(--carbon-900);
  border-radius: 9999px;
  width: '100%';
  padding: 0 15px;
  display: flex;
  justify-content: center;
  align-items: center;

  h3 {
    font-size: 1em;
  }
}

.banner-spacer {
  @media (min-width: 768px) {
    height: 34px;
  }
}
</style>
