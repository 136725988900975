var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.currentApparatusDetails || !_vm.thisApparatus
        ? _c(
            "div",
            { staticClass: "d-flex justify-content-center mb-3 pt-4" },
            [
              _c("span", {
                staticClass: "spinner-border spinner-border-xl text-warning",
                staticStyle: { width: "5rem", height: "5rem" },
                attrs: { "data-test-hook": "LoadingSpinner", label: "Spinning" }
              })
            ]
          )
        : _c(
            "div",
            { staticClass: "row" },
            [
              _c("div", { staticClass: "col" }, [
                _vm.generatorDealerManagedSubscriptionExpired()
                  ? _c("div", { staticClass: "card" }, [
                      _c(
                        "div",
                        { staticClass: "card-header mb-4 statusDisconnected" },
                        [
                          _c(
                            "div",
                            [
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "b-toggle",
                                      rawName:
                                        "v-b-toggle.dealerHsbIssueBanner",
                                      modifiers: { dealerHsbIssueBanner: true }
                                    }
                                  ],
                                  staticClass:
                                    "row noClickOutline details-header",
                                  class: _vm.getHeaderTextClass(),
                                  staticStyle: { "overflow-anchor": "none" },
                                  attrs: { "data-test-hook": "StatusHeader" }
                                },
                                [
                                  _c("div", { staticClass: "col" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "row align-center no-gutters",
                                        attrs: { type: "button" }
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "col d-flex align-items-center"
                                          },
                                          [
                                            _c(
                                              "h2",
                                              {
                                                staticClass:
                                                  "statusText mb-0 d-flex align-items-center",
                                                class: _vm.getHeaderTextClass(),
                                                attrs: {
                                                  "data-test-hook":
                                                    "StatusLabel"
                                                }
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "mr-2 fa-light fa-triangle-exclamation"
                                                }),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "xYourAccountNeedsAttention"
                                                      )
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            )
                                          ]
                                        ),
                                        _c(
                                          "b-col",
                                          {
                                            staticClass:
                                              "float-right when-closed",
                                            attrs: { cols: "auto" }
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "fas fa-lg fa-chevron-down"
                                            })
                                          ]
                                        ),
                                        _c(
                                          "b-col",
                                          {
                                            staticClass:
                                              "float-right when-open",
                                            attrs: { cols: "auto" }
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "fas fa-lg fa-chevron-up"
                                            })
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ])
                                ]
                              ),
                              _c(
                                "b-collapse",
                                {
                                  staticClass: "p-0 mt-2 statusCollapse",
                                  class: "statusDisconnected",
                                  attrs: {
                                    visible: "",
                                    id: "dealerHsbIssueBanner"
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "p-4",
                                      class: _vm.getHeaderTextClass()
                                    },
                                    [
                                      _c("div", { staticClass: "row" }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "col",
                                            attrs: {
                                              "data-test-hook":
                                                "subExpiredTitle"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "xRemoteMonitoringExpiredBodyDealerManagedDetailsTitle"
                                                  )
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      ]),
                                      _c("div", { staticClass: "row mt-4" }, [
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "mr-2 ml-2 btn btn-secondary",
                                            attrs: {
                                              type: "button",
                                              "data-test-hook":
                                                "dealer-managed-expired-contact-support-device-details"
                                            },
                                            on: {
                                              click: function($event) {
                                                _vm.navToSupportPage(),
                                                  _vm.trackAppInsightsEvent(
                                                    "dealer-managed-expired-contact-support-button",
                                                    {
                                                      page: "device details",
                                                      organizationId:
                                                        _vm.accountProfile
                                                          .organizationId,
                                                      userId: _vm.user.userId,
                                                      apparatusId:
                                                        _vm
                                                          .currentApparatusDetails
                                                          .apparatusId
                                                    }
                                                  )
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "xContactSupportUpgrade"
                                                  )
                                                ) +
                                                " "
                                            )
                                          ]
                                        ),
                                        _c(
                                          "button",
                                          {
                                            staticClass: "btn btn-primary",
                                            attrs: {
                                              type: "button",
                                              "data-test-hook":
                                                "expired-sub-contact-dealer-button-device-details"
                                            },
                                            on: {
                                              click: function($event) {
                                                _vm.navToContactYourDealer(),
                                                  _vm.trackAppInsightsEvent(
                                                    "dealer-managed-expired-contact-dealer-button",
                                                    {
                                                      page: "device details",
                                                      organizationId:
                                                        _vm.accountProfile
                                                          .organizationId,
                                                      userId: _vm.user.userId,
                                                      apparatusId:
                                                        _vm
                                                          .currentApparatusDetails
                                                          .apparatusId
                                                    }
                                                  )
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "xRemoteMonitoringExpiredBodyDealerManagedContactDealer"
                                                  )
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      ])
                                    ]
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "card-body",
                          staticStyle: { "padding-top": "0px" }
                        },
                        [
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-12 col-lg-5" }, [
                              _vm.currentApparatusDetails.weather
                                ? _c("div", { staticClass: "row" }, [
                                    _c("div", { staticClass: "col" }, [
                                      _c(
                                        "h3",
                                        {
                                          staticClass: "headerInline ml-1",
                                          attrs: {
                                            "data-test-hook": "Temperature"
                                          }
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "mr-1",
                                            class: _vm._f("weatherIcon")(
                                              _vm.currentApparatusDetails
                                                .weather.iconCode
                                            )
                                          }),
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.temperature) +
                                              "°" +
                                              _vm._s(_vm.temperatureUnits) +
                                              " "
                                          )
                                        ]
                                      )
                                    ])
                                  ])
                                : _vm._e(),
                              _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col" }, [
                                  _c("img", {
                                    staticClass: "img-fluid",
                                    attrs: {
                                      src:
                                        _vm.currentApparatusDetails
                                          .heroImageUrl,
                                      alt: _vm.$t("xHeroImage"),
                                      "data-test-hook": "HeroImg"
                                    }
                                  })
                                ])
                              ]),
                              _c("div", { staticClass: "row mt-2 mb-2" }, [
                                _c("div", { staticClass: "col" }, [
                                  _vm.currentApparatusDetails.isConnected
                                    ? _c("span", [
                                        _vm._v(_vm._s(_vm.$t("xOnline")))
                                      ])
                                    : _c("span", [
                                        _vm._v(_vm._s(_vm.$t("xOffline")))
                                      ]),
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("xSince")) +
                                      " " +
                                      _vm._s(
                                        _vm.displayShortDateTimeWithTZ(
                                          _vm.currentApparatusDetails
                                            .connectionTimestamp
                                        )
                                      ) +
                                      " "
                                  )
                                ])
                              ])
                            ]),
                            _c("div", { staticClass: "col" }, [
                              _c("div", { staticClass: "row align-center" }, [
                                _c("div", { staticClass: "col" }, [
                                  _c("div", { staticClass: "row" }, [
                                    _c("div", { staticClass: "col-12" }, [
                                      _c(
                                        "h2",
                                        {
                                          attrs: {
                                            "data-test-hook": "ApparatusName"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.currentApparatusDetails.name
                                            )
                                          )
                                        ]
                                      )
                                    ])
                                  ]),
                                  _c("div", { staticClass: "row" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "col-12",
                                        attrs: {
                                          "data-test-hook": "ApparatusAddress"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.DisplayAddress(
                                                _vm.currentApparatusDetails
                                                  .address
                                              )
                                            ) +
                                            " "
                                        )
                                      ]
                                    )
                                  ])
                                ]),
                                _c("div", { staticClass: "col-auto" }, [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-icon-button",
                                      attrs: {
                                        type: "button",
                                        "data-test-hook": "UpdateApparatusBtn"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.openUpdateApparatusNameAddress()
                                        }
                                      }
                                    },
                                    [_c("i", { staticClass: "fas fa-pencil" })]
                                  )
                                ])
                              ]),
                              _vm.subscriptionActive(
                                _vm.currentApparatusDetails.subscription
                              )
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "row detailsCardAction hoverPointer my-3 d-md-none align-items-center no-gutters",
                                      attrs: {
                                        "data-test-hook":
                                          "status-history-clickable-row-device-details"
                                      },
                                      on: { click: _vm.toStatusHistory }
                                    },
                                    [
                                      _c("div", { staticClass: "col ml-2" }, [
                                        _c(
                                          "h5",
                                          { staticClass: "headerInline" },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "fas fa-history colorOrange pr-2"
                                            }),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("xStatusHistory")
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      ]),
                                      _vm._m(0)
                                    ]
                                  )
                                : _vm._e(),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "row no-gutters align-items-center detailsCardAction hoverPointer my-3",
                                  attrs: {
                                    "data-test-hook":
                                      "run-hours-clickable-row-device-details"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.openModal(
                                        "RunHoursDetailsModal"
                                      )
                                    }
                                  }
                                },
                                [
                                  _c("div", { staticClass: "col ml-2" }, [
                                    _c(
                                      "h5",
                                      { staticClass: "headerInline mb-0 pb-0" },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "fas fa-shield-check colorOrange pr-2"
                                        }),
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("xHoursProtected")) +
                                            " "
                                        )
                                      ]
                                    )
                                  ]),
                                  _vm._m(1)
                                ]
                              ),
                              _vm.fuelLevel
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "row no-gutters my-4 align-items-center",
                                      attrs: {
                                        "data-test-hook":
                                          "fuel-level-clickable-row-device-details"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.$bvModal.show(
                                            "FuelLevelDetailsModal"
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("div", { staticClass: "col ml-2" }, [
                                        _c(
                                          "h5",
                                          {
                                            staticClass:
                                              "headerInline mb-0 pb-0"
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "fas fa-gas-pump colorOrange pr-2"
                                            }),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm._f("formatLabel")(
                                                    "xFuelLevel"
                                                  )
                                                ) +
                                                " "
                                            )
                                          ]
                                        ),
                                        _c("strong", { staticClass: "ml-1" }, [
                                          _vm._v(
                                            " " + _vm._s(_vm.fuelLevel) + "% "
                                          )
                                        ])
                                      ])
                                    ]
                                  )
                                : _vm._e(),
                              _vm.batteryVoltage
                                ? _c(
                                    "div",
                                    { staticClass: "row my-4 no-gutters" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "col-auto ml-2" },
                                        [
                                          _c(
                                            "h5",
                                            { staticClass: "headerInline" },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "fas fa-battery-bolt colorOrange pr-2"
                                              }),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm._f("formatLabel")(
                                                      "xBatteryVoltage"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                              _c(
                                                "strong",
                                                {
                                                  staticClass: "ml-1",
                                                  class: _vm.getBatteryVoltageClass(),
                                                  attrs: {
                                                    "data-test-hook":
                                                      "BatteryVoltage"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.batteryVoltage
                                                      ) +
                                                      _vm._s(
                                                        _vm.$t("xVoltageAbbr")
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "col float-right text-right mr-3"
                                        },
                                        [
                                          _c(
                                            "popper",
                                            {
                                              attrs: {
                                                trigger: "hover",
                                                options: { placement: "bottom" }
                                              }
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "popper" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "xYourBatteryLevelIs"
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                  _c("strong", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.getBatteryVoltageClassification()
                                                      ) + "."
                                                    )
                                                  ]),
                                                  _vm._v(
                                                    " ( " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "xValuesOf11.2VOrHigherAreGood"
                                                        )
                                                      ) +
                                                      " ) "
                                                  )
                                                ]
                                              ),
                                              _c("i", {
                                                staticClass:
                                                  "fas fa-question-circle hoverPointer",
                                                attrs: { slot: "reference" },
                                                slot: "reference"
                                              })
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ])
                          ])
                        ]
                      )
                    ])
                  : _vm.currentApparatusDetails.apparatusClassification ==
                    _vm.hsbGeneratorApparatus
                  ? _c("div", { staticClass: "card" }, [
                      _c(
                        "div",
                        {
                          staticClass: "card-header mb-4",
                          class:
                            _vm.currentApparatusDetails.isConnected &&
                            !_vm.currentApparatusDetails.isConnecting
                              ? `status${_vm.currentApparatusDetails.apparatusStatus}`
                              : "statusNoSignal"
                        },
                        [
                          _c(
                            "div",
                            [
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "b-toggle",
                                      rawName: "v-b-toggle.hsbIssueBanner",
                                      modifiers: { hsbIssueBanner: true }
                                    }
                                  ],
                                  staticClass:
                                    "row noClickOutline details-header",
                                  class: _vm.getHeaderTextClass(),
                                  staticStyle: { "overflow-anchor": "none" },
                                  attrs: { "data-test-hook": "StatusHeader" }
                                },
                                [
                                  _c("div", { staticClass: "col" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "row align-center no-gutters",
                                        attrs: { type: "button" }
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "col d-flex align-items-center"
                                          },
                                          [
                                            _c(
                                              "h2",
                                              {
                                                staticClass:
                                                  "statusText mb-0 d-flex align-items-center",
                                                class: _vm.getHeaderTextClass(),
                                                attrs: {
                                                  "data-test-hook":
                                                    "StatusLabel"
                                                }
                                              },
                                              [
                                                !_vm.isSwitchedOff()
                                                  ? _c("StatusIcon", {
                                                      staticClass: "mr-2",
                                                      attrs: {
                                                        apparatusStatus:
                                                          _vm
                                                            .currentApparatusDetails
                                                            .apparatusStatus,
                                                        isConnected:
                                                          _vm
                                                            .currentApparatusDetails
                                                            .isConnected,
                                                        showWarning:
                                                          _vm
                                                            .currentApparatusDetails
                                                            .showWarning,
                                                        isConnecting:
                                                          _vm
                                                            .currentApparatusDetails
                                                            .isConnecting,
                                                        deviceType:
                                                          _vm
                                                            .currentApparatusDetails
                                                            .deviceType == null
                                                            ? _vm.hsbLteDeviceType
                                                            : _vm
                                                                .currentApparatusDetails
                                                                .deviceType ==
                                                              _vm.hsbG3DeviceType
                                                            ? _vm
                                                                .currentApparatusDetails
                                                                .networkType
                                                            : _vm
                                                                .currentApparatusDetails
                                                                .deviceType,
                                                        inverse: true
                                                      }
                                                    })
                                                  : _c("i", {
                                                      staticClass:
                                                        "mr-2 fas fa-fw fa-octagon-exclamation"
                                                    }),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm
                                                        .currentApparatusDetails
                                                        .isConnecting
                                                        ? _vm.$t("xWorkingOnIt")
                                                        : _vm
                                                            .currentApparatusDetails
                                                            .statusLabel
                                                    ) +
                                                    " "
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        ),
                                        _c(
                                          "b-col",
                                          {
                                            staticClass:
                                              "float-right when-closed",
                                            attrs: { cols: "auto" }
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "fas fa-lg fa-chevron-down"
                                            })
                                          ]
                                        ),
                                        _c(
                                          "b-col",
                                          {
                                            staticClass:
                                              "float-right when-open",
                                            attrs: { cols: "auto" }
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "fas fa-lg fa-chevron-up"
                                            })
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ])
                                ]
                              ),
                              _c(
                                "b-collapse",
                                {
                                  staticClass: "p-0 mt-2 statusCollapse",
                                  class:
                                    _vm.currentApparatusDetails.isConnected &&
                                    !_vm.currentApparatusDetails.isConnecting
                                      ? `status-message${_vm.currentApparatusDetails.apparatusStatus}`
                                      : "statusNoSignal",
                                  attrs: { visible: "", id: "hsbIssueBanner" }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "p-4",
                                      class: _vm.getHeaderTextClass()
                                    },
                                    [
                                      _c("div", { staticClass: "row" }, [
                                        _c("div", { staticClass: "col" }, [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "status-date",
                                              attrs: {
                                                "data-test-hook": "LastSeen"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.displayLongDateTime(
                                                      _vm
                                                        .currentApparatusDetails
                                                        .lastSeen
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ])
                                      ]),
                                      _c("div", { staticClass: "row" }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "col",
                                            attrs: {
                                              "data-test-hook": "ECodeLabel"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.currentApparatusDetails
                                                    .alarmEcodeLabel ??
                                                    _vm.currentApparatusDetails
                                                      .eCodeLabel
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      ]),
                                      !_vm.currentApparatusDetails
                                        .isConnecting &&
                                      _vm.currentApparatusDetails.isConnected
                                        ? _c("div", { staticClass: "row" }, [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "col",
                                                attrs: {
                                                  "data-test-hook": "StatusText"
                                                }
                                              },
                                              [
                                                _vm.currentApparatusDetails
                                                  .apparatusStatus != 8
                                                  ? _c("p", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm
                                                              .currentApparatusDetails
                                                              .statusText
                                                          ) +
                                                          " "
                                                      )
                                                    ])
                                                  : _c("p", {
                                                      domProps: {
                                                        innerHTML: _vm._s(
                                                          _vm.getSwitchedOffStatus(
                                                            _vm
                                                              .currentApparatusDetails
                                                              .statusText
                                                          )
                                                        )
                                                      }
                                                    })
                                              ]
                                            )
                                          ])
                                        : _vm._e(),
                                      _vm.currentApparatusDetails.isConnecting
                                        ? _c(
                                            "div",
                                            { staticClass: "row mt-2" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "col" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "xMobileLinkIsWorkingToGetYourGeneratorData"
                                                        )
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        : !_vm.currentApparatusDetails
                                            .isConnected
                                        ? _c(
                                            "div",
                                            { staticClass: "row mt-2" },
                                            [
                                              _vm.currentApparatusDetails
                                                .deviceType == "lte" ||
                                              _vm.currentApparatusDetails
                                                .deviceType == "cdma"
                                                ? _c(
                                                    "div",
                                                    { staticClass: "col" },
                                                    [
                                                      _c("p", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "xCellularNoSignalHelpMessage"
                                                            )
                                                          )
                                                        )
                                                      ]),
                                                      _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "btn btn-outline-warning mt-3",
                                                          attrs: {
                                                            type: "button",
                                                            href:
                                                              "https://support.generac.com/mobilelink/s/global-search/LTE",
                                                            target: "_blank",
                                                            "data-test-hook":
                                                              "TroubleshootingLink"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "xGetHelp"
                                                                )
                                                              ) +
                                                              " "
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                : _c(
                                                    "div",
                                                    { staticClass: "col" },
                                                    [
                                                      _c("p", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "xNoSignalHelpMessage"
                                                            )
                                                          )
                                                        )
                                                      ]),
                                                      _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "btn btn-outline-warning mt-3",
                                                          attrs: {
                                                            type: "button",
                                                            href:
                                                              "https://support.generac.com/mobilelink/s/global-search/wifi%20troubleshooting",
                                                            target: "_blank",
                                                            "data-test-hook":
                                                              "TroubleshootingLink"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "xGetHelp"
                                                                )
                                                              ) +
                                                              " "
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.subscriptionPaidAndNotManaged(
                                        _vm.currentApparatusDetails.subscription
                                      ) &&
                                      _vm.currentApparatusDetails
                                        .hasMaintenanceAlert
                                        ? _c(
                                            "div",
                                            { staticClass: "row mt-3" },
                                            [
                                              _vm.currentApparatusDetails
                                                .isConnected
                                                ? _c(
                                                    "div",
                                                    { staticClass: "col" },
                                                    [
                                                      _c(
                                                        "button",
                                                        {
                                                          staticClass: "btn",
                                                          class: _vm.clearMaintenanceButtonClass(),
                                                          attrs: {
                                                            type: "button",
                                                            "data-test-hook":
                                                              "dismiss-warning-button-device-details"
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              _vm.openModal(
                                                                "clearMaintenanceModal"
                                                              ),
                                                                _vm.trackAppInsightsEvent(
                                                                  "open-clear-maintenance-modal",
                                                                  {
                                                                    page:
                                                                      "device details",
                                                                    organizationId:
                                                                      _vm
                                                                        .accountProfile
                                                                        .organizationId,
                                                                    userId:
                                                                      _vm.user
                                                                        .userId,
                                                                    apparatusId:
                                                                      _vm
                                                                        .currentApparatusDetails
                                                                        .apparatusId
                                                                  }
                                                                )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c("i", {
                                                            staticClass:
                                                              "fas fa-clipboard-check"
                                                          }),
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "xDismiss"
                                                                )
                                                              ) +
                                                              " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "xWarning"
                                                                )
                                                              ) +
                                                              " "
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                : _vm._e()
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.virtualPowerPlantEnabled(
                                        _vm.currentAppConfig
                                      ) &&
                                      _vm.apparatusIsRunning &&
                                      _vm.currentApparatusDetails
                                        .enrolledInVpp &&
                                      _vm.currentApparatusDetails
                                        .hasActiveVppEvent
                                        ? _c(
                                            "div",
                                            { staticClass: "row mt-3" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "col" },
                                                [
                                                  _c(
                                                    "button",
                                                    {
                                                      staticClass:
                                                        "btn btn-block btn-outline-light",
                                                      attrs: { type: "button" },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.$router.push(
                                                            {
                                                              name:
                                                                "virtualPowerPlant"
                                                            }
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "fas fa-eye"
                                                      }),
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "xViewEventInProgress"
                                                            )
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ]
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "card-body",
                          staticStyle: { "padding-top": "0px" }
                        },
                        [
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-12 col-lg-5" }, [
                              _vm.currentApparatusDetails.weather
                                ? _c("div", { staticClass: "row" }, [
                                    _c("div", { staticClass: "col" }, [
                                      _c(
                                        "h3",
                                        {
                                          staticClass: "headerInline ml-1",
                                          attrs: {
                                            "data-test-hook": "Temperature"
                                          }
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "mr-1",
                                            class: _vm._f("weatherIcon")(
                                              _vm.currentApparatusDetails
                                                .weather.iconCode
                                            )
                                          }),
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.temperature) +
                                              "°" +
                                              _vm._s(_vm.temperatureUnits) +
                                              " "
                                          )
                                        ]
                                      )
                                    ])
                                  ])
                                : _vm._e(),
                              _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col" }, [
                                  _c("img", {
                                    staticClass: "img-fluid",
                                    attrs: {
                                      src:
                                        _vm.currentApparatusDetails
                                          .heroImageUrl,
                                      alt: _vm.$t("xHeroImage"),
                                      "data-test-hook": "HeroImg"
                                    }
                                  })
                                ])
                              ]),
                              _c("div", { staticClass: "row mt-2 mb-2" }, [
                                _c("div", { staticClass: "col" }, [
                                  _vm.currentApparatusDetails.isConnected
                                    ? _c("span", [
                                        _vm._v(_vm._s(_vm.$t("xOnline")))
                                      ])
                                    : _c("span", [
                                        _vm._v(_vm._s(_vm.$t("xOffline")))
                                      ]),
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("xSince")) +
                                      " " +
                                      _vm._s(
                                        _vm.displayShortDateTimeWithTZ(
                                          _vm.currentApparatusDetails
                                            .connectionTimestamp
                                        )
                                      ) +
                                      " "
                                  )
                                ])
                              ])
                            ]),
                            _c("div", { staticClass: "col" }, [
                              _c("div", { staticClass: "row align-center" }, [
                                _c("div", { staticClass: "col" }, [
                                  _c("div", { staticClass: "row" }, [
                                    _c("div", { staticClass: "col-12" }, [
                                      _c(
                                        "h2",
                                        {
                                          attrs: {
                                            "data-test-hook": "ApparatusName"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.currentApparatusDetails.name
                                            )
                                          )
                                        ]
                                      )
                                    ])
                                  ]),
                                  _c("div", { staticClass: "row" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "col-12",
                                        attrs: {
                                          "data-test-hook": "ApparatusAddress"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.DisplayAddress(
                                                _vm.currentApparatusDetails
                                                  .address
                                              )
                                            ) +
                                            " "
                                        )
                                      ]
                                    )
                                  ])
                                ]),
                                _c("div", { staticClass: "col-auto" }, [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-icon-button",
                                      attrs: {
                                        type: "button",
                                        "data-test-hook": "UpdateApparatusBtn"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.openUpdateApparatusNameAddress()
                                        }
                                      }
                                    },
                                    [_c("i", { staticClass: "fas fa-pencil" })]
                                  )
                                ])
                              ]),
                              _vm.subscriptionActive(
                                _vm.currentApparatusDetails.subscription
                              )
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "row detailsCardAction hoverPointer my-3 d-md-none align-items-center no-gutters",
                                      attrs: {
                                        "data-test-hook":
                                          "status-history-clickable-row-device-details"
                                      },
                                      on: { click: _vm.toStatusHistory }
                                    },
                                    [
                                      _c("div", { staticClass: "col ml-2" }, [
                                        _c(
                                          "h5",
                                          { staticClass: "headerInline" },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "fas fa-history colorOrange pr-2"
                                            }),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("xStatusHistory")
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      ]),
                                      _vm._m(2)
                                    ]
                                  )
                                : _vm._e(),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "row no-gutters align-items-center detailsCardAction hoverPointer my-3",
                                  attrs: {
                                    "data-test-hook":
                                      "run-hours-clickable-row-device-details"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.openModal(
                                        "RunHoursDetailsModal"
                                      )
                                    }
                                  }
                                },
                                [
                                  _c("div", { staticClass: "col ml-2" }, [
                                    _c(
                                      "h5",
                                      { staticClass: "headerInline mb-0 pb-0" },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "fas fa-shield-check colorOrange pr-2"
                                        }),
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("xHoursProtected")) +
                                            " "
                                        )
                                      ]
                                    )
                                  ]),
                                  _vm._m(3)
                                ]
                              ),
                              _vm.fuelLevel
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "row no-gutters my-4 align-items-center",
                                      attrs: {
                                        "data-test-hook":
                                          "fuel-level-clickable-row-device-details"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.$bvModal.show(
                                            "FuelLevelDetailsModal"
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("div", { staticClass: "col ml-2" }, [
                                        _c(
                                          "h5",
                                          {
                                            staticClass:
                                              "headerInline mb-0 pb-0"
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "fas fa-gas-pump colorOrange pr-2"
                                            }),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm._f("formatLabel")(
                                                    "xFuelLevel"
                                                  )
                                                ) +
                                                " "
                                            )
                                          ]
                                        ),
                                        _c("strong", { staticClass: "ml-1" }, [
                                          _vm._v(
                                            " " + _vm._s(_vm.fuelLevel) + "% "
                                          )
                                        ])
                                      ])
                                    ]
                                  )
                                : _vm._e(),
                              _vm.batteryVoltage
                                ? _c(
                                    "div",
                                    { staticClass: "row my-4 no-gutters" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "col-auto ml-2" },
                                        [
                                          _c(
                                            "h5",
                                            { staticClass: "headerInline" },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "fas fa-battery-bolt colorOrange pr-2"
                                              }),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm._f("formatLabel")(
                                                      "xBatteryVoltage"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                              _c(
                                                "strong",
                                                {
                                                  staticClass: "ml-1",
                                                  class: _vm.getBatteryVoltageClass(),
                                                  attrs: {
                                                    "data-test-hook":
                                                      "BatteryVoltage"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.batteryVoltage
                                                      ) +
                                                      _vm._s(
                                                        _vm.$t("xVoltageAbbr")
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "col float-right text-right mr-3"
                                        },
                                        [
                                          _c(
                                            "popper",
                                            {
                                              attrs: {
                                                trigger: "hover",
                                                options: { placement: "bottom" }
                                              }
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "popper" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "xYourBatteryLevelIs"
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                  _c("strong", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.getBatteryVoltageClassification()
                                                      ) + "."
                                                    )
                                                  ]),
                                                  _vm._v(
                                                    " ( " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "xValuesOf11.2VOrHigherAreGood"
                                                        )
                                                      ) +
                                                      " ) "
                                                  )
                                                ]
                                              ),
                                              _c("i", {
                                                staticClass:
                                                  "fas fa-question-circle hoverPointer",
                                                attrs: { slot: "reference" },
                                                slot: "reference"
                                              })
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ])
                          ])
                        ]
                      )
                    ])
                  : _vm.currentApparatusDetails.apparatusClassification ==
                    _vm.fuelTankApparatus
                  ? _c("div", { staticClass: "card" }, [
                      _c(
                        "div",
                        {
                          staticClass: "card-header",
                          class: _vm.currentApparatusDetails.isConnected
                            ? ""
                            : "statusNoSignal",
                          attrs: {
                            "background-color": _vm.currentApparatusDetails
                              .isConnected
                              ? "white"
                              : ""
                          }
                        },
                        [
                          _vm.currentApparatusDetails.isConnected
                            ? _c("div", [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "row w-100 align-items-center no-gutters"
                                  },
                                  [
                                    _vm.tankFuelLevel
                                      ? _c(
                                          "div",
                                          {
                                            staticClass: "col-auto float-left",
                                            attrs: { "text-right": "" }
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "fa-light fa-gauge-max mr-1"
                                            }),
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.tankFuelLevel) +
                                                "% "
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.tankFuelType
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "col-auto float-left ml-3"
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "fal fa-flame mr-2"
                                            }),
                                            _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  "text-transform": "capitalize"
                                                }
                                              },
                                              [_vm._v(_vm._s(_vm.tankFuelType))]
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ]
                                )
                              ])
                            : _c(
                                "div",
                                [
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "b-toggle",
                                          rawName:
                                            "v-b-toggle.fuelMonitorIssueBanner",
                                          modifiers: {
                                            fuelMonitorIssueBanner: true
                                          }
                                        }
                                      ],
                                      staticClass:
                                        "row noClickOutline details-header",
                                      class: _vm.getHeaderTextClass(),
                                      attrs: {
                                        "data-test-hook": "StatusHeader"
                                      }
                                    },
                                    [
                                      _c("div", { staticClass: "col" }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "row align-items-center no-gutters hoverPointer"
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "col-auto" },
                                              [
                                                _vm.currentApparatusDetails
                                                  .deviceType ==
                                                _vm.tankUtilityWifiDeviceType
                                                  ? _c("i", {
                                                      staticClass:
                                                        "fa-solid fa-wifi-slash"
                                                    })
                                                  : _c("i", {
                                                      staticClass:
                                                        "fa-solid fa-signal-bars-slash"
                                                    })
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "col ml-2" },
                                              [
                                                _c(
                                                  "h2",
                                                  {
                                                    staticClass:
                                                      "statusText mb-0",
                                                    class: _vm.getHeaderTextClass(),
                                                    attrs: {
                                                      "data-test-hook":
                                                        "StatusLabel"
                                                    }
                                                  },
                                                  [
                                                    _vm.currentApparatusDetails
                                                      .deviceType ==
                                                    _vm.tankUtilityWifiDeviceType
                                                      ? _c("span", [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "xNoWifiSignal"
                                                                )
                                                              ) +
                                                              " "
                                                          )
                                                        ])
                                                      : _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "xNoCellularSignal"
                                                              )
                                                            )
                                                          )
                                                        ])
                                                  ]
                                                )
                                              ]
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                staticClass:
                                                  "float-right when-closed",
                                                attrs: { cols: "auto" }
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "fas fa-lg fa-chevron-down"
                                                })
                                              ]
                                            ),
                                            _c(
                                              "b-col",
                                              {
                                                staticClass:
                                                  "float-right when-open",
                                                attrs: { cols: "auto" }
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "fas fa-lg fa-chevron-up"
                                                })
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ])
                                    ]
                                  ),
                                  _c(
                                    "b-collapse",
                                    {
                                      staticClass:
                                        "p-0 statusNoSignal statusCollapse",
                                      attrs: {
                                        visible: "",
                                        id: "fuelMonitorIssueBanner"
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "p-4",
                                          class: _vm.getHeaderTextClass()
                                        },
                                        [
                                          _c("div", { staticClass: "row" }, [
                                            _c("div", { staticClass: "col" }, [
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "status-date",
                                                  attrs: {
                                                    "data-test-hook": "LastSeen"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.displayLongDateTime(
                                                          _vm.tankLastReadingDate
                                                        )
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            ])
                                          ]),
                                          _c(
                                            "div",
                                            { staticClass: "row mt-2" },
                                            [
                                              _vm.currentApparatusDetails
                                                .deviceType !=
                                              _vm.tankUtilityWifiDeviceType
                                                ? _c(
                                                    "div",
                                                    { staticClass: "col" },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "xCellularTankDeviceNotCommunicating"
                                                            )
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  )
                                                : _c(
                                                    "div",
                                                    { staticClass: "col" },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "xWifiTankDeviceNotCommunicating"
                                                            )
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  )
                                            ]
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                        ]
                      ),
                      _c("div", { staticClass: "card-body row" }, [
                        _c("div", { staticClass: "col-12 col-lg-5" }, [
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col" }, [
                              _vm.tankOrientation == "horizontal"
                                ? _c("img", {
                                    staticClass: "tankImage img-fluid",
                                    attrs: {
                                      src: require("@/assets/images/tank-image.jpg"),
                                      alt: _vm.$t("xHeroImage"),
                                      "data-test-hook": "HeroImg"
                                    }
                                  })
                                : _c("img", {
                                    staticClass: "tankImage img-fluid",
                                    attrs: {
                                      src: require("@/assets/images/vertical-propane-tank.jpg"),
                                      alt: _vm.$t("xHeroImage"),
                                      "data-test-hook": "HeroImg"
                                    }
                                  })
                            ])
                          ]),
                          _c("div", { staticClass: "row mt-4" }, [
                            _c(
                              "div",
                              {
                                staticClass: "col-12",
                                attrs: {
                                  "data-test-hook":
                                    "apparatus-details-tu-fuel-capacity-label"
                                }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("xFuelCapacity")) +
                                    " (" +
                                    _vm._s(_vm.abbreviatedLiquidUom) +
                                    ") "
                                )
                              ]
                            ),
                            _c("div", { staticClass: "col-12" }, [
                              _c(
                                "strong",
                                {
                                  attrs: {
                                    "data-test-hook":
                                      "apparatus-details-tu-fuel-capacity"
                                  }
                                },
                                [_vm._v(_vm._s(_vm.tankCapacity))]
                              )
                            ])
                          ]),
                          _c("div", { staticClass: "row mt-3" }, [
                            _c(
                              "div",
                              {
                                staticClass: "col-12",
                                attrs: {
                                  "data-test-hook":
                                    "apparatus-details-tu-id-label"
                                }
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("xTankDeviceId")) + " "
                                )
                              ]
                            ),
                            _c("div", { staticClass: "col-12" }, [
                              _c(
                                "strong",
                                {
                                  attrs: {
                                    "data-test-hook": "apparatus-details-tu-id"
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.currentApparatusDetails.shortDeviceId
                                    )
                                  )
                                ]
                              )
                            ])
                          ])
                        ]),
                        _c("div", { staticClass: "col" }, [
                          _c("div", { staticClass: "row align-items-center" }, [
                            _c("div", { staticClass: "col" }, [
                              _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col-12" }, [
                                  _c(
                                    "h2",
                                    {
                                      attrs: {
                                        "data-test-hook": "ApparatusName"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.currentApparatusDetails.name)
                                      )
                                    ]
                                  )
                                ])
                              ]),
                              _c("div", { staticClass: "row" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "col-12",
                                    attrs: {
                                      "data-test-hook": "ApparatusAddress"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.DisplayAddress(
                                            _vm.currentApparatusDetails.address
                                          )
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ])
                            ]),
                            _c("div", { staticClass: "col-auto" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-icon-button",
                                  attrs: {
                                    type: "button",
                                    "data-test-hook": "UpdateApparatusBtn"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.openUpdateApparatusNameAddress()
                                    }
                                  }
                                },
                                [_c("i", { staticClass: "fas fa-pencil" })]
                              )
                            ])
                          ]),
                          _c("div", { staticClass: "row mt-3" }, [
                            _c(
                              "div",
                              {
                                staticClass: "col",
                                attrs: {
                                  "data-test-hook":
                                    "apparatus-details-tu-last-reading-date-time"
                                }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("formatLabel")("xLastReadingDate")
                                    ) +
                                    " "
                                ),
                                _c("strong", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.displayShortDateTimeWithTZ(
                                        _vm.tankLastReadingDate
                                      )
                                    )
                                  )
                                ])
                              ]
                            )
                          ]),
                          _c(
                            "div",
                            { staticClass: "row mt-3 align-items-center" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "col-auto",
                                  attrs: {
                                    "data-test-hook":
                                      "apparatus-details-tu-battery-level-label"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("xDeviceBattery")) +
                                      ": "
                                  )
                                ]
                              ),
                              _vm.tankBatteryLevel == "good"
                                ? _c(
                                    "div",
                                    { staticClass: "col tankBatteryLevelGood" },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "fa-solid fa-battery-full mr-2",
                                        attrs: {
                                          "data-test-hook":
                                            "apparatus-details-tu-battery-level-icon"
                                        }
                                      }),
                                      _c(
                                        "strong",
                                        {
                                          staticStyle: {
                                            "text-transform": "capitalize"
                                          },
                                          attrs: {
                                            "data-test-hook":
                                              "apparatus-details-tu-battery-level-text"
                                          }
                                        },
                                        [_vm._v(_vm._s(_vm.$t("xGood")))]
                                      )
                                    ]
                                  )
                                : _vm.tankBatteryLevel == "warning"
                                ? _c("div", { staticClass: "col" }, [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "tankBatteryLevelWarning px-2"
                                      },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "fa-solid fa-battery-half mr-2",
                                          attrs: {
                                            "data-test-hook":
                                              "apparatus-details-tu-battery-level-icon"
                                          }
                                        }),
                                        _c(
                                          "strong",
                                          {
                                            staticStyle: {
                                              "text-transform": "capitalize"
                                            },
                                            attrs: {
                                              "data-test-hook":
                                                "apparatus-details-tu-battery-level-text"
                                            }
                                          },
                                          [_vm._v(_vm._s(_vm.$t("xWarning")))]
                                        )
                                      ]
                                    )
                                  ])
                                : _c("div", { staticClass: "col" }, [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "tankBatteryLevelCritical px-2"
                                      },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "fa-solid fa-battery-exclamation mr-2",
                                          attrs: {
                                            "data-test-hook":
                                              "apparatus-details-tu-battery-level-icon"
                                          }
                                        }),
                                        _c(
                                          "strong",
                                          {
                                            staticStyle: {
                                              "text-transform": "capitalize"
                                            },
                                            attrs: {
                                              "data-test-hook":
                                                "apparatus-details-tu-battery-level-text"
                                            }
                                          },
                                          [_vm._v(_vm._s(_vm.$t("xCritical")))]
                                        )
                                      ]
                                    )
                                  ])
                            ]
                          )
                        ])
                      ])
                    ])
                  : _vm._e(),
                _vm.PortablesEnabledFF(_vm.currentAppConfig) &&
                _vm.currentApparatusDetails.apparatusClassification ==
                  _vm.portableGenApparatus
                  ? _c("div", { staticClass: "card" }, [
                      _c(
                        "div",
                        { staticClass: "card-header portable-details-header" },
                        [
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col" }, [
                              _c("h2", { staticClass: "mb-0" }, [
                                _vm._v(
                                  _vm._s(_vm.$t("xConnectViaBluetoothOnMobile"))
                                )
                              ])
                            ])
                          ])
                        ]
                      ),
                      _c("div", { staticClass: "card-body" }, [
                        _c("div", { staticClass: "col" }, [
                          _c("div", { staticClass: "row" }, [
                            _c(
                              "div",
                              { staticClass: "col-sm-12 col-md-4 col-lg-4" },
                              [
                                _c("img", {
                                  staticClass: "img-fluid",
                                  attrs: {
                                    src:
                                      _vm.currentApparatusDetails.heroImageUrl,
                                    alt: _vm.$t("xHeroImage"),
                                    "data-test-hook": "HeroImg"
                                  }
                                })
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "col-sm-12 col-md-8 col-lg-8 mt-3"
                              },
                              [
                                _c("div", { staticClass: "row" }, [
                                  _c("div", { staticClass: "col" }, [
                                    _c("p", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("formatLabelNoTranslation")(
                                            _vm.$t("xSerial#")
                                          )
                                        ) +
                                          " " +
                                          _vm._s(
                                            _vm.currentApparatusDetails
                                              .serialNumber
                                          )
                                      )
                                    ]),
                                    _c("h2", [
                                      _vm._v(
                                        _vm._s(_vm.currentApparatusDetails.name)
                                      )
                                    ])
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "col-auto float-right" },
                                    [
                                      _c(
                                        "button",
                                        {
                                          staticClass: "btn btn-icon-button",
                                          attrs: {
                                            type: "button",
                                            "data-test-hook":
                                              "UpdateApparatusBtn"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.openUpdateApparatusNameAddress()
                                            }
                                          }
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fas fa-pencil"
                                          })
                                        ]
                                      )
                                    ]
                                  )
                                ])
                              ]
                            )
                          ])
                        ])
                      ])
                    ])
                  : _vm._e()
              ]),
              _c("RunHoursDetailsModal", {
                attrs: {
                  standByHours: _vm.standByHours,
                  runHours: _vm.runHours,
                  activationDate: _vm.currentApparatusDetails.activationDate
                }
              })
            ],
            1
          ),
      _c(
        "Modal",
        { attrs: { modalId: "UpdateApparatusNameAddress" } },
        [
          _c("ModalHeader", {
            attrs: {
              title: _vm.$t("xEditInformation"),
              icon: "fas fa-address-card colorOrange mr-1",
              "data-test-hook": "ModalTitle"
            }
          }),
          _c("ModalBody", [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col" }, [
                _c(
                  "div",
                  {
                    staticClass: "form-group",
                    attrs: { id: "apparatusName-group" }
                  },
                  [
                    _c(
                      "label",
                      {
                        staticClass: "d-block",
                        attrs: {
                          for: "apparatusName",
                          "data-test-hook": "ApparatusNameHeader"
                        }
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("xApparatusName")) + " ")]
                    ),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.$v.apparatusName.$model,
                          expression: "$v.apparatusName.$model"
                        }
                      ],
                      staticClass: "form-control",
                      class: _vm.status(_vm.$v.apparatusName),
                      attrs: {
                        id: "apparatusName",
                        type: "text",
                        maxlength: "50",
                        "aria-describedby": "apparatusName-invalid",
                        "data-test-hook": "ApparatusNameValue"
                      },
                      domProps: { value: _vm.$v.apparatusName.$model },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.$v.apparatusName,
                            "$model",
                            $event.target.value
                          )
                        }
                      }
                    }),
                    _c(
                      "div",
                      {
                        staticClass: "invalid-feedback",
                        attrs: { id: "apparatusName-invalid" }
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("xRequiredField")) + " ")]
                    )
                  ]
                )
              ])
            ]),
            _vm.apparatus
              ? _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col" },
                    [
                      _c("TypeAheadAddress", {
                        ref: "typeAheadAddressDeviceDetails",
                        attrs: { currentAddress: _vm.apparatus.address },
                        on: { newAddress: _vm.setCurrentAddress }
                      })
                    ],
                    1
                  )
                ])
              : _vm._e()
          ]),
          _c("ModalFooter", [
            _c(
              "button",
              {
                staticClass: "btn btn-outline-warning",
                attrs: { type: "button", "data-test-hook": "CancelBtn" },
                on: {
                  click: function($event) {
                    return _vm.closeUpdateApparatusNameAddress()
                  }
                }
              },
              [_vm._v(" " + _vm._s(_vm.$t("xCancel")) + " ")]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-warning",
                attrs: {
                  type: "button",
                  disabled: _vm.updatingApparatusInfo,
                  "data-test-hook": "UpdateBtn"
                },
                on: {
                  click: function($event) {
                    return _vm.UpdateApparatusInfo()
                  }
                }
              },
              [
                _vm._v(" " + _vm._s(_vm.$t("xUpdate")) + " "),
                _vm.updatingApparatusInfo
                  ? _c("span", {
                      staticClass: "spinner-border spinner-border-sm"
                    })
                  : _vm._e()
              ]
            )
          ])
        ],
        1
      ),
      _c(
        "Modal",
        { attrs: { modalId: "clearMaintenanceModal", size: "modal-md" } },
        [
          _c(
            "ModalHeader",
            {
              attrs: {
                title: _vm.$t("xAreYouSure"),
                icon: "fas fa-exclamation-triangle colorOrange"
              }
            },
            [
              _c("div", { staticClass: "col-auto float-right" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-icon-button btn-icon-button--gray",
                    attrs: {
                      type: "button",
                      "data-test-hook":
                        "x-button-clearMaintenanceModal-device-details"
                    },
                    on: {
                      click: function($event) {
                        return _vm.closeModal("clearMaintenanceModal")
                      }
                    }
                  },
                  [_c("i", { staticClass: "fas fa-times" })]
                )
              ])
            ]
          ),
          _c("ModalBody", [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col" }, [
                _vm._v(" " + _vm._s(_vm.$t("xClearMaintenanceModalP1")) + " ")
              ])
            ]),
            _c("div", { staticClass: "row mt-2" }, [
              _c("div", { staticClass: "col" }, [
                _vm._v(" " + _vm._s(_vm.$t("xClearMaintenanceModalP2")) + " ")
              ])
            ]),
            _c("div", { staticClass: "row mt-3" }, [
              _c("div", { staticClass: "col" }, [
                _c("strong", [
                  _vm._v(_vm._s(_vm.$t("xClearCommand15MinuteMessage")))
                ])
              ])
            ])
          ]),
          _c("ModalFooter", [
            _c(
              "button",
              {
                staticClass: "btn btn-warning",
                attrs: {
                  type: "button",
                  "data-test-hook":
                    "yes-im-sure-button-clearMaintenanceModal-device-details"
                },
                on: {
                  click: function($event) {
                    _vm.closeModal("clearMaintenanceModal"),
                      _vm.openModal("addMaintenanceModal"),
                      _vm.trackAppInsightsEvent(
                        "user-is-clearing-maintenance",
                        {
                          page: "device details",
                          organizationId: _vm.accountProfile.organizationId,
                          userId: _vm.user.userId,
                          apparatusId: _vm.currentApparatusDetails.apparatusId
                        }
                      )
                  }
                }
              },
              [_vm._v(" " + _vm._s(_vm.$t("xYesImSure")) + " ")]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-outline-warning",
                attrs: {
                  type: "button",
                  "data-test-hook":
                    "no-cancel-button-clearMaintenanceModal-device-details"
                },
                on: {
                  click: function($event) {
                    return _vm.closeModal("clearMaintenanceModal")
                  }
                }
              },
              [_vm._v(" " + _vm._s(_vm.$t("xNoCancel")) + " ")]
            )
          ])
        ],
        1
      ),
      _c("AddMaintenanceModal", {
        attrs: {
          editedMaintenanceRecord: null,
          disableCancel: true,
          editingRecord: false,
          sendClearMaintenance: true,
          apparatus: _vm.currentApparatusDetails
        }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-auto" }, [
      _c(
        "button",
        { staticClass: "btn btn-outline", attrs: { type: "button" } },
        [_c("i", { staticClass: "fas fa-lg fa-chevron-right" })]
      )
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-auto" }, [
      _c(
        "button",
        { staticClass: "btn btn-outline", attrs: { type: "button" } },
        [_c("i", { staticClass: "fas fa-arrow-alt-square-up" })]
      )
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-auto" }, [
      _c(
        "button",
        { staticClass: "btn btn-outline", attrs: { type: "button" } },
        [_c("i", { staticClass: "fas fa-lg fa-chevron-right" })]
      )
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-auto" }, [
      _c(
        "button",
        { staticClass: "btn btn-outline", attrs: { type: "button" } },
        [_c("i", { staticClass: "fas fa-arrow-alt-square-up" })]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }