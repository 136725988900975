var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.isLoading
        ? _c(
            "div",
            { staticClass: "d-flex justify-content-center mb-3 pt-4" },
            [
              _c("b-spinner", {
                staticStyle: { width: "5rem", height: "5rem" },
                attrs: { variant: "warning", label: "Spinning" }
              })
            ],
            1
          )
        : _c(
            "div",
            [
              _c(
                "b-row",
                { staticClass: "pt-1", attrs: { "align-v": "center" } },
                [
                  _c("b-col", [
                    _c("h1", { staticClass: "mb-0" }, [_vm._v("All Products")])
                  ]),
                  _c(
                    "b-col",
                    [
                      _vm.apparatuses.length > 0 && _vm.accountProfile
                        ? _c(
                            "b-dropdown",
                            {
                              staticClass: "my-0 float-right",
                              attrs: {
                                variant: "link",
                                right: "",
                                "no-caret": "",
                                "data-test-hook":
                                  "enrollment-dropdown-dashboard",
                                disabled:
                                  _vm.currentAppConfig &&
                                  _vm.currentAppConfig.featureFlags &&
                                  _vm.currentAppConfig.featureFlags[
                                    _vm.billingMigrationFeatureFlag
                                  ]
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "button-content",
                                    fn: function() {
                                      return [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "d-flex justify-content-center align-items-center add-product-button",
                                            attrs: {
                                              "data-test-hook":
                                                "dashboard-add-device-menu-button"
                                            }
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "fas fa-circle-plus fa-lg my-2 p-0 mr-2"
                                            }),
                                            _c("p", { staticClass: "mb-0" }, [
                                              _vm._v(
                                                _vm._s(_vm.$t("xAddProduct"))
                                              )
                                            ])
                                          ]
                                        )
                                      ]
                                    },
                                    proxy: true
                                  }
                                ],
                                null,
                                false,
                                3762539860
                              )
                            },
                            [
                              _c(
                                "b-dropdown-item",
                                {
                                  attrs: {
                                    "data-test-hook":
                                      "dashboard-add-device-menu-add-gen-button"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.beginEnrollmentFlow()
                                    }
                                  }
                                },
                                [
                                  _c("i", {
                                    staticClass: "fas fa-plus fa-lg colorOrange"
                                  }),
                                  _vm._v(" " + _vm._s(_vm.$t("xAddAGen")) + " ")
                                ]
                              ),
                              _vm.PortablesEnabledFF(_vm.currentAppConfig)
                                ? _c("b-dropdown-divider")
                                : _vm._e(),
                              _vm.PortablesEnabledFF(_vm.currentAppConfig)
                                ? _c(
                                    "b-dropdown-item",
                                    {
                                      attrs: {
                                        "data-test-hook":
                                          "dashboard-add-device-menu-add-portable-button"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.beginPortableGenEnrollmentFlow()
                                        }
                                      }
                                    },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "fas fa-plus fa-lg colorOrange"
                                      }),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("xAddPortableGenerator")
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _c("b-dropdown-divider"),
                              _c(
                                "b-dropdown-item",
                                {
                                  attrs: {
                                    "data-test-hook":
                                      "dashboard-add-device-menu-add-tank-button"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.beginTankEnrollmentFlow()
                                    }
                                  }
                                },
                                [
                                  _c("i", {
                                    staticClass: "fas fa-plus fa-lg colorOrange"
                                  }),
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("xAddTankMonitor")) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.cdmaWarning
                ? _c(
                    "div",
                    {
                      on: {
                        click: function($event) {
                          return _vm.trackAppInsightsEvent("cdma-banner-click")
                        }
                      }
                    },
                    [
                      _c(
                        "b-link",
                        {
                          staticStyle: { "text-decoration": "none !important" },
                          attrs: {
                            href: _vm.cdmaWarning.link,
                            target: "_blank"
                          }
                        },
                        [
                          _c(
                            "b-alert",
                            {
                              staticClass: "hoverPointer",
                              attrs: { variant: "warning", show: "" }
                            },
                            [
                              _c(
                                "b-row",
                                [
                                  _c("b-col", [
                                    _c("h2", [
                                      _c("i", {
                                        staticClass:
                                          "fas fa-exclamation-triangle banner-category-warning"
                                      }),
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.cdmaWarning.title) +
                                          " "
                                      )
                                    ]),
                                    _c("p", [
                                      _vm._v(_vm._s(_vm.cdmaWarning.message))
                                    ]),
                                    _c("p", { staticClass: "mb-0 pb-0" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.cdmaWarning.linkText) +
                                          " "
                                      ),
                                      _c("i", {
                                        staticClass: "fas fa-arrow-right"
                                      })
                                    ])
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.showRefreshSuccessBanner
                ? _c(
                    "b-alert",
                    {
                      attrs: {
                        variant: "success",
                        dismissible: "",
                        show: _vm.refreshSuccessCountdown
                      },
                      on: {
                        dismissed: function($event) {
                          _vm.showRefreshSuccessBanner = false
                        }
                      }
                    },
                    [
                      _c("i", { staticClass: "fas fa-check-circle fa-lg" }),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("xYouSuccessfullyLoggedIntoTankUtility")
                          ) +
                          " "
                      )
                    ]
                  )
                : _vm._e(),
              _vm.currentAppConfig &&
              _vm.currentAppConfig.featureFlags &&
              _vm.currentAppConfig.featureFlags[_vm.billingMigrationFeatureFlag]
                ? _c("b-alert", { attrs: { show: "", variant: "warning" } }, [
                    _vm._v(
                      " " + _vm._s(_vm.$t("xBillingMaintenanceMessage")) + " "
                    )
                  ])
                : _vm._e(),
              _c(
                "div",
                {
                  on: {
                    click: function($event) {
                      return _vm.$bvModal.show("banner-message-modal")
                    }
                  }
                },
                [
                  _vm.showBannerMessage(_vm.bannerMessage)
                    ? _c(
                        "b-alert",
                        {
                          staticClass: "hoverPointer",
                          attrs: {
                            show: "",
                            variant: _vm.getBannerVariant(_vm.bannerMessage)
                          }
                        },
                        [
                          _c("i", {
                            class: _vm.getBannerIconClassAndColor(
                              _vm.bannerMessage
                            )
                          }),
                          _vm._v(
                            " " + _vm._s(_vm.bannerMessage.BannerMessage) + " "
                          ),
                          _c("strong", [
                            _vm._v(" " + _vm._s(_vm.$t("xLearnMore")) + " "),
                            _c("i", { staticClass: "fas fa-arrow-right" })
                          ])
                        ]
                      )
                    : _vm._e()
                ],
                1
              ),
              _vm.apparatuses.length == 0
                ? _c(
                    "div",
                    [
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { staticClass: "pt-3", attrs: { cols: "12" } },
                            [
                              _c("h2", [
                                _vm._v(_vm._s(_vm.$t("xLetsGetStarted")))
                              ])
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        { attrs: { "align-v": "stretch" } },
                        [
                          _c(
                            "b-col",
                            { attrs: { cols: "12", md: "6", lg: "4" } },
                            [
                              _c(
                                "b-card",
                                {
                                  staticClass:
                                    "card-shadow add-generator-card hoverPointer mb-3",
                                  attrs: {
                                    "data-test-hook":
                                      "dashboard-add-generator-button"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.beginEnrollmentFlow()
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "b-row",
                                    { attrs: { "align-v": "center" } },
                                    [
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: "mr-0 pr-0",
                                          attrs: { cols: "2" }
                                        },
                                        [
                                          _c("b-img", {
                                            staticClass:
                                              "generator-success-img",
                                            attrs: {
                                              width: 200,
                                              fluid: "",
                                              src: require("@/assets/images/enroll-a-generator.svg")
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        {
                                          staticClass:
                                            "d-flex align-items-center"
                                        },
                                        [
                                          _c("h3", [
                                            _c("i", {
                                              staticClass:
                                                "colorOrange fas fa-plus mr-2"
                                            })
                                          ]),
                                          _c("h3", { staticClass: "mb-0" }, [
                                            _vm._v(_vm._s(_vm.$t("xAddAGen")))
                                          ])
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm.PortablesEnabledFF(_vm.currentAppConfig)
                            ? _c(
                                "b-col",
                                { attrs: { cols: "12", md: "6", lg: "4" } },
                                [
                                  _c(
                                    "b-card",
                                    {
                                      staticClass:
                                        "card-shadow add-generator-card hoverPointer mb-3",
                                      attrs: {
                                        "data-test-hook":
                                          "dashboard-add-portable-button"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.beginPortableGenEnrollmentFlow()
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "b-row",
                                        { attrs: { "align-v": "center" } },
                                        [
                                          _c(
                                            "b-col",
                                            {
                                              staticClass: "mr-0 pr-0",
                                              attrs: { cols: "2" }
                                            },
                                            [
                                              _c("b-img", {
                                                staticClass:
                                                  "generator-success-img",
                                                attrs: {
                                                  width: 200,
                                                  fluid: "",
                                                  src: require("@/assets/images/enroll-a-portable-gen.svg")
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-col",
                                            {
                                              staticClass:
                                                "d-flex align-items-center"
                                            },
                                            [
                                              _c("h3", [
                                                _c("i", {
                                                  staticClass:
                                                    "colorOrange fas fa-plus mr-2"
                                                })
                                              ]),
                                              _c(
                                                "h3",
                                                { staticClass: "mb-0" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "xAddPortableGenerator"
                                                      )
                                                    )
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "b-col",
                            { attrs: { cols: "12", md: "6", lg: "4" } },
                            [
                              _c(
                                "b-card",
                                {
                                  staticClass:
                                    "card-shadow add-generator-card hoverPointer mb-3",
                                  attrs: {
                                    "data-test-hook":
                                      "dashboard-add-tank-button"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.beginTankEnrollmentFlow()
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "b-row",
                                    { attrs: { "align-v": "center" } },
                                    [
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: "mr-0 pr-0",
                                          attrs: { cols: "2" }
                                        },
                                        [
                                          _c("b-img", {
                                            staticClass:
                                              "generator-success-img",
                                            attrs: {
                                              width: 200,
                                              fluid: "",
                                              src: require("@/assets/images/enroll-a-tank.svg")
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        {
                                          staticClass:
                                            "d-flex align-items-center"
                                        },
                                        [
                                          _c("h3", [
                                            _c("i", {
                                              staticClass:
                                                "colorOrange fas fa-plus mr-2"
                                            })
                                          ]),
                                          _c("h3", { staticClass: "mb-0" }, [
                                            _vm._v(
                                              _vm._s(_vm.$t("xAddTankMonitor"))
                                            )
                                          ])
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _c(
                    "b-row",
                    { staticClass: "px-2" },
                    _vm._l(_vm.apparatuses, function(apparatus) {
                      return _c(
                        "b-col",
                        {
                          key: apparatus.id,
                          attrs: {
                            cols: "12",
                            md: "6",
                            lg: "6",
                            xl: "4",
                            "align-v": "stretch"
                          }
                        },
                        [
                          apparatus.type == _vm.Constants.HsbApparatusType
                            ? _c("ApparatusCard", {
                                staticClass: "dashboard-card",
                                attrs: {
                                  apparatusInfo: apparatus,
                                  paymentInfo: _vm.existingPaymentInfo,
                                  organizationSubscriptionDetailsData:
                                    _vm.organizationSubscriptionDetailsData,
                                  "data-test-hook": "dashboard-generator-card"
                                },
                                on: {
                                  incompleteApparatus: _vm.incompleteApparatus
                                }
                              })
                            : _vm._e(),
                          apparatus.type == _vm.Constants.FuelTankApparatusType
                            ? _c("TankUtilityCard", {
                                staticClass: "dashboard-card",
                                attrs: {
                                  apparatusInfo: apparatus,
                                  paymentInfo: _vm.existingPaymentInfo,
                                  organizationSubscriptionDetailsData:
                                    _vm.organizationSubscriptionDetailsData,
                                  "data-test-hook": "dashboard-tank-card"
                                },
                                on: {
                                  missingProperties:
                                    _vm.tankEnrollmentMissingProperties,
                                  noSubscription:
                                    _vm.tankEnrollmentNoSubscription
                                }
                              })
                            : _vm._e(),
                          apparatus.pending == true
                            ? _c("PendingCard", {
                                staticClass: "dashboard-card",
                                attrs: {
                                  apparatusType: apparatus.apparatusType,
                                  "data-test-hook": "dashboard-pending-card"
                                }
                              })
                            : _vm._e(),
                          apparatus.type ==
                          _vm.Constants.PortableGeneratorApparatusType
                            ? _c("PortableGenCard", {
                                staticClass: "dashboard-card",
                                attrs: {
                                  apparatusInfo: apparatus,
                                  "data-test-hook": "dashboard-portable-card"
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    }),
                    1
                  )
            ],
            1
          ),
      _vm.accountProfile
        ? _c("EnrollmentModals", {
            attrs: {
              apparatusId: _vm.apparatusIdForEnrollment,
              modelNumber: _vm.apparatusModelNumberForEnrollment
            }
          })
        : _vm._e(),
      _c("TankUtilityEnrollmentModals", {
        attrs: {
          enrollmentNoSubscription: _vm.enrollmentNoSubscription,
          deviceType: _vm.tankDeviceType,
          apparatusId: _vm.tankApparatusId
        }
      }),
      _vm.featureAnnouncement
        ? _c("NewAnnouncementModal", {
            attrs: { announcement: _vm.featureAnnouncement }
          })
        : _vm._e(),
      _c("PortablesEnrollmentModals"),
      _c(
        "b-modal",
        {
          ref: "cdma-remind-me",
          attrs: {
            id: "cdmaRemindMessage",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            "ok-disabled": true,
            "hide-footer": "",
            centered: ""
          },
          scopedSlots: _vm._u([
            {
              key: "modal-header",
              fn: function() {
                return [
                  _c(
                    "b-row",
                    {
                      staticClass: "w-100",
                      attrs: { "align-v": "center", "no-gutters": "" }
                    },
                    [
                      _c("b-col", [
                        _c("h2", { staticClass: "modal-title" }, [
                          _c("i", {
                            staticClass:
                              "fas fa-exclamation-triangle colorOrange mr-1"
                          }),
                          _vm._v(
                            " " + _vm._s(_vm.$t("xCDMADeviceSupport")) + " "
                          )
                        ])
                      ])
                    ],
                    1
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c(
            "b-row",
            [
              _c("b-col", { attrs: { "align-v": "center" } }, [
                _c("h5", { staticClass: "headerInline" }, [
                  _c("h5", [
                    _c("i", { staticClass: "fas fa-sm" }),
                    _vm._v(_vm._s(_vm.$t("xMobileLinkLoseCellular")))
                  ])
                ])
              ])
            ],
            1
          ),
          _c("br"),
          _c(
            "b-row",
            { attrs: { "align-v": "center" } },
            [
              _c(
                "b-col",
                { attrs: { "align-v": "center" } },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "feedback-button",
                      attrs: { variant: "outline-warning", block: "" },
                      on: {
                        click: function($event) {
                          return _vm.cdmaRemindMeLater()
                        }
                      }
                    },
                    [
                      _c("i", {
                        staticClass: "fas fa-alarm-snooze fa-sm colorOrange"
                      }),
                      _vm._v(
                        " Remind me in " +
                          _vm._s(
                            _vm.currentAppConfig != null
                              ? this.currentAppConfig.configurations[
                                  _vm.Constants.CdmaUpdateRemindMeDays
                                ]
                              : "30"
                          ) +
                          " days "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("br"),
          _c(
            "b-row",
            { attrs: { "align-v": "center" } },
            [
              _c(
                "b-col",
                { attrs: { "align-v": "center" } },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "feedback-button",
                      attrs: {
                        block: "",
                        variant: "warning",
                        href:
                          "https://support.generac.com/s/article/How-Do-I-Upgrade-My-CDMA-Device-to-the-LTE-Device",
                        target: "_blank"
                      },
                      on: {
                        click: function($event) {
                          return _vm.cdmaUpgradeLearnMoreDate()
                        }
                      }
                    },
                    [
                      _c("i", { staticClass: "fas fa-external-link" }),
                      _vm._v(" " + _vm._s(_vm.$t("xLearnMore")) + " ")
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            size: "md",
            id: "has-subscription-from-other-platform",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            centered: ""
          },
          scopedSlots: _vm._u([
            {
              key: "modal-header",
              fn: function() {
                return [
                  _c(
                    "b-row",
                    {
                      staticClass: "w-100 mb-n3 mt-n2 pl-2",
                      attrs: { "align-v": "center", "no-gutters": "" }
                    },
                    [
                      _c("b-col", [
                        _c("h2", { staticClass: "modal-title" }, [
                          _c("i", {
                            staticClass:
                              "far fa-exclamation-triangle colorOrange mr-2"
                          }),
                          _c("strong", [
                            _vm._v(_vm._s(_vm.$t("xActionRequired")))
                          ])
                        ])
                      ]),
                      _c(
                        "b-col",
                        {
                          staticClass: "float-right mr-n3",
                          attrs: { cols: "auto" }
                        },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "btn-icon-button--gray",
                              attrs: { variant: "icon-button" },
                              on: {
                                click: function($event) {
                                  return _vm.$bvModal.hide(
                                    "has-subscription-from-other-platform"
                                  )
                                }
                              }
                            },
                            [_c("i", { staticClass: "fas fa-times" })]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              },
              proxy: true
            },
            {
              key: "modal-footer",
              fn: function() {
                return [
                  _c(
                    "b-row",
                    { attrs: { "align-v": "center" } },
                    [
                      _c(
                        "b-col",
                        {
                          staticClass: "pt-0 mr-n3 mb-n2 mt-n2",
                          attrs: { cols: "auto" }
                        },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "feedback-button",
                              attrs: { variant: "warning" },
                              on: {
                                click: function($event) {
                                  return _vm.$bvModal.hide(
                                    "has-subscription-from-other-platform"
                                  )
                                }
                              }
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("xDone")) + " ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c(
            "b-row",
            [
              _vm.subscriptionTypeApple()
                ? _c("b-col", [
                    _vm._v(" " + _vm._s(_vm.$t("xAppleSubscriptionText")) + " ")
                  ])
                : _vm.subscriptionTypeGoogle()
                ? _c("b-col", [
                    _vm._v(
                      " " + _vm._s(_vm.$t("xAndroidSubscriptionText")) + " "
                    )
                  ])
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }