<template>
  <Modal modalId="RunHoursDetailsModal" size="modal-md">
    <ModalHeader :title="$t('xHoursProtected')" icon="fas fa-shield-check colorOrange mr-1">
      <div class="cols-auto float-right">
        <button
          type="button"
          class="btn btn-icon-button--gray btn-icon-button"
          @click="closeModal('RunHoursDetailsModal')"
          data-test-hook="x-button-run-hours-details"
        >
          <i class="fas fa-times" />
        </button>
      </div>
    </ModalHeader>
    <ModalBody>
      <div class="row">
        <div class="col">
          <h6 data-test-hook="ProtectedSince">
            <i class="far fa-fw fa-shield-check colorOrange"></i>
            {{ 'xProtectedSince' | formatLabel }}
          </h6>
          <strong>{{ activationDate ? $moment.utc(activationDate).format($t('xShortDate')) : 'N/A' }}</strong>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col">
          <h6 data-test-hook="HoursOfProtectionLabel">
            <i class="far fa-fw fa-clock colorOrange"></i>
            {{ $t('xTotalHoursOfProtection') }}
          </h6>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <strong v-if="standByHours && standByHours <= 96" data-test-hook="HoursOfProtection"
            >{{ standByHours ? standByHours : 0 }} {{ $t('xHours') }}</strong
          >
          <strong v-else-if="standByHours" data-test-hook="HoursOfProtection">
            {{ Math.round((standByHours ? standByHours : 0) / 24).toLocaleString() }}
            {{ $t('xDays') }}
          </strong>
          <strong v-else data-test-hook="HoursOfProtection">N/A</strong>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col">
          <h6 data-test-hook="RunHoursLabel">
            <i class="far fa-fw fa-hourglass-clock colorOrange"></i>
            {{ $t('xRunHours') }}
          </h6>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <strong data-test-hook="RunHours">{{ (runHours ? runHours : 0).toLocaleString() }} {{ $t('xHours') }}</strong>
        </div>
      </div>
    </ModalBody>
  </Modal>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  components: {},
  props: ['standByHours', 'runHours', 'activationDate'],
  data() {
    return {}
  }
}
</script>
