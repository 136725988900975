export const state = {
  currentRoute: null
}
export const getters = {
  currentRoute: state => state.currentRoute,
  currentRouteName: state => {
    if (state.currentRoute) {
      return state.currentRoute.name
    }
    return 'default'
  }
}
export const mutations = {
  setCurrentRoute(state, route) {
    state.currentRoute = route
  }
}
export default {
  state,
  getters,
  mutations
}
