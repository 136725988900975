var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        id: "announcement-modal",
        centered: "",
        "hide-footer": "",
        size: "md"
      },
      on: {
        hidden: function($event) {
          return _vm.closeModal()
        }
      },
      scopedSlots: _vm._u([
        {
          key: "modal-header",
          fn: function() {
            return [
              _c(
                "b-row",
                {
                  staticClass: "w-100",
                  attrs: { "align-v": "center", "no-gutters": "" }
                },
                [
                  _c("b-col", [
                    _c("h2", { staticClass: "modal-title" }, [
                      _c("i", {
                        staticClass: "fas fa-certificate colorOrange mr-1"
                      }),
                      _vm._v(" " + _vm._s(_vm.announcement.modalHeader) + " ")
                    ])
                  ]),
                  _c(
                    "b-col",
                    { staticClass: "float-right", attrs: { cols: "auto" } },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "btn-icon-button",
                          attrs: {
                            variant: "icon-button",
                            "data-test-hook": "x-button-new-announcement"
                          },
                          on: {
                            click: function($event) {
                              return _vm.closeModal()
                            }
                          }
                        },
                        [_c("i", { staticClass: "fas fa-times" })]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "b-row",
        { staticClass: "text-center" },
        [
          _c("b-col", [
            _c("h3", { staticClass: "colorOrange" }, [
              _vm._v(_vm._s(_vm.announcement.featureHeader))
            ])
          ])
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "text-center" },
        [
          _c("b-col", [
            _vm._v(" " + _vm._s(_vm.announcement.featureDescription) + " ")
          ])
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mt-3", attrs: { "no-gutters": "" } },
        [
          _c(
            "b-col",
            { staticClass: "checkbox-row" },
            [
              _c(
                "b-form-checkbox",
                {
                  staticClass: "font-weight-bold",
                  attrs: {
                    "data-test-hook":
                      "dont-show-again-checkbox-new-announcement"
                  },
                  model: {
                    value: _vm.dontShowAgain,
                    callback: function($$v) {
                      _vm.dontShowAgain = $$v
                    },
                    expression: "dontShowAgain"
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("xDontShowThisAgain")) + " ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mt-3" },
        [
          _c(
            "b-col",
            [
              _c(
                "b-button",
                {
                  attrs: {
                    variant: "outline-warning",
                    block: "",
                    "data-test-hook": "dismiss-button-new-announcement"
                  },
                  on: {
                    click: function($event) {
                      return _vm.closeModal()
                    }
                  }
                },
                [
                  _vm._v(" " + _vm._s(_vm.$t("xDismiss")) + " "),
                  _vm.savingSetting
                    ? _c("b-spinner", { staticClass: "ml-2" })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mt-2" },
        [
          _c(
            "b-col",
            [
              _c(
                "b-link",
                {
                  staticClass: "white-text",
                  attrs: {
                    href: _vm.announcement.featureInfoUrl,
                    target: "_blank"
                  }
                },
                [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        variant: "warning",
                        block: "",
                        "data-test-hook": "learn-more-new-announcement"
                      },
                      on: {
                        click: function($event) {
                          _vm.learningMore(),
                            (_vm.dontShowAgain = true),
                            _vm.closeModal()
                        }
                      }
                    },
                    [
                      _c("i", { staticClass: "fas fa-external-link-alt" }),
                      _vm._v(" " + _vm._s(_vm.$t("xLearnMore")) + " ")
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }