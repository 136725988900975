<template>
  <div class="mt-2 ml-2">
    <div class="row no-gutters mb-2">
      <div class="col">
        <h1 class="mb-0 pb-0">
          <i class="far fa-meter-droplet colorOrange mr-1" data-test-hook="fuel-consumption-page-title-icon" />
          <span data-test-hook="fuel-consumption-page-title-text">{{ $t('xFuelConsumption') }}</span>
        </h1>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col">
            <div class="row">
              <div class="col">
                <h3 data-test-hook="fuelUsageListHeader">{{ 'xDevicesThatUseFuelFromThisTank' | formatLabel }}</h3>
              </div>
            </div>
            <div v-if="!hasSelectedFuelUses" class="row">
              <div class="col">
                <span data-test-hook="fuel-consumption-no-devices-selected">{{ $t('xNoDevicesSelected') }}</span>
              </div>
            </div>
          </div>
          <div class="cols-auto float-right">
            <button
              type="button"
              class="btn btn-icon-button"
              @click="openModal('edit-fuel-consumption-modal')"
              data-test-hook="fuel-consumption-edit-devices-button"
            >
              <i class="fas fa-pencil" data-test-hook="fuel-consumption-edit-devices-button-icon" />
            </button>
          </div>
        </div>
        <ul
          v-for="(type, index) in selectedTankUtilFuelUses"
          :key="index"
          :data-test-hook="'FuelUse-' + removeExtraChars($t(type.name))"
        >
          <li
            :id="index"
            v-if="type.selected"
            :data-test-hook="'FuelUse-' + removeExtraChars($t(type.name)) + '-isVisible'"
          >
            {{ $t(type.name) }}
          </li>
        </ul>
      </div>
    </div>
    <EditFuelConsumptionModal />
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import * as Constants from '@/constants'
import EditFuelConsumptionModal from '@/components/ApparatusDetails/EditFuelConsumptionModal'

export default {
  components: { EditFuelConsumptionModal },
  data() {
    return {
      selectedTankUtilFuelUses: null,
      hasSelectedFuelUses: false
    }
  },
  computed: {
    ...mapState({
      currentApparatusDetails: state => state.apparatus.currentApparatusDetails
    })
  },
  mounted() {
    this.loadFuelUses()
  },
  methods: {
    getVariant(selected) {
      if (selected) return 'warning'
      else return 'outline-warning'
    },
    async loadFuelUses() {
      let consumptionTypes = null
      await this.$store
        .dispatch('getTankUtilityConsumptionTypes')
        .then(response => {
          if (response.status == 200) {
            consumptionTypes = response.data
          }
        })
        .catch(error => {
          this.validateError = error.response.data
          this.$store.dispatch('displayToast', {
            title: this.$t('xError'),
            message: this.validateError.message,
            variant: 'danger'
          })
        })

      consumptionTypes.forEach(element => (element.selected = false))

      this.selectedTankUtilFuelUses = null
      this.selectedTankUtilFuelUses = _.cloneDeep(consumptionTypes)
      let tuPropertyFuelUses = this.currentApparatusDetails.tuProperties
        .filter(p => p.type == Constants.TuSnmpTypeConsumptionTypes)[0]
        .value.split(',')

      if (tuPropertyFuelUses) {
        this.selectedTankUtilFuelUses.forEach(fu =>
          tuPropertyFuelUses.includes(fu.type) ? (fu.selected = true) : (fu.selected = false)
        )
      }
      let hasASelection = this.selectedTankUtilFuelUses.filter(x => x.selected)
      if (hasASelection && hasASelection.length > 0) this.hasSelectedFuelUses = true
      else this.hasSelectedFuelUses = false
    },
    removeExtraChars(testhook) {
      return testhook
        .split(' ')
        .join('')
        .split('/')
        .join('')
    }
  },
  watch: {
    currentApparatusDetails: function(newVal, oldVal) {
      if (newVal) {
        this.loadFuelUses()
      }
    }
  }
}
</script>
