import router from '@/router/'
import i18n from '@/lang/'
import Axios from 'axios'
import { vueAppApiBaseAddress } from '@/utils/mixins'

var initialState = {}

export const state = _.cloneDeep(initialState)

export const getters = {}

export const mutations = {}

export const actions = {
  async saveQrScan({ commit }, request) {
    return await Axios.post(vueAppApiBaseAddress() + `v2/Mobile/qrScan`, request, {
      withCredentials: true
    })
  },
  async GetQrScan({ commit }, ipAddress) {
    return await Axios.get(vueAppApiBaseAddress() + `v2/Mobile/qrScan/${ipAddress}`, {
      withCredentials: true
    })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
