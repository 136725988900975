<template>
  <div>
    <div class="card pending-change-card no-border" data-test-hook="PendingPlanChangeCard">
      <div class="card-body ml-3">
        <div class="row">
          <div class="col" style="padding-left: 0px;">
            <h4 v-if="pendingCancellation()" class="pending-title-color" data-test-hook="NewPlanCardTitle">
              {{ $t('xPendingCancellation') }}
            </h4>
            <h4 v-else class="pending-title-color" data-test-hook="NewPlanCardTitle">
              {{ $t('xPendingSubscriptionChanges') }}
            </h4>
          </div>
        </div>
        <div v-if="pendingFreeToPaidOrPaidToPaid()">
          <div class="row align-items-center">
            <div class="cols-auto">
              <div class="row mb-0 pb-0">
                <div class="col pending-labels ml-3 mb-0 pb-0" data-test-hook="NewPlanTypeLabel">
                  {{ $t('xNewPlanType') }}
                </div>
              </div>
              <div class="row mb-2">
                <div class="col ml-3">
                  <strong data-test-hook="NewPlanType">{{ pendingSubscription.productName }}</strong>
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-0 pb-0" data-test-hook="NewPlanStartDateLabel">
            <div class="col pending-labels mb-0 pb-0">
              {{ $t('xPlanStartDate') }}
            </div>
          </div>
          <div class="row mb-2">
            <div class="col">
              <strong data-test-hook="NewPlanStartDate">
                {{ $moment(pendingSubscription.effectiveDate).format($t('xShortDate')) }}
              </strong>
            </div>
          </div>
          <div class="row mb-0 pb-0">
            <div class="col pending-labels mb-0 pb-0" data-test-hook="NewPlanAmountLabel">
              {{ $t('xNewPlanAmount') }}
            </div>
          </div>
          <div class="row mb-2">
            <div class="col">
              <strong data-test-hook="NewPlanCost">
                <span v-if="pendingSubscription.durationInMonths == 12" data-test-hook="NewPlanCostInterval">
                  {{ $t('xYearly') }}
                </span>
                <span v-else data-test-hook="NewPlanCostInterval">
                  {{ $t('xMonthly') }}
                </span>
                - {{ pendingSubscription.cost.toFixed(2) | formatCurrency }}
              </strong>
            </div>
          </div>
          <div class="cols-auto float-left ml-0">
            <button
              type="button"
              class="btn btn-outline-warning"
              @click="openModal('confirm-revert-pending-subscription')"
              data-test-hook="revertChangesButtonPendingPlanChangeCard"
            >
              {{ $t('xRevertChanges') }}
            </button>
          </div>
        </div>
        <div v-else-if="pendingPaidToFree()">
          <div class="row align-items-center">
            <div class="cols-auto">
              <div class="row mb-0 pb-0">
                <div class="col pending-labels mb-0 pb-0 ml-3" data-test-hook="NewPlanTypeLabel">
                  {{ $t('xNewPlanType') }}
                </div>
              </div>
              <div class="row mb-2 ml-0">
                <div class="col">
                  <strong data-test-hook="NewPlanType">{{ pendingSubscription.productName }}</strong>
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-0 pb-0">
            <div class="col pending-labels mb-0 pb-0" data-test-hook="NewPlanStartDateLabel">
              {{ $t('xPlanStartDate') }}
            </div>
          </div>
          <div class="row mb-2">
            <div class="col">
              <strong data-test-hook="NewPlanStartDate">
                {{ $moment(pendingSubscription.effectiveDate).format($t('xShortDate')) }}
              </strong>
            </div>
          </div>
          <div class="cols-auto float-left">
            <button
              type="button"
              class="btn btn-outline-warning"
              @click="openModal('confirm-revert-pending-subscription')"
              data-test-hook="revertChangesButtonPendingPlanChangeCard"
            >
              {{ $t('xRevertChanges') }}
            </button>
          </div>
        </div>
        <div v-else-if="pendingCancellation()">
          <div class="row">
            <div class="cols-auto">
              <div class="row mb-0 pb-0">
                <div class="col pending-labels mb-0 pb-0" data-test-hook="PlanCancelDateLabel">
                  <p v-if="pendingCancellation()">{{ $t('xSubscriptionEndsOn') }}</p>
                  <p v-else>{{ $t('xEffectiveDate') }}</p>
                </div>
              </div>
              <div class="row mb-2">
                <div class="col">
                  <strong data-test-hook="PlanCancelDate">
                    {{ $moment(currentSubscription.nextBillingDate).format($t('xShortDate')) }}
                  </strong>
                </div>
              </div>
              <div class="cols-auto float-left ml-0">
                <button
                  type="button"
                  class="btn btn-outline-warning"
                  @click="openModal('confirm-revert-pending-subscription')"
                  data-test-hook="revertChangesButtonPendingPlanChangeCard"
                >
                  {{ $t('xUndoCancellation') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Modal modalId="confirm-revert-pending-subscription" size="modal-lg">
      <ModalHeader
        :title="$t('xAreYouSure')"
        icon="fas fa-exclamation-triangle colorOrange mr-1"
        data-test-hook="modalTitleTextRevertChangesModal"
      >
        <div class="cols-auto float-right">
          <button
            type="button"
            class="btn btn-icon-button btn-icon-button--gray"
            @click="closeModal('confirm-revert-pending-subscription')"
            data-test-hook="xCloseButtonRevertChangesModal"
          >
            <i class="fas fa-times colorOrange" />
          </button>
        </div>
      </ModalHeader>
      <ModalBody>
        <div class="row">
          <div class="col" data-test-hook="modalBodyText1">
            {{ $t('xThisActionWillRevert') }}
            <strong>{{ currentSubscription.productName }}.</strong>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col" data-test-hook="modalBodyText2">
            {{ $t('xYourPlanWillRenew') }}
            <strong>{{ $moment(currentSubscription.nextBillingDate).format($t('xShortDate')) }}</strong>
            {{ $t('xInTheAmountOf') }}
            <strong>{{ currentSubscription.cost.toFixed(2) | formatCurrency }}.</strong>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <button
          type="button"
          class="btn btn-outline-warning"
          @click="closeModal('confirm-revert-pending-subscription')"
          data-test-hook="noCancelButtonRevertChangesModal"
        >
          {{ $t('xNoCancel') }}
        </button>
        <button
          type="button"
          class="btn btn-warning"
          @click="revertPendingChanges()"
          :disabled="revertingPendingChanges"
          data-test-hook="yesImSureButtonRevertChangesModal"
        >
          <span v-if="!revertingPendingChanges">
            {{ $t('xYesImSure') }}
          </span>
          <span v-else>
            <span class="spinner-border"></span>
          </span>
        </button>
      </ModalFooter>
    </Modal>
  </div>
</template>
<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import * as Constants from '@/constants'

export default {
  props: ['currentSubscription', 'pendingSubscription'],
  data() {
    return {
      revertingPendingChanges: false
    }
  },
  computed: {
    ...mapState({
      accountProfile: state => state.account.accountProfile,
      currentApparatusDetails: state => state.apparatus.currentApparatusDetails
    })
  },
  methods: {
    revertPendingChanges() {
      this.revertingPendingChanges = true
      this.$store
        .dispatch('changeBillingAccountProduct', {
          organizationId: this.accountProfile.organizationId,
          apparatusId: this.currentApparatusDetails.apparatusId,
          productId: this.currentSubscription.productId
        })
        .then(response => {
          this.revertingPendingChanges = false
          this.$emit('pendingSubscriptionReverted')
          this.closeModal('confirm-revert-pending-subscription')
          this.$store.dispatch('displayToast', {
            title: this.$t('xSuccess'),
            message: this.$t('xPendingChangesReverted'),
            variant: 'success'
          })
          this.$appInsights.trackEvent({
            name: 'revert-pending-subscription-changes',
            properties: {
              page: 'subscriptions',
              organizationId: this.accountProfile.organizationId,
              userId: this.accountProfile.userId,
              apparatusId: this.currentApparatusDetails.apparatusId,
              subscriptionId: this.currentSubscription.productId
            }
          })
        })
        .catch(error => {
          this.revertingPendingChanges = false
          this.$store.dispatch('displayToast', {
            title: this.$t('xError'),
            message: this.$t('xPendingChangesError'),
            variant: 'danger'
          })
        })
    },
    pendingCancellation() {
      if (
        this.currentSubscription &&
        (this.currentSubscription.status == Constants.PendingCancellationStatus ||
          this.currentSubscription.status == Constants.PendingSuspensionStatus)
      )
        return true
      return false
    },
    pendingPaidToFree() {
      if (
        this.currentSubscription &&
        this.currentSubscription.productId != Constants.FreeWifiEthProductId &&
        this.pendingSubscription &&
        this.pendingSubscription.productId == Constants.FreeWifiEthProductId
      ) {
        return true
      }
      return false
    },
    pendingFreeToPaidOrPaidToPaid() {
      if (this.pendingSubscription && this.pendingSubscription.productId != Constants.FreeWifiEthProductId) {
        return true
      }
      return false
    }
  }
}
</script>
<style scoped>
.pending-change-card {
  background: #eeeeee 0% 0% no-repeat padding-box;
  border-radius: 10px;
}
.pending-title-color {
  color: gray-800 !important;
}
.pending-labels {
  font-family: 'Roboto', sans-serif;
  font-size: 1rem;
  line-height: 1.25rem;
  color: #555555;
}
.btn-pending-sub-reversion {
  font-family: 'Roboto', sans-serif;
  font-size: 1rem;
  color: white;
  text-transform: capitalize;
  background-color: #949494;
  border-color: #949494;
  border-radius: 5px;
  box-shadow: none !important;
}
.btn-pending-sub-reversion:not(:disabled):not(.disabled):hover {
  background-color: #555555;
  border-color: #555555;
  color: white;
}
.btn-pending-sub-reversion:not(:disabled):not(.disabled):focus:active {
  background-color: #cccccc;
  border-color: #cccccc;
  color: white;
}
</style>
