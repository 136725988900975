<template>
  <b-modal id="announcement-modal" centered hide-footer size="md" @hidden="closeModal()">
    <template #modal-header>
      <b-row class="w-100" align-v="center" no-gutters>
        <b-col>
          <h2 class="modal-title">
            <i class="fas fa-certificate colorOrange mr-1" />
            {{ announcement.modalHeader }}
          </h2>
        </b-col>
        <b-col cols="auto" class="float-right">
          <b-button
            class="btn-icon-button"
            variant="icon-button"
            @click="closeModal()"
            data-test-hook="x-button-new-announcement"
          >
            <i class="fas fa-times" />
          </b-button>
        </b-col>
      </b-row>
    </template>
    <b-row class="text-center">
      <b-col>
        <h3 class="colorOrange">{{ announcement.featureHeader }}</h3>
      </b-col>
    </b-row>
    <b-row class="text-center">
      <b-col>
        {{ announcement.featureDescription }}
      </b-col>
    </b-row>
    <b-row no-gutters class="mt-3">
      <b-col class="checkbox-row">
        <b-form-checkbox
          v-model="dontShowAgain"
          class="font-weight-bold"
          data-test-hook="dont-show-again-checkbox-new-announcement"
        >
          {{ $t('xDontShowThisAgain') }}
        </b-form-checkbox>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col>
        <b-button
          variant="outline-warning"
          @click="closeModal()"
          block
          data-test-hook="dismiss-button-new-announcement"
        >
          {{ $t('xDismiss') }}
          <b-spinner class="ml-2" v-if="savingSetting" />
        </b-button>
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col>
        <b-link class="white-text" :href="announcement.featureInfoUrl" target="_blank">
          <b-button
            variant="warning"
            block
            @click="learningMore(), (dontShowAgain = true), closeModal()"
            data-test-hook="learn-more-new-announcement"
          >
            <i class="fas fa-external-link-alt" />
            {{ $t('xLearnMore') }}
          </b-button>
        </b-link>
      </b-col>
    </b-row>
  </b-modal>
</template>
<script>
import { mapState } from 'vuex'
export default {
  props: ['announcement'],
  data() {
    return {
      dontShowAgain: false,
      savingSetting: false
    }
  },
  computed: {
    ...mapState({
      isImpersonating: state => state.auth.isImpersonating
    })
  },
  methods: {
    learningMore() {
      this.$appInsights.trackEvent({
        name: 'learning-more-about-announcement',
        properties: {
          orgId: this.accountProfile && this.accountProfile.organizationId ? this.accountProfile.organizationId : null,
          userId: this.accountProfile && this.accountProfile.userId ? this.accountProfile.userId : null,
          announcementId: this.announcement.releaseId
        }
      })
    },
    closeModal() {
      if (this.dontShowAgain) {
        this.savingSetting = true
        let request = {
          settings: {
            announcementId: this.announcement.releaseId,
            showAnnouncement: false
          }
        }
        this.$store
          .dispatch('updateAccountSettings', request)
          .then(response => {
            if (this.isImpersonating) this.$store.dispatch('getUser')
            else this.$store.dispatch('getImpersonatedUser')
            this.$bvModal.hide('announcement-modal')
            this.dontShowAgain = false
            this.$appInsights.trackEvent({
              name: 'dont-show-announcement-again',
              properties: {
                orgId:
                  this.accountProfile && this.accountProfile.organizationId ? this.accountProfile.organizationId : null,
                userId: this.accountProfile && this.accountProfile.userId ? this.accountProfile.userId : null,
                announcementId: this.announcement.releaseId
              }
            })
          })
          .catch(error => {
            this.$store.dispatch('displayToast', {
              title: this.$t('xError'),
              message: this.$t('xFailedToUpdateSettings'),
              variant: 'danger'
            })
          })
          .finally(() => {
            this.savingSetting = false
          })
      } else {
        this.dontShowAgain = false
        this.$bvModal.hide('announcement-modal')
      }
    }
  }
}
</script>

<style scoped>
.checkbox-row {
  background-color: #f5f5f5;
}
</style>
