var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Modal",
    { attrs: { modalId: "EditAccountInfoModal" } },
    [
      _c(
        "ModalHeader",
        {
          attrs: {
            title: _vm.$t("xEditAccountInformation"),
            icon: "fas fa-user-circle"
          }
        },
        [
          _c(
            "button",
            {
              staticClass: "btn btn-icon-button btn-icon-button--gray",
              attrs: {
                "data-test-hook": "x-button-edit-account-information-modal"
              },
              on: {
                click: function($event) {
                  _vm.resetModal(), _vm.closeModal("EditAccountInfoModal")
                }
              }
            },
            [_c("i", { staticClass: "fas fa-times" })]
          )
        ]
      ),
      _c("ModalBody", [
        _vm.accountData
          ? _c("div", [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col" }, [
                  _c(
                    "div",
                    {
                      staticClass: "form-group",
                      attrs: { id: "firstName-group" }
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.$v.accountData.firstName.$model,
                            expression: "$v.accountData.firstName.$model"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: {
                          id: "firstName",
                          type: "text",
                          placeholder: _vm.$t("xFirstName"),
                          state: _vm.validState(_vm.$v.accountData.firstName),
                          "aria-describedby": "firstName-invalid"
                        },
                        domProps: {
                          value: _vm.$v.accountData.firstName.$model
                        },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.$v.accountData.firstName,
                              "$model",
                              $event.target.value
                            )
                          }
                        }
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "invalid-feedback",
                          attrs: { id: "firstName-invalid" }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("xRequiredField")) + " ")]
                      )
                    ]
                  )
                ])
              ]),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col" }, [
                  _c(
                    "div",
                    {
                      staticClass: "form-group",
                      attrs: { id: "lastName-group" }
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.$v.accountData.lastName.$model,
                            expression: "$v.accountData.lastName.$model"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: {
                          id: "lastName",
                          type: "text",
                          placeholder: _vm.$t("xLastName"),
                          state: _vm.validState(_vm.$v.accountData.lastName),
                          "aria-describedby": "lastName-invalid"
                        },
                        domProps: { value: _vm.$v.accountData.lastName.$model },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.$v.accountData.lastName,
                              "$model",
                              $event.target.value
                            )
                          }
                        }
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "invalid-feedback",
                          attrs: { id: "lastName-invalid" }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("xRequiredField")) + " ")]
                      )
                    ]
                  )
                ])
              ]),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col" }, [
                  _c(
                    "div",
                    { staticClass: "form-group", attrs: { id: "phone-group" } },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.$v.phoneNumber.$model,
                            expression: "$v.phoneNumber.$model"
                          }
                        ],
                        staticClass: "form-control",
                        class: _vm.status(_vm.$v.phoneNumber),
                        attrs: {
                          id: "phone",
                          placeholder: _vm.$t("xPhoneNumber"),
                          type: "text",
                          maxlength: "12",
                          state: _vm.validState(_vm.$v.phoneNumber),
                          "aria-describedby": "phoneNumber-invalid"
                        },
                        domProps: { value: _vm.$v.phoneNumber.$model },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.$v.phoneNumber,
                              "$model",
                              $event.target.value
                            )
                          }
                        }
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "invalid-feedback",
                          attrs: { id: "phoneNumber-invalid" }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("xPleaseEnterAValidPhoneNumber")) +
                              " "
                          )
                        ]
                      )
                    ]
                  )
                ])
              ]),
              !_vm.editingAddress
                ? _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col" }, [
                      _c("p", [
                        _vm._v(_vm._s(_vm.accountProfile.address.line1))
                      ]),
                      _vm.accountProfile.address.line2
                        ? _c("p", [
                            _vm._v(_vm._s(_vm.accountProfile.address.line2))
                          ])
                        : _vm._e(),
                      _c("p", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.accountProfile.address.city +
                                ", " +
                                _vm.accountProfile.address.region +
                                " " +
                                _vm.accountProfile.address.postalCode
                            ) +
                            " "
                        )
                      ])
                    ]),
                    !_vm.editingAddress
                      ? _c("div", { staticClass: "col-auto" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-icon-button",
                              attrs: {
                                "data-test-hook":
                                  "pen-button-edit-account-modal"
                              },
                              on: {
                                click: function($event) {
                                  _vm.editingAddress = !_vm.editingAddress
                                }
                              }
                            },
                            [_c("i", { staticClass: "fas fa-pencil" })]
                          )
                        ])
                      : _vm._e()
                  ])
                : _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col" },
                      [
                        _c("TypeAheadAddress", {
                          ref: "typeAheadAddressEditAccountModal",
                          attrs: { currentAddress: _vm.accountData.address },
                          on: { newAddress: _vm.setCurrentAddress }
                        })
                      ],
                      1
                    )
                  ])
            ])
          : _vm._e()
      ]),
      _c("ModalFooter", [
        _c(
          "button",
          {
            staticClass: "btn btn-outline-warning",
            attrs: {
              "data-test-hook": "cancel-button-edit-account-information-modal"
            },
            on: {
              click: function($event) {
                _vm.resetModal(), _vm.closeModal("EditAccountInfoModal")
              }
            }
          },
          [_vm._v(" " + _vm._s(_vm.$t("xCancel")) + " ")]
        ),
        _c(
          "button",
          {
            staticClass: "btn btn-warning",
            attrs: {
              disabled: _vm.canSave(),
              "data-test-hook": "save-button-edit-account-information-modal"
            },
            on: {
              click: function($event) {
                return _vm.save()
              }
            }
          },
          [
            _vm._v(" " + _vm._s(_vm.$t("xSave")) + " "),
            _vm.saving
              ? _c("div", {
                  staticClass: "spinner-border text-muted ml-2",
                  attrs: { label: "Spinning" }
                })
              : _vm._e()
          ]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }