<template>
  <div id="signUpDiv">
    <b-row align-h="center" class="h-100 m-0" align-v="center">
      <b-col class="d-flex justify-content-center">
        <b-card class="card-shadow login-cards">
          <b-row class="my-3 justify-content-center">
            <b-col class="col-10">
              <b-img
                :src="require('@/assets/images/MobileLink_Logo_2023_COLOR.svg')"
                fluid
                alt="Mobile Link Logo"
              ></b-img>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group id="email-group" label-for="email">
                <h5 class="text-center pb-4">{{ $t('xSignUp') }}</h5>
                <b-form-input
                  :class="status($v.email)"
                  :state="validState($v.email)"
                  id="email"
                  v-model="$v.email.$model"
                  maxlength="70"
                  type="text"
                  :placeholder="$t('xEmailAddress')"
                  aria-describedby="email-invalid"
                  :formatter="lowerCaseFormatter"
                  :disabled="processing"
                ></b-form-input>
                <b-form-invalid-feedback id="email-invalid">
                  {{ $t('xRequiredField') }}. {{ $t('xEmailInvalidError') }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
          <div>
            <b-row class="mt-1 mb-3">
              <b-col>
                <b-button block variant="warning" @click="next()" data-test-hook="next-button-noUserEmailStatus-home">
                  {{ $t('xNext') }}
                  <b-spinner class="ml-2" v-if="processing"></b-spinner>
                  <span class="pl-1">
                    <i class="far fa-arrow-right"></i>
                  </span>
                </b-button>
              </b-col>
            </b-row>
          </div>
          <div>
            <b-row class="mt-1 mb-3">
              <b-col>
                <span class="font-weight-bold">
                  {{ $t('Already have an account?') }}
                </span>
                <b-button
                  class="mb-1 font-weight-bold"
                  style="text-transform: capitalize !important; font-family: 'Roboto', sans-serif !important;"
                  variant="link"
                  @click="signIn()"
                  data-test-hook="signIn-button-noUserEmailStatus-signUp"
                >
                  {{ $t('xSignIn') | lowercase }}
                </b-button>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'
import { vueAppApiBaseAddress } from '@/utils/mixins'

export default {
  mixins: [validationMixin],
  data() {
    return {
      email: '',
      step: 'email',
      processing: false
    }
  },
  validations: {
    email: {
      required,
      email
    }
  },
  computed: {},
  methods: {
    next() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.processing = true
        window.location.assign(vueAppApiBaseAddress() + `Auth/SignUp?email=${encodeURIComponent(this.email)}`)
      }
    },
    lowerCaseFormatter(value) {
      return value.toLowerCase()
    },
    signIn() {
      window.location.assign(vueAppApiBaseAddress() + `Auth/SignIn`)
    }
  }
}
</script>

<style scoped>
.btn-link {
  color: var(--blue-700);
  outline: none !important;
  box-shadow: none !important;
  text-decoration: underline;
  font-size: 1em;
  padding: 0.75em 0;
}
.btn-link:hover {
  color: var(--carbon-900);
  background-color: transparent !important;
  text-decoration: underline;
}
.errorIcon {
  color: var(--red-500);
}
.login-cards {
  z-index: 100;
}
@media only screen and (max-width: 600px) {
  .login-cards {
    width: 100%;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .login-cards {
    width: 400px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .login-cards {
    width: 480px;
  }
}

.card-body {
  padding: 1rem;
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .card-body {
    padding: 2rem;
  }
}

#signUpDiv {
  height: 100vh;
  background: var(--blue-900);
  margin-left: -15px;
  margin-right: -15px;
}
</style>
