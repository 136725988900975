<template>
  <div>
    <div v-if="!manualEntry">
      <b-form-group id="address-group" :label="$t('xAddress')" label-for="address" data-test-hook="addressSearchHeader">
        <span v-click-outside="hideResults">
          <b-input-group>
            <b-form-input
              v-model="freeformAddress"
              type="text"
              v-on:keyup="typeAheadSearch()"
              autocomplete="new-password"
              data-test-hook="AddressInputField"
            >
            </b-form-input>
            <b-input-group-append>
              <b-button
                class="my-0 py-0"
                variant="warning"
                @click="!awaitingSearch ? typeAheadSearch() : null"
                data-test-hook="AddressSearchBtn"
              >
                <b-spinner
                  v-if="awaitingSearch"
                  style="width: 1.5rem; height: 1.5rem"
                  data-test-hook="AddressSearchSpinner"
                />
                <i v-else class="fas fa-search" />
              </b-button>
            </b-input-group-append>
          </b-input-group>
          <b-row>
            <b-col>
              <b-list-group class="suggested-input-list" v-if="addressResults">
                <b-list-group-item
                  class="hoverPointer"
                  v-for="(address, index) in addressResults"
                  :key="index"
                  @click="setSelectedAddress(address)"
                  data-test-hook="AddressResult"
                >
                  {{ address.address.freeformAddress }}
                </b-list-group-item>
                <b-list-group-item
                  class="hoverPointer text-blue-500"
                  @click=";(selectedPostalCode = null), (selectedCountry = null), (manualEntry = true)"
                  data-test-hook="ManualEntryOption"
                >
                  {{ $t('xDontSeeAddress') }}
                </b-list-group-item>
              </b-list-group>
            </b-col>
          </b-row>
        </span>
      </b-form-group>
      <b-row class="mt-2" v-if="selectedAddress">
        <b-col>
          <b-form-group
            id="line2-group"
            :label="$t('xAddressLine2Optional')"
            label-for="line2"
            data-test-hook="addressLine2Header"
          >
            <b-form-input id="line2" v-model="selectedAddress.line2" type="text" data-test-hook="addressLine2" />
          </b-form-group>
        </b-col>
      </b-row>
      <div
        v-if="
          selectedAddress &&
            selectedAddress.line1 &&
            selectedAddress.city &&
            selectedAddress.region &&
            selectedAddress.postalCode
        "
      >
        <b-row>
          <b-col>
            <h5 class="mb-2 mt-3" data-test-hook="selectedAddrHeader">{{ 'xSelectedAddress' | formatLabel }}</h5>
            <div>
              <p data-test-hook="selectedAddrLine1">{{ selectedAddress.line1 }}</p>
              <p data-test-hook="selectedAddrCityRegionPostalCode">
                {{ selectedAddress.city + ', ' + selectedAddress.region + ' ' + selectedAddress.postalCode }}
              </p>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
    <div v-else>
      <b-row>
        <b-col>
          <b-form-group id="country-group" :label="$t('xCountry')" label-for="country">
            <b-form-select
              id="country"
              v-model="$v.selectedCountry.$model"
              :options="countries"
              :state="validState($v.selectedCountry)"
              :placeholder="$t('xCountry')"
              aria-describedby="country-invalid"
            ></b-form-select>
            <b-form-invalid-feedback id="country-invalid"> {{ $t('xRequiredField') }}. </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group id="postalCode-group" :label="$t('xZipCode')" label-for="postalCode">
            <b-form-input
              id="postalCode"
              v-model="$v.selectedPostalCode.$model"
              :maxlength="postalCodeLimit"
              type="text"
              :state="validState($v.selectedPostalCode)"
              :placeholder="$t('xZipCode')"
              debounce="500"
              aria-describedby="postalCode-invalid"
            ></b-form-input>
            <b-form-invalid-feedback id="postalCode-invalid"> {{ $t('xZipCodeInvalid') }}. </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row v-if="showVerifyPostalCode">
        <b-col>
          <b-button
            variant="outline-warning"
            @click="verifyPostalCode(selectedPostalCode, selectedCountry)"
            :disabled="!selectedCountry || !selectedPostalCode"
            block
          >
            {{ $t('xVerifyPostalCode') }}
          </b-button>
        </b-col>
      </b-row>

      <b-row v-if="showRegionStateAddress && countryStates.value[selectedAddress.country].countryRegions.length > 0">
        <b-col>
          <b-form-group id="region-group" label="State / Region" label-for="region">
            <b-form-select
              id="region"
              v-model="selectedAddress.region"
              :options="
                selectedCountry == 'US' || selectedCountry == 'CA'
                  ? countryRegions.map(s => ({
                      text: s.name,
                      value: s.abbreviation
                    }))
                  : countryStates.value[selectedCountry].countryRegions.map(s => ({
                      text: s.name,
                      value: s.abbreviation
                    }))
              "
              :placeholder="$t('xStateRegion')"
              aria-describedby="region-invalid"
            ></b-form-select>
            <b-form-invalid-feedback id="region-invalid"> {{ $t('xRequiredField') }}. </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row v-if="showRegionStateAddress">
        <b-col v-if="selectedCountry == 'US' || selectedCountry == 'CA'">
          <b-form-group id="city-group" :label="$t('xCity')" label-for="city">
            <b-form-select
              id="city"
              v-model="$v.selectedAddress.city.$model"
              :options="
                citySelectList.map(s => ({
                  text: s,
                  value: s
                }))
              "
              :placeholder="$t('xCity')"
            ></b-form-select>
          </b-form-group>
        </b-col>
        <b-col v-else>
          <b-form-group id="city-group" :label="$t('xCity')" label-for="city">
            <b-form-input
              id="city"
              v-model="$v.selectedAddress.city.$model"
              maxlength="50"
              type="text"
              :state="validState($v.selectedAddress.city)"
              aria-describedby="city-invalid"
            ></b-form-input>
            <b-form-invalid-feedback id="city-invalid"> {{ $t('xRequiredField') }}. </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row v-if="showRegionStateAddress">
        <b-col>
          <b-form-group id="line1-group" :label="$t('xAddressLine1')" label-for="line1">
            <b-form-input
              id="line1"
              v-model="$v.selectedAddress.line1.$model"
              maxlength="50"
              type="text"
              :state="validState($v.selectedAddress.line1)"
              aria-describedby="line1-invalid"
            ></b-form-input>
            <b-form-invalid-feedback id="line1-invalid"> {{ $t('xRequiredField') }}. </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="showRegionStateAddress">
        <b-col>
          <b-form-group id="line2-group" :label="$t('xAddressLine2')" label-for="line2">
            <b-form-input id="line2" v-model="selectedAddress.line2" type="text"></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { required, alphaNum, maxLength, minLength, integer } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'

export default {
  mixins: [validationMixin],
  props: {
    isATankAddress: {
      type: Boolean,
      default: false
    },
    tankAddress: {
      type: String,
      default: null
    },
    currentAddress: {
      type: Object,
      default: function() {
        return {
          line1: null,
          line2: null,
          city: null,
          region: null,
          country: null,
          postalCode: null
        }
      }
    },
    showSelectedAddress: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      addressResults: null,
      manualEntry: false,
      awaitingSearch: false,
      states: null,
      countries: null,
      freeformAddress: null,
      citySelectList: [],
      selectedCountry: null,
      selectedPostalCode: null,
      selectedAddress: {
        line1: null,
        line2: null,
        city: null,
        region: null,
        country: null,
        postalCode: null
      },
      timeout: null,
      countryRegions: [],
      showVerifyPostalCode: false,
      showRegionStateAddress: false,
      postalCodeLimit: null
    }
  },
  validations() {
    return {
      selectedAddress: {
        line1: {
          required
        },
        city: {
          required
        }
      },
      selectedCountry: {
        required
      },
      selectedPostalCode: {
        required,
        valid: () => {
          if (this.selectedCountry == 'US') {
            return (
              integer(Number(this.selectedPostalCode)) &&
              minLength(this.postalCodeLimit)(this.selectedPostalCode) &&
              maxLength(this.postalCodeLimit)(this.selectedPostalCode)
            )
          }

          if (this.selectedCountry == 'CA') {
            return (
              alphaNum(this.selectedPostalCode) &&
              minLength(this.postalCodeLimit)(this.selectedPostalCode) &&
              maxLength(this.postalCodeLimit)(this.selectedPostalCode)
            )
          }

          return minLength(1)(this.selectedPostalCode)
        }
      }
    }
  },
  computed: {
    ...mapState({
      countryStates: state => state.application.countryStates
    })
  },
  methods: {
    typeAheadSearch() {
      if (this.timeout) {
        this.awaitingSearch = false
        clearTimeout(this.timeout)
      }
      if (!this.awaitingSearch && !this.latitude && !this.longitude) {
        this.timeout = setTimeout(() => {
          this.$store
            .dispatch('typeAheadSearch', this.freeformAddress)
            .then(response => {
              if (response.status == 204) this.addressResults = []
              else this.addressResults = response.data
            })
            .catch(error => {
              this.addressResults = []
            })
            .finally(() => {
              this.awaitingSearch = false
            })
        }, 1000)
      } else if (!this.awaitingSearch && this.latitude && this.longitude) {
        this.timeout = setTimeout(() => {
          this.$store
            .dispatch('typeAheadSearchWithCoords', {
              address: this.freeformAddress,
              latitude: this.latitude,
              longitude: this.longitude
            })
            .then(response => {
              if (response.status == 204) this.addressResults = []
              else this.addressResults = response.data
            })
            .catch(error => {
              this.addressResults = []
            })
            .finally(() => {
              this.awaitingSearch = false
            })
        }, 1000)
      }
      this.awaitingSearch = true
    },
    setSelectedAddress(newAddress) {
      if (this.selectedAddress == null) {
        this.selectedAddress = {}
      }

      this.selectedAddress.line1 =
        (newAddress.address.streetNumber != null ? newAddress.address.streetNumber + ' ' : '') +
        newAddress.address.streetName
      this.selectedAddress.line2 = null
      this.selectedAddress.city = newAddress.address.municipality
      this.selectedAddress.country = newAddress.address.countryCode
      this.selectedCountry = newAddress.address.countryCode
      this.selectedAddress.region = newAddress.address.countrySubdivision
        ? newAddress.address.countrySubdivision
        : newAddress.address.countryCode
      var postalCode = newAddress.address.postalCode
      switch(newAddress.address.countryCode) {
        case 'US':
          this.postalCodeLimit = 5;
          break;
        case 'CA':
          this.postalCodeLimit = 6;
          if (newAddress.address.extendedPostalCode) {
            // Not sure what this was initially added for; seems like Canada ALWAYS uses 6-character codes. Leaving in just in case
            postalCode = newAddress.address.extendedPostalCode
          }
          break;
        default:
          this.postalCodeLimit = null;
          break;
      }
      this.selectedAddress.postalCode = postalCode
      this.selectedPostalCode = postalCode
      this.$emit('newAddress', this.selectedAddress)
      this.addressResults = null
      this.manualEntry = false
      this.awaitingSearch = false
      this.freeformAddress = null
    },
    hideResults() {
      this.addressResults = null
    },
    showPosition(position) {
      this.longitude = position.coords.longitude
      this.latitude = position.coords.latitude
    },
    async verifyAddress(address) {
      if (!address) return false
      let validPostalCodeCountry = await this.verifyPostalCodeCountry(address.postalCode, address.country)
      if (validPostalCodeCountry) {
        let regionFound = this.countryRegions.find(s => s == address.region) == address.region
        let cityFound = this.citySelectList.find(s => s == address.city) == address.city
        return regionFound && cityFound
      }
      return false
    },
    async verifyPostalCode(postalCode, country) {
      let validPostalCodeCountry = await this.verifyPostalCodeCountry(postalCode, country)
      if (validPostalCodeCountry) {
        this.showVerifyPostalCode = false
        this.selectedAddress.country = country
        this.selectedAddress.postalCode = postalCode
        this.selectedAddress.region = this.countryRegions.length == 1 ? this.countryRegions[0].abbreviation : null
        this.selectedAddress.city = this.citySelectList.length == 1 ? this.citySelectList[0] : null
        this.showRegionStateAddress = true
      } else {
        this.$store.dispatch('displayToast', {
          title: this.$t('xError'),
          message: this.$t('xThereWasProblemValidatingAddress'),
          variant: 'danger'
        })
      }
    },
    async verifyPostalCodeCountry(postalCode, country) {
      //validates zip/country, loads region and city lists
      let result = false
      await this.$store
        .dispatch('verifyPostalCode', {
          country: country,
          postalCode: postalCode
        })
        .then(response => {
          this.countryRegions = []
          this.citySelectList = []
          if (response?.data[0]?.verifiedRegions && response?.data[0]?.verifiedCities) {
            this.countryRegions = response.data[0].verifiedRegions
            this.citySelectList = response.data[0].verifiedCities
            result = true
          }
        })
        .catch(error => {
          result = false
        })
      return result
    }
  },
  watch: {
    selectedAddress: {
      handler: function(newVal, oldVal) {
        if (newVal && oldVal) {
          this.$emit('newAddress', newVal)
        }
      },
      deep: true
    },
    selectedPostalCode: function(newVal, oldVal) {
      if (newVal && this.manualEntry) {
        this.countryRegions = []
        this.citySelectList = []
        this.selectedAddress.line1 = null
        this.selectedAddress.line2 = null
        if (this.selectedCountry) {
          if (this.selectedCountry == 'US' || this.selectedCountry == 'CA') {
            this.showVerifyPostalCode = true
            this.showRegionStateAddress = false
          } else {
            this.selectedAddress.postalCode = this.selectedPostalCode
            this.selectedAddress.region =
              this.countryStates.value[this.selectedAddress.country].countryRegions.length > 0
                ? null
                : this.selectedAddress.country
            this.selectedAddress.city = null
            this.showVerifyPostalCode = false
            this.showRegionStateAddress = true
          }
        }
      }
    },
    selectedCountry: function(newVal, oldVal) {
      if (newVal && this.manualEntry) {
        this.countryRegions = []
        this.citySelectList = []
        this.selectedPostalCode = null
        this.selectedAddress.line1 = null
        this.selectedAddress.line2 = null
        this.selectedAddress.city = null
        this.selectedAddress.postalCode = null
        this.selectedAddress.region =
          this.countryStates.value[this.selectedCountry].countryRegions.length > 0 ? null : this.selectedCountry
        if (newVal == 'US' || newVal == 'CA') {
          this.showVerifyPostalCode = true
          this.showRegionStateAddress = false
          this.postalCodeLimit = newVal == 'US' ? 5 : 6
        } else {
          this.selectedAddress.country = this.selectedCountry
          this.showVerifyPostalCode = false
          this.showRegionStateAddress = true
          this.postalCodeLimit = null
        }
      }
    }
  },
  async mounted() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(this.showPosition)
    }
    if (this.isATankAddress) {
      this.freeformAddress = this.tankAddress
      this.typeAheadSearch()
    } else {
      this.selectedAddress = this.currentAddress
      this.selectedCountry = this.currentAddress.country
      this.selectedPostalCode = this.currentAddress.postalCode
    }
    if (!this.countryStates || !this.countryStates.value) {
      await this.$store.dispatch('getCountrySubdivisions')
    }
    this.states = _.cloneDeep(this.countryStates.value)
    this.countries = []
    this.countryStates.countryCodes.forEach(element => {
      this.countries.push({ value: element, text: this.countryStates.value[element].countryName })
    })
  }
}
</script>
