var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Modal",
    {
      attrs: {
        modalId:
          "AddEditContactInfoModal-" + _vm.addEditContactModalInfo.apparatusId
      }
    },
    [
      _c(
        "ModalHeader",
        { attrs: { title: _vm.modalTitle, icon: "fas fa-user-circle" } },
        [
          _c(
            "button",
            {
              staticClass: "btn btn-icon-button btn-icon-button--gray",
              attrs: { "data-test-hook": "x-button-add-edit-contact" },
              on: {
                click: function($event) {
                  _vm.saving
                    ? null
                    : (_vm.resetModal(),
                      _vm.closeModal(
                        "AddEditContactInfoModal-" +
                          _vm.addEditContactModalInfo.apparatusId
                      ))
                }
              }
            },
            [_c("i", { staticClass: "fas fa-times" })]
          )
        ]
      ),
      _c("ModalBody", [
        _vm.addEditContactModalInfo ||
        (_vm.addEditContactModalInfo != null &&
          _vm.addEditContactModalInfo.actionType == "add")
          ? _c("div", { staticClass: "row" }, [
              _vm.addEditContactModalInfo.infoType == "phone"
                ? _c("div", { staticClass: "col" }, [
                    _c(
                      "div",
                      {
                        staticClass: "form-group",
                        attrs: { id: "phone-group" }
                      },
                      [
                        _c(
                          "label",
                          { staticClass: "d-block", attrs: { for: "phone" } },
                          [_vm._v(_vm._s(_vm.$t("xPhoneNumber")))]
                        ),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.$v.editPhone.$model,
                              expression: "$v.editPhone.$model"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: {
                            id: "phone",
                            maxlength: "12",
                            type: "text",
                            state: _vm.validState(_vm.$v.editPhone),
                            "aria-describedby": "phoneNumber-invalid"
                          },
                          domProps: { value: _vm.$v.editPhone.$model },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.$v.editPhone,
                                "$model",
                                $event.target.value
                              )
                            }
                          }
                        }),
                        _c(
                          "div",
                          {
                            staticClass: "invalid-feedback",
                            attrs: { id: "phoneNumber-invalid" }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("xRequiredField")) +
                                ". " +
                                _vm._s(
                                  _vm.$t("xPleaseEnterAValidPhoneNumber")
                                ) +
                                " "
                            )
                          ]
                        )
                      ]
                    )
                  ])
                : _vm._e(),
              _vm.addEditContactModalInfo.infoType == "email"
                ? _c("div", { staticClass: "col" }, [
                    _c(
                      "div",
                      {
                        staticClass: "form-group",
                        attrs: { id: "email-group" }
                      },
                      [
                        _c(
                          "label",
                          { staticClass: "d-block", attrs: { for: "email" } },
                          [_vm._v(_vm._s(_vm.$t("xEmailAddress")))]
                        ),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.$v.editEmail.$model,
                              expression: "$v.editEmail.$model"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: {
                            id: "email",
                            maxlength: "70",
                            type: "text",
                            state: _vm.validState(_vm.$v.editEmail),
                            "aria-describedby": "email-invalid"
                          },
                          domProps: { value: _vm.$v.editEmail.$model },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.$v.editEmail,
                                "$model",
                                $event.target.value
                              )
                            }
                          }
                        }),
                        _c(
                          "div",
                          {
                            staticClass: "invalid-feedback",
                            attrs: { id: "email-invalid" }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("xRequiredField")) +
                                ". " +
                                _vm._s(_vm.$t("xEmailInvalidError")) +
                                " "
                            )
                          ]
                        )
                      ]
                    )
                  ])
                : _vm._e()
            ])
          : _vm._e()
      ]),
      _c("ModalFooter", [
        _c(
          "button",
          {
            staticClass: "btn btn-outline-warning",
            attrs: {
              type: "button",
              disabled: _vm.saving,
              "data-test-hook": "cancel-btn-add-edit-contact-info-modal"
            },
            on: {
              click: function($event) {
                _vm.resetModal(),
                  _vm.closeModal(
                    "AddEditContactInfoModal-" +
                      _vm.addEditContactModalInfo.apparatusId
                  )
              }
            }
          },
          [_vm._v(" " + _vm._s(_vm.$t("xCancel")) + " ")]
        ),
        _c(
          "button",
          {
            staticClass: "btn btn-warning",
            attrs: {
              type: "button",
              disabled: !_vm.canSave(),
              "data-test-hook": "save-btn-add-edit-contact-info-modal"
            },
            on: {
              click: function($event) {
                return _vm.saveContactInfo()
              }
            }
          },
          [
            _vm._v(" " + _vm._s(_vm.$t("xSave")) + " "),
            _vm.saving
              ? _c("div", {
                  staticClass: "ml-2 spinner-border text-muted",
                  attrs: {
                    "data-test-hook":
                      "save-btn-spinner-add-edit-contact-info-modal"
                  }
                })
              : _vm._e()
          ]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }