var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Modal",
    { attrs: { modalId: "EditFuelLevelWarningModal" } },
    [
      _c("ModalHeader", {
        attrs: {
          title: _vm.$t("xEditFuelLevelWarning"),
          icon: "far fa-warning",
          "data-test-hook": "editFuelLevelModalTitle"
        }
      }),
      _c("ModalBody", [
        _c(
          "div",
          { staticClass: "row", staticStyle: { "margin-bottom": "8px" } },
          [
            _c(
              "div",
              {
                staticClass: "col",
                attrs: { "data-test-hook": "warningLevelHeading" }
              },
              [_vm._v(" " + _vm._s(_vm.$t("xWarningLevel")) + " ")]
            ),
            _c("div", { staticClass: "col" }, [
              _c(
                "strong",
                {
                  staticClass: "float-right smaller-text",
                  attrs: { "data-test-hook": "fuel-level-warning-value" }
                },
                [_vm._v(_vm._s(_vm.level) + "%")]
              )
            ])
          ]
        ),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col" },
            [
              _c("b-input", {
                attrs: {
                  id: "fuel-level-range",
                  type: "range",
                  min: "20",
                  max: "80",
                  step: "1",
                  "data-test-hook": "fuel-level-warning"
                },
                model: {
                  value: _vm.level,
                  callback: function($$v) {
                    _vm.level = $$v
                  },
                  expression: "level"
                }
              })
            ],
            1
          )
        ]),
        _c(
          "div",
          { staticClass: "row", staticStyle: { "margin-bottom": "36px" } },
          [
            _c(
              "div",
              {
                staticClass: "col",
                attrs: { "data-test-hook": "min-fuel-level-warning" }
              },
              [_c("strong", { staticClass: "smaller-text" }, [_vm._v(" 20% ")])]
            ),
            _c(
              "div",
              {
                staticClass: "col",
                attrs: { "data-test-hook": "max-fuel-level-warning" }
              },
              [
                _c("strong", { staticClass: "float-right smaller-text" }, [
                  _vm._v(" 80% ")
                ])
              ]
            )
          ]
        ),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col" }, [
            _c(
              "span",
              {
                staticClass: "smaller-text",
                staticStyle: { color: "var(--carbon-700)" },
                attrs: { "data-test-hook": "modalBodyText" }
              },
              [
                _vm._v(
                  " " + _vm._s(_vm.$t("xEditFuelLevelWarningNotice")) + " "
                )
              ]
            )
          ])
        ])
      ]),
      _c("ModalFooter", [
        _c(
          "button",
          {
            staticClass: "btn btn-secondary",
            attrs: { "data-test-hook": "cancel-fuel-level-warning-button" },
            on: {
              click: function($event) {
                return _vm.closeModal("EditFuelLevelWarningModal")
              }
            }
          },
          [_vm._v(" " + _vm._s(_vm.$t("xCancel")) + " ")]
        ),
        _c(
          "button",
          {
            staticClass: "btn btn-warning",
            attrs: {
              disabled: _vm.saving,
              "data-test-hook": "save-fuel-level-warning-button"
            },
            on: {
              click: function($event) {
                return _vm.onSave()
              }
            }
          },
          [
            _vm._v(" " + _vm._s(_vm.$t("xSave")) + " "),
            _vm.saving
              ? _c("div", {
                  staticClass: "spinner-border text-muted ml-2",
                  attrs: { label: "Spinning" }
                })
              : _vm._e()
          ]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }