<template>
  <div
    class="modal hide-footer no-stacking"
    id="ConfirmDeleteAccountModal"
    data-test-hook="modal-title-confirm-delete-acct-modal"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h2 class="modal-title">
            <i class="far fa-keyboard colorOrange mr-1" data-test-hook="modal-heading-confirm-delete-acct-modal"></i>
            {{ $t('xConfirmDelete') }}
          </h2>
          <button
            class="btn btn-icon-button btn-icon-button--gray"
            @click="closeModal()"
            data-test-hook="x-close-btn-confirm-delete-acct-modal"
          >
            <i class="fas fa-times" />
          </button>
        </div>

        <div class="modal-body">
          <div align-h="around" class="row mt-3">
            <div class="col ml-3 mr-3" data-test-hook="modal-body-confirm-delete-acct-modal">
              {{ $t('xEnterDeleteInFieldBelow') }}
            </div>
          </div>
          <div align-h="around" class="row mt-4">
            <div class="col ml-3 mr-3">
              <div class="form-group" id="deleteConfirm-group">
                <input
                  class="form-control"
                  id="deleteConfirm"
                  v-model="$v.editDeleteConfrim.$model"
                  type="text"
                  :class="status($v.editDeleteConfrim)"
                  aria-describedby="deleteConfirm-invalid"
                  data-test-hook="enter-delete-input-confirm-delete-acct-modal"
                />
                <div
                  class="invalid-feedback"
                  id="deleteConfirm-invalid"
                  data-test-hook="enter-delete-error-text-confirm-delete-acct-modal"
                >
                  {{ $t('xPleaseEnterDeleteInAllCapitalLetters') }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            @click="closeModal()"
            :disabled="deleting"
            data-test-hook="cancel-btn-confirm-delete-acct-modal"
          >
            {{ $t('xCancel') }}
          </button>
          <button
            class="btn btn-primary"
            @click="deleteAccount"
            :disabled="editDeleteConfrim != 'DELETE' || deleting"
            data-test-hook="delete-btn-confirm-delete-acct-modal"
          >
            <div class="mr-2 spinner-border text-muted" v-if="deleting" style="width: 1.5rem; height: 1.5rem" />
            {{ $t('xConfirmDelete') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'

export default {
  props: ['confirmDeleteAccountModalInfo'],
  data() {
    return {
      deleting: false,
      editDeleteConfrim: null
    }
  },
  validations: {
    editDeleteConfrim: {
      required,
      minLength: minLength(6),
      maxLength: maxLength(6),
      isDelete(deleteConfirm) {
        return deleteConfirm == 'DELETE'
      }
    }
  },
  methods: {
    resetModal() {
      this.deleting = false
      this.$v.$reset()
    },
    closeModal() {
      this.editDeleteConfrim = null
      this.resetModal()
      document.getElementById('ConfirmDeleteAccountModal').style.display = 'none'
    },
    deleteAccount() {
      this.deleting = true

      this.$store
        .dispatch('disableAccount', {
          orgId: this.confirmDeleteAccountModalInfo.orgId
        })
        .then(response => {
          this.forceSignOut()
        })
        .catch(error => {
          this.$store.dispatch('displayToast', {
            title: this.$t('xError'),
            message: this.$t('xPendingChangesError'),
            variant: 'danger'
          })
        })
        .finally(() => {
          this.deleting = false
          this.closeModal()
        })
    }
  }
}
</script>
<style scoped></style>
