<template>
  <div v-if="source && source.organizationId">
    <!--Find Destination Email Modal-->
    <Modal :modalId="'destination-org-search' + source.organizationId" size="modal-lg">
      <ModalHeader title="Find Destination Email" icon="fad fa-exchange-alt"></ModalHeader>
      <ModalBody>
        <div v-if="!destinationOwner && !showSearchError" class="row">
          <div class="col">
            <div class="row">
              <div class="col">
                <fieldset class="form-group">
                  <legend class="bv-no-focus-ring col-form-label pt-0">
                    User Name (Email Address)
                  </legend>
                  <div>
                    <div class="input-group" role="group">
                      <input
                        class="form-control"
                        type="text"
                        maxlength="50"
                        v-on:keyup.enter="getDestinationOrg"
                        v-model="emailToSearch"
                      />
                    </div>
                  </div>
                </fieldset>
              </div>
            </div>
            <div class="row align-items-center">
              <div class="col">
                <button
                  type="button"
                  class="btn btn-warning btn-block"
                  @click="getDestinationOrg()"
                  :disabled="!emailToSearch || searchingForOwner"
                  data-test-hook="search-button-destinationOrgSearch-transfer-acct-modal"
                >
                  <i class="fas fa-search" />
                  Search
                  <div v-if="searchingForOwner" class="spinner-border text-warning ml-2" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </button>
              </div>
            </div>
            <ul class="mt-3">
              <li>Use this feature when the homeowner requests an email change</li>
              <li>If there is a new homeowner use the Homeowner Change button</li>
              <li>
                The owner must have a new Mobile Link account to move the generator to (shown as the destination email)
              </li>
              <li>
                This process will overwrite all current source owner information (name, address, phone, and email) with
                the destination owner information
              </li>
              <li>
                This process will update the email address for ALL generators to the destination owner email address
              </li>
              <li>
                The billing account information will remain intact - DO NOT USE THIS OPTION IF THE BILLING INFO SHOULD
                BE CHANGING
              </li>
            </ul>
          </div>
        </div>
        <div v-else class="row">
          <div class="col">
            {{ searchErrorMessage }}
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <button
          class="btn btn-outline-warning btn-block"
          type="button"
          @click="resetTransferModals()"
          data-test-hook="cancel-button-destinationOrgSearch-transfer-acct-modal"
        >
          Cancel
        </button>
      </ModalFooter>
    </Modal>

    <Modal :modalId="'verify-gen-transfer' + source.organizationId" size="modal-xl">
      <ModalHeader title="Transfer Account" icon="fad fa-exchange-alt"></ModalHeader>
      <ModalBody>
        <div class="row">
          <div class="col">
            <div v-if="apparatuses && apparatuses[0]" class="card shadow">
              <div class="card-body">
                <div v-if="apparatuses.length == 1">
                  <div class="card-title">{{ 'Generator' | formatLabelNoTranslation }}</div>
                  <p class="mt-2 tile-label">{{ 'Generator Name' | formatLabelNoTranslation }}</p>
                  <p class="tile-content">{{ apparatuses[0].apparatus.name }}</p>
                  <p class="mt-2 tile-label">{{ 'Serial Number' | formatLabelNoTranslation }}</p>
                  <p class="tile-content">
                    {{ apparatuses[0].apparatus.serialNumber }}
                    <button type="button" class="btn btn-link" v-clipboard="apparatuses[0].apparatus.serialNumber">
                      <i class="far fa-copy" />
                    </button>
                  </p>
                  <p class="mt-2 tile-label">{{ 'Device ID' | formatLabelNoTranslation }}</p>
                  <p class="tile-content">
                    {{ apparatuses[0].devices[0].id }}
                    <button type="button" class="btn btn-link" v-clipboard="apparatuses[0].devices[0].id">
                      <i class="far fa-copy" />
                    </button>
                  </p>
                  <p class="mt-2 tile-label">{{ 'Device Type' | formatLabelNoTranslation }}</p>
                  <p class="tile-content">{{ apparatuses[0].devices[0].type }}</p>
                  <p class="mt-2 tile-label">{{ 'Panel ID' | formatLabelNoTranslation }}</p>
                  <p class="tile-content">{{ apparatuses[0].apparatus.panelId || 'N/A' }}</p>
                  <p class="mt-2 tile-label">{{ 'Generator Address' | formatLabelNoTranslation }}</p>
                  <p class="tile-content">
                    {{ DisplayAddress(apparatuses[0].apparatus) }}
                    <button type="button" class="btn btn-link" v-clipboard="DisplayAddress(apparatuses[0].apparatus)">
                      <i class="far fa-copy" />
                    </button>
                  </p>
                </div>
                <div v-else-if="apparatuses.length > 1">
                  <div class="row">
                    <div class="tile-label colorRed col-12">
                      {{ $t('xThereAreMultipleGenerators') }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-2 card-deck">
              <div class="card shadow">
                <div class="card-body">
                  <div class="card-title">Source Owner: {{ source.organizationId }}</div>
                  <div class="row">
                    <div class="tile-label col-12">
                      {{ 'Name' | formatLabelNoTranslation }}
                    </div>
                    <div class="tile-content col-12">
                      {{ source.ownerInformation.firstName + ' ' + source.ownerInformation.lastName || 'N/A' }}
                    </div>
                  </div>
                  <div class="row">
                    <div class="tile-label col-12">
                      {{ 'Phone Number' | formatLabelNoTranslation }}
                    </div>
                    <div class="tile-content col-12">
                      {{ source.ownerInformation.phones[0] || 'N/A' }}
                    </div>
                  </div>
                  <div class="row">
                    <div class="tile-label col-12">
                      {{ 'Email Address' | formatLabelNoTranslation }}
                    </div>
                    <div class="tile-content col-12">
                      {{
                        source && source.ownerInformation && source.ownerInformation.emails[0]
                          ? source.ownerInformation.emails[0]
                          : 'N/A'
                      }}
                    </div>
                  </div>
                  <div class="row">
                    <div class="tile-label col-12">
                      {{ 'Owner Address' | formatLabelNoTranslation }}
                    </div>
                    <div class="tile-content col-12">
                      {{ DisplayAddress(source.ownerAddress) }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-body">
                  <div class="card-title">
                    Destination Owner:
                    {{ destinationOwner && destinationOwner.organizationId ? destinationOwner.organizationId : 'N/A' }}
                  </div>
                  <div class="row">
                    <div class="tile-label col-12">
                      {{ 'Name' | formatLabelNoTranslation }}
                    </div>
                    <div class="tile-content col-12">
                      {{
                        destinationOwner &&
                        destinationOwner.contact &&
                        destinationOwner.contact.firstName &&
                        destinationOwner.contact.lastName
                          ? destinationOwner.contact.firstName + ' ' + destinationOwner.contact.lastName
                          : 'N/A'
                      }}
                    </div>
                  </div>
                  <div class="row">
                    <div class="tile-label col-12">
                      {{ 'Phone Number' | formatLabelNoTranslation }}
                    </div>
                    <div class="tile-content col-12">
                      {{
                        destinationOwner && destinationOwner.contact && destinationOwner.contact.phones[0]
                          ? destinationOwner.contact.phones[0]
                          : 'N/A'
                      }}
                    </div>
                  </div>
                  <div class="row">
                    <div class="tile-label col-12">
                      {{ 'Email Address' | formatLabelNoTranslation }}
                    </div>
                    <div class="tile-content col-12">
                      {{
                        destinationOwner && destinationOwner.contact && destinationOwner.contact.emails[0]
                          ? destinationOwner.contact.emails[0]
                          : 'N/A'
                      }}
                    </div>
                  </div>
                  <div class="row">
                    <div class="tile-label col-12">
                      {{ 'Owner Address' | formatLabelNoTranslation }}
                    </div>
                    <div class="tile-content col-12">
                      {{
                        destinationOwner && destinationOwner.address ? DisplayAddress(destinationOwner.address) : 'N/A'
                      }}
                    </div>
                  </div>
                </div>
                <div class="card-footer">
                  <div class="row">
                    <div class="col">
                      <b-form-checkbox
                        v-model="$v.destinationOwnerVerified.$model"
                        :state="$v.destinationOwnerVerified.$model"
                        data-test-hook="info-verified-with-caller-checkbox-verifyGenTransfer-transfer-gen-modal"
                      >
                        Information Verified With Caller
                        <span v-if="!destinationOwnerVerified">
                          <i class="asterisk-text colorRed">required</i>
                        </span>
                      </b-form-checkbox>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="destinationOwnerLoaded" class="card shadow">
              <div class="card-body">
                <h4 class="card-title">Subscription Information</h4>
                <div class="row">
                  <div class="tile-label colorRed col-12">
                    {{ $t('xThereAreNoSubscriptions') }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <button class="btn btn-outline-warning" @click="resetTransferModals()" type="button">
          Cancel
        </button>
        <div id="completeTransferButton">
          <button
            class="btn btn-warning"
            :disabled="!destinationOwnerVerified"
            @click="openModal('transfer-confirmation' + source.organizationId)"
            data-test-hook="complete-transfer-button-verifyGenTranfser-transfer-acct-modal"
            type="button"
          >
            Complete Transfer
          </button>
        </div>
      </ModalFooter>
    </Modal>

    <!-- Transfer Confirmation Modal -->
    <Modal :modalId="'transfer-confirmation' + source.organizationId">
      <ModalHeader title="Transfer Confirmation" icon="fad fa-exchange-alt"></ModalHeader>
      <ModalBody>
        <div class="row">
          <div class="col">
            <h4>Are you sure you want to complete the transfer?</h4>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <button
          type="button"
          class="btn btn-outline-warning"
          :disabled="transferringGen"
          @click="closeModal('transfer-confirmation' + source.organizationId), resetTransferModals()"
          data-test-hook="cancel-button-transferConfirmation-transfer-acct-modal"
        >
          Cancel
        </button>
        <button
          type="button"
          class="btn btn-warning"
          :disabled="transferringGen"
          @click="completeTransfer()"
          data-test-hook="complete-transfer-transferConfirmation-transfer-acct-modal"
        >
          Complete Transfer
          <div class="spinner-border text-muted ml-2" v-if="transferringGen" />
        </button>
      </ModalFooter>
    </Modal>

    <!-- Transfer Successful Modal -->
    <Modal :modalId="'transfer-success' + source.organizationId">
      <ModalHeader title="Transfer Successful" icon="fad fa-exchange-alt"></ModalHeader>
      <ModalBody>
        <div class="row" v-if="!transferredWithError">
          <div class="col">
            The Transfer completed successfully.
          </div>
        </div>
        <div class="row" v-else>
          <div class="col colorRed">
            {{ transferFailureMessage }}
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <button
          type="button"
          class="btn btn-warning btn-block"
          @click="closeModal('transfer-success' + source.organizationId), emitSuccess()"
          data-test-hook="close-button-transferSuccess-transfer-acct-modal"
        >
          Close
        </button>
      </ModalFooter>
    </Modal>

    <!-- Transfer Failed Modal -->
    <Modal :modalId="'transfer-failure' + source.organizationId">
      <ModalHeader title="Transfer Failed" icon="fad fa-exchange-alt"></ModalHeader>
      <ModalBody>
        <div class="row">
          <div class="col">
            {{ transferFailureMessage }}
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <button
          type="button"
          class="btn btn-warning"
          block
          @click="closeModal('transfer-failure' + source.organizationId)"
          data-test-hook="close-button-transferFailure-transfer-acct-modal"
        >
          Close
        </button>
      </ModalFooter>
    </Modal>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { required, requiredIf, minLength, maxLength, email, numeric } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'
import * as Constants from '@/constants'

export default {
  mixins: [validationMixin],
  props: ['source', 'currentSubscription', 'transferType', 'apparatuses'],
  data() {
    return {
      emailToSearch: null,
      destinationOwner: null,
      destinationOwnerLoaded: false,
      searchingForOwner: false,
      destinationOwnerVerified: false,
      showSearchError: false,
      searchErrorMessage: null,
      transferFailureMessage: null,
      transferringGen: false,
      transferredWithError: false,
      newEmail: null,
      subscription: null,
      hasMultipleSubscriptions: false,
      apparatus: null,
      fields: [
        {
          key: 'generatorName',
          label: 'Generator Name',
          formatter: (value, key, item) => {
            return { name: item.apparatus.name }
          }
        },
        {
          key: 'serialNumber',
          label: 'Serial Number',
          formatter: (value, key, item) => {
            return { serialNumber: item.apparatus.serialNumber }
          }
        },
        {
          key: 'generatorAddress',
          label: 'Generator Address',
          formatter: (value, key, item) => {
            if (!item.apparatus) {
              return 'N/A'
            }
            if (item.apparatus.line2) {
              return {
                address:
                  item.apparatus.line1 +
                  ' ' +
                  item.apparatus.line2 +
                  ' ' +
                  item.apparatus.city +
                  ', ' +
                  item.apparatus.region +
                  ' ' +
                  item.apparatus.postalCode
              }
            } else
              return {
                address:
                  item.apparatus.line1 +
                  ' ' +
                  item.apparatus.city +
                  ', ' +
                  item.apparatus.region +
                  ' ' +
                  item.apparatus.postalCode
              }
          }
        }
      ]
    }
  },
  validations: {
    destinationOwnerVerified: {
      required
    },
    transferWithFreeSubscription: {
      required
    }
  },
  computed: {
    ...mapState({
      user: state => state.account.user
    })
  },
  methods: {
    emitSuccess() {
      this.$emit('transferEmission', {
        criteria: 'Email',
        value: this.newEmail
      })
    },
    completeTransfer() {
      this.transferringGen = true
      let request = {
        body: {
          sourceOrganizationId: this.source.organizationId,
          destinationOrganizationId: this.destinationOwner.organizationId,
          setUpFreeSubscription: this.transferWithFreeSubscription == null ? false : this.transferWithFreeSubscription
        },
        type: this.transferType
      }
      this.$store
        .dispatch('completeTransfer', request)
        .then(response => {
          this.transferringGen = false
          if (response.data) {
            this.transferredWithError = true
            this.transferFailureMessage = response.data
          }
          this.openModal('transfer-success' + this.source.organizationId)
          let event = this.createEventLogObjectFromAdmin(
            this.source,
            this.currentSubscription,
            this.user,
            Constants.TransferEventType,
            request.body.setUpFreeSubscription ? Constants.FreeWifiEthProductId : null
          )
          this.$store.dispatch('logEvent', event)
        })
        .catch(error => {
          if (error.response.data.status == 401) {
            this.$store.dispatch('displayToast', {
              title: this.$t('xError'),
              message: 'Your session has expired, please re-log in.',
              variant: 'danger'
            })
            this.forceSignOut()
          } else {
            this.transferFailureMessage = error.response.data
            this.closeModal('verify-gen-transfer' + this.source.organizationId)
            this.closeModal('transfer-confirmation' + this.source.organizationId)
            this.closeModal('destination-org-search' + this.source.organizationId)
            this.openModal('transfer-failure' + this.source.organizationId)
          }
        })
    },
    getDestinationOrg() {
      this.searchingForOwner = true
      this.newEmail = this.emailToSearch
      let request = {
        email: this.emailToSearch,
        type: this.transferType
      }
      this.emailToSearch = null
      this.$store
        .dispatch('getDestinationOrg', request)
        .then(response => {
          this.destinationOwner = _.cloneDeep(response.data)
          if (this.destinationOwner && this.destinationOwner.organizationId == this.source.organizationId) {
            this.searchingForOwner = false
            this.showSearchError = true
            this.searchErrorMessage = 'You cannot transfer generators to the same account.'
          } else {
            this.searchingForOwner = false
            this.destinationOwnerLoaded = true
            this.openModal('verify-gen-transfer' + this.source.organizationId)
          }
        })
        .catch(error => {
          if (error.response.data.status == 401) {
            this.$store.dispatch('displayToast', {
              title: this.$t('xError'),
              message: 'Your session has expired, please re-log in.',
              variant: 'danger'
            })
            this.forceSignOut()
          } else {
            this.searchingForOwner = false
            this.showSearchError = true
            this.searchErrorMessage = error.response.data
          }
        })
    },
    resetTransferModals() {
      this.emailToSearch = null
      this.destinationOwner = null
      this.destinationOwnerVerified = false
      this.showSearchError = false
      this.searchErrorMessage = null
      this.searchingForOwner = false
      this.destinationOwnerLoaded = false
      this.transferringGen = false
      this.closeModal('destination-org-search' + this.source.organizationId)
      this.closeModal('verify-gen-transfer' + this.source.organizationId)
    },
    async multipleSubscriptions() {
      if (!this.apparatuses) {
        this.subscription = null
        return false
      }
      let apparatusesIndex = 0
      let subFound = false
      while (apparatusesIndex < this.apparatuses.length) {
        if (this.apparatuses[apparatusesIndex].billing) {
          if (subFound == true) {
            this.subscription = null
            this.apparatus = null
            return true
          } else {
            subFound = true
            this.subscription = await this.getSubscription(
              this.source.organizationId,
              this.apparatuses[apparatusesIndex].id
            )
            this.apparatus = await this.getApparatus(this.apparatuses[apparatusesIndex].id)
          }
        }
        apparatusesIndex++
      }
      return false
    },
    async getSubscription(orgId, apparatusId) {
      let result = null
      await this.$store
        .dispatch('getApparatusSubscription', {
          organizationId: orgId,
          apparatusId: apparatusId
        })
        .then(response => {
          result = response.data
        })
        .catch(error => {})
      return result
    },
    async doApparatusesWaitAction() {
      this.hasMultipleSubscriptions = await this.multipleSubscriptions()
    },
    async getApparatus(apparatusId) {
      let result = null
      await this.$store
        .dispatch('getApparatusById', apparatusId)
        .then(response => {
          result = response.data
        })
        .catch(error => {})
      return result
    }
  },
  async mounted() {
    this.hasMultipleSubscriptions = await this.multipleSubscriptions()
  },
  watch: {
    apparatuses: function(newVal, oldVal) {
      if (newVal) {
        this.doApparatusesWaitAction()
      }
    }
  }
}
</script>
<style scoped>
.search-table {
  font-size: 0.75rem;
}
</style>
