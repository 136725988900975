<template>
  <div>
    <b-row class="text-center float-center">
      <b-col>
        <b-spinner style="width: 10rem; height: 10rem;" variant="warning" label="Spinning"></b-spinner>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      ipAddress: null,
      serialNumber: null,
      modelNumber: null,
      btId: null
    }
  },
  methods: {
    isRegistered() {
      this.$store
        .dispatch('isRegistered', this.serialNumber)
        .then(response => {
          if (response.data) {
            this.trackAppInsightsEvent('qrScanEvent', {
              isRegistered: response.data,
              sn: this.serialNumber,
              mn: this.modelNumber,
              ipAddress: this.ipAddress,
              btId: this.btId,
              OS: this.getOS()
            })
            //redirect to new download marketing page
            this.$router.push('/download')
          } else {
            window.location.assign(
              process.env.VUE_APP_REGISTER_GEN_URL +
                `?SerialNbr=${btoa(this.serialNumber)}&MaterialNbr=${btoa(this.modelNumber)}&AutoEnroll=${btoa(
                  true
                )}&ShowSurvey=${btoa(false)}&ReturnURL=${btoa(this.vueAppBaseAddress() + 'download')}`
            )
          }
        })
        .catch(error => {
          window.location.assign(
            process.env.VUE_APP_REGISTER_GEN_URL +
              `?SerialNbr=${btoa(this.serialNumber)}&MaterialNbr=${btoa(this.modelNumber)}&AutoEnroll=${btoa(
                true
              )}&ShowSurvey=${btoa(false)}&ReturnURL=${btoa(this.vueAppBaseAddress() + 'download')}`
          )
        })
    }
  },
  watch: {
    ipAddress: function(newVal, oldVal) {
      if (newVal) {
        let request = {
          ipAddress: newVal,
          serialNumber: this.serialNumber,
          modelNumber: this.modelNumber,
          blueToothId: this.btId
        }
        this.$store
          .dispatch('saveQrScan', request)
          .then(response => {
            this.isRegistered()
          })
          .catch(error => {})
      }
    }
  },
  mounted() {
    const ipify = require('ipify2')
    ipify
      .ipv4()
      .then(v4 => {
        this.ipAddress = v4
      })
      .catch(error => {})
    this.serialNumber = this.$route.params.serialNumber
    this.modelNumber = this.$route.params.modelNumber
    this.btId = this.$route.params.btId
  }
}
</script>
