var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Modal",
    {
      attrs: {
        modalId:
          "EditTextSmsPreferencesModal-" +
          _vm.editTextSmsPreferencesModalInfo.apparatusId
      }
    },
    [
      _c(
        "ModalHeader",
        {
          attrs: {
            title: _vm.$t("xEditTextSmsPreferences"),
            icon: "fas fa-user-circle"
          }
        },
        [
          _c(
            "button",
            {
              staticClass: "btn btn-icon-button btn-icon-button--gray",
              attrs: { "data-test-hook": "x-button-edit-sms-prefs-modal" },
              on: {
                click: function($event) {
                  _vm.saving
                    ? null
                    : _vm.closeModal(
                        "EditTextSmsPreferencesModal-" +
                          _vm.editValue.apparatusId
                      )
                }
              }
            },
            [_c("i", { staticClass: "fas fa-times" })]
          )
        ]
      ),
      _c("ModalBody", [
        _vm.editValue
          ? _c("div", [
              _c("div", { staticClass: "row collapseBackground" }, [
                _c(
                  "div",
                  { staticClass: "col" },
                  [
                    _vm._l(_vm.settings, function(setting) {
                      return _c(
                        "div",
                        { key: setting.type, class: setting.rowClass },
                        [
                          !setting.first &&
                          _vm.settingTypeList(
                            _vm.editValue.currentPreferences,
                            setting.type
                          )
                            ? _c("hr", { staticClass: "row" })
                            : _vm._e(),
                          _vm.settingTypeList(
                            _vm.editValue.currentPreferences,
                            setting.type
                          ) &&
                          _vm.isEnrolledInVPP(
                            setting,
                            _vm.editTextSmsPreferencesModalInfo
                          )
                            ? _c(
                                "div",
                                {
                                  staticClass: "row",
                                  attrs: { "align-v": "center" }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "col-1",
                                      attrs: {
                                        "data-test-hook": setting.title
                                          .toLowerCase()
                                          .replace(/\s/g, "-")
                                          .concat("-icon-edit-sms-prefs-modal")
                                      }
                                    },
                                    [
                                      _c("i", {
                                        class: _vm.iconForDisplay(
                                          setting.type,
                                          setting.icon
                                        )
                                      })
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "col pl-0",
                                      attrs: {
                                        "data-test-hook": setting.title
                                          .toLowerCase()
                                          .replace(/\s/g, "-")
                                          .concat("-name-edit-sms-prefs-modal")
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(setting.title) + ": "
                                      ),
                                      _c(
                                        "b",
                                        {
                                          staticClass: "pl-0",
                                          attrs: {
                                            "data-test-hook": setting.title
                                              .toLowerCase()
                                              .replace(/\s/g, "-")
                                              .concat(
                                                "-status-edit-sms-prefs-modal"
                                              )
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.editValue.currentPreferences.preferences.find(
                                                  p => p.type == setting.type
                                                ).receiveSms
                                                  ? "ON"
                                                  : "OFF"
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _c("div", { staticClass: "ml-1 col-auto" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "custom-control custom-switch b-custom-control-lg"
                                      },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.editValue.currentPreferences.preferences.find(
                                                p => p.type == setting.type
                                              ).receiveSms,
                                              expression:
                                                "editValue.currentPreferences.preferences.find(p => p.type == setting.type).receiveSms"
                                            }
                                          ],
                                          staticClass: "custom-control-input",
                                          attrs: {
                                            type: "checkbox",
                                            id: _vm.message + setting.title,
                                            "data-test-hook": setting.title
                                              .toLowerCase()
                                              .replace(/\s/g, "-")
                                              .concat(
                                                "-switch-edit-sms-prefs-modal"
                                              )
                                          },
                                          domProps: {
                                            checked: Array.isArray(
                                              _vm.editValue.currentPreferences.preferences.find(
                                                p => p.type == setting.type
                                              ).receiveSms
                                            )
                                              ? _vm._i(
                                                  _vm.editValue.currentPreferences.preferences.find(
                                                    p => p.type == setting.type
                                                  ).receiveSms,
                                                  null
                                                ) > -1
                                              : _vm.editValue.currentPreferences.preferences.find(
                                                  p => p.type == setting.type
                                                ).receiveSms
                                          },
                                          on: {
                                            change: function($event) {
                                              var $$a = _vm.editValue.currentPreferences.preferences.find(
                                                  p => p.type == setting.type
                                                ).receiveSms,
                                                $$el = $event.target,
                                                $$c = $$el.checked
                                                  ? true
                                                  : false
                                              if (Array.isArray($$a)) {
                                                var $$v = null,
                                                  $$i = _vm._i($$a, $$v)
                                                if ($$el.checked) {
                                                  $$i < 0 &&
                                                    _vm.$set(
                                                      _vm.editValue.currentPreferences.preferences.find(
                                                        p =>
                                                          p.type == setting.type
                                                      ),
                                                      "receiveSms",
                                                      $$a.concat([$$v])
                                                    )
                                                } else {
                                                  $$i > -1 &&
                                                    _vm.$set(
                                                      _vm.editValue.currentPreferences.preferences.find(
                                                        p =>
                                                          p.type == setting.type
                                                      ),
                                                      "receiveSms",
                                                      $$a
                                                        .slice(0, $$i)
                                                        .concat(
                                                          $$a.slice($$i + 1)
                                                        )
                                                    )
                                                }
                                              } else {
                                                _vm.$set(
                                                  _vm.editValue.currentPreferences.preferences.find(
                                                    p => p.type == setting.type
                                                  ),
                                                  "receiveSms",
                                                  $$c
                                                )
                                              }
                                            }
                                          }
                                        }),
                                        _c("label", {
                                          staticClass: "custom-control-label",
                                          attrs: {
                                            for: _vm.message + setting.title
                                          }
                                        })
                                      ]
                                    )
                                  ])
                                ]
                              )
                            : _vm._e(),
                          setting.type == _vm.preferenceTypeTankFuelWarning &&
                          _vm.settingTypeList(
                            _vm.editValue.currentPreferences,
                            setting.type
                          )
                            ? _c(
                                "div",
                                {
                                  staticClass: "row align-items-center mt-3",
                                  attrs: { "align-v": "center" }
                                },
                                [
                                  _c("div", { staticClass: "col-auto ml-3" }, [
                                    _c("i", {
                                      staticClass:
                                        "fa-light fa-triangle-exclamation"
                                    })
                                  ]),
                                  _c("div", { staticClass: "col-5 pl-0" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("xWarningLevel")) +
                                        " "
                                    )
                                  ]),
                                  _c("div", { staticClass: "col-5 ml-auto" }, [
                                    _c("div", { staticClass: "input-group" }, [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.$v.editValue
                                                .currentPreferences.threshold1
                                                .$model,
                                            expression:
                                              "$v.editValue.currentPreferences.threshold1.$model"
                                          },
                                          {
                                            name: "mask",
                                            rawName: "v-mask",
                                            value: "##",
                                            expression: "'##'"
                                          }
                                        ],
                                        staticClass: "form-control text-right",
                                        attrs: {
                                          type: "text",
                                          state: _vm.validState(
                                            _vm.$v.editValue.currentPreferences
                                              .threshold1
                                          ),
                                          "aria-describedby":
                                            "tankThreshold-invalid",
                                          maxlength: "2",
                                          disabled: !_vm.editValue.currentPreferences.preferences.find(
                                            p => p.type == setting.type
                                          ).receiveSms
                                        },
                                        domProps: {
                                          value:
                                            _vm.$v.editValue.currentPreferences
                                              .threshold1.$model
                                        },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              _vm.$v.editValue
                                                .currentPreferences.threshold1,
                                              "$model",
                                              $event.target.value
                                            )
                                          }
                                        }
                                      }),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "input-group-append",
                                          attrs: { "is-text": true }
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "input-group-text" },
                                            [_vm._v("%")]
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "invalid-feedback",
                                          attrs: { id: "tankThreshold-invalid" }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("xInvalidTankThreshold")
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    ])
                                  ])
                                ]
                              )
                            : _vm._e()
                        ]
                      )
                    }),
                    _vm.editTextSmsPreferencesModalInfo.apparatusType !=
                    _vm.fuelTankApparatusType
                      ? _c("div", { class: _vm.noSignalAlert.rowClass }, [
                          _vm.somethingInList
                            ? _c("hr", { staticClass: "row" })
                            : _vm._e(),
                          _c(
                            "div",
                            {
                              staticClass: "row",
                              attrs: { "align-v": "center" }
                            },
                            [
                              _c("div", { staticClass: "col-1" }, [
                                _c("i", {
                                  class: _vm.iconForDisplay(
                                    _vm.noSignalAlert.type,
                                    _vm.noSignalAlert.icon
                                  )
                                })
                              ]),
                              _c("div", { staticClass: "col pl-0" }, [
                                _vm._v(
                                  " " + _vm._s(_vm.noSignalAlert.title) + ": "
                                ),
                                _c("b", { staticClass: "pl-0" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.editValue.currentPreferences.preferences.find(
                                          p => p.type == _vm.noSignalAlert.type
                                        ).receiveSms
                                          ? "ON"
                                          : "OFF"
                                      ) +
                                      " "
                                  )
                                ])
                              ]),
                              _c("div", { staticClass: "col-auto ml-1" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "custom-control custom-switch b-custom-control-lg",
                                    class: _vm.editValue.currentPreferences.preferences.find(
                                      p => p.type == _vm.noSignalAlert.type
                                    ).receiveSms
                                      ? "switch-success"
                                      : null
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.editValue.currentPreferences.preferences.find(
                                            p =>
                                              p.type == _vm.noSignalAlert.type
                                          ).receiveSms,
                                          expression:
                                            "\n                      editValue.currentPreferences.preferences.find(p => p.type == noSignalAlert.type).receiveSms\n                    "
                                        }
                                      ],
                                      staticClass: "custom-control-input",
                                      attrs: {
                                        type: "checkbox",
                                        id: _vm.noSignalAlert.title,
                                        "data-test-hook": _vm.noSignalAlert.title
                                          .toLowerCase()
                                          .replace(/\s/g, "-")
                                          .concat(
                                            "-switch-edit-sms-prefs-modal"
                                          )
                                      },
                                      domProps: {
                                        checked: Array.isArray(
                                          _vm.editValue.currentPreferences.preferences.find(
                                            p =>
                                              p.type == _vm.noSignalAlert.type
                                          ).receiveSms
                                        )
                                          ? _vm._i(
                                              _vm.editValue.currentPreferences.preferences.find(
                                                p =>
                                                  p.type ==
                                                  _vm.noSignalAlert.type
                                              ).receiveSms,
                                              null
                                            ) > -1
                                          : _vm.editValue.currentPreferences.preferences.find(
                                              p =>
                                                p.type == _vm.noSignalAlert.type
                                            ).receiveSms
                                      },
                                      on: {
                                        change: function($event) {
                                          var $$a = _vm.editValue.currentPreferences.preferences.find(
                                              p =>
                                                p.type == _vm.noSignalAlert.type
                                            ).receiveSms,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? true : false
                                          if (Array.isArray($$a)) {
                                            var $$v = null,
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                _vm.$set(
                                                  _vm.editValue.currentPreferences.preferences.find(
                                                    p =>
                                                      p.type ==
                                                      _vm.noSignalAlert.type
                                                  ),
                                                  "receiveSms",
                                                  $$a.concat([$$v])
                                                )
                                            } else {
                                              $$i > -1 &&
                                                _vm.$set(
                                                  _vm.editValue.currentPreferences.preferences.find(
                                                    p =>
                                                      p.type ==
                                                      _vm.noSignalAlert.type
                                                  ),
                                                  "receiveSms",
                                                  $$a
                                                    .slice(0, $$i)
                                                    .concat($$a.slice($$i + 1))
                                                )
                                            }
                                          } else {
                                            _vm.$set(
                                              _vm.editValue.currentPreferences.preferences.find(
                                                p =>
                                                  p.type ==
                                                  _vm.noSignalAlert.type
                                              ),
                                              "receiveSms",
                                              $$c
                                            )
                                          }
                                        }
                                      }
                                    }),
                                    _c("label", {
                                      staticClass: "custom-control-label",
                                      attrs: { for: _vm.noSignalAlert.title }
                                    })
                                  ]
                                )
                              ])
                            ]
                          )
                        ])
                      : _vm._e()
                  ],
                  2
                )
              ])
            ])
          : _vm._e()
      ]),
      _c("ModalFooter", [
        _c(
          "button",
          {
            staticClass: "btn btn-outline-warning",
            attrs: {
              disabled: _vm.saving,
              "data-test-hook": "cancel-button-edit-sms-prefs-modal"
            },
            on: {
              click: function($event) {
                return _vm.closeModal(
                  "EditTextSmsPreferencesModal-" +
                    _vm.editTextSmsPreferencesModalInfo.apparatusId
                )
              }
            }
          },
          [_vm._v(" " + _vm._s(_vm.$t("xCancel")) + " ")]
        ),
        _c(
          "button",
          {
            staticClass: "btn btn-warning",
            attrs: {
              disabled: !_vm.validThreshold || _vm.saving,
              "data-test-hook": "save-button-edit-sms-prefs-modal"
            },
            on: {
              click: function($event) {
                return _vm.save()
              }
            }
          },
          [
            _vm._v(" " + _vm._s(_vm.$t("xSave")) + " "),
            _vm.saving
              ? _c("div", {
                  staticClass: "ml-2 spinner-border text-muted",
                  attrs: {
                    "data-test-hook": "save-button-spinner-edit-sms-prefs-modal"
                  }
                })
              : _vm._e()
          ]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }