<template>
  <Modal :modalId="'AddEditContactInfoModal-' + addEditContactModalInfo.apparatusId">
    <ModalHeader :title="modalTitle" icon="fas fa-user-circle">
      <button
        class="btn btn-icon-button btn-icon-button--gray"
        @click="
          saving ? null : (resetModal(), closeModal('AddEditContactInfoModal-' + addEditContactModalInfo.apparatusId))
        "
        data-test-hook="x-button-add-edit-contact"
      >
        <i class="fas fa-times" />
      </button>
    </ModalHeader>
    <ModalBody>
      <div
        class="row"
        v-if="
          addEditContactModalInfo || (addEditContactModalInfo != null && addEditContactModalInfo.actionType == 'add')
        "
      >
        <div class="col" v-if="addEditContactModalInfo.infoType == 'phone'">
          <div class="form-group" id="phone-group">
            <label class="d-block" for="phone">{{ $t('xPhoneNumber') }}</label>
            <input
              class="form-control"
              id="phone"
              v-model="$v.editPhone.$model"
              maxlength="12"
              type="text"
              :state="validState($v.editPhone)"
              aria-describedby="phoneNumber-invalid"
            />
            <div class="invalid-feedback" id="phoneNumber-invalid">
              {{ $t('xRequiredField') }}. {{ $t('xPleaseEnterAValidPhoneNumber') }}
            </div>
          </div>
        </div>
        <div class="col" v-if="addEditContactModalInfo.infoType == 'email'">
          <div class="form-group" id="email-group">
            <label class="d-block" for="email">{{ $t('xEmailAddress') }}</label>
            <input
              class="form-control"
              id="email"
              v-model="$v.editEmail.$model"
              maxlength="70"
              type="text"
              :state="validState($v.editEmail)"
              aria-describedby="email-invalid"
            />
            <div class="invalid-feedback" id="email-invalid">
              {{ $t('xRequiredField') }}. {{ $t('xEmailInvalidError') }}
            </div>
          </div>
        </div>
      </div>
    </ModalBody>
    <ModalFooter>
      <button
        type="button"
        class="btn btn-outline-warning"
        @click="resetModal(), closeModal('AddEditContactInfoModal-' + addEditContactModalInfo.apparatusId)"
        :disabled="saving"
        data-test-hook="cancel-btn-add-edit-contact-info-modal"
      >
        {{ $t('xCancel') }}
      </button>
      <button
        type="button"
        class="btn btn-warning"
        @click="saveContactInfo()"
        :disabled="!canSave()"
        data-test-hook="save-btn-add-edit-contact-info-modal"
      >
        {{ $t('xSave') }}
        <div
          class="ml-2 spinner-border text-muted"
          v-if="saving"
          data-test-hook="save-btn-spinner-add-edit-contact-info-modal"
        ></div>
      </button>
    </ModalFooter>
  </Modal>
</template>

<script>
import { required, requiredIf, minLength, maxLength, email, numeric } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'

export default {
  mixins: [validationMixin],
  props: ['addEditContactModalInfo'],
  data() {
    return {
      editPhone: null,
      editEmail: null,
      saving: false
    }
  },
  validations: {
    editPhone: {
      required: requiredIf(function() {
        return this.addEditContactModalInfo.infoType == 'phone'
      }),
      minLength: minLength(10),
      maxLength: maxLength(12),
      numeric,
      startsWithZero(phoneNumber) {
        const regex = /^[1-9]{1}[0-9]{9,11}$/
        return regex.test(phoneNumber)
      }
    },
    editEmail: {
      required: requiredIf(function() {
        return this.addEditContactModalInfo.infoType == 'email'
      }),
      email
    }
  },
  computed: {
    modalTitle() {
      if (this.addEditContactModalInfo) {
        let title = ''
        if (this.addEditContactModalInfo.actionType == 'add') {
          title += this.$t('xAdd') + ' '
        }
        if (this.addEditContactModalInfo.actionType == 'edit') {
          title += this.$t('xEdit') + ' '
        }

        if (this.addEditContactModalInfo.infoType == 'phone') {
          title += this.$t('xPhoneNumber')
        }
        if (this.addEditContactModalInfo.infoType == 'email') {
          title += this.$t('xEmailAddress')
        }
        return title
      }
      return ''
    }
  },
  methods: {
    canSave() {
      if (!this.$v.$anyError && !this.saving && this.addEditContactModalInfo) {
        if (this.addEditContactModalInfo.infoType == 'phone') {
          if (this.editPhone) return true
        } else {
          if (this.editEmail) return true
        }
      } else return false
    },
    resetModal() {
      this.$v.$reset()
      this.saving = false
    },
    saveContactInfo() {
      this.saving = true
      this.$emit('saveAddEdit', {
        actionType: this.addEditContactModalInfo.actionType,
        infoType: this.addEditContactModalInfo.infoType,
        index: this.addEditContactModalInfo.index,
        value: this.addEditContactModalInfo.infoType == 'email' ? this.editEmail : this.editPhone,
        prefIndex: this.addEditContactModalInfo.prefIndex,
        contacts: this.addEditContactModalInfo.contacts,
        apparatusId: this.addEditContactModalInfo.apparatusId
      })
      this.resetModal()
      this.closeModal('AddEditContactInfoModal-' + this.addEditContactModalInfo.apparatusId)
    }
  },
  watch: {
    addEditContactModalInfo: {
      handler: function(newVal, oldVal) {
        if (newVal.infoType == 'email') {
          this.editEmail = _.cloneDeep(newVal.value)
        }
        if (newVal.infoType == 'phone') {
          this.editPhone = _.cloneDeep(newVal.value)
        }
      },
      deep: true
    }
  },
  mounted() {
    if (this.addEditContactModalInfo.infoType == 'email') {
      this.editEmail = _.cloneDeep(this.addEditContactModalInfo.value)
    }
    if (this.addEditContactModalInfo.infoType == 'phone') {
      this.editPhone = _.cloneDeep(this.addEditContactModalInfo.value)
    }
  }
}
</script>

<style scoped></style>
