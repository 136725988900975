var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "Modal",
        { attrs: { modalId: "upgrade-free-sub-step-1", size: "modal-lg" } },
        [
          _c(
            "ModalHeader",
            {
              attrs: {
                title: _vm.$t("xManageSubscription"),
                icon: "far fa-badge-dollar colorOrange mr-1"
              }
            },
            [
              _c(
                "button",
                {
                  staticClass: "btn btn-icon-button btn-icon-button--gray",
                  attrs: {
                    type: "button",
                    "data-test-hook": "x-button-step1-upgrade-free-sub"
                  },
                  on: {
                    click: function($event) {
                      return _vm.closeModals()
                    }
                  }
                },
                [_c("i", { staticClass: "fas fa-times" })]
              )
            ]
          ),
          _c("ModalBody", [
            _vm.currentSubscription[0].productId == _vm.dealerManaged
              ? _c("div", [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("xYourDealerHasAManagedSubscription")) +
                          " "
                      )
                    ])
                  ])
                ])
              : _vm.availableSubscriptionProducts && !_vm.gettingSubscriptions
              ? _c("div", [
                  _c(
                    "div",
                    { staticClass: "row" },
                    _vm._l(_vm.availableSubscriptionProducts, function(
                      product,
                      index
                    ) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass: "col-12 col-md-6 mb-3",
                          attrs: {
                            "data-test-hook":
                              "set-selected-product-clickable-card-step1-upgrade-free-sub" +
                              index
                          }
                        },
                        [
                          product.durationInMonths > 0
                            ? _c(
                                "div",
                                { staticClass: "available-subscription" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex flex-column align-items-center"
                                    },
                                    [
                                      product.durationInMonths == 12
                                        ? _c("div", [
                                            _c(
                                              "h3",
                                              {
                                                staticClass: "mb-0 save-banner"
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("xBestValueSave")
                                                  ) +
                                                    " " +
                                                    _vm._s(
                                                      _vm.getSubscriptionSavings(
                                                        product
                                                      )
                                                    ) +
                                                    "%"
                                                )
                                              ]
                                            )
                                          ])
                                        : _c("div", {
                                            staticClass: "banner-spacer"
                                          }),
                                      _c("h1", { staticClass: "mb-0" }, [
                                        _vm._v(_vm._s(product.name))
                                      ]),
                                      _c("h2", [
                                        _vm._v(
                                          _vm._s(_vm.getProductCost(product))
                                        )
                                      ]),
                                      _c(
                                        "button",
                                        {
                                          staticClass: "btn btn-primary",
                                          attrs: {
                                            type: "button",
                                            "data-test-hook":
                                              "select-button-step1-upgrade-free-sub" +
                                              index
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.setSelectedProduct(
                                                product
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t("xSelect")) +
                                              " "
                                          ),
                                          _vm.paying && product.cost == 0
                                            ? _c("span", {
                                                staticClass:
                                                  "spinner-borderer ml-2"
                                              })
                                            : _vm._e()
                                        ]
                                      )
                                    ]
                                  ),
                                  _vm.paidFeatures
                                    ? _c(
                                        "div",
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "row mb-2 mt-3" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "col" },
                                                [
                                                  _c("p", [
                                                    _c("strong", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "xIncludedFeatures"
                                                          )
                                                        )
                                                      )
                                                    ])
                                                  ]),
                                                  _c("p", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.paidFeatures
                                                          .subscriptionDescription
                                                      )
                                                    )
                                                  ])
                                                ]
                                              )
                                            ]
                                          ),
                                          _vm._l(
                                            _vm.paidFeatures.features,
                                            function(feature, index) {
                                              return _c(
                                                "div",
                                                {
                                                  key: index,
                                                  staticClass: "row mb-2"
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "col" },
                                                    [
                                                      _c("p", [
                                                        _vm._v(_vm._s(feature))
                                                      ])
                                                    ]
                                                  )
                                                ]
                                              )
                                            }
                                          )
                                        ],
                                        2
                                      )
                                    : _c("div", [
                                        _c("span", {
                                          staticClass:
                                            "spinner-border text-warning",
                                          staticStyle: {
                                            width: "5rem",
                                            height: "5rem"
                                          }
                                        })
                                      ])
                                ]
                              )
                            : _c(
                                "div",
                                { staticClass: "available-subscription" },
                                [
                                  _vm.freeFeatures
                                    ? _c(
                                        "div",
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "row mb-2 mt-3" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "col" },
                                                [
                                                  _c("p", [
                                                    _c("strong", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "xIncludedFeatures"
                                                          )
                                                        )
                                                      )
                                                    ])
                                                  ]),
                                                  _c("p", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.freeFeatures
                                                          .subscriptionDescription
                                                      )
                                                    )
                                                  ])
                                                ]
                                              )
                                            ]
                                          ),
                                          _vm._l(
                                            _vm.freeFeatures.features,
                                            function(feature, index) {
                                              return _c(
                                                "div",
                                                {
                                                  key: index,
                                                  staticClass: "row mb-2"
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "col" },
                                                    [
                                                      _c("p", [
                                                        _vm._v(_vm._s(feature))
                                                      ])
                                                    ]
                                                  )
                                                ]
                                              )
                                            }
                                          )
                                        ],
                                        2
                                      )
                                    : _c("div", [
                                        _c("span", {
                                          staticClass:
                                            "spinner-border text-warning",
                                          staticStyle: {
                                            width: "5rem",
                                            height: "5rem"
                                          }
                                        })
                                      ])
                                ]
                              )
                        ]
                      )
                    }),
                    0
                  ),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-auto" }, [
                      !_vm.currentApparatusDetails.enrolledInVpp
                        ? _c(
                            "a",
                            {
                              attrs: {
                                href: "#",
                                target: "_self",
                                "data-test-hook":
                                  "cancel-remote-monitoring-link"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.emitCancelFlow()
                                }
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("xCancelRemoteMonitoring")) + " »"
                              )
                            ]
                          )
                        : _c("p", { staticClass: "asterisk-text" }, [
                            _vm._v(_vm._s(_vm.$t("xVppCannotCancelMessage")))
                          ])
                    ])
                  ]),
                  _c("div", { staticClass: "row mt-3" }, [
                    _c("div", { staticClass: "col" }, [
                      _c("p", { staticClass: "asterisk-text" }, [
                        _vm._v(
                          "*" +
                            _vm._s(
                              _vm.$t(
                                "xPushNotificationsAreAvailableWithInstallation"
                              )
                            )
                        )
                      ]),
                      _c("p", { staticClass: "asterisk-text" }, [
                        _vm._v(
                          "**" +
                            _vm._s(
                              _vm.$t(
                                "xFuellevelIsAvailableOnCompatibleDieselGenerators"
                              )
                            )
                        )
                      ])
                    ])
                  ])
                ])
              : !_vm.availableSubscriptionProducts && _vm.gettingSubscriptions
              ? _c("div", [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "xCouldNotFindAnyAvailableSubscriptionsForYourGenerator"
                            )
                          ) +
                          " "
                      )
                    ])
                  ])
                ])
              : _c("div", { staticClass: "pt-4" }, [
                  _c("span", {
                    staticClass: "spinner-border text-warning",
                    staticStyle: { width: "5rem", height: "5rem" },
                    attrs: { label: "Spinning" }
                  })
                ])
          ])
        ],
        1
      ),
      _c(
        "Modal",
        { attrs: { modalId: "upgrade-free-sub-step-2", size: "modal-lg" } },
        [
          _c(
            "ModalHeader",
            {
              attrs: {
                title: _vm.$t("xManageSubscription"),
                icon: "far fa-badge-dollar colorOrange mr-1"
              }
            },
            [
              _c(
                "button",
                {
                  staticClass: "btn btn-icon-button btn-icon-button--gray",
                  attrs: {
                    type: "button",
                    "data-test-hook": "x-button-step1-upgrade-free-sub"
                  },
                  on: {
                    click: function($event) {
                      return _vm.closeModals()
                    }
                  }
                },
                [_c("i", { staticClass: "fas fa-times" })]
              )
            ]
          ),
          _c("ModalBody", [
            _vm.showValidationBanner
              ? _c(
                  "div",
                  {
                    staticClass: "alert alert-danger",
                    attrs: { role: "alert" }
                  },
                  [
                    _c("p", [
                      _vm._v(_vm._s(_vm.$t("xExperiencedTheseErrors")))
                    ]),
                    _vm._l(_vm.errorMessages, function(message, index) {
                      return _c("ul", { key: index }, [
                        _c("li", { attrs: { id: index } }, [
                          _vm._v(" " + _vm._s(message) + " ")
                        ])
                      ])
                    })
                  ],
                  2
                )
              : _vm._e(),
            _vm.paymentInfo
              ? _c("div", [
                  _vm.useExistingPaymentInfo
                    ? _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col" }, [
                          _c("h3", [
                            _c("strong", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("xCurrentCreditCardInformationOnFile")
                                )
                              )
                            ]),
                            _c("span", { staticClass: "body-text-color" }, [
                              _vm._v("*")
                            ])
                          ])
                        ])
                      ])
                    : _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col" }, [
                          _c("h3", [
                            _c("strong", [
                              _vm._v(
                                _vm._s(_vm.$t("xUpdateCreditCardInformation"))
                              )
                            ]),
                            _c("span", { staticClass: "body-text-color" }, [
                              _vm._v("*")
                            ])
                          ])
                        ])
                      ]),
                  _c("div", { staticClass: "row mb-2" }, [
                    _c("div", { staticClass: "col" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "font-weight-bold custom-control custom-checkbox"
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.useExistingPaymentInfo,
                                expression: "useExistingPaymentInfo"
                              }
                            ],
                            staticClass: "custom-control-input",
                            attrs: {
                              type: "checkbox",
                              id: "useExistingPaymentInfo"
                            },
                            domProps: {
                              checked: Array.isArray(_vm.useExistingPaymentInfo)
                                ? _vm._i(_vm.useExistingPaymentInfo, null) > -1
                                : _vm.useExistingPaymentInfo
                            },
                            on: {
                              change: function($event) {
                                var $$a = _vm.useExistingPaymentInfo,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      (_vm.useExistingPaymentInfo = $$a.concat([
                                        $$v
                                      ]))
                                  } else {
                                    $$i > -1 &&
                                      (_vm.useExistingPaymentInfo = $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1)))
                                  }
                                } else {
                                  _vm.useExistingPaymentInfo = $$c
                                }
                              }
                            }
                          }),
                          _c(
                            "label",
                            {
                              staticClass: "custom-control-label",
                              attrs: { for: "useExistingPaymentInfo" }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("xUseCurrentCreditCardInformation")
                                )
                              )
                            ]
                          )
                        ]
                      )
                    ])
                  ]),
                  _vm.useExistingPaymentInfo
                    ? _c("div", [
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-12" }, [
                            _c("p", [
                              _c("strong", [
                                _c("i", { staticClass: "fal fa-credit-card" }),
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.paymentInfo.cardType) +
                                    " - " +
                                    _vm._s(_vm.paymentInfo.cardSuffix) +
                                    " "
                                )
                              ])
                            ]),
                            _c("p", [
                              _vm._v(
                                _vm._s(_vm.$t("xExpires")) +
                                  " " +
                                  _vm._s(
                                    _vm.paymentInfo.expirationMonth +
                                      "/" +
                                      _vm.paymentInfo.expirationYear
                                  )
                              )
                            ])
                          ])
                        ])
                      ])
                    : _c("div", [
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-12" }, [
                            _c(
                              "fieldset",
                              {
                                staticClass: "form-group",
                                attrs: { id: "credit-card-number" }
                              },
                              [
                                _c("div", [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.$v.creditCardNumber.$model,
                                        expression: "$v.creditCardNumber.$model"
                                      },
                                      {
                                        name: "mask",
                                        rawName: "v-mask",
                                        value: "################",
                                        expression: "'################'"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    class: _vm.status(_vm.$v.creditCardNumber),
                                    attrs: {
                                      type: "text",
                                      maxlength: "16",
                                      placeholder: _vm.$t("xCreditCardNumber"),
                                      "aria-describedby": "card-number-invalid"
                                    },
                                    domProps: {
                                      value: _vm.$v.creditCardNumber.$model
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.$v.creditCardNumber,
                                          "$model",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  }),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "invalid-feedback",
                                      attrs: { id: "card-number-invalid" }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("xCCFieldValidation")) +
                                          " "
                                      )
                                    ]
                                  )
                                ])
                              ]
                            )
                          ])
                        ]),
                        _c("div", { staticClass: "row" }, [
                          _c(
                            "div",
                            { staticClass: "col col-lg-6 col-md-t6 col-sm-12" },
                            [
                              _c(
                                "fieldset",
                                {
                                  staticClass: "form-group",
                                  attrs: { id: "expiry-date-month" }
                                },
                                [
                                  _c("div", [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.$v.expirationMonth.$model,
                                          expression:
                                            "$v.expirationMonth.$model"
                                        },
                                        {
                                          name: "mask",
                                          rawName: "v-mask",
                                          value: "##",
                                          expression: "'##'"
                                        }
                                      ],
                                      staticClass: "form-control",
                                      class: _vm.status(_vm.$v.expirationMonth),
                                      attrs: {
                                        type: "text",
                                        maxlength: "2",
                                        placeholder: _vm.$t("xMm"),
                                        "aria-describedby":
                                          "expiry-month-invalid"
                                      },
                                      domProps: {
                                        value: _vm.$v.expirationMonth.$model
                                      },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            _vm.$v.expirationMonth,
                                            "$model",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    }),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "invalid-feedback",
                                        attrs: { id: "expiry-month-invalid" }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "xExpirationMonthValidation"
                                              )
                                            ) +
                                            " "
                                        )
                                      ]
                                    )
                                  ])
                                ]
                              )
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "col col-lg-6 col-md-6 col-sm-12" },
                            [
                              _c(
                                "fieldset",
                                {
                                  staticClass: "form-group",
                                  attrs: { id: "expiry-date-year" }
                                },
                                [
                                  _c("div", [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.$v.expirationYear.$model,
                                          expression: "$v.expirationYear.$model"
                                        },
                                        {
                                          name: "mask",
                                          rawName: "v-mask",
                                          value: "##",
                                          expression: "'##'"
                                        }
                                      ],
                                      staticClass: "form-control",
                                      class: _vm.status(_vm.$v.expirationYear),
                                      attrs: {
                                        type: "text",
                                        maxlength: "2",
                                        placeholder: _vm.$t("xYy"),
                                        "aria-describedby":
                                          "expiry-year-invalid"
                                      },
                                      domProps: {
                                        value: _vm.$v.expirationYear.$model
                                      },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            _vm.$v.expirationYear,
                                            "$model",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    }),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "invalid-feedback",
                                        attrs: { id: "expiry-year-invalid" }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "xExpirationYearValidation"
                                              )
                                            ) +
                                            " "
                                        )
                                      ]
                                    )
                                  ])
                                ]
                              )
                            ]
                          ),
                          _c("div", { staticClass: "col-12" }, [
                            _c(
                              "div",
                              {
                                staticClass: "input-group",
                                attrs: { id: "cvv" }
                              },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.$v.cvv.$model,
                                      expression: "$v.cvv.$model"
                                    },
                                    {
                                      name: "mask",
                                      rawName: "v-mask",
                                      value: "####",
                                      expression: "'####'"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  class: _vm.status(_vm.$v.cvv),
                                  attrs: {
                                    type: "text",
                                    maxlength: "4",
                                    placeholder: _vm.$t("xCvv"),
                                    "aria-describedby": "cvv-invalid",
                                    "data-test-hook": "CcCvv"
                                  },
                                  domProps: { value: _vm.$v.cvv.$model },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        _vm.$v.cvv,
                                        "$model",
                                        $event.target.value
                                      )
                                    }
                                  }
                                }),
                                _c(
                                  "div",
                                  { staticClass: "input-group-append" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "input-group-text" },
                                      [
                                        _c("img", {
                                          attrs: {
                                            height: 20,
                                            src: require("@/assets/images/3-digit-code.svg")
                                          }
                                        })
                                      ]
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "invalid-feedback",
                                    attrs: { id: "cvv-invalid" }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("xCVVValidation")) +
                                        " "
                                    )
                                  ]
                                )
                              ]
                            )
                          ])
                        ])
                      ])
                ])
              : _c("div", [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-12" }, [
                      _c("h3", [
                        _c("strong", [
                          _vm._v(
                            _vm._s(_vm.$t("xEnterYourCreditCardInformation"))
                          )
                        ])
                      ])
                    ])
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-12" }, [
                      _c(
                        "div",
                        {
                          staticClass: "form-group",
                          attrs: { id: "credit-card-number" }
                        },
                        [
                          _c("div", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.$v.creditCardNumber.$model,
                                  expression: "$v.creditCardNumber.$model"
                                },
                                {
                                  name: "mask",
                                  rawName: "v-mask",
                                  value: "################",
                                  expression: "'################'"
                                }
                              ],
                              staticClass: "form-control",
                              class: _vm.status(_vm.$v.creditCardNumber),
                              attrs: {
                                type: "text",
                                maxlength: "16",
                                placeholder: _vm.$t("xCreditCardNumber"),
                                "aria-describedby": "card-number-invalid"
                              },
                              domProps: {
                                value: _vm.$v.creditCardNumber.$model
                              },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.$v.creditCardNumber,
                                    "$model",
                                    $event.target.value
                                  )
                                }
                              }
                            }),
                            _c(
                              "div",
                              {
                                staticClass: "invalid-feedback",
                                attrs: { id: "card-number-invalid" }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("xCCFieldValidation")) +
                                    " "
                                )
                              ]
                            )
                          ])
                        ]
                      )
                    ])
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col col-lg-6 col-md-t6 col-sm-12" },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "form-group",
                            attrs: { id: "expiry-date-month" }
                          },
                          [
                            _c("div", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.$v.expirationMonth.$model,
                                    expression: "$v.expirationMonth.$model"
                                  },
                                  {
                                    name: "mask",
                                    rawName: "v-mask",
                                    value: "##",
                                    expression: "'##'"
                                  }
                                ],
                                staticClass: "form-control",
                                class: _vm.status(_vm.$v.expirationMonth),
                                attrs: {
                                  type: "text",
                                  maxlength: "2",
                                  placeholder: _vm.$t("xMm"),
                                  "aria-describedby": "expiry-month-invalid"
                                },
                                domProps: {
                                  value: _vm.$v.expirationMonth.$model
                                },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.$v.expirationMonth,
                                      "$model",
                                      $event.target.value
                                    )
                                  }
                                }
                              }),
                              _c(
                                "div",
                                {
                                  staticClass: "invalid-feedback",
                                  attrs: { id: "expiry-month-invalid" }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("xExpirationMonthValidation")
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ])
                          ]
                        )
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "col col-lg-6 col-md-t6 col-sm-12" },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "form-group",
                            attrs: { id: "expiry-date-year" }
                          },
                          [
                            _c("div", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.$v.expirationYear.$model,
                                    expression: "$v.expirationYear.$model"
                                  },
                                  {
                                    name: "mask",
                                    rawName: "v-mask",
                                    value: "##",
                                    expression: "'##'"
                                  }
                                ],
                                staticClass: "form-control",
                                class: _vm.status(_vm.$v.expirationYear),
                                attrs: {
                                  type: "text",
                                  maxlength: "2",
                                  placeholder: _vm.$t("xYy"),
                                  "aria-describedby": "expiry-year-invalid"
                                },
                                domProps: {
                                  value: _vm.$v.expirationYear.$model
                                },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.$v.expirationYear,
                                      "$model",
                                      $event.target.value
                                    )
                                  }
                                }
                              }),
                              _c(
                                "div",
                                {
                                  staticClass: "invalid-feedback",
                                  attrs: { id: "expiry-year-invalid" }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("xExpirationYearValidation")
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ])
                          ]
                        )
                      ]
                    ),
                    _c("div", { staticClass: "col-12" }, [
                      _c(
                        "div",
                        { staticClass: "input-group", attrs: { id: "cvv" } },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.$v.cvv.$model,
                                expression: "$v.cvv.$model"
                              },
                              {
                                name: "mask",
                                rawName: "v-mask",
                                value: "####",
                                expression: "'####'"
                              }
                            ],
                            staticClass: "form-control",
                            class: _vm.status(_vm.$v.cvv),
                            attrs: {
                              type: "text",
                              maxlength: "4",
                              placeholder: _vm.$t("xCvv"),
                              "aria-describedby": "cvv-invalid",
                              "data-test-hook": "CcCvv"
                            },
                            domProps: { value: _vm.$v.cvv.$model },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.$v.cvv,
                                  "$model",
                                  $event.target.value
                                )
                              }
                            }
                          }),
                          _c("div", { staticClass: "input-group-append" }, [
                            _c("div", { staticClass: "input-group-text" }, [
                              _c("img", {
                                attrs: {
                                  height: 20,
                                  src: require("@/assets/images/3-digit-code.svg")
                                }
                              })
                            ])
                          ]),
                          _c(
                            "div",
                            {
                              staticClass: "invalid-feedback",
                              attrs: { id: "cvv-invalid" }
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("xCVVValidation")) + " "
                              )
                            ]
                          )
                        ]
                      )
                    ])
                  ])
                ]),
            _c("div", { staticClass: "mt-2" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c(
                    "div",
                    {
                      staticClass: "form-group",
                      attrs: { id: "optional-coupon-code" }
                    },
                    [
                      _c("div", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.couponCode,
                              expression: "couponCode"
                            }
                          ],
                          staticClass: "form-control",
                          class: {
                            "is-invalid": _vm.couponCodeIsValid != null
                          },
                          attrs: {
                            type: "text",
                            placeholder: _vm.$t("xCouponCode"),
                            "aria-describedby": "coupon-code-invalid"
                          },
                          domProps: { value: _vm.couponCode },
                          on: {
                            input: [
                              function($event) {
                                if ($event.target.composing) return
                                _vm.couponCode = $event.target.value
                              },
                              function($event) {
                                _vm.couponCodeIsValid = null
                              }
                            ]
                          }
                        }),
                        _c(
                          "div",
                          {
                            staticClass: "invalid-feedback",
                            attrs: { id: "coupon-code-invalid" }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("xPleaseEnterValidCouponCode")) +
                                " "
                            )
                          ]
                        )
                      ])
                    ]
                  )
                ])
              ])
            ]),
            _vm.accountData
              ? _c("div", { staticClass: "mt-2" }, [
                  _c("div", { staticClass: "row mb-2" }, [
                    _c("div", { staticClass: "col" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "font-weight-bold custom-control custom-checkbox"
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.useExistingBillingInformation,
                                expression: "useExistingBillingInformation"
                              }
                            ],
                            staticClass: "custom-control-input",
                            attrs: {
                              type: "checkbox",
                              id: "useExistingBillingInformationId",
                              disabled: _vm.savingAccountHolder,
                              "data-test-hook":
                                "use-current-billing-info-checkbox-step2-upgrade-free-sub"
                            },
                            domProps: {
                              checked: Array.isArray(
                                _vm.useExistingBillingInformation
                              )
                                ? _vm._i(
                                    _vm.useExistingBillingInformation,
                                    null
                                  ) > -1
                                : _vm.useExistingBillingInformation
                            },
                            on: {
                              change: function($event) {
                                var $$a = _vm.useExistingBillingInformation,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      (_vm.useExistingBillingInformation = $$a.concat(
                                        [$$v]
                                      ))
                                  } else {
                                    $$i > -1 &&
                                      (_vm.useExistingBillingInformation = $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1)))
                                  }
                                } else {
                                  _vm.useExistingBillingInformation = $$c
                                }
                              }
                            }
                          }),
                          _c(
                            "label",
                            {
                              staticClass: "custom-control-label",
                              attrs: { for: "useExistingBillingInformationId" }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("xUseCurrentBillingInfo")) +
                                  " "
                              )
                            ]
                          )
                        ]
                      )
                    ])
                  ]),
                  _vm.useExistingBillingInformation
                    ? _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-12" }, [
                          _c("p", [
                            _vm._v(
                              _vm._s(_vm.accountData.firstName) +
                                " " +
                                _vm._s(_vm.accountData.lastName)
                            )
                          ]),
                          _c("p", [
                            _vm._v(
                              _vm._s(
                                _vm.DisplayAddress(_vm.accountData.address)
                              )
                            )
                          ])
                        ])
                      ])
                    : _vm._e(),
                  !_vm.useExistingBillingInformation
                    ? _c("div", [
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-12" }, [
                            _c(
                              "div",
                              {
                                staticClass: "form-group",
                                attrs: { id: "firstName-group" }
                              },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.accountData.firstName,
                                      expression: "accountData.firstName"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  attrs: { id: "firstName", type: "text" },
                                  domProps: {
                                    value: _vm.accountData.firstName
                                  },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        _vm.accountData,
                                        "firstName",
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                              ]
                            )
                          ])
                        ]),
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-12" }, [
                            _c(
                              "div",
                              {
                                staticClass: "form-group",
                                attrs: { id: "lastName-group" }
                              },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.accountData.lastName,
                                      expression: "accountData.lastName"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  attrs: { id: "lastName", type: "text" },
                                  domProps: { value: _vm.accountData.lastName },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        _vm.accountData,
                                        "lastName",
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                              ]
                            )
                          ])
                        ]),
                        _c("div", { staticClass: "row" }, [
                          _c(
                            "div",
                            { staticClass: "col" },
                            [
                              _c("TypeAheadAddress", {
                                ref: "typeAheadAddressUpgradeFreeSub",
                                attrs: {
                                  currentAddress: _vm.accountData.address
                                },
                                on: { newAddress: _vm.setNewBillingAddress }
                              })
                            ],
                            1
                          )
                        ])
                      ])
                    : _vm._e()
                ])
              : _vm._e(),
            _vm.selectedProduct
              ? _c("div", { staticClass: "my-3" }, [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-12" }, [
                      _c("div", { staticClass: "subscription-cost" }, [
                        _c("h3", { staticClass: "mb-0" }, [
                          _vm._v(
                            _vm._s(_vm.$t("xTotalPayment")) +
                              ": " +
                              _vm._s(
                                _vm._f("formatCurrency")(
                                  _vm.selectedProduct.cost.toFixed(2)
                                )
                              )
                          )
                        ]),
                        _c("p", [
                          _c("i", [
                            _vm._v(" " + _vm._s(_vm.$t("xBilled")) + " "),
                            _vm.selectedProduct.durationInMonths == 12
                              ? _c("span", [_vm._v(_vm._s(_vm.$t("xYearly")))])
                              : _c("span", [_vm._v(_vm._s(_vm.$t("xMonthly")))])
                          ])
                        ])
                      ])
                    ])
                  ])
                ])
              : _vm._e(),
            _c("div", { staticClass: "row mb-2" }, [
              _c("div", { staticClass: "col" }, [
                _c("p", [
                  _c("i", { staticClass: "asterisk-text" }, [
                    _vm._v(
                      "*" +
                        _vm._s(_vm.$t("xUpdatingCCApplyActiveSubscriptions"))
                    )
                  ])
                ]),
                _c("p", [
                  _c("i", [
                    _vm._v(_vm._s(_vm.$t("xThisSubscriptionWillAutoRenew")))
                  ])
                ])
              ])
            ])
          ]),
          _c("ModalFooter", [
            _vm.useExistingBillingInformation
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-outline-warning",
                    attrs: {
                      type: "button",
                      "data-test-hook": "previous-button-step2-upgrade-free-sub"
                    },
                    on: {
                      click: function($event) {
                        _vm.openModal("upgrade-free-sub-step-1"),
                          _vm.closeModal("upgrade-free-sub-step-2")
                      }
                    }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("xPrevious")) + " ")]
                )
              : _vm._e(),
            _vm.useExistingBillingInformation
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-warning",
                    attrs: {
                      type: "button",
                      disabled: !_vm.canPay() || _vm.paying,
                      "data-test-hook": "pay-now-button-step2-upgrade-free-sub"
                    },
                    on: {
                      click: function($event) {
                        return _vm.payForProduct()
                      }
                    }
                  },
                  [
                    _vm._v(" " + _vm._s(_vm.$t("xPayNow")) + " "),
                    _vm.paying
                      ? _c("span", { staticClass: "spinner-border ml-2" })
                      : _vm._e()
                  ]
                )
              : _c(
                  "button",
                  {
                    staticClass: "btn btn-outline-warning btn-block",
                    attrs: {
                      type: "button",
                      disabled:
                        _vm.savingAccountHolder || _vm.addressSaveDisabled,
                      "data-test-hook":
                        "confirm-billing-info-step2-upgrade-free-sub"
                    },
                    on: {
                      click: function($event) {
                        _vm.saveAccountHolder(),
                          (_vm.savingAccountHolder = true)
                      }
                    }
                  },
                  [
                    _vm._v(" " + _vm._s(_vm.$t("xConfirmBillingInfo")) + " "),
                    _vm.savingAccountHolder
                      ? _c("span", { staticClass: "spinner-border ml-2" })
                      : _vm._e()
                  ]
                )
          ])
        ],
        1
      ),
      _c(
        "Modal",
        { attrs: { modalId: "upgrade-free-sub-step-3", size: "modal-md" } },
        [
          _c(
            "ModalHeader",
            {
              attrs: {
                title: _vm.$t("xManageSubscription"),
                icon: "far fa-badge-dollar colorOrange mr-1"
              }
            },
            [
              _c("div", { staticClass: "col-auto float-right" }, [
                _c(
                  "button",
                  {
                    staticClass: "btm btn-icon-button btn-icon-button--gray",
                    attrs: {
                      type: "button",
                      "data-test-hook": "x-button-step3-upgrade-free-sub"
                    },
                    on: {
                      click: function($event) {
                        return _vm.closeModals()
                      }
                    }
                  },
                  [_c("i", { staticClass: "fas fa-times" })]
                )
              ])
            ]
          ),
          _c("ModalBody", [
            _c("div", { staticClass: "row no-gutters" }, [
              _c(
                "div",
                { staticClass: "col" },
                [
                  _c("lottie", {
                    attrs: {
                      options: _vm.successAnimationOptions,
                      height: 80,
                      width: 80
                    },
                    on: { animCreated: _vm.handleAnimation }
                  })
                ],
                1
              )
            ]),
            _c("div", { staticClass: "row text-center mt-2" }, [
              _c("div", { staticClass: "col" }, [
                _c("h1", [_vm._v(_vm._s(_vm.$t("xSuccess")) + "!")]),
                _c("p", [
                  _vm._v(
                    _vm._s(
                      _vm.$t("xYourSubscriptionPlanHasBeenSuccessfullyUpdated")
                    )
                  )
                ])
              ])
            ])
          ]),
          _c("ModalFooter", [
            _c(
              "button",
              {
                staticClass: "btn btn-warning",
                attrs: {
                  type: "button",
                  "data-test-hook": "ok-button-step3-upgrade-free-sub"
                },
                on: {
                  click: function($event) {
                    _vm.closeModals(), _vm.emitSuccess()
                  }
                }
              },
              [_vm._v(" " + _vm._s(_vm.$t("xOK")) + " ")]
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }