var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mt-2 ml-2" },
    [
      _c("div", { staticClass: "row no-gutters mb-2" }, [
        _c("div", { staticClass: "col" }, [
          _c(
            "h1",
            {
              staticClass: "mb-0 pb-0",
              attrs: { "data-test-hook": "PageTitle" }
            },
            [
              _c("i", { staticClass: "far fa-tank-water colorOrange mr-1" }),
              _vm._v(" " + _vm._s(_vm.$t("xTankSettings")) + " ")
            ]
          )
        ])
      ]),
      _c("div", { staticClass: "card" }, [
        _c("div", { staticClass: "card-body" }, [
          _c("div", { staticClass: "row" }, [
            _vm.tankFuelType
              ? _c("div", { staticClass: "col" }, [
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      {
                        staticClass: "col tile-label",
                        attrs: {
                          "data-test-hook": "tank-details-fuel-type-label"
                        }
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("xFuelType")) + " ")]
                    )
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _vm.tankFuelType == _vm.PROPANE
                      ? _c("div", { staticClass: "col tile-content" }, [
                          _c(
                            "strong",
                            {
                              attrs: {
                                "data-test-hook":
                                  "tank-details-fuel-type-" + _vm.$t("xPropane")
                              }
                            },
                            [_vm._v(_vm._s(_vm.$t("xPropane")))]
                          )
                        ])
                      : _c("div", { staticClass: "col tile-content" }, [
                          _c(
                            "strong",
                            {
                              attrs: {
                                "data-test-hook":
                                  "tank-details-fuel-type-" + _vm.$t("xOil")
                              }
                            },
                            [_vm._v(_vm._s(_vm.$t("xOil")))]
                          )
                        ])
                  ])
                ])
              : _vm._e(),
            _c("div", { staticClass: "cols-auto float-right" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-icon-button",
                  attrs: {
                    type: "button",
                    "data-test-hook": "tank-details-edit-details-button"
                  },
                  on: {
                    click: function($event) {
                      return _vm.openEditModal()
                    }
                  }
                },
                [
                  _c("i", {
                    staticClass: "fas fa-pencil",
                    attrs: {
                      "data-test-hook": "tank-details-edit-details-button-icon"
                    }
                  })
                ]
              )
            ])
          ]),
          _c("div", { staticClass: "row mt-3" }, [
            _c("div", { staticClass: "col" }, [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  {
                    staticClass: "col tile-label",
                    attrs: {
                      "data-test-hook": "tank-details-tank-orientation-label"
                    }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("xTankOrientation")) + " ")]
                )
              ]),
              _c("div", { staticClass: "row align-items-center" }, [
                _c("div", { staticClass: "col tile-content" }, [
                  _c(
                    "strong",
                    {
                      attrs: {
                        "data-test-hook":
                          "tank-details-tank-orientation-" + _vm.tankOrientation
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.tankOrientation) + " ")]
                  )
                ])
              ])
            ])
          ]),
          _c("div", { staticClass: "row mt-3" }, [
            _c("div", { staticClass: "col" }, [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  {
                    staticClass: "col tile-label",
                    attrs: { "data-test-hook": "tank-details-capacity-label" }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("xTankCapacity")) + " ")]
                )
              ]),
              _c("div", { staticClass: "row" }, [
                _vm.tankCapacity
                  ? _c("div", { staticClass: "col tile-content" }, [
                      _c(
                        "strong",
                        {
                          attrs: {
                            "data-test-hook":
                              "tank-details-capacity-" + _vm.tankCapacity
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.convertedTankCapacity) +
                              " " +
                              _vm._s(this.abbreviatedLiquidUom) +
                              " "
                          )
                        ]
                      )
                    ])
                  : _vm._e()
              ])
            ])
          ])
        ])
      ]),
      _c(
        "Modal",
        { attrs: { modalId: "edit-tank-details", size: "modal-lg" } },
        [
          _c(
            "ModalHeader",
            {
              attrs: {
                title: _vm.$t("xEditTankSettings"),
                icon: "fas fa-pen colorOrange mr-1",
                "data-test-hook": "ModalTitle"
              }
            },
            [
              _c("div", { staticClass: "cols-auto" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-icon-button btn-icon-button--gray",
                    attrs: {
                      type: "button",
                      "data-test-hook": "edit-tank-details-modal-close-button"
                    },
                    on: {
                      click: function($event) {
                        return _vm.closeModal("edit-tank-details")
                      }
                    }
                  },
                  [
                    _c("i", {
                      staticClass: "fas fa-times",
                      attrs: {
                        "data-test-hook":
                          "edit-tank-details-modal-close-button-icon"
                      }
                    })
                  ]
                )
              ])
            ]
          ),
          _c("ModalBody", [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                {
                  staticClass: "col",
                  attrs: {
                    "data-test-hook": "edit-tank-details-modal-fuel-type-label"
                  }
                },
                [_c("p", [_vm._v(_vm._s(_vm.$t("xFuelType")))])]
              )
            ]),
            _c("div", { staticClass: "row mt-1" }, [
              _c("div", { staticClass: "col-12" }, [
                _c(
                  "div",
                  { staticClass: "btn-group d-flex", attrs: { role: "group" } },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "w-100 btn btn-tank-outline",
                        class:
                          _vm.userSelectedFuelType == _vm.PROPANE
                            ? "active"
                            : "",
                        attrs: {
                          type: "button",
                          "data-test-hook":
                            "edit-tank-details-modal-fuel-type-propane-button"
                        },
                        on: {
                          click: function($event) {
                            ;(_vm.userSelectedFuelType = _vm.PROPANE),
                              (_vm.userSelectedOrientation = null),
                              _vm.setCapacities()
                          }
                        }
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("xPropane")) + " ")]
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "w-100 btn btn-tank-outline",
                        class:
                          _vm.userSelectedFuelType == _vm.HEATINGOIL
                            ? "active"
                            : "",
                        attrs: {
                          type: "button",
                          "data-test-hook":
                            "edit-tank-details-modal-fuel-type-oil-button"
                        },
                        on: {
                          click: function($event) {
                            ;(_vm.userSelectedFuelType = _vm.HEATINGOIL),
                              (_vm.userSelectedOrientation = _vm.HORIZONTAL),
                              _vm.setCapacities()
                          }
                        }
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("xOil")) + " ")]
                    )
                  ]
                )
              ])
            ]),
            _c("div", { staticClass: "row mt-4" }, [
              _c(
                "div",
                {
                  staticClass: "col",
                  attrs: {
                    "data-test-hook":
                      "edit-tank-details-modal-tank-orientation-label"
                  }
                },
                [_c("p", [_vm._v(_vm._s(_vm.$t("xTankOrientation")))])]
              )
            ]),
            _c("div", { staticClass: "row mt-1" }, [
              _c("div", { staticClass: "col" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn py-0 mx-0 btn-tank-outline btn-block",
                    class:
                      _vm.userSelectedOrientation == _vm.VERTICAL
                        ? "active"
                        : "",
                    staticStyle: { height: "80px" },
                    attrs: {
                      type: "button",
                      disabled: _vm.userSelectedFuelType == _vm.HEATINGOIL,
                      "data-test-hook":
                        "edit-tank-details-modal-vertical-orientation-button"
                    },
                    on: {
                      click: function($event) {
                        ;(_vm.userSelectedOrientation = _vm.VERTICAL),
                          _vm.setCapacities()
                      }
                    }
                  },
                  [
                    _c("div", { staticClass: "row align-items-center" }, [
                      _c("div", { staticClass: "col-5" }),
                      _c("div", { staticClass: "col-auto" }, [
                        _vm.userSelectedOrientation == _vm.VERTICAL
                          ? _c("img", {
                              attrs: {
                                height: "60px",
                                src: require("@/assets/images/tank-vertical-selected.svg"),
                                "data-test-hook":
                                  "edit-tank-details-modal-vertical-orientation-button-image"
                              }
                            })
                          : _vm._e(),
                        _vm.userSelectedOrientation != _vm.VERTICAL &&
                        (_vm.userSelectedFuelType == _vm.PROPANE ||
                          _vm.userSelectedFuelType == null)
                          ? _c("img", {
                              attrs: {
                                height: "60px",
                                src: require("@/assets/images/tank-vertical-unselected.svg"),
                                "data-test-hook":
                                  "edit-tank-details-modal-vertical-orientation-button-image"
                              }
                            })
                          : _vm._e(),
                        _vm.userSelectedOrientation == _vm.HEATINGOIL
                          ? _c("img", {
                              attrs: {
                                height: "60px",
                                src: require("@/assets/images/tank-vertical-disabled.svg"),
                                "data-test-hook":
                                  "edit-tank-details-modal-vertical-orientation-button-image"
                              }
                            })
                          : _vm._e()
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "col-auto",
                          attrs: {
                            "data-test-hook":
                              "edit-tank-details-modal-vertical-orientation-button-text"
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("xVertical")) + " ")]
                      ),
                      _c("div")
                    ])
                  ]
                )
              ])
            ]),
            _c("div", { staticClass: "row mt-3" }, [
              _c("div", { staticClass: "col" }, [
                _c(
                  "button",
                  {
                    staticClass: "py-0 mx-0 btn btn-tank-outline btn-block",
                    class:
                      _vm.userSelectedFuelType == _vm.HEATINGOIL ||
                      _vm.userSelectedOrientation == _vm.HORIZONTAL
                        ? "active"
                        : "",
                    staticStyle: { height: "80px" },
                    attrs: {
                      type: "button",
                      "data-test-hook":
                        "edit-tank-details-modal-horizontal-orientation-button"
                    },
                    on: {
                      click: function($event) {
                        ;(_vm.userSelectedOrientation = _vm.HORIZONTAL),
                          _vm.setCapacities()
                      }
                    }
                  },
                  [
                    _c("div", { staticClass: "row align-items-center" }, [
                      _c("div", { staticClass: "col-4" }),
                      _c("div", { staticClass: "col-auto" }, [
                        _vm.userSelectedOrientation == _vm.HORIZONTAL
                          ? _c("img", {
                              attrs: {
                                height: "50px",
                                src: require("@/assets/images/tank-horizontal-selected.svg"),
                                "data-test-hook":
                                  "edit-tank-details-modal-horizontal-orientation-button-image"
                              }
                            })
                          : _c("img", {
                              attrs: {
                                height: "50px",
                                src: require("@/assets/images/tank-horizontal-unselected.svg"),
                                "data-test-hook":
                                  "edit-tank-details-modal-horizontal-orientation-button-image"
                              }
                            })
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "col-auto",
                          attrs: {
                            "data-test-hook":
                              "edit-tank-details-modal-horizontal-orientation-button-text"
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("xHorizontal")) + " ")]
                      )
                    ])
                  ]
                )
              ])
            ]),
            _c("div", { staticClass: "row mt-4" }, [
              _c(
                "div",
                {
                  staticClass: "col",
                  attrs: {
                    "data-test-hook":
                      "edit-tank-details-modal-fuel-capacity-label"
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("xFuelCapacity")) + " ")]
              )
            ]),
            _c("div", { staticClass: "row mt-1" }, [
              _c("div", { staticClass: "col" }, [
                _c("fieldset", { staticClass: "form-group" }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.userSelectedCapacity,
                          expression: "userSelectedCapacity"
                        }
                      ],
                      staticClass: "custom-select",
                      attrs: {
                        placeHolder: _vm.$t("xSelectCapacity"),
                        "data-test-hook":
                          "edit-tank-details-modal-fuel-capacity-field"
                      },
                      on: {
                        change: function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.userSelectedCapacity = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        }
                      }
                    },
                    _vm._l(_vm.currentTankCapacities, function(
                      capacity,
                      index
                    ) {
                      return _c(
                        "option",
                        {
                          key: index,
                          attrs: {
                            "data-test-hook": `${_vm.userSelectedFuelType}-${_vm.userSelectedOrientation}-${capacity.value}`
                          },
                          domProps: { value: capacity.value }
                        },
                        [_vm._v(" " + _vm._s(capacity.text) + " ")]
                      )
                    }),
                    0
                  )
                ])
              ])
            ])
          ]),
          _c("ModalFooter", [
            _c(
              "button",
              {
                staticClass: "btn btn-outline-warning",
                attrs: {
                  type: "button",
                  "data-test-hook": "edit-tank-details-modal-cancel-button"
                },
                on: {
                  click: function($event) {
                    return _vm.closeModal("edit-tank-details")
                  }
                }
              },
              [_vm._v(" " + _vm._s(_vm.$t("xCancel")) + " ")]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-warning",
                attrs: {
                  type: "button",
                  disabled: !_vm.userCanSave() || _vm.saving,
                  "data-test-hook": "edit-tank-details-modal-save-button"
                },
                on: {
                  click: function($event) {
                    return _vm.updateTank()
                  }
                }
              },
              [
                !_vm.saving
                  ? _c("span", [_vm._v(_vm._s(_vm.$t("xSave")))])
                  : _c("span", [_c("span", { staticClass: "spinner-border" })])
              ]
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }