<template>
  <div class="notifications">
    <div class="card no-border" v-if="notifications">
      <div class="card-header" tabs fill>
        <ul class="nav nav-tabs card-header-tabs nav-fill">
          <li class="nav-item hoverPointer" @click="tabIndex = 0">
            <a class="nav-link" :class="isActive(0)">Email</a>
          </li>
          <li class="nav-item hoverPointer" @click="tabIndex = 1">
            <a class="nav-link" :class="isActive(1)">SMS</a>
          </li>
        </ul>
      </div>
      <div v-if="tabIndex == 0">
        <div v-if="notifications.emailNotifications && notifications.emailNotifications.length > 0">
          <table head-variant="light" class="search-table table table-striped table-hover" sticky-header="600px">
            <thead>
              <tr>
                <th>Recipient</th>
                <th>Date Sent</th>
                <th>Email Subject</th>
                <th>Notification Type</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="emailNotification in notifications.emailNotifications" :key="emailNotification.index">
                <td>{{ emailNotification.to }}</td>
                <td>{{ displayShortDate(emailNotification.sent) }}</td>
                <td>{{ emailNotification.emailSubject }}</td>
                <td>{{ emailNotification.templateName }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-else>
          <b-row>
            <b-col>
              No available email notifications.
            </b-col>
          </b-row>
        </div>
      </div>
      <div v-if="tabIndex == 1">
        <div v-if="notifications.smsNotifications && notifications.smsNotifications.length > 0">
          <table head-variant="light" class="search-table table table-striped table-hover" sticky-header="600px">
            <thead>
              <tr>
                <th>Recipient</th>
                <th>Date Sent</th>
                <th>Notification Type</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="smsNotification in notifications.smsNotifications" :key="smsNotification.sent">
                <td>{{ smsNotification.to }}</td>
                <td>{{ displayShortDate(smsNotification.sent) }}</td>
                <td>{{ smsNotification.templateName }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-else>
          <div class="row">
            <div class="col">
              No available sms notifications.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['value', 'notifications'],
  data() {
    return {
      tabIndex: 0
    }
  },
  methods: {
    isActive(index) {
      if (index === this.tabIndex) {
        return 'active'
      }
      return ''
    }
  }
}
</script>
<style scoped>
.search-table {
  font-size: 0.75rem;
}
.notifications {
  margin: -1.25rem;
}
</style>
