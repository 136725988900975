var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-12 col-md-6" }, [
      _c("h1", [_vm._v("Generator: " + _vm._s(_vm.value.apparatusId))]),
      _c("div", { staticClass: "mb-3" }, [
        _c("p", [
          _vm._v(_vm._s(_vm._f("formatLabelNoTranslation")("Generator Name")))
        ]),
        _c("p", { staticClass: "font-weight-bold pb-2" }, [
          _vm._v(_vm._s(_vm.value.generatorName || "N/A"))
        ])
      ]),
      _c("div", { staticClass: "mb-3" }, [
        _c("p", [
          _vm._v(_vm._s(_vm._f("formatLabelNoTranslation")("Serial Number")))
        ]),
        _c("div", { staticClass: "d-flex align-items-center" }, [
          _c("p", { staticClass: "font-weight-bold" }, [
            _vm._v(_vm._s(_vm.value.serialNumber || "N/A"))
          ]),
          _c(
            "button",
            {
              staticClass: "btn btn-light btn-sm ml-2",
              attrs: { "data-test-hook": "copy-SN-button-general-info-tab" },
              on: {
                click: function($event) {
                  return _vm.copyToClipboard(_vm.value.serialNumber)
                }
              }
            },
            [_c("i", { staticClass: "far colorOrange fa-copy" })]
          )
        ])
      ]),
      _vm.hasDevice(_vm.value)
        ? _c("div", { staticClass: "mb-3" }, [
            _c("p", [
              _vm._v(_vm._s(_vm._f("formatLabelNoTranslation")("Device ID")))
            ]),
            _c("div", { staticClass: "d-flex align-items-center" }, [
              _c("p", { staticClass: "font-weight-bold" }, [
                _vm._v(
                  " " + _vm._s(_vm.value.deviceInfo.deviceId || "N/A") + " "
                )
              ]),
              _c(
                "button",
                {
                  staticClass: "btn btn-light btn-sm ml-2",
                  attrs: {
                    "data-test-hook": "copy-device-id-button-general-info-tab"
                  },
                  on: {
                    click: function($event) {
                      return _vm.copyToClipboard(_vm.value.deviceInfo.deviceId)
                    }
                  }
                },
                [_c("i", { staticClass: "far colorOrange fa-copy" })]
              )
            ])
          ])
        : _c("div", { staticClass: "mb-3" }, [
            _c("p", [_vm._v("No Attached Device")]),
            _c("p", { staticClass: "pb-2" }, [
              _vm._v("This generator has not been fully enrolled.")
            ])
          ]),
      _vm.hasDevice(_vm.value)
        ? _c("div", { staticClass: "mb-3" }, [
            _c("p", [
              _vm._v(
                " " +
                  _vm._s(_vm._f("formatLabelNoTranslation")("Device Type")) +
                  " "
              )
            ]),
            _c("p", { staticClass: "font-weight-bold pb-2" }, [
              _vm._v(
                _vm._s(_vm.toLongDeviceType(_vm.value.deviceInfo.deviceType))
              )
            ])
          ])
        : _vm._e(),
      _c("div", { staticClass: "mb-3" }, [
        _c("p", [
          _vm._v(
            " " + _vm._s(_vm._f("formatLabelNoTranslation")("Panel ID")) + " "
          )
        ]),
        _c("p", { staticClass: "font-weight-bold pb-2" }, [
          _vm._v(_vm._s(_vm.value.panelId || "N/A"))
        ])
      ]),
      _c("div", { staticClass: "mb-3" }, [
        _c("p", [
          _vm._v(
            _vm._s(_vm._f("formatLabelNoTranslation")("Generator Address"))
          )
        ]),
        _c("div", { staticClass: "d-flex align-items-center" }, [
          _c("p", { staticClass: "font-weight-bold" }, [
            _vm._v(
              " " + _vm._s(_vm.DisplayAddress(_vm.value.apparatusAddress)) + " "
            )
          ]),
          _c(
            "button",
            {
              staticClass: "btn btn-light btn-sm ml-2",
              attrs: {
                "data-test-hook":
                  "copy-apparatus-address-button-general-info-tab"
              },
              on: {
                click: function($event) {
                  _vm.copyToClipboard(
                    _vm.DisplayAddress(_vm.value.apparatusAddress)
                  )
                }
              }
            },
            [_c("i", { staticClass: "far colorOrange fa-copy" })]
          )
        ])
      ])
    ]),
    _vm.hasAssociatedAccount(_vm.value)
      ? _c("div", { staticClass: "col-12 col-md-6" }, [
          _c("h1", [
            _vm._v("Owner: " + _vm._s(_vm.value.organizationId || "N/A"))
          ]),
          _vm.value.enrolledInVpp
            ? _c("div", { staticClass: "mb-3" }, [_vm._m(0)])
            : _vm._e(),
          _c("div", { staticClass: "mb-3" }, [
            _c("p", [
              _vm._v(_vm._s(_vm._f("formatLabelNoTranslation")("Name")))
            ]),
            _c("div", { staticClass: "d-flex align-items-center" }, [
              _c("p", { staticClass: "font-weight-bold" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.value.ownerInformation.firstName +
                        " " +
                        _vm.value.ownerInformation.lastName || "N/A"
                    ) +
                    " "
                )
              ]),
              _c(
                "button",
                {
                  staticClass: "btn btn-light btn-sm ml-2",
                  attrs: {
                    "data-test-hook": "copy-name-button-general-info-tab"
                  },
                  on: {
                    click: function($event) {
                      return _vm.copyToClipboard(
                        _vm.value.ownerInformation.firstName +
                          " " +
                          _vm.value.ownerInformation.lastName
                      )
                    }
                  }
                },
                [_c("i", { staticClass: "far colorOrange fa-copy" })]
              )
            ])
          ]),
          _c("div", { staticClass: "mb-3" }, [
            _c("p", [
              _vm._v(_vm._s(_vm._f("formatLabelNoTranslation")("Phone Number")))
            ]),
            _c("p", { staticClass: "font-weight-bold pb-2" }, [
              _vm._v(_vm._s(_vm.value.ownerInformation.phones[0] || "N/A"))
            ])
          ]),
          _c("div", { staticClass: "mb-3" }, [
            _c("p", [
              _vm._v(
                _vm._s(_vm._f("formatLabelNoTranslation")("Email Address"))
              )
            ]),
            _c("div", { staticClass: "d-flex align-items-center" }, [
              _c("p", { staticClass: "font-weight-bold" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.value.ownerInformation.emails[0] || "N/A") +
                    " "
                )
              ]),
              _c(
                "button",
                {
                  staticClass: "btn btn-light btn-sm ml-2",
                  attrs: {
                    variant: "light",
                    size: "sm",
                    "data-test-hook": "copy-email-button-general-info-tab"
                  },
                  on: {
                    click: function($event) {
                      return _vm.copyToClipboard(
                        _vm.value.ownerInformation.emails[0]
                      )
                    }
                  }
                },
                [_c("i", { staticClass: "far colorOrange fa-copy" })]
              )
            ])
          ]),
          _c("div", { staticClass: "mb-3" }, [
            _c("p", [
              _vm._v(
                _vm._s(_vm._f("formatLabelNoTranslation")("Owner Address"))
              )
            ]),
            _c("div", { staticClass: "d-flex align-items-center" }, [
              _c("p", { staticClass: "font-weight-bold" }, [
                _vm._v(
                  " " + _vm._s(_vm.DisplayAddress(_vm.value.ownerAddress)) + " "
                )
              ]),
              _c(
                "button",
                {
                  staticClass: "btn btn-light btn-sm ml-2",
                  attrs: {
                    variant: "light",
                    size: "sm",
                    "data-test-hook": "copy-owner-address-general-info-tab"
                  },
                  on: {
                    click: function($event) {
                      _vm.copyToClipboard(
                        _vm.DisplayAddress(_vm.value.ownerAddress)
                      )
                    }
                  }
                },
                [_c("i", { staticClass: "far colorOrange fa-copy" })]
              )
            ])
          ])
        ])
      : _c("div", { staticClass: "ml-3 mb-3", attrs: { title: "Owner" } }, [
          _c("h4", { staticClass: "inactive-subscription" }, [
            _vm._v("No Owner Info Found")
          ])
        ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "colorRed" }, [
      _c("i", [_vm._v("Enrolled In VPP Program")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }