<template>
  <div>
    <Modal modalId="downgrade-paid-sub-step-1" size="modal-lg">
      <ModalHeader :title="$t('xManageSubscription')" icon="far fa-badge-dollar colorOrange">
        <button
          class="btn btn-icon-button btn-icon-button--gray"
          @click="closeModals()"
          data-test-hook="x-button-step1-downgrade-sub-modal"
        >
          <i class="fas fa-times" />
        </button>
      </ModalHeader>
      <ModalBody>
        <div v-if="currentSubscription[0].productId == dealerManaged">
          <div class="row">
            <div class="col">
              {{ $t('xYourDealerHasAManagedSubscription') }}
            </div>
          </div>
        </div>
        <div v-else>
          <div class="row">
            <div class="col">
              <h3>{{ $t('xAreYouSure') }}</h3>
            </div>
          </div>
          <div class="row">
            <div class="col">
              {{ $t('xYoullBeMissingOut') }}
            </div>
          </div>
          <div class="row mt-2">
            <div class="col">
              <ul>
                <li>{{ $t('xEmailRemindersAndNotifications') }}</li>
                <li>{{ $t('xTextOrPushNotifications') }}</li>
                <li>{{ $t('xAbilityToModifyExerciseSchedule') }}</li>
              </ul>
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <button
          type="button"
          class="btn btn-outline-warning"
          @click="openModal('manage-sub-paid-step-1'), closeModal('downgrade-paid-sub-step-1')"
          data-test-hook="previous-button-step1-downgrade-sub-modal"
        >
          {{ $t('xPrevious') }}
        </button>
        <button
          type="button"
          class="btn btn-warning"
          @click="openModal('downgrade-paid-sub-step-2'), closeModal('downgrade-paid-sub-step-1')"
          data-test-hook="yes-downgrade-button-step1-downgrade-sub-modal"
        >
          {{ $t('xYesDowngrade') }}
        </button>
      </ModalFooter>
    </Modal>

    <Modal modalId="downgrade-paid-sub-step-2" size="modal-lg">
      <ModalHeader :title="$t('xManageSubscription')" icon="far fa-badge-dollar colorOrange mr-1">
        <div class="cols-auto float-right">
          <button
            type="button"
            class="btn btn-icon-button btn-icon-button--gray"
            @click="closeModals()"
            data-test-hook="x-button-step2-downgrade-sub-modal"
          >
            <i class="fas fa-times" />
          </button>
        </div>
      </ModalHeader>
      <ModalBody>
        <div class="row">
          <div class="col">
            <h3>{{ $t('xGotAMinuteToShareYourFeedback') }}</h3>
            <p>
              {{ $t('xWereSorryToHearThatThe') }} {{ currentSubscription[0].productName }}
              {{ $t('xPlanIsNoLongerSatisfyingYourNeeds') }}
              {{ $t('xPleaseTellUsWhy') }}
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <fieldset class="form-group">
              <div class="custom-control custom-radio">
                <input
                  class="custom-control-input"
                  type="radio"
                  id="itsTooExpensive"
                  v-model="downgradeReason"
                  value="It's too expensive"
                />
                <label
                  class="custom-control-label"
                  data-test-hook="too-expensive-radio-step2-downgrade-sub-modal"
                  for="itsTooExpensive"
                >
                  {{ $t('xItsTooExpensive') }}
                </label>
              </div>
              <div class="custom-control custom-radio">
                <input
                  class="custom-control-input"
                  type="radio"
                  id="iAmHavingTooManyTechnicalProblems"
                  v-model="downgradeReason"
                  value="I'm having too many techincal problems"
                />
                <label
                  class="custom-control-label"
                  data-test-hook="too-many-problems-radio-step2-downgrade-sub-modal"
                  for="iAmHavingTooManyTechnicalProblems"
                >
                  {{ $t('xIAmHavingTooManyTechnicalProblems') }}
                </label>
              </div>
              <div class="custom-control custom-radio">
                <input
                  class="custom-control-input"
                  type="radio"
                  id="theFeaturesAreNotValuableEnoughForThePrice"
                  v-model="downgradeReason"
                  value="The features aren't valuable enough for the price"
                />
                <label
                  class="custom-control-label"
                  data-test-hook="not-valuable-radio-step2-downgrade-sub-modal"
                  for="theFeaturesAreNotValuableEnoughForThePrice"
                >
                  {{ $t('xTheFeaturesAreNotValuableEnoughForThePrice') }}
                </label>
              </div>
              <div class="custom-control custom-radio">
                <input
                  class="custom-control-input"
                  type="radio"
                  id="thereAreNotEnoughFeaturesForThePrice"
                  v-model="downgradeReason"
                  value="There are not enough features for the price"
                />
                <label
                  class="custom-control-label"
                  data-test-hook="not-enough-features-radio-step2-downgrade-sub-modal"
                  for="thereAreNotEnoughFeaturesForThePrice"
                >
                  {{ $t('xThereAreNotEnoughFeaturesForThePrice') }}
                </label>
              </div>
              <div class="custom-control custom-radio">
                <input class="custom-control-input" type="radio" id="other" v-model="downgradeReason" value="Other" />
                <label class="custom-control-label" data-test-hook="other-radio-step2-downgrade-sub-modal" for="other">
                  {{ $t('xOther') }}
                </label>
              </div>
              <div v-if="downgradeReason == 'Other'" class="mt-2">
                <input
                  type="text"
                  class="form-control"
                  maxlength="500"
                  v-model="downgradeReasonSpecification"
                  data-test-hook="other-reason-description-downgrade-sub-modal"
                />
              </div>
            </fieldset>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <button
          type="button"
          class="btn btn-outline-warning"
          @click="openModal('downgrade-paid-sub-step-1'), closeModal('downgrade-paid-sub-step-2'), cleanReasons()"
          data-test-hook="previous-button-step2-downgrade-sub-modal"
        >
          {{ $t('xPrevious') }}
        </button>
        <button
          type="button"
          class="btn btn-warning"
          @click="downgradeSubscription()"
          :disabled="!downgradeReason || downgradingSubscription"
          data-test-hook="confirm-downgrade-step2-downgrade-sub-modal"
        >
          {{ $t('xConfirmDowngrade') }}
          <b-spinner v-if="downgradingSubscription" class="ml-2" />
        </button>
      </ModalFooter>
    </Modal>

    <Modal modalId="downgrade-paid-sub-step-3" size="modal-md">
      <ModalHeader :title="$t('xManageSubscription')" icon="far fa-badge-dollar colorOrange mr-1">
        <button
          type="button"
          class="btn btn-icon-button btn-icon-button--gray"
          @click="closeModals()"
          data-test-hook="x-button-step3-downgrade-sub-modal"
        >
          <i class="fas fa-times" />
        </button>
      </ModalHeader>
      <ModalBody>
        <div class="row no-gutters">
          <div class="col">
            <lottie :options="successAnimationOptions" v-on:animCreated="handleAnimation" :height="80" :width="80" />
          </div>
        </div>
        <div class="row text-center mt-3">
          <div class="col">
            <h1>{{ $t('xSuccess') }}!</h1>
            <p>
              {{ $t('xYourSubscriptionPlanHasBeenDowngraded') }}
              {{ $moment(currentSubscription[0].nextBillingDate).format($t('xShortDate')) }}.{{ ' '
              }}{{ $t('xYouCanUpgradeAtAnyTime') }}
            </p>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <button
          type="button"
          class="btn btn-primary"
          @click="closeModals(), emitSuccess()"
          data-test-hook="ok-button-step3-downgrade-sub-modal"
        >
          {{ $t('xOK') }}
        </button>
      </ModalFooter>
    </Modal>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import Lottie from '../lottie.vue'
import * as successAnimation from '@/assets/images/manage-subscription-success.json'
import * as Constants from '@/constants'

export default {
  components: { lottie: Lottie },
  props: ['currentSubscription'],
  data() {
    return {
      successAnimationOptions: { animationData: successAnimation, loop: false },
      downgradingSubscription: false,
      downgradeReason: null,
      downgradeReasonSpecification: null
    }
  },
  computed: {
    ...mapState({
      accountProfile: state => state.account.accountProfile,
      currentApparatusDetails: state => state.apparatus.currentApparatusDetails,
      isImpersonating: state => state.auth.isImpersonating
    }),
    freeWifiEth() {
      return Constants.FreeWifiEthProductId
    },
    dealerManaged() {
      return Constants.DealerManagedProductId
    }
  },
  methods: {
    emitSuccess() {
      this.$emit('success')
    },
    cleanReasons() {
      this.downgradeReason = null
      this.downgradeReasonSpecification = null
    },
    handleAnimation(anim) {
      this.anim = anim
    },
    downgradeSubscription() {
      this.downgradingSubscription = true
      this.$store
        .dispatch('changeBillingAccountProduct', {
          organizationId: this.accountProfile.organizationId,
          apparatusId: this.currentApparatusDetails.apparatusId,
          productId: this.freeWifiEth
        })
        .then(response => {
          this.downgradingSubscription = false
          this.closeModal('downgrade-paid-sub-step-2')
          this.openModal('downgrade-paid-sub-step-3')
          let event = this.createEventLogObjectFromSubscriptionsPage(
            this.currentApparatusDetails,
            this.currentSubscription,
            this.accountProfile,
            this.isImpersonating,
            Constants.DowngradeEventType,
            this.freeWifiEth,
            this.downgradeReason == 'Other' ? this.downgradeReasonSpecification : this.downgradeReason
          )
          this.$store.dispatch('logEvent', event)
        })
        .catch(error => {
          this.downgradingSubscription = false
          this.$store.dispatch('displayToast', {
            title: this.$t('xError'),
            message: this.$t('xFailedSetUpGeneratorSubscription'),
            variant: 'danger'
          })
        })
    },
    closeModals() {
      this.closeModal('manage-sub-paid-step-1')
      this.closeModal('downgrade-paid-sub-step-1')
      this.closeModal('downgrade-paid-sub-step-2')
      this.cleanReasons()
      this.closeModal('downgrade-paid-sub-step-3')
      this.downgradingSubscription = false
      this.downgradeReason = null
      this.downgradeReasonSpecification = null
    }
  },
  watch: {
    downgradeReason: function(newVal, oldVal) {
      if (newVal != 'Other') this.downgradeReasonSpecification = null
    }
  }
}
</script>

<style scoped>
li {
  margin-bottom: 0.35em;
}
</style>
