<template>
  <div v-if="source && currentSubscription">
    <!-- Find Destination Email modal -->
    <Modal :modalId="'destination-org-search' + source.apparatusId" size="modal-lg">
      <ModalHeader title="Find Destination Email" icon="fad fa-exchange-alt"></ModalHeader>
      <ModalBody>
        <div class="row" v-if="!destinationOwner && !showSearchError">
          <div class="col">
            <div class="row">
              <div class="col">
                <fieldset class="form-group">
                  <legend class="bv-no-focus-ring col-form-label pt-0">User Name (Email Address)</legend>
                  <div>
                    <div role="group" class="input-group">
                      <input
                        type="text"
                        class="form-control"
                        maxlength="50"
                        v-model="emailToSearch"
                        name="emailToSearch"
                      />
                    </div>
                  </div>
                </fieldset>
              </div>
            </div>
            <div class="row align-items-center">
              <div class="col">
                <button
                  @click="getDestinationOrg()"
                  :disabled="!emailToSearch"
                  class="btn btn-warning btn-block"
                  type="button"
                  data-test-hook="search-button-destinationOrgSearch-transfer-gen-modal"
                >
                  <i class="fas fa-search" /> Search
                  <div class="spinner-border text-muted ml-2" v-if="searchingForOwner" />
                </button>
              </div>
            </div>
            <ul class="mt-3">
              <li>Use this feature when the homeowner requests an email change</li>
              <li>If there is a new homeowner use the Homeowner Change button</li>
              <li>
                The owner must have a new Mobile Link account to move the generator to (shown as the destination email)
              </li>
              <li>
                This process will transfer the generator to the new Mobile Link account with the new email address as
                the login
              </li>
              <li>Only the one generator will be transferred</li>
            </ul>
          </div>
        </div>
        <div class="row" v-else>
          <div class="col">
            {{ searchErrorMessage }}
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <button
          type="button"
          class="btn btn-outline-warning"
          @click="closeModal('destination-org-search' + source.apparatusId), resetTransferModals()"
          data-test-hook="cancel-button-destinationOrgSearch-transfer-gen-modal"
        >
          Cancel
        </button>
      </ModalFooter>
    </Modal>

    <!-- Transfer Generator modal -->
    <Modal :modalId="'verify-gen-transfer' + source.apparatusId" size="modal-xl">
      <ModalHeader title="Transfer Generator" icon="fad fa-exchange-alt" />
      <ModalBody>
        <div class="row">
          <div class="col">
            <div class="card shadow">
              <div class="card-body">
                <h4 class="card-title">Generator: {{ source.apparatusId }}</h4>
                <div class="card-deck">
                  <div class="card no-border">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-12 tile-label">{{ 'Generator Name' | formatLabelNoTranslation }}</div>
                        <div class="col-12 tile-content">{{ source.generatorName || 'N/A' }}</div>
                      </div>
                      <div class="row">
                        <div class="col-12 tile-label">{{ 'Serial Number' | formatLabelNoTranslation }}</div>
                        <div class="col-12 tile-content">
                          {{ source.serialNumber || 'N/A' }}
                        </div>
                      </div>
                      <div class="row" v-if="hasDevice(source)">
                        <div class="col-12 tile-label">{{ 'Device ID' | formatLabelNoTranslation }}</div>
                        <div class="col-12 tile-content">
                          {{ source.deviceInfo.deviceId || 'N/A' }}
                        </div>
                      </div>
                      <div class="row" v-else>
                        <div class="col-12 tile-label colorOrange">No Attached Device</div>
                        <div class="col-12 tile-label colorOrange">
                          This generator has not been fully enrolled.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card no-border">
                    <div class="card-body">
                      <div class="row" v-if="hasDevice(source)">
                        <div class="col-12 tile-label">{{ 'Device Type' | formatLabelNoTranslation }}</div>
                        <div class="col-12 tile-content">{{ source.deviceInfo.deviceType || 'N/A' }}</div>
                      </div>
                      <div class="row">
                        <div class="col-12 tile-label">
                          {{ 'Generator Address' | formatLabelNoTranslation }}
                        </div>
                        <div class="col-12 tile-content">
                          {{ DisplayAddress(source.apparatusAddress) }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="card-deck mt-2">
              <div class="card shadow" v-if="source">
                <div class="card-body">
                  <div class="card-title">
                    <h4>Source Owner: {{ source.organizationId }}</h4>
                  </div>
                  <div class="row">
                    <div class="col-12 tile-label">{{ 'Name' | formatLabelNoTranslation }}</div>
                    <div class="col-12 tile-content">
                      {{ source.ownerInformation.firstName + ' ' + source.ownerInformation.lastName || 'N/A' }}
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 tile-label">{{ 'Phone Number' | formatLabelNoTranslation }}</div>
                    <div class="col-12 tile-content">{{ source.ownerInformation.phones[0] || 'N/A' }}</div>
                  </div>
                  <div class="row">
                    <div class="col-12 tile-label">{{ 'Email Address' | formatLabelNoTranslation }}</div>
                    <div class="col-12 tile-content">
                      {{ source.ownerInformation.emails[0] || 'N/A' }}
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 tile-label">{{ 'Owner Address' | formatLabelNoTranslation }}</div>
                    <div class="col-12 tile-content">
                      {{ DisplayAddress(source.ownerAddress) }}
                    </div>
                  </div>
                </div>
              </div>

              <!-- Destination Owner card -->
              <div class="card shadow" v-if="destinationOwnerLoaded">
                <div class="card-body">
                  <h4 class="card-title">Destination Owner: {{ destinationOwner.organizationId }}</h4>
                  <div class="row">
                    <div class="col-12 tile-label">{{ 'Name' | formatLabelNoTranslation }}</div>
                    <div class="col-12 tile-content">
                      {{ destinationOwner.contact.firstName + ' ' + destinationOwner.contact.lastName || 'N/A' }}
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 tile-label">{{ 'Phone Number' | formatLabelNoTranslation }}</div>
                    <div class="col-12 tile-content">{{ destinationOwner.contact.phones[0] || 'N/A' }}</div>
                  </div>
                  <div class="row">
                    <div class="col-12 tile-label">{{ 'Email Address' | formatLabelNoTranslation }}</div>
                    <div class="col-12 tile-content">
                      {{ destinationOwner.contact.emails[0] || 'N/A' }}
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 tile-label">{{ 'Owner Address' | formatLabelNoTranslation }}</div>
                    <div class="col-12 tile-content">
                      {{ DisplayAddress(destinationOwner.address) }}
                    </div>
                  </div>
                </div>
                <div class="card-footer">
                  <div class="row">
                    <div class="col">
                      <b-form-checkbox
                        v-model="$v.destinationOwnerVerified.$model"
                        :state="$v.destinationOwnerVerified.$model"
                        data-test-hook="info-verified-with-caller-checkbox-verifyGenTransfer-transfer-gen-modal"
                      >
                        Information Verified With Caller
                        <span v-if="!destinationOwnerVerified">
                          <i class="asterisk-text colorRed">required</i>
                        </span>
                      </b-form-checkbox>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card shadow" v-else>
                <div class="card-body">
                  <div class="spinner-border text-muted ml-2" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Subscription Information card -->
        <div class="mt-2 row">
          <div class="col">
            <div class="card-deck">
              <div class="card shadow">
                <div class="card-body">
                  <h4 class="card-title">Subscription Information</h4>
                  <div
                    v-if="
                      currentSubscription && currentSubscription.activeProduct && currentSubscription.activeProduct[0]
                    "
                  >
                    <div class="row">
                      <div class="col-12 tile-label">
                        <span class="tile-label">{{ 'Subscription' | formatLabelNoTranslation }}</span>
                        <span :class="getSubscriptionClass()" class="tile-content">
                          <strong> {{ currentSubscription.activeProduct[0].productName }}</strong>
                        </span>
                      </div>
                    </div>
                    <div class="row">
                      <b-col cols="12" class="tile-label">
                        <span class="tile-label">{{ 'Cost' | formatLabelNoTranslation }} </span>
                        <span class="tile-content">
                          {{ currentSubscription.activeProduct[0].cost.toFixed(2) | formatCurrency }}
                        </span>
                      </b-col>
                    </div>
                    <div class="row">
                      <b-col cols="12" class="tile-label">
                        <span class="tile-label">{{ 'Start Date' | formatLabelNoTranslation }} </span>
                        <span class="tile-content"> {{ currentSubscription.activeProduct[0].startDate }}</span>
                      </b-col>
                    </div>
                    <div class="row">
                      <b-col cols="12" class="tile-label">
                        <span class="tile-label">{{ 'Next Billing Date' | formatLabelNoTranslation }} </span>
                        <span class="tile-content"> {{ currentSubscription.activeProduct[0].nextBillingDate }}</span>
                      </b-col>
                    </div>
                  </div>
                  <div v-else>
                    <div class="row">
                      <b-col cols="12">
                        <h4 class="inactive-subscription">Subscription not found</h4>
                      </b-col>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Choose A Subscription Option card -->
              <div class="card shadow">
                <div class="card-body">
                  <div v-if="canTransferWithSubscription()">
                    <h4 class="card-title">Choose A Subscription Option</h4>
                    <div class="row" v-if="transferWithFreeSubscription == null">
                      <div class="col">
                        <i class="asterisk-text colorRed">selection required</i>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <fieldset class="form-group">
                          <div class="custom-control custom-radio">
                            <input
                              type="radio"
                              class="custom-control-input"
                              value="true"
                              v-model="transferWithFreeSubscription"
                              :class="$v.transferWithFreeSubscription.$model == null ? 'is-invalid' : 'is-valid'"
                              id="transferWithFreeSubscriptionRadioTrue"
                              data-test-hook="set-up-free-sub-radio-verifyGenTransfer-transfer-gen-modal"
                            />
                            <label for="transferWithFreeSubscriptionRadioTrue" class="custom-control-label"
                              >Set Up Free Subscription</label
                            >
                          </div>
                          <span class="asterisk-text">
                            <i>
                              This option will setup a free Mobile Link subscription for the user. The user will not
                              need to do any setup upon logging in to Mobile Link.
                            </i>
                          </span>

                          <div class="custom-control custom-radio">
                            <input
                              class="custom-control-input"
                              id="transferWithFreeSubscriptionRadioFalse"
                              type="radio"
                              v-model="transferWithFreeSubscription"
                              value="false"
                              :class="$v.transferWithFreeSubscription.$model == null ? 'is-invalid' : 'is-valid'"
                              data-test-hook="sub-prompt-radio-verifyGenTransfer-transfer-gen-modal"
                            />
                            <label for="transferWithFreeSubscriptionRadioFalse" class="custom-control-label"
                              >Subscription Prompt in Mobile Link</label
                            >
                          </div>
                          <span class="asterisk-text">
                            <i>
                              This option will prompt the user to select a subscription upon logging into Mobile Link.
                              Choose this option if the user wants a paid subscription.
                            </i>
                          </span>
                        </fieldset>
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <div class="row">
                      <div class="col">
                        This Apparatus Will Be Transferred With No Subscription, this will prompt the user to select a
                        subscription upon logging into Mobile Link.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ModalBody>

      <ModalFooter>
        <button
          type="button"
          class="btn btn-outline-warning"
          :disabled="transferringGen"
          @click="closeModal('verify-gen-transfer' + source.apparatusId), resetTransferModals()"
          data-test-hook="cancel-button-verifyGenTransfer-transfer-gen-modal"
        >
          Cancel
        </button>
        <button
          type="button"
          class="btn btn-warning"
          :disabled="!destinationOwnerVerified || transferWithFreeSubscription == null"
          @click="
            openModal('transfer-confirmation' + source.apparatusId),
              closeModal('verify-gen-transfer' + source.apparatusId)
          "
          data-toggle="tooltip"
          :title="completeTransferBtnTooltip()"
          data-test-hook="complete-transfer-transferConfirmation-transfer-acct-modal"
        >
          Complete Transfer
          <div class="spinner-border text-muted ml-2" v-if="transferringGen" />
        </button>
      </ModalFooter>
    </Modal>

    <!-- Transfer Confirmation modal -->
    <Modal :modalId="'transfer-confirmation' + source.apparatusId">
      <ModalHeader title="Transfer Confirmation" icon="fad fa-exchange-alt"></ModalHeader>
      <ModalBody>
        <div class="row">
          <div class="col">
            <h4>Are you sure you want to complete the transfer?</h4>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <button
          type="button"
          class="btn btn-outline-warning"
          :disabled="transferringGen"
          @click="closeModal('transfer-confirmation' + source.apparatusId), resetTransferModals()"
          data-test-hook="cancel-button-transferConfirmation-transfer-gen-modal"
        >
          Cancel
        </button>
        <button
          type="button"
          class="btn btn-warning"
          :disabled="transferringGen"
          @click="completeTransfer()"
          data-test-hook="complete-transfer-button-transferConfirmation-transfer-gen-modal"
        >
          Complete Transfer
          <div class="spinner-border text-muted ml-2" v-if="transferringGen" />
        </button>
      </ModalFooter>
    </Modal>

    <!-- Transfer Successful Modal -->
    <Modal :modalId="'transfer-success' + source.apparatusId">
      <ModalHeader title="Transfer Successful" icon="fad fa-exchange-alt"></ModalHeader>
      <ModalBody>
        <div class="row" v-if="!transferredWithError">
          <div class="col">
            The Generator was successfully transferred.
          </div>
        </div>
        <div class="row" v-else>
          <div class="col colorRed">
            {{ transferFailureMessage }}
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <button
          type="button"
          class="btn btn-warning btn-block"
          @click="closeModal('transfer-success' + source.apparatusId), emitSuccess()"
          data-test-hook="close-button-transferSuccess-transfer-gen-modal"
        >
          Close
        </button>
      </ModalFooter>
    </Modal>

    <!-- Transfer Failed Modal -->
    <Modal :modalId="'transfer-failure' + source.apparatusId">
      <ModalHeader title="Transfer Failed" icon="fad fa-exchange-alt"></ModalHeader>
      <ModalBody>
        <div class="row">
          <div class="col">
            {{ transferFailureMessage }}
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <button
          type="button"
          class="btn btn-warning btn-block"
          @click="closeModal('transfer-failure' + source.apparatusId)"
          data-test-hook="close-button-transferFailure-transfer-gen-button"
        >
          Close
        </button>
      </ModalFooter>
    </Modal>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { required, requiredIf, minLength, maxLength, email, numeric } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'
import * as Constants from '@/constants'

export default {
  mixins: [validationMixin],
  props: ['source', 'currentSubscription', 'transferType'],
  data() {
    return {
      emailToSearch: null,
      destinationOwner: null,
      destinationOwnerLoaded: false,
      searchingForOwner: false,
      destinationOwnerVerified: false,
      transferWithFreeSubscription: null,
      showSearchError: false,
      searchErrorMessage: null,
      transferFailureMessage: null,
      transferringGen: false,
      transferredWithError: false
    }
  },
  validations: {
    destinationOwnerVerified: {
      required
    },
    transferWithFreeSubscription: {
      required
    }
  },
  computed: {
    ...mapState({
      user: state => state.account.user
    })
  },
  methods: {
    emitSuccess() {
      this.$emit('transferEmission', {
        criteria: this.source.deviceInfo ? 'Device ID' : 'Serial Number',
        value: this.source.deviceInfo ? this.source.deviceInfo.deviceId : this.source.serialNumber
      })
    },
    canTransferWithSubscription() {
      if (
        this.currentSubscription &&
        this.currentSubscription.activeProduct &&
        this.currentSubscription.activeProduct[0]
      ) {
        return true
      } else if (this.source.deviceInfo.deviceType == 'wifi' || this.source.deviceInfo.deviceType == 'eth') {
        return true
      } else {
        this.transferWithFreeSubscription = false
        return false
      }
    },
    completeTransfer() {
      this.transferringGen = true
      let request = {
        body: {
          apparatusId: this.source.apparatusId,
          deviceType: this.source.deviceInfo.deviceType,
          sourceOrganizationId: this.source.organizationId,
          destinationOrganizationId: this.destinationOwner.organizationId,
          setUpFreeSubscription: this.transferWithFreeSubscription == null ? false : this.transferWithFreeSubscription
        },
        type: this.transferType
      }
      this.$store
        .dispatch('completeTransfer', request)
        .then(response => {
          this.transferringGen = false
          if (response.data) {
            this.transferredWithError = true
            this.transferFailureMessage = response.data
          }
          this.closeModal('transfer-confirmation' + this.source.apparatusId)
          this.openModal('transfer-success' + this.source.apparatusId)
          let event = this.createEventLogObjectFromAdmin(
            this.source,
            this.currentSubscription,
            this.user,
            Constants.TransferEventType,
            request.body.setUpFreeSubscription ? Constants.FreeWifiEthProductId : null
          )
          this.$store.dispatch('logEvent', event)
        })
        .catch(error => {
          if (error.response.data.status == 401) {
            this.$store.dispatch('displayToast', {
              title: this.$t('xError'),
              message: 'Your session has expired, please re-log in.',
              variant: 'danger'
            })
            this.forceSignOut()
          } else if (error.response.data.status == 500) {
            this.transferFailureMessage = 'An error occurred while transferring the generator.'
            this.closeModal('transfer-confirmation' + this.source.apparatusId)
            this.openModal('transfer-failure' + this.source.apparatusId)
          } else {
            this.transferFailureMessage = error.response.data
            this.closeModal('transfer-confirmation' + this.source.apparatusId)
            this.openModal('transfer-failure' + this.source.apparatusId)
          }
        })
    },
    getSubscriptionClass() {
      if (
        this.currentSubscription &&
        this.currentSubscription.activeProduct &&
        this.currentSubscription.activeProduct[0]
      ) {
        if (this.currentSubscription.activeProduct[0].status == 'Active') {
          return 'active-subscription'
        } else return 'inactive-subscription'
      }
    },
    hasDevice() {
      if (this.source.deviceInfo && this.source.deviceInfo.deviceId && this.source.deviceInfo.deviceType) {
        return true
      } else return false
    },
    getDestinationOrg() {
      this.searchingForOwner = true
      let request = {
        email: this.emailToSearch,
        type: this.transferType
      }
      this.$store
        .dispatch('getDestinationOrg', request)
        .then(response => {
          this.destinationOwner = _.cloneDeep(response.data)
          if (this.destinationOwner && this.destinationOwner.organizationId == this.source.organizationId) {
            this.searchingForOwner = false
            this.showSearchError = true
            this.searchErrorMessage = 'You cannot transfer generators to the same account.'
          } else {
            this.searchingForOwner = false
            this.destinationOwnerLoaded = true
            this.closeModal('destination-org-search' + this.source.apparatusId)
            this.openModal('verify-gen-transfer' + this.source.apparatusId)
          }
        })
        .catch(error => {
          if (error.response.data.status == 401) {
            this.$store.dispatch('displayToast', {
              title: this.$t('xError'),
              message: 'Your session has expired, please re-log in.',
              variant: 'danger'
            })
            this.forceSignOut()
          } else {
            this.searchingForOwner = false
            this.showSearchError = true
            this.searchErrorMessage = error.response.data
          }
        })
    },
    resetTransferModals() {
      this.emailToSearch = null
      this.destinationOwner = null
      this.destinationOwnerVerified = false
      this.transferWithFreeSubscription = null
      this.showSearchError = false
      this.searchErrorMessage = null
      this.searchingForOwner = false
      this.destinationOwnerLoaded = false
      this.transferringGen = false
      this.closeModal('destination-org-search')
      this.closeModal('verify-gen-transfer')
    },
    completeTransferBtnTooltip() {
      if (!this.destinationOwnerVerified) return 'Verify destination owner information and check the box to continue.'
      if (this.transferWithFreeSubscription == null) return 'Choose a subscription option to continue.'
      return ''
    }
  }
}
</script>
