<template>
  <div data-test-hook="dashboard-pending-card" class="dashboard-card">
    <div class="card hoverPointer apparatus-card">
      <div class="card-body">
        <div class="row mt-5 justify-content-around">
          <div class="col-auto">
            <h5>{{ apparatusType }} {{ $t('xInfoUnavailableAtThisTimePleaseCheckBackLater') }}</h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  props: ['apparatusType'],
  data() {},
  computed: {},
  methods: {},
  mounted() {}
}
</script>

<style></style>
