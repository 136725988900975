<template>
  <Modal :modalId="'EditPrimaryInfoModal-' + modalInfo.apparatusId">
    <ModalHeader :title="$t('xEditPrimaryContactInfo')" icon="fas fa-user-circle">
      <button
        class="btn btn-icon-button btn-icon-button--gray"
        @click="saving ? null : (resetModal(), closeModal('EditPrimaryInfoModal-' + modalInfo.apparatusId))"
        data-test-hook="x-button-edit-primary"
      >
        <i class="fas fa-times" />
      </button>
    </ModalHeader>
    <ModalBody v-if="editValue">
      <div class="row">
        <div class="col">
          <div class="form-group" id="phone-group">
            <label class="d-block" for="phone">{{ $t('xPhoneNumber') }}</label>
            <input
              class="form-control"
              id="phone"
              v-model="$v.editValue.phone.$model"
              maxlength="12"
              type="text"
              :state="validState($v.editValue.phone)"
              aria-describedby="phoneNumber-invalid"
            />
            <div class="invalid-feedback" id="phoneNumber-invalid">
              {{ $t('xRequiredField') }}. {{ $t('xPleaseEnterAValidPhoneNumber') }}
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="form-group" id="email-group">
            <label class="d-block" for="email">{{ $t('xEmailAddress') }}</label>
            <input
              class="form-control"
              id="email"
              disabled
              v-model="$v.editValue.email.$model"
              maxlength="70"
              type="text"
              :state="validState($v.editValue.email)"
              aria-describedby="email-invalid"
            />
            <div class="invalid-feedback" id="email-invalid">
              {{ $t('xRequiredField') }}. {{ $t('xEmailInvalidError') }}
            </div>
          </div>
        </div>
      </div>
    </ModalBody>

    <ModalFooter>
      <button
        type="button"
        class="btn btn-outline-warning"
        @click="resetModal(), closeModal('EditPrimaryInfoModal-' + modalInfo.apparatusId)"
        :disabled="saving"
        data-test-hook="cancel-button-edit-primary"
      >
        {{ $t('xCancel') }}
      </button>
      <button
        type="button"
        class="btn btn-warning"
        @click="savePrimaryContactInfo()"
        :disabled="canSave()"
        data-test-hook="save-button-edit-primary"
      >
        {{ $t('xSave') }}
        <div
          class="ml-2 spinner-border text-muted"
          v-if="saving"
          data-test-hook="save-button-spinner-edit-primary"
        ></div>
      </button>
    </ModalFooter>
  </Modal>
</template>

<script>
import { required, requiredIf, minLength, maxLength, email, numeric } from 'vuelidate/lib/validators'

export default {
  props: ['modalInfo'],
  data() {
    return {
      editValue: null,
      saving: false
    }
  },
  validations: {
    editValue: {
      phone: {
        required,
        minLength: minLength(10),
        maxLength: maxLength(12),
        numeric,
        startsWithZero(phoneNumber) {
          const regex = /^[1-9]{1}[0-9]{9,11}$/
          return regex.test(phoneNumber)
        }
      },
      email: {
        required,
        email
      }
    }
  },
  methods: {
    canSave() {
      this.$v.$touch()
      if (!this.$v.$anyError && !this.saving) {
        return false
      } else return true
    },
    resetModal() {
      this.$v.$reset()
      this.saving = false
    },
    savePrimaryContactInfo() {
      this.saving = true
      this.$emit('saveEditPrimary', {
        phone: this.editValue.phone,
        email: this.modalInfo.email,
        apparatusId: this.modalInfo.apparatusId
      })
      setTimeout(() => {
        this.resetModal()
      }, 1000)
    }
  },
  watch: {
    modalInfo: {
      handler: function(newVal, oldVal) {
        this.editValue = _.cloneDeep(newVal)
      },
      deep: true
    }
  },
  mounted() {
    this.editValue = _.cloneDeep(this.modalInfo)
  }
}
</script>

<style scoped></style>
