<template>
  <div>
    <div class="card no-border">
      <div class="card-body">
        <h4 class="card-title">Commands</h4>
        <div v-if="commandPending" class="row">
          <div class="col-12">{{ commandTypeDescription }} is sending.</div>
        </div>
        <div role="group" class="btn-group">
          <button
            class="btn btn-primary"
            type="button"
            data-test-hook="get-status-button-commands-tab"
            :disabled="commandPending"
            @click="InitiateCommand('GetStatus')"
          >
            <span v-if="value.deviceInfo.deviceType != 'cdma'">Get Status</span>
            <span v-else>Update Modem Status</span>
          </button>
          <button
            class="btn btn-primary"
            type="button"
            data-test-hook="update-alarm-log-button-commands-tab"
            :disabled="commandPending"
            @click="InitiateCommand('UpdateAlarmLog')"
          >
            Update Alarm Log
          </button>
          <button
            class="btn btn-primary"
            type="button"
            data-test-hook="update-run-log-button-commands-tab"
            :disabled="commandPending"
            @click="InitiateCommand('UpdateRunLog')"
          >
            Update Run Log
          </button>
          <button
            class="btn btn-primary"
            type="button"
            data-test-hook="update-maintenance-log-button-commands-tab"
            :disabled="commandPending"
            @click="InitiateCommand('UpdateMaintenanceLog')"
          >
            Update Maintenance Log
          </button>
          <button
            class="btn btn-primary"
            type="button"
            data-test-hook="reset-alarm-button-commands-tab"
            :disabled="commandPending"
            @click="InitiateCommand('ResetAlarm')"
          >
            Reset Alarm
          </button>
          <button
            class="btn btn-primary"
            type="button"
            data-test-hook="reboot-controller-button-commands-tab"
            :disabled="commandPending"
            @click="InitiateCommand('RebootController')"
          >
            <span v-if="value.deviceInfo.deviceType != 'cdma'">Reboot Controller</span>
            <span v-else>Recycle Modem</span>
          </button>
        </div>
      </div>
    </div>
    <Modal modalId="remoteDisclaimerModal">
      <span tabindex="0"></span>
      <ModalBody>
        <p>{{ DisclaimerText() }}</p>
      </ModalBody>
      <ModalFooter>
        <button
          class="btn btn-secondary"
          data-test-hook="cancel-button-remoteDisclaimerModal-commands-tab"
          @click="closeCommandModal()"
        >
          Cancel
        </button>
        <button
          class="btn btn-primary"
          data-test-hook="continue-button-remoteDisclaimerModal-commands-tab"
          @click="issueCommand()"
        >
          Continue
        </button>
      </ModalFooter>
    </Modal>

    <!--Command Status Modal-->
    <div class="modal" id="commandStatusModal">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h2 class="modal-title">
              {{ commandResponseText }}
            </h2>
          </div>
          <div class="modal-body">
            {{ commandResponseMessage }}
          </div>
          <div class="modal-footer">
            <button class="btn btn-primary" @click="closeCommandStatusModal()">OK</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import * as Constants from '@/constants'

export default {
  props: ['value'],
  data() {
    return {
      commandResponseText: '',
      commandResponseMessage: '',
      commandTypeDescription: '',
      commandPending: false,
      lastIssued: null,
      pendingCommandType: ''
    }
  },
  methods: {
    CanSendActionCommands() {
      return Constants.CanSendActionCommands.includes(this.value.panelId)
    },
    DisclaimerText() {
      if (this.pendingCommandType === 'GetStatus') {
        this.commandTypeDescription = 'Get Status'
        return 'This will send a Get Status command to this Generator'
      } else if (this.pendingCommandType === 'UpdateAlarmLog') {
        this.commandTypeDescription = 'Update Alarm Log'
        return 'This will send an Update Alarm Log command to this Generator'
      } else if (this.pendingCommandType === 'UpdateRunLog') {
        this.commandTypeDescription = 'Update Run Log'
        return 'This will send an Update Run Log command to this Generator'
      } else if (this.pendingCommandType === 'UpdateMaintenanceLog') {
        this.commandTypeDescription = 'Update Maintenance Log'
        return 'This will send an Update Maintenance Log to this Generator'
      } else if (this.pendingCommandType === 'ResetAlarm') {
        this.commandTypeDescription = 'Reset Alarm'
        return 'This will send a Reset Alarm command to this Generator'
      } else if (this.pendingCommandType === 'RebootController') {
        this.commandTypeDescription = 'Reboot Controller'
        return 'This will send a Reboot Controller/Recycle Modem command to this Generator'
      } else return ''
    },
    InitiateCommand(commandType) {
      this.pendingCommandType = commandType
      document.getElementById('remoteDisclaimerModal').style.display = 'block'
    },
    closeCommandModal() {
      this.$appInsights.trackEvent({
        name: 'cancel-initiate-remote-command',
        properties: { page: 'generator-details', commandType: this.pendingCommandType }
      })
      document.getElementById('remoteDisclaimerModal').style.display = 'none'
    },
    closeCommandStatusModal() {
      document.getElementById('commandStatusModal').style.display = 'none'
    },
    issueCommand() {
      this.commandPending = true
      this.$store
        .dispatch('issueCommand', {
          CommandType: this.pendingCommandType,
          DeviceId: this.value.deviceInfo.deviceId.toLowerCase()
        })
        .then(response => {
          this.commandPending = false
          this.commandResponseText =
            this.commandTypeDescription +
            ' successfully sent, please wait at least 15 minutes for this command to be processed'
          this.commandResponseMessage = 'Good to go.'
          this.openModal('commandStatusModal')
        })
        .catch(error => {
          if (error.response.data.status == 401) {
            this.$store.dispatch('displayToast', {
              title: this.$t('xError'),
              message: 'Your session has expired, please re-log in.',
              variant: 'danger'
            })
            this.forceSignOut()
          }
          this.commandPending = false
          this.commandResponseText = this.commandTypeDescription + ' failed to send'
          this.commandResponseMessage = error.response.data.detail
          this.openModal('commandStatusModal')
        })
        .finally(() => this.closeModal('remoteDisclaimerModal'))
    }
  }
}
</script>
<style scoped>
.btn {
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 10px;
  font-weight: 400;
}
</style>
