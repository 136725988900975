<template>
  <Modal :modalId="'EditTextSmsPreferencesModal-' + editTextSmsPreferencesModalInfo.apparatusId">
    <ModalHeader :title="$t('xEditTextSmsPreferences')" icon="fas fa-user-circle">
      <button
        class="btn btn-icon-button btn-icon-button--gray"
        @click="saving ? null : closeModal('EditTextSmsPreferencesModal-' + editValue.apparatusId)"
        data-test-hook="x-button-edit-sms-prefs-modal"
      >
        <i class="fas fa-times" />
      </button>
    </ModalHeader>
    <ModalBody>
      <div v-if="editValue">
        <div class="row collapseBackground">
          <div class="col">
            <div v-for="setting in settings" :key="setting.type" :class="setting.rowClass">
              <hr class="row" v-if="!setting.first && settingTypeList(editValue.currentPreferences, setting.type)" />
              <div
                class="row"
                align-v="center"
                v-if="
                  settingTypeList(editValue.currentPreferences, setting.type) &&
                    isEnrolledInVPP(setting, editTextSmsPreferencesModalInfo)
                "
              >
                <div
                  class="col-1"
                  :data-test-hook="
                    setting.title
                      .toLowerCase()
                      .replace(/\s/g, '-')
                      .concat('-icon-edit-sms-prefs-modal')
                  "
                >
                  <i :class="iconForDisplay(setting.type, setting.icon)"></i>
                </div>
                <div
                  class="col pl-0"
                  :data-test-hook="
                    setting.title
                      .toLowerCase()
                      .replace(/\s/g, '-')
                      .concat('-name-edit-sms-prefs-modal')
                  "
                >
                  {{ setting.title }}:
                  <b
                    class="pl-0"
                    :data-test-hook="
                      setting.title
                        .toLowerCase()
                        .replace(/\s/g, '-')
                        .concat('-status-edit-sms-prefs-modal')
                    "
                  >
                    {{
                      editValue.currentPreferences.preferences.find(p => p.type == setting.type).receiveSms
                        ? 'ON'
                        : 'OFF'
                    }}
                  </b>
                </div>
                <div class="ml-1 col-auto">
                  <div class="custom-control custom-switch b-custom-control-lg">
                    <input
                      class="custom-control-input"
                      type="checkbox"
                      :id="message + setting.title"
                      v-model="editValue.currentPreferences.preferences.find(p => p.type == setting.type).receiveSms"
                      :data-test-hook="
                        setting.title
                          .toLowerCase()
                          .replace(/\s/g, '-')
                          .concat('-switch-edit-sms-prefs-modal')
                      "
                    />
                    <label :for="message + setting.title" class="custom-control-label"></label>
                  </div>
                </div>
              </div>
              <div
                class="row align-items-center mt-3"
                align-v="center"
                v-if="
                  setting.type == preferenceTypeTankFuelWarning &&
                    settingTypeList(editValue.currentPreferences, setting.type)
                "
              >
                <div class="col-auto ml-3">
                  <i class="fa-light fa-triangle-exclamation"></i>
                </div>
                <div class="col-5 pl-0">
                  {{ $t('xWarningLevel') }}
                </div>
                <div class="col-5 ml-auto">
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control text-right"
                      v-model="$v.editValue.currentPreferences.threshold1.$model"
                      :state="validState($v.editValue.currentPreferences.threshold1)"
                      aria-describedby="tankThreshold-invalid"
                      v-mask="'##'"
                      maxlength="2"
                      :disabled="!editValue.currentPreferences.preferences.find(p => p.type == setting.type).receiveSms"
                    />
                    <div class="input-group-append" :is-text="true">
                      <div class="input-group-text">%</div>
                    </div>
                    <div class="invalid-feedback" id="tankThreshold-invalid">
                      {{ $t('xInvalidTankThreshold') }}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              :class="noSignalAlert.rowClass"
              v-if="editTextSmsPreferencesModalInfo.apparatusType != fuelTankApparatusType"
            >
              <hr class="row" v-if="somethingInList" />
              <div class="row" align-v="center">
                <div class="col-1">
                  <i :class="iconForDisplay(noSignalAlert.type, noSignalAlert.icon)" />
                </div>
                <div class="col pl-0">
                  {{ noSignalAlert.title }}:
                  <b class="pl-0">
                    {{
                      editValue.currentPreferences.preferences.find(p => p.type == noSignalAlert.type).receiveSms
                        ? 'ON'
                        : 'OFF'
                    }}
                  </b>
                </div>
                <div class="col-auto ml-1">
                  <div
                    class="custom-control custom-switch b-custom-control-lg"
                    :class="
                      editValue.currentPreferences.preferences.find(p => p.type == noSignalAlert.type).receiveSms
                        ? 'switch-success'
                        : null
                    "
                  >
                    <input
                      class="custom-control-input"
                      type="checkbox"
                      :id="noSignalAlert.title"
                      v-model="
                        editValue.currentPreferences.preferences.find(p => p.type == noSignalAlert.type).receiveSms
                      "
                      :data-test-hook="
                        noSignalAlert.title
                          .toLowerCase()
                          .replace(/\s/g, '-')
                          .concat('-switch-edit-sms-prefs-modal')
                      "
                    />
                    <label :for="noSignalAlert.title" class="custom-control-label"></label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ModalBody>

    <ModalFooter>
      <button
        class="btn btn-outline-warning"
        @click="closeModal('EditTextSmsPreferencesModal-' + editTextSmsPreferencesModalInfo.apparatusId)"
        :disabled="saving"
        data-test-hook="cancel-button-edit-sms-prefs-modal"
      >
        {{ $t('xCancel') }}
      </button>
      <button
        class="btn btn-warning"
        @click="save()"
        :disabled="!validThreshold || saving"
        data-test-hook="save-button-edit-sms-prefs-modal"
      >
        {{ $t('xSave') }}
        <div
          class="ml-2 spinner-border text-muted"
          v-if="saving"
          data-test-hook="save-button-spinner-edit-sms-prefs-modal"
        ></div>
      </button>
    </ModalFooter>
  </Modal>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { required, minLength, maxLength, email, numeric, between, requiredIf } from 'vuelidate/lib/validators'
import * as Constants from '@/constants'

export default {
  props: ['editTextSmsPreferencesModalInfo'],
  computed: {
    ...mapState({
      currentAppConfig: state => state.application.currentAppConfig
    }),
    vppFeatureFlag() {
      return Constants.VirtualPowerPlantFF
    },
    preferenceTypeTankFuelWarning() {
      return Constants.PreferenceTypeTankFuelWarning
    },
    fuelTankApparatusType() {
      return Constants.FuelTankApparatusType
    }
  },
  data() {
    return {
      editValue: null,
      saving: false,
      validThreshold: null,
      minThresholdVal: 17,
      maxThresholdVal: 99,
      somethingInList: false,
      message: 'EditTextSmsPreferencesModal: ',
      settings: [
        {
          title: this.$t('xStatusChanges'),
          icon: 'far fa-history',
          type: Constants.PreferenceTypeStatusChange,
          rowClass: null,
          first: true
        },
        {
          title: this.$t('xMaintenanceReminders'),
          icon: 'far fa-toolbox',
          type: Constants.PreferenceTypeMaintenance,
          rowClass: null
        },
        {
          title: this.$t('xFaults'),
          icon: 'far fa-minus-octagon',
          type: Constants.PreferenceTypeFault,
          rowClass: null
        },
        {
          title: this.$t('xWarnings'),
          icon: 'far fa-exclamation-triangle',
          type: Constants.PreferenceTypeWarning,
          rowClass: null
        },
        {
          title: this.$t('xExerciseConfirmations'),
          icon: 'far fa-bolt',
          type: Constants.PreferenceTypeExercise,
          rowClass: null
        },
        {
          title: this.$t('xVirtualPowerPlant'),
          icon: 'far fa-regular fa-plug',
          type: Constants.PreferenceTypeGridServices,
          rowClass: null
        },
        {
          title: this.$t('xCriticalBatteryLevel'),
          icon: 'far fa-light fa-battery-exclamation',
          type: Constants.PreferenceTypeTankBatteryCritcal,
          rowClass: null,
          first: true
        },
        {
          title: this.$t('xTankLevelWarning'),
          icon: 'far fa-light fa-gauge-high',
          type: Constants.PreferenceTypeTankFuelWarning,
          rowClass: null
        }
      ],
      noSignalAlert: {
        title: this.$t('xNoSignalAlerts'),
        icon: 'far fa-signal-slash',
        type: Constants.PreferenceTypeDisconnected,
        rowClass: null
      }
    }
  },
  validations: {
    editValue: {
      phone: {
        required,
        minLength: minLength(10),
        maxLength: maxLength(12),
        numeric
      },
      email: {
        required,
        email
      },
      currentPreferences: {
        threshold1: {
          required: requiredIf(function() {
            return this.editValue.currentPreferences.preferences.find(
              p => p.type == Constants.PreferenceTypeTankFuelWarning
            ).receiveSms
          }),
          between: between(17, 99),
          maxLength: maxLength(2),
          numeric
        }
      }
    }
  },
  methods: {
    async save() {
      this.saving = true
      for (let setting of this.settings) {
        if (this.settingTypeList(this.editValue.currentPreferences, setting.type)) {
          if (
            this.editValue.currentPreferences.preferences.find(p => p.type == setting.type).receiveSms !=
            this.editTextSmsPreferencesModalInfo.currentPreferences.preferences.find(p => p.type == setting.type)
              .receiveSms
          ) {
            await this.savePreference(setting.type, 'phone')
          }
          if (setting.type == Constants.PreferenceTypeTankFuelWarning) {
            if (
              this.editValue.currentPreferences.threshold1 !=
              this.editTextSmsPreferencesModalInfo.currentPreferences.threshold1
            ) {
              await this.saveThreshold()
            }
          }
          this.closeModal('EditTextSmsPreferencesModal-' + this.editTextSmsPreferencesModalInfo.apparatusId)
        }
      }
      if (
        this.editValue.currentPreferences.preferences.find(p => p.type == this.noSignalAlert.type).receiveSms !=
        this.editTextSmsPreferencesModalInfo.currentPreferences.preferences.find(p => p.type == this.noSignalAlert.type)
          .receiveSms
      ) {
        await this.savePreference(this.noSignalAlert.type, 'phone')
        this.closeModal('EditTextSmsPreferencesModal-' + this.editTextSmsPreferencesModalInfo.apparatusId)
      }

      this.$emit('buildPreferenceInfo', {})
      this.saving = false
    },
    async savePreference(preferenceType, notificationType) {
      await this.$store
        .dispatch('updateApparatusPreference', {
          apparatusId: this.editTextSmsPreferencesModalInfo.apparatusId,
          preference: this.editValue.currentPreferences.preferences.find(p => p.type == preferenceType)
        })
        .then(response => {
          this.editTextSmsPreferencesModalInfo.currentPreferences.preferences.find(
            p => p.type == preferenceType
          ).receiveSms = !this.editTextSmsPreferencesModalInfo.currentPreferences.preferences.find(
            p => p.type == preferenceType
          ).receiveSms
        })
    },
    async saveThreshold() {
      await this.$store
        .dispatch('updateTankUtilityApparatus', {
          apparatusId: this.editTextSmsPreferencesModalInfo.apparatusId,
          threshold1: this.editValue.currentPreferences.threshold1
        })
        .then(response => {
          this.$store.dispatch('displayToast', {
            title: this.$t('xSuccess'),
            message: this.$t('xTankThresholdUpdated'),
            variant: 'success'
          })
        })
        .catch(error => {
          this.$store.dispatch('displayToast', {
            title: this.$t('xError'),
            message: this.$t('xTankThresholdFailed'),
            variant: 'danger'
          })
        })
    },
    isEnrolledInVPP(settings, preference) {
      if (settings.type != Constants.PreferenceTypeGridServices) return true
      if (preference.currentPreferences.isEnrolledInVPP) return true
      return false
    },
    vppFlag() {
      return (
        this.currentAppConfig &&
        this.currentAppConfig.featureFlags &&
        this.currentAppConfig.featureFlags[this.vppFeatureFlag]
      )
    },
    settingTypeList(currentPreferences, settingType) {
      if (this.editTextSmsPreferencesModalInfo.apparatusType == Constants.FuelTankApparatusType) {
        if (
          settingType == Constants.PreferenceTypeTankBatteryWarning ||
          settingType == Constants.PreferenceTypeTankBatteryCritcal ||
          settingType == Constants.PreferenceTypeTankFuelWarning
        ) {
          this.somethingInList = true
          return true
        }
        return false
      }
      if (this.editTextSmsPreferencesModalInfo.apparatusType == Constants.HsbApparatusType) {
        if (
          settingType == Constants.PreferenceTypeTankBatteryWarning ||
          settingType == Constants.PreferenceTypeTankBatteryCritcal ||
          settingType == Constants.PreferenceTypeTankFuelWarning
        ) {
          return false
        }
        if (
          (settingType != Constants.PreferenceTypeGridServices &&
            currentPreferences &&
            currentPreferences.subscription &&
            this.subscriptionPaid(currentPreferences.subscription)) ||
          settingType == Constants.PreferenceTypeDisconnected
        ) {
          this.somethingInList = true
          return true
        }
        if (
          settingType == Constants.PreferenceTypeGridServices &&
          currentPreferences &&
          currentPreferences.subscription &&
          currentPreferences.preferences.find(p => p.type == settingType) != undefined &&
          this.vppFlag()
        ) {
          this.somethingInList = true
          return true
        }
        return false
      } else {
        if (settingType == Constants.PreferenceTypeDisconnected) {
          this.somethingInList = true
          return true
        }
      }
    },
    iconForDisplay(type, icon) {
      if (type == Constants.PreferenceTypeDisconnected) {
        if (
          this.editValue.currentPreferences.deviceType == Constants.HsbWifiDeviceType ||
          this.editValue.currentPreferences.deviceNetworkType == Constants.HsbWifiDeviceType
        )
          return 'far fa-light fa-wifi-slash'
        if (
          this.editValue.currentPreferences.deviceType == Constants.HsbLteDeviceType ||
          this.editValue.currentPreferences.deviceType == Constants.HsbEthDeviceType ||
          this.editValue.currentPreferences.deviceType == Constants.HsbCdmaDeviceType ||
          this.editValue.currentPreferences.deviceNetworkType == Constants.HsbLteDeviceType
        )
          return 'far fa-signal-slash'
      }
      return icon
    }
  },
  watch: {
    editTextSmsPreferencesModalInfo: function(newVal, oldVal) {
      this.somethingInList = false
      this.editValue = _.cloneDeep(newVal)
      if (this.editValue.currentPreferences.threshold1 > 99 || this.editValue.currentPreferences.threshold1 < 1) {
        this.editValue.currentPreferences.threshold1 = 30
      }
    },
    'editValue.currentPreferences.threshold1': function(newVal, oldVal) {
      if (newVal && (newVal < this.minThresholdVal || newVal > this.maxThresholdVal)) {
        this.validThreshold = false
      } else if (!newVal) this.validThreshold = false
      else if (isNaN(newVal)) this.validThreshold = false
      else this.validThreshold = true
    },
    deep: true
  },
  mounted() {
    this.editValue = _.cloneDeep(this.editTextSmsPreferencesModalInfo)
    if (this.editValue.currentPreferences.threshold1 > 99 || this.editValue.currentPreferences.threshold1 < 17) {
      this.editValue.currentPreferences.threshold1 = 30
    }
    this.validThreshold = true
    if (!this.currentAppConfig) this.$store.dispatch('getApplicationConfiguration', 'Any')
  }
}
</script>
