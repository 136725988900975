<template>
  <div>
    <table
      v-if="orgApparatuses"
      head-variant="light"
      class="table table-striped table-hover search-table"
      :items="orgApparatuses"
      stacked="md"
    >
      <thead>
        <tr>
          <th>Generator Name</th>
          <th>Serial Number</th>
          <th>Generator Address</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="apparatus in orgApparatuses" :key="apparatus.apparatus.serialNumber">
          <td>{{ apparatus.apparatus.name }}</td>
          <td>
            {{ apparatus.apparatus.serialNumber }}
            <button class="btn ml-2 btn-light btn-sm" @click="copyToClipboard(apparatus.apparatus.serialNumber)">
              <i class="far fa-copy colorOrange" />
            </button>
          </td>
          <td>
            {{ DisplayAddress(apparatus.apparatus) }}
            <button class="btn ml-2 btn-light btn-sm" @click="copyToClipboard(DisplayAddress(apparatus.apparatus))">
              <i class="far fa-copy colorOrange" />
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <div v-else>
      This user has no generators in their account.
    </div>
  </div>
</template>
<script>
export default {
  props: ['orgApparatuses']
}
</script>
<style scoped>
.search-table {
  font-size: 0.75rem;
}
</style>
