<template>
  <div>
    <b-modal id="portables-enrollment" size="lg" no-close-on-backdrop no-close-on-esc no-fade no-stacking centered>
      <template #modal-header>
        <h3>
          <strong class="colorOrange"><i class="fas fa-plus" /> {{ $t('xAddPortableGenerator') }}</strong>
        </h3>
      </template>
      <template #modal-footer>
        <b-button
          variant="outline-warning"
          @click="$bvModal.show('close-portable-enrollment-flow')"
          data-test-hook="cancel-button-step1-enrollment"
        >
          {{ $t('xCancel') }}
        </b-button>
        <b-button
          variant="warning"
          @click="validateAndEnrollGen()"
          :disabled="validatingAndEnrolling || !userEnteredSerialNumber || !generatorName || validationFailed"
          data-test-hook="ContinueBtn"
        >
          {{ $t('xContinue') }} <i class="fas fa-arrow-right fa-lg" />
          <b-spinner class="ml-2" v-if="validatingAndEnrolling"></b-spinner>
        </b-button>
      </template>
      <b-row align-v="center" class="mb-2">
        <b-col>
          <h5>
            <strong>{{ $t('xEnterYourGenSN') }}</strong>
          </h5>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-input-group>
            <b-form-input
              maxlength="18"
              v-mask="'NNNNNNNNNNNNNNNNNN'"
              v-model="userEnteredSerialNumber"
              autocomplete="new-password"
              data-test-hook="SerialNumberField"
            />
          </b-input-group>
        </b-col>
      </b-row>
      <b-row class="mt-4">
        <b-col>
          <h5>
            <strong>{{ $t('xNameYourGen') }}</strong>
          </h5>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-input-group>
            <b-form-input
              maxlength="50"
              v-model="generatorName"
              autocomplete="new-password"
              data-test-hook="GeneratorNameField"
            />
          </b-input-group>
        </b-col>
      </b-row>
      <div
        v-if="showValidateAndEnrollError"
        :show="showValidateAndEnrollError"
        class="alert alert-danger alert--validation"
      >
        {{ validateAndEnrollErrorMessage }}
      </div>
    </b-modal>

    <b-modal
      id="portables-enrollment-success"
      size="lg"
      no-close-on-backdrop
      no-close-on-esc
      no-fade
      no-stacking
      hide-header
      centered
    >
      <template #modal-footer>
        <b-button variant="outline-warning" @click="goToDashboard()" block data-test-hook="GenDetailsBtn">
          {{ $t('xGoToDashboard') }}
        </b-button>
      </template>
      <b-row class="text-center">
        <b-col>
          <h2 class="success-color">{{ $t('xSuccess') }}!</h2>
        </b-col>
      </b-row>
      <b-row class="text-center">
        <b-col>
          {{ $t('xPortableEnrollmentSuccessMessage', { 0: generatorName }) }}
        </b-col>
      </b-row>
      <b-row class="text-center mt-4">
        <b-col>
          <b-img height="202.13px" :src="require('@/assets/images/PGenGraphic.svg')" />
        </b-col>
      </b-row>
    </b-modal>

    <b-modal id="close-portable-enrollment-flow" centered no-close-on-backdrop no-close-on-esc no-fade no-stacking>
      <template #modal-header>
        <b-row class="w-100" align-v="center" no-gutters>
          <b-col>
            <h3>
              <strong class="colorOrange">
                <i class="fa-solid fa-triangle-exclamation colorOrange" />
                {{ $t('xAreYouSure') }}
              </strong>
            </h3>
          </b-col>
          <b-col cols="auto" class="float-right">
            <b-button variant="icon-button" @click="$bvModal.show('portables-enrollment')">
              <i class="fas fa-times" />
            </b-button>
          </b-col>
        </b-row>
      </template>
      <template #modal-footer>
        <b-button variant="outline-warning" @click="$bvModal.show('portables-enrollment')">
          {{ $t('xNoContinue') }}
        </b-button>
        <b-button variant="warning" @click="exitFlow()">
          {{ $t('xYesIAmSure') }}
        </b-button>
      </template>
      <b-row>
        <b-col>
          {{ $t('xExitPortablesEnrollmentFlowMessage') }}
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>
<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import * as Constants from '@/constants'
import { validationMixin } from 'vuelidate'

export default {
  mixins: [validationMixin],
  data() {
    return {
      userEnteredSerialNumber: null,
      generatorName: null,
      validatingAndEnrolling: false,
      apparatusModelNumber: null,
      apparatusDescription: null,
      validationFailed: false,
      showValidateAndEnrollError: false,
      validateAndEnrollErrorMessage: null
    }
  },
  computed: {
    ...mapState({
      accountProfile: state => state.account.accountProfile
    })
  },
  methods: {
    exitFlow() {
      this.resetData()
      this.$bvModal.hide('close-portable-enrollment-flow')
    },
    resetData() {
      this.validationFailed = false
      this.userEnteredSerialNumber = null
      this.generatorName = null
      this.validatingAndEnrolling = false
      this.apparatusModelNumber = null
      this.apparatusDescription = null
      this.showValidateAndEnrollError = false
      this.validateAndEnrollErrorMessage = null
    },
    goToDashboard() {
      this.resetData()
      this.$bvModal.hide('portables-enrollment-success')
    },
    validateAndEnrollGen() {
      this.validatingAndEnrolling = true
      this.$store
        .dispatch('ValidateSerialNumberWithQueryParams', {
          serialNumber: this.userEnteredSerialNumber,
          apparatusType: Constants.PortableGeneratorApparatusType
        })
        .then(response => {
          if (response.data.success) {
            this.apparatusModelNumber = response.data.modelNumber
            this.apparatusDescription = response.data.description
            let address = this.accountProfile.address
            this.$store
              .dispatch('EnrollApparatus', {
                organizationId: this.accountProfile.organizationId,
                serialNumber: this.userEnteredSerialNumber,
                apparatusName: this.generatorName,
                apparatusAddress: address,
                apparatusType: Constants.PortableGeneratorApparatusType,
                apparatusModelNumber: this.apparatusModelNumber,
                apparatusDescription: this.apparatusDescription
              })
              .then(response => {
                let newApparatusInfo = response.data
                if (!response.data.registrationSuccess) {
                  this.validateAndEnrollErrorMessage = this.$t('xYourGeneratorWasSuccessfullyEnrolledBut')
                  this.showValidateAndEnrollError
                  this.$appInsights.trackEvent({
                    name: 'enrollment-registration-failure',
                    properties: {
                      orgId:
                        this.accountProfile && this.accountProfile.organizationId
                          ? this.accountProfile.organizationId
                          : null,
                      userId: this.accountProfile.userId,
                      apparatusId: response.data.apparatusId,
                      serialNumber: this.userEnteredSerialNumber
                    }
                  })
                }
                this.$store
                  .dispatch('changeBillingAccountProduct', {
                    organizationId: this.accountProfile.organizationId,
                    apparatusId: newApparatusInfo.apparatusId,
                    productId: Constants.PortablesProductId
                  })
                  .then(response => {
                    this.validatingAndEnrolling = false
                    this.showValidateAndEnrollError = false
                    this.$bvModal.show('portables-enrollment-success')
                    this.$store.dispatch('getApparatusesSilent')
                  })
                  .catch(error => {
                    this.validatingAndEnrolling = false
                    let failureMessage = this.$t('xFailedSetUpGeneratorSubscription')
                    if (error.response?.data?.message) {
                      try {
                        let messageAsJson = JSON.parse(error.response.data.message.replace(/\\"/g, '"'))
                        if (messageAsJson?.gatewayResponse?.message) {
                          failureMessage = messageAsJson.gatewayResponse.message
                        }
                      } catch (err) {}
                    }
                    this.paying = false
                    this.validateAndEnrollErrorMessage = failureMessage
                    this.showValidateAndEnrollError = true
                  })
              })
              .catch(error => {
                this.validationFailed = true
                this.validatingAndEnrolling = false
                this.validateAndEnrollErrorMessage = this.$t('xFailedToAddApparatus')
                this.showValidateAndEnrollError = true
              })
          } else {
            this.validationFailed = true
            this.validatingAndEnrolling = false
            this.validateAndEnrollErrorMessage = response.data.message
            this.showValidateAndEnrollError = true
          }
        })
        .catch(error => {
          this.validationFailed = true
          this.validatingAndEnrolling = false
          this.validateAndEnrollErrorMessage = error.response.data.message
          this.showValidateAndEnrollError = true
        })
    }
  },
  watch: {
    userEnteredSerialNumber: function(newVal, oldVal) {
      if (this.validationFailed) {
        if (newVal != oldVal) this.validationFailed = false
      }
    }
  },
  mounted() {
    if (!this.accountProfile) {
      this.$store.dispatch('getAccountProfile')
    }
  }
}
</script>
<style scoped>
.success-color {
  color: #35a744 !important;
}
</style>
