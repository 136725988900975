<template>
  <div>
    <Modal modalId="manage-sub-paid-step-1" size="modal-lg" hide-footer>
      <ModalHeader :title="$t('xManageSubscription')" icon="far fa-badge-dollar colorOrange mr-1">
        <div class="cols-auto float-right">
          <button
            type="button"
            class="btn btn-icon-button btn-icon-button--gray"
            @click="closeModals()"
            data-test-hook="CloseModalBtn"
          >
            <i class="fas fa-times" />
          </button>
        </div>
      </ModalHeader>
      <ModalBody>
        <div v-if="currentSubscription[0].productId == dealerManaged">
          <div class="row">
            <div class="col" data-test-hook="DealerManagedMsg">
              {{ $t('xYourDealerHasAManagedSubscription') }}
            </div>
          </div>
        </div>
        <div v-else-if="availableSubscriptionProducts && !gettingSubscriptions">
          <div class="card-group deck mx-0 mb-5" v-for="(product, index) in availableSubscriptionProducts" :key="index">
            <div
              class="card hoverPointer"
              @click="
                currentSubscription[0].productId == wifiAnnual ||
                currentSubscription[0].productId == lteAnnual ||
                currentSubscription[0].productId == cdmaAnnual
                  ? annualToMonthlyFlow(product)
                  : monthlyToAnnualFlow(product)
              "
              :data-test-hook="'subscription-clickable-card-step1-manage-sub-modal' + index"
            >
              <div class="row w-100 align-item-center">
                <div class="col d-flex align-items-center">
                  <i class="far fa-calendar-edit colorOrange pr-2 ml-3" />
                  <p class="mb-3 mt-3">
                    <strong>
                      {{ $t('xSwitchTo') }} {{ product.name }} ({{ product.cost.toFixed(2) | formatCurrency }}
                      {{ $t('xPer') }}
                      <span v-if="product.durationInMonths == 12">{{ $t('xYear') }}</span>
                      <span v-else>{{ $t('xMonth') }}</span
                      >)</strong
                    >
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            class="row"
            v-if="
              currentSubscription[0].productId == wifiAnnual ||
                currentSubscription[0].productId == wifiMonthly ||
                currentSubscription[0].productId == ethAnnual ||
                currentSubscription[0].productId == ethMonthly
            "
          >
            <div class="col" v-if="!currentApparatusDetails.enrolledInVpp">
              <a
                href="#"
                target="_self"
                class="hoverPointer"
                data-test-hook="DowngradeToFreeBtn"
                @click="openModal('downgrade-paid-sub-step-1'), closeModal('manage-sub-paid-step-1')"
              >
                <i class="far fa-arrow-down" /> {{ $t('xDowngradeToAFreePlan') }}
              </a>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col asterisk-text" v-if="!currentApparatusDetails.enrolledInVpp">
              <a
                class="hoverPointer"
                href="#"
                target="_self"
                @click="emitCancelFlow()"
                data-test-hook="CancelAutoRenewBtn"
              >
                <i class="fa fa-cancel" /> {{ $t('xCancelAutoRenew') }}
              </a>
            </div>
          </div>
          <div class="row" v-if="currentApparatusDetails.enrolledInVpp">
            <div class="col asterisk-text">
              {{ $t('xVppCannotCancelMessage') }}
            </div>
          </div>
        </div>
        <div v-else-if="!availableSubscriptionProducts && gettingSubscriptions">
          <div class="row">
            <div class="col">{{ $t('xCouldNotFindAnyAvailableSubscriptionsForYourGenerator') }}'</div>
          </div>
        </div>
        <div v-else>
          <span class="spinner-border spinner-border-md text-warning" style="width: 10rem; height: 10rem;"></span>
        </div>
      </ModalBody>
    </Modal>

    <Modal modalId="manage-sub-paid-step-2.1" size="modal-lg">
      <ModalHeader :title="$t('xManageSubscription')" icon="far fa-badge-dollar colorOrange mr-1">
        <div class="cols-auto float-right">
          <button
            type="button"
            class="btn btn-icon-button btn-icon-button--gray"
            @click="closeModals()"
            data-test-hook="x-button-step2.1-manage-sub-modal"
          >
            <i class="fas fa-times" />
          </button>
        </div>
      </ModalHeader>
      <ModalBody>
        <div class="row">
          <div class="col">
            <p>
              <strong>{{ $t('xAreYouSure') }}</strong>
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col">
            {{ $t('xYoullSaveAnEstimated') }} {{ getSubscriptionSavings(newSubscription) }}%
            {{ $t('xIfYouKeepYourCurrentAnnualPlan') }}
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <button
          type="button"
          class="btn btn-outline-warning"
          @click="openModal('manage-sub-paid-step-1'), closeModal('manage-sub-paid-step-2.1')"
          data-test-hook="previous-button-step2.1-manage-sub-modal"
        >
          {{ $t('xPrevious') }}
        </button>
        <button
          type="button"
          class="btn btn-warning"
          @click="openModal('manage-sub-paid-step-3.1')"
          data-test-hook="yes-im-sure-button-step2.1-manage-sub-modal"
        >
          {{ $t('xYesImSure') }}
        </button>
      </ModalFooter>
    </Modal>

    <Modal modalId="manage-sub-paid-step-2.2" size="modal-lg">
      <ModalHeader :title="$t('xManageSubscription')" icon="far fa-badge-dollar colorOrange mr-1">
        <div class="cols-auto float-right">
          <button
            type="button"
            class="btn btn-icon-button btn-icon-button--gray"
            @click="closeModals()"
            data-test-hook="x-button-step2.2-manage-sub-modal"
          >
            <i class="fas fa-times" />
          </button>
        </div>
      </ModalHeader>
      <ModalBody>
        <div class="row mt-3 ml-1">
          <div class="col">
            <h4>{{ $t('xReviewSubscriptionChanges') }}</h4>
          </div>
        </div>
        <div class="row mt-3 ml-1">
          <div class="col">
            <div class="row mb-1">
              <div class="col">
                <strong> {{ $t('xYourCurrentSubscription') }}: {{ currentSubscription[0].productName }} </strong>
              </div>
            </div>
            <div class="row mb-1">
              <div class="col">
                {{ $t('xStartDateLower') }}: {{ $moment(currentSubscription[0].startDate).format($t('xShortDate')) }}
              </div>
            </div>
            <div class="row mb-1">
              <div class="col">
                {{ $t('xEndDateLower') }}: {{ $moment(String(calculateEnd)).format($t('xShortDate')) }}
              </div>
            </div>
            <div
              v-if="
                coupon[0] != null &&
                  $moment().isBefore($moment(coupon[0].endDate).add(23, 'hours', 59, 'minutes', 59, 'seconds')) &&
                  $moment().isAfter($moment(coupon[0].startDate))
              "
              class="row mb-1"
            >
              <div class="col">
                {{ $t('xSubscriptionCost') }}: {{ calculatePrice.toFixed(2) | formatCurrency }}
                {{ $t('xPerMonth') }} ({{ $t('xRegularly') }} {{ currentSubscription[0].cost | formatCurrency }}
                {{ $t('xPerMonth') }})
                <p style="font-size: 13px" class="mt-1">
                  {{ coupon[0].percentage }}% {{ $t('xDiscountApplied')
                  }}{{ $moment(coupon[0].endDate).format($t('xShortDate')) }}
                </p>
                <strong
                  ><i
                    ><p style="font-size: 13px" class="mt-1">{{ $t('xCouponCodeWillNotTransfer') }}</p></i
                  ></strong
                >
              </div>
            </div>
            <div v-else class="row mb-1">
              <div class="col">
                {{ $t('xSubscriptionCost') }}: {{ currentSubscription[0].cost.toFixed(2) | formatCurrency }}
                {{ $t('xPerMonth') }}
              </div>
            </div>
          </div>
        </div>
        <div v-if="newSubscription" class="row my-3 ml-1">
          <div class="col">
            <div class="row mb-1">
              <div class="col">
                <strong> {{ $t('xYourNewSubscription') }}: {{ $t(newSubscription.name) }} </strong>
              </div>
            </div>
            <div class="row mb-1">
              <div class="col">
                {{ $t('xStartDateLower') }}: {{ $moment(String(subscriptionStart)).format($t('xShortDate')) }}
              </div>
            </div>
            <div class="row mb-1">
              <div class="col">
                {{ $t('xSubscriptionCost') }}: {{ newSubscription.cost.toFixed(2) | formatCurrency }}
                {{ $t('xPerYear') }}
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <button
          type="button"
          class="btn btn-outline-warning"
          @click="openModal('manage-sub-paid-step-1'), closeModal('manage-sub-paid-step-2.2')"
          data-test-hook="previous-button-step2.2-manage-sub-modal"
        >
          {{ $t('xPrevious') }}
        </button>
        <button
          type="button"
          class="btn btn-warning"
          @click="openModal('manage-sub-paid-step-4'), closeModal('manage-sub-paid-step-2.2')"
          data-test-hook="next-button-step2.2-manage-sub-modal"
        >
          {{ $t('xNext') }}
        </button>
      </ModalFooter>
    </Modal>

    <Modal modalId="manage-sub-paid-step-3.1" size="modal-lg">
      <ModalHeader :title="$t('xManageSubscription')" icon="far fa-badge-dollar colorOrange mr-1">
        <div class="cols-auto float-right">
          <button
            type="button"
            class="btn btn-icon-button btn-icon-button--gray"
            @click="closeModals()"
            data-test-hook="x-button-step3.1-manage-sub-modal"
          >
            <i class="fas fa-times" />
          </button>
        </div>
      </ModalHeader>
      <ModalBody>
        <div class="row">
          <div class="col">
            <h4>{{ $t('xSubscriptionChangeNotice') }}</h4>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col">
            {{ $t('xYourCurrentActiveAnnualSubscriptionBeganOn') }}
            {{ $moment(currentSubscription[0].startDate).format($t('xShortDate')) }}
            {{ $t('xInTheAmountOf') }} {{ currentSubscription[0].cost.toFixed(2) | formatCurrency }}.
          </div>
        </div>
        <div class="row mb-3">
          <div class="col">
            {{ $t('xYourCurrentSubscriptionIsPaidThrough') }}
            {{
              $moment(currentSubscription[0].nextBillingDate)
                .subtract(1, 'd')
                .format($t('xShortDate'))
            }}.
          </div>
        </div>
        <div class="row">
          <div class="col" v-if="newSubscription">
            {{ $t('xYourNewMonthlyPaymentOf') }} {{ newSubscription.cost.toFixed(2) | formatCurrency }}
            {{ $t('xWillBeginOn') }} {{ $moment(currentSubscription[0].nextBillingDate).format($t('xShortDate')) }}.
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <button
          type="button"
          class="btn btn-outline-warning"
          @click="closeModal('manage-sub-paid-step-3.1'), openModal('manage-sub-paid-step-2.1')"
          data-test-hook="previous-button-step3.1-manage-sub-modal"
        >
          {{ $t('xPrevious') }}
        </button>
        <button
          type="button"
          class="btn btn-warning"
          @click="openModal('manage-sub-paid-step-4')"
          data-test-hook="next-button-step3.1-manage-sub-modal"
        >
          {{ $t('xNext') }}
        </button>
      </ModalFooter>
    </Modal>

    <Modal modalId="manage-sub-paid-step-4" size="modal-lg">
      <ModalHeader :title="$t('xManageSubscription')" icon="far fa-badge-dollar colorOrange mr-1">
        <div class="cols-auto float-right">
          <button
            type="button"
            class="btn btn-icon-button btn-icon-button--gray"
            @click="closeModals()"
            data-test-hook="x-button-step4-manage-sub-modal"
          >
            <i class="fas fa-times" />
          </button>
        </div>
      </ModalHeader>
      <ModalBody>
        <div v-if="paymentInfo">
          <div class="row">
            <div class="col">
              <h3>
                <strong>{{ $t('xCreditCardInfo') }}*</strong>
              </h3>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <p>
                <strong>
                  <i class="fal fa-credit-card" />
                  {{ paymentInfo.cardType }} -
                  {{ paymentInfo.cardSuffix }}
                </strong>
              </p>
              <p>{{ $t('xExpires') }} {{ paymentInfo.expirationMonth + '/' + paymentInfo.expirationYear }}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-12">{{ accountProfile.firstName }} {{ accountProfile.lastName }}</div>
            <div class="col-12">
              {{ DisplayAddress(accountProfile.address) }}
            </div>
          </div>

          <div class="row my-3">
            <div class="col-12" data-test-hook="TotalPaymentLabel">
              <div class=" subscription-cost">
                <h3 class="mb-0">
                  {{ $t('xTotalPayment') }}:
                  <span v-if="newSubscription" data-test-hook="TotalPayment">
                    {{ newSubscription.cost.toFixed(2) | formatCurrency }}
                  </span>
                </h3>
                <p>
                  {{ $t('xStartingOn') }} {{ $moment(currentSubscription[0].nextBillingDate).format($t('xShortDate')) }}
                </p>
                <p>
                  <i class="body-text-color">
                    {{ $t('xBilled') }}
                    <span v-if="newSubscription && newSubscription.durationInMonths == 12">{{ $t('xYearly') }}</span>
                    <span v-else>{{ $t('xMonthly') }}</span
                    >**
                  </i>
                </p>
              </div>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col">
              <p>
                <i class="asterisk-text">**{{ $t('xYourNextScheduledPaymentWillBeDeducted') }}</i>
              </p>
              <p>
                <i>{{ $t('xThisSubscriptionWillAutoRenew') }}</i>
              </p>
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <button
          type="button"
          class="btn btn-outline-warning"
          @click="
            currentSubscription[0].productId == wifiAnnual ||
            currentSubscription[0].productId == lteAnnual ||
            currentSubscription[0].productId == wifiAnnual
              ? openModal('manage-sub-paid-step-3.1')
              : openModal('manage-sub-paid-step-2.2'),
              closeModal('manage-sub-paid-step-4')
          "
          data-test-hook="previous-button-step4-manage-sub-modal"
        >
          {{ $t('xPrevious') }}
        </button>
        <button
          type="button"
          class="btn btn-warning"
          @click="changeSubscription()"
          :disabled="changingSubscription"
          data-test-hook="confirm-button-step4-manage-sub-modal"
        >
          {{ $t('xConfirm') }}
          <b-spinner class="ml-2" v-if="changingSubscription" />
        </button>
      </ModalFooter>
    </Modal>

    <Modal modalId="manage-sub-paid-step-5" size="modal-md">
      <ModalHeader :title="$t('xManageSubscription')" icon="far fa-badge-dollar colorOrange mr-1">
        <div class="cols-auto float-right">
          <button
            type="button"
            class="btn btn-icon-button btn-icon-button--gray"
            @click="closeModals()"
            data-test-hook="x-button-step5-manage-sub-modal"
          >
            <i class="fas fa-times" />
          </button>
        </div>
      </ModalHeader>
      <ModalBody>
        <div class="row no-gutters">
          <div class="col">
            <lottie :options="successAnimationOptions" v-on:animCreated="handleAnimation" :height="80" :width="80" />
          </div>
        </div>
        <div class="row text-center mt-2">
          <div class="col">
            <h1>{{ $t('xSuccess') }}!</h1>
            <p>{{ $t('xYourSubscriptionPlanHasBeenSuccessfullyUpdated') }}</p>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <button
          type="button"
          class="btn btn-warning"
          @click="closeModals(), emitSuccess()"
          data-test-hook="ok-button-step5-manage-sub-modal"
        >
          {{ $t('xOK') }}
        </button>
      </ModalFooter>
    </Modal>
    <DowngradeSubscriptionModals :currentSubscription="currentSubscription" @success="emitSuccess()" />
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import Lottie from '../lottie.vue'
import * as successAnimation from '@/assets/images/manage-subscription-success.json'
import DowngradeSubscriptionModals from '@/components/ApparatusDetails/DowngradeSubscriptionModals'
import * as Constants from '@/constants'

export default {
  components: { lottie: Lottie, DowngradeSubscriptionModals },
  props: ['currentSubscription', 'paymentInfo', 'coupon'],
  data() {
    return {
      availableSubscriptionProducts: null,
      gettingSubscriptions: true,
      newSubscription: null,
      successAnimationOptions: { animationData: successAnimation, loop: false },
      changingSubscription: false
    }
  },
  computed: {
    ...mapState({
      accountProfile: state => state.account.accountProfile,
      currentApparatusDetails: state => state.apparatus.currentApparatusDetails
    }),
    freeWifiEth() {
      return Constants.FreeWifiEthProductId
    },
    wifiMonthly() {
      return Constants.WiFiMonthlyProductId
    },
    wifiAnnual() {
      return Constants.WiFiAnnualProductId
    },
    ethMonthly() {
      return Constants.EthMonthlyProductId
    },
    ethAnnual() {
      return Constants.EthAnnualProductId
    },
    lteMonthly() {
      return Constants.LteMonthlyProductId
    },
    lteAnnual() {
      return Constants.LteAnnualProductId
    },
    cdmaMonthly() {
      return Constants.CdmaMonthlyProductId
    },
    cdmaAnnual() {
      return Constants.CdmaAnnualProductId
    },
    dealerManaged() {
      return Constants.DealerManagedProductId
    },
    calculatePrice() {
      if (this.currentSubscription.productId != this.FreeProductId) {
        return (
          this.currentSubscription.cost -
          this.currentSubscription.activeProduct[0].cost * (this.coupon[0].percentage / 100)
        )
      } else {
        return 0
      }
    },
    subscriptionStart() {
      const d = this.calculateEnd
      d.setDate(d.getDate() + 1)
      return d
    },
    calculateEnd() {
      const endDate = new Date(this.currentSubscription[0].startDate)
      endDate.setMonth(endDate.getMonth() + 1)
      endDate.setDate(endDate.getDate())
      return endDate
    }
  },
  methods: {
    emitSuccess() {
      this.$emit('success')
    },
    emitCancelFlow() {
      this.$emit('cancelSubscription')
    },
    handleAnimation(anim) {
      this.anim = anim
    },
    changeSubscription() {
      this.changingSubscription = true
      let managePaidNotificationsRequest = {
        apparatusId: this.currentApparatusDetails.apparatusId,
        newProductId: this.newSubscription.id,
        oldProductId: this.currentSubscription[0].productId
      }
      this.$store
        .dispatch('changeBillingAccountProduct', {
          organizationId: this.accountProfile.organizationId,
          apparatusId: this.currentApparatusDetails.apparatusId,
          productId: this.newSubscription.id
        })
        .then(response => {
          this.$store.dispatch('managePaidPreferences', managePaidNotificationsRequest)
          this.changingSubscription = false
          this.openModal('manage-sub-paid-step-5')
          let event = this.createEventLogObjectFromSubscriptionsPage(
            this.currentApparatusDetails,
            this.currentSubscription,
            this.accountProfile,
            this.isImpersonating,
            this.getEventType(this.currentSubscription[0].productId, this.newSubscription.id),
            this.newSubscription.id
          )
          this.$store.dispatch('logEvent', event)
        })
        .catch(error => {
          this.changingSubscription = false
          this.$store.dispatch('displayToast', {
            title: this.$t('xError'),
            message: this.$t('xFailedSetUpGeneratorSubscription'),
            variant: 'danger'
          })
        })
    },
    annualToMonthlyFlow(product) {
      this.newSubscription = product
      this.closeModal('manage-sub-paid-step-1')
      this.openModal('manage-sub-paid-step-2.1')
    },
    monthlyToAnnualFlow(product) {
      this.newSubscription = product
      this.closeModal('manage-sub-paid-step-1')
      this.openModal('manage-sub-paid-step-2.2')
    },
    closeModals() {
      this.closeModal('manage-sub-paid-step-1')
      this.closeModal('manage-sub-paid-step-2.1')
      this.closeModal('manage-sub-paid-step-2.2')
      this.closeModal('manage-sub-paid-step-3.1')
      this.closeModal('manage-sub-paid-step-4')
      this.closeModal('manage-sub-paid-step-5')
      this.newSubscription = null
      this.changingSubscription = false
    },
    getPremiumSubscriptions(product) {
      if (product.id != this.currentSubscription[0].productId && product.id != this.freeWifiEth) {
        return product
      }
    },
    getSubscriptionSavings(product) {
      if (this.currentSubscription && this.currentSubscription[0] && product) {
        return Math.round(((product.cost * 12 - this.currentSubscription[0].cost) / (product.cost * 12)) * 100)
      }
    }
  },
  mounted() {
    this.$store
      .dispatch('getSubscriptionProductByApparatusId', this.$route.params.apparatusId)
      .then(response => {
        this.availableSubscriptionProducts = response.data.filter(this.getPremiumSubscriptions)
      })
      .catch(error => {
        this.$store.dispatch('displayToast', {
          title: this.$t('xError'),
          message: this.$t('xFailedToGetSubscriptionProducts'),
          variant: 'danger'
        })
      })
      .finally(() => {
        this.gettingSubscriptions = false
      })
  }
}
</script>
